<template>
  <div v-if="segmentGroupSelected !== 1" class="container loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('group-frequency') }}</h5>
      </header>
      <lightbox-template-chart v-if="chartGroupFrequency" :settings="chartGroupFrequency" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'FrequencyGroupChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  data() {
    return {
      chartGroupFrequency: undefined,
      segmentGroupSelected: '',
    };
  },
  mounted() {
    this.reset();
    this.fetchGraphic();
  },
  methods: {
    fetchGraphic() {
      getMetrics({
        metrics: 'arr_active_frequency',
        expand: 1,
      })
        .then(({ data: { data } }) => {
          if (this.$_verifyData(data.metrics)) return;

          const frequency = data.metrics.arr_active_frequency;
          const { labels } = data;
          this.loadChartGroupFrequency(frequency, labels);
        })
        .catch(() => {
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadChartGroupFrequency(frequency = [], labels = []) {
      const formatSeries = () => {
        const legends = Object.keys(frequency);
        const value = legends.map(legend => ({
          name: legend,
          data: frequency[legend],
          color: this.$_segmentColor(legend),
        }));
        return value;
      };
      this.chartGroupFrequency = {
        chart: {
          type: 'column',
          height: 280,
        },
        title: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 30,
          itemDistance: 15,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
          },
        },
        xAxis: {
          allowDecimals: false,
          barWidth: 4,
          categories: labels,
          labels: {
            formatter: label => `${label.value}<br/ > ${this.$tc('purchase', label.value)}`,
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineWidth: 3,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0,
          },
          series: {
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
            lineWidth: 3,
            fillOpacity: 1,
          },
        },
        series: formatSeries(),
      };
    },
    reset() {
      this.$_reqConfig();
      this.chartGroupFrequency = [];
      this.segmentGroupSelected = this.$store.getters.selectedSegment.segmentGroupId;
    },
  },
};
</script>
