import store from '@/store';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

const FORMAT_DATE = 'DD-MM-YYYY, HH:mm:ss';

export const useMixpanelConfig = (pageViewName: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
  });
};

export const useMixpanelConfigAudience = (pageViewName: string, id: string | number, name: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    audience_id: id,
    audience_name: name,
  });
};

export const useMixpanelConfigLabels = (pageViewName: string, id: string | number, name: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    labels_id: id,
    labels_name: name,
  });
};

export const useMixpanelConfigPersonas = (pageViewName: string, id: string | number, name: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    personas_id: id,
    personas_name: name,
  });
};

export const useMixpanelConfigProductDetail = (pageViewName: string, id: string | number, name: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    product_id: id,
    product_name: name,
  });
};

export const useMixpanelConfigCategorytDetail = (pageViewName: string, name: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    category_name: name,
  });
};

export const useMixpanelSelectConversionWindow = (pageViewName: string, lookback: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    number_of_days: lookback,
  });
};

export const useMixpanelSelectContactType = (pageViewName: string, contactType: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    contact_type: contactType,
  });
};

export const useMixpanelSelectSegment = (pageViewName: string, segment: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    segment: segment,
  });
};

export const useMixpanelSelectSegmentBy = (pageViewName: string, id: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    id: id,
  });
};

export const useMixpanelSelectAggregation = (pageViewName: string, value: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    selected_aggregation: value,
  });
};

export const useMixpanelExportTableIndicators = (
  pageViewName: string,
  aggregation: string,
  columns: string[],
  fileType: string,
  filterConversionWindow: string,
  filterContactType: string,
  filterSegmentId: string,
  filterSegmentBy: string,
) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    selected_aggregation: aggregation,
    visible_columns: columns,
    startDate: store.getters.dates.startDate,
    endDate: store.getters.dates.endDate,
    filetype: fileType,
    filter_conversion_window: filterConversionWindow,
    filter_contact_type: filterContactType,
    filter_segment_id: filterSegmentBy,
    filter_segment_by: filterSegmentId,
  });
};

export const useMixpanelUpdateColumns = (pageViewName: string, columns: string[]) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    selected_columns: columns,
  });
};

export const useMixpanelSortedColumn = (pageViewName: string, column: string, type: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    column: column,
    orderType: type,
  });
};

export const useMixpanelComparableUpdate = (
  pageViewName: string,
  metrics: string[],
  criteria: string,
  comparisonStoresList: string[],
) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    metrics: metrics,
    criteria: criteria,
    comparisonStoresList: comparisonStoresList,
  });
};

export const useMixpanelDatepicker = (pageViewName: string, startDate: string, endDate: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    current: moment(new Date()).format(FORMAT_DATE),
    startDate: moment(startDate).format(FORMAT_DATE),
    endDate: moment(endDate).format(FORMAT_DATE),
  });
};

export const useMixpanelDatepickerPanelLinks = (pageViewName: string, text: string, dates: object) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    text: text,
    current: moment(new Date()).format(FORMAT_DATE),
    startDate: moment(dates['startDate']).format(FORMAT_DATE),
    endDate: moment(dates['endDate']).format(FORMAT_DATE),
  });
};

export const useMixpanelDataInconsistency = (pageViewName: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
  });
};

export const useMixpanelPushCommunications = (pageViewName: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
  });
};
