import axios from 'axios';
import store from '@/store';

export const getApp = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/app', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getDoubleMatch = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/double_match', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getEcommerce = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/ecommerce', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getEmail = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/email', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getGoogleUserIdSync = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/google_user_id_sync', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getOfflineStore = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/offline_store', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getPush = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/push', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getRockinTags = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/rockin_tags', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getSac = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/sac', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getSms = () => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  return axios.get('/cdp/sms', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};
