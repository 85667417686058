<template>
  <!-- eslint-disable -->
  <div :id="id">
    <div
      class="dropdown dropdown-audiences-add"
      :class="validateIfHasSupportPermission(permissionsSupport) ? 'has-freshdesk-widget' : ''"
    >
      <button
        data-cy="rounded-btn-add-audience"
        id="dropdownAudiencesAdd"
        href="#"
        class="dropdown-toggle btn btn-plus"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="icon icon-plus"></i>
      </button>

      <div
        class="dropdown-menu"
        :class="validateIfHasSupportPermission(permissionsSupport) ? 'has-freshdesk-widget' : ''"
        aria-labelledby="dropdownAudiencesAdd"
      >
        <router-link
          class="dropdown-item"
          :to="{ name: 'AudienceCreateOld', params: { pageHistory: data.page } }"
          :title="$t('audiences.new-audience')"
        >
          {{ $t('audiences.new-audience') }}
        </router-link>
        <a class="dropdown-item" href="#" @click.prevent="audienceImportation">{{ $t('audiences.import') }}</a>
      </div>
    </div>

    <table-list :id="id" :data="data" :type="type">
      <table-list-header>
        <table-list-column data-cy="name-audiences-list-header" order="audience" class-name="col-xl-3">{{
          $t('audiences.name')
        }}</table-list-column>
        <table-list-column
          data-cy="customers-number-audiences-list-header"
          order="customers"
          class-name="col-xl-1 text-right al__customers-contacts"
        >
          {{ $t('audiences.contacts') }}
        </table-list-column>
        <table-list-column
          data-cy="registration-date-audiences-list-header"
          order="date"
          class-name="col-xl-2 offset-xl-1"
        >
          {{ $t('audiences.created') }}
        </table-list-column>
        <table-list-column data-cy="integrations-audiences-list-header" class-name="col-xl-2">{{
          $t('audiences.integration.title')
        }}</table-list-column>
        <table-list-column data-cy="registered-by-audiences-list-header" order="user" class-name="col-xl-2">{{
          $t('audiences.created-by')
        }}</table-list-column>
        <table-list-column class-name="text-right col-1"></table-list-column>
      </table-list-header>

      <table-list-body>
        <table-list-row
          data-cy="audience-list-row"
          v-for="(aud, index) in audiences"
          :key="aud.id"
          :class="
            (opened === aud.id ? 'current' : '') + (opened !== 0 && opened !== aud.id ? 'pointer-events-none' : '')
          "
          :click-callback="() => openAudience(aud)"
        >
          <table-list-column class-name="audience-name col-xl-3">
            <span
              data-cy="name-column-values-audiences-table"
              ref="audience_name"
              class="name"
              :contenteditable="aud.renameActive"
              @blur="renameOnBlur(aud)"
              @keypress.enter.prevent="submitRename($event, aud)"
              >{{ aud.name }}</span
            >
            <span class="info" data-cy="id-column-values-audiences-table">{{ $t('id') }}: {{ aud.id }}</span>
          </table-list-column>
          <table-list-column
            data-cy="customers-column-values-audiences-table"
            class-name="col-xl-1 text-right audience-contacts text-semibold"
          >
            {{ $_formatDecimal(aud.contacts, 1) }}
          </table-list-column>
          <table-list-column class-name="col-xl-2 offset-xl-1 text-semibold text-capitalize">
            {{ $_formatDate(aud.date, dateTimeFormatVariation) }}
          </table-list-column>
          <table-list-column class-name="col-xl-2 integrations">
            <span v-if="aud.connectors.length" class="count">
              {{ aud.connectors.length }} {{ $tc('audiences.platform', aud.connectors.length) }}
            </span>
            <span v-else class="count empty">{{ $t('audiences.no-integration') }}</span>
            <ul v-if="aud.connectors.length" class="connector-list tooltip-white">
              <li v-for="(connector, index) in aud.connectors" :key="index" class="connector row align-items-center">
                <div class="details col-7">
                  <strong class="d-block">
                    <p>{{ connector.name }}</p>
                  </strong>
                  <span v-if="connector.lastExecution">
                    <p>Última: {{ $_formatDate(connector.lastExecution, dateTimeFormatVariation) }}</p>
                  </span>
                  <span v-if="connector.nextExecution === null"><p></p></span>
                  <span v-if="connector.nextExecution">
                    <p>Próxima: {{ $_formatDate(connector.nextExecution, dateTimeFormatVariation) }}</p>
                  </span>
                </div>

                <integration-status :status="connector.status" class="col-5" />
              </li>
            </ul>
          </table-list-column>
          <table-list-column data-cy="creator-column-values-audiences-table" class-name="col-xl-2 text-semibold">
            {{ aud.creator }}
          </table-list-column>
          <table-list-column data-cy="audiences-list-dropdown" class-name="text-right col-1">
            <div class="dropdown">
              <a
                id="dropdownAudiences"
                href="#"
                class="dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="openDropdown(aud.id)"
              >
                <i class="icon icon-menu-14px"></i>
              </a>
              <div class="dropdown-menu aud-dropdown" aria-labelledby="dropdownAudiences">
                <a
                  data-cy="audiences-list-dropdown-rename-option"
                  class="dropdown-item"
                  :class="aud.creatorId !== user.id ? 'disabled' : ''"
                  href="#"
                  @click.prevent="renameTrigger(aud, index)"
                >
                  {{ $t('rename') }}
                </a>
                <a
                  data-cy="audiences-list-dropdown-export-option"
                  class="dropdown-item"
                  :class="aud.contacts === 0 ? 'disabled' : ''"
                  href="#"
                  @click.prevent="audienceExportation(aud)"
                >
                  {{ $t('export') }}
                </a>
                <a
                  data-cy="audiences-list-dropdown-duplicate-option"
                  class="dropdown-item"
                  @click.prevent="duplicateAudience(aud)"
                  href="#"
                >
                  {{ $t('duplicate') }}
                </a>
                <a
                  data-cy="audiences-list-dropdown-delete-option"
                  class="dropdown-item"
                  :class="aud.creatorId !== user.id ? 'disabled' : ''"
                  href="#"
                  @click.prevent="showDeleteModal(aud)"
                >
                  {{ $t('delete') }}
                </a>
              </div>
            </div>
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </table-list>

    <el-dialog v-model="isVisible" id="audience-delete-confirm" append-to-body>
      <p>{{ $t('audiences.do-you-want-delete') }}</p>
      <div class="dialog">
        <div class="cancel btn btn-secondary" @click="hideDeleteModal" data-cy="btn-cancel-audience-modal-remove">
          {{ $t('cancel') }}
        </div>
        <div
          class="confirm btn"
          @click="
            () => {
              deleteTrigger(toDelete);
              hideDeleteModal();
            }
          "
          data-cy="btn-confirm-audience-modal-remove"
        >
          {{ $t('confirm') }}
        </div>
      </div>
    </el-dialog>

    <audiences-exportation :audience="exporting" />
    <audiences-importation />
  </div>
</template>

<script>
/* eslint-disable */

// libs
import store from '@/store';
import { useToast } from 'vue-toastification';
// helpers
import bus from '@/helpers/events/bus';
import { dateTimeFormatVariation } from '@/utilities/constants';
import { numberMixin, dateMixin } from '@/helpers/mixins';
import hasPermission from '@/helpers/permissions';
// services
import { renameAudience, deleteAudience } from '@/services/audiences';
// components
import AudiencesExportation from '@/components/audiences/exportation/AudiencesExportation';
import AudiencesImportation from '@/components/audiences/importation/AudiencesImportation';
import IntegrationStatus from '@/components/audiences/_atoms/IntegrationStatus';
// utilities
import { useMixpanelConfigAudience } from '@/utilities/mixpanel';
import { permissionsSupport } from '@/utilities/accessLevel/WidgetSupportPermissions';

export default {
  name: 'AudiencesList',
  components: {
    AudiencesExportation,
    AudiencesImportation,
    IntegrationStatus,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mixins: [numberMixin, dateMixin],
  props: {
    pageHistory: {
      type: Number,
      default: 1,
    },
    audiences: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      permissionsSupport: permissionsSupport,
      opened: 0,
      toDelete: null,
      exporting: null,
      dateTimeFormatVariation,
      progressBar: 0,
      isVisible: false,
    };
  },
  computed: {
    user() {
      return store.getters.user;
    },
  },
  mounted() {
    this.bodyClick();
  },
  methods: {
    renameTrigger(aud, index) {
      aud.renameActive = true;
      if (aud.creatorId === this.user.id) {
        this.$nextTick(() => {
          this.onFocus(index);
          this.$refs.audience_name[index].focus();
        });
      }
    },
    onFocus(index) {
      const element = this.$refs.audience_name[index];
      element.addEventListener('focus', () => {
        const cursorPosition = element.innerText.length;
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(element.childNodes[0], cursorPosition);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      });
    },
    deleteTrigger(aud) {
      deleteAudience(aud.id).then(() => {
        bus.$emit('render-cards');
      });
    },
    renameOnBlur(aud) {
      aud.renameActive = false;
    },
    submitRename(event, aud) {
      aud.renameActive = false;
      renameAudience(aud.id, event.target.innerText);
      return aud, event.target.innerText;
    },
    openDropdown(id) {
      this.opened = this.opened === id ? 0 : id;
    },
    openAudience(aud) {
      const { id, creatorId, name } = aud;
      useMixpanelConfigAudience('view_audience_management_audience_details', id, name);
      this.$router.push({
        name: 'AudienceDetailOld',
        params: { id, creator: creatorId, pageHistory: this.data.page },
      });
    },
    showDeleteModal(audience) {
      if (audience.creatorId === this.user.id) {
        this.toDelete = audience;
        this.isVisible = true;
      }
    },
    hideDeleteModal() {
      this.toDelete = null;
      this.isVisible = false;
    },
    audienceExportation(audience) {
      if (audience.contacts > 0) {
        this.exporting = audience;
        bus.$emit('open-exportation');
      }
    },
    audienceImportation() {
      bus.$emit('open-importation');
    },
    duplicateAudience(audience) {
      if (!audience.copyable) {
        this.toast.error(this.$t(`audiences.copyable`), { timeout: 10000 });
      } else {
        this.$router.push({
          name: 'AudienceDuplicateOld',
          params: { id: audience.id, pageHistory: this.data.page },
        });
      }
    },
    bodyClick() {
      const body = document.querySelector('body');
      body.addEventListener('click', event => {
        if (event.target.className !== 'icon icon-menu-14px') {
          this.opened = 0;
        }
      });
    },
    validateIfHasSupportPermission(permission) {
      return hasPermission(permission);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-audiences-add {
  position: fixed;
  z-index: 95;
  right: 25px;
  bottom: 30px;

  &.has-freshdesk-widget {
    bottom: 90px !important;
  }

  .btn-plus {
    font-size: 0;
    // background-color: $oto-omni;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    box-shadow: -2px 3px 10px rgba(black, 0.1);
    &::before {
      font-size: 28px;
      font-weight: normal;
    }
  }

  .dropdown-toggle {
    cursor: pointer;
    text-decoration: none;

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    min-width: 100px;
    box-shadow: -1px 2px 9px rgba(0, 0, 0, 0.25);
    border: 0 none;
    border-radius: 0;
    top: -10px !important;
    padding: 0;
    transform: rotate(0, 25px, 0) !important;

    &.has-freshdesk-widget {
      top: -115px !important;
    }

    .dropdown-item {
      font-size: 11.46px;
      padding: 3px 12px;
      color: $gray-600;
      line-height: 22px;
      font-weight: 600;
      cursor: pointer;

      &:hover:not(.disabled) {
        background-color: $gray-400;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.audience-name {
  .name {
    -webkit-user-select: text;
    user-select: text;
    padding: 5px 0;
    display: inline-block;
    margin-right: 10px;
    &:focus {
      min-width: 150px;
      outline: 0 none;
      border-bottom: 1px solid;
    }
  }
}

.integrations {
  .count {
    font-weight: 400;
    &:not(.empty) {
      text-decoration: underline;
    }
  }
  .tooltip-white {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(15px, -50%);
    width: 345px;
    background-color: $color-white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 25px 60px rgba(51, 58, 69, 0.2);
    z-index: 2;
    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 50%;
      left: -15px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 15px solid $color-white;
    }
    li {
      padding: 10px 0;
      margin: 0;
      border-bottom: 1px solid $gray-400;
      &:last-child {
        border-bottom: 0;
      }
      > div {
        padding: 0 10px;
      }
      strong {
        p {
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
      span {
        p {
          font-weight: 400;
          margin-bottom: 5px;
        }
      }
    }
  }

  &:hover {
    .tooltip-white {
      display: block;
    }
  }
}
#audiences-list {
  .table-list-header {
    .al__customers-contacts {
      display: flex;
    }
  }
}
</style>

<style lang="scss">
#audience-delete-confirm {
  max-width: 500px;

  .el-dialog__body {
    p {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .dialog {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin: 28px 0 20px 0;

      div {
        width: 170px;
      }
    }
  }
}
</style>
