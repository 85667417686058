<template>
  <nav class="navbar">
    <div class="navbar-collapse">
      <ul class="navbar__list">
        <li
          class="navbar__item"
          :class="this.$route.name == 'adm-preferences' || this.$route.name == 'adm-preferences-root' ? 'active' : ''"
        >
          <router-link to="/settings/preferences" class="navbar__link">{{
            $t('settings.preferences.title')
          }}</router-link>
        </li>

        <li
          v-if="validateRoute('adm-metrics')"
          class="navbar__item"
          :class="this.$route.name == 'adm-metrics' ? 'active' : ''"
        >
          <router-link to="/settings/metrics" class="navbar__link">{{ $t('settings.metrics.title') }}</router-link>
        </li>

        <li v-if="validateRoute('adm-stores')" class="navbar__item" :class="isStoreRoutes ? 'active' : ''">
          <router-link to="/settings/stores" class="navbar__link">{{ $t('settings.stores.title') }}</router-link>
        </li>

        <li class="navbar__item" :class="this.$route.name == 'adm-security' ? 'active' : ''">
          <router-link to="/settings/security" class="navbar__link">{{ $t('settings.security.title') }}</router-link>
        </li>

        <li
          v-if="validateRoute('adm-users')"
          class="navbar__item"
          :class="this.$route.name == 'adm-users' ? 'active' : ''"
        >
          <router-link to="/settings/users" class="navbar__link">{{ $t('settings.users.title') }}</router-link>
        </li>

        <li
          v-if="validateRoute('adm-integrations')"
          class="navbar__item"
          :class="this.$route.name == 'adm-integrations' ? 'active' : ''"
        >
          <router-link to="/settings/integrations" class="navbar__link">
            {{ $tc('settings.integrations.title', 2) }}
          </router-link>
        </li>

        <li
          v-if="validateRoute('adm-custom-fields')"
          class="navbar__item"
          :class="this.$route.name == 'adm-custom-fields' ? 'active' : ''"
        >
          <router-link to="/settings/custom-fields" class="navbar__link">
            {{ $tc('settings.custom-fields.title', 2) }}
          </router-link>
        </li>

        <li
          v-if="validateRoute('adm-limits') && validateIfHasPermission(permissionsLimits)"
          class="navbar__item"
          :class="this.$route.name == 'adm-limits' ? 'active' : ''"
        >
          <router-link to="/settings/limits" class="navbar__link">
            {{ $t('settings.limits.title') }}
          </router-link>
        </li>
      </ul>
      <div class="navbar__product-version">{{ version }}{{ commit }}</div>
    </div>
  </nav>
</template>

<script>
//helpers
import hasPermission from '@/helpers/permissions';

//utilities
import { permissionsLimits } from '@/utilities/accessLevel/LimitsPermissions';

export default {
  name: 'SettingsMenu',
  data() {
    const { VUE_APP_VERSION, VUE_APP_COMMIT_REF } = process.env;

    return {
      permissionsLimits,
      version: VUE_APP_VERSION ? `v${VUE_APP_VERSION}` : '',
      commit: VUE_APP_COMMIT_REF ? ` (${VUE_APP_COMMIT_REF})` : '',
    };
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isContext() {
      // indica se a permissao do usuario e context
      return this.$store.getters.user?.profile === 1;
    },
    isStoreRoutes() {
      const isAtStoresRoutes = ['adm-stores', 'adm-stores-create', 'adm-stores-edit', 'adm-stores-duplicate'];
      return isAtStoresRoutes.find(item => this.$route.name == item);
    },
  },
  methods: {
    validateIfHasPermission(permissionGroup) {
      return hasPermission(permissionGroup);
    },
    validateRoute(route) {
      return this.routes.includes(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  margin-top: 30px;

  &__list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__item {
    padding: 0 15px;
    margin-bottom: 10px;

    &.active {
      background-color: $oto-omni;
      border-radius: 100px;

      a {
        color: $color-white;
      }
    }
  }

  &__link {
    padding: 0;
    color: $gray-800;
    font-weight: 600;
    text-decoration: none;
    line-height: 29px;
  }

  &__product-version {
    font-size: 0.8em;
    opacity: 0.5;
    padding: 0 15px;
    margin-top: 30px;
  }
}
</style>
