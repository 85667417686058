<template>
  <div class="row">
    <div class="col col-xl-3 card-height">
      <div class="card card-filters-comparable">
        <div class="card-body">
          <comparable-filters :stores="stores" />
        </div>
      </div>
    </div>
    <div class="col col-xl-9 card-height">
      <base-card-v2 v-if="!parallelCoordinates.loading && parallelCoordinatesHasData">
        <div>
          <h2 class="card-title card-custom-title d-flex align-items-center">
            <i class="orange-circle"></i>
            <span v-if="getActiveSellerObject">{{ getActiveSellerObject.name }}</span>
          </h2>
          <div class="comparable-list-items customScrollBar">
            <comparable-line
              v-for="(serie, key) in parallelCoordinates.data.series"
              :key="key"
              :options="{ ...serie }"
            />
          </div>
        </div>
      </base-card-v2>
      <div v-if="!parallelCoordinatesHasData && !parallelCoordinates.empty" class="card presentation-card">
        <p>
          {{ $tc('stores.empty-metrics-card', 1) }}
          <br />
          {{ $tc('stores.empty-metrics-card', 0) }}
        </p>
      </div>
      <info-card v-if="parallelCoordinates.empty" class="card presentation-card" />
      <load-spinner :show="parallelCoordinates.loading" class="white" />
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
// store
import { mapState, mapGetters, mapActions } from 'vuex';
// services
import listStores from '@/services/stores';
// components
import LoadSpinner from '@/components/oto/sellersAnalytics/_atoms/Spinner';
import ComparableFilters from '@/components/oto/sellersAnalytics/_molecules/ComparableFilters';
import ComparableLine from '@/components/oto/sellersAnalytics/_molecules/ComparableLine';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'ComparableSellers',
  components: {
    LoadSpinner,
    ComparableFilters,
    ComparableLine,
    InfoCard,
  },
  data() {
    return {
      stores: [],
      storesToPlot: [],
      id: 'comparable-sellers',
    };
  },
  computed: {
    ...mapState('sellersAnalytics', ['parallelCoordinates', 'availableMetrics']),
    ...mapGetters('sellersAnalytics', ['getActiveSellerObject', 'parallelCoordinatesHasData', 'getFilters']),
    storeName() {
      const selected = this.stores.find(item => item.id === this.filters.store);
      return selected ? selected.store : '';
    },
    metrics: {
      get() {
        return Object.keys(this.availableMetrics.data).map(item => ({
          key: item,
          name: this.availableMetrics.data[item].name,
        }));
      },
    },
  },
  watch: {
    getActiveSellerObject() {
      this.resetComparableFilters();
    },
  },
  created() {
    this.getStoresInitial();
  },
  mounted() {
    bus.$on('reset-filters', () => {
      this.resetComparableFilters();
    });
    bus.$on('render-cards', () => {
      this.getStores();
      this.resetComparableFilters();
    });
    bus.$on('wrap-stores-plot', () => {
      this.wrapStoresToPlot();
    });
  },
  beforeUnmount() {
    bus.$off('wrap-stores-plot');
    bus.$off('render-cards');
  },
  methods: {
    ...mapActions('sellersAnalytics', ['resetFilters', 'clearParallelCoordinates']),

    getStoresInitial() {
      listStores().then(response => {
        this.stores = response.data.data || [];
      });
    },
    getStores() {
      listStores().then(response => {
        this.stores = response.data.data || [];
        this.wrapStoresToPlot();
        this.resetComparisonStores();
        bus.$emit('get-stores-list');
      });
    },
    wrapStoresToPlot() {
      this.storesToPlot = this.stores;
      this.getFilters.comparisonStore = [];
    },
    resetComparisonStores() {
      this.getFilters.comparisonStore = [];
    },
    resetComparableFilters() {
      // reset metrics and comparisonStores
      this.resetFilters();
      this.clearParallelCoordinates();
      bus.$emit('comparison-stores-filters', this.getFilters);
      bus.$emit('reset-comparable-stores');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-filters-comparable {
  height: 503px;
  margin-bottom: 0px;
}
.comparable-list-items {
  height: 416px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.orange-circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: $color-orange;
  border-radius: 50%;
  margin-right: 10px;
}
.card-custom-title {
  font-size: 12px;
  color: $gray-700;
}
.presentation-card {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;

  p {
    font-size: 14px;
  }
}
</style>
