<template>
  <section id="store-infos" class="register-infos">
    <div class="ri_title">
      <h2>{{ $t('settings.stores.register-store.infos-title') }}</h2>
    </div>
    <template v-if="!loadingStoreDetail">
      <div class="ri_form">
        <div>
          <label
            >{{ $t('settings.stores.register-store.name') }} <span>*</span>
            <icon
              class="brand icon icon-question-14px"
              v-tooltip="{
                theme: 'default',
                content: `<div class='infos-form-tooltip'>${$t('settings.stores.tooltip-titles.icon-name')}</div>`,
                distance: 8,
                html: true,
              }"
          /></label>
          <input
            v-model="name"
            type="text"
            :class="[isNameError ? 'field-invalid' : '', 'input-brand']"
            :placeholder="$t('settings.stores.placeholder.name')"
            @input="hideTooltips()"
            v-tooltip.left="{
              theme: 'error',
              shown: isNameError,
              content: requiredField,
              distance: 8,
              autoHide: false,
            }"
          />
        </div>
        <div>
          <label>
            {{ $t('settings.stores.register-store.friendly-name') }}
            <icon
              class="friendly icon icon-question-14px"
              v-tooltip="{
                theme: 'default',
                content: `<div class='infos-form-tooltip'>${$t('settings.stores.tooltip-titles.icon-friendly')}</div>`,
                distance: 8,
                html: true,
              }"
          /></label>
          <input v-model="friendlyName" type="text" :placeholder="$t('settings.stores.placeholder.friendly-name')" />
        </div>
        <div>
          <label
            >{{ $t('settings.stores.register-store.email') }}
            <icon
              class="email icon icon-question-14px"
              v-tooltip="{
                theme: 'default',
                content: `<div class='infos-form-tooltip'>${$t('settings.stores.tooltip-titles.icon-email')}</div>`,
                distance: 8,
                html: true,
              }"
          /></label>
          <input
            v-model="email"
            type="text"
            :placeholder="$t('settings.stores.placeholder.email', { account: $t('account'), domain: $t('domain') })"
          />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.cluster') }}</label>
          <input v-model="cluster" type="text" :placeholder="$t('settings.stores.placeholder.cluster')" />
        </div>
        <div class="ri_form-box">
          <div>
            <label
              >{{ $t('settings.stores.register-store.oto') }} <span>*</span>
              <icon
                class="idOto icon icon-question-14px"
                v-tooltip="{
                  theme: 'default',
                  content: `<div class='infos-form-tooltip'>${$t('settings.stores.tooltip-titles.icon-idOto')}</div>`,
                  distance: 8,
                  html: true,
                }"
            /></label>
            <input
              v-model="idOto"
              ref="idOto"
              type="text"
              :class="[isIdOtoError ? 'field-invalid' : '', 'input-idOto']"
              :disabled="mode === 'edit'"
              :placeholder="$t('settings.stores.placeholder.oto')"
              @input="validateJustNumber($event), hideTooltips()"
              v-tooltip.left="{
                theme: 'error',
                shown: isIdOtoError,
                content: isIdAlreadyExists ? idAlreadyExists : requiredField,
                distance: 8,
                autoHide: false,
              }"
            />
          </div>
          <div>
            <label>{{ $t('settings.stores.register-store.ecommerce') }}</label>
            <input
              v-model="idEcommerce"
              type="text"
              name=""
              :placeholder="$t('settings.stores.placeholder.ecommerce')"
            />
          </div>
        </div>
        <div class="ri_form-box">
          <div>
            <label>{{ $t('settings.stores.register-store.type') }}</label>
            <v-select
              v-model="type"
              :clearable="false"
              label="name"
              :placeholder="$tc('_select', 2)"
              :options="typeOptions()"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('integrations.no-results') }}
              </template>
            </v-select>
          </div>
          <div>
            <label
              >{{ $t('settings.stores.register-store.status') }}
              <icon
                class="status icon icon-question-14px"
                v-tooltip="{
                  theme: 'default',
                  content: `<div class='infos-form-tooltip'>${$t('settings.stores.tooltip-titles.icon-status')}</div>`,
                  distance: 8,
                  html: true,
                }"
            /></label>
            <v-select
              v-model="status"
              :clearable="false"
              label="name"
              :placeholder="$tc('_select', 2)"
              :options="statusOptions()"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('integrations.no-results') }}
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </template>
    <base-loader v-if="loadingStoreDetail" />
  </section>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { mapState, mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
// helpers
import bus from '@/helpers/events/bus';

const physicalStore = 'L';
const ecommerceStore = 'E';

export default {
  name: 'StoresRegisterInfosForm',
  components: {
    vSelect,
    BaseLoader,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isNameError: false,
      isIdOtoError: false,
      requiredField: this.$t('settings.stores.tooltip-titles.blank-field'),
      idAlreadyExists: this.$t('settings.stores.tooltip-titles.id-exists'),
      isIdAlreadyExists: false,
    };
  },
  validations() {
    return {
      name: {
        required,
      },
      idOto: {
        required,
      },
    };
  },
  computed: {
    ...mapState('settingsStores', ['loadingStoreDetail']),
    ...mapGetters('settingsStores', [
      'getName',
      'getFriendlyName',
      'getEmail',
      'getCluster',
      'getIdOto',
      'getIdEcommerce',
      'getType',
      'getStatus',
      'getFormatStatus',
    ]),

    isEditOrDuplicateMode() {
      return this.mode !== 'create';
    },
    name: {
      get() {
        return this.getName;
      },
      set(name) {
        this.updateName(name);
      },
    },
    friendlyName: {
      get() {
        return this.getFriendlyName;
      },
      set(friendlyName) {
        this.updateFriendlyName(friendlyName);
      },
    },
    email: {
      get() {
        return this.getEmail;
      },
      set(email) {
        this.updateEmail(email);
      },
    },
    cluster: {
      get() {
        return this.getCluster;
      },
      set(cluster) {
        this.updateCluster(cluster);
      },
    },
    idOto: {
      get() {
        return this.getIdOto;
      },
      set(idOto) {
        this.updateStoreId(idOto);
      },
    },
    idEcommerce: {
      get() {
        return this.getIdEcommerce;
      },
      set(idEcommerce) {
        this.updateIdEcommerce(idEcommerce);
      },
    },
    type: {
      get() {
        const formatType =
          this.getType === physicalStore
            ? this.$t('settings.stores.type.store')
            : this.$t('settings.stores.type.ecommerce');
        return this.getType ? formatType : this.getType;
      },
      set(type) {
        const formatType = type === this.$t('settings.stores.type.ecommerce') ? ecommerceStore : physicalStore;
        this.updateType(formatType);
        this.updateIsRequired(formatType);
      },
    },
    status: {
      get() {
        const formatStatus =
          this.getStatus === 'inactive' || this.getStatus === '0'
            ? this.$t('settings.stores.status.inactive')
            : this.$t('settings.stores.status.active');
        return this.isEditOrDuplicateMode ? formatStatus : this.getFormatStatus;
      },
      set(status) {
        const formatStatus = status === this.$t('settings.stores.status.active') ? '1' : '0';
        this.updateStatus(formatStatus);
        this.updateFormatStatus(status);
      },
    },
  },
  mounted() {
    bus.$on('hide-tooltips', () => {
      this.hideTooltips();
      this.isIdAlreadyExists = false;
    });

    bus.$on('required-fields', item => {
      this.verifyRequiredFields(item);
    });
    bus.$on('id-already-exists', () => {
      this.focusInput();
      this.isIdAlreadyExists = true;
      this.verifyIfdIdOtoAlreadyExists();
    });
  },
  beforeMount() {
    bus.$off('required-fields');
    bus.$off('id-already-exists');
    bus.$off('hide-tooltips');
  },
  methods: {
    ...mapActions('settingsStores', [
      'updateName',
      'updateFriendlyName',
      'updateEmail',
      'updateCluster',
      'updateStoreId',
      'updateIdEcommerce',
      'updateType',
      'updateStatus',
      'updateFormatStatus',
      'updateIsRequired',
    ]),
    verifyIfdIdOtoAlreadyExists() {
      this.hideTooltips();
      this.isIdOtoError = true;
    },
    verifyRequiredFields(field) {
      this.hideTooltips();

      if (field === 'name') {
        this.isNameError = true;
      }
      if (field === 'idOto') {
        this.isIdOtoError = true;
      }
    },
    hideTooltips() {
      if (this.getName) {
        this.isNameError = false;
      }

      if (this.idOto) {
        this.isIdOtoError = false;
      }
    },
    statusOptions() {
      return [this.$t('settings.stores.status.active'), this.$t('settings.stores.status.inactive')];
    },
    typeOptions() {
      return [this.$t('settings.stores.type.store'), this.$t('settings.stores.type.ecommerce')];
    },
    validateJustNumber(event) {
      this.idOto = event.target?.value?.replace(/[^,0-9]+/g, '');
    },
    focusInput() {
      this.$refs.idOto.focus();
    },
    emailPlaceholder() {
      return `${this.$t('settings.stores.placeholder.email')}@${this.$t('settings.stores.placeholder.email-part-2')}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.register-infos {
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  height: 303px;

  h2 {
    font-size: 20px;
    width: 145px;
    line-height: 25px;
  }
  .ri_title {
    width: 30%;
  }
  .ri_form {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
      margin-bottom: 32px;
      input {
        width: 256px;
      }
      .field-invalid {
        border-color: $oto-seller;
      }
    }

    &-box {
      display: flex;
      width: 256px;
      justify-content: space-between;
      div {
        width: 120px;
        margin-bottom: 0px;
        input {
          width: 100%;
          margin-right: unset;
        }
      }
    }

    label {
      span {
        color: $pink-500;
      }
      .icon {
        color: #bbbbbb;
        margin-left: 7px;
        font-size: 14px;
      }
    }
  }
}
</style>
<style lang="scss">
#store-infos {
  .tooltip {
    z-index: 1;
  }
  .friendly:hover,
  .brand:hover,
  .email:hover,
  .idOto:hover,
  .status:hover {
    color: $blue-500;
  }
}

.infos-form-tooltip {
  width: 180px;
  text-align: center;
}
</style>
