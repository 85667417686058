<template>
  <div class="row">
    <base-card-v2 class="details-header">
      <div class="details-header-blocks">
        <span class="customer-icon">
          <i class="icon icon-customer"></i>
        </span>
        <div class="text-title">{{ $t('360.sellers-analytics.title') }}</div>
        <div class="seller-select">
          <label>{{ $t('360.sellers-analytics.select-seller') }}</label>
          <v-select
            v-model="term"
            label="fullName"
            :clearable="false"
            :input-id="`sellers-select`"
            class="vselect-seller-analytics w-100"
            :options="getSellerResumeList"
            :filter-by="sellerFilter"
            :placeholder="$t('360.select')"
            @search="searchTerm"
            @search:blur="changeSeller(term.id)"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #option="{ fullName, enrolment }">
              <span v-if="fullName">{{ fullName }} - {{ enrolment }}</span>
            </template>
            <template #selected-option="{ fullName, enrolment }">
              <span v-if="fullName">{{ fullName }} - {{ enrolment }}</span>
            </template>
            <template #no-options>
              {{ $t('360.sellers-analytics.select-empty') }}
            </template>
          </v-select>
        </div>
      </div>
    </base-card-v2>
  </div>
</template>

<script>
// libs
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      term: '',
      isFirstSearch: true,
    };
  },
  computed: {
    ...mapGetters('sellersAnalytics', ['getSellerResumeList']),
  },
  watch: {
    getSellerResumeList() {
      this.$nextTick(() => {
        // at the first load - set input details (name - enrolment)
        if (!this.getSellerResumeList) return;
        this.term = this.getSellerResumeList[0];
      });
    },
  },
  methods: {
    ...mapActions('sellersAnalytics', ['setSellerSelected', 'setSellersByTerm', 'clearParallelCoordinates']),
    /**
     * @description search by term
     */
    searchTerm(term) {
      if (!term) return;
      this.triggerDebounce(term);
    },
    /**
     * @description set term on seller store
     */
    triggerDebounce: debounce(function (term) {
      this.setSellersByTerm({ term });
    }, 500),
    /**
     * @description change seller after and bofore select update
     */
    changeSeller(sellerId) {
      this.setSellerSelected(sellerId);
      this.clearParallelCoordinates();
    },
    /**
     * @description filter options with two labels
     */
    sellerFilter(option, fullName, search) {
      const temp = search.toLowerCase();
      return fullName.toLowerCase()?.includes(temp) || option?.enrolment?.includes(temp);
    },
  },
};
</script>

<style lang="scss" scoped>
.details-header {
  background: url(~@/assets/img/figures/genericOtoBg.png) no-repeat right -70px center, $color-white;
  height: 92px;
  padding: 15px 40px;
  .details-header-blocks {
    display: grid;
    grid-template-columns: 30px 300px 230px;
    grid-column-gap: 25px;
    align-items: center;
    .customer-icon {
      color: $oto-omni;
      height: 30px;
    }
    .text-title {
      font-size: 20px;
      color: $gray-700;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/components/_custom-scrollbar.scss';

.vselect-seller-analytics {
  .vs__dropdown-menu {
    @extend .customScrollBar;
    overflow-x: hidden;
    .vs__dropdown-option {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
