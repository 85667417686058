<template>
  <base-card-v2 class="push-create__header">
    <div class="row h-100">
      <div class="col-3 pc__title-wrapper">
        <icon-base class="pc__icon-phone" :box="'0 0 12 18'" w="12" h="18">
          <icon-phone />
        </icon-base>
        <h2 class="pc__title">{{ $t('360.push.campaign.create.title') }}</h2>
      </div>
    </div>
  </base-card-v2>
</template>

<script>
// libs
import { mapGetters, mapActions } from 'vuex';
// assets
import IconBase from '@/assets/vue-icons/IconBase';
import IconPhone from '@/assets/vue-icons/icons/Phone';

export default {
  name: 'PushCreateHeader',
  components: {
    IconBase,
    IconPhone,
  },
  computed: {
    ...mapGetters('pushNotifications', ['getPublicType']),
    publicType: {
      get() {
        return this.getPublicType;
      },
      set(audience) {
        return audience;
      },
    },
  },
  methods: {
    ...mapActions('pushNotifications', ['updatePublic']),
    changeSelector(aud) {
      this.updatePublic(aud);
    },
  },
};
</script>

<style lang="scss" scoped>
.push-create__header {
  height: 100px;
  width: 100%;

  .pc__title-wrapper {
    display: flex;
    align-items: center;

    .pc__title {
      margin: 0 0 0 20px;
    }
  }

  .pc__audience-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
