import _ from 'lodash';
import { ref } from 'vue';
import { verifyMixinType } from '@/utilities/constants';

export default function useVerifyMixin() {
  const isEmpty = ref(false);
  const hasError = ref(false);
  const isLoading = ref(true);
  const isNotEmptyThen = ref(true);

  /**
   * @description Checks the current state is: 'done', 'loading', 'with a error' or 'is empty'.
   */
  const verifyLoaded = verify => {
    switch (verify) {
      case verifyMixinType.done:
        return !hasError.value && !isLoading.value && !isEmpty.value && isNotEmptyThen.value;
      case verifyMixinType.loading:
        return isLoading.value && isNotEmptyThen.value;
      case verifyMixinType.info:
        return !isLoading.value && (hasError.value || isEmpty.value);
      case verifyMixinType.infoThens:
        return !isLoading.value && (hasError.value || !isNotEmptyThen.value);
      default:
        hasError.value = true;
        return false;
    }
  };

  /**
   * @description First pattern test, checks if the card has data
   */
  const verifyData = data => {
    isEmpty.value = _.isEmpty(data);
    if (isEmpty.value) {
      isLoading.value = false;
    }
    return isEmpty.value;
  };

  /**
   * @description Second pattern test, checks if the card has data
   */
  const verifyHasData = response => {
    for (const e in response) {
      isEmpty.value = !response[e]?.has_data;
      if (isEmpty.value == false) {
        return isEmpty.value;
      }
    }
    return isEmpty.value;
  };

  /**
   * @description Second pattern test, checks if the card has data
   */
  const reqConfig = () => {
    isLoading.value = true;
    hasError.value = false;
    isEmpty.value = false;
    isNotEmptyThen.value = true;
  };

  return {
    isEmpty,
    hasError,
    isLoading,
    isNotEmptyThen,
    verifyLoaded,
    reqConfig,
    verifyData,
    verifyHasData,
  };
}
