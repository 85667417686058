import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const controller = '/nps/campaigns';

export const getMetrics = metrics => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const persona = store.state.auth.selectedPersona?.id;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getMetrics`,
    params: {
      brand: brand.id,
      persona,
      startDate,
      endDate,
      metrics,
    },
  }).then(data => data.data);
};

export const getMetricsPeriod = metrics => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const endDate = store.getters.dates.endDate;
  const persona = store.state.auth.selectedPersona?.id;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getMetricsPeriod`,
    params: {
      brand: brand.id,
      persona,
      endDate,
      metrics,
    },
  }).then(data => data);
};

export const getTable = ({ metrics, groupBy, fileType = '', responseType = '' }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const persona = store.state.auth.selectedPersona?.id;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getTable`,
    params: {
      brand: brand.id,
      persona,
      startDate,
      endDate,
      export: fileType,
      metrics,
      groupBy,
    },
    responseType,
  });
};

export const getTableMetrics = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getTableMetrics`,
    params: {
      brand: brand.id,
    },
  }).then(data => data);
};
