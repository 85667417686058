<template>
  <div class="suggestion-card-content">
    <div v-if="campaign.campaigns != null">
      <h2 class="scc__title">{{ $t('campaigns.title') }}</h2>
      <div v-for="(item, index) in campaign.campaigns" :key="index" class="scc__item">
        <p v-if="item.name" class="scc__desc m-0">{{ item.name }}</p>
      </div>
    </div>
    <div v-else>
      <h2 class="scc__title">{{ $t('campaigns.no-campaigns') }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampaignsManagementCardContent',
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-card-content {
  padding: 10px 10px 20px;
  .scc__title {
    font-size: 14px;
    color: $gray-700;
  }

  .scc__item {
    padding: 6px 0;
    p.sci__desc {
      font-size: 12px;
      margin-bottom: 15px;
      margin-left: 2px;
      line-height: 20px;
    }
  }
}
</style>
