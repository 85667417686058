<template>
  <div>
    <section>
      <header-bar />
    </section>
    <base-loader v-if="!personasCompareLoaded" />
    <section v-else class="personas-compare-view">
      <section-block
        v-for="dt in getDataTable"
        :key="getName(dt)"
        :key-name="getName(dt)"
        :name="getName(dt)"
        :data-table="dt[getName(dt)]"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import bus from '@/helpers/events/bus';
import SectionBlock from '@/components/personasCompare/_organisms/SectionBlock';
import HeaderBar from '@/components/personasCompare/_organisms/HeaderBar';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'PersonasCompare',
  components: {
    SectionBlock,
    HeaderBar,
    BaseLoader,
  },
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    lang() {
      return this.$store.getters.user.language || 'pt-BR';
    },
    ...mapGetters('personas', ['personasCompareLoaded', 'getDataTable']),
  },
  mounted() {
    if (this.id) {
      this.updatePersonas(this.id);
    }
    bus.$on('render-cards', () => {
      this.updatePersonas(this.id);
      this.updateDataTable();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    ...mapActions('personas', ['updatePersonas', 'updateDataTable']),
    getName(obj) {
      return Object.keys(obj)[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.personas-compare-view {
  display: flex;
  flex-direction: column;
}
</style>
