import axios from 'axios';
import store from '@/store';

const controller = '360/orders';

/**
 * Get Last Order
 *
 * @param {Number} pmwebId
 */
export const getLastOrder = pmwebId => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/getLastOrder`, {
      params: {
        brand: brand.id,
        pid: pmwebId,
      },
    })
    .then(data => data.data);
};
