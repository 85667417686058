import router from '@/router';
import { escapeRegExp } from 'lodash';
import { stringParser } from '@/helpers/string-parser.js';
import { getMetrics, getStore, putStore } from '@/services/storeSettings';
import bus from '@/helpers/events/bus';

const $_objectToArray = value => {
  return Object.keys(value).map(item => ({
    key: value[item].key,
    name: value[item].name,
  }));
};

const defaultState = {
  metrics: [],
  checkedMetrics: [],
  filterMetrics: [],
  lastCustomConfig: {},

  idStores: [],

  // store register
  id: '',
  loadingStoreDetail: false,
  registerForm: {},

  formatStatus: '',
  isRequired: true,
  mode: null,
  toastSuccess: false,
};

const settingsStores = {
  namespaced: true,
  state: defaultState,
  mutations: {
    SET_MODE(state, payload) {
      state.mode = payload;
    },

    SET_TOAST(state, payload) {
      state.toastSuccess = payload;
    },

    SET_LOADING_STORE_DETAIL(state, payload) {
      state.loadingStoreDetail = payload;
    },

    SET_METRICS(state, metrics) {
      state.metrics = metrics;
    },

    SET_CHECKED_METRICS(state, checked) {
      state.checkedMetrics = checked;
    },

    SET_FILTER_METRICS(state, metrics) {
      state.filterMetrics = metrics;
    },

    SET_LAST_CUSTOM_CONFIG(state) {
      state.lastCustomConfig = {
        metrics: state.checkedMetrics,
        filterMetrics: state.filterMetrics,
      };
    },

    DELETE_CHECKED_METRIC(state, index) {
      state.checkedMetrics.splice(index, 1);
    },

    DELETE_FILTER_METRICS(state) {
      state.filterMetrics = state.filterMetrics.filter(el => state.checkedMetrics.includes(el.key));
    },

    RESET_ALL_CHECKED(state) {
      state.checkedMetrics = [];
      state.filterMetrics = [];
    },

    // store register
    SET_ID(state, payload) {
      state.id = payload;
    },

    SET_NAME(state, name) {
      state.registerForm.name = name;
    },

    SET_FRIENDLY_NAME(state, friendlyName) {
      state.registerForm.nameOto = friendlyName;
    },

    SET_EMAIL(state, email) {
      state.registerForm.email = email;
    },

    SET_CLUSTER(state, cluster) {
      state.registerForm.cluster = cluster;
    },

    SET_STORE_ID(state, idOto) {
      state.registerForm.storeId = idOto;
    },

    SET_ID_ECOMMERCE(state, idEcommerce) {
      state.registerForm.idEcommerce = idEcommerce;
    },

    SET_TYPE(state, type) {
      state.registerForm.type = type;
    },

    SET_STATUS(state, status) {
      state.registerForm.status = status;
    },

    SET_FORMAT_STATUS(state, formatStatus) {
      state.formatStatus = formatStatus;
    },

    SET_ZIP_CODE(state, zipCode) {
      state.registerForm.postalCode = zipCode;
    },

    SET_COUNTRY(state, country) {
      state.registerForm.country = country;
    },

    SET_STATE(state, getState) {
      state.registerForm.state = getState;
    },

    SET_CITY(state, city) {
      state.registerForm.city = city;
    },

    SET_NEIGHBORHOOD(state, neighborhood) {
      state.registerForm.neighborhood = neighborhood;
    },

    SET_CUSTOM_FIELD_1(state, custom1) {
      state.registerForm.customField01 = custom1;
    },

    SET_CUSTOM_FIELD_2(state, custom2) {
      state.registerForm.customField02 = custom2;
    },

    SET_CUSTOM_FIELD_3(state, custom3) {
      state.registerForm.customField03 = custom3;
    },

    SET_CUSTOM_FIELD_4(state, custom4) {
      state.registerForm.customField04 = custom4;
    },

    SET_CUSTOM_FIELD_5(state, custom5) {
      state.registerForm.customField05 = custom5;
    },

    SET_CUSTOM_FIELD_6(state, custom6) {
      state.registerForm.customField06 = custom6;
    },

    SET_CUSTOM_FIELD_7(state, custom7) {
      state.registerForm.customField07 = custom7;
    },

    SET_CUSTOM_FIELD_8(state, custom8) {
      state.registerForm.customField08 = custom8;
    },

    SET_CUSTOM_FIELD_9(state, custom9) {
      state.registerForm.customField09 = custom9;
    },

    SET_CUSTOM_FIELD_10(state, custom10) {
      state.registerForm.customField10 = custom10;
    },

    SET_IS_REQUIRED(state, type) {
      const required = type === 'E' ? false : true;
      state.isRequired = required;
    },

    SET_RESET_FIELDS(state) {
      state.registerForm = {};
      state.formatStatus = '';
    },

    SET_STORE_DETAILS(state, storeDetails) {
      const {
        name,
        nameOto,
        email,
        cluster,
        storeId,
        idEcommerce,
        type,
        status,
        postalCode,
        country,
        state: newState,
        city,
        neighborhood,
        customField01,
        customField02,
        customField03,
        customField04,
        customField05,
        customField06,
        customField07,
        customField08,
        customField09,
        customField10,
      } = storeDetails;

      // Verify if is edit mode, if it is, return storeId from API, otherwise is duplicate mode, so return ""
      const idOto = state.mode === 'edit' ? storeId : '';

      state.registerForm.name = name;
      state.registerForm.nameOto = nameOto;
      state.registerForm.email = email;
      state.registerForm.cluster = cluster;
      state.registerForm.storeId = idOto;
      state.registerForm.idEcommerce = idEcommerce;
      state.registerForm.type = type;
      state.registerForm.status = status;
      state.registerForm.state = newState;
      state.registerForm.postalCode = postalCode;
      state.registerForm.city = city;
      state.registerForm.neighborhood = neighborhood;
      state.registerForm.country = country;
      state.registerForm.customField01 = customField01;
      state.registerForm.customField02 = customField02;
      state.registerForm.customField03 = customField03;
      state.registerForm.customField04 = customField04;
      state.registerForm.customField05 = customField05;
      state.registerForm.customField06 = customField06;
      state.registerForm.customField07 = customField07;
      state.registerForm.customField08 = customField08;
      state.registerForm.customField09 = customField09;
      state.registerForm.customField10 = customField10;
    },
  },
  actions: {
    setToastSuccess({ commit }, payload) {
      commit('SET_TOAST', payload);
    },

    setActionId({ commit, dispatch }, id) {
      commit('SET_ID', id);
      dispatch('getStoreById', id);
    },

    setMode({ commit }, payload) {
      commit('SET_MODE', payload);
    },

    setSaveStore({ dispatch, getters }, action) {
      putStore({ action: action, ...getters.getAllFormFields }).then(data => {
        if (data.data.errors.code === 'duplicated-store-id') {
          bus.$emit('close-modal');
          bus.$emit('id-already-exists');
          return;
        }
        if (data.data.success) {
          bus.$emit('close-modal');
          dispatch('setToastSuccess', true);
          router.push('/settings/stores');
        }
      });
    },

    getStoreById({ commit, dispatch }, id) {
      commit('SET_LOADING_STORE_DETAIL', true);
      getStore(id)
        .then(({ data }) => {
          commit('SET_STORE_DETAILS', data.data);
          dispatch('updateIsRequired', data.data.type);
        })
        .catch(() => {
          commit('SET_LOADING_STORE_DETAIL', false);
        })
        .finally(() => {
          commit('SET_LOADING_STORE_DETAIL', false);
        });
    },

    updateMetrics({ commit }) {
      getMetrics().then(({ data }) => {
        commit('SET_METRICS', $_objectToArray(data.data.columns));
      });
    },

    updateCheckedMetrics({ commit }, payload) {
      commit('SET_CHECKED_METRICS', payload);
    },

    updateLastCustomConfig({ commit }) {
      commit('SET_LAST_CUSTOM_CONFIG');
    },

    updateFilterMetrics({ commit }, payload) {
      commit('SET_FILTER_METRICS', payload);
    },

    deleteCheckedMetric({ commit }, payload) {
      commit('DELETE_CHECKED_METRIC', payload);
      commit('DELETE_FILTER_METRICS');
    },

    resetAllStoreMetrics({ commit }) {
      commit('RESET_ALL_CHECKED');
    },

    // store register
    updateName({ commit }, payload) {
      commit('SET_NAME', payload);
    },

    updateFriendlyName({ commit }, payload) {
      commit('SET_FRIENDLY_NAME', payload);
    },

    updateEmail({ commit }, payload) {
      commit('SET_EMAIL', payload);
    },

    updateCluster({ commit }, payload) {
      commit('SET_CLUSTER', payload);
    },

    updateStoreId({ commit }, payload) {
      commit('SET_STORE_ID', payload);
    },

    updateIdEcommerce({ commit }, payload) {
      commit('SET_ID_ECOMMERCE', payload);
    },

    updateType({ commit }, payload) {
      commit('SET_TYPE', payload);
    },

    updateStatus({ commit }, payload) {
      commit('SET_STATUS', payload);
    },

    updateFormatStatus({ commit }, payload) {
      commit('SET_FORMAT_STATUS', payload);
    },

    updateZipCode({ commit }, payload) {
      commit('SET_ZIP_CODE', payload);
    },

    updateCountry({ commit }, payload) {
      commit('SET_COUNTRY', payload);
    },

    updateState({ commit }, payload) {
      commit('SET_STATE', payload);
    },

    updateCity({ commit }, payload) {
      commit('SET_CITY', payload);
    },

    updateNeighborhood({ commit }, payload) {
      commit('SET_NEIGHBORHOOD', payload);
    },

    updateCustomField1({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_1', payload);
    },

    updateCustomField2({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_2', payload);
    },

    updateCustomField3({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_3', payload);
    },

    updateCustomField4({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_4', payload);
    },

    updateCustomField5({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_5', payload);
    },

    updateCustomField6({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_6', payload);
    },

    updateCustomField7({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_7', payload);
    },

    updateCustomField8({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_8', payload);
    },

    updateCustomField9({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_9', payload);
    },

    updateCustomField10({ commit }, payload) {
      commit('SET_CUSTOM_FIELD_10', payload);
    },

    updateIsRequired({ commit }, payload) {
      commit('SET_IS_REQUIRED', payload);
    },

    resetFields({ commit }) {
      commit('SET_RESET_FIELDS');
    },
  },
  getters: {
    getMode: state => state.mode,
    getAllCheckedMetrics: state => {
      return {
        metrics: state.checkedMetrics,
      };
    },
    getLastConfigMetrics: state => {
      return state.lastCustomConfig;
    },
    getFilteredMetrics:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.metrics.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    // store register
    getAllFormFields: state => state.registerForm,
    getName: state => state.registerForm.name,
    getFriendlyName: state => state.registerForm.nameOto,
    getEmail: state => state.registerForm.email,
    getCluster: state => state.registerForm.cluster,
    getIdOto: state => state.registerForm.storeId,
    getIdEcommerce: state => state.registerForm.idEcommerce,
    getType: state => state.registerForm.type,
    getStatus: state => state.registerForm.status,
    getFormatStatus: state => state.formatStatus,
    getZipCode: state => state.registerForm.postalCode,
    getCountry: state => state.registerForm.country,
    getState: state => state.registerForm.state,
    getCity: state => state.registerForm.city,
    getNeighborhood: state => state.registerForm.neighborhood,
    getCustomField1: state => state.registerForm.customField01,
    getCustomField2: state => state.registerForm.customField02,
    getCustomField3: state => state.registerForm.customField03,
    getCustomField4: state => state.registerForm.customField04,
    getCustomField5: state => state.registerForm.customField05,
    getCustomField6: state => state.registerForm.customField06,
    getCustomField7: state => state.registerForm.customField07,
    getCustomField8: state => state.registerForm.customField08,
    getCustomField9: state => state.registerForm.customField09,
    getCustomField10: state => state.registerForm.customField10,
    getIsRequired: state => state.isRequired,
    getToastSuccess: state => state.toastSuccess,
  },
};

export default settingsStores;
