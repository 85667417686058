<template>
  <div :class="`board-${keyName}`" class="section">
    <title-section-block :type="name" />
    <template v-for="item in dataTable" :key="item.title">
      <subtitle-section-block :name="item.title" />
      <template v-for="row in item.data" :key="row.type">
        <row
          :data-item="row"
          data-toggle="collapse"
          :data-target="`#collapse-${row.type}`"
          aria-expanded="false"
          :aria-controls="`collapse-${row.type}`"
        />
        <template v-if="row.expanded">
          <div :id="`collapse-${row.type}`" :key="`${row.type}-sub`" class="subs collapse">
            <template v-for="subRow in row.body" :key="subRow.type">
              <row class="exp-sub" :data-item="subRow" />
            </template>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import TitleSectionBlock from '@/components/personasCompare/_molecules/TitleSectionBlock';
import SubtitleSectionBlock from '@/components/personasCompare/_molecules/SubtitleSectionBlock';
import Row from '@/components/personasCompare/_molecules/Row';

export default {
  name: 'Section',
  components: {
    TitleSectionBlock,
    SubtitleSectionBlock,
    Row,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    dataTable: {
      type: Object,
      default: () => {},
    },
    keyName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
  &.board-overview {
    order: 1;
  }
  &.board-profile {
    order: 2;
  }
  &.board-revenue {
    order: 3;
  }
  &.board-segments {
    order: 4;
  }
  > div:last-child {
    border-radius: 0px 0px 8px 8px;
  }
}
</style>
