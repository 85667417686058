<template>
  <el-dialog v-model="isVisible" id="detail-dialog-error" append-to-body>
    <i class="icon icon-error-60px" aria-hidden="true"></i>
    <p v-html="$t(message)"></p>
    <div class="dialog">
      <div class="btn" @click="hide">{{ $t('back') }}</div>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';

export default {
  name: 'DialogError',
  data() {
    return {
      message: this.$tc('errors.title', 1),
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('show-detail-error', message => {
      this.message = message || this.$tc('errors.title', 1);
      this.show();
    });
    bus.$on('hide-detail-error', () => {
      this.message = this.$tc('errors.title', 1);
      this.hide();
    });
  },
  beforeUnmount() {
    bus.$off(['show-detail-error', 'hide-detail-error']);
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
#detail-dialog-error {
  max-width: 500px;
  p {
    font-size: 14px;
    line-height: 22px;
    padding: 0 60px;
    text-align: center;
  }
  .icon {
    display: block;
    text-align: center;
    font-size: 76px;
    margin-bottom: 34px;
    color: $oto-ecommerce;
  }
  .icon {
    &[class*='-error'] {
      color: $color-red;
    }
  }

  .dialog {
    div {
      width: 170px;
      border-radius: 80px;
    }
  }
}
</style>
