// libs
import { createApp } from '@vue/compat';
import { configureCompat } from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import axiosDefaults from 'axios/lib/defaults';
import Vuelidate from '@vuelidate/core';
import HighchartsVue from 'highcharts-vue';
import VueTheMask from 'vue-the-mask';
import FloatingVue from 'floating-vue';
import { VueQueryPlugin } from 'vue-query';
import {
  ElTable,
  ElTableColumn,
  ElSelectV2,
  ElDatePicker,
  ElDialog,
  ElPagination,
  ElInput,
  ElPopover,
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElTooltip,
  ElConfigProvider,
  ElMenu,
  ElMenuItemGroup,
  ElMenuItem,
  ElTimeline,
  ElTimelineItem,
  ElSkeleton,
  ElSkeletonItem,
  ElSwitch,
  ElTree,
  ElCheckbox,
  ElTabs,
  ElTabPane,
} from 'element-plus';
// helpers
import bus from '@/helpers/events/bus';
import endpointVersion from '@/helpers/endpoint-version';
import '@/helpers/directives';
import '@/helpers/filters';
import { titleMixin } from '@/helpers/mixins';
// components
import TableList from '@/components/_atoms/table/TableList';
import TableListHeader from '@/components/_atoms/table/TableListHeader';
import TableListBody from '@/components/_atoms/table/TableListBody';
import TableListColumn from '@/components/_atoms/table/TableListColumn';
import TableListRow from '@/components/_atoms/table/TableListRow';
import BaseCard from '@/components/_atoms/BaseCard';
import BaseCardV2 from '@/components/_atoms/BaseCardV2';
import BaseCardHeader from '@/components/_atoms/BaseCardHeader';
// style
import 'vue-select/dist/vue-select.css';
import '@/assets/scss/main.scss';
import 'floating-vue/dist/style.css';
import 'element-plus/dist/index.css';

// plugins call
import '@/plugins/toast/toast';
import '@/plugins/tooltip/tooltip';
// utilities
import { typeEnvironment } from '@/utilities/constants';
// mixpanel
import mixpanel from 'mixpanel-browser';

import { formatCurrency } from '@/utilities/formatters';

const app = createApp(App);
configureCompat({
  MODE: 3,
  ATTR_FALSE_VALUE: false,
});

app.use(store);
app.use(i18n);
app.use(VueQueryPlugin);

app.config.globalProperties.$bus = bus;
app.config.globalProperties.formatCurrency = formatCurrency;
app.config.productionTip = false;

// Define axios default Base URL
axiosDefaults.baseURL = endpointVersion();

// Add HTTP Authorization header
if (localStorage.getItem('token')) {
  const token = localStorage.getItem('token');
  axiosDefaults.headers.common.Authorization = `Bearer ${token}`;
}

// global components
app.component('TableList', TableList);
app.component('TableListHeader', TableListHeader);
app.component('TableListBody', TableListBody);
app.component('TableListColumn', TableListColumn);
app.component('TableListRow', TableListRow);
app.component('BaseCard', BaseCard);
app.component('BaseCardV2', BaseCardV2);
app.component('BaseCardHeader', BaseCardHeader);

app.component(ElTable.name, ElTable);
app.component(ElInput.name, ElInput);
app.component(ElTableColumn.name, ElTableColumn);
app.component(ElDatePicker.name, ElDatePicker);
app.component(ElDialog.name, ElDialog);
app.component(ElPagination.name, ElPagination);
app.component(ElTooltip.name, ElTooltip);
app.component(ElPopover.name, ElPopover);
app.component(ElButton.name, ElButton);
app.component(ElDropdown.name, ElDropdown);
app.component(ElDropdownMenu.name, ElDropdownMenu);
app.component(ElDropdownItem.name, ElDropdownItem);
app.component(ElConfigProvider.name, ElConfigProvider);
app.component(ElMenu.name, ElMenu);
app.component(ElMenuItemGroup.name, ElMenuItemGroup);
app.component(ElMenuItem.name, ElMenuItem);
app.component(ElTimeline.name, ElTimeline);
app.component(ElTimelineItem.name, ElTimelineItem);
app.component(ElSkeleton.name, ElSkeleton);
app.component(ElSkeletonItem.name, ElSkeletonItem);
app.component(ElSwitch.name, ElSwitch);
app.component(ElTree.name, ElTree);
app.component(ElSelectV2.name, ElSelectV2);
app.component(ElCheckbox.name, ElCheckbox);
app.component(ElTabs.name, ElTabs);
app.component(ElTabPane.name, ElTabPane);

// variable to control all experimental components
app.config.globalProperties.$experimental = localStorage.getItem('experimental') === 'true';
// variable to control datepicker range
app.config.globalProperties.$isDatePickerRangeActive = true;

bus.$on('update-experimental-mode', isActive => {
  if (isActive) localStorage.setItem('experimental', isActive);
  else localStorage.removeItem('experimental');
  app.config.globalProperties.$experimental = isActive;
});

if (typeEnvironment === 'beta') mixpanel.init('5c3537b82099799612a2b9af81481eb5');
else if (typeEnvironment === 'production') mixpanel.init('6124e626b1959eb32286ff91419ec87a');
else if (typeEnvironment === 'development') mixpanel.init('3428dbdde80dc115725fd20b8bcdb9b9');

mixpanel.identify(store.getters?.user?.id);
mixpanel.people.set({
  user: store.getters?.user?.id,
  $name: store.getters?.user?.name,
  $email: store.getters?.user?.email,
  brand_id: store.getters?.selectedBrand?.id,
  brand_name: store.getters?.selectedBrand?.brand,
});

app.use(VueTheMask);
app.use(Vuelidate);
app.use(HighchartsVue);
app.use(FloatingVue);

app.mixin(titleMixin);

app.use(router).mount('#app');
