import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';
import { ParamsDataPutPriority, ParamsPostAction, ParamsGetActions } from '../models';

export const getActionList = (params: ParamsGetActions) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/action/getActionList', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      ...params,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const postSwitchCampaign = (params: ParamsPostAction) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `/action/switch`,
    params: {
      brand: brand.id,
      actionId: params.actionId,
      enabled: params.enabled,
    },
  });
};

export const putPriority = (params: ParamsDataPutPriority) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `/action/putPriority`,
    params: {
      brand: brand.id,
      campaigns_order: params.campaigns_order,
      actions: params.actions,
    },
  });
};
