import { getIndicators } from '../services';
import { FormattedIndicators, ResponseIndicators } from '../models';

export const fetchIndicators = dates => getIndicators(dates).then(response => formattedIndicators(response));

const formattedIndicators = ({ data }: { data: ResponseIndicators }) => {
  const indicators = data.data;
  let result: FormattedIndicators | null;

  if (indicators) {
    result = {
      identified_rate: indicators.identified_rate.data.amount,
      revenue_total: indicators.revenue_total.data.amount,
      total_identified: +indicators.total_identified.data.amount,
      total_orders: +indicators.total_orders.data.amount,
      total_revenue_identified: indicators.total_revenue_identified.data.amount,
      total_revenue_unidentified_fictitious: indicators.total_revenue_unidentified_fictitious.data.amount,
      total_unidentified_fictitious: +indicators.total_unidentified_fictitious.data.amount,
      unidentified_fictitious_rate: indicators.unidentified_fictitious_rate.data.amount,
    };
  } else {
    result = null;
  }

  return result;
};
