<template>
  <div ref="info-card">
    <div class="error-message" :class="{ hideMsg: !showMsg }">
      <template v-if="error">
        <img src="@/assets/img/illustrations/settings.svg" :style="'height:' + height" :alt="$t('error-message')" />
        <p v-if="showMsg">{{ $t('error-message') }}</p>
      </template>
      <template v-else>
        <img src="@/assets/img/illustrations/empty.svg" :style="'height:' + height" :alt="$t('is-empty-message')" />
        <p v-if="showMsg">{{ $t('is-empty-message') }}</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: null,
    },
    showMsg: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    text-align: center;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 15px;
  }

  &.hideMsg {
    display: flex;
    align-items: center;
    align-content: center;
  }
}
</style>
