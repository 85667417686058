<template>
  <div class="export-wrapper">
    <button class="btn export-btn" @click="toggleExport">
      <i class="icon icon-download-18px"></i>
      {{ $t('360.export', { value: '' }) }}
    </button>
    <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
      <div v-for="item in getExportItems" :key="item.value" class="items" @click="onChangeExport(item.value)">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
// libs
import vClickOutside from 'click-outside-vue3';
import { reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import FileDownload from 'js-file-download';
// helpers
import bus from '@/helpers/events/bus';
import { dashboardMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';
// services
import { fetchDataTable } from '@/features/RawDataAccess/services';

export default {
  name: 'RawDataAccessExportrButton',
  setup() {
    const store = useStore();

    const events = reactive({
      isExportVisible: false,
      isLoadingExport: false,
    });

    //getters
    const getExportItems = computed(() => store.getters['rawDataAccess/getExportItems']);
    const getExportTableParameters = computed(() => store.getters['rawDataAccess/getExportTableParameters']);

    function toggleExport() {
      events.isExportVisible = !events.isExportVisible;
    }

    return {
      ...toRefs(events),
      toggleExport,
      getExportItems,
      getExportTableParameters,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dashboardMixin],
  methods: {
    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     */
    fetchExportData(fileType) {
      this.isExportVisible = false;
      bus.$emit('render-page-loader', true);

      const { table, filters } = this.getExportTableParameters;

      const args = { table: table, responseType: 'blob', fileType, filters: filters };

      fetchDataTable(args)
        .then(response => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(response.export, `${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },
  },
};
</script>
<style lang="scss">
@import './index.scss';
</style>
