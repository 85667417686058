<template>
  <base-card-v2 class="card customers__header">
    <div class="row customers-header">
      <div class="col-10 ch__title-wrapper">
        <icon-base :color="'#bbb'" class="ch__icon" :box="'0 0 12 18'" w="12" h="18">
          <icon-user-outline />
        </icon-base>
        <h2 class="ch__title">{{ $t('360.customers.page-title') }}</h2>
      </div>
    </div>
  </base-card-v2>
</template>

<script>
// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconUserOutline from '@/assets/vue-icons/icons/UserOutline';

export default {
  name: 'CustomersHeader',
  components: {
    IconBase,
    IconUserOutline,
  },
};
</script>

<style lang="scss" scoped>
.customers-header {
  align-items: center;

  .ch__title-wrapper {
    display: flex;
    align-items: center;

    .ch__title {
      margin: 0 0 0 15px;
    }

    .ch__icon {
      height: 26px;
      width: 22px;
      vertical-align: middle;
      margin-top: 4px;
    }
  }

  .ch__button {
    width: 160px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    float: right;
    padding-left: 20px;
    padding: 0 15px;

    span {
      font-size: 34px;
      font-weight: 200;
    }
  }
}
</style>
