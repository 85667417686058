<template>
  <el-dialog v-model="isVisible" id="labels-modal-delete" append-to-body>
    <p>{{ $t('labels.home.do-you-want-delete') }}</p>
    <div class="dialog">
      <div class="cancel btn btn-secondary" @click="closeModal">
        {{ $t('cancel') }}
      </div>
      <div
        class="confirm btn"
        @click="
          () => {
            confirmOperation();
            closeModal();
          }
        "
      >
        {{ $t('confirm') }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
import { useToast } from 'vue-toastification';
// endpoints
import { deleteLabel } from '@/services/labels/index.js';

export default {
  name: 'DeleteLabel',
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      labelId: null,
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('labels-delete-label', labelId => {
      this.labelId = labelId;
      this.openModal();
    });
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.labelId = null;
      this.isVisible = false;
    },
    confirmOperation() {
      deleteLabel(this.labelId)
        .then(res => {
          if (!res?.data?.success) {
            this.toast.error(
              this.$t(`errors.err-${res.status}`, {
                type: this.$tc('labels.title', 1).toLowerCase(),
              }),
            );
          }
        })
        .finally(() => {
          bus.$emit('labels-reload-list');
          this.closeModal();
        });
    },
  },
};
</script>

<style lang="scss">
#labels-modal-delete {
  max-width: 500px;

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .dialog {
    div {
      width: 170px;
      border-radius: 80px;
    }
  }
}
</style>
