<template>
  <section :class="name">
    <div class="title-area">
      <button class="icon icon-back-arrow" @click="back"></button>
      <h1 class="card-group-title">{{ information.name }}</h1>
      <span :class="`${name}-id`">Id: {{ reference }}</span>
    </div>
    <h2 class="card-group-title">{{ $t('overview') }}</h2>
    <div class="cards">
      <div class="row">
        <div class="col-12">
          <detail-information :load="loading" :data="information" />
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <purchase-channel />
        </div>
        <div class="col-4">
          <detail-kpis />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 mb-3">
          <h2 class="card-group-title">{{ $t('segments-title') }}</h2>
        </div>
        <detail-segments />
      </div>
      <div class="row mt-5">
        <div class="col-12 mb-3">
          <h2 class="card-group-title">{{ $t('performance-per-store') }}</h2>
        </div>
        <detail-performance-per-store />
      </div>
      <div class="row mt-5">
        <div class="col-12 mb-3">
          <h2 class="card-group-title">
            {{ $t('performance-per-preferred-store') }}
          </h2>
        </div>
        <detail-performance-per-preferred-store />
      </div>
      <div class="row mt-5">
        <div class="col-12 mb-3">
          <h2 class="card-group-title">{{ $t('performance-per-category') }}</h2>
        </div>
        <detail-performance-per-category />
      </div>
      <div class="row mt-5">
        <div class="col-12 mb-3">
          <h2 class="card-group-title">{{ $t('performance-per-product') }}</h2>
        </div>
        <detail-performance-per-product />
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import CampaignsDetailPerformancePerCategory from '@/components/campaigns/detail/CampaignsDetailPerformancePerCategory';
import CampaignsDetailPerformancePerProduct from '@/components/campaigns/detail/CampaignsDetailPerformancePerProduct';
import CampaignsDetailPurchaseChannel from '@/components/campaigns/detail/CampaignsDetailPurchaseChannel';
import CampaignsDetailKPIs from '@/components/campaigns/detail/CampaignsDetailKPIs';
import CampaignsDetailInformation from '@/components/campaigns/detail/CampaignsDetailInformation';
import CampaignsDetailSegments from '@/components/campaigns/detail/CampaignsDetailSegments';
import CampaignsDetailPerformancePerStore from '@/components/campaigns/detail/CampaignsDetailPerformancePerStore';
import CampaignsDetailPerformancePerPreferredStore from '@/components/campaigns/detail/CampaignsDetailPerformancePerPreferredStore';
import bus from '@/helpers/events/bus';
import { info } from '@/services/campaigns';
import { dateDayMonthYearVariation } from '@/utilities/constants';

export default {
  components: {
    'purchase-channel': CampaignsDetailPurchaseChannel,
    'detail-kpis': CampaignsDetailKPIs,
    'detail-information': CampaignsDetailInformation,
    'detail-segments': CampaignsDetailSegments,
    'detail-performance-per-store': CampaignsDetailPerformancePerStore,
    'detail-performance-per-preferred-store': CampaignsDetailPerformancePerPreferredStore,
    'detail-performance-per-category': CampaignsDetailPerformancePerCategory,
    'detail-performance-per-product': CampaignsDetailPerformancePerProduct,
  },
  props: {
    pageHistory: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      name: 'campaigns-detail',
      reference: this.$route.params.id,
      information: {
        createdAt: undefined,
        lastUpdate: undefined,
        audience: undefined,
        audienceName: undefined,
        audienceId: undefined,
        createBy: undefined,
        name: undefined,
        period: {
          start: undefined,
          end: undefined,
        },
        investment: undefined,
      },
      loading: false,
    };
  },
  mounted() {
    this.loadInfo();

    bus.$on('update-campaign-information', () => {
      this.loadInfo();
    });
  },
  beforeUnmount() {
    bus.$off('update-campaign-information');
  },
  methods: {
    back() {
      this.$router.push({
        name: 'performance-audiences',
        query: {
          page: this.pageHistory,
        },
      });
    },
    loadInfo() {
      this.loading = true;

      info(this.reference)
        .then(({ data }) => {
          if (data.data) {
            const infos = data.data;
            this.information.period.start = moment(infos.start_date).format(dateDayMonthYearVariation);
            this.information.period.end = moment(infos.end_date).format(dateDayMonthYearVariation);
            this.information.createdAt = infos.created_date
              ? moment(infos.created_date).format(dateDayMonthYearVariation)
              : undefined;
            this.information.lastUpdate = infos.last_update
              ? moment(infos.last_update).format(dateDayMonthYearVariation)
              : undefined;
            this.information.audienceName = infos.audience_name;
            this.information.audienceId = infos.audience_id;
            this.information.createBy = infos.user_name;
            this.information.name = infos.campaign_name;
            this.information.investment = infos.investment;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-detail {
  .card-group-title {
    font-weight: 400;
    color: $gray-700;
    font-size: 20px;
  }

  .cards {
    margin-top: 24px;

    .card {
      height: 100%;
    }
  }

  .title-area {
    display: flex;
    margin: 40px 0;
    align-items: center;
    > button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: $gray-600;
      padding-left: 0;
    }
    .card-group-title {
      margin: 0;
      display: flex;
      max-width: 400px;
      white-space: normal;
      word-break: break-word;
      color: $gray-800;
      font-weight: 600;
    }
    .campaigns-detail-id {
      font-size: 14px;
      font-weight: 300;
      color: $gray-800;
      margin-left: 14px;
      margin-bottom: -3px;
    }
  }
}
</style>

<style lang="scss">
.campaigns-detail-information {
  &.loadHeight {
    min-height: 108px;
  }
}
.campaigns-detail {
  .cards .card {
    &.campaigns-detail-information {
      height: auto;
    }
    .value-label {
      line-height: 14px;
      margin: 6px 0;
    }
  }
}
</style>
