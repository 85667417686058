import store from '@/store';
import { valueType } from '../constants';

type Locale = {
  locale: string;
};

type PercentFormatting = {
  style: string;
  maximumFractionDigits: number | undefined;
};

export const formatPercent = (value: string | number, options?: number) => {
  const DEFAULT_LANGUAGE = {
    locale: 'pt-BR',
  };

  const { locale }: Locale = store.getters?.currency || DEFAULT_LANGUAGE;
  const percentValue = value ? (typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value) : 0;
  const formatting: PercentFormatting = {
    style: valueType.percent,
    maximumFractionDigits: options ? options : undefined,
  };

  const result = new Intl.NumberFormat(locale, formatting).format(percentValue);

  return result;
};
