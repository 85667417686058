
// libs
import { useStore } from 'vuex';
import { computed, defineComponent, ref, watch } from 'vue';
import { formatNumber } from '@/utilities/formatters';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';

export default defineComponent({
  components: {
    BaseLoader,
  },
  setup() {
    const store = useStore();

    const isVisible = ref(false);

    // getters
    const getIsSummaryVisible = computed(() => store.getters['rawDataAccess/getIsSummaryVisible']);
    const getSummaryInfos = computed(() => store.getters['rawDataAccess/getSummaryInfos']);
    const getPercet = computed(() =>
      formatNumber((getSummaryInfos.value.totalCount / getSummaryInfos.value.totalTable) * 100, '0%'),
    );
    const getSummaryLoading = computed(() => store.getters['rawDataAccess/getSummaryLoading']);

    watch(getIsSummaryVisible, () => {
      isVisible.value = getIsSummaryVisible.value;
    });

    return {
      isVisible,
      getSummaryInfos,
      getPercet,
      getSummaryLoading,
    };
  },
});
