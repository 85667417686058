<template>
  <div class="product-line">
    <figure class="media">
      <img :src="image" :alt="product.name" />
    </figure>
    <div class="details">
      <h3>{{ product.name }}</h3>
      <div class="list">
        <div class="id">
          <i class="icon icon-box icon-18"></i>
          <span v-if="product.id">
            {{ $t('product.reference') }}
            <strong>{{ product.id }}</strong>
          </span>
          <span v-else class="empty">{{ $t('product.empty.no-reference') }}</span>
        </div>
        <div class="category">
          <i class="icon icon-box icon-18"></i>
          <span v-if="product.category">
            {{ $t('category') }}
            <strong>
              {{ product.category }}
              <span v-if="product.subcategory">/ {{ product.subcategory }}</span>
            </strong>
          </span>
          <span v-else class="empty">{{ $t('product.empty.no-category') }}</span>
        </div>
      </div>
      <div class="results d-flex">
        <div class="orders mr-4">
          <span class="label">{{ $tc('order', 0) }}</span>
          <span
            v-if="product.sales_variation"
            class="value chart-value-big"
            :class="`${isPositive(product.sales_variation) ? 'color-oto-brand-omni' : 'color-red'}`"
          >
            {{ salesVariation }}</span
          >
          <span v-else class="value value-empty">-</span>
        </div>
        <div class="revenue">
          <span class="label">{{ $t('revenue') }}</span>
          <span
            v-if="product.revenue_variation"
            class="value chart-value-big"
            :class="`${isPositive(product.revenue_variation) ? 'color-oto-brand-omni' : 'color-red'}`"
          >
            {{ revVariation }}</span
          >
          <span v-else class="value value-empty">-</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPercent } from '@/utilities/formatters';

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    salesVariation() {
      const number = this.product.sales_variation;
      return this.formatVariation(number);
    },
    revVariation() {
      const number = this.product.revenue_variation;
      return this.formatVariation(number);
    },
    image() {
      return this.product.image || require('@/assets/img/figures/product/no_photo_line.png'); // eslint-disable-line
    },
  },
  methods: {
    isPositive(number) {
      const result = Math.sign(number);
      return result !== -1;
    },
    formatVariation(number) {
      if (this.isPositive(number)) return `+${formatPercent(number / 100)}`;
      return formatPercent(number / 100);
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  width: 235px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

figure {
  height: 120px;
  overflow: hidden;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0;

  img {
    width: 100%;
  }
}

.details {
  padding: 20px;

  .list {
    > div {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 12px;
      }

      strong {
        padding-left: 5px;
      }
    }

    .category {
      padding-top: 12px;
    }
  }

  .list,
  .results {
    border-top: 1px solid $gray-300;
    padding: 12px 0;
  }

  .results {
    .label {
      display: block;
      margin-bottom: 9px;
    }

    .value {
      line-height: 21px;
    }

    .value-empty {
      display: block;
      color: $gray-500;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
