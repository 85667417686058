type ErrorType = {
  code: string;
  message: string;
};

interface ApiResponse<T> {
  errors: ErrorType[];
  success: boolean;
  data: T;
}

interface Data {
  key: string;
  name: string;
}

export type ContactResponse = ApiResponse<Data[]>;
type RemoteContactData = Record<string, string>;
export type RemoteContactResponse = ApiResponse<RemoteContactData>;
export type ContactType = Data;

// sales
export type SalesResponse = ApiResponse<Data[]>;
type RemoteSalesData = Record<string, string>;
export type RemoteSalesResponse = ApiResponse<RemoteSalesData>;
export type SalesType = Data;

// segments
export type Segments = {
  id: string;
  name: string;
};

export enum SelectedSegment {
  ALL = 'all',
  STORE = 'store',
  SELLER = 'seller',
  CAMPAIGN = 'campaign',
  CLUSTER = 'cluster',
}

export type SelectedFilterListOptions = {
  value: number;
  label: string;
  isChecked: boolean;
};

export type LoadingBySegments = {
  isLoadingStores: boolean;
  isLoadingSellers: boolean;
  isLoadingCampaigns: boolean;
  isLoadingClusters: boolean;
};
