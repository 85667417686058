<template>
  <div id="security" class="security">
    <h1 class="card-group-title">{{ $t('settings.security.title') }}</h1>
    <div class="card security__card">
      <div class="card-body">
        <div class="row security__row">
          <div class="col-4">
            <h2 class="card-title">
              {{ $t('settings.preferences.password.title') }}
            </h2>
            <p>{{ $t('settings.preferences.password.description.title') }}</p>
            <span>
              <ul>
                <li>
                  {{ $t('settings.preferences.password.description.rule-1') }}
                </li>
                <li>
                  {{ $t('settings.preferences.password.description.rule-2') }}
                </li>
                <li>
                  {{ $t('settings.preferences.password.description.rule-3') }}
                </li>
                <li>
                  {{ $t('settings.preferences.password.description.rule-4') }}
                </li>
              </ul>
            </span>
          </div>
          <div class="col-8">
            <form name="change-password" class="security__form">
              <VTooltip
                :shown="isPasswordOnTheCorrectForm"
                :triggers="[]"
                placement="right"
                theme="default"
                :distance="8"
              >
                <div class="form-wrapper security__form__wrapper">
                  <div class="value-label security__value-label">
                    {{ $t('settings.preferences.password.label') }}
                  </div>
                  <div class="input-wrapper input-password security__form--input">
                    <input
                      v-model="password"
                      type="password"
                      :placeholder="$t('settings.preferences.password.placeholder.new-password')"
                    />
                    <i class="icon icon-attention-14px" />
                    <i class="icon icon-checked-14px" />
                  </div>
                  <div class="value-label security__value-label">
                    {{ $t('settings.preferences.password.label-confirm') }}
                  </div>
                  <div class="input-wrapper input-confirm-password security__form--input">
                    <VTooltip
                      :shown="isConfirmPasswordCorrect"
                      :triggers="[]"
                      placement="right"
                      theme="default"
                      :distance="8"
                    >
                      <input
                        v-model="confirmPassword"
                        type="password"
                        :placeholder="$t('settings.preferences.password.placeholder.repeat-password')"
                      />
                      <i class="icon icon-attention-14px" />
                      <template #popper>
                        <div class="security__input-tooltip">
                          {{ $t('settings.preferences.password.error-different-password') }}
                        </div>
                      </template>
                    </VTooltip>
                  </div>
                </div>

                <template #popper>
                  <div class="security__input-tooltip">
                    {{ $t('settings.preferences.password.error-validation') }}
                  </div>
                </template>
              </VTooltip>
              <button class="btn security__btn-submit" @click.prevent="updatePassword">
                {{ $t('settings.preferences.password.btn') }}
              </button>
            </form>
          </div>
        </div>
        <div class="row security__row">
          <div class="row">
            <div class="col-4">
              <h2 class="card-title">
                {{ $t('settings.security.two-factor.title') }}
              </h2>
              <p>{{ $t('settings.security.two-factor.subtitle') }}</p>
              <p>{{ $t('settings.security.two-factor.body') }}</p>
              <p v-if="$experimental" class="link">
                {{ $t('settings.security.two-factor.link') }}
              </p>
            </div>
            <div v-if="!MFA.isEnabled" class="col-8">
              <p>
                {{ $t('settings.security.steps.title') }}
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                  class="link"
                >
                  Google Authenticator
                </a>
              </p>
              <div class="row">
                <div class="col-4">
                  <img v-if="MFA.qrCodeImage" class="img-qrcode" :src="MFA.qrCodeImage" />
                </div>
                <div class="col-8 security__mfa-steps">
                  <ul>
                    <li>{{ $t('settings.security.steps.step-1') }}</li>
                    <li>{{ $t('settings.security.steps.step-2') }}</li>
                    <li>{{ $t('settings.security.steps.step-3') }}</li>
                    <li>{{ $t('settings.security.steps.step-4') }}</li>
                    <li>{{ $t('settings.security.steps.step-5') }}</li>
                  </ul>
                </div>
              </div>
              <load-spinner id="login-loader" color="white" :text="text" />
              <p>{{ $t('settings.security.ps') }}</p>
              <form name="auth-mfa">
                <label for="input-verification-code">
                  {{ $t('settings.security.lbl-input-code') }}
                </label>
                <div class="input-wrapper verification security__form--input">
                  <input
                    v-model="MFA.oauthCode"
                    name="input-verification-code"
                    type="text"
                    :placeholder="$t('settings.security.lbl-input-code')"
                    @keypress="maskInput"
                    @focus="clearThrowErrorMFA"
                  />
                  <i class="icon icon-attention-14px" />
                  <i class="icon icon-checked-14px" />
                </div>
                <button class="btn security__btn-submit" @click.prevent="activeMFA">
                  {{ $tc('settings.security.btn-active-auth', 1) }}
                </button>
              </form>
            </div>
            <div v-else class="col-8">
              <p>
                {{ $t('settings.security.status') }}:
                <b>{{ $t('settings.security.active') }}</b>
              </p>
              <p class="link" @click="deactiveMFA">
                {{ $t('settings.security.change-device') }}
              </p>
              <button class="btn security__btn-submit" @click.prevent="deactiveMFA">
                {{ $tc('settings.security.btn-active-auth', 2) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="isVisible" id="password-update-success" append-to-body>
      <i class="icon icon-checked-60px" />
      <p class="mb-0">{{ $t('settings.preferences.password.success') }}</p>
    </el-dialog>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
// components
import store from '@/store';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import {
  changePassword,
  generateQrCodeMfa,
  generateQrCode,
  setValidToken,
  getStatusMFA,
  disableMFA,
} from '@/services/userSettings';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'Security',
  components: {
    LoadSpinner,
  },
  data() {
    return {
      password: undefined,
      confirmPassword: undefined,
      loading: true,
      showText: true,
      text: '',
      MFA: {
        isEnabled: undefined,
        qrCodeImage: undefined,
        oauthCode: '',
      },
      isConfirmPasswordCorrect: false,
      isPasswordOnTheCorrectForm: false,
      isVisible: false,
    };
  },
  mounted() {
    useMixpanelConfig('view_settings_security');
    this.getQRcode();
    this.toggleEnabledMFA();
  },
  methods: {
    /**
     *  Password Methods
     */
    updatePassword() {
      this.hideTooltips();
      const $password = document.querySelector('.input-password');
      const $confirm = document.querySelector('.input-confirm-password');

      if (this.password !== this.confirmPassword) {
        $confirm.classList.add('error');
        this.isConfirmPasswordCorrect = true;
        setTimeout(() => (this.isConfirmPasswordCorrect = false), 2000);
        $password.classList.add('success');
      } else if (this.password && this.confirmPassword) {
        changePassword(this.password).then(response => {
          if (response.data.success) {
            this.$store.dispatch('changeToken', response.data.data.public_token);
            this.showModal();
          } else {
            this.isPasswordOnTheCorrectForm = true;
            setTimeout(() => (this.isPasswordOnTheCorrectForm = false), 2000);
            $password.classList.add('error');
            $confirm.classList.add('error');
          }
        });
      } else {
        $password.classList.add('error');
        $confirm.classList.add('error');
      }
      this.reset();
    },
    hideTooltips() {
      this.isConfirmPasswordCorrect = false;
      this.isPasswordOnTheCorrectForm = false;
    },
    showModal() {
      this.isVisible = true;
      setTimeout(() => (this.isVisible = false), 1500);
    },
    reset() {
      this.password = undefined;
      this.confirmPassword = undefined;
    },
    /**
     * Loader
     */
    MfaLoader(loading = false, showText = false) {
      this.loading = loading;
      this.showText = showText;
      if (this.loading && this.showText) {
        bus.$emit('show-spinner', 'login-loader');
        this.text = '';
      } else if (this.loading && !this.showText) {
        bus.$emit('show-spinner', 'login-loader');
        this.text = this.$t('login.loader-authentication');
      } else if (!this.loading && !this.showText) {
        bus.$emit('hide-spinner', 'login-loader');
        this.text = '';
      } else {
        bus.$emit('hide-spinner', 'login-loader');
      }
    },
    /**
     *  MFA Methods
     */
    getQRcode() {
      this.MfaLoader(this.loading, this.showText);
      generateQrCodeMfa({ size: 4 }).then(res => {
        this.generateQrCodeUrl(res.data.data.qr_code_url.split('v3/')[1]);
        this.MfaLoader(!this.loading, this.showText);
      });
    },
    generateQrCodeUrl(url) {
      generateQrCode(url).then(res => {
        const imageUrl = URL.createObjectURL(res.data);
        this.MFA.qrCodeImage = imageUrl;
      });
    },
    toggleEnabledMFA() {
      getStatusMFA().then(res => {
        this.MFA.isEnabled = res?.data?.data?.status;
      });
    },
    activeMFA() {
      setValidToken(this.MFA.oauthCode.replace(' ', '')).then(res => {
        if (!res.data.data.validate) {
          this.throwErrorMFA();
        }
        this.toggleEnabledMFA();
      });
    },
    maskInput(event) {
      const { key } = event;
      this.MFA.oauthCode.length === 3 ? (this.MFA.oauthCode += ' ') : this.MFA.oauthCode.replace(' ', '');
      if (this.MFA.oauthCode.length >= 7 || !/\d/g.test(key)) event.preventDefault();
    },
    throwErrorMFA() {
      const $inputWrapper = document.querySelector('.input-wrapper.verification');
      const $inputVerification = document.querySelector('input[name="input-verification-code"]');
      $inputVerification.tooltip({
        placement: 'right',
        title: this.$t('settings.security.error-code'),
        trigger: 'manual',
        html: true,
        template:
          "<div class='tooltip error' role='tooltip'><div class='arrow'></div><div class='tooltip-inner'></div></div>",
      });
      $inputWrapper.addClass('error');
      $inputVerification.addClass('error');
      $inputVerification.tooltip('show');
      setTimeout(() => $inputVerification.tooltip('hide'), 3000);
    },
    clearThrowErrorMFA() {
      const $inputWrapper = document.querySelector('.input-wrapper.verification');
      const $inputVerification = document.querySelector('input[name="input-verification-code"]');
      $inputVerification.tooltip('hide');
      $inputWrapper.removeClass('error');
      $inputVerification.removeClass('error');
    },
    deactiveMFA() {
      this.MfaLoader(this.loading, this.showText);
      disableMFA().then(() => {
        this.toggleEnabledMFA();
        this.MfaLoader(!this.loading, this.showText);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.security {
  &__card {
    padding: 50px;
  }

  &__row {
    padding: 40px 0;
    border-bottom: 1px solid $gray-400;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
  }

  &__value-label {
    margin-bottom: 10px;
    display: block;
  }

  &__btn-submit {
    width: 274px;
    margin-bottom: 1rem;
  }

  &__form {
    &,
    &__wrapper {
      max-width: 274px;
    }

    &--input {
      width: 274px;
      position: relative;
      margin-bottom: 1rem;

      input:not([type='checkbox']):not([type='radio']) {
        width: 274px;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .icon {
        display: none;
        font-size: 20px;
        position: absolute;
        top: 8px;
        right: 10px;
      }

      &.error {
        input {
          border: 1px solid $color-red;
        }
        .icon-attention-14px {
          display: block;
          color: $color-red;
        }
      }
      &.success {
        input {
          border: 1px solid $oto-ecommerce;
        }
        .icon-checked-14px {
          display: block;
          color: $oto-ecommerce;
        }
      }
    }
  }

  &__mfa-steps {
    align-items: center;
    display: flex;
    ul {
      padding: 0;
      margin: 0;
      li {
        font-size: 11px;
        list-style: none;
        line-height: 25px;
        color: $gray-800;
      }
    }
  }

  &__input-tooltip {
    width: 165px;
  }
}

span {
  color: $gray-800;
  font-size: 12px;
  ul {
    padding: 20px 0 0;
    list-style: none;
    li:before {
      content: '•';
      padding-right: 5px;
    }
  }
}

.link {
  &:active,
  &:hover,
  &:visited {
    color: $blue-400;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
}

#password-update-success {
  width: 540px;
  text-align: center;

  .el-dialog {
    &__header {
      display: none;
    }

    &__body {
      .icon-checked-60px {
        display: block;
        text-align: center;
        color: $oto-ecommerce;
        font-size: 60px;
        text-align: center;
      }

      p {
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
