<template>
  <section class="labels-view labels-card">
    <div class="row">
      <div class="col-12 align-self-center">
        <base-card-v2 class="labels-list">
          <base-card-header :title="$tc('labels.title', 2)" icon="icon-folder-30px f-30">
            <template v-slot:item-right>
              <div class="float-right ml-auto d-flex list-actions">
                <!-- SEARCH AND ADD MENU -->
                <div class="form-wrapper search-and-filter">
                  <input v-model="term" class="findTerm" :placeholder="$t('labels.search-label')" />
                  <i v-if="term !== ''" class="icon icon-close" @click="clearTerm"></i>
                  <i v-else class="icon icon-search"></i>
                </div>
                <button class="btn btn-add-label" :title="$t('labels.home.add-label')" @click.prevent="addLabel">
                  {{ $t('labels.home.add-label') }}
                </button>
              </div>
              <!-- END SEARCH AND ADD MENU -->
            </template>
          </base-card-header>
          <div v-if="$_verifyLoadedVuex('done')" class="tg-list">
            <div class="tg-row -header">
              <div class="tg-col">
                <span>{{ $t('labels.home.name') }}</span>
              </div>
              <div class="tg-col">{{ $t('labels.home.size') }}</div>
              <div class="tg-col">{{ $t('labels.home.description') }}</div>
              <div class="tg-col"></div>
            </div>
            <div
              v-for="(label, index) in getFilteredLabelsList(term)"
              :key="index"
              class="tg-row"
              @click.self="verifyIsRename(label.id, label.name)"
            >
              <div class="tg-col" @click="verifyIsRename(label.id, label.name)">
                <icon-base w="17" h="18" color="#BBB" box="0 0 17 18"><icon-label /></icon-base>
                <span
                  ref="label_list_item"
                  class="label-name ellipsis"
                  :contenteditable="editLabelName === label.id"
                  @keypress.enter.prevent="onEnter()"
                  @blur="onBlur(label.id, label.name)"
                >
                  {{ label.name }}
                </span>
              </div>
              <div class="tg-col" @click="openLabel(label.id, label.name)">
                <icon-base w="11" h="13" color="#888" box="0 0 11 13">
                  <icon-user-outline />
                </icon-base>
                {{ $_getValue({ type: 'integer', amount: label.count }) }}
              </div>
              <div class="tg-col" @click="openLabel(label.id, label.name)">
                <span>{{ label.description }}</span>
              </div>
              <div class="tg-col">
                <menu-dropdown :label="label.id" :actions="actionsDropdown(label, index)" />
              </div>
            </div>
            <div v-if="filteredListIsEmpty" class="no-results">
              {{ $t('labels.home.no-results') }}
            </div>
          </div>
        </base-card-v2>
        <base-loader v-show="$_verifyLoadedVuex('loading')" class="card labels-info loadHeight" />
        <empty-state
          v-show="getIsEmpty && !getHasError"
          :title="$t('360.labels.empty-state-labels.title')"
          :text="$t('360.labels.empty-state-labels.text')"
          :button-text="$t('360.labels.empty-state-labels.button')"
          :exec-function="addLabel"
        >
          <template v-slot:image>
            <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" alt="" />
          </template>
        </empty-state>
        <info-card v-if="getHasError" class="card labels-info loadHeight" :error="true" />
      </div>
    </div>
    <delete-label />
  </section>
</template>

<script>
// libs, helpers, services
import { isEmpty } from 'lodash';
import bus from '@/helpers/events/bus';
import { mapState, mapGetters, mapActions } from 'vuex';
import { updateLabel, getHasLabelsLeft } from '@/services/labels/index.js';
import { tableMixin, verifyMixin } from '@/helpers/mixins';
import { useToast } from 'vue-toastification';
// utilities
import { useMixpanelConfig, useMixpanelConfigLabels } from '@/utilities/mixpanel';
// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconLabel from '@/assets/vue-icons/icons/Label';
import IconUserOutline from '@/assets/vue-icons/icons/UserOutline';
// components - molecules
import MenuDropdown from '@/components/_atoms/MenuDropdown.vue';
// components - organisms
import DeleteLabel from '@/components/labels/_organisms/DeleteLabel';

import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '../components/_atoms/InfoCard.vue';
import EmptyState from '@/components/_atoms/EmptyState';

export default {
  name: 'Labels',
  components: {
    IconBase,
    IconLabel,
    IconUserOutline,
    DeleteLabel,
    BaseLoader,
    InfoCard,
    EmptyState,
    MenuDropdown,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  mixins: [tableMixin, verifyMixin],
  data() {
    return {
      term: '',
      editLabelName: null,
      isRenameId: '',
      renameCell: {
        index: null,
        name: null,
      },
    };
  },
  computed: {
    ...mapState('labels', ['list', 'totalLabels']),
    ...mapGetters('labels', ['getFilteredLabelsList', 'getIsEmpty', 'getHasError', 'getLoading']),
    filteredListIsEmpty: function () {
      return isEmpty(this.getFilteredLabelsList(this.term));
    },
  },
  mounted() {
    useMixpanelConfig('view_audience_management_labels');

    this.updateVuex();
    bus.$on('update-selected-brand', () => {
      this.updateVuex();
    });
    bus.$on('render-cards', () => {
      this.updateVuex();
    });
    bus.$on('labels-reload-list', () => {
      this.updateVuex();
    });
    bus.$on('restore-cell-name', this.restoreCellName);
    bus.$on('labels-rename-label', ({ labelId, labelIndex }) => {
      this.isRenameId = labelId;
      this.renameCell.index = labelIndex;
      this.renameCell.name = this.$refs.label_list_item[labelIndex].innerText;
      this.renameLabel(labelId, labelIndex);
    });
  },
  beforeUnmount() {
    bus.$off(['update-selected-brand', 'labels-reload-list', 'labels-rename-label', 'restore-cell-name']);
  },
  methods: {
    ...mapActions('labels', ['getLabelsList', 'actionCheckAccount360']),
    /**
     * @description Verify if this Label is with rename active and call openLabel method
     * @param {Number} id
     */
    verifyIsRename(id, name) {
      if (this.isRenameId != id) this.openLabel(id, name);
    },
    addLabel() {
      getHasLabelsLeft().then(({ data }) => {
        if (data.data.labelsLeft <= 0) {
          this.toast.error(
            this.$t(`errors.err-429`, {
              type: this.$tc('labels.title', 2).toLowerCase(),
            }),
          );
          return;
        }
        this.$router.push({
          path: `/labels/create/`,
        });
      });
    },
    updateVuex() {
      this.getLabelsList();
      this.actionCheckAccount360();
    },
    /**
     * @description clear term of search input
     */
    clearTerm() {
      this.term = '';
    },
    /**
     * @description change route to detailed label page
     * @param {Number} id
     */
    openLabel(id, name) {
      useMixpanelConfigLabels('view_audience_management_labels_details', id, name);
      this.$router.push({
        name: 'LabelView',
        params: { id },
      });
    },
    /**
     * @description set id of renaming label and add events to open span tag edit mode
     * @param {Number} labelId
     * @param {Number} labelIndex
     */
    renameLabel(labelId, labelIndex) {
      this.editLabelName = labelId;
      this.$nextTick(() => {
        this.onFocus(labelIndex);
        this.$refs.label_list_item[labelIndex].focus();
      });
    },
    /**
     * @description update label with span inner text and call eventbus to reload labels list
     * @param {Number} labelId
     */
    renameAction(labelId) {
      updateLabel({ labelId, name: event.target.innerText }).then(res => {
        if (!res?.data?.success) {
          bus.$emit('restore-cell-name');
          this.showErrorMessage(res.status);
        } else {
          this.toast.success(this.$t('labels.detail.messages.updated'), {
            timeout: 5000,
          });
          bus.$emit('labels-reload-list');
        }
      });
      this.editLabelName = null;
      this.isRenameId = '';
    },
    /**
     * @description change rename innerText to data name/index stored by 'labels-rename-label' emitter
     */
    restoreCellName() {
      this.$refs.label_list_item[this.renameCell.index].innerText = this.renameCell.name;
      this.renameCell.index = null;
      this.renameCell.name = null;
    },
    /**
     * @description validate error code and fire emitter
     */
    showErrorMessage(errorCode) {
      if (errorCode === 429) {
        bus.$emit(
          'show-detail-error',
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('labels.title', 2).toLowerCase(),
          }),
        );
      } else {
        this.toast.error(
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('labels.title', 1).toLowerCase(),
          }),
        );
        bus.$emit('header-details-input-blur');
      }
    },
    /**
     * @description add focus event to element to simulate input element
     * @param {Number} index of label object
     */
    onFocus(index) {
      const element = this.$refs.label_list_item[index];
      element.addEventListener('focus', () => {
        const cursorPosition = element.innerText.length;
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(element.childNodes[0], cursorPosition);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      });
    },
    /**
     * @description action to update label name on blur event
     * @param {Number} labelId
     * @param {String} name
     */
    onBlur(labelId, name) {
      if (event.target.innerText === '') {
        bus.$emit('restore-cell-name');
        this.showErrorMessage('422');
        return false;
      }
      if (event.target.innerText === name) return;
      this.renameAction(labelId);
    },
    /**
     * @description action to remove focus of rename label
     */
    onEnter() {
      event.target.blur();
    },

    /**
     * @description actions to menu dropdown
     */
    rename(label, index) {
      bus.$emit('labels-rename-label', { labelId: label.id, labelIndex: index });
    },
    delete(label) {
      bus.$emit('labels-delete-label', label.id);
    },
    duplicate(label) {
      if (!label.copyable) {
        this.toast.error(this.$t(`labels.copyable`), { timeout: 10000 });
      } else {
        this.$router.push({ name: 'LabelsDuplicate', params: { id: label.id } });
      }
    },
    actionsDropdown(label, index) {
      return [
        {
          name: 'duplicate',
          action: () => this.duplicate(label),
        },
        {
          name: 'rename',
          action: () => this.rename(label, index),
        },
        {
          name: 'delete',
          action: () => this.delete(label),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.labels-info {
  &.loadHeight {
    min-height: 514px;
  }
}
.tg-list {
  margin-top: 20px;
  min-height: 250px;
  font-size: 12.1px;
  color: #888;

  .tg-row {
    display: grid;
    grid-template-columns: 320px 160px auto 54px;
    grid-template-rows: 40px;
    border-bottom: 1px solid #eee;
    justify-items: start;
    align-items: center;

    &.-header {
      font-weight: 600;
    }

    &:not(.-header) {
      cursor: pointer;

      &:nth-child(even) {
        background: #eeeeee4d;
      }
      &:hover {
        background: #eeeeee;
      }
      .tg-col:first-child {
        padding-left: 15px;
      }
    }
  }
}

.no-results {
  text-align: center;
  margin-top: 80px;
}

.tg-col {
  display: flex;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 500px;
    z-index: 1;

    &.ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.label-name {
      user-select: text;
      max-width: 260px;
      color: $blue-500;
      &:focus {
        max-width: 100%;
        min-width: 150px;
        outline: 0 none;
        border-bottom: 1px solid;
        background: #fbfbfb;
        z-index: 2;
      }
    }
  }
  svg {
    margin-right: 8px;
  }
}

.list-actions {
  .search-and-filter {
    width: 310px;
    margin-right: 15px;
    position: relative;
    .findTerm {
      width: 100%;
    }
    i {
      position: absolute;
      right: 0px;
      top: 4px;
      font-size: 26px;
      color: #bbb;
      cursor: pointer;
    }
  }
  .btn-add-label {
    width: 170px;
    height: 34px;
    display: block;
    line-height: 34px;
  }
}

.labels-list {
  &.loadHeight {
    min-height: 300px;
  }
}
</style>

<style lang="scss">
.f-30 {
  font-size: 30px !important;
}

.labels-card {
  .card {
    padding: 18px !important;
    .card-title {
      margin-bottom: 0 !important;
    }
  }
}
</style>
