<template>
  <div id="dashboard-webpush">
    <base-card-v2 class="alert-performance">
      <alert type="info" :message="$t('alert-performance')" />
    </base-card-v2>
    <header class="title-area">
      <div class="d-flex align-items-center"></div>
    </header>
    <dashboard-web-push-graph />
    <dashboard-web-push-metrics-group />
    <dashboard-web-push-metrics-table />
    <base-loader v-show="loadingExport" color="colored-block" class="render-loader"></base-loader>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import DashboardWebPushGraph from '@/components/dashboard/revenueAndEngagement/DashboardWebPushGraph';
import DashboardWebPushMetricsGroup from '@/components/dashboard/DashboardWebPushMetricsGroup';
import DashboardWebPushMetricsTable from '@/components/dashboard/metricsTable/DashboardWebPushMetricsTable';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import Alert from '@/components/_atoms/Alert';

export default {
  name: 'DashboardWebPush',
  components: {
    DashboardWebPushGraph,
    DashboardWebPushMetricsGroup,
    DashboardWebPushMetricsTable,
    BaseLoader,
    Alert,
  },
  data() {
    return {
      dashUrl: this.verifProd(),
      isAlertActive: true,
      loadingExport: false,
    };
  },
  mounted() {
    // Show warning just once and hide after the first view
    !localStorage.getItem('ctx-dash-alert')
      ? localStorage.setItem('ctx-dash-alert', true)
      : (this.isAlertActive = false);

    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
  },
  beforeUnmount() {
    bus.$off('render-page-loader');
  },
  methods: {
    verifProd() {
      return process.env.NODE_ENV === 'development'
        ? 'https://dashboard-dev.pmweb.com.br'
        : 'https://dashboard.pmweb.com.br/';
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard-webpush {
  .title-area a {
    padding: 8px;
  }
  .render-loader {
    background-color: #232642;
    height: 100%;
    left: 50%;
    opacity: 0.7;
    overflow: hidden;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 9999;
  }
}
</style>
