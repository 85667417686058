<template>
  <div class="indicators-comparable-sellers row">
    <div :class="['col', 'col-xl-3', 'card-height', !getStoreId && !getSellerId && !getCampaignId ? 'disabled' : '']">
      <div class="card card-filters-comparable">
        <div class="card-body">
          <indicator-comparable-filters :stores="stores" />
        </div>
      </div>
    </div>
    <div class="col col-xl-9 card-height">
      <base-card-v2 v-if="!parallelCoordinates.loading && parallelCoordinatesHasData">
        <div>
          <h2 class="card-title card-custom-title d-flex align-items-center">
            <i class="orange-circle"></i>
            <span v-if="getActiveSellerObject || getActiveStoreObject">{{ sellerOrStoreActive() }}</span>
          </h2>
          <div class="comparable-list-items customScrollBar">
            <indicator-comparable-line
              v-for="(serie, key) in parallelCoordinates.data.series"
              :key="key"
              :segment-selected="segment"
              :options="{ ...serie }"
            />
          </div>
        </div>
      </base-card-v2>
      <div v-else class="card presentation-card">
        <empty-state
          :title="$t('360.indicators.empty-state.title')"
          :text="$t('360.indicators.empty-state.text')"
          :button-text="$t('360.indicators.empty-state.button')"
          :exec-function="focusSearch"
        >
          <template v-slot:image>
            <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" alt="" />
          </template>
        </empty-state>
      </div>
      <load-spinner :show="parallelCoordinates.loading" class="white" />
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { useMixpanelConfig } from '@/utilities/mixpanel';
// store
import { mapState, mapGetters, mapActions } from 'vuex';
// services
import listStores from '@/services/stores';
// components
import LoadSpinner from '@/components/oto/sellersAnalytics/_atoms/Spinner';
import NoStoresOnChart from '@/assets/img/figures/no-stores-chart.jpg';
import IndicatorComparableFilters from '@/components/oto/indicators/IndicatorComparableFilters';
import IndicatorComparableLine from '@/components/oto/indicators/IndicatorComparableLine';
import EmptyState from '@/components/_atoms/EmptyState';

export default {
  name: 'ComparableSellers',
  components: {
    LoadSpinner,
    IndicatorComparableFilters,
    IndicatorComparableLine,
    EmptyState,
  },
  data() {
    return {
      stores: [],
      storesToPlot: [],
      bgImagePresentationCard: NoStoresOnChart,
      id: 'comparable-sellers',
      segment: '',
      tab: 'tab-store-comparison',
    };
  },
  computed: {
    ...mapState('indicators', ['availableMetrics', 'parallelCoordinates']),
    ...mapGetters('indicators', [
      'getActiveSellerObject',
      'getActiveStoreObject',
      'parallelCoordinatesHasData',
      'getSellerId',
      'getStoreId',
      'getCampaignId',
      'getFilters',
    ]),
    metrics: {
      get() {
        return Object.keys(this.availableMetrics.data).map(item => ({
          key: item,
          name: this.availableMetrics.data[item].name,
        }));
      },
    },
  },
  created() {
    this.getStoresInitial();
  },
  mounted() {
    useMixpanelConfig('view_oto_indicators_comparable-stores');

    bus.$on('indicators-change-tab-nav', () => {
      this.getStores();
      this.resetComparableFilters();
    });
    bus.$on('wrap-stores-plot', () => {
      this.wrapStoresToPlot();
    });
    bus.$on('selected-segment', segment => {
      this.segment = segment.id;
    });
  },
  beforeUnmount() {
    bus.$off('wrap-stores-plot');
    bus.$off('selected-segment');
    bus.$off(`indicators-select-emit-${this.tab}`);
  },
  methods: {
    ...mapActions('indicators', ['resetFilters', 'clearParallelCoordinates']),

    sellerOrStoreActive() {
      if (this.getActiveSellerObject && this.getActiveSellerObject.id) return this.getActiveSellerObject.fullName;
      if (this.getActiveStoreObject && this.getActiveStoreObject.id) return this.getActiveStoreObject.name;
    },
    getStoresInitial() {
      listStores().then(response => {
        this.stores = response?.data?.data || [];
      });
    },
    getStores() {
      listStores().then(response => {
        this.stores = response?.data?.data || [];
        this.wrapStoresToPlot();
        this.resetComparisonStores();
        bus.$emit('get-stores-list');
      });
    },
    wrapStoresToPlot() {
      this.storesToPlot = this.stores;
      this.getFilters.comparisonStore = [];
    },
    resetComparisonStores() {
      this.getFilters.comparisonStore = [];
    },
    resetComparableFilters() {
      // reset metrics and comparisonStores
      this.resetFilters();
      this.clearParallelCoordinates();
      bus.$emit('comparison-stores-filters', this.getFilters);
      bus.$emit('reset-comparable-stores');
    },
    focusSearch() {
      useMixpanelConfig('view_oto_indicators_select_store');
      bus.$emit('indicator-focus-segment-by');
    },
  },
};
</script>

<style lang="scss" scoped>
.indicators-comparable-sellers {
  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  .presentation-card {
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;

    p {
      font-size: 14px;
    }
  }
}

.card-filters-comparable {
  height: 503px;
  margin-bottom: 0px;
}
.comparable-list-items {
  height: 416px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.orange-circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: $color-orange;
  border-radius: 50%;
  margin-right: 10px;
}
.card-custom-title {
  font-size: 12px;
  color: $gray-700;
}
</style>

<style lang="scss">
.indicators-comparable-sellers {
  .presentation-card {
    .container {
      margin-top: 0px;
      flex-direction: column;
      align-items: center;
      img {
        margin-right: 0px;
        margin-bottom: 40px;
      }

      .esc__box {
        display: flex;
        flex-direction: column;

        p {
          margin: 0 auto;
        }

        button {
          margin-top: 16px;
          width: 225px;
          align-self: center;
        }
      }
    }
  }
}
</style>
