<template>
  <div class="history__container">
    <div class="history__item">
      <label for="status">{{ $t('integrations.exportation') }}</label>
      <v-select
        :clearable="true"
        :searchable="true"
        :options="audiences"
        :placeholder="$t('integrations.type-export')"
        label="name"
        @option:selected="onChangeAudiences"
        v-model="audienceValue"
      >
        <template #open-indicator="{ attributes }">
          <i v-bind="attributes" class="icon icon-search"></i>
        </template>
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
    <div class="history__item">
      <label for="status">{{ $t('integrations.status') }}</label>
      <v-select
        :clearable="false"
        :searchable="false"
        :options="status"
        label="label"
        :placeholder="$t('integrations.select')"
        @option:selected="setStatus"
      >
        <template #option="{ label }">
          <span style="text-transform: capitalize">{{ $t(label) }}</span>
        </template>
        <template #selected-option-container="{ option }">
          <div class="vs__selected" style="text-transform: capitalize">
            {{ $t(option.label) }}
          </div>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
    <div class="history__item">
      <label for="status">{{ $t('integrations.connector') }}</label>
      <v-select
        :clearable="false"
        :searchable="false"
        :options="connectors"
        label="name"
        :placeholder="$t('integrations.all-connectors')"
        @option:selected="onChangeConnector"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
    <div class="history__item--export">
      <div class="history__export-wrapper">
        <button ref="button" class="btn" @click="toggleExport">
          {{ $t('integrations.new-export') }}
          <i class="icon icon-down-14px"></i>
        </button>
        <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
          <div
            v-for="item in exportOptions"
            :key="item.value"
            :class="['items', item.value]"
            @click="triggerSelectAction(item.value)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs, helpers
import vSelect from 'vue-select';
import vClickOutside from 'click-outside-vue3';
import { mapState, mapGetters, mapActions } from 'vuex';
import bus from '@/helpers/events/bus';

export default {
  name: 'HistoryExportSelects',
  components: {
    vSelect,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      audienceValue: '',
      isExportVisible: false,
      exportOptions: [
        {
          value: 'audience',
          title: this.$t('integrations.create-aud'),
        },
        {
          value: 'export',
          title: this.$t('integrations.create-exp'),
        },
      ],
    };
  },
  computed: {
    ...mapState('historyExport', ['status', 'connectors', 'audiences']),
    ...mapGetters('historyExport', ['getSelectStatus']),
  },
  mounted() {
    this.setConnectors();
    this.setAudiences();
    bus.$on('update-selected-brand', () => {
      this.callEventBusFunctions();
    });
    bus.$on('render-cards', () => {
      this.callEventBusFunctions();
    });
  },
  watch: {
    audienceValue() {
      if (!this.audienceValue) {
        this.setSelectedAudience({ audience: '' });
      }
    },
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
    bus.$off('render-cards');
  },
  methods: {
    ...mapActions('historyExport', [
      'setConnectors',
      'setAudiences',
      'setSelectedStatus',
      'setSelectedConnector',
      'setSelectedAudience',
    ]),
    setStatus(status) {
      if (!status) return;
      this.setSelectedStatus({ status: status.key });
    },
    onChangeConnector(connector) {
      if (!connector) return;
      this.setSelectedConnector({ connector: connector.id });
    },
    onChangeAudiences(audience) {
      this.setSelectedAudience({ audience: audience.name });
    },
    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },
    /**
     * @description check button type to open modal or redirect
     * @param {object} preset selected preset
     */
    triggerSelectAction(preset) {
      preset === 'audience' ? this.$router.push({ name: 'AudienceCreateOld' }) : bus.$emit('open-create-export-modal');
    },
    callEventBusFunctions() {
      this.setConnectors();
      this.setAudiences();
    },
  },
};
</script>

<style lang="scss" scoped>
.history__container {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  width: 800px;

  .history__item {
    display: flex;
    flex-direction: column;
    width: 190px;

    select {
      height: 36px;
    }

    &--export {
      display: flex;
      align-items: flex-end;
    }
  }

  .history__export-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-around;

    .export-container {
      position: absolute;
      top: 40px;
      z-index: 99;
      width: 100%;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);

      .items {
        background-color: $color-white;
        color: $gray-800;
        font-weight: bold;
        padding: 10px 15px;
        font-size: 12px;
        &:hover {
          background-color: $gray-300;
          cursor: pointer;
        }
      }
    }

    .btn {
      align-items: center;
      display: flex;
      height: 36px;
      justify-content: space-evenly;
      line-height: 20px;
      width: 170px;
      padding: 0 15px;

      .icon {
        font-weight: bold;
      }
    }
  }
}
</style>

<style lang="scss">
.history__container {
  @import '@/assets/scss/vendors/_v-select';

  select:disabled {
    background-color: #e3e3e3;
    color: $gray-600;
  }
}
</style>
