<template>
  <a>
    <path
      d="M17.5 23H5.5C2.5 23 0 20.5 0 17.5V5.5C0 2.5 2.5 0 5.5 0H17.5C20.5 0 23 2.5 23 5.5V17.5C23 20.5 
      20.5 23 17.5 23ZM5.5 1C3 1 1 3 1 5.5V17.5C1 20 3 22 5.5 22H17.5C20 22 22 20 22 17.5V5.5C22 3 20 1 17.5 1H5.5Z"
    />
    <circle cx="6.5" cy="6.5" r="1.5" />
    <rect x="11" y="6" width="7" height="1" />
    <circle cx="6.5" cy="11.5" r="1.5" />
    <rect x="11" y="11" width="7" height="1" />
    <circle cx="6.5" cy="16.5" r="1.5" />
    <rect x="11" y="16" width="7" height="1" />
  </a>
</template>
