<template>
  <div class="push-notifications-footer">
    <div class="container app-container-margin">
      <div class="row h-100 justify-content-end">
        <div class="push-footer__submit col-2">
          <button
            class="btn"
            :disabled="!isSubmitActive || getLoadingStatus || !getScheduleDate"
            type="submit"
            @click.prevent="submit"
          >
            <span v-if="getLoadingStatus">{{ $t('loading-dots') }}</span>
            <span v-else>{{ $t('360.push.preview.save') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { dateTimeInverseFormat } from '@/utilities/constants';
import moment from 'moment';
import bus from '@/helpers/events/bus';

export default {
  name: 'PushFooterSubmit',
  data() {
    return {
      currentTime: '',
    };
  },
  computed: {
    ...mapGetters('pushNotifications', ['getSubmitStatus', 'getLoadingStatus', 'getScheduleDate']),
    isSubmitActive() {
      return this.getSubmitStatus;
    },
  },
  methods: {
    ...mapActions('pushNotifications', ['submitPush']),
    submit() {
      this.verifyConditionToShowDateTimeError();
    },
    getCurrentTime() {
      const currentDateTime = moment().second(0).add(5, 'minutes').format(dateTimeInverseFormat);
      this.currentTime = currentDateTime;
    },

    verifyConditionToShowDateTimeError() {
      this.getCurrentTime();
      const hasStartDateTimeLessThanCurrent = this.getScheduleDate < this.currentTime;

      if (hasStartDateTimeLessThanCurrent) {
        bus.$emit('open-push-notification-start-time-error-modal');
        return;
      }
      this.submitPush();
    },
  },
};
</script>

<style lang="scss" scoped>
.push-notifications-footer {
  background-color: $color-white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  color: $color-white;
  z-index: 99;
  height: 72px;
  display: flex;
  justify-content: center;

  .push-footer__size {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .push-footer__size-text {
      margin-bottom: 3px;
      p {
        color: $color-white;
        font-weight: 600;
      }
    }

    .push-footer__customers {
      display: flex;
      align-items: center;
      flex-direction: row;

      i.icon-customer {
        font-size: 24px;
        color: $gray-600;
      }
      .push-footer__counter {
        margin: 3px 0 0 8px;
        display: flex;
        flex-direction: column;
        p {
          color: $color-white;
          .push-footer__total {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
  }

  .push-footer__submit {
    display: flex;
    justify-content: center;
    align-items: center;

    button.btn {
      width: 180px;
      height: 40px;
      transition: all ease-in 0.3s;
    }
  }
}
</style>
