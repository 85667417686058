<template>
  <section id="stores-database">
    <div v-if="ready" class="row">
      <div class="col-sm-12 col-xl-8">
        <div :class="{ loadHeight: loading }" class="card">
          <div v-if="!loading" class="card-body">
            <div class="row">
              <div class="col">
                <div class="chart-title">{{ $t('store-database-people') }}</div>
                <label class="chart-value-big color-oto-brand-omni">
                  {{ $_formatDecimal(metrics.users_total.data.value.amount) }}
                </label>
              </div>
              <div class="col">
                <div class="chart-title">{{ $tc('total-clients', 0) }}</div>
                <label class="chart-value-big color-oto-brand-omni">
                  {{ $_formatDecimal(metrics.customers_total.data.value.amount) }}
                </label>
              </div>
              <div class="col">
                <div class="chart-title">
                  {{ $t('database-active-clients') }}
                </div>
                <label class="chart-value-big color-oto-brand-omni">
                  {{ $_formatDecimal(metrics.customers_active.data.value.amount) }}
                </label>
              </div>
              <div class="col">
                <div class="chart-title">
                  {{ $t('database-clients-period') }}
                </div>
                <label class="chart-value-big color-oto-brand-omni">{{
                  $_formatDecimal(metrics.customers.data.value.amount)
                }}</label>
              </div>
            </div>
          </div>
          <store-skeleton-left v-else />
        </div>
      </div>
      <!-- Recency -->
      <div class="col">
        <div :class="{ loadHeight: loading }" class="card recency">
          <div v-if="!loading" class="card-body">
            <div class="chart-title">{{ $t('recency') }}</div>
            <label class="chart-value-big color-oto-brand-omni value">
              {{ $_formatDecimal(metrics[recency].data.value.amount, 0) }}
              {{ $tc('day', checkIsPlural(recency)) }}
            </label>
          </div>
          <store-skeleton v-else />
        </div>
      </div>
      <!-- Frequency -->
      <div class="col">
        <div :class="{ loadHeight: loading }" class="card frequency">
          <div v-if="!loading" class="card-body">
            <div class="chart-title">{{ $t('frequency') }}</div>
            <label class="chart-value-big color-oto-brand-omni value">
              {{ $_formatDecimal(metrics.frequency.data.value.amount, 2) }}
            </label>
          </div>
          <store-skeleton v-else />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import getMetrics from '@/services/getmetrics';

import bus from '@/helpers/events/bus';
import { numberMixin } from '@/helpers/mixins';

import StoreSkeleton from '@/components/stores/storeDatabaseSkeletons/StoreSkeleton';
import StoreSkeletonLeft from '@/components/stores/storeDatabaseSkeletons/StoreSkeletonLeft';

export default {
  name: 'StoresDatabase',
  components: {
    StoreSkeleton,
    StoreSkeletonLeft,
  },
  mixins: [numberMixin],
  data() {
    return {
      metrics: {},
      ready: false,
      loading: false,
    };
  },
  computed: {
    recency() {
      let metric = 'recency';
      if (this.$store.getters.recency) {
        const recency = this.$store.getters.recency.toLowerCase();
        if (recency !== 'all') metric = `recency_${recency}`;
      }
      return metric;
    },
  },
  mounted() {
    bus.$on('store-changed', () => {
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('store-changed');
  },
  methods: {
    fetch_data() {
      this.loading = true;
      getMetrics({
        endpoint: 'stores/get_metrics',
        metrics: ['users_total', 'customers_total', 'customers_active', 'customers', this.recency, 'frequency'],
        filters: `store_id:${this.$parent.filters.store}`,
      })
        .then(response => {
          this.metrics = response?.data?.metrics;
          this.ready = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkIsPlural(recency) {
      const plural = 2;
      const singular = 1;
      const value = this.metrics[recency]?.data?.value?.amount;

      if (!value) return 1;
      return parseInt(value) > 1 ? plural : singular;
    },
  },
};
</script>

<style lang="scss" scoped>
#stores-database {
  .loadHeight {
    height: 88px;
  }
}
</style>
