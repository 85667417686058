<template>
  <el-dialog v-model="isVisible" :id="'create-report-modal'" width="680">
    <form @submit.prevent="saveReport()">
      <div class="form-container">
        <h2 class="title">{{ $t('reports.add-report-modal.title') }}</h2>
        <div class="row">
          <div class="col-12 item">
            <label>{{ $t('reports.add-report-modal.report-name') }}</label>
            <input
              v-model="reportName"
              :class="['reportModalInput', { error: reportRequiredError && v$.reportName.$invalid }]"
              type="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 item">
            <label>{{ $t('reports.add-report-modal.url') }}</label>
            <input
              v-model="reportUrl"
              :class="['reportModalInput', { error: reportRequiredError && v$.reportUrl.$invalid }]"
              type="url"
              @paste="detectGoogleSlides"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 item subItem">
            <label>{{ $t('reports.add-report-modal.description') }}</label>
            <textarea v-model="reportDescription" class="reportModalInput" type="text" rows="5" />
          </div>
        </div>
        <div class="row">
          <div class="col-6 item">
            <label>{{ $t('reports.add-report-modal.width') }}</label>
            <input v-model="reportWidth" class="reportModalInput" type="text" />
          </div>
          <div class="col-6 item">
            <label>{{ $t('reports.add-report-modal.height') }}</label>
            <input v-model="reportHeight" class="reportModalInput" type="text" />
          </div>
        </div>
        <div class="actions-report">
          <div class="actions-error">
            <p v-if="reportRequiredError && (v$.reportName.$invalid || v$.reportUrl.$invalid)" class="error">
              Por favor, preencha os campos obrigatórios.
            </p>
            <p v-if="reportError" class="error">Ocorreu um erro ao submeter o formulário.</p>
          </div>
          <button class="btn" type="submit">
            {{ $t('reports.add-report-modal.save') }}
          </button>
        </div>
      </div>
    </form>
  </el-dialog>
</template>

<script>
// helpers, libs
import bus from '@/helpers/events/bus';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
// import { required } from "vuelidate/lib/validators";
// services
import { postReports } from '@/services/reports';

export default {
  name: 'CreateReportModal',
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      reportName: '',
      reportUrl: '',
      reportDescription: '',
      reportHeight: '',
      reportWidth: '',
      reportRequiredError: false,
      reportError: false,
      isVisible: false,
    };
  },
  validations() {
    return {
      reportName: {
        required,
      },
      reportUrl: {
        required,
      },
    };
  },
  mounted() {
    bus.$on('open-create-report-modal', () => {
      this.openModal();
    });
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    detectGoogleSlides(e) {
      // Extract pasted text
      let pasted;
      this.$nextTick(() => {
        pasted = e.clipboardData?.getData('text') || null;
      });

      if (pasted) {
        // Detect Google Slides embeded presentation
        const m = pasted.match('"(https://docs.google.com/presentation/d/e/[^"]+)"');

        if (!m) {
          this.reportUrl = pasted;
          return;
        }

        // Only prevent default when regex matched, otherwise allow usual copy & paste
        e.preventDefault();

        // Override report URL
        this.reportUrl = m[1];
      }

      // Override width and height with good defaults
      if (this.reportHeight === '' && this.reportWidth === '') {
        this.reportWidth = 1080;
        this.reportHeight = 708;
      }
    },
    saveReport() {
      if (this.v$.reportName.$invalid || this.v$.reportUrl.$invalid) {
        this.reportRequiredError = true;
        return;
      }
      postReports({
        name: this.reportName,
        url: this.reportUrl,
        description: this.reportDescription,
        height: this.reportHeight,
        width: this.reportWidth,
      })
        .then(res => {
          if (res.status === 200) {
            this.closeModal();
            this.resetModalData();
            bus.$emit('report-render-list');
          }
        })
        .catch(() => {
          this.reportError = true;
        });
    },
    resetModalData() {
      this.reportName = '';
      this.reportUrl = '';
      this.reportDescription = '';
      this.reportHeight = '';
      this.reportWidth = '';
      this.reportRequiredError = false;
      this.reportError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 45px;
}

.row {
  width: 100%;
  margin: 10px 0;
  display: block;

  .reportModalInput {
    &.error {
      border-color: $color-red;
    }
  }

  .item .reportModalInput {
    width: 100%;
    display: block;
  }

  .col-6 {
    display: inline-block;
  }
}

.actions-report {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .actions-error {
    display: flex;
    p {
      color: $color-red;
    }
  }

  button {
    margin-top: 40px;
    width: 45%;
    float: right;
  }
}
</style>

<style lang="scss">
#create-report-modal {
  .el-dialog__header {
    padding: 30px 30px 0 30px;
  }

  .el-dialog__body {
    padding: 0 50px;
    overflow: hidden;
    height: 530px;
  }
}
</style>
