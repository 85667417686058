<template>
  <div class="status">
    <i v-if="status === 'success'" class="icon icon-check-filled icon-18" :class="status"></i>
    <i v-if="status === 'error'" class="icon icon-attention-filled icon-18" :class="status"></i>
  </div>
</template>
<script>
export default {
  name: 'Status',
  props: {
    status: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  .icon {
    margin-right: 0px;
    font-size: 25px !important;
    &.error {
      color: $color-red;
      border-radius: 60px;
      border: 1px solid $color-red;
      padding: 2px 1px 2px 2px;
    }
    &.success {
      color: $oto-ecommerce;
      border-radius: 60px;
      border: 1px solid $oto-ecommerce;
      padding: 2px 1px 2px 2px;
    }
  }
}
</style>
