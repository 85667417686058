<template>
  <base-card-v2 id="audience-header" class="pl-0 pb-0">
    <div class="ah__form-group">
      <div>
        <label>{{ $t('audiences.name-label') }}</label>
        <input
          v-model="localName"
          v-focus
          type="text"
          :placeholder="$t('audiences.define-name')"
          :readonly="isView"
          maxlength="100"
          data-cy="form-audience-name"
        />
      </div>
      <div v-if="IsDescriptionAvailable()" class="ah__textarea-wrapper">
        <div>
          <label>
            {{ isView && localDescription ? $t('audiences.description-audience') : $t('audiences.describe-audience') }}
          </label>
        </div>
        <textarea
          v-model="localDescription"
          name="message"
          cols="30"
          rows="3"
          data-cy="form-audience-description"
          :placeholder="$tc('360.campaigns.details.placeholder')"
          :readonly="edit && isView"
        ></textarea>
        <div v-if="isView && creator === user.id" class="d-flex">
          <div v-if="edit">
            <a href="#" @click="editDescription()">{{ $t('edit') }}</a>
          </div>
          <div v-else>
            <a href="#" @click="newDescription()">{{ $t('save') }}</a>
          </div>
        </div>
      </div>
    </div>
    <steps-controller />
  </base-card-v2>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import AudienceStepsController from '@/components/audiences/create/AudienceStepsController';
import { renameAudience, getDescription } from '@/services/audiences';
import store from '@/store';

export default {
  components: {
    'steps-controller': AudienceStepsController,
  },
  data() {
    return {
      edit: true,
      image: require('@/assets/img/figures/genericOtoBgBig.png'), // eslint-disable-line global-require
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  computed: {
    ...mapGetters('audience', ['isView', 'query']), // -> this.isView, this.query
    ...mapGetters(['getHasCampaignApproval']),
    ...mapState('audience', ['name', 'description', 'reference', 'creator']), // -> this.name, this.description, this.reference
    user() {
      return store.getters.user;
    },
    localName: {
      get() {
        return this.name;
      },
      set(newValue) {
        this.$store.dispatch('audience/setName', newValue);
      },
    },
    localDescription: {
      get() {
        return this.description;
      },
      set(newValue) {
        this.$store.dispatch('audience/setDescription', newValue);
      },
    },
  },
  methods: {
    editDescription() {
      return (this.edit = false);
    },

    newDescription() {
      renameAudience(this.reference, this.name, this.description);
      getDescription(this.reference);
      return (this.edit = true);
    },

    IsDescriptionAvailable() {
      return (!this.creator || this.description || this.creator === this.user.id) && this.getHasCampaignApproval;
    },
  },
};
</script>
<style lang="scss" scoped>
#audience-header {
  margin: 20px 0 40px;
  .ah__form-group {
    padding-left: 40px;
    display: flex;
    height: 96px;
  }

  input {
    width: 330px;
  }

  .ah__textarea-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    width: 700px;

    textarea {
      width: 100%;
      height: 72px;
      border-radius: 15px;
    }

    a {
      color: #6c90f4;
      text-decoration: underline;
      font-size: 12px;
    }
  }
}
</style>
