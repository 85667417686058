<template>
  <div class="persona-item" :class="`${level} ${active}`">
    <div v-if="persona.id !== 0" class="persona-image" :style="generateBgColor()">
      <img src="@/assets/svgs/GenericPersonaPicture.svg" />
    </div>
    <div v-else class="persona-image -allBase">
      <img src="@/assets/svgs/Database.svg" />
    </div>
    <div class="persona-name">
      <p>{{ persona.name }}</p>
      <img v-if="active" src="@/assets/svgs/CheckActive.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PersonaItem',
  props: {
    persona: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: '-nested',
    },
    active: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      getAllSelecteds: 'personas/getAllSelecteds',
    }),
  },
  methods: {
    generateBgColor() {
      return `background-color: #${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.persona-item {
  display: flex;
  align-items: center;
  .persona-image {
    text-align: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center right;
    overflow: hidden;
    &.-allBase {
      img {
        height: 80%;
      }
    }
  }
  .persona-name {
    width: 150px;
    margin-left: 7px;
    justify-content: space-between;
    display: flex;
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: auto;
      margin-bottom: 0;
    }
  }
  &.-selected,
  &.-active {
    p {
      font-weight: 600;
      width: 130px;
    }
  }
  &.-nested {
    border-bottom: 1px solid #eeeeee;
    padding: 10px 0;
    .persona-image {
      width: 25px;
      height: 25px;
      img {
        width: 21px;
      }
      &.-allBase {
        width: 25px;
        height: 20px;
        overflow: visible;
        img {
          width: auto;
          position: relative;
          height: 120%;
          top: -4px;
        }
      }
    }
  }
}
</style>
