
// libs
import { reactive, toRefs, defineComponent, PropType } from 'vue';
// components
import CustomerRankingTitle from './CustomerRankingTitle/CustomerRankingTitle.vue';
import CustomerRankingLink from './CustomerRankingLink/CustomerRankingLink.vue';
import CustomerRankingChartList from './CustomerRankingChartList/CustomerRankingChartList.vue';
// types
import { StoresType, CustomerRankingDataTypes } from '@/features/SalesRanking/types';

export default defineComponent({
  name: 'CustomerRanking',
  components: {
    CustomerRankingTitle,
    CustomerRankingLink,
    CustomerRankingChartList,
  },
  props: {
    stores: {
      type: Array as PropType<StoresType[]>,
      required: true,
    },
  },
  setup() {
    const events: CustomerRankingDataTypes = reactive({
      selectedStore: '-100',
      objectChartListTopCustomers: [],
    });

    function storeChanged(value: string) {
      events.selectedStore = value;
    }

    return {
      ...toRefs(events),
      storeChanged,
    };
  },
});
