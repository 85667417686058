<template>
  <div id="product-best-seller-skeleton" class="table-list col-12" :data="data" :type="type" :loading="true">
    <table-list-header class="p-bottom">
      <table-list-column class-name="col-6 p-0">
        <skeleton-loader width="200px" height="14px" />
      </table-list-column>
      <table-list-column v-for="item in 3" :key="item" class-name="text-right col-2">
        <skeleton-loader width="85px" height="14px" />
      </table-list-column>
    </table-list-header>
    <table-list-body :remove-last-line="true">
      <table-list-row v-for="row in rows" :key="row">
        <table-list-column class-name="text-left col-6 pl-0 pr-0">
          <skeleton-loader width="100px" height="14px" />
        </table-list-column>
        <table-list-column v-for="item in 3" :key="item" class-name="text-right col-2">
          <skeleton-loader width="65px" height="14px" />
        </table-list-column>
      </table-list-row>
      <div class="bottom-row d-flex">
        <skeleton-loader width="140px" height="14px" />
      </div>
    </table-list-body>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    rows() {
      const length = this.data.limit;
      const arr = new Array(length);

      for (let i = 0; i < length; i += 1) {
        arr[i] = (i + 1).toString();
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-bottom {
  padding-bottom: 22px;
}
.bottom-row {
  padding: 14px 20px;
  justify-content: flex-end;
}
</style>
