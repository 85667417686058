<template>
  <div id="persona-detail">
    <header-details
      :path="'personas'"
      :vuex-detail="personaDetail"
      :action-set-name="setName"
      :action-set-group="setGroup"
      :has-group="true"
      edit-description-emitter="openEditPersonaDescriptionModal"
    />
    <div class="audience-composition">
      <div>
        <section-include :is-include="true" />
        <section-include :is-include="false" />
      </div>
    </div>
    <!-- footer -->
    <personas-footer />
    <!-- modals -->
    <dialog-error />
    <dialog-success />
    <edit-description-modal />
    <save-label-modal />
    <personas-labels-list-modal />
  </div>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
import { mapState, mapActions } from 'vuex';
// components - molecules
import HeaderDetails from '@/components/_molecules/HeaderDetails';
// components - organisms
import DialogError from '@/components/personas/_organisms/DialogError';
import DialogSuccess from '@/components/personas/_organisms/DialogSuccess';
import EditDescriptionModal from '@/components/personas/_organisms/EditDescriptionModal';
import SaveLabelModal from '@/components/personas/_organisms/SavePersonaModal';
import PersonasFooter from '@/components/personas/_organisms/PersonasFooter';
import PersonasLabelsListModal from '@/components/personas/_organisms/PersonasLabelsListModal';
// components - templates
import SectionInclude from '@/components/personas/templates/SectionInclude';

export default {
  name: 'PersonasDetail',
  components: {
    DialogError,
    DialogSuccess,
    HeaderDetails,
    SectionInclude,
    EditDescriptionModal,
    SaveLabelModal,
    PersonasFooter,
    PersonasLabelsListModal,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    ...mapState('personas', ['personaDetail']),
  },
  mounted() {
    this.setMode(this.mode);
    this.actionAddAvailableLabels();
    this.updateVuex();
    bus.$on('update-selected-brand', () => {
      this.actionResetCheckAccount360();
      this.updateVuex();
    });
  },
  methods: {
    ...mapActions('personas', [
      'getPersona',
      'setMode',
      'actionCheckAccount360',
      'actionResetCheckAccount360',
      'actionSetName',
      'actionSetGroup',
      'actionAddAvailableLabels',
      'addGroup',
    ]),
    updateVuex() {
      this.getPersona(this.id);
      if (this.mode === 'create') {
        this.addGroup();
      }
      this.actionCheckAccount360();
    },
    setName(e) {
      this.actionSetName(e.target.value);
    },
    setGroup(e) {
      this.actionSetGroup(e?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-include,
.groups-exclude {
  margin-top: 35px;
}
.group-list {
  margin-bottom: 20px;
}
.audience-include-exclude {
  margin-bottom: 40px;
}
.empty-query {
  text-align: center;
  padding-top: 50px;
  margin: 0 auto;
  .btn {
    padding: 0 40px;
  }
}
</style>
