<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <base-card-v2 class="category-stores">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('stores-title')" icon="icon-cart" />
      <div class="row">
        <div class="col-6">
          <minibarchart :prop-data="jsonHighestStore"></minibarchart>
        </div>
        <div class="col-6">
          <minibarchart :prop-data="jsonLowestStore"></minibarchart>
        </div>
      </div>
      <bottom-button :typecard="'card'">
        <template v-slot:bottom-button>
          <label
            class="link color-oto-brand-omni col-auto text-right mr-3"
            @click="openLightbox('lightbox-category-stores')"
            >{{ $t('categories.orders.see-all-stores') }}</label
          >
        </template>
      </bottom-button>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import { getTable } from '@/services/categories';
import { openLightbox } from '@/helpers/lightbox';
import MiniBarChart from '@/components/MiniBarChart';
import BottomButton from '@/components/_atoms/BaseCardBottomButton';
import miniBarChartMixin from '@/helpers/mixins/miniBarChartMixin';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import verifyThensMixin from '@/helpers/mixins/verifyThensMixin';

export default {
  name: 'ConversionSalesStoresBrands',
  components: {
    minibarchart: MiniBarChart,
    BottomButton,
    BaseLoader,
    InfoCard,
  },
  mixins: [miniBarChartMixin, verifyMixin, verifyThensMixin],
  props: {
    category: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      loader: {
        loadingHighest: false,
        loadingLowest: false,
      },
      ready: false,
      dataReturn: {},
      jsonHighestStore: [],
      jsonLowestStore: [],
    };
  },
  created() {
    return this.fetch_data();
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetch_data() {
      this.componentHasData = [];

      this.$_reqConfig();
      this.loader.loadingHighest = true;
      this.loader.loadingLowest = true;

      getTable({
        name: this.category,
        table: 'store_revenue',
        order_by: 'revenue_desc',
        limit: 5,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.jsonHighestStore = this.$_mountMiniBarTotalRange(
            'blue',
            this.$t('highest-revenue-stores'),
            data.data,
            data.value_total,
          );
        })
        .catch(() => {
          // this.dataReturn = {};
          this.jsonHighestStore = [];
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingHighest = false;
          this.$_verifLoading();
        });
      getTable({
        name: this.category,
        table: 'store_revenue',
        limit: 5,
        order_by: 'revenue',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.jsonLowestStore = this.$_mountMiniBarTotalRange(
            'red',
            this.$t('lowest-revenue-stores'),
            data.data,
            data.value_total,
          );
        })
        .catch(() => {
          // this.dataReturn = {};
          this.jsonLowestStore = [];
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingLowest = false;
          this.$_verifLoading();
        });
    },
    openLightbox(id) {
      openLightbox(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-stores {
  height: 270px;
}
</style>
