import { getIndicators } from '../services';
import { IndicatorsResponse, RemoteIndicatorsResponse, IdicatorsParams } from '../models';

export const fetchIndicators = ({
  campaignId,
  sellerId,
  storeId,
  cluster,
}: IdicatorsParams): Promise<IndicatorsResponse> =>
  getIndicators({ campaignId, sellerId, storeId, cluster }).then(response => formatResponse(response?.data));

const formatResponse = (response: RemoteIndicatorsResponse): IndicatorsResponse => {
  const remoteKpis = response?.kpis;
  const {
    proactive_contacts,
    proactive_with_order,
    suggestions_available,
    suggestions_used,
    suggestions_with_order,
    talked,
  } = remoteKpis;

  return {
    errors: response?.errors,
    success: response?.success,
    kpis: {
      proactiveContacts: {
        name: proactive_contacts?.name,
        key: proactive_contacts?.key,
        tooltip: proactive_contacts?.tooltip,
        value: proactive_contacts?.value,
      },
      proactiveWithOrder: {
        name: proactive_with_order?.name,
        key: proactive_with_order?.key,
        percent: proactive_with_order?.percent,
        tooltip: proactive_with_order?.tooltip,
        value: proactive_with_order?.value,
      },
      suggestionsWithOrder: {
        name: suggestions_with_order?.name,
        key: suggestions_with_order?.key,
        percent: suggestions_with_order?.percent,
        tooltip: suggestions_with_order?.tooltip,
        value: suggestions_with_order?.value,
      },
      suggestionsAvailable: {
        name: suggestions_available?.name,
        key: suggestions_available?.key,
        tooltip: suggestions_available?.tooltip,
        value: suggestions_available?.value,
      },
      suggestionsUsed: {
        name: suggestions_used?.name,
        key: suggestions_used?.key,
        percent: suggestions_used?.percent,
        tooltip: suggestions_used?.tooltip,
        value: suggestions_used?.value,
      },
      talked: {
        name: talked?.name,
        key: talked?.key,
        tooltip: talked?.tooltip,
        value: talked?.value,
      },
    },
    grafics: {
      labels: response?.grafics?.labels,
      metrics: {
        suggestionsUsed: response?.grafics?.metrics?.Suggestions_used,
        percentageSuggestionsUsed: response?.grafics?.metrics?.percentage_suggestions_used,
        suggestionsAvailable: response?.grafics?.metrics?.suggestions_available,
      },
    },
  };
};
