import axios from 'axios';
import moment from 'moment';
import store from '@/store';

const PRODUCT_URL = '/products';

/**
 * Search products by term
 * @param {string} term Product's name or id to search for
 * @param {boolean} full Enables the product's full search
 * @param {number} limit Maximum number of results to return
 * @param {number} page Pagination. Used for product's full search (Optional)
 */

export const search = ({ term = '', full = false, limit = 6, page = '', filterId = store.getters.selectedFilter }) => {
  let { startDate } = store.getters.dates;
  let { endDate } = store.getters.dates;
  const brand = store.getters.selectedBrand;
  const persona = store.getters.selectedPersona;

  if (startDate && endDate) {
    startDate = moment(startDate).format('YYYY-MM-DD');
    endDate = moment(endDate).format('YYYY-MM-DD');
  }

  return axios.get(`${PRODUCT_URL}/search`, {
    params: {
      brand: brand.id,
      persona: persona.id,
      start_date: startDate,
      end_date: endDate,
      search: term,
      order_filters: filterId,
      full: full ? 1 : 0,
      limit,
      ...(page && { page }),
    },
  });
};

/**
 * Get details, photo and overview of product
 * @param {number} id Product id
 */
export const getOverview = (id, filterId = store.getters.selectedFilter) => {
  let { startDate } = store.getters.dates;
  let { endDate } = store.getters.dates;
  const brand = store.getters.selectedBrand.id;
  const persona = store.getters.selectedPersona;

  if (startDate && endDate) {
    startDate = moment(startDate).format('YYYY-MM-DD');
    endDate = moment(endDate).format('YYYY-MM-DD');
  }

  return axios.get(`${PRODUCT_URL}/get_product_overview`, {
    params: {
      brand,
      persona: persona.id,
      product_id: id,
      start_date: startDate,
      end_date: endDate,
      order_filters: filterId,
    },
  });
};

/**
 * Get life cycle infos. Used for graphic, orders and prices details
 * @param {number} id Product id
 */
export const getLifeCycle = (id, filterId) => {
  const brand = store.getters.selectedBrand.id;
  const persona = store.getters.selectedPersona;

  return axios.get(`${PRODUCT_URL}/get_product_lifecycle`, {
    params: {
      brand,
      persona: persona.id,
      product_id: id,
      order_filters: filterId,
    },
  });
};

/**
 * Get table structure
 * @param {number} productID Product id
 * @param {string} locale Language
 * @param {number} page Pagination
 * @param {number} limit Maximum number of results to return
 * @param {string} table Table's name to consult
 * @param {string} orderBy Column's name to order
 */
export const getTable = ({
  productID = '',
  locale = 'pt-BR',
  page = '',
  limit = '',
  table = '',
  orderBy = '',
  expand = 0,
  startDate = '' || store.getters.dates.startDate,
  endDate = '' || store.getters.dates.endDate,
  filterId = store.getters.selectedFilter,
} = {}) => {
  const { user } = store.getters;
  const brand = store.getters.selectedBrand;
  const persona = store.getters.selectedPersona;
  let startDateVal;
  let endDateVal;

  if (startDate && endDate) {
    startDateVal = moment(startDate).format('YYYY-MM-DD');
    endDateVal = moment(endDate).format('YYYY-MM-DD');
  }

  return axios.get(`${PRODUCT_URL}/get_table`, {
    params: {
      brand: brand.id,
      persona: persona.id,
      start_date: startDateVal,
      end_date: endDateVal,
      locale: user.language || locale,
      product_id: productID,
      order_filters: filterId,
      tbl: table,
      ...(page && { page }),
      ...(limit && { limit }),
      ...(orderBy && { orderby: orderBy }),
      ...(expand && { expand }),
    },
  });
};

/**
 * Get metrics data
 * @param {array} metrics
 * @param {number} productID Product id
 * @param {number} topvalues Quantity of top values (optional)
 * @param {string} filters Maximum number of results to return (optional)
 * @param {string} locale Language (optional)
 */
export const getMetrics = ({
  metrics = '',
  productID = '',
  topvalues = '',
  filters = '',
  locale = 'pt-BR',
  filterId = store.getters.selectedFilter,
} = {}) => {
  const { user } = store.getters;
  let { startDate } = store.getters.dates;
  let { endDate } = store.getters.dates;
  const brand = store.getters.selectedBrand;
  const persona = store.getters.selectedPersona;

  if (startDate && endDate) {
    startDate = moment(startDate).format('YYYY-MM-DD');
    endDate = moment(endDate).format('YYYY-MM-DD');
  }

  return axios.get(`${PRODUCT_URL}/get_metrics`, {
    params: {
      locale: user.language || locale,
      brand: brand.id,
      persona: persona.id,
      m: metrics.toString(),
      start_date: startDate,
      end_date: endDate,
      product_id: productID,
      order_filters: filterId,
      ...(filters && { filter: filters }),
      ...(topvalues && { top: topvalues }),
    },
  });
};

export default search;
