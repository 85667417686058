<template>
  <div class="audience-historic-skeleton">
    <skeleton-loader height="20px" width="219px" class="mb-2" />

    <table-list id="audience-historic-skeleton" :data="table" :type="type" :loading="true">
      <table-list-header>
        <table-list-column class-name="text-center col-1">
          <skeleton-loader width="40px" height="20px" />
        </table-list-column>
        <table-list-column class-name="text-center col-2">
          <skeleton-loader width="70px" height="20px" />
        </table-list-column>
        <table-list-column class-name="col-3">
          <skeleton-loader width="80px" height="20px" />
        </table-list-column>
        <table-list-column class-name="col-2">
          <skeleton-loader width="60px" height="20px" />
        </table-list-column>
        <table-list-column class-name="col-2">
          <skeleton-loader width="120px" height="20px" />
        </table-list-column>
        <table-list-column class-name="text-right col-2">
          <skeleton-loader width="110px" height="20px" />
        </table-list-column>
      </table-list-header>
      <table-list-body>
        <table-list-row v-for="(row, index) in table.rows" :key="index">
          <table-list-column class-name="text-center col-1 pl-0 pr-0">
            <skeleton-loader width="80px" height="20px" />
          </table-list-column>
          <table-list-column class-name="text-center col-2">
            <skeleton-loader width="50px" height="20px" />
          </table-list-column>
          <table-list-column class-name="col-3">
            <skeleton-loader width="160px" height="20px" />
          </table-list-column>
          <table-list-column class-name="col-2">
            <skeleton-loader width="50px" height="20px" />
          </table-list-column>
          <table-list-column class-name="col-2">
            <skeleton-loader width="170px" height="20px" />
          </table-list-column>
          <table-list-column class-name="text-right col-2">
            <skeleton-loader width="50px" height="20px" />
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </table-list>
  </div>
</template>
<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    table: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
};
</script>
