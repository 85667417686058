import moment from 'moment';

/**
 * @description return an object with the start and end date from date now
 * @param {String} datePattern last-month / last-six-months / last-year
 * @param {String} dateFormat defalt date format
 * @returns {Object}
 */
export const getPreviousDateFormat = (datePattern, dateFormat = 'YYYY-MM-DD') => {
  const pattern = datePattern;
  const now = moment();
  const startDate = now.format(dateFormat);
  let endDate = '';

  switch (pattern) {
    case 'last-month':
      endDate = now.subtract(1, 'month').format(dateFormat);
      break;
    case 'last-six-months':
      endDate = now.subtract(6, 'months').format(dateFormat);
      break;
    case 'last-year':
      endDate = now.subtract(1, 'year').format(dateFormat);
      break;
    default:
      break;
  }

  return {
    startDate,
    endDate,
  };
};
