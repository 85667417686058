<template>
  <lightbox-template
    id="lightbox-orders"
    :title="`${$tc('store', 0)} / ${settings.storeSelected.store}`"
    show-segment-group
  >
    <orders-chart :store-id="storeId" />
    <orders-table :store-id="storeId" />
  </lightbox-template>
</template>

<script>
import lightboxesMixin from '@/helpers/mixins/lightboxesMixin';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import OrdersChart from '@/components/lightbox/lightboxes/_organisms/orders/OrdersChart';
import OrdersTable from '@/components/lightbox/lightboxes/_organisms/orders/OrdersTable';

export default {
  components: {
    LightboxTemplate,
    OrdersChart,
    OrdersTable,
  },
  mixins: [lightboxesMixin],
  data() {
    return {
      storeId: '',
    };
  },
  beforeMount() {
    this.storeId = this.settings.storeSelected.id;
  },
};
</script>

<style lang="scss" scoped>
.loadHeight {
  min-height: 250px;
  position: relative;
}
</style>
