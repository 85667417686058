import bus from '@/helpers/events/bus';

const body = document.querySelector('body');

export const openLightbox = (id, settings) => {
  body.classList.add('overflow-hidden');

  bus.$emit('changeActiveLightbox', { lightbox: id, settings: settings });
};

export const closeLightbox = () => {
  body.classList.remove('overflow-hidden');

  bus.$emit('changeActiveLightbox', { lightbox: null });
};

export default openLightbox;
