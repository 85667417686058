<template>
  <div id="comparison-criteria" class="form-group mt-3">
    <label>{{ $t('360.sellers-analytics.comparable.criteria') }}</label>
    <div id="selectCriteria" class="select-fake criteria no-click" @click.prevent="toggleDropdown('criteriaOpened')">
      {{ $t(`stores.criteria-selection-${criteriaSelectedName}`) }}
    </div>
    <div v-show="criteriaOpened" class="check-list dropdown-menu no-click">
      <div class="list no-click">
        <div v-for="option in criteriaOptions" :key="option.value" class="select no-click">
          <label class="no-click" @click.prevent="toggleCriteria(option.value)">
            <check-box class="no-click" :checked="option.value === criteriaSelectedName" />
            <div class="text no-click">
              <span class="name no-click">{{ $t(option.label) }}</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import CheckBox from '@/components/_atoms/CheckBox';
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'ComparisonCriteria',
  components: {
    'check-box': CheckBox,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    setCriteriaName: {
      type: Function,
      required: () => true,
    },
  },
  data() {
    return {
      criteriaOptions: [
        { label: 'stores.criteria-selection-cluster', value: 'cluster' },
        { label: 'stores.criteria-selection-region', value: 'region' },
      ],
      clustersSelected: this.$t('select'),
      regionsSelected: this.$t('select'),
      criteriaSelectedName: 'cluster',
      criteriaSelected: true,
      criteriaOpened: false,
      selectedClusters: [],
      selectedRegions: [],
      selectedStates: [],
    };
  },
  watch: {
    criteriaSelectedName() {
      this.clustersSelected = this.$t('select');
      this.regionsSelected = this.$t('select');
      this.selectedClusters = [];
      this.selectedRegions = [];
      this.selectedStates = [];
    },
  },
  mounted() {
    this.bodyClick();
  },
  updated() {
    this.$nextTick(() => {
      this.watchSelectedClusters();
      this.watchSelectedRegions();
    });
  },
  methods: {
    watchSelectedClusters() {
      if (!this.selectedClusters.length) {
        this.clustersSelected = this.$t('select');
        return;
      }
      const sortedClusters = [...this.selectedClusters].sort();
      const listString = sortedClusters.toString().split(',').join(', ');
      this.clustersSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
    watchSelectedRegions() {
      if (!this.selectedRegions.length) {
        this.regionsSelected = this.$t('select');
        return;
      }
      const listString = this.selectedRegions.toString().split(',').join(', ');
      this.regionsSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
    toggleDropdown(current) {
      this[current] = !this[current];

      this.name ?? useMixpanelConfig(`view_oto_${this.name}_comparable-filters_criteria-opened`);
    },
    toggleCriteria(criteriaName) {
      if (criteriaName !== this.criteriaSelectedName) {
        this.criteriaSelected = !this.criteriaSelected;
        this.criteriaSelectedName = criteriaName;

        this.setCriteriaName(criteriaName);
      }
    },
    bodyClick() {
      document.querySelector('body').addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.criteriaOpened = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
.dropdown-menu {
  display: block;
  .searchStore {
    margin: 5px auto 18px;
    position: relative;
    max-width: 236px;
    input {
      width: 100%;
    }
    .icon-search {
      position: absolute;
      color: $gray-500;
      right: 5px;
      top: 2px;
    }
  }
}
.check-list {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  background-color: $color-white;
  padding: 18px 15px;
  position: absolute;
  width: 223px;
  z-index: 98;
  top: 58px;
  border: 0;
  margin: 0;
  left: 0;
  &.hide {
    display: none;
  }
  .select {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    label {
      grid-template-columns: 16px 1fr;
      align-items: center;
      user-select: none;
      cursor: pointer;
      display: grid;
      margin: 0;
      &:hover {
        .check-box:not(.checked) {
          border-color: $gray-600;
        }
      }
      .text {
        color: $gray-700;
        margin-left: 10px;
        .name {
          line-height: 15px;
          font-weight: 400;
          font-size: 12px;
          display: block;
        }
      }
    }
  }

  > .select {
    &:hover {
      .check-list-child {
        visibility: visible;
      }
    }
    label {
      grid-template-columns: 16px 1fr 25px;
    }
    .icon-right-14px {
      font-size: 10px;
    }
  }
  .check-list-child {
    transition: all 0.25s cubic-bezier(0.1, 0.65, 0.5, 1);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    background-color: $color-white;
    transition-delay: 0.3s;
    position: absolute;
    visibility: hidden;
    max-height: 315px;
    overflow: hidden;
    display: block;
    width: 285px;
    padding: 10px;
    left: 200px;
    top: 0;
    .list {
      max-height: 295px;
      overflow-y: auto;
      padding: 5px;
      .state .name {
        font-weight: 600;
      }
    }
    .check-list-subchild {
      padding: 12px 14px 12px 25px;
    }
  }
}
.check-list,
.list {
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $gray-300;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }
}
.select-fake {
  padding-right: 25px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}
</style>
