<template>
  <div id="dashboard">
    <dashboard-server-calls />
    <dashboard-tags-metrics-group />
    <base-loader v-show="loadingExport" color="colored-block" class="render-loader"></base-loader>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import DashboardServerCalls from '@/components/dashboard/serverCalls/DashboardServerCalls';
import DashboardTagsMetricsGroup from '@/components/dashboard/DashboardTagsMetricsGroup';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'RockinTags',
  components: {
    DashboardServerCalls,
    DashboardTagsMetricsGroup,
    BaseLoader,
  },
  data() {
    return {
      dashUrl: this.verifProd(),
      isAlertActive: true,
      loadingExport: false,
    };
  },
  mounted() {
    useMixpanelConfig('view_performance_rockinTags');
    // Show warning just once and hide after the first view
    !localStorage.getItem('ctx-dash-alert')
      ? localStorage.setItem('ctx-dash-alert', true)
      : (this.isAlertActive = false);

    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
  },
  beforeUnmount() {
    bus.$off('render-page-loader');
  },
  methods: {
    verifProd() {
      return process.env.NODE_ENV === 'development'
        ? 'https://context-dev.pmweb.com.br'
        : 'https://dashboard.pmweb.com.br/';
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard {
  .render-loader {
    background-color: #232642;
    height: 100%;
    left: 50%;
    opacity: 0.7;
    overflow: hidden;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 9999;
  }
}
</style>
