<template>
  <base-card-v2 class="age-gender-card pb-1" :class="expanded ? 'opened' : 'closed'">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('age-gender-title')" icon="icon-customer" />
      <div class="row justify-content-center">
        <div class="col-5 p-0 text-right">
          <label class="chart-title d-block">{{ $t('male') }}</label>
          <label class="mr-1"
            ><small class="bar-chart-text"> {{ $_formatDecimal(totalAmountMale) }}</small>
          </label>
          <label v-if="dataReturn[0]" class="chart-value-big d-inline color-oto-brand-omni">
            {{ $_formatNumber(dataReturn[0].value, '0.0%') }}
          </label>
        </div>
        <div class="p-0" style="width: 15px"></div>
        <div class="col-5 p-0">
          <label class="chart-title d-block">{{ $t('female') }}</label>
          <label v-if="dataReturn[1]" class="chart-value-big d-inline color-blue-800">
            {{ $_formatNumber(dataReturn[1].value, '0.0%') }}
          </label>
          <label class="ml-1"
            ><small class="bar-chart-text"> {{ $_formatDecimal(totalAmountFemale) }}</small>
          </label>
        </div>
      </div>
      <div class="row chart-group">
        <div class="col-4 pl-0 pr-0">
          <chart-list :rows-list="chartMale()" preset="subtitle" direction="left" />
        </div>
        <div class="col-4 pl-0 pr-0">
          <div v-for="(item, index) in categories" :key="`rangeage-${index}`" class="rangeAge">
            <label class="text-center textRangeAge">{{ item }}</label>
          </div>
        </div>
        <div class="col-4 pl-0 pr-0">
          <chart-list :rows-list="chartFemale()" preset="subtitle" direction="right" />
        </div>
      </div>
      <button
        v-if="canExpand"
        type="button"
        class="expand-button"
        :class="expanded ? 'opened' : 'closed'"
        @click="expanded = !expanded"
      >
        {{ expandTitle }}
      </button>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
// @ is an alias to /src
import _ from 'lodash';
import getMetrics from '@/services/getmetrics';
import { getPercentual, getMax } from '@/utilities/numbers';
import { formatPercent } from '@/utilities/formatters';
import bus from '@/helpers/events/bus';
import ChartList from '@/components/_organisms/ChartList';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  components: {
    ChartList,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  props: {
    filterStore: {
      type: Boolean,
      default() {
        return false;
      },
    },
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ready: false,
      dataReturn: {},
      categories: [],
      canExpand: true,
      expanded: false,
      selectedStore: '-100',
    };
  },
  computed: {
    // TODO - to filter by gender name when API return a name compatible with all languages
    totalAmountMale() {
      if (!this.dataReturn[0] || !this.dataReturn[0].data) return 0;

      const total = Object.values(this.dataReturn[0].data).reduce((t, n) => t + n);
      return total;
    },
    totalAmountFemale() {
      if (!this.dataReturn[1] || !this.dataReturn[1].data) return 0;

      const total = Object.values(this.dataReturn[1].data).reduce((t, n) => t + n);
      return total;
    },
    expandTitle() {
      return this.expanded ? this.$t('reduce-card') : this.$t('expand-card');
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        if (this.filterStore === false) {
          this.fetch_data();
          bus.$on('render-cards', () => {
            this.fetch_data();
          });
        }
      }
    },
  },
  mounted() {
    bus.$on('store-changed', value => {
      // used by Stores report
      this.selectedStore = value;
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('store-changed');
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'arr_gender_agerange',
        ...(this.filterStore && {
          endpoint: 'stores/get_metrics',
          filters: `store_id:${this.selectedStore}`,
        }),
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) return;

          const arrGender = data.metrics.arr_gender_agerange;
          if (arrGender.has_data) {
            this.dataReturn = arrGender.data.series;
            this.categories = arrGender.categories;

            // get age quantity
            const quantity = this.categories.length;
            // logic to show expand button - (> 7 results or > 5 results in Stores report)
            if ((!this.filterStore && quantity > 7) || (this.filterStore && quantity > 5)) this.canExpand = true;
            else this.canExpand = false;
          } else {
            this.reset();
          }

          this.ready = true;
        })
        .catch(() => {
          this.reset();
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    chartMale() {
      if (!this.dataReturn.length) return;
      const maleData = this.dataReturn[0].data;
      const max = getMax(maleData);
      return _.values(maleData).map(value => ({
        progress: String(getPercentual(value, max)),
        value: formatPercent(value / this.totalAmountMale),
        color: 'lightblue',
      }));
    },
    chartFemale() {
      if (!this.dataReturn.length) return;
      const femaleData = this.dataReturn[1].data;
      const max = getMax(femaleData);
      return _.values(femaleData).map(value => ({
        progress: String(getPercentual(value, max)),
        value: formatPercent(value / this.totalAmountFemale),
        color: 'darkblue',
      }));
    },
    reset() {
      this.dataReturn = {};
      this.categories = [];
      this.expanded = false;
      this.canExpand = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.age-gender-card {
  overflow: hidden;
  min-height: 200px;

  &.closed {
    height: 290px;
  }

  &.opened {
    padding-bottom: 40px !important;
  }
}

.title {
  height: 40px;
  font-size: 0.8em;
  font-weight: bold;
}

.rangeAge {
  height: 12px;
  margin: 8px 0;
  font-size: 0.8em;
  align-items: center;
}

.textRangeAge {
  width: 100%;
  margin-top: -3px;
}

.expand-button {
  bottom: 0;
  width: 100%;
  position: absolute;
  left: 0;
  color: $gray-700;
  border: none;
  font-weight: 700;
  font-size: 12px;
  padding: 10px 0;
  cursor: pointer;
  padding-top: 20px;
  background-color: transparent;
  background-image: filter-gradient(#ffffff, #ffffff, vertical); // IE6-9
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 100%);
  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: 0.255em;
    content: '';
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-bottom: 0;
    border-left: 4px solid transparent;
  }
  &.opened:after {
    border-top: 0;
    border-bottom: 4px solid;
  }
  &:focus {
    outline: 0 none;
  }
}
</style>
