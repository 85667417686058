<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute  -->
  <base-card-v2 class="category-visualizationBySegment" :class="{ loadHeight: loading || isEmpty || hasError }">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('categories.client.view-by-segment')" icon="icon-money">
        <template v-slot:item-right>
          <select id="option" v-model="selectedOpt" class="float-right ml-auto" @change="switchChart">
            <option v-for="(option, index) in selectItems" :key="index" :value="option.value">
              {{ option.title }}
            </option>
          </select>
        </template>
      </base-card-header>
      <div id="chart-category-visualizationBySegment" style="min-width: 360px; margin: 0 auto"></div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import Highcharts from 'highcharts';
import { mapGetters } from 'vuex';
import { getCategoryMetrics } from '@/services/categories';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import { formatCurrency, formatPercent, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  name: 'CategoryVisualizationBySegment',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    category: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      chart: {},
      segmentLabel: this.$t('categories.client.revenue'),
      chartConfig: {},
      categories: [],
      segmentAverage: [],
      segmentValue: [],
      valueTotal: undefined,
      dataType: undefined,
      arrValue: [],
      selectedOpt: 'revenue',
      selectItems: [
        {
          value: 'revenue',
          title: this.$t('categories.client.revenue'),
          colorBase: '#6C90F4',
          colorBackground: '#365B6B20',
        },
        {
          value: 'customer',
          title: this.$t('categories.client.customer'),
          colorBase: '#36AAD6',
          colorBackground: '#36AAD620',
        },
        {
          value: 'product',
          title: this.$t('categories.client.product'),
          colorBase: '#FA6F32',
          colorBackground: '#FA6F3220',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['selectedFilter']),
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', () => {
      // validation to just render if is on the correct page
      const getBreadcrumb = this.$route.meta.breadcrumb?.map(list => list.name);
      if (getBreadcrumb.includes('category-detail')) {
        this.fetchData();
      }
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      this.reset();
      this.loadGraphic();

      getCategoryMetrics(this.category.toUpperCase(), 'metrics_by_segment', this.selectedFilter, {
        filter: this.selectedOpt,
      })
        .then(({ data }) => {
          // if (this.$_verifyData(data)) return;

          this.valueTotal = data.metrics.metrics_by_segment.value_total;
          data.metrics.metrics_by_segment.data.forEach(item => {
            this.categories.push(item.group);
            this.segmentAverage.push(item.data.average);
            this.segmentValue.push(item.data.value);
            this.dataType = item.data.type;
          });
          this.loadGraphic(false, this.categories, this.segmentAverage, this.segmentValue);
        })
        .catch(() => {
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadGraphic(isLoading = true, categories = [], segmentAverage = [], segmentValue = []) {
      const self = this;
      this.chart = {
        isLoading,
        lang: {
          loading: self.$i18n.t('loading-dots'),
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: -30,
          itemMarginBottom: 25,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column',
          height: 274,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        xAxis: {
          categories,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              formatter: function formatter() {
                return self.getNumber(self.dataType, this.value, true);
              },
            },
          },
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            const { index } = this.points[0].point;
            const name = categories[index];
            const average = segmentAverage[index];
            const value = segmentValue[index];
            const diffText = value > average ? 'maior' : 'menor';
            const args = [
              name,
              self.calcPercentage(value, self.valueTotal),
              self.getNumber(self.dataType, value),
              self.calcPercentage(value, average, 1),
              diffText,
              self.getNumber(self.dataType, average),
            ];
            let fText;
            if (self.selectedOpt === 'revenue') {
              fText = `O segmento <b>${args[0]}</b> gerou <b>${args[1]}</b><br/>
              (${args[2]}) da receita total do<br/>período. Esse número é <b>${args[3]} ${args[4]}</b><br/>
              do que a média geral (${args[5]}).`;
            } else if (self.selectedOpt === 'customer') {
              fText = `O segmento <b>${args[0]}</b> compõe <b>${args[1]}</b><br/>
              (${args[2]}) da base de clientes que<br/>
              compraram esse produto. Esse<br/>
              número é <b>${args[3]} ${args[4]}</b> do que a média<br/>
              da categoria (${args[5]}).`;
            } else if (self.selectedOpt === 'product') {
              fText = `O segmento <b>${args[0]}</b> comprou <b>${args[2]}</b><br/>
              unidades deste produto no período.<br/>
              Esse número é <b>${args[3]} ${args[4]}</b><br/>
              do que a média da categoria (${args[5]}).`;
            }
            return fText;
          },
          style: {
            color: '#555',
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: this.segmentLabel,
            color: self.selectItems.filter(selected => selected.value === self.selectedOpt)[0].colorBase,
            doubleValues: segmentValue,
            data: segmentValue,
            pointPadding: 0.2,
          },
          {
            name: this.$i18n.t('categories.client.overall-average'),
            color: self.selectItems.filter(selected => selected.value === self.selectedOpt)[0].colorBackground,
            data: segmentAverage,
            pointPadding: -0.01,
          },
        ],
      };
      this.chartConfig = Highcharts.chart('chart-category-visualizationBySegment', this.chart);
    },
    resolveLabel(option) {
      const opt = this.selectItems.filter(opt => opt.value === option);
      this.segmentLabel = opt[0]?.title;
    },
    reset() {
      this.categories = [];
      this.segmentAverage = [];
      this.segmentValue = [];
    },
    getNumber(numberType, number, short = false) {
      /**
       * @description get formatted number based on type
       */
      let formatS;
      switch (numberType) {
        case valueType.decimal:
          formatS = '0,000';
          break;
        default:
          formatS = '0,000';
          break;
      }
      if (short && number >= 100000) {
        formatS += 'a';
      }

      if (numberType === valueType.currency) {
        return formatCurrency(number);
      } else {
        return formatNumber(number, formatS);
      }
    },
    calcPercentage(arg1, arg2, sub = 0) {
      /**
       * @description receive 2 args and divide to get percentage
       */
      const phormula = Math.abs(arg1 / arg2 - sub);
      return formatPercent(phormula, 1);
    },
    switchChart() {
      this.resolveLabel(this.selectedOpt);
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.category-visualizationBySegment {
  &.loadHeight {
    height: 378px;
  }
}
</style>
