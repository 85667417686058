<template>
  <section class="push-notifications-create">
    <!-- header -->
    <push-create-header />
    <!-- audience -->
    <h1 class="card-group-title">{{ $t('360.push.audience.group-title') }}</h1>
    <push-audience-selector :mode="mode" />
    <!-- message -->
    <h1 class="card-group-title">{{ $t('360.push.preview.group-title') }}</h1>
    <push-create-message :mode="mode" />
    <push-footer-submit v-if="mode !== 'view'" />
    <push-dialog-error-modal />
    <push-dialog-success-modal />
    <push-dialog-start-time-error-modal />
  </section>
</template>

<script>
// libs
import { mapActions } from 'vuex';
import bus from '@/helpers/events/bus';
// components
import PushCreateHeader from '@/components/oto/pushNotifications/_organisms/PushCreateHeader';
import PushCreateMessage from '@/components/oto/pushNotifications/_organisms/PushCreateMessage';
import PushAudienceSelector from '@/components/oto/pushNotifications/_organisms/PushAudienceSelector';
import PushFooterSubmit from '@/components/oto/pushNotifications/_organisms/PushFooterSubmit';
// modals
import PushDialogErrorModal from '@/components/oto/pushNotifications/modals/PushDialogErrorModal';
import PushDialogSuccessModal from '@/components/oto/pushNotifications/modals/PushDialogSuccessModal';
import PushDialogStartTimeErrorModal from '@/components/oto/pushNotifications/modals/PushDialogStartTimeErrorModal';

export default {
  name: 'PushNotificationsDetail',
  components: {
    PushCreateHeader,
    PushCreateMessage,
    PushAudienceSelector,
    PushFooterSubmit,
    PushDialogErrorModal,
    PushDialogSuccessModal,
    PushDialogStartTimeErrorModal,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 0,
    },
  },
  mounted() {
    this.actionResetData();

    this.setMode(this.mode);

    if (this.mode === 'view' || this.mode === 'edit') {
      this.actionGetNotification(this.id);
      this.updateSubmitStatus(true);
    }

    bus.$on('push-trigger-reload', ({ id }) => {
      this.actionGetNotification(id);
      this.updateSubmitStatus(true);
    });
  },
  beforeUnmount() {
    bus.$off('push-trigger-reload');
  },
  methods: {
    ...mapActions('pushNotifications', ['setMode', 'actionGetNotification', 'actionResetData', 'updateSubmitStatus']),
  },
};
</script>
