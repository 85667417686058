<template>
  <div id="product">
    <header class="title-area">
      <button class="icon icon-back-arrow" @click="back"></button>
      <h1 class="card-group-title">{{ $tc('product.title', 1) }}</h1>
    </header>

    <product-overview :product-i-d="id" />

    <product-lifecycle :product-i-d="id" />

    <div class="row">
      <div class="col-sm-12 col-xl-8">
        <product-order-analysis :product-i-d="id" />
        <product-who-bought :product-i-d="id" />
      </div>
      <div class="col-sm-12 col-xl-4">
        <product-most-purchased :product-i-d="id" :product-info="productInfo" />
        <product-identified :product-i-d="id" :product-info="productInfo" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-5 col-12">
        <product-revenue :product-i-d="id" :product-info="productInfo" />
      </div>
      <div class="col-lg-7 col-12">
        <product-revenue-by-segment :product-i-d="id" :product-info="productInfo" />
      </div>
    </div>

    <div v-if="$experimental" class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <h2 class="h1 card-group-title">{{ $t('profile') }}</h2>
        <product-client-profile :product-i-d="id" />
      </div>
    </div>

    <product-sales-per-sku :product-i-d="id" />
    <product-sales-per-store :product-i-d="id" />

    <search-fixed />
  </div>
</template>
<script>
import ProductOverview from '@/components/products/product/ProductOverview';
import ProductLifecycle from '@/components/products/product/ProductLifeCycle';
import ProductSalesPerSku from '@/components/products/product/ProductSalesPerSku';
import ProductSalesPerStore from '@/components/products/product/ProductSalesPerStore';
import ProductRevenue from '@/components/products/product/ProductRevenue';
import ProductRevenueBySegment from '@/components/products/product/ProductRevenuebySegment';
import SearchFixed from '@/components/products/search/SearchFixed';
import bus from '@/helpers/events/bus';
import ProductOrderAnalysis from '@/components/products/product/ProductOrderAnalysis';
import ProductMostPurchased from '@/components/products/product/ProductMostPurchased';
import ProductIdentified from '@/components/products/product/ProductIdentified';
import ProductWhoBought from '@/components/products/product/ProductWhoBought';
import ProductClientProfile from '@/components/products/product/ProductClientProfile';
// utilities
import { useMixpanelConfigProductDetail } from '@/utilities/mixpanel';

export default {
  components: {
    ProductOverview,
    ProductLifecycle,
    ProductSalesPerSku,
    ProductSalesPerStore,
    SearchFixed,
    ProductOrderAnalysis,
    ProductRevenue,
    ProductRevenueBySegment,
    ProductMostPurchased,
    ProductIdentified,
    ProductWhoBought,
    ProductClientProfile,
  },
  data() {
    return {
      id: this.$route.params.id,
      productInfo: Object,
    };
  },
  watch: {
    '$route.params.id'(newValue) {
      // eslint-disable-line
      this.id = newValue;

      this.$nextTick(() => {
        bus.$emit('render-cards');
      });
    },
  },
  mounted() {
    bus.$on('update-selected-brand', this.back);

    bus.$on('update-search-fixed-data', res => {
      this.productInfo = res;
      useMixpanelConfigProductDetail('view_analytics_products_detail_product', this.id, this.productInfo.title);
    });
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
    bus.$off('update-search-fixed-data');
  },
  methods: {
    back() {
      this.$router.push('/products');
    },
  },
};
</script>
<style lang="scss" scoped>
.title-area {
  display: flex;
  align-items: center;
  margin: 20px 0 40px;

  > button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $gray-600;
    padding-left: 0;
  }

  .card-group-title {
    margin: 0;
  }
}
</style>

<style lang="scss">
.product-revenue {
  height: 305px;
}

.product-revenue-by-segment {
  height: 305px;

  &.loadHeight {
    height: 305px;
  }
}
</style>
