import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

type OptionsGet = { page?: number; limit?: number };
type OptionsExport = { fileType?: string; responseType?: 'blob'; date: string };

export const getDataIngestionData = ({ page = 1, limit = 10 }: OptionsGet) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/integration/getDataIngestionHistory', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      page,
      limit,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getExportDataIngestionHistory = ({ fileType, responseType, date }: OptionsExport) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/integration/getDataIngestionHistory', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      export: fileType,
      date,
    },
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
