import { getCustomerRule } from '../services';
import { FormattedRule, ResponseRule } from '../models';

export const fetchRule = (id: number) => getCustomerRule(id).then(response => formatRule(response?.data));

const formatRule = (response: ResponseRule) => {
  const result: FormattedRule = {
    name: response?.data?.name || '-',
    createdAt: response?.data?.createdAt || '',
    mainRule: response?.data?.mainRule,
    content: response?.data?.content || '',
    slot: response?.data?.slot || 0,
    enableOverload: response?.data?.enableOverload,
    id: response?.data?.id,
  };

  return result;
};
