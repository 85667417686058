// libs
import { cloneDeep, isEmpty } from 'lodash';

// helpers / utilities
import bus from '@/helpers/events/bus';
import hasPermission, { actions } from '@/helpers/permissions';
import { getPreviousDateFormat } from '@/utilities/dates';
import { formatNumber } from '@/utilities/formatters';

// services
import { putCashback, getPersonas, getCashback, getCountRemaining, getPredictions } from '@/services/oto/cashback';

const defaultRangeValues = [
  {
    persona: {},
    percent: '',
  },
];

const defaultCashbackDetails = {
  enabled: true,
  expireDays: 0,
  creditDays: 0,
};

const cashback = {
  namespaced: true,
  state: {
    permissionCreate: {
      id: 'cashback-create',
      featureId: 'cashback',
      action: actions.CREATE,
      status: false,
    },

    permissionView: {
      id: 'cashback-create',
      featureId: 'cashback',
      action: actions.VIEW,
      status: false,
    },

    cashbackDetails: cloneDeep(defaultCashbackDetails),

    cashbackRanges: [],
    cashbackFreezeRange: [],
    cashbackPrediction: [],

    personas: [],
    database: 0,

    analysisPeriodSelected: {
      id: 'last-month',
      name: '360.goals.analysis-period.last-month',
    },
    analysisPeriodList: [
      {
        id: 'last-month',
        name: '360.goals.analysis-period.last-month',
      },
      {
        id: 'last-six-months',
        name: '360.goals.analysis-period.last-six-months',
      },
      {
        id: 'last-year',
        name: '360.goals.analysis-period.last-year',
      },
    ],

    loadingSubmit: false,
    loadingCashbackDetail: false,
    loadingInvestment: false,

    hasEmptyPersona: false,
  },
  mutations: {
    SET_CREATE_PERMISSION(state, payload) {
      state.permissionCreate.status = payload;
    },

    SET_VIEW_PERMISSION(state, payload) {
      state.permissionView.status = payload;
    },

    SET_ID(state, payload) {
      state.cashbackDetails.cashbackId = payload;
    },
    SET_EXPIRE_DAYS(state, payload) {
      state.cashbackDetails.expireDays = Number(payload);
    },
    SET_CREDIT_DAYS(state, payload) {
      state.cashbackDetails.creditDays = Number(payload);
    },
    SET_DETAILS(state, payload) {
      const { expireDays = 0, creditDays = 0, range = [], enabled = true } = payload;

      state.cashbackDetails.expireDays = expireDays;
      state.cashbackDetails.creditDays = creditDays;
      state.cashbackDetails.enabled = enabled;

      if (range) {
        const rangeWithPersonaId = range.filter(el => el?.personaId !== null);
        const rangeWithoutPersonaId = range.filter(el => el?.personaId === null);
        // create persona object with respective persona data
        state.cashbackRanges = rangeWithPersonaId.map(el => {
          return { ...el, persona: state.personas.find(item => item?.id === el?.personaId) };
        });
        state.cashbackFreezeRange = rangeWithoutPersonaId;
      }
    },
    SET_STATUS(state, payload) {
      state.cashbackDetails.enabled = payload === 'enabled';
    },
    SET_PREDICTION_DATA(state, payload) {
      state.cashbackPrediction = payload;
    },

    SET_PERSONAS(state, payload) {
      state.personas = payload;
    },
    SET_DATABASE(state, payload) {
      state.database = payload;
    },
    SET_EMPTY_PERSONA(state, payload) {
      state.hasEmptyPersona = payload;
    },

    SET_RANGE(state, payload) {
      state.cashbackRanges = payload;
    },

    SET_REMOVE_RANGE_ITEM(state, payload) {
      state.cashbackRanges.splice(payload, 1);
    },

    SET_LAST_RANGE_LINE(state, { lRange, percent, totalPercent, total }) {
      const nRange = lRange;
      // add prop to last range line
      nRange.freeze = true;

      nRange.percent = percent;

      nRange.persona = {
        percent: totalPercent,
        total: total,
      };
      // set freeze range property
      state.cashbackFreezeRange.splice(0, 1, nRange);
    },

    SET_ANALYSIS_PERIOD(state, id) {
      state.analysisPeriodSelected = state.analysisPeriodList.find(el => el.id === id);
    },

    RESET(state) {
      state.cashbackDetails = cloneDeep(defaultCashbackDetails);
    },

    SET_LOADING_DETAIL(state, payload) {
      state.loadingCashbackDetail = payload;
    },
    SET_LOADING_SUBMIT(state, payload) {
      state.loadingSubmit = payload;
    },
    SET_LOADING_INVESTMENT(state, payload) {
      state.loadingInvestment = payload;
    },
  },
  actions: {
    setCreatePermission({ state, commit }) {
      const createPermission = hasPermission(state.permissionCreate);
      commit('SET_CREATE_PERMISSION', createPermission);

      if (createPermission) return;

      const viewPermission = hasPermission(state.permissionView);
      commit('SET_VIEW_PERMISSION', viewPermission);
    },

    setCashbackStatus({ commit }, payload) {
      commit('SET_STATUS', payload);
    },
    setExpireDays({ commit }, payload) {
      commit('SET_EXPIRE_DAYS', payload);
    },
    setCreditDays({ commit }, payload) {
      commit('SET_CREDIT_DAYS', payload);
    },

    getPersonasList({ commit, dispatch }) {
      commit('SET_LOADING_DETAIL', true);
      getPersonas()
        .then(response => {
          const data = response?.data;
          if (!data.success) throw Error;
          commit('SET_PERSONAS', data?.data);
          commit('SET_DATABASE', data?.database);
        })
        .then(() => {
          dispatch('getCashbackDetail');
        });
    },

    getCashbackDetail({ commit, dispatch }) {
      commit('SET_LOADING_DETAIL', true);
      getCashback()
        .then(data => {
          if (!data.success) throw Error;

          if (!data?.data) dispatch('setDefaultRangeValues');
          else commit('SET_DETAILS', data?.data);

          dispatch('setLastRangeLine');
        })
        .catch(() => {
          commit('SET_LOADING_DETAIL', false);
          dispatch('setDefaultRangeValues');
          bus.$emit('open-cashback-dialog-error-modal');
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false);
        });
    },

    getCountRemainingValue({ getters }) {
      const personas = getters.getCashbackRanges.map(el => el.persona.id).join(',');
      return getCountRemaining({ personas }).then(({ data }) => {
        if (!data.success) return;
        return data.data.count;
      });
    },

    getPredictionValue({ commit, getters }) {
      commit('SET_LOADING_INVESTMENT', true);

      const range = JSON.stringify(getters.getCashbackRanges);
      const startDate = getPreviousDateFormat(getters.getAnalysisPeriodSelect.id).startDate;
      const endDate = getPreviousDateFormat(getters.getAnalysisPeriodSelect.id).endDate;

      getPredictions({ range, startDate, endDate })
        .then(({ data }) => {
          if (!data.success) throw Error;
          commit('SET_PREDICTION_DATA', data?.data);
        })
        .catch(() => {
          bus.$emit('open-cashback-dialog-error-modal');
        })
        .finally(() => {
          commit('SET_LOADING_INVESTMENT', false);
        });
    },

    setDefaultRangeValues({ commit }) {
      const ranges = cloneDeep(defaultRangeValues);
      commit('SET_RANGE', ranges);
    },

    includeRangeBuilder({ getters, dispatch }, { persona = {}, percent = '' } = {}) {
      const defaultValues = { persona, percent };

      const ranges = getters.getCashbackRanges;

      ranges.push(defaultValues);

      dispatch('verifyEmptyPersona');
    },

    removeRange({ commit }, payload) {
      commit('SET_REMOVE_RANGE_ITEM', payload);
    },

    setAnalysisPeriod({ commit, dispatch }, payload) {
      commit('SET_ANALYSIS_PERIOD', payload);
      dispatch('getPredictionValue');
    },

    resetData({ commit }) {
      commit('RESET');
    },

    setEmptyPersona({ commit }, payload) {
      commit('SET_EMPTY_PERSONA', payload);
    },

    verifyEmptyPersona({ commit, getters }) {
      const isPersonaEmpty = getters.getCashbackRanges.some(el => isEmpty(el.persona));
      commit('SET_EMPTY_PERSONA', isPersonaEmpty);
    },

    validateConfig({ dispatch, getters }) {
      // remove empty range case hasn't persona selected
      if (getters.getCashbackRanges.length > 1) {
        getters.getCashbackRanges.map((el, index) => {
          if (isEmpty(el.persona)) dispatch('removeRange', index);
        });
      }
    },

    setLastRangeLine({ commit, dispatch, getters }) {
      const lRange = cloneDeep(defaultRangeValues);
      const percent = getters.getCashbackFreezeRange[0]?.percent || '0,0%';
      const database = getters.getDatabase;
      let total = 0;
      let totalPercent;

      dispatch('getCountRemainingValue').then(remaining => {
        const ranges = getters.getCashbackRanges;
        const totalRangeSum =
          ranges?.length > 1
            ? ranges.reduce((acc, value) => {
                return acc + value?.persona?.total;
              }, 0)
            : 0;

        if (database) total = database - totalRangeSum;
        totalPercent = remaining / database;

        commit('SET_LAST_RANGE_LINE', {
          lRange: lRange[0],
          percent: formatNumber(percent, '0.0%'),
          totalPercent,
          total,
        });

        dispatch('getPredictionValue');
      });

      dispatch('verifyEmptyPersona');
    },

    submitCashback({ state, commit, dispatch }) {
      commit('SET_LOADING_SUBMIT', true);

      const stateRanges = [...state.cashbackRanges, ...state.cashbackFreezeRange];

      const formattedRanges = stateRanges.map((el, i) => {
        const index = i;
        const personaId = el.persona?.id || null;
        const percent = el.percent;
        return {
          index,
          personaId,
          percent,
        };
      });

      const range = formattedRanges;

      const args = {
        expireDays: state.cashbackDetails.expireDays,
        creditDays: state.cashbackDetails.creditDays,
        enabled: state.cashbackDetails.enabled,
        range: JSON.stringify(range),
      };

      putCashback({ ...args })
        .then(({ data }) => {
          if (!data.success) throw Error;

          bus.$emit('cashback-details-toast-success');
          dispatch('getCashbackDetail');
        })
        .catch(() => {
          bus.$emit('open-cashback-dialog-error-modal');
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false);
        });
    },

    setRangeHighValue({ state }, { index, status, last }) {
      const stateRanges = [...state.cashbackRanges, ...state.cashbackFreezeRange];
      const i = last ? stateRanges.length - 1 : index;
      stateRanges[i].hasHighValue = status;
    },
  },
  getters: {
    getPermissionCreate: state => state.permissionCreate.status,
    getPermissionView: state => state.permissionView.status,
    getCashbackRanges: state => state.cashbackRanges,
    getCashbackFreezeRange: state => state.cashbackFreezeRange,
    getCashbackPrediction: state => state.cashbackPrediction,
    getPersonasSelectList: state => state.personas,
    getAnalysisPeriodList: state => state.analysisPeriodList,
    getAnalysisPeriodSelect: state => state.analysisPeriodSelected,
    getEmptyPersonaRange: state => state.hasEmptyPersona,
    getLoadingInvestmentStatus: state => state.loadingInvestment,
    getDatabase: state => state.database,
  },
};

export default cashback;
