<template>
  <table-list :data="dataTable" class="loadHeight" type="filled">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column><modal-dates /></table-list-column>
        <table-list-column class="text-right">{{ $t('revenue') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('tracked-revenue.attributed') }}</table-list-column>
        <table-list-column class="text-right">% {{ $t('tracked-revenue.attributed') }}</table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row
          v-for="(item, index) in dataTable.rows"
          :key="`lightbox-tracked-revenue-${index}`"
          :class="{ active: item.segment_id == segmentSelected }"
          :click-callback="() => $_handleTableRow(item.segment_id)"
        >
          <table-list-column> <i class="icon icon-filter"></i>{{ item.name }} </table-list-column>
          <table-list-column class="text-right">{{
            formatCurrency(item.metrics && item.metrics.revenue)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            formatCurrency(item.metrics && item.metrics.revenue_tracked)
          }}</table-list-column>
          <table-list-column class="text-right">
            {{
              item.metrics && item.metrics.revenue_tracked_total_rate.toFixed(2) > 0
                ? $_formatNumber(item.metrics.revenue_tracked_total_rate, '0.00%')
                : '-'
            }}
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </table-list>
</template>

<script>
import getTable from '@/services/getTable';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import ModalDates from '@/components/ModalDates';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'TrackedRevenueTable',
  components: {
    ModalDates,
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataTable: {
        count: 0,
        rows: [],
      },
      segmentSelected: '',
    };
  },
  mounted() {
    this.reset();
    this.fetchData();
  },
  methods: {
    fetchData() {
      getTable({
        metrics: ['revenue', 'revenue_tracked', 'revenue_tracked_total_rate'],
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.data)) return;

          this.dataTable.rows = response.data.data;
          this.dataTable.count = this.dataTable.rows.length;
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.ready = false;
      this.dataTable.rows = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
