<template>
  <section class="allsegmentgroups-wrapper">
    <section class="asg__collapsed" @click="getCollapsed(id)">
      <div :class="['asg__collapsed-border', !collapsed ? '--gray' : '--pink']">
        {{ $t('360.analytics-o2o.segments') }}
      </div>
      <div class="asg__icon">
        <i v-show="!collapsed" class="icon icon-plus"></i>
        <i v-show="collapsed" class="icon icon-minus-30px"></i>
      </div>
    </section>
    <section v-show="collapsed" class="allsegmentgroups">
      <h1 v-if="$route.path.includes('/dashboard/fullview')" class="card-group-title">
        {{ $t('segments-title') }}
      </h1>
      <div class="card">
        <div class="card-body row">
          <div class="col-sm-12 col-xl-9 order-xl-1 order-sm-2">
            <crm-segments-blocks :make-api-call="makeApiCall" />
          </div>
          <div class="col-sm-12 col-xl-3 order-xl-2 order-sm-1">
            <crm-segments-form />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <segments-revenue :make-api-call="makeApiCall" />
        </div>
      </div>
      <div v-if="showChurnLtv" class="row">
        <div class="col-12">
          <churn-ltv-period :make-api-call="makeApiCall" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <segments-migration-table :make-api-call="makeApiCall" />
        </div>
      </div>
    </section>
  </section>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';

// @ is an alias to /src
import CrmSegmentsBlocks from '@/components/segments/crmBlocks/CrmSegmentsBlocks';
import CrmSegmentsForm from '@/components/segments/form/CrmSegmentsForm';
import SegmentsMigrationTable from '@/components/segments/groupsMigrationTable/AllSegmentGroupsMigrationTable';
import SegmentsRevenue from '@/components/segments/groupsRevenue/AllSegmentGroupsRevenue';
import ChurnLtvPeriod from '@/components/segments/churnLtv/ChurnLtvPeriod';

export default {
  name: 'Allsegmentgroups',
  components: {
    CrmSegmentsBlocks,
    CrmSegmentsForm,
    SegmentsMigrationTable,
    SegmentsRevenue,
    ChurnLtvPeriod,
  },
  data() {
    return {
      id: 'allsegmentsgroups',
      collapsed: false,
      brand: null,
      makeApiCall: false,
    };
  },
  computed: {
    showChurnLtv() {
      return [1, 177].indexOf(this.brand) >= 0;
    },
  },
  mounted() {
    this.brand = this.$store.getters.selectedBrand?.id;
    bus.$on('keep-last-collapsed-open', id => {
      if (id !== this.id) this.collapsed = false;
    });
  },
  beforeUnmount() {
    bus.$off(['keep-last-collapsed-open']);
  },
  methods: {
    getCollapsed(id) {
      this.collapsed = !this.collapsed;
      if (this.collapsed) {
        bus.$emit('is-active-collapse', id);
        this.makeApiCall = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.allsegmentgroups-wrapper {
  .asg__collapsed {
    background: $color-white;
    margin-bottom: 10px;
    border-radius: 8px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    .asg__collapsed-border {
      margin-left: 25px;
      font-weight: 500;

      &.--gray {
        color: $gray-700;
      }

      &.--pink {
        color: $pink-500;
      }
    }

    .icon {
      color: $pink-500;
      margin-right: 45px;
      font-weight: bold;
    }
  }

  .asg__collapsed::after {
    content: '';
    background: $pink-500;
    height: 100%;
    width: 5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    left: 0;
  }
  .allsegmentgroups {
    .segments-profile-card,
    .retention-card {
      height: 274px;
    }
    .profile-card {
      min-height: 235px;
    }

    .age-gender-card.closed {
      @media (min-width: $xl-min-width) {
        height: 310px;
      }

      @media (max-width: $tablet-max-width) {
        height: 310px;
      }
    }

    .allsegmentgroups-frequency {
      min-height: 363px;

      &.loadHeight {
        height: 388px;
      }
    }

    .omnichannel-profile-card {
      @media (min-width: $xl-min-width) {
        min-height: 193px;
      }
    }
  }
}
</style>
