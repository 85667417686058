<template>
  <base-card-v2 class="actions__header">
    <div class="actions__container">
      <actions-defaults :title="title" :has-icon="hasIcon" :svg="svg" :icon="icon">
        <template v-slot:svg-icon>
          <icon-base v-if="svg === 'union'" :color="'#6c90f4'" class="svg-icon">
            <icon-union />
          </icon-base>
        </template>
      </actions-defaults>
      <slot></slot>
    </div>
  </base-card-v2>
</template>

<script>
import ActionsDefaults from '@/components/oto/actions/_molecules/ActionsDefaults';

import IconBase from '@/assets/vue-icons/IconBase';
import IconUnion from '@/assets/vue-icons/icons/Union';

export default {
  name: 'HistoryHeader',
  components: {
    ActionsDefaults,

    IconBase,
    IconUnion,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'icon-message-18px',
    },
    svg: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.actions__header {
  background: no-repeat right #fff;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  height: 100px;
  width: 100%;

  .actions__container {
    display: flex;
    align-items: center;
  }
}
</style>
