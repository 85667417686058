<template>
  <div class="personas-footer-counter">
    <p>{{ $t('personas.detail.people') }}</p>
    <p class="number">
      <i class="icon icon-customer"></i>
      <span>{{ showCount ? countPeople : '-' }}</span>
      <span v-if="showTime" class="time">
        <span v-if="time < 5">{{ $t('audiences.updatedAt.now') }}</span>
        <span v-if="time >= 5 && time < 60">{{
          $t('audiences.updatedAt.atTime', { time: Number.parseInt(time) })
        }}</span>
        <span v-if="time > 60">{{ $t('audiences.updatedAt.long-time') }}</span>
      </span>
      <span v-if="showCalculating" class="time">{{ $t('audiences.calculating') }}...</span>
    </p>
  </div>
</template>

<script>
// libs, helpers, services
import { formatDecimal } from '@/utilities/formatters';
import { mapState } from 'vuex';

export default {
  name: 'PersonasFooterCounter',
  props: {
    people: {
      type: Number,
      default: null,
    },
    time: {
      type: Number,
      default: null,
    },
  },
  computed: {
    showCount() {
      return !this.requestsCounter && this.people !== undefined;
    },
    showTime() {
      return !this.requestsCounter && this.people !== undefined && this.personaDetailMode !== 'view';
    },
    showCalculating() {
      return this.requestsCounter && this.personaDetailMode !== 'view';
    },
    countPeople() {
      return formatDecimal(this.people, 2);
    },
    ...mapState('personas', ['personaDetailMode', 'requestsCounter']),
  },
};
</script>

<style lang="scss" scoped>
.personas-footer-counter {
  p {
    color: $gray-800;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0;
    cursor: default;
    &.number {
      font-size: 16px;
      margin-top: 7px;
    }
    .icon {
      margin-right: 5px;
      color: $gray-600;
    }
  }
  .time {
    font-size: 12px;
    font-weight: normal;
    color: $oto-omni;
    display: block;
    margin-left: 14px;
    cursor: default;
  }
}
</style>
