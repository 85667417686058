<template>
  <section id="nps-channel-ratings">
    <template v-if="$_verifyLoaded('done')">
      <div v-for="(item, key) in dataset" :key="key" class="card">
        <channel-ratings :channel="item.channel" :metadata="metadata" :dataset="item" />
      </div>
    </template>
    <div v-else class="card loadHeight">
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </div>
  </section>
</template>

<script>
import bus from '@/helpers/events/bus';
import { getChannelMetrics } from '@/services/nps/analytics';
import ChannelRatings from '@/components/nps/_molecules/ChannelRatings';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  name: 'AnalyticsChannelRatings',
  components: {
    ChannelRatings,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      dataset: {},
      metadata: {},
    };
  },
  mounted() {
    this.getChannelData();
    bus.$on('render-cards', () => {
      this.getChannelData();
    });
  },
  methods: {
    getChannelData() {
      this.$_reqConfig();
      const metrics = [
        'nps',
        'ratings',
        'detractors_rate',
        'passive_rate',
        'promoters_rate',
        'item_01_score',
        'item_02_score',
        'item_03_score',
        'item_04_score',
        'item_05_score',
        'item_06_score',
        'item_07_score',
        'item_08_score',
        'item_09_score',
        'item_10_score',
      ];
      getChannelMetrics(metrics.join(','))
        .then(res => {
          if (this.$_verifyData(res.data)) return;
          this.metadata = Object.fromEntries(
            res.series.map(m => {
              return [
                m.key,
                {
                  name: m.name,
                  type: m.type,
                  threshold: m.threshold,
                },
              ];
            }),
          );
          this.dataset = res?.data;
          bus.$emit('update-channel-ratings');
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#nps-channel-ratings {
  .loadHeight {
    height: 260px;
  }
}
</style>
