<template>
  <base-card-v2 id="customers-filter">
    <base-card-header :title="$t('360.filter.title')" icon="icon-filter" />
    <div class="row no-gutters">
      <div class="col-12">
        <customers-filter-serie
          v-for="serie in series"
          :id="serie.id"
          :key="serie.id"
          :fill="columnOptions"
          :data="serie"
        />
        <transition name="fade">
          <div v-if="warning" class="warning">
            <p class="m-0">{{ $t('360.filter.warning') }}</p>
          </div>
        </transition>
        <footer class="buttons d-flex align-items-center justify-content-between">
          <div class="add-filter">
            <a href="#" class="add-filter" @click.prevent="addFilter">
              <i class="icon icon-plus btn btn-plus"></i>
              <span>{{ $t('360.filter.add') }}</span>
            </a>
          </div>
          <button class="btn btn-search" @click.prevent="filter">
            <i class="icon icon-search"></i>
          </button>
        </footer>
      </div>
    </div>
  </base-card-v2>
</template>
<script>
import CustomersFilterSerie from '@/components/oto/customers/components/CustomersFilterSerie';
import bus from '@/helpers/events/bus';
import { getFilterColumns } from '@/services/oto/customers';

export default {
  name: 'CustomerBaseFilter',
  components: {
    CustomersFilterSerie,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      serieFill: {},
      warning: false,
    };
  },
  computed: {
    columnOptions() {
      const { serieFill } = this;
      const keys = Object.keys(serieFill);

      return this.formatColumns(keys, serieFill);
    },
  },
  mounted() {
    // request filters
    this.getOptions();
  },
  methods: {
    getOptions() {
      getFilterColumns().then(res => {
        this.serieFill = res?.data?.data;
        bus.$emit('add-serie');
      });
    },
    formatColumns(keys, fill, isLastLevel = false) {
      const options = [];

      keys.forEach(key => {
        const fillKeys = Object.keys(fill[key]);
        const values = isLastLevel ? fill[key] : this.formatColumns(fillKeys, fill[key], true);
        options.push({
          key,
          ...(isLastLevel ? values : { columns: values }),
        });
      });

      return options;
    },
    addFilter() {
      bus.$emit('add-serie');
    },
    filter() {
      // validates if at least one value inside series its empty
      if (this.series[0].operator === 'isnull' || this.series[0].operator === 'notnull') {
        this.series.value === '';
      } else if (this.series.some(el => Boolean(el.value?.length) === false || el.value?.includes(null))) {
        this.warning = true;
        return;
      }

      bus.$emit('refresh-search');
      this.warning = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#customers-filter {
  background-repeat: no-repeat;
  background-position: center right;
}
a.add-filter {
  display: flex;
  align-items: center;
  text-decoration: none;
  span {
    color: $gray-700;
    font-size: 12px;
  }
  .btn-plus {
    width: 36px;
    height: 36px;
    font-size: 28px;
    line-height: 36px;
    padding: 0;
    text-align: center;
    margin-right: 10px;
    background-color: #25a4f1;
  }
}
.btn-search {
  padding: 0;
  width: 170px;
  height: 48px;
  font-weight: 600;
  border-radius: 80px;
  .icon-search {
    line-height: 40px;
  }
}

.warning {
  padding: 10px;
  p {
    color: $color-red;
  }
}
</style>

<style lang="scss">
#customers-filter {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
