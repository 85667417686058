<template>
  <base-card-v2 class="product-identified">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header icon="icon-cart" :title="$t('identified')" />
      <div v-if="!loader.loadingMetric" class="row mb-4">
        <div v-if="ordersTrackedRate" class="col-6">
          <label class="chart-title">{{ $t('identified-orders') }}</label>
          <span class="chart-value-big d-block color-blue-500">
            {{ $_formatNumber(ordersTrackedRate.data.value, '0.00%') }}
          </span>
        </div>
        <div v-if="ordersProgramRate" class="col-6">
          <label class="chart-title">{{ $t('valid-identified') }}</label>
          <span class="chart-value-big d-block color-blue-500">
            {{ $_formatNumber(ordersProgramRate.data.value, '0.00%') }}
          </span>
        </div>
      </div>
      <div v-if="!loader.loadingTable && storeData.length" class="row">
        <div class="col-12">
          <mini-bar-chart :prop-data="jsonStore" />
        </div>
      </div>
      <bottom-button :typecard="'card'">
        <template v-slot:bottom-button>
          <label
            class="link color-oto-brand-omni col-auto text-right mr-3"
            @click="openLightbox('lightbox-products-identified', productInfo)"
            >{{ $tc('product.see-all', 2) }}</label
          >
        </template>
      </bottom-button>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import MiniBarChart from '@/components/MiniBarChart';
import bus from '@/helpers/events/bus';
import { getTable, getMetrics } from '@/services/product';
import { openLightbox } from '@/helpers/lightbox';
import BottomButton from '@/components/_atoms/BaseCardBottomButton';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, verifyThensMixin, numberMixin, miniBarChartMixin } from '@/helpers/mixins';

export default {
  name: 'ProductIdentified',
  components: {
    MiniBarChart,
    BottomButton,
    BaseLoader,
    InfoCard,
  },
  mixins: [miniBarChartMixin, verifyMixin, verifyThensMixin, numberMixin],
  props: {
    productID: {
      type: String,
      default: '',
      required: true,
    },
    productInfo: {
      type: [Object, Function],
      default: null,
      required: false,
    },
  },
  data() {
    return {
      loader: {
        loadingMetric: true,
        loadingTable: true,
      },
      ready: false,
      ordersTrackedRate: {},
      ordersProgramRate: {},
      storeData: [],
      storeDataTotalValue: 0,
    };
  },
  computed: {
    jsonStore() {
      return this.$_mountMiniBarTotalRange(
        'red',
        this.$t('worst-performances-product'),
        this.storeData,
        this.storeDataTotalValue,
        'quantityUnits',
      );
    },
  },
  mounted() {
    this.fetchData();

    // called after a global date change or brand change
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.componentHasData = [];

      this.$_reqConfig();
      this.loader.loadingMetric = true;
      this.loader.loadingTable = true;
      this.ready = false;

      getMetrics({
        productID: this.productID,
        metrics: ['orders_tracked_rate', 'orders_program_rate'],
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
          } else this.componentHasData.push(true);
          this.$_verifyThenIsEmpty();

          this.ordersTrackedRate = data.metrics.orders_tracked_rate;
          this.ordersProgramRate = data.metrics.orders_program_rate;

          this.ready = true;
        })
        .catch(() => {
          this.ordersTrackedRate = {};
          this.ordersProgramRate = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingMetric = false;
          this.$_verifLoading();
        });

      getTable({
        productID: this.productID,
        table: 'orders_program_rate',
        limit: 3,
        orderBy: 'orders_total_desc',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);
          this.$_verifyThenIsEmpty();

          this.storeData = data.data;
          this.storeDataTotalValue = data.value_total;
        })
        .catch(() => {
          this.storeData = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingTable = false;
          this.$_verifLoading();
        });
    },
    openLightbox(id, settings) {
      openLightbox(id, settings);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  min-height: 275px;
}
</style>
