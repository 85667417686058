<template>
  <div v-if="showSegments && isLogged()" class="nav-segments dropdown">
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav">
        <li class="nav-item ml-auto dropdown">
          <a
            id="navbarDropdownSegmentsGroup"
            href="#"
            class="nav-link dropdown-segments dropdown-toggle toggle-without-arrow align-content-center justify-content-between"
            :class="$route.name === 'stores' ? 'd-none' : 'd-flex'"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span>{{ selectedPersona.name }}</span>
            <i class="icon icon-segment"></i>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownSegmentsGroup">
            <li v-for="(persona, index) in segmentPersona" :key="`${persona.id}-${index}`" class="dropdown-submenu">
              <a
                href="#"
                class="dropdown-item"
                data-toggle="dropdown"
                :class="{
                  'dropdown-toggle': persona.group === true,
                  'active-segment': persona.id === selectedPersona.id,
                }"
                @click.stop.prevent="selectPersonaByGroup(persona), refreshCards()"
              >
                {{ persona.name }}
                <ul v-if="persona.group === true" class="dropdown-menu" :class="`dropdown-group-${persona.id}`">
                  <li v-for="(personaGroup, index) in persona.personas" :key="index">
                    <a
                      data-toggle="dropdown"
                      href="#"
                      class="dropdown-item"
                      tabindex="-1"
                      :class="{
                        'active-segment': personaGroup.id === selectedPersona.id,
                      }"
                      @click.stop.prevent="
                        selectPersona(personaGroup.id, personaGroup.name, persona.name), refreshCards()
                      "
                    >
                      {{ personaGroup.name }}
                    </a>
                  </li>
                </ul>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import store from '@/store';
import { mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
import getSegments from '@/services/getSegments';
import { getSegmentPersonas } from '@/services/segments';
import { excludeSegmentRoutes, exceptionallyIncludeDateRoutes } from '@/helpers/menu';

export default {
  name: 'HeaderSegments',
  data() {
    return {
      segmentPersona: [],
      name: 'Todos',
      personaColors: null,
      isUpdate: false,
    };
  },
  computed: {
    ...mapGetters(['selectedPersona']),

    hasMatchedSegment() {
      const group = this.segments.filter(g => g.group_id === this.selectedPersona.segmentGroupId);
      const segment = group[0].segments.filter(s => s.segment_id === this.selectedPersona.segmentId);
      return !_.isEmpty(segment);
    },
    /**
     * @description show segmnets just to values off the exclusion list
     */
    showSegments() {
      if (this.$route.path === '/') return false;
      // Validate exceptionally segment include routes
      if (exceptionallyIncludeDateRoutes.some(el => this.$route.path === el)) return true;
      return !excludeSegmentRoutes.some(el => this.$route.path.includes(el));
    },
  },
  watch: {
    selectedPersona() {
      if (this.isLogged()) this.fetchData();
    },
  },
  mounted() {
    this.selectPersona(null, 'Todos', null);

    bus.$on('update-brand', () => {
      this.selectPersona(null, 'Todos', null);
      if (this.isLogged()) this.fetchData();
    });
  },
  beforeUpdate() {
    if (this.isLogged() && !this.isUpdate) {
      this.isUpdate = true;
      this.fetchData();
      return;
    }
  },
  beforeUnmount() {
    bus.$off('update-brand');
  },
  methods: {
    isLogged() {
      return store?.getters?.isLoggedIn ? true : false;
    },
    fetchData() {
      if (this.showSegments) {
        getSegmentPersonas().then(response => {
          const data = response?.data;
          this.segmentPersona = data?.segmentGroupPersonas;
        });
      }
    },
    /**
     * @description call segment groups from api if hasn't any - update store and names
     */
    resolveSegments() {
      if (!store.getters.segments) {
        getSegments().then(({ data }) => {
          let segmentsColors;
          data.segment_groups.forEach(group => {
            if (group.group_id === 2) {
              segmentsColors = group.segments.map(segment => ({
                [segment.segment_name]: `#${segment.segment_color}`,
              }));
            }
          });

          this.$store.dispatch('changeSegments', this.segments);
          this.$store.dispatch('changeSegmentsColors', segmentsColors);
        });
      }
    },
    /**
     * @description update store and cards with new selected segment
     */
    selectPersona(id, name, group) {
      this.name = name;
      this.groupName = group;
      const persona = {
        id,
        name,
        group,
      };

      this.$store.dispatch('changePersona', persona);
      bus.$emit('select-persona', { persona });
    },
    /**
     * @description select by group
     */
    selectPersonaByGroup(personaGroup) {
      if (personaGroup.group === false) {
        if (personaGroup.id < 0) {
          this.selectPersona(null, personaGroup.name, undefined);
        } else {
          this.selectPersona(personaGroup.id, personaGroup?.name, undefined);
        }
      }
    },
    /**
     * @description responsible to render cards and close dropdown - fix event propagation to parent scope
     */
    refreshCards() {
      bus.$emit('render-cards');
      document.querySelector('.dropdown-menu').dropdown('toggle');
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-segments {
  margin: 0 10px;
  max-width: 180px;
  width: 180px;
}

.dropdown-menu {
  background-color: $gray-300;
  border-radius: 0;
  border: 0 none;
  padding: 15px 0;
}

.dropdown-item {
  color: $gray-800;
  font-size: 13px;
  font-weight: 400;
  padding: 5px 30px;
  &:hover,
  &:active,
  &:focus {
    background-color: inherit;
    color: $oto-omni;
    outline: none;
  }
  &.router-link-exact-active {
    color: $oto-omni;
    font-weight: 600;
  }
}

.nav-item {
  @media screen and (max-width: $tablet-max-width) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbar-nav .nav-link {
  padding-left: 7px;
  padding-right: 7px;
  &.dropdown-toggle {
    color: $gray-500;
    &.drop-toggle-menu {
      color: $gray-300;
    }
    &:after {
      color: $gray-600;
    }
    &:hover,
    &[aria-expanded='true'] {
      &,
      &:after {
        color: $oto-omni;
      }
    }
    &.toggle-without-arrow:after {
      display: none;
    }
  }
  &.dropdown-segments {
    color: $gray-600;
    min-width: 180px;
    font-size: 12px;
    border: 1px solid $gray-500;
    border-radius: 25px;
    padding: 4.5px 16px;
    .icon {
      font-size: 24px;
      line-height: 24px;
      color: $gray-500;
    }
    span {
      line-height: 25px;
      font-weight: 600;
    }
    &:hover,
    &:hover > .icon,
    &:focus,
    &:focus > .icon,
    .dropdown-item.active-segment {
      color: $oto-omni;
      border-color: $oto-omni;
    }
    @media screen and (max-width: $tablet-max-width) {
      min-width: 130px;
      .icon {
        display: none;
      }
    }
  }
}

.dropdown-submenu {
  > a:hover {
    .dropdown-menu {
      visibility: visible;
    }
  }
  .dropdown-menu {
    display: block;
    visibility: hidden;
    top: 0;
    left: 100%;
    margin-top: -1px;
    box-shadow: 2px 1px 9px rgba(0, 0, 0, 0.25);
    transition: all 0.25s cubic-bezier(0.1, 0.65, 0.5, 1);
    transition-delay: 0.3s;
  }
  a:after {
    transform: rotate(-90deg);
  }
  .active-segment {
    color: $oto-omni;
    border-color: $oto-omni;
    font-weight: 600;
  }
}
</style>
