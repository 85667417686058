<template>
  <div class="audience-group-skeleton">
    <div class="mb-2">
      <skeleton-loader height="14px" width="114px" />
    </div>
    <div class="d-flex form">
      <skeleton-loader width="400px" />
      <skeleton-loader width="217px" />
      <skeleton-loader width="217px" />
      <skeleton-loader width="174px" />
    </div>
  </div>
</template>
<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
<style lang="scss">
.audience-group-skeleton {
  background-color: $color-white;
  padding: 25px 40px;
  margin-bottom: 18px;
  border-radius: 15px;
  box-shadow: -2px 3px 10px rgba(black, 0.1);
  .form {
    > span {
      margin-right: 15px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
