<template>
  <div id="CdpBarChart">
    <div class="lineBar d-flex justify-content-center align-items-center">
      <div
        v-if="typeBar === 'left'"
        class="colorBar-off"
        :class="'percentage-' + parseInt(percentageLeft) + ' percentage-' + typeBar"
      ></div>
      <div
        v-else
        class="percentage colorBar-on"
        :class="'percentage-' + parseInt(percentageRight) + ' percentage-' + typeBar"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CdpBarChart',
  props: {
    typeBar: {
      type: String,
      default: '',
    },
    percentageRight: {
      type: Number,
      default: null,
    },
    percentageLeft: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.lineBar {
  width: 100%;
  height: 10px;
  position: relative;
}
.percentage-left {
  border-radius: 60px;
  position: relative;
  background-color: #ddd;
  width: 100%;
  height: 7px;
  &:after {
    border-radius: 60px;
    content: '';
    display: block;
    background-color: #6c90f4;
    width: 50px;
    margin-bottom: 10px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0;
    cursor: pointer;
  }
}

.percentage-right {
  border-radius: 60px;
  position: relative;
  background-color: #ddd;
  width: 100%;
  height: 7px;
  &:after {
    border-radius: 60px;
    content: '';
    display: block;
    background-color: #6c90f4;
    width: 50px;
    margin-bottom: 10px;
    height: 100%;
    position: relative;
    top: 0px;
    right: 0;
    cursor: pointer;
  }
}

@for $i from 0 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 1%);
      width: $value;
      transition: width 500ms cubic-bezier(0.25, 0.1, 0.25, 0.1);
    }
  }
  .colorBar-on {
    &:after {
      background-color: $oto-ecommerce;
    }
  }
  .colorBar-off {
    &:after {
      background-color: $purple-200;
    }
  }
  .colorBar-omni {
    &:after {
      background-color: $oto-omni;
    }
  }
  .colorbase-orange {
    &:after {
      background-color: $color-orange;
    }
  }
  .colorbase-red {
    &:after {
      background-color: $color-red;
    }
  }
  .colorbase-green {
    &:after {
      background-color: $oto-ecommerce;
    }
  }
  .colorbase-darkblue {
    &:after {
      background-color: #1569c2;
    }
  }
  .colorbase-purple {
    &:after {
      background-color: #8941d0;
    }
  }
}
</style>
