<template>
  <div v-if="!isEmpty" id="custom-fields" class="custom-fields-table">
    <base-card-v2 class="card custom-fields-table__card">
      <alert v-if="getActiveTable === 'EXPORT'" type="info" :message="$t('settings.custom-fields.contacts-message')" />

      <div class="tbl-shell">
        <div ref="tbl-inner-wrapper" class="tbl-inner-wrapper" @scroll="$_scrollTable">
          <div ref="tbl-config" class="tbl-config">
            <div class="custom-fields-table__table-header">
              <div class="form-wrapper search-and-filter custom-fields-table__search">
                <input
                  ref="inputTerm"
                  v-model="term"
                  class="findTerm"
                  :placeholder="$t('settings.custom-fields.search-field')"
                />
                <i v-if="term !== ''" class="icon icon-close" @click="clearTerm"></i>
                <i v-else class="icon icon-search"></i>
              </div>
              <button v-if="!getIsMaximumByTable" class="btn" @click="openModal">
                {{ $t('settings.custom-fields.button') }}
              </button>
              <div
                v-else
                v-tooltip="{
                  content: $t('settings.custom-fields.tooltips.button-disabled'),
                  theme: 'default',
                  skidding: -32,
                  distance: 8,
                }"
              >
                <button :class="['btn', getIsMaximumByTable ? 'disabled' : '']" :disabled="getIsMaximumByTable">
                  {{ $t('settings.custom-fields.button') }}
                </button>
              </div>
            </div>
          </div>
          <template v-if="$_verifyLoaded('done')">
            <div ref="tableHeader" class="tbl-header">
              <div class="tbl-row">
                <div v-for="(vH, kH) in tableHeader" :key="kH" class="tbl-col">
                  <div class="header-title">
                    {{ vH.name }}
                  </div>
                  <div
                    v-if="vH.key !== 'use'"
                    :ref="vH.key"
                    :class="[
                      `order-by ${vH.key}`,
                      {
                        active: isActive === vH.key,
                      },
                    ]"
                    @click.prevent="ordenate(vH.key)"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="headerFixed" ref="header-fixed" class="tbl-header header-fixed">
              <div class="tbl-row">
                <div v-for="(vH, kH) in tableHeader" :key="kH" class="tbl-col">
                  <div class="header-title">
                    {{ vH.name }}
                  </div>
                  <div
                    :ref="vH.key"
                    :class="[
                      `order-by ${vH.key}`,
                      {
                        active: isActive === vH.key,
                      },
                    ]"
                    @click.prevent="ordenate(vH.key)"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
              </div>
            </div>
            <div ref="tbl-body" class="tbl-body">
              <div
                v-for="(item, index) in tableData.rows"
                :key="index"
                class="tbl-row"
                @click.prevent="edit(item.customField)"
              >
                <div v-for="(vH, kH) in tableHeader" :key="kH" class="tbl-col">
                  <div v-if="vH.key === 'use'" class="tbl-icons">
                    <icon-base
                      v-if="item[vH.key].segmentation === true"
                      class="icon-graphic"
                      :box="'0 0 22 22'"
                      w="22"
                      h="22"
                      v-tooltip="{
                        content: $t('settings.custom-fields.tooltips.segmentation'),
                        skidding: -32,
                        distance: 10,
                      }"
                    >
                      <icon-graphic-pizza />
                    </icon-base>
                    <i
                      v-if="item[vH.key].messages === true"
                      class="icon icon-engagement"
                      v-tooltip="{
                        content: $t('settings.custom-fields.tooltips.messages'),
                        skidding: -32,
                        distance: 10,
                      }"
                    ></i>
                    <i
                      v-if="item[vH.key].profile === true"
                      class="icon icon-mobile"
                      v-tooltip="{
                        content: $t('settings.custom-fields.tooltips.profile'),
                        skidding: -32,
                        distance: 10,
                      }"
                    ></i>
                  </div>
                  <p v-else-if="vH.key === 'type'">{{ translateType(item[vH.key]) }}</p>
                  <p
                    v-else
                    :class="
                      (['threshold', `${$_checkThreshold({ amount: item[vH.key], threshold: vH.threshold })}`],
                      vH.key === 'fieldName' ? 'color-oto-brand-omni' : '')
                    "
                  >
                    {{ $_getValueNewFormat({ vH, amount: item[vH.key] }) }}
                  </p>
                </div>
              </div>
            </div>
          </template>
          <base-loader v-show="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
        </div>
      </div>
    </base-card-v2>
  </div>
</template>
<script>
// libs
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, debounce } from 'lodash';
import bus from '@/helpers/events/bus';
import { useToast } from 'vue-toastification';
// services
import { getCustomFields } from '@/services/customFields';
// helpers
import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import IconGraphicPizza from '@/assets/vue-icons/icons/GraphicPizza';
import IconBase from '@/assets/vue-icons/IconBase';
import Alert from '@/components/_atoms/Alert';

export default {
  name: 'CustomFieldsTable',
  components: {
    BaseLoader,
    InfoCard,
    IconGraphicPizza,
    IconBase,
    Alert,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  props: {
    isEmpty: {
      type: Boolean,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      headerFixedWidth: 0,
      headerFixed: false,

      tableData: {},
      tableHeader: {},

      term: '',
      isActive: null,
      id: 'custom-fields-table',
      orderBy: '',
      conversionWindow: 30,
    };
  },
  computed: {
    ...mapGetters('customFields', ['getActiveTable', 'getIsMaximumByTable']),
  },
  watch: {
    getActiveTable() {
      this.getTableInfo();
    },
    term: debounce(function () {
      this.getTableInfo();
    }, 800),
  },
  mounted() {
    this.setup();

    bus.$on('render-cards', () => {
      this.getTableInfo();
    });

    bus.$on('custom-fields-save-succes-toast', () => {
      this.toast.success(this.$t('settings.custom-fields.toast.success'), {
        timeout: 2500,
      });
      this.getTableInfo();
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off(['render-cards', 'custom-fields-save-succes-toast']);
  },
  methods: {
    ...mapActions('customFields', [
      'setIsEmptyState',
      'setCustomFieldsAvailable',
      'setReset',
      'setCustomFieldToEdit',
      'setOpenModal',
    ]),
    setup() {
      this.getTableInfo();
      this.setCustomFieldsAvailable('EXPORT');
    },

    getTableInfo() {
      this.$_reqConfig();

      const args = {
        table: this.getActiveTable,
        orderBy: this.orderBy,
        ...(!isEmpty(this.term) && { term: this.term }),
      };

      getCustomFields(args)
        .then(({ data }) => {
          this.setIsEmptyState(data?.totalCount);
          if (this.$_verifyData(data.data)) return;

          this.tableHeader = data?.series;
          this.tableData.rows = data?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                return [data.series[i].key, val];
              }),
            );
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    ordenate(order) {
      if (!this.orderBy) {
        this.orderBy = order;
        this.isActive = order;
        this.getTableInfo();
        return;
      }

      this.isActive = order;

      if (this.orderBy === order) this.orderBy = `-${order}`;
      else this.orderBy = order;
      this.getTableInfo();
    },
    clearTerm() {
      this.term = '';
    },
    openModal() {
      this.setReset();
      bus.$emit('open-custom-fields-modal');
    },
    translateType(type) {
      const getType = {
        Decimal: this.$t('decimal'),
        Boolean: this.$t('boolean'),
        String: this.$t('string'),
        Integer: this.$t('integer'),
        Date: this.$t('date'),
      };
      return getType[type];
    },
    edit(customField) {
      this.setCustomFieldToEdit(customField);
      bus.$emit('open-custom-fields-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-fields-table {
  padding: 0;

  &__card {
    min-height: 430px;
  }

  &__table-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 17px 12px;

    button {
      height: 38px;
    }
  }

  &__search {
    width: 307px;
    position: relative;
    .findTerm {
      width: 100%;
    }

    i {
      position: absolute;
      right: 8px;
      top: 4px;
      font-size: 26px;
      color: $gray-500;
      cursor: pointer;
    }
  }

  .tbl-icons {
    display: flex;
    align-items: center;
  }

  .icon {
    &-engagement:hover,
    &-mobile:hover {
      color: $blue-500;
    }
  }
}
</style>
<style lang="scss">
#custom-fields {
  .tbl-row {
    border-top: none;
    min-width: 843px;
    .tbl-col:nth-child(1) {
      width: 25px;
    }
    .tbl-col:nth-child(2) {
      width: 200px;
    }
    .tbl-col:nth-child(4) {
      width: 100px;
    }
    .tbl-col:nth-child(5) {
      width: 100px;
    }
  }
  .tbl-col {
    text-align: left;
    max-width: unset;
    margin-right: unset;
    width: 170px;

    flex-basis: unset;
    flex-shrink: unset;
    flex-grow: unset;
    min-width: unset;
    justify-content: unset;
  }

  .header-fixed {
    width: 843px;
  }

  .tbl-body {
    .tbl-row {
      cursor: pointer;
    }
    .icon:nth-child(1),
    .icon:nth-child(2) {
      margin-right: 15px;
    }
    .icon-graphic {
      width: 19px;
      height: 19px;
    }
    .icon-graphic:hover g {
      fill: $blue-500;
    }
    .icon {
      font-size: 22px;
      color: #888888;
    }
    .icon-engagement {
      font-size: 25px;
    }
  }
  .disabled {
    background-color: #dddddd;
    opacity: unset;
    cursor: unset;
  }
}
.v-popper--theme-tooltip .v-popper__inner {
  background: $color-white;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  color: #666666;
  font-size: 12px;
  padding: 15px;
}
.v-popper--theme-tooltip .v-popper__arrow-outer {
  border-color: $color-white;
}
</style>
