<template>
  <base-card-v2 class="category-revenue">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('revenue')" icon="icon-money" />
      <div v-if="!loader.loadRevenue" class="row channel-revenue">
        <div class="col-4">
          <span class="label">{{ $t('tracked-revenue.total') }}</span>
          <span class="chart-value-big color-oto-brand-omni">{{ formatCurrency(revenue) }}</span>
        </div>
        <div class="col-4">
          <span class="label">{{ $tc('in-store', 1) }}</span>
          <span class="chart-value-big color-purple-400">{{ formatCurrency(revenueOff) }}</span>
        </div>
        <div class="col-4">
          <span class="label">{{ $t('ecommerce') }}</span>
          <span class="chart-value-big color-oto-ecommerce">{{ formatCurrency(revenueOn) }}</span>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <mini-bar-chart v-if="!isNull(jsonOff)" :prop-data="jsonOff" />
        </div>
        <div class="col-6">
          <mini-bar-chart v-if="!isNull(jsonOn)" :prop-data="jsonOn" />
        </div>
      </div>
      <bottom-button :typecard="'card'">
        <template v-slot:bottom-button>
          <label
            class="link color-oto-brand-omni col-auto text-right mr-3"
            @click="openLightbox('lightbox-category-revenue', productInfo)"
            >{{ $t('categories.see-all-origins') }}</label
          >
        </template>
      </bottom-button>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import _ from 'lodash';
import { getCategoryMetrics } from '@/services/categories';
import { openLightbox } from '@/helpers/lightbox';
import { mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
import MiniBarChart from '@/components/MiniBarChart';
import miniBarChartMixin from '@/helpers/mixins/miniBarChartMixin';
import BottomButton from '@/components/_atoms/BaseCardBottomButton';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import verifyThensMixin from '@/helpers/mixins/verifyThensMixin';

export default {
  components: {
    MiniBarChart,
    BottomButton,
    BaseLoader,
    InfoCard,
  },
  mixins: [miniBarChartMixin, verifyMixin, verifyThensMixin, numberMixin],
  props: {
    category: {
      type: String,
      default: '',
      required: true,
    },
    productInfo: {
      type: [Object, Function],
      default: null,
      required: false,
    },
  },
  data() {
    return {
      top: 3,
      instore: {},
      ecommerce: {},
      revenue: undefined,
      revenueOn: undefined,
      revenueOff: undefined,
      ecommerceTotal: 0,
      revenueTotal: 0,
      loader: {
        loadRevenue: true,
        loadInstore: true,
        loadEcommerce: true,
      },
    };
  },
  computed: {
    ...mapGetters(['selectedFilter']),
    jsonOn() {
      if (this.isNull(this.ecommerce)) return;
      const onToArray = Object.keys(this.ecommerce).map(key => [key, this.ecommerce[key]]);
      return this.$_mountMiniBarTotalRange('green', this.$t('ecommerce'), onToArray, this.ecommerceTotal);
    },
    jsonOff() {
      if (this.isNull(this.instore)) return;
      const offToArray = Object.keys(this.instore).map(key => [key, this.instore[key]]);
      return this.$_mountMiniBarTotalRange('purple', this.$tc('in-store', 1), offToArray, this.instoreTotal);
    },
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', this.fetchData);
  },
  beforeUnmount() {
    bus.$on('render-cards', this.fetchData);
  },
  methods: {
    isNull(value) {
      return _.isNull(value);
    },
    fetchData() {
      this.componentHasData = [];

      this.$_reqConfig();
      this.loader.loadInstore = true;
      this.loader.loadEcommerce = true;
      this.loader.loadRevenue = true;

      // load channel revenue
      getCategoryMetrics(this.category.toUpperCase(), 'revenue,revenue_off,revenue_on', this.selectedFilter)
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.revenue = data.metrics.revenue.data.value;
          this.revenueOn = data.metrics.revenue_on.data.value;
          this.revenueOff = data.metrics.revenue_off.data.value;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadRevenue = false;
          this.$_verifLoading();
        });
      // instore
      getCategoryMetrics(this.category.toUpperCase(), 'revenue_by_medium', this.selectedFilter, {
        filter: 'store_type:off',
        top: this.top,
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.instoreTotal = data.metrics.revenue_by_medium.revenue_total;
          this.instore = data?.metrics.revenue_by_medium.data[0]?.series?.data;
        })
        .catch(() => {
          this.instore = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadInstore = false;
          this.$_verifLoading();
        });
      // ecommerce
      getCategoryMetrics(this.category.toUpperCase(), 'revenue_by_medium', this.selectedFilter, {
        filter: 'store_type:on',
        top: this.top,
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.ecommerceTotal = data.metrics.revenue_by_medium.revenue_total;
          this.ecommerce = data?.metrics.revenue_by_medium.data[0]?.series?.data;
        })
        .catch(() => {
          this.ecommerce = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadEcommerce = false;
          this.$_verifLoading();
        });
    },
    openLightbox(id, settings) {
      openLightbox(id, settings);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-revenue {
  height: 270px;
}
</style>
