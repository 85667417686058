<template>
  <div :class="{ loadHeight: loading }" class="card segments-migration-table">
    <div v-show="$_verifyLoaded('done') && hasData" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-segment"></i>
        <span>{{ $t('migration-table') }}</span>
      </h2>
      <div v-show="hasData" class="content-chart">
        <div class="previous">{{ $t('previous') }}</div>
        <div id="segments-table-chart"></div>
        <div class="actual">{{ $t('current') }}</div>
      </div>
    </div>
    <empty-message v-if="!hasData && !loading" />
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>
<script>
import Highcharts from 'highcharts';
import getTable from '@/services/getTable';
import bus from '@/helpers/events/bus';
import EmptyMessage from '@/components/_atoms/EmptyMessage';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import { formatDecimal, formatPercent } from '@/utilities/formatters';

export default {
  components: {
    'empty-message': EmptyMessage,
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin],
  data() {
    return {
      matrix: [],
      segments: [],
      categories: [],
      total: [],
      hasData: true,
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      // reseta as variaveis
      this.reset();
      getTable({
        metrics: 'segment_matrix',
      })
        .then(response => {
          const { data } = response;
          // valida se o retorno possui dados
          if (data.has_data) {
            // salva as categorias do eixo x
            this.categories = data.columns.filter((item, index) => index !== 0 && index !== data.columns.length - 1);
            data.data.reverse().forEach((row, index) => {
              this.total.push(row.filter((column, i) => i === row.length - 1));
              row.forEach((column, i) => {
                // salva as categorias do eixo y
                if (i === 0) this.segments.push(column);
                // monta array com as coordenadas e os valores
                else if (i !== row.length - 1) this.matrix.push([i - 1, index, column]);
              });
            });
            // chama funcao para montagem do grafico
            this.loadGraphic();
          } else {
            // caso o retorno nao tenha dados, oculta a div do grafico e exibe mensagem
            this.hasData = false;
          }
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadGraphic() {
      const self = this;

      // override getContrast function
      Highcharts.Renderer.prototype.getContrast = rgba => {
        const c = Highcharts.Color(rgba).rgba;
        return c[0] + c[1] + c[2] > 500 ? '#365B6B' : '#FFFFFF';
      };

      Highcharts.chart('segments-table-chart', {
        chart: {
          type: 'heatmap',
          marginTop: 5,
          marginBottom: 80,
          plotBorderWidth: 1,
          height: 500,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: '',
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 15,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 380,
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: this.categories,
          tickLength: 0,
          tickColor: '#FFFFFF',
          lineWidth: 0,
          labels: {
            style: {
              color: '#666666',
              lineHeight: '20px',
            },
          },
        },
        yAxis: {
          categories: this.segments,
          title: null,
          labels: {
            style: {
              color: '#666666',
              lineHeight: '20px',
            },
          },
        },
        colorAxis: {
          min: 0,
          minColor: '#FFFFFF',
          maxColor: '#365B6B',
          labels: {
            format: '{value}%',
          },
        },
        plotOptions: {
          heatmap: {
            states: {
              hover: {
                color: '#61CAE4',
              },
            },
            dataLabels: {
              formatter: function formatter() {
                return formatPercent(this.point.value / 100, 2);
              },
            },
          },
        },
        tooltip: {
          backgroundColor: '#ffffff',
          padding: 15,
          style: {
            color: '#555555',
            lineHeight: '20px',
          },
          formatter: function formatter() {
            if (this.point.value !== null) {
              // segmento de anterior
              const from = this.series.yAxis.categories[this.point.y];
              // segmento atual
              const to = this.series.xAxis.categories[this.point.x];
              // calcula a quantidade de clientes de acordo com a porcentagem e o total
              const value = (self.total[this.point.y] * this.point.value) / 100;

              const text =
                from === to ? self.$i18n.t('migration-tooltip.stay') : self.$i18n.t('migration-tooltip.migration');

              return `<b>${formatPercent(this.point.value / 100, 2)}</b>
              <span style="color: #888"> (${formatDecimal(value, 1)})</span>
              ${self.$i18n.t('migration-tooltip.clients')} <b>${from}</b><br>
              ${text}<br><b>${to}</b>
              ${self.$i18n.t('migration-tooltip.period')}.`;
            }
            return self.$i18n.t('no-data');
          },
        },
        series: [
          {
            data: this.matrix,
            nullColor: '#FFFFFF',
            dataLabels: {
              enabled: true,
              color: 'contrast',
              style: {
                textOutline: 'none',
                lineHeight: '50px',
              },
            },
          },
        ],
      });
    },
    reset() {
      this.matrix = [];
      this.segments = [];
      this.categories = [];
      this.total = [];
      this.hasData = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.segments-migration-table {
  min-height: 200px;
}
.content-chart {
  margin: 0 auto;
  padding-top: 30px;
  padding-left: 30px;
  max-width: 870px;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: #c4c4c4;
    position: absolute;
    z-index: 1;
  }
  &:before {
    top: 12px;
    right: 90px;
    width: 76%;
    height: 1px;
  }
  &:after {
    top: 8%;
    left: 10px;
    width: 1px;
    height: 80%;
  }
  .previous,
  .actual {
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    background-color: $color-white;
    color: $gray-700;
    padding: 5px 15px;
    z-index: 2;
  }
  .previous {
    top: 46%;
    left: -29px;
    transform: rotate(-90deg);
  }
  .actual {
    top: 0;
    left: 50%;
  }
}
</style>
<style lang="scss">
#segments-table-chart {
  .highcharts-plot-border {
    stroke: $gray-300;
  }
}
.segments-migration-table {
  &.loadHeight {
    height: 625px;
  }
}
</style>
