<template>
  <div class="action-button ml-auto">
    <!-- case create/duplicate and integration is disabled -->
    <button v-if="!enabled" :data-cy="`btn-${serie.connect}`" class="btn enable" @click="toggle">
      {{ $tc('_activate', 2) }}
    </button>

    <!-- case create/duplicate/edit and integration is enabled -->
    <div class="buttons-active" v-else>
      <button class="disable" @click="toggle">{{ $tc('_deactivate', 2) }}</button>
      <button class="btn" @click="edit">{{ $tc('_edit', 2) }}</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    toggle: {
      type: Function,
      default: () => {},
    },
    edit: {
      type: Function,
      default: () => {},
    },
    enabled: {
      type: [Number, Boolean],
      default: null,
    },
    isView: {
      type: Boolean,
      default: null,
    },
    creator: {
      type: [Number, String],
      default: null,
    },
    serie: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    toggleVisible() {
      const { user } = this.$store.getters;

      return this.isView && +this.creator === user.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.enable {
  width: 70px;
  height: 40px;
  padding: unset !important;
}

.buttons-active {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  button {
    width: 70px;
    height: 40px;
    padding: unset !important;
  }

  .disable {
    background-color: transparent !important;
    color: $oto-omni;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
