<template>
  <div class="integration-list">
    <template v-if="$_verifyLoaded('done')">
      <integration-list-item v-for="item in integrations" :key="item.id" :integration="item" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'85'" class="integration-list__base-loader" />
    <div v-if="$_verifyLoaded('info')" class="card integration-list__info-card">
      <info-card ref="message-error" :error="hasError" />
    </div>
    <create-export-modal />
  </div>
</template>

<script>
// helpers
import bus from '@/helpers/events/bus';
import verifyMixin from '@/helpers/mixins/verifyMixin';
// services
import { getInstances } from '@/services/connectors';
// components
import IntegrationListItem from '@/components/settings/integrations/IntegrationListItem';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import CreateExportModal from '@/components/history/CreateExportModal';

export default {
  name: 'IntegrationList',
  components: {
    IntegrationListItem,
    BaseLoader,
    InfoCard,
    CreateExportModal,
  },
  mixins: [verifyMixin],
  data() {
    return {
      integrations: [],
    };
  },
  mounted() {
    this.fetchData();
    // events when reload list is needed
    bus.$on('reload-integration-list', () => {
      this.fetchData();
    });
    bus.$on('update-selected-brand', () => {
      bus.$emit('reload-integration-list');
    });
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      // get all integrations
      getInstances()
        .then(({ data }) => {
          if (this.$_verifyData(data.data[0])) return;
          this.integrations = [];
          this.integrations = data.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-list {
  &__info-card {
    min-height: 450px;
  }

  &__base-loader {
    margin-top: 60px;
  }
}
</style>
