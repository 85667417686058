<template>
  <base-card-v2>
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('omnichannel-customer-revenue')" icon="icon-customer" />
      <table v-if="ready" class="mt-5">
        <thead>
          <tr>
            <th>{{ $t('omnichannel-client-type') }}</th>
            <th>{{ $t('clients') }}</th>
            <th class="text-right">{{ $t('revenue') }}</th>
            <th class="text-right" v-html="$t('revenue-per-client')"></th>
            <th>{{ $t('repeat-clients') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="off">
            <td>
              <div class="center color-purple-400">
                <span>{{ $tc('in-store-clients') }}</span>
              </div>
            </td>
            <td>{{ $_formatDecimal(off.customers.customers_total) }}</td>
            <td>{{ formatCurrency(off.customers.revenue) }}</td>
            <td>{{ formatCurrency(off.customers.avg_revenue) }}</td>
            <td>{{ $_formatNumber(off.customers.recurring_rate, '0%') }}</td>
          </tr>
          <tr v-if="on">
            <td>
              <div class="center color-oto-ecommerce">
                <span>{{ $t('ecommerce-clients') }}</span>
              </div>
            </td>
            <td>{{ $_formatDecimal(on.customers.customers_total) }}</td>
            <td>{{ formatCurrency(on.customers.revenue) }}</td>
            <td>{{ formatCurrency(on.customers.avg_revenue) }}</td>
            <td>{{ $_formatNumber(on.customers.recurring_rate, '0%') }}</td>
          </tr>
          <tr v-if="omni">
            <td>
              <div class="center color-oto-brand-omni">
                <span>{{ $t('omni-clients') }}</span>
              </div>
            </td>
            <td>{{ $_formatDecimal(omni.customers.customers_total) }}</td>
            <td>{{ formatCurrency(omni.customers.revenue) }}</td>
            <td>
              {{ formatCurrency(omni.customers.avg_revenue) }}
            </td>
            <td>{{ $_formatNumber(omni.customers.recurring_rate, '0%') }}</td>
          </tr>
          <tr v-if="unidentified">
            <td>
              <div class="center cl-icon">
                <span>{{ $tc('unidentified-clients') }}</span>
              </div>
            </td>
            <td>
              {{ $_formatDecimal(unidentified.customers.customers_total) }}
            </td>
            <td>
              {{ formatCurrency(unidentified.customers.revenue) }}
            </td>
            <td>
              {{ formatCurrency(unidentified.customers.avg_revenue) }}
            </td>
            <td>-</td>
          </tr>
          <tr v-if="total">
            <td>{{ $t('total') }}</td>
            <td>{{ $_formatDecimal(total.customers) }}</td>
            <td>{{ formatCurrency(total.revenue) }}</td>
            <td>
              {{ formatCurrency(total.avg_revenue) }}
              <span class="legend">{{ $t('average') }}</span>
            </td>
            <td>
              {{ $_formatNumber(total.avg_recurring_rate, '0%') }}
              <span class="legend">{{ $t('average') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'OmnichannelOverviewTable',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      on: [],
      off: [],
      omni: [],
      total: [],
      unidentified: [],
      ready: false,
    };
  },
  mounted() {
    this.fetch_data();
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'attribution_table',
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) return;

          const response = data.metrics.attribution_table.data;
          this.on = response.on;
          this.off = response.off_identified;
          this.omni = response.omni;
          this.total = response.sumary;
          this.unidentified = response.unidentified;
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;

  thead {
    tr {
      th {
        color: $gray-800;
        font-size: 11px;
        padding: 0 15px 15px 0;
        vertical-align: top;

        &:not(:first-child):not(:last-child) {
          text-align: center;
        }

        &:last-child {
          text-align: right;
          width: 105px;
        }
      }
    }
  }

  tbody {
    .legend {
      display: block;
      font-weight: 300;
    }
    tr {
      &:not(.no-border) {
        border-bottom: 1px solid #e8e8e8;
      }

      td {
        font-size: 12px;
        padding: 15px;
        padding-left: 0;
        color: $gray-700;
        font-weight: 600;
        vertical-align: top;

        &:not(:first-child) {
          text-align: right;
        }

        .center {
          display: flex;
          align-items: center;
        }

        .icon {
          margin-right: 18px;
        }
      }

      &:last-child:not(.child) {
        border-bottom: none;

        td {
          font-weight: 600;
          color: $gray-700;

          &:first-child {
            text-transform: uppercase;
          }
        }
      }
      &.no-border {
        td {
          padding-bottom: 0;
        }
      }
      &.child {
        td {
          &:first-child {
            padding-left: 30px;
            padding-right: 0;
          }
          &:not(:first-child) {
            font-weight: 300;
          }
        }
      }
    }
  }
}
</style>
