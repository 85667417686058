<template>
  <base-card-v2 id="timeline" :title="$t('360.person.time-line.title')" icon="icon-cart">
    <div>
      <div id="comp-timeline"></div>
      <div id="tooltip-timeline"></div>
    </div>
  </base-card-v2>
</template>

<script>
/* eslint no-param-reassign: ["error", { "props": false }] */
import * as d3 from 'd3';
import moment from 'moment';
import eventDrops from 'event-drops';
import bus from '@/helpers/events/bus';
import { getTimeline } from '@/services/oto/profile';
import { formatCurrency } from '@/utilities/formatters';

export default {
  name: 'Timeline',
  data() {
    return {
      timelineData: {},
    };
  },
  mounted() {
    bus.$on('load-profile', customerId => {
      if (!customerId) return;

      this.loadDataProfile(customerId, this.$i18n);
    });
  },
  beforeUnmount() {
    bus.$off('load-profile');
  },
  methods: {
    formatDate(date) {
      return moment(date).format('L');
    },
    formatDateHour(date) {
      return moment(date).format('LLLL');
    },
    loadDataProfile(customerId, $i18n) {
      getTimeline(customerId)
        .then(res => {
          this.timelineData = res.data;
          this.error = '';

          const objEmail = this.timelineData.email.map(elem => {
            elem.type = 'email';
            return elem;
          });

          const objSms = this.timelineData.sms.map(elem => {
            elem.type = 'sms';
            return elem;
          });

          const objOn = this.timelineData.on.map(elem => {
            elem.type = 'on';
            return elem;
          });

          const objOff = this.timelineData.off.map(elem => {
            elem.type = 'off';
            return elem;
          });

          const tooltip = d3
            .select('#tooltip-timeline')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('min-width', '350px')
            .style('padding', '10px')
            .style('background-color', '#fff')
            .style('border-radius', '5px')
            .style('color', 'e3fbff')
            .style('box-shadow', 'rgba(0, 0, 0, 0.15) 0px 6px 16px 6px');

          const chart = eventDrops({
            d3,
            drop: {
              date: d => new Date(d.date),
              radius: d => d.size * 2 || 7,
              color: d => {
                if (d.type === 'on') {
                  return '#A167D9';
                } else if (d.type === 'off') {
                  return '#4084CB';
                } else if (d.type === 'email') {
                  return '#35BA9B';
                }
                return '#FA525D';
              },
              onMouseOver: data => {
                tooltip.transition().duration(200).style('opacity', 1).style('pointer-events', 'auto');
                if (data.type === 'email') {
                  tooltip
                    .html(
                      `<div class="content">
                        <p class="cl-mail"><strong>${$i18n.t('360.timeline.mail')}</strong></p>
                        <p><strong>${data.subject}</strong></p>
                        <p class="mb-0"><strong>${$i18n.t('360.timeline.campaign')}:</strong> ${data.name}</p>
                        <p class="mb-0"><strong>${$i18n.t('360.timeline.date')}:</strong> ${this.formatDateHour(
                        data.timestamp,
                      )}</p>
                        <div class="row mt-3">
                          <div class="col">
                            <p class="mb-0"><strong>${$i18n.t('360.timeline.open')}?</strong> ${
                        data.opened === true ? $i18n.t('360.timeline.yes') : $i18n.t('360.timeline.no')
                      }</p>
                          </div>
                          <div class="col">
                            <p class="mb-0"><strong>${$i18n.t('360.timeline.click')}?</strong> ${
                        data.clicked === true ? $i18n.t('360.timeline.yes') : $i18n.t('360.timeline.no')
                      }</p>
                          </div>
                        </div>
                      </div>`,
                    )
                    .style('left', `${d3.event.pageX - 270}px`)
                    .style('top', `${d3.event.layerY + 20}px`);
                } else if (data.type === 'sms') {
                  tooltip
                    .html(
                      `<div class="content">
                        <p class="cl-sms"><strong>${$i18n.t('360.timeline.sms')}</strong></p>
                        <p><strong>${$i18n.t('360.timeline.campaign')}:</strong> ${data.name}</p>
                        <p class="mb-0"><strong>${$i18n.t('360.timeline.send')}</strong>: ${this.formatDateHour(
                        data.timestamp,
                      )}</p>
                      </div>`,
                    )
                    .style('left', `${d3.event.pageX - 270}px`)
                    .style('top', `${d3.event.layerY + 20}px`);
                } else if (data.type === 'on') {
                  tooltip
                    .html(
                      `<div class="content">
                        <p class="color-oto-ecommerce"><strong>${$i18n.t('360.timeline.site')}</strong></p>
                        <p><strong>${data.store}</strong></p>
                        <p><strong>${$i18n.t('360.timeline.date')}: </strong> ${this.formatDate(data.date)}</p>
                        <p><strong>${$i18n.t('360.timeline.order')}:</strong> ${data.order_id}</p>
                        <p><strong>${$i18n.t('360.timeline.product-quantity')}:</strong> ${data.items}</p>
                        <p>
                          <strong>${$i18n.t('360.timeline.value')}:</strong> ${formatCurrency(data.amount)}
                        </p>
                      </div>`,
                    )
                    .style('left', `${d3.event.pageX - 270}px`)
                    .style('top', `${d3.event.layerY + 20}px`);
                } else if (data.type === 'off') {
                  tooltip
                    .html(
                      `<div class="content">
                        <p class="color-purple-200"><strong>${$i18n.t('360.timeline.store')}</strong></p>
                        <p><strong>${data.store}</strong></p>
                        <p><strong>${$i18n.t('360.timeline.date')}: </strong> ${this.formatDate(data.date)}</p>
                        <p><strong>${$i18n.t('360.timeline.order')}:</strong> ${data.order_id}</p>
                        <p><strong>${$i18n.t('360.timeline.product-quantity')}:</strong> ${data.items}</p>
                        <p>
                          <strong>${$i18n.t('360.timeline.value')}:</strong> ${formatCurrency(data.amount)}
                        </p>
                      </div>`,
                    )
                    .style('left', `${d3.event.pageX - 270}px`)
                    .style('top', `${d3.event.layerY + 20}px`);
                }
              },
              onMouseOut: () => {
                tooltip.transition().duration(500).style('opacity', 0).style('pointer-events', 'none');
              },
            },
            line: {
              color: '#DDDDDD',
            },
            label: {
              padding: 40,
              width: 150,
            },
            axis: {
              formats: {
                days: '%d',
                months: '%b',
                weeks: '%d',
                year: '%Y',
              },
              labels: {
                color: 'red',
              },
            },
          });
          const repositoriesData = [
            {
              name: $i18n.t('360.timeline.site'),
              data: objOn,
            },
            {
              name: $i18n.t('360.timeline.store'),
              data: objOff,
            },
            {
              name: $i18n.t('360.timeline.mail'),
              data: objEmail,
            },
            {
              name: $i18n.t('360.timeline.sms'),
              data: objSms,
            },
          ];
          d3.select('#comp-timeline')
            .data([repositoriesData])
            .call(chart)
            .selectAll('.drop-line')
            .selectAll('.line-label')
            .style('fill', d => {
              if (d.name === 'Site') {
                return '#A167D9';
              } else if (d.name === 'Loja Física') {
                return '#4084CB';
              } else if (d.name === 'E-mail') {
                return '#35BA9B';
              }
              return '#FA525D';
            });
        })
        .catch(error => {
          this.profileData = '';
          this.error = error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#timeline {
  min-height: 230px;
}
.card-title {
  margin-bottom: 37px;
}
.title-popover {
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
}
.commit {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content {
  flex: 1 0 0;
}
#tooltip-timeline {
  z-index: 2;
}
/* The element to hover over */
.popoverpmweb2 {
  border-top: 7px solid rgba(0, 0, 0, 0.85);
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  bottom: -7px;
  content: '';
  display: block;
  left: 50%;
  margin-left: -7px;
  position: absolute;
}

@media only screen and (max-width: 576px) {
  .line-label {
    display: none !important;
  }
}
</style>
<style lang="scss">
#timeline {
  min-height: 150px;
}
#comp-timeline {
  .event-drop-chart {
    max-height: 210px;
  }
  /* svg {
    min-width: auto;
    width: auto !important;
  } */
  line {
    stroke: #dddddd;
    stroke-width: 1px;
    fill: none;
  }
  .axis text,
  .bound text {
    fill: #888888;
    font-family: 'Montserrat';
    font-size: 11px;
  }
  .axis {
    .domain {
      stroke: #dddddd;
    }
  }
  .line-label {
    font-size: 12px;
    font-weight: 600;
  }
}
</style>
