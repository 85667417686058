import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-996e1c4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card seller-ranking" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_seller_ranking_title = _resolveComponent("seller-ranking-title")!
  const _component_seller_ranking_link = _resolveComponent("seller-ranking-link")!
  const _component_seller_ranking_chart_list = _resolveComponent("seller-ranking-chart-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_seller_ranking_chart_list, {
      onStoreChanged: _ctx.storeChanged,
      "selected-store": _ctx.selectedStore
    }, {
      title: _withCtx(() => [
        _createVNode(_component_seller_ranking_title)
      ]),
      link: _withCtx(() => [
        _createVNode(_component_seller_ranking_link, {
          stores: _ctx.stores,
          "selected-store": _ctx.selectedStore
        }, null, 8, ["stores", "selected-store"])
      ]),
      _: 1
    }, 8, ["onStoreChanged", "selected-store"])
  ]))
}