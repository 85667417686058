<template>
  <section>
    <header v-if="!loading">
      <router-link
        v-show="!isEmpty"
        class="btn btn-new-audience btn-link"
        data-cy="btn-new-audience"
        :to="{ name: 'AudienceCreateOld', params: { pageHistory: table.page } }"
        :title="$t('audiences.new-audience')"
      >
        {{ $t('audiences.new-audience') }}
      </router-link>
    </header>
    <audiences-list-skeleton v-if="loading" :table="table" :type="type" />
    <audiences-empty v-if="isEmpty && !loading" />
    <audiences-list v-if="!isEmpty && !loading" :id="id" :type="type" :data="table" :audiences="audiences" />

    <load-spinner id="audiences" color="purple" :text="$t('spinner')" />
  </section>
</template>

<script>
import AudiencesList from '@/components/audiences/list/AudiencesList';
import AudiencesEmpty from '@/components/audiences/list/AudiencesEmpty';
import AudiencesListSkeleton from '@/components/audiences/list/AudiencesListSkeleton';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import bus from '@/helpers/events/bus';
import { getAudiences } from '@/services/audiences';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
import { validateAudienceActivationRoute } from '@/helpers/verify-routes';

export default {
  name: 'Audiences',
  components: {
    AudiencesList,
    AudiencesEmpty,
    AudiencesListSkeleton,
    LoadSpinner,
  },
  data() {
    return {
      id: 'audiences-list',
      loading: true,
      audiences: [],
      type: 'normal',
      table: {
        page: Number(this.$route.query.page) || 1,
        limit: 10,
        count: 0,
        order: '',
      },
    };
  },
  computed: {
    isEmpty() {
      return !this.table.count;
    },
    getRoutes() {
      return this.$store.getters?.getRoutes;
    },
  },
  watch: {
    $route(to) {
      this.table.page = to.query.page;
    },
  },
  mounted() {
    this.checkAndRedirect();
    useMixpanelConfig('view_audience_management_audience');

    this.triggerGetAudiences();

    bus.$on('render-cards', () => {
      this.triggerGetAudiences();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.triggerGetAudiences();
    });
    bus.$on(`${this.id}-change-order`, this.triggerGetAudiences);
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    checkAndRedirect() {
      if (!validateAudienceActivationRoute('audiences-activation')) this.$router.push('/new-audiences');
    },
    triggerGetAudiences() {
      getAudiences({
        orderBy: this.table.order,
        limit: this.table.limit,
        page: this.table.page,
      })
        .then(({ data }) => {
          this.table.count = data.totalCount;
          this.formatAudiences(data.data);
        })
        .catch(() => {
          this.table.count = 0;
          this.audiences = [];
          this.loading = false;
        });
    },
    formatAudiences(data) {
      function AUDIENCE(aud) {
        [this.id, this.name, this.contacts, this.creator, this.creatorId, this.date, this.connectors, this.copyable] =
          aud;
        this.renameActive = false;
      }

      this.audiences = [];
      for (let i = 0; i < data.length; i += 1) {
        this.audiences.push(new AUDIENCE(data[i]));
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  margin: 40px 0 25px;
  display: flex;
  align-items: center;
  h1 {
    margin: 0;
  }
  .btn-new-audience {
    background-color: transparent;
    font-weight: 600;
    font-size: 12px;
    color: $oto-omni;
    border: none;
    cursor: pointer;
    position: relative;
    right: 0;
    text-decoration: underline;
  }
}
</style>
