<template>
  <div id="login-recover-success">
    <div class="card-header justify-content-start d-flex">
      <button class="back" @click.prevent="loaderBackToEmail">
        {{ $t('back') }}
      </button>
    </div>
    <div class="card-body">
      <div class="title">{{ $t('login.password-recover') }}</div>
      <p class="description" v-html="$t('login.forgot-success', { email: this.$parent.recoverEmail })"></p>
    </div>
  </div>
</template>

<script>
import loginLoaderMixin from '@/helpers/mixins/loginLoaderMixin';

export default {
  mixins: [loginLoaderMixin],
  data() {
    return {
      loading: true,
      showText: true,
    };
  },
  methods: {
    loaderBackToEmail() {
      return this.$_loaderLogin('email', this.loading, this.showText);
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: block;
  text-align: left;
  color: $gray-700;
  font-size: 12px;
  font-weight: normal;
}
</style>
