<template>
  <section>
    <raw-data-access-header />
    <raw-data-access-filter />
    <raw-data-access-summary />
    <base-card-v2 v-if="getSelectedTable === 'OPTOUT_CUSTOMERS'" class="raw-data-access__card">
      <alert type="info" :message="$t('integrations.raw-data-access.opt-out-customers.alert')" />
    </base-card-v2>
    <base-card-v2 v-else-if="getSelectedTable === 'CONSOLIDATED_CUSTOMERS'" class="raw-data-access__card">
      <alert type="info" :message="$t('integrations.raw-data-access.consolidated_customers.alert')" />
    </base-card-v2>
    <base-card-v2 v-else-if="getSelectedTable === 'CUSTOMERS_REGISTERED_APP'" class="raw-data-access__card">
      <alert type="info" :message="$t('integrations.raw-data-access.customers-app.alert')" />
    </base-card-v2>
    <raw-data-access-table />
    <base-loader v-show="loadingExport" color="colored-block" class="render-loader"></base-loader>
  </section>
</template>

<script>
// libs
import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
// components
import RawDataAccessHeader from '@/features/RawDataAccess/components/RawDataAccessHeader/RawDataAccessHeader';
import RawDataAccessTable from '@/features/RawDataAccess/components/RawDataAccessTable/RawDataAccessTable';
import RawDataAccessFilter from '@/features/RawDataAccess/components/RawDataAccessFilter/RawDataAccessFilter';
import RawDataAccessSummary from '@/features/RawDataAccess/components/RawDataAccessSummary/RawDataAccessSummary';
import Alert from '@/components/_atoms/Alert';
import BaseCardV2 from '@/components/_atoms/BaseCardV2';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';
import bus from '@/helpers/events/bus';

export default {
  name: 'RawDataAccess',
  components: {
    RawDataAccessHeader,
    RawDataAccessTable,
    RawDataAccessFilter,
    RawDataAccessSummary,
    Alert,
    BaseCardV2,
    BaseLoader,
  },
  setup() {
    const store = useStore();

    const loadingExport = ref(false);

    onMounted(() => {
      useMixpanelConfig('view_integrations_rawDataAccess');

      bus.$on('render-page-loader', isActive => {
        loadingExport.value = isActive;
      });
    });

    onBeforeUnmount(() => {
      bus.$off('render-page-loader');
    });

    const getSelectedTable = computed(() => store.getters['rawDataAccess/getSelectedTable']);

    return {
      getSelectedTable,
      loadingExport,
    };
  },
};
</script>

<style lang="scss" scoped>
.raw-data-access {
  &__card {
    padding: 16px !important;

    .alert {
      margin-bottom: 0 !important;
    }
  }
}

.render-loader {
  background-color: #232642;
  height: 100%;
  left: 50%;
  opacity: 0.7;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 9999;
}
</style>
