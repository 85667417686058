<template>
  <div class="lightbox-header">
    <div class="left">
      <h4 class="lightbox-title d-flex align-items-center">
        <i v-show="icon" class="icon" :class="`icon-${icon || 'money'}`" aria-hidden="true"></i>
        <span :title="title">{{ title }}</span>
      </h4>
    </div>
    <div class="right d-flex ml-auto">
      <div v-if="showSegmentGroup" v-show="segmentGroup" class="lightbox-header-segment d-flex align-items-center">
        <i class="icon icon-segment"></i>
        <span>{{ segmentGroup }}</span>
      </div>
      <div v-if="showDate" class="lightbox-header-dates d-flex align-items-center">
        <i class="icon icon-calendar-30px"></i>
        <modal-dates :subtracted-date="subtractedDate" />
      </div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$parent.closeLightbox">
        <i aria-hidden="true" class="icon icon-close"></i>
      </button>
    </div>
  </div>
</template>

<script>
import ModalDates from '@/components/ModalDates';
import store from '@/store';

export default {
  components: {
    ModalDates,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    showSegmentGroup: {
      type: Boolean,
      default: null,
    },
    subtractedDate: {
      type: String,
      default: null,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      segmentGroup: '',
    };
  },
  mounted() {
    if (!this.showSegmentGroup) return;
    const { segmentGroupId } = store.getters.selectedSegment;
    const newsegmentGroupId = segmentGroupId;
    const segmentGroup = store.getters.segments?.find(segment => segment.group_id === newsegmentGroupId);
    this.segmentGroup = segmentGroup?.group_name;
  },
};
</script>

<style lang="scss" scoped>
.lightbox-header {
  border: 0 none;
  border-radius: 0;
  min-height: 80px;
  padding: 0 50px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: $color-white;
  color: $gray-700;

  .lightbox-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    padding: 0;
    margin: 0;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 540px;
    }

    cursor: default;
    .icon {
      font-size: 26px;
      margin-right: 15px;
    }
  }

  .lightbox-header-segment,
  .lightbox-header-dates {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin-left: 25px;
    cursor: default;

    span {
      font-weight: 600;
    }

    .icon {
      font-size: 26px;
      margin-right: 15px;
    }
  }

  @media (max-width: $tablet-max-width) {
    justify-content: space-between;
    .lightbox-title {
      order: 0;
      flex-basis: 50%;
    }
    .modal-header-segment {
      order: 1;
      margin: 0;
    }
    .close {
      order: 2;
    }
    .modal-header-dates {
      display: none !important;
    }
  }
  .close {
    margin: 0;
    padding: 5px;
    opacity: 1;
    color: $gray-700;
    margin-left: 25px;

    .icon-close {
      font-size: 25px;
    }
  }
}
</style>
