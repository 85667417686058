<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <base-card-v2 class="most-purchased">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header icon="icon-customer" :title="$t('product.most-purchased')" />
      <div class="row mt-4">
        <div class="col-6">
          <mini-bar-chart :prop-data="jsonSize" />
        </div>
        <div class="col-6">
          <mini-bar-chart :prop-data="jsonColor" />
        </div>
      </div>
      <bottom-button :typecard="'card'">
        <template v-slot:bottom-button>
          <label
            class="link color-oto-brand-omni col-auto text-right mr-3"
            @click="openLightbox('lightbox-products-most-purchased', productInfo)"
            >{{ $t('categories.see-all') }}</label
          >
        </template>
      </bottom-button>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import { openLightbox } from '@/helpers/lightbox';
import { getTable } from '@/services/product';
import bus from '@/helpers/events/bus';
import MiniBarChart from '@/components/MiniBarChart';
import miniBarChartMixin from '@/helpers/mixins/miniBarChartMixin';
import BottomButton from '@/components/_atoms/BaseCardBottomButton';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import verifyThensMixin from '@/helpers/mixins/verifyThensMixin';
import { formatDecimal, formatNumeral } from '@/utilities/formatters';

export default {
  components: {
    MiniBarChart,
    BottomButton,
    BaseLoader,
    InfoCard,
  },
  mixins: [miniBarChartMixin, verifyMixin, verifyThensMixin],
  props: {
    productID: {
      type: String,
      default: '',
      required: true,
    },
    productInfo: {
      type: [Object, Function],
      default: null,
      required: false,
    },
  },
  data() {
    return {
      size: [],
      totalSize: 0,
      color: [],
      totalColor: 0,
      readySize: false,
      loader: {
        loadingSize: false,
        loadingColor: false,
      },
    };
  },
  computed: {
    jsonSize() {
      return this.$_mountMiniBarTotalRange('omni', this.$tc('size', 2), this.size, this.totalSize, 'quantity');
    },
    jsonColor() {
      return this.$_mountMiniBarTotalRange('darkblue', this.$tc('color', 2), this.color, this.totalColor, 'quantity');
    },
  },
  mounted() {
    this.fetchData();

    // called after a global date change or brand change
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.componentHasData = [];

      this.$_reqConfig();
      this.loader.loadingSize = true;
      this.loader.loadingColor = true;

      getTable({
        productID: this.productID,
        table: 'products_per_size',
        limit: 4,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.size = data.data;
          this.totalSize = data.value_total;
        })
        .catch(() => {
          this.size = [];
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingSize = false;
          this.$_verifLoading();
        });

      getTable({
        productID: this.productID,
        table: 'products_per_color',
        limit: 4,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.color = data.data;
          this.totalColor = data.value_total;
        })
        .catch(() => {
          this.color = [];
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingColor = false;
          this.$_verifLoading();
        });
    },
    /**
     * Create MiniBarChart
     * @param {array} color - bar color
     * @param {string} title - chart title
     * @param {object} data - object content
     */
    $_mountMiniBar(color, title, data) {
      const json = {};
      json.data = [];
      json.title = title;
      json.suffix = '';

      if (data) {
        // total used to calculate percentage
        const total = data.reduce((a, c) => a + c[1], 0);

        // loop with [key, value] of data param to mount json.data
        data.map(([key, value]) => {
          const percent = ((value / total) * 100).toFixed(0);
          return json.data.push({
            text: key,
            amount: formatNumeral(percent, '0%'),
            percentageamount: percent,
            colorbase: color,
            tooltip: `${key}: <br> ${formatNumeral(percent, '0%')} (<strong>${formatDecimal(value, 1)}</strong>)`,
          });
        });
      }
      return json;
    },
    openLightbox(id, settings) {
      openLightbox(id, settings);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  min-height: 232px;
}
</style>
