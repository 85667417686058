<template>
  <section id="campaigns-metrics" class="card">
    <div v-if="$_verifyLoaded('done')">
      <div class="chart-title-container row">
        <i class="icon dashboard-icon light-gray icon-trending-18px"></i>
        <h2 class="card-title mb-0 ml-3">{{ $t('nps.metrics') }}</h2>
      </div>
      <div class="row col-sm-12">
        <div class="col-sm-5">
          <email-metrics />
        </div>
        <div class="col-sm-7 row">
          <div class="col-6">
            <chart-list :rows-list="percentages" />
          </div>
          <div class="col-6">
            <chart-list :rows-list="rates" />
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </section>
</template>

<script>
import _ from 'lodash';
import bus from '@/helpers/events/bus';
import ChartList from '@/components/_organisms/ChartList';
import EmailMetrics from '@/components/nps/_molecules/EmailMetrics';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { getPercentual, getNumeral } from '@/utilities/numbers';
import { formatNumber } from '@/utilities/formatters';
import { getMetrics } from '@/services/nps/campaigns';
import { verifyMixin } from '@/helpers/mixins';

// open_rate,cto,ctr,ratings,sent,opens
// 35BA9B
export default {
  name: 'CampaignMetrics',
  components: {
    ChartList,
    EmailMetrics,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      percentages: [],
      rates: [],
    };
  },
  mounted() {
    this.fetchData();

    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      getMetrics('open_rate,cto,ctr,ratings,sent,opens')
        .then(res => {
          if (this.$_verifyHasData(res.metrics)) return;
          if (!res.success) return;

          const dataset = Object.fromEntries(
            Object.entries(res.metrics).map(([key, val]) => {
              return [key, val.data.amount];
            }),
          );

          const { open_rate, cto, ctr, ratings, sent, opens } = dataset;
          const metrics = { open_rate, cto, ctr };
          const rates = {
            ratings_sent_rate: getNumeral(sent) ? ratings / sent : 0,
            ratings_opens_rate: getNumeral(opens) ? ratings / opens : 0,
          };

          const maxVal = _.max([...Object.values(metrics), ...Object.values(rates)]);
          this.percentages = Object.entries(metrics).map(([key, val]) => this.mapEntry(key, val, maxVal));
          this.rates = Object.entries(rates).map(([key, val]) => this.mapEntry(key, val, maxVal));
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapEntry(label, val, max) {
      const labelFormat = this.$t(`360.nps.${label}`);
      return {
        label: labelFormat,
        progress: String(getPercentual(val, max)),
        value: formatNumber(val * 100, '0.0 %'),
        color: 'green',
        tooltip: undefined,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#campaigns-metrics {
  height: 165px;
}
.chart-title-container {
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.dashboard-icon {
  font-size: 26px;
  margin-left: 10px;
  &.light-gray {
    color: $gray-500;
  }
}
</style>
