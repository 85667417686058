<template>
  <el-dialog
    v-model="isVisible"
    :id="id"
    backdrop="static"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="user-question-modal"
  >
    <template v-if="!loading && !errorModal">
      <div data-cy="user-question-modal">
        <header>
          <icon-base :color="'#ec3942'" :box="'0 0 60 60'" w="60" h="60">
            <icon-alert />
          </icon-base>
        </header>
        <div class="row">
          <div class="col-12 pt-3">
            <span class="title user-question-modal__title">{{ $t('settings.users.reset-question') }}</span>
          </div>
        </div>
        <footer class="user-question-modal__action-buttons">
          <button class="btn" @click.prevent="resetPassword" data-cy="btn-user-question-modal-reset">
            {{ $t('settings.users.reset') }}
          </button>
          <button class="btn user-question-modal__action-buttons--close" @click.prevent="closeModal">
            {{ $t('settings.users.cancel') }}
          </button>
        </footer>
      </div>
    </template>
    <template v-if="loading && !errorModal">
      <dialog-loader :text="this.$t('settings.users.reset-message')" />
    </template>
    <template v-if="errorModal">
      <dialog-error :close="closeModal" />
    </template>
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
// components
import DialogLoader from '@/components/_atoms/DialogLoader';
import DialogError from '@/components/_atoms/DialogError';
// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconAlert from '@/assets/vue-icons/icons/Alert';

export default {
  name: 'UserQuestionModal',
  components: {
    IconBase,
    IconAlert,
    DialogLoader,
    DialogError,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: 'user-question-modal',
      loadingModal: false,
      errorModal: false,
      isVisible: false,
    };
  },
  watch: {
    loading(loading) {
      this.loadingModal = loading;
      if (!this.loadingModal && this.errorModal === false) this.closeModal();
    },
    error(error) {
      this.errorModal = error;
    },
  },
  mounted() {
    bus.$on('openUserQuestionModal', () => {
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off('openUserQuestionModal');
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    resetPassword() {
      bus.$emit('user-reset-password');
    },
  },
};
</script>

<style lang="scss">
.user-question-modal {
  text-align: center;
  width: 540px;
  max-width: 540px;
  min-height: 300px;

  .el-overlay .el-dialog__body {
    padding: 40px 0;
  }

  &__title {
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
  }

  &__action-buttons {
    justify-content: space-evenly;
    width: 360px;
    margin: 28px auto;
    display: flex;

    &--close {
      background-color: $gray-600 !important;

      &:hover {
        background-color: darken($gray-600, 20%) !important;
      }
    }

    .btn {
      width: 160px;
      height: 40px;
    }
  }
}
</style>
