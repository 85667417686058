<template>
  <base-card-v2 class="product-details" :title="title">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="title" />
      <template slot:item-top>
        <div class="category pb-1">
          <span v-if="details.category">
            {{ details.category }} <span v-if="details.subcategory">/ {{ details.subcategory }}</span>
          </span>
          <span v-else class="empty">{{ $t('product.empty.no-category') }}</span>
        </div>
      </template>
      <ul class="details-list pt-3">
        <li>
          <i class="icon icon-category-18px"></i>
          <span v-if="details.brand"
            >{{ $tc('brands', 1) }} <strong>{{ details.brand }}</strong></span
          >
          <span v-else class="empty"> {{ $t('product.empty.no-brand') }}</span>
        </li>
        <li>
          <i class="icon icon-category-18px"></i>
          <span v-if="details.collection"
            >{{ $t('collection') }} <strong>{{ details.collection }}</strong></span
          >
          <span v-else class="empty"> {{ $t('product.empty.no-collection') }}</span>
        </li>
        <li>
          <i class="icon icon-box icon-18"></i>
          <span v-if="skus" v-html="skus"></span>
          <span v-else class="empty">{{ $t('product.empty.no-sku') }}</span>
        </li>
        <li>
          <i class="icon icon-in-store icon-22"></i>
          <span v-if="details.high_season"
            >{{ $t('product.high-season') }}: <strong>{{ season }}</strong></span
          >
          <span v-else class="empty"> {{ $t('product.empty.no-highseason') }}</span>
        </li>
      </ul>
    </div>
    <details-skeleton v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="error" />
  </base-card-v2>
</template>

<script>
import DetailsSkeleton from '@/components/products/product/overview/DetailsSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  components: {
    'details-skeleton': DetailsSkeleton,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    details: {
      type: Object,
      default: () => {},
    },
    load: {
      type: Boolean,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    skus() {
      const length = this.details.skus ? this.details.skus.length : 0;
      if (length) {
        return `<span class="strong">${length}</span> ${this.$tc('product.registered-sku', length)}`;
      }
      return length;
    },
    title() {
      return this.details.name ? this.details.name : this.$t('product.empty.no-name');
    },
    season() {
      let varSeason = '';
      if (this.details.high_season === 'P') {
        varSeason = this.$tc('seasons.spring');
      } else if (this.details.high_season === 'V') {
        varSeason = this.$tc('seasons.summer');
      } else if (this.details.high_season === 'I') {
        varSeason = this.$tc('seasons.winter');
      } else if (this.details.high_season === 'O') {
        varSeason = this.$tc('seasons.fall');
      }
      return varSeason;
    },
  },
  watch: {
    load() {
      this.loading = this.load;
    },
    details() {
      this.$_verifyData(this.details);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-22 {
  font-size: 22px;
}

.category {
  color: $gray-700;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.empty {
  color: $gray-500;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 11px 0;
    color: $gray-700;
    border-bottom: 1px solid $gray-300;
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
    }

    span {
      font-size: 12px;
    }

    strong {
      font-weight: 600;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
  }
}
</style>
