<template>
  <div class="wrap select">
    <div class="input-wrapper">
      <input
        id="columns-search"
        v-model="term"
        type="text"
        class="no-click"
        :class="{ error: !success }"
        :placeholder="$t('360.select')"
        autocomplete="off"
        @focus="openDropdown"
        @click="openDropdown"
        @blur="blur"
      />

      <i :class="`icon icon-${showColumns ? 'search' : 'select-12px'}`"></i>
    </div>
    <div
      v-show="!success"
      class="error-alert icon icon-attention-14px"
      v-tooltip="{
        theme: 'default',
        content: $t('360.filter.value-error'),
        html: true,
      }"
    ></div>
    <div v-if="showColumns" class="columns-list no-click">
      <div class="list no-click">
        <ul v-if="filtered" class="groups customScrollBar no-click">
          <li v-for="group in filtered" :key="group.key" class="group no-click" :data-value="group.key">
            <strong>{{ group.key }}</strong>
            <ul v-if="!!group.columns" class="columns">
              <li
                v-for="column in group.columns"
                :key="column.key"
                class="column no-click"
                :data-value="column.key"
                @click="change"
              >
                {{ column.name }}
              </li>
            </ul>
          </li>
        </ul>
        <p v-else class="pl-3 pr-3 pt-2">{{ $t('360.filter.no-results') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { validate } from '@/helpers/oto/search';
import bus from '@/helpers/events/bus';

export default {
  name: 'CustomersFilterOption',
  props: {
    filterOptions: {
      type: Array,
      default: () => [],
    },
    updateOperatorOptions: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    serie: {
      type: Number,
      default: 0,
    },
  },
  success: true,
  data() {
    return {
      success: true,
      term: undefined,
      showColumns: false,
      dataProp: {},
    };
  },
  computed: {
    filtered() {
      let groups = this.filterOptions;
      if (this.term) {
        groups = [];

        this.filterOptions.forEach(group => {
          if (group && group.columns) {
            const columnsFiltered = group.columns.filter(column =>
              column.name.toLowerCase().includes(this.term.toLowerCase()),
            );
            if (columnsFiltered && columnsFiltered.length) {
              groups.push({ key: group.key, columns: columnsFiltered });
            }
          }
        });
      }
      return groups;
    },
    selectedColumnName() {
      let columnName;
      if (this.dataProp.column) {
        this.filterOptions.forEach(group => {
          if (group && group.columns) {
            const filter = group.columns.filter(col => col.key === this.dataProp.column)[0];
            if (filter) columnName = filter.name;
          }
        });
      }
      return columnName;
    },
  },
  created() {
    bus.$on('validate-fields', () => {
      this.validate();
    });
    this.dataProp = this.data;
  },
  mounted() {
    if (this.dataProp.column) {
      this.term = this.selectedColumnName;
    }
    this.bodyClick();
  },
  beforeUnmount() {
    bus.$off('validate-fields');
  },
  methods: {
    blur() {
      setTimeout(() => {
        if (this.term !== this.selectedColumnName) {
          this.term = undefined;
          this.dataProp['column'] = undefined;
        }
      }, 500);
    },
    validate() {
      const result = validate.field(null, null, this.dataProp.column);
      this.success = result;
    },
    change(event) {
      this.term = event.target.textContent.trim();
      this.dataProp['column'] = event.target.dataset.value;
      this.showColumns = false;

      // validate column
      this.validate();
      // update operators list
      this.updateOperatorOptions();
    },
    openDropdown() {
      this.showColumns = true;
    },
    bodyClick() {
      document.querySelector('body').addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.showColumns = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 100%;
  position: relative;
  input {
    cursor: pointer;
  }
  .icon {
    position: absolute;
    right: 12px;
    pointer-events: none;
    &.icon-select-12px {
      top: 10px;
      font-size: 14px;
      color: $gray-700;
    }
    &.icon-search {
      right: 5px;
      top: 1px;
      color: $oto-omni;
    }
  }
}
.wrap {
  position: relative;
}
#columns-search {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 22px 7px 14px;

  &:focus {
    padding-right: 35px;
  }
}
.columns-list {
  width: 100%;
  padding-top: 35px;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0;
  border: 1px solid $gray-500;
  border-top: 0;
  border-radius: 18px;
  z-index: 100;
  .list {
    height: 100%;
    overflow: hidden;
    background-color: $color-white;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  ul {
    list-style: none;
    padding: 0;
    pointer-events: auto;

    font-size: 12px;
    color: $gray-700;
    line-height: 26px;

    &.groups {
      max-height: 310px;
      overflow-y: auto;
      margin: 10px 0;
    }
    &.columns {
      margin: 0;
      li {
        padding: 0 15px;
        &:hover {
          background-color: $gray-300;
          cursor: pointer;
        }
      }
    }
  }
  strong {
    padding: 0 15px;
  }
}
</style>
