<template>
  <section id="customers">
    <h1 v-show="term">{{ `${$t('360.search.page-title')} "${term}"` }}</h1>
    <div>
      <customers-header />
      <empty-state
        v-show="!hasData || hasError"
        :title="$t('360.customers.empty-state-customer.title')"
        :text="$t('360.customers.empty-state-customer.text')"
        :has-button="false"
      >
        <template v-slot:image>
          <img src="@/assets/img/oto/emoji/large/oto_emoji_large_maintenance.png" alt="" />
        </template>
      </empty-state>
      <div v-show="hasData && firstRequestLoaded && !hasError">
        <customers-search-box />
        <customers-base-filter :series="series" />
        <customers-base-table :filters="series" :term="term" />
      </div>
    </div>
  </section>
</template>
<script>
import bus from '@/helpers/events/bus';
import CustomersHeader from '@/components/oto/customers/components/CustomersHeader';
import CustomersBaseFilter from '@/components/oto/customers/CustomersBaseFilter';
import CustomersBaseTable from '@/components/oto/customers/CustomersBaseTable';
import CustomersSearchBox from '@/components/oto/customers/CustomerSearchBox';
import EmptyState from '@/components/_atoms/EmptyState';
import { dateFormat } from '@/utilities/constants';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'Customers',
  components: {
    CustomersHeader,
    CustomersBaseFilter,
    CustomersBaseTable,
    CustomersSearchBox,
    EmptyState,
  },
  data() {
    return {
      term: this.$route.query.t,
      series: [],
      hasData: true,
      hasError: false,
      firstRequestLoaded: false,
    };
  },
  watch: {
    $route(to) {
      this.term = to.query.t;
      bus.$emit('refresh-search');
    },
  },
  mounted() {
    useMixpanelConfig('view_oto_customers');
    // listener to events
    this.listeners();
  },
  beforeUnmount() {
    this.listeners(true);
  },
  methods: {
    addSerie() {
      const template = {
        id: new Date().getTime(),
        column: undefined,
        operator: undefined,
        value: [],
        referenceObjects: [], // stores key:value objects reference from multiple selections
      };
      this.series.push(template);
    },
    removeSerie({ serie }) {
      if (this.series.length > 1) {
        const filters = this.series.filter(item => item.id !== serie);
        this.series = [...filters];
      }
    },
    updateDate({ event, serie, between }) {
      this.series[serie].value[between] = event.format(dateFormat);
      this.series = [...this.series];
    },
    listeners(off = false) {
      bus[off ? '$off' : '$on']('add-serie', this.addSerie);
      bus[off ? '$off' : '$on']('remove-serie', this.removeSerie);
      bus[off ? '$off' : '$on']('change-customer-date', this.updateDate);
    },
  },
};
</script>
