import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const URI = 'cashback';

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-getPersonas
 */
export const getPersonas = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getPersonas`,
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-getCountRemaining
 */
export const getCountRemaining = ({ personas }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getCountRemaining`,
    params: {
      brand: brand.id,
      personas,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-getPredictions
 */
export const getPredictions = ({ range, startDate, endDate }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getPredictions`,
    params: {
      brand: brand.id,
      range,
      startDate,
      endDate,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-putCashback
 */
export const putCashback = ({ expireDays, creditDays, enabled, range }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/putCashback`,
    data: {
      brand: brand.id,
      expireDays,
      creditDays,
      enabled,
      range,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-getCashback
 */
export const getCashback = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getCashback`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-getBalance
 */
export const getBalance = ({ customerId, balance }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getBalance`,
    params: {
      brand: brand.id,
      customerId,
      balance,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-getRules
 */
export const getRules = ({ customerId }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getRules`,
    params: {
      brand: brand.id,
      customerId,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-validateUse
 */
export const postValidateUse = ({ customerId, value }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/validateUse`,
    data: {
      brand: brand.id,
      customerId,
      value,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Cashback-getHistory
 */
export const getHistoric = ({ customerId, limit }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getHistory`,
    params: {
      brand: brand.id,
      customerId,
      limit,
    },
  });
};
