<template>
  <div class="indicators-comparable-filters">
    <div id="comparison-filters-title" class="form-group mt-3">
      <comparison-filters-title
        :title="$t('360.indicators.comparable.title')"
        :subtitle="$t('360.sellers-analytics.comparable.subtitle')"
        :full-name="getActiveSellerObject?.fullName"
      />
    </div>

    <div id="comparison-metrics" class="form-group mt-3">
      <comparison-metrics
        :filters="filters"
        :action="setFilters"
        :metrics="$parent.metrics"
        :showAlertTooltip="showSelectMetricsTooltip"
      />
    </div>

    <div id="comparison-criteria" class="form-group mt-3">
      <comparison-criteria :setCriteriaName="setCriteriaName" />
    </div>

    <div id="comparison-stores" class="form-group mt-3">
      <comparison-stores
        :stores="stores"
        :showAlertTooltip="showSelectStoresTooltip"
        :filters="filters"
        :getCriteria="getCriteria"
        :setFilters="setFilters"
        :resetFilters="resetFilters"
      />
    </div>

    <div id="filter-handle" class="form-group mt-3">
      <button class="btn btn-update" @click.prevent="filterHandler">
        {{ $t('360.sellers-analytics.comparable.update') }}
      </button>

      <a href="#" class="btn-reset" @click.prevent="resetComparableFilters">
        {{ $t('360.sellers-analytics.comparable.reset') }}
      </a>
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapActions, mapGetters, mapState } from 'vuex';

import ComparisonFiltersTitle from '@/components/oto/indicators/ComparisonFiltersTitle';
import ComparisonCriteria from '@/components/comparisons/comparisonCriteria/ComparisonCriteria.vue';
import ComparisonMetrics from '@/components/comparisons/comparisonMetrics/ComparisonMetrics';
import ComparisonStores from '@/components/comparisons/comparisonStores/ComparisonStores';

export default {
  name: 'ComparableFilters',
  components: {
    ComparisonFiltersTitle,
    ComparisonCriteria,
    ComparisonMetrics,
    ComparisonStores,
  },
  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      term: '',
      ready: false,
      selectedStates: [],
      selectedRegions: [],
      showSelectStoresTooltip: false,
      showSelectMetricsTooltip: false,
    };
  },
  computed: {
    ...mapState('sellersAnalytics', ['filters']),
    ...mapGetters('sellersAnalytics', ['getActiveSellerObject', 'getFilters', 'getCriteria']),
    comparisonStoresList() {
      const list = this.getComparisonStoresList();
      const regions = Object.keys(this.getComparisonStoresList());
      regions.forEach(region => {
        const states = Object.keys(list[region]);
        // ordena lojas de A-Z
        states.forEach(state => {
          list[region][state].sort((a, b) => (a.name > b.name ? 1 : -1));
        });
      });
      return list;
    },
    hasMetrics() {
      return this.getFilters?.metrics.length;
    },
    hasStores() {
      return this.getFilters?.comparisonStores.length;
    },
  },
  mounted() {
    this.reset();
    bus.$on('render-cards', () => {
      this.reset();
    });
  },
  beforeUnmount() {
    bus.$off(['render-cards']);
  },
  methods: {
    ...mapActions('sellersAnalytics', [
      'setParallelCoordinates',
      'clearParallelCoordinates',
      'setFilters',
      'resetFilters',
      'setCriteriaName',
    ]),

    // Manipula o filtro
    filterHandler() {
      this.handleComparableMetricsValues();

      bus.$emit('wrap-stores-plot');
    },

    // Manipula os valores das métricas comparáveis
    handleComparableMetricsValues() {
      if (!this.hasMetrics) return this.emitMetricsTooltip();
      else if (!this.hasStores) return this.emitStoresTooltip();
      else this.fetchParallelCoordinates();
    },

    // Emite o tooltip de métricas
    emitMetricsTooltip() {
      this.showSelectMetricsTooltip = true;
      setTimeout(() => (this.showSelectMetricsTooltip = false), 2000);
    },

    // Emite o tooltip de lojas
    emitStoresTooltip() {
      this.showSelectStoresTooltip = true;
      setTimeout(() => (this.showSelectStoresTooltip = false), 2000);
    },

    // Verifica se há métricas e lojas selecionadas antes de buscar os dados para criar o gráfico de coordenadas paralelas.
    fetchParallelCoordinates() {
      if (!this.hasMetrics || !this.hasStores) return this.clearParallelCoordinates();

      this.setParallelCoordinates();
    },

    // Reseta os filtros de comparação, emite eventos e limpa os gráficos de coordenadas paralelas.
    resetComparableFilters() {
      bus.$emit('reset-comparable-stores');

      this.clearParallelCoordinates();
      this.resetFilters();
    },

    // Reseta as seleções, limpando os arrays de regiões e estados selecionados e definindo a flag 'ready' como falsa.
    reset() {
      this.selectedRegions.length = 0;
      this.selectedStates.length = 0;
      this.ready = false;
    },

    // Obtém a lista de lojas para comparação, organizando-as por região e estado.
    getComparisonStoresList() {
      const comparisonStoresList = {};

      this.$parent.stores.forEach(store => {
        if (store.region) {
          if (typeof comparisonStoresList[store.region] !== 'object') comparisonStoresList[store.region] = {};
          if (!Array.isArray(comparisonStoresList[store.region][store.state]))
            comparisonStoresList[store.region][store.state] = [];
          comparisonStoresList[store.region][store.state].push({
            id: store.id,
            name: store.store,
          });
        }
      });
      this.ready = true;
      return comparisonStoresList;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  border-radius: 100px;
  margin-top: 45px;
  padding: 0 15px;
}
.btn-reset {
  display: block;
  font-weight: 600;
  line-height: 46px;
  font-size: 12px;
  text-align: center;
  color: $gray-700;
}
.form-group {
  position: relative;
}
</style>
