//libs
import i18n from '@/i18n';

const rawDataAccess = {
  namespaced: true,
  state: {
    selectedTable: 'ORDERS',
    isFilterOpen: false,
    filters: [],
    exportTableParameters: {},
    isApplyFilter: false,
    exportItems: [
      {
        value: 'csv',
        title: i18n.global.t('360.export-type', { value: '.CSV' }),
      },
      {
        value: 'xls',
        title: i18n.global.t('360.export-type', { value: '.XLS' }),
      },
      {
        value: 'xlsx',
        title: i18n.global.t('360.export-type', { value: '.XLSX' }),
      },
    ],
    orderBy: '',
    summaryInfos: {
      totalCount: 0,
      totalTable: 0,
    },
    isSummaryLoading: false,
    isSummaryVisible: false,
    hasSummaryError: false,
  },
  mutations: {
    SET_SELECTED_TABLE(state, payload) {
      state.selectedTable = payload;
    },

    SET_IS_FILTER_OPEN(state, payload) {
      state.isFilterOpen = payload;
    },

    SET_FILTERS(state, payload) {
      state.filters = payload;
    },

    SET_IS_APPLY_FILTER(state, payload) {
      state.isApplyFilter = payload;
    },

    SET_EXPORT_TABLE_PARAMETERS(state, payload) {
      state.exportTableParameters = payload;
    },

    SET_ORDER_BY(state, payload) {
      state.orderBy = payload;
    },

    SET_SUMMARY_INFOS(state, payload) {
      state.summaryInfos.totalCount = payload.totalCount;
      state.summaryInfos.totalTable = payload.totalTable;
    },

    SET_SUMMARY_LOADING(state, payload) {
      state.isSummaryLoading = payload;
    },

    SET_IS_SUMMARY_VISIBLE(state, payload) {
      state.isSummaryVisible = payload;
    },

    SET_HAS_SUMMARY_ERROR(state, payload) {
      state.hasSummaryError = payload;
    },
  },
  actions: {
    setSelectedTable({ commit, dispatch }, payload) {
      commit('SET_SELECTED_TABLE', payload);
      dispatch('setOrderBy', '');
    },

    setIsFilterOpen({ commit }, payload) {
      commit('SET_IS_FILTER_OPEN', payload);
    },

    setFilters({ commit }, payload) {
      commit('SET_FILTERS', payload);
    },

    setIsApplyFilter({ commit }, payload) {
      commit('SET_IS_APPLY_FILTER', payload);
    },

    setExportTableParameters({ commit }, payload) {
      commit('SET_EXPORT_TABLE_PARAMETERS', payload);
    },

    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload);
    },

    setSummaryInfos({ commit }, payload) {
      commit('SET_SUMMARY_INFOS', payload);
    },

    setSummaryLoading({ commit }, payload) {
      commit('SET_SUMMARY_LOADING', payload);
    },

    setIsSummaryVisible({ commit }, payload) {
      commit('SET_IS_SUMMARY_VISIBLE', payload);
    },

    setHasSummaryError({ commit }, payload) {
      commit('SET_HAS_SUMMARY_ERROR', payload);
    },
  },
  getters: {
    getSelectedTable: state => state.selectedTable,
    getIsFilterOpen: state => state.isFilterOpen,
    getFilters: state => state.filters,
    getIsApplyFilter: state => state.isApplyFilter,
    getExportItems: state => state.exportItems,
    getExportTableParameters: state => state.exportTableParameters,
    getOrderBy: state => state.orderBy,
    getSummaryInfos: state => state.summaryInfos,
    getIsSummaryVisible: state => (!state.hasSummaryError ? state.isSummaryVisible : false),
    getSummaryLoading: state => state.isSummaryLoading,
  },
};

export default rawDataAccess;
