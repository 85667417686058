<template>
  <section class="audience-group" :class="include ? 'include' : 'exclude'">
    <div v-if="isEditable" class="wrap-delete-group">
      <button class="delete-group" @click.prevent="removeCurrentGroup">
        <i class="icon icon-trash"></i> {{ $t('audiences.delete-group') }}
      </button>
    </div>
    <div class="series">
      <serie-form
        v-show="!series.length || formEnabled"
        :group="id"
        :include="include"
        :disable="disableForm"
        :get-selected-column="getSelectedColumn"
        :selected-column-infos="selectedColumnInfos"
      />

      <div v-if="series.length" class="series-list d-flex align-items-end flex-wrap" :class="{ 'mt-2': formEnabled }">
        <series-list
          v-for="(serie, index) in series"
          :id="index"
          :key="index"
          :group="id"
          :include="include"
          :data="serie"
          :is-editable="isEditable"
          :form-enabled="formEnabled"
          :enable="enableForm"
          :get-serie="getSelectedColumnInfos"
          :data-cy="include ? 'audience-list-include' : 'audience-list-exclude'"
        />
        <button v-show="!formEnabled && isEditable" class="btn add-serie" @click="enableForm">
          <i class="icon icon-plus" v-tooltip="{ content: $t('audiences.add-serie'), distance: 8 }"> </i>
        </button>
      </div>
    </div>
    <span class="more-top" aria-hidden="true">
      <span class="text">{{ $t('or') }}</span>
    </span>
    <span class="more-bottom" aria-hidden="true"></span>
  </section>
</template>

<script>
import AudienceSerieCreate from '@/components/audiences/create/composition/AudienceSerieCreate';
import AudienceSerie from '@/components/audiences/create/composition/AudienceSerie';
import bus from '@/helpers/events/bus';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AudiencesGroup',
  components: {
    'serie-form': AudienceSerieCreate,
    'series-list': AudienceSerie,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    series: {
      type: Array,
      default: null,
    },
    include: {
      type: Boolean,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      formEnabled: this.isEditable,
      selectedColumnInfos: {},
    };
  },
  computed: {
    ...mapGetters('audience', [
      'columnOptions', // -> this.columnOptions
    ]),
  },
  watch: {
    series() {
      if (this.series.length === 0) {
        this.enableForm();
        this.selectedColumnInfos = {};
        bus.$emit('audience-reset-form');
      } else if (this.series.length) {
        this.disableForm();
      } else this.enableForm();
    },
  },
  mounted() {
    bus.$on('update-selected-column-info', this.getSelectedColumn);
  },
  beforeUnmount() {
    bus.$off('update-selected-column-info');
  },
  methods: {
    removeCurrentGroup() {
      this.removeGroup({ include: !!this.include, group: this.id });
    },
    getSelectedColumnInfos(column) {
      let selectedColumn = [];
      if (column) {
        this.columnOptions.forEach(group => {
          const filter = group.columns.filter(col => col.key === column)[0];
          if (filter) selectedColumn = filter;
        });
      }
      return selectedColumn;
    },
    getSelectedColumn(column) {
      this.selectedColumnInfos = {};
      if (column) {
        this.selectedColumnInfos = this.getSelectedColumnInfos(column);
      }
    },
    enableForm() {
      this.formEnabled = true;
    },
    disableForm() {
      if (this.series.length) this.formEnabled = false;
    },
    ...mapActions('audience', [
      'removeGroup', // -> removeGroup()
    ]),
  },
};
</script>

<style lang="scss" scoped>
.audience-group {
  background-color: $color-white;
  padding: 25px 40px;
  border-radius: 15px;
  box-shadow: -2px 3px 10px rgba(black, 0.1);
  position: relative;

  &:first-of-type:last-of-type.include {
    .delete-group {
      display: none;
    }
  }

  &:first-of-type {
    .more-bottom {
      display: none !important;
    }
  }
  &:last-of-type {
    .more-top {
      display: none !important;
    }
  }

  .more-top,
  .more-bottom {
    &:before {
      content: '';
      width: 30px;
      height: 1px;
      background-color: $gray-500;
      display: block;
      position: absolute;
      top: 50%;
      left: -30px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .more-bottom {
    &:after {
      content: '';
      width: 1px;
      height: 50%;
      background-color: $gray-500;
      display: block;
      position: absolute;
      bottom: 50%;
      left: -30px;
    }
  }
  .more-top {
    &:after {
      content: '';
      width: 1px;
      height: calc(50% + 20px);
      background-color: $gray-500;
      display: block;
      position: absolute;
      top: 50%;
      left: -30px;
    }
    .text {
      position: absolute;
      left: -40px;
      bottom: -21px;
      background-color: $oto-omni;
      display: flex;
      width: 30px;
      height: 20px;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 600;
      border-radius: 10px;
      font-size: 10px;
      text-transform: uppercase;
      cursor: default;
      z-index: 10;
    }
  }

  .wrap-delete-group {
    position: absolute;
    right: 40px;
    top: 15px;
    .delete-group {
      background-color: transparent;
      font-weight: 600;
      font-size: 12px;
      color: $oto-omni;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }
  .add-serie {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: $gray-600;
    padding: 0;
    &:hover {
      background-color: $oto-omni;
    }
  }
}
</style>
