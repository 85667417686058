import numeral from 'numeral';

// load a locale
numeral.register('locale', 'pt-br', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'mi',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: 'R$',
  },
});
