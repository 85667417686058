<template>
  <section class="cashback__create-board">
    <div class="row">
      <div class="col-4">
        <cashback-select />
      </div>
      <div class="col-8">
        <cashback-details />
      </div>
    </div>
    <div class="row cashback__range-block">
      <div class="col-7 mt-4">
        <cashback-ranges />
      </div>
      <!-- TODO -->
      <!-- <div class="col-5 mt-5">
        <cashback-investment />
      </div> -->
    </div>
    <hr class="solid my-4" />
    <div class="row">
      <div class="col-12 cashback__submit-block">
        <Button
          :text="$t('360.cashback.save')"
          :exec-function="submit"
          :is-loading="loadingSubmit"
          :is-disabled="getEmptyPersonaRange || loadingSubmit || !getPermissionCreate"
          size="large"
        ></Button>
      </div>
    </div>
  </section>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapState, mapActions, mapGetters } from 'vuex';
// components
import CashbackSelect from '@/components/oto/cashback/_atoms/CashbackSelect';
import CashbackDetails from '@/components/oto/cashback/_atoms/CashbackDetails';
import CashbackRanges from '@/components/oto/cashback/_molecules/CashbackRanges';
// import CashbackInvestment from '@/components/oto/cashback/_molecules/CashbackInvestment';

import Button from '@/components/_atoms/Button';

export default {
  name: 'CashbackCreateBoard',
  components: {
    CashbackSelect,
    CashbackDetails,
    CashbackRanges,
    // CashbackInvestment,
    Button,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('cashback', ['loadingSubmit']),
    ...mapGetters('cashback', [
      'getEmptyPersonaRange',
      'getCashbackRanges',
      'getCashbackFreezeRange',
      'getPermissionCreate',
    ]),
  },
  methods: {
    ...mapActions('cashback', ['submitCashback']),

    /**
     * @description validate if some range has high value inside
     */
    submit() {
      const stateRanges = [...this.getCashbackRanges, ...this.getCashbackFreezeRange];
      if (stateRanges.some(el => el.hasHighValue)) {
        bus.$emit('open-cashback-high-value-modal');
        return;
      } else this.submitCashback();
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback__create-board {
  .cashback__range-block {
    min-height: 500px;
  }
  .cashback__submit-block {
    display: flex;
    justify-content: flex-end;
    .cashback__submit {
      width: 194px;
      height: 40px;
    }
  }
}
</style>

<style lang="scss">
.cashback__create-board {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
