import { createApp } from 'vue';
import App from '@/App';
import FloatingVue from 'floating-vue';

import 'floating-vue/dist/style.css';

const app = createApp(App);

const tooltip = {
  themes: {
    default: {
      $extend: 'tooltip',
      triggers: ['hover'],
    },
    error: {
      $extend: 'tooltip',
      triggers: [],
      autoHide: ['focus'],
    },
    vSelect: {
      $extend: 'tooltip',
      triggers: ['hover'],
      html: true,
      distance: 22,
    },
    'tooltip-blue': {
      $extend: 'tooltip',
      triggers: ['hover'],
      html: true,
    },
    'error-no-autohide': {
      $extend: 'tooltip',
      triggers: [],
      autohide: false,
    },
  },
};

app.use(FloatingVue, tooltip);
