<template>
  <base-card-v2 class="audience-header-skeleton">
    <div class="mb-2">
      <skeleton-loader height="16px" width="130px" />
    </div>
    <div class="mb-4">
      <skeleton-loader width="330px" />
    </div>
    <div class="d-flex">
      <skeleton-loader height="16px" width="180px" />
      <skeleton-loader height="16px" width="110px" class="m-60" />
      <skeleton-loader height="16px" width="150px" />
    </div>
  </base-card-v2>
</template>
<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
<style lang="scss" scoped>
.audience-header-skeleton {
  margin: 20px 0 40px;
}
.m-60 {
  margin: 0 60px;
}
</style>
