
import { computed, onMounted, onBeforeMount, ref, reactive, defineComponent, toRefs } from 'vue';
import type { Ref } from 'vue';
import { first, last, values } from 'lodash';
import { useStore } from 'vuex';
import numeral from 'numeral';
import bus from '@/helpers/events/bus';

import { getPercentual, getNumeral } from '@/utilities/numbers';
import useVerifyMixin from '@/utilities/mixins/useVerifyMixin';
import '@/plugins/tooltip/tooltip';

import ChartList from '@/components/_organisms/ChartList.vue';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';
import InfoCard from '@/components/_atoms/InfoCard.vue';

import {
  TopFollowUpTypes,
  DataTopFollowUpTypeResponse,
  FollowUpTypeMetricData,
} from '@/features/ConversationRanking/types';

import { fetchFollowUpMetrics, fetchTopFollowUpMetrics } from '@/features/ConversationRanking/services';

export default defineComponent({
  name: 'ConversationRanking',
  components: {
    ChartList,
    BaseLoader,
    InfoCard,
  },
  setup() {
    const { hasError, isLoading, reqConfig, verifyData, verifyLoaded } = useVerifyMixin();

    const store = useStore();
    const metrics: Ref<object> = ref([]);
    const tab = 'tab-store-comparison';

    const events = reactive<{
      objectChartListTopConversation: TopFollowUpTypes[];
      objectChartListLowerConversation: TopFollowUpTypes[];
      isViewChartList: boolean;
      isViewChartListLower: boolean;
    }>({
      objectChartListTopConversation: [],
      objectChartListLowerConversation: [],
      isViewChartList: false,
      isViewChartListLower: false,
    });

    const getStoreId = computed(() => store.getters['indicators/getStoreId']);
    const getSellerId = computed(() => store.getters['indicators/getSellerId']);
    const getSourceId = computed(() => store.getters['indicators/getSourceId']);
    const getCampaignId = computed(() => store.getters['indicators/getCampaignId']);
    const getConversionWindow = computed(() => store.getters['indicators/getConversionWindow']);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function fillChartListTopConversationObject(response: any[], getTopConversation: number | null) {
      response.map(item => {
        events.objectChartListTopConversation.push({
          label: item?.store,
          progress: String(getPercentual(item?.activitiesStartedShare, getTopConversation)),
          value: numeral(item?.activitiesStartedShare * 100).format('0.0%'),
          color: 'lightblue',
          valueTooltip: String(item?.activitiesStartedShare),
          labelTooltip: item?.store,
        });
      });

      handleChartList();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function fillChartListLowerConversationObject(response: any[], getLowerConversation: number | null) {
      response.map(item => {
        events.objectChartListLowerConversation.push({
          label: item?.store,
          progress: String(getPercentual(item?.activitiesStartedShare, getLowerConversation)),
          value: numeral(item?.activitiesStartedShare * 100).format('0.0%'),
          color: 'red',
          valueTooltip: String(item?.activitiesStartedShare),
          labelTooltip: item?.store,
        });
      });
    }

    function resolveDataTopConversation(data: DataTopFollowUpTypeResponse) {
      const dataTopConversation = data?.higherValues.slice(0, 5);
      const conversationTop = dataTopConversation?.map(item => item?.activitiesStartedShare);
      const getTopConversation = getNumeral(first(values(conversationTop)));

      fillChartListTopConversationObject(dataTopConversation, getTopConversation);
    }

    function resolveDataLowerConversation(data: DataTopFollowUpTypeResponse) {
      const dataLowerConversation = data?.lowerValues.slice(0, 5);
      const conversationLower = dataLowerConversation?.map(item => item?.activitiesStartedShare);
      const getLowerConversation = getNumeral(last(values(conversationLower)));

      fillChartListLowerConversationObject(dataLowerConversation, getLowerConversation);
    }

    function fetchFollowUp() {
      reqConfig();

      const args = {
        source: getSourceId.value,
        storeId: getStoreId.value,
        sellerId: getSellerId.value,
        campaignId: getCampaignId.value,
        lookback: getConversionWindow.value,
      };

      fetchFollowUpMetrics(args)
        .then(response => {
          if (verifyData(response?.data)) return (isLoading.value = false);
          metrics.value = response?.data;

          if (handleStoreSellerCampaignId()) fetchTopFollowUp();
          else isLoading.value = false;
        })
        .catch(() => {
          hasError.value = true;
        });
    }

    function fetchTopFollowUp() {
      reqConfig();

      const args = {
        source: getSourceId.value,
        storeId: getStoreId.value,
        sellerId: getSellerId.value,
        campaignId: getCampaignId.value,
        lookback: getConversionWindow.value,
      };

      fetchTopFollowUpMetrics(args)
        .then(response => {
          if (verifyData(response?.data)) return;

          events.objectChartListTopConversation = [];
          events.objectChartListLowerConversation = [];

          const data = response?.data;

          resolveDataTopConversation(data);
          resolveDataLowerConversation(data);
        })
        .catch(() => {
          hasError.value = true;
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function reset() {
      fetchFollowUp();
      events.objectChartListTopConversation = [];
      events.objectChartListLowerConversation = [];
      events.isViewChartList = false;
      events.isViewChartListLower = false;
    }

    function handleStoreSellerCampaignId() {
      return !getStoreId.value && !getSellerId.value && !getCampaignId.value;
    }

    function handleChartList() {
      if (events.objectChartListTopConversation.length) events.isViewChartList = true;
      if (events.objectChartListTopConversation.length >= 5) events.isViewChartListLower = true;
    }

    function getFormattedAmount(metric: FollowUpTypeMetricData) {
      const metricType = metric?.data?.type;
      const amount = metric?.data?.amount;

      let formattedAmount = '';

      if (metricType === 'integer') {
        formattedAmount = numeral(amount).format('0');
      } else if (metricType === 'float') {
        formattedAmount = numeral(amount).format('0.00');
      } else if (metricType === 'percent') {
        formattedAmount = numeral(amount * 100).format('0.00%');
      }

      return formattedAmount;
    }

    function resolveTooltip(metric: FollowUpTypeMetricData) {
      const metricType = metric?.data?.type;
      const amount = metric?.data?.amount;

      if (metricType !== 'percent') return;

      return amount;
    }

    onMounted(() => {
      reset();

      bus.$on(`indicators-select-emit-${tab}`, () => {
        reset();
      });
    });

    onBeforeMount(() => {
      bus.$off(`indicators-select-emit-${tab}`);
    });

    return { ...toRefs(events), metrics, verifyLoaded, hasError, getFormattedAmount, resolveTooltip };
  },
});
