/**
 * @description export all menu options
 */
export const menuItems = [
  {
    id: 'analytics',
    name: 'header.sidebar.analytics',
    url: '/analytics',
  },
  {
    id: 'analytics-o2o',
    name: 'header.sidebar.analytics-o2o',
    url: '/analytics/o2o',
  },
  {
    id: 'analytics-products',
    name: 'header.sidebar.products',
    url: '/products',
  },
  {
    id: 'analytics-stores',
    name: 'header.sidebar.stores',
    url: '/stores',
  },
  {
    id: 'audience-mgmt',
    name: 'header.sidebar.audiences',
    url: '/audiences-mgmt',
  },
  {
    id: 'audiences-activation',
    name: 'header.sidebar.audiences-activation',
    url: '/audiences',
  },
  {
    id: 'new-audience-activation',
    name: 'header.sidebar.new-audience-activation',
    url: '/new-audiences',
  },
  {
    id: 'audiences-labels',
    name: 'header.sidebar.audiences-labels',
    url: '/labels',
  },
  {
    id: 'audiences-personas-compare',
    name: 'header.sidebar.personas-compare',
    url: '/personas/compare',
  },
  {
    id: 'audiences-personas',
    name: 'header.sidebar.audiences-personas',
    url: '/personas',
  },
  {
    id: 'performance',
    name: 'header.sidebar.performance',
    url: '/performance',
  },
  {
    id: 'performance-audiences',
    name: 'header.sidebar.performance-audiences',
    url: '/campaigns',
  },
  {
    id: 'performance-email',
    name: 'header.sidebar.performance-email',
    url: '/performance/email',
  },
  {
    id: 'performance-push',
    name: 'header.sidebar.performance-push',
    url: '/performance/push',
  },
  {
    id: 'performance-sms',
    name: 'header.sidebar.performance-sms',
    url: '/performance/sms',
  },
  {
    id: 'performance-tags',
    name: 'header.sidebar.performance-tags',
    url: '/performance/tags',
  },
  {
    id: 'performance-webpush',
    name: 'header.sidebar.performance-webpush',
    url: '/performance/webpush',
  },
  {
    id: 'nps',
    name: 'header.sidebar.nps',
    url: '/nps',
  },
  {
    id: 'nps-analytics',
    name: 'header.sidebar.nps-analytics',
    url: '/nps/analytics',
  },
  {
    id: 'nps-campaigns',
    name: 'header.sidebar.nps-campaigns',
    url: '/nps/campaigns',
  },
  {
    id: '360',
    name: 'header.sidebar.360',
    url: '/oto',
  },
  {
    id: 'customer-distribution',
    name: 'header.sidebar.oto-customer-distribution',
    url: '/oto/customer-distribution',
  },
  {
    id: '360-customers',
    name: 'header.sidebar.360-customers',
    url: '/oto/customers',
  },
  {
    id: '360-campaigns',
    name: 'header.sidebar.360-campaigns',
    url: '/oto/campaigns',
  },
  {
    id: 'campaign-new',
    name: 'header.sidebar.campaign-new',
    url: '/oto/actions',
  },
  {
    id: '360-indicators',
    name: 'header.sidebar.360-indicators',
  },
  {
    id: 'oto-engagement-report',
    name: 'header.sidebar.oto-engagement-report',
    url: '/oto/engagementReport',
  },
  {
    id: 'oto-sales-report',
    name: 'header.sidebar.oto-sales-report',
    url: '/oto/sales-report',
  },

  {
    id: 'sales-report-new',
    name: 'header.sidebar.oto-sales-report-new',
    url: '/oto/sales-report-new',
  },
  {
    id: 'oto-registration-culture',
    name: 'header.sidebar.oto-registration-culture',
    url: '/oto/registration-culture',
  },
  {
    id: '360-indicators-old',
    name: 'header.sidebar.360-indicators-old',
    url: '/oto/indicators-old',
  },
  {
    id: '360-sellers',
    name: 'header.sidebar.360-sellers',
    url: '/oto/sellers',
  },
  {
    id: '360-push',
    name: 'header.sidebar.360-push',
    url: '/oto/push',
  },
  {
    id: '360-sales',
    name: 'header.sidebar.360-sales',
    url: '/oto/sales',
  },
  {
    id: 'oto-attachments',
    name: 'header.sidebar.oto-attachments',
    url: '/oto/attachments',
  },
  {
    id: 'oto-messages',
    name: 'header.sidebar.oto-messages',
    url: '/oto/messages',
  },
  {
    id: 'oto-cashback',
    name: 'header.sidebar.oto-cashback',
    url: '/oto/cashback',
  },
  {
    id: 'oto-cashback-report',
    name: 'header.sidebar.oto-cashback-report',
    url: '/oto/cashback-report',
  },
  {
    id: 'reports',
    name: 'header.sidebar.reports',
    url: '/reports',
  },
  {
    id: 'welcome',
    name: 'header.sidebar.welcome',
    url: '/',
  },
  {
    id: 'oto-academy',
    name: 'header.sidebar.oto-academy',
    url: '/oto-academy',
  },
  {
    id: 'integrations',
    name: 'header.sidebar.integrations',
    url: '/connect',
  },
  {
    id: 'connect-dashboard',
    name: 'header.sidebar.connect-dashboard',
    url: '/connect/dashboard',
  },
  {
    id: 'connect-import',
    name: 'header.sidebar.connect-import',
    url: '/connect/import',
  },
  {
    id: 'connect-export',
    name: 'header.sidebar.connect-export',
    url: '/connect/export',
  },
  {
    id: 'connect-raw-data-access',
    name: 'header.sidebar.connect-raw-data-access',
    url: '/connect/raw-data-access',
  },
  {
    id: 'connect-data-ingestion-history',
    name: 'header.sidebar.connect-data-ingestion-history',
    url: '/connect/data-ingestion-history',
  },
  {
    id: 'connect-data-inconsistency',
    name: 'header.sidebar.connect-data-inconsistency',
    url: '/connect/data-inconsistency',
  },
];

/**
 * @description list all user menu options
 */
export const menuUserOptions = [
  {
    id: 'glossary',
    name: 'header.menu.glossary',
    url: '/glossary',
  },
  {
    id: 'config',
    name: 'header.menu.settings',
    url: '/settings',
  },
];

/**
 * @description list all user menu options
 */
export const menuUserLogout = {
  id: 'logout',
  name: 'header.menu.logout',
  url: '',
};

/**
 * @description exclude datepicker just for these routes - urls
 */
export const excludeDateRoutes = [
  'audiences',
  'personas',
  'campaigns',
  'glossary',
  'settings',
  'reports',
  'oto/customers',
  'oto/campaigns',
  'oto/sellers',
  'oto/push',
  'oto/attachments',
  'oto/messages',
  'oto/cashback',
  'oto/engagement-report',
  '/connect/import',
  '/connect/export',
  'connect/raw-data-access',
  'connect/data-inconsistency',
  'labels',
  'connect/data-ingestion-history',
  'oto-academy',
  'oto/customer-distribution',
  'oto/sales-report-new',
  'oto/actions',
];

/**
 * @description show datepicker with other settings just for engagament report
 */
export const showDatepickerWithOtherRules = ['oto/engagement-report'];

/**
 * @description show datepicker with other settings just for sales report new
 */
export const showDatepickerWithCurrentAndFowardDatesDisabled = ['oto/sales-report-new'];

/**
 * @description exceptionally datepicker is include for these routes - urls
 */
export const exceptionallyIncludeDateRoutes = ['/nps/campaigns', '/oto/cashback-report'];

/**
 * @description exclude segments just for these routes - urls
 */
export const excludeSegmentRoutes = [
  'audiences',
  'campaigns',
  'cdp',
  'performance',
  'glossary',
  'reports',
  'settings',
  'welcome',
  'oto/customers',
  'oto/campaigns',
  'oto/engagement-report',
  'oto/indicators',
  'oto/indicators-old',
  'oto/sellers',
  'oto/push',
  'oto/sales',
  'oto/attachments',
  'oto/messages',
  'oto/cashback',
  'labels',
  'personas',
  'audiences-personas-compare',
  'connect/import',
  'connect/export',
  'connect/raw-data-access',
  'connect/data-ingestion-history',
  'connect/data-inconsistency',
  'oto-academy',
  'oto/customer-distribution',
  'oto/registration-culture',
  'oto/actions',
];

export const excludeFilterRoutes = [
  'audiences',
  'campaigns',
  'cdp',
  'performance',
  'performance/email',
  'performance/push',
  'performance/sms',
  'performance/tags',
  'performance/webpush',
  'glossary',
  'reports',
  'settings',
  'welcome',
  'oto/customers',
  'oto/campaigns',
  'oto/engagement-report',
  'oto/indicators',
  'oto/indicators-old',
  'oto/sellers',
  'oto/push',
  'oto/sales',
  'oto/attachments',
  'oto/messages',
  'oto/cashback',
  'oto/cashback-report',
  'oto/customer-distribution',
  'oto/registration-culture',
  'labels',
  'personas',
  'audiences-personas-compare',
  'connect',
  'connect/dashboard',
  'connect/import',
  'connect/export',
  'nps',
  'nps/analytics',
  'nps/campaigns',
  'oto-academy',
];

/**
 * @description exceptionally segment is include for these routes - urls
 */
export const exceptionallyIncludeSegmentRoutes = ['/', '/nps/campaigns'];

/**
 * @description add routes url in experimental mode
 */
export const includeExperimentalRoutes = ['websitebehavior'];

/**
 * @description exceptionally show conversion window on header
 */
export const exceptionallyIncludeConversionWindowRoutes = ['/oto/sales-report-new', '/oto/engagement-report'];

/**
 * @description exceptionally show icon star new feature
 */
export const exceptionallyIncludeIconStarOnRoutes = ['/oto/sales-report-new', '/oto/engagementReport'];
