import { getCustomerRuleList } from '../services';
import { Slot, ResponseRuleList } from '../models';

export const fetchRuleList = () => getCustomerRuleList().then(response => formatRule(response?.data));

const formatRule = (response: ResponseRuleList) => {
  const result: Slot[] = response?.data?.map(item => ({
    name: item?.name || '-',
    createdAt: item?.createdAt,
    mainRule: item?.mainRule,
    id: item?.id,
  }));

  return result;
};
