<template>
  <div class="row m-20">
    <div class="col b-bottom p-b5">
      <!-- Slot to title for this header ex: label -->
      <div>
        <slot name="title-header-content"></slot>
      </div>

      <!-- Slot to calculate a content result -->
      <div>
        <div class="header-content">
          <slot name="header-content"></slot>
        </div>
        <div class="little-percent">
          <slot name="little-percent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.p-b5 {
  padding-bottom: 5px;
}
.header-content {
  font-size: 32px;
  font-weight: 200;
  font-style: normal;
  line-height: 40px;
  display: inline-block;
}
.little-percent {
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  margin: 0 0 0 5px;
}
.m-20:nth-child(n + 1) {
  margin-right: 35px;
}
</style>
