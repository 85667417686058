<template>
  <div id="login-form-card">
    <div class="card">
      <login-form-email v-if="mode === 'email'" />
      <login-form-password v-if="mode === 'password'" :show-email="emailLogin" :error="error" />
      <login-form-mfa v-if="mode === 'mfa'" :show-email="emailLogin" :receive-password="passwordLogin" :error="error" />
      <login-form-recover v-if="mode === 'recover'" />
      <login-recover-alternative v-if="mode === 'recover-alternative'" />
      <load-recover-email v-if="mode === 'recover-email'" />
      <login-recover-success v-if="mode === 'recover-success'" />
      <load-spinner id="login-loader" color="white" :text="text" />
    </div>
  </div>
</template>

<script>
import LoginFormEmail from '@/components/login/LoginFormEmail';
import LoginFormPassword from '@/components/login/LoginFormPassword';
import LoginFormMfa from '@/components/login/LoginFormMfa';
import LoginFormRecover from '@/components/login/LoginRecover';
import LoginRecoverAlternative from '@/components/login/LoginRecoverAlternative';
import LoadRecoverEmail from '@/components/login/LoginRecoverEmail';
import LoginRecoverSuccess from '@/components/login/LoginRecoverSuccess';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import bus from '@/helpers/events/bus';

export default {
  components: {
    LoginFormEmail,
    LoginFormPassword,
    LoginFormMfa,
    LoginFormRecover,
    LoginRecoverAlternative,
    LoginRecoverSuccess,
    LoadSpinner,
    LoadRecoverEmail,
  },
  props: {
    showEmail: {
      type: String,
      default: '',
    },
    receivePassword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mode: 'email',
      code: '',
      loading: false,
      recoverEmail: '',
      emailLogin: '',
      passwordLogin: '',
      showText: false,
      text: '',
      consoleMfa: {},
      error: false,
    };
  },
  mounted() {
    // request API when change date range or brand
    bus.$on('mfa-change', item => {
      this.mode = item;
    });
    bus.$on('trigger-global-error', () => {
      this.error = true;
    });
    this.checkSingleSignOn();
  },
  beforeUnmount() {
    bus.$off('mfa-change');
    bus.$off('trigger-global-error');
  },
  methods: {
    /** *
     * @changeLoginMode: Method to change cards by mode, this also show loader when change cards.
     * @mode: Data that switch cards when receive certain values from children components.
     * @loading: Data that display or not loader.
     * @showText: Data that display or not a text when loader is on.
     ** */
    changeLoginMode(mode = 'email', loading = false, showText = false) {
      this.mode = mode;
      this.loading = loading;
      this.showText = showText;
      this.validateLoading();
    },
    changeLoginLoading(loading = false, showText = false) {
      this.loading = loading;
      this.showText = showText;
      this.validateLoading();
    },
    checkSingleSignOn() {
      // check URL for `#code=<uuid>` pattern, triggering single sign-on
      const { hash } = window.location;
      const code = hash && hash.match(/code=([0-9a-f-]{36})/);
      if (code) {
        this.code = code[1];
        this.changeLoginLoading(true, true);

        this.$store.dispatch('federatedLogin', {
          code: this.code,
        });
      }
    },
    /** *
     * @validateLoading: Method that active or not loader and set a text description to it.
     ** */
    validateLoading() {
      if (this.loading && this.showText) {
        bus.$emit('show-spinner', 'login-loader');
        this.text = '';
      } else if (this.loading && !this.showText) {
        bus.$emit('show-spinner', 'login-loader');
        this.text = this.$t('login.loader-authentication');
      } else if (!this.loading && !this.showText) {
        bus.$emit('hide-spinner', 'login-loader');
        this.text = '';
      } else {
        bus.$emit('hide-spinner', 'login-loader');
      }
    },
    /** *
     * @displayEmail: Method that receives emailLogin as a prop from LoginFormEmail
     * to display it at LoginFormPassword.
     ** */
    displayEmail(emailLogin = '') {
      this.emailLogin = emailLogin;
    },
    sendPassword(passwordLogin = '') {
      this.passwordLogin = passwordLogin;
    },
  },
};
</script>

<style lang="scss" scoped>
#login-form-card .card {
  position: relative;
  max-width: 300px;
  width: 350px;
  margin: 0 auto;
}
</style>

<style lang="scss">
#login-form-card .card {
  border-radius: $border-radius-size !important;
  button.submit {
    padding: 0 8px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input[type='email'],
  input[type='password'],
  input[type='text'] {
    border-radius: 22px;
    border: 1px solid $gray-600;
    color: $gray-700;

    &:focus:not(.is-invalid) {
      border-color: $oto-omni;
    }
  }

  .card-link {
    font-size: 12px;
    color: $gray-700;
    font-weight: 600;

    &:hover {
      color: $oto-omni;
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    color: $gray-800;
    text-align-last: left;
    margin-top: 51px;
  }

  button.back {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    font-size: 11px;
    color: $gray-700;

    &:hover {
      text-decoration: underline;
    }
  }

  .error {
    border-color: $color-red !important;
  }
}
</style>
