import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const URI = 'filter';

/**
 * @see https://api-context.pmweb.com.br/v3/filter/getFilters
 */
export const getFilters = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getFilters`,
    params: {
      brand: brand?.id,
    },
  });
};
