const mixin = {
  methods: {
    getIcon(item) {
      /**
       * @description get rounded brand icon used in integration
       * @param {Object} item connector object
       * @returns {String} path of image or blank if invalid path image
       */
      if (item?.icon != null) {
        return item.icon;
      }

      const img = item?.alias || item?.connect || item?.connector;

      try {
        return require(`@/assets/img/icons/integrations/${img.toLowerCase()}.svg`);
      } catch (error) {
        return require(`@/assets/img/icons/integrations/blank.svg`);
      }
    },
  },
};

export default mixin;
