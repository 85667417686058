<template>
  <div id="stores" class="stores">
    <h1 class="card-group-title">{{ $t('settings.stores.title-dashboard') }}</h1>
    <section class="stores__table">
      <base-card-v2>
        <div class="tbl-shell">
          <div ref="tbl-inner-wrapper" class="tbl-inner-wrapper" @scroll="$_scrollTable">
            <div ref="tbl-config" class="tbl-config">
              <div class="tbl-config-options-header row">
                <div class="form-wrapper search-and-filter stores__search">
                  <input
                    ref="inputTerm"
                    v-model="term"
                    class="findTerm stores__search--find-term"
                    :placeholder="$t('settings.stores.filter-search')"
                  />
                  <i v-if="term !== ''" class="icon icon-close" @click="clearTerm"></i>
                  <i v-else class="icon icon-search"></i>
                </div>
                <div class="btn-options">
                  <button ref="button-edit" class="btn btn-secondary edit-columns" @click="openEditColumnsModal">
                    <i class="icon icon-config 18px"></i>
                    {{ $t('settings.stores.edit-columns') }}
                  </button>
                  <button ref="button" class="btn" @click="addStore">
                    {{ $t('settings.stores.add-store') }}
                  </button>
                </div>
              </div>
            </div>
            <template v-if="$_verifyLoaded('done') && isTermExists">
              <div ref="tableHeader" class="tbl-header">
                <div class="tbl-row">
                  <div v-for="(vH, kH) in tableHeader" :key="kH" class="tbl-col metric">
                    <div class="header-title">
                      {{ vH.name }}
                    </div>
                    <div
                      :ref="vH.key"
                      :class="[
                        `order-by ${vH.key}`,
                        {
                          active: isActive === vH.key,
                        },
                      ]"
                      @click.prevent="ordenate(vH.key)"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="headerFixed" ref="header-fixed" class="tbl-header header-fixed">
                <div class="tbl-row">
                  <div v-for="(vH, kH) in tableHeader" :key="kH" class="tbl-col metric">
                    <div class="header-title">
                      {{ vH.name }}
                    </div>
                    <div
                      :ref="vH.key"
                      :class="[
                        `order-by ${vH.key}`,
                        {
                          active: isActive === vH.key,
                        },
                      ]"
                      @click.prevent="ordenate(vH.key)"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div ref="tbl-body" class="tbl-body">
                <div v-for="(item, index) in tableData.rows" :key="index" class="tbl-row">
                  <div v-for="(vH, kH) in tableHeader" :key="kH" class="tbl-col metric">
                    <span v-if="vH.key === 'status'" class="stores--status">
                      <span :class="['stores--status-bol', item[vH.key]]"></span>
                      <p v-if="item[vH.key] === 'active'">{{ $t('settings.stores.status.active') }}</p>
                      <p v-else>{{ $t('settings.stores.status.inactive') }}</p>
                    </span>
                    <div v-else-if="vH.key === 'type' || vH.key === 'dataSource'" :class="[`${item[vH.key]}-${index}`]">
                      <i
                        :class="['icon', iconType(item[vH.key])]"
                        v-tooltip="{
                          theme: 'default',
                          content: showTooltip(item[vH.key]),
                          distance: 8,
                        }"
                      ></i>
                    </div>
                    <p
                      v-else
                      :class="[
                        'threshold',
                        `${$_checkThreshold({
                          amount: item[vH.key],
                          threshold: vH.threshold,
                        })}`,
                      ]"
                    >
                      {{ $_getValueNewFormat({ vH, amount: item[vH.key] }) }}
                    </p>
                  </div>
                  <div class="dropdown stores__dropdown">
                    <a
                      id="dropdownAudiences"
                      href="#"
                      class="dropdown-toggle stores__dropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="icon icon-menu-14px"></i>
                    </a>
                    <div class="dropdown-menu stores__dropdown-menu aud-dropdown" aria-labelledby="dropdownAudiences">
                      <a class="dropdown-item stores__dropdown-item" @click.prevent="redirect(item.storeId)">
                        {{ $t('settings.stores.edit') }}
                      </a>
                      <a
                        v-if="item.status === 'active'"
                        class="dropdown-item stores__dropdown-item"
                        @click.prevent="inactivate(item.storeId)"
                      >
                        {{ $t('settings.stores.inactivate') }}
                      </a>
                      <a v-else class="dropdown-item stores__dropdown-item" @click.prevent="activate(item.storeId)">
                        {{ $t('settings.stores.activate') }}
                      </a>
                      <a class="dropdown-item stores__dropdown-item" @click.prevent="duplicate(item.storeId)">
                        {{ $t('settings.stores.duplicate') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <stores-edit-columns-modal :page="'email'" />
            <base-loader v-if="$_verifyLoaded('loading')" />
            <info-card
              v-if="($_verifyLoaded('info') && isCustomTable) || !isTermExists"
              ref="message-error"
              :error="(hasError && isCustomTable) || !isTermExists"
            />
          </div>
        </div>
        <list-pagination :data="pagination" :limit="pagination.limit" :limit-range="limitRange" :identify="id" />
      </base-card-v2>
    </section>
  </div>
</template>

<script>
// helpers
import bus from '@/helpers/events/bus';
import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';

// services
import { getTable, updateStoreStatus } from '@/services/storeSettings';

// libs
import { isEmpty, debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import ListPagination from '@/components/_atoms/ListPagination';
import StoresEditColumnsModal from '@/components/settings/stores/StoresEditColumnsModal';

// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'StoreSettingsTable',
  components: {
    BaseLoader,
    InfoCard,
    ListPagination,
    StoresEditColumnsModal,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      headerFixedWidth: 0,
      headerFixed: false,

      tableHeader: {},
      tableData: [],

      isActive: null,
      term: '',
      isTermExists: true,

      id: 'settings-stores',
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
      },
      orderBy: '',
      limitRange: [5, 10, 20, 30, 50],

      isCustomTable: false,
      status: '',
    };
  },
  computed: {
    ...mapGetters({
      getLastConfigMetrics: 'settingsStores/getLastConfigMetrics',
      getAllCheckedMetrics: 'settingsStores/getAllCheckedMetrics',
      getToastSuccess: 'settingsStores/getToastSuccess',
    }),
  },
  watch: {
    term: debounce(function () {
      this.pagination.page = 1;
      this.isCustomTable ? this.fetchCheckedMetrics() : this.getTableInfo();
    }, 800),
  },
  created() {
    bus.$on('render-cards', () => {
      this.isCustomTable ? this.fetchCheckedMetrics() : this.getTableInfo();
    });
  },
  mounted() {
    useMixpanelConfig('view_settings_stores');
    this.getTableInfo();

    if (this.getToastSuccess) {
      this.toastSuccess();
    }

    bus.$on(`${this.id}-change-page`, () => {
      this.isCustomTable ? this.fetchCheckedMetrics() : this.getTableInfo();
      this.headerFixed = false;
    });

    bus.$on('dashboard-update-table', () => {
      this.fetchCheckedMetrics();

      // Reset table position
      this.$refs['tbl-inner-wrapper'].scrollLeft = 0;
      this.$refs['tbl-config'].style.left = 0;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off(`${this.id}-change-page`);
    bus.$off('dashboard-update-table');
  },
  methods: {
    ...mapActions('settingsStores', [
      'updateCheckedMetrics',
      'updateFilterMetrics',
      'updateLastCustomConfig',
      'setToastSuccess',
      'setUpdateStoreStatus',
    ]),
    getTableInfo() {
      this.$_reqConfig();
      this.isTermExists = true;

      const args = {
        ...(!isEmpty(this.term) && { term: this.term }),
        orderBy: this.orderBy,
        page: this.pagination.page,
        limit: this.pagination.limit,
      };

      getTable(args)
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.isTermExists = false;
            return;
          }

          this.checkedDefaultMetrics(data);

          this.tableHeader = data.series;
          this.tableData.rows = data.data;
          this.pagination.count = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCustomTable(metrics) {
      this.$_reqConfig();
      this.isCustomTable = true;
      this.isTermExists = true;

      const args = {
        columns: metrics,
        ...(!isEmpty(this.term) && { term: this.term }),
        orderBy: this.orderBy,
        page: this.pagination.page,
        limit: this.pagination.limit,
      };

      getTable(args)
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.isTermExists = false;
            return;
          }
          this.tableHeader = data.series;
          this.tableData.rows = data.data;
          this.pagination.count = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCheckedMetrics() {
      const { metrics } = this.getAllCheckedMetrics;
      const columns = metrics.map(item => item).toString();
      this.fetchCustomTable(columns);
    },
    changeStoreStatus(storeId, status) {
      this.status = status === '0' ? 'inactive' : 'active';
      updateStoreStatus(storeId, status).then(data => {
        if (this.$_verifyData(data.data)) return;
        if (data.data.success) {
          this.toast.success(this.$t(`settings.stores.toast-success.${this.status}`), {
            timeout: 3000,
          });
          this.getTableInfo();
        }
      });
    },
    ordenate(order) {
      if (!this.orderBy) {
        this.orderBy = order;
        this.isActive = order;
        this.isCustomTable ? this.fetchCheckedMetrics() : this.getTableInfo();
        return;
      }

      this.isActive = order;

      if (this.orderBy === order) this.orderBy = `-${order}`;
      else this.orderBy = order;
      this.isCustomTable ? this.fetchCheckedMetrics() : this.getTableInfo();
    },
    iconType(type) {
      if (type === 'L') {
        return 'icon-in-store-18px';
      } else if (type === 'E') {
        return 'icon-ecommerce-18px';
      } else if (type === 'OTO') {
        return 'icon-table-18px';
      } else {
        return 'icon-database-18px';
      }
    },
    clearTerm() {
      this.term = '';
    },
    showTooltip(type) {
      let title;

      if (type === 'L') {
        title = this.$t('settings.stores.tooltip-titles.store');
      } else if (type === 'E') {
        title = this.$t('settings.stores.tooltip-titles.e-commerce');
      } else if (type === 'OTO') {
        title = this.$t('settings.stores.tooltip-titles.user');
      } else {
        title = this.$t('settings.stores.tooltip-titles.database');
      }

      return title;
    },
    openEditColumnsModal() {
      bus.$emit('open-stores-edit-columns');
    },
    checkedDefaultMetrics(data) {
      const initialMetricsFiltered = data?.series.filter(el => el);
      const initialMetricsChecked = initialMetricsFiltered.map(item => item.key);

      this.updateCheckedMetrics(initialMetricsChecked);
      this.updateFilterMetrics(initialMetricsFiltered);

      this.updateLastCustomConfig();
    },
    addStore() {
      this.$router.push({ name: 'adm-stores-create' });
    },
    redirect(id) {
      this.$router.push({
        name: 'adm-stores-edit',
        params: {
          id,
        },
      });
    },
    duplicate(id) {
      this.$router.push({
        name: 'adm-stores-duplicate',
        params: {
          id,
        },
      });
    },
    inactivate(id) {
      const status = '0';
      this.changeStoreStatus(id, status);
    },
    activate(id) {
      const status = '1';
      this.changeStoreStatus(id, status);
    },
    toastSuccess() {
      const isCreateOrEdit = this.mode === 'edit' ? 'update' : 'add';
      this.toast.success(this.$t(`settings.stores.toast-success.${isCreateOrEdit}`), {
        timeout: 3000,
      });
      this.setToastSuccess(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.stores {
  &__table {
    button:nth-child(1) {
      margin-right: 16px;
      padding: 0 15px;
    }

    button {
      display: flex;
    }

    .icon-in-store-18px:hover,
    .icon-ecommerce-18px:hover,
    .icon-table-18px:hover,
    .icon-database-18px:hover {
      color: $blue-500;
    }

    i {
      font-size: 18px;
    }
  }

  &--status {
    display: flex;
    align-items: center;

    p {
      margin-bottom: unset;
    }

    &-bol {
      border-radius: 50%;
      display: inline-block;
      height: 9px;
      width: 9px;
      margin-right: 10px;
    }

    .active {
      background-color: $green-300;
    }

    .inactive {
      background-color: $pink-500;
    }
  }

  &__search {
    width: 307px;
    margin-right: 15px;
    position: relative;
    &--find-term {
      width: 100%;
    }

    i {
      position: absolute;
      right: 8px;
      top: 4px;
      font-size: 26px;
      color: #bbb;
      cursor: pointer;
    }
  }

  &__dropdown {
    position: absolute;
    right: 25px;

    &-toggle {
      cursor: pointer;
      text-decoration: none;
      color: unset;

      &:after {
        display: none;
      }
    }

    &-menu {
      min-width: 100px;
      box-shadow: -1px 2px 9px rgba(0, 0, 0, 0.25);
      border: 0 none;
      border-radius: 0;
      padding: 0;
    }

    &-item {
      font-size: 11.46px;
      padding: 3px 12px;
      color: $gray-600;
      line-height: 22px;
      font-weight: 600;
      cursor: pointer;

      &:hover:not(.disabled) {
        background-color: $gray-400;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
</style>

<style lang="scss">
#stores {
  .tbl-inner-wrapper {
    .tbl-row {
      border-top: none;
      .metric {
        justify-content: flex-start;
        display: flex;
        text-align: left;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
  .tbl-header {
    .tbl-row {
      min-width: 843px;
      .header-title {
        max-width: 75px;
      }
    }
  }
  .tbl-body {
    .tbl-row {
      min-width: 843px;
      position: relative;
    }
  }
  .tbl-col {
    max-width: 130px;
    margin-right: 0px;
  }
  .card {
    min-height: 364px;
  }
  .header-fixed {
    border-top: 1px solid $gray-400;
    width: 842px;
  }
  .tbl-shell {
    padding-bottom: 20px;
  }

  .dropdown-menu {
    left: -25px !important;
  }
  .tooltip {
    .tooltip-inner {
      text-align: left;
      max-width: 170px;
    }
  }
}
</style>
