<template>
  <div>
    <button
      class="btn btn-new-campaign icon icon-plus"
      :class="validateIfHasSupportPermission(permissionsSupport) ? 'has-freshdesk-widget' : ''"
      :title="$t('campaigns.new-campaign')"
      @click="$parent.openCreate"
    >
      {{ $t('campaigns.new-campaign') }}
    </button>
    <table-list v-if="!loading || hasLoaded" :id="id" :data="data" :type="type">
      <table-list-header>
        <table-list-column order="campaign" class-name="col-4">{{ $t('campaigns.name') }}</table-list-column>
        <table-list-column order="customers_total" class-name="text-right">{{ $t('clients') }}</table-list-column>
        <table-list-column order="conversion_rate" class-name="text-right">{{
          $t('campaigns.conversion-rate')
        }}</table-list-column>
        <table-list-column class-name="text-right">{{ $t('campaigns.start') }}</table-list-column>
        <table-list-column class-name="text-right">{{ $t('campaigns.end') }}</table-list-column>
        <table-list-column class-name="text-right col-1"></table-list-column>
      </table-list-header>
      <table-list-body>
        <table-list-row
          v-for="(campaign, index) in campaigns"
          :key="index"
          :click-callback="
            () => {
              details(campaign.id);
            }
          "
        >
          <table-list-column class-name="campaign-name col-4">
            <span
              :ref="`campaign-name-${campaign.id}`"
              class="name"
              :contenteditable="campaign.id === renaming"
              @focus="onFocus(campaign.id)"
              @blur="submitRename(campaign)"
              @keydown.esc="renameCampaignCancel(campaign)"
              @keydown.enter.prevent="onEnter()"
            >
              {{ campaign.name }}
            </span>
            <span class="info">{{ $t('id') }} {{ campaign.id }}</span>
          </table-list-column>
          <table-list-column class-name="text-right">{{ $_formatDecimal(campaign.clients) }}</table-list-column>
          <table-list-column class-name="text-right">{{
            $_formatNumber(campaign.conversionRate, '0.00%')
          }}</table-list-column>
          <table-list-column class-name="text-right">{{ dateFormat(campaign.start) }}</table-list-column>
          <table-list-column class-name="text-right">{{ dateFormat(campaign.end) }}</table-list-column>
          <table-list-column class-name="text-right col-1">
            <div class="dropdown">
              <a
                id="dropdownCampaigns"
                href="#"
                class="dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="openDropdown(campaign.id)"
              >
                <i class="icon icon-menu-14px"></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdowncampaign">
                <a
                  class="dropdown-item"
                  :class="campaign.user !== user.id ? 'disabled' : ''"
                  href="#"
                  @click.prevent="renameCampaign(campaign)"
                  >{{ $t('rename') }}</a
                >
                <a
                  class="dropdown-item"
                  :class="campaign.user !== user.id ? 'disabled' : ''"
                  href="#"
                  @click.prevent="showDeleteModal(campaign)"
                  >{{ $t('delete') }}</a
                >
              </div>
            </div>
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </table-list>
    <base-loader v-show="$_verifyLoaded('loading') && !hasLoaded" :height="'95'" />
    <el-dialog v-model="isModalVisible" id="campaign-delete-confirm" append-to-body>
      <p>{{ $t('campaigns.do-you-want-delete') }}</p>
      <div class="dialog">
        <div class="cancel btn btn-gray" @click="hideDeleteModal">
          {{ $t('cancel') }}
        </div>
        <div class="confirm btn" @click="disableCampaign(deleting)">
          {{ $t('confirm') }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign, no-return-assign */
import moment from 'moment';
import { list, disable, rename } from '@/services/campaigns';
import bus from '@/helpers/events/bus';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import store from '@/store';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import { dateDayMonthYearVariation } from '@/utilities/constants';
import hasPermission from '@/helpers/permissions';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
import { permissionsSupport } from '@/utilities/accessLevel/WidgetSupportPermissions';

const id = 'campaigns-list';

export default {
  name: id,
  components: {
    BaseLoader,
  },
  mixins: [verifyMixin, numberMixin],
  props: {
    pageHistory: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      id,
      campaigns: [],
      hasLoaded: false,
      totalCount: 1,
      orderBy: 'campaign',
      opened: 0,
      deleting: null,
      renaming: null,
      renameCancel: false,
      type: 'normal',
      data: {
        page: this.pageHistory,
        limit: 10,
        count: 0,
        order: 'cid_desc',
        orderDefault: 'cid_desc',
      },
      isModalVisible: false,
      permissionsSupport: permissionsSupport,
    };
  },
  computed: {
    user() {
      return store.getters.user;
    },
  },
  created() {
    this.getCampaigns();
    bus.$on(`${id}-run`, (key, params) => {
      this[key](params);
    });
  },
  mounted() {
    useMixpanelConfig('view_performance_audiences');

    bus.$on('render-cards', () => {
      this.getCampaigns();
    });

    this.bodyClick();

    bus.$on(`${this.id}-change-page`, () => {
      this.getCampaigns();
    });
    bus.$on(`${this.id}-change-order`, this.getCampaigns);
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    getCampaigns() {
      this.$_reqConfig();
      list({
        dash: 'campaigns_list',
        orderby: this.data.order,
        limit: this.data.limit,
        page: this.data.page,
        columns: 'cid,campaign,start_date,end_date,audience,customers_total,user_id,conversion_rate',
      })
        .then(response => {
          this.data.count = response.data.total_count;
          this.formatCampaigns(response.data.data);
          bus.$emit('campaigns-list-updated', this.data.count);
        })
        .catch(() => {
          bus.$emit('campaigns-list-updated', 0);
        })
        .finally(() => {
          this.loading = false;
          this.hasLoaded = true;
        });
    },
    formatCampaigns(data) {
      function CAMPAIGN(campaign) {
        [this.id, this.name, this.start, this.end, this.audience, this.clients, this.user, this.conversionRate] =
          campaign;
      }

      this.campaigns = [];
      for (let i = 0; i < data.length; i += 1) {
        this.campaigns.push(new CAMPAIGN(data[i]));
      }
    },
    dateFormat(date) {
      return moment(date).format(dateDayMonthYearVariation);
    },
    showDeleteModal(campaign) {
      if (campaign.user === this.user.id) {
        this.deleting = campaign;
        this.isModalVisible = true;
      }
    },
    hideDeleteModal() {
      this.deleting = null;
      this.isModalVisible = false;
    },
    resetPagination() {
      this.pagination.page = 1;
    },
    openDropdown(campaignId) {
      this.opened = this.opened === campaignId ? 0 : campaignId;
    },
    disableCampaign(campaign) {
      disable(campaign.id).then(() => {
        this.hideDeleteModal();
        this.getCampaigns();
      });
    },
    onFocus(campaignId) {
      const element = this.$refs[`campaign-name-${campaignId}`][0];
      const sel = window.getSelection();
      const range = document.createRange();
      range.selectNode(element.childNodes[0]);
      sel.removeAllRanges();
      sel.addRange(range);
      sel.collapseToEnd();
    },
    renameCampaign(campaign) {
      this.renameCancel = false;
      if (campaign.user === this.user.id) {
        this.renaming = campaign.id;
        this.$nextTick(() => {
          this.$refs[`campaign-name-${campaign.id}`][0].focus();
        });
      }
    },
    renameCampaignCancel(campaign) {
      this.renameCancel = true;
      this.renaming = null;
      this.$nextTick(() => {
        this.$refs[`campaign-name-${campaign.id}`][0].innerText = campaign.name;
      });
      document.activeElement.blur();
    },
    onEnter() {
      event.target.blur();
    },
    submitRename(campaign) {
      this.renaming = null;
      const newName = event.target.innerText;

      if (newName === '' || newName === campaign.name || this.renameCancel) return;

      campaign.name = newName;
      rename(campaign.id, newName);
    },
    details(campaignId) {
      this.$router.push({
        name: 'campaignsDetails',
        params: {
          id: campaignId,
          pageHistory: this.data.page,
        },
      });
    },
    bodyClick() {
      const body = document.querySelector('body');
      body.addEventListener('click', event => {
        if (event.target.className !== 'icon icon-menu-14px') {
          this.opened = 0;
        }
      });
    },
    validateIfHasSupportPermission(permission) {
      return hasPermission(permission);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-new-campaign {
  font-size: 0;
  background-color: $oto-omni;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  position: fixed;
  z-index: 99;
  right: 25px;
  bottom: 30px;
  box-shadow: -2px 3px 10px rgba(black, 0.1);
  transition: ease 0.3s;

  &.has-freshdesk-widget {
    bottom: 90px !important;
  }

  padding: 0 25px;
  &::before {
    font-size: 28px;
    font-weight: normal;
  }
}
.campaign-name {
  .name {
    -webkit-user-select: text;
    user-select: text;
    padding: 5px 0;
    display: inline-block;

    &:focus {
      min-width: 150px;
      outline: 0 none;
      border-bottom: 1px solid;
    }
  }

  .info {
    font-weight: 300;
    font-size: 11px;
    margin-left: 9px;
  }
}

.dropdown-item {
  &.disabled {
    pointer-events: initial;
  }
}
</style>

<style lang="scss">
#campaign-delete-confirm {
  max-width: 500px;

  p {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  .dialog {
    display: flex;
    justify-content: center;
    margin: 28px 0 20px 0;
    gap: 20px;

    div {
      width: 170px;
      border-radius: 80px;
    }
  }
}
</style>
