<template>
  <div class="actions-message-new">
    <button class="btn" @click="openModal" data-cy="btn-new-message">
      {{ $t('360.actions.new.message') }}
    </button>
  </div>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';

export default {
  name: 'ActionsMessageHeaderNew',
  data() {
    return {};
  },
  methods: {
    openModal() {
      bus.$emit('open-actions-new-message-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-message-new {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  width: 100%;

  .btn {
    height: 36px;
    line-height: 20px;
    width: 194px;
    padding: 0 15px;

    .icon {
      font-weight: bold;
    }
  }
}
</style>
