<template>
  <div class="cashback-customers-dont-use-metrics card">
    <template v-if="$_verifyLoaded('done')">
      <h3 class="ccdm__title" v-html="$t('360.cashback-report.customers-dont-use.title')"></h3>
      <div class="ccdm__metrics">
        <div v-for="(metric, key) in metrics" :key="key" class="ccdm__metric">
          <h4 class="ccdm__metric-title">{{ metric.title }}</h4>
          <p class="ccdm__metric-amount">{{ formatMetric(metric) }}</p>
        </div>
      </div>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" :height="'45px'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getMetrics } from '@/services/oto/cashbackReport';

// helpers
import bus from '@/helpers/events/bus';
import { valuesByFormat } from '@/utilities/formatters';
import { verifyMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'CashbackCustomersDontUseMetrics',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],

  data() {
    return {
      metrics: [],
      threshold: 1099999,
    };
  },

  computed: {
    ...mapGetters('cashbackReport', ['getStoreId', 'getSellerId']),
  },

  mounted() {
    this.fetchData();

    // bus called when dates are changed globally
    bus.$on('render-cards', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-store', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-seller', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-segment-general', () => {
      this.fetchData();
    });
  },

  beforeUnmount() {
    bus.$off('cashback-report-change-segment-general');
    bus.$off('cashback-report-change-seller');
    bus.$off('render-cards');
    bus.$off('cashback-report-change-store');
  },

  methods: {
    fetchData() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'aov,frequency,recency',
        type: 'not-used',
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
      })
        .then(({ data }) => {
          this.$_verifyHasData(data.data);

          this.metrics = data.data;
        })

        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })

        .finally(() => {
          this.loading = false;
        });
    },
    formatMetric(metric) {
      return valuesByFormat(metric?.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback-customers-dont-use-metrics {
  height: 135px;
  box-sizing: border-box;

  .ccdm__title {
    font-size: 18px;
    color: $gray-800;
  }

  .ccdm__metrics {
    display: flex;
  }

  .ccdm__metric {
    margin: 5px 20px 0 20px;

    &:first-child {
      margin-left: 2px;
    }

    .ccdm__metric-title {
      font-weight: 600;
      color: $gray-700;
      font-size: 12px;
      margin: 0;
      padding-top: 5px;
    }

    .ccdm__metric-amount {
      color: $oto-omni;
      font-weight: 600;
      font-size: 16px;
      padding-top: 10px;
      margin: 0;
    }
  }
}
</style>

<style lang="scss">
.cashback-customers-dont-use-metrics .ccdm__title {
  span {
    color: $oto-seller;
  }
}
</style>
