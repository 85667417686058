<template>
  <div class="buttons">
    <div v-if="selectedStep === 1" class="wrapper">
      <button
        v-if="!isView"
        class="continue btn"
        data-cy="btn-next-step"
        :disabled="!saveButtonActive"
        @click="changeStep(2)"
      >
        {{ $t('audiences.next-step') }}
      </button>
      <button v-else class="export btn" @click="openExport">
        {{ $t('audiences.csv-export') }}
      </button>
    </div>
    <div v-if="selectedStep === 2 && !isView" class="wrapper">
      <button v-if="false" class="btn btn-info back" @click="changeStep(3)">
        {{ $t('audiences.control-groups') }}
      </button>
      <button
        class="btn save"
        data-cy="btn-create-audience"
        :disabled="!saveButtonActive || !include[0].length"
        @click="save"
      >
        {{ $t('audiences.create-audience') }}
      </button>
    </div>
    <div v-if="selectedStep === 2 && isView" class="wrapper">
      <button class="export btn" @click="updateExport">
        {{ $t('save-and-export') }}
      </button>
      <button class="export btn" @click="updateIntegrations">
        {{ $t('save') }}
      </button>
    </div>
    <div v-if="selectedStep === 3" class="wrapper">
      <button v-if="!isView" class="btn btn-info cancel" @click="cancel">
        {{ $t('cancel') }}
      </button>
      <button v-if="!isView" class="btn save" :disabled="!saveButtonActive" @click="save">
        {{ $t('save') }}
      </button>
      <button v-else class="export btn" @click="openExport">
        {{ $t('audiences.csv-export') }}
      </button>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { mapState, mapGetters } from 'vuex';
import { connectAudience, updateAudience } from '@/services/audiences';
import { omnichatIndividualApproachStartDate, dateTimeInverseFormat } from '@/utilities/constants';
import moment from 'moment';
import { useToast } from 'vue-toastification';

export default {
  props: {
    selectedStep: {
      type: Number,
      default: 1,
    },
    changeStep: {
      type: Function,
      default: null,
    },
    isView: {
      type: Boolean,
      default: null,
    },
    people: {
      type: Number,
      default: null,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      saving: false,
      updating: false,
      isSomeStartDateTimesLessThanCurrent: false,
      hasDateTime: false,
      hasSchedule: [],
      currentTime: '',
    };
  },
  computed: {
    ...mapState('audience', [
      'reference',
      'exporting',
      'enabledIntegrations',
      'isOmnichatAndIndividualApproach',
      'integrationSeries',
      'include',
    ]),

    ...mapGetters('audience', ['getIntegrationsByAudience']),

    saveButtonActive() {
      if (this.saving) return false;
      return true;
    },
  },
  methods: {
    async save(settings, response) {
      this.saving = true;
      this.getCurrentTime();

      this.verifyConditionToShowDateTimeError(this.currentTime);

      if (this.saving) {
        const rej = () => {
          bus.$emit('validate-connect-fields', this.enabledIntegrations);
          this.success = false;
          this.saving = false;
        };

        const res = async promise => {
          const { data } = await promise;

          if (!settings.exports) {
            bus.$emit('show-success-audience');
          } else {
            bus.$emit('show-success-audience-export');
          }

          this.$store.dispatch('audience/setAudienceID', data.data.id);
          this.setIntegrations(data.data.id);

          if (response) {
            response(data);
          }

          return data;
        };

        bus.$emit('save-audience', res, rej);
        bus.$emit('show-spinner', 'audiences');
      }
    },
    updateExport() {
      this.updateIntegrations({ exports: true }, () => {
        this.openExport(this.reference);
      });
    },
    updateIntegrations(settings, response) {
      this.updating = true;

      this.getCurrentTime();
      this.verifyConditionToShowDateTimeError(this.currentTime);

      if (this.updating) {
        const res = () => {
          if (!settings.exports) bus.$emit('show-success-update-audience');
          else bus.$emit('show-success-audience-export');
          this.setIntegrations(this.reference);
          if (response) response();
        };

        const rej = () => {
          bus.$emit('validate-connect-fields', this.enabledIntegrations);
        };
        bus.$emit('update-integrations', res, rej);
      }
    },
    setIntegrations(reference = null) {
      const integrations = this.enabledIntegrations;

      if (integrations.length) {
        integrations.forEach(service => {
          const verifyIfIsOminhcatAndIndividualApproach =
            service.connect === 'omnichat' && this.isOmnichatAndIndividualApproach;

          const hasDatetime = () => {
            if (!service.hasDatetime) {
              if (verifyIfIsOminhcatAndIndividualApproach) {
                return omnichatIndividualApproachStartDate;
              } else {
                return '';
              }
            } else {
              return service.startDatetime;
            }
          };
          // check if integration is already configurated and if is view page
          if (service.id && this.isView) {
            // update integration
            if (!service.enabled) return this.updateSchedule(service);

            if (service.hasEdited) {
              connectAudience({
                audience: reference,
                service: service.connect,
                interval: service.schedule,
                typeSendCampaign: verifyIfIsOminhcatAndIndividualApproach ? 0 : 1,
                startDate: hasDatetime(),
                instance: service.instance,
                config: service.config,
              });
            }
          } else if (service.enabled) {
            // add new integration
            connectAudience({
              audience: reference,
              service: service.connect,
              interval: service.schedule,
              typeSendCampaign: verifyIfIsOminhcatAndIndividualApproach ? 0 : 1,
              startDate: verifyIfIsOminhcatAndIndividualApproach
                ? omnichatIndividualApproachStartDate
                : service.startDatetime,
              instance: service.instance,
              config: service.config,
            });
          }
        });
      }
    },
    updateSchedule(data) {
      const enabled = data.enabled ? 1 : 0;
      const interval = data.schedule;

      updateAudience({
        scheduleID: data.scheduleID,
        connectID: data.id,
        interval,
        enabled,
      });
    },
    openExport(id = null) {
      if (this.people > 0) {
        this.$store.dispatch('audience/updateExporting', id);
        bus.$emit('open-exportation');
      } else {
        this.toast.error(this.$t('audiences.no-people-export'), { timeout: 6000 });
      }
    },
    cancel() {
      bus.$emit('request-discard-audience');
    },
    verifyConditionToShowDateTimeError(currentTime) {
      const hasStartDateTimeLessThanCurrent = item => item.startDatetime < currentTime;

      const hasItemOnArray = this.integrationSeries
        .filter(item => item.hasDatetime && item.enabled && item.hasEdited)
        .some(hasStartDateTimeLessThanCurrent);

      if (hasItemOnArray) {
        bus.$emit('open-audience-start-time-error-modal');
        this.updating = false;
        this.saving = false;
      }
    },
    getCurrentTime() {
      const currentDateTime = moment().second(0).add(5, 'minutes').format(dateTimeInverseFormat);
      this.currentTime = currentDateTime;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  flex-grow: 1;
}
.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: $md-max-width) {
    justify-content: space-between;
    margin-top: 20px;
  }

  button {
    border: none;
    margin-right: 12px;
    width: 180px;
    padding: 0 15px;

    @media (max-width: $md-max-width) {
      width: 100%;
    }
    &:last-of-type {
      margin-right: 0;
    }
    cursor: pointer;
    border-radius: 100px;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
</style>
