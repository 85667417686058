<template>
  <table-list :id="id" :type="type" :data="data" :empty="!data.count">
    <table-list-header v-if="$_verifyLoaded('done')" :type="type">
      <table-list-column :type="type" class-name="col-3">{{ $t('segments-title') }}</table-list-column>
      <table-list-column :type="type" class-name="text-right">{{ $t('conversion-rate') }}</table-list-column>
      <table-list-column :type="type" class-name="text-right">{{ $t('reached-people') }}</table-list-column>
      <table-list-column :type="type" class-name="text-right">{{ $t('clients') }}</table-list-column>
      <table-list-column :type="type" class-name="text-right">{{ $t('orders') }}</table-list-column>
      <table-list-column :type="type" class-name="text-right">{{ $t('aov-ticket') }}</table-list-column>
      <table-list-column :type="type" class-name="text-right">{{ $t('revenue') }}</table-list-column>
    </table-list-header>
    <table-list-body :type="type" :class="{ loadHeight: loading || hasError || isEmpty }" class="detail-segments-table">
      <template v-if="$_verifyLoaded('done')">
        <table-list-row v-for="(row, index) in rows" :key="index" :type="type">
          <table-list-column :type="type" class-name="col-3">{{ row[0] }}</table-list-column>
          <table-list-column :type="type" class-name="text-right">
            {{ $_formatNumber(row[3], '%0.00') }}
          </table-list-column>
          <table-list-column :type="type" class-name="text-right">{{ $_formatDecimal(row[1]) }}</table-list-column>
          <table-list-column :type="type" class-name="text-right">{{ $_formatDecimal(row[2]) }}</table-list-column>
          <table-list-column :type="type" class-name="text-right">{{ $_formatDecimal(row[4]) }}</table-list-column>
          <table-list-column :type="type" class-name="text-right">
            {{ formatCurrency(row[5]) }}
          </table-list-column>
          <table-list-column :type="type" class-name="text-right">
            {{ formatCurrency(row[6]) }}
          </table-list-column>
        </table-list-row>
      </template>
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </table-list-body>
  </table-list>
</template>

<script>
import getTable from '@/services/getTable';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      id: 'campaigns-detail-segments',
      type: 'filled',
      rows: [],
      reference: this.$route.params.id,
      data: {
        page: 1,
        limit: 0,
        count: 0,
      },
    };
  },
  mounted() {
    this.fetch_data();
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();
      getTable({
        metrics: 'campaign_segment_table',
        page: this.data.page,
        limit: this.data.limit,
        dash: 'campaigns',
        campaign: this.reference,
      })
        .then(data => {
          if (this.$_verifyData(data.data.data)) return;

          this.data.count = data.data.total_count;
          this.rows = data.data.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-segments-table {
  &.loadHeight {
    height: 300px;
  }
}
</style>
