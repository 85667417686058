<template>
  <section class="cdp-view">
    <div class="row">
      <div class="col-6" :class="'card-in-store'">
        <cdp-in-store :experimental="$experimental" />
      </div>
      <div v-if="$experimental" class="col-6" :class="'card-app'">
        <cdp-app :experimental="$experimental" />
      </div>
      <div class="col-6" :class="'card-double-match'">
        <cdp-double-match :experimental="$experimental" />
      </div>
      <div class="col-6" :class="'card-ecommerce'">
        <cdp-ecommerce :experimental="$experimental" />
      </div>
      <div class="col-6" :class="'card-email'">
        <cdp-email :experimental="$experimental" />
      </div>
      <div v-if="$experimental" class="col-6" :class="'card-google-user-id-sync'">
        <cdp-google :experimental="$experimental" />
      </div>
      <div v-if="$experimental" class="col-6" :class="'card-push'">
        <cdp-push :experimental="$experimental" />
      </div>
      <div v-if="$experimental" class="col-6" :class="'card-rockin-tags'">
        <cdp-rockin-tags :experimental="$experimental" />
      </div>
      <div v-if="$experimental" class="col-6" :class="'card-sac'">
        <cdp-sac :experimental="$experimental" />
      </div>
      <div class="col-6" :class="'card-sms'">
        <cdp-sms :experimental="$experimental" />
      </div>
    </div>
  </section>
</template>
<script>
// import bus from '@/helpers/events/bus';
import CdpInStore from '@/components/cdp/CustomerDataPlatformCardInsStore';
import CdpApp from '@/components/cdp/CustomerDataPlatformCardApp';
import CdpDoubleMatch from '@/components/cdp/CustomerDataPlatformCardDoubleMatch';
import CdpEcommerce from '@/components/cdp/CustomerDataPlatformCardEcommerce';
import CdpEmail from '@/components/cdp/CustomerDataPlatformCardEmail';
import CdpGoogle from '@/components/cdp/CustomerDataPlatformCardGoogleUserIdSync';
import CdpPush from '@/components/cdp/CustomerDataPlatformCardPush';
import CdpRockinTags from '@/components/cdp/CustomerDataPlatformCardRockinTags';
import CdpSac from '@/components/cdp/CustomerDataPlatformCardSac';
import CdpSms from '@/components/cdp/CustomerDataPlatformCardSms';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'CustomerDataPlatform',
  components: {
    CdpInStore,
    CdpApp,
    CdpDoubleMatch,
    CdpEcommerce,
    CdpEmail,
    CdpGoogle,
    CdpPush,
    CdpRockinTags,
    CdpSac,
    CdpSms,
  },
  mounted() {
    useMixpanelConfig('view_integrations_dashboard');
  },
};
</script>
<style lang="scss" scoped>
div[class^='col-'] {
  &.card-app {
    order: 5;
  }
  &.card-ecommerce {
    order: 3;
  }
  &.card-google-user-id-sync {
    order: 6;
  }
  &.card-cdp-in-store {
    order: 1;
  }
  &.card-rockin-tags {
    order: 2;
  }
  &.card-double-match {
    order: 4;
  }
  &.card-email {
    order: 7;
  }
  &.card-push {
    order: 9;
  }
  &.card-sms {
    order: 8;
  }
  &.card-sac {
    order: 10;
  }
}
img {
  width: 100%;
  height: auto;
  box-shadow: 0px 24px 60px rgba(0, 0, 0, 0.07);
  margin-bottom: 12px;
}
</style>
