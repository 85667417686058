<template>
  <div class="dash-campaign-table">
    <div v-if="$_verifyLoaded('done')">
      <div class="tbl-config">
        <div class="tbl-config-options-header tbl-config-inline">
          <header class="d-flex align-items-center">
            <h5 class="modal-subtitle">
              {{ this.$t('dashboard.campaign-group.campaign-perf') }}
            </h5>
          </header>
          <div class="btn-options">
            <select-fake
              :id="'dash-campaign-select'"
              :select-items-object="tablePresets"
              :width="'auto'"
              :show-span-msg="true"
              :selected-item-index-object="selectedOption"
            />
            <div class="export-wrapper">
              <button ref="button" class="btn export" @click="toggleExport">
                <i class="icon icon-download 18px"></i>
                {{ this.$t('dashboard.export', { value: '' }) }}
              </button>
              <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
                <div
                  v-for="item in exportItems"
                  :key="item.value"
                  :class="['items', item.value]"
                  @click="onChangeExport(item.value, selectedOption)"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="tableHeader" class="tbl-header">
        <div class="tbl-row">
          <div v-for="(vH, kH) in tableHeader" :key="kH" :class="['tbl-col', `${checkColDirection(vH.key)}`]">
            <div>{{ $t(`dashboard.table.cols.${vH.key}`) }}</div>
            <div
              :ref="vH.key"
              :class="[
                `order-by ${vH.key}`,
                {
                  active: isActive === vH.key,
                },
              ]"
              @click.prevent="$_dashOrdination(vH.key)"
            >
              <span :class="['dash-arrows', `${orderType}`]"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="tbl-body">
        <div v-for="(item, index) in tableData.rows" :key="index" class="tbl-row">
          <div v-for="(vH, kH) in tableHeader" :key="kH" :class="`tbl-col ${checkColDirection(vH.key)}`">
            <p
              :class="[
                'threshold',
                `${$_checkThreshold({
                  amount: item[vH.key],
                  threshold: vH.threshold,
                })}`,
                applyKeyClassName(vH.key),
              ]"
              @click="resolveRowClickAction(vH, item[vH.key])"
            >
              {{ $_getValue({ type: vH.type, amount: item[vH.key] }) }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="!isNil(tableData.totalResults)" class="tbl-footer">
        <div v-for="(item, index) in tableData.totalResults" :key="index" class="tbl-row">
          <div v-for="(vH, kH) in tableHeader" :key="kH" :class="`tbl-col ${checkColDirection(vH.key)}`">
            <p :class="['footer-item', { 'text-center': isNil(item[vH.key]) && vH.type != 'date' }]">
              {{
                vH.type == 'date'
                  ? $t('dashboard.table.cols.total')
                  : !isNil(item[vH.key])
                  ? $_getValue({ type: vH.type, amount: item[vH.key] })
                  : '-'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'30'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// libs
import moment from 'moment';
import FileDownload from 'js-file-download';
import vClickOutside from 'click-outside-vue3';
// services
import { getTableMetrics, getTablePresets } from '@/services/dashboard';
// helpers
import bus from '@/helpers/events/bus';
import { dashboardMixin, tableMixin, verifyMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import SelectFake from '@/components/_atoms/SelectFake';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'DashboardCampaignTable',
  components: {
    BaseLoader,
    SelectFake,
    InfoCard,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      /** table report presets data */
      isActive: null,
      limit: 10,
      order: null,
      orderType: 'asc',
      orderDefault: [],
      tablePresets: {},
      selectedOption: '',
      tableHeader: null,
      tableData: {
        rows: [],
        totalResults: [],
      },
      /** table export */
      isExportVisible: false,
      exportItems: [
        {
          value: 'csv',
          title: this.$t('dashboard.export', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('dashboard.export', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('dashboard.export', { value: '.XLSX' }),
        },
      ],
    };
  },
  mounted() {
    this.fetchData();

    bus.$on('changeSelectedOption', newSelectedOption => {
      if (newSelectedOption.id == 'dash-campaign-select') {
        this.selectedOption = newSelectedOption.index;
        this.getReport();
      }
    });
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      getTablePresets({ table: 'campaign' })
        .then(({ data }) => {
          this.tablePresets = data.presets;
          this.selectedOption = this.tablePresets?.performance?.toLowerCase();
        })
        .then(() => {
          this.getReport();
        });
    },
    applyKeyClassName(key) {
      const colsLeftAlign = ['campaign'];
      return colsLeftAlign.includes(key) ? String(key) : '';
    },
    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },
    fetchExportData(fileType, preset) {
      bus.$emit('render-page-loader', true);

      getTableMetrics({
        preset: this.selectedOption,
        filter: `campaign:${this.settings.campaign}`,
        responseType: 'blob',
        fileType,
      })
        .then(({ data }) => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset}-${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },
    getReport() {
      if (this.isActive == '' || this.isNil(this.isActive)) {
        this.isActive = this.orderDefault;
        this.order = this.orderDefault;
      }
      getTableMetrics({
        preset: this.selectedOption,
        filter: `campaign:${this.settings.campaign}`,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.series)) return;
          this.tableHeader = data.series;
          this.tableData.rows = data.data;
          this.tableData.totalResults = data.totalResults;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dash-campaign-table {
  margin-top: 40px;
  min-height: 300px;
  position: relative;

  .tbl-row {
    min-width: initial;
  }
}
</style>
