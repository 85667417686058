// libs
import { escapeRegExp, isEmpty } from 'lodash';

// helpers
import bus from '@/helpers/events/bus';
import { stringParser } from '@/helpers/string-parser';

// endpoints
import { getScopeValue } from '@/services/scope';
import { deleteAttachment, getAttachments } from '@/services/actions';

const actionAttachments = {
  namespaced: true,
  state: {
    // selected options
    scopeValue: '',
    channel: '',
    scope: '',
    scopeType: '',
    type: '',

    channelList: [
      { id: '', name: '360.actions.all' },
      { id: 'whatsapp', name: '_whatsapp' },
      { id: 'sms', name: '_sms' },
    ],
    scopeTypeList: [
      { id: '', name: '360.actions.all' },
      { id: 'brand', name: '_brand' },
      { id: 'action', name: '_action' },
      { id: 'campaign', name: '_campaign' },
      { id: 'store', name: '_store' },
    ],
    scopeList: [],

    series: ['name', 'channel', 'scope', 'createdAt', 'modifiedAt', 'size'],

    attachmentList: [],

    isEmptyGlobal: false,
    loadingGlobal: false,
    hasErrorGlobal: false,
    // pagination data
    id: 'actions-attachments',
    pagination: {
      count: 10,
      limit: 10,
      page: 1,
      order: '',
    },
    limitRange: [5, 10, 20, 30, 50],

    logIsEmpty: false,
    logLoading: false,
    logHasError: false,

    // actionsNewAttachmentsModal
    periodStartDate: '',
    periodEndDate: '',
  },
  mutations: {
    SET_TABLE_DATA(state, payload) {
      state.attachmentList = payload;
      if (isEmpty(payload)) {
        state.isEmptyGlobal = true;
        state.loadingGlobal = false;
      } else state.isEmptyGlobal = false;
    },

    SET_CHANNEL(state, payload) {
      state.channel = payload;
    },
    SET_SCOPE_TYPE(state, payload) {
      state.scopeType = payload;
    },
    SET_SCOPE_VALUE(state, payload) {
      state.scopeValue = payload;
    },

    SET_SCOPE_LIST(state, payload) {
      state.scopeList = payload;
    },

    SET_LOADING(state, isLoading) {
      state.loadingGlobal = isLoading;
    },
    SET_ERROR(state, hasError) {
      state.hasErrorGlobal = hasError;
    },

    SET_EXPORT_TOTALCOUNT(state, total) {
      state.pagination.count = total;
    },
    SET_PAGINATION_ORDER(state, order) {
      state.pagination.order = order;
    },

    RESET_PAGINATION(state) {
      state.pagination.page = 1;
    },
    RESET_SELECTED(state) {
      state.name = '';
      state.channel = '';
      state.scopeValue = '';
      state.scopeType = '';
    },

    RESET_SCOPES(state) {
      state.scopeList = [];
      state.scopeValue = '';
    },

    SET_ACTION_STARTEND_DATES(state, payload) {
      state.periodStartDate = payload.startDate || '';
      state.periodEndDate = payload.endDate || '';
    },
  },
  actions: {
    // update table
    getTableData({ commit, state }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', false);
      getAttachments({
        channel: state.channel,
        scope: state.scopeType,
        scopeValue: state.scopeValue,
        term: state.term,
        page: state.pagination.page,
        limit: state.pagination.limit,
        orderBy: state.pagination.order,
      })
        .then(({ data }) => {
          if (!data.totalCount && (state.moment || state.channel || state.scopeType || state.scopeValue)) {
            commit('SET_ERROR', true);
            return;
          }
          commit('SET_TABLE_DATA', data?.data);
          commit('SET_EXPORT_TOTALCOUNT', data?.totalCount);
        })
        .catch(() => {
          commit('SET_ERROR', true);
          commit('SET_LOADING', false);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    // update scope value list
    getScopeListData({ commit, state }) {
      if (state.scopeType != '') {
        getScopeValue({
          type: state.scopeType,
          scope: state.scopeType,
        }).then(({ data }) => {
          if (!data.success) return;
          commit('SET_SCOPE_LIST', data?.data);
        });
      }
    },

    setChannel({ commit, dispatch }, payload) {
      commit('SET_CHANNEL', payload);
      commit('RESET_PAGINATION');
      dispatch('getTableData');
    },
    setScopeType({ commit, dispatch }, payload) {
      commit('RESET_SCOPES');

      commit('SET_SCOPE_TYPE', payload);
      commit('RESET_PAGINATION');
      dispatch('getTableData');
      dispatch('getScopeListData');
    },
    setScopeValue({ commit, dispatch }, payload) {
      commit('SET_SCOPE_VALUE', payload);
      commit('RESET_PAGINATION');
      dispatch('getTableData');
    },

    setPaginationOrder({ commit, dispatch }, order) {
      commit('SET_PAGINATION_ORDER', order);
      dispatch('getTableData');
    },

    setScopeList({ commit }, payload) {
      commit('SET_SCOPE_LIST', payload);
    },

    resetSelected({ commit }) {
      commit('RESET_SELECTED');
    },
    refreshTableData({ dispatch }) {
      dispatch('getTableData');
    },

    disableAttachment({ commit, dispatch }, attachmentId) {
      deleteAttachment({ attachmentId })
        .then(({ data }) => {
          if (!data.success) {
            commit('SET_ERROR', true);
            return;
          }
          bus.$emit('actions-attachment-toast-delete');
        })
        .catch(() => {
          commit('SET_ERROR', true);
          commit('SET_LOADING', false);
        })
        .finally(() => dispatch('refreshTableData'));
    },

    updateDates({ commit }, payload) {
      commit('SET_ACTION_STARTEND_DATES', payload);
    },
  },
  getters: {
    getFilteredTerm:
      state =>
      (term = '') => {
        if (!term) return state.attachmentList;
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.attachmentList.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getScopeType: state => state.scopeType,
    getScopeList: state => state.scopeList,

    getAttachmentsList: state => state.attachmentList,
    // Verify Mixin Getters
    getLoading: state => state.loadingGlobal,
    getIsEmpty: state => state.isEmptyGlobal,
    getHasError: state => state.hasErrorGlobal,
  },
};

export default actionAttachments;
