<template>
  <section id="store-locale" class="stores-register-locale">
    <div class="stores-register-locale__title">
      <h2>Localização</h2>
    </div>
    <template v-if="!loadingStoreDetail">
      <div class="stores-register-locale__form">
        <div>
          <label>{{ $t('settings.stores.register-store.zip-code') }}</label>
          <input
            v-model="zipCode"
            type="text"
            v-mask="['#####-###']"
            :placeholder="$t('settings.stores.placeholder.zip-code')"
            @blur="searchZipCode"
          />
        </div>
        <div class="stores-register-locale__form-box">
          <div>
            <label>{{ $t('settings.stores.register-store.country') }} <span v-if="getIsRequired">*</span></label>
            <v-select
              v-model="country"
              :class="[isCountryError ? 'field-invalid' : '', 'input-country']"
              label="name"
              :placeholder="$tc('_select', 2)"
              :options="countryOptions"
              :clearable="false"
              :taggable="true"
              @option:selected="hideTooltips()"
              v-tooltip.top="{
                theme: 'error',
                shown: isCountryError,
                content: requiredField,
                distance: 8,
                autoHide: false,
              }"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('integrations.no-results') }}
              </template>
            </v-select>
          </div>
          <div>
            <label>{{ $t('settings.stores.register-store.state') }} <span v-if="getIsRequired">*</span></label>
            <v-select
              v-model="state"
              :class="[isStateError ? 'field-invalid' : '', 'input-state']"
              label="name"
              :placeholder="$tc('_select', 2)"
              :options="stateOptions"
              :loading="stateLoading"
              :clearable="false"
              @search="searchResults($event, 'state')"
              @search:focus="searchResults('', 'state')"
              @option:selected="hideTooltips()"
              v-tooltip.right="{
                theme: 'error',
                shown: isStateError,
                content: requiredField,
                distance: 8,
                autoHide: false,
              }"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('integrations.no-results') }}
              </template>
            </v-select>
          </div>
        </div>
        <div id="rl_form-city" class="stores-register-locale__form-city">
          <label>{{ $t('settings.stores.register-store.city') }} <span v-if="getIsRequired">*</span></label>
          <v-select
            v-model="city"
            :class="[isCityError ? 'field-invalid' : '', 'input-city']"
            label="name"
            :placeholder="$tc('_select', 2)"
            :options="cityOptions"
            :loading="cityLoading"
            :clearable="false"
            :taggable="true"
            @search="searchResultsWithDepends($event, 'city', state)"
            @search:focus="callDependsRequest('city', state)"
            @option:selected="hideTooltips()"
            v-tooltip.left="{
              theme: 'error',
              shown: isCityError,
              content: requiredField,
              distance: 8,
              autoHide: false,
            }"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('integrations.no-results') }}
            </template>
          </v-select>
        </div>
        <div id="rl_form-district" class="stores-register-locale__form-district">
          <label>{{ $t('settings.stores.register-store.neighborhood') }} <span v-if="getIsRequired">*</span></label>
          <input
            v-model="neighborhood"
            :class="[isNeighborhoodError ? 'field-invalid' : '', 'input-neighborhood']"
            type="text"
            :placeholder="$t('settings.stores.placeholder.neighborhood')"
            @input="hideTooltips()"
            v-tooltip.bottom="{
              theme: 'error',
              shown: isNeighborhoodError,
              content: requiredField,
              distance: 8,
              autoHide: false,
            }"
          />
        </div>
      </div>
    </template>
    <base-loader v-if="loadingStoreDetail" />
  </section>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { mapState, mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
// helpers
import bus from '@/helpers/events/bus';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
// services
import { getCustomerZipCode, getResultsByField } from '@/services/oto/customers';

export default {
  name: 'StoresRegisterLocaleForm',
  components: {
    vSelect,
    BaseLoader,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isNeighborhoodError: false,
      isCountryError: false,
      isCityError: false,
      isStateError: false,

      stateOptions: [],
      cityOptions: [],
      countryOptions: [],
      stateLoading: false,
      cityLoading: false,
      requiredField: this.$t('settings.stores.tooltip-titles.blank-field'),
    };
  },
  validations() {
    if (this.getIsRequired) {
      return {
        country: {
          required,
        },
        state: {
          required,
        },
        city: {
          required,
        },
        neighborhood: {
          required,
        },
      };
    }
  },
  computed: {
    ...mapState('settingsStores', ['loadingStoreDetail']),
    ...mapGetters('settingsStores', [
      'getZipCode',
      'getCountry',
      'getState',
      'getCity',
      'getNeighborhood',
      'getIsRequired',
    ]),
    zipCode: {
      get() {
        return this.getZipCode;
      },
      set(zipCode) {
        this.updateZipCode(zipCode);
      },
    },
    country: {
      get() {
        return this.getCountry;
      },
      set(country) {
        this.updateCountry(country);
        if (country) this.hideTooltips();
      },
    },
    state: {
      get() {
        return this.getState;
      },
      set(state) {
        this.updateState(state);
        if (state) this.hideTooltips();
      },
    },
    city: {
      get() {
        return this.getCity;
      },
      set(city) {
        this.updateCity(city);
        if (city) this.hideTooltips();
      },
    },
    neighborhood: {
      get() {
        return this.getNeighborhood;
      },
      set(neighborhood) {
        this.updateNeighborhood(neighborhood);
      },
    },
  },
  watch: {
    getIsRequired() {
      if (!this.getIsRequired) this.hideTooltips();
    },
  },
  mounted() {
    bus.$on('required-fields', item => {
      this.verifyRequiredFields(item);
    });
    bus.$on('hide-tooltips', () => {
      this.hideTooltips();
    });
  },
  beforeMount() {
    bus.$off('required-fields');
    bus.$off('hide-tooltips');
  },
  methods: {
    ...mapActions('settingsStores', [
      'updateZipCode',
      'updateCountry',
      'updateState',
      'updateCity',
      'updateNeighborhood',
    ]),
    verifyRequiredFields(field) {
      this.hideTooltips();

      if (field === 'country') {
        this.isCountryError = true;
      } else if (field === 'city') {
        this.isCityError = true;
      } else if (field === 'state') {
        this.isStateError = true;
      } else if (field === 'neighborhood') {
        this.isNeighborhoodError = true;
      }
    },
    hideTooltips() {
      if (this.country || !this.getIsRequired) {
        this.isCountryError = false;
      }
      if (this.city || !this.getIsRequired) {
        this.isCityError = false;
      }
      if (this.state || !this.getIsRequired) {
        this.isStateError = false;
      }
      if (this.neighborhood || !this.getIsRequired) {
        this.isNeighborhoodError = false;
      }
    },
    /**
     * @description Zipcode search, validation of it and set address informations
     */
    searchZipCode(event) {
      let code = event?.target?.value;
      if (!code) return;

      code = code.replace(/[^\w\s]/gi, '');
      if (code.length < 4) return;

      getCustomerZipCode(code).then(({ data }) => {
        if (!data) return;

        this.country = data.data.country;
        this.state = data.data.state;
        this.city = data.data.city;
      });
    },
    /**
     * @description Specific search according to field and term
     */
    searchResults(term, fieldKey) {
      const filterTerm = term.length > 0 ? term : ' ';
      this.stateLoading = true;

      getResultsByField({
        field: fieldKey,
        term: filterTerm,
      })
        .then(({ data }) => {
          this.stateOptions = data?.data.map(item => item.id);
        })
        .finally(() => {
          this.stateLoading = false;
        });
    },
    /**
     * @description Specific search according to field and term in a field with depends
     */
    searchResultsWithDepends(term, fieldKey, fieldDepends) {
      const filterTerm = term.length > 0 ? term : ' ';
      this.cityLoading = true;

      getResultsByField({
        field: fieldKey,
        term: filterTerm,
        where: `state:${fieldDepends}`,
      })
        .then(({ data }) => {
          this.cityOptions = data?.data.map(item => item.id);
        })
        .finally(() => {
          this.cityLoading = false;
        });
    },
    /**
     * @description Organize depends field data and call the getResultsByField request
     */
    callDependsRequest(fieldKey, fieldDepends) {
      this.searchResultsWithDepends('', fieldKey, fieldDepends);
    },
    /**
     * @description Validate if this field is editable or not
     */
  },
};
</script>
<style lang="scss" scoped>
.stores-register-locale {
  display: flex;
  padding: 40px 20px 20px;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  height: 242px;

  h2 {
    font-size: 20px;
    width: 145px;
    line-height: 25px;
  }
  &__title {
    width: 30%;
  }
  &__form {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-district,
    &-city {
      margin-bottom: 0px;
      width: 256px;
    }

    div {
      margin-bottom: 32px;
      input {
        width: 256px;
      }
      .field-invalid {
        border-color: $oto-seller;
      }
    }

    &-box {
      display: flex;
      width: 256px;
      justify-content: space-between;
      div {
        width: 120px;
        margin-bottom: 0px;
        input {
          width: 100%;
          margin-right: unset;
        }
      }
    }

    label {
      span {
        color: $pink-500;
      }
    }
  }
}
</style>

<style lang="scss">
.stores-register-locale {
  .field-invalid {
    .vs__dropdown-toggle {
      border-color: $oto-seller;
    }
  }
}
</style>
