import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'home';

/**
 * @see https://api-prd.otocrm.com.br/v3/home/getBanners
 * @description Get home banner
 */
export const getHomeBanner = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getBanners`, {
    headers: { Authorization: `Bearer ${tokenV3}` },
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      key: 'banner-home',
    },
  });
};
