<template>
  <div class="products-dashboard">
    <search-dashboard />

    <h1 class="card-group-title">{{ $t('product.last-seven-days') }}</h1>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <product-category-best-seller />
      </div>
      <div class="col-sm-12 col-md-6">
        <product-best-seller />
      </div>
    </div>
  </div>
</template>

<script>
import SearchDashboard from '@/components/products/search/SearchDashboard';
import ProductCategoryBestSeller from '@/components/products/product/ProductCategoryBestSeller';
import ProductBestSeller from '@/components/products/product/ProductBestSeller';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'ProductsDashboard',
  components: {
    SearchDashboard,
    ProductCategoryBestSeller,
    ProductBestSeller,
  },
  mounted() {
    useMixpanelConfig('view_analytics_products');
  },
};
</script>
