import _ from 'lodash';
import { verifyMixinType } from '@/utilities/constants';

const mixin = {
  data() {
    return {
      isEmpty: false,
      hasError: false,
      loading: true,
      thensIsNotEmpty: true,
    };
  },
  methods: {
    /**
     * @description Checks the current state is: 'done', 'loading', 'with a error' or 'is empty'.
     */
    $_verifyLoaded(verify) {
      switch (verify) {
        case verifyMixinType.done:
          return !this.hasError && !this.loading && !this.isEmpty && this.thensIsNotEmpty;
        case verifyMixinType.loading:
          return this.loading && this.thensIsNotEmpty;
        case verifyMixinType.info:
          return !this.loading && (this.hasError || this.isEmpty);
        case verifyMixinType.infoThens:
          return !this.loading && (this.hasError || !this.thensIsNotEmpty);
        default:
          this.hasError = true;
          return false;
      }
    },
    $_verifyLoadedVuex(verify) {
      switch (verify) {
        case verifyMixinType.done:
          return !this.getIsEmpty && !this.getHasError && !this.getLoading;
        case verifyMixinType.loading:
          return this.getLoading;
        case verifyMixinType.info:
          return !this.getLoading && (this.getHasError || this.getIsEmpty);
        default:
          this.getHasError = true;
          return false;
      }
    },
    /**
     * @description Card height adjustment.
     */
    $_componentHeight() {
      const style = getComputedStyle(this.$el);
      const height = parseInt(style.height) - (parseInt(style.paddingTop) + parseInt(style.paddingBottom));

      if (this.hasError || this.isEmpty) {
        const InfoCard = this.$refs['message-error']?.$el;
        if (!InfoCard) return;
        InfoCard.style.height = height + 'px';
      }
    },
    /**
     * @description First pattern test, checks if the card has data
     */
    $_verifyData(data) {
      this.isEmpty = _.isEmpty(data);
      if (this.isEmpty) {
        this.$_componentHeight();
        this.loading = false;
      }
      return this.isEmpty;
    },
    /**
     * @description Second pattern test, checks if the card has data
     */
    $_verifyHasData(response) {
      for (const e in response) {
        this.isEmpty = !response[e]?.has_data;
        if (this.isEmpty == false) {
          return this.isEmpty;
        }
      }
      this.$_componentHeight();
      return this.isEmpty;
    },
    /**
     * @description Second pattern test, checks if the card has data
     */
    $_reqConfig() {
      this.loading = true;
      this.hasError = false;
      this.isEmpty = false;
      this.thensIsNotEmpty = true;
    },
  },
};

export default mixin;
