<template>
  <div class="list-pagination-skeleton mt-4" :class="{ 'is-lightbox-active': isLightboxActive }">
    <div class="see-per-page">
      <skeleton-loader width="23px" height="14px" class="mr-2" />
      <skeleton-loader width="70px" height="32px" class="mr-2" />
      <skeleton-loader width="67px" height="14px" />
    </div>
    <div class="pages">
      <skeleton-loader width="16px" height="14px" class="mr-3" />
      <skeleton-loader width="30px" height="26px" class="mr-3" />
      <skeleton-loader width="16px" height="14px" class="mr-3" />
      <skeleton-loader width="16px" height="14px" class="mr-3" />
      <skeleton-loader width="16px" height="14px" class="mr-3" />
      <skeleton-loader width="16px" height="14px" />
    </div>
    <div class="go-to-page">
      <skeleton-loader width="96px" height="14px" class="mr-2" />
      <skeleton-loader width="70px" height="32px" />
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    isLightboxActive: {
      type: Boolean,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-pagination-skeleton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
}
.is-lightbox-active {
  padding: 0 40px 0 45px;
}
</style>
