import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

type TableType = {
  table: string;
};

type RawTableParamTypes = {
  table: string;
  orderBy?: string;
  page?: number;
  limit?: number;
  filters?: string[];
  fileType?: string;
  responseType?: string;
};

/**
 *
 * @description return raw data access columns
 * @see https://labs.pmweb.com.br/docs/context/v3/rawdata/getTable
 */
export const getRawData = ({ table, page, limit, orderBy, filters, fileType, responseType }: RawTableParamTypes) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/rawdata/getTable', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      table,
      orderBy,
      page,
      limit,
      filters: filters,
      export: fileType,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 *
 * @description return raw data access columns
 * @see https://labs.pmweb.com.br/docs/context/v3/rawdata/getAvailableTables
 */
export const getRawDataAvailableTables = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/rawdata/getAvailableTables',
    params: {
      brand: brand.id,
    },
  });
};

/**
 *
 * @description return raw data access columns
 * @see https://labs.pmweb.com.br/docs/context/v3/rawdata/getAvailableColumns
 */
export const getAvailableColumns = ({ table }: TableType) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const locale = store.getters.user ? store.getters.user.language.toLowerCase() : 'pt-br';

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/rawdata/getAvailableColumns',
    params: {
      brand: brand.id,
      table: table,
      locale,
    },
  });
};
