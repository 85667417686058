<template>
  <el-dialog v-model="isVisible" id="personas-modal-delete" append-to-body>
    <p>{{ $t('personas.home.do-you-want-delete') }}</p>
    <div class="dialog">
      <div class="cancel btn btn-secondary" @click="closeModal">
        {{ $t('cancel') }}
      </div>
      <div
        class="confirm btn"
        @click="
          () => {
            confirmOperation();
            closeModal();
          }
        "
      >
        {{ $t('confirm') }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
import { useToast } from 'vue-toastification';
// endpoints
import { deletePersona } from '@/services/personas';

export default {
  name: 'DeletePersona',
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      personaId: null,
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('personas-delete-item', personaId => {
      this.personaId = personaId;
      this.openModal();
    });
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.personaId = null;
      this.isVisible = false;
    },
    confirmOperation() {
      deletePersona(this.personaId)
        .then(res => {
          if (!res?.data?.success) {
            this.toast.error(
              this.$t(`errors.err-${res.status}`, {
                type: this.$tc('personas.title', 1).toLowerCase(),
              }),
            );
          }
        })
        .finally(() => {
          bus.$emit('personas-reload-list');
          this.closeModal();
        });
    },
  },
};
</script>

<style lang="scss">
#personas-modal-delete {
  max-width: 500px;

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}
</style>
