<template>
  <div class="col-lg-12 card indicators__header">
    <div class="row">
      <div class="col-md-3 ih__title-wrapper">
        <icon-base class="ih__icon-phone" :color="'#6c90f4'" :box="'0 0 12 18'" w="16" h="21">
          <icon-phone />
        </icon-base>
        <h1 class="ih__title">{{ $t('360.indicators.header.title') }}</h1>
      </div>
      <div class="col-md-2 ih__item">
        <label>{{ $t('360.conversion-window') }}:</label>
        <v-select
          v-model="conversionWindowSelected"
          label="title"
          class="w-100"
          :reduce="option => option.value"
          :clearable="false"
          :options="conversionWindowOptions"
          @option:selected="selectConversionWindow"
          @click="openMixPanel('view_oto_indicators_open_conversion-window-input')"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('360.sellers.no-results') }}
          </template>
        </v-select>
      </div>

      <div class="col-md-2 ih__item">
        <label>{{ $t('360.indicators.header.label-contact-types') }}:</label>
        <v-select
          v-model="sourceId"
          :clearable="false"
          :searchable="false"
          :options="sourceOptions"
          :reduce="item => item.id"
          label="name"
          :placeholder="$tc('_select', 2)"
          @option:selected="selectSourceId"
          @click="openMixPanel('view_oto_indicators_open_contact-type_input')"
        >
          <template #option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #selected-option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('integrations.no-results') }}
          </template>
        </v-select>
      </div>

      <div class="col-md-2 ih__item">
        <label>{{ $t('360.indicators.header.label-segment') }}</label>
        <v-select
          v-model="segmentSelected"
          label="name"
          :clearable="false"
          class="w-100"
          :options="getSegments"
          :placeholder="$t('select')"
          @option:selected="selectSegmentId"
          @click="openMixPanel('view_oto_indicators_open_segment_input')"
        >
          <template #option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #selected-option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('360.sellers.no-results') }}
          </template>
        </v-select>
      </div>

      <div class="col-md-3 ih__item">
        <div v-show="segmentSelected.id === 'store'">
          <label>{{ $t('360.indicators.header.label-store') }}</label>
          <v-select
            ref="segmentStore"
            v-model="storeSelected"
            label="name"
            :clearable="false"
            class="vselect-indicator-store w-100"
            :options="getStores"
            :filter-by="storesFilter"
            :placeholder="$tc('_select', 2)"
            @option:selected="selectStoreId"
            @click="openMixPanel('view_oto_indicators_open_store_input')"
          >
            <template #option="{ name, id }">
              <span v-if="id" :title="`${name} - ${id}`">{{ $t(name) }} - {{ id }}</span>
              <span v-else>{{ $t(name) }}</span>
            </template>
            <template #selected-option="{ name, id }">
              <span v-if="id">{{ $t(name) }} - {{ id }}</span>
              <span v-else>{{ $t(name) }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </div>

        <template v-if="segmentSelected.id === 'seller'">
          <label>{{ $t('360.indicators.header.label-seller') }}</label>
          <v-select
            v-model="sellerSelected"
            label="fullName"
            :clearable="false"
            class="w-100"
            :options="getSellers"
            :filter-by="sellersFilter"
            :placeholder="$tc('_select', 2)"
            @search="searchTerm"
            @search:blur="resetSellers"
            @option:selected="selectSellerId"
            @click="openMixPanel('view_oto_indicators_open_seller_input')"
          >
            <template #option="{ fullName, enrolment }">
              <span v-if="enrolment" :title="`${fullName} - ${enrolment}`">{{ $t(fullName) }} - {{ enrolment }}</span>
              <span v-else>{{ $t(fullName) }}</span>
            </template>
            <template #selected-option="{ fullName, enrolment }">
              <span v-if="enrolment">{{ $t(fullName) }} - {{ enrolment }}</span>
              <span v-else>{{ $t(fullName) }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </template>

        <template v-if="segmentSelected.id === 'campaign'">
          <label>{{ $t('360.indicators.header.label-campaign') }}</label>
          <v-select
            v-model="campaignSelected"
            label="name"
            :clearable="false"
            class="w-100"
            :options="getCampaigns"
            :placeholder="$tc('_select', 2)"
            @option:selected="selectCampaignId"
            @click="openMixPanel('view_oto_indicators_open_campaign_input')"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </template>
      </div>
    </div>
    <div class="row ih__funnel-header">
      <indicator-funnel-header />
    </div>
  </div>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

// components
import IndicatorFunnelHeader from '@/components/oto/indicators/IndicatorFunnelHeader';

// helpers
import bus from '@/helpers/events/bus';
import {
  useMixpanelSelectConversionWindow,
  useMixpanelSelectContactType,
  useMixpanelSelectSegment,
  useMixpanelSelectSegmentBy,
  useMixpanelConfig,
} from '@/utilities/mixpanel';

// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconPhone from '@/assets/vue-icons/icons/Phone';

export default {
  name: 'IndicatorsHeader',
  components: {
    vSelect,
    IconPhone,
    IconBase,
    IndicatorFunnelHeader,
  },
  data() {
    return {
      conversionWindowOptions: [],
      conversionWindowSelected: 30,
      segmentSelected: [],
      storeSelected: null,
      sellerSelected: null,
      campaignSelected: null,
      sourceId: {
        id: '',
        name: this.$t('all'),
      },
      sourceOptions: [
        {
          id: '',
          name: this.$t('all'),
        },
        {
          id: 'oto',
          name: this.$t('360.indicators.funnel-header.text-activities'),
        },
        {
          id: 'user',
          name: this.$t('360.indicators.funnel-header.text-contracts'),
        },
      ],
      tab: '',
    };
  },
  computed: {
    ...mapGetters('indicators', [
      'getConversionWindow',
      'getSegments',
      'getStores',
      'getSellers',
      'getCampaigns',
      'getSourceId',
    ]),
    ...mapGetters(['selectedBrand']),
  },
  created() {
    this.initConversionWindowOptions();
  },
  mounted() {
    this.setStores();
    this.setSellers();
    this.setCampaigns();
    this.resetSelected();

    bus.$on('render-cards', brand => {
      this.verifyResetValues(brand);
    });

    bus.$on('update-selected-brand', () => {
      this.initConversionWindowOptions();
    });

    bus.$on('indicator-focus-segment-by', () => {
      this.scrollToTop();
      this.setSegmentSelectedToStore();
      this.setTimeOutToRenderFocus();
    });

    bus.$on('indicators-change-tab-nav', item => {
      this.tab = item;
    });
  },
  beforeUnmount() {
    bus.$off(['render-cards', 'update-selected-brand', 'indicator-focus-segment-by']);
  },
  methods: {
    ...mapActions('indicators', [
      'setConversionWindow',
      'setStores',
      'setSellers',
      'setCampaigns',
      'setSegmentId',
      'setSellerId',
      'setStoreId',
      'setCampaignId',
      'setSourceId',
      'setSourceName',
      'resetSelected',
      'setIndicatorsByTerm',
    ]),

    openMixPanel(name) {
      useMixpanelConfig(name);
    },

    verifyResetValues(brand) {
      if (brand?.brand) {
        this.setStores();
        this.setSellers();
        this.setCampaigns();
        this.resetSelectedModel();
      }

      bus.$emit(`indicators-select-emit-${this.tab}`, brand);
      bus.$emit('indicator-funnel-header-select');
    },

    selectSegmentId(segment) {
      if (segment.id === 'general') {
        this.setStoreId('');
        this.setSellerId('');
        this.setCampaignId('');

        this.storeSelected = null;
        this.sellerSelected = null;
        this.campaignSelected = null;

        this.handleChangeSelectValue();
      }

      useMixpanelSelectSegment('view_oto_indicators_select_segment', segment.id);
      bus.$emit('selected-segment', segment);

      if (segment && segment.id) this.setSegmentId(segment.id);
    },

    selectConversionWindow(option) {
      const { value } = option;
      useMixpanelSelectConversionWindow('view_oto_indicators_select_conversion-window', value);
      this.setConversionWindow(value);
      this.conversionWindowSelected = value;

      this.handleChangeSelectValue();
    },

    initConversionWindowOptions() {
      const { lookback } = this.selectedBrand;
      this.conversionWindowSelected = lookback;
      this.setConversionWindow(lookback);

      //create an array for LOOKBACK/conversion window options (same day, 1 day... until 31 days)
      this.conversionWindowOptions = Array(...Array(32)).map((x, i) => ({
        title: `${i} ${this.$tc('360.day', i)}`,
        value: i,
      }));
      this.conversionWindowOptions[0].title = this.$t('360.conversion-window-same-day');
      this.conversionWindowOptions[1].title = `1 ${this.$tc('360.day', 1)}`;
    },

    resetSelectedModel() {
      this.storeSelected = null;
      this.sellerSelected = null;
      this.campaignSelected = null;
      this.segmentSelected = '';

      this.resetSelected();
    },

    handleChangeSelectValue() {
      bus.$emit(`indicators-select-emit-${this.tab}`);
      bus.$emit('indicator-funnel-header-select');
    },

    selectSourceId(source) {
      if (!source) return;
      useMixpanelSelectContactType('view_oto_indicators_select_contact-type', source.name);
      this.setSourceId(source.id);
      this.setSourceName(source.name);

      this.handleChangeSelectValue();
    },

    resetSellers() {
      this.setSellers();
    },

    selectSellerId(seller) {
      if (seller) this.setSellerId(seller.id);

      useMixpanelSelectSegmentBy('view_oto_indicators_select_seller', seller);

      // reset storeId and campaignId
      this.setStoreId(null);
      this.storeSelected = null;
      this.setCampaignId(null);
      this.campaignSelected = null;

      this.handleChangeSelectValue();
    },
    selectStoreId(store) {
      if (store) this.setStoreId(store.id);

      useMixpanelSelectSegmentBy('view_oto_indicators_select_store-id', store);

      // reset sellerId and campaignId
      this.setSellerId(null);
      this.sellerSelected = null;
      this.setCampaignId(null);
      this.campaignSelected = null;

      this.handleChangeSelectValue();
    },
    selectCampaignId(campaign) {
      if (campaign) this.setCampaignId(campaign.id);

      useMixpanelSelectSegmentBy('view_oto_indicators_select_campaign', campaign.fullname);

      // reset sellerId and storeId
      this.setStoreId(null);
      this.storeSelected = null;
      this.setSellerId(null);
      this.sellerSelected = null;

      this.handleChangeSelectValue();
    },

    /**
     * @description stores filter options with two labels
     */
    storesFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name.toLowerCase()?.includes(temp) || option?.id?.includes(temp);
    },

    /**
     * @description sellers filter options with two labels
     */
    sellersFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name.toLowerCase()?.includes(temp) || option?.enrolment?.toLowerCase()?.includes(temp);
    },

    /**
     * @description search by term
     */
    searchTerm(term) {
      if (!term) return;
      this.triggerDebounce(term);
    },

    /**
     * @description set term on seller store
     */
    triggerDebounce: debounce(function (term) {
      this.setIndicatorsByTerm({ term });
    }, 500),

    /**
     * @description set scroll to top
     */
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    },

    /**
     * @description set segmentSelected to store
     */
    setSegmentSelectedToStore() {
      const segment = { id: 'store', name: '_store' };
      this.segmentSelected = segment;
      this.selectSegmentId(segment);
    },

    /**
     * @description set timeOut to render focus in time
     */
    setTimeOutToRenderFocus() {
      setTimeout(() => {
        this.$refs.segmentStore.$refs.search.focus();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.indicators__header {
  min-height: 173px;
  .ih__title-wrapper {
    display: flex;
    align-items: center;
  }
  .ih__title {
    margin: 0;
    padding-left: 15px;
  }
  .ih__funnel-header {
    justify-content: center;
  }
}
</style>

<style lang="scss">
.indicators__header {
  .vselect-indicator-store {
    width: 275px;
    .vs__dropdown-menu {
      border-radius: 0 18px 18px 18px;
      min-width: 275px;
      max-width: 395px;
      width: auto;
      .vs__dropdown-option {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
