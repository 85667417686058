<template>
  <div :class="{ loadHeight: loading }" class="allsegments-revenuebysegment card">
    <div v-show="$_verifyLoaded('done')" class="card-body">
      <div class="header-wrapper">
        <h2 class="card-title d-flex align-items-center">
          <i class="icon icon-money"></i>
          <span v-if="metricActive.value === 'revenue'">{{ $t('segments-revenue.title') }}</span>
          <span v-if="metricActive.value === 'churn'">Churn e LTV por segmento</span>
        </h2>

        <div v-if="showSelectOptions" class="metric-group">
          <label>Métrica</label>
          <v-select
            v-model="metricActive"
            :clearable="false"
            :searchable="false"
            :options="selectOptions"
            label="title"
            @option:selected="onChangeSelectOption"
          >
            <template #no-options>
              {{ $t('integrations.no-results') }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
          </v-select>
        </div>
      </div>

      <template v-if="metricActive.value === 'revenue'">
        <div id="containerRevenuebySegment"></div>
      </template>
      <template v-if="metricActive.value === 'churn'">
        <div id="containerChurnLtv"></div>
      </template>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// libs
import Highcharts from 'highcharts';
import vSelect from 'vue-select';
// services
import getMetrics from '@/services/getmetrics';
import { getMetricsSegment } from '@/services/churnLtv';
// helpers
import bus from '@/helpers/events/bus';
import verifyMixin from '@/helpers/mixins/verifyMixin';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { formatDecimal, formatCurrency, formatPercent } from '@/utilities/formatters';

export default {
  name: 'RevenueBySegment',
  components: {
    BaseLoader,
    InfoCard,
    vSelect,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin],
  data() {
    return {
      categories: [],
      revenue: [],
      customers: [],
      avgRevenue: [],
      avgCustomers: [],

      metricActive: {
        value: 'revenue',
        title: 'Clientes e receita',
      },
      selectOptions: [
        {
          value: 'revenue',
          title: 'Clientes e receita',
        },
        {
          value: 'churn',
          title: 'Churn e LTV',
        },
      ],
      churnAvarageRate: [],
      churnLtvAvarage: [],
      churnSeries: [],
      brand: null,
    };
  },
  computed: {
    abrevCategories() {
      return this.categories.map(category => {
        const split = category.split(' ');
        if (split.length >= 3) return split.map(word => word.charAt(0).toUpperCase()).join('');
        return category;
      });
    },
    showSelectOptions() {
      return [1, 177].indexOf(this.brand) >= 0;
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetchData();
      }
    },
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.metricActive.value === 'revenue' ? this.fetchData() : this.fetchChurnData();
    });
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      this.brand = this.$store.getters.selectedBrand?.id;

      this.reset();
      getMetrics({
        metrics: 'revenue_by_segment',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;
          data.data.forEach(item => {
            this.categories.push(item.group);
            this.revenue.push(item.data.revenue);
            this.customers.push(item.data.customers);
            this.avgRevenue.push(item.data.avg_revenue);
            this.avgCustomers.push(item.data.avg_customers);
          });
          this.loadGraphic();
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchChurnData() {
      this.$_reqConfig();

      getMetricsSegment({
        metrics: 'churnAverageRate,ltvAverage',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data)) return;
          this.churnAvarageRate = data?.metrics?.churnAverageRate;
          this.churnLtvAvarage = data?.metrics?.ltvAverage;
          this.churnSeries = data?.metrics?.series;
        })
        .then(() => {
          this.loadChurnGraphic();
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadGraphic() {
      const self = this;
      Highcharts.chart('containerRevenuebySegment', {
        chart: {
          height: 270,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        xAxis: {
          categories: this.abrevCategories,
          lineWidth: 0,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            labels: {
              format: '{value}%',
            },
            lineWidth: 0,
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          padding: 15,
          backgroundColor: '#fff',
          style: {
            lineHeight: '20px',
          },
          formatter: function formater() {
            const customers = formatDecimal(self.customers[this.points[0].point.index]);
            const avgCustomers = formatPercent(this.points[0].y / 100, 2);
            const revenue = formatCurrency(self.revenue[this.points[1].point.index]);
            const avgRevenue = formatPercent(this.points[1].y / 100, 2);

            return `${self.$i18n.t('segments-revenue.tooltip.segment')} <span style="font-weight:600">${
              this.x
            }</span>, ${self.$i18n.t('segments-revenue.tooltip.compose')}<br>
              <span style="font-weight:600">${avgCustomers}</span> <span style="color: #666">(${customers})</span>
              ${self.$i18n.t('segments-revenue.tooltip.base')}<br>
              ${self.$i18n.t('segments-revenue.tooltip.period')} <span style="font-weight:600">${avgRevenue}</span>
              <span style="color: #666">(${revenue})</span><br>${self.$i18n.t('segments-revenue.tooltip.revenue')}`;
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          series: {
            pointPadding: 0,
            borderWidth: 0,
            shadow: false,
          },
        },
        series: [
          {
            name: this.$i18n.t('clients'),
            color: '#b8cafa',
            data: this.avgCustomers,
            type: 'column',
          },
          {
            name: this.$i18n.t('revenue'),
            color: '#3354ad',
            data: this.avgRevenue,
            type: 'column',
          },
        ],
      });
    },
    loadChurnGraphic() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });
      Highcharts.chart('containerChurnLtv', {
        chart: {
          zoomType: 'xy',
          height: 270,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: '',
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        xAxis: {
          categories: this.churnSeries,
          lineWidth: 0,
          tickWidth: 0,
        },
        yAxis: [
          {
            title: {
              text: '',
            },
            labels: {
              formatter: function formatter() {
                return formatPercent(this.value, 2);
              },
            },
            lineWidth: 0,
          },
          {
            title: {
              text: '',
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value > 1000 && this.value <= 999999) return formatCurrency(this.value, { shortNumber: true });
                else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                return formatCurrency(this.value);
              },
            },
            opposite: true,
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 14,
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;
              if (x.series.userOptions?.id === 'ltv') {
                formattedValue = formatCurrency(x.y);
              } else if (x.series.userOptions?.id === 'churn') {
                formattedValue = formatPercent(x.y, 2);
              }
              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          series: {
            pointPadding: 0,
            borderWidth: 0,
            shadow: false,
          },
        },
        series: [
          {
            id: 'ltv',
            name: 'LTV médio',
            type: 'column',
            color: '#36AAD6',
            data: this.churnLtvAvarage,
            yAxis: 1,
            tooltip: {
              valueSuffix: '',
            },
          },
          {
            id: 'churn',
            name: 'Churn rate',
            type: 'spline',
            color: '#A05195',
            data: this.churnAvarageRate,
            tooltip: {
              valueSuffix: '',
            },
          },
        ],
      });
    },
    reset() {
      this.categories = [];
      this.revenue = [];
      this.customers = [];
      this.avgRevenue = [];
      this.avgCustomers = [];
    },
    onChangeSelectOption() {
      this.metricActive.value === 'churn' ? this.fetchChurnData() : this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.allsegments-revenuebysegment {
  &.loadHeight {
    height: 365px;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .metric-group {
    width: 180px;
  }
}
</style>

<style lang="scss">
.allsegments-revenuebysegment {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
