<template>
  <div>
    <custom-fields-header />
    <custom-fields-table :is-empty="isEmptyState" />
    <custom-fields-empty-state :is-empty="isEmptyState" />
    <custom-fields-modal />
  </div>
</template>
<script>
// libs
import { mapGetters, mapActions } from 'vuex';
// import mixpanel from 'mixpanel-browser';
// components
import CustomFieldsHeader from '@/components/settings/customFields/CustomFieldsHeader';
import CustomFieldsTable from '@/components/settings/customFields/CustomFieldsTable';
import CustomFieldsEmptyState from '@/components/settings/customFields/CustomFieldsEmptyState';
import CustomFieldsModal from '@/components/settings/customFields/CustomFieldsModal';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'CustomFields',
  components: {
    CustomFieldsHeader,
    CustomFieldsTable,
    CustomFieldsEmptyState,
    CustomFieldsModal,
  },
  computed: {
    ...mapGetters('customFields', ['getIsEmptyState']),
    isEmptyState() {
      return this.getIsEmptyState;
    },
  },
  mounted() {
    useMixpanelConfig('view_settings_customFields');
    this.setIsEmptyState(0);
  },
  methods: {
    ...mapActions('customFields', ['setIsEmptyState']),
  },
};
</script>
