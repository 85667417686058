<template>
  <div class="container mb-4 loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('frequency') }}</h5>
        <span class="segment-label">{{ $_segmentNameSelect() }}</span>
      </header>
      <lightbox-template-chart v-if="chartFrequency" :settings="chartFrequency" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'FrequencyChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  data() {
    return {
      chartFrequency: undefined,
      segmentSelected: '',
    };
  },
  mounted() {
    this.reset();
    this.fetchGraphic();
  },
  methods: {
    fetchGraphic() {
      getMetrics({
        metrics: ['arr_active_frequency_dd', 'frequency'],
        segments: this.segmentSelected || undefined,
        expand: 1,
        isLightbox: true,
      })
        .then(({ data: { data } }) => {
          const { metrics } = data;
          if (this.$_verifyData(metrics)) return;
          const { frequency } = data.metrics;
          const obj = data.metrics;
          const value = obj ? Object.keys(obj) : null;
          const activeFrequency = {
            [value[0]]: [],
            [value[1]]: [],
            [value[2]]: [],
            [value[3]]: [],
            [value[4]]: [],
            [value[5]]: [],
          };
          for (let i = 0; i < value.length - 1; i += 1) {
            obj[value[i]].forEach(item => {
              activeFrequency[value[i]].push(item);
            });
            // Frequency
            let { labels } = data;
            labels = this.format({ labels, formatTo: 'MMM/YY' });
            this.loadChartFrequency(labels, activeFrequency, frequency);
          }
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadChartFrequency(labels, activeFrequency, frequency) {
      const seriesName = activeFrequency ? Object.keys(activeFrequency) : null;
      const series = seriesName
        ? seriesName.map(name => ({
            name: `${name} ${this.$tc('purchase', name, activeFrequency[name])}`,
            data: activeFrequency[name],
            type: 'column',
          }))
        : null;
      if (series) {
        series.push({
          name: this.$t('avg-frequency'),
          data: frequency,
          type: 'spline',
          yAxis: 1,
          tooltip: {
            valueDecimals: 2,
          },
        });
      }
      this.chartFrequency = {
        chart: {
          height: 280,
        },
        colors: ['#36AAD6', '#EEDA36', '#FA6F32', '#9CFF99', '#F8A9D6', '#4866FF'],
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 30,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
          },
        },
        xAxis: {
          allowDecimals: false,
          barWidth: 4,
          categories: labels,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
          },
          {
            title: {
              text: null,
            },
            opposite: true,
          },
        ],
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0,
          },
          series: {
            stacking: 'normal',
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
            lineWidth: 3,
            fillOpacity: 1,
          },
        },
        series,
      };
    },
    reset() {
      this.$_reqConfig();
      this.chartFrequency = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
