<template>
  <path
    d="M1.66668 13.8667H17.36V14.6H0.93335V2.42669H1.66668V13.8667ZM2.76668 9.90669C2.69335 9.02669 3.28002 8.29335 4.08668
    8.14669C4.38002 8.14669 4.60002 8.14669 4.89335 8.29335L5.84668 6.75335C5.70002 6.53335 5.55335 6.24002 5.48002 5.94669C5.40668
    5.14002 5.99335 4.33335 6.87335 4.26002C7.75335 4.18669 8.48668 4.77335 8.63335 5.58002C8.63335 5.80002 8.63335 6.02002 8.56002
    6.24002L10.2467 7.04669C10.32 6.53335 10.6134 6.09335 10.98 5.65335C11.7867 4.84669 13.0334 4.62669 14.06 5.14002L15.16
    3.38002C15.0867 3.30669 15.0134 3.30669 15.0134 3.23335C14.72 2.94002 14.5734 2.50002 14.5734 2.13335C14.5734 1.69335
    14.7934 1.32669 15.0867 1.03335C15.2334 0.813354 15.6734 0.666687 16.1134 0.666687C16.9934 0.666687 17.6534 1.40002
    17.6534 2.28002C17.6534 2.72002 17.5067 3.08669 17.14 3.38002C16.8467 3.67335 16.48 3.82002 16.04 3.82002C16.04 3.82002
    16.04 3.82002 15.9667 3.82002C15.8934 3.82002 15.82 3.82002 15.7467 3.82002L14.5734 5.65335C14.6467 5.65335 14.6467
    5.72669 14.72 5.72669C14.94 5.94669 15.16 6.24002 15.3067 6.53335C15.7467 7.56002 15.5267 8.66002 14.7934 9.46669L16.8467
    11.52L16.3334 12.0334L14.2067 9.90669C13.7667 10.1267 13.3267 10.2734 12.8134 10.2734C12.08 10.2734 11.42 9.98002 10.9067
    9.46669C10.4667 9.02669 10.1734 8.44002 10.1 7.78002L8.19335 6.82669C7.90002 7.12002 7.60668 7.26669 7.24002 7.34002C7.16668
    7.34002 7.09335 7.34002 7.02002 7.34002C6.87335 7.34002 6.65335 7.26669 6.43335 7.19335L5.55335 8.73335C5.77335 8.95335
    5.84668 9.24669 5.92002 9.54002C5.99335 9.98002 5.84668 10.3467 5.62668 10.7134C5.40668 11.0067 5.04002 11.2267 4.60002
    11.3C4.52668 11.3 4.45335 11.3 4.38002 11.3C3.57335 11.3 2.91335 10.7134 2.76668 9.90669ZM15.2334 2.20669C15.2334 2.42669
    15.3067 2.64669 15.4534 2.79335C15.6 2.94002 15.82 3.01335 16.04 3.01335C16.26 3.01335 16.48 2.94002 16.6267 2.79335C16.7734
    2.64669 16.8467 2.42669 16.92 2.20669C16.92 1.76669 16.5534 1.40002 16.1134 1.32669C15.6 1.40002 15.2334 1.76669 15.2334
    2.20669ZM10.9067 7.41335C10.9067 7.56002 10.9067 7.63335 10.9067 7.78002C10.98 8.22002 11.1267 8.66002 11.4934 9.02669C12.2267
    9.76002 13.5467 9.76002 14.28 9.02669C14.8667 8.44002 15.0134 7.63335 14.72 6.82669C14.6467 6.60669 14.5 6.38669 14.28
    6.24002C13.9134 5.87335 13.4 5.65335 12.8867 5.65335C12.3734 5.65335 11.86 5.87335 11.4934 6.24002C11.1267 6.53335 10.9067 
    6.97335 10.9067 7.41335ZM6.21335 5.87335C6.21335 6.09335 6.36002 6.31335 6.50668 6.38669C6.72668 6.53335 6.94668 6.60669
    7.16668 6.60669C7.38668 6.60669 7.60668 6.46002 7.68002 6.31335C7.75335 6.16669 7.90002 5.94669 7.82668 5.72669C7.82668
    5.50669 7.68002 5.28669 7.53335 5.21335C7.38668 4.99335 7.24002 4.92002 7.09335 4.92002H7.02002C6.72668 4.99335 6.50668
    5.06669 6.43335 5.28669C6.28668 5.43335 6.21335 5.65335 6.21335 5.87335ZM3.50002 9.83335C3.50002 10.0534 3.64668 10.2734
    3.79335 10.3467C3.94002 10.4934 4.16002 10.5667 4.38002 10.4934C4.60002 10.4934 4.82002 10.3467 4.89335 10.2C4.96668
    10.0534 5.11335 9.83335 5.04002 9.61335C5.04002 9.39335 4.89335 9.17335 4.74668 9.10002C4.60002 8.95335 4.45335 
    8.95335 4.23335 8.95335H4.16002C3.94002 8.95335 3.72002 9.10002 3.64668 9.24669C3.57335 9.39335 3.50002 9.61335 3.50002 9.83335Z"
  />
</template>
