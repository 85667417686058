<template>
  <base-card-v2
    id="last-purchases"
    :title="$t('360.person.last-purchases.title')"
    icon="icon-cart"
    class="pr-0 pl-0"
    :class="{ loadHeight: loading }"
  >
    <base-card-header :title="$t('360.components.item-last-purchase.title')" />
    <customer-view-Last-purchase v-if="$_verifyLoaded('done')" :prop-data="itensLastPurchases" />
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import bus from '@/helpers/events/bus';

import CustomerViewLastPurchase from '@/components/oto/customerview/components/CustomerViewLastPurchase';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { getLastPurchases } from '@/services/oto/profile';
import { verifyMixin } from '@/helpers/mixins';

export default {
  name: 'LastPurchases',
  components: {
    CustomerViewLastPurchase,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      itensLastPurchases: {},
    };
  },
  mounted() {
    bus.$on('load-profile', profile => {
      if (!profile) return;

      this.fetchData(profile);
    });
  },
  beforeUnmount() {
    bus.$off('load-profile');
  },
  methods: {
    fetchData(pId) {
      const limit = 6;
      const page = 1;
      const orderBy = 'date_desc';
      this.$_reqConfig();

      getLastPurchases(pId, limit, page, orderBy)
        .then(res => {
          if (this.$_verifyData(res?.data)) return;
          this.itensLastPurchases = { ...res, pId };
          this.error = '';
        })
        .catch(error => {
          this.itensLastPurchases = {};
          this.error = error;
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
#last-purchases {
  &.loadHeight {
    height: 340px;
  }
  .card-title {
    padding-left: 25px;
    padding-right: 25px;
  }
}
</style>
