<template>
  <lightbox-template :id="id" icon="cart" :title="$tc('product.title', 2)" :show-segment-group="false">
    <div class="container mb-4">
      <header class="d-flex align-items-center mb-4">
        <h5 class="modal-subtitle">
          {{ $t('product.identified-worst-performances') }}
        </h5>
      </header>
    </div>

    <lightbox-skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <div v-show="$_verifyLoaded('done')" class="col-12">
      <lightbox-table
        :id="id"
        :data="data"
        :data-type="tabTypeActive"
        :is-lightbox-active="isLightboxActive"
        :settings-data="settingsData"
        :col-scheme="['chart:percentual']"
      />
    </div>
    <div v-show="$_verifyLoaded('info')" class="card">
      <info-card ref="message-error" :error="hasError" />
    </div>
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import bus from '@/helpers/events/bus';
import { getTable } from '@/services/product';
import { lightboxesMixin, verifyMixin, miniBarChartMixin } from '@/helpers/mixins';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import LightboxSkeleton from '@/components/lightbox/lightboxes/products/LightboxProductsIdentifiedSkeleton';
import LightboxTable from '@/components/lightbox/_molecules/_atoms/LightboxTable';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'LightboxProductsIdentified',
  components: {
    LightboxTemplate,
    LightboxSkeleton,
    LightboxTable,
    InfoCard,
  },
  mixins: [lightboxesMixin, miniBarChartMixin, verifyMixin],
  props: {
    settingsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: 'lightbox-products-identified',
      isLightboxActive: true,
      productID: this.$route.params.id,
      data: {
        limit: 10,
        page: 1,
        count: 0,
        values: [],
        valueTotal: 0,
      },
      tabTypeActive: 'worstPerformances',
      type: 'filled',
    };
  },
  mounted() {
    this.fetchData();

    bus.$on('lightbox-update-tab', () => {
      this.data.page = 1; // reset page
      this.fetchData();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
    bus.$off('lightbox-update-tab');
  },

  methods: {
    fetchData() {
      this.$_reqConfig();
      this.fetchWorstPerfData();
    },
    fetchWorstPerfData() {
      getTable({
        productID: this.productID,
        table: 'orders_program_rate',
        orderBy: 'orders_total_desc',
        limit: this.data.limit,
        page: this.data.page,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;
          this.data.values = data.data;
          this.data.count = data.total_count;
          this.data.valueTotal = data.value_total;
        })
        .catch(() => {
          this.data.count = 0;
          this.data.values = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
