<template>
  <div class="container loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('omnichannel-customer-revenue') }}</h5>
        <span class="segment-label">{{ $_segmentNameSelect() }}</span>
      </header>
      <lightbox-template-chart :settings="chartOmnichannelRevenue" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import moment from 'moment';
import getMetrics from '@/services/getmetrics';
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import { formatCurrency } from '@/utilities/formatters';
import { dateFormat } from '@/utilities/constants';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'OmnichannelRevenueChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  data() {
    return {
      dataChartOmnichannelRevenue: {},
      chartOmnichannelRevenue: undefined,
      segmentSelected: undefined,
      segmentName: undefined,
    };
  },
  mounted() {
    this.reset();
    this.fetchChart();
  },
  methods: {
    fetchChart() {
      getMetrics({
        metrics: ['revenue_on', 'revenue_app', 'revenue_off', 'revenue_omni'],
        segments: this.segmentSelected,
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          const { data } = response.data;
          this.dataChartOmnichannelRevenue = data;

          const { metrics } = data;

          if (this.$_verifyData(metrics)) return;

          const revenueOn = metrics.revenue_on;
          const revenueApp = metrics.revenue_app;
          const revenueOff = metrics.revenue_off;
          const revenueOmni = metrics.revenue_omni;

          let { labels } = data;
          labels = this.format({ labels, formatTo: 'MMM/YY' });

          const date = {
            start: moment(this.$store.getters.dates.startDate, dateFormat),
            end: moment(this.$store.getters.dates.endDate, dateFormat),
            now: moment(new Date()),
          };

          const months = date.end.diff(date.start, 'month');

          if (date.end.month() === date.now.month()) {
            labels = labels.slice(0, -1);
            labels[labels.length] = this.$t('current-month');
          }

          this.loadChartOmnichannelRevenue(
            {
              revenueOn,
              revenueApp,
              revenueOff,
              revenueOmni,
            },
            labels,
            labels.length - 0.5,
            labels.length - months - 1.5,
          );
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadChartOmnichannelRevenue(metrics, labels, minSelect, maxSelect) {
      this.chartOmnichannelRevenue = {
        chart: {
          type: 'spline',
          height: 280,
        },
        title: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
          },
        },
        xAxis: {
          allowDecimals: false,
          lineWidth: 0,
          categories: labels,
          plotBands: [
            {
              from: minSelect,
              to: maxSelect,
              color: 'rgba(68, 170, 213, .2)',
            },
          ],
        },
        yAxis: {
          title: {
            text: null,
          },
          lineWidth: 0,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            let s = `<b>${this.x}</b>`;

            this.points.forEach(points => {
              s += `<br/><span style="color:${points.color}">\u25CF</span> ${points.series.name}: <b>
                ${formatCurrency(points.y)}</b>`;
            });
            return s;
          },
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
            lineWidth: 2.5,
            fillOpacity: 1,
          },
        },
        series: [
          {
            name: this.$t('ecommerce'),
            color: '#A167D9',
            data: metrics !== null ? metrics.revenueOn : null,
          },
          {
            name: this.$tc('mobile'),
            color: '#36AAD6',
            data: metrics !== null ? metrics.revenueApp : null,
          },
          {
            name: this.$tc('in-store'),
            color: '#4084CB',
            data: metrics !== null ? metrics.revenueOff : null,
          },
          {
            name: this.$t('omnichannel'),
            color: '#EF4C8B',
            data: metrics !== null ? metrics.revenueOmni : null,
          },
        ],
      };
    },
    reset() {
      this.$_reqConfig();
      this.dataChartOmnichannelRevenue = {};
      this.segmentName = this.$parent.segmentName;
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
