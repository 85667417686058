<template>
  <div class="integration-create-edit">
    <el-dialog v-model="isVisible" id="integration-create-edit" width="572">
      <div v-if="!loading && !error.status" class="container integration-create-edit__container">
        <h2 class="integration-create-edit__title">{{ $t('settings.integrations.new-config') }}</h2>

        <div class="integration-create-edit__fields">
          <div
            v-for="(group, indexGroup) in groups"
            :key="indexGroup"
            :class="[
              {
                'integration-create-edit__fields--header': indexGroup === 0,
                'd-none': !hasAnyVisible(indexGroup),
              },
              'integration-create-edit__fields--section',
            ]"
          >
            <template v-for="(field, indexField) in fields">
              <div
                v-if="indexGroup === field.group"
                :key="indexField"
                :class="[{ 'd-none': field.type === 'hidden' }, 'field']"
              >
                <label :for="field.name">{{ field.label }}</label>
                <div class="wrap">
                  <textarea
                    v-if="field.type === 'textarea'"
                    v-model="field.value"
                    rows="5"
                    :class="[{ error: field.valid === false }]"
                    :placeholder="field.placeholder"
                  ></textarea>
                  <select v-else-if="field.type === 'select'" :id="field.name" v-model="field.value">
                    <option v-for="(option, index) in field.options" :key="index" :value="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                  <div v-else-if="field.name === 'name'" class="field-name">
                    <input
                      v-model="field.value"
                      :class="['field-name__input', { error: field.valid === false }]"
                      :placeholder="field.placeholder"
                      :type="field.type"
                      maxlength="50"
                    />
                    <span class="field-name__counter">{{ field.value.length }}/50</span>
                  </div>
                  <input
                    v-else
                    v-model="field.value"
                    :class="[{ error: field.valid === false }]"
                    :placeholder="field.placeholder"
                    :type="field.type"
                  />
                </div>
              </div>
            </template>
            <div v-show="indexGroup === 0" class="integration-create-edit__fields--icon">
              <img :src="getIcon(connector)" :alt="connectorName" />
            </div>
          </div>
          <div v-show="validationError" class="integration-create-edit__fields--error">
            {{ $t('settings.integrations.required-fields') }}
          </div>
          <div class="integration-create-edit__action-buttons">
            <button class="btn btn-close-modal" @click.prevent="closeModal">
              {{ $t('back') }}
            </button>
            <button class="btn" @click.prevent="register">
              {{ $t('register') }}
            </button>
          </div>
        </div>
      </div>

      <div v-else-if="!loading && error.status" class="error-message">
        <error-message :message="error.message" :button-click="closeModal" />
      </div>

      <div v-else class="integration-create-edit__waiting">
        <h1>{{ $t('settings.integrations.loading.title') }}<br />{{ $t('settings.integrations.loading.subtitle') }}</h1>
        <div class="spinner">
          <base-loader-spinner color="colored-block" />
        </div>
        <p>{{ $t('settings.integrations.loading.message') }}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { isNil, find } from 'lodash';
import integrationMixin from '@/helpers/mixins/integrationMixin';
import { getFields, putInstance } from '@/services/connectors';
import BaseLoaderSpinner from '@/components/_atoms/BaseLoaderSpinner';
import ErrorMessage from '@/components/settings/integrations/ErrorMessage';
import { valueType } from '@/utilities/constants';

export default {
  name: 'IntegrationCreateEdit',
  components: {
    BaseLoaderSpinner,
    ErrorMessage,
  },
  mixins: [integrationMixin],
  data() {
    return {
      connectorName: null,
      connector: null,
      loading: false,
      fields: [],
      refresh_token: null /** @TODO add array of object with N args */,
      groups: 0,
      validationError: false,
      error: {
        status: false,
        message: '',
      },
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('openIntegrationCreateEdit', obj => {
      this.connector = obj;
      this.connectorName = obj.alias;
      this.connectorId = obj.id;
      this.refresh_token = obj.refresh_token;
      this.openModal();
      this.mountFields(obj.fields);
    });
  },
  methods: {
    register() {
      // get each field apply validate function
      this.fields.forEach(field => {
        this.validateField(field.name);
      });
      // check if has some field required and empty
      if (!this.hasAnyValidationError()) {
        // if hasn't invalid field mount an array of objects with key[field name]: value
        const options = this.fields.map(e => ({ [e.name]: e.value }));
        // check if has refresh_token and push to option array if exists (required for google auth)
        // and not previously added as 'hidden' input
        if (this.refresh_token && this.fields.filter(k => k['refresh_token']).length > 0) {
          options.push({ refresh_token: this.refresh_token });
        }
        // show loader until request is complete
        this.loading = true;
        // call endpoint to post new instance on connector and show sucess modal
        // or error if success on response is false or undefined
        putInstance(this.connectorId, options).then(res => {
          if (res?.data?.success) {
            setTimeout(() => {
              bus.$emit('openSuccessModal', 'default');
              bus.$emit('refresh-create-export-modal');
            }, 300);
            this.closeModal();
          } else {
            this.setErrorObject(true);
          }
          this.loading = false;
        });
      }
    },
    hasAnyValidationError() {
      // find in fields data for at least one invalid and/or blank fields
      return this.fields.some(field => !field.valid);
    },
    validateField(fieldName) {
      const regIndex = this.fields.findIndex(e => e.name === fieldName);
      const requiredAndFilled = this.fields[regIndex].required && this.fields[regIndex].value.length > 0;
      const notRequired = !this.fields[regIndex].required;
      this.fields[regIndex].valid = requiredAndFilled || notRequired;
      this.validationError = this.hasAnyValidationError();
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.validationError = false;
      this.isVisible = false;
    },
    fetchFields() {
      // clear fields data-component and call request method to get fields with connector's name
      this.fields = [];
      return getFields(this.connectorName)
        .then(({ data }) => {
          // if hasn't configuration data in configuration key (from data response), show error and return
          // otherwise return configuration value
          if (!data || typeof data.data.configuration === valueType.string) {
            this.setErrorObject(true, 'unavailable');
            return;
          }
          return data.data.configuration;
        })
        .catch(() => {
          // if request failure, show error
          this.setErrorObject(true, 'unavailable');
        })
        .finally(() => {
          // hide loading status and show error or data
          this.loading = false;
        });
    },
    async mountFields(rawFields) {
      // reset component variables
      this.setErrorObject(false, '');
      this.validationError = false;
      this.loading = true;
      this.groups = 0;
      // get fields from request or oauth prop, and prepare fields in fields component data to show
      const sections = isNil(rawFields) ? await this.fetchFields() : rawFields;
      for (const section in sections) {
        Object.keys(sections[section]).forEach(e => {
          const targetField = sections[section][e];
          const required = targetField.required;
          let initialValue = '';
          if (targetField.type === 'select' && targetField.options.length) {
            initialValue = targetField.options[0].id;
          } else if (targetField.value !== undefined) {
            initialValue = targetField.value;
          }
          this.fields.push({
            name: targetField.name,
            label: targetField.label,
            placeholder: targetField?.placeholder,
            type: targetField?.type,
            value: initialValue,
            ...(targetField.type === 'select' && {
              options: targetField.options,
            }),
            group: parseInt(section),
            required,
            valid: null,
          });
        });
      }
      this.groups = sections?.length;
      this.loading = false;
    },
    setErrorObject(status, message) {
      this.error = {
        status: status,
        message: message,
      };
    },
    hasAnyVisible(groupIndex) {
      return find(this.fields, o => o.group === groupIndex && o.type !== 'hidden') !== undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-create-edit {
  &__container {
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__fields {
    &--section {
      padding: 24px 0;
      border-bottom: 1px solid $gray-300;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 12px;
      row-gap: 16px;

      .field {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      input {
        width: 100%;
      }
    }

    &--header {
      padding-bottom: 24px;
      border-bottom: 1px solid $gray-300;
      grid-template-columns: 2fr 1fr 1fr;
    }

    &--icon {
      align-self: center;
      padding-left: 20px;
    }

    &--error {
      color: $color-red;
      font-size: 12px;
      margin-top: 20px;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    .btn {
      min-width: 134px;
      &.btn-close-modal {
        &,
        &:hover {
          background-color: $gray-700;
        }
      }
    }
  }

  &__waiting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    text-align: center;
    position: relative;
    margin-bottom: 50px;

    h1 {
      font-size: 18px;
      font-weight: 600;
    }
    .spinner {
      height: 60px;
      position: relative;
    }
    p {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .field-name {
    display: flex;
    align-items: center;
    border: 1px solid #bbb;
    border-radius: 25px;
    &:focus-within {
      border: 1px solid $oto-omni;
    }
    &__input {
      border: none;
      outline: none;
    }

    &__counter {
      margin-right: 10px;
      color: $gray-600;
    }
  }
}
</style>

<style lang="scss">
#integration-create-edit {
  overflow: hidden;

  .el-dialog__body {
    padding: 0 50px;
  }
}
</style>
