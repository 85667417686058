<template>
  <div v-show="showFilters" class="header-selector">
    <div
      id="selectMetrics"
      :class="['select-fake', 'metrics', 'no-click', { show: toggle }]"
      @click.prevent="toggleDropdown()"
    >
      {{ $t('header.selector.filter') }}
    </div>
    <div v-show="toggle" class="check-list dropdown-menu no-click">
      <div class="list no-click customScrollBar">
        <div v-for="(metric, index) in filters" :key="index" class="select no-click">
          <label class="no-click" @click.stop.prevent="selectFilter(metric), refreshCards()">
            <check-box class="no-click" :border-color="true" :checked="isSelected(metric, selectedFilterOptions)" />
            <div class="text no-click">
              <span class="name no-click">{{ metric.name }}</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { getFilters } from '@/services/getFilters';
import CheckBox from '@/components/_atoms/CheckBox';
import { excludeFilterRoutes } from '@/helpers/menu';

export default {
  name: 'HeaderSelector',
  components: {
    CheckBox,
  },
  data() {
    return {
      filters: [],
      toggle: false,
      selectedFilterOptions: [],
    };
  },
  computed: {
    /**
     * @description verify if is in correct route and if filters are equal or higher than one
     */
    showFilters() {
      if (this.$route.path === '/') return false;
      return !excludeFilterRoutes.some(el => this.$route.path.includes(el)) && this.filters.length >= 1 ? true : false;
    },
  },
  mounted() {
    this.$store.dispatch('changeFilter', null);

    bus.$on('update-brand', () => {
      this.$store.dispatch('changeFilter', null);
      this.selectedFilterOptions = [];
    });
    this.fetchData();
    this.bodyClick();
  },
  methods: {
    fetchData() {
      getFilters().then(data => {
        this.filters = data?.data?.data;
      });
    },
    toggleDropdown() {
      this.toggle = !this.toggle;
    },
    /**
     * @description it adds or removes selected item from new array
     */
    selectFilter(metric) {
      if (this.selectedFilterOptions.includes(metric)) {
        const filter = this.selectedFilterOptions.filter(item => item !== metric);
        this.selectedFilterOptions = filter;
      } else {
        this.selectedFilterOptions.push(metric);
      }
      const selectedIds = this.selectedFilterOptions?.length
        ? this.selectedFilterOptions?.map(filter => filter.filterId).toString()
        : null;

      this.$store.dispatch('changeFilter', selectedIds);
    },
    /**
     * @description fill the checkbox if the clicked item matches with obj param
     */
    isSelected(metric, obj) {
      return !!obj.includes(metric);
    },
    /**
     * @description responsible to render cards
     */
    refreshCards() {
      bus.$emit('render-cards');
    },
    bodyClick() {
      const body = document.querySelector('body');
      body.addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.toggle = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-selector {
  width: 200px;
  position: relative;

  .hs__checkbox {
    display: flex;

    span {
      margin-left: 10px;
    }
  }

  .check-list {
    padding: 10px;
  }
  .list {
    max-height: 145px;
    padding: 5px;
    overflow: auto;
    margin-top: 20px;
  }
  .check-list {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    padding: 18px 15px;
    background-color: white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    z-index: -1;
    border: 0;
    margin: 0;
    border-radius: 18px;

    &.hide {
      display: none;
    }
    .select {
      margin-bottom: 13px;
      &:last-child {
        margin-bottom: 0;
      }

      label {
        display: grid;
        align-items: center;
        user-select: none;
        cursor: pointer;
        margin: 0;
        grid-template-columns: 16px 1fr;
        &:hover {
          .check-box:not(.checked) {
            border-color: $gray-600;
          }
        }
        .text {
          margin-left: 10px;
          color: $gray-700;
          .name {
            display: block;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
          }
        }
      }
    }

    > .select {
      &:hover {
        .check-list-child {
          visibility: visible;
        }
      }
      label {
        grid-template-columns: 16px 1fr 25px;
      }
      .icon-right-14px {
        font-size: 10px;
      }
    }
  }
  .check-list,
  .select-fake {
    width: 100%;
    overflow: hidden;
    padding-right: 25px;
    cursor: pointer;
  }

  .dropdown-menu {
    display: block;
    .searchStore {
      position: relative;
      max-width: 236px;
      margin: 5px auto 18px;
      input {
        width: 100%;
      }
      .icon-search {
        position: absolute;
        right: 5px;
        top: 2px;
        color: #bbbbbb;
      }
    }
  }
  .show {
    border-color: $blue-500;
  }
}
#selectMetrics:hover {
  border-color: $blue-500;
}
</style>
