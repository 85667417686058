import store from '@/store';
import { typeEnvironment } from '@/utilities/constants';

export default function endpointVersion(version) {
  const baseURL = store.getters.selectedBrandEndpoint;
  const URL_API = {
    BETA: 'https://api-uat.otocrm.com.br',
    DEV: 'https://api-dev.otocrm.com.br',
  };
  const getVersion = version === 'v3' ? 'v3' : 'v2';

  if (typeEnvironment === 'beta') return `${URL_API.BETA}/${getVersion}`;
  if (baseURL) return `${baseURL}/${getVersion}`;
  if (process.env.NODE_ENV === 'development') return `${URL_API.DEV}/${getVersion}`;

  return getVersion === 'v3' ? process.env.VUE_APP_ROOT_API_V3 : process.env.VUE_APP_ROOT_API;
}
