<template>
  <div class="table-list-header row align-items-start" :class="type">
    <header v-if="title || icon" class="d-flex w-100 pb-3">
      <div class="card-title d-flex align-items-center">
        <i v-if="icon" class="icon" :class="icon"></i>
        <h2 v-if="title" class="m-0">{{ title }}</h2>
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
      required: false,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
  },
  computed: {
    type() {
      let $current = this.$parent;

      while (!$current.type) $current = $current.$parent;

      return $current.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-header {
  padding: 0 25px 20px 25px;
  line-height: 16px;

  span {
    display: block;
  }

  &.filled {
    padding-top: 35px;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 600;
    color: $gray-900;
    position: relative;
    z-index: 10;
  }

  :slotted(&.normal .table-list-column:first-child) {
    margin: 0 25px 0 -25px;
  }
}
</style>
