<template>
  <div class="block details-skeleton">
    <skeleton-loader class="mb-4 d-block" height="17px" width="182px"></skeleton-loader>
    <skeleton-loader class="mb-4 d-block" height="17px" width="154px"></skeleton-loader>
    <skeleton-loader class="mb-4 d-block" height="17px" width="154px"></skeleton-loader>
    <skeleton-loader class="mb-4 d-block" height="17px" width="154px"></skeleton-loader>
    <skeleton-loader class="d-block" height="17px" width="182px"></skeleton-loader>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>

<style lang="scss" scoped>
.details-skeleton {
  padding-top: 14px;
}
</style>
