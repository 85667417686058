<template>
  <div>
    <div class="row">
      <div class="col-12">
        <skeleton-loader class="d-block" height="12px" width="70px"></skeleton-loader>
        <skeleton-loader class="d-block mt-2" height="12px" width="50px"></skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  name: 'StoreSkeleton',
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
