<template>
  <base-card-v2 class="card table-indicators">
    <base-card-header v-if="!$_verifyLoaded('loading')" :title="$t('360.metrics')" icon="icon-table-30px" />

    <el-dialog class="dialog-metrics" v-model="dialogVisible" fullscreen height="70vh" width="100%">
      <template #title>
        <div class="card-title d-flex align-items-center">
          <i class="icon icon-table-30px"></i>
          <h2 class="m-0">{{ $t('metrics') }}</h2>
        </div>
        <div class="config-options-header" v-if="!$_verifyLoaded('loading')">
          <div class="btn-options">
            <v-select
              v-model="preset"
              label="name"
              :clearable="false"
              :options="presets"
              @option:selected="fetchCheckedMetrics"
            >
              <template #option="{ name }">
                {{ $t(`${name}`) }}
              </template>
              <template #selected-option="{ name }">
                {{ $t(`${name}`) }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('360.sellers.no-results') }}
              </template>
            </v-select>
            <header-datepicker
              :user="user"
              :language="user?.language"
              route="/oto/indicators"
              class="datepicker-table"
            />
          </div>
          <template v-if="$_verifyLoaded('done') && tableData.rows.length">
            <div class="btn-options">
              <div class="export-wrapper">
                <el-dropdown>
                  <el-button round>
                    {{ $t('dashboard.more-options') }}
                    <i class="icon ml--4 icon-down-14px"></i>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item disabled>
                        {{ $t('dashboard.options') }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="openEditColumnsModal">
                        {{ $t('dashboard.edit-columns.title') }}
                      </el-dropdown-item>
                      <el-dropdown-item disabled divided>
                        {{ $t('dashboard.export-data') }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('csv', preset)">
                        {{ $t('dashboard.export', { value: '.CSV' }) }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('xls', preset)">
                        {{ $t('dashboard.export', { value: '.XLS' }) }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('xlsx', preset)">
                        {{ $t('dashboard.export', { value: '.XLSX' }) }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </template>
        </div>
      </template>

      <el-table
        v-if="!$_verifyLoaded('loading') && tableData.rows.length"
        :data="tableData.filtered"
        style="width: 100%"
        stripe
        empty-text=" "
        :cell-class-name="tableRowClassName"
        :header-cell-class-name="tableHeaderClassName"
        @sort-change="orderTable"
      >
        <template v-for="(column, index) in getColumns" :key="index">
          <el-table-column
            sortable
            :fixed="index === 0 ?? true"
            :prop="column.key"
            :label="column.label"
            :formatter="formatter"
            :min-width="getWidth(column.key)"
          >
            <template v-if="getColumnsToSearch.no_label.includes(column.key)" #header>
              <div class="el-table__column">
                <label> {{ getLabel(column.key) }} </label>
                <el-popover trigger="hover" placement="bottom" :width="260">
                  <el-input
                    v-model="search[column.key]"
                    v-mask="getMask(column.key)"
                    :placeholder="getPlaceholder(column.key)"
                    size="small"
                    @keyup.enter="searchByColumn(column.key, search[column.key], index)"
                  />
                  <div>
                    <el-button
                      :disabled="!search[column.key]"
                      round
                      size="small"
                      text
                      @click="clearFilter(column.key, index)"
                    >
                      {{ search[column.key] ? 'limpar filtro' : 'cancelar' }}
                    </el-button>
                    <el-button
                      :disabled="!search[column.key]"
                      round
                      size="small"
                      type="primary"
                      @click="searchByColumn(column.key, search[column.key], index)"
                      >pesquisar</el-button
                    >
                  </div>
                  <template #reference>
                    <i
                      :class="{ 'color-oto-brand-omni': columnsFiltered.includes(column.key) }"
                      class="icon icon-search-12px ml--4"
                    ></i>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <base-loader v-if="$_verifyLoaded('loading')" />
      <info-card
        v-if="($_verifyLoaded('info') && !isCustomTable) || (!tableData.filtered.length && !$_verifyLoaded('loading'))"
        ref="message-error"
        :error="hasError && !isCustomTable"
      />
    </el-dialog>

    <div class="config-options-header" v-if="!$_verifyLoaded('loading')">
      <div class="btn-options">
        <v-select
          v-model="preset"
          label="name"
          :clearable="false"
          :options="presets"
          @option:selected="fetchCheckedMetrics"
        >
          <template #option="{ name }">
            {{ $t(`${name}`) }}
          </template>
          <template #selected-option="{ name }">
            {{ $t(`${name}`) }}
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('360.sellers.no-results') }}
          </template>
        </v-select>
      </div>

      <template v-if="$_verifyLoaded('done') && tableData.rows.length">
        <div class="btn-options">
          <div class="export-wrapper">
            <el-dropdown>
              <el-button round>
                {{ $t('dashboard.more-options') }}
                <i class="icon ml--4 icon-down-14px"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item disabled>
                    {{ $t('dashboard.options') }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="openEditColumnsModal">
                    {{ $t('dashboard.edit-columns.title') }}
                  </el-dropdown-item>
                  <el-dropdown-item disabled divided>
                    {{ $t('dashboard.export-data') }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="resolveExport('csv', preset)">
                    {{ $t('dashboard.export', { value: '.CSV' }) }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="resolveExport('xls', preset)">
                    {{ $t('dashboard.export', { value: '.XLS' }) }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="resolveExport('xlsx', preset)">
                    {{ $t('dashboard.export', { value: '.XLSX' }) }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <button ref="button" class="btn export" @click="expandTable">
              {{ $t('dashboard.expand-table') }}
            </button>
          </div>
        </div>
      </template>
    </div>

    <el-table
      v-if="!$_verifyLoaded('loading') && tableData.rows.length"
      :data="tableData.filtered"
      style="width: 100%"
      stripe
      empty-text=" "
      :scrollbar-always-on="true"
      :cell-class-name="tableRowClassName"
      :header-cell-class-name="tableHeaderClassName"
      @sort-change="orderTable"
    >
      <template v-for="(column, index) in getColumns" :key="index">
        <el-table-column
          sortable
          :fixed="index === 0 ?? true"
          :prop="column.key"
          :label="column.label"
          :formatter="formatter"
          :min-width="getWidth(column.key)"
        >
          <template v-if="getColumnsToSearch.no_label.includes(column.key)" #header>
            <div class="el-table__column">
              <label> {{ getLabel(column.key) }} </label>
              <el-popover trigger="hover" placement="bottom" :width="260">
                <el-input
                  v-model="search[column.key]"
                  v-mask="getMask(column.key)"
                  :placeholder="getPlaceholder(column.key)"
                  size="small"
                  @keyup.enter="searchByColumn(column.key, search[column.key], index)"
                />
                <div>
                  <el-button
                    :disabled="!search[column.key]"
                    round
                    size="small"
                    text
                    @click="clearFilter(column.key, index)"
                  >
                    {{ search[column.key] ? 'limpar filtro' : 'cancelar' }}
                  </el-button>
                  <el-button
                    :disabled="!search[column.key]"
                    round
                    size="small"
                    type="primary"
                    @click="searchByColumn(column.key, search[column.key], index)"
                    >pesquisar</el-button
                  >
                </div>
                <template #reference>
                  <i
                    :class="{ 'color-oto-brand-omni': columnsFiltered.includes(column.key) }"
                    class="icon icon-search-12px ml--4"
                  ></i>
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <indicator-edit-modal />

    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card
      v-if="($_verifyLoaded('info') && !isCustomTable) || (!tableData.filtered.length && !$_verifyLoaded('loading'))"
      ref="message-error"
      :error="hasError && !isCustomTable"
    />
  </base-card-v2>
</template>

<script>
// libs
import { mapGetters, mapState, mapActions } from 'vuex';
import vSelect from 'vue-select';
import moment from 'moment';
import FileDownload from 'js-file-download';
import bus from '@/helpers/events/bus';
import vClickOutside from 'click-outside-vue3';
// helpers
import { getTable, getTablePresets } from '@/services/oto/indicators';
import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import IndicatorEditModal from '@/components/oto/indicators/IndicatorEditModal';
import HeaderDatepicker from '@/components/menu/_organisms/HeaderDatepicker';

import {
  useMixpanelConfig,
  useMixpanelSelectAggregation,
  useMixpanelExportTableIndicators,
  useMixpanelSortedColumn,
} from '@/utilities/mixpanel';
import { ElTable, ElTableColumn } from 'element-plus';

export default {
  components: {
    BaseLoader,
    InfoCard,
    vSelect,
    IndicatorEditModal,
    ElTable,
    ElTableColumn,
    HeaderDatepicker,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  mixins: [dashboardMixin, tableMixin, verifyMixin],

  data() {
    return {
      headerFixedWidth: 0,
      headerFixed: false,
      tableHeader: [],
      preset: {
        id: 'campaign',
        name: 'Campanha',
      },
      presets: [],
      isActive: null,
      orderDefault: [],
      order: null,
      columns: [],
      columnsFiltered: [],
      visible: {
        page: [],
        modal: [],
      },
      search: {},
      noMask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ },
        },
      },
      tableData: {
        rows: [],
        filtered: [],
        totalResults: [],
      },
      isCustomTable: false,
      dialogVisible: false,
      loadingExport: false,
      tab: 'tab-table',
    };
  },

  computed: {
    ...mapGetters('indicators', [
      'getStoreId',
      'getSellerId',
      'getSegmentId',
      'getSourceId',
      'getSourceName',
      'getCampaignId',
      'getAllCheckedMetrics',
      'getCheckedMetrics',
    ]),
    ...mapGetters(['user']),
    ...mapState('indicators', ['conversionWindow']),
    segmentBy() {
      if (this.getStoreId !== null) {
        return `storeId: ${this.getStoreId}`;
      } else if (this.getSellerId !== null) {
        return `sellerId: ${this.getSellerId}`;
      } else if (this.getCampaignId !== null) {
        return `getCampaignId: ${this.getCampaignId}`;
      }
      return 'all';
    },
    getColumns() {
      return this.tableHeader.map(column => ({
        key: column.key,
        label: column.name,
        type: column.type,
        threshold: column?.threshold || null,
        isMetric: column.isMetric,
      }));
    },

    getColumnsToSearch() {
      return {
        no_label: this.getColumns.filter(column => !column.isMetric).map(c => c.key),
        with_label: this.getColumns.filter(column => !column.isMetric).map(c => ({ key: c.key, label: c.label })),
      };
    },
  },
  watch: {
    preset: function () {
      useMixpanelSelectAggregation('view_oto_indicators_select-aggregation', this.preset.name);
    },
  },
  mounted() {
    this.setup();
    useMixpanelConfig('view_oto_indicators_table');

    bus.$on(`indicators-select-emit-${this.tab}`, brand => {
      this.handleChangeBrand(brand);
    });

    bus.$on('dashboard-update-table', () => {
      this.fetchCheckedMetrics();
    });

    bus.$on('changeSelectedOption', newSelectedOption => {
      if (newSelectedOption.id === 'table-select') {
        this.preset = newSelectedOption.index;
        this.getTableInfo();
      }
    });

    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });

    window.onscroll = () => this.debounce();
  },

  beforeUnmount() {
    bus.$off([
      'changeSelectedOption',
      'render-page-loader',
      'dashboard-update-table',
      `indicators-select-emit-${this.tab}`,
    ]);
  },

  methods: {
    ...mapActions('indicators', ['updateCheckedMetrics', 'updateFilterMetrics', 'updateLastCustomConfig']),
    setup() {
      this.getTableInfo();
      this.getTablePresetsInfo();
    },

    expandTable() {
      this.dialogVisible = true;
      useMixpanelConfig(`view_oto_indicators_expand_table`);
    },

    orderTable({ prop, order }) {
      useMixpanelSortedColumn('view_oto_indicators_sort-column', prop, order);
    },

    getWidth(column) {
      const width = {
        campaign: 260,
        aov: 160,
        revenue_tracked: 190,
        store: 250,
        seller: 220,
        store_city: 220,
        seller_enrolment: 150,
        store_region: 160,
        activities_started_share: 135,
        revenue_conversation: 180,
        sent_attachments: 180,
      };

      const hasWidth = Object.keys(width).includes(column);
      return hasWidth ? width[column] : 130;
    },

    getLabel(key) {
      return this.getColumnsToSearch.with_label.filter(column => column.key === key)[0]['label'];
    },

    getMask(column) {
      const mask = {
        date: '##/##/####',
      };

      const hasMask = Object.keys(mask).includes(column);
      return hasMask ? mask[column] : this.noMask;
    },

    getPlaceholder(column) {
      const placeholder = {
        date: '01/01/2000',
      };

      const hasPlaceholder = Object.keys(placeholder).includes(column);
      return hasPlaceholder ? placeholder[column] : 'Busque nesta coluna';
    },

    /**
     * @description clears the filter for a specific column
     */
    clearFilter(column, index) {
      this.loading = true;
      this.columnsFiltered = this.columnsFiltered.filter(col => col !== column);
      this.search[column] = '';

      this.tableData.filtered = this.restoreActiveFilters();

      this.visible[index] = false;
      this.loading = false;
    },

    /**
     * @description filters table data for the searched value in a specific column
     * @param {column} Ex: date
     * @param {value} Ex: 12/12/2012
     */
    searchByColumn(column, value) {
      this.loading = true;
      this.columnsFiltered.push(column);

      let result = value;

      if (column === 'date') {
        result = this.getFormattedDate(value);
      }

      this.tableData.filtered = this.restoreActiveFilters();
      this.tableData.filtered = this.tableData.filtered.filter(row => {
        return this.equalizeValue(row[column]).includes(this.equalizeValue(result));
      });

      this.loading = false;
    },

    /**
     * @description formats the filter date to that of the pattern that comes from the api
     * @param {date} 12/12/2012
     * @returns 2012-12-12
     */
    formatDate(date) {
      const spplitedDate = date.split('/');
      return position => {
        if (position !== 0) {
          return spplitedDate.length > position ? `${spplitedDate[position]}-` : '';
        }
        return spplitedDate[position];
      };
    },

    /**
     * @description gets the result of the formatted date
     * @param {date} 12/12/2012
     * @returns 2012-12-12
     */
    getFormattedDate(date) {
      const getPosition = this.formatDate(date);
      return `${getPosition(2)}${getPosition(1)}${getPosition(0)}`;
    },

    equalizeValue(value) {
      return String(value).toLowerCase();
    },

    /**
     * @description searches the table again for the filters that are still active
     */
    restoreActiveFilters() {
      const getActiveFilters = Object.fromEntries(Object.entries(this.search).filter(result => !!result[1]));

      if (Object.prototype.hasOwnProperty.call(getActiveFilters, 'date')) {
        getActiveFilters.date = this.getFormattedDate(getActiveFilters.date);
      }

      const keys = Object.keys(getActiveFilters);

      const result = [];
      for (const row of this.tableData.rows) {
        const verifyIfIsSameValueInActiveFilters = keys.every(key =>
          this.equalizeValue(row[key]).includes(this.equalizeValue(getActiveFilters[key])),
        );

        if (verifyIfIsSameValueInActiveFilters) {
          result.push(row);
        }
      }

      return result;
    },

    tableRowClassName({ row, column, columnIndex }) {
      const threshold = this.getColumns[columnIndex]['threshold'];
      const isMetric = this.getColumns[columnIndex]['isMetric'] ? 'metric' : 'dimension';
      const paint = ['conversion_rate', 'activities_started_share', 'contacts_success_share'];
      const values = `threshold ${isMetric} ${this.$_checkThreshold({
        amount: row[column.property],
        threshold,
      })}`;

      if (paint.includes(column.property)) {
        return `pointer-green ${values}`;
      }
      return values;
    },

    tableHeaderClassName({ columnIndex }) {
      const isMetric = this.getColumns[columnIndex]['isMetric'] ? 'metric' : 'dimension';
      return isMetric;
    },

    formatter(row, column) {
      const typeColumn = this.getColumns.filter(col => col.key === column.property)[0]['type'];
      const typeValue = column.property === 'date' ? 'date' : typeColumn;
      return this.$_getValue({ type: typeValue, amount: row[column.property] });
    },

    handleChangeBrand(brand) {
      if (brand?.brand) {
        this.preset = { id: 'campaign', name: 'Campanha' };
        this.setup();
      } else this.fetchCheckedMetrics();
    },

    getTableInfo() {
      this.$_reqConfig();

      if (this.isActive == '' || this.isNil(this.isActive)) {
        this.isActive = this.orderDefault;
        this.order = this.orderDefault;
      }

      getTable({
        preset: this.preset.id,
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        sourceId: this.getSourceId,
        lookback: this.conversionWindow,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.tableData.filtered = [];
            this.tableData.rows = [];
            return;
          }

          this.columnsFiltered = [];
          this.search = {};

          this.checkedDefaultMetricsAndDimensions(data);
          this.tableHeader = data.series;
          const dataFormated = data?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                const key = data.series[i].key;
                return [key, val];
              }),
            );
          });

          this.tableData.rows = dataFormated;
          this.tableData.filtered = dataFormated;
          this.tableData.totalResults = data.totalResults;
          this.tableData.totalCount = data.totalCount;

          this.columns = data?.series.map(column => ({ key: column.key, label: column.name, format: column.type }));
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
          if (this.$_dashVerifyIsActive) {
            this.order = this.isActive;
            this.$_dashOrderBy(this.isActive);
          }
        });
    },

    getTablePresetsInfo() {
      getTablePresets().then(({ presets }) => {
        const dataPresets = Object.entries(presets);

        dataPresets.map(el => {
          this.presets.push({
            id: el[0],
            name: el[1],
          });
        });
      });
    },

    openMixPanel() {
      useMixpanelConfig('view_oto_indicators_open_aggregation_input');
    },

    /**
     * @description emit event to open dash columns editor
     */
    openEditColumnsModal() {
      useMixpanelConfig('view_oto_indicators_open-edit-columns');
      bus.$emit('open-indicator-edit-columns');
    },

    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    fetchExportData(fileType, preset) {
      useMixpanelExportTableIndicators(
        'view_oto_indicators_export',
        `${this.preset.id}: ${this.preset.name}`,
        this.tableHeader.map(column => column.name),
        fileType,
        this.conversionWindow,
        this.getSourceName,
        this.getSegmentId,
        this.segmentBy,
      );

      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getTable({
        preset: preset.id,
        fileType,
        lookback: this.conversionWindow,
        responseType: 'blob',
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        sourceId: this.getSourceId,
        metrics: this.getCheckedMetrics,
      })
        .then(response => {
          const data = response?.data;
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset.id}-${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },

    /**
     * @description Verify if is fetchCustomExportData or fetchExportData
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    resolveExport(fileType, preset) {
      if (preset == 'custom') this.fetchExportData(fileType, preset);
      else this.fetchExportData(fileType, preset);
    },

    fetchCustomTable(columns) {
      this.$_reqConfig();
      this.isCustomTable = true;
      getTable({
        metrics: columns,
        preset: this.preset.id,
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        sourceId: this.getSourceId,
        lookback: this.conversionWindow,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.tableData.filtered = [];
            this.tableData.rows = [];
            return;
          }
          this.columnsFiltered = [];
          this.search = {};

          this.tableData.totalResults = data?.totalResults;
          const dataFormated = data?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                const key = data.series[i].key;
                return [key, val];
              }),
            );
          });
          this.tableData.rows = dataFormated;
          this.tableData.filtered = dataFormated;
          this.tableHeader = data?.series;
          this.order = data.orderBy ? data.orderBy : null;
          this.isActive = data.orderBy ? data.orderBy : null;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
          this.isCustomTable = false;
          if (this.$_dashVerifyIsActive) {
            this.order = this.isActive;
            this.$_dashOrderBy(this.isActive);
          }
        });
    },

    fetchCheckedMetrics() {
      const { metrics } = this.getAllCheckedMetrics;
      const columns = metrics
        .map(item => item)
        .toString()
        .toLowerCase();
      this.fetchCustomTable(columns);
    },

    checkedDefaultMetricsAndDimensions(data) {
      const initialMetricsFiltered = data?.series
        .filter(el => el.isMetric)
        .map(item => ({
          key: item.key,
          name: item.name,
        }));
      const initialMetricsChecked = initialMetricsFiltered.map(item => item.key);

      this.updateCheckedMetrics(initialMetricsChecked);
      this.updateFilterMetrics(initialMetricsFiltered);

      this.updateLastCustomConfig();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select {
  &.vs--open {
    z-index: 3;
  }
}
.export-icon {
  font-size: 17px;
  font-weight: 600;
}
.table-presets-select {
  height: 36px;
  width: 225px;
  margin-left: 16px;
}
.table-indicators {
  .tbl-row {
    min-width: 1128px;
    .tbl-col {
      min-width: 150px;
    }
  }
}
</style>

<style lang="scss">
.table-indicators {
  min-height: 430px;

  .vs__dropdown-toggle {
    width: 200px;
  }

  .metric {
    justify-content: flex-end;
    padding: 10px 0 10px 0;
  }

  .threshold {
    border-bottom: none !important;
  }

  .pointer-green {
    background: #5172cf !important;

    .cell {
      color: $color-white !important;
    }
  }

  .pointer-yellow {
    background: #90aaf4 !important;

    .cell {
      color: $color-white !important;
    }
  }

  .pointer-red {
    background: #d9e0f4 !important;

    .cell {
      color: $gray-800 !important;
    }
  }

  .el-dialog__header {
    display: block !important;
    padding: var(--el-dialog-padding-primary) !important;
    padding-bottom: 10px !important;
    margin-right: 16px !important;

    .el-dialog__headerbtn {
      position: absolute !important;
      height: 54px !important;
      width: 54px !important;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog.is-fullscreen {
    border-radius: 0;
  }
}

.el-table {
  &__inner-wrapper {
    z-index: 2;
  }

  .cell {
    font-size: 12px !important;
    display: flex;
    align-items: center;
  }

  &__column {
    display: flex;
    align-items: center;

    i {
      font-size: 14px;
      margin-left: 8px;

      &:hover {
        color: $gray-600;
      }
    }
  }

  &__header {
    table-layout: fixed !important;
  }

  th .cell {
    word-break: initial !important;
    color: #666 !important;

    label {
      font-weight: bold !important;
    }
  }

  &__cell {
    padding: 8px !important;
  }

  .campaign {
    margin: 0;
    padding-right: 12px;
  }

  [class*='pointer-'] {
    font-weight: bold;
  }

  th {
    text-transform: initial;
  }
}

.btn-options {
  display: flex;
}

.datepicker-table {
  margin-left: 10px;
}

.metric .cell {
  padding-right: 10px;
}
</style>
