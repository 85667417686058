import store from '@/store';
import mixpanel from 'mixpanel-browser';

export const useMixpanelWelcome = (pageViewName: string, selectedLink: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    selectedLink: selectedLink,
  });
};
