import numeral from 'numeral';
import _ from 'lodash';

export const getPercentual = (target, max) => (max ? (getNumeral(target) / getNumeral(max)) * 100 : 0);

export const getNumeral = number => numeral(number).value();

export const getMaxFromObj = singleArg => {
  if (!isNaN(singleArg)) {
    return getNumeral(singleArg);
  }
  if (!Array.isArray(singleArg)) {
    /* tslint:disable-next-line */
    return getNumeral(_.max(_.values(singleArg).map(value => getNumeral(value))));
  }
  const extractValues = _.fromPairs(singleArg);
  return getMaxFromObj(extractValues);
};

export const getMax = (...args) => {
  if (args.length > 1) {
    /* tslint:disable-next-line */
    return getNumeral(_.max(Array.from(args).map(value => getNumeral(value))));
  }
  return getMaxFromObj(args[0]);
};

/**
 * @description check if int number is correct and clean
 * @param {Number} amount amount value, for checked
 * @return {Number}
 */
export const validateIntNumber = amount => {
  if (!amount) return 0;
  const amountValidate = amount.replace(/[^,0-9]+/g, '');

  return parseInt(amountValidate) ?? 0;
};
