<template>
  <base-card-v2 :class="{ loadHeight: loading }" class="product-revenue-by-segment card">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('segments-revenue.title')" icon="icon-money" />
      <div id="chart-revenue-by-segment"></div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import Highcharts from 'highcharts';
import { getMetrics } from '@/services/product';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin } from '@/helpers/mixins';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    productID: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      chart: {},
      chartConfig: {},
      categories: [],
      revenue: [],
      customers: [],
      products: [],
      avgRevenue: [],
      avgCustomers: [],
      ordersShare: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', () => {
      const getBreadcrumb = this.$route.meta.breadcrumb?.map(list => list.name);
      if (getBreadcrumb.includes('product-detail')) {
        this.fetchData();
      }
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.reset();
      this.$_reqConfig();

      this.loadGraphic();

      getMetrics({
        metrics: 'revenue_by_segment',
        productID: this.productID,
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) return;

          data.metrics.revenue_by_segment.data.forEach(item => {
            this.categories.push(item.group);
            this.revenue.push(item.data.revenue);
            this.customers.push(item.data.customers);
            this.products.push(item.data.product_quantity);
            this.avgRevenue.push(item.data.avg_revenue);
            this.avgCustomers.push(item.data.avg_customers);
            this.ordersShare.push(item.data.orders_share);
          });

          this.loadGraphic(
            false,
            this.categories,
            this.revenue,
            this.customers,
            this.products,
            this.avgRevenue,
            this.avgCustomers,
            this.ordersShare,
          );
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadGraphic(isLoading = true, categories, revenue, customers, products, avgRevenue, avgCustomers, ordersShare) {
      const self = this;
      this.chart = {
        isLoading,
        lang: {
          loading: self.$i18n.t('loading-dots'),
        },
        chart: {
          renderTo: 'chart-revenue-by-segment',
          height: 210,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        xAxis: {
          categories,
          lineWidth: 0,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            labels: {
              format: '{value}%',
            },
            lineWidth: 0,
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          padding: 15,
          backgroundColor: '#fff',
          style: {
            lineHeight: '20px',
            color: '#555',
          },
          formatter: function formater() {
            const customer = formatDecimal(self.customers[this.points[0].point.index], 1);
            const avgCust = formatPercent(this.points[0].y / 100, 2);
            // eslint-disable-next-line
            const revenue = formatCurrency(self.revenue[this.points[1].point.index]);
            const avgRev = formatPercent(this.points[1].y / 100, 2);
            const avgOrder = formatPercent(self.ordersShare[this.points[0].point.index] / 100, 2);

            return self.$i18n.t('product.revenue-by-segment.tooltip', {
              segment: this.x,
              customer,
              avgCust,
              revenue,
              avgRev,
              avgOrder,
            });
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          series: {
            shadow: false,
          },
        },
        series: [
          {
            name: self.$i18n.t('clients'),
            color: '#61CAE4',
            data: avgCustomers,
            type: 'column',
          },
          {
            name: self.$i18n.t('revenue'),
            color: '#365B6B',
            data: avgRevenue,
            type: 'column',
          },
          {
            name: self.$i18n.t('product.sold-products'),
            color: '#FA6F32',
            data: ordersShare,
            type: 'column',
          },
        ],
      };
      this.chartConfig = Highcharts.chart(this.chart);
    },
    reset() {
      this.categories = [];
      this.revenue = [];
      this.customers = [];
      this.products = [];
      this.avgRevenue = [];
      this.avgCustomers = [];
      this.ordersShare = [];
    },
  },
};
</script>

<style lang="scss">
.product-revenue-by-segment {
  .bold {
    font-weight: 600;
  }
  .cl-gray {
    color: $gray-600;
  }
}
</style>
