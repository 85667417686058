<template>
  <base-card-v2 class="cashback-customers-use-graphic">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('360.cashback-report.customers-use.rfv')">
        <template v-slot:item-right>
          <v-select
            v-model="selectedOpt"
            label="title"
            :clearable="false"
            class="float-right ml-auto"
            :options="selectItems"
            :placeholder="$tc('_select', 2)"
            @option:selected="fetchData"
          >
            <template #option="{ title }">
              {{ $tc(title, 1) }}
            </template>
            <template #selected-option="{ title }">
              {{ $tc(title, 1) }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $tc('_no-results') }}
            </template>
          </v-select>
        </template>
      </base-card-header>
      <div id="chart-cashback-customers-use" style="min-width: 360px; margin: 0 auto"></div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
// libs
import vSelect from 'vue-select';
import Highcharts from 'highcharts';
import { mapGetters } from 'vuex';

// services
import { getSegmentMetrics } from '@/services/oto/cashbackReport';

// helpers
import { verifyMixin } from '@/helpers/mixins';
import bus from '@/helpers/events/bus';
import { valuesByFormat, formatCurrency, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'CashbackCustomersUseGraphic',
  components: {
    BaseLoader,
    InfoCard,
    vSelect,
  },
  mixins: [verifyMixin],
  data() {
    return {
      chart: {},
      chartConfig: {},
      categories: [],
      segmentValue: [],
      dataType: undefined,
      selectedOpt: {
        value: 'aov',
        title: this.$t('360.cashback-report.customers-use.average'),
        colorBase: '#6C90F4',
        colorBackground: '#365B6B20',
      },
      selectItems: [
        {
          value: 'aov',
          title: this.$t('360.cashback-report.customers-use.average'),
          colorBase: '#6C90F4',
          colorBackground: '#365B6B20',
        },
        {
          value: 'frequency',
          title: this.$t('360.cashback-report.customers-use.frequency'),
          colorBase: '#1569c2',
          colorBackground: '#36AAD620',
        },
        {
          value: 'recency',
          title: this.$t('360.cashback-report.customers-use.recency'),
          colorBase: '#FA6F32',
          colorBackground: '#FA6F3220',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('cashbackReport', ['getStoreId', 'getSellerId']),
  },

  mounted() {
    this.fetchData();

    // bus called when dates are changed globally
    bus.$on('render-cards', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-store', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-seller', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-segment-general', () => {
      this.fetchData();
    });
  },

  beforeUnmount() {
    bus.$off('cashback-report-change-segment-general');
    bus.$off('cashback-report-change-seller');
    bus.$off('render-cards');
    bus.$off('cashback-report-change-store');
  },

  methods: {
    fetchData() {
      this.$_reqConfig();
      this.reset();
      this.loadGraphic();

      getSegmentMetrics({
        metrics: this.selectedOpt.value,
        type: 'used',
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          data.data.forEach(item => {
            this.categories.push(item.group);
            this.segmentValue.push(item.data.value);
            this.dataType = item.data.type;
          });
          this.loadGraphic(false, this.categories, this.segmentValue);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadGraphic(isLoading = false, categories = [], segmentValue = []) {
      const self = this;
      this.chart = {
        isLoading,
        lang: {
          loading: self.$i18n.t('loading-dots'),
        },
        title: {
          text: '',
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column',
          height: 274,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        xAxis: {
          categories,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              formatter: function formatter() {
                return self.getNumber(self.dataType, this.value, true);
              },
            },
          },
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            const { index } = this.points[0].point;
            const name = categories[index];
            const value = segmentValue[index];

            // organize metric for use on valuesByFormat
            const metric =
              self.selectedOpt.value === 'aov'
                ? { amount: value, type: valueType.currency }
                : { amount: value, type: valueType.float };

            const option = self.selectedOpt?.title.toLowerCase();

            const optionText =
              self.selectedOpt.value === 'average-ticket'
                ? `${self.$i18n.t('360.cashback-report.singular')} ${option}`
                : `${self.$i18n.t('360.cashback-report.plural')} ${option}`;

            const fText = `
              ${self.$i18n.t('360.cashback-report.customers-use.tooltip-1')}
              <span class="ccug_bold">${name}</span>
              ${self.$i18n.t('360.cashback-report.customers-use.tooltip-2')}
              <span>${optionText}</span>
              <span class="ccug_bold">${valuesByFormat(metric)}</span>`;
            return fText;
          },
          style: {
            color: '#555',
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: self.selectedOpt?.title,
            color: self.selectedOpt.colorBase,
            doubleValues: segmentValue,
            data: segmentValue,
            pointPadding: 0.2,
          },
        ],
      };
      this.chartConfig = Highcharts.chart('chart-cashback-customers-use', this.chart);
    },
    reset() {
      this.categories = [];
      this.segmentValue = [];
    },
    /**
     * @description get formatted number based on type
     */
    getNumber(numberType, number, short = false) {
      let formatS;
      switch (numberType) {
        case valueType.decimal:
          formatS = '0,000';
          break;
        default:
          formatS = '0,000';
          break;
      }
      if (short && number >= 100000) {
        formatS += 'a';
      }

      if (numberType === valueType.currency) {
        return formatCurrency(number);
      }

      return formatNumber(number, formatS);
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback-customers-use-graphic {
  height: 380px;
}
</style>

<style lang="scss">
.cashback-customers-use-graphic {
  .card-title h2 {
    font-size: 12px;
  }

  .ccug_bold {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .v-select {
    width: 27%;
  }
}
</style>
