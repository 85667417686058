import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

export const getUser = (email: string) => {
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/memberKit/getUser',
    params: {
      email,
    },
  });
};

export const createUser = (email: string, fullName: string) => {
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: '/memberKit/createUser',
    params: {
      email,
      fullName,
    },
  });
};
