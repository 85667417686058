<template>
  <div class="btn-toggle d-flex align-items-center" :class="{ active: !!enabled }" @click.prevent="callback">
    <span class="bar"></span>
    <span class="ball"></span>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    callback: {
      type: Function,
      default: null,
    },
    enabled: {
      type: [Number, Boolean],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-toggle {
  width: 52px;
  position: relative;
  cursor: pointer;
  .bar {
    width: 33px;
    height: 10px;
    margin: 0 9px;
    background-color: $gray-300;
    border-radius: 20px;
  }
  .ball {
    width: 19px;
    height: 19px;
    border-radius: 100%;
    background-color: $gray-600;
    position: absolute;
  }
  &.active {
    .ball {
      background-color: $oto-omni;
      right: 0;
    }
  }
  &.disabled {
    pointer-events: none;
    .bar {
      background-color: $gray-300;
    }
    .ball {
      background-color: $gray-500;
    }
  }
}
</style>
