<template>
  <div class="audience-historic">
    <div v-if="!loading" class="content">
      <header class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0">{{ $t('audiences.historic') }}</h2>
        <select
          v-if="connectors && connectors.length"
          v-model="selectedConnector"
          class="connector-filter"
          @change="list"
          data-cy="audience-historic-select"
        >
          <option :value="undefined">
            {{ $t('audiences.all-platforms') }}
          </option>
          <option v-for="connector in connectors" :key="connector.connector_id" :value="connector.connector_id">
            {{ connector.name }}
          </option>
        </select>
      </header>

      <table-list :id="id" :data="table" :type="type" :empty="!table.count">
        <table-list-header>
          <table-list-column class-name="text-center col-1" data-cy="status-audiences-log-list-header">
            {{ $t('audiences.status.title') }}
          </table-list-column>
          <table-list-column
            class-name="text-center col-2 text-capitalize"
            data-cy="platform-audiences-log-list-header"
          >
            {{ $tc('audiences.platform', 1) }}
          </table-list-column>
          <table-list-column class-name="col-3" data-cy="audience-audiences-log-list-header">
            {{ $t('audiences.single-title') }}
            <i
              class="icon icon-question-12px"
              v-tooltip="{
                theme: 'default',
                content: `<div class='audience-historic-tooltip'>${$t('audiences.audience-name-tooltip')}</div>`,
                distance: 8,
                html: true,
              }"
            ></i>
          </table-list-column>
          <table-list-column class-name="col-2" data-cy="duration-audiences-log-list-header">
            {{ $t('audiences.duration') }}
          </table-list-column>
          <table-list-column class-name="col-2" data-cy="export-date-audiences-log-list-header">
            {{ $t('audiences.exportation-date') }}
          </table-list-column>
          <table-list-column class-name="text-right col-2" data-cy="database-audiences-log-list-header">
            {{ $t('audiences.base-size') }}
          </table-list-column>
        </table-list-header>
        <table-list-body>
          <table-list-row v-for="(row, index) in table.rows" :key="index" data-cy="audiences-log-list-row">
            <table-list-column class-name="text-center col-1 pl-0 pr-0">
              <span
                :id="`historic-status-${index}`"
                class="status"
                :class="row.status"
                :data-message="row.message"
                data-toggle="tooltip"
                data-placement="top"
                :title="row.message"
              >
                {{ $t(`audiences.status.${row.status}`) }}
              </span>
            </table-list-column>
            <table-list-column class-name="text-center col-2 logo">
              <img v-if="row.connector" :src="getIcon(row)" :alt="row.alias" />
            </table-list-column>
            <table-list-column class-name="col-3">
              {{ row.audience }}
            </table-list-column>
            <table-list-column class-name="col-2">
              <div class="d-flex align-items-center"><i class="icon icon-clock"></i> {{ row.exec_time }}</div>
            </table-list-column>
            <table-list-column class-name="col-2">
              <div class="d-flex align-items-center">
                <i class="icon icon-calendar-14px"></i>
                {{ formatDate(row.export_date) }}
              </div>
            </table-list-column>
            <table-list-column class-name="text-right col-2">
              <span v-if="row.count !== null">{{ $_formatDecimal(row.count) }}</span>
              <span v-else>-</span>
            </table-list-column>
          </table-list-row>
        </table-list-body>
      </table-list>
    </div>
    <historic-skeleton v-else :table="table" :type="type" />
  </div>
</template>
<script>
import moment from 'moment';
import integrationMixin from '@/helpers/mixins/integrationMixin';
import { numberMixin } from '@/helpers/mixins';
import bus from '@/helpers/events/bus';
import { historic } from '@/services/audiences';
import { getConnectorsbyAudience } from '@/services/audiences';
import AudienceHistoricSkeleton from '@/components/audiences/create/historic/AudienceHistoricSkeleton';
import { mapState } from 'vuex';
import { dateTimeInverseFormat } from '@/utilities/constants';

export default {
  components: {
    'historic-skeleton': AudienceHistoricSkeleton,
  },
  mixins: [integrationMixin, numberMixin],
  data() {
    return {
      id: 'audience-historic',
      table: {
        rows: [],
        limit: 10,
        page: 1,
        count: 0,
      },
      type: 'filled',
      connectors: [],
      selectedConnector: undefined,
      error: undefined,
      loading: true,
    };
  },
  computed: {
    ...mapState('audience', { audienceID: state => state.reference }),
  },
  mounted() {
    this.getConnectors();
    this.list();
    this.listeners();
  },
  beforeUnmount() {
    this.listeners(true);
  },
  methods: {
    list() {
      historic({
        aid: this.audienceID,
        cid: this.selectedConnector,
        limit: this.table.limit,
        page: this.table.page,
      })
        .then(({ data }) => {
          if (data) {
            this.table.rows = data.data;
            this.table.count = data.total_count;
          }
          this.loading = false;
        })
        .catch(err => {
          this.error = err;
          this.table.rows = [];
          this.loading = false;
        });
    },
    getConnectors() {
      getConnectorsbyAudience(this.audienceID)
        .then(({ data }) => {
          this.connectors = data.data;
        })
        .catch(() => {
          this.connectors = [];
        });
    },
    formatDate(date) {
      let dateTime = date;
      // check if date is valid
      if (moment(date).isValid()) {
        const diff = moment(date).diff(moment());
        // if date is in the past
        if (diff <= 0) {
          const minutes = Math.floor(diff / 60000);
          // humanize example: a hour ago
          dateTime = moment.duration(minutes, 'minutes').humanize(true);
        } else dateTime = moment(date).format(dateTimeInverseFormat);
      }
      return dateTime || '---';
    },
    listeners(off = false) {
      const action = off ? '$off' : '$on';
      bus[action](`${this.id}-change-page`, this.list);
      bus[action](`${this.id}-change-order`, this.list);
    },
  },
};
</script>
<style lang="scss" scoped>
.connector-filter {
  min-width: 220px;
}
.icon {
  color: $gray-700;
}
.icon-calendar-14px {
  font-size: 14px;
  margin-right: 10px;
}
.icon-clock {
  font-size: 18px;
  margin-right: 5px;
}
.icon-question-12px {
  font-size: 12px;
}

.logo img {
  max-width: 40px;
}
.logo span {
  font-family: $font-family;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.status {
  display: inline-block;
  padding: 0 7px;
  font-weight: 600;
  line-height: 22px;
  border: 1px solid;
  border-radius: 5px;
  &.success {
    color: $oto-ecommerce;
    border-color: $oto-ecommerce;
  }
  &.error,
  &.cancelled {
    color: $color-red;
    border-color: $color-red;
  }
  &.running {
    color: $color-orange;
    border-color: $color-orange;
  }
  &.scheduled {
    color: $oto-omni;
    border-color: $oto-omni;
  }
}
</style>

<style lang="scss">
.audience-historic-tooltip {
  width: 170px;
  text-align: center;
}
</style>
