<template>
  <div class="container loadHeight mb-4">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('recency') }}</h5>
        <span class="segment-label">{{ $_segmentNameSelect() }}</span>
      </header>
      <lightbox-template-chart :settings="recencyData" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import getMetrics from '@/services/getmetrics';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'RecencyChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  data() {
    return {
      recencyData: [],
      recencyGroupData: [],
      segmentSelected: '',
    };
  },
  mounted() {
    this.reset();
    this.fetchChart();
  },
  methods: {
    fetchChart() {
      getMetrics({
        metrics: ['arr_active_recency_dd', 'recency'],
        expand: 1,
        segments: this.segmentSelected || undefined,
        isLightbox: true,
      })
        .then(({ data: { data } }) => {
          if (this.$_verifyData(data.metrics)) return;
          const { recency } = data.metrics;
          const obj = data.metrics;
          const days = obj ? Object.keys(obj) : null;
          const arrRecency = {
            [days[0]]: [],
            [days[1]]: [],
            [days[2]]: [],
            [days[3]]: [],
            [days[4]]: [],
            [days[5]]: [],
          };
          for (let i = 0; i < days.length - 1; i += 1) {
            obj[days[i]].forEach(item => {
              arrRecency[days[i]].push(item);
            });
            let { labels } = data;
            labels = this.format({ labels, formatTo: 'MMM/YY' });
            this.LoadDashRecency(arrRecency, recency, labels);
          }
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    LoadDashRecency(arrRecency, recency, labels) {
      const seriesName = arrRecency ? Object.keys(arrRecency) : null;
      const series = seriesName
        ? seriesName.map(name => ({
            name: `${name} ${this.$tc('day', parseInt(arrRecency[name]))}`,
            data: arrRecency[name],
            type: 'column',
          }))
        : null;
      if (series) {
        series.push({
          name: this.$t('medium-recency'),
          data: recency,
          type: 'spline',
          yAxis: 1,
        });
      }
      this.recencyData = {
        chart: {
          height: 280,
        },
        title: {
          text: null,
        },
        colors: [
          '#36AAD6',
          '#EEDA36',
          '#FA6F32',
          '#9CFF99',
          '#EC3942',
          '#406B62',
          '#A05195',
          '#2F4B7C',
          '#665191',
          '#BEBEBE',
        ],
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
          },
        },
        xAxis: {
          allowDecimals: false,
          barWidth: 4,
          categories: labels,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
          },
          {
            title: {
              text: null,
            },
            labels: {
              formatter: label => `${label.value} ${this.$tc('day', label.value)}`,
            },
            lineWidth: 0,
            opposite: true,
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
            borderWidth: 0,
            lineWidth: 2.5,
            fillOpacity: 1,
          },
        },
        series,
      };
    },
    reset() {
      this.$_reqConfig();
      this.recencyData = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
