<!--
  Modal Dates
  Retorna um span com as datas selecionas no header no seguinte formato:
  <span>01 jan 2018 - 30 jun 2018</span>
-->
<template>
  <span>{{ dates }}</span>
</template>

<script>
import moment from 'moment';
import { dateCompleteVariation } from '@/utilities/constants';

export default {
  name: 'ModalDates',
  props: {
    subtractedDate: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    dates() {
      return this.formatDates(this.$store.getters.dates);
    },
  },
  methods: {
    formatDates(dates) {
      const startDate = this.subtractedDate
        ? moment(this.subtractedDate).format(dateCompleteVariation)
        : moment(dates.startDate).format(dateCompleteVariation);
      const endDate = moment(dates.endDate).format(dateCompleteVariation);
      return `${startDate} - ${endDate}`;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-weight: 400;
}
</style>
