import { getTableAudience } from '../services';
import { Audience, ResponseAudience, ResponseDataAudience, ResponseColumnAudience, TableParams } from '../models';

export const fetchAudience = (params: TableParams) =>
  getTableAudience(params).then(response => formatAudience(response?.data));

const formatAudience = (response: ResponseAudience) => ({
  success: response.success,
  data: formatDataTable(response.data, response.series),
  columns: formatHeaderTable(response.series),
  totalCount: response.totalCount,
});

const formatDataTable = (data: ResponseDataAudience, columns: ResponseColumnAudience[]) => {
  return data.map(row => {
    const formattedRow: Audience = Object.fromEntries(row.map((cell, index) => [columns[index].key, cell])) as Audience;
    return formattedRow;
  });
};

const formatHeaderTable = (columns: ResponseColumnAudience[]) =>
  columns.map(column => ({
    key: column.key,
    label: column.name,
    type: column.type,
  }));
