<template>
  <div>
    <div v-for="(campaign, index) in filterCampaignsList" :key="`campaign-${index}`">
      <suggestion-card-item :campaign="campaign" />
    </div>
    <div v-if="!getActionsList.length && !getLoadingStatus" class="campaign-empty">
      <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" alt="" />
      <h2>{{ $t('360.campaigns.first-action') }}</h2>
    </div>
    <template v-if="getLoadingStatus">
      <div v-for="i in 6" :key="i" class="card card-loader">
        <base-loader />
      </div>
    </template>
    <list-pagination
      v-if="getActionsList.length"
      :data="pagination"
      :limit="pagination.limit"
      :limit-range="limitRange"
      :identify="id"
    />
  </div>
</template>

<script>
// libs
import { mapActions, mapGetters, mapState } from 'vuex';
// helpers
import bus from '@/helpers/events/bus';
// components
import SuggestionCardItem from '@/components/oto/campaignsManagement/_organisms/CampaignsManagementCardItem';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import ListPagination from '@/components/_atoms/ListPagination';

export default {
  name: 'SuggestionCards',
  components: {
    SuggestionCardItem,
    BaseLoader,
    ListPagination,
  },
  computed: {
    ...mapState('campaignsManagement', ['id', 'pagination', 'limitRange']),
    ...mapGetters('campaignsManagement', ['getActionsList', 'getFilteredSuggestionList', 'getLoadingStatus']),
    filterCampaignsList: function () {
      return this.getFilteredSuggestionList();
    },
  },
  mounted() {
    this.fetchData();
    bus.$on('update-selected-brand', () => {
      this.fetchData();
    });
    bus.$on('render-cards', () => {
      this.fetchData();
    });
    bus.$on('suggestion-campaign-refresh', () => {
      this.fetchData();
    });
    bus.$on(`${this.id}-change-page`, () => {
      this.fetchData();
    });
  },
  // TODO: remove unnused events
  beforeUnmount() {
    bus.$off([
      'update-selected-brand',
      'render-cards',
      'open-suggestion-management-modal',
      'suggestion-campaign-refresh',
      `${this.id}-change-page`,
    ]);
  },
  methods: {
    ...mapActions('campaignsManagement', ['getActions']),
    fetchData() {
      this.getActions();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-loader {
  height: 200px;
}

.campaign-empty {
  height: 400px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;

  img {
    margin-right: 10px;
  }
}
</style>
