<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body :close-on-press-escape="false" :show-close="false">
    <dialog-error :close="closeModal" :message="$t('settings.users.accounts-error')" />
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
// components
import DialogError from '@/components/_atoms/DialogError';

export default {
  name: 'UserDialogErrorModal',
  components: {
    DialogError,
  },
  data() {
    return {
      id: 'user-accounts-error-modal',
      message: this.$tc('errors.title', 1),
      isVisible: false,
    };
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off(`open-${this.id}`);
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
#user-accounts-error-modal {
  width: 500px;
}
</style>
