<template>
  <el-dialog v-model="isVisible" :id="id" class="export" append-to-body width="535">
    <form @submit.prevent="submit()">
      <div class="export__wrapper export__wrapper__type">
        <div class="export__item">
          <label>{{ $t('integrations.import-header.typeLoad') }}</label>
          <div class="export__columns-wrapper wrap">
            <input
              ref="input-destination"
              v-model="selectedOption"
              type="text"
              :placeholder="$t('integrations.select')"
              v-focus="openSelectType"
              @click="openSelectType"
            />
            <i class="arrow-down"></i>
            <div v-if="isTypeColumnsActive" v-click-outside="closeSelectType" class="export__columns no-click">
              <div class="export__columns-list no-click">
                <ul v-if="selectOptions.length" class="export__columns-groups customScrollBar no-click">
                  <li v-for="(connectors, index) in selectOptions" :key="index" class="export__columns-group no-click">
                    <strong>{{ connectors[0] }}</strong>
                    <ul v-if="connectors[1].length" class="export__columns-row">
                      <template v-for="connector in connectors[1]" :key="connector.key">
                        <template v-if="connector.key === 'OPTOUT_CUSTOMERS'">
                          <el-popover trigger="hover" placement="right" :width="250">
                            <p v-html="$t('settings.integrations.tooltip-opt-out')"></p>
                            <template #reference>
                              <li
                                @click="selectTypeTable(connector, $event)"
                                v-html="connector.name"
                                class="export__columns-item"
                              ></li>
                            </template>
                          </el-popover>
                        </template>

                        <template v-else-if="connector.key === 'CONSOLIDATED_CUSTOMERS'">
                          <el-popover trigger="hover" placement="right" :width="250">
                            <p v-html="$t('settings.integrations.tooltip-consolidated')"></p>
                            <template #reference>
                              <li
                                @click="selectTypeTable(connector, $event)"
                                v-html="connector.name"
                                class="export__columns-item"
                              ></li>
                            </template>
                          </el-popover>
                        </template>

                        <template v-else-if="connector.key === 'CUSTOMERS_REGISTERED_APP'">
                          <el-popover trigger="hover" placement="right" :width="250">
                            <p v-html="$t('settings.integrations.tooltip-customers-app')"></p>
                            <template #reference>
                              <li
                                @click="selectTypeTable(connector, $event)"
                                v-html="connector.name"
                                class="export__columns-item"
                              ></li>
                            </template>
                          </el-popover>
                        </template>

                        <template v-else>
                          <li
                            class="rd-select__columns__item"
                            @click="selectTypeTable(connector, $event)"
                            v-html="connector.name"
                          ></li>
                        </template>
                      </template>
                    </ul>
                  </li>
                </ul>
                <div v-else class="no-click rd-select__columns__empty-select">
                  <span v-html="$t('integrations.no-results')"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="export__item">
          <label>{{ $t('integrations.destiny') }}</label>
          <div class="export__columns-wrapper wrap">
            <input
              ref="input-destination"
              v-model="models.modelDestiny"
              type="text"
              :placeholder="$t('integrations.select')"
              v-focus="openDestinationSelect"
              @click="openDestinationSelect"
            />
            <i class="arrow-down"></i>
            <div
              v-if="isDestinationColumnsActive"
              v-click-outside="closeDestinationSelect"
              class="export__columns no-click"
            >
              <div class="export__columns-list no-click">
                <ul v-if="instanceConnectors.length" class="export__columns-groups customScrollBar no-click">
                  <li
                    v-for="connectors in instanceConnectors"
                    :key="connectors.id"
                    class="export__columns-group no-click"
                  >
                    <strong>{{ connectors.name }}</strong>
                    <ul v-if="connectors.instances.length" class="export__columns-row">
                      <li
                        v-for="connector in connectors.instances"
                        :key="connector.id"
                        class="export__columns-item"
                        @click="selectConnector(connector, $event)"
                        v-html="connector.name"
                      ></li>
                    </ul>
                  </li>
                </ul>
                <div v-else class="export__empty-select no-click">
                  <span v-html="$t('integrations.empty-destiny')"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="export__wrapper">
        <div class="export__item">
          <label>{{ $t('integrations.first') }}</label>
          <v-select
            v-model="models.modelFirstExport"
            :clearable="false"
            :searchable="false"
            :options="selectFirstExport"
            :placeholder="$t('integrations.select')"
            label="title"
            @option:selected="onChangeFirstExport"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('integrations.no-results') }}
            </template>
          </v-select>
        </div>

        <div class="export__item element--datepicker datepicker--cpm">
          <label>{{ $t('integrations.first') }}</label>
          <el-date-picker
            v-model="models.modelDateTime"
            type="datetime"
            format="YYYY-MM-DD HH:mm"
            value-format="YYYY-MM-DD HH:mm"
            :clearable="false"
            :prefix-icon="customPrefix"
            :disabled-date="disabledBackwardDates"
            :placeholder="$tc('_select', 2)"
            :disabled-hours="disabledDatepickerHours"
            :disabled-minutes="disabledDatepickerMinutes"
            :disabled="isDatePickerDisabled"
          />
        </div>
      </div>

      <div class="export__wrapper frequence">
        <label>{{ $t('integrations.frequence') }}</label>
        <div class="export__frequence">
          <div class="export__frequence-item">
            <v-select
              v-model="models.modelFrequence"
              class="recurrence"
              :clearable="false"
              :searchable="false"
              :options="selectFrequence"
              :placeholder="$t('integrations.select')"
              label="title"
              @option:selected="onChangeFrequence"
              v-tooltip.right="{
                content: getContentTooltip(textTooltip),
                theme: 'default',
                distance: 20,
                html: true,
                autoHide: false,
                triggers: [],
                shown: isActiveTooltip,
                skidding: 30,
              }"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #option="{ value, title }">
                <div @mouseover="activeTooltip(value)" @mouseleave="desactiveTooltip">
                  <span>
                    {{ title }}
                  </span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div class="export__actions">
        <button :class="['btn ', { disabled: !isSubmitActive }]" type="submit">
          {{ $t('integrations.create-exp') }}
        </button>
      </div>
    </form>
  </el-dialog>
</template>

<script>
// libs
import vSelect from 'vue-select';
import moment from 'moment';
import vClickOutside from 'click-outside-vue3';
import { h, shallowRef } from 'vue';
import { useToast } from 'vue-toastification';

// helpers
import bus from '@/helpers/events/bus';
import { dateTimeInverseFormat } from '@/utilities/constants';
// services
import { getTableList, getInstancesByConnector, putExportSchedule } from '@/services/integrations';
// utilities
import { disabledBackwardDates, disabledHours, disabledMinutes } from '@/utilities/datepicker';
import { mapActions } from 'vuex';
export default {
  components: {
    vSelect,
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const customPrefix = shallowRef({
      render() {
        return h('i', {
          class: 'icon icon-calendar-30px',
        });
      },
    });
    const toast = useToast();

    return { customPrefix, toast };
  },
  data() {
    return {
      id: 'create-export-modal',
      loadTypes: [],
      selectedOption: '',
      isDestinationColumnsActive: false,
      isTypeColumnsActive: false,
      instanceConnectors: [],
      datePickerId: 'export-integration-datepicker',
      instanceConnectorSelected: null,
      isDatePickerDisabled: true,
      isSelectFrequenceActive: false,
      isSelectExportModeActive: false,
      isVisible: false,
      baseState: {
        modelLoadTypes: '',
        modelDestiny: '',
        modelFirstExport: '',
        modelDateTime: this.$t('integrations.select'),
        modelFrequence: '',
        modelFrequenceEachValue: '1',
        modelFrequenceType: '',
        modelExportMode: '',
      },
      isActiveTooltip: false,
      textTooltip: '',
      models: {},
      selectFirstExport: [
        {
          value: 'save',
          title: this.$t('integrations.modal.save'),
        },
        {
          value: 'scheduled',
          title: this.$t('integrations.modal.scheduled'),
        },
      ],
      selectFrequence: [
        {
          value: 'daily',
          title: this.$t('integrations.modal.daily'),
        },
        {
          value: 'exact',
          title: this.$t('integrations.modal.exact'),
        },
      ],
      selectFrequenceTypes: [
        {
          value: 'day',
          title: this.$t('integrations.modal.day'),
        },
        {
          value: 'hour',
          title: this.$t('integrations.modal.hour'),
        },
      ],
      selectExportMode: [
        {
          value: 'full',
          title: this.$t('integrations.modal.full'),
        },
        {
          value: 'increment',
          title: this.$t('integrations.modal.increment'),
        },
      ],
    };
  },
  computed: {
    isSubmitActive() {
      return (
        this.models.modelLoadTypes &&
        this.models.modelDestiny &&
        this.models.modelFirstExport &&
        this.models.modelFrequence
      );
    },
  },
  beforeUnmount() {
    bus.$off(['open-create-export-modal', 'refresh-create-export-modal']);
  },
  mounted() {
    this.fetchLoadList();
    this.fetchInstances();
    bus.$on('open-create-export-modal', instance => {
      this.openModal();

      if (instance) {
        this.models.modelDestiny = instance?.name;
        this.instanceConnectorSelected = instance;
      }
    });
    bus.$on('refresh-create-export-modal', () => {
      this.fetchLoadList();
      this.fetchInstances();
    });
  },
  methods: {
    ...mapActions('historyExport', ['getExportHistoryList']),

    selectTypeTable(event) {
      this.selectedOption = event.name;
      this.models.modelLoadTypes = event.key;
      this.closeSelectType();
    },

    openSelectType() {
      if (this.isTypeColumnsActive) {
        this.closeSelectType();
        return;
      }
      this.isTypeColumnsActive = true;
    },

    closeSelectType() {
      this.isTypeColumnsActive = false;
    },

    fetchLoadList() {
      getTableList().then(response => {
        const data = response?.data;
        if (!data.success) return;
        this.loadTypes = data?.data;

        this.selectOptions = Object.entries(this.loadTypes);
      });
    },
    getContentTooltip(value) {
      return this.$t(`integrations.modal.tooltip.${value}`);
    },
    fetchInstances() {
      getInstancesByConnector().then(({ data }) => {
        if (!data.success) return;
        const sftpOptions = data?.data.filter(options => options.name === 'SFTP');
        this.instanceConnectors = sftpOptions;
      });
    },

    openDestinationSelect() {
      this.isDestinationColumnsActive = true;
    },
    closeDestinationSelect() {
      this.isDestinationColumnsActive = false;
    },
    selectConnector(connector, event) {
      this.instanceConnectorSelected = connector;
      this.models.modelDestiny = event.target.textContent;
      this.closeDestinationSelect();
    },
    submit() {
      const { modelLoadTypes, modelFirstExport, modelDateTime, modelFrequence } = this.models;

      const intervalHours = 24;
      const instanceLoadTypeId = modelLoadTypes;
      const instanceConnectorId = this.instanceConnectorSelected?.id;
      const instanceDateTime = modelFirstExport?.value === 'scheduled' ? modelDateTime : '';
      const instanceFrequencePeriod = modelFrequence?.value === 'daily' ? intervalHours : '';

      putExportSchedule({
        instanceLoadTypeId,
        instanceConnectorId,
        instanceDateTime,
        instanceFrequencePeriod,
      })
        .then(response => {
          if (response?.data?.success) {
            this.toast.success(this.$t(`integrations.modal.create-export.success`), {
              timeout: 5000,
            });
          }
        })
        .catch(() => {
          this.toast.error(this.$t('integrations.modal.create-export.error'));
        })
        .finally(() => {
          bus.$on('refresh-create-export-modal', () => {
            this.fetchLoadList();
            this.fetchInstances();
          });
          this.closeModal();
        });
    },
    openModal() {
      this.resetStates();
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    onChangeFirstExport(item) {
      if (item?.value === 'scheduled') {
        this.isDatePickerDisabled = false;
        this.triggerDatePicker();
      } else {
        this.isDatePickerDisabled = true;
        this.models.modelDateTime = this.$t('integrations.select');
      }
    },
    activeTooltip(value) {
      this.isActiveTooltip = true;
      this.textTooltip = value;
    },
    desactiveTooltip() {
      this.isActiveTooltip = false;
      this.textTooltip = 'daily';
    },
    triggerDatePicker() {
      this.models.modelDateTime = moment().second(0).add(10, 'minutes').format(dateTimeInverseFormat);
    },
    onChangeFrequence(item) {
      if (item?.value === 'daily') {
        this.isSelectFrequenceActive = true;
      } else {
        this.isSelectFrequenceActive = false;
      }
    },
    resetStates() {
      this.isDatePickerDisabled = true;
      this.isSelectFrequenceActive = false;
      this.isSelectExportModeActive = false;
      this.models = Object.assign({}, this.baseState);
      this.selectedOption = '';
    },
    disabledDatepickerHours() {
      return disabledHours(this.models.modelDateTime);
    },
    disabledDatepickerMinutes() {
      return disabledMinutes(this.models.modelDateTime);
    },
    disabledBackwardDates,
  },
};
</script>

<style lang="scss" scoped>
.export {
  .export__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;

    &__type {
      .export__item:first-child {
        width: 100%;
        max-width: 225px;
      }

      .export__item:last-child {
        max-width: 200px;
      }
    }

    &.frequence {
      align-items: baseline;
      flex-direction: column;
    }
  }
  .export__item {
    width: 50%;
    max-width: 210px;
  }

  &__empty-select {
    display: block;
    font-size: 12px;
    padding: 15px;
  }

  .export__frequence {
    display: grid;
    grid-template-columns: 140px 47px 124px 124px;
    align-items: center;
    grid-auto-columns: 25px;
    .export__frequence-item {
      span {
        font-size: 12px;
        width: 47px;
      }
      .recurrence {
        width: 130px;
      }
      .input-frequence {
        width: 115px;
      }
    }
  }
  .export__actions {
    display: flex;
    justify-content: flex-end;
    button {
      width: 170px;
      height: 40px;

      &.disabled {
        background-color: #bbb;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  .export__columns-wrapper {
    position: relative;

    input {
      cursor: pointer;
      z-index: 102;
    }

    .arrow-down {
      position: absolute;
      margin: 4px 10px 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $gray-600;
      right: 0;
      top: 11px;
      z-index: 104;
    }
  }

  .export__columns {
    background-color: transparent;
    border-radius: 18px;
    border-top: 0;
    border: 1px solid $gray-500;
    overflow: hidden;
    padding-top: 35px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    .export__columns-list {
      height: 100%;
      overflow: hidden;
      background-color: $color-white;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
    }

    ul {
      list-style: none;
      padding: 0;
      pointer-events: auto;
      font-size: 12px;
      color: $gray-700;
      line-height: 26px;

      &.export__columns-groups {
        max-height: 260px;
        min-height: 140px;
        overflow-y: auto;
        margin: 10px 0;
      }

      &.export__columns-row {
        margin: 0;

        li.export__columns-item {
          padding: 0 15px;

          &:hover {
            background-color: $gray-300;
            cursor: pointer;
          }
        }
      }
    }
    strong {
      padding: 0 15px;
    }
  }
}
</style>

<style lang="scss">
#create-export-modal {
  @import '@/assets/scss/vendors/_v-select';

  height: 420px;

  .el-dialog__header {
    padding: 30px 30px 0 30px;
  }

  .el-dialog__body {
    padding: 0 50px;
  }
}

.el-popover p {
  font-family: 'Montserrat';
  font-size: 12px !important;
  font-weight: 400;
  color: $gray-700 !important;
  word-break: break-word !important;
  text-align: left !important;
  margin-bottom: 0 !important;
}
</style>
