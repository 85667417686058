<template>
  <section class="persona-group" :class="include ? 'include' : 'exclude'">
    <div v-if="isEditable" class="wrap-delete-group">
      <button class="delete-group" @click.prevent="removeCurrentGroup">
        <i class="icon icon-trash"></i> {{ $t('audiences.delete-group') }}
      </button>
    </div>
    <div class="series">
      <persona-add-label-button
        v-show="formEnabled"
        :title="$t('personas.detail.group.add-new')"
        :action="openLabelListModal"
      />

      <div v-if="series.length" class="detail-serie-wrapper">
        <detail-serie
          v-for="(serie, index) in series"
          :key="index"
          :group="id"
          :data="serie"
          :is-editable="isEditable"
        />

        <button
          v-show="!formEnabled && isEditable"
          class="btn add-serie"
          @click="openLabelListModal"
          v-tooltip="{
            theme: 'default',
            content: $t('labels.home.add-label'),
            distance: 8,
          }"
        >
          <span class="edit-labels-icon">
            <icon-base w="16" h="20" color="#fff" box="0 0 16 20"><icon-pencil /></icon-base>
          </span>
        </button>
      </div>
    </div>
    <span class="more-top" aria-hidden="true">
      <span class="text">{{ $t('or') }}</span>
    </span>
    <span class="more-bottom" aria-hidden="true"></span>
  </section>
</template>

<script>
// globals
import bus from '@/helpers/events/bus';
import { mapActions, mapGetters } from 'vuex';
// components
import DetailSerie from '@/components/personas/_organisms/DetailSerie.vue';
import PersonaAddLabelButton from '@/components/personas/_molecules/PersonaAddLabelButton';
//icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconPencil from '@/assets/vue-icons/icons/Pencil';

export default {
  name: 'PersonaDetailGroup',
  components: {
    DetailSerie,
    PersonaAddLabelButton,
    IconBase,
    IconPencil,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    series: {
      type: Array,
      default: null,
    },
    include: {
      type: Boolean,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      formEnabled: this.isEditable,
      selectedColumnInfos: {},
    };
  },
  computed: {
    ...mapGetters('personas', ['personaDetail', 'columnOptions']),
  },
  watch: {
    series() {
      if (this.series.length === 0) {
        this.enableForm();
        this.selectedColumnInfos = {};
      } else if (this.series.length) {
        this.disableForm();
      }
    },
  },
  mounted() {
    bus.$on('update-selected-column-info', this.getSelectedColumn);
    if (this.series.length) this.disableForm();
  },
  beforeUnmount() {
    bus.$off('update-selected-column-info');
  },
  methods: {
    openLabelListModal() {
      bus.$emit('open-persona-label-list-modal', {
        include: !!this.include,
        group: this.id,
        series: this.series,
      });
    },
    removeCurrentGroup() {
      this.removeGroup({ include: !!this.include, group: this.id });
    },
    getSelectedColumnInfos(column) {
      let selectedColumn = [];
      if (column) {
        this.columnOptions.forEach(group => {
          const filter = group.columns.filter(col => col.key === column)[0];
          if (filter) selectedColumn = filter;
        });
      }
      return selectedColumn;
    },
    getSelectedColumn(column) {
      this.selectedColumnInfos = {};
      if (column) {
        this.selectedColumnInfos = this.getSelectedColumnInfos(column);
      }
    },
    enableForm() {
      this.formEnabled = true;
    },
    disableForm() {
      if (this.series.length) this.formEnabled = false;
    },
    ...mapActions('personas', ['removeGroup']),
  },
};
</script>

<style lang="scss" scoped>
.persona-group {
  background-color: $color-white;
  padding: 25px 40px;
  border-radius: 15px;
  box-shadow: 0 -10px 10px -10px rgba(black, 0.1);
  position: relative;

  .detail-serie-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
  }

  &:first-of-type:last-of-type.include {
    .delete-group {
      display: none;
    }
  }

  &:first-of-type {
    .more-bottom {
      display: none !important;
    }
  }
  &:last-of-type {
    .more-top {
      display: none !important;
    }
  }

  .more-top,
  .more-bottom {
    &:before {
      content: '';
      width: 50px;
      height: 1px;
      background-color: $gray-500;
      display: block;
      position: absolute;
      top: 50%;
      left: -50px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .more-bottom {
    &:after {
      content: '';
      width: 1px;
      height: 50%;
      background-color: $gray-500;
      display: block;
      position: absolute;
      bottom: 50%;
      left: -50px;
    }
  }

  .more-top {
    &:after {
      content: '';
      width: 1px;
      height: calc(50% + 20px);
      background-color: $gray-500;
      display: block;
      position: absolute;
      top: 50%;
      left: -50px;
    }

    .text {
      position: absolute;
      left: calc(-50px - 20px);
      bottom: -21px;
      background-color: $gray-600;
      display: flex;
      width: 40px;
      height: 22px;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 600;
      border-radius: 10px;
      font-size: 11px;
      text-transform: uppercase;
      cursor: default;
      z-index: 10;
    }
  }

  .wrap-delete-group {
    position: absolute;
    right: 40px;
    top: 15px;
    .delete-group {
      background-color: transparent;
      font-weight: 600;
      font-size: 12px;
      color: $oto-omni;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }

  .add-serie {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    background-color: #36aad6;
    padding: 0;
    margin: 8px 0 0 12px;
    .edit-labels-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      background-color: $oto-omni;
    }
  }
}
</style>
