<template>
  <div :id="id" :class="{ card: defaultClass }">
    <div class="card-body">
      <header v-if="ready">
        <!-- subtitle top - ex: category name -->
        <slot name="item-top"></slot>
        <div class="card-title d-flex align-items-center">
          <i v-if="icon" class="icon" :class="icon"></i>
          <h2 v-if="title" class="m-0">{{ title }}</h2>

          <!-- slot to append some item at the right corner - ex: select -->
          <slot name="item-right"></slot>
        </div>
      </header>

      <!-- skeletons to title top or default h2 -->
      <base-card-skeleton :has-item-top-slot="hasItemTopSlot" :ready="ready" :is-space-active="isSpaceActive" />

      <slot />
    </div>
  </div>
</template>

<script>
import BaseCardSkeleton from '@/components/_atoms/BaseCardSkeleton';

export default {
  name: 'BaseCard',
  components: {
    'base-card-skeleton': BaseCardSkeleton,
  },
  props: {
    ready: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
    id: {
      type: String,
      default: null,
      required: false,
    },
    defaultClass: {
      type: Boolean,
      default: true,
    },
    isSpaceActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasItemTopSlot() {
      return !!this.$slots['item-top'];
    },
  },
};
</script>
