<template>
  <div class="comparison-stores">
    <div v-if="resetCard && !!stores.length && !!series.length" class="card">
      <div class="card-body">
        <h2 class="card-title card-custom-title d-flex align-items-center">
          <i class="orange-circle"></i>
          <span>{{ $parent.storeName }}</span>
        </h2>
        <div class="row">
          <comparison-item
            v-for="(serie, index) in series"
            :key="mountKey(serie, index)"
            :options="serie"
            class="col-12"
            :store-selected-name="$parent.storeName"
          ></comparison-item>
        </div>
      </div>
    </div>
    <div v-else class="card presentation-card">
      <p>
        {{ $tc('stores.empty-metrics-card', 1) }}
        <br />
        {{ $tc('stores.empty-metrics-card', 0) }}
      </p>
    </div>
    <load-spinner :id="id" class="white" />
  </div>
</template>

<script>
/* eslint-disable */
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import ComparisonItem from '@/components/stores/ComparisonStoresItem';
import NoStoresOnChart from '@/assets/img/figures/no-stores-chart.jpg';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import { valueType } from '@/utilities/constants';

export default {
  name: 'comparison-stores',
  components: {
    ComparisonItem,
    LoadSpinner,
  },
  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
    filters: Object,
  },
  data() {
    return {
      id: 'comparison-stores',
      series: [],
      bgImagePresentationCard: NoStoresOnChart,
      resetCard: false,
    };
  },
  methods: {
    fetch_data() {
      if (!this.filters.comparisonStores.length) return;
      bus.$emit('show-spinner', this.id);
      const { store, comparisonStores } = this.$parent.filters;
      const metrics = this.$parent.filters.metrics.length
        ? this.$parent.filters.metrics
        : Object.keys(this.$parent.metrics);
      this.series = [];

      getMetrics({
        endpoint: '/stores/get_parallel_coordinates',
        metrics: `${metrics.join(',')}`,
        filters: `store_id:${store},other_stores_ids:${comparisonStores.toString().split(',').join('|')}`,
      }).then(response => {
        this.mountSeries(response.data.metrics.parallel_coordinates.data.series, response.config.params.filter);
        bus.$emit('hide-spinner', this.id);
      });
    },
    mountSeries(series) {
      if (series) {
        this.series = [];
        for (let i = 0; i < series.length; i += 1) {
          const serie = series[i];
          if (serie.data) {
            const options = {
              key: i,
              selectedStore: this.$parent.filters.store,
              name: serie.name,
              data: this.formatData(serie.data),
              value: serie.value,
              min: this.getMin(Object.values(serie.data), serie.value),
              max: this.getMax(Object.values(serie.data), serie.value),
              radius: 5,
              type: serie.type,
            };
            this.series.push(options);
          }
        }
      }
    },
    getMax(data, value) {
      const arr = data.map(val => {
        if (typeof val === valueType.string) {
          return Number(val.replace(',', '.'));
        }
        return val;
      });

      return Math.max(Math.max(...arr), value);
    },
    getMin(data, value) {
      const arr = data.map(val => {
        if (typeof val === valueType.string) {
          return Number(val.replace(',', '.'));
        }
        return val;
      });

      return Math.min(Math.min(...arr), value);
    },
    mountKey(serie, index) {
      const key = `${serie.name}-${serie.selectedStore}-${serie.max}-${Math.random() + index}`;
      return key;
    },
    formatData(data) {
      const sorted = {};
      if (typeof data === 'object' && Object.keys(data).length) {
        Object.keys(data)
          .sort()
          .forEach(key => (sorted[key] = data[key])); // eslint-disable-line
        return sorted;
      }
      return data;
    },
  },
  mounted() {
    bus.$on('get-stores-list', () => {
      this.fetch_data();
    });
    bus.$on('comparison-stores-filters', () => {
      this.resetCard = true;
      this.fetch_data();
    });
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
    bus.$on('store-changed', () => {
      this.fetch_data();
    });
    bus.$on('restore-default-filters', () => {
      this.resetCard = false;
      this.series = [];
    });
  },
  beforeDestroy() {
    bus.$off('get-stores-list');
    bus.$off('comparison-stores-filters');
  },
};
</script>

<style lang="scss" scoped>
.comparison-stores {
  width: 100%;
  height: 100%;

  .card {
    margin-bottom: 0;
  }

  .presentation-card {
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;

    p {
      font-size: 14px;
    }
  }
}

.orange-circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: $oto-omni;
  border-radius: 50%;
  margin-right: 10px;
}
.card-custom-title {
  font-size: 12px;
  color: $gray-700;
}
</style>
