<template>
  <div class="ico-brand" :class="brand">
    <img :src="require(`@/assets/img/icons/brands/ico_${getBrandFilename()}.png`)" alt="icon" />
  </div>
</template>

<script>
export default {
  name: 'IntegrationBrand',
  props: {
    brand: {
      type: String,
      default: '',
    },
  },
  methods: {
    getBrandFilename() {
      return this.brand.toLowerCase().replace(/ /g, '_');
    },
  },
};
</script>

<style lang="scss" scoped>
.ico-brand {
  position: absolute;
  width: 65px;
  height: 32px;
  bottom: 6px;
  right: 14px;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
