<template>
  <div class="suggestion-details-period">
    <h3>{{ $t('360.campaigns.details.action-period') }}</h3>
    <!-- campaign active period -->
    <div class="sdp__active-period-wrapper">
      <label>{{ $t('360.campaigns.details.active-period') }}</label>
      <div class="sdp__datepicker-container">
        <div class="form-group sdp__datepicker element--datepicker">
          <el-date-picker
            v-model="dateValues"
            type="daterange"
            format="DD MMM YYYY"
            value-format="YYYY-MM-DD"
            :start-placeholder="$tc('start-date')"
            :end-placeholder="$tc('end-date')"
            :clearable="false"
            :disabled-date="disabledBackwardDates"
            :prefix-icon="customPrefix"
          />
        </div>
        <transition name="fade">
          <div
            v-show="(startDate && endDate) || formattedDates"
            class="sdp__icon-container"
            @click="deleteActivePeriodDate()"
          >
            <icon-base :color="'#EC3942'" class="sdp__trash-icon">
              <icon-trash />
            </icon-base>
          </div>
        </transition>
      </div>
    </div>
    <!-- campaign days of week -->
    <div class="sdp__days-of-week-wrapper">
      <h2 class="label">{{ $t('360.campaigns.details.days-of-week') }}</h2>
      <div v-if="sentDays" class="sdp__days-of-week-container">
        <div v-for="day in sentDays" :key="day.id" class="sdp__days-of-week">
          <label v-if="day" class="label sdp__checkbox" @click.prevent="$_toggleSelection(day.id, campaignCheckedDays)">
            <check-box class="checkbox" :checked="$_isSelected(day.id, campaignCheckedDays)" />
            <span class="sdp__days">{{ day.name }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { h, shallowRef } from 'vue';
// helpers
import { checkboxMixin } from '@/helpers/mixins';
import { dateCompleteVariation } from '@/utilities/constants';
// utilities
import { disabledBackwardDates } from '@/utilities/datepicker';
// assets
import IconBase from '@/assets/vue-icons/IconBase';
import IconTrash from '@/assets/vue-icons/icons/Trash';
// components
import CheckBox from '@/components/_atoms/CheckBox';

export default {
  name: 'CampaignsManagementDetailsPeriod',
  components: {
    CheckBox,
    IconBase,
    IconTrash,
  },
  setup() {
    const customPrefix = shallowRef({
      render() {
        return h('i', {
          class: 'icon icon-calendar-30px',
        });
      },
    });
    return { customPrefix };
  },
  mixins: [checkboxMixin],
  data() {
    return {
      campaign: {
        startDate: '',
        endDate: '',
      },
      sentDays: [
        {
          id: '1',
          name: moment.weekdays(1),
        },
        {
          id: '2',
          name: moment.weekdays(2),
        },
        {
          id: '3',
          name: moment.weekdays(3),
        },
        {
          id: '4',
          name: moment.weekdays(4),
        },
        {
          id: '5',
          name: moment.weekdays(5),
        },
        {
          id: '6',
          name: moment.weekdays(6),
        },
        {
          id: '7',
          name: moment.weekdays(0),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('campaignsManagement', ['getSuggestionDetail', 'getMode']),
    campaignCheckedDays() {
      return this.getSuggestionDetail?.dayOfWeek;
    },
    startDate() {
      return this.getSuggestionDetail.startDate;
    },
    endDate() {
      return this.getSuggestionDetail.endDate;
    },
    formattedDates() {
      if (!this.startDate && !this.endDate) return;
      return `${moment(this.startDate).format(dateCompleteVariation)} - ${moment(this.endDate).format(
        dateCompleteVariation,
      )}`;
    },
    dateValues: {
      get() {
        const dates = {
          startDate: this.getSuggestionDetail?.startDate,
          endDate: this.getSuggestionDetail?.endDate,
        };
        return [dates.startDate, dates.endDate];
      },
      set(newDate) {
        this.campaign.startDate = newDate[0];
        this.campaign.endDate = newDate[1];
        this.updateDates(this.campaign);
      },
    },
    isEditMode() {
      return this.getMode === 'edit';
    },
  },
  methods: {
    ...mapActions('campaignsManagement', ['updateDates']),

    /**
     * @description delete active period date
     */
    deleteActivePeriodDate() {
      this.updateDates({});
    },

    disabledBackwardDates,
  },
};
</script>

<style lang="scss" scoped>
.suggestion-details-period {
  margin: 40px 0;
  .sdp__active-period-wrapper {
    padding-top: 16px;
    .sdp__datepicker-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .sdp__icon-container {
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .sdp__trash-icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .sdp__datepicker {
    margin-bottom: 0;
    position: relative;
    width: 257px;
    .sdp__datepicker-input {
      min-width: 257px;
    }
    .icon {
      color: $gray-600;
      position: absolute;
      right: 15px;
      bottom: 5px;
      font-size: 24px;
      pointer-events: none;
    }
  }

  .daterangepicker {
    .drp-buttons {
      display: none;
    }
  }

  .sdp__days-of-week-wrapper {
    padding-top: 20px;
    .sdp__days-of-week-container {
      display: grid;
      grid-template-columns: repeat(4, 90px);
      grid-template-rows: 32px 32px;
      padding-bottom: 25px;
      .sdp__days-of-week {
        display: flex;
        align-items: center;
        .sdp__days {
          color: $gray-700;
          font-size: 12px;
        }
      }
      .sdp__checkbox {
        margin-right: 10px;
        cursor: pointer;
        display: flex;

        .checkbox {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
