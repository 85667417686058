<template>
  <div class="suggestions-datails-group">
    <div class="row">
      <div class="col-12">
        <campaigns-management-details-header :stores="stores" :filters="filters" />
      </div>
    </div>
    <hr class="solid" />
    <div class="row">
      <div class="col-5">
        <campaigns-management-details-period :filters="filters" />
      </div>
      <div class="col-7">
        <campaigns-management-details-dates />
      </div>
    </div>

    <hr class="solid my-4" />

    <campaigns-management-details-quarantine />

    <hr class="solid my-4" />

    <div class="row">
      <div class="col-12">
        <campaigns-management-details-campaigns />
      </div>
    </div>
    <div class="submit">
      <button :disabled="disabledButton" class="btn" @click.prevent="submit">{{ submitText }}</button>
    </div>

    <campaigns-management-detail-campaigns-modal />
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapActions, mapGetters, mapState } from 'vuex';

//services
import listStores from '@/services/stores';
// components
import CampaignsManagementDetailsHeader from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementDetailsHeader';
import CampaignsManagementDetailsPeriod from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementDetailsPeriod';
import CampaignsManagementDetailsDates from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementDetailsDates';
import CampaignsManagementDetailsCampaigns from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementDetailsCampaigns';
import CampaignsManagementDetailCampaignsModal from '@/components/oto/campaignsManagement/modals/CampaignsManagementDetailCampaignsModal';
import CampaignsManagementDetailsQuarantine from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementDetailsQuarantine';

const defaultfilters = {
  comparisonStores: [],
};
export default {
  name: 'SuggestionsDetailsGroup',
  components: {
    CampaignsManagementDetailsHeader,
    CampaignsManagementDetailsPeriod,
    CampaignsManagementDetailsDates,
    CampaignsManagementDetailsCampaigns,
    CampaignsManagementDetailCampaignsModal,
    CampaignsManagementDetailsQuarantine,
  },
  data() {
    return {
      stores: [],
      filters: { ...defaultfilters },
    };
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState('campaignsManagement', ['suggestionDetail', 'disabledButton']),

    ...mapGetters('campaignsManagement', ['getMode', 'getStoresId', 'hasEmptyRequiredFields']),

    submitText() {
      return this.getMode === 'edit' ? this.$t('360.campaigns.details.save') : this.$t('360.campaigns.details.new');
    },

    storeName() {
      const selected = this.stores.find(item => item.id === this.filters.store);
      return selected ? selected.store : '';
    },
  },

  mounted() {
    this.getStoresInitial();
    this.storesId();
    bus.$on('render-cards', () => {
      this.getStores();
    });
  },
  beforeUnmount() {
    this.setStatusTooltip({ key: 'name', value: false });
    this.setStatusTooltip({ key: 'actionCategory', value: false });
  },
  methods: {
    ...mapActions('campaignsManagement', ['actionSubmit', 'setStatusTooltip']),

    submit() {
      if (this.hasEmptyRequiredFields) {
        this.checkAndSetTooltip('name');
        this.checkAndSetTooltip('actionCategory');
        return;
      }

      this.actionSubmit();
    },

    checkAndSetTooltip(field) {
      if (!this.suggestionDetail[field]) {
        this.setStatusTooltip({ key: field, value: true });
      }
    },

    getStoresInitial() {
      listStores().then(response => {
        this.stores = response.data.data || [];
      });
    },

    resetComparisonStores() {
      this.filters.comparisonStore = [];
    },

    getStores() {
      listStores().then(response => {
        this.stores = response.data.data || [];
        this.resetComparisonStores();
        bus.$emit('get-stores-list');
      });
    },

    storesId() {
      this.getStoresId ? (this.filters.comparisonStores = this.getStoresId) : (this.filters.comparisonStores = []);
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestions-datails-group {
  .submit {
    display: flex;
    justify-content: flex-end;
    button {
      width: 190px;
      padding: 0 15px;
    }
  }
}
</style>
