<template>
  <figure>
    <img v-show="$_verifyLoaded('done')" :src="image" :alt="alt" />
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="error" />
  </figure>
</template>

<script>
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    url: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    load: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    image() {
      return this.url || require('@/assets/img/figures/product/no_photo.png'); // eslint-disable-line
    },
  },
  watch: {
    load() {
      this.loading = this.load;
    },
    url() {
      this.$_verifyData(this.url);
    },
  },
};
</script>

<style lang="scss" scoped>
figure {
  width: 100%;
  overflow: hidden;
  border-radius: $border-radius-size;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 290px;
}

img {
  width: 100%;
  height: auto;
}
</style>
