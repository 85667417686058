<template>
  <!-- eslint-disable -->
  <div class="card">
    <div class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <span>{{ $t('stores-filter-title') }}</span>
      </h2>
      <p>
        {{ $t('stores-filter-subtitle') }}
        <strong>{{ $parent.storeName }}</strong
        >.
      </p>
      <div id="comparison-metrics" class="form-group mt-3">
        <label>{{ $t('comparison-metrics') }}</label>
        <div
          id="selectMetrics"
          class="select-fake metrics no-click"
          @click.prevent="toggleDropdown('metricsOpened')"
          v-tooltip.right="{
            theme: 'error',
            shown: isShowingSelectMetricsTooltip,
            content: `<div class='stores-comparison-tooltip'>${$t('comparison-metrics-error')}</div>`,
            distance: 8,
            html: true,
          }"
        >
          {{ metricsSelected || $t('select') }}
        </div>
        <div v-show="metricsOpened" class="check-list dropdown-menu no-click">
          <div class="list no-click">
            <div v-for="(metric, key) in $parent.metrics" :key="key" class="select no-click">
              <label class="no-click" @click.prevent="$_toggleSelection(metric.key, filters.metrics)">
                <check-box class="no-click" :checked="$_isSelected(metric.key, filters.metrics)" />
                <div class="text no-click">
                  <span class="name no-click">{{ metric.name }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="comparison-criteria" class="form-group mt-3">
        <label>{{ $t('stores.criteria-selection-label') }}</label>
        <div
          id="selectCriteria"
          class="select-fake criteria no-click"
          @click.prevent="toggleDropdown('criteriaOpened')"
        >
          {{ $t(`stores.criteria-selection-${criteriaSelectedName}`) }}
        </div>
        <div v-show="criteriaOpened" class="check-list dropdown-menu no-click">
          <div class="list no-click">
            <div class="select no-click">
              <label class="no-click" @click.prevent="toggleCriteria('cluster')">
                <check-box class="no-click" :checked="criteriaSelected" />
                <div class="text no-click">
                  <span class="name no-click">{{ $t('stores.criteria-selection-cluster') }}</span>
                </div>
              </label>
            </div>
            <div class="select no-click">
              <label class="no-click" @click.prevent="toggleCriteria('region')">
                <check-box class="no-click" :checked="!criteriaSelected" />
                <div class="text no-click">
                  <span class="name no-click">{{ $t('stores.criteria-selection-region') }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="comparison-stores" class="form-group mt-3">
        <label>{{ $t('comparison-stores') }}</label>
        <div
          id="selectStores"
          class="select-fake stores no-click"
          @click.prevent="toggleDropdown('storesOpened')"
          v-tooltip.right="{
            theme: 'error',
            shown: isShowingSelectStoresTooltip,
            content: `<div class='stores-comparison-tooltip'>${$t('comparison-stores-error')}</div>`,
            distance: 8,
            html: true,
          }"
        >
          {{ criteriaSelectedName === 'cluster' ? clustersSelected : regionsSelected }}
        </div>
        <div v-if="criteriaSelectedName === 'region'" v-show="storesOpened" class="check-list dropdown-menu no-click">
          <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-regions') }}</div>
          <div v-for="(value, key) in comparisonStoresList" :key="key" class="select no-click" :class="`select-${key}`">
            <label class="no-click" @click.prevent="toggleRegion(key)">
              <check-box class="no-click" :checked="$_isSelected(key, selectedRegions)" />
              <div class="text no-click">
                <span class="name no-click">{{ key }}</span>
              </div>
              <i class="icon icon-right-14px no-click"></i>
            </label>
            <div class="check-list-child no-click">
              <div class="list no-click">
                <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-states') }}</div>
                <div class="searchStore no-click">
                  <input
                    v-model="term"
                    type="text"
                    class="no-click"
                    :placeholder="$t('stores.stores-search')"
                    autocomplete="none"
                  />
                  <i class="icon icon-search no-click"></i>
                </div>
                <div
                  v-for="(stores, index) in value"
                  v-show="filterResults(stores)"
                  :key="index"
                  class="select no-click"
                  :class="`select-${index}`"
                >
                  <label class="no-click" @click.prevent="toggleState(key, index)">
                    <check-box class="no-click" :checked="$_isSelected(index, selectedStates)" />
                    <div class="text state no-click">
                      <span class="name no-click">{{ index }}</span>
                    </div>
                  </label>
                  <div class="check-list-subchild no-click">
                    <div v-for="store in stores" :key="store.id" class="select no-click">
                      <label
                        v-show="filterResults(store)"
                        class="no-click"
                        @click.prevent="toggleStore(key, index, store.id)"
                      >
                        <check-box class="no-click" :checked="$_isSelected(store.id, filters.comparisonStores)" />
                        <div class="text no-click">
                          <span class="name no-click">{{ $tc('store', 1) }} {{ store.name }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else v-show="storesOpened" class="check-list dropdown-menu no-click">
          <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-regions') }}</div>
          <div v-for="(stores, key) in orderbyClusterList" :key="key" class="select no-click" :class="`select-${key}`">
            <label class="no-click" @click.prevent="toggleCluster(key)">
              <check-box class="no-click" :checked="$_isSelected(key, selectedClusters)" />
              <div class="text no-click">
                <span class="name no-click">{{ key }}</span>
              </div>
              <i class="icon icon-right-14px no-click"></i>
            </label>
            <div class="check-list-child no-click">
              <div class="list no-click">
                <div class="label text-uppercase mb-1 no-click">{{ $t('stores-title') }}</div>
                <div class="searchStore no-click">
                  <input
                    v-model="term"
                    type="text"
                    class="no-click"
                    :placeholder="$t('stores.stores-search')"
                    autocomplete="none"
                  />
                  <i class="icon icon-search no-click"></i>
                </div>
                <div
                  v-for="store in stores"
                  v-show="filterResults(store)"
                  :key="store.id"
                  class="select no-click"
                  :class="`select-${store.id}`"
                >
                  <label class="no-click" @click.prevent="toggleStore(key, null, store.id)">
                    <check-box class="no-click" :checked="$_isSelected(store.id, filters.comparisonStores)" />
                    <div class="text state no-click">
                      <span class="name no-click">{{ $tc('store', 1) }} {{ store.store }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-update" @click.prevent="filterHandler">
        {{ $t('stores-filter-comparison-btn') }}
      </button>
      <a href="#" class="btn-reset" @click.prevent="applyDefaultFilters">{{ $t('stores-filter-reset') }}</a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import bus from '@/helpers/events/bus';
import CheckBox from '@/components/_atoms/CheckBox';

export default {
  name: 'ComparisonStoresFilter',
  components: {
    'check-box': CheckBox,
  },
  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ready: false,
      metricsOpened: false,
      storesOpened: false,
      criteriaOpened: false,
      selectedStates: [],
      selectedRegions: [],
      selectedClusters: [],
      regionsSelected: this.$t('select'),
      clustersSelected: this.$t('select'),
      criteriaSelected: true,
      criteriaSelectedName: 'cluster',
      term: '',
      isShowingSelectMetricsTooltip: false,
      isShowingSelectStoresTooltip: false,
    };
  },
  computed: {
    comparisonStoresList() {
      const list = this.getComparisonStoresList();
      const regions = Object.keys(this.getComparisonStoresList());
      regions.forEach(region => {
        const states = Object.keys(list[region]);
        // ordena lojas de A-Z
        states.forEach(state => {
          list[region][state].sort((a, b) => (a.name > b.name ? 1 : -1));
        });
      });
      return list;
    },
    metricsSelected() {
      const metrics = [];
      this.$parent.metrics.forEach(item => {
        if (this.filters.metrics.includes(item.key)) {
          metrics.push(item.name);
        }
      });
      const listString = metrics.toString().split(',').join(', ');
      return listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
    orderbyClusterList() {
      // let clusterList = ;

      return this.groupByCluster();
    },
  },
  watch: {
    criteriaSelectedName() {
      this.filters.comparisonStores.length = 0;
      this.selectedClusters = [];
      this.selectedRegions = [];
      this.selectedStates = [];
      this.clustersSelected = this.$t('select');
      this.regionsSelected = this.$t('select');
    },
  },
  mounted() {
    this.reset();
    this.bodyClick();

    bus.$on('render-cards', () => {
      this.reset();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.watchSelectedClusters();
      this.watchSelectedRegions();
    });
  },
  methods: {
    filterHandler() {
      if (!this.filters.metrics.length) {
        this.isShowingSelectMetricsTooltip = true;
        setTimeout(() => (this.isShowingSelectMetricsTooltip = false), 20000);
      } else if (!this.filters.comparisonStores.length) {
        this.isShowingSelectStoresTooltip = true;
        setTimeout(() => (this.isShowingSelectStoresTooltip = false), 20000);
      } else bus.$emit('comparison-stores-filters', this.filters);
      bus.$emit('wrap-stores-plot');
    },
    applyDefaultFilters() {
      this.filters.comparisonStores.length = 0;
      this.clustersSelected = this.$t('select');
      this.regionsSelected = this.$t('select');

      this.criteriaSelected = true;
      this.criteriaSelectedName = 'cluster';
      this.selectedClusters.length = 0;
      this.selectedRegions.length = 0;
      this.selectedStates.length = 0;
      this.ready = false;
      bus.$emit('restore-default-filters');
    },
    toggleDropdown(current) {
      this[current] = !this[current];
      if (current === 'storesOpened') this.storesRegion = false;
      else if (current === 'metricsOpened') this.criteriaOpened = false;
      else this.storesOpened = false;
    },
    $_isSelected(key, obj) {
      return !!obj.includes(key);
    },
    $_toggleSelection(key, obj) {
      if (this.$_isSelected(key, obj)) this.$_unselectOption(key, obj);
      else this.$_selectOption(key, obj);
    },
    $_selectOption(key, obj) {
      if (!this.$_isSelected(key, obj)) obj.push(key);
    },
    $_unselectOption(key, obj) {
      if (!this.$_isSelected(key, obj)) return;

      const index = obj.indexOf(key);
      obj.splice(index, 1);
    },
    toggleStore(region, state, store) {
      if (!this.$_isSelected(store, this.filters.comparisonStores)) {
        // seleciona a loja e seus respectivos estados e regiao
        this.$_selectOption(store, this.filters.comparisonStores);
        if (this.criteriaSelectedName !== 'cluster') {
          this.$_selectOption(state, this.selectedStates);
          this.$_selectOption(region, this.selectedRegions);
        } else {
          this.$_selectOption(region, this.selectedClusters);
        }
      } else {
        this.$_unselectOption(store, this.filters.comparisonStores);
        if (this.criteriaSelectedName !== 'cluster') {
          // valida se todas as lojas estao desmarcadas e entao desmarca estado
          if (!this.storesByState(region, state)) this.$_unselectOption(state, this.selectedStates);
          // valida se todos os estados estao desmarcados e entao desmarca a regiao
          if (!this.statesByRegion(region)) this.$_unselectOption(region, this.selectedRegions);
        } else if (!this.storesByCluster(region)) {
          this.$_unselectOption(region, this.selectedClusters);
        }
      }
    },
    toggleCriteria(criteriaName) {
      if (criteriaName !== this.criteriaSelectedName) {
        this.criteriaSelectedName = criteriaName;
        this.criteriaSelected = !this.criteriaSelected;
      }
    },
    toggleRegion(region) {
      this.$_toggleSelection(region, this.selectedRegions);
      const states = Object.keys(this.comparisonStoresList[region]);
      states.forEach(state => {
        if (this.$_isSelected(region, this.selectedRegions)) this.selectState(region, state);
        else this.unselectState(region, state);
      });
    },
    toggleCluster(cluster) {
      if (this.$_isSelected(cluster, this.selectedClusters)) {
        this.unselectCluster(cluster);
      } else {
        this.selectCluster(cluster);
      }
    },
    selectCluster(cluster) {
      this.toggleClusterStores(cluster, true);
      this.$_selectOption(cluster, this.selectedClusters);
    },
    unselectCluster(cluster) {
      this.toggleClusterStores(cluster, false);
      this.$_unselectOption(cluster, this.selectedClusters);
    },
    toggleClusterStores(cluster, select) {
      this.orderbyClusterList[cluster].forEach(store => {
        if (select) this.$_selectOption(store.id, this.filters.comparisonStores);
        else this.$_unselectOption(store.id, this.filters.comparisonStores);
      });
    },
    toggleState(region, state) {
      if (this.$_isSelected(state, this.selectedStates)) {
        this.unselectState(region, state);
        // valida se todos os estados estao desmarcados e entao desmarca a regiao
        if (!this.statesByRegion(region)) this.$_unselectOption(region, this.selectedRegions);
      } else {
        this.selectState(region, state);
        this.$_selectOption(region, this.selectedRegions);
      }
    },
    selectState(region, state) {
      this.toggleStateStores(region, state, true);
      this.$_selectOption(state, this.selectedStates);
    },
    unselectState(region, state) {
      this.toggleStateStores(region, state, false);
      this.$_unselectOption(state, this.selectedStates);
    },
    toggleStateStores(region, state, select) {
      this.comparisonStoresList[region][state].forEach(store => {
        if (select) this.$_selectOption(store.id, this.filters.comparisonStores);
        else this.$_unselectOption(store.id, this.filters.comparisonStores);
      });
    },
    statesByRegion(region) {
      const states = Object.keys(this.comparisonStoresList[region]);
      return states.some(v => this.selectedStates.includes(v));
    },
    storesByState(region, state) {
      return this.comparisonStoresList[region][state].some(v => this.filters.comparisonStores.includes(v.id));
    },
    storesByCluster(region) {
      return this.orderbyClusterList[region].some(v => this.filters.comparisonStores.includes(v.id));
    },
    getComparisonStoresList() {
      const comparisonStoresList = {};
      this.$parent.stores.forEach(store => {
        if (store.region) {
          if (typeof comparisonStoresList[store.region] !== 'object') comparisonStoresList[store.region] = {};
          if (!Array.isArray(comparisonStoresList[store.region][store.state]))
            comparisonStoresList[store.region][store.state] = [];
          comparisonStoresList[store.region][store.state].push({
            id: store.id,
            name: store.store,
          });
        }
      });
      this.ready = true;
      return comparisonStoresList;
    },
    reset() {
      this.selectedRegions.length = 0;
      this.selectedStates.length = 0;
      this.ready = false;
    },
    groupByCluster() {
      const groupBy = key => array =>
        array
          .filter(store => store.id !== this.filters.store)
          .reduce((objectsByKeyValue, obj) => {
            let value = this.$t('stores.select-others');
            if (obj[key]) {
              value = obj[key];
            }
            const clipboard = objectsByKeyValue;
            clipboard[value] = (clipboard[value] || []).concat(obj);
            return clipboard;
          }, {});
      const groupByCluster = groupBy('cluster');
      return groupByCluster(this.stores);
    },
    filterResults(obj) {
      if (obj.name) return obj.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      else if (obj.store) return obj.store.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      return obj.filter(store => store.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1).length;
    },
    watchSelectedClusters() {
      if (!this.selectedClusters.length) {
        this.clustersSelected = this.$t('select');
        return;
      }
      const sortedClusters = [...this.selectedClusters].sort();
      const listString = sortedClusters.toString().split(',').join(', ');
      this.clustersSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
    watchSelectedRegions() {
      if (!this.selectedRegions.length) {
        this.regionsSelected = this.$t('select');
        return;
      }
      const listString = this.selectedRegions.toString().split(',').join(', ');
      this.regionsSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
    bodyClick() {
      const body = document.querySelector('body');
      body.addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.metricsOpened = false;
            this.criteriaOpened = false;
            this.storesOpened = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  border-radius: 100px;
  margin-top: 45px;
  padding: 0 15px;
}
.btn-reset {
  display: block;
  font-weight: 600;
  line-height: 46px;
  font-size: 12px;
  text-align: center;
  color: $gray-700;
}
.form-group {
  position: relative;
}
.dropdown-menu {
  display: block;
  .searchStore {
    position: relative;
    max-width: 236px;
    margin: 5px auto 18px;
    input {
      width: 100%;
    }
    .icon-search {
      position: absolute;
      right: 5px;
      top: 2px;
      color: #bbbbbb;
    }
  }
}
#comparison-metrics {
  .check-list {
    padding: 10px;
  }
  .list {
    max-height: 235px;
    padding: 5px;
    overflow-y: auto;
  }
}
.check-list {
  position: absolute;
  top: 58px;
  left: 0;
  width: 223px;
  padding: 18px 15px;
  background-color: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  z-index: 98;
  border: 0;
  margin: 0;
  &.hide {
    display: none;
  }
  .select {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    label {
      display: grid;
      align-items: center;
      user-select: none;
      cursor: pointer;
      margin: 0;
      grid-template-columns: 16px 1fr;
      &:hover {
        .check-box:not(.checked) {
          border-color: $gray-600;
        }
      }
      .text {
        margin-left: 10px;
        color: $gray-700;
        .name {
          display: block;
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
        }
      }
    }
  }

  > .select {
    &:hover {
      .check-list-child {
        visibility: visible;
      }
    }
    label {
      grid-template-columns: 16px 1fr 25px;
    }
    .icon-right-14px {
      font-size: 10px;
    }
  }
  .check-list-child {
    display: block;
    visibility: hidden;
    width: 285px;
    max-height: 315px;
    padding: 10px;
    position: absolute;
    left: 200px;
    top: 0;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.25s cubic-bezier(0.1, 0.65, 0.5, 1);
    transition-delay: 0.3s;
    .list {
      padding: 5px;
      max-height: 295px;
      overflow-y: auto;
      .state .name {
        font-weight: 600;
      }
    }
    .check-list-subchild {
      padding: 12px 14px 12px 25px;
    }
  }
}
.check-list,
.list {
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $gray-300;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }
}
.select-fake {
  width: 100%;
  overflow: hidden;
  padding-right: 25px;
  cursor: pointer;
}
</style>

<style lang="scss">
.stores-comparison-tooltip {
  width: 170px;
}
</style>
