<template>
  <lightbox-templates id="lightbox-tracked-revenue" :title="$t('attribution-title')">
    <tracked-revenue-chart />
    <tracked-revenue-table />
  </lightbox-templates>
</template>

<script>
// @ is an alias to /src
import LightboxTemplates from '@/components/lightbox/LightboxTemplate';
import { lightboxesMixin } from '@/helpers/mixins';
import TrackedRevenueChart from '@/components/lightbox/lightboxes/_organisms/trackedRevenue/TrackedRevenueChart';
import TrackedRevenueTable from '@/components/lightbox/lightboxes/_organisms/trackedRevenue/TrackedRevenueTable';

export default {
  name: 'LightboxTrackedRevenue',
  components: {
    LightboxTemplates,
    TrackedRevenueChart,
    TrackedRevenueTable,
  },
  mixins: [lightboxesMixin],
};
</script>
