<template>
  <div class="cashback-range-item">
    <div class="cri__close">
      <i
        v-if="!rangeModel.freeze"
        :class="`icon icon-close cri__i-close${isRangeUnique || !getPermissionCreate ? '--disabled' : ''}`"
        @click="triggerRemoveRange"
      ></i>
    </div>

    <div class="cri__list-block">
      <div :class="['cri__index']">
        <span>{{ calcIndex }}</span>
      </div>

      <!-- personas -->
      <div class="cri__personas form-group">
        <label>{{ $t('360.cashback.ranges.personas') }}</label>
        <v-select
          v-model="rangeModel.persona"
          label="name"
          :clearable="false"
          class="cri__personas-input cri__input"
          :options="filterSelectedPersonas"
          :disabled="rangeModel.freeze || !getPermissionCreate"
          :placeholder="$tc('_select', 2)"
          @option:selected="onChangePersonas"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('360.sellers.no-results') }}
          </template>
        </v-select>
      </div>

      <!-- percent -->
      <div class="cri__accumulation form-group">
        <div>
          <label>{{ $t('360.cashback.ranges.accumulation') }}</label>
          <input
            v-model="rangeModel.percent"
            class="cri__input w-100"
            :disabled="rangeModel.freeze || !getPermissionCreate"
            @blur="validateValue"
            @focus="clearSymbols"
            @input="removeNotNumber($event)"
          />
        </div>
        <div v-if="isHightValueActive" class="cri__alert">
          <i class="icon icon-attention-12px"></i>
          <p class="m-0">{{ $t('360.cashback.ranges.high') }}</p>
        </div>
      </div>

      <div class="cri__persons-block">
        <div class="cri__persons-percentage">
          <p class="m-0">
            {{ rangeModel.persona.percent ? formatValues(rangeModel.persona.percent, '0.0%') : '0' }}
          </p>
        </div>
        <div class="cri__persons-total">
          <p class="m-0 text-lowercase">
            (<span>{{ rangeModel.persona.total ? rangeModel.persona.total : '0' }}</span> {{ $tc('_people', 2) }})
          </p>
        </div>
      </div>
    </div>

    <slot name="range-input-items"></slot>
  </div>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';
// helpers
import { formatNumber } from '@/utilities/formatters';

export default {
  name: 'CashbackRangeLine',
  components: {
    vSelect,
  },
  props: {
    rangeData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      max: 50,
      isHightValueActive: false,
    };
  },
  computed: {
    ...mapGetters('cashback', [
      'getPermissionCreate',
      'getPermissionView',
      'getCashbackRanges',
      'getPersonasSelectList',
    ]),

    isRangeUnique() {
      return this.getCashbackRanges.length === 1;
    },

    rangeModel() {
      return this.rangeData;
    },

    calcIndex() {
      return this.rangeModel.freeze ? this.getCashbackRanges.length + 1 : this.index + 1;
    },

    filterSelectedPersonas() {
      const personas = this.getCashbackRanges.map(el => el.persona.id);
      return this.getPersonasSelectList.filter(el => !personas.includes(el.id));
    },
  },
  mounted() {
    if (this.rangeModel?.percent !== null) this.setPercent();
  },
  methods: {
    ...mapActions('cashback', [
      'removeRange',
      'updateRangeProgressions',
      'setLastRangeLine',
      'verifyEmptyPersona',
      'setRangeHighValue',
    ]),

    onChangePersonas() {
      this.verifyEmptyPersona();
      this.setLastRangeLine();
    },

    validateValue() {
      if (this.rangeModel.percent >= this.max) {
        this.isHightValueActive = true;
      } else this.isHightValueActive = false;

      this.setRangeHighValue({
        index: this.index,
        status: this.isHightValueActive,
      });
      this.setPercent();
    },

    setPercent() {
      this.rangeModel.percent = formatNumber(this.rangeModel?.percent, '0.0%');
    },

    clearSymbols() {
      this.rangeModel.percent = this.rangeModel?.percent?.replace(/[^,0-9]+/g, '').trim();
    },

    removeNotNumber(event) {
      this.rangeModel.percent = event.target?.value?.replace(/[^,0-9]+/g, '');
    },

    triggerRemoveRange() {
      // prevent click to single range
      if (this.isRangeUnique) return;

      this.removeRange(this.index);
      this.setLastRangeLine();
    },

    formatValues(val, format) {
      return formatNumber(val, format);
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback-range-item {
  transition: all ease-out 0.2s;

  &:first-child {
    border: 1px solid $gray-400;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid $gray-400;
  }

  &:nth-child(n + 2) {
    border: 1px solid $gray-400;
    border-top: none;
  }

  &:nth-last-child(1) {
    border-radius: 0 0 10px 10px;
  }

  .cri__list-block {
    display: grid;
    grid-template-columns: 260px 150px 125px;
    grid-template-rows: 124px;
    align-items: center;
    justify-items: start;
    gap: 16px;
    padding: 0 20px;
    position: relative;

    .cri__index {
      display: flex;
      background-color: $color-white;
      font-weight: 600;
      height: 26px;
      position: absolute;
      width: 20px;
      z-index: 1;
      align-items: center;
      justify-content: center;
      left: -10px;
      top: 60px;

      &.disabled {
        cursor: not-allowed;
        color: $gray-400;
      }

      span {
        color: $gray-600;
      }
    }

    .cri__personas,
    .cri__all-base {
      width: 260px;
    }

    .cri__persons-block {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      height: 58px;
      justify-content: flex-end;
      .cri__persons-percentage {
        p {
          color: $oto-omni;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .form-group {
      margin: 0;
    }

    .cri__accumulation {
      position: relative;
      .cri__alert {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: -24px;
        width: 100%;
        p,
        .icon {
          font-weight: 600;
          color: $color-red;
          font-size: 11px;
        }
      }
    }
  }

  .cri__close {
    position: relative;
    width: 100%;
    z-index: 1;

    i.icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: $gray-500;

      &.cri__i-close {
        &--disabled {
          cursor: not-allowed;
          color: $gray-400;
        }
      }
    }
  }

  :slotted(.cri__add-range) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    background-color: $gray-300;
    border: 1px solid $gray-400;
    border-bottom: none;

    &.disabled {
      p {
        cursor: not-allowed;
        color: $gray-600;
        pointer-events: none;
      }
    }

    p {
      color: $oto-omni;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}
</style>

<style lang="scss">
.cashback-range-item {
  @import '@/assets/scss/vendors/_v-select';

  .cri__progression-input {
    .vs__selected-options {
      max-width: 96px;
    }
  }
}
</style>
