<template>
  <div class="table">
    <div v-if="$_verifyLoadedVuex('done')" class="table__header">
      <div class="table__row">
        <div v-for="item in historySerie" :key="item" :class="['table__column', resolveTextAlign(item)]">
          <div class="table__header-title" v-html="$t(`integrations.export-header.${item}`)"></div>
          <div
            :class="['table__order-by', { 'table__order-by--active': isActive === item }]"
            @click.prevent="ordenate(item)"
          >
            <span :class="['table__dash-arrows', isActive === item ? `table__dash-arrows--${orderType}` : '']"></span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$_verifyLoadedVuex('done')" class="table__body">
      <div v-for="(item, index) in tableData" :key="index" class="table__row">
        <div class="table__column text-center">
          <div class="table__status-wrapper">
            <div class="table__recurrent">
              <img
                v-show="Boolean(item.recurring)"
                src="@/assets/svgs/Recurrent.svg"
                v-tooltip.top-start="{
                  theme: 'default',
                  content: buildRecurrenceTitle(item),
                  html: true,
                  distance: 8,
                }"
              />
            </div>
            <div class="table__status-item">
              <span :class="[`table__status table__status--${item.statusKey}`]">
                {{ item.name.toLowerCase() }}
              </span>
            </div>
          </div>
        </div>
        <div class="table__column text-left table__connector">
          <span :class="{ pointer: isHovering }">
            {{ item.connector ? item.connector : '-' }}
          </span>
        </div>
        <div class="table__column table__export">
          {{ item.export ? item.export : '-' }}
        </div>
        <div class="table__column">
          <div class="table__icon-wrapper">
            <i class="icon icon-clock-18px mr-2"></i>
            {{ item.execTime ? item.execTime : '-' }}
          </div>
        </div>
        <div class="table__column">
          <div
            class="table__icon-wrapper"
            v-tooltip="{
              theme: 'default',
              content: `${item.exportDate ? $_rawDate(item.exportDate) : '-'}`,
              html: true,
              distance: 8,
            }"
          >
            <i class="icon icon-calendar-14px mr-2"></i>
            {{ item.exportDate ? $_formatDate(item.exportDate) : '-' }}
          </div>
        </div>
        <div class="table__column">
          <div class="table__icon-wrapper justify-content-end">
            <i class="icon icon-user mr-2"></i>
            {{ item.processedCount ? $_formatTotalCount(item.processedCount) : '-' }}
          </div>
        </div>
        <div class="table__column text-right">
          {{ item.size ? $_resolveValueUnit(item) : '-' }}
        </div>
        <menu-dropdown
          class="table__column table__dropdown"
          :label="item.scheduleId"
          :actions="actionsDropdown(item, modalId)"
        />
      </div>
    </div>
    <modal-dialog-question
      :id="modalId"
      :delete-action="triggerAction"
      :is-visible="isVisible"
      @closeDialogModal="isVisible = $event"
      :message="$t('integrations.do-you-want-continue')"
    />
    <base-loader v-show="$_verifyLoadedVuex('loading')" />
    <info-card v-show="$_verifyLoadedVuex('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getRunExport } from '@/services/integrations';
import { useToast } from 'vue-toastification';
// helpers
import { historyMixin, tableMixin, valueUnitMixin, verifyMixin } from '@/helpers/mixins';
import { openLightbox } from '@/helpers/lightbox';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import MenuDropdown from '@/components/_atoms/MenuDropdown.vue';
import ModalDialogQuestion from '@/components/_atoms/ModalDialogQuestion';

export default {
  name: 'HistoryExportTable',
  components: {
    MenuDropdown,
    BaseLoader,
    InfoCard,
    ModalDialogQuestion,
  },
  mixins: [historyMixin, tableMixin, valueUnitMixin, verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      order: null,
      orderType: 'asc',
      isActive: '',
      tableData: null,
      isHovering: false,
      modalId: 'connector-export',
      scheduleId: null,
      isCancelRecurrenceModalActive: false,
      isVisible: false,
    };
  },
  computed: {
    ...mapState('historyExport', ['historyData', 'historySerie', 'loadingGlobal', 'isEmptyGlobal', 'hasErrorGlobal']),
    ...mapGetters('historyExport', ['getHistoryData', 'getIsEmpty', 'getHasError', 'getLoading']),
  },
  beforeUpdate() {
    this.updateTable();
  },
  mounted() {
    this.resetSelected();
    this.getExportHistoryList();
    bus.$on('update-selected-brand', () => {
      this.callEventBusFunctions();
    });
    bus.$on('render-cards', () => {
      this.callEventBusFunctions();
    });
    bus.$on(`${this.modalId}-show-recurrence-confirm-modal`, scheduleId => {
      this.isVisible = true;
      this.isCancelRecurrenceModalActive = true;
      this.scheduleId = scheduleId;
    });
    bus.$on(`${this.modalId}-show-new-payload-confirm-modal`, scheduleId => {
      this.isVisible = true;
      this.scheduleId = scheduleId;
    });
    bus.$on(`${this.modalId}-open-connector-logs`, scheduleId => {
      openLightbox('lightbox-history-export', { scheduleId });
    });
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
    bus.$off('render-cards');
    bus.$off(`${this.modalId}-open-connector-logs`);
    bus.$off(`${this.modalId}-show-recurrence-confirm-modal`);
    bus.$off(`${this.modalId}-show-new-payload-confirm-modal`);
  },
  methods: {
    ...mapActions('historyExport', [
      'getExportHistoryList',
      'setPaginationOrder',
      'setNewPayload',
      'setCancelRecurrence',
      'resetSelected',
    ]),
    /**
     * @description Verify list order, filter and organize personas list
     */
    updateTable() {
      this.tableData = this.getHistoryData;
    },
    /**
     * @description Receive and send data for $_tableOrdination
     * @param {Object} data
     */
    ordenate(key) {
      // update internal table state with order type mixin
      const { order, orderType } = this.$_tableOrderType({
        key,
        order: this.order,
        orderType: this.orderType,
      });
      this.isActive = key;
      this.order = order;
      this.orderType = orderType;

      // increment key with desc to be sent by action
      const orderFormat = orderType === 'asc' ? key : `${key}Desc`;
      this.setPaginationOrder(orderFormat);
    },
    /**
     * @description add class to table headers
     * @param {String} key
     */
    resolveTextAlign(key) {
      let align;
      if (key === 'name') {
        align = 'text-center';
      }
      if (key === 'connector' || key === 'export' || key === 'execTime' || key === 'exportDate') {
        align = 'text-left';
      }
      if (key === 'processedCount' || key === 'size') {
        align = 'text-right d-flex justify-content-end align-items-center';
      }
      return align;
    },
    /**
     * @description trigger export vuex action
     */
    triggerAction() {
      if (this.isCancelRecurrenceModalActive) this.setCancelRecurrence(this.scheduleId);
      else this.fetchRunNewPayload();
      // reset modal type flag
      this.isCancelRecurrenceModalActive = false;
    },
    /**
     * @desription build tooltip title
     * @param {Object} item
     * @return {String}
     */
    buildRecurrenceTitle(item) {
      if (!item?.recurring) return;
      const time =
        item?.typeInterval === 'hour'
          ? item?.recurrenceInterval === 1
            ? this.$tc('integrations.hours', 1).toLowerCase()
            : this.$tc('integrations.hours', 2).toLowerCase()
          : this.$tc('integrations.day', 1).toLowerCase();
      const interval = item?.recurrenceInterval
        ? `${this.$t('integrations.each')} ${item?.recurrenceInterval} ${time}`
        : this.$t('integrations.exact');
      return `${this.$t('integrations.frequence')} - ${interval}`;
    },
    /**
     * @description fetch to request a new export
     */
    fetchRunNewPayload() {
      getRunExport({ scheduleId: this.scheduleId })
        .then(({ data }) => {
          if (!data.success) this.toast.error(this.$t('integrations.error-export'));
        })
        .then(() => this.getExportHistoryList());
    },

    callEventBusFunctions() {
      this.resetSelected();
      this.getExportHistoryList();
    },

    /**
     * @description actions to menu dropdown
     */
    handleRunNewPayload(connector) {
      return Boolean(connector.enabled) && connector.statusKey === 'error';
    },
    isCancelRecurrenceActive(connector) {
      return Boolean(connector.enabled) && Boolean(connector.recurring);
    },
    cancelRecurrence(connector, modalId) {
      bus.$emit(`${modalId}-show-recurrence-confirm-modal`, connector.scheduleId);
    },
    runNewPayload(connector, modalId) {
      bus.$emit(`${modalId}-show-new-payload-confirm-modal`, connector.scheduleId);
    },
    openConnectorLogs(connector, modalId) {
      bus.$emit(`${modalId}-open-connector-logs`, connector.scheduleId);
    },
    actionsDropdown(connector, modalId) {
      return [
        {
          name: 'integrations.action.log',
          action: () => this.openConnectorLogs(connector, modalId),
        },
        {
          name: 'integrations.action.run',
          action: () => this.runNewPayload(connector, modalId),
          hidden: !this.handleRunNewPayload(connector),
        },
        {
          name: 'integrations.action.cancel',
          action: () => this.cancelRecurrence(connector, modalId),
          hidden: !this.isCancelRecurrenceActive(connector),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  color: #555;
  font-size: 12px;
  height: auto;
  min-height: 630px;
  margin: 0;

  .table__header,
  .table__body {
    .table__row {
      display: grid;
      grid-template-rows: 55px;
      grid-template-columns: 18% 14% 14% 10% 14% 12% 10% 8%;
      align-items: center;
    }
  }

  .table__header {
    font-weight: 600;
    margin-bottom: 12px;
    .table__row {
      padding: 0 10px;
    }
  }

  .table__body {
    .table__row {
      grid-template-columns: 18% 14% 14% 10% 14% 12% 10% 8%;
      grid-auto-columns: 80px;
      padding: 0 10px;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: none;
      }

      .table__column {
        .table__status-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .table__recurrent {
            margin-left: 12px;
            min-width: 20px;
            img {
              cursor: pointer;
            }
          }
          .table__status-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65%;
          }
        }
        &.table__dropdown {
          display: grid;
          grid-column: 8;
          justify-content: right;
          padding-right: 10px;
        }
        .table__icon-wrapper {
          display: flex;
          align-items: center;
          cursor: context-menu;

          .icon {
            font-size: 14px;
          }

          &:hover {
            &[data-toggle='tooltip'] {
              cursor: pointer;
            }
          }
        }

        &.table__connector {
          width: 110px;
          max-width: 110px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          span {
            &.pointer {
              cursor: pointer;
            }
          }
        }

        &.table__export {
          width: 120px;
          overflow: hidden;
        }
      }
    }
  }

  .table__status {
    display: inline-block;
    padding: 0 7px;
    font-weight: 600;
    line-height: 22px;
    border: 1px solid;
    border-radius: 5px;

    &--success {
      color: $oto-ecommerce;
      border-color: $oto-ecommerce;
    }
    &--error,
    &--cancelled {
      color: $color-red;
      border-color: $color-red;
    }
    &--running {
      color: $color-orange;
      border-color: $color-orange;
    }
    &--scheduled {
      color: $oto-omni;
      border-color: $oto-omni;
    }
  }

  .table__header-title {
    display: inline-block;
    width: fit-content;
    white-space: initial;
    margin-right: 5px;
  }

  .table__order-by {
    background-color: $gray-400;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    padding: 3px 0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    text-align: center;

    .table__dash-arrows {
      display: block;
      color: $color-white;
      border: solid $gray-900;
      border-width: 0 1px 1px 0;
      border-radius: 0;
      display: inline-block;
      padding: 3px;
      transform: translate(0, -50%) rotate(45deg);

      &--asc {
        transform: translate(0, -50%) rotate(45deg);
      }
      &--desc {
        transform: rotate(-135deg);
      }
    }

    &--active {
      background-color: $oto-omni;

      .table__dash-arrows {
        border-color: $color-white;
      }

      .table__dash-arrows {
        &--asc {
          transform: translate(0, -50%) rotate(45deg);
        }
        &--desc {
          transform: rotate(-135deg);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.tooltip.export-recurring,
.tooltip.export-connector {
  top: -5px !important;
  div.tooltip-inner {
    padding: 0px 15px !important;
    height: 35px !important;
    line-height: 35px !important;
    max-width: 100% !important;
    min-width: 200px !important;
  }
  div.arrow {
    left: 18px !important;
  }
}

.tooltip.export-recurring {
  left: 74px !important;
}
.tooltip.export-connector {
  left: 85px !important;
}
.tooltip.export.export-date {
  left: -20px !important;
}
</style>
