<template>
  <campaigns-detail :page-history="pageHistory" />
</template>

<script>
import CampaignsDetail from '@/components/campaigns/detail/CampaignsDetail';

export default {
  name: 'CampaignsDetailsView',
  components: {
    CampaignsDetail,
  },
  props: {
    pageHistory: {
      type: Number,
      default: null,
    },
  },
};
</script>
