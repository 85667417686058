// libs
import { escapeRegExp, isEmpty } from 'lodash';
// helpers
import bus from '@/helpers/events/bus';
import { stringParser } from '@/helpers/string-parser';
// endpoints
import { getScopeValue } from '@/services/scope';
import { putTemplate, getMessages, deleteTemplate } from '@/services/actions';

const actionMessages = {
  namespaced: true,
  state: {
    // selected options
    moment: '',
    channel: '',
    scopeType: '',
    scopeValue: '',
    type: '',

    momentList: [
      { id: '', name: '360.actions.all' },
      { id: 'begin', name: '360.actions.start' },
      { id: 'middle', name: '360.actions.middle' },
      { id: 'end', name: '360.actions.end' },
    ],
    channelList: [
      { id: '', name: '360.actions.all' },
      { id: 'whatsapp', name: '_whatsapp' },
      { id: 'sms', name: '_sms' },
    ],
    scopeTypeList: [
      { id: '', name: '360.actions.all' },
      { id: 'brand', name: '_brand' },
      { id: 'action', name: '_action' },
      { id: 'campaign', name: '_campaign' },
      { id: 'store', name: '_store' },
    ],
    scopeList: [],

    series: ['text', 'channel', 'scope', 'createdAt', 'moment'],

    messagesList: [], // main table data

    isEmptyGlobal: false,
    loadingGlobal: false,
    hasErrorGlobal: false,
    // pagination data
    id: 'actions-messages',
    pagination: {
      count: 10,
      limit: 10,
      page: 1,
      order: '',
    },
    limitRange: [5, 10, 20, 30, 50],

    logIsEmpty: false,
    logLoading: false,
    logHasError: false,
  },
  mutations: {
    SET_MESSAGE_LIST(state, payload) {
      state.messagesList = payload;
      if (isEmpty(payload)) {
        state.isEmptyGlobal = true;
        state.loadingGlobal = false;
      } else state.isEmptyGlobal = false;
    },

    SET_MOMENT(state, payload) {
      state.moment = payload;
    },
    SET_CHANNEL(state, payload) {
      state.channel = payload;
    },
    SET_SCOPE_TYPE(state, payload) {
      state.scopeType = payload;
    },
    SET_SCOPE_VALUE(state, payload) {
      state.scopeValue = payload;
    },

    SET_SCOPE_LIST(state, payload) {
      state.scopeList = payload;
    },

    SET_LOADING(state, isLoading) {
      state.loadingGlobal = isLoading;
    },
    SET_ERROR(state, hasError) {
      state.hasErrorGlobal = hasError;
    },

    SET_EXPORT_TOTALCOUNT(state, total) {
      state.pagination.count = total;
    },
    SET_PAGINATION_ORDER(state, order) {
      state.pagination.order = order;
    },

    RESET_PAGINATION(state) {
      state.pagination.page = 1;
    },
    RESET_SELECTED(state) {
      state.moment = '';
      state.channel = '';
      state.scopeType = '';
      state.scopeValue = '';
    },

    RESET_SCOPES(state) {
      state.scopeList = [];
      state.scopeValue = '';
    },
  },
  actions: {
    // update table
    getMessagesListData({ commit, state }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', false);
      getMessages({
        moment: state.moment,
        channel: state.channel,
        scope: state.scopeType,
        scopeValue: state.scopeValue,
        page: state.pagination.page,
        limit: state.pagination.limit,
        orderBy: state.pagination.order,
      })
        .then(({ data }) => {
          if (!data.totalCount && (state.moment || state.channel || state.scopeType || state.scopeValue)) {
            commit('SET_ERROR', true);
            return;
          }
          commit('SET_MESSAGE_LIST', data?.data);
          commit('SET_EXPORT_TOTALCOUNT', data?.totalCount);
        })
        .catch(() => {
          commit('SET_ERROR', true);
          commit('SET_LOADING', false);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    // update scope value list
    getScopeListData({ commit, state }) {
      if (state.scopeType != '') {
        getScopeValue({
          type: state.scopeType,
          scope: state.scopeType,
        }).then(response => {
          const data = response?.data;
          if (data && data.success) commit('SET_SCOPE_LIST', data?.data);
        });
      }
    },

    setMoment({ commit, dispatch }, payload) {
      commit('SET_MOMENT', payload);
      commit('RESET_PAGINATION');
      dispatch('getMessagesListData');
    },
    setChannel({ commit, dispatch }, payload) {
      commit('SET_CHANNEL', payload);
      commit('RESET_PAGINATION');
      dispatch('getMessagesListData');
    },
    setScopeType({ commit, dispatch }, payload) {
      commit('RESET_SCOPES');

      commit('SET_SCOPE_TYPE', payload);
      commit('RESET_PAGINATION');
      dispatch('getMessagesListData');
      dispatch('getScopeListData');
    },
    setScopeValue({ commit, dispatch }, payload) {
      commit('SET_SCOPE_VALUE', payload);
      commit('RESET_PAGINATION');
      dispatch('getMessagesListData');
    },

    setPaginationOrder({ commit, dispatch }, order) {
      commit('SET_PAGINATION_ORDER', order);
      dispatch('getMessagesListData');
    },

    setScopeList({ commit }, payload) {
      commit('SET_SCOPE_LIST', payload);
    },

    resetSelected({ commit }) {
      commit('RESET_SELECTED');
    },
    refreshTableData({ dispatch }) {
      dispatch('getMessagesListData');
    },

    disableMessage({ commit, dispatch }, templateId) {
      deleteTemplate({ templateId })
        .then(({ data }) => {
          if (!data.success) {
            commit('SET_ERROR', true);
            return;
          }
          bus.$emit('actions-message-toast-delete');
        })
        .catch(() => {
          commit('SET_ERROR', true);
          commit('SET_LOADING', false);
        })
        .finally(() => dispatch('refreshTableData'));
    },
  },
  getters: {
    getFilteredTerm:
      state =>
      (term = '') => {
        if (!term) return state.messagesList;
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.messagesList.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getScopeType: state => state.scopeType,
    getScopeList: state => state.scopeList,

    getMessagesList: state => state.messagesList,
    // Verify Mixin Getters
    getLoading: state => state.loadingGlobal,
    getIsEmpty: state => state.isEmptyGlobal,
    getHasError: state => state.hasErrorGlobal,
  },
};

export default actionMessages;
