import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URL = 'connector';
const AUTH = 'oauth2';

/**
 * @description retrieves all available connectors
 * @params https://labs.pmweb.com.br/docs/context-v3/#api-Connect-getConnectors
 */
export const getConnectors = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`/${URL}/getConnectors`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description get connector by id
 * @params https://labs.pmweb.com.br/docs/context-v3/#api-Connect-getConnector
 */
export const getConnector = id => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`/${URL}/getConnector`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description create or edit a instance
 * @param {Number} connectorID id of connector found in getInstances || getConnectors endpoint
 * @param {Array} options array of options in json notation to set params of instance and incremental data if required [refresh_token, etc]
 * full possible params in https://labs.pmweb.com.br/docs/context-v3/#api-Connector-putInstance
 */
export const putInstance = (connectorID, options) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    `/${URL}/putInstance`,
    {
      brand: brand.id,
      connectorID,
      options,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @description enable or disable instance (configuration)
 * @param {Number} id (of instance) [found in getInstances[x].instances[x].id]
 * @param {Boolean} enabled to enable or disable instance
 * full possible params in https://labs.pmweb.com.br/docs/context-v3/#api-Connector-putInstance
 */
export const toggleInstance = (id, enabled) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    `/${URL}/putInstance`,
    {
      brand: brand.id,
      id,
      enabled,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};

/**
 * Retrieves fields from connector
 * @params https://labs.pmweb.com.br/docs/context-v3/#api-Connect-getFields
 */
export const getFields = (alias, enabled = false, instanceId) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`/${URL}/getFields`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      alias,
      enabled,
      instanceId,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Retrieves all instances
 * @params https://labs.pmweb.com.br/docs/context-v3/#api-Connect-getInstances
 */
export const getInstances = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`/${URL}/getInstances`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 *
 * Retrieve state information after a successful OAuth2 flow
 * @params https://labs.pmweb.com.br/docs/context/#api-OAuth2-get_state
 */
export const getState = state => {
  return axios.get(`/${AUTH}/get_state`, {
    params: {
      state,
    },
  });
};
