<template>
  <div class="suggestion-details-campaigns">
    <div class="sdc__header">
      <h3 class="m-0">{{ $tc('_campaign', 2) }}</h3>
      <button v-show="hasCampaign" class="btn btn-link sdc__add-campaign" @click="openModal">
        {{ $t('campaigns.create.add-campaign') }}
      </button>
    </div>

    <template v-if="hasCampaign">
      <div class="sdc__table customScrollBar">
        <div class="table">
          <div class="table__header">
            <div class="table__row">
              <div class="table__column">
                <div class="table__header-title">
                  {{ $t('name') }}
                </div>
              </div>
              <div class="table__column">
                <div class="table__header-title">{{ $tc('_audience', 1) }}</div>
              </div>
              <div class="table__column">
                <div class="table__header-title">{{ $t('campaigns.create.people-audience') }}</div>
              </div>
              <div class="table__column text-right">
                <div class="table__header-title">{{ $t('campaigns.create.limits') }}</div>
              </div>
              <div class="table__column text-right">
                <div class="table__header-title">{{ $t('campaigns.create.status') }}</div>
              </div>
            </div>
          </div>

          <div class="table__body">
            <div v-for="(item, index) in campaignsList" :key="item.id" class="table__row">
              <div :title="item.name" :class="['table__column text-left']">
                {{ item.name }}
              </div>
              <div :title="item.audience ? item.audience.name : '-'" :class="['table__column text-left']">
                <a
                  v-if="item.audience"
                  :href="`/${getAudienceNameRoute}/view/${this.$store.getters.user.id}/${item.audience.id}`"
                  target="_blank"
                  >{{ item.audience.name }}</a
                >
                <p v-else>-</p>
              </div>
              <div
                :class="['table__column']"
                :title="
                  item.audience?.count ? formatValues(item.audience?.count) : $t('360.actions.check-marketing-cloud')
                "
              >
                {{
                  item.audience?.count === 0 && item.audience?.id === 0
                    ? $t('360.actions.check-marketing-cloud')
                    : formatValues(item.audience?.count)
                }}
              </div>

              <VTooltip :class="['sdc__tooltip table__column']">
                <a>{{ typeText(item.limitType) }}</a>

                <template #popper>
                  <div class="content">
                    <div class="title">
                      <span>{{ typeText(item.limitType) }}</span>
                    </div>
                    <div>
                      <span v-if="item.limitType !== 'store_seller'">
                        {{ `${resolveContent(item)} ${$tc('_suggestions', 2)}` }}
                      </span>
                      <span v-else v-html="resolveContentStoreSeller(item)"></span>
                    </div>
                  </div>
                </template>
              </VTooltip>

              <div class="table__column">
                <span>
                  <span class="bol" :class="`status-${item.status}`"></span>
                  {{ $t(`campaigns.create.status-code.${item.status}`) }}
                </span>
              </div>

              <div class="table__column">
                <campaigns-management-details-campaign-dropdown
                  :campaign="item"
                  :enabled="item.status"
                  :index="index"
                  :hasSuggestions="item.hasSuggestions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="!hasCampaign">
      <div class="sdc__empty-wrapper">
        <p class="m-0">
          {{ $t('campaigns.create.empty-campaigns') }}
        </p>
        <button class="btn btn-link sdc__add-campaign" @click="openModal">
          {{ $t('campaigns.create.create-campaign') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
import '@/plugins/tooltip/tooltip';
import { formatNumber } from '@/utilities/formatters';
import { validateAudienceActivationRoute } from '@/helpers/verify-routes';
import CampaignsManagementDetailsCampaignDropdown from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementDetailsCampaignDropdown';

export default {
  name: 'CampaignsManagementDetailsCampaigns',
  components: {
    CampaignsManagementDetailsCampaignDropdown,
  },
  computed: {
    ...mapGetters('campaignsManagement', ['getSuggestionDetail', 'getLimitsSelectOptions']),

    campaignsList() {
      return this.getSuggestionDetail.campaigns;
    },
    hasCampaign() {
      return this.campaignsList?.length;
    },
    getAudienceNameRoute() {
      return validateAudienceActivationRoute('audiences-activation') ? 'audiences' : 'new-audiences';
    },
  },
  methods: {
    ...mapActions('campaignsManagement', ['updateCampaigns']),

    openModal() {
      bus.$emit('open-suggestion-detail-campaigns-modal');
    },

    formatValues(val) {
      return formatNumber(val);
    },
    typeText(typeId) {
      const pathI18n = this.getLimitsSelectOptions[typeId];
      return pathI18n ? this.$t(pathI18n) : '-';
    },
    resolveContent(item) {
      return item?.limitData?.limitValue ? item?.limitData?.limitValue : 0;
    },
    resolveContentStoreSeller(item) {
      const contentLimitValue = item.limitData
        .map(
          (item, index) =>
            `<p>
                <b>${this.$t('group')} ${index + 1}:</b>
                ${item.limitValue} ${this.$tc('campaigns.detail.suggestion', 2)}
              </p>`,
        )
        .join('');
      const title = `
          <div class="content">
            <div class="title">
            <div>
            <p>${contentLimitValue}</p>
          </div>`;
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-details-campaigns {
  margin: 40px 0;

  .sdc__table {
    overflow-y: scroll;
    padding-top: 59px;
  }

  .table {
    font-size: 12px;
    margin: 0;

    .table__header,
    .table__body {
      .table__row {
        color: $gray-800;
        display: grid;
        grid-template-rows: 40px;
        grid-template-columns: 32% 20% 20% 12% 10% 6%;
        justify-items: start;
        align-items: center;
      }
      .tooltip-overview {
        cursor: pointer;
      }
    }

    .table__header {
      font-weight: 600;
      .table__row {
        border-bottom: 1px solid $gray-300;
      }
    }

    .table__body {
      .table__row {
        grid-auto-columns: 80px;
        border-bottom: 1px solid $gray-400;

        &:last-child {
          border-bottom: none;
        }

        .table__column {
          padding-right: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 95%;

          &:first-child {
            padding-left: 1px;
          }

          span {
            .bol {
              border-radius: 50%;
              display: inline-block;
              height: 9px;
              width: 9px;
              margin-right: 10px;
            }

            .status-0 {
              background: $gray-500;
            }

            .status-1 {
              background: #35ba9b;
            }

            .status-2 {
              background: $pink-500;
            }
          }
        }
      }
    }

    .table__header-title {
      display: inline-block;
      width: fit-content;
      white-space: initial;
      margin-right: 5px;
    }

    .table__order-by {
      background-color: $gray-400;
      border-radius: 5px;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      line-height: 100%;
      padding: 3px 0;
      height: 18px;
      width: 18px;
      min-width: 18px;
      text-align: center;

      .table__dash-arrows {
        display: block;
        color: $color-white;
        border: solid $gray-900;
        border-width: 0 1px 1px 0;
        border-radius: 0;
        display: inline-block;
        padding: 3px;
        transform: translate(0, -50%) rotate(45deg);
      }

      &--active {
        background-color: $oto-seller;

        .table__dash-arrows {
          border-color: $color-white;
        }

        .table__dash-arrows {
          &--asc {
            transform: translate(0, -50%) rotate(45deg);
          }
          &--desc {
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  .sdc__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .sdc__empty-wrapper {
    display: flex;
    align-items: center;
  }

  button.sdc__add-campaign {
    font-weight: normal;
    text-decoration: underline;
  }
}
.sdc__tooltip {
  cursor: pointer;
}
.v-popper--theme-tooltip .v-popper__inner {
  .content {
    display: block;
    justify-content: center;

    .title {
      padding-bottom: 10px;

      span {
        color: $gray-800;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}
</style>
