<template>
  <section class="sellers-analytics">
    <!-- header -->
    <select-seller-header />
    <!-- metrics -->
    <metrics-blocks />
    <!-- conversion and suggestions -->
    <div class="row">
      <seller-funnel />
      <seller-suggestion />
    </div>
    <!-- comparable -->
    <comparable-sellers />

    <base-loader v-show="loadingExport" color="colored-block" class="render-loader" />
  </section>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
// helpers
import { verifyMixin, chartMixin } from '@/helpers/mixins';
// store
import { mapActions } from 'vuex';
// components
import ComparableSellers from '@/components/oto/sellersAnalytics/_organisms/ComparableSellers';
import SelectSellerHeader from '@/components/oto/sellersAnalytics/_organisms/SelectSellerHeader';
import MetricsBlocks from '@/components/oto/sellersAnalytics/_organisms/MetricsBlocks';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import SellerFunnel from '@/components/oto/sellersAnalytics/_organisms/SellerFunnel';
import SellerSuggestion from '@/components/oto/sellersAnalytics/_organisms/SellerSuggestion';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'SellersAnalytics',
  components: {
    ComparableSellers,
    SelectSellerHeader,
    BaseLoader,
    MetricsBlocks,
    SellerFunnel,
    SellerSuggestion,
  },
  mixins: [verifyMixin, chartMixin],
  data() {
    return {
      hasError: false,
      loadingExport: false,
    };
  },
  mounted() {
    useMixpanelConfig('view_oto_sellers_analytics');
    this.setAvailableMetrics();
    this.setSellersResumeList();
    bus.$on('update-selected-brand', () => {
      this.setSellersResumeList();
    });
    bus.$on('change-seller', () => {
      this.callFunctionsOnBus();
    });
    bus.$on('render-cards', () => {
      this.callFunctionsOnBus();
    });
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
  },
  beforeUnmount() {
    bus.$off('change-seller');
    bus.$off('render-cards');
    bus.$off('update-selected-brand');
  },
  methods: {
    ...mapActions('sellersAnalytics', [
      'clearParallelCoordinates',
      'setSellerMetrics',
      'setSellerFunnel',
      'setSellerSuggestions',
      'setParallelCoordinates',
      'setAvailableMetrics',
      'setSellersResumeList',
    ]),

    callFunctionsOnBus() {
      this.setSellerMetrics();
      this.setSellerFunnel();
      this.setSellerSuggestions();
      this.clearParallelCoordinates();
    },
  },
};
</script>

<style lang="scss" scoped>
.sellers-analytics {
  .render-loader {
    background-color: $gray-900;
    height: 100%;
    left: 50%;
    opacity: 0.7;
    overflow: hidden;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 104;
  }
}
</style>

<style lang="scss">
.sellers-analytics {
  @import '@/assets/scss/vendors/_v-select';

  .v-select {
    min-width: 300px;
    .vs__selected {
      min-width: 260px;
    }
  }
}
</style>
