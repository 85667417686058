<template>
  <div v-if="propData" id="minibarchart" class="minibarchart" :class="`preset-${presetId}`">
    <div v-if="showText" class="chart-title">{{ propData.title }}</div>
    <template v-if="presetId === 1">
      <div v-for="(item, index) in visibleItems" :key="'minibarline-' + index">
        <span class="bar-chart-text text-overflow">{{ item.text }}</span>
        <div class="d-flex justify-content-left align-items-center">
          <minibar v-if="propData" :item="item" self-width />
          <span class="bar-chart-value" type="number" style="white-space: nowrap; margin-left: 5px">
            {{ item.amount + ' ' + propData.suffix }}
          </span>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(item, index) in visibleItems"
        :key="'minibarline-' + index"
        class="lineBar d-flex justify-content-center align-items-center"
      >
        <div v-if="showText" class="divText d-flex justify-content-between">
          <span class="bar-chart-text text-overflow">{{ item.text }}</span>
          <span class="bar-chart-value" type="number" style="white-space: nowrap"
            >{{ item.amount + ' ' + propData.suffix }}
          </span>
        </div>
        <minibar v-if="propData" :item="item" />
      </div>
    </template>
  </div>
</template>

<script>
import Minibar from '@/components/MiniBar';

export default {
  name: 'MiniBarChart',
  components: {
    Minibar,
  },
  props: {
    propData: {
      type: [Array, Object],
      default: null,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    presetId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    visibleItems() {
      return this.propData.data ? this.propData.data.filter(item => !item.hidden) : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.lineBar {
  width: 100%;
  height: 10px;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.divText {
  width: 70%;
  padding-right: 10px;
}

.bar-tooltip {
  display: none;
  position: absolute;
  bottom: 18px;
  right: 0;
  background-color: $color-white;
  font-size: 12px;
  color: $gray-800;
  line-height: 20px;
  padding: 12px 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  &:before {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $color-white;
    bottom: -8px;
  }
  strong {
    font-weight: 600;
  }
}

.percentage {
  width: 30%;
  height: 8px;
  &:after {
    content: '';
    display: block;
    background-color: #6c90f4;
    width: 50px;
    margin-bottom: 10px;
    height: 100%;
    position: relative;
    top: 0;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    &:after {
      background-color: #aaa;
    }
  }
}

@for $i from 0 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 1%);
      width: $value;
      transition: width 500ms cubic-bezier(0.25, 0.1, 0.25, 0.1);
    }
  }
  .colorBar-on {
    &:after {
      background-color: $oto-ecommerce;
    }
  }
  .colorBar-off {
    &:after {
      background-color: $purple-200;
    }
  }
  .colorBar-omni {
    &:after {
      background-color: $oto-omni;
    }
  }
  .colorbase-orange {
    &:after {
      background-color: $color-orange;
    }
  }
  .colorbase-red {
    &:after {
      background-color: $color-red;
    }
  }
  .colorbase-green {
    &:after {
      background-color: $oto-ecommerce;
    }
  }
  .colorbase-darkblue {
    &:after {
      background-color: $blue-800;
    }
  }
  .colorbase-purple {
    &:after {
      background-color: $oto-in-store;
    }
  }
}

.minibarchart {
  &.preset-1 {
    .chart-title {
      display: none;
    }
    // #minibar {
    //   width: 70%;
    // }
  }
}
</style>
