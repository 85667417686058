<template>
  <div class="card">
    <div v-if="$_verifyLoaded('done') && ready" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-money"></i>
        <span>{{ $t('stores-total-revenue-title') }}</span>
      </h2>
      <div class="row chart-group">
        <div class="col-6">
          <label class="chart-title">{{ $t('stores-total-revenue-title') }}</label>
          <span class="chart-value-big color-oto-brand-omni d-block">
            {{ formatCurrency(metrics.revenue.data.value.amount) }}
          </span>
        </div>
        <div class="col-6">
          <label class="chart-title">{{ $t('avg-order-value') }}</label>
          <span class="chart-value-big color-oto-brand-omni d-block">
            {{ formatCurrency(metrics.avg_order_value.data.value.amount) }}
          </span>
        </div>
      </div>
      <div class="row chart-group">
        <div class="col">
          <chart-list :chart-name="$t('highest-revenue-categories')" :rows-list="objectChartListRevenue" />
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import _ from 'lodash';

import getMetrics from '@/services/getmetrics';

import bus from '@/helpers/events/bus';
import { getPercentual, getMax } from '@/utilities/numbers';
import { formatCurrency } from '@/utilities/formatters';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

import ChartList from '@/components/_organisms/ChartList';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'StoresTotalRevenue',
  components: {
    BaseLoader,
    InfoCard,
    'chart-list': ChartList,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      metrics: {},
      ready: false,
      selectedStore: '',
      objectChartListRevenue: [],
    };
  },
  computed: {
    storeType() {
      return this.selectedStore == '-100' ? 'on' : 'off'; // eslint-disable-line
    },
  },
  mounted() {
    bus.$on('store-changed', value => {
      this.selectedStore = value;
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('store-changed');
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        endpoint: 'stores/get_metrics',
        metrics: ['revenue', 'avg_order_value', 'best_seller_categ'],
        filters: `store_id:${this.selectedStore},store_type:${this.storeType}`,
        limit: '5',
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          // get response
          this.metrics = response.data.metrics;
          const bestSellers = this.metrics.best_seller_categ.data;
          //  clean data
          this.objectChartListRevenue = [];
          // mount lists
          const maxRevenue = getMax(bestSellers);
          // iterate to fill chart bars
          _.each(bestSellers, (val, key) => {
            this.objectChartListRevenue.push({
              label: key,
              progress: String(getPercentual(val, maxRevenue)),
              value: formatCurrency(val, { shortNumber: true, max: 1 }),
            });
          });

          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
