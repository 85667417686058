import axios from 'axios';
import moment from 'moment';
import store from '../store';

/*
  @args {
    metrics = '',
    filters = '',
    startdate = '',
    enddate = '',
    segments = 1,
    version = 1,
    topvalues = ''
  }
*/
export default ({
  endpoint = '/metrics/get_metrics',
  metrics = '',
  filters = '',
  startdate = '',
  enddate = '',
  segments = '',
  version = 1,
  topvalues = '',
  limit = '',
  scale = '',
  expand = '',
  locale = 'pt-BR',
  campaign = '',
  dash = '',
  tbl = '',
  isLightbox = false,
} = {}) => {
  const brand = store.getters.selectedBrand;
  const segment = store.getters.selectedSegment;
  const { user } = store.getters;
  let { segmentId } = segment;
  let { startDate } = store.getters.dates;
  let { endDate } = store.getters.dates;
  const personaId = store.getters.selectedPersona?.id;
  const filterId = store.getters.selectedFilter;

  if (startdate && enddate) {
    startDate = moment(startdate).format('YYYY-MM-DD');
    endDate = moment(enddate).format('YYYY-MM-DD');
  }

  segmentId = segments || segmentId;
  if (filters.includes('store_id') && !isLightbox) segmentId = 1;

  return axios.get(endpoint, {
    params: {
      locale: user.language || locale,
      brand: brand.id,
      m: metrics.toString(),
      start_date: startDate,
      end_date: endDate,
      segment: segmentId,
      persona: personaId,
      order_filters: filterId,
      v: version,
      campaign_id: campaign,
      dash,
      tbl,
      ...(filters && { filter: filters }),
      ...(topvalues && { top: topvalues }),
      ...(limit && { limit }),
      ...(scale && { scale }),
      ...(expand && { expand }),
    },
  });
};
