<template>
  <table-list :data="dataTable" class="loadHeight" type="filled">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column><modal-dates /></table-list-column>
        <table-list-column class="text-right">{{ $t('clients') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('new') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('inactive') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('recovered') }}</table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row
          v-for="(item, index) in dataTable.rows"
          :key="index"
          :click-callback="() => $_handleTableRow(item.segment_id)"
          :class="{ active: item.segment_id == segmentSelected }"
        >
          <table-list-column> <i class="icon icon-filter"></i> {{ item.name }} </table-list-column>
          <table-list-column class="text-right">{{ $_formatDecimal(item.metrics.customers_total) }}</table-list-column>
          <table-list-column class="text-right">{{ $_formatDecimal(item.metrics.customers_new) }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics.customers_inactive)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics.customers_regained)
          }}</table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" :empty="isEmpty" />
  </table-list>
</template>

<script>
import getTable from '@/services/getTable';
import ModalDates from '@/components/ModalDates';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'RetentionTable',
  components: {
    ModalDates,
    InfoCard,
    BaseLoader,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataTable: {
        count: 0,
        rows: [],
      },
      segmentSelected: '',
      chartClientVariation: undefined,
    };
  },
  mounted() {
    this.reset();
    this.fetchData();
  },
  methods: {
    fetchData() {
      getTable({
        metrics: ['customers_total', 'customers_new', 'customers_inactive', 'customers_regained'],
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          if (!this.dataTable.count) this.isEmpty = true;
          if (this.$_verifyHasData(response.data.data)) return;
          this.dataTable.rows = response.data.data;
          this.dataTable.count = this.dataTable.rows.length;
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$_reqConfig();
      this.ready = false;
      this.dataTable.rows = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
