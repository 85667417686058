<template>
  <div class="welcome__contact">
    <p class="contact-msg" v-html="$t('welcome.contact.msg')"></p>
    <icon-base :color="'#6c90f4'" :box="'0 0 51 52'" w="51" h="52">
      <icon-contact />
    </icon-base>
    <p class="contact-more" v-html="$t('welcome.contact.more-info')"></p>
  </div>
</template>

<script>
import IconBase from '@/assets/vue-icons/IconBase';
import IconContact from '@/assets/vue-icons/icons/Contact';

export default {
  name: 'WelcomeContact',
  components: {
    IconBase,
    IconContact,
  },
};
</script>

<style lang="scss" scoped>
.welcome__contact {
  color: #555555;
  margin: 60px auto;
  text-align: center;

  .contact-msg {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    padding-bottom: 30px;
  }
  .contact-more {
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    margin: 0;
    padding-top: 24px;
  }
}
</style>
