import bus from '@/helpers/events/bus';
import moment from 'moment';

const mixin = {
  methods: {
    $_fullSearch(term) {
      if (!term) return;
      this.$router.push({ path: '/products/search', query: { term } });
      bus.$emit('clear-search');
    },
    $_openProduct(id) {
      this.$router.push({ name: 'product', params: { id } });
      bus.$emit('clear-search');
    },
    /**
     * Get last X days from the actual date
     * @param {number} days
     */
    $_getSubtractedDate(days) {
      const dateEnd = moment(this.$store.getters.dates.endDate, 'YYYY-MM-DD');
      return dateEnd.subtract(days, 'days').format('YYYY-MM-DD');
    },
  },
};

export default mixin;
