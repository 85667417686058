<template>
  <div class="steps-controller">
    <ul class="nav nav-tabs" :class="type">
      <li v-for="step in visibleSteps" :key="step.id" class="nav-item">
        <a
          v-if="isFull"
          class="nav-link d-flex align-items-center"
          :class="{
            active: step.id === selectedStep,
            error: step.id === 2 && !isConnectValid,
          }"
          href="#"
          @click.prevent="change(step.id)"
          :data-cy="step.name"
        >
          <i v-if="step.id === 2 && !isConnectValid && !isView" class="icon icon-attention-12px"></i>
          <i v-if="!isView" class="rounded-circle mr-2">{{ step.id }}</i>
          <span>{{ $t(step.name) }}</span>
        </a>
        <span v-else class="nav-link rounded-circle mr-2" :class="{ active: selectedStep >= step.id }">
          {{ step.id }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'AudienceStepsController',
  props: {
    type: {
      type: String,
      default: 'full',
    },
  },
  data() {
    return {
      hasError: false,
    };
  },
  computed: {
    ...mapState('audience', [
      'selectedStep', // -> this.selectedStep
      'people', // -> this.people
    ]),
    ...mapGetters('audience', [
      'isView', // -> this.isView
      'isConnectValid', // -> this.isConnectValid
    ]),
    isSimple() {
      return this.type === 'simple';
    },
    isFull() {
      return this.type === 'full';
    },
    steps() {
      return [
        {
          id: 1,
          name: 'audiences.composition',
          visible: true,
        },
        {
          id: 2,
          name: 'audiences.integration.title',
          visible: true,
        },
        {
          id: 3,
          name: this.isView ? 'audiences.historic' : 'audiences.control-groups',
          visible: this.isView,
        },
      ];
    },
    visibleSteps() {
      return this.steps.filter(step => step.visible);
    },
  },
  methods: {
    ...mapActions('audience', [
      'changeStep', // -> this.changeStep()
    ]),
    change(id) {
      let isValid = true;
      if (this.selectedStep === 1 && !this.people && !this.isView) isValid = false;
      if (isValid) {
        this.changeStep(id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-tabs {
  border: 0;
  .nav-link {
    font-size: 12px;
    padding-bottom: 23px;
    border: 0;
    position: relative;
    .rounded-circle {
      background-color: $gray-500;
      color: $color-white;
      width: 24px;
      line-height: 24px;
      font-weight: 600;
      text-align: center;
      font-style: initial;
    }
    .icon-attention-12px {
      position: absolute;
      font-size: 12px;
      color: $color-red;
      left: 50px;
      top: 0px;
    }
    span {
      color: $gray-700;
      font-weight: 600;
    }
    &:hover,
    &.active {
      border: 0;
      background-color: transparent;
    }
  }
  &.full {
    .nav-item {
      .nav-link {
        padding-left: 30px;
        padding-right: 30px;
        &.active {
          border-bottom: 3px solid $oto-omni;
          .rounded-circle {
            background-color: $oto-omni;
          }
          span {
            color: $oto-omni;
          }
        }
        &.error {
          .rounded-circle {
            background-color: $color-red;
          }
          &.active {
            border-bottom-color: $color-red;
            span {
              color: $color-red;
            }
          }
        }
      }
      &:first-child {
        .nav-link {
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
  &.simple {
    position: relative;

    .nav-item {
      padding-right: 20px;
      .nav-link {
        padding: 0;
        width: 36px;
        line-height: 36px;
        color: $gray-600;
        font-weight: 600;
        text-align: center;
        font-style: initial;
        background-color: $gray-300;
        margin: 0 !important;
        &.active {
          color: $color-white;
          background-color: $oto-seller;
        }
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}
</style>
