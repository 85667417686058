<template>
  <el-dialog v-model="isVisible" id="personas-edit-description-modal" append-to-body>
    <div class="content">
      <h2>{{ $t('personas.description-title') }}</h2>
      <p>
        {{ $t('personas.edit-description.describe') }}
      </p>
      <div class="textarea-container">
        <label>{{ $t('personas.description-title') }}</label>
        <textarea v-model="description" :placeholder="$t('personas.edit-description.describe-add')" />
      </div>
      <div class="button-container">
        <button class="btn" @click="setDescription">
          {{ $t('labels-personas.save-description') }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'EditDescriptionModal',
  data() {
    return {
      description: '',
      isVisible: false,
    };
  },
  computed: {
    ...mapState('personas', ['personaDetail']),
  },
  mounted() {
    bus.$on('openEditPersonaDescriptionModal', () => {
      this.openModal();
    });
  },
  methods: {
    ...mapActions('personas', ['actionSetDescription']),
    setDescription() {
      this.actionSetDescription(this.description);
      this.closeModal();
    },
    openModal() {
      this.description = this.personaDetail.description;
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
      this.description = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: -25px 50px 0;
  h2 {
    margin-bottom: 17px;
  }
  p {
    font-size: 12px !important;
    font-weight: 400 !important;
    text-align: left !important;
  }
  .textarea-container {
    display: flex;
    flex-direction: column;
    margin-top: 27px;
    textarea {
      border-radius: 15px;
      height: 85px;
      resize: none;
    }
  }
  .button-container {
    margin-top: 17px;
    button {
      margin: auto;
      width: 180px;
      height: 40px;
    }
  }
}
</style>

<style lang="scss">
#personas-edit-description-modal {
  max-width: 440px;
}
</style>
