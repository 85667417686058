<template>
  <div class="table-list-column col" :class="`${className} ${type}`">
    <span :class="{ elipsis: elipsis }">
      <slot />
    </span>
    <!-- Order -->
    <a
      :data-cy="'table-list-column-order-by-' + order"
      v-if="order"
      href="#"
      class="order-by"
      :class="{ active: isOrder }"
      @click.prevent="orderBy"
    >
      <span class="pg-arrows" :class="isDesc ? 'up-arrow' : 'down-arrow'"></span>
    </a>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';

export default {
  props: {
    className: {
      type: String,
      default: null,
    },
    order: {
      type: String,
      default: null,
    },
    elipsis: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    id() {
      let $current = this.$parent;

      while (!$current.id) $current = $current.$parent;

      return $current.id;
    },
    type() {
      let $current = this.$parent;

      while (!$current.type) $current = $current.$parent;

      return $current.type;
    },
    data() {
      let $current = this.$parent;

      while (!$current.data) $current = $current.$parent;

      return $current.data;
    },
    isOrder() {
      return this.data.order && this.data.order.replace('_desc', '') === this.order;
    },
    isDesc() {
      return this.isOrder && this.data.order.indexOf('_desc') > -1;
    },
  },
  methods: {
    orderBy() {
      if (this.isDesc) this.data.order = this.order;
      else if (this.isOrder) this.data.order = `${this.order}_desc`;
      else this.data.order = this.order;
      bus.$emit(`${this.id}-pagination-reset`);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-column {
  span {
    font-size: 12px;

    :slotted(.info) {
      font-weight: 300;
      font-size: 11px;
      margin-left: 9px;
    }

    &.elipsis {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.normal {
    font-weight: 600;
  }

  .order-by {
    background-color: $gray-400;
    border-radius: 5px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    padding: 3px 0;
    height: 18px;
    width: 18px;
    text-align: center;

    &.active {
      background-color: $oto-omni;

      .pg-arrows {
        border-color: $color-white;
      }
    }
  }

  .dropdown {
    :slotted(.dropdown-toggle) {
      cursor: pointer;
      text-decoration: none;

      &:after {
        display: none;
      }

      .icon {
        font-size: 25px;
        color: $gray-600;
        display: block;
      }
    }

    :slotted(.dropdown-menu) {
      min-width: 100px;
      box-shadow: -1px 2px 9px rgba(0, 0, 0, 0.25);
      border: 0 none;
      border-radius: 0;
      padding: 0;
      transform: translate3d(0, 25px, 0) !important;

      .dropdown-item {
        font-size: 11.46px;
        padding: 3px 12px;
        color: $gray-600;
        line-height: 22px;
        font-weight: 600;
        cursor: pointer;

        &:hover:not(.disabled) {
          background-color: $gray-400;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
