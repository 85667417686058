import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

type TopFiveRanking = {
  segments: string;
  filters: string;
};

type TopSalesRankingDetail = {
  segments: string;
  storeId: string;
  orderBy?: string;
  limit?: number;
  page?: number;
  term?: string;
  fileType?: string;
  responseType?: string;
};

export const getTopSellers = ({ segments = '', filters = '' }: TopFiveRanking) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;
  const persona = store.getters.selectedPersona;
  const filterId = store.getters.selectedFilter;
  const segment = store.getters.selectedSegment;
  let { segmentId } = segment;

  segmentId = segments || segmentId;
  if (filters.includes('storeId')) segmentId = 1;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'seller/ranking/getTopSellers',
    params: {
      brand: brand.id,
      startDate,
      endDate,
      persona,
      orderFilters: filterId,
      segment: segmentId,
      ...(filters && { filter: filters }),
    },
  });
};

export const getTopSellersDetails = ({
  segments = '',
  storeId,
  orderBy,
  limit,
  page,
  fileType,
  term,
  responseType,
}: TopSalesRankingDetail) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;
  const persona = store.getters.selectedPersona;
  const filterId = store.getters.selectedFilter;
  const segment = store.getters.selectedSegment;
  let { segmentId } = segment;

  segmentId = segments || segmentId;
  if (storeId.includes('storeId')) segmentId = 1;

  return axios.get('/seller/ranking/getTable', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate,
      endDate,
      persona,
      orderFilters: filterId,
      segment: segmentId,
      filter: storeId,
      orderBy,
      limit,
      page,
      term,
      export: fileType,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getTopCustomersDetails = ({
  segments = '',
  storeId,
  orderBy,
  limit,
  page,
  fileType,
  term,
  responseType,
}: TopSalesRankingDetail) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;
  const persona = store.getters.selectedPersona;
  const filterId = store.getters.selectedFilter;
  const segment = store.getters.selectedSegment;
  let { segmentId } = segment;

  segmentId = segments || segmentId;
  if (storeId.includes('storeId')) segmentId = 1;

  return axios.get('/customer/ranking/getTable', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate,
      endDate,
      persona,
      orderFilters: filterId,
      segment: segmentId,
      filter: storeId,
      orderBy,
      limit,
      page,
      term,
      export: fileType,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getTopCustomers = ({ segments = '', filters = '' }: TopFiveRanking) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;
  const persona = store.getters.selectedPersona;
  const filterId = store.getters.selectedFilter;
  const segment = store.getters.selectedSegment;
  let { segmentId } = segment;

  segmentId = segments || segmentId;
  if (filters.includes('storeId')) segmentId = 1;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: 'customer/ranking/getTopCustomers',
    params: {
      brand: brand.id,
      startDate,
      endDate,
      persona,
      orderFilters: filterId,
      segment: segmentId,
      ...(filters && { filter: filters }),
    },
  });
};
