<template>
  <div class="dash-push-table">
    <base-card-v2>
      <el-dialog class="dialog-metrics" v-model="dialogVisible" fullscreen height="70vh" width="100%">
        <template #title>
          <div class="card-title d-flex align-items-center">
            <i class="icon icon-table-30px"></i>
            <h2 class="m-0">{{ $t('metrics') }}</h2>
          </div>
          <div class="config-options-header">
            <div class="btn-options">
              <v-select
                id="dash-metrics-select"
                v-model="selectedOption"
                label="name"
                class="select-dash-metrics"
                :clearable="false"
                :reduce="item => item.id"
                :options="triggerPresetFormat(tablePresets)"
                :placeholder="$t('select')"
                @option:selected="fetchData"
              >
                <template #option="{ name }">
                  {{ $t(`${name}`) }}
                </template>
                <template #selected-option="{ name }">
                  {{ $t(`${name}`) }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes" class="arrow-down"></span>
                </template>
                <template #no-options>
                  {{ $t('no-results') }}
                </template>
              </v-select>
              <header-datepicker :user="user" :language="user?.language" />
            </div>

            <div class="btn-options">
              <div class="export-wrapper">
                <el-dropdown>
                  <el-button round>
                    {{ $t('dashboard.more-options') }}
                    <i class="icon ml--4 icon-down-14px"></i>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item disabled>
                        {{ $t('dashboard.options') }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="openEditColumnsModal">
                        {{ $t('dashboard.edit-columns.title') }}
                      </el-dropdown-item>
                      <el-dropdown-item disabled divided>
                        {{ $t('dashboard.export-data') }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('csv', selectedOption)">
                        {{ $t('dashboard.export', { value: '.CSV' }) }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('xls', selectedOption)">
                        {{ $t('dashboard.export', { value: '.XLS' }) }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('xlsx', selectedOption)">
                        {{ $t('dashboard.export', { value: '.XLSX' }) }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>
        </template>
        <el-table
          v-if="!$_verifyLoaded('loading') && tableData.rows.length"
          :data="tableData.filtered"
          :scrollbar-always-on="true"
          :cell-class-name="tableRowClassName"
          :header-cell-class-name="tableHeaderClassName"
          style="width: 100%"
          height="80vh"
          empty-text=" "
          stripe
          :show-summary="!!tableData.totalResults"
          :summary-method="getSummary"
        >
          <template v-for="(column, index) in getColumns" :key="index">
            <el-table-column
              sortable
              :formatter="formatter"
              :prop="column.key"
              :label="column.label"
              :min-width="getWidth(column.key)"
            >
              <template v-if="getColumnsToSearch.no_label.includes(column.key)" #header>
                <div class="el-table__column">
                  <label> {{ getLabel(column.key) }} </label>
                  <el-popover trigger="hover" placement="bottom" :width="260">
                    <el-input
                      v-model="search[column.key]"
                      v-mask="getMask(column.key)"
                      :placeholder="getPlaceholder(column.key)"
                      size="small"
                      @keyup.enter="searchByColumn(column.key, search[column.key], index)"
                    />
                    <div style="text-align: right">
                      <el-button
                        :disabled="!search[column.key]"
                        round
                        size="small"
                        text
                        @click="clearFilter(column.key, index)"
                      >
                        {{ search[column.key] ? 'limpar filtro' : 'cancelar' }}
                      </el-button>
                      <el-button
                        :disabled="!search[column.key]"
                        round
                        size="small"
                        type="primary"
                        @click="searchByColumn(column.key, search[column.key], index)"
                        >pesquisar</el-button
                      >
                    </div>
                    <template #reference>
                      <i
                        :class="{ 'color-oto-brand-omni': columnsFiltered.includes(column.key) }"
                        class="icon icon-search-12px ml--4"
                      ></i>
                    </template>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <base-loader v-if="$_verifyLoaded('loading')" />
        <info-card
          v-if="
            ($_verifyLoaded('info') && !isCustomTable) || (!tableData.filtered.length && !$_verifyLoaded('loading'))
          "
          ref="message-error"
          :error="hasError && !isCustomTable"
        />
      </el-dialog>
      <div v-if="$_verifyLoaded('done')">
        <base-card-header :title="$t('metrics')" icon="icon-table-30px" />
        <div class="config-options-header">
          <v-select
            id="dash-metrics-select"
            v-model="selectedOption"
            label="name"
            class="select-dash-metrics"
            :clearable="false"
            :reduce="item => item.id"
            :options="triggerPresetFormat(tablePresets)"
            :placeholder="$t('select')"
            @option:selected="fetchData"
          >
            <template #option="{ name }">
              {{ $t(`${name}`) }}
            </template>
            <template #selected-option="{ name }">
              {{ $t(`${name}`) }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('no-results') }}
            </template>
          </v-select>

          <div class="btn-options">
            <div class="export-wrapper">
              <el-dropdown>
                <el-button round>
                  {{ $t('dashboard.more-options') }}
                  <i class="icon ml--4 icon-down-14px"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item disabled>
                      {{ $t('dashboard.options') }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="openEditColumnsModal">
                      {{ $t('dashboard.edit-columns.title') }}
                    </el-dropdown-item>
                    <el-dropdown-item disabled divided>
                      {{ $t('dashboard.export-data') }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="resolveExport('csv', selectedOption)">
                      {{ $t('dashboard.export', { value: '.CSV' }) }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="resolveExport('xls', selectedOption)">
                      {{ $t('dashboard.export', { value: '.XLS' }) }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="resolveExport('xlsx', selectedOption)">
                      {{ $t('dashboard.export', { value: '.XLSX' }) }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <button ref="button" class="btn export" @click="dialogVisible = true">
                {{ $t('dashboard.expand-table') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <el-table
        v-show="!$_verifyLoaded('loading') && tableData.rows.length"
        :data="tableData.filtered"
        :scrollbar-always-on="true"
        :cell-class-name="tableRowClassName"
        :header-cell-class-name="tableHeaderClassName"
        :row-class-name="rowClassName"
        style="width: 100%"
        stripe
        empty-text=" "
        :show-summary="!!tableData.totalResults"
        :summary-method="getSummary"
      >
        <template v-for="(column, index) in getColumns" :key="index">
          <el-table-column
            sortable
            :formatter="formatter"
            :prop="column.key"
            :label="column.label"
            :min-width="getWidth(column.key)"
          >
            <template v-if="getColumnsToSearch.no_label.includes(column.key)" #header>
              <div class="el-table__column">
                <label> {{ getLabel(column.key) }} </label>
                <el-popover trigger="hover" placement="bottom" :width="260">
                  <el-input
                    v-model="search[column.key]"
                    v-mask="getMask(column.key)"
                    :placeholder="getPlaceholder(column.key)"
                    size="small"
                    :ref="column.key"
                    @keyup.enter="searchByColumn(column.key, search[column.key], index)"
                  />
                  <div style="text-align: right">
                    <el-button
                      round
                      size="small"
                      text
                      @click="clearFilter(column.key, index)"
                      :disabled="!search[column.key]"
                    >
                      {{ search[column.key] ? 'limpar filtro' : 'cancelar' }}
                    </el-button>
                    <el-button
                      :disabled="!search[column.key]"
                      round
                      size="small"
                      type="primary"
                      @click="searchByColumn(column.key, search[column.key], index)"
                      >pesquisar</el-button
                    >
                  </div>
                  <template #reference>
                    <i
                      :class="{ 'color-oto-brand-omni': columnsFiltered.includes(column.key) }"
                      class="icon icon-search-12px ml--4"
                    ></i>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <dashboard-edit-columns-modal :page="'push'" />
      <base-loader v-if="$_verifyLoaded('loading')" />
      <info-card
        v-if="($_verifyLoaded('info') && !isCustomTable) || (!tableData.filtered.length && !$_verifyLoaded('loading'))"
        ref="message-error"
        :error="hasError && !isCustomTable"
      />
    </base-card-v2>
  </div>
</template>

<script>
import FileDownload from 'js-file-download';
import moment from 'moment';
import vSelect from 'vue-select';
import { mapState, mapGetters, mapActions } from 'vuex';
import vClickOutside from 'click-outside-vue3';

import { getTableMetrics, getTablePresets } from '@/services/dashboard';

import bus from '@/helpers/events/bus';
import { dashboardMixin, tableMixin, verifyMixin, chartMixin } from '@/helpers/mixins';
import { dateFormatYMDHms, valueType } from '@/utilities/constants';

import DashboardEditColumnsModal from '@/components/dashboard/metricsTable/DashboardEditColumnsModal';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import HeaderDatepicker from '@/components/menu/_organisms/HeaderDatepicker';

export default {
  name: 'DashboardPushMetricsTable',
  components: {
    BaseLoader,
    DashboardEditColumnsModal,
    InfoCard,
    vSelect,
    HeaderDatepicker,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin, chartMixin],
  data() {
    return {
      selectedOption: 'date',
      tablePresets: {},
      headerFixedWidth: 0,
      headerFixed: false,
      isActive: null,
      limit: 10,
      dialogVisible: false,
      order: null,
      visible: {
        page: [],
        modal: [],
      },
      search: {},
      noMask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ },
        },
      },
      orderType: 'asc',
      orderDefault: [],
      tableHeader: [],
      columnsFiltered: [],
      tableData: {
        rows: [],
        filtered: [],
        totalResults: [],
      },
      isExportVisible: false,
      isCustomTable: false,
    };
  },
  computed: {
    ...mapState('dashboard', ['presets']),
    ...mapGetters(['user']),
    ...mapGetters({
      getLastConfigMetrics: 'dashboard/getLastConfigMetrics',
      getAllCheckedMetrics: 'dashboard/getAllCheckedMetrics',
    }),

    getColumns() {
      return this.tableHeader.map(column => ({
        key: column.key,
        label: column.name,
        type: column.type,
        threshold: column.threshold,
        isMetric: column.isMetric,
      }));
    },

    getColumnsToSearch() {
      return {
        no_label: this.getColumns.filter(column => !column.isMetric).map(c => c.key),
        with_label: this.getColumns.filter(column => !column.isMetric).map(c => ({ key: c.key, label: c.label })),
      };
    },
  },
  mounted() {
    this.selectedOption = valueType.date;
    this.fetchData(this.selectedOption);
    this.fetchTablePresets();

    bus.$on('render-cards', () => {
      this.selectedOption = valueType.date;
      this.fetchData(this.selectedOption);
    });

    bus.$on('dashboard-update-table', () => {
      this.fetchCheckedMetrics();
    });

    bus.$on('changeSelectedOption', newSelectedOption => {
      if (newSelectedOption.id == 'dash-metrics-select') {
        this.selectedOption = newSelectedOption.index;
        this.fetchData(this.selectedOption);
      }
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('dashboard-update-table');
    bus.$off('changeSelectedOption');
  },
  methods: {
    ...mapActions('dashboard', [
      'updatePresets',
      'updateCheckedMetrics',
      'updateCheckedDimensions',
      'updateFilterMetrics',
      'updateFilterDimensions',
      'updateLastCustomConfig',
    ]),
    getLabel(key) {
      return this.getColumnsToSearch.with_label.filter(column => column.key === key)[0]['label'];
    },
    getMask(column) {
      const mask = {
        date: '##/##/####',
        launch_date: '##/##/####',
        launch_time: '##:##:##',
      };

      const hasMask = Object.keys(mask).includes(column);
      return hasMask ? mask[column] : this.noMask;
    },

    getPlaceholder(column) {
      const placeholder = {
        date: '01/01/2000',
        launch_date: '01/01/2000',
        launch_time: '01:01:01',
      };

      const hasPlaceholder = Object.keys(placeholder).includes(column);
      return hasPlaceholder ? placeholder[column] : 'Busque nesta coluna';
    },

    getWidth(column) {
      const width = {
        campaign: 200,
        date: 120,
        launch_date: 120,
        folder_name: 240,
        program_name: 240,
        program_stage: 240,
        marketing_program: 200,
        marketing_strategy: 160,
        ext_campaign_id: 160,
        account_id: 210,
        segment: 170,
      };

      const hasWidth = Object.keys(width).includes(column);
      return hasWidth ? width[column] : 140;
    },

    /**
     * @description formats the filter date to that of the pattern that comes from the api
     * @param {date} 12/12/2012
     * @returns 2012-12-12
     */
    formatDate(date) {
      const spplitedDate = date.split('/');
      return position => {
        if (position !== 0) {
          return spplitedDate.length > position ? `${spplitedDate[position]}-` : '';
        }
        return spplitedDate[position];
      };
    },

    /**
     * @description gets the result of the formatted date
     * @param {date} 12/12/2012
     * @returns 2012-12-12
     */
    getFormattedDate(date) {
      const getPosition = this.formatDate(date);
      return `${getPosition(2)}${getPosition(1)}${getPosition(0)}`;
    },
    equalizeValue(value) {
      return String(value).toLowerCase();
    },

    /**
     * @description filters table data for the searched value in a specific column
     * @param {column} Ex: date
     * @param {value} Ex: 12/12/2012
     */
    searchByColumn(column, value) {
      this.loading = true;
      this.columnsFiltered.push(column);

      let result = value;

      if (column === 'date') {
        result = this.getFormattedDate(value);
      }

      this.tableData.filtered = this.restoreActiveFilters();
      this.tableData.filtered = this.tableData.filtered.filter(row =>
        this.equalizeValue(row[column]).includes(this.equalizeValue(result)),
      );

      this.loading = false;
    },

    /**
     * @description searches the table again for the filters that are still active
     */
    restoreActiveFilters() {
      const getActiveFilters = Object.fromEntries(Object.entries(this.search).filter(result => !!result[1]));

      if (Object.prototype.hasOwnProperty.call(getActiveFilters, 'date')) {
        getActiveFilters.date = this.getFormattedDate(getActiveFilters.date);
      }

      const keys = Object.keys(getActiveFilters);

      const result = [];
      for (const row of this.tableData.rows) {
        const verifyIfIsSameValueInActiveFilters = keys.every(key =>
          this.equalizeValue(row[key]).includes(this.equalizeValue(getActiveFilters[key])),
        );

        if (verifyIfIsSameValueInActiveFilters) {
          result.push(row);
        }
      }

      return result;
    },

    /**
     * @description clears the filter for a specific column
     */
    clearFilter(column, index) {
      this.loading = true;
      this.columnsFiltered = this.columnsFiltered.filter(col => col !== column);
      this.search[column] = '';

      this.tableData.filtered = this.restoreActiveFilters();

      this.visible[index] = false;
      this.loading = false;
    },
    tableRowClassName({ row, column, columnIndex }) {
      const threshold = this.getColumns[columnIndex]['threshold'];
      const isMetric = this.getColumns[columnIndex]['isMetric'] ? 'metric' : 'dimension';

      return `threshold ${isMetric} ${this.$_checkThreshold({
        amount: row[column.property],
        threshold,
      })}`;
    },
    tableHeaderClassName({ columnIndex }) {
      const isMetric = this.getColumns[columnIndex]['isMetric'] ? 'metric' : 'dimension';
      return isMetric;
    },
    formatter(row, column) {
      const typeColumn = this.getColumns.filter(col => col.key === column.property)[0]['type'];
      const typeValue = column.property === 'date' || column.property === 'launch_date' ? 'date' : typeColumn;
      return this.$_getValue({ type: typeValue, amount: row[column.property] });
    },
    /**
     * @description main fetch data method checks if has custom preset
     * @param {object} preset object with select options
     */
    fetchData(preset) {
      const presetData = typeof preset === 'object' ? preset.id : preset;

      if (this.isActive == '' || this.isNil(this.isActive)) {
        this.isActive = this.orderDefault;
        this.order = this.orderDefault;
      }
      this.$_reqConfig();
      this.isCustomTable = false;

      // case custom preset has been selected get last config options
      presetData === 'custom' ? this.fetchLastConfig() : this.fetchTableMetrics(presetData);
    },

    getSummary() {
      const withValue = Array.isArray(this.tableData.totalResults) && this.tableData.totalResults.length;

      if (withValue) {
        const totalResults = this.tableData.totalResults[0];

        const result = this.getColumns
          .map(column => ({
            ...column,
            total: this.$_getValue({ type: column.type, amount: totalResults[column.key] }),
          }))
          .filter(column => column.key !== 'date');

        return [this.$t('dashboard.table.cols.total'), ...result.map(value => value.total || '-')];
      }
    },

    /**
     * @description fetch data table
     * @param {object} preset object with select options
     */
    fetchTableMetrics(preset) {
      getTableMetrics({ dashboard: 'push', preset })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.tableData.filtered = [];
            this.tableData.rows = [];
            return;
          }

          this.columnsFiltered = [];
          this.search = {};

          this.checkedDefaultMetricsAndDimensions(data);
          this.tableHeader = data.series;
          this.tableData.rows = data.data;
          this.tableData.filtered = data.data;
          this.tableData.totalResults = data.totalResults;
          this.tableData.totalCount = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          if (this.$_dashVerifyIsActive) {
            this.order = this.isActive;
            this.$_dashOrderBy(this.isActive);
          }
          this.loading = false;
        });
    },

    /**
     * @description Verify if is fetchCustomExportData or fetchExportData
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    resolveExport(fileType, preset) {
      if (preset == 'custom') this.fetchCustomExportData(fileType, preset);
      else this.fetchExportData(fileType, preset);
    },

    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    fetchExportData(fileType, preset) {
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getTableMetrics({
        dashboard: 'push',
        fileType,
        preset,
        responseType: 'blob',
      })
        .then(({ data }) => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset}-${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },

    /**
     * @description fetch custom data to export file
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    fetchCustomExportData(fileType, preset) {
      const { metrics, dimensions } = this.getAllCheckedMetrics;
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getTableMetrics({
        dashboard: 'push',
        fileType,
        preset: '',
        metrics,
        dimensions,
        responseType: 'blob',
      })
        .then(({ data }) => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset}-${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },

    /**
     * @description fetch after submit custom data options
     * @param {array} metrics
     * @param {array} dimensions
     */
    fetchCustomTable(metrics, dimensions) {
      this.$_reqConfig();
      this.isCustomTable = true;

      getTableMetrics({
        dashboard: 'push',
        preset: '',
        ...metrics,
        ...dimensions,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.tableData.filtered = [];
            this.tableData.rows = [];
            return;
          }
          this.tableHeader = data.series;
          this.tableData.rows = data.data;
          this.tableData.filtered = this.tableData.rows;
          this.tableData.totalResults = data.totalResults;

          const custom = { custom: this.$t('dashboard.custom') };

          // update dropdown with custom option
          this.selectedOption = 'custom';

          // update table presets with new custom object
          this.tablePresets = { ...this.tablePresets, ...custom };

          // update presets in vuex state
          this.updatePresets(this.tablePresets);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          if (this.$_dashVerifyIsActive) {
            this.order = this.isActive;
            this.$_dashOrderBy(this.isActive);
          }
          this.loading = false;
        });
    },

    /**
     * @description fetch data to fill select options
     */
    fetchTablePresets() {
      getTablePresets({ dashboard: 'push' })
        .then(({ data }) => {
          this.tablePresets = data?.presets;
        })
        .catch(() => {
          this.hasError = true;
        });
    },

    /**
     * @description checked and filtered default metrics and dimensions on DashboardEditColumnsModal
     */
    checkedDefaultMetricsAndDimensions(data) {
      const initialMetricsFiltered = data?.series.filter(el => el.isMetric);
      const initialMetricsChecked = initialMetricsFiltered.map(item => item.key);

      this.updateCheckedMetrics(initialMetricsChecked);
      this.updateFilterMetrics(initialMetricsFiltered);

      const initialDimensionsFiltered = data?.series.filter(el => !el.isMetric);
      const initialDimensionsChecked = initialDimensionsFiltered.map(item => item.key);

      this.updateCheckedDimensions(initialDimensionsChecked);
      this.updateFilterDimensions(initialDimensionsFiltered);

      this.updateLastCustomConfig();
    },

    /**
     * @description fetch data after select custom table options
     */
    fetchCheckedMetrics() {
      const { metrics, dimensions } = this.getAllCheckedMetrics;
      this.fetchCustomTable({ metrics, dimensions });
    },

    onChangeOption(value) {
      this.loading = true;
      if (value.id === 'edit-columns') {
        this.openEditColumnsModal();
      }
      this.loading = false;
    },

    /**
     * @description fetch last search until new search be concluded
     */
    fetchLastConfig() {
      const { metrics, dimensions } = this.getLastConfigMetrics;
      this.fetchCustomTable({ metrics, dimensions });
    },

    /**
     * @description toggle export button
     */
    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },

    /**
     * @description emit event to open dash columns editor
     */
    openEditColumnsModal() {
      bus.$emit('open-dashboard-edit-columns');
    },

    /**
     * @description checks if the tooltip needs to show up
     * @param {Object} el
     * @param {String} key
     */
    validateTooltip(el, key, event) {
      const offsetWidth = event?.target?.offsetWidth;
      const maxLengthEllipsis = 300;
      return el.key === 'campaign' && !this.isEmptyValue(key) && offsetWidth >= maxLengthEllipsis;
    },

    /**
     * @description apply tooltip just on campaigns with ellipsis
     * @param {Object} el
     * @param {String} key
     * @param {Object} event
     */
    checksTooltip(el, key, event) {
      if (this.validateTooltip(el, key, event)) return key;
    },

    /**
     * @description format presets
     */
    triggerPresetFormat(presets) {
      return this.$_formatToArrayOfObjects(presets);
    },
  },
};
</script>

<style lang="scss" scoped>
.dash-push-table {
  .card {
    min-height: 300px;

    .v-select {
      &.vs--open {
        z-index: 3;
      }
    }
  }
  .btn-secondary {
    padding: 0 15px;
  }
}
.colored-block {
  &.loading-export {
    position: fixed;
  }
}
</style>

<style lang="scss">
.tooltip.tooltip-dashboard .tooltip-inner {
  min-width: fit-content;
  max-width: max-content;
  text-align: center;
}

.dash-push-table {
  @import '@/assets/scss/vendors/_v-select';

  .select-dash-metrics {
    width: 150px;
  }
}
</style>
