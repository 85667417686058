<template>
  <div :id="id" class="grid-list" :class="type">
    <div class="grid-list-items">
      <slot />
    </div>

    <footer-skeleton v-if="loading" />
    <grid-list-footer
      v-if="data.count && !loading"
      :type="type"
      :data="data"
      :limit-range="limitRange"
      :identify="id"
    />
  </div>
</template>

<script>
import ListPaginationSkeleton from '@/components/_atoms/ListPaginationSkeleton';
import ListPagination from '@/components/_atoms/ListPagination';

export default {
  components: {
    'grid-list-footer': ListPagination,
    'footer-skeleton': ListPaginationSkeleton,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      limitRange: [8, 12, 16, 20],
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  color: $gray-800;
}
</style>

<style lang="scss">
.grid-list {
  $product-size: 276px;
  .grid-list-items {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: $product-size $product-size $product-size $product-size;
  }
  .pg-arrows {
    display: block;
    color: #fff;
    border: solid $gray-900;
    border-width: 0 1px 1px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;

    &.previous-arrow {
      transform: rotate(135deg);
      margin-right: -3px;
    }

    &.next-arrow {
      transform: rotate(-45deg);
      margin-left: -3px;
    }

    &.down-arrow {
      transform: translate(0, -50%) rotate(45deg);
    }

    &.up-arrow {
      transform: rotate(-135deg);
    }
  }
}
</style>
