<template>
  <el-dialog v-model="isVisible" id="seller-detail" width="680">
    <template v-if="!loading">
      <h2>
        {{ $t(`360.sellers.${isNewSeller ? 'new-seller' : 'edit-seller'}`) }}
      </h2>
      <div class="fields">
        <!-- fullname -->
        <div class="wrap field">
          <label>{{ $t('360.sellers.name') }}</label>
          <input
            v-model="fields.fullName"
            :placeholder="$t('360.sellers.seller-fullname')"
            data-cy="form-seller-fullname"
            :disabled="hasSso"
          />
        </div>
        <!-- name -->
        <div class="wrap field">
          <label>{{ $t('360.sellers.name-label') }}</label>
          <input
            v-model="fields.name"
            :placeholder="$t('360.sellers.name-placeholder')"
            data-cy="form-seller-name"
            :disabled="hasSso"
          />
        </div>
        <!-- enrolment -->
        <div class="wrap field">
          <label>{{ $t('360.sellers.enrolment') }}</label>
          <input
            v-model="fields.enrolment"
            :placeholder="$t('360.sellers.enrolment')"
            :disabled="hasSso"
            data-cy="form-seller-enrolment"
            @blur="fetchVerifyEnrolment"
          />
          <div v-if="isLoadingMessage" class="message_loader">
            <base-loader :height="'25'" />
          </div>
          <span v-show="enrolmentMessage" class="message">{{ enrolmentMessage }}</span>
        </div>
        <!-- store -->
        <div class="wrap field">
          <label>{{ $t('360.sellers.store') }}</label>
          <v-select
            :key="selectedStoreId"
            v-model="fields.storeId"
            :value="selectedStoreId"
            label="name"
            :clearable="false"
            :input-id="`stores-select`"
            data-cy="form-seller-stores-select"
            class="w-100"
            :options="getOrderedStores"
            :reduce="store => store.id"
            :placeholder="$t('360.sellers.placeholder')"
            :disabled="Boolean(fields.isAdmin) || hasSso"
            @option:selected="storeChanged"
          >
            <template #option="{ name, id }">
              <span>{{ resolveStoreName(id, name) }}</span>
            </template>
            <template #selected-option="{ name, id }">
              <span>{{ resolveStoreName(id, name) }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </div>
        <!-- associated account -->
        <div class="wrap field">
          <label>{{ $t('360.sellers.associated-account') }}</label>
          <v-select
            v-model="fields.userId"
            label="email"
            :clearable="false"
            :input-id="`stores-account-email`"
            data-cy="form-seller-email"
            class="sellers-email-list w-100"
            :reduce="email => email.userId"
            :options="getAvailableEmails"
            :placeholder="$t('360.sellers.type-context-account')"
            :loading="getEmailLoadingStatus"
            :disabled="hasSso"
            @search="setEmailSearch"
            @option:selected="setUserEmail"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #spinner="{ loading }">
              <div v-if="loading" style="border-left-color: #999" class="vs__spinner"></div>
            </template>
            <template #selected-option="{ userId }">
              <!-- get first email on the list -->
              <span v-if="getAvailableEmails[0] && !getAvailableEmails.find(el => el.userId === userId)">
                {{ getAvailableEmails[0].email }}
              </span>
              <!-- get user email if has associated account -->
              <span v-else-if="fields.userEmail">
                {{ fields.userEmail }}
              </span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </div>
        <!-- access type -->
        <div class="wrap field">
          <label>{{ $t('360.sellers.access-type') }}</label>
          <v-select
            v-model="fields.isAdmin"
            label="name"
            :clearable="false"
            :input-id="`stores-access-type`"
            class="w-100"
            :reduce="admin => admin.id"
            :options="[
              { id: 0, name: this.$t('360.sellers.seller') },
              { id: 1, name: 'Admin' },
            ]"
            :placeholder="$t('360.sellers.placeholder')"
            :disabled="!permissionType || hasSso"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </div>
        <!-- status -->
        <div class="wrap field seller__status">
          <label>{{ $t('360.sellers.status') }}</label>
          <v-select
            v-model="fields.enabled"
            label="name"
            :clearable="false"
            class="w-100"
            data-cy="form-select-status"
            :reduce="enabled => enabled.id"
            :options="statusList"
            :placeholder="$t('360.sellers.placeholder')"
            @option:selected="onChangeStatus"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </div>
      </div>
      <!-- pin -->
      <div v-if="!hasSso" class="pin-wrapper">
        <div class="wrap pin-container-button">
          <button class="btn btn-pin" data-cy="btn-pin" @click.prevent="resetPin">
            {{ getCustomTextPinButton() }}
          </button>
        </div>
        <div v-if="pin.length" class="wrap pin-container-text">
          <p class="pin-text">
            {{ pin }}<a href="#" @click="copyText">{{ $t('360.sellers.copy') }}</a>
          </p>
          <input ref="inputPin" type="hidden" :value="pin" />
          <p v-html="$t('360.sellers.store-the-number-message')"></p>
        </div>
      </div>
      <div v-show="fields.status && fields.status !== 'active' && !fields.isAdmin" class="row inactive-seller-wrapper">
        <div v-if="customerDistribution && fields.status === 'inactive'">
          <p class="seeler-text-1">
            {{ $t('360.sellers.seller') }}
            <span :class="fields.status">{{ $t(`360.sellers.${fields.status}`) }}</span>
          </p>
          <p :class="`seeler-text-${fields.status}-leveling`">
            {{ $t(`360.sellers.${fields.status}-seller-leveling`) }}
          </p>
        </div>
        <div class="wrap" v-else>
          <div>
            <p class="seeler-text-1">
              {{ $t('360.sellers.seller') }}
              <span :class="fields.status">{{ $t(`360.sellers.${fields.status}`) }}</span>
            </p>
            <p :class="`seeler-text-${fields.status}`">
              {{ $t(`360.sellers.${fields.status}-seller`) }}
            </p>
          </div>
          <div v-if="fields.status === 'inactive'">
            <label>{{ $t('360.sellers.alternate') }}</label>
            <v-select
              v-model="fields.alternate"
              label="name"
              :clearable="false"
              :input-id="`sellers-select`"
              class="responsible"
              :options="[{ id: null, name: $t('360.sellers.random') }, ...alternativeSellers]"
              :reduce="store => store.id"
              :placeholder="$t('360.sellers.placeholder')"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('360.sellers.no-results') }}
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <!-- submmit -->
      <div class="row button-submit-wrapper">
        <div class="wrap">
          <button class="btn" data-cy="btn-save-seller" :disabled="isDisabledSubmit()" @click.prevent="commitChange">
            {{ $t('360.sellers.save') }}
          </button>
        </div>
      </div>
    </template>
    <base-loader v-show="loading" />
  </el-dialog>
</template>

<script>
// libs, helpers
import { useToast } from 'vue-toastification';
import bus from '@/helpers/events/bus';
import vSelect from 'vue-select';
import { status, profileType } from '@/utilities/constants';
import { some, debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
// services
import {
  getSeller,
  getActiveSellers,
  generatePin,
  putSeller,
  setAlternateSeller,
  getVerifyEnrolment,
} from '@/services/oto/sellers';
import store from '@/store';
import { orderBy, map } from 'lodash';

// components - atoms
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'SellerDetail',
  components: {
    vSelect,
    BaseLoader,
  },
  props: {
    sellerId: {
      type: [String, Number],
      default: undefined,
      required: true,
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      sellerDetail: {},
      storeId: null,
      fields: {
        name: '',
        fullName: '',
        storeId: [],
        enrolment: '',
        enabled: 1,
        status: 'active',
        alternate: null,
        userId: null,
        userEmail: null,
        isAdmin: 0,
      },
      statusList: [
        { id: 0, name: this.$t('360.sellers.inactive') },
        { id: 1, name: this.$t('360.sellers.active') },
      ],
      selectedStoreId: '',
      loading: false,
      loadingPin: false,
      pin: '',
      alternativeSellers: [],
      enrolmentMessage: '',
      isLoadingMessage: false,
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters(['customerDistribution']),
    ...mapGetters('sellers', ['getOrderedStores', 'getAvailableEmails', 'getEmailLoadingStatus']),
    isNewSeller: function () {
      return isNaN(this.sellerId);
    },
    user() {
      return store?.getters?.user;
    },
    hasSso() {
      return store?.getters?.hasSso;
    },
    enrolment() {
      return this.fields.enrolment;
    },
  },
  watch: {
    sellerId: function () {
      if (!this.sellerId) return;
      this.fetchDetails();
      this.open();
    },
    isVisible() {
      // close modal actions
      if (!this.isVisible) {
        this.resetEmailList();
        this.$router.push({
          name: '360-sellers',
        });
        this.sellerDetail = {};
        this.fields.name = '';
        this.fields.fullName = '';
        this.fields.storeId = [];
        this.fields.enrolment = '';
        this.fields.enabled = 1;
        this.fields.status = 'active';
        this.fields.alternate = null;
        this.fields.userId = null;
        this.fields.userEmail = null;
        this.fields.isAdmin = 0;
        this.pin = '';
        this.selectedStoreId = '';
        this.enrolmentMessage = '';
      }
    },
  },
  mounted() {
    this.hasPermissionAdmin();
    this.isVisible = false;

    bus.$on('seller-detail-modal', () => {
      this.isVisible = true;
    });
    bus.$on('restore-store', () => {
      this.fields.storeId = this.sellerDetail.store.id === '-1' ? [] : this.sellerDetail.store.id;
    });
    if (!this.sellerId) return;
    this.fetchDetails();
    this.open();
  },
  beforeUnmount() {
    bus.$off(['restore-store', 'seller-detail-modal']);
  },
  methods: {
    ...mapActions('sellers', ['getAvailableEmailList', 'resetEmailList']),
    open() {
      this.isVisible = true;
    },
    onChangeStatus() {
      if (this.fields.enabled === 1) this.fields.status = status.active;
      else this.fields.status = status.inactive;
    },
    async fetchDetails() {
      if (isNaN(this.sellerId)) return;

      this.loading = true;

      await getSeller(this.sellerId)
        .then(({ data }) => {
          this.sellerDetail = data;
          this.fields.name = this.sellerDetail.name;
          this.fields.fullName = this.sellerDetail.fullName;
          this.fields.storeId = this.sellerDetail.store.id === '-1' ? [] : this.sellerDetail.store.id;
          this.fields.enrolment = this.sellerDetail.enrolment;
          this.fields.enabled = this.sellerDetail.enabled;
          this.fields.status = this.sellerDetail.status;
          this.fields.alternate = this.sellerDetail.alternate;
          this.fields.userId = this.sellerDetail.userId || null;
          this.fields.userEmail = this.sellerDetail.userEmail;
          this.fields.isAdmin = this.sellerDetail.admin ? 1 : 0;
          this.storeId = this.sellerDetail.store.id;
          if (this.fields.status !== status.active) {
            this.fields.enabled = this.$t(`360.sellers.${this.fields.status}`);
          }
        })
        .finally(() => {
          this.loading = false;
        });

      await getActiveSellers(this.storeId).then(({ data }) => {
        const result = data.data;

        this.alternativeSellers = orderBy(
          map(result, store => ({
            id: store.id,
            name: store.name,
          })),
          'name',
        );
      });
    },
    resetPin() {
      this.loadingPin = true;
      generatePin().then(response => {
        const data = response?.data;
        this.pin = data?.data?.pin;
        this.loadingPin = false;
      });
    },
    copyText() {
      this.$refs.inputPin.type = 'text';
      this.$refs.inputPin.select();
      document.execCommand('copy');
      this.$refs.inputPin.type = 'hidden';
      this.toast.success(this.$t('360.sellers.pin-copied'));
    },
    getCustomTextPinButton() {
      if (this.loadingPin) return this.$t('360.sellers.loading');
      return this.isNewSeller ? this.$t('360.sellers.generate-pin') : this.$t('360.sellers.reset-pin');
    },
    hasPermissionAdmin() {
      if (
        this.user?.profile ===
        (profileType.executives ||
          profileType.operations ||
          profileType.products ||
          profileType.stores ||
          profileType.onlyOto)
      ) {
        this.permissionType = false;
      } else this.permissionType = true;
    },
    commitChange() {
      // bypass empty storeId from validation case admin is active
      if (this.fields.isAdmin === 1) {
        delete this.fields.storeId;
        delete this.fields.alternate;
      }
      // check if has some empty field
      if (
        some(this.fields, function (value) {
          return value === '';
        })
      ) {
        this.toast.error(this.$t('360.sellers.fill-fields'));
        return;
      }
      if (!this.isNewSeller && this.fields.enabled === 0 && this.fields.alternate !== null && !this.fields.isAdmin) {
        setAlternateSeller(this.sellerId, this.fields.alternate);
      }

      // new seller or edit seller
      putSeller({
        ...this.fields,
        ...(this.pin !== '' && { pin: this.pin }),
        ...(!this.isNewSeller && { id: this.sellerId }),
        action: this.fields.status,
        alternate: this.fields.alternate,
      }).then(response => this.responsePutSeller(response));
    },
    storeChanged(storeId) {
      this.selectedStoreId = storeId;
      if (this.isSellerWithNewStore()) {
        setTimeout(() => {
          bus.$emit('openChangeStoreModal');
        }, 100);
      }
    },
    resolveStoreName(id, name) {
      if (id) return `${name} - ${id}`;
    },
    handleErrorCode(response) {
      return response?.status === 422 || response?.status === 403;
    },

    responsePutSeller(response) {
      if (this.handleErrorCode(response)) {
        const errorName = response.data.errors?.code.replaceAll(' ', '-');
        this.toast.error(this.$t(`errors.err-${errorName}`));
      } else {
        this.isVisible = false;

        const message = this.isNewSeller
          ? this.$t('360.sellers.seller-registered')
          : this.$t('360.sellers.seller-updated');

        setTimeout(() => {
          this.toast.success(message);
          bus.$emit('sellers-reload-list');
        }, 70);
      }
    },
    setEmailSearch(value) {
      if (!value) return;
      this.triggerDebounce(value);
    },
    triggerDebounce: debounce(function (value) {
      this.getAvailableEmailList(value);
    }, 800),
    setUserEmail(userId) {
      // refresh user on edition mode - get email by selected user
      const user = this.getAvailableEmails.find(el => el.userId === userId);
      this.fields.userEmail = user?.email;
    },
    isSellerWithNewStore() {
      return !this.isNewSeller && this.fields.storeId !== this.sellerDetail.store.id && this.fields.isAdmin !== 1;
    },
    // return message according to response from api
    enrolmentMessagesOptions(response) {
      const messages = {
        'seller-found': this.$t('360.sellers.enrolment-messages.belongs-to-other-seller'),
        'enrolment-found': this.$t('360.sellers.enrolment-messages.no-associated-seller'),
        'enrolment-not-found': this.$t('360.sellers.enrolment-messages.not-found'),
      };

      this.enrolmentMessage = response ? messages[response] : '';
    },
    // function to validate enrolment
    fetchVerifyEnrolment() {
      this.isLoadingMessage = true;
      getVerifyEnrolment({ enrolment: this.fields.enrolment })
        .then(({ data }) => {
          this.enrolmentMessagesOptions(data?.data?.code);
        })
        .finally(() => {
          this.isLoadingMessage = false;
        });
    },

    isDisabledSubmit() {
      if (this.fields.userId && this.fields.fullName) return false;
      else return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-pin {
  padding: 0 15px;
}

.fields {
  display: grid;
  grid-template-columns: 55% 40%;
  grid-template-rows: 60px 60px 60px;
  grid-column-gap: 5%;
  grid-row-gap: 20px;
  margin: 20px 0 30px;
  .wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .message {
    font-size: 11px;
    color: $gray-500;
  }

  .message_loader {
    right: 0;
    position: absolute;
    width: 30px;
    right: 5px;
    top: 23px;
    height: 35px;
  }
}

h2 {
  margin-bottom: 40px;
}

.seller__status {
  max-width: 160px;
}

.pin-wrapper {
  display: grid;
  grid-template-columns: 26% 70%;
  grid-column-gap: 10%;
  margin-bottom: 25px;
  .pin-container-text {
    display: grid;
    p {
      margin-bottom: 0;
      &.pin-text {
        font-size: 20px;
        a {
          font-size: 12px;
          margin-left: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

.inactive-seller-wrapper {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 30px 0;
  padding: 20px 30px;
  .seeler-text-1 {
    font-size: 14px;
    font-weight: 600;
    .inactive {
      color: $gray-500;
      text-transform: lowercase;
    }
    .deleted {
      color: $pink-500;
      text-transform: lowercase;
    }
    .locked,
    .pending {
      color: $yellow-400;
      text-transform: lowercase;
    }
  }
  .seeler-text-inactive {
    width: 326px;
  }
  .seeler-text-inactive-leveling {
    width: 100%;
  }
  .seeler-text-locked,
  .seeler-text-pending,
  .seeler-text-deleted {
    width: 502px;
  }
  .sellers-select {
    max-width: 176px;
  }
}

.button-submit-wrapper {
  margin: 0;
  width: calc(100% + 100px);
  left: -50px;
  position: relative;
  border-top: 1px solid #eee;
  .wrap {
    justify-content: flex-end;
    margin-right: 50px;
    button {
      width: 170px;
      margin: 20px 0 0px;
    }
  }
}
</style>

<style lang="scss">
#seller-detail {
  @import '@/assets/scss/vendors/_v-select';
  @import '@/assets/scss/components/_custom-scrollbar';

  .responsible {
    width: 114%;
    font-size: 11px;
  }

  .vs__dropdown-menu {
    max-height: 165px;
  }

  .vs__dropdown-option {
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .v-select.sellers-email-list {
    .vs__selected,
    .vs__search {
      max-width: 250px;
    }
  }

  select:disabled {
    background-color: #e3e3e3;
    color: $gray-600;
  }

  .el-dialog__body {
    min-height: 534px;
    padding: 20px 50px;
    overflow: hidden;
    overflow-y: auto;

    @extend .customScrollBar;
    @media screen and (max-height: 768px) {
      max-height: 560px;
    }
  }
}
</style>
