<template>
  <div v-show="active" class="search-suggestion">
    <div class="suggestion-container">
      <div v-if="results && results.length" class="suggestion-content">
        <p class="title">{{ $t('search.results-for') }} "{{ term }}"</p>
        <div class="results-list">
          <div
            v-for="(product, index) in results"
            :key="index"
            class="item"
            :title="product.name"
            @click.prevent="$_openProduct(product.id)"
          >
            <figure>
              <img :src="formatImage(product.image)" :alt="product.name" width="40" @error="errorImage" />
            </figure>
            <div class="details">
              <span class="name">{{ product.name }}</span>
              <span class="ref">{{ $t('product.ref') }}: {{ product.id }}</span>
            </div>
          </div>
        </div>
        <div class="search-by">
          <a class="full-search" href="#" @click.prevent="$_fullSearch(term)">
            <i class="icon icon-search"></i>
            <span class="color-oto-brand-omni">{{ $t('search.search-by', { term }) }}</span>
          </a>
        </div>
      </div>
      <p v-else class="suggestion-empty">{{ $t('search.empty') }}</p>
      <load-spinner id="suggestion-loader" color="white" />
    </div>
  </div>
</template>
<script>
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import productsMixin from '@/helpers/mixins/productsMixin';

export default {
  components: {
    'load-spinner': LoadSpinner,
  },
  mixins: [productsMixin],
  props: {
    term: {
      type: String,
      default: '',
    },
    results: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatImage(img) {
      return img || require('@/assets/img/figures/product/no_photo_small.png'); // eslint-disable-line
    },
    errorImage(event) {
      event.target.src = require('@/assets/img/figures/product/no_photo_small.png'); // eslint-disable-line
    },
  },
};
</script>
<style lang="scss" scoped>
.search-suggestion {
  position: absolute;
  top: 3px;
  left: 0;
  width: 100%;
  background-color: $color-white;
  border: 1px solid $gray-500;
  padding: 60px 25px 25px;
  border-radius: 18px;

  .suggestion-container {
    position: relative;
  }

  .title {
    font-weight: 600;
  }

  .results-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    .item {
      display: grid;
      grid-template-columns: 40px 1fr;
      grid-gap: 10px;
      font-size: 12px;
      cursor: pointer;

      figure {
        margin: 0;
        width: 40px;
        height: 46px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: auto;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          display: block;
          width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ref {
          display: block;
          color: $gray-500;
        }
      }
    }
  }

  .full-search {
    display: flex;
    align-items: center;
    padding-top: 15px;
    i {
      color: $gray-500;
    }
    span {
      font-size: 12px;
      font-weight: 600;
    }
    &:hover {
      text-decoration: none;
      span {
        color: $blue-500;
      }
    }
  }

  .suggestion-empty {
    text-align: center;
    margin: 10px 0;
  }
}
</style>
