<template>
  <div :class="{ loadHeight: loading }" class="campaigns-detail-kpis card">
    <div v-if="$_verifyLoaded('done')">
      <div class="row">
        <div class="col">
          <h3 class="card-title d-flex align-items-center">
            <i class="icon icon-media"></i>
            <span>KPIs</span>
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div v-if="!!usersTotal" class="metric mb-4">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('reached-people') }}
            </div>
            <label class="data-value color-oto-brand-omni">
              {{ $_formatDecimal(usersTotal) }}
            </label>
          </div>
          <div v-if="!!avgCustomerValue" class="metric mb-4">
            <div class="value-label d-flex justify-content-left align-items-center">
              {{ $t('aov-per-client') }}
            </div>
            <label class="data-value color-purple-200">
              {{ formatCurrency(avgCustomerValue) }}
            </label>
          </div>
          <div v-if="!!itemsOrder && !!cac" class="metric mb-4">
            <div class="value-label d-flex justify-content-left align-items-center">
              {{ $tc('product-by-purchase', 0) }}
            </div>
            <label class="data-value color-oto-brand-omni">
              {{ $_formatDecimal(itemsOrder, 2) }}
            </label>
          </div>
        </div>
        <div class="col-6">
          <div v-if="!!itemsOrder && !cac" class="metric mb-4">
            <div class="value-label d-flex justify-content-left align-items-center">
              {{ $tc('product-by-purchase', 0) }}
            </div>
            <label class="data-value color-oto-brand-omni">
              {{ $_formatDecimal(itemsOrder, 2) }}
            </label>
          </div>
          <div v-if="conversionRate" class="metric mb-4">
            <div class="value-label d-flex justify-content-left align-items-center">
              {{ $t('conversion-rate') }}
            </div>
            <label class="data-value color-oto-brand-omni">
              {{ $_formatNumber(conversionRate, '0.00%') }}
            </label>
          </div>
          <div v-if="!!cac" class="metric mb-4">
            <div class="value-label d-flex justify-content-left align-items-center">CAC</div>
            <label class="data-value color-purple-200">
              {{ formatCurrency(cac) }}
            </label>
          </div>
          <div v-if="!!roi" class="metric mb-4">
            <div class="value-label d-flex justify-content-left align-items-center">ROI</div>
            <label class="data-value color-oto-brand-omni">
              {{ $_formatNumber(roi, '0.00%') }}
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-if="!!media" class="metric">
            <div class="value-label d-flex justify-content-left align-items-center">
              {{ $tc('enabled-medias', 1) }}
            </div>
            <label class="label d-flex align-items-center text-capitalize">
              <span class="circle d-block bg-oto-ecommerce"></span> {{ media }}
            </label>
          </div>
        </div>
      </div>
      <div v-if="!cac && !roi" class="row set-investnemt">
        <div class="col-12">
          <h4>{{ $t('campaigns.detail.more-insights') }}</h4>
          <p v-html="$t('campaigns.detail.set-investment')"></p>
          <div class="form-group mb-0">
            <input
              id="set-investment"
              type="text"
              :value="formatInvestment"
              :placeholder="$t('campaigns.detail.placeholder')"
              @blur="updateInvestment"
            />
            <a href="#" class="ml-3" @click.prevent="setInvestment">{{ $t('confirm') }}</a>
          </div>
        </div>
      </div>
      <el-dialog v-model="isModalVisible" id="set-investment-error" append-to-body>
        <div class="container">
          <i class="icon icon-sad-face" aria-hidden="true"></i>
          <p>{{ $t('generic-error') }}</p>
        </div>
      </el-dialog>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import { setInvestment } from '@/services/campaigns';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { formatCurrency } from '@/utilities/formatters';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      reference: this.$route.params.id,
      usersTotal: undefined,
      avgCustomerValue: undefined,
      conversionRate: undefined,
      itemsOrder: undefined,
      cac: undefined,
      roi: undefined,
      media: undefined,
      investment: undefined,
      formatInvestment: undefined,
      isModalVisible: false,
    };
  },
  mounted() {
    this.fetch_data();
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'users_total,avg_customer_value,conversion_rate,itens_order,roi,cac,media',
        dash: 'campaigns',
        campaign: this.reference,
      })
        .then(data => {
          this.usersTotal = data.data.metrics.users_total.has_data
            ? data.data.metrics.users_total.data.value.amount
            : null;
          this.avgCustomerValue = data.data.metrics.avg_customer_value.has_data
            ? data.data.metrics.avg_customer_value.data.value.amount
            : null;
          this.conversionRate = data.data.metrics.conversion_rate.has_data
            ? data.data.metrics.conversion_rate.data.value.amount
            : null;
          this.itemsOrder = data.data.metrics.itens_order.has_data
            ? data.data.metrics.itens_order.data.value.amount
            : null;
          this.cac = data.data.metrics.cac.has_data ? data.data.metrics.cac.data.value.amount : null;
          this.roi = data.data.metrics.roi.has_data ? data.data.metrics.roi.data.value.amount : null;
          this.media = data.data.metrics.media.has_data
            ? data.data.metrics.media.data.value.amount.split(',').join(', ')
            : null;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateInvestment(event) {
      let inputVal = event.target.value;

      if (!parseFloat(inputVal) && !inputVal.includes('$')) inputVal = 0;
      // format input value
      this.formatInvestment = formatCurrency(inputVal);
      // format value to send to API
      this.investment = parseFloat(formatCurrency(this.formatInvestment).replace(',', '.'));
      // update input value
      inputVal = this.formatInvestment;
    },
    setInvestment() {
      setInvestment(this.reference, this.investment)
        .then(() => {
          this.fetch_data();
          bus.$emit('update-campaign-information');
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
          this.isModalVisible = true;
          this.investment = undefined;
          this.formatInvestment = undefined;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  color: $gray-700;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
}
.set-investnemt {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid $gray-400;
  margin-right: -25px;
  margin-left: -25px;
  .form-group {
    input {
      width: 145px;
    }
    a {
      color: $oto-omni;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}
.label {
  line-height: 40px;
}
#set-investment-error {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .icon-sad-face {
      font-size: 60px;
      color: $color-red;
      margin-bottom: 20px;
    }
  }
}

.campaigns-detail-kpis {
  &.loadHeight {
    height: 365px;
  }
}
</style>

<style lang="scss">
#set-investment-error {
  max-width: 500px;

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}
</style>
