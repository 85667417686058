<template>
  <div class="action">
    <actions-header :title="$t('360.actions.attachments')" :has-icon="true">
      <actions-attachment-header-new />
    </actions-header>
    <empty-state
      v-show="!getAttachmentsList.length"
      :title="$t('360.actions.empty-state-attachment.title')"
      :text="$t('360.actions.empty-state-attachment.text')"
      :title-list-item="$t('360.actions.empty-state.example')"
      :final-text="$t('360.actions.empty-state.final-message')"
      :button-text="$t('360.actions.empty-state-attachment.button')"
      :exec-function="openModal"
    >
      <template v-slot:image>
        <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" alt="" />
      </template>
      <template v-slot:list-items>
        <div v-for="(items, index) in listItems" :key="index">
          <i class="icon icon-check-filled"></i>
          <p>{{ items.title }}</p>
        </div>
      </template>
    </empty-state>
    <div v-show="getAttachmentsList.length">
      <actions-header :title="$t('360.actions.filters')" :has-icon="true" icon="icon-filter">
        <actions-attachment-header-filters />
      </actions-header>
      <actions-table :id="id" :pagination="pagination" :limit-range="limitRange" :refresh-list="getTableData">
        <template v-slot:table>
          <actions-attachment-table />
        </template>
      </actions-table>
    </div>
    <actions-new-attachment-modal />
    <actions-dialog-error-modal />
  </div>
</template>

<script>
// libs
import { mapState, mapActions, mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
// components
import ActionsHeader from '@/components/oto/actions/_organisms/ActionsHeader';
import ActionsAttachmentHeaderNew from '@/components/oto/actions/_molecules/ActionsAttachmentHeaderNew';
import ActionsAttachmentHeaderFilters from '@/components/oto/actions/_molecules/ActionsAttachmentHeaderFilters';
import ActionsTable from '@/components/oto/actions/_organisms/ActionsTable';
import ActionsAttachmentTable from '@/components/oto/actions/_molecules/ActionsAttachmentTable';
import ActionsNewAttachmentModal from '@/components/oto/actions/modals/ActionsNewAttachmentModal';
import ActionsDialogErrorModal from '@/components/oto/actions/modals/ActionsDialogErrorModal';
import EmptyState from '@/components/_atoms/EmptyState';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'Actionsattachment',
  components: {
    ActionsHeader,
    ActionsAttachmentHeaderNew,
    ActionsAttachmentHeaderFilters,
    ActionsTable,
    ActionsAttachmentTable,
    ActionsNewAttachmentModal,
    ActionsDialogErrorModal,
    EmptyState,
  },
  data() {
    return {
      listItems: [
        {
          title: this.$t('360.actions.empty-state-attachment.item-1'),
        },
        {
          title: this.$t('360.actions.empty-state-attachment.item-2'),
        },
        {
          title: this.$t('360.actions.empty-state-attachment.item-3'),
        },
      ],
    };
  },
  computed: {
    ...mapState('actionAttachments', ['id', 'pagination', 'limitRange']),
    ...mapGetters('actionAttachments', ['getAttachmentsList']),
  },
  mounted() {
    useMixpanelConfig('view_oto_attachments');
  },
  methods: {
    ...mapActions('actionAttachments', ['refreshExportList', 'getTableData']),
    openModal() {
      bus.$emit('open-actions-new-attachment-modal');
    },
  },
};
</script>
