<template>
  <div class="cd-empty-slot">
    <div class="cd-empty-slot__container">
      <div class="cd-empty-slot__header">
        <el-skeleton :rows="1" :loading="true" animated />

        <div class="cd-empty-slot__info">
          <el-skeleton :loading="true" animated>
            <template #template>
              <el-skeleton-item variant="p" style="width: 120px" />
              <el-skeleton-item variant="p" style="width: 160px" />
            </template>
          </el-skeleton>
        </div>
      </div>

      <div class="cd-empty-slot__footer">
        <div class="cd-empty-slot__wrapper">
          <el-skeleton :loading="true" animated>
            <template #template>
              <el-skeleton-item variant="text" style="width: 70px" />
            </template>
          </el-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.cd-empty-slot {
  width: 348px;
  height: 230px;
  box-shadow: 0px 4px 7px 0px #00000012;
  border: 1px solid $gray-300;
  border-radius: 8px;

  &__container {
    padding: 18px;
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    padding: 18px 0;
  }

  &__info {
    padding: 18px 0;

    .el-skeleton {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  &__header {
    .el-skeleton {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .el-skeleton__p.is-first {
        height: 48px !important;
        width: 48px;
      }

      .el-skeleton__paragraph {
        height: 20px;
        width: 73px;
      }
    }
  }

  &__footer {
    border-top: 1px solid $gray-300;
    text-align: right;
  }
}
</style>
