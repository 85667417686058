<template>
  <!-- eslint-disable max-len -->
  <a>
    <path
      d="M30 0C13.4 0 0 13.4 0 30s13.4 30 30 30 30-13.4 30-30S46.6 0 30 0zm0 59C14 59 1 46 1 30S14 1 30 1s29 13 29 29-13 29-29 29z"
    />
    <path
      d="M29.406 16.006a.742.742 0 00-.478.27.805.805 0 00-.178.538v18.79a.815.815 0 00.216.561.724.724 0 001.068 0 .787.787 0 00.216-.56V16.814a.811.811 0 00-.243-.608.721.721 0 00-.601-.2zm.094 25.862c-.828 0-1.5.701-1.5 1.566C28 44.3 28.672 45 29.5 45s1.5-.701 1.5-1.566c0-.865-.672-1.566-1.5-1.566z"
    />
  </a>
</template>
