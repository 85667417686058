import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';
import { TableParams, ParamsDeleteAudience, ParamsRenameAudience } from '../models';

export const getTablePresets = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/audience/getTablePresets', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getTableAudience = (params: TableParams) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/audience/getTableAudience', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      page: params.page,
      limit: params.limit,
      orderBy: params.orderBy,
      type: params.type,
      term: params.term,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const postDeleteAudience = (params: ParamsDeleteAudience) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    '/audience/putAudience',
    {
      brand: brand.id,
      audienceId: params.audienceId,
      enabled: params.enabled,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};

export const postRenameAudience = (params: ParamsRenameAudience) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    '/audience/putAudience',
    {
      brand: brand.id,
      audienceId: params.audienceId,
      name: params.name,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};
