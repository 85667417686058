<template>
  <el-dialog v-model="isVisible" append-to-body id="change-store-modal">
    <div>
      <header>
        <i class="icon icon-attention-30px color-red"></i>
      </header>
      <div class="row">
        <div class="col-12 pt-3">
          <span class="title">{{ $t('360.sellers.change-store-text-1') }}</span>
          <span class="subtitle">{{ $t('360.sellers.change-store-text-2') }}</span>
        </div>
      </div>
      <footer class="action-buttons d-flex">
        <button class="btn" @click.prevent="closeModal">
          {{ $t('360.sellers.change-store-submit') }}
        </button>
        <button class="btn btn-close" @click.prevent="restoreStore">
          {{ $t('360.sellers.change-store-cancel') }}
        </button>
      </footer>
    </div>
  </el-dialog>
</template>

<script>
import bus from '@/helpers/events/bus';

export default {
  name: 'ChangeStoreModal',
  data() {
    return {
      id: null,
      modalId: 'change-store-modal',
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('openChangeStoreModal', () => {
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off(['openChangeStoreModal']);
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    restoreStore() {
      bus.$emit('restore-store');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#change-store-modal {
  padding: 0 50px;

  .icon-attention-30px {
    font-size: 60px;
  }
  .title {
    display: block;
    font-size: 12px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
  }

  .subtitle {
    font-size: 11px;
  }

  .action-buttons {
    margin-top: 28px;
    justify-content: space-evenly;

    .btn {
      width: 136px;

      &.btn-close {
        background-color: #888;
        &:hover {
          background-color: #666;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#change-store-modal {
  text-align: center;
  width: 540px;
  min-height: 300px;
}
</style>
