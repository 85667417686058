import { EditTableResponse, TableMetricsData, ErrorType } from '../models';
import { getTableMetrics } from '../services';

interface EditColumnsData {
  success: boolean;
  errors: ErrorType[];
  table_metrics: {
    dimensions: TableMetricsData[];
    metrics: TableMetricsData[];
  };
  ordering: TableMetricsData[];
  warnings: object[];
}

export const fetchEditColumnsData = (preset: string): Promise<EditTableResponse> =>
  getTableMetrics(preset).then(response => formatResponse(response?.data));

const formatResponse = (response: EditColumnsData): EditTableResponse => ({
  success: response?.success,
  errors: response?.errors,
  tableMetrics: [
    {
      key: 'dimensions',
      data: [...response?.table_metrics?.dimensions].map(dimension => ({
        key: dimension?.key,
        name: dimension?.name,
        type: dimension?.type,
        preview: dimension?.preview,
      })),
    },
    {
      key: 'metrics',
      data: [...response?.table_metrics?.metrics].map(metric => ({
        key: metric?.key,
        name: metric?.name,
        type: metric?.type,
        preview: metric?.preview,
      })),
    },
  ],
  ordering: response?.ordering,
  warnings: response?.warnings,
});
