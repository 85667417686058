// constants of permissions
const actions = {
  MODIFY: 'MODIFY',
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  CREATE: 'CREATE',
};

const roles = {
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
};

const status = {
  active: 'active',
  inactive: 'inactive',
  deleted: 'deleted',
  pending: 'pending',
  locked: 'locked',
};

const ddi = {
  br: '55',
};

const limitTypes = {
  noLimit: 'no_limit',
  seller: 'seller',
  store: 'store',
  storeSeller: 'store_seller',
};

const profileType = {
  context: 1,
  admin: 2,
  brands: 3,
  stores: 6,
  operations: 6,
  products: 6,
  executives: 6,
  onlyOto: 6,
};

const accessType = {
  desk: 0,
  both: 1,
  app: 2,
};

const valueType = {
  string: 'string',
  integer: 'integer',
  percent: 'percent',
  percentCircle: 'percentCircle',
  percentual: 'percentual',
  decimal: 'decimal',
  float: 'float',
  date: 'date',
  currency: 'currency',
  monetary: 'monetary',
  text: 'text',
};

const verifyMixinType = {
  done: 'done',
  loading: 'loading',
  info: 'info',
  infoThens: 'infoThens',
};

export const otoSupportEmail = 'suporte@otocrm.com.br';

export const dateComplete = 'DD/MM/YYYY';
export const dateBeautiful = 'D MMM YYYY';
export const dateMonthBeautiful = 'DD MMM YYYY';
export const dateFormat = 'YYYY-MM-DD';
export const dateCompleteVariation = 'DD MMM YYYY';
export const dateDayMonthYear = 'MM/DD/YYYY';
export const dateDayMonthYearVariation = 'D MMM YYYY';
export const exportDateFormat = 'DD.MM.YYYY';

export const dateTimeFormat = 'DD MMM YYYY HH:mm';
export const dateTimeFormatVariation = 'DD MMM YYYY - HH:mm';
export const dateTimeInverseFormat = 'YYYY-MM-DD HH:mm';
export const dateTimeCompleteFormat = 'YYYY-MM-DD HH:mm:ss';

export const dateFormatYMDHms = 'YMDHms';
export const dateWeekDay = 'dddd';
export const dateMonthYear = 'MMM/YYYY';
export const monthYear = 'MMMM YYYY';
export const dateFormatDayAndMonth = 'DD MMM';

export const prodEnvironment = process.env.NODE_ENV === 'production';
export const typeEnvironment = process.env.VUE_APP_TYPE_ENV;

export const omnichatIndividualApproachStartDate = '1970-01-01 09:00:00';

export const thirtyDays = 30;

export { actions, roles, status, ddi, limitTypes, profileType, accessType, valueType, verifyMixinType };
