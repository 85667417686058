<template>
  <div v-if="validateIfHasPermission(permissionReports)" class="cashback-report">
    <cashback-report-header />
    <cashback-report-metrics />
    <div class="row">
      <div class="col-6">
        <cashback-customers-dont-use-metrics />
      </div>
      <div class="col-6">
        <cashback-customers-use-metrics />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <cashback-customers-dont-use-graphic />
      </div>
      <div class="col-6">
        <cashback-customers-use-graphic />
      </div>
    </div>
    <cashback-report-table />
  </div>
</template>

<script>
// helpers
import hasPermission, { actions } from '@/helpers/permissions';
// components
import CashbackReportHeader from '@/components/oto/cashbackReport/CashbackReportHeader';
import CashbackReportMetrics from '@/components/oto/cashbackReport/CashbackReportMetrics';
import CashbackCustomersDontUseMetrics from '@/components/oto/cashbackReport/CashbackCustomersDontUseMetrics';
import CashbackCustomersUseMetrics from '@/components/oto/cashbackReport/CashbackCustomersUseMetrics';
import CashbackCustomersDontUseGraphic from '@/components/oto/cashbackReport/CashbackCustomersDontUseGraphic';
import CashbackCustomersUseGraphic from '@/components/oto/cashbackReport/CashbackCustomersUseGraphic';
import CashbackReportTable from '@/components/oto/cashbackReport/CashbackReportTable';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'ReportCashback',
  components: {
    CashbackReportHeader,
    CashbackReportMetrics,
    CashbackCustomersDontUseMetrics,
    CashbackCustomersDontUseGraphic,
    CashbackCustomersUseGraphic,
    CashbackCustomersUseMetrics,
    CashbackReportTable,
  },
  data() {
    return {
      permissionReports: {
        id: 'cashback-reports',
        featureId: 'cashback',
        action: actions.VIEW,
      },
    };
  },
  mounted() {
    useMixpanelConfig('view_oto_cashback_report');
  },
  methods: {
    // validate permission for a specific functionality
    validateIfHasPermission(permissionGroup) {
      return hasPermission(permissionGroup);
    },
  },
};
</script>
