<template>
  <div>
    <base-card-v2 class="card table-cashback-report">
      <template v-if="$_verifyLoaded('done')">
        <base-card-header :title="$t('360.metrics')" icon="icon-table-30px" />
        <div class="tbl-shell">
          <div ref="tbl-inner-wrapper" class="tbl-inner-wrapper" @scroll="$_scrollTable">
            <div ref="tbl-config" class="tbl-config">
              <div class="tbl-config-options-header row">
                <v-select
                  v-model="preset"
                  label="name"
                  :clearable="false"
                  :options="presets"
                  @option:selected="getTableInfo"
                >
                  <template #option="{ name }">
                    {{ $t(`${name}`) }}
                  </template>
                  <template #selected-option="{ name }">
                    {{ $t(`${name}`) }}
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class="arrow-down"></span>
                  </template>
                  <template #no-options>
                    {{ $t('_no-results') }}
                  </template>
                </v-select>

                <div class="btn-options">
                  <div class="export-wrapper">
                    <button ref="button" class="btn export btn-export" @click="toggleExport">
                      <i class="icon export-icon icon-download"></i>
                      {{ this.$t('360.export', { value: '' }) }}
                    </button>
                    <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
                      <div
                        v-for="item in exportItems"
                        :key="item.value"
                        class="items"
                        @click="onChangeExport(item.value, preset.id)"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ref="tableHeader" class="tbl-header">
              <div class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
                >
                  <div class="header-title">
                    {{ vH.name }}
                  </div>
                  <div
                    :ref="vH.key"
                    :class="[
                      `order-by ${vH.key}`,
                      {
                        active: isActive === vH.key,
                      },
                    ]"
                    @click.prevent="ordenate(vH.key)"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="headerFixed" ref="header-fixed" class="tbl-header header-fixed">
              <div class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
                >
                  <div class="header-title">
                    {{ vH.name }}
                  </div>
                  <div
                    :ref="vH.key"
                    :class="[
                      `order-by ${vH.key}`,
                      {
                        active: isActive === vH.key,
                      },
                    ]"
                    @click.prevent="ordenate(vH.key)"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
              </div>
            </div>
            <div ref="tbl-body" class="tbl-body">
              <div v-for="(item, index) in tableData.rows" :key="index" class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
                >
                  <p
                    :class="[
                      'threshold',
                      `${$_checkThreshold({
                        amount: item[vH.key],
                        threshold: vH.threshold,
                      })}`,
                    ]"
                  >
                    {{ $_getValueNewFormat({ vH, amount: item[vH.key] }) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="tbl-footer">
              <div v-for="(item, index) in tableData.totalResults" :key="index" class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
                >
                  <p :class="['footer-item']">
                    {{
                      vH.key == 'store'
                        ? $t('360.lightbox.order-details.total')
                        : !isNil(item[vH.key])
                        ? $_getValueNewFormat({ vH, amount: item[vH.key] })
                        : '-'
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </base-card-v2>
    <list-pagination :data="pagination" :limit="pagination.limit" :limit-range="limitRange" :identify="id" />
  </div>
</template>

<script>
// libs
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import moment from 'moment';
import FileDownload from 'js-file-download';

// services
import { getTable, getTablePresets } from '@/services/oto/cashbackReport';

// helpers
import bus from '@/helpers/events/bus';
import vClickOutside from 'click-outside-vue3';

import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import ListPagination from '@/components/_atoms/ListPagination';

export default {
  name: 'CashbackReportTable',
  components: {
    BaseLoader,
    InfoCard,
    vSelect,
    ListPagination,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  data() {
    return {
      headerFixedWidth: 0,
      headerFixed: false,
      tableData: {},
      tableHeader: {},
      preset: {
        id: 'store',
        name: 'Loja',
      },
      presets: [],
      isActive: null,
      isExportVisible: false,
      exportItems: [
        {
          value: 'csv',
          title: this.$t('360.export-type', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('360.export-type', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('360.export-type', { value: '.XLSX' }),
        },
      ],
      loadingExport: false,
      conversionWindow: 30,
      // pagination data
      id: 'cashback-report-table',
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
      },
      orderBy: '',
      limitRange: [5, 10, 20, 30, 50],
    };
  },
  computed: {
    ...mapGetters('cashbackReport', ['getStoreId', 'getSellerId']),
  },
  watch: {
    getStoreId() {
      this.getTableInfo();
    },
    getSellerId() {
      this.getTableInfo();
    },
  },
  created() {
    // bus called when dates are changed globally
    bus.$on('render-cards', () => {
      this.getTableInfo();
    });
    bus.$on('cashback-report-change-store', () => {
      this.getTableInfo();
    });
    bus.$on('cashback-report-change-seller', () => {
      this.getTableInfo();
    });
    bus.$on('cashback-report-change-segment-general', () => {
      this.getTableInfo();
    });
  },
  mounted() {
    this.setup();
    bus.$on('changeSelectedOption', newSelectedOption => {
      if (newSelectedOption.id === 'table-select') {
        this.preset = newSelectedOption.index;
        this.getTableInfo();
      } else if (newSelectedOption.id === 'conversion-window-select') {
        this.conversionWindow = newSelectedOption.index;
        this.getTableInfo();
      }
    });
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
    bus.$on(`${this.id}-change-page`, () => {
      this.getTableInfo();
      this.headerFixed = false;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off('changeSelectedOption');
    bus.$off('render-page-loader');
    bus.$off('render-cards');
    bus.$off('cashback-report-change-store');
    bus.$off('cashback-report-change-seller');
    bus.$off('cashback-report-change-segment-general');
    bus.$off(`${this.id}-change-page`);
  },
  methods: {
    setup() {
      this.getTableInfo();
      this.getTablePresetsInfo();
    },
    getTableInfo() {
      this.$_reqConfig();

      getTable({
        preset: this.preset.id,
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        page: this.pagination.page,
        limit: this.pagination.limit,
        orderBy: this.orderBy,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.tableHeader = data.series;
          this.tableData.rows = data.data;
          this.pagination.count = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTablePresetsInfo() {
      getTablePresets().then(({ presets }) => {
        const dataPresets = Object.entries(presets);

        dataPresets.map(el => {
          this.presets.push({
            id: el[0],
            name: el[1],
          });
        });
      });
    },

    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },

    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    fetchExportData(fileType, preset) {
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getTable({
        preset,
        fileType,
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        responseType: 'blob',
      })
        .then(({ data }) => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset}-${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },

    ordenate(order) {
      if (!this.orderBy) {
        this.orderBy = order;
        this.isActive = order;
        this.getTableInfo();
        return;
      }

      this.isActive = order;

      if (this.orderBy === order) this.orderBy = `-${order}`;
      else this.orderBy = order;
      this.getTableInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.export-icon {
  font-size: 17px;
  font-weight: 600;
}
.table-presets-select {
  height: 36px;
  width: 225px;
  margin-left: 16px;
}
.tbl-body {
  width: 100%;
}
</style>

<style lang="scss">
.table-cashback-report {
  min-height: 430px;

  .vs__dropdown-toggle {
    width: 200px;
  }
}
</style>
