import axios from 'axios';
import store from '@/store';
import { valueType } from '@/utilities/constants';

export const list = ({
  dash = 'campaigns_list',
  orderby = '',
  locale = 'pt-BR',
  limit = '',
  page = '',
  columns = '',
} = {}) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;

  return axios.get('/metrics/get_table', {
    params: {
      locale: user.language || locale,
      brand: brand.id,
      dash,
      orderby,
      limit,
      page,
      m: columns,
    },
  });
};

export const validate = {
  field(format, type, value) {
    if (!value) return false;

    if (format === 'date_iso') return this.test.date(value);
    if (type === valueType.string) return this.test.string(value);
    if (type === valueType.integer) return this.test.integer(value);
    if (type === valueType.decimal) return this.test.decimal(value);

    return true;
  },
  fields(items) {
    return items.every(item => this.field(...item));
  },
  test: {
    date(str) {
      return /\d{4}-\d{2}-\d{2}/.test(str);
    },
    string(str) {
      return str.length;
    },
    number(str) {
      return str.length && !Number.isNaN(str);
    },
    integer(str) {
      return this.number(str) && str.indexOf('.') < 0;
    },
    decimal(str) {
      return this.number(str);
    },
  },
};

export const disable = campaignId => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;

  return axios.get('/campaigns/put_campaign', {
    params: {
      cid: campaignId,
      brand: brand.id,
      enabled: 0,
      campaign_user: user.id,
    },
  });
};

export const rename = (campaignId, name) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;

  return axios.get('/campaigns/put_campaign', {
    params: {
      cid: campaignId,
      brand: brand.id,
      campaign_user: user.id,
      campaign: name,
    },
  });
};

export const setInvestment = (campaignId, investment) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;

  return axios.get('/campaigns/put_campaign', {
    params: {
      cid: campaignId,
      brand: brand.id,
      campaign_user: user.id,
      investment,
    },
  });
};

export const create = ({ title, audience, start, end, investment, media }) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;

  return axios.get('/campaigns/put_campaign', {
    params: {
      campaign: title,
      aid: audience,
      start_date: start,
      end_date: end,
      brand: brand.id,
      user_id: user.id,
      investment,
      media,
    },
  });
};

export const info = campaignId => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;

  return axios.get('/campaigns/get_campaign_info', {
    params: {
      cid: campaignId,
      brand: brand.id,
      campaign_user: user.id,
    },
  });
};

export default list;
