<template>
  <span v-if="persona" :style="{ backgroundColor: persona ? persona.color : '' }" class="segment-label">
    {{ persona.name }}
  </span>
</template>
<script>
export default {
  name: 'ComponentSegmentBubble',
  props: {
    segmentID: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    persona() {
      const persona = this.$store.getters.selectedBrand.personas.filter(pers => pers.id === this.segmentID);
      return persona[0];
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: $color-white;
  text-align: center;
  border-radius: 100px;
  padding: 0 6px;
}

.segment-label {
  border: 0;
}
</style>
