<template>
  <lightbox-template id="lightbox-retention" :title="$t('segments-title')" icon="customer">
    <retention-chart />
    <retention-table />
  </lightbox-template>
</template>

<script>
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import RetentionChart from '@/components/lightbox/lightboxes/_organisms/retention/RetentionChart';
import RetentionTable from '@/components/lightbox/lightboxes/_organisms/retention/RetentionTable';

export default {
  name: 'LightboxRetention',
  components: {
    LightboxTemplate,
    RetentionChart,
    RetentionTable,
  },
};
</script>

<style lang="scss" scoped>
.loadHeight {
  min-height: 250px;
  position: relative;
}
</style>
