import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "seller-ranking-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('lightbox-seller-ranking')))
    }, _toDisplayString(_ctx.$t('show-details')), 1)
  ]))
}