<template>
  <li class="nav-item">
    <a
      :id="label + '-tab'"
      class="nav-link"
      :class="status"
      data-toggle="tab"
      :href="'#' + label"
      role="tab"
      :aria-controls="label"
      aria-selected="true"
      >{{ label }}</a
    >
  </li>
</template>

<script>
export default {
  name: 'TabItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  margin-top: -1px;
  .nav-link {
    padding: 0.8rem 1rem 0.5rem;
    border-radius: 0;
    border-width: 0;
    border-color: transparent;
    color: $gray-700;
    font-weight: 600;
    font-size: 12px;
    &.active {
      padding: 0.6rem 1px 10px 2px;
      border-top: 3px solid $oto-omni;
      color: $oto-omni;
    }
  }
}
</style>
