<template>
  <div class="audience-include-exclude-skeleton">
    <div class="mb-15">
      <skeleton-loader height="20px" width="219px" />
    </div>

    <skeleton-group />

    <div class="mb-40">
      <skeleton-loader width="166px" />
    </div>

    <skeleton-loader height="20px" width="136px" class="mb-15 d-block" />
    <skeleton-loader width="166px" />
  </div>
</template>
<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';
import AudienceGroupSkeleton from '@/components/audiences/create/composition/AudienceGroupSkeleton';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
    'skeleton-group': AudienceGroupSkeleton,
  },
};
</script>
<style lang="scss" scoped>
.mb-15 {
  margin-bottom: 15px;
}
.mb-40 {
  margin-bottom: 40px;
}
</style>
