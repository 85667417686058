<template>
  <div>
    <div v-if="getSellerMetricsLoading" class="row">
      <div v-for="(metric, key) in 8" :key="key" class="col-md-3">
        <indicator-block :metric="{}" :is-empty="metric" />
      </div>
    </div>
    <div v-else class="row">
      <div v-for="(metric, key) in sellerMetrics.data" :key="key" class="col-md-3">
        <indicator-block :metric="metric" :is-empty="!metric" />
      </div>
    </div>
  </div>
</template>

<script>
// store
import { mapState, mapGetters } from 'vuex';
// components
import IndicatorBlock from '@/components/oto/sellersAnalytics/_molecules/IndicatorBlock';

export default {
  components: {
    IndicatorBlock,
  },
  computed: {
    ...mapState('sellersAnalytics', ['sellerMetrics']),
    ...mapGetters('sellersAnalytics', ['getSellerMetricsLoading']),
  },
};
</script>
