<template>
  <div>
    <el-dialog v-model="isVisible" id="integration-config-modal" width="572" class="integration-modal">
      <div class="container">
        <h2 class="title">
          {{ $t('settings.integrations.select') }}
        </h2>
        <div v-if="!loading">
          <div class="integration-modal__search">
            <p>
              {{ $t('settings.integrations.search') }}
            </p>
            <input v-model="search" class="search" type="text" :placeholder="$t('settings.integrations.type')" />
          </div>
          <div class="integration-modal__list">
            <p v-if="!filterOptions" class="empty text-center">{{ $t('settings.integrations.no-results') }}</p>
            <p v-if="empty" class="empty text-center">{{ $t('settings.integrations.no-connectors') }}</p>
            <div v-for="item in filterOptions" :key="item.id" class="integration-modal__list-item">
              <div class="wrapper">
                <div class="icon">
                  <img :src="getIcon(item)" :alt="item.name" />
                </div>
                <span class="name">{{ item.name }}</span>
              </div>
              <integration-oauth-form
                :configuration="Array.isArray(item.configuration) ? {} : item.configuration"
                :item="{ alias: item.alias, id: item.id }"
              >
                <button class="btn integration-modal__btn-config">
                  {{ $t('settings.integrations.config') }}
                </button>
              </integration-oauth-form>
            </div>
          </div>
        </div>
        <base-loader-spinner v-else />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import integrationMixin from '@/helpers/mixins/integrationMixin';
import IntegrationOauthForm from '@/components/settings/integrations/IntegrationOauthForm';
import BaseLoaderSpinner from '@/components/_atoms/BaseLoaderSpinner';
import { getConnectors } from '@/services/connectors';

export default {
  name: 'IntegrationConfigModal',
  components: {
    IntegrationOauthForm,
    BaseLoaderSpinner,
  },
  mixins: [integrationMixin],
  data() {
    return {
      empty: false,
      formId: null,
      integrations: [],
      loading: false,
      search: '',
      isVisible: false,
    };
  },
  computed: {
    filterOptions() {
      return this.integrations?.filter(item => item.name.toLowerCase().includes(this.search));
    },
  },
  mounted() {
    this.search = '';
    bus.$on('openConfigurationModal', this.openModal);
    bus.$on('integration-config-modal-close', () => {
      this.isVisible = false;
    });
  },
  beforeUnmount() {
    bus.$off('openConfigurationModal');
    bus.$off('integration-config-modal-close');
  },
  methods: {
    openModal() {
      this.isVisible = true;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      getConnectors()
        .then(({ data }) => {
          if (!data.data) {
            this.empty = true;
            return;
          }
          if (data) this.integrations = data.data;
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-modal {
  &__search {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 10px 0 0;
    width: 100%;

    p {
      font-weight: 600;
      margin: 0;
    }
  }

  &__list {
    min-height: 300px;
    position: relative;
    padding: 40px 0 0;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 40px 0;
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .name {
          padding-left: 30px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #555;
        }
      }
    }
  }

  &__btn-config {
    height: 30px;
    line-height: 30px;
    width: 117px;
  }
}
</style>

<style lang="scss">
#integration-config-modal {
  height: 572px;
  overflow: hidden;

  .el-dialog__body {
    padding: 0 50px;
    overflow: auto;
    height: 520px;
  }
}
</style>
