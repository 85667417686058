<template>
  <div class="col-sm p-2">
    <customer-view-most-purchased
      v-show="$_verifyLoaded('done')"
      :id="id"
      :data="categories"
      :title="$t('360.person.customer-categories.title')"
      color="#36AAD6"
    />
    <div v-show="$_verifyLoaded('loading')" class="card">
      <base-loader />
    </div>
    <div v-show="$_verifyLoaded('info')" class="card">
      <info-card ref="message-error" :error="hasError" />
    </div>
  </div>
</template>
<script>
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import CustomerViewMostPurchased from '@/components/oto/customerview/components/CustomerViewMostPurchased';
import InfoCard from '@/components/_atoms/InfoCard';
import { getCategories } from '@/services/oto/customers';
import { verifyMixin } from '@/helpers/mixins';

export default {
  components: {
    CustomerViewMostPurchased,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      id: 'most-purchased-categories',
      categories: {
        title: this.$tc('360.categories', 1),
        options: [],
      },
      season: null,
      customerId: null,
    };
  },
  mounted() {
    bus.$on('load-profile', customerId => {
      if (!customerId) return;

      this.customerId = customerId;
      this.loadData();
    });
    bus.$on('change-season', id => {
      if (id === this.id) {
        this.loadData();
      }
    });
  },
  methods: {
    loadData() {
      this.$_reqConfig();

      getCategories(this.customerId, this.season)
        .then(res => {
          if (this.$_verifyData(res.data.categories.categories)) return;
          this.categories.options = res.data.categories.categories;
        })
        .catch(() => {
          this.categories.options = [];
          this.season = undefined;
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  height: 325px;
  .card-title {
    margin-bottom: 48px;
  }
}
</style>
