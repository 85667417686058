import axios from 'axios';
import moment from 'moment';
import store from '../store';

/*
  @args {
    metrics = '',
    filters = '',
    startdate = '',
    enddate = '',
    segments = 1,
    version = 1,
    topvalues = '',
    expand = ''
  }
*/
export default ({
  endpoint = '/metrics/get_table',
  metrics = '',
  filters = '',
  startdate = '',
  enddate = '',
  version = 1,
  topvalues = '',
  scale = '',
  segmentgroup = '',
  locale = 'pt-BR',
  expand = '',
  campaign = '',
  dash = '',
  page = '',
  limit = '',
  table = '',
  orderBy = '',
  tbl = '',
  isLightbox = false,
} = {}) => {
  const brand = store.getters.selectedBrand;
  const segment = store.getters.selectedSegment;
  const { user } = store.getters;
  let { startDate } = store.getters.dates;
  let { endDate } = store.getters.dates;
  const personaId = store.getters.selectedPersona?.id;
  const filterId = store.getters.selectedFilter;

  if (startdate && enddate) {
    startDate = moment(startdate).format('YYYY-MM-DD');
    endDate = moment(enddate).format('YYYY-MM-DD');
  }

  let segmentGroupSelected = segmentgroup || segment.segmentGroupId;
  if (filters.includes('store_id')) segmentGroupSelected = 1;
  if (isLightbox && segmentGroupSelected === 1) segmentGroupSelected = 2;

  return axios.get(endpoint, {
    params: {
      brand: brand.id,
      m: metrics ? metrics.toString() : '',
      start_date: startDate,
      end_date: endDate,
      locale: user.language || locale,
      segment_group: segmentGroupSelected,
      persona: personaId,
      order_filters: filterId,
      v: version,
      campaign_id: campaign,
      limit,
      page,
      dash,
      tbl,
      ...(table && { tbl: table }),
      ...(orderBy && { orderby: orderBy }),
      ...(filters && { filter: filters }),
      ...(topvalues && { top: topvalues }),
      ...(scale && { scale }),
      ...(expand && { expand }),
    },
  });
};
