import { StoresResponse } from '../models';
import { getStores } from '../services';

export const fetchStores = (): Promise<StoresResponse> => getStores().then(response => formatResponse(response?.data));

const formatResponse = (response: StoresResponse): any => {
  const formattedData = Object.entries(response?.data)?.map(([value, label]) => ({
    value,
    label: label + ' - ' + value,
  }));
  return { data: formattedData };
};
