<template>
  <nav id="header-personas" class="personas-header">
    <div class="row no-gutters personas-header-container">
      <div class="col-dates col-6">
        <p>Sua persona em relação à base</p>
        <div class="header-dates" @click="triggerSelectDates">
          <p class="date">{{ getStringCalendar() }}</p>
          <img src="@/assets/svgs/Pen.svg" />
        </div>
      </div>
      <div class="col-dropdowns col-6 justify-content-end">
        <persona-dropdown :is-context-main="true" context-type="ctx-main" />
        <persona-dropdown />
      </div>
    </div>
  </nav>
</template>

<script>
import * as moment from 'moment';
import PersonaDropdownVue from '@/components/personasCompare/_molecules/PersonaDropdown';
// helpers
import { dateCompleteVariation } from '@/utilities/constants';

export default {
  components: {
    'persona-dropdown': PersonaDropdownVue,
  },
  data() {
    return {
      windowTop: undefined,
    };
  },
  mounted() {
    if (document.querySelector('.alert-warning-div') !== null) {
      document.querySelector('.alert-warning-div').style.display = 'none';
    }
    window.onscroll = () => {
      if (window.pageYOffset <= 30) {
        document.getElementById('header').style.top = '0';
        document.getElementById('header-personas')
          ? (document.getElementById('header-personas').style.top = '70px')
          : false;
      } else {
        document.getElementById('header').style.top = '-70px';
        document.getElementById('header-personas')
          ? (document.getElementById('header-personas').style.top = '0')
          : false;
      }
    };
  },
  methods: {
    getStringCalendar() {
      const { startDate, endDate } = this.$store.getters.dates;
      const format = dateCompleteVariation;
      return `${moment(startDate).format(format)} - ${moment(endDate).format(format)}`;
    },
    triggerSelectDates() {
      window.scrollTo(0, 0);
      document.getElementsByTagName('input').datarangeheader.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.personas-header {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  background-color: $color-white;
  box-shadow: 0px 24px 60px rgba(0, 0, 0, 0.07);
  z-index: 2;
}
.personas-header-container {
  width: 1128px;
  margin: auto;
  justify-content: space-around;
  @media (min-width: 1200px) and (max-width: 1365px) {
    margin-left: 104px;
  }
}
.col-dates,
.col-dropdowns {
  display: inline-flex;
}
.col-dates {
  p,
  .header-dates {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: $gray-800;
    margin-bottom: 0;
  }
  .header-dates {
    margin-left: 17px;
    cursor: pointer;
    p {
      font-weight: 400;
    }
    img {
      margin-left: 7px;
    }
  }
}
</style>
