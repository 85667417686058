<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.8332 10.44C13.0799 9.63334 13.6666 8.09334 13.2266 6.62667C12.7866 5.16001 11.4666 4.20667 9.99989 4.20667C8.53322 4.20667
    7.21322 5.16001 6.77322 6.62667C6.33322 8.09334 6.91989 9.63334 8.16655 10.44C5.89322 11.1733 4.27989 13.1533 3.98655
    15.5H4.71989C5.08655 12.86 7.35989 10.88 9.99989 10.88C12.6399 10.88 14.9132 12.86 15.2799 15.5H16.0132C15.7199 13.1533
    14.1066 11.1 11.8332 10.44ZM9.99989 10.22C9.26655 10.22 8.60655 9.92667 8.16655 9.41334C7.65322 8.90001 7.35989 8.24001
    7.43322 7.58001C7.43322 6.11334 8.60655 4.94001 10.0732 4.94001C11.5399 4.94001 12.7132 6.11334 12.7132 7.58001C12.6399
    9.04667 11.4666 10.22 9.99989 10.22ZM16.7466 3.25334C14.9866 1.49334 12.4932 0.466675 9.99989 0.466675C4.71989 0.466675
    0.466553 4.72001 0.466553 10C0.466553 15.28 4.71989 19.5333 9.99989 19.5333C15.2799 19.5333 19.5332 15.28 19.5332 10C19.5332
    7.50667 18.5066 5.01334 16.7466 3.25334ZM16.2332 16.2333C14.5466 17.8467 12.3466 18.8 9.99989 18.8C5.15989 18.8 1.19989 14.84
    1.19989 10C1.19989 5.16001 5.15989 1.20001 9.99989 1.20001C14.8399 1.20001 18.7999 5.16001 18.7999 10C18.7999 12.3467 17.8466
    14.5467 16.2332 16.2333Z"
  />
</template>
