import { getTable, getExportTable } from '../services';
import { FormattedTable, ResponseTable, Dates, Export } from '../models';

export const fetchTable = params => getTable(params).then(response => formattedTable(response));

export const fetchExportTable = (
  preset: string,
  dates: Dates,
  fileType,
  responseType?: 'blob',
  query?: [{ column: string; value: string }],
) => getExportTable(preset, dates, fileType, responseType, query).then(response => formatExport(response?.data));

const formattedTable = ({ data }: { data: ResponseTable }) => {
  const result: FormattedTable = {
    data: data?.data || [],
    series: data?.series || [],
    totalCount: data?.totalCount,
    errors: data?.errors || [],
  };

  return result;
};

const formatExport = (response: Export): Export => response;
