import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = '/auth';
const URIUser = '/user';

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Auth-verifyToken
 * @description Retrieves if user still authenticated. This endpoint validate v2 and v3
 */
export const getTokenStatus = () => {
  const token = store.state.auth.token;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/verifyToken`, {
    baseURL: endpointVersion('v3'),
    params: {
      token,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Auth-auth
 * @description Authenticate user
 */
export const postLogin = data => {
  const tokenV3 = store.state.auth.tokenV3;
  return axios.post(`${URI}/login`, data, {
    baseURL: endpointVersion('v3'),
    headers: { 'content-type': 'multipart/form-data', Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description Authenticate user using federation
 */
export const postFederatedLogin = data => {
  return axios.get(`${URI}/token`, {
    baseURL: endpointVersion('v3'),
    params: data,
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context/#api-Login-password_forgot
 * @description Post forgot password
 */
export const postForgotLogin = data => {
  const tokenV3 = store.state.auth.tokenV3;
  return axios.post(`${URIUser}/passwordForgot`, data, {
    baseURL: endpointVersion('v3'),
    headers: {
      Authorization: `Bearer ${tokenV3}`,
      'content-type': 'multipart/form-data',
    },
  });
};

/**
 * @see https://api-context.pmweb.com.br/v3/auth/verifyEmail
 * @description Verify if is fedarated login
 */
export const getVerifyEmail = email => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/auth/verifyEmail', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      email: email,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
