<template>
  <div class="welcome">
    <home-banner />
    <div class="welcome__container">
      <welcome-cards />
      <base-card-v2>
        <div id="updates"></div>
      </base-card-v2>
    </div>
  </div>
</template>

<script>
import { openLightbox } from '@/helpers/lightbox';

// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
import WelcomeCards from '@/components/welcome/_molecules/WelcomeCards';
import HomeBanner from '@/features/HomeBanner/components/Banner.vue';

export default {
  name: 'Welcome',
  components: {
    WelcomeCards,
    HomeBanner,
  },
  data() {
    return {
      noticeable: 'tQ4wrFCK01bxtvkYxgd2',
    };
  },
  computed: {
    termsOfService() {
      return this.$store.state.auth.user?.termsOfService;
    },
  },
  mounted() {
    useMixpanelConfig('view_home');

    if (!this.$store?.getters?.isLoggedIn) return;

    this.$nextTick(function () {
      window.noticeable.render('widget', this.noticeable, { selector: '#updates' });
    });
  },
  unmounted() {
    window.noticeable.destroy('widget', this.noticeable);
  },
  methods: {
    resolveTermsOfService() {
      const consent = this.termsOfService;
      if (!consent) {
        openLightbox('lightbox-terms-of-service');
      }
    },
  },
};
</script>

<style lang="scss">
.welcome {
  &__container {
    display: flex;
    flex-direction: column;
  }
}

#updates {
  #global-container {
    .wrapper {
      width: 100% !important;
    }
  }
}
</style>
