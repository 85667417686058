<template>
  <div class="card">
    <div class="card-body">
      <template v-if="!isEmpty(reports) && !loading">
        <template v-if="reports.length">
          <label>{{ $t('reports.subtitle') }}</label>
          <div v-click-outside="hideReports" class="report-wrapper">
            <div class="report-select select-fake" @click="toggle">
              <span class="report-name">{{ selected.name }}</span>
            </div>
            <div v-show="reportContainer.isVisible" class="report-container">
              <div v-for="report in reports" :key="report.id" class="report-items" @click="selectReport(report)">
                {{ report.name }}
              </div>
            </div>
          </div>
        </template>
        <h2 v-else class="reports-text">{{ options.name }}</h2>
      </template>
      <skeleton-reports v-else-if="loading" />
      <p v-else class="no-reports reports-text">
        {{ $t('reports.no-reports') }}
      </p>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import bus from '@/helpers/events/bus';
import SkeletonReports from '@/components/reports/SkeletonReports';
import vClickOutside from 'v-click-outside';

export default {
  name: 'HeaderReports',
  components: {
    SkeletonReports,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    selected: {
      type: Object,
      default: null,
    },
    reports: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reportContainer: {
        isVisible: false,
      },
    };
  },
  methods: {
    /**
     * @description update selected report
     */
    selectReport(item) {
      this.hideReports();
      bus.$emit('change-report', item);
    },
    /**
     * @description hide report container
     */
    hideReports() {
      this.reportContainer.isVisible = false;
    },
    /**
     * @description toggle report container
     */
    toggle() {
      this.reportContainer.isVisible = !this.reportContainer.isVisible;
    },
    isEmpty(data) {
      return isEmpty(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 100px;
  background: url(~@/assets/img/figures/genericOtoBg.png) no-repeat right 0 center, $color-white;
  .card-body {
    padding: 0 39px;
    select {
      min-width: 332px;
    }
  }
}

.reports-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;

  &.no-reports {
    font-size: 16px;
  }
}

.report-wrapper {
  cursor: pointer;
  position: relative;
  width: 332px;

  .report-select {
    align-items: center;
    color: $gray-600;
    font-size: 12px;
    font-weight: bold;
    width: 100%;

    .report-name {
      width: auto;
      white-space: nowrap;
    }
  }

  .report-container {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
    position: absolute;
    width: 100%;
    z-index: 9;

    .report-items {
      background-color: $color-white;
      color: $gray-800;
      font-weight: bold;
      padding: 10px 15px;
      font-size: 12px;

      &:hover {
        background-color: $gray-300;
        cursor: pointer;
      }
    }
  }
}
</style>
