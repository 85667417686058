<template>
  <div class="card conversionsalesorder">
    <div v-if="$_verifyLoaded('done') && ready" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-cart"></i>
        <span>{{ $t('orders') }}</span>
      </h2>
      <table>
        <thead>
          <tr>
            <th>{{ $t('channel') }}</th>
            <th>{{ $t('orders') }}</th>
            <th>
              <span>{{ $t('avg-basket-size') }}</span>
            </th>
            <th>{{ $t('repeat-clients') }}</th>
            <th>{{ $t('avg-order-value') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span class="color-purple-400">{{ $tc('in-store') }}</span>
            </td>
            <td>{{ $_formatDecimal(dataReturn.orders_off.data.value.amount, 1) }}</td>
            <td>{{ $_formatDecimal(dataReturn.itens_order_off.data.value.amount, 1) }}</td>
            <td>
              {{
                $_formatNumber(
                  (dataReturn.customers_recurring_off.data.value.amount / dataReturn.customers_off.data.value.amount) *
                    100,
                  '0%',
                )
              }}
            </td>
            <td>
              {{ formatCurrency(dataReturn.avg_order_value_off.data.value.amount) }}
            </td>
          </tr>
          <tr>
            <td>
              <span class="color-oto-ecommerce">{{ $t('ecommerce') }}</span>
            </td>
            <td>
              {{ $_formatDecimal(dataReturn.orders_on.data.value.amount, 1) }}
            </td>
            <td>
              {{ $_formatDecimal(dataReturn.itens_order_on.data.value.amount, 1) }}
            </td>
            <td>
              {{
                $_formatNumber(
                  (dataReturn.customers_recurring_on.data.value.amount / dataReturn.customers_on.data.value.amount) *
                    100,
                  '0%',
                )
              }}
            </td>
            <td>
              {{ formatCurrency(dataReturn.avg_order_value_on.data.value.amount) }}
            </td>
          </tr>
          <tr v-if="dataReturn.orders_app.has_data">
            <td>
              <span class="color-blue-400">{{ $t('app') }}</span>
            </td>
            <td>
              {{ $_formatDecimal(dataReturn.orders_app.data.value.amount, 1) }}
            </td>
            <td>
              {{ $_formatDecimal(dataReturn.itens_order_app.data.value.amount, 1) }}
            </td>
            <td>
              {{
                $_formatNumber(
                  (dataReturn.customers_recurring_app.data.value.amount / dataReturn.customers_app.data.value.amount) *
                    100,
                  '0%',
                )
              }}
            </td>
            <td>
              {{ formatCurrency(dataReturn.avg_order_value_app.data.value.amount) }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>{{ $t('total') }}</strong>
            </td>
            <td>
              {{ $_formatDecimal(dataReturn.orders.data.value.amount, 1) }}
            </td>
            <td>
              {{ $_formatDecimal(dataReturnItems, 1) }}
              <span class="legend">{{ $t('average') }}</span>
            </td>
            <td>
              {{
                $_formatNumber(
                  (dataReturn.customers_recurring.data.value.amount / dataReturn.customers.data.value.amount) * 100,
                  '0%',
                )
              }}
              <span class="legend">{{ $t('average') }}</span>
            </td>
            <td>
              {{ formatCurrency(dataReturn.avg_order_value.data.value.amount) }}
              <span class="legend">{{ $t('average') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// @ is an alias to /src
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { valueType } from '@/utilities/constants';

export default {
  name: 'ConversionSalesOrders',
  components: {
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataReturnItems: null,
      dataReturn: {},
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        metrics: [
          'itens_order_off',
          'itens_order_on',
          'itens_order_app',
          'itens_order',
          'orders_off',
          'orders_on',
          'orders_app',
          'orders',
          'customers_recurring_off',
          'customers_recurring_on',
          'customers_recurring_app',
          'customers_recurring',
          'customers_on',
          'customers_off',
          'customers_app',
          'customers',
          'avg_order_value_off',
          'avg_order_value_on',
          'avg_order_value_app',
          'avg_order_value',
        ],
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          this.dataReturn = response.data.metrics;
          const amt = this.dataReturn.itens_order.data.value.amount || null;
          this.dataReturnItems = typeof amt === valueType.string ? parseFloat(amt.replace(',', '.')) : amt;
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @media (min-width: $xl-min-width) {
    min-height: 310px;
  }
  @media (max-width: $tablet-max-width) {
    min-height: 281px;
    .chart-title {
      line-height: 15px;
    }
  }
}
table {
  width: 100%;

  thead {
    tr {
      th {
        color: $gray-900;
        font-size: 11px;
        padding: 10px 15px 15px 15px;
        vertical-align: top;

        &:not(:first-child):not(:last-child) {
          text-align: right;
        }

        &:last-child {
          text-align: right;
        }
        &:nth-child(3) {
          span {
            width: 80px;
            display: block;
            line-height: 14px;
            float: right;
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e8e8e8;

      td {
        font-size: 12px;
        padding: 15px;
        color: $gray-600;
        font-weight: 400;

        &:first-child {
          color: $gray-800;
          font-weight: bold;
        }

        &:not(:first-child) {
          text-align: right;
        }

        .center {
          display: flex;
          align-items: center;
        }

        .icon {
          margin-right: 18px;
        }
      }

      &:last-child {
        border-bottom: none;

        td {
          font-weight: 600;
          color: $gray-900;
          vertical-align: top;

          &:first-child {
            text-transform: uppercase;
          }

          .legend {
            display: block;
            color: $gray-600;
            font-weight: 300;
          }
        }
      }
    }
  }
}
</style>
