import { DataIngestionHistoryExport, DataIngestionHistoryResponse } from '../types';
import { getDataIngestionData, getExportDataIngestionHistory } from '@/services/dataIngestionHistory';

type Options = { page?: number; limit?: number };

// fetch and format table
export const fetchDataIngestionHistory = ({ limit, page }: Options) =>
  getDataIngestionData({
    limit,
    page,
  }).then(response => formatAvailableTables(response?.data));

const formatAvailableTables = (response: DataIngestionHistoryResponse): DataIngestionHistoryResponse => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
  totalCount: response?.totalCount,
  series: response?.series,
  warnings: response?.warnings,
});

// export data ingestion history
export const exportDataIngestionHistory = (
  date: string,
  fileType?: string,
  responseType?: 'blob',
): Promise<DataIngestionHistoryExport> =>
  getExportDataIngestionHistory({ fileType, responseType, date }).then(response => formatExport(response?.data));

const formatExport = (response: DataIngestionHistoryExport): DataIngestionHistoryExport => response;
