<template>
  <div class="empty-table-deactive-action">
    <img class="empty-table-deactive-action__image" src="@/assets/img/illustrations/meditation.svg" />

    <div class="empty-table-deactive-action__content">
      <strong class="empty-table-deactive-action__title">
        {{ $t('campaigns-management.deactive-actions.empty-table.title') }}
      </strong>
      <p class="empty-table-deactive-action__description">
        {{ $t('campaigns-management.deactive-actions.empty-table.description') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.empty-table-deactive-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;

  &__image {
    margin-right: 40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 40%;
  }

  &__title {
    color: $gray-800;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    line-height: 1;
    text-align: left;
  }

  &__description {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 16px;
    text-align: left;
  }
}
</style>
