<template>
  <div class="container loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $tc('order', 0) }}</h5>
      </header>
      <lightbox-template-chart :settings="chartStores" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import { formatDecimal, formatNumber } from '@/utilities/formatters';
import getMetrics from '@/services/getmetrics';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'OrdersChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  props: {
    storeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartStores: {},
    };
  },
  mounted() {
    this.fetchChart();
  },
  methods: {
    fetchChart() {
      this.$_reqConfig();
      getMetrics({
        endpoint: 'stores/get_metrics',
        metrics: ['orders', 'customers_recurring_rate', 'orders_identified_valid_rate'],
        tbl: 'stores',
        filters: `store_id:${this.storeId}`,
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          const { data } = response.data;
          const { metrics } = data;

          if (this.$_verifyData(metrics)) return;

          const { orders } = metrics;
          const customersRate = metrics.customers_recurring_rate;
          const ordersRate = metrics.orders_identified_valid_rate;

          let { labels } = data;
          labels = this.format({ labels, formatTo: 'MMM/YY' });

          this.loadChartStores(orders, customersRate, ordersRate, labels);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadChartStores(orders = [], customers = [], ordersRate = [], labels = []) {
      this.chartStores = {
        chart: {
          height: 280,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: labels,
        },
        tooltip: {
          shared: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            let s = `<b>${this.x}</b>`;
            this.points.forEach(points => {
              s += `<br/><span style="color:${points.color}">\u25CF</span> ${points.series.name}:
              <b>${
                points.series.name.includes('%') ? formatNumber(points.y, '0.00%') : formatDecimal(points.y, 1)
              }</b>`;
            });

            return s;
          },
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
          },
        },
        yAxis: [
          {
            title: {
              text: '',
            },
          },
          {
            title: {
              text: '',
            },
            labels: {
              format: '{value}%',
            },
            opposite: true,
          },
        ],
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            pointWidth: 10,
          },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
        },
        series: [
          {
            name: this.$tc('order', 0),
            data: orders,
            color: '#EC3942',
            type: 'column',
          },
          {
            name: `% ${this.$t('repeat-clients')}`,
            data: customers,
            color: '#35BA9B',
            type: 'spline',
            yAxis: 1,
          },
          {
            name: `% ${this.$t('valid-identified')}`,
            data: ordersRate,
            color: '#EEDA36',
            type: 'spline',
            yAxis: 1,
          },
        ],
      };
    },
  },
};
</script>
