<template>
  <base-card-v2 class="details-header">
    <!-- header -->
    <div class="details-header-blocks">
      <div>
        <label>{{ $t(path + '.detail.header.name') }}</label>
        <input
          ref="headerDetailsInputBlur"
          class="header-details-input"
          :placeholder="$t(path + '.detail.header.name-add')"
          :value="vuexDetail.name"
          @input="actionSetName"
        />
      </div>
      <div v-if="hasGroup" class="details-group-block">
        <label>Grupo <span>*opcional</span> </label>
        <v-select
          v-model="groupOption"
          label="name"
          :clearable="false"
          :options="groups"
          @option:selected="actionSetGroup"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('no-results') }}
          </template>
        </v-select>
      </div>
      <div class="details-description-block">
        <label>{{ $t(path + '.description-title') }}</label>
        <div>
          <span class="description">{{ vuexDetail.description }}</span>
          <a href="#" @click="openEditDescriptionModal">
            {{ vuexDetail.description ? $t('labels-personas.edit') : $t(path + '.detail.header.description-add') }}
          </a>
        </div>
      </div>
    </div>
  </base-card-v2>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
import vSelect from 'vue-select';
import { getPersonasGroups } from '@/services/personas';

export default {
  name: 'HeaderDetails',
  components: {
    vSelect,
  },
  props: {
    hasGroup: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    vuexDetail: {
      type: Object,
      default: () => {},
      required: true,
    },
    actionSetName: {
      type: Function,
      required: true,
    },
    actionSetGroup: {
      type: Function,
      default: () => {},
    },
    editDescriptionEmitter: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      groupOption: '',
      groups: [],
    };
  },
  watch: {
    vuexDetail() {
      if (!this.vuexDetail?.groupId) {
        this.groupOption = this.groups[0]?.name;
        return;
      }
      this.groupOption = this.groups[this.vuexDetail?.groupId]?.name;
    },
  },
  mounted() {
    bus.$on('header-details-input-blur', () => {
      this.$refs.headerDetailsInputBlur.focus();
    });

    this.fetchDataGroups();
  },
  beforeUnmount() {
    bus.$off('header-details-input-blur');
  },
  methods: {
    fetchDataGroups() {
      const emptyGroup = { name: this.$t('personas.none'), id: null };
      getPersonasGroups().then(({ data }) => {
        if (data?.data?.length > 0) {
          this.groups = data.data;
          this.groups.unshift(emptyGroup);
          this.groupOption = this.groups[this.vuexDetail?.groupId]?.name;
        }
      });
    },
    openEditDescriptionModal() {
      bus.$emit(this.editDescriptionEmitter);
    },
  },
};
</script>

<style lang="scss" scoped>
.details-header {
  background: url(~@/assets/img/figures/genericOtoBg.png) no-repeat right 0 center, $color-white;
  height: 128px;
  padding: 25px 40px;
}
.details-header-blocks {
  display: inline-grid;
  grid-template-columns: 220px 170px auto;
  column-gap: 25px;
  margin-top: 10px;

  .header-details-input {
    width: 216px;
  }

  .details-group-block {
    width: 170px;
  }

  span {
    color: #bbbbbb;
  }
}

.details-description-block {
  font-size: 12.1px;
  color: #888;
  line-height: 20px;
  width: 300px;
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  a {
    color: $oto-omni;
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
.details-header {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
