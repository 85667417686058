<template>
  <div class="suggestion-edit">
    <campaigns-management-edit-header v-if="mode === 'edit'" />
    <campaigns-management-detail v-show="getActivePage === 'composition'" :id="id" :mode="mode" />
    <campaigns-management-participants v-show="getActivePage === 'participants' && mode === 'edit'" :id="id" />
  </div>
</template>

<script>
// libs
import { mapGetters } from 'vuex';

// components
import CampaignsManagementDetail from '@/components/oto/campaignsManagement/_organisms/CampaignsManagementDetail';
import CampaignsManagementParticipants from '@/components/oto/campaignsManagement/_organisms/CampaignsManagementParticipants';
import CampaignsManagementEditHeader from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementEditHeader';
import bus from '@/helpers/events/bus';

export default {
  name: 'SuggestionEdit',
  components: {
    CampaignsManagementDetail,
    CampaignsManagementParticipants,
    CampaignsManagementEditHeader,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('campaignsManagement', ['getActivePage']),
  },
  mounted() {
    bus.$on('update-selected-brand', this.back);
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
  },
  methods: {
    back() {
      if (this.$route.meta.title === 'campaign-new') {
        this.$router.push({
          name: '360-campaigns',
          query: {
            page: this.pageHistory,
          },
        });
      } else {
        this.$router.push({
          name: 'campaign-new',
          query: {
            page: this.pageHistory,
          },
        });
      }
    },
  },
  unmounted() {
    this.$store.dispatch('campaignsManagement/setActivePage', 'composition');
  },
};
</script>

<style lang="scss"></style>
