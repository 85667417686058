<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body class="store-settings-modal">
    <div class="store-settings-modal__wrapper">
      <i class="icon icon-alert-60px yellow"></i>
      <div class="store-settings-modal__paragraph">
        <p>{{ $t('settings.stores.modal.text') }}</p>
        <p>{{ $t('settings.stores.modal.text-2') }}</p>
      </div>
      <div class="store-settings-modal__buttons">
        <button class="btn btn-secondary" @click="cancel">{{ $t('settings.stores.modal.cancel') }}</button>
        <button class="btn" @click="save">{{ $t('settings.stores.modal.save') }}</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// libs
import { mapActions, mapGetters } from 'vuex';
// helpers
import bus from '@/helpers/events/bus';
import { verifyMixin } from '@/helpers/mixins';

export default {
  name: 'StoresSettingsModal',
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  mixins: [verifyMixin],
  data() {
    return {
      id: 'store-settings-modal',
      action: 'add',
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters('settingsStores', ['getAllFormFields']),
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
    });
    bus.$on('close-modal', () => {
      this.closeModal();
    });
  },
  beforeMount() {
    bus.$off(`open-${this.id}`);
    bus.$off('close-modal');
  },
  methods: {
    ...mapActions('settingsStores', ['setToastSuccess', 'setSaveStore']),
    /**
     * @description modal events
     */
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    cancel() {
      this.closeModal();
    },
    save() {
      this.action = this.mode === 'edit' ? 'update' : 'add';
      this.setSaveStore(this.action);
    },
  },
};
</script>

<style lang="scss">
.store-settings-modal {
  width: 500px;
  text-align: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;

    i {
      font-size: 60px;
      margin-bottom: 20px;
    }

    .yellow {
      color: $yellow-500;
    }
  }

  &__paragraph {
    p:nth-child(1) {
      width: 380px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__buttons {
    display: flex;
    width: 70%;
    justify-content: space-around;
    margin-top: 20px;
  }
}
</style>
