import { getFollowUpMetrics, getFollowUp } from '@/services/indicators/ConversationRanking';
import { FollowUpType, FollowUpTypeResponse, TopFollowUpTypeResponse } from '../types';

// fetch and format fetchFollowUpMetrics
export const fetchFollowUpMetrics = ({
  source,
  storeId,
  sellerId,
  campaignId,
  lookback,
}: FollowUpType): Promise<FollowUpTypeResponse> =>
  getFollowUpMetrics({ source, storeId, sellerId, campaignId, lookback }).then(response =>
    formatFollowUpResponse(response.data),
  );

const formatFollowUpResponse = (response: FollowUpTypeResponse): FollowUpTypeResponse => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});

// fetch and format fetchTopFollowUpMetrics
export const fetchTopFollowUpMetrics = ({
  source,
  storeId,
  sellerId,
  campaignId,
  lookback,
}: FollowUpType): Promise<TopFollowUpTypeResponse> =>
  getFollowUp({ source, storeId, sellerId, campaignId, lookback }).then(response =>
    formatTopFollowUpResponse(response.data),
  );

const formatTopFollowUpResponse = (response: TopFollowUpTypeResponse): TopFollowUpTypeResponse => ({
  data: response?.data,
});
