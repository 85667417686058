<template>
  <div class="table">
    <template v-if="$_verifyLoadedVuex('done')">
      <div class="table__header">
        <div class="table__row">
          <div v-for="item in series" :key="item" :class="['table__column', resolveTextAlign(item)]">
            <div class="table__header-title" v-html="$t(`360.actions.${item}`)"></div>
            <div
              :class="['table__order-by', { 'table__order-by--active': isActive === item }]"
              @click.prevent="ordenate(item)"
            >
              <span :class="['table__dash-arrows', isActive === item ? `table__dash-arrows--${orderType}` : '']"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="table__body">
        <div v-for="(item, index) in tableData" :key="index" class="table__row">
          <div v-if="!item?.file" class="table__column attachment" :title="item.name">
            {{ item?.name ? item?.name : '-' }}
          </div>
          <div v-else class="table__column attachment" :title="item.name">
            <a :href="item?.file" target="_blank">
              <p>{{ item?.name ? item?.name : '-' }}</p>
              <i class="icon icon-external-14px" />
            </a>
          </div>
          <div class="table__column channel">
            {{ item?.channel ? $tc(`_${item?.channel}`, 2) : '-' }}
          </div>
          <div v-if="!getScopeType" class="table__column scope">
            {{ item?.scope?.name ? $tc(`_${item?.scope?.key}`, 1) + ': ' : '' }}
            <strong>{{ item?.scope?.name ? item?.scope?.name : '' }}</strong>
          </div>
          <div v-else class="table__column scope">
            {{ item?.scope?.name ? item?.scope?.name : '-' }}
          </div>
          <div class="table__column created-at text-right">
            {{ item?.createdAt ? $_formatDate(item?.createdAt) : '-' }}
          </div>
          <div class="table__column modified-at text-right">
            {{ item?.modifiedAt ? $_formatDate(item?.modifiedAt) : '-' }}
          </div>
          <div class="table__column modified-at text-right">
            {{ item?.filesize ? item?.filesize : '-' }}
          </div>
          <menu-dropdown
            class="table__column table__dropdown"
            :label="item?.id"
            :actions="actionsDropdown(item, modalId)"
          />
        </div>
      </div>
      <modal-dialog-question
        :id="modalId"
        :delete-action="triggerAction"
        :is-visible="isVisible"
        @closeDialogModal="isVisible = $event"
        :message="$t('360.actions.dialog.delete-asset')"
      />
    </template>
    <base-loader v-show="$_verifyLoadedVuex('loading')" />
    <info-card v-show="$_verifyLoadedVuex('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapState, mapGetters, mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
// helpers
import { tableMixin, valueUnitMixin, verifyMixin, actionsMixin } from '@/helpers/mixins';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import ModalDialogQuestion from '@/components/_atoms/ModalDialogQuestion';
import MenuDropdown from '@/components/_atoms/MenuDropdown.vue';

export default {
  name: 'ActionAttachmentTable',
  components: {
    BaseLoader,
    InfoCard,
    ModalDialogQuestion,
    MenuDropdown,
  },
  mixins: [tableMixin, valueUnitMixin, verifyMixin, actionsMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      attachmentId: null,

      order: null,
      orderType: 'asc',
      isActive: '',
      modalId: 'action-attachments',
      tableData: null,
      isHovering: false,
      isCancelRecurrenceModalActive: false,
      isVisible: false,
    };
  },
  computed: {
    ...mapState('actionAttachments', ['attachmentsList', 'series', 'loadingGlobal', 'isEmptyGlobal', 'hasErrorGlobal']),
    ...mapGetters('actionAttachments', [
      'getAttachmentsList',
      'getIsEmpty',
      'getHasError',
      'getLoading',
      'getScopeType',
    ]),
  },
  beforeUpdate() {
    this.updateTable();
  },
  mounted() {
    this.resetSelected();
    this.getTableData();

    bus.$on('update-selected-brand', () => {
      this.callBusEventFunctions();
    });

    bus.$on('render-cards', () => {
      this.callBusEventFunctions();
    });

    bus.$on('actions-attachments-refresh-list', () => {
      this.callBusEventFunctions();
    });

    bus.$on('actions-attachment-toast-delete', () => {
      this.toast.success(this.$t('360.actions.toast.file-delete'), {
        timeout: 5000,
      });
    });

    bus.$on(`${this.modalId}-open-confirm-modal`, id => {
      this.isVisible = true;
      this.attachmentId = id;
    });
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
    bus.$off('render-cards');
    bus.$off('actions-attachments-refresh-list');
    bus.$off('actions-attachment-toast-delete');
    bus.$off(`${this.modalId}-open-confirm-modal`);
  },
  methods: {
    ...mapActions('actionAttachments', ['getTableData', 'resetSelected', 'disableAttachment', 'setPaginationOrder']),

    /**
     * @description Verify list order, filter and organize personas list
     */
    updateTable() {
      this.tableData = this.getAttachmentsList;
    },

    /**
     * @description Receive and send data for $_tableOrdination
     * @param {Object} data
     */
    ordenate(key) {
      // update internal table state with order type mixin
      const { order, orderType } = this.$_tableOrderType({
        key,
        order: this.order,
        orderType: this.orderType,
      });
      this.isActive = key;
      this.order = order;
      this.orderType = orderType;

      // increment key with desc to be sent by action
      const orderFormat = orderType === 'asc' ? key : `${key}Desc`;
      this.setPaginationOrder(orderFormat);
    },

    /**
     * @description disable attachment
     */
    triggerAction() {
      this.disableAttachment(this.attachmentId);
    },

    /**
     * @description fix alignment based on item key
     */
    resolveTextAlign(key) {
      let align;
      if (key === 'name' || key === 'channel' || key === 'scope') {
        align = 'text-left';
      }
      if (key === 'createdAt' || key === 'modifiedAt' || key === 'size') {
        align = 'text-right';
      }
      return align;
    },

    callBusEventFunctions() {
      this.resetSelected();
      this.getTableData();
    },

    /**
     * @description actions to menu dropdown
     */
    edit(attachment) {
      bus.$emit('open-actions-new-attachment-modal', attachment.id);
    },
    delete(attachment, modalId) {
      bus.$emit(`${modalId}-open-confirm-modal`, attachment.id);
    },
    actionsDropdown(attachment, modalId) {
      return [
        {
          name: 'edit',
          action: () => this.edit(attachment),
        },
        {
          name: 'delete',
          action: () => this.delete(attachment, modalId),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  color: $gray-800;
  font-size: 12px;
  height: auto;
  min-height: 630px;
  margin: 0;

  .table__header,
  .table__body {
    .table__row {
      color: $gray-800;
      display: grid;
      grid-template-rows: 60px;
      grid-template-columns: 20% 18% 16% 12% 12% 14% 10%;
      align-items: center;
      padding: 0 25px;
    }
  }

  .table__header {
    font-weight: 600;
    .table__row {
      border-bottom: 1px solid $gray-300;
    }
  }

  .table__body {
    .table__row {
      grid-auto-columns: 80px;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: none;
      }

      .table__column {
        .table__status-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .table__recurrent {
            margin-left: 12px;
            min-width: 20px;
            img {
              cursor: pointer;
            }
          }
          .table__status-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65%;
          }
        }
        &.table__dropdown {
          display: grid;
          grid-column: 7;
          justify-content: right;
          padding-right: 10px;
        }
        .table__icon-wrapper {
          display: flex;
          align-items: center;

          .icon {
            font-size: 14px;
          }

          &:hover {
            &[data-toggle='tooltip'] {
              cursor: pointer;
            }
          }
        }

        &.attachment {
          max-width: 380px;
          line-height: 20px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;

          a {
            display: flex;
            align-items: center;
            color: $blue-500;
            cursor: pointer;
            width: fit-content;

            p {
              text-decoration: underline;
              margin-bottom: 0;
              color: inherit;
            }

            i {
              margin-left: 5px;
            }
          }

          a:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .table__header-title {
    display: inline-block;
    width: fit-content;
    white-space: initial;
    margin-right: 5px;
  }

  .table__order-by {
    background-color: $gray-400;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    padding: 3px 0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    text-align: center;

    .table__dash-arrows {
      display: block;
      color: $color-white;
      border: solid $gray-900;
      border-width: 0 1px 1px 0;
      border-radius: 0;
      display: inline-block;
      padding: 3px;
      transform: translate(0, -50%) rotate(45deg);

      &--asc {
        transform: translate(0, -50%) rotate(45deg);
      }
      &--desc {
        transform: rotate(-135deg);
      }
    }

    &--active {
      background-color: $oto-omni;

      .table__dash-arrows {
        border-color: $color-white;
      }

      .table__dash-arrows {
        &--asc {
          transform: translate(0, -50%) rotate(45deg);
        }
        &--desc {
          transform: rotate(-135deg);
        }
      }
    }
  }
}
</style>
