<template>
  <el-dialog v-model="isVisible" id="audiences-exportation-preview" append-to-body width="820">
    <div class="header">
      <div class="title d-flex align-items-center">
        <button class="icon icon-back-arrow" @click="back"></button>
        <h2 class="mb-0">{{ $t('audiences.exportation.title') }}</h2>
      </div>
      <p v-if="audience">
        {{ $t('audiences.exportation.audience-preview') }}
        <strong>{{ audience.name }}</strong>
      </p>
    </div>
    <div class="content">
      <table-list :data="dataTable" type="filled">
        <div v-if="!ready" class="table-container">
          <audience-exportation-preview-skeleton :data="dataTable" :type="type" />
        </div>
        <div v-else class="table-container">
          <table-list-header :style="{ transform: `translateY(${position}px)` }">
            <table-list-column v-for="title in dataTable.titles" :key="title.key" :order="title.key" class="d-flex">
              <div class="title d-flex flex-column">
                <span>{{ title.name }}</span>
                <span class="thin">{{ title.key }}</span>
              </div>
            </table-list-column>
          </table-list-header>
          <table-list-body v-if="ready">
            <table-list-row v-for="(row, index) in dataTable.rows" :key="index">
              <table-list-column v-for="(column, index) in row" :key="index">
                {{ formatString(column) }}
              </table-list-column>
            </table-list-row>
          </table-list-body>
        </div>
      </table-list>
    </div>
    <footer>
      <p v-if="audience">
        {{ $t('audiences.exportation.audience-size') }}
        <b>{{ $_formatDecimal(audience.contacts, 1) }} {{ $t('audiences.exportation.records') }}</b>
      </p>
      <button class="btn" @click="exportation">{{ $t('export') }}</button>
    </footer>
  </el-dialog>
</template>
<script>
import { numberMixin } from '@/helpers/mixins';
import { previewAudience } from '@/services/audiences';
import bus from '@/helpers/events/bus';
import AudienceExpPreviewSkeleton from '@/components/audiences/exportation/AudiencesExportationPreviewSkeleton';

export default {
  components: {
    'audience-exportation-preview-skeleton': AudienceExpPreviewSkeleton,
  },
  mixins: [numberMixin],
  props: {
    audience: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id: 'audiences-exportation-preview',
      type: 'filled',
      dataTable: {
        loading: true,
        count: 0,
        rows: [],
        titles: [],
        order: 'PMWEB_ID',
      },
      position: 0,
      ready: false,
      isVisible: false,
    };
  },
  computed: {
    orderBy() {
      let { order } = this.dataTable;
      if (this.dataTable.order.includes('desc')) order = this.dataTable.order.split('_desc').join(' DESC');
      return order;
    },
  },
  watch: {
    isVisible() {
      if (!this.isVisible) this.reset();
    },
  },
  mounted() {
    bus.$on('open-exportation-preview', () => {
      this.openModal();
    });

    bus.$on(`${this.id}-change-order`, this.loadTable);
  },
  beforeUnmount() {
    bus.$off('open-exportation-preview');
  },
  methods: {
    setPosition(event) {
      this.position = event.target.scrollTop;
    },
    loadTable() {
      previewAudience(this.audience, this.columns, this.orderBy, this.dataTable.limit).then(response => {
        const data = response?.data;
        this.dataTable.count = data.data.length;
        this.dataTable.titles = data.columns;
        this.dataTable.rows = data.data;
        this.ready = true;
      });
    },
    openModal() {
      this.loadTable();
      this.isVisible = true;
    },
    back() {
      this.reset();
      this.isVisible = false;
      setTimeout(() => {
        bus.$emit('open-exportation');
      }, 300);
    },
    reset() {
      this.dataTable.rows = [];
      this.dataTable.titles = [];
      this.dataTable.count = [];
      this.dataTable.order = 'PMWEB_ID';
      this.ready = false;
    },
    exportation() {
      this.isVisible = false;
      setTimeout(() => {
        bus.$emit('open-modal-process-progress-exportation');
      }, 300);
      this.$parent.fetchInitialExportation();
    },
    formatString(string) {
      return string?.length > 21 ? `${string.slice(0, 20)}...` : string;
    },
  },
};
</script>
<style lang="scss">
#audiences-exportation-preview {
  .header {
    margin: 0 50px 25px;
    border-bottom: 1px solid #e8e8e8;
  }
  footer {
    margin: 15px 50px 0;
    padding: 25px 0;
    border-top: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      min-width: 170px;
      padding: 0 20px;
      border-radius: 40px;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  .table-container {
    overflow: auto;
    height: calc(100vh - 302px);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &::-webkit-scrollbar-track {
      background-color: $gray-300;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: $gray-300;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $gray-500;
      border-radius: 10px;
    }
    > .row {
      margin: 0;
    }
  }
  .row {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
  .table-list-body.filled {
    width: fit-content;
    max-width: initial;
    overflow: initial;
  }
  .table-list {
    padding: 0;
  }
  .table-list-header {
    min-width: fit-content;
    padding: 0 44px 10px 44px;
    .thin {
      font-weight: 400;
      font-size: 10px;
    }
  }
  .table-list-footer {
    display: none;
  }
  .table-list-row {
    .card {
      padding: 5px 50px;
    }
  }
  .table-list-column {
    width: 180px;
  }
  .icon-back-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $gray-600;
    padding-left: 0;
  }
}
</style>
