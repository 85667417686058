<template>
  <h2 class="d-flex align-items-center">
    <i class="icon" :class="getTitleIcon().icon"></i>
    <span>{{ getTitleIcon().title }}</span>
  </h2>
</template>

<script>
export default {
  name: 'TitleSectionBlock',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    getTitleIcon() {
      switch (this.type) {
        case 'overview':
          return {
            icon: 'icon-omni',
            title: this.$t('personas.compare.omnichannel.title'),
          };
        case 'profile':
          return {
            icon: 'icon-customer',
            title: this.$t('personas.compare.profile.title'),
          };
        case 'revenue':
          return {
            icon: 'icon-money',
            title: this.$t('personas.compare.revenue.title'),
          };
        case 'segments':
          return {
            icon: 'icon-segment',
            title: this.$t('personas.compare.segment.title'),
          };
        default:
          return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  padding: 25px;
  margin-bottom: 0;
  border-bottom: 1px solid $gray-400;
  i {
    font-size: 26px;
  }
}
</style>
