<template>
  <section v-if="getPermissionView || getPermissionCreate" class="cashback-detail">
    <base-card-v2 class="section">
      <template v-if="!loadingCashbackDetail">
        <base-card-header :title="$t('360.cashback.title')" icon="icon-money-18px"></base-card-header>
        <cashback-create-board />
      </template>
      <base-loader v-if="loadingCashbackDetail" />
    </base-card-v2>
    <!-- cashback modal dialogs -->
    <cashback-success-modal />
    <cashback-error-modal />
    <modal-dialog-question
      :id="modalId"
      :delete-action="triggerAction"
      :is-visible="isVisible"
      @closeDialogModal="isVisible = $event"
      :message="$t('360.cashback.alert-high-value')"
    />
  </section>
</template>

<script>
// libs
import { mapState, mapActions, mapGetters } from 'vuex';
// helpers
import bus from '@/helpers/events/bus';
import { useToast } from 'vue-toastification';
// components
import CashbackCreateBoard from '@/components/oto/cashback/_organisms/CashbackCreateBoard';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
// success - error modals
import CashbackSuccessModal from '@/components/oto/cashback/modals/CashbackSuccessModal';
import CashbackErrorModal from '@/components/oto/cashback/modals/CashbackErrorModal';
import ModalDialogQuestion from '@/components/_atoms/ModalDialogQuestion';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'CashbackDetail',
  components: {
    CashbackCreateBoard,
    CashbackSuccessModal,
    CashbackErrorModal,
    ModalDialogQuestion,
    BaseLoader,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      modalId: 'cashback-high-value-modal',
      isVisible: false,
    };
  },
  computed: {
    ...mapState('cashback', ['loadingCashbackDetail', 'cashbackDetails']),
    ...mapGetters('cashback', ['getPermissionView', 'getPermissionCreate']),
  },
  mounted() {
    useMixpanelConfig('view_oto_cashback');
    this.setCreatePermission();

    // get personas to be able to get cashback details
    this.getPersonasList();

    bus.$on('cashback-details-toast-success', () => {
      this.toast.success(this.$t('360.cashback.toast.updated'));
    });

    bus.$on(`open-${this.modalId}`, () => {
      this.isVisible = true;
    });
  },
  beforeUnmount() {
    this.resetData();

    bus.$off(['cashback-details-toast-success']);
  },
  methods: {
    ...mapActions('cashback', ['setCreatePermission', 'getPersonasList', 'resetData', 'submitCashback']),

    triggerAction() {
      this.submitCashback();
    },
  },
};
</script>

<style lang="scss">
.cashback-detail {
  .section {
    min-height: 750px;
  }

  hr {
    margin-left: -50px;
    width: calc(100% + 100px);
  }
}
</style>
