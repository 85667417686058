
// libs
import { defineComponent, PropType, toRefs, reactive, watch } from 'vue';
import { first, values } from 'lodash';
import { TopFiveChartList, ChartListTopCustomersType } from '@/features/SalesRanking/types';
import bus from '@/helpers/events/bus';
// utilities
import { getPercentual, getNumeral } from '@/utilities/numbers';
import { formatCurrency } from '@/utilities/formatters';
import useVerifyMixin from '@/utilities/mixins/useVerifyMixin';
// services
import { fetchTopCustomersRanking } from '@/features/SalesRanking/services';
// components
import ChartList from '@/components/_organisms/ChartList.vue';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';
import InfoCard from '@/components/_atoms/InfoCard.vue';

export default defineComponent({
  name: 'CustomerRankingChartList',
  components: {
    ChartList,
    BaseLoader,
    InfoCard,
  },
  props: {
    selectedStore: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const { hasError, isLoading, reqConfig, verifyData, verifyLoaded } = useVerifyMixin();
    const { selectedStore } = toRefs(props);

    const events: ChartListTopCustomersType = reactive({
      objectChartListTopCustomers: [],
    });

    //watchers
    watch(
      () => selectedStore.value,
      () => {
        fetchTopCustomers();
      },
    );

    function fillChartListTopCustomersObject(response: TopFiveChartList[], getTopRevenue: number | null) {
      response.map(val => {
        events.objectChartListTopCustomers.push({
          label: val.name,
          progress: String(getPercentual(val.revenue, getTopRevenue)),
          value: formatCurrency(val.revenue),
          color: 'lightblue',
        });
      });
    }

    function fetchTopCustomers() {
      reqConfig();

      const args = {
        filters: `storeId:${selectedStore.value}`,
        segments: '',
      };

      fetchTopCustomersRanking(args)
        .then(response => {
          if (verifyData(response?.data)) return;

          events.objectChartListTopCustomers = [];
          const data = response.data;
          const revenue = data.map(item => item.revenue);
          const getTopRevenue = getNumeral(first(values(revenue)));

          fillChartListTopCustomersObject(data, getTopRevenue);
        })
        .catch(() => {
          hasError.value = true;
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    return {
      ...toRefs(events),
      fetchTopCustomers,
      verifyLoaded,
      hasError,
    };
  },
  mounted() {
    this.fetchTopCustomers();

    bus.$on('render-cards', () => {
      this.fetchTopCustomers();
    });

    bus.$on('store-changed', (value: string) => {
      this.$emit('storeChanged', value);
    });
  },
  beforeUnmount() {
    bus.$off(['render-cards', 'store-changed']);
  },
  emits: ['storeChanged'],
});
