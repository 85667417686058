<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    :viewBox="box"
    :aria-labelledby="name"
    role="presentation"
    class="icon"
  >
    <title v-if="name" :id="name" lang="en">{{ name }} icon</title>
    <g :fill="color">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    w: {
      type: [Number, String],
      default: 18,
    },
    h: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: '#888',
    },
    box: {
      type: String,
      default: '0 0 18 18',
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  vertical-align: baseline;
}
</style>
