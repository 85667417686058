<template>
  <div class="action-filters">
    <!-- channel -->
    <div class="a-filters__item">
      <label>{{ $t('mean-of-contact') }}</label>
      <v-select
        v-model="channel"
        :clearable="false"
        :searchable="false"
        :options="channelList"
        label="name"
        class="a-filters__item-select"
        :placeholder="$tc('_select', 2)"
        @option:selected="onChangeChannel"
      >
        <template #option="{ name }">
          {{ $tc(name, 2) }}
        </template>
        <template #selected-option="{ name }">
          {{ $tc(name, 2) }}
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
    <!-- scopeType -->
    <div class="a-filters__item">
      <label>{{ $t('360.actions.scope') }}</label>
      <v-select
        v-model="scopeSelect"
        :clearable="false"
        :searchable="false"
        :options="scopeTypeList"
        :placeholder="$tc('_select', 2)"
        label="name"
        class="a-filters__item-select"
        @option:selected="onChangeScopeType"
      >
        <template #option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #selected-option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('no-results') }}
        </template>
      </v-select>
    </div>
    <!-- campaign -->
    <div v-if="scopeSelect" class="a-filters__item">
      <label>{{ $tc(`${scopeSelect.name}`, 1) }}</label>
      <v-select
        v-model="scope"
        :clearable="false"
        :options="getScopeList"
        :placeholder="$tc('_select', 2)"
        :disabled="checkScopeTypeBrand"
        label="name"
        class="a-filters__item-select"
        @option:selected="onChangeScopeValue"
      >
        <template #option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #selected-option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('no-results') }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
// libs, helpers
import vSelect from 'vue-select';
import { mapState, mapGetters, mapActions } from 'vuex';
import bus from '@/helpers/events/bus';

export default {
  name: 'ActionAttachmentHeaderFilters',
  components: {
    vSelect,
  },
  data() {
    return {
      channel: '',
      scope: '',
      scopeSelect: '',
    };
  },
  computed: {
    ...mapState('actionAttachments', ['channelList', 'scopeType', 'scopeTypeList']),
    ...mapGetters('actionAttachments', ['getScopeType', 'getScopeList']),

    /**
     * @description if the scopeType is type brand, disable the scope input
     */
    checkScopeTypeBrand() {
      return this.getScopeType === 'brand';
    },
  },
  mounted() {
    this.reset();

    bus.$on('update-selected-brand', () => {
      this.reset();
    });

    bus.$on('render-cards', () => {
      this.reset();
    });
  },
  beforeUnmount() {
    bus.$off(['update-selected-brand', 'render-cards']);
  },
  methods: {
    ...mapActions('actionAttachments', ['setChannel', 'setScopeType', 'setScopeValue', 'resetSelected']),

    onChangeChannel($event) {
      this.setChannel($event.id);
    },

    onChangeScopeType($event) {
      this.setScopeType($event.id);
      this.scope = '';
    },

    onChangeScopeValue($event) {
      this.setScopeValue($event.id);
    },

    reset() {
      this.channel = '';
      this.scopeSelect = '';
      this.scope = '';
      this.resetSelected();
    },
  },
};
</script>

<style lang="scss" scoped>
.action-filters {
  display: flex;
  justify-content: flex-start;
  margin: auto 30px auto auto;
  width: 620px;

  .a-filters__item {
    display: flex;
    flex-direction: column;
    width: 190px;
    padding-right: 25px;

    .a-filters__item-select {
      width: 184px;
      height: 36px;
    }

    select {
      height: 36px;
    }

    &--export {
      display: flex;
      align-items: flex-end;
    }
  }
}
</style>

<style lang="scss">
.a-filters__container {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
