import { createI18n } from 'vue-i18n';
import dictionaryPTBR from './locales/dictionary/pt-BR.json';
import dictionaryEN from './locales/dictionary/en.json';
import dictionaryES from './locales/dictionary/es.json';
import store from './store';

// Locale messages
const messages = {
  'pt-BR': dictionaryPTBR,
  en: dictionaryEN,
  es: dictionaryES,
};

// Create VueI18n instance
const hasStore = !!store && store.getters.user;

const i18n = createI18n({
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  locale: hasStore ? store.getters.user.language : 'pt-BR', // Default locale
  fallbackLocale: 'pt-BR',
  warnHtmlInMessage: 'off',
  messages,
});

export default i18n;
