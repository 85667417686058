<template>
  <path
    d="M12.3998 11.125C11.3741 11.125 10.4906 11.6251 9.95885 12.3473L6.33318 10.2146C6.47261 9.85779 6.60817 9.43065 6.60817 9.00002C6.60817 8.56938 6.47261
    8.14224 6.33318 7.78545L9.96229 5.65069C10.5561 6.37036 11.3689 6.87502 12.3998 6.87502C14.0546 6.87502 15.4082 5.52144 15.4082 3.86668C15.4082 2.21193
    14.0546 0.85835 12.3998 0.85835C10.7451 0.85835 9.3915 2.21193 9.3915 3.86668C9.3915 4.2164 9.45648 4.5687 9.58883 4.86246L5.81549 6.99831C5.28858 6.42065
    4.48136 5.99168 3.59984 5.99168C1.94508 5.99168 0.591504 7.34526 0.591504 9.00002C0.591504 10.6548 1.94508 12.0084 3.59984 12.0084C4.47776 12.0084 5.28798
    11.6516 5.81822 11.0039L9.52898 13.1447C9.52197 13.1792 9.51497 13.2132 9.50804 13.2467C9.4467 13.5443 9.3915 13.812 9.3915 14.1334C9.3915 15.7881 10.7451
    17.1417 12.3998 17.1417C14.0546 17.1417 15.4082 15.7881 15.4082 14.1334C15.4082 12.4786 14.0546 11.125 12.3998 11.125ZM12.3998 1.74168C13.6051 1.74168
    14.5248 2.66144 14.5248 3.86668C14.5248 5.07193 13.6051 5.99168 12.3998 5.99168C11.1946 5.99168 10.2748 5.07193 10.2748 3.86668C10.2748 2.66144 11.1946
    1.74168 12.3998 1.74168ZM3.59984 11.125C2.39459 11.125 1.47484 10.2053 1.47484 9.00002C1.47484 7.79477 2.39459 6.87502 3.59984 6.87502C4.80508 6.87502
    5.72484 7.79477 5.72484 9.00002C5.72484 10.2053 4.80508 11.125 3.59984 11.125ZM12.3998 16.2584C11.1946 16.2584 10.2748 15.3386 10.2748 14.1334C10.2748
    12.9281 11.1946 12.0084 12.3998 12.0084C13.6051 12.0084 14.5248 12.9281 14.5248 14.1334C14.5248 15.3386 13.6051 16.2584 12.3998 16.2584Z"
    stroke-width="0.15"
  />
</template>
