<template>
  <button class="persona-add-label-button" @click.prevent="action">
    <i class="icon icon-plus"></i>
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'PersonaAddLabelButton',
  props: {
    title: {
      type: String,
      default: null,
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.persona-add-label-button {
  background-color: transparent;
  border: none;
  color: $oto-omni;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
  i {
    background-color: $oto-omni;
    color: $color-white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 14px;
  }
}
</style>
