import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12 col-xl-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart_list = _resolveComponent("chart-list")!
  const _component_base_loader = _resolveComponent("base-loader")!
  const _component_info_card = _resolveComponent("info-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "title"),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_chart_list, {
            "chart-name": _ctx.$t('sales-ranking-revenue'),
            "rows-list": _ctx.objectChartListTopCustomers
          }, null, 8, ["chart-name", "rows-list"])
        ])
      ]),
      _renderSlot(_ctx.$slots, "link")
    ], 512), [
      [_vShow, _ctx.verifyLoaded('done')]
    ]),
    _withDirectives(_createVNode(_component_base_loader, null, null, 512), [
      [_vShow, _ctx.verifyLoaded('loading')]
    ]),
    _withDirectives(_createVNode(_component_info_card, {
      ref: "message-error",
      error: _ctx.hasError
    }, null, 8, ["error"]), [
      [_vShow, _ctx.verifyLoaded('info')]
    ])
  ], 64))
}