<template>
  <base-card-v2 class="category-details">
    <base-card-header :title="title" />
    <ul v-if="$_verifyLoaded('done')" class="details-list pt-3">
      <li>
        <i class="icon icon-category-18px"></i>
        <span v-if="details.subcategories">
          <strong>{{ details.subcategories }} {{ $tc('categories.overview.subcategories') }}</strong>
          {{ $tc('categories.overview.indexed') }}
        </span>
        <span v-else class="empty">{{ $tc('categories.overview.empty.no-subcategories') }}</span>
      </li>
      <li>
        <i class="icon icon-category-18px"></i>
        <span v-if="details.products">
          <strong>{{ details.products }} {{ $tc('categories.overview.products') }}</strong>
          {{ $tc('categories.overview.indexed-masc') }}
        </span>
        <span v-else class="empty">{{ $tc('categories.overview.empty.no-products') }}</span>
      </li>
      <li>
        <i class="icon icon-category-18px"></i>
        <span v-if="details.sku">
          <strong>{{ details.sku }} {{ $tc('categories.overview.sku') }}</strong>
          {{ $tc('categories.overview.indexed-masc') }}
        </span>
        <span v-else class="empty">{{ $tc('categories.overview.empty.no-sku') }}</span>
      </li>
      <li>
        <i class="icon icon-category-18px"></i>
        <span v-if="details.brands_total">
          <strong>{{ details.brands_total }} {{ $tc('categories.overview.brand') }}</strong>
          {{ $tc('categories.overview.available') }}
        </span>
        <span v-else class="empty">{{ $tc('categories.overview.empty.no-brand') }}</span>
      </li>
      <li>
        <i class="icon icon-in-store icon-22"></i>
        <span v-if="details.high_season">
          {{ $tc('categories.overview.season') }}:
          <strong class="text-lowercase">{{ details.high_season }}</strong>
        </span>
        <span v-else class="empty">{{ $tc('categories.overview.empty.no-season') }}</span>
      </li>
    </ul>
    <details-skeleton v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="error" />
  </base-card-v2>
</template>

<script>
import DetailsSkeleton from '@/components/categories/category/overview/skeletons/DetailsSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  name: 'CategoryDetails',
  components: {
    DetailsSkeleton,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    details: {
      type: Object,
      default: () => {},
    },
    category: {
      type: String,
      default: '',
    },
    load: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      const categoryName = this.category.charAt(0).toUpperCase() + this.category.slice(1);
      return this.category ? categoryName : this.$t('product.empty.no-name');
    },
    season() {
      let varSeason = '';
      if (this.details.high_season === 'P') {
        varSeason = this.$tc('seasons.spring');
      } else if (this.details.high_season === 'V') {
        varSeason = this.$tc('seasons.summer');
      } else if (this.details.high_season === 'I') {
        varSeason = this.$tc('seasons.winter');
      } else if (this.details.high_season === 'O') {
        varSeason = this.$tc('seasons.fall');
      }
      return varSeason;
    },
  },
  watch: {
    load() {
      this.loading = this.load;
    },
    details() {
      if (this.$_verifyData(this.details)) return;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-22 {
  font-size: 22px;
}

.category {
  color: $gray-700;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.empty {
  color: $gray-500;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 11px 0;
    color: $gray-700;
    border-bottom: 1px solid $gray-300;
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
    }

    span {
      font-size: 12px;
    }

    strong {
      font-weight: 600;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
  }
}
</style>
