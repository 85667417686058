<template>
  <div v-if="data.limit && show" class="list-pagination" :class="{ 'is-lightbox-active': isLightboxActive }">
    <div class="list-pagination__per-page" v-if="show">
      <label for="show-per-page list-limit">{{ $t('pagination.show') }}</label>
      <select
        data-cy="pagination-select"
        v-model="dataProps.limit"
        name="show-per-page"
        class="show-per-page"
        @change="reset"
      >
        <option v-for="(item, index) in limitRange" :key="index" :value="item" :selected="item === dataProps.limit">
          {{ item }}/{{ $t('pagination.per-page') }}
        </option>
      </select>
    </div>
    <div class="pagination d-flex justify-content-center align-items-center">
      <nav v-if="pages.length > 1" aria-label="list pagination">
        <ul class="pagination m-0">
          <!-- Previous -->
          <li class="page-item page-previous mr-2" :class="{ disabled: data.page === 1 }">
            <a class="page-link" href="#" @click.prevent="prevPage">
              <span class="pg-arrows previous-arrow"></span>
            </a>
          </li>
          <!-- Pages -->
          <li
            v-for="(item, index) in pageRange"
            :key="index"
            class="page-item"
            :class="data.page === item ? 'active' : ''"
            :data-cy="`page-number-${item}`"
          >
            <a class="page-link" href="#" @click.prevent="changePage(item)">{{ item }}</a>
          </li>
          <!-- Next -->
          <li class="page-item page-next ml-2" :class="{ disabled: data.page === pages.length }">
            <a class="page-link" href="#" @click.prevent="nextPage">
              <span class="pg-arrows next-arrow"></span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div v-if="pages.length > 1 && showListPages" class="d-flex align-items-center list-pages">
      <label for="go-to-page" class="ml-auto">{{ $t('pagination.go-to-page') }}</label>
      <select id="go-to-page" v-model="dataProps.page" name="go-to-page" @change="emitPageChangeEvent">
        <option v-for="(item, index) in pages" :key="index" :value="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div v-if="showQuantityLines && pages.length > 1" class="d-flex align-items-center list-quantity-pages">
      <span
        ><span data-cy="pagination-limit">{{ lineRange }}</span> de {{ data.count }} linhas</span
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign, no-return-assign */
import bus from '@/helpers/events/bus';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    identify: {
      type: String,
      default: null,
    },
    limitRange: {
      type: Array,
      default: null,
    },
    isLightboxActive: {
      type: Boolean,
      default: null,
    },
    showListPages: {
      type: Boolean,
      default: true,
    },
    showQuantityLines: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handlePageEvents', 'change-page', 'pagination-reset'],
  data() {
    return {
      dataProps: {},
    };
  },
  computed: {
    pageRange() {
      const { pages } = this;
      const maxSize = this.data.size || 5;
      const page = this.data.page || 1;
      let start = Math.max(page - 1 - Math.floor(maxSize / 2), 0);
      let end = start + maxSize;

      if (end >= pages.length) {
        end = pages.length;
        start = Math.max(end - maxSize, 0);
      }

      return pages.slice(start, end);
    },
    pages() {
      const pagesTotal = Math.ceil(this.data.count / this.data.limit);
      const pages = Array(pagesTotal)
        .fill()
        .map((x, i) => (i += 1));
      return pages;
    },
    show() {
      return Math.ceil(this.data.count / this.limitRange[0]) > 1 && !this.empty;
    },
    empty() {
      let $current = this.$parent;

      while ($current && !$current.empty) $current = $current.$parent;

      return $current && $current.empty;
    },
    lineRange() {
      const startLine = (this.data.page - 1) * this.data.limit + 1;
      const endLine = Math.min(this.data.page * this.data.limit, this.data.count);
      return `${startLine}-${endLine}`;
    },
  },
  mounted() {
    this.dataProps = this.data;
    bus.$on(`${this.identify}-pagination-reset`, this.reset);
    this.$emit('pagination-reset', this.reset);
  },
  beforeUnmount() {
    bus.$off(`${this.identify}-pagination-reset`);
  },
  methods: {
    emitPageChangeEvent() {
      bus.$emit(`${this.identify}-change-page`, this.reset);
      this.$emit('change-page', this.reset);
    },
    changePage(page) {
      this.dataProps.page = page;
      this.emitPageChangeEvent();
    },
    nextPage() {
      this.changePage(this.data.page + 1);
    },
    prevPage() {
      this.changePage(this.data.page - 1);
    },
    reset() {
      this.changePage(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-pagination {
  font-size: 12px;
  font-weight: 600;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  label {
    margin-bottom: 0;
    font-weight: normal;
    color: $gray-800;
  }

  label + select {
    display: inline-block;
    margin-left: 10px;
    min-width: 65px;
    border: 1px solid #bbbbbb;
    padding: 0px 24px 0px 10px !important;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    color: $gray-800;
  }

  .list-quantity-pages {
    font-size: 12px;
    color: #555555;
    font-weight: 400;
  }

  .label-per-page {
    margin-left: 10px;
  }

  .pagination {
    .page-item {
      .pg-arrows {
        display: block;
        color: $color-white;
        border: solid $gray-900;
        border-width: 0 1px 1px 0;
        border-radius: 0;
        display: inline-block;
        padding: 3px;

        &.previous-arrow {
          transform: rotate(135deg);
          margin-right: -3px;
        }

        &.next-arrow {
          transform: rotate(-45deg);
          margin-left: -3px;
        }

        &.down-arrow {
          transform: translate(0, -50%) rotate(45deg);
        }

        &.up-arrow {
          transform: rotate(-135deg);
        }
      }
      a {
        background-color: #f4f4f4;
        border: 0 none;
        border-radius: 50%;
        color: $gray-600;
        padding: 6px;
        margin: 0 3px;
        font-size: 12px;
        min-width: 27px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
      }
      &.active a,
      a:hover {
        color: $color-white;
        background-color: $oto-omni;
      }
      &.page-previous,
      &.page-next {
        a {
          background-color: #f4f4f4;
          border-radius: 50%;

          &:hover {
            color: $color-white !important;
            background-color: $oto-omni;
          }
        }
      }
    }
  }
  &.is-lightbox-active {
    padding: 0 35px 0 40px;
  }
}
</style>
