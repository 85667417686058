import { PresetsResponse } from '../models';
import { getTablePresets } from '../services';

export const fetchPresets = (): Promise<PresetsResponse> =>
  getTablePresets().then(response => formatResponse(response?.data));

const formatResponse = (response: PresetsResponse): PresetsResponse => ({
  presets: response?.presets,
  errors: response?.errors,
  success: response?.success,
  warnings: response?.warnings,
});
