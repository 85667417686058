import {
  getAvailableMetrics,
  getParallelCoordinates,
  getSellersResume,
  getStores,
  getTableMetrics,
  getCampaigns,
} from '@/services/oto/indicators';
import { escapeRegExp } from 'lodash';
import { stringParser } from '@/helpers/string-parser.js';
import { assyncStatus } from '@/components/oto/indicators/IndicatorFunnelHeader.vue';

const $_objectToArray = value => {
  return Object.keys(value).map(item => ({
    key: value[item].key,
    name: value[item].name,
  }));
};

const indicators = {
  namespaced: true,
  state: {
    conversionWindow: 30,

    // data of groups
    segments: [
      { id: 'general', name: '_general' },
      { id: 'store', name: '_store' },
      { id: 'seller', name: '_seller' },
      { id: 'campaign', name: '_campaign' },
    ],
    stores: [],
    sellers: [],
    campaigns: [],

    // data of id's
    segmentId: '',
    storeId: null,
    sellerId: null,
    sourceId: null,
    sourceName: null,
    campaignId: null,

    // comparable
    filters: {
      metrics: ['activities_total', 'activities_started', 'revenue_tracked'],
      comparisonStores: [],
    },
    criteria: 'cluster',
    parallelCoordinates: {
      loading: false,
      data: [],
    },
    availableMetrics: {
      loading: true,
      data: [],
    },

    // // batchIds
    getFunnelBatchId: '',
    getMetricsBatchId: '',
    isAssync: false,

    funnel: {},
    isLoadingFunnel: false,
    assyncCodeFunnel: assyncStatus.started,
    hasErrorFunnel: false,

    // edit columns
    metrics: [],
    checkedMetrics: [],
    filterMetrics: [],
    lastCustomConfig: {},
  },

  mutations: {
    // mutations of groups
    SET_STORES(state, storesList) {
      state.stores = storesList;
    },

    SET_SELLERS(state, sellersList) {
      state.sellers = sellersList;
    },

    SET_CAMPAIGNS(state, campaignsList) {
      state.campaigns = campaignsList;
    },

    // mutations of id's
    SET_CONVERSION_WINDOW(state, conversionWindow) {
      state.conversionWindow = conversionWindow;
    },

    SET_SEGMENT_ID(state, segmentId) {
      state.segmentId = segmentId;
    },

    SET_STORE_ID(state, storeId) {
      state.storeId = !storeId ? null : storeId;
    },

    SET_SELLER_ID(state, sellerId) {
      state.sellerId = !sellerId ? null : sellerId;
    },

    SET_CAMPAIGN_ID(state, campaignId) {
      state.campaignId = !campaignId ? null : campaignId;
    },

    SET_SOURCE_ID(state, sourceId) {
      state.sourceId = sourceId;
    },

    SET_SOURCE_NAME(state, sourceName) {
      state.sourceName = sourceName;
    },

    RESET_SELECTED(state) {
      state.segmentId = '';
      state.storeId = null;
      state.sellerId = null;
      state.sourceId = null;
      state.sourceName = null;
      state.campaignId = null;
    },

    RESET_FILTERS(state) {
      state.filters = {
        metrics: ['activities_total', 'activities_started', 'revenue_tracked'],
        comparisonStores: [],
      };
    },

    // edit columns
    SET_METRICS(state, metrics) {
      state.metrics = metrics;
    },
    SET_CHECKED_METRICS(state, checked) {
      state.checkedMetrics = checked;
    },
    SET_FILTER_METRICS(state, metrics) {
      state.filterMetrics = metrics;
    },
    SET_LAST_CUSTOM_CONFIG(state) {
      state.lastCustomConfig = {
        metrics: state.checkedMetrics,
        filterMetrics: state.filterMetrics,
      };
    },
    DELETE_CHECKED_METRIC(state, key) {
      state.filterMetrics = state.filterMetrics.filter(item => item.key !== key);
    },
    RESET_ALL_CHECKED(state) {
      state.checkedMetrics = [];
      state.filterMetrics = [];
    },
    SET_UPDATE_CHECKED_FILTER_METRICS(state, metric) {
      state.filterMetrics = [...state.filterMetrics, metric];
    },

    // comparable
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },

    SET_CRITERIA(state, criteria) {
      state.criteria = criteria;
    },

    SET_AVAILABLE_METRICS(state, availableMetrics) {
      state.availableMetrics.data = availableMetrics;
      state.availableMetrics.loading = false;
    },

    SET_PARALLEL_COORDINATES(state, parallelCoordinates) {
      state.parallelCoordinates.data = parallelCoordinates;
      state.parallelCoordinates.loading = false;
    },

    SET_LOADING(state, obj) {
      const componentType = obj['type'];
      const isLoading = obj['verifyState'];

      switch (componentType) {
        case 'parallel':
          state.parallelCoordinates.loading = isLoading;
          break;
      }
    },

    SET_GET_FUNNEL_BATCH_ID(state, batchId) {
      state.getFunnelBatchId = batchId;
    },

    SET_FUNNEL(state, funnel) {
      state.funnel = funnel;
    },

    SET_IS_LOADING_FUNNEL(state, isLoading) {
      state.isLoadingFunnel = isLoading;
    },

    SET_ASSYNC_CODE_FUNNEL(state, assyncCode) {
      state.assyncCodeFunnel = assyncCode;
    },

    SET_HAS_ERROR_FUNNEL(state, hasError) {
      state.hasErrorFunnel = hasError;
    },

    SET_METRICS_BATCH_ID(state, batchId) {
      state.getMetricsBatchId = batchId;
    },

    SET_IS_ASSYNC(state, isAssync) {
      state.isAssync = isAssync;
    },
  },

  actions: {
    // set groups
    setStores({ commit }) {
      getStores().then(({ data }) => {
        if (!data) {
          commit('SET_STORES', []);
          return;
        }

        const temp = Object.entries(data)?.map(el => {
          return {
            id: el[0],
            name: el[1],
          };
        });

        const stores = [{ id: null, name: '360.indicators.all-stores' }, ...temp];
        commit('SET_STORES', stores);
      });
    },

    setCampaigns({ commit }) {
      getCampaigns().then(data => {
        if (!data?.data) {
          commit('SET_CAMPAIGNS', []);
          return;
        }

        const temp = data?.data;
        const campaigns = [{ id: null, name: 'Todas' }, ...temp];

        commit('SET_CAMPAIGNS', campaigns);
      });
    },

    setSellers({ commit }, { term } = {}) {
      getSellersResume({ term }).then(data => {
        if (!data?.data) {
          commit('SET_SELLERS', []);
          return;
        }

        const temp = data?.data?.data;
        const sellers = [{ id: null, fullName: '360.indicators.all' }, ...temp];

        commit('SET_SELLERS', sellers);
      });
    },

    // set id's
    setConversionWindow({ commit }, conversionWindow) {
      commit('SET_CONVERSION_WINDOW', conversionWindow);
    },

    setSegmentId({ commit }, segmentId) {
      commit('SET_SEGMENT_ID', segmentId);
    },

    setStoreId({ commit }, storeId) {
      commit('SET_STORE_ID', storeId);
    },

    setSellerId({ commit }, sellerId) {
      commit('SET_SELLER_ID', sellerId);
    },

    setCampaignId({ commit }, campaignId) {
      commit('SET_CAMPAIGN_ID', campaignId);
    },

    setSourceId({ commit }, sourceId) {
      commit('SET_SOURCE_ID', sourceId);
    },

    setSourceName({ commit }, sourceName) {
      commit('SET_SOURCE_NAME', sourceName);
    },

    resetSelected({ commit }) {
      commit('RESET_SELECTED');
    },

    resetFilters({ commit }) {
      commit('RESET_FILTERS');
    },

    // edit columns
    updateMetrics({ commit }) {
      getTableMetrics().then(({ data }) => {
        commit('SET_METRICS', $_objectToArray(data.table_metrics.metrics));
      });
    },
    updateCheckedMetrics({ commit }, payload) {
      commit('SET_CHECKED_METRICS', payload);
    },
    updateLastCustomConfig({ commit }) {
      commit('SET_LAST_CUSTOM_CONFIG');
    },
    updateFilterMetrics({ commit }, payload) {
      commit('SET_FILTER_METRICS', payload);
    },
    deleteCheckedMetric({ commit }, payload) {
      commit('DELETE_CHECKED_METRIC', payload);
    },
    resetAllStoreMetrics({ commit }) {
      commit('RESET_ALL_CHECKED');
    },
    setUpdateCheckedFilterMetrics({ commit }, payload) {
      commit('SET_UPDATE_CHECKED_FILTER_METRICS', payload);
    },

    // comparable
    setFilters({ commit }, payload) {
      commit('SET_FILTERS', payload);
    },

    setCriteriaName({ commit }, payload) {
      commit('SET_CRITERIA', payload);
    },

    setAvailableMetrics({ commit }) {
      getAvailableMetrics().then(response => {
        const data = response?.data?.data;
        commit('SET_AVAILABLE_METRICS', data);
      });
    },

    setParallelCoordinates({ state, commit }) {
      const lookback = state.conversionWindow;
      commit('SET_LOADING', { type: 'parallel', verifyState: true });
      getParallelCoordinates(
        state.sellerId,
        state.storeId,
        state.campaignId,
        state.filters.comparisonStores.join(),
        state.filters.metrics.join(),
        lookback,
      )
        .then(({ data }) => {
          const parallelCoordinates = data.data;
          commit('SET_PARALLEL_COORDINATES', parallelCoordinates);
        })
        .finally(() => {
          commit('SET_LOADING', { type: 'parallel', verifyState: false });
        });
    },

    clearParallelCoordinates({ commit }) {
      commit('SET_PARALLEL_COORDINATES', []);
    },

    setIndicatorsByTerm({ dispatch }, { term }) {
      dispatch('setSellers', { term });
    },

    setGetFunnelBatchId({ commit }, payload) {
      commit('SET_GET_FUNNEL_BATCH_ID', payload);
    },

    setFunnel({ commit }, payload) {
      commit('SET_FUNNEL', payload);
    },

    setIsLoadingFunnel({ commit }, payload) {
      commit('SET_IS_LOADING_FUNNEL', payload);
    },

    setAssyncCodeFunnel({ commit }, payload) {
      commit('SET_ASSYNC_CODE_FUNNEL', payload);
    },

    setHasErrorFunnel({ commit }, payload) {
      commit('SET_HAS_ERROR_FUNNEL', payload);
    },

    setMetricsBatchId({ commit }, payload) {
      commit('SET_METRICS_BATCH_ID', payload);
    },

    setIsAssync({ commit }, payload) {
      commit('SET_IS_ASSYNC', payload);
    },
  },

  getters: {
    // get groups
    getSegments: state => state.segments,
    getStores: state => state.stores,
    getSellers: state => state.sellers,
    getCampaigns: state => state.campaigns,

    // get id's
    getConversionWindow: state => state.conversionWindow,
    getSegmentId: state => state.segmentId,
    getStoreId: state => state.storeId,
    getSellerId: state => state.sellerId,
    getSourceId: state => state.sourceId,
    getSourceName: state => state.sourceName,
    getCampaignId: state => state.campaignId,

    // edit columns
    getAllCheckedMetrics: state => {
      return {
        metrics: state.checkedMetrics,
      };
    },
    getCheckedMetrics: state => state.checkedMetrics?.join(','),
    getLastConfigMetrics: state => {
      return state.lastCustomConfig;
    },
    getFilteredMetrics:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.metrics.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },

    //comparable
    getFilters: state => state.filters,
    getCriteria: state => state.criteria,
    getActiveSellerObject: (state, getters) => getters.getSellerObject(state.sellerId),
    getSellerObject: state => sellerId => state.sellers.filter(seller => seller.id === sellerId)[0],
    getActiveStoreObject: (state, getters) => getters.getStoreObject(state.storeId),
    getStoreObject: state => storeId => state.stores.filter(store => store.id === storeId)[0],
    getActiveSCampaignObject: (state, getters) => getters.getCampaignObject(state.campaignId),
    getCampaignObject: state => campaignId => state.campaigns.filter(campaign => campaign.id === campaignId)[0],
    parallelCoordinatesHasData: state => state.parallelCoordinates.data.series?.length,
    getFunnelBatchId: state => state.getFunnelBatchId,
    getMetricsBatchId: state => state.getMetricsBatchId,
    getIsAssync: state => state.isAssync,
  },
};

export default indicators;
