const mixin = {
  methods: {
    $_isSelected(key, obj) {
      return !!obj.includes(key);
    },
    $_toggleSelection(key, obj) {
      if (this.$_isSelected(key, obj)) this.$_unselectOption(key, obj);
      else this.$_selectOption(key, obj);
    },
    $_selectOption(key, obj) {
      if (!this.$_isSelected(key, obj)) obj.push(key);
    },
    $_unselectOption(key, obj) {
      if (!this.$_isSelected(key, obj)) return;
      const index = obj.indexOf(key);
      obj.splice(index, 1);
    },
    $_objectToArray(value) {
      return Object.keys(value).map(item => ({
        key: value[item].key,
        name: value[item].name,
      }));
    },
  },
};

export default mixin;
