<template>
  <div class="cashback-report-metrics card">
    <template v-if="$_verifyLoaded('done')">
      <div v-for="(metric, key) in metrics" :key="key" class="crm__group-metrics">
        <h4 class="crm__metric-title">{{ metric.title }}</h4>
        <p class="crm__metric-amount">{{ formatMetric(metric) }}</p>
      </div>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card
      v-show="$_verifyLoaded('info')"
      ref="message-error"
      :error="hasError"
      :show-msg="false"
      :height="'45px'"
    />
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { mapGetters } from 'vuex';

import { getMetrics } from '@/services/oto/cashbackReport';

// helpers
import { valuesByFormat } from '@/utilities/formatters';
import { verifyMixin } from '@/helpers/mixins';

import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'CashbackReportMetrics',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      metrics: [],
      threshold: 1099999,
    };
  },

  computed: {
    ...mapGetters('cashbackReport', ['getStoreId', 'getSellerId']),
  },

  mounted() {
    this.fetchData();

    // bus called when dates are changed globally
    bus.$on('render-cards', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-store', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-seller', () => {
      this.fetchData();
    });
    bus.$on('cashback-report-change-segment-general', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off([
      'render-cards',
      'cashback-report-change-store',
      'cashback-report-change-seller',
      'cashback-report-change-segment-general',
    ]);
  },

  methods: {
    fetchData() {
      this.$_reqConfig();

      getMetrics({
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        metrics: 'cashback_orders,cashback_revenue,revenue_invest,balance_generated,orders_balance,balance_used_rate',
      })
        .then(({ data }) => {
          this.$_verifyHasData(data.data);
          this.metrics = data.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })

        .finally(() => {
          this.loading = false;
        });
    },

    formatMetric(metric) {
      return valuesByFormat(metric?.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback-report-metrics {
  display: flex;
  flex-direction: row;
  min-height: 95px;
  height: 95px;
  position: relative;

  .crm__group-metrics {
    width: 23%;

    &:first-child {
      margin-left: 5px;
      width: 20%;
    }
  }

  .crm__metric-title {
    color: $gray-700;
    font-size: 12px;
    margin: 0;
  }

  .crm__metric-amount {
    color: $oto-omni;
    font-weight: 600;
    font-size: 16px;
    padding-top: 10px;
    margin: 0;
  }
}
</style>
