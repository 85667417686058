import { formatDecimal, formatCurrency, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

const mixin = {
  methods: {
    $_formatValue(obj, keyName, shortable = true) {
      let val = '';
      if (obj.total === '' && obj.format === 'text') {
        val = '-';
        return val;
      }
      if (!(obj.total || obj.value)) {
        if (obj.format !== null) {
          val = '0';
        } else {
          return val;
        }
      }
      switch (obj.format) {
        case valueType.monetary:
          val = this.$_shorterCurrency(obj[keyName], shortable);
          break;
        case valueType.percent:
          val = formatNumber(obj[keyName], '0.00%');
          break;
        case valueType.percentual:
          val = formatNumber(obj[keyName], '0.00%');
          break;
        case valueType.decimal:
          val = formatDecimal(obj[keyName], 3);
          break;
        case valueType.integer:
          val = formatDecimal(obj[keyName]);
          break;
        case valueType.text:
          val = obj[keyName];
          break;
        default:
          val = `${obj[keyName]} ${obj.format}`;
          break;
      }
      return val;
    },

    $_shorterCurrency(value, shortable = true) {
      return value >= 1000000 && shortable ? formatCurrency(value, { shortNumber: true }) : formatCurrency(value);
    },

    $_percentageCalculation(num1, num2) {
      const n1 = parseInt(num1);
      const n2 = parseInt(num2);
      if (n1 === 0 || n2 === 0 || !n1 || !n2) return '(0%)';

      // calc and round
      const calc = ((num1 / num2) * 100).toFixed(1);

      // return on percentage format
      return `(${calc}%)`;
    },
  },
};

export default mixin;
