/* eslint-disable no-param-reassign */
import {
  escapeRegExp,
  pick,
  isNil,
  isEmpty,
  sortBy,
  orderBy,
  difference,
  cloneDeep,
  has,
  set,
  each,
  isArray,
  find,
} from 'lodash';
import { stringParser, createLabelObject } from '@/helpers/string-parser';
import { getRandomColors } from '@/utilities/general';
import router from '@/router';

import {
  getPersona,
  getPersonas,
  getComparedPersonas,
  checkAccount360,
  getAvailableLabels,
  getPersonaCount,
} from '@/services/personas';

const defaultPersonaDetail = {
  name: '',
  description: '',
  group: '', // persona's group ID.
  persona360: false,
  count: 0,
  query: {
    include: [],
    exclude: [],
  },
};

const defaultState = {
  all: [],
  personaMain: null,
  personaCompared: 0,
  dataTable: [],
  list: [],
  statsList: [],
  personasGroupsType: {},
  requestsCounter: 0,
  personaDetailMode: '',
  brandHas360: null,
  personaDetail: cloneDeep(defaultPersonaDetail),
  personaDetailReadOnly: cloneDeep(defaultPersonaDetail),
  personaDetailUnsaved: [],
  serieFill: {},
  labelsAvailable: [],
  loadingGlobal: true,
  hasErrorGlobal: false,
  isEmptyGlobal: false,
  verifyIsLoad: false,
  personasCompareBoards: ['compareOverviewPersonas', 'compareProfilePersonas'],
  personasCompareBoardsLoaded: 0,
};

const personas = {
  namespaced: true,
  state: cloneDeep(defaultState),
  mutations: {
    SET_PERSONAS(state, list) {
      state.all = list;
    },
    SET_PERSONA_MAIN(state, id) {
      state.personaMain = id;
    },
    SET_PERSONA_COMPARED(state, id) {
      state.personaCompared = id;
    },
    SET_DATATABLE(state, data) {
      state.dataTable = data;
    },
    SET_PERSONAS_LIST(state, personas) {
      state.list = personas;
      state.verifyIsLoad = true;

      if (isEmpty(personas)) {
        state.isEmptyGlobal = true;
        state.loadingGlobal = false;
      } else state.isEmptyGlobal = false;
    },
    SET_PERSONAS_GROUPS_TYPE(state, personasType) {
      state.personasGroupsType = personasType;
    },
    SET_LOADING(state, isLoading) {
      state.loadingGlobal = isLoading;
    },
    SET_ERROR(state, hasError) {
      state.hasErrorGlobal = hasError;
      state.loadingGlobal = false;
    },
    SET_PERSONA_DETAIL(state, persona) {
      const personaInfo = cloneDeep(persona);
      if (state.personaDetailMode === 'create' && !isNil(persona.personaId)) {
        // Run in DupicateMode,
        // - remove name
        // - check if has 'query', 'include' and 'exclude' properties and creates if doesn't exist
        personaInfo['name'] = '';
        isArray(personaInfo.query) && (personaInfo.query = {});
        each(['include', 'exclude'], queryItem => {
          !has(personaInfo, `query.${queryItem}`) && set(personaInfo, `query.${queryItem}`, []);
        });
      }
      state.personaDetail = Object.assign({}, personaInfo);
      state.personaDetailReadOnly = Object.assign({}, personaInfo);
    },
    RESET_PERSONA_DETAIL(state) {
      state.personaDetail = cloneDeep(defaultPersonaDetail);
      state.personaDetailReadOnly = cloneDeep(defaultPersonaDetail);
      state.personaDetailUnsaved = [];
    },
    SET_MODE(state, mode) {
      state.personaDetailMode = mode;
    },
    SET_BRAND_HAS_360(state, brandHas360) {
      state.brandHas360 = brandHas360;
    },
    RESET_BRAND_HAS_360(state) {
      state.brandHas360 = null;
    },
    SET_NAME(state, newName) {
      state.personaDetail.name = newName;
      if (!state.personaDetailUnsaved.includes('name')) {
        state.personaDetailUnsaved.push('name');
      }
    },
    SET_GROUP(state, newGroup) {
      state.personaDetail.groupId = newGroup;
      if (!state.personaDetailUnsaved.includes('groupId')) {
        state.personaDetailUnsaved.push('groupId');
      }
    },
    SET_DESCRIPTION(state, newDescription) {
      state.personaDetail.description = newDescription;
      if (!state.personaDetailUnsaved.includes('description')) {
        state.personaDetailUnsaved.push('description');
      }
    },
    SET_PERSONA360(state) {
      state.personaDetail.persona360 = !state.personaDetail.persona360;
      if (!state.personaDetailUnsaved.includes('persona360')) {
        state.personaDetailUnsaved.push('persona360');
      }
    },
    SET_LABELS_AVAILABLE(state, payload) {
      const labels = payload.map(label => {
        return {
          ...label,
          color: getRandomColors(),
        };
      });
      state.labelsAvailable = labels;
    },
    /**
     * Add a group
     * @param {Boolean} include Set type of condition: true for include or false for exclude
     */
    ADD_GROUP(state, include) {
      if (include) {
        state.personaDetail.query.include.push([]);
      } else {
        state.personaDetail.query.exclude.push([]);
      }
    },
    /**
     * Remove a group
     * @param {Object} data Object with condition type (include or exclude) and group index
     */
    REMOVE_GROUP(state, { include = true, group }) {
      if (include) {
        if (state.personaDetail.query.include.length > 1) {
          state.personaDetail.query.include.splice(group, 1);
        }
      } else {
        state.personaDetail.query.exclude.splice(group, 1);
      }
    },
    /**
     * Add a serie to a group
     * @param {Object} data Object with serie data, condition type (boolean: include - true - or exclude - false) and group index
     */
    ADD_SERIE(state, { data, include = true, group = 0 }) {
      const condition = createLabelObject(data);
      if (include) state.personaDetail.query.include[group].push(...condition);
      else state.personaDetail.query.exclude[group].push(...condition);
    },
    /**
     * Remove a serie
     * @param {Object} data Object with condition type (include or exclude), group index and serie index
     */
    REMOVE_SERIE(state, { include = true, group, serie }) {
      if (include) {
        state.personaDetail.query.include[group].splice(serie, 1);
      } else {
        state.personaDetail.query.exclude[group].splice(serie, 1);
      }
    },
    /**
     * Update serie data
     * @param {*} state
     * @param {Object} data Object with serie index, group index, condition type (boolean: include - true - or exclude - false) and serie data
     */
    UPDATE_SERIE(state, { group, include, data }) {
      const condition = createLabelObject(data);

      if (include) state.personaDetail.query.include[group] = condition;
      else state.personaDetail.query.exclude[group] = condition;
    },
    UPDATE_COUNT(state, getters) {
      const { personaId } = state.personaDetail;
      const isNilId = isNil(personaId);
      const isDuplicateMode = !isNilId && state.personaDetailMode === 'create';
      // send ID to personaCount if isn't null
      const params = {
        ...(isNilId || isDuplicateMode ? { query: JSON.stringify(getters.query) } : personaId),
      };

      state.requestsCounter += 1;

      getPersonaCount(params).then(({ data }) => {
        state.personaDetail.count = Number(data?.data?.count);
        state.requestsCounter -= 1;
      });
    },
    SET_LOADED_BOARD(state, increment) {
      state.personasCompareBoardsLoaded = increment === 0 ? 0 : state.personasCompareBoardsLoaded + 1;
    },
  },
  actions: {
    updatePersonas({ commit, dispatch }) {
      getPersonas().then(data => {
        const personasListOrdered = orderBy(data.data.data, 'name');
        const idFromRoute = Number(router.currentRoute?.value?.params?.id);
        const idFound = find(personasListOrdered, persona => persona.id === idFromRoute) !== undefined;

        if (!idFound) router.push('/personas');
        commit('SET_PERSONAS', data.data.data);
        dispatch('updatePersonaMain', idFound ? idFromRoute : personasListOrdered[0].id);
        dispatch('updatePersonaCompared', 0);
      });
    },
    updatePersonaMain({ commit, getters, dispatch }, id) {
      if (getters.getPersonaMain !== null && id === getters.getPersonaMain.id) return;
      commit('SET_PERSONA_MAIN', getters.getById(id));
      dispatch('updateDataTable');
    },
    updatePersonaCompared({ commit, getters, dispatch }, id) {
      if (getters.getPersonaCompared !== null && id === getters.getPersonaCompared.id) return;
      if (id === 0) {
        commit('SET_PERSONA_COMPARED', { name: 'Toda a base', id: 0 });
      } else {
        commit('SET_PERSONA_COMPARED', getters.getById(id));
      }
      dispatch('updateDataTable');
    },
    updatePersonasStats({ dispatch, state }) {
      if (state.isEmptyGlobal) return;
      // Iterate through statsList, sequentially retrieving personas' stats
      // Use reverse order in order to Array.pop() work as expected
      state.statsList = state.list.map(item => item.id).sort((a, b) => b - a);
      dispatch('getPersonaStats');
    },
    getPersonaStats({ dispatch, state }) {
      if (!state.statsList.length) return;

      // Get last value
      const id = state.statsList.pop();
      const persona = state.list.filter(persona => persona.id === id)[0];

      getPersona(id).then(({ data }) => {
        if (data && data.data?.count) {
          const { count, revenue, base_percent, revenue_percent } = data.data;
          persona.count = count;
          persona.revenue = revenue;
          persona.base_percent = base_percent;
          persona.revenue_percent = revenue_percent;
        }

        // Use recursion to iterate next item
        dispatch('getPersonaStats');
      });
    },
    updateDataTable({ commit, getters, state }) {
      const boards = [];
      commit('SET_LOADED_BOARD', 0);
      state.personasCompareBoards.forEach(endpoint => {
        getComparedPersonas(getters.getPersonaMain.id, getters.getPersonaCompared.id, endpoint).then(data => {
          boards.push(data.data.data);
          commit('SET_LOADED_BOARD', 1);
        });
      });
      commit('SET_DATATABLE', boards);
    },
    getPersonasList({ dispatch, commit }) {
      commit('SET_LOADING', true);
      getPersonas()
        .then(({ data }) => {
          commit('SET_PERSONAS_GROUPS_TYPE', data.groupTypes);
          commit('SET_PERSONAS_LIST', data.data);
          dispatch('updatePersonasStats');
        })
        .catch(() => {
          commit('SET_PERSONAS_LIST', defaultState.list);
          commit('SET_ERROR', true);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    getPersona({ state, dispatch, commit }, id) {
      commit('RESET_PERSONA_DETAIL');
      if (id !== 0) {
        getPersona(id).then(({ data }) => {
          commit('SET_PERSONA_DETAIL', data.data);
          if (state.personaDetailMode === 'create') dispatch('updateCount');
        });
      }
    },
    setMode({ commit }, mode) {
      commit('SET_MODE', mode);
    },
    /**
     * @description add/change persona name
     */
    actionSetName({ commit }, newName) {
      commit('SET_NAME', newName);
    },
    actionSetGroup({ commit }, newGroup) {
      commit('SET_GROUP', newGroup);
    },
    /**
     * @description add/change persona description
     */
    actionSetDescription({ commit }, newDescription) {
      commit('SET_DESCRIPTION', newDescription);
    },
    /**
     * @description enable/disable 360 feature
     */
    actionSetPersona360({ commit }) {
      commit('SET_PERSONA360');
    },
    actionResetCheckAccount360({ commit }) {
      commit('RESET_BRAND_HAS_360');
    },
    actionCheckAccount360({ state, commit }) {
      if (state.brandHas360 !== null) return;
      checkAccount360().then(({ data }) => {
        commit('SET_BRAND_HAS_360', data.data['360']);
      });
    },
    actionAddAvailableLabels({ commit }) {
      getAvailableLabels().then(({ data }) => {
        commit('SET_LABELS_AVAILABLE', data?.data);
      });
    },
    addGroup({ commit }, { include = true } = {}) {
      commit('ADD_GROUP', include);
    },
    addSerie({ commit, dispatch }, data) {
      commit('ADD_SERIE', data);
      dispatch('updateCount');
    },
    removeGroup({ commit, dispatch }, data) {
      commit('REMOVE_GROUP', data);
      dispatch('updateCount');
    },
    removeSerie({ commit, dispatch }, data) {
      commit('REMOVE_SERIE', data);
      dispatch('updateCount');
    },
    updateSerie({ commit, dispatch }, data) {
      commit('UPDATE_SERIE', data);
      dispatch('updateCount');
    },
    updateCount({ commit, getters }) {
      commit('UPDATE_COUNT', getters);
    },
  },
  getters: {
    getAll: state => state.all,
    getAllFiltered: state => persona => difference(state.all, [persona]),
    getAllFilteredMain: state =>
      sortBy(
        state.all.filter(persona => persona.id !== state.personaCompared.id),
        'name',
      ),
    getAllFilteredCompared: state =>
      sortBy(
        state.all.filter(persona => persona.id !== state.personaMain.id),
        'name',
      ),
    getAllSelecteds: state => [state.personaMain, state.personaCompared],
    getSelectedByContext: state => context => context === 'ctx-main' ? state.personaMain : state.personaCompared,
    getById: state => id => state.all.filter(persona => persona.id === id)[0],
    getPersonaMain: state => state.personaMain,
    getPersonaCompared: state => state.personaCompared,
    getFirstLoad: state => state.verifyIsLoad,
    getLoading: state => state.loadingGlobal,
    getIsEmpty: state => state.isEmptyGlobal,
    getHasError: state => state.hasErrorGlobal,
    getDataTable: state => state.dataTable,
    getFilteredPersonasList:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.list.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getPersonasGroupsType: state => state.personasGroupsType,
    areRulesEmpty: state => {
      const { include, exclude } = state.personaDetail?.query;
      return !include?.length && !exclude?.length;
    },
    getPersonaDetailUnsaved: state => {
      let group = -1;
      // get groupId and changes to group - needed to update personas group
      const { groupId } = state.personaDetail;
      if (groupId) group = groupId;
      const picked = pick(state.personaDetail, state.personaDetailUnsaved);
      return Object.assign(picked, { group });
    },
    getLabelDetails: state => labelId => {
      return state.labelsAvailable.find(item => item.id === labelId);
    },
    getPersonaDetailUnsavedCompose: (state, getters) => {
      const { personaId } = state.personaDetail;

      const objectReady = getters.getPersonaDetailUnsaved;
      Object.assign(objectReady, { personaId });

      return objectReady;
    },
    getFilteredLabels:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.labelsAvailable.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    formateColumns:
      (state, getters) =>
      (keys, fill, isLastLevel = false) => {
        const options = [];

        keys.forEach(key => {
          const chaves = Object.keys(fill[key]);
          const values = isLastLevel ? fill[key] : getters.formateColumns(chaves, fill[key], true);
          options.push({
            key,
            ...(isLastLevel ? values : { columns: values }),
          });
        });

        return options;
      },
    columnOptions: (state, getters) => {
      const { serieFill } = state;
      const keys = Object.keys(serieFill);

      return getters.formateColumns(keys, serieFill);
    },
    /**
     * @description create an object with valid include/exclude arrays from personaDetail.query
     */
    query: state => {
      const obj = {};
      const { include, exclude } = state.personaDetail.query;
      if (!isEmpty(include) && !isEmpty(include[0])) Object.assign(obj, { include });
      if (!isEmpty(exclude) && !isEmpty(exclude[0])) Object.assign(obj, { exclude });
      return obj;
    },
    /**
     * @description check if duplicate mode in persona details page
     */
    duplicateModeEnabled: state => {
      return state.personaDetailMode === 'create' && !isNil(state.personaDetail.personaId);
    },
    /**
     * @description check if has loaded all compare boards
     */
    personasCompareLoaded: state => {
      return state.personasCompareBoardsLoaded >= state.personasCompareBoards.length;
    },
  },
};

export default personas;
