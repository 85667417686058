<template>
  <div :class="{ loadHeight: loading || hasError || isEmpty }" class="card campaigns-detail-purchase-channel">
    <div v-if="$_verifyLoaded('done')">
      <div class="row">
        <div class="col">
          <h3 class="card-title d-flex align-items-center">
            <i class="icon icon-money"></i>
            <span>{{ $t('purchase-channel') }}</span>
          </h3>
        </div>
      </div>
      <div class="row">
        <!-- Left -->
        <div class="col-6">
          <div v-if="revenue !== undefined" class="metric">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('revenue') }}
            </div>
            <label class="data-value w-100 color-oto-brand-omni">
              {{ formatCurrency(revenue) }}
            </label>
          </div>
          <div v-if="revenue" class="metric mt-3">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('revenue-by-channel') }}
            </div>
            <div v-if="revenueOff !== undefined" class="data-value w-100">
              <minibarchart :prop-data="revenueByChannel"></minibarchart>
            </div>
          </div>
          <div v-if="avgOrderValue !== undefined" class="metric column-bottom">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('aov-ticket') }}
            </div>
            <label class="data-value w-100 color-oto-brand-omni">
              {{ formatCurrency(avgOrderValue) }}
            </label>
          </div>
          <div v-if="avgOrderValue" class="metric mt-3">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('ticket-by-channel') }}
            </div>
            <div class="data-value w-100">
              <minibarchart :prop-data="ticketByChannel"></minibarchart>
            </div>
          </div>
        </div>
        <!-- Right -->
        <div class="col-6">
          <div v-if="customersTotal !== undefined" class="metric">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('clients') }}
            </div>
            <label class="data-value w-100 color-oto-brand-omni"> {{ $_formatDecimal(customersTotal) }} </label>
          </div>
          <div v-if="customersTotal" class="metric mt-3">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('clients-by-channel') }}
            </div>
            <div v-if="revenueOff !== undefined" class="data-value w-100">
              <minibarchart :prop-data="clientsByChannel"></minibarchart>
            </div>
          </div>
          <div v-if="orders !== undefined" class="metric column-bottom">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $tc('order', 0) }}
            </div>
            <label class="data-value w-100 color-oto-brand-omni"> {{ $_formatDecimal(orders) }} </label>
          </div>
          <div v-if="orders" class="metric mt-3">
            <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
              {{ $t('orders-by-channel') }}
            </div>
            <div class="data-value w-100">
              <minibarchart :prop-data="ordersByChannel"></minibarchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import MiniBarChart from '@/components/MiniBarChart';
import getMetrics from '@/services/getmetrics';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { formatDecimal, formatCurrency } from '@/utilities/formatters';
import { getNumeral } from '@/utilities/numbers';

export default {
  components: {
    minibarchart: MiniBarChart,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      revenueByChannel: undefined,
      ticketByChannel: undefined,
      clientsByChannel: undefined,
      ordersByChannel: undefined,
      revenue: undefined,
      avgOrderValue: undefined,
      customersTotal: undefined,
      orders: undefined,
      reference: this.$route.params.id,
      revenueOff: undefined,
      revenueOn: undefined,
      revenueApp: undefined,
      customersOff: undefined,
      customersOn: undefined,
      customersApp: undefined,
      avgOrderValueOff: undefined,
      avgOrderValueOn: undefined,
      avgOrderValueApp: undefined,
      allowOff: undefined,
      allowOn: undefined,
      allowApp: undefined,
    };
  },
  mounted() {
    this.fetch_data();
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();
      getMetrics({
        metrics: [
          'revenue',
          'avg_order_value',
          'customers_total',
          'orders',
          'revenue_off',
          'revenue_on',
          'revenue_app',
          'customers_off',
          'customers_on',
          'customers_app',
          'avg_order_value_off',
          'avg_order_value_on',
          'avg_order_value_app',
          'orders_off',
          'orders_on',
          'orders_app',
        ],
        dash: 'campaigns',
        campaign: this.reference,
      })
        .then(data => {
          const { metrics } = data.data;
          if (this.$_verifyHasData(metrics)) return;

          this.revenue = getNumeral(metrics.revenue.data.value.amount);
          this.avgOrderValue = getNumeral(metrics.avg_order_value.data.value.amount);
          this.customersTotal = getNumeral(metrics.customers_total.data.value.amount);
          this.orders = getNumeral(metrics.orders.data.value.amount);
          this.revenueOff = getNumeral(metrics.revenue_off.data.value.amount);
          this.revenueOn = getNumeral(metrics.revenue_on.data.value.amount);
          this.revenueApp = getNumeral(metrics.revenue_app.data.value.amount);
          this.customersOff = getNumeral(metrics.customers_off.data.value.amount);
          this.customersOn = getNumeral(metrics.customers_on.data.value.amount);
          this.customersApp = getNumeral(metrics.customers_app.data.value.amount);
          this.avgOrderValueOff = getNumeral(metrics.avg_order_value_off.data.value.amount);
          this.avgOrderValueOn = getNumeral(metrics.avg_order_value_on.data.value.amount);
          this.avgOrderValueApp = getNumeral(metrics.avg_order_value_app.data.value.amount);
          this.ordersOff = getNumeral(metrics.orders_off.data.value.amount);
          this.ordersOn = getNumeral(metrics.orders_on.data.value.amount);
          this.ordersApp = getNumeral(metrics.orders_app.data.value.amount);

          this.allowOff = metrics.orders_off.has_data;
          this.allowOn = metrics.orders_on.has_data;
          this.allowApp = metrics.orders_app.has_data;

          this.fillJson();
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fillJson() {
      this.revenueByChannel = {
        title: '',
        totalAmount: 1,
        suffix: '',
        prefix: '',
        data: [
          {
            text: this.$i18n.tc('in-store'),
            type: 'percentage',
            amount: formatCurrency(this.revenueOff),
            percentageamount: ((this.revenueOff / this.revenue) * 100).toFixed(0),
            typeclient: 'off',
            hidden: !this.allowOff,
          },
          {
            text: this.$i18n.t('ecommerce'),
            type: 'percentage',
            amount: formatCurrency(this.revenueOn),
            percentageamount: ((this.revenueOn / this.revenue) * 100).toFixed(0),
            typeclient: 'on',
            hidden: !this.allowOn,
          },
          {
            text: this.$i18n.t('app'),
            type: 'percentage',
            amount: formatCurrency(this.revenueApp),
            percentageamount: ((this.revenueApp / this.revenue) * 100).toFixed(0),
            colorbase: 'blue',
            hidden: !this.allowApp,
          },
        ],
      };
      this.ticketByChannel = {
        title: '',
        totalAmount: 1,
        suffix: '',
        prefix: '',
        data: [
          {
            text: this.$i18n.tc('in-store'),
            type: 'percentage',
            amount: formatCurrency(this.avgOrderValueOff),
            percentageamount: ((this.avgOrderValueOff / this.avgOrderValue) * 100).toFixed(0),
            typeclient: 'off',
            hidden: !this.allowOff,
          },
          {
            text: this.$i18n.t('ecommerce'),
            type: 'percentage',
            amount: formatCurrency(this.avgOrderValueOn),
            percentageamount: ((this.avgOrderValueOn / this.avgOrderValue) * 100).toFixed(0),
            typeclient: 'on',
            hidden: !this.allowOn,
          },
          {
            text: this.$i18n.t('app'),
            type: 'percentage',
            amount: formatCurrency(this.avgOrderValueApp),
            percentageamount: ((this.avgOrderValueApp / this.avgOrderValue) * 100).toFixed(0),
            colorbase: 'blue',
            hidden: !this.allowApp,
          },
        ],
      };
      this.clientsByChannel = {
        title: '',
        totalAmount: 1,
        suffix: '',
        prefix: '',
        data: [
          {
            text: this.$i18n.tc('in-store'),
            type: 'percentage',
            amount: formatDecimal(this.customersOff),
            percentageamount: ((this.customersOff / this.customersTotal) * 100).toFixed(0),
            typeclient: 'off',
            hidden: !this.allowOff,
          },
          {
            text: this.$i18n.t('ecommerce'),
            type: 'percentage',
            amount: formatDecimal(this.customersOn),
            percentageamount: ((this.customersOn / this.customersTotal) * 100).toFixed(0),
            typeclient: 'on',
            hidden: !this.allowOn,
          },
          {
            text: this.$i18n.t('app'),
            type: 'percentage',
            amount: formatDecimal(this.customersApp),
            percentageamount: ((this.customersApp / this.customersTotal) * 100).toFixed(0),
            colorbase: 'blue',
            hidden: !this.allowApp,
          },
        ],
      };
      this.ordersByChannel = {
        title: '',
        totalAmount: 1,
        suffix: '',
        prefix: '',
        data: [
          {
            text: this.$i18n.tc('in-store'),
            type: 'percentage',
            amount: formatDecimal(this.ordersOff),
            percentageamount: ((this.ordersOff / this.orders) * 100).toFixed(0),
            typeclient: 'off',
            hidden: !this.allowOff,
          },
          {
            text: this.$i18n.t('ecommerce'),
            type: 'percentage',
            amount: formatDecimal(this.ordersOn),
            percentageamount: ((this.ordersOn / this.orders) * 100).toFixed(0),
            typeclient: 'on',
            hidden: !this.allowOn,
          },
          {
            text: this.$i18n.t('app'),
            type: 'percentage',
            amount: formatDecimal(this.ordersApp),
            percentageamount: ((this.ordersApp / this.orders) * 100).toFixed(0),
            colorbase: 'blue',
            hidden: !this.allowApp,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-detail-purchase-channel {
  .column-bottom {
    margin-top: 40px;

    .value-label {
      margin-top: 0 !important;
    }
  }
  &.loadHeight {
    height: 364px;
  }
}
</style>
