<template>
  <div class="nav-wrapper">
    <ul id="lightbox-nav-tab" class="nav nav-tabs navbar-inverse" role="tablist">
      <li v-for="item in tabList" :key="item.type" class="nav-item">
        <a class="nav-link" :class="item.type === tabActive ? 'active' : ''" @click="updateTab(item.type)">{{
          item.name
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';

export default {
  name: 'LightboxTabItem',
  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
    tabActive: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateTab(item) {
      bus.$emit('lightbox-update-tab', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-wrapper {
  margin: 0 50px;
  .nav-item {
    margin-top: -1px;
    .nav-link {
      padding: 0.8rem 2rem 1rem;
      border-radius: 0;
      border-width: 0;
      border-color: transparent;
      color: $gray-700;
      cursor: pointer;
      font-weight: 600;
      font-size: 12px;
      border-bottom: 3px solid transparent;
      &.active {
        border-bottom: 3px solid $oto-omni;
        color: $oto-omni;
      }
    }
  }
}
</style>
