<template>
  <el-dialog v-model="isVisible" class="suggestion-campaigns-modal" :id="id" width="820">
    <h2 class="scm__title card-title">{{ $t('campaigns.create.select-campaign') }}</h2>

    <div class="row">
      <div class="col-4 form-group suggestion-campaigns-modal__field">
        <label>{{ $t('campaigns.create.campaign-name') }}</label>
        <input v-model="campaignProps.name" class="w-100" type="text" @blur="handleSubmitDisabledButton" />
      </div>

      <div class="col-4 form-group suggestion-campaigns-modal__field">
        <label>{{ $tc('_audience', 2) }}</label>
        <v-select
          v-model="campaignProps.audience"
          label="name"
          :clearable="false"
          :options="audienceList"
          :placeholder="$tc('_select', 2)"
          @option:selected="handleSubmitDisabledButton"
          @search="searchAudience"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('no-results') }}
          </template>
        </v-select>
      </div>
      <div class="col-4 form-group suggestion-campaigns-modal__field">
        <label>{{ $t('360.campaigns.details.label-rule') }}</label>
        <i
          class="icon icon-question-12px"
          v-tooltip="{
            theme: 'default',
            content: `<div class='scm__radio-tooltip'>${$t('360.campaigns.details.tooltip.rule')}</div>`,
            html: true,
            distance: 8,
          }"
        ></i>
        <v-select
          v-model="selectedRule"
          label="label"
          :reduce="item => item.value"
          :clearable="false"
          :options="ruleList"
          :placeholder="$tc('_select', 2)"
          @option:selected="handleSubmitDisabledButton"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('no-results') }}
          </template>
        </v-select>
      </div>
    </div>

    <div class="row">
      <div class="scm__radio-row row col-12">
        <label class="scm__radio-label">{{ $t('360.campaigns.details.create-limit') }}</label>
        <div class="scm__radio">
          <div class="scm__radio-wrapper">
            <input
              id="scm__radio-1"
              v-model="campaignProps.limitType"
              type="radio"
              value="no_limit"
              name="scm__radio"
              @change="handleSubmitDisabledButton"
              @input="onChangeRadio"
            />
            <label for="scm__radio-1">{{ $t('360.campaigns.details.no-limit') }}</label>
          </div>
          <div class="scm__radio-wrapper">
            <input
              id="scm__radio-2"
              v-model="campaignProps.limitType"
              type="radio"
              value="seller"
              name="scm__radio"
              @change="handleSubmitDisabledButton"
              @input="onChangeRadio"
            />
            <label for="scm__radio-2">{{ $t('360.campaigns.details.seller') }}</label>
            <i
              class="icon icon-question-12px tooltip-suggestion-seller"
              v-tooltip="{
                theme: 'default',
                content: `<div class='scm__radio-tooltip'>${$t('360.campaigns.details.tooltip.seller')}</div>`,
                html: true,
                distance: 8,
              }"
            ></i>
          </div>
          <div class="scm__radio-wrapper">
            <input
              id="scm__radio-3"
              v-model="campaignProps.limitType"
              type="radio"
              value="store"
              name="scm__radio"
              @change="handleSubmitDisabledButton"
              @input="onChangeRadio"
            />
            <label for="scm__radio-3">{{ $t('360.campaigns.details.store') }}</label>
            <i
              class="icon icon-question-12px tooltip-suggestion-store"
              v-tooltip="{
                theme: 'default',
                content: `<div class='scm__radio-tooltip'>${$t('360.campaigns.details.tooltip.store')}</div>`,
                html: true,
                distance: 8,
              }"
            ></i>
          </div>
          <div class="scm__radio-wrapper">
            <input
              id="scm__radio-4"
              v-model="campaignProps.limitType"
              type="radio"
              value="store_seller"
              name="scm__radio"
              @change="handleSubmitDisabledButton"
              @input="onChangeRadio"
            />
            <label for="scm__radio-4">{{ $t('360.campaigns.details.store-seller') }}</label>
            <i
              class="icon icon-question-12px tooltip-suggestion-store-seller"
              v-tooltip="{
                theme: 'default',
                content: `<div class='scm__radio-tooltip'>${$t('360.campaigns.details.tooltip.store-seller')}</div>`,
                html: true,
                distance: 8,
              }"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <template v-if="!isMaxInputContainerDisabled">
      <div class="row">
        <div class="scm__suggestions-max col-3 form-group">
          <label>{{ $t('360.campaigns.details.suggestions-max') }}</label>
          <input
            v-model="campaignProps.limitValue"
            @input="validateValueNumber($event, 'limitValue')"
            placeholder="Ex: 20"
            class="w-100"
            type="number"
            min="0"
            @blur="handleSubmitDisabledButton"
          />
        </div>
      </div>

      <div class="row">
        <span v-if="campaignProps.limitType === 'seller'" class="scm__suggestions-type col-4">
          {{ $t('360.campaigns.details.campaign-seller') }}
        </span>
        <span v-else class="scm__suggestions-type col-4">
          {{ $t('360.campaigns.details.campaign-store') }}
        </span>
      </div>
    </template>

    <template v-if="!isSellerByStoreContainerDisabled">
      <div v-for="(item, groupIndex) in campaignProps.limitData" :key="groupIndex">
        <div class="scm__suggestions-max-store-seller">
          <div class="row">
            <div class="scm__suggestions-max col-3 form-group">
              <label>{{ $t('360.campaigns.details.suggestions-max') }}</label>
              <input
                class="scm__limit-value"
                v-model="item.limitValue"
                @input="validateLimitDataNumber(groupIndex, $event)"
                placeholder="Ex: 20"
                type="number"
                min="0"
                @blur="handleSubmitDisabledButton"
              />
            </div>
            <div class="scm__suggestions-max col-6 form-group">
              <campaigns-management-modal-stores
                :stores="stores"
                :group-index="groupIndex"
                :filters="item.stores"
                :region-groups="item.groups"
                :groupingCriteria="item.groupingCriteria"
                @click="handleSubmitDisabledButton"
              />
            </div>

            <div class="scm__suggestions-max col-3 form-group include-new-group">
              <button
                v-if="campaignProps.limitData.length > 1 && groupIndex + 1 === campaignProps.limitData.length"
                @click="handleRemoveGroup(groupIndex)"
                class="scm__add-group"
              >
                <i class="icon icon-trash"></i>
              </button>
              <button
                v-if="groupIndex + 1 === campaignProps.limitData.length"
                @click="handleAddNewGroup()"
                @blur="handleSubmitDisabledButton"
                class="scm__add-group"
              >
                <i class="icon icon-plus"></i>
                {{ $t('360.campaigns.details.add-new-group') }}
              </button>
            </div>
          </div>

          <div class="row">
            <span class="scm__suggestions-type col-3">{{ $t('360.campaigns.details.campaign-store-seller') }}</span>
          </div>
        </div>
      </div>
      <div class="scm__rest">
        <h3 class="scm__subtitle">
          {{ $t('360.campaigns.details.remaining-stores') }}
          <i
            class="icon icon-question-12px tooltip-suggestion-remaining-stores"
            v-tooltip="{
              theme: 'default',
              content: `<div class='scm__radio-tooltip'>${$t('360.campaigns.details.tooltip.remaining-stores')}</div>`,
              html: true,
              distance: 8,
            }"
          ></i>
        </h3>

        <div class="row">
          <div class="col-3 form-group">
            <label>{{ $t('360.campaigns.details.suggestions-max') }}</label>

            <input
              v-model="campaignProps.limitGroupRemaining"
              @input="validateValueNumber($event, 'limitGroupRemaining')"
              placeholder="Ex: 20"
              class="w-100"
              type="number"
              min="0"
              @blur="handleSubmitDisabledButton"
            />
          </div>

          <div class="col-3">
            <span class="scm__suggestions-type store-seller">{{
              $t('360.campaigns.details.campaign-store-seller')
            }}</span>
          </div>
        </div>
      </div>
    </template>

    <div class="row">
      <button class="scm__btn btn" :disabled="isSubmitButtonDisabled" type="submit" @click.prevent="submit">
        {{ $tc('_save', 2) }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, debounce } from 'lodash';

import { limitTypes } from '@/utilities/constants';

// helpers
import bus from '@/helpers/events/bus';
import { validateIntNumber } from '@/utilities/numbers';

// services
import { getAudiences } from '@/services/actions';
import getStores from '@/services/stores';

// components
import CampaignsManagementModalStores from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementModalStores';

const defaultLimitGroup = {
  limitValue: null,
  stores: { comparisonStores: [] },
  groupingCriteria: 'cluster',
  groups: [],
};

const defaultCampaignDetails = {
  index: null,
  campaignId: '',
  name: '',
  audience: '',
  enabled: 1,
  status: 1,
  limitType: limitTypes.noLimit,
  limitData: [],
  limitValue: null,
  limitGroupRemaining: null,
  sellerAllocation: 0,
};

export default {
  name: 'SuggestionDetailCampaignsModal',
  components: {
    vSelect,
    CampaignsManagementModalStores,
  },
  data() {
    return {
      id: 'suggestion-detail-campaigns-modal',
      campaignProps: cloneDeep(defaultCampaignDetails),
      audienceList: [],
      isMaxInputContainerDisabled: false,
      isSellerByStoreContainerDisabled: false,
      validateLimitValue: null,
      isSubmitButtonDisabled: true,
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters('campaignsManagement', ['getMode', 'getSuggestionDetail']),

    isOnEditMode() {
      return this.getMode === 'edit';
    },

    ruleList() {
      const rules = [
        { key: 'main', value: 0 },
        { key: 'seller', value: 1 },
      ];

      return rules.map(rule => ({
        label: this.$tc(`360.campaigns.details.rules.${rule.key}`),
        value: rule.value,
      }));
    },

    selectedRule: {
      get() {
        return this.ruleList.find(rule => rule.value === this.campaignProps.sellerAllocation);
      },
      set(newValue) {
        this.campaignProps.sellerAllocation = newValue;
      },
    },
  },
  watch: {
    isVisible() {
      if (!this.isVisible) this.resetCampaignData();
    },
  },
  mounted() {
    this.fetchData();

    bus.$on(`open-${this.id}`, campaign => {
      this.handleInitialAttribution(campaign);

      if (!this.isLimitTypeStoreOrSeller() && !this.isLimitTypeSellerByStore()) {
        this.hideAllContainers();
      }

      if (this.isLimitTypeStoreOrSeller()) {
        this.hideStoreOrSellerContainer(false);
        this.hideSellerByStoreContainer();
        this.campaignProps.limitValue = campaign?.campaign?.limitData?.limitValue;
      }

      if (this.isLimitTypeSellerByStore()) {
        this.hideStoreOrSellerContainer();
        this.hideSellerByStoreContainer(false);

        if (this.isOnEditMode) this.handleEditionFields(campaign?.campaign?.limitData);
      }

      this.openModal();
    });

    bus.$on('campaign-modal-criteria-info', data => this.handleGroupUpdateFromEvent(data));
    bus.$on('campaign-modal-update-group-selection', data => this.handleGroupSelection(data));
  },

  beforeUnmount() {
    bus.$off(`open-${this.id}`);
    bus.$off('campaign-modal-criteria-info');
    bus.$off('campaign-modal-update-group-selection');
  },

  methods: {
    ...mapActions('campaignsManagement', ['actionSaveAudienceCampaigns', 'actionEditAudienceCampaigns']),

    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },

    fetchData() {
      this.fetchAudiences();
      this.fetchStores();
    },

    fetchStores() {
      getStores().then(response => {
        this.stores = response?.data?.data ?? [];
      });
    },

    fetchAudiences() {
      getAudiences().then(({ data }) => {
        this.audienceList = data?.data ?? [];
      });
    },

    hideStoreOrSellerContainer(isDisabled = true) {
      this.isMaxInputContainerDisabled = isDisabled;
    },

    hideSellerByStoreContainer(isDisabled = true) {
      this.isSellerByStoreContainerDisabled = isDisabled;
    },

    hideAllContainers() {
      this.hideStoreOrSellerContainer();
      this.hideSellerByStoreContainer();
    },

    isLimitTypeNoLimit() {
      return this.campaignProps.limitType === limitTypes.noLimit;
    },

    isLimitTypeStoreOrSeller() {
      return this.campaignProps.limitType === limitTypes.store || this.campaignProps.limitType === limitTypes.seller;
    },

    isLimitTypeSellerByStore() {
      return this.campaignProps.limitType === limitTypes.storeSeller;
    },

    handleGroupUpdateFromEvent(data) {
      const { index, criteria } = data;
      if (!this.campaignProps.limitData[index]) return;
      this.campaignProps.limitData[index].groupingCriteria = criteria ?? '';
    },

    handleGroupSelection(data) {
      const { index, groups } = data;
      if (!this.campaignProps.limitData[index]) return;
      this.campaignProps.limitData[index].groups = groups ?? [];
    },

    handleEditionFields(limitData) {
      if (!limitData?.length) return;

      const aux = limitData.map(item => {
        const stores = item?.stores;
        const comparisonStores = stores.comparisonStores ? stores.comparisonStores : stores;

        const validate = {
          limitValue: item?.limitValue,
          groupingCriteria: item?.groupingCriteria,
          stores: {
            comparisonStores,
          },
          groups: item?.groups,
        };
        return validate;
      });

      this.campaignProps.limitData = aux;
    },

    onChangeRadio(event) {
      this.campaignProps.limitType = event?.target?.value;

      this.resetlimitValue();

      if (!this.isLimitTypeStoreOrSeller() && !this.isLimitTypeSellerByStore()) {
        this.hideAllContainers();
      }

      if (this.isLimitTypeStoreOrSeller()) {
        this.hideStoreOrSellerContainer(false);
        this.hideSellerByStoreContainer();
      }

      if (this.isLimitTypeSellerByStore()) {
        this.handleAddInitialGroup();

        this.hideStoreOrSellerContainer();
        this.hideSellerByStoreContainer(false);
      }
    },

    handleAddInitialGroup() {
      if (!this.campaignProps?.limitData?.length) {
        this.campaignProps.limitData = cloneDeep([{ ...defaultLimitGroup }]);
        return;
      }
    },

    handleAddNewGroup() {
      this.campaignProps.limitData = cloneDeep([...this.campaignProps.limitData, { ...defaultLimitGroup }]);
    },

    handleRemoveGroup(index) {
      this.campaignProps.limitData.splice(index, 1);
    },

    formatByStoreOrSeller() {
      const { index, campaignId, name, enabled, status, audience, limitType, limitValue, sellerAllocation } =
        this.campaignProps;
      const formattedValues = {
        index,
        campaignId,
        name,
        enabled,
        status,
        audience,
        limitType,
        limitData: { limitValue },
        sellerAllocation,
      };
      return formattedValues;
    },

    formatCaseSellerByStore() {
      const {
        index,
        campaignId,
        name,
        enabled,
        status,
        audience,
        limitType,
        limitData,
        limitGroupRemaining,
        sellerAllocation,
      } = this.campaignProps;
      const formattedValues = {
        index,
        campaignId,
        name,
        enabled,
        status,
        audience,
        limitType,
        limitData,
        limitGroupRemaining,
        sellerAllocation,
      };
      return formattedValues;
    },

    submit() {
      let tempLimitData;

      if (this.isLimitTypeNoLimit()) {
        const { index, campaignId, name, enabled, status, audience, limitData, limitType, sellerAllocation } =
          this.campaignProps;
        tempLimitData = { index, campaignId, name, enabled, status, audience, limitData, limitType, sellerAllocation };
      }

      if (!this.isLimitTypeNoLimit() && !this.isLimitTypeSellerByStore()) {
        tempLimitData = this.formatByStoreOrSeller();
      }

      if (!this.isLimitTypeNoLimit() && this.isLimitTypeSellerByStore()) {
        tempLimitData = this.formatCaseSellerByStore();
      }

      if (!this.isEdit) this.actionSaveAudienceCampaigns({ ...tempLimitData });
      else this.actionEditAudienceCampaigns({ ...tempLimitData });

      this.closeModal();
    },

    // validate submit campaign with limitType === no_limit
    isSubmitCampaignNoLimit() {
      if (this.campaignProps.name && this.campaignProps.audience.name) this.isSubmitButtonDisabled = false;
    },

    // validate submit campaign with limitType === (store || seller)
    isSubmitCampaignStoreAndSeller() {
      if (this.campaignProps.name && this.campaignProps.audience.name && this.campaignProps.limitValue)
        this.isSubmitButtonDisabled = false;
    },

    searchAudience(term) {
      if (!term) return;
      this.triggerDebounce(term);
    },

    triggerDebounce: debounce(function (term) {
      getAudiences({ term }).then(({ data }) => {
        this.audienceList = data?.data;
      });
    }, 500),

    // validate submit campaign with limitType === store_seller
    isSubmitCampaignStoreSeller() {
      this.campaignProps.limitData?.filter(item => {
        this.validateLimitValue = item?.limitValue;
      });

      if (
        this.campaignProps?.name &&
        this.campaignProps?.audience?.name &&
        this.campaignProps?.limitGroupRemaining &&
        this.validateLimitValue
      ) {
        this.isSubmitButtonDisabled = false;
      }
    },

    handleSubmitDisabledButton() {
      this.isSubmitButtonDisabled = true;

      if (this.isLimitTypeNoLimit()) {
        this.isSubmitCampaignNoLimit();
        return;
      }
      if (this.isLimitTypeStoreOrSeller()) {
        this.isSubmitCampaignStoreAndSeller();
        return;
      }
      if (this.isLimitTypeSellerByStore()) {
        this.isSubmitCampaignStoreSeller();
        return;
      }
    },

    resetlimitValue() {
      this.campaignProps.limitValue = '';
    },

    handleInitialAttribution(campaign) {
      const campaignData = campaign?.campaign;

      this.campaignProps.limitValue = '';
      this.campaignProps.limitData = campaignData?.limitData?.length > 0 ? campaignData?.limitData : [];

      this.campaignProps.index = campaignData?.campaign?.id ?? campaign?.index;
      this.campaignProps.name = campaignData?.name ?? '';
      this.campaignProps.audience = campaignData?.audience ?? '';
      this.campaignProps.limitType = campaignData?.limitType ?? limitTypes.noLimit;
      this.campaignProps.limitGroupRemaining = campaignData?.limitGroupRemaining ?? '';
      this.campaignProps.sellerAllocation = +campaignData?.sellerAllocation || 0;
      this.isEdit = campaign?.isEdit;
      this.isSubmitButtonDisabled = true;
    },

    validateLimitDataNumber(itemIndex, event) {
      const value = event?.target?.value;
      const valueFormatted = validateIntNumber(value);
      if (!this.campaignProps.limitData[itemIndex]) return;

      this.campaignProps.limitData[itemIndex].limitValue = valueFormatted;
    },

    validateValueNumber(event, type) {
      const value = event?.target?.value;
      const valueFormatted = validateIntNumber(value);

      if (type === 'limitValue') this.campaignProps.limitValue = valueFormatted;
      if (type === 'limitGroupRemaining') this.campaignProps.limitGroupRemaining = valueFormatted;
    },

    resetCampaignData() {
      this.campaignProps = cloneDeep(defaultCampaignDetails);
    },
  },
};
</script>

<style lang="scss">
// set vue-select max-height
$vs-dropdown-max-height: 190px;

#suggestion-detail-campaigns-modal {
  @import '@/assets/scss/vendors/_v-select';
  @import '@/assets/scss/components/_custom-scrollbar';

  .el-dialog__body {
    padding: 25px 50px;
    min-height: 300px;
    @extend .customScrollBar;
    @media screen and (max-height: 500px) {
      max-height: 560px;
      overflow: auto;
    }
  }
}

.suggestion-campaigns-modal {
  .scm__radio-label {
    margin: 15px 0;
  }
  .scm__radio {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .scm__suggestions-max {
    margin-top: 15px;
    .scm__add-group {
      background-color: transparent;
      border: none;
      color: $gray-700;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px 0;
      &:active {
        outline: 0;
        background-color: transparent;
      }
    }
    .icon-trash {
      color: $gray-500;
      font-size: 20px;
      margin-right: 10px;
    }
    .icon-plus {
      background-color: $gray-400;
      width: 23px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin-right: 3px;
    }
  }

  .scm__suggestions-max-store-seller {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid $gray-500;
  }

  .scm__suggestions-type {
    color: $gray-600;
    font-size: 12px;

    &.store-seller {
      position: absolute;
      bottom: 15px;
    }
  }

  .scm__rest {
    padding-top: 40px;
  }

  .scm__subtitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 20px;
  }

  .scm__btn {
    position: absolute;
    bottom: 0;
    right: 25px;
    padding: 0 30px;
    width: 208px;
    margin-bottom: 25px;
  }

  input.scm__limit-value {
    width: 135px;
  }

  .include-new-group {
    display: flex;
    padding-top: 22px;
    justify-content: space-around;
  }

  .icon-question-12px {
    font-size: 12px;
    margin: 0 10px 0 6px;
    color: $gray-500;
    cursor: pointer;
    &:hover {
      color: $blue-500;
    }
  }
}

.scm__radio-tooltip {
  width: 170px;
}
</style>
