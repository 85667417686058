<template>
  <div id="audiences-create">
    <!-- header -->
    <audience-header-skeleton v-if="loading" />
    <audience-header v-else />

    <!-- middle -->
    <audience-steps />

    <!-- footer -->
    <audience-stats-skeleton v-if="loading" />
    <audience-stats v-else />

    <!-- exporting modal -->
    <audience-exportation :audience="exporting" :is-creation="true" />

    <!-- integration importing modal -->
    <integration-active-modal />

    <!-- goback modal -->
    <el-dialog v-model="isConfirmModalVisible" id="audience-confirm-cancel" append-to-body>
      <p>{{ $t('audiences.change-not-saved') }}</p>
      <p>{{ $t('audiences.do-you-want-exit') }}</p>
      <div class="dialog">
        <div class="btn btn-secondary" @click="hideDiscartModal">
          {{ $t('audiences.answer.cancel') }}
        </div>
        <div class="btn" @click="back">
          {{ $t('audiences.answer.confirm') }}
        </div>
      </div>
    </el-dialog>

    <!-- success modal -->

    <el-dialog
      v-if="!isNewAudiencePage"
      v-model="isAudienceSuccessModalVisible"
      id="audience-success"
      append-to-body
      :close-on-click-modal="false"
      :draggable="false"
      :show-close="false"
    >
      <i class="icon icon-checked-60px" aria-hidden="true"></i>
      <p>{{ $t('audiences.success-created') }}</p>

      <a :href="`/audiences/view/${this.$store.getters.user.id}/${this.reference}`">
        <div class="dialog">
          <div class="btn">
            {{ $t('continue') }}
          </div>
        </div>
      </a>
    </el-dialog>

    <!-- update success modal -->
    <el-dialog
      v-model="isUpdateModalVisible"
      id="audience-update-success"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <i class="icon icon-checked-60px" aria-hidden="true"></i>
      <p>{{ $t('audiences.success-updated') }}</p>
      <a
        :href="`/audiences/view/${this.$store.getters.user.id}/${this.reference}`"
        data-cy="btn-audience-success-created"
      >
        <div class="dialog">
          <div class="btn">
            {{ $t('continue') }}
          </div>
        </div>
      </a>
    </el-dialog>

    <!-- expansion error modal -->
    <el-dialog
      v-model="isErrorModalVisible"
      id="audience-expansion-error"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <i class="icon icon-error-60px" aria-hidden="true"></i>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('audiences.expansions.error')"></p>
      <div class="dialog">
        <div class="btn" @click="hideExpansionError">{{ $t('continue') }}</div>
      </div>
    </el-dialog>

    <audience-start-time-error-modal />

    <!-- spinner -->
    <load-spinner id="audiences" color="purple" />
  </div>
</template>

<script>
import AudienceHeader from '@/components/audiences/create/AudienceHeader';
import AudienceHeaderSkeleton from '@/components/audiences/create/AudienceHeaderSkeleton';
import AudienceStats from '@/components/audiences/create/stats/AudienceStats';
import AudienceStatsSkeleton from '@/components/audiences/create/stats/AudienceStatsSkeleton';
import AudienceSteps from '@/components/audiences/create/AudienceSteps';
import AudienceExportation from '@/components/audiences/exportation/AudiencesExportation';
import IntegrationActiveModal from '@/components/audiences/create/integration/IntegrationActiveModal';
import AudienceStartTimeErrorModal from '@/components/audiences/create/AudienceStartTimeErrorModal';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import { saveAudience } from '@/services/audiences';
import bus from '@/helpers/events/bus';
import { mapState, mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';

export default {
  name: 'Audience',
  components: {
    AudienceHeader,
    AudienceHeaderSkeleton,
    AudienceSteps,
    AudienceStats,
    AudienceStatsSkeleton,
    AudienceExportation,
    LoadSpinner,
    IntegrationActiveModal,
    AudienceStartTimeErrorModal,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      isConfirmModalVisible: false,
      isAudienceSuccessModalVisible: false,
      isUpdateModalVisible: false,
      isErrorModalVisible: false,
    };
  },
  computed: {
    ...mapGetters('audience', [
      'isValid', // -> this.isValid
      'isConnectValid', // -> this.isConnectValid
      'query', // -> this.query
    ]),
    ...mapState('audience', [
      'reference', // -> this.reference
      'name', // -> this.name
      'description', // -> this.description
      'exporting', // -> this.exporting
      'integrationSeries', // -> this.integrationSeries
      'loading', // -> this.loading
      'token', // -> this.token
      'expansion', // -> this.expansion
      'isLookALikeActive',
    ]),
    ...mapState(['auth']),
    isNewAudiencePage() {
      return !!this.$store.getters?.getRoutes
        ?.filter(route => route.id === 'audience-mgmt')[0]
        .routes.filter(subRoute => subRoute.id === 'new-audience-activation').length;
    },
  },
  watch: {
    integrationSeries: {
      handler(series) {
        // filter enabled integrations or integrations with serie.id (already integrated)
        this.updateEnabledIntegrations(series);
      },
      deep: true,
    },
  },
  created() {
    // dispatch without maping to be clear
    // reset audience state
    this.$store.dispatch('audience/reset');
    // set audience ID
    this.$store.dispatch('audience/setAudienceID', this.$route.params.id);
    // set audience Creator
    this.$store.dispatch('audience/setCreator', this.$route.params.creator);
    // set audience Description
    this.$store.dispatch('audience/setDescription', this.$route.params.description);
  },
  mounted() {
    if (this.$route.path.includes('/audiences/create') || this.$route.path.includes('/new-audiences/create')) {
      this.listColumns('create');
    } else {
      this.listColumns('edit');
    }
    this.listConnectors();
    this.listeners();
  },
  beforeUnmount() {
    this.listeners(true);
  },
  methods: {
    saveNewAudience(res, rej) {
      if (this.isNewAudiencePage) {
        this.saveNewAudiencePage(res, rej);
      } else {
        this.saveNewAudienceOldPage(res, rej);
      }
    },
    saveNewAudienceOldPage(res, rej) {
      if (!this.isValid) return rej();
      if (!this.isConnectValid) return rej();
      if (!this.isLookALikeActive) this.expansionLookALike(0);
      return res(
        saveAudience(
          this.query,
          this.name || this.$t('audiences.name-dafault'),
          this.token,
          this.expansion,
          this.description,
        ),
      );
    },
    async saveNewAudiencePage(res, rej) {
      if (!this.isValid) return rej();
      if (!this.isConnectValid) return rej();
      if (!this.isLookALikeActive) this.expansionLookALike(0);
      const result = await res(
        saveAudience(
          this.query,
          this.name || this.$t('audiences.name-dafault'),
          this.token,
          this.expansion,
          this.description,
        ),
      );

      if (result?.success) {
        this.toast.success(this.$t(`audiences.success-created`), {
          timeout: 1000,
        });

        setTimeout(() => {
          this.$router.push({
            name: 'new-audience-activation',
            query: {
              page: this.pageHistory,
            },
          });
        }, 1000);
      } else {
        this.toast.error(this.$t(`audiences.error-created`), {
          timeout: 1000,
        });

        bus.$emit('hide-spinner', 'audiences');
      }
    },
    updateIntegration(res, rej) {
      if (!this.isConnectValid) return rej();
      return res();
    },
    back() {
      this.$router.push({
        name: 'audiences',
        query: {
          page: this.pageHistory,
        },
      });
      this.hideDiscartModal();
      this.hideSaved();
      this.hideUpdated();
      this.hideExpansionError();
    },
    showDiscartModal() {
      this.isConfirmModalVisible = true;
    },
    hideDiscartModal() {
      this.isConfirmModalVisible = false;
    },
    showSaved() {
      this.isAudienceSuccessModalVisible = true;
    },
    hideSaved() {
      this.isAudienceSuccessModalVisible = false;
    },
    showUpdated() {
      this.isUpdateModalVisible = true;
    },
    hideUpdated() {
      this.isUpdateModalVisible = false;
    },
    showExpansionError() {
      this.isErrorModalVisible = true;
    },
    hideExpansionError() {
      this.isErrorModalVisible = false;
    },
    listeners(off = false) {
      const action = off ? '$off' : '$on';
      bus[action]('save-audience', this.saveNewAudience);
      bus[action]('request-discard-audience', this.showDiscartModal);
      bus[action]('show-success-audience', this.showSaved);
      bus[action]('show-success-update-audience', this.showUpdated);
      bus[action]('update-integrations', this.updateIntegration);
      bus[action]('render-cards', this.back);
      bus[action]('audience-expansion-error', this.showExpansionError);
    },
    ...mapActions('audience', [
      'updateCount', // -> this.updateCount()
      'listColumns', // -> this.listColumns()
      'listConnectors', // -> this.listConnectors()
      'updateEnabledIntegrations', // -> this.updateEnabledIntegrations(),
      'expansionLookALike',
    ]),
  },
};
</script>

<style lang="scss">
#audience-success,
#audience-update-success,
#audience-expansion-error {
  .icon {
    display: block;
    text-align: center;
    font-size: 76px;
    margin-bottom: 34px;
    color: $oto-ecommerce;
  }
  .icon {
    &[class*='-error'] {
      color: $color-red;
    }
  }
}

#audience-expansion-error,
#audience-update-success,
#audience-success,
#audience-confirm-cancel {
  width: 500px;
  text-align: center;

  p {
    font-size: 18px;
    font-weight: 600;
  }

  .dialog {
    .btn {
      width: 200px;
    }
  }
}

#audience-expansion-error,
#audience-update-success,
#audience-success {
  .dialog {
    .btn {
      width: 170px;
    }
  }
}

#audience-expansion-error {
  p {
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
