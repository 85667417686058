<template>
  <div>
    <lightbox-orders v-if="active === 'lightbox-orders'" />
    <lightbox-retention v-if="active === 'lightbox-retention'" />
    <lightbox-frequency v-if="active === 'lightbox-frequency'" />
    <lightbox-omnichannel-revenue v-if="active === 'lightbox-omnichannel-revenue'" />
    <lightbox-tracked-revenue v-if="active === 'lightbox-tracked-revenue'" />
    <lightbox-rev-by-media v-if="active === 'lightbox-revenue-by-media'" />
    <lightbox-recency v-if="active === 'lightbox-recency'" />
    <lightbox-database v-if="active === 'lightbox-database'" />
    <lightbox-who-bought v-if="active === 'lightbox-who-bought'" :settings-data="settings" />
    <lightbox-category-best-seller v-if="active === 'lightbox-category-best-seller'" :settings-data="settings" />
    <lightbox-product-best-seller v-if="active === 'lightbox-product-best-seller'" />
    <lightbox-category-most-purchased v-if="active === 'lightbox-category-most-purchased'" />
    <lightbox-products-most-purchased v-if="active === 'lightbox-products-most-purchased'" :settings-data="settings" />
    <lightbox-category-revenue v-if="active === 'lightbox-category-revenue'" />
    <lightbox-category-stores v-if="active === 'lightbox-category-stores'" />
    <lightbox-products-identified v-if="active === 'lightbox-products-identified'" :settings-data="settings" />
    <lightbox-products-revenue v-if="active === 'lightbox-products-revenue'" :settings-data="settings" />
    <lightbox-campaign-details v-if="active === 'lightbox-campaign-datails'" :settings-data="settings" />
    <lightbox-last-order v-if="active === 'lightbox-last-order'" :settings="settings" />
    <lightbox-order-details v-if="active === 'lightbox-order-details'" :settings="settings" />
    <lightbox-add-size v-if="active === 'lightbox-add-size'" :settings="settings" />
    <lightbox-phones v-if="active === 'lightbox-phones'" :settings="settings" />
    <lightbox-history-export v-if="active === 'lightbox-history-export'" :settings-data="settings" />
    <lightbox-history-import v-if="active === 'lightbox-history-import'" :settings-data="settings" />
    <lightbox-welcome-card v-if="active === 'lightbox-welcome-card'" :settings-data="settings" />
    <lightbox-terms-of-service v-if="active === 'lightbox-terms-of-service'" :settings-data="settings" />
    <lightbox-seller-ranking v-if="active === 'lightbox-seller-ranking'" :settings-data="settings" />
    <lightbox-customer-ranking v-if="active === 'lightbox-customer-ranking'" :settings-data="settings" />
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import LightboxOrders from '@/components/lightbox/lightboxes/LightboxOrders';
import LightboxRetention from '@/components/lightbox/lightboxes/LightboxRetention';
import LightboxFrequency from '@/components/lightbox/lightboxes/LightboxFrequency';
import LightboxOmnichannelRevenue from '@/components/lightbox/lightboxes/LightboxOmnichannelRevenue';
import LightboxTrackedRevenue from '@/components/lightbox/lightboxes/LightboxTrackedRevenue';
import LightboxRevByMedia from '@/components/lightbox/lightboxes/LightboxRevByMedia';
import LightboxRecency from '@/components/lightbox/lightboxes/LightboxRecency';
import LightboxWhoBought from '@/components/lightbox/lightboxes/LightboxWhoBought';
import LightboxDatabase from '@/components/lightbox/lightboxes/LightboxDatabase';
import LightboxCategoryBestSeller from '@/components/lightbox/lightboxes/LightboxCategoryBestSeller';
import LightboxProductBestSeller from '@/components/lightbox/lightboxes/LightboxProductBestSeller';
import LightboxCategoryMostPurchased from '@/components/lightbox/lightboxes/categories/LightboxCategoryMostPurchased';
import LightboxCategoryRevenue from '@/components/lightbox/lightboxes/categories/LightboxCategoryRevenue';
import LightboxCategoryStores from '@/components/lightbox/lightboxes/categories/LightboxCategoryStores';
import LightboxProductsMostPurchased from '@/components/lightbox/lightboxes/products/LightboxProductsMostPurchased';
import LightboxProductsIdentified from '@/components/lightbox/lightboxes/products/LightboxProductsIdentified';
import LightboxProductsRevenue from '@/components/lightbox/lightboxes/products/LightboxProductsRevenue';
import LightboxCampaignDetails from '@/components/lightbox/lightboxes/dashboard/LightboxCampaignDetails';
import LightboxLastOrder from '@/components/lightbox/lightboxes/oto/LightboxLastOrder';
import LightboxOrderDetails from '@/components/lightbox/lightboxes/oto/LightboxOrderDetails';
import LightboxAddSize from '@/components/lightbox/lightboxes/oto/LightboxAddSize';
import LightboxPhones from '@/components/lightbox/lightboxes/oto/LightboxPhones';
import LightboxHistoryExport from '@/components/lightbox/lightboxes/integration/LightboxHistoryExport';
import LightboxHistoryImport from '@/components/lightbox/lightboxes/integration/LightboxHistoryImport';
import LightboxWelcomeCard from '@/components/lightbox/lightboxes/welcome/LightboxWelcomeCard';
import LightboxTermsOfService from '@/components/lightbox/lightboxes/welcome/LightboxTermsOfService';
import LightboxSellerRanking from '@/components/lightbox/lightboxes/LightboxSellerRanking';
import LightboxCustomerRanking from '@/components/lightbox/lightboxes/LightboxCustomerRanking';

export default {
  components: {
    LightboxOrders,
    LightboxRetention,
    LightboxFrequency,
    LightboxOmnichannelRevenue,
    LightboxTrackedRevenue,
    LightboxRevByMedia,
    LightboxRecency,
    LightboxDatabase,
    LightboxWhoBought,
    LightboxCategoryBestSeller,
    LightboxProductBestSeller,
    LightboxCategoryMostPurchased,
    LightboxCategoryRevenue,
    LightboxCategoryStores,
    LightboxProductsMostPurchased,
    LightboxProductsIdentified,
    LightboxProductsRevenue,
    LightboxCampaignDetails,
    LightboxLastOrder,
    LightboxOrderDetails,
    LightboxAddSize,
    LightboxPhones,
    LightboxHistoryExport,
    LightboxHistoryImport,
    LightboxWelcomeCard,
    LightboxTermsOfService,
    LightboxSellerRanking,
    LightboxCustomerRanking,
  },
  data() {
    return {
      active: null,
    };
  },
  mounted() {
    bus.$on('changeActiveLightbox', ({ lightbox, settings }) => {
      this.active = lightbox;
      this.settings = settings;
    });
  },
  beforeUnmount() {
    bus.$off('changeActiveLightbox');
  },
};
</script>
<style scoped>
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
</style>
