<template>
  <div class="items-group">
    <!-- case has options - with multiselection or not -->
    <div v-if="hasOptions" :class="['wrap', { multiselectOn: multipleSelection }]">
      <template v-if="multipleSelection && /equal|different/g.test(operator)">
        <input
          :value="labelField"
          type="text"
          class="input-autocomplete input-options"
          readonly
          :class="(success[0] ? 'success' : 'error') + ` input-${group}-${serie}`"
          @click="openDropDown()"
        />
        <i class="icon icon-attention-14px"></i>
        <div v-if="activeDropdown" class="columns-list no-click">
          <div class="list no-click">
            <div class="customScrollBar">
              <div
                v-for="(item, key) in options"
                :key="key"
                :title="item"
                class="item multiselection"
                @click="updateFieldOptions([key, item])"
              >
                <check-box class="no-click multiselection-content" :checked="itemsMarked.indexOf(key) !== -1" />
                <div class="text text-truncate multiselection-content">
                  {{ item }}
                </div>
              </div>
            </div>
            <div v-if="Object.keys(options).length && itemsMarked.length" class="items-marked ig-click">
              <div class="text mb-2 ig-click">
                {{ $t('audiences.selected') }}
              </div>
              <div class="customScrollBar">
                <div
                  v-for="(item, key) in options"
                  :key="key"
                  :title="item"
                  class="selecteds ig-click"
                  @click="updateFieldOptions([key, item])"
                >
                  <template v-if="itemsMarked.indexOf(key) !== -1">
                    <div class="text-truncate mb-2 ig-click">{{ item }}</div>
                    <icon-base :color="'#EC3942'" class="trash-icon ig-click">
                      <icon-trash />
                    </icon-base>
                  </template>
                </div>
              </div>
              <div class="update-button ig-click">
                <button class="btn">{{ $t('audiences.select') }}</button>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- if has just a select with options -->
      <template v-else>
        <select v-model="valueProps[0]" :class="`input-${group}-${serie}`">
          <option v-for="optionKey in Object.keys(options)" :key="optionKey" :value="optionKey">
            {{ options[optionKey] }}
          </option>
        </select>
        <span v-if="between" class="and">e</span>
        <select v-if="between" v-model="valueProps[1]" :class="`input-${group}-${serie}`">
          <option v-for="optionKey in Object.keys(options)" :key="optionKey" :value="optionKey">
            {{ options[optionKey] }}
          </option>
        </select>
      </template>
    </div>

    <!-- if has a select -->
    <div v-if="hasSelect" class="wrap">
      <input
        v-model="valueProps[0]"
        type="number"
        data-cy="form-audience-number"
        min="0"
        :class="(success[0] ? 'success' : 'error') + ` input-${group}-${serie}`"
      />
      <i class="icon icon-attention-14px"></i>
    </div>
    <span v-if="hasSelect && between" class="and">e</span>
    <div v-if="hasSelect && between" class="wrap">
      <input
        ref="inputBetween"
        v-model="valueProps[1]"
        type="number"
        data-between="true"
        :class="(success[1] ? 'success' : 'error') + ` input-${group}-${serie}`"
      />
      <i class="icon icon-attention-14px"></i>
    </div>

    <!-- if has type date format -->

    <div
      v-show="hasDateFormat"
      :class="['wrap element--datepicker', renderClass(success[0], group, serie, name, valueOrigin)]"
    >
      <el-date-picker
        v-model="valueProps[0]"
        type="date"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        :clearable="false"
        :editable="false"
      />
      <i class="icon icon-attention-14px"></i>
    </div>

    <span v-show="hasDateFormat && between" class="and">{{ $t('and') }}</span>
    <div
      v-show="hasDateFormat && between"
      :class="['wrap element--datepicker', renderClass(success[1], group, serie, name, valueOrigin)]"
    >
      <el-date-picker
        v-model="valueProps[1]"
        type="date"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        :clearable="false"
        :disabled-date="disabledDate"
        :editable="false"
      />
      <i class="icon icon-attention-14px"></i>
    </div>
    <!-- if has just a simple input - with multiselection or not -->
    <template v-if="hasSimpleInput">
      <div
        v-if="/equal|different/g.test(operator) && autocomplete"
        :class="['wrap', { multiselectOn: multipleSelection }]"
      >
        <input
          v-model="labelFieldProps"
          type="text"
          class="input-autocomplete"
          :class="(success[0] ? 'success' : 'error') + ` input-${group}-${serie}`"
          @keyup="inputSearch"
          @click="inputSearch"
          @focus="checkEmptySearch()"
          data-cy="audience-multiple-select"
        />
        <i class="icon icon-attention-14px"></i>
        <div v-if="autocomplete && activeDropdown" class="columns-list no-click">
          <div class="list no-click">
            <div class="customScrollBar">
              <div v-if="!emptySearchTrigger && loading" class="spinner">
                <load-spinner id="audience-autocomplete-loader" color="white" />
              </div>
              <template v-if="autoCompleteHasData()">
                <div
                  v-for="(item, key) in fieldsAutoComplete"
                  :key="key"
                  :title="item"
                  :class="['item', { multiselection: /equal/g.test(operator) }]"
                  @click="updateField([key, item])"
                  data-cy="audience-multiple-select-checkbox"
                >
                  <template v-if="multipleSelection && /equal/g.test(operator)">
                    <check-box class="no-click multiselection-content" :checked="itemsMarked.indexOf(key) !== -1" />
                    <div class="text text-truncate multiselection-content">{{ item }}</div>
                  </template>
                  <template v-else>
                    {{ item }}
                  </template>
                </div>
              </template>
              <template v-if="hasNoResults()">
                <div class="item off">{{ $t('audiences.no-results-data') }}</div>
              </template>
              <template v-if="emptySearchTrigger">
                <div class="item off">{{ $t('audiences.type-your-search') }}</div>
              </template>
            </div>
            <div
              v-if="itemsMarked.length && multipleSelection && /equal/g.test(operator)"
              class="items-marked ig-click"
            >
              <div class="text mb-2 ig-click">{{ $t('audiences.selected') }}</div>
              <div class="customScrollBar">
                <div
                  v-for="(item, key) in referenceObjects"
                  :key="key"
                  :title="item"
                  class="selecteds ig-click"
                  @click="updateField([key, item])"
                  data-cy="audience-multiple-selected"
                >
                  <template v-if="itemsMarked.indexOf(key) !== -1">
                    <div class="text-truncate ig-click">{{ item }}</div>
                    <icon-base :color="'#EC3942'" class="trash-icon ig-click">
                      <icon-trash />
                    </icon-base>
                  </template>
                </div>
              </div>
              <div class="update-button ig-click">
                <button class="btn" @click="updateLabelField()" data-cy="btn-audience-multiple-select">
                  {{ $t('audiences.select') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- has simple input to free writing -->
      <template v-else>
        <div class="wrap">
          <input
            v-model="valueProps[0]"
            type="text"
            class="input-autocomplete"
            :class="(success[0] ? 'success' : 'error') + ` input-${group}-${serie}`"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import bus from '@/helpers/events/bus';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import CheckBox from '@/components/_atoms/CheckBox';
import IconBase from '@/assets/vue-icons/IconBase';
import IconTrash from '@/assets/vue-icons/icons/Trash';

import { validate } from '@/helpers/audiences';
import { getResultByTerm } from '@/services/audiences';
import { valueType, dateFormat } from '@/utilities/constants';

import { disabledBackwardDatesFromStartDate } from '@/utilities/datepicker';

let timeoutKeyUp = null;

export default {
  components: {
    LoadSpinner,
    CheckBox,
    IconBase,
    IconTrash,
  },
  props: {
    type: String,
    options: Object,
    value: Array,
    labelField: String,
    name: String,
    format: String,
    between: Boolean,
    autocomplete: Boolean,
    multipleSelection: {
      type: Boolean,
      default: false,
    },
    operator: String,
    serie: {
      type: Number,
      default: undefined,
    },
    group: {
      type: Number,
      default: undefined,
    },
    include: Boolean,
    success: Array,
    valueOrigin: String,
    referenceObjects: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      fieldsAutoComplete: {},
      activeDropdown: false,
      label: this.labelFieldProps,
      emptySearchTrigger: false,
      loading: false,
      itemsMarked: this.value || [],
      valueProps: [],
      labelFieldProps: '',
    };
  },
  computed: {
    /**
     * @description if has options => apply a select input
     */
    hasOptions() {
      return !isEmpty(this.options);
    },
    /**
     * @description if hasn't options => show just a integer or decimal select
     */
    hasSelect() {
      return !this.hasOptions && (this.type === valueType.integer || this.type === valueType.decimal);
    },
    /**
     * @description if has date format => apply date format
     */
    hasDateFormat() {
      if (this.format === 'date_iso') {
        this.applyDefaultDatepickerValue();
        return true;
      }
      return false;
    },
    /**
     * @description without options and date => apply a simple string input
     */
    hasSimpleInput() {
      return !this.hasOptions && !this.hasDateFormat && this.type === valueType.string;
    },
    getReferenceObjects: function () {
      return this.referenceObjects;
    },
    getItemsMarked: function () {
      return this.itemsMarked;
    },
  },
  watch: {
    validate() {
      this.validateValues();
    },
    value() {
      this.itemsMarked = this.value || [];
      this.valueProps = this.value;
    },
    labelField() {
      this.labelFieldProps = this.labelField;
    },
    'valueProps.0'() {
      if (this.hasDateFormat) {
        this.valueProps[1] = this.valueProps[0];
      }
    },
  },
  mounted() {
    this.valueProps = this.value;
    this.labelFieldProps = this.labelField;

    this.dropDownClose();
    bus.$on('validate-fields', data => {
      if (data.include === this.include && data.group === this.group) {
        // only visible fields
        const elements = document.querySelectorAll(
          `.input-${this.group}-${this.serie}:not(.filter):not([style*="display:none"])`,
        );
        // validate fields individually
        elements.forEach(element => {
          this.validateValues(element);
        });
      }
    });
  },
  beforeUnmount() {
    bus.$off('operator-options-updated');
  },
  methods: {
    /* eslint-disable function-paren-newline */
    /* eslint-disable no-shadow */
    validateValues(target) {
      const between = target.dataset.between ? 1 : 0;
      const result = validate.field(this.format, this.type, between ? this.valueProps[1] : this.valueProps[0]);

      this.success[between] = result;
      if (!result) {
        this.$emit('showTooltip', true);
        setTimeout(() => this.$emit('showTooltip', false), 2000);
      }
    },
    inputSearch(event) {
      // if already searched for something, and has some item selected
      // when click search by the first input word
      const userTerm = event.type === 'click' ? event.target.value.split(' ')[0] : event.target.value;
      const fieldName = this.name;

      this.loading = true;
      this.activeDropdown = true;
      this.fieldsAutoComplete = {};

      bus.$emit('show-spinner', 'audience-autocomplete-loader');

      clearTimeout(timeoutKeyUp);

      timeoutKeyUp = setTimeout(() => {
        bus.$emit('show-spinner', 'audience-autocomplete-loader');
        if (userTerm.length > 0) {
          getResultByTerm(fieldName, userTerm).then(response => {
            const data = response?.data;
            if (data.has_data) {
              this.fieldsAutoComplete = Object.assign({}, data.series);
            }
            this.loading = false;
            this.activeDropdown = true;
            bus.$emit('hide-spinner', 'audience-autocomplete-loader');
          });
        } else {
          this.loading = false;
          this.fieldsAutoComplete = {};
          if (!this.multipleSelection) {
            this.valueProps = [];
            this.labelFieldProps = '';
            bus.$emit('audience-reset-form-condition');
          }
          this.activeDropdown = true;
          this.emptySearchTrigger = true;
        }
      }, 400);
    },
    openDropDown(event) {
      this.loading = true;
      this.activeDropdown = true;
    },
    updateLabelField() {
      bus.$emit('audience-labelField-multiselect-update');
    },
    updateField(item) {
      // declare variable with referenced objects prop
      let itemsChecked = this.getReferenceObjects;
      // check if field has multiselection
      if (this.multipleSelection && /equal/g.test(this.operator)) {
        // store index of arg item
        const ITEM_ADDED_INDEX = this.itemsMarked.indexOf(item[0]);
        // if there isn't item in itemsMarked add it to itemsMarked and update audience with value, options and items
        // if item exists remove by index using splice
        if (ITEM_ADDED_INDEX === -1) {
          this.itemsMarked.push(item[0]);
          itemsChecked[item[0]] = item[1];
        } else {
          this.itemsMarked.splice(ITEM_ADDED_INDEX, 1);
        }
        // emmit event with difference between id's in itemsMarked and options with multipleSelection boolean

        bus.$emit('audience-fields-update', {
          items: _.pick(itemsChecked, this.getItemsMarked),
          isMultipleSelection: this.multipleSelection,
        });
      } else {
        this.activeDropdown = false;
        bus.$emit('audience-fields-update', {
          items: item,
          isMultipleSelection: false,
        });
      }
    },
    updateFieldOptions(item) {
      // declare variable with referenced objects prop
      let itemsChecked = this.getReferenceObjects;
      // argument is an array of [key, value] pair
      // check if field has multiselection

      if (this.multipleSelection) {
        // store index of arg item
        const ITEM_ADDED_INDEX = this.itemsMarked.indexOf(item[0]);
        // if there isn't item in itemsMarked add it to itemsMarked and update audience with value, options and items
        // if item exists remove by index using splice
        if (ITEM_ADDED_INDEX === -1) {
          this.itemsMarked.push(item[0]);
          itemsChecked[item[0]] = item[1];
        } else {
          this.itemsMarked.splice(ITEM_ADDED_INDEX, 1);
        }

        // emmit event with difference between id's in itemsMarked and options with multipleSelection boolean
        bus.$emit('audience-fields-update', {
          items: _.pick(itemsChecked, this.getItemsMarked),
          isMultipleSelection: this.multipleSelection,
        });
      } else {
        this.activeDropdown = false;
        bus.$emit('audience-fields-update', item);
      }
    },
    dropDownClose() {
      document.body.addEventListener('click', e => {
        // know classes to avoid of click out dropdown
        const PREVENTION_CLASSES = [
          'input-autocomplete',
          'multiselection',
          'multiselection-content',
          'items-marked',
          'ig-click',
        ];
        // if has any class of array on classList of any targe element,
        //  close function will be ignored
        const HAS_ANY_CLASS = Boolean(PREVENTION_CLASSES.filter(cl => e.target.classList.contains(cl)).length);
        if (this.activeDropdown && !HAS_ANY_CLASS) {
          this.activeDropdown = false;
          this.emptySearchTrigger = false;
          this.fieldsAutoComplete = {};
        }
      });
    },
    /**
     * Check if has empty input
     * @return {boolean}
     */
    hasEmptySearch() {
      let value;
      if (!this.labelFieldProps) {
        value = true;
      }
      return value;
    },
    /**
     * Show new search info to user
     * Function trigger after focus event
     */
    checkEmptySearch() {
      if (this.hasEmptySearch()) {
        this.emptySearchTrigger = true;
        this.activeDropdown = true;
        if (!this.multipleSelection) {
          bus.$emit('audience-reset-form-condition');
        }
      }
    },
    /**
     * Check if has value inside the main object
     * @return {boolean}
     */
    autoCompleteHasData() {
      return Object.keys(this.fieldsAutoComplete).length > 0;
    },
    /**
     * When contains any text inside input and isn't loading
     * @return {boolean}
     */
    hasNoResults() {
      let value;
      if (!this.hasEmptySearch() && !this.loading) {
        this.emptySearchTrigger = false;
        value = this.labelFieldProps.length && Object.keys(this.fieldsAutoComplete).length === 0;
      }
      return value;
    },
    disabledDate(time) {
      return disabledBackwardDatesFromStartDate(time, this.valueProps[0]);
    },
    applyDefaultDatepickerValue() {
      this.valueProps[0] = moment().format(dateFormat);
      this.valueProps[1] = moment().format(dateFormat);
    },
    renderClass(success, group, serie, name, valueOrigin) {
      return (
        (success ? 'success' : 'error') +
        ` input-${group}-${serie}` +
        ` datepicker-${name}-${group}-${serie}` +
        ` ${valueOrigin}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-attention-14px {
  display: none;
  color: $color-red;
  font-size: 20px;
  position: absolute;
  top: 7px;
  right: 10px;
}
.error + .icon-attention-14px {
  display: block;
}
.items-group {
  display: flex;
  .and {
    display: flex;
    align-items: center;
    padding: 0 5px;
    cursor: default;
    text-transform: lowercase;
  }
}
.columns-list {
  width: 100%;
  padding-top: 35px;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0;
  border: 1px solid $gray-500;
  border-top: 0;
  border-radius: 18px;
  z-index: 100;
  .list {
    height: 100%;
    overflow: hidden;
    background-color: $color-white;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  .customScrollBar {
    max-height: 125px;
    overflow-y: auto;
    margin: 10px 0;
    list-style: none;
    padding: 0;
    pointer-events: auto;
    font-size: 12px;
    color: $gray-700;
    line-height: 26px;
    position: relative;
    .item {
      cursor: pointer;
      padding: 0 15px;
      font-size: 12px;
      color: $gray-700;
      text-transform: capitalize;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      &.multiselection {
        display: grid;
        grid-template-columns: 16px 1fr;
        align-items: center;
        .text {
          margin-left: 10px;
        }
      }
    }
    .off {
      text-align: center;
      text-transform: initial;
    }
    .spinner {
      min-height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
  strong {
    padding: 0 15px;
  }
}
.items-marked {
  padding: 15px 0;
  font-size: 12px;
  color: #666;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #eee;
  pointer-events: auto;
  > .text {
    padding: 0 15px;
    font-weight: 500;
    color: grey;
  }
  .selecteds {
    display: grid;
    grid-template-columns: 1fr 26px;
    padding: 0 15px;
    cursor: pointer;
    .trash-icon {
      transition: 0.3s ease-in;
      opacity: 0.4;
      grid-column-end: none;
    }
    &:hover {
      .trash-icon {
        opacity: 1;
      }
    }
  }
  .update-button {
    margin-top: 15px;
    text-align: center;
    padding: 0 10%;
    button {
      margin: auto;
      width: 100%;
      height: 40px;
      max-width: 170px;
    }
  }
}

.wrap {
  .input-options {
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.items-group {
  .element--datepicker.error {
    .el-date-editor.el-input__wrapper,
    .el-date-editor.el-input {
      .el-input__wrapper {
        border-color: $color-red;
        border-radius: 25px;
        box-shadow: unset;
        padding: 0px 10px;

        .el-input__prefix {
          display: none;
        }
      }
    }

    .icon-attention-14px {
      display: block;
    }
  }

  .element--datepicker {
    .el-date-editor.el-input__wrapper,
    .el-date-editor.el-input {
      .el-input__wrapper {
        .el-input__prefix {
          display: none;
        }
      }
    }
  }
}
</style>
