<template>
  <p :class="getText().length === 2 ? 'no-justify' : ''">
    <template v-if="getText().length">
      <span class="value-text">{{ $_formatValue(getText()[0], 'total') }}</span>
      <span v-if="getText().length === 2" class="value-text">({{ $_formatValue(getText()[1], 'total') }})</span>
    </template>
    <template v-else>
      <!-- rule for old object format -->
      <span class="value-text">{{ $_formatValue(dataValue[0], 'total') }}</span>
    </template>
    <span v-if="getBar().length" class="bar"><span class="fill" :style="`width: ${getBar()[0].total}%`" /></span>
  </p>
</template>

<script>
import valueMixin from '@/helpers/mixins/valueMixin';

export default {
  name: 'ValueCell',
  mixins: [valueMixin],
  props: {
    dataValue: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getText() {
      return this.dataValue.filter(e => e.show === 'text');
    },
    getBar() {
      return this.dataValue.filter(e => e.show === 'bar');
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.no-justify {
    justify-content: start;
  }
  .value-text:nth-of-type(2) {
    font-weight: 600;
    margin-left: 5px;
  }
  .bar {
    width: 50px;
    height: 8px;
    display: inline-block;
    .fill {
      min-width: 9%;
      display: block;
      background: #36aad6;
      height: 100%;
    }
  }
}
</style>
