<template>
  <select v-if="operatorOptions.length" v-model="$parent.data.operator" @change="$parent.updateValueInfos">
    <option v-for="operator in operatorOptions" :key="operator.key" :value="operator.key">
      {{ operator.name }}
    </option>
  </select>
</template>
<script>
export default {
  name: 'CustomersFilterOperator',
  props: {
    operatorOptions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
