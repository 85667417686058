<template>
  <div class="gaugeChart">
    <div class="value-label text-center">{{ $t('tracked-revenue.gauge') }}</div>
    <div :id="labelId"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
  name: 'GaugeChart',
  props: {
    chartValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelId: `gaugeChart-${Math.random() * 100}`,
    };
  },
  mounted() {
    this.createGaugeChart();
  },
  methods: {
    recalculateTrackedRevenueGauge() {
      let gaugeTrackedRevenueRate = ((this.totalTrackedRevenue * 100) / this.totalRevenue).toFixed(0);
      let gaugeColor = '#A167D9';
      if (this.attributionFilter.storeType === 'on') {
        gaugeTrackedRevenueRate = ((this.totalRevenueTrackedOn * 100) / this.totalRevenueOn).toFixed(0);
        gaugeColor = '#A167D9';
      }
      if (this.attributionFilter.storeType === 'off') {
        gaugeTrackedRevenueRate = ((this.totalRevenueTrackedOff * 100) / this.totalRevenueOff).toFixed(0);
        gaugeColor = '#A167D9';
      }
      this.gaugeColor = gaugeColor;
      this.gaugeScaled = parseInt(gaugeTrackedRevenueRate, 10);
      this.createGaugeChart();
    },
    createGaugeChart() {
      const gaugeColor = '#A167D9';
      const labelColor = '#555555';
      const seriesData = [this.$props.chartValue.value];
      seriesData.push(this.gaugeScaled);
      Highcharts.chart(this.labelId, {
        chart: {
          type: 'solidgauge',
          backgroundColor: 'transparent',
          margin: 0,
          width: 150,
          height: 100,
        },
        exporting: {
          enabled: false,
        },
        title: null,
        legend: {
          enabled: false,
        },
        pane: {
          center: ['50%', '90%'],
          size: '140%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'solid',
          },
        },
        tooltip: {
          enabled: false,
        },
        yAxis: {
          min: 0,
          max: 100,
          stops: [
            [0.1, gaugeColor],
            [0.5, gaugeColor],
            [0.9, gaugeColor],
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickPixelInterval: 400,
          tickWidth: 0,
          title: {
            enabled: false,
          },
          labels: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
        series: [
          {
            name: '',
            data: seriesData,
            dataLabels: {
              y: 30,
              format: `<div style="text-align:center"><h2 style="color: ${labelColor};font-family:Montserrat,sans-serif;font-size:16px;">{y}%</h2><br/></div>`,
            },
            tooltip: {
              valueSuffix: '',
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gaugeChart {
  display: inline-block;
  position: relative;
  top: -20px;
  &:before {
    content: '';
    background: white;
    width: 110%;
    display: block;
    height: 10px;
    position: relative;
  }
}
</style>
