// libs
import { escapeRegExp } from 'lodash';
// helpers
import { stringParser } from '@/helpers/string-parser.js';

// endpoints
import { getTableMetrics } from '@/services/nps/campaigns';

const $_objectToArray = value => {
  return Object.keys(value).map(item => ({
    key: value[item].key,
    name: value[item].name,
  }));
};

const npsCampains = {
  namespaced: true,
  state: {
    firstLoad: null,

    // Table
    metrics: [],
    dimensions: [],
    checkedMetrics: ['sent', 'delivered_rate', 'open_rate', 'ctr', 'cto', 'bounces'],
    checkedDimensions: ['date', 'subject'],
    filterDimensions: [],
    filterMetrics: [],
    lastCustomConfig: {},
  },
  mutations: {
    SET_METRICS(state, metrics) {
      state.metrics = metrics;
    },
    SET_DIMENSIONS(state, dimensions) {
      state.dimensions = dimensions;
    },
    SET_CHECKED_METRICS(state, checked) {
      state.checkedMetrics = checked;
    },
    SET_CHECKED_DIMENSIONS(state, checked) {
      state.checkedDimensions = checked;
    },
    SET_FILTER_DIMENSIONS(state, dimensions) {
      state.filterDimensions = dimensions;
    },
    SET_FILTER_METRICS(state, metrics) {
      state.filterMetrics = metrics;
    },
    SET_LAST_CUSTOM_CONFIG(state) {
      state.lastCustomConfig = {
        metrics: state.checkedMetrics,
        dimensions: state.checkedDimensions,
        filterMetrics: state.filterMetrics,
        filterDimensions: state.filterDimensions,
      };
    },
    DELETE_FILTER_DIMENSIONS(state) {
      state.filterDimensions = state.filterDimensions.filter(el => state.checkedDimensions.includes(el.key));
    },
    DELETE_FILTER_METRICS(state) {
      state.filterMetrics = state.filterMetrics.filter(el => state.checkedMetrics.includes(el.key));
    },
    DELETE_CHECKED_METRIC(state, index) {
      state.checkedMetrics.splice(index, 1);
    },
    DELETE_CHECKED_DIMENSION(state, index) {
      state.checkedDimensions.splice(index, 1);
    },
    RESET_ALL_CHECKED(state) {
      state.checkedMetrics = [];
      state.checkedDimensions = [];
      state.filterMetrics = [];
      state.filterDimensions = [];
    },
  },
  actions: {
    updateMetrics({ commit }, payload) {
      getTableMetrics(payload).then(({ data }) => {
        commit('SET_METRICS', $_objectToArray(data.table_metrics.metrics));
        commit('SET_DIMENSIONS', $_objectToArray(data.table_metrics.dimensions));
      });
    },
    updateCheckedMetrics({ commit }, payload) {
      commit('SET_CHECKED_METRICS', payload);
    },
    updateCheckedDimensions({ commit }, payload) {
      commit('SET_CHECKED_DIMENSIONS', payload);
    },
    updateLastCustomConfig({ commit }) {
      commit('SET_LAST_CUSTOM_CONFIG');
    },
    updateFilterDimensions({ commit }, payload) {
      commit('SET_FILTER_DIMENSIONS', payload);
    },
    updateFilterMetrics({ commit }, payload) {
      commit('SET_FILTER_METRICS', payload);
    },
    deleteCheckedMetric({ commit }, payload) {
      commit('DELETE_CHECKED_METRIC', payload);
      commit('DELETE_FILTER_METRICS');
    },
    deleteCheckedDimension({ commit }, payload) {
      commit('DELETE_CHECKED_DIMENSION', payload);
      commit('DELETE_FILTER_DIMENSIONS');
    },
    resetAllStoreMetrics({ commit }) {
      commit('RESET_ALL_CHECKED');
    },
  },
  getters: {
    getAllCheckedMetrics: state => {
      return {
        metrics: state.checkedMetrics,
        dimensions: state.checkedDimensions,
      };
    },
    getLastConfigMetrics: state => {
      return state.lastCustomConfig;
    },
    getFilteredDimensions:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.dimensions.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getFilteredMetrics:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.metrics.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
  },
};

export default npsCampains;
