<template>
  <div class="bar-chart-row" :class="getCustomClasses()">
    <div class="bar-chart-label text-overflow">
      <p
        :class="labelTooltip ? 'bar-chart-tooltip' : ''"
        v-tooltip.top-start="{
          content: labelTooltip,
          theme: 'default',
          distance: 8,
        }"
      >
        {{ label }}
      </p>
    </div>
    <chart-bar :progress="progress" :value="value" :color="color" :direction="direction" :valueTooltip="valueTooltip" />
    <div v-if="tooltip" class="bar-tooltip" v-html="tooltip"></div>
  </div>
</template>

<script>
import ChartBar from '@/components/_atoms/ChartBar';
import '@/plugins/tooltip/tooltip';

export default {
  name: 'ChartRow',
  components: {
    ChartBar,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    progress: {
      type: String,
      default: '100',
    },
    value: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'lightblue',
    },
    tooltip: {
      type: String,
      default: undefined,
    },
    labelTooltip: {
      type: String,
      default: undefined,
    },
    valueTooltip: {
      type: String,
      default: undefined,
    },
    preset: {
      type: String,
      default: 'default',
    },
    direction: {
      type: String,
      default: '',
    },
  },
  methods: {
    getCustomClasses() {
      const preset = this.preset !== undefined ? `custom-p-${this.preset}` : '';
      const direction = this.direction !== undefined ? `custom-d-${this.direction}` : '';
      return `${preset} ${direction}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-chart-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  &:hover {
    .bar-tooltip {
      display: block;
    }
  }
}
.bar-chart-label {
  max-width: 40%;
  .bar-chart-tooltip {
    cursor: pointer;
  }
  p {
    font-size: 12px;
    color: #666;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.custom-p-subtitle {
  flex-direction: column;
  .bar-chart-label {
    max-width: 100%;
  }
  .atom-bar-chart {
    max-width: 100%;
  }
}
.bar-tooltip {
  display: none;
  position: absolute;
  bottom: 18px;
  right: 0;
  background-color: $color-white;
  font-size: 12px;
  color: $gray-800;
  line-height: 20px;
  padding: 12px 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  &:before {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $color-white;
    bottom: -8px;
  }
  strong {
    font-weight: 600;
  }
}
</style>
