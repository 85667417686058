<template>
  <form class="register-customer-form">
    <div v-if="$_verifyLoaded('done')">
      <div v-for="(section, key) in sections" :key="key">
        <template v-if="key !== 'key'">
          <div class="row rcf__section" :class="{ form_disabled: registerFormDisabled }">
            <div class="col-md-4">
              <h3>{{ sectionName(key) }}</h3>
            </div>
            <div class="col-md-8 rcf__fields" :class="{ form_disabled: registerFormDisabled }">
              <div
                v-for="(field, index) in section"
                :key="index"
                :class="isMobileField(field) ? `rcf__field_${field.id}` : 'rcf__field'"
              >
                <template v-if="field.depends && field.type === 'enum'">
                  <label>{{ field.label }}<template v-if="field.required === 1">*</template></label>
                  <v-select
                    :ref="field.id"
                    :key="customerData[field.id]"
                    v-model="customerData[field.id]"
                    label="desc"
                    :clearable="false"
                    class="rcf__basic-data-input"
                    :options="field.options"
                    :taggable="isEditable(field.editable)"
                    :placeholder="field.label"
                    :loading="field.loading"
                    :disabled="dependsValidate(field.depends)"
                    @search:focus="callDependsRequest(field.id, key, field.depends)"
                    @search="searchResultsWithDepends($event, field.id, key, field.depends)"
                  >
                    <template #option="{ desc }">
                      {{ $t(`${desc}`) }}
                    </template>
                    <template #selected-option="{ desc }">
                      {{ $t(`${desc}`) }}
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes" class="arrow-down"></span>
                    </template>
                  </v-select>
                </template>
                <template v-else-if="field.depends && field.type === 'string'">
                  <label>{{ field.label }}<template v-if="field.required === 1">*</template></label>
                  <input
                    :ref="field.id"
                    v-model="customerData[field.id]"
                    class="rcf__basic-data-input"
                    type="text"
                    :disabled="dependsValidate(field.depends)"
                    :placeholder="field.label"
                    @change="validateField(field)"
                  />
                </template>
                <template v-else-if="field.type === 'enum' && field.autoComplete === true">
                  <label>{{ field.label }}<template v-if="field.required === 1">*</template></label>
                  <v-select
                    :ref="field.id"
                    v-model="customerData[field.id]"
                    label="desc"
                    :clearable="false"
                    class="rcf__basic-data-input"
                    :taggable="isEditable(field.editable)"
                    :options="field.options"
                    :placeholder="field.label"
                    :loading="field.loading"
                    @search="searchResults($event, field.id, key)"
                    @search:focus="searchResults('', field.id, key)"
                  >
                    <template #option="{ desc }">
                      {{ $t(`${desc}`) }}
                    </template>
                    <template #selected-option="{ desc }">
                      {{ $t(`${desc}`) }}
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes" class="arrow-down"></span>
                    </template>
                  </v-select>
                </template>
                <template v-else-if="field.type === 'enum'">
                  <label>{{ field.label }}<template v-if="field.required === 1">*</template></label>
                  <v-select
                    :ref="field.id"
                    v-model="customerData[field.id]"
                    label="name"
                    :clearable="false"
                    class="rcf__basic-data-input"
                    :taggable="isEditable(field.editable)"
                    :options="field.options"
                    :placeholder="field.label"
                  >
                    <template #option="{ desc }">
                      {{ $t(`${desc}`) }}
                    </template>
                    <template #selected-option="{ desc }">
                      {{ $t(`${desc}`) }}
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes" class="arrow-down"></span>
                    </template>
                  </v-select>
                </template>
                <template v-else-if="field.id === 'date_birth'">
                  <label> {{ field.label }}<template v-if="field.required === 1">*</template> </label>
                  <input
                    :ref="field.id"
                    v-model="customerData[field.id]"
                    v-mask="['##/##/####']"
                    class="rcf__basic-data-input"
                    type="text"
                    :placeholder="'dd/mm/aaaa'"
                    @change="validateField(field)"
                  />
                </template>
                <template v-else-if="field.id === 'zipcode'">
                  <label>{{ field.label }}<template v-if="field.required === 1">*</template></label>
                  <input
                    :ref="field.id"
                    v-model="customerData[field.id]"
                    v-mask="['#####-###']"
                    class="rcf__basic-data-input rcf__zipcode"
                    :placeholder="field.label"
                    @change="validateField(field)"
                    @blur="searchZipCode"
                  />
                </template>
                <template v-else-if="field.id === 'ddi'">
                  <label>{{ field.label }}<template v-if="field.required === 1">*</template></label>
                  <input
                    :data-cy="`input_${field.id}_customer`"
                    :ref="field.id"
                    v-model="customerData[field.id]"
                    v-mask="['+##', '+###']"
                    class="rcf__basic-data-input"
                    :placeholder="field.label"
                    @change="validateField(field)"
                  />
                </template>
                <template v-else>
                  <label> {{ field.label }}<template v-if="field.required === 1">*</template> </label>
                  <div v-if="isInputMobileNumber(field)">
                    <input
                      :data-cy="`input_${field.id}_customer`"
                      :ref="field.id"
                      v-model="customerData[field.id]"
                      class="rcf__basic-data-input"
                      v-mask="['(##)#####-####', '(##)####-####']"
                      type="text"
                      placeholder="(88)88888-8888"
                      @change="validateField(field)"
                    />
                  </div>
                  <div v-else>
                    <input
                      :data-cy="`input_${field.id}_customer`"
                      :ref="field.id"
                      v-model="customerData[field.id]"
                      class="rcf__basic-data-input"
                      type="text"
                      :placeholder="field.label"
                      @change="validateField(field)"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <button data-cy="register_customer_button" class="btn rcf__btn" @click.prevent="registerCustomer">
        {{ $t('360.register-customer.button') }}
      </button>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" class="card-loader" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </form>
</template>

<script>
// libs
import vSelect from 'vue-select';
import moment from 'moment';
import { useToast } from 'vue-toastification';
// helpers
import bus from '@/helpers/events/bus';
import { verifyMixin } from '@/helpers/mixins';
import { dateComplete } from '@/utilities/constants';
// services
import { getCustomerZipCode, getResultsByField, getFieldsNewCustomer, putCustomer } from '@/services/oto/customers';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'RegisterCustomerForm',
  components: {
    vSelect,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      registerFormDisabled: true,
      regexValidations: {},
      requiredFields: [],
      sections: {},
      customerData: {},
    };
  },
  mounted() {
    this.fetchData();

    bus.$on('validate-new-customer', ({ document_number, isDisabled, ddi }) => {
      this.customerData.document_number = document_number;
      this.customerData.ddi = ddi;
      this.registerFormDisabled = isDisabled;
    });
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      getFieldsNewCustomer()
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;
          this.sections = data.data;
          this.requiredFieldsCheck();
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    isInputMobileNumber(field) {
      return field.id === 'mobile_number';
    },

    isMobileField(field) {
      return field.id === 'mobile_number' || field.id === 'ddi';
    },

    /**
     * @description organize sections names
     */
    sectionName(key) {
      switch (key) {
        case 'profile':
          return this.$t('360.register-customer.basic-data');
        case 'address':
          return this.$t('360.register-customer.address');
        case 'preferences':
          return this.$t('360.register-customer.preferences');
        case 'credit':
          return this.$t('360.register-customer.credit');
        default:
          return null;
      }
    },

    registerCustomer() {
      const customer = Object.entries(this.customerData);

      for (const index in this.requiredFields) {
        const filter = customer.filter(el => el[0] === this.requiredFields[index]);
        const fieldKey = this.requiredFields[index];

        if (filter.length < 1 || (filter.length && filter[0][1]?.length === 0)) {
          this.$refs[fieldKey][0].classList.add('field-invalid');
          this.toast.error('Campo(s) obrigatório(s) não preenchido(s)', { timeout: 5000 });
          return;
        }
      }

      if (!this.regexValidate()) {
        this.toast.error('Campo(s) inválido(s)', { timeout: 5000 });
        return;
      }

      const newCustomerData = this.organizeCustomerFields();

      putCustomer(newCustomerData).then(({ data }) => {
        const customer_id = data?.data?.customer_id;
        this.$router.push({ name: '360-customerviewId', params: { id: customer_id } });
      });
    },

    /**
     * @description treatment of data before sending them
     */
    organizeCustomerFields() {
      const newCustomerData = {};
      const customer = this.customerData;

      Object.keys(customer).forEach(function (item) {
        if (item === 'date_birth') {
          const dateFormat = moment(customer[item], dateComplete).format(dateComplete);
          newCustomerData[item] = dateFormat;
        } else if (item === 'mobile_number' || item === 'ddi') {
          const er = /[^a-z0-9]/gi;
          const mobileFormat = customer[item].replace(er, '');
          newCustomerData[item] = mobileFormat;
        } else if (typeof customer[item] === 'object' && customer[item] !== null) {
          newCustomerData[item] = !customer[item].id ? customer[item]?.desc : customer[item]?.id;
        } else {
          newCustomerData[item] = customer[item];
        }
      });

      return newCustomerData;
    },

    /**
     * @description Zipcode search, validation of it and set address informations
     */
    searchZipCode(event) {
      let code = event?.target?.value;
      if (!code) return;

      code = code.replace(/[^\w\s]/gi, '');
      if (code.length < 4) return;

      getCustomerZipCode(code).then(({ data }) => {
        if (!data) return;
        const dataValue = data?.data;
        const country = dataValue?.country;
        const state = dataValue?.state;
        const city = dataValue?.city;
        const address = dataValue?.address;

        if (country) this.customerData['country'] = country;
        else this.customerData['country'] = '';

        if (state) this.customerData['state'] = { id: `${state}`, desc: `${state}` };
        else this.customerData['state'] = { id: '', desc: '' };

        if (city) this.customerData['city'] = { id: `${city}`, desc: `${city}` };
        else this.customerData['city'] = { id: '', desc: '' };

        if (address) this.customerData['address'] = address;
        else this.customerData['address'] = '';
      });
    },

    /**
     * @description Validate if this field is editable or not
     */
    isEditable(editable) {
      return editable === 1 ? true : false;
    },

    /**
     * @description Validate if the field have depends and if is filled
     */
    dependsValidate(depends) {
      if (!depends || (this.customerData && this.customerData[depends])) return false;
      else return true;
    },

    /**
     * @description Organize depends field data and call the getResultsByField request
     */
    callDependsRequest(fieldKey, sectionKey, fieldDepends) {
      this.searchResultsWithDepends('', fieldKey, sectionKey, fieldDepends);
    },

    /**
     * @description Specific search according to field and term in a field with depends
     */
    searchResultsWithDepends(term, fieldKey, sectionKey, fieldDepends) {
      const filterTerm = term.length > 0 ? term : ' ';

      const sectionGroup = this.sections[sectionKey];
      const keyGroup = sectionGroup?.filter(el => el.id === fieldKey);
      keyGroup[0]['loading'] = true;

      getResultsByField({
        field: fieldKey,
        term: filterTerm,
        where: `${fieldDepends}:${this.customerData[fieldDepends].id}`,
      })
        .then(({ data }) => {
          keyGroup[0].options = data?.data;
        })
        .finally(() => {
          keyGroup[0]['loading'] = false;
        });
    },

    /**
     * @description Specific search according to field and term
     */
    searchResults(term, fieldKey, sectionKey) {
      const filterTerm = term.length > 0 ? term : ' ';

      const sectionGroup = this.sections[sectionKey];
      const keyGroup = sectionGroup?.filter(el => el.id === fieldKey);
      keyGroup[0]['loading'] = true;

      getResultsByField({
        field: fieldKey,
        term: filterTerm,
      })
        .then(({ data }) => {
          keyGroup[0].options = data?.data;
        })
        .finally(() => {
          keyGroup[0]['loading'] = false;
        });
    },

    /**
     * @description Specific search according to field and term
     */
    validateField(field) {
      const { id, mask } = field;
      const fieldRef = this.$refs[id][0].classList;

      if (fieldRef.contains('field-invalid')) fieldRef.remove('field-invalid');

      if (!mask) return;
      const regex = new RegExp(`${mask}`);

      const regexCheck = regex.test(this.customerData[id]);
      this.regexValidations[id] = regexCheck;
      if (!regexCheck) fieldRef.add('field-invalid');
    },

    /**
     * @description Validate fields with regex
     */
    regexValidate() {
      const validationsValues = Object.values(this.regexValidations);
      return !validationsValues.some(value => value === false);
    },

    /**
     * @description Select fields are required
     */
    requiredFieldsCheck() {
      const fields = Object.entries(this.sections)
        .map(section => section[1])
        .flat()
        .filter(item => item.required);

      this.requiredFields = fields.map(field => field.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.register-customer-form {
  background-color: #fff;
  padding: 65px 50px;
  min-height: 500px;
  position: relative;

  .rcf__section {
    padding: 45px 0;

    &.form_disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .rcf__fields {
    display: flex;
    flex-wrap: wrap;

    .rcf__field {
      width: 50%;
    }
    .rcf__field_ddi {
      width: 15%;
    }

    .rcf__field_mobile_number {
      width: 35%;
    }

    h3 {
      color: $gray-700;
    }
    .rcf__basic-data-input {
      width: 95%;
      margin-bottom: 15px;

      &.field-invalid {
        border-color: $oto-seller;
      }
    }
  }

  // remove arrows of type number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .rcf__btn {
    float: right;
    margin-right: 15px;
    width: 195px;
    padding: 0 15px;
  }

  .card-loader {
    min-height: 500px;
  }
}
</style>

<style lang="scss">
.register-customer-form {
  .rcf__basic-data-input {
    .vs__search {
      max-width: 195px;
    }

    .vs__dropdown-menu {
      max-height: 230px;
    }

    &.field-invalid {
      .vs__dropdown-toggle {
        border-color: $oto-seller;
      }
    }
  }
}
</style>
