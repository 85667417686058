<template>
  <div class="comparable-store-item">
    <h6 class="chart-name">{{ options.name }}</h6>
    <div class="row">
      <div class="col min-max">
        <span class="strong">{{ formatValue(getMinValue()) }}</span>
      </div>
      <div class="col col-xl-auto">
        <div :id="chartID"></div>
      </div>
      <div class="col min-max text-right">
        <span class="strong">{{ formatValue(getMaxValue()) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { minBy, maxBy } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  name: 'ComparableLine',
  props: {
    options: {
      type: Object,
      default() {
        return {
          value: 0,
          name: '',
          data: [],
          type: '',
        };
      },
    },
    storeSelectedName: {
      type: String,
      default: '',
    },
    segmentSelected: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartID: `comparable-line-chart-${this.options.name}`,
      radius: 5,
    };
  },
  computed: {
    ...mapState('indicators', ['sellerId']),
    ...mapGetters('indicators', ['getActiveSellerObject']),
    getSegmentSelectd() {
      if (this.segmentSelected === 'seller') {
        return this.segmentSelected;
      }
      return '';
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.createChart();
    });
  },
  methods: {
    createChart() {
      const self = this;
      Highcharts.chart(this.chartID, {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          height: 76,
          inverted: false,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: '',
        },
        xAxis: {
          title: null,
          startOnTick: true,
          endOnTick: false,
          labels: { enabled: false },
          visible: false,
          min: this.getMinValue(),
          max: this.getMaxValue(),
        },
        yAxis: {
          title: {
            text: '',
          },
          visible: true,
          labels: { enabled: false },
          min: this.radius,
          max: this.radius,
          endOnTick: false,
          gridLineColor: '#888',
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: this.radius,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(211, 211, 211)',
                },
              },
            },
            states: {
              hover: {
                marker: {
                  enabled: false,
                },
              },
            },
          },
        },
        tooltip: {
          formatter: function formatter() {
            const seller =
              this.series.name === 'selected'
                ? self.getActiveSellerObject
                : self.findSeller(self.listWithoutSeller(), this.point);

            if (self.getSegmentSelectd) {
              return `<span style="font-weight: 600">
                ${
                  seller?.fullName ? self.$i18n.t(seller?.fullName) : seller?.sellerName || self.$i18n.t('360.salesman')
                }
              </span><br/><span>
                ${seller?.store?.name ? seller.store.name : seller?.store || self.options.store}
              </span><br/>
                ${self?.options?.name}: <span style="font-weight: 600">${self.formatValue(this.point.x)}</span>`;
            }

            return `
              <span>
                ${seller?.store?.name ? seller.store.name : seller?.store || self.options.store}
              </span><br/>
                ${self?.options?.name}: <span style="font-weight: 600">${self.formatValue(this.point.x)}</span>
            `;
          },
          backgroundColor: '#FFFFFF',
          style: {
            color: '#555555',
            lineHeight: '20px',
          },
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        legend: { enabled: false },
        series: [
          {
            name: 'others',
            color: 'rgb(211, 211, 211)',
            data: this.listWithoutSellerFormatted(),
          },
          {
            name: 'selected',
            color: 'rgba(250, 111, 50, 0.75)',
            marker: {
              radius: this.radius * 2,
              symbol: 'circle',
            },
            data: [[this.formatNumber(this.options?.value), this.radius]],
            dataLabels: {
              enabled: true,
              y: -30,
              formatter() {
                return self.formatValue(this.x);
              },
              style: {
                color: '#666666',
                fontWeight: 600,
              },
            },
          },
        ],
      });
    },
    mapValues() {
      return this.options?.data?.map(e => Object.values(e)[0]);
    },
    listWithoutSeller() {
      const list = [];
      this.mapValues().map(sellerData => {
        if (sellerData.seller !== this.sellerId) {
          list.push(sellerData);
        }
      });
      return list;
    },
    listWithoutSellerFormatted() {
      const formatted = [];
      this.mapValues().map(sellerData => {
        if (sellerData.seller !== this.sellerId) {
          formatted.push([this.formatNumber(sellerData.value), this.radius]);
        }
      });
      return formatted;
    },
    formatNumber(num) {
      if (typeof num === valueType.string) {
        return Number(num.replace(',', '.'));
      }
      return num;
    },
    findSeller(sellerList, point) {
      const group = sellerList;
      const sellerId = group[point.index].seller;
      return group.find(seller => seller.seller === sellerId);
    },
    formatValue(value) {
      if (this.options?.type === valueType.percent) return formatPercent(value, 1);
      else if (this.options?.type === valueType.integer) return formatDecimal(value, 1);
      else if (this.options?.type === valueType.float) return formatDecimal(value, 2);
      else if (this.options?.type === valueType.decimal || this.options?.type === valueType.monetary)
        return formatCurrency(value);
      return value;
    },
    storeName(id) {
      return this.storesNames[id];
    },
    getMinValue() {
      if (!this.options.data.length) return;
      const minValue = minBy(
        this.options?.data?.map(e => Object.values(e)[0]),
        seller => seller?.value,
      ).value;
      if (minValue < this.options?.value) return minValue;
      else return this.options?.value;
    },
    getMaxValue() {
      if (!this.options.data.length) return;
      const maxValue = maxBy(
        this.options?.data?.map(e => Object.values(e)[0]),
        seller => seller?.value,
      ).value;
      if (maxValue > this.options?.value) return maxValue;
      else return this.options?.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.min-max {
  font-size: 12px;
  display: flex;
  padding-top: 0;
  align-items: center;
  color: $gray-700;
}
.chart-name {
  font-size: 12px;
  color: $gray-700;
}
.strong {
  font-weight: 600;
}
</style>
<style lang="scss">
.comparable-store-item {
  svg {
    overflow: initial;
  }
}
</style>
