<template>
  <div class="card">
    <div class="row suggestion-participants-header">
      <div class="sph__title-wrapper">
        <icon-base class="sph__icon" color="#fff" w="30" h="30" :box="'0 0 30 30'">
          <icon-oto />
        </icon-base>
        <h1 class="sph__title">{{ $t('campaigns.list-of-participants') }}</h1>
      </div>
      <div class="sph__period-wrapper">
        <p v-show="startDate && endDate" class="sph__period">
          {{ $tc('period', 1) }}: <strong>{{ formatDate(startDate) }} - {{ formatDate(endDate) }}</strong>
        </p>
      </div>
      <div class="sph__inputs-wrapper col-auto row">
        <div class="sph__inputs-item col-auto">
          <input v-model="searchTerm" class="sph__input" :placeholder="$t('360.campaigns.search-campaigns')" />
          <i class="icon icon-search sph__icon-search"></i>
        </div>

        <div class="sph__inputs-item">
          <v-select
            v-model="status"
            label="title"
            :clearable="false"
            class="rcf__basic-data-input"
            :options="statusItems"
            @option:selected="updateStatus()"
          >
            <template #option="{ title }">
              {{ $t(`${title}`) }}
            </template>
            <template #selected-option="{ title }">
              {{ $t(`${title}`) }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
          </v-select>
        </div>
        <div class="sph__inputs-item btn-options">
          <div class="export-wrapper">
            <button ref="button" class="btn export btn-export" @click="toggleExport">
              <i class="icon export-icon icon-download"></i>
              {{ this.$t('360.export', { value: '' }) }}
            </button>
            <div v-if="isExportVisible" class="export-container" v-click-outside="toggleExport">
              <div v-for="item in exportItems" :key="item.value" class="items" @click="fetchExportData(item.value)">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="loadingExport" color="colored-block" class="render-loader"></base-loader>
  </div>
</template>

<script>
// libs
import { mapActions } from 'vuex';
import { debounce } from 'lodash';
import vSelect from 'vue-select';
import moment from 'moment';
import bus from '@/helpers/events/bus';
import vClickOutside from 'click-outside-vue3';

// import moment from 'moment';
import FileDownload from 'js-file-download';

// services
import { getApprovalList } from '@/services/oto/campaigns';
import { getAction } from '@/services/actions';

// assets
import IconBase from '@/assets/vue-icons/IconBase';
import IconOto from '@/assets/vue-icons/icons/Oto';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

// helpers
import { dateComplete } from '@/utilities/constants';

export default {
  name: 'CampaignsManagementParticipantsHeader',
  components: {
    IconBase,
    IconOto,
    BaseLoader,
    vSelect,
  },
  props: {
    actionId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: '',
      startDate: '',
      endDate: '',
      isExportVisible: false,
      exportItems: [
        {
          value: 'csv',
          title: this.$t('360.export-type', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('360.export-type', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('360.export-type', { value: '.XLSX' }),
        },
      ],
      loadingExport: false,
      isActive: null,

      // status data
      status: {
        value: 'all',
        title: this.$t('campaigns.status.all'),
      },
      statusItems: [
        {
          value: 'all',
          title: this.$t('campaigns.status.all'),
        },
        {
          value: 'participating',
          title: this.$t('campaigns.status.participating'),
        },
        {
          value: 'rejected',
          title: this.$t('campaigns.status.rejected'),
        },
        {
          value: 'waiting',
          title: this.$t('campaigns.status.waiting'),
        },
        {
          value: 'no-response',
          title: this.$t('campaigns.status.no-response'),
        },
        {
          value: 'not-set',
          title: this.$t('campaigns.status.not-set'),
        },
      ],
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    searchTerm(term) {
      this.triggerDebounce(term);
    },
  },
  mounted() {
    this.fetchData();
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off('render-page-loader');
  },
  methods: {
    ...mapActions('campaignsManagement', ['updateListApprovalTerm', 'updateFilterStatus']),
    fetchData() {
      getAction(this.actionId).then(({ data }) => {
        this.startDate = data?.data?.startDate;
        this.endDate = data?.data?.endDate;
      });
    },

    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },

    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     */
    fetchExportData(fileType) {
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getApprovalList({ fileType, responseType: 'blob', actionId: this.actionId })
        .then(({ data }) => {
          const date = moment().format(dateComplete);
          FileDownload(data, `${date}.${fileType}`);
        })
        .finally(() => {
          this.loadingExport = false;
          bus.$emit('render-page-loader', false);
        });
    },

    updateStatus() {
      if (!this.status) return;

      if (this.status.value === 'all') {
        this.updateFilterStatus(null);
        return;
      }

      this.updateFilterStatus(this.status.value);
    },

    formatDate(date) {
      return moment(date).format(dateComplete);
    },

    triggerDebounce: debounce(function (term) {
      this.updateListApprovalTerm(term);
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.suggestion-participants-header {
  align-items: center;
  justify-content: space-between;
  display: flex;

  .sph__title-wrapper {
    width: 25%;

    @media screen and (max-width: 1200px) {
      width: 20%;
    }
  }

  .sph__period-wrapper {
    width: 20%;
  }

  .sph__inputs-wrapper {
    width: 50%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
      width: 57%;
    }

    .sph__inputs-item {
      width: 37%;

      &:last-child {
        width: 22%;
      }
    }
  }

  .sph__title {
    padding: 0 10px;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;

    @media screen and (max-width: 1200px) {
      padding: 0;
      padding-left: 5px;
      font-size: 16px;
    }
  }

  .sph__icon {
    height: 30px;
    width: 30px;
    vertical-align: middle;
    stroke: $oto-omni;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .sph__period {
    margin-bottom: 0;
    font-size: 13px;

    strong {
      font-size: 12px;
      color: $gray-600;
    }
  }

  .sph__inputs-item .sph__input {
    width: 100%;
    height: 34px;
    padding: 7px 35px 7px 12px;
  }

  .sph__icon-search {
    position: absolute;
    right: 15px;
    top: 6px;
    font-size: 24px;
    color: $gray-500;
    cursor: pointer;
  }

  .export-icon {
    font-size: 17px;
    font-weight: 600;
  }

  .btn-options {
    display: flex;
    position: relative;

    .export-wrapper {
      display: flex;
      position: relative;
      justify-content: space-evenly;
      width: fit-content;

      .export-container {
        position: absolute;
        top: 40px;
        z-index: 99;
        width: 100%;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);

        .items {
          background-color: $color-white;
          color: $gray-800;
          font-weight: bold;
          padding: 10px 15px;
          font-size: 12px;
          &:hover {
            background-color: $gray-300;
            cursor: pointer;
          }
        }
      }
    }

    .btn {
      align-items: center;
      display: flex;
      height: 36px;
      justify-content: space-evenly;
      line-height: 20px;
      width: fit-content;

      .icon {
        font-weight: bold;
      }

      &.export {
        width: 125px;
      }

      &.edit-columns {
        width: 160px;
      }

      &.edit-columns {
        margin-left: 10px;
      }
    }
  }
}

.card {
  .render-loader {
    background-color: #232642;
    height: 100%;
    left: 50%;
    opacity: 0.7;
    overflow: hidden;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 9999;
  }
}
</style>
