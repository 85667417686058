<template>
  <div class="suggestion-details-header" :id="id">
    <div class="sdh__select-fake-wrapper">
      <div id="comparison-criteria" class="form-group">
        <label>{{ $t('360.campaigns.details.grouping-criteria') }}</label>

        <div id="selectCriteria" class="select-fake metrics no-click" @click.prevent="toggleDropdown('criteriaOpened')">
          {{ $t(`stores.criteria-selection-${criteriaSelectedName}`) }}
        </div>

        <div v-show="criteriaOpened" class="check-list dropdown-menu no-click">
          <div class="list no-click">
            <div class="select no-click">
              <label class="no-click" @click.prevent="toggleCriteria('cluster')">
                <check-box class="no-click" :checked="criteriaSelected" />

                <div class="text no-click">
                  <span class="name no-click">{{ $t('stores.criteria-selection-cluster') }}</span>
                </div>
              </label>
            </div>

            <div class="select no-click">
              <label class="no-click" @click.prevent="toggleCriteria('region')">
                <check-box class="no-click" :checked="!criteriaSelected" />

                <div class="text no-click">
                  <span class="name no-click">{{ $t('stores.criteria-selection-region') }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div id="comparison-stores" class="form-group">
        <label>{{ $t('360.campaigns.details.stores-limit') }}</label>
        <div id="selectStores" class="select-fake stores no-click" @click.prevent="toggleDropdown('storesOpened')">
          {{ criteriaSelectedName === 'cluster' ? clustersSelected : regionsSelected }}
        </div>

        <div v-if="criteriaSelectedName === 'region'" v-show="storesOpened" class="check-list dropdown-menu no-click">
          <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-regions') }}</div>

          <div v-for="(value, key) in comparisonStoresList" :key="key" class="select no-click" :class="`select-${key}`">
            <label class="no-click" @click.prevent="toggleRegion(key)">
              <check-box class="no-click" :checked="isSelected(key, regions)" />

              <div class="text no-click">
                <span class="name no-click">{{ key }}</span>
              </div>
              <i class="icon icon-right-14px no-click"></i>
            </label>

            <div class="check-list-child no-click">
              <div class="list no-click">
                <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-states') }}</div>

                <div class="searchStore no-click">
                  <input
                    v-model="term"
                    type="text"
                    class="no-click"
                    :placeholder="$t('stores.stores-search')"
                    autocomplete="none"
                  />
                  <i class="icon icon-search no-click"></i>
                </div>
                <div
                  v-for="(stores, index) in value"
                  v-show="filterResults(stores)"
                  :key="index"
                  class="select no-click"
                  :class="`select-${index}`"
                >
                  <label class="no-click" @click.prevent="toggleState(key, index)">
                    <check-box class="no-click" :checked="isSelected(index, states)" />
                    <div class="text state no-click">
                      <span class="name no-click">{{ index }}</span>
                    </div>
                  </label>

                  <div class="check-list-subchild no-click">
                    <div v-for="store in stores" :key="store.id" class="select no-click">
                      <label
                        v-show="filterResults(store)"
                        :class="['no-click', checkboxDisabled(store.id) ? 'disabled' : '']"
                        @click.prevent="toggleStore(key, index, store.id)"
                      >
                        <check-box
                          class="no-click"
                          :checked="isSelected(store.id, filters.comparisonStores)"
                          :disabled="checkboxDisabled(store.id)"
                        />
                        <div class="text no-click">
                          <span class="name no-click">{{ $tc('store', 1) }} {{ store.name }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else v-show="storesOpened" class="check-list dropdown-menu no-click">
          <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-regions') }}</div>

          <div v-for="(stores, key) in orderbyClusterList" :key="key" class="select no-click" :class="`select-${key}`">
            <label class="no-click" @click.prevent="toggleCluster(key)">
              <check-box class="no-click" :checked="isSelected(key, clusters)" />

              <div class="text no-click">
                <span class="name no-click">{{ key }}</span>
              </div>
              <i class="icon icon-right-14px no-click"></i>
            </label>
            <div class="check-list-child no-click">
              <div class="list no-click">
                <div class="label text-uppercase mb-1 no-click">{{ $t('stores-title') }}</div>

                <div class="searchStore no-click">
                  <input
                    v-model="term"
                    type="text"
                    class="no-click"
                    :placeholder="$t('stores.stores-search')"
                    autocomplete="none"
                  />
                  <i class="icon icon-search no-click"></i>
                </div>

                <div
                  v-for="store in stores"
                  v-show="filterResults(store)"
                  :key="store.id"
                  class="select no-click"
                  :class="`select-${store.id}`"
                >
                  <label
                    :class="['no-click', checkboxDisabled(store.id) ? 'disabled' : '']"
                    @click.prevent="toggleStore(key, null, store.id)"
                  >
                    <check-box
                      class="no-click"
                      :checked="isSelected(store.id, filters.comparisonStores)"
                      :disabled="checkboxDisabled(store.id)"
                    />
                    <div class="text state no-click">
                      <span class="name no-click">{{ $tc('store', 1) }} {{ store.store }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CheckBox from '@/components/_atoms/CheckBox';
import bus from '@/helpers/events/bus';

export default {
  name: 'CampaignsManagementModalStores',
  data() {
    return {
      id: 'suggestion-campaigns-modal-stores',
      term: '',
      storesList: '',
      ready: false,
      criteriaOpened: false,
      storesOpened: false,
      criteriaSelected: true,
      criteriaSelectedName: null,
      states: [],
      clusters: [],
      regions: [],
      clustersSelected: this.$t('select'),
      regionsSelected: this.$t('select'),
    };
  },
  components: {
    CheckBox,
  },
  computed: {
    ...mapState('campaignsManagement', ['suggestionDetail']),
    comparisonStoresList() {
      const list = this.getComparisonStoresList();
      const regions = Object.keys(this.getComparisonStoresList());

      regions.forEach(region => {
        const states = Object.keys(list[region]);
        // ordena lojas de A-Z
        states.forEach(state => {
          list[region][state].sort((a, b) => (a.name > b.name ? 1 : -1));
        });
      });
      return list;
    },
    orderbyClusterList() {
      return this.groupByCluster();
    },
  },
  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
    filters: {
      type: Object,
      default: () => {},
    },
    groupIndex: {
      type: Number,
      default: 0,
    },
    groupingCriteria: {
      type: String,
      default: '',
    },
    regionGroups: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    this.bodyClick();

    this.$nextTick(() => {
      if (this.groupingCriteria !== this.criteriaSelectedName) {
        this.criteriaSelectedName = this.groupingCriteria;
        this.criteriaSelected = this.groupingCriteria === 'cluster';
      }
      this.getStores();

      if (this.groupingCriteria === 'cluster') {
        if (this.regionGroups?.length > 0) this.clusters = this.regionGroups;
        this.handleClusterSelection();
      } else {
        if (this.regionGroups?.length > 0) this.regions = this.regionGroups;
        this.handleRegionSelection();
      }
    });
  },

  methods: {
    ...mapActions('campaignsManagement', ['updateSuggestionStores', 'actionCampaignSetGroupingCriteria']),

    getStores() {
      const stores = this.filters?.comparisonStores ? this.filters?.comparisonStores.toString() : '';
      this.updateSuggestionStores(stores);
    },

    isSelected(key, obj) {
      if (!obj || !key) return false;
      return !!obj.includes(key);
    },

    toggleSelection(key, obj) {
      if (this.isSelected(key, obj)) this.unselectOption(key, obj);
      else this.selectOption(key, obj);
    },

    selectOption(key, obj) {
      if (!this.isSelected(key, obj)) obj.push(key);
    },

    unselectOption(key, obj) {
      if (!this.isSelected(key, obj)) return;

      const index = obj.indexOf(key);
      obj.splice(index, 1);
    },

    selectCluster(cluster) {
      this.toggleClusterStores(cluster, true);
      this.selectOption(cluster, this.clusters);
    },

    unselectCluster(cluster) {
      this.toggleClusterStores(cluster, false);
      this.unselectOption(cluster, this.clusters);
    },

    // TODO refatorar
    toggleStore(region, state, store) {
      if (!this.isSelected(store, this.filters.comparisonStores)) {
        // seleciona a loja e seus respectivos estados e regiao
        this.selectOption(store, this.filters.comparisonStores);
        if (this.criteriaSelectedName !== 'cluster') {
          this.selectOption(state, this.states);
          this.selectOption(region, this.regions);
        } else {
          this.selectOption(region, this.clusters);
        }
      } else {
        this.unselectOption(store, this.filters.comparisonStores);
        if (this.criteriaSelectedName !== 'cluster') {
          // valida se todas as lojas estao desmarcadas e entao desmarca estado
          if (!this.storesByState(region, state)) {
            this.unselectOption(state, this.states);
          }
          // valida se todos os estados estao desmarcados e entao desmarca a regiao
          if (!this.statesByRegion(region)) {
            this.unselectOption(region, this.regions);
          }
        } else if (!this.storesByCluster(region)) {
          this.unselectOption(region, this.clusters);
        }
      }
    },

    toggleDropdown(current) {
      this[current] = !this[current];
      if (current === 'storesOpened') this.storesRegion = false;
      else if (current === 'metricsOpened') this.criteriaOpened = false;
      else this.storesOpened = false;
    },

    toggleCriteria(criteriaName) {
      if (criteriaName !== this.criteriaSelectedName) {
        this.criteriaSelectedName = criteriaName;
        this.criteriaSelected = !this.criteriaSelected;

        this.resetAllPreviousSelectedStores();

        bus.$emit('campaign-modal-criteria-info', { index: this.groupIndex, criteria: criteriaName });
      }
    },

    // TODO refatorar
    toggleRegion(region) {
      this.toggleSelection(region, this.regions);
      const states = Object.keys(this.comparisonStoresList[region]);
      states.forEach(state => {
        if (this.isSelected(region, this.regions)) this.selectState(region, state);
        else this.unselectState(region, state);
      });

      this.handleRegionSelection();
    },

    toggleCluster(cluster) {
      if (this.isSelected(cluster, this.clusters)) {
        this.unselectCluster(cluster);
      } else {
        this.selectCluster(cluster);
      }

      this.handleClusterSelection();
    },

    // TODO refatorar
    toggleClusterStores(cluster, select) {
      this.orderbyClusterList[cluster].forEach(store => {
        if (select) this.selectOption(store.id, this.filters.comparisonStores);
        else this.unselectOption(store.id, this.filters.comparisonStores);
      });
    },

    toggleState(region, state) {
      if (this.isSelected(state, this.states)) {
        this.unselectState(region, state);
        // valida se todos os estados estao desmarcados e entao desmarca a regiao
        if (!this.statesByRegion(region)) this.unselectOption(region, this.regions);
      } else {
        this.selectState(region, state);
        this.selectOption(region, this.regions);
      }
    },

    selectState(region, state) {
      this.toggleStateStores(region, state, true);
      this.selectOption(state, this.states);
    },

    unselectState(region, state) {
      this.toggleStateStores(region, state, false);
      this.unselectOption(state, this.states);
    },

    toggleStateStores(region, state, select) {
      this.comparisonStoresList[region][state].forEach(store => {
        if (select) this.selectOption(store.id, this.filters.comparisonStores);
        else this.unselectOption(store.id, this.filters.comparisonStores);
      });
    },

    statesByRegion(region) {
      const states = Object.keys(this.comparisonStoresList[region]);
      return states.some(v => this.states.includes(v));
    },

    storesByState(region, state) {
      return this.comparisonStoresList[region][state].some(v => this.filters.comparisonStores.includes(v.id));
    },

    storesByCluster(region) {
      return this.orderbyClusterList[region].some(v => this.filters.comparisonStores.includes(v.id));
    },

    getComparisonStoresList() {
      const comparisonStoresList = {};

      this.stores.forEach(store => {
        if (store.region) {
          if (typeof comparisonStoresList[store.region] !== 'object') comparisonStoresList[store.region] = {};
          if (!Array.isArray(comparisonStoresList[store.region][store.state]))
            comparisonStoresList[store.region][store.state] = [];
          comparisonStoresList[store.region][store.state].push({
            id: store.id,
            name: store.store,
          });
        }
      });
      this.ready = true;
      return comparisonStoresList;
    },

    groupByCluster() {
      if (!this.stores || !this.filters) return [];
      const groupBy = key => array =>
        array
          .filter(store => store.id !== this.filters.store)
          .reduce((objectsByKeyValue, obj) => {
            let value = this.$t('stores.select-others');
            if (obj[key]) {
              value = obj[key];
            }
            const clipboard = objectsByKeyValue;
            clipboard[value] = (clipboard[value] || []).concat(obj);
            return clipboard;
          }, {});
      const groupByCluster = groupBy('cluster');
      return groupByCluster(this.stores);
    },

    filterResults(obj) {
      if (obj.name) return obj.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      else if (obj.store) return obj.store.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      return obj.filter(store => store.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1).length;
    },

    checkboxDisabled(store) {
      const mapStoresIds = this.suggestionDetail.storesId?.map(store => store.id);
      if (mapStoresIds) return mapStoresIds.includes(store);
    },

    handleClusterSelection() {
      if (!this.clusters?.length) {
        this.clustersSelected = this.$t('select');
        return;
      }
      const sortedClusters = [...this.clusters].sort();
      const listString = sortedClusters.toString().split(',').join(', ');
      this.clustersSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;

      bus.$emit('campaign-modal-update-group-selection', { index: this.groupIndex, groups: this.clusters });
    },

    handleRegionSelection() {
      if (!this.regions.length) {
        this.regionsSelected = this.$t('select');
        return;
      }
      const listString = this.regions.toString().split(',').join(', ');

      this.regionsSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;

      bus.$emit('campaign-modal-update-group-selection', { index: this.groupIndex, groups: this.regions });
    },

    resetAllPreviousSelectedStores() {
      Object.values(this.orderbyClusterList)
        .flat()
        .map(store => this.unselectOption(store.id, this.filters.comparisonStores));
    },

    bodyClick() {
      const body = document.querySelector('body');
      body.addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.criteriaOpened = false;
            this.storesOpened = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-details-header {
  width: 100%;
  // margin: 10px 0 30px;

  .sdh__select-fake-wrapper {
    // margin-left: 54px;
    display: flex;
    justify-content: space-between;
    height: 50px;

    .note {
      font-size: 12px;
      max-width: 255px;

      strong {
        color: $color-red;
        margin-right: 5px;
      }

      span {
        font-weight: bold;
      }
    }
  }

  .form-group {
    position: relative;
  }
  .dropdown-menu {
    display: block;
    .searchStore {
      position: relative;
      max-width: 236px;
      margin: 5px auto 18px;
      input {
        width: 100%;
      }
      .icon-search {
        position: absolute;
        right: 5px;
        top: 2px;
        color: #bbbbbb;
      }
    }
  }

  .check-list {
    position: absolute;
    top: 58px;
    left: 0;
    width: 226px;
    padding: 18px 15px;
    background-color: white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    z-index: 98;
    border: 0;
    margin: 0;
    &.hide {
      display: none;
    }
    .select {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }

      label {
        display: grid;
        align-items: center;
        user-select: none;
        cursor: pointer;
        margin: 0;
        grid-template-columns: 16px 1fr;
        &:hover {
          .check-box:not(.checked) {
            border-color: $gray-600;
          }
        }
        .text {
          margin-left: 10px;
          color: $gray-700;
          .name {
            display: block;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
          }
        }
      }
    }

    > .select {
      &:hover {
        .check-list-child {
          visibility: visible;
        }
      }
      label {
        grid-template-columns: 16px 1fr 25px;
      }
      .icon-right-14px {
        font-size: 10px;
      }
    }
    .check-list-child {
      display: block;
      visibility: hidden;
      width: 285px;
      max-height: 315px;
      padding: 10px;
      position: absolute;
      left: 200px;
      top: 0;
      background-color: #fff;
      overflow: hidden;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
      transition: all 0.25s cubic-bezier(0.1, 0.65, 0.5, 1);
      transition-delay: 0.3s;
      .list {
        padding: 5px;
        max-height: 295px;
        overflow-y: auto;
        .state .name {
          font-weight: 600;
        }
      }
      .check-list-subchild {
        padding: 12px 14px 12px 25px;
      }
    }
  }

  .check-list,
  .list {
    &::-webkit-scrollbar-track {
      background-color: $gray-300;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      background-color: $gray-300;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbb;
      border-radius: 10px;
    }
  }
  .select-fake {
    width: 170px;
    overflow: hidden;
    padding-right: 25px;
    // margin-bottom: 28px;
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
  }
}
</style>
