import { SalesResponse, RemoteSalesResponse } from '../models';
import { getFilterSales } from '../services';

export const fetchFilterSale = (): Promise<SalesResponse> =>
  getFilterSales().then(response => formatResponse(response?.data));

const formatResponse = (response: RemoteSalesResponse): SalesResponse => ({
  data: Object.entries(response?.data ?? {}).map(([key, name]) => ({
    key,
    name,
  })),
  errors: response?.errors,
  success: response?.success,
});
