<template>
  <base-card
    id="custom-fields-header"
    class="custom-fields-header"
    :ready="true"
    :title="$t('settings.custom-fields.title')"
    :style="{
      backgroundImage: `url(${require('@/assets/img/figures/genericOtoBgBig.png')})`,
    }"
  >
    <p class="custom-fields-header__text">{{ $t('settings.custom-fields.text') }}</p>
    <ul class="nav-tabs custom-fields-header__nav-tabs">
      <li class="custom-fields-header__item">
        <a
          class="custom-fields-header__link"
          :class="{ active: isActive('EXPORT') }"
          @click.prevent="setActive('EXPORT')"
          >{{ $t('settings.custom-fields.contacts') }}</a
        >
      </li>
      <li class="custom-fields-header__item">
        <a
          class="custom-fields-header__link"
          :class="{ active: isActive('STORE_DETAIL') }"
          @click.prevent="setActive('STORE_DETAIL')"
          >{{ $t('settings.custom-fields.stores') }}</a
        >
      </li>
      <li class="custom-fields-header__item">
        <a
          class="custom-fields-header__link"
          :class="{ active: isActive('ORDERS') }"
          @click.prevent="setActive('ORDERS')"
          >{{ $t('settings.custom-fields.orders') }}</a
        >
      </li>
    </ul>
  </base-card>
</template>
<script>
// libs
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CustomFieldsHeader',
  computed: {
    ...mapGetters('customFields', ['getActiveTable']),
  },
  methods: {
    ...mapActions('customFields', ['setActiveTable']),
    isActive(menuItem) {
      return this.getActiveTable === menuItem;
    },
    setActive(menuItem) {
      this.setActiveTable(menuItem);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-fields-header {
  background: no-repeat right -70px center, $color-white;
  padding: 24px 47px 0;
  position: relative;

  &__text {
    margin-bottom: 16px;
    margin-top: -8px;
  }

  &__item {
    cursor: pointer;
    width: 120px;
    text-align: center;
  }

  &__link {
    color: $gray-700;
    border: none;
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 8px 0;

    &.active {
      color: $oto-omni;
      border-bottom: 3px solid $oto-omni;
    }
  }

  &__nav-tabs {
    display: flex;
    align-items: baseline;
    border: none;
    width: 50%;
    padding: 0;
    list-style-type: none;
    margin-bottom: 0px;
    margin-left: -34px;
    border-bottom: none;
  }
}
</style>
