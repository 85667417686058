<template>
  <div class="data-row d-flex align-items-center" :class="dataItem.expanded ? 'exp-parent' : ''">
    <cell :data-item="dataItem" :index="1" />
    <cell :data-item="dataItem" :index="2" />
    <cell :data-item="dataItem" :index="3" />
    <cell :data-item="dataItem" :index="4" />
  </div>
</template>

<script>
import Cell from '@/components/personasCompare/_atoms/Cell';

export default {
  name: 'Row',
  components: {
    Cell,
  },
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.data-row {
  height: 41px;
  background-color: $color-white;
  border-bottom: 1px solid $gray-400;
  &.exp-parent {
    cursor: pointer;
  }
  > .data-cell {
    height: 100%;
    &:not(:last-child) {
      border-right: 1px solid $gray-400;
    }
  }
}
.data-row:hover {
  background-color: $gray-200;
}
</style>
