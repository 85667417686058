<template>
  <div id="websitebehaviorbehavior" class="card fake blocked" style="min-height: 350px">
    <div class="card-body">
      <h5 class="card-title customTitle">{{ $t('behavior-title') }}</h5>
      <div id="chartBehavior"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Highcharts from 'highcharts';
import moment from 'moment';
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import { dateDayMonthYear } from '@/utilities/constants';
import { formatNumber } from '@/utilities/formatters';

function CreateChartSessions(categories, metrics) {
  Highcharts.chart('chartBehavior', {
    chart: {
      height: 300,
      style: {
        fontFamily: 'Montserrat',
      },
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: [
      {
        gridLineWidth: 0,
        categories,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        gridLineWidth: 0,
        labels: {
          formatter: function format() {
            return `${formatNumber(this.value, '0,0a')}`;
          },

          style: {
            color: '#888',
          },
        },
        title: {
          text: '',
          style: {
            color: '#888',
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      valueDecimals: 0,
      borderWidth: 0,
      backgroundColor: '#fff',
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemMarginTop: -30,
      itemMarginBottom: 25,
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      itemStyle: {
        color: 'grey',
        fontWeight: 400,
        paddingbottom: 20,
      },
    },
    series: Object.keys(metrics).map(name => ({
      name,
      type: 'spline',
      data: metrics[name],
    })),
  });
}
export default {
  name: 'WebSiteBehaviorBehavior',
  data() {
    return {
      labels: [],
      ready: false,
      data: [],
      dataChart1: {
        name: '',
        type: '',
        data: [],
        tooltip: {
          valueSuffix: '',
        },
      },
      dataChart2: {
        name: '',
        type: '',
        data: [],
        tooltip: {
          valueSuffix: '',
        },
      },
      dataChart3: {
        name: '',
        type: '',
        data: [],
        tooltip: {
          valueSuffix: '',
        },
      },
    };
  },
  created() {
    // segmento default
    return this.fetch_data();
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetch_data() {
      getMetrics({
        metrics: 'arr_sessions_funnel',
        expand: 1,
      }).then(data => {
        const categories = data.data.data.labels.map(date => moment(new Date(`${date}`) - 1).format(dateDayMonthYear));
        const { metrics } = data.data.data;
        CreateChartSessions(categories, metrics);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customTitle {
  @media (min-width: 1200px) {
    position: absolute;
    z-index: 1;
  }
}
</style>
