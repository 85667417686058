import moment from 'moment';
import { formatDecimal } from '@/utilities/formatters';
import LightboxTemplateChart from '@/components/lightbox/LightboxTemplateChart';
import store from '@/store';

const mixin = {
  data() {
    return {
      settings: this.$parent.settings,
      segmentName: undefined,
    };
  },
  methods: {
    $_segmentNameSelect() {
      let nameSegment = '';
      if (this.$store.getters.selectedSegment.segmentGroupId === 1) {
        nameSegment = 'Todos';
      } else {
        nameSegment = this.segmentName;
      }
      return nameSegment;
    },
    $_handleTableRow(segmentId) {
      if (this.segmentSelected === segmentId) return;

      this.segmentGroupSelected = segmentId === 1 ? 1 : 2;
      this.segmentSelected = segmentId;
      this.fetchData();
    },
    format({ labels, formatTo, formatFrom = 'YYYY-M' }) {
      let formatedLabels;

      if (formatTo === 'MMM/YY') {
        formatedLabels = labels.map(item => moment(item, formatFrom).format(formatTo).toUpperCase());
      }

      if (formatTo === 'tooltip') {
        return function formater() {
          let s = `<b>${this.x}</b>`;

          this.points.forEach(item => {
            let value = item.y;

            if (value < 0) value *= -1;

            s += `<br/><span style="color:${item.color}">\u25CF</span> ${item.series.name}: <strong>${formatDecimal(
              value,
              1,
            )}</strong>`;
          });
          return s;
        };
      }

      return formatedLabels || labels;
    },
    $_updateSegmentName() {
      const { segments } = store.getters;
      const { selectedSegment } = this.segmentObj;
      if (segments && selectedSegment.segment_id === 1) {
        this.segmentName = segments[1].segments.find(loopSegment => loopSegment.segment_id === 4).segment_name;
      } else {
        this.segmentName = selectedSegment?.segment_name;
      }
    },
    $_segmentColor(name) {
      let color;
      if (this.$store.getters.segmentsColors) {
        this.$store.getters.segmentsColors.forEach(item => {
          if (item[name]) color = item[name];
        });
      }
      return color;
    },
  },
  watch: {
    segmentSelected() {
      this.$_updateSegmentName();
    },
  },
  components: {
    'lightbox-template-chart': LightboxTemplateChart,
  },
  computed: {
    segmentObj() {
      const { segments } = store.getters;

      const clicked = this.segmentSelected;

      const segmentGroupId = clicked === 1 ? 1 : 2;
      const segmentGroup = segments?.find(segment => segment.group_id === segmentGroupId);

      const selectedSegment = segmentGroup?.segments.find(segment => segment.segment_id === clicked);

      return { segmentGroup, selectedSegment };
    },
  },
};

export default mixin;
