<template>
  <lightbox-template
    id="lightbox-add-size"
    :title="$t('360.person.customer-sizes.lightbox.add-size')"
    :small="true"
    :show-segment-group="false"
    :icon="''"
    :show-date="false"
  >
    <div v-if="ready" class="container">
      <div v-if="step === 1" class="clothes-type">
        <span v-html="$t('360.person.customer-sizes.lightbox.clothing-title')"></span>
        <div class="input-group d-flex flex-column pt-4">
          <div v-for="(value, key) in clothingList" :key="key" class="wrap">
            <input :id="value" v-model="clothingSelected" type="radio" :value="value" />
            <label :for="value">
              {{ value }}
            </label>
          </div>
        </div>
        <div class="dialog">
          <button
            class="btn btn-continue confirm"
            @click.prevent="changeStep"
            v-tooltip="{
              theme: 'default',
              triggers: [],
              autoHide: false,
              distance: 8,
              content: this.$t('360.person.customer-sizes.lightbox.error'),
              shown: isShowingTooltip,
            }"
          >
            {{ $t('360.person.customer-sizes.lightbox.next') }}
          </button>
        </div>
      </div>
      <div v-else class="select-size">
        <header class="d-flex align-items-center">
          <button class="go-back icon icon-left-14px mr-3" @click="goBack"></button>
          <div class="selected">
            <span>{{ clothingSelected }}</span>
          </div>
        </header>
        <span>{{ $t('360.person.customer-sizes.lightbox.size-title') }}</span>
        <div class="input-group d-flex flex-column pt-2">
          <div v-for="(value, key) in sizeList" :key="key" class="wrap">
            <input :id="value" v-model="sizeSelected" type="radio" :value="value" />
            <label :for="value" class="text-uppercase">{{ value }}</label>
          </div>
        </div>
        <div class="dialog">
          <button
            class="btn btn-submit confirm"
            @click.prevent="submit"
            v-tooltip="{
              theme: 'default',
              triggers: [],
              autoHide: false,
              distance: 8,
              content: this.$t('360.person.customer-sizes.lightbox.error'),
              shown: isShowingTooltip,
            }"
          >
            {{ $t('360.person.customer-sizes.lightbox.add-size') }}
          </button>
        </div>
      </div>
    </div>
  </lightbox-template>
</template>

<script>
import { closeLightbox } from '@/helpers/lightbox';
import { getCategoriesList, getCategorySizesList, putCategorySizes } from '@/services/oto/customers';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';

export default {
  name: 'LightboxAddSize',
  components: {
    LightboxTemplate,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ready: false,
      step: 1,
      clothingSelected: undefined,
      sizeSelected: undefined,
      sizeList: [],
      clothingList: [],
      isShowingTooltip: false,
    };
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      this.reset();
      this.ready = true;
      this.fetch();
    },
    fetch() {
      const { customerId } = this.settings;

      getCategoriesList(customerId).then(({ data }) => {
        this.clothingList = data.categories;
        const [clothingSelected] = this.clothingList;
        this.clothingSelected = clothingSelected;
      });
    },
    changeStep() {
      if (this.clothingSelected) {
        this.updateSizesList();
        this.isShowingTooltip = false;
        this.step = 2;
      } else this.isShowingTooltip = true;
    },
    goBack() {
      this.step = 1;
    },
    submit() {
      const { customerId } = this.settings;
      if (this.sizeSelected) this.isShowingTooltip = false;
      else return (this.isShowingTooltip = true);

      const [cId, cloSelected, sizeSelected] = [customerId, this.clothingSelected, this.sizeSelected];

      return putCategorySizes(cId, cloSelected, sizeSelected).then(() => {
        closeLightbox();
      });
    },
    createTooltip(el) {
      el.tooltip({
        placement: 'top',
        title: this.$t('360.person.customer-sizes.lightbox.error'),
        trigger: 'manual',
      });
      el.tooltip('show');
    },
    reset() {
      this.step = 1;
      this.size = undefined;
      this.clothing = undefined;
    },
    updateSizesList() {
      getCategorySizesList(this.customerId, this.clothingSelected).then(data => {
        this.sizeList = data.data.sizes;
        const [sizeSelected] = this.sizeList;
        this.sizeSelected = sizeSelected;
      });
    },
  },
};
</script>

<style lang="scss">
#lightbox-add-size {
  label {
    margin-bottom: 10px;
  }
  header {
    padding-bottom: 15px;
    padding-left: 10px;
    margin-bottom: 24px;
    border-bottom: 1px solid $gray-300;
  }
  .go-back {
    background-color: transparent;
    border: none;
    color: $gray-600;
    cursor: pointer;
    padding: 0;
    font-size: 13px;
  }
}
</style>
