import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'user';

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-User-acceptTos
 * @description Accept terms of service
 */
export const acceptTos = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios.get(`${URI}/acceptTos`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-User-routesProfile
 * @description Get routes by profile
 * @param { Number } profileId
 */
export const getRoutesByProfile = profileId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;
  return axios.get(`${URI}/routesProfile`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      token,
      profileId,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-User-routes
 * @description Get user routes
 * @param { Number } userId
 */
export const getUserRoutes = userId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;
  return axios.get(`${URI}/routes`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      token,
      userId,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-User-listProfiles
 * @description Return a list of profiles
 */
export const getListProfiles = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;
  return axios.get(`${URI}/listProfiles`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      token,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-User-welcome
 * @description Get welcome page configuration
 */
export const getWelcomeData = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios.get(`${URI}/welcome`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Welcome-home
 * @description Get welcome page configuration
 */
export const getOtoWelcomeData = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios.get('360/welcome/home', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-User-getUserBrands
 * @description Get the user brand
 */
export const getUserBrands = () => {
  const tokenV3 = store.state.auth.tokenV3;
  const userId = store.state.auth.user.id;

  return axios.get(`${URI}/getUserBrands`, {
    baseURL: endpointVersion('v3'),
    params: {
      userId,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see
 * @description Get all stores from selected brand
 */
export const getBrandStores = params => {
  const tokenV3 = store.state.auth.tokenV3;
  const userId = store.state.auth.user.id;

  return axios.get(`${URI}/getUserBrandStores`, {
    baseURL: endpointVersion('v3'),
    params: {
      userId,
      ...params,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see
 * @description Get all options from selected brand
 */
export const getBrandOptions = params => {
  const tokenV3 = store.state.auth.tokenV3;
  const userId = store.state.auth.user.id;

  return axios.get(`${URI}/getUserBrandOptions`, {
    baseURL: endpointVersion('v3'),
    params: {
      userId,
      ...params,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see -
 * @description get user access level
 */
export const getAccessLevel = () => {
  const tokenV3 = store.state.auth.tokenV3;
  const userId = store.state.auth.user.id;
  const brand = store.getters.selectedBrand;

  return axios.get(`${URI}/getPermissions`, {
    baseURL: endpointVersion('v3'),
    params: {
      userId,
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
