<template>
  <div>
    <div id="store-select">
      <label>{{ $t('nps.store') }}</label>
      <v-select
        v-model="store"
        label="name"
        :clearable="true"
        class="w-100"
        :options="getStores"
        :filter-by="storeFilter"
        :placeholder="$t('nps.select')"
        @search="searchTerm"
        @option:selected="changeStore(store)"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #option="{ name, id }">
          <span v-if="name">{{ name }} - {{ id }}</span>
        </template>
        <template #selected-option="{ name, id }">
          <span v-if="name">{{ name }} - {{ id }}</span>
        </template>
        <template #no-options>
          {{ $t('nps.select-empty') }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
// components
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      store: [],
      isFirstSearch: true,
    };
  },
  computed: {
    ...mapGetters('npsAnalytics', ['getStores']),
  },
  watch: {
    getStores() {
      this.$nextTick(() => {
        // at the first load - set input details (name - storeId)
        if (!this.getStores) return;
        // this.store = this.getStores[0];
      });
    },
    store() {
      if (!this.store) {
        const id = null;
        this.setStoreSelected(id);
        bus.$emit('render-cards');
      }
    },
  },
  mounted() {
    this.setStoresList();
  },
  methods: {
    ...mapActions('npsAnalytics', ['setStoreSelected', 'setStoreByTerm', 'setStoresList']),
    /**
     * @description search by store
     */
    searchTerm(term) {
      // if (!term) return;
      this.triggerDebounce(term);
    },
    /**
     * @description set search term on store
     */
    triggerDebounce: debounce(function (term) {
      this.setStoreByTerm({ term });
    }, 500),
    /**
     * @description change store after and bofore select update
     */
    changeStore(storeId) {
      const id = storeId?.id ? storeId.id : null;
      this.setStoreSelected(id);
      this.$nextTick(() => {
        bus.$emit('render-cards');
      });
    },
    /**
     * @description filter options with two labels
     */
    storeFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name.toLowerCase()?.includes(temp) || option?.storeId?.includes(temp);
    },
  },
};
</script>

<style lang="scss">
#store-select {
  @import '@/assets/scss/vendors/_v-select';

  .v-select {
    .vs__selected-options {
      max-width: 80%;
    }
    .vs__clear {
      margin-right: 0;
    }
    .vs__dropdown-option {
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
</style>
