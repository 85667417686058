import axios from 'axios';
import store from '@/store';

/**
 * Get metrics from a category
 * @param {String} name - Category name
 * @param {String} metrics - A single metric or a list of metrics, separated by comma
 * @param {Object=} optionals - Object with optional params
 * @param {String=} optionals.filter - Set store type filter.
 * @param {Number=} optionals.top - Set the top 'N' rows. Available for revenue_by_medium metric.
 */
export const getCategoryMetrics = (name, metrics, filterId, optionals) => {
  const { id } = store?.getters?.selectedBrand;
  const { startDate, endDate } = store?.getters?.dates;
  try {
    if (!name) {
      throw new Error('name cant`t be empty');
    }
    if (!metrics) {
      throw new Error('metrics cant`t be empty');
    }
  } catch (e) {
    console.error(`getCategoryMetrics: ${e.message}`);
    return false;
  }
  return axios.get('categories/get_metrics', {
    params: {
      brand: id,
      category_name: name,
      m: metrics,
      start_date: startDate,
      end_date: endDate,
      order_filters: filterId,
      ...optionals,
    },
  });
};

/**
 * Get lifecycle values from category
 * @param {String} name - Category name
 */
export const getCategoryLifecycle = (name, filterId = store.getters.selectedFilte) => {
  const { id } = store.getters.selectedBrand;
  // const { startDate, endDate } = store.getters.dates;
  try {
    if (!name) {
      throw new Error('name cant`t be empty');
    }
  } catch (e) {
    console.error(`getCategoryLifecycle: ${e.message}`);
    return false;
  }
  return axios.get('categories/get_category_lifecycle', {
    params: {
      brand: id,
      // start_date: startDate,
      // end_date: endDate,
      category_name: name,
      order_filters: filterId,
    },
  });
};

/**
 * Get category overview
 * @param {String} name - Category name
 */
export const getCategoryOverview = (name, filterId = store.getters.selectedFilter) => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  try {
    if (!name) {
      throw new Error('name cant`t be empty');
    }
  } catch (e) {
    console.error(`getCategoryOverview: ${e.message}`);
    return false;
  }
  return axios.get('categories/get_category_overview', {
    params: {
      brand: id,
      category_name: name.toUpperCase(),
      start_date: startDate,
      end_date: endDate,
      order_filters: filterId,
    },
  });
};

/**
 * Get table metrics from category
 * @param {String} name - Category name
 * @param {String} table - Table or metric item to retrieve data
 * @param {Number=} limit - Max number of results
 * @param {Number=} page - Pagination
 * @param {String=} order_by - which key to sort {key}_desc | keys = revenue, name
 * @param {String=} term- Search a given term in the list
 */
export const getTable = ({ name, table, filterId = store.getters.selectedFilter, ...optionals } = {}) => {
  const { id } = store.getters.selectedBrand;
  const { startDate, endDate } = store.getters.dates;
  try {
    if (!name) {
      throw new Error('name cant`t be empty');
    }
    if (!table) {
      throw new Error('table cant`t be empty');
    }
  } catch (e) {
    console.error(`getTable: ${e.message}`);
    return false;
  }
  return axios.get('categories/get_table', {
    params: {
      brand: id,
      start_date: startDate,
      end_date: endDate,
      category_name: name.toUpperCase(),
      tbl: table,
      order_filters: filterId,
      ...optionals,
    },
  });
};
