<template>
  <section class="customerBase-wrapper">
    <section class="cb__collapsed" @click="getCollapsed(id)">
      <div :class="['cb__collapsed-border', !collapsed ? '--gray' : '--pink']">
        {{ $t('360.analytics-o2o.customer-base') }}
      </div>
      <div class="cb__icon">
        <i v-show="!collapsed" class="icon icon-plus"></i>
        <i v-show="collapsed" class="icon icon-minus-30px"></i>
      </div>
    </section>
    <section v-show="collapsed" class="customerBase">
      <h1 v-if="$route.path.includes('/dashboard/fullview')" class="card-group-title">
        {{ $t('segments-title') }}
      </h1>
      <div class="row">
        <div class="col-12">
          <segments-data-base :make-api-call="makeApiCall" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-5 col-xl-3">
          <retention-card :make-api-call="makeApiCall" />
        </div>
        <div class="col-sm-5 col-xl-5">
          <segments-clients-by-region :make-api-call="makeApiCall" />
        </div>
        <div class="col-sm-6 col-xl-4">
          <age-gender-card :make-api-call="makeApiCall" />
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="row">
            <div class="col-4">
              <segments-profile-card :make-api-call="makeApiCall" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';

// @ is an alias to /src
import AgeGenderCard from '@/components/storeSegments/ageGenderCard/AgeGenderCard';
import RetentionCard from '@/components/storeSegments/retentionCard/RetentionCard';
import SegmentsClientsByRegion from '@/components/segments/clientsByRegion/AllSegmentGroupsClientsByRegion';
import SegmentsDataBase from '@/components/segments/database/AllSegmentGroupsDataBase';
import SegmentsProfileCard from '@/components/segments/profileCard/SegmentsProfileCard';

export default {
  name: 'Allsegmentgroups',
  components: {
    AgeGenderCard,
    RetentionCard,
    SegmentsClientsByRegion,
    SegmentsDataBase,
    SegmentsProfileCard,
  },
  data() {
    return { id: 'customerbase', collapsed: false, makeApiCall: false };
  },
  mounted() {
    bus.$on('keep-last-collapsed-open', id => {
      this.getLastCollapsedComponent = id;
      if (id !== this.id) this.collapsed = false;
    });
  },
  beforeUnmount() {
    bus.$off(['keep-last-collapsed-open']);
  },
  methods: {
    getCollapsed(id) {
      this.collapsed = !this.collapsed;
      if (this.collapsed) {
        bus.$emit('is-active-collapse', id);
        this.makeApiCall = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customerBase-wrapper {
  .cb__collapsed {
    background: $color-white;
    margin-bottom: 10px;
    border-radius: 8px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    .cb__collapsed-border {
      margin-left: 25px;
      font-weight: 500;

      &.--gray {
        color: $gray-700;
      }

      &.--pink {
        color: $pink-500;
      }
    }

    .icon {
      color: $pink-500;
      margin-right: 45px;
      font-weight: bold;
    }
  }

  .cb__collapsed::after {
    content: '';
    background: $pink-500;
    height: 100%;
    width: 5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    left: 0;
  }
  .allsegmentgroups {
    .segments-profile-card,
    .retention-card {
      height: 274px;
    }
    .profile-card {
      min-height: 235px;
    }

    .age-gender-card.closed {
      @media (min-width: $xl-min-width) {
        height: 290px;
      }

      @media (max-width: $tablet-max-width) {
        height: 290px;
      }
    }

    .allsegmentgroups-frequency {
      min-height: 363px;

      &.loadHeight {
        height: 388px;
      }
    }

    .omnichannel-profile-card {
      @media (min-width: $xl-min-width) {
        min-height: 193px;
      }
    }
  }
}
</style>
