<template>
  <base-card-v2 :class="{ loadHeight: loading }" class="sales-report-graphic">
    <div v-show="$_verifyLoaded('done')">
      <h2 class="srm_group-graphic">
        <i class="icon icon-money srh__icon"></i>{{ $t('360.sales-report.graphic.title') }}
      </h2>
      <div id="chart-sales-report" style="min-width: 360px; height: 400px; margin: 0 auto">
        {{ $t('loading-dots') }}
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
// libs
import { mapGetters } from 'vuex';
import Highcharts from 'highcharts';

// services
import { getMetrics } from '@/services/oto/sales-report';

// helpers
import bus from '@/helpers/events/bus';
import { verifyMixin, chartMixin } from '@/helpers/mixins';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { formatDecimal, formatCurrency } from '@/utilities/formatters';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, chartMixin],
  data() {
    return {
      chartData: {},
      chartDateOptions: [],
    };
  },
  computed: {
    ...mapGetters('salesReport', ['getStoreId', 'getSellerId', 'getCampaignId', 'getConversionWindow']),
  },
  mounted() {
    this.fetchData();

    // bus called when dates are changed globally
    bus.$on('render-cards', () => {
      this.fetchData();
    });
    bus.$on('srh-change-store', () => {
      this.fetchData();
    });
    bus.$on('srh-change-seller', () => {
      this.fetchData();
    });
    bus.$on('srh-change-campaign', () => {
      this.fetchData();
    });

    bus.$on('srh-change-segment-general', () => {
      this.fetchData();
    });
    bus.$on('srh-change-conversion-window', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off([
      'render-cards',
      'srh-change-store',
      'srh-change-seller',
      'srh-change-campaign',
      'srh-change-segment-general',
      'srh-change-conversion-window',
    ]);
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getMetrics({
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        lookback: this.getConversionWindow,
        graphic: 1,
        metrics: 'aov,conversion_window,orders,revenue_tracked,unique_customers',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) return;

          this.chartData = data.metrics.data;
          this.chartDateOptions = this.$_resolvePlotDateFormat(this.chartData.labels);
        })
        .then(() => {
          this.mountFixedPlacementColumns();
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })

        .finally(() => {
          this.loading = false;
        });
    },
    mountFixedPlacementColumns() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });

      Highcharts.chart('chart-sales-report', {
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: -30,
          itemMarginBottom: 25,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        credits: {
          enabled: false,
        },
        chart: {
          type: 'column',
          style: {
            fontFamily: 'Montserrat',
          },
        },
        xAxis: {
          categories: this.chartDateOptions,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              formatter: function formatter() {
                return formatCurrency(this.value);
              },
            },
          },
          {
            title: {
              text: null,
            },
            labels: {
              formatter: function formatter() {
                return formatDecimal(this.value);
              },
            },
            lineWidth: 0,
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;

              const chartIndex = x?.series?.index;
              formattedValue = chartIndex === 0 ? formatCurrency(x.y) : formatDecimal(x.y);

              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
          style: {
            color: '#555',
          },
        },
        plotOptions: {
          series: { marker: { enabled: true } },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: this.$i18n.t('360.sales-report.graphic.revenue'),
            color: '#FF3162',
            data: this.chartData.metrics.revenue,
            pointPadding: -0.01,
          },
          {
            name: this.$i18n.t('360.sales-report.graphic.orders'),
            marker: { symbol: 'line' },
            color: '#6C90F4',
            data: this.chartData.metrics.order,
            type: 'spline',
            yAxis: 1,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#chart-sales-report {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #555;
}

.srm_group-graphic {
  display: flex;
  color: $gray-800;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
}

.srh__icon {
  font-size: 30px;
  color: $gray-500;
  line-height: inherit;
}

.sales-report-graphic {
  &.loadHeight {
    height: 490px;
  }
}
</style>
