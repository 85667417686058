import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const URI = 'cashback';

export const getStores = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getStores`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};

export const getSellers = ({ storeId = null, term = null } = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getSellers`,
    params: {
      brand: brand.id,
      storeId,
      term,
    },
  });
};

export const getMetrics = ({ metrics, type = null, storeId = '', sellerId = '' }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/cashback/getMetrics`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      metrics,
      storeId,
      sellerId,
      type,
    },
  });
};

export const getSegmentMetrics = ({ metrics, type = null, storeId = '', sellerId = '' }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/cashback/getSegmentMetrics`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      metrics,
      storeId,
      sellerId,
      type,
    },
  });
};

export const getTablePresets = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/cashback/getTablePresets`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};

// cashback table
export const getTable = ({ preset, fileType, responseType, storeId, sellerId, page, limit, orderBy }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios.get('/cashback/getTable', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      preset,
      startDate,
      endDate,
      export: fileType,
      storeId,
      sellerId,
      page,
      limit,
      orderBy,
    },
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
