<template>
  <div>
    <el-dialog v-model="isAudiencesExportationModalVisible" id="audiences-exportation-modal" append-to-body>
      <div class="global">
        <div class="title">
          <h2>{{ $t('audiences.exportation.title') }}</h2>
          <p class="mb-0">{{ $t('audiences.exportation.select-below') }}</p>
          <p v-if="audience" class="m-0">
            {{ $t('audiences.exportation.audience-exporting') }}
            <strong>{{ audience.name }}</strong>
          </p>
        </div>
      </div>
      <div class="fast-selection">
        <div class="title">
          <h3>{{ $t('audiences.exportation.fast-selections') }}</h3>
          <p>{{ $t('audiences.exportation.click-on-buttons') }}</p>
        </div>
        <div class="buttons">
          <button
            v-for="group in groups"
            :key="group.key"
            class="btn"
            @click.prevent="toggleGroup(group.key)"
            :data-cy="`btn-export-audience-group-${group.key}`"
          >
            {{ group.name }}
          </button>
          <button class="btn" @click.prevent="toggleAll" data-cy="btn-export-audience-group-all">
            {{ $t('all') }}
          </button>
        </div>
      </div>

      <div class="fields">
        <div class="title">
          <h3>
            {{ $tc('field', 0) }}
            <button class="btn-fields" @click.prevent="clearSelections" data-cy="btn-export-clear-selections-audience">
              {{ $t('audiences.exportation.clear-selections') }}
            </button>
          </h3>
        </div>

        <div class="selects">
          <div v-for="column in columns" :key="column.key" class="select">
            <label
              :title="column.name"
              @click.prevent="$_toggleSelection(column.key)"
              data-cy="checkbox-export-audience"
            >
              <check-box :checked="$_isSelected(column.key)" :data-cy="`checkbox-export-audience-${column.key}`" />
              <div class="text">
                <span class="name">{{ column.name }}</span>
                <span class="key">{{ column.key }}</span>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="confirm">
        <button class="btn btn-secondary mr-3" @click="openPreview">
          {{ $t('audiences.exportation.btn-preview') }}
        </button>
        <button class="btn" @click="openProgressModal" data-cy="btn-export-audience">
          {{ $t('export') }}
        </button>
      </div>
    </el-dialog>

    <modal-process-progress
      :is-close-modal="isCloseProgressModal"
      :progress-bar="progress"
      :id="'modal-process-progress-exportation'"
      :batch-id="batchId"
    />

    <el-dialog v-model="isModalSuccessVisible" :id="`${id}-success`" append-to-body>
      <div class="container">
        <i class="icon icon-checked-60px" aria-hidden="true"></i>
        <p v-html="$t('audiences.exportation.success')"></p>
        <div class="dialog">
          <div class="confirm btn" @click="closeSuccess" data-cy="btn-audience-modal-export-success">
            {{ $t('continue') }}
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="isModalErrorVisible" :id="`${id}-error`" append-to-body>
      <div class="general-error d-flex flex-column align-items-center">
        <i class="icon icon-sad-face color-red"></i>
        <div class="title">{{ $t('audiences.exportation.error.title') }}</div>

        <span class="align-items-center">
          <span class="subtitle" v-html="$t('audiences.exportation.error.subtitle')"></span>
          <a class="subtitle-link" :href="mailToOtoSupport">{{ link }}</a>
        </span>

        <button class="btn btn-back" @click.prevent="goBack()">
          <i class="icon icon-back-arrow"></i> {{ $t('back') }}
        </button>
      </div>
    </el-dialog>

    <exportation-preview :audience="audience" :columns="selected" />
  </div>
</template>

<script>
// helpers
import bus from '@/helpers/events/bus';

// libs
import { useToast } from 'vue-toastification';

// utilities
import { otoSupportEmail } from '@/utilities/constants';

// services
import { getExportationColumns, exportAudience, getExportJobDetails } from '@/services/audiences';

// components
import CheckBox from '@/components/_atoms/CheckBox';
import ExportationPreview from '@/components/audiences/exportation/AudiencesExportationPreview';
import ModalProcessProgress from '@/components/audiences/processProgress/ModalProcessProgress';

export default {
  components: {
    CheckBox,
    ExportationPreview,
    ModalProcessProgress,
  },
  props: {
    id: {
      type: String,
      default: 'audiences-exportation',
    },
    audience: {
      type: Object,
      default: () => {},
    },
    isCreation: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      groups: [],
      columns: [],
      selected: [],
      isCloseProgressModal: false,
      progress: 0,
      batchId: '',
      link: otoSupportEmail,
      mailToOtoSupport: `mailto:${otoSupportEmail}`,
      isModalSuccessVisible: false,
      isModalErrorVisible: false,
      isAudiencesExportationModalVisible: false,
    };
  },
  mounted() {
    bus.$on('open-exportation', () => {
      this.columns = {};
      this.fetch_data();
      this.isAudiencesExportationModalVisible = true;
    });
  },
  beforeMount() {
    bus.$off(['open-exportation']);
  },
  methods: {
    fetch_data() {
      getExportationColumns().then(data => {
        this.groups = data?.data?.groups;

        const { columns } = data.data;
        this.columns = Object.keys(columns).map(key => columns[key]);
      });
    },

    $_isSelected(key) {
      return this.selected.includes(key);
    },

    $_toggleSelection(key) {
      if (this.$_isSelected(key)) this.unselectColumn(key);
      else this.selectColumn(key);
    },

    clearSelections() {
      this.selected = [];
    },

    selectAllColumns() {
      this.columns.forEach(column => this.selectColumn(column.key));
    },

    isAllSelected(columns) {
      let count = 0;
      columns.forEach(column => {
        if (this.$_isSelected(column)) count += 1;
      });
      return count === columns.length;
    },

    toggleAll() {
      const columns = this.columns.map(column => column.key);
      const isAllSelected = this.isAllSelected(columns);

      if (isAllSelected) this.clearSelections();
      else this.selectAllColumns();
    },

    toggleGroup(key) {
      const group = this.groups.find(item => item.key === key);
      const isAllSelected = this.isAllSelected(group.columns);

      group.columns.forEach(column => {
        if (isAllSelected) this.unselectColumn(column);
        else this.selectColumn(column);
      });
    },

    selectColumn(column) {
      if (!this.$_isSelected(column)) this.selected.push(column);
    },

    unselectColumn(column) {
      if (!this.$_isSelected(column)) return;

      const index = this.selected.indexOf(column);
      this.selected.splice(index, 1);
    },

    closeMe() {
      this.isAudiencesExportationModalVisible = false;
    },

    openPreview() {
      this.closeMe();
      setTimeout(() => bus.$emit('open-exportation-preview'), 300);
    },

    openProgressModal() {
      this.progress = 0;
      this.isCloseProgressModal = false;

      this.fetchInitialExportation();
      this.closeMe();

      setTimeout(() => {
        bus.$emit('open-modal-process-progress-exportation');
      }, 300);
    },

    fetchInitialExportation() {
      exportAudience(this.audience, this.selected)
        .then(({ data }) => {
          this.batchId = data?.data?.batchId;
        })
        .catch(() => {
          this.isCloseProgressModal = true;
          setTimeout(() => {
            this.isModalErrorVisible = true;
          }, 300);
        })
        .finally(() => {
          this.fetchExportation();
        });
    },

    fetchExportation() {
      if (!this.batchId) return;

      getExportJobDetails(this.batchId)
        .then(({ data }) => {
          const errorCodes = ['job-cancelled-with-errors', 'clickhouse-select-fail'];
          const result = data?.data?.result;
          const cancelledByUser = result?.code === 'job-cancelled-by-user';
          const cancelledWithErrors = errorCodes.includes(result?.code);
          const noResults = result?.code === 'no-results-found';
          this.progress = result?.progress;

          const urlFile = data?.data?.result?.url;

          if (!result || cancelledByUser) return this.cancelExportationFetch();
          else if (cancelledWithErrors) {
            this.isModalErrorVisible = true;
            return this.cancelExportationFetch();
          } else if (noResults) {
            this.toast.warning(this.$t('audiences.no-results'));
            return this.cancelExportationFetch();
          } else if (!urlFile) setTimeout(() => this.fetchExportation(this.batchId), 3000);
          else {
            window.location.replace(urlFile);
            setTimeout(() => this.exportSuccess(), 1000);
            this.batchId = '';
          }
        })
        .catch(() => {
          this.isCloseProgressModal = true;
          setTimeout(() => {
            this.isModalErrorVisible = true;
          }, 300);
        });
    },

    cancelExportationFetch() {
      this.isCloseProgressModal = true;
      this.progress = 0;
    },

    goBack() {
      this.isModalErrorVisible = false;
    },

    exportSuccess() {
      this.closeMe();
      this.isCloseProgressModal = true;
      this.progress = 0;
      setTimeout(() => {
        this.isModalSuccessVisible = true;
      }, 300);
    },

    closeSuccess() {
      this.isModalSuccessVisible = false;
    },
  },
};
</script>

<style lang="scss">
#audiences-exportation-modal {
  width: 820px;
  top: 0;
  position: absolute;
  margin: 1.75rem auto;

  .el-dialog__body {
    padding: 25px 50px;
    padding-top: unset;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: $gray-800;
    cursor: default;
    margin: 0;
    display: block;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: $gray-800;
    cursor: default;
    margin: 0;
    display: block;
  }

  p {
    font-size: 12px;
    color: $gray-700;
    text-align: left;
    margin: 14px 0;
  }

  .fast-selection {
    margin-top: 25px;

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0 -10px 0 -10px;

      .btn {
        margin: 5px;
      }
    }
  }

  .fields {
    margin-top: 30px;

    .title {
      .btn-fields {
        font-size: 12px;
        font-weight: 600;
        background-color: transparent;
        color: $oto-omni;
        border: none;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .selects {
      margin-top: 25px;
      margin: 25px -16px 0 -16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .select {
        margin: 10px 16px;

        &:hover {
          .check-box:not(.checked) {
            border-color: $gray-600;
          }
        }

        label {
          display: grid;
          align-items: center;
          user-select: none;
          cursor: pointer;
          margin: 0;
          grid-template-columns: 16px 1fr;

          .text {
            margin-left: 10px;
            color: $gray-700;

            .name {
              display: block;
              font-size: 12px;
              font-weight: 600;
              line-height: 15px;
            }

            .key {
              display: block;
              font-size: 10px;
              font-weight: 300;
              line-height: 15px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .confirm {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    .btn {
      min-width: 170px;
    }
  }
}

#audiences-exportation-success {
  max-width: 500px;

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .dialog {
    display: flex;
    justify-content: center;
    margin: 28px 0 20px 0;

    .confirm {
      width: 170px;
    }
  }

  .icon {
    display: block;
    text-align: center;
    font-size: 76px;
    margin-bottom: 34px;
    color: $oto-ecommerce;
  }
}

#audiences-exportation-error {
  max-width: 500px;

  .general-error {
    .title {
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      color: $gray-800;
      margin-bottom: 10px;
    }
    span {
      text-align: center;
      .subtitle {
        font-size: 12px;
        text-align: center;
      }
      .subtitle-link {
        font-size: 12px;
        color: $oto-omni;
        text-decoration: none;
        background-color: transparent;
      }
    }

    .icon-error-60px,
    .icon-sad-face {
      font-size: 60px;
      margin-bottom: 20px;
    }

    .btn-back {
      margin-top: 35px;
      margin-bottom: 20px;
      width: 130px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
