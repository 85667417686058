<template>
  <div id="login" class="login-bg">
    <div class="container login-container">
      <div class="col-12 login-wrapper p-0">
        <div class="col-12 col-lg-8 p-0 login-info">
          <div class="title">
            <span v-html="$t('welcome-to')"></span>
            <div>
              <img src="@/assets/img/oto/logo/default/oto_logo_default_large.png" alt="oto logo" />
            </div>
          </div>
          <p class="description" v-html="$t('login-text-oto')"></p>
        </div>
        <div class="col-12 col-lg-4 login-cards">
          <login-card />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginCard from '@/components/login/LoginCard';
// libs
import { inject } from 'vue';

export default {
  name: 'LoginOto',
  components: {
    LoginCard,
  },
  setup() {
    const languages = inject('languages');

    return {
      languages,
    };
  },
  mounted() {
    const browserLang = navigator.language || navigator.userLanguage;
    if (this.languages.indexOf(browserLang) > -1) {
      this.$i18n.locale = browserLang;
    } else {
      this.$i18n.locale = 'en';
    }
  },
};
</script>

<style lang="scss" scoped>
.login-bg {
  background-color: $blue-800;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../assets/img/login_oto.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
}

.login-container {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-wrapper {
  display: flex;
  height: 100%;
  flex-flow: wrap;
}

.login-info {
  color: $color-white;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  width: 382px;
  justify-content: center;

  .title {
    display: flex;
    flex-direction: column;
    margin-left: -3px;
    span {
      font-size: 28px;

      &.context {
        font-size: 46px;
        font-weight: 500;
        line-height: 54px;
      }
    }
  }

  .description {
    color: $color-white;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    padding: 12px 0 0;
    width: 393px;

    b {
      font-weight: bold;
    }
  }
}

.login-cards {
  z-index: 1;
}

@media (max-width: $lg-min-width) {
  .login-info {
    text-align: center;
    max-width: 393px;
    margin: 0 auto;

    .title {
      span {
        font-size: 24px;
        &.context {
          font-size: 32px;
        }
      }
    }
    .description {
      width: 100%;
      padding-top: 10px;
    }
  }
  .login-cards {
    display: flex;
    justify-content: center;
    margin-top: 43px;
  }
}
</style>
