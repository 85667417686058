<template>
  <div class="container loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('revenue') }}</h5>
        <span class="segment-label">{{ $_segmentNameSelect() }}</span>
      </header>
      <lightbox-template-chart :settings="chartRevenue" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import moment from 'moment';
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import { formatCurrency, formatNumber } from '@/utilities/formatters';
import { dateFormat } from '@/utilities/constants';
import getMetrics from '@/services/getmetrics';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'RevByMediaChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  data() {
    return {
      chartRevenue: {},
      segmentSelected: '',
    };
  },
  mounted() {
    this.reset();
    this.loadGraphic();
  },
  methods: {
    loadChartRevenue(revenue = [], labels = [], minSelect, maxSelect) {
      this.chartRevenue = {
        chart: {
          height: 300,
          type: 'spline',
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: '',
        },
        subtitle: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 35,
          itemDistance: 15,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
            paddingBottom: 20,
          },
        },
        colors: ['#FD639F', '#FA6F32', '#9EB4E1', '#840303', '#EEDA36', '#1E8E15', '#39B69A', '#FF222C', '#5DFFB8'],
        xAxis: [
          {
            gridLineWidth: 0,
            categories: labels,
            crosshair: true,
            plotBands: [
              {
                from: minSelect,
                to: maxSelect,
                color: 'rgba(68, 170, 213, .2)',
              },
            ],
          },
        ],
        yAxis: [
          {
            title: {
              text: '',
              style: {
                color: '#888',
              },
            },
            labels: {
              formatter: label => {
                if (label.value > 1000 && label.value <= 999999)
                  return formatCurrency(label.value, { shortNumber: true });
                else if (label.value >= 1000000) return formatCurrency(label.value, { shortNumber: true });
                return label.value;
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            let s = `<b>${this.x}</b>`;

            this.points.forEach(points => {
              s += `<br/><span style="color:${points.color}">\u25CF</span> ${points.series.name.replace(/\b\w/g, l =>
                l.toUpperCase(),
              )}: <b>${points.series.name.includes('%') ? formatNumber(points.y, '0%') : formatCurrency(points.y)}</b>`;
            });
            return s;
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
        },
        credits: {
          enabled: false,
        },
        series: Object.keys(revenue).map(name => ({
          name,
          data: revenue[name],
        })),
      };
    },
    loadGraphic() {
      getMetrics({
        metrics: 'revenue_by_medium',
        segments: this.segmentSelected,
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          if (this.$_verifyData(response.data.data.metrics)) return;

          const { data } = response.data;
          const revenue = data.metrics;
          let { labels } = data;

          // trata os labels para converter para texto do Mes/Ano
          labels = this.format({ labels, formatTo: 'MMM/YY' });

          // tratamento para a seleção do gráfico
          const dateStart = moment(this.$store.getters.dates.startDate, dateFormat);
          const dateEnd = moment(this.$store.getters.dates.endDate, dateFormat);
          const numberMonthsSelect = dateEnd.diff(dateStart, 'month');

          this.loadChartRevenue(revenue, labels, labels.length - 1, labels.length - 1 - numberMonthsSelect - 0.5);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$_reqConfig();
      this.chartRevenue = {};
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
