import { formatNumber } from '@/utilities/formatters';
import { formatDecimal, formatPercent } from '@/utilities/formatters';

const mixin = {
  methods: {
    $_formatNumber(date, format) {
      return formatNumber(date, format);
    },
    $_formatDecimal(val, format = 1) {
      return formatDecimal(val, format);
    },
    $_formatPercent(val, format = 1) {
      return formatPercent(val, format);
    },
  },
};

export default mixin;
