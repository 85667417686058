<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body>
    <div v-if="!isModalVoucher">
      <div class="cashback-modal-content container">
        <base-card-header :title="$t('360.customers.cashback.validation.title')" />
        <div>
          <span>{{ $t('360.customers.cashback.validation.text') }}</span>
        </div>
        <span>{{ $t('360.customers.cashback.validation.cashback-value') }}</span>
      </div>
      <div class="cashback-modal-validation container">
        <label>{{ $t('360.customers.cashback.validation.label') }}</label>
        <input
          v-model.lazy="value"
          v-money="money"
          :placeholder="
            $t('_placeholder-monetary', {
              prefix: money.prefix,
            })
          "
        />
      </div>
      <div class="cashback-modal-footer">
        <button class="btn" @click="openVoucherModal">
          {{ $t('360.customers.cashback.button.validation') }}
        </button>
      </div>
    </div>

    <div v-if="isModalVoucher" class="cashback-modal-voucher">
      <div v-if="$_verifyLoaded('done')">
        <div class="cashback-modal-content container">
          <base-card-header :title="$t('360.customers.cashback.voucher.title')" />
          <div class="cmv__content">
            <p>
              {{ $t('360.customers.cashback.voucher.id') }}:
              <span>{{ validateUseData.receipt }}</span>
            </p>
            <p>
              {{ $t('360.customers.cashback.voucher.customer') }}:
              <span>{{ validateUseData.customer.name }}</span>
            </p>
            <p>
              {{ $t('360.customers.cashback.voucher.date') }}:
              <span>{{ formatDate(validateUseData.date) }}</span>
            </p>
            <p>
              {{ $t('360.customers.cashback.voucher.value') }}:
              <span>{{ value }}</span>
            </p>
          </div>
        </div>
        <div class="cashback-modal-footer">
          <button class="btn" @click="closeModal">
            {{ $t('360.customers.cashback.button.continue') }}
          </button>
        </div>
      </div>

      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </div>
  </el-dialog>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import moment from 'moment';
import { VMoney } from 'v-money';
import { useToast } from 'vue-toastification';
// helpers
import { formatNumber, formatCashToNumber } from '@/utilities/formatters';
import { verifyMixin } from '@/helpers/mixins';
import { dateCompleteVariation } from '@/utilities/constants';
// services
import { postValidateUse } from '@/services/oto/cashback';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
// store
import store from '@/store';

export default {
  name: 'CashbackValidationModal',
  components: {
    BaseLoader,
    InfoCard,
  },
  directives: { money: VMoney },
  mixins: [verifyMixin],
  props: {
    isModalVoucher: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: [String, Number],
      default: null,
    },
    balance: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      id: 'cashback-validation-modal',
      value: '',
      validateUseData: {},
      money: {
        decimal: ',',
        thousands: '.',
        prefix: ' ',
        precision: 2,
        masked: false,
      },
      isVisible: false,
    };
  },
  watch: {
    isVisible: function () {
      if (!this.isVisible) this.resetData();
    },
  },
  beforeUnmount() {
    bus.$off([`open-${this.id}`]);
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
      this.decimalPrefix();
      this.thousandsPrefix();
    });

    this.money.prefix = `${store.getters?.currency?.symbol} ` || 'R$ ';
  },
  methods: {
    openVoucherModal() {
      bus.$emit('open-cashback-voucher-modal', true);
      this.validadeUse();
    },

    formatDate(date) {
      return moment(date).format(dateCompleteVariation);
    },

    formatValues(val, format) {
      return formatNumber(val, format);
    },

    validadeUse() {
      this.$_reqConfig();

      postValidateUse({
        customerId: this.customerId,
        value: formatCashToNumber(this.value),
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;
          const errors = data.errors;
          if (formatCashToNumber(this.value) <= 0) {
            this.resetData();
            return (this.data.success = false);
          }
          if (errors.code) {
            this.toast.error(errors.message, { timeout: 5000 });
            bus.$emit('open-cashback-voucher-modal', false);
            this.resetData();
          }
          if (!errors.code) {
            bus.$emit('open-cashback-voucher-modal', true);
          }
          this.validateUseData = data.data;
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    decimalPrefix() {
      if (this.money.prefix.includes('R$')) {
        this.money.decimal = ',';
      } else {
        this.money.decimal = '.';
      }
    },

    thousandsPrefix() {
      if (this.money.prefix.includes('R$')) {
        this.money.thousands = '.';
      } else {
        this.money.thousands = ',';
      }
    },

    resetData() {
      this.value = '';
    },

    /**
     * modal events
     */
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
      bus.$emit('cashback-update-balance');
    },
  },
};
</script>

<style lang="scss" scoped>
#cashback-validation-modal {
  .container {
    padding: 0 30px 30px;
  }
  .cashback-modal-content {
    text-align: left;

    div:nth-child(2) {
      padding-bottom: 20px;
    }

    span {
      font-size: 12px;
      text-align: left;
    }

    .btn-no-bg {
      background: transparent;
      color: $pink-500;
      outline: none;
      padding: unset;
      width: fit-content;

      img {
        margin-right: 5px;
      }
    }
  }

  .cashback-modal-footer {
    border-top: 1px solid #f1f1f1;
    padding: 20px 0 0;
    text-align: center;

    .btn {
      display: inline-block;
      width: 260px;
    }
  }

  .cmv__content {
    padding-top: 25px;
    p {
      font-size: 12px;
      font-weight: bold;
      text-align: left;

      span {
        font-weight: normal;
      }
    }
  }

  .cashback-modal-voucher {
    height: 320px;
  }

  .btn {
    background: $oto-seller;
  }
}
</style>

<style lang="scss">
#cashback-validation-modal {
  width: 330px;
  box-shadow: none;

  .el-dialog__header {
    padding: 28px 30px 0px;
    z-index: 1;
  }

  .el-dialog__body {
    margin-top: -40px;
  }
}
</style>
