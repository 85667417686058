import store from '@/store';
import { actions, roles } from '@/utilities/constants';

const mappings = new Map();

mappings.set(actions.MODIFY, [roles.ADMIN, roles.EDITOR]);
mappings.set(actions.VIEW, [roles.ADMIN, roles.EDITOR, roles.VIEWER]);
mappings.set(actions.DELETE, [roles.ADMIN]);
mappings.set(actions.CREATE, [roles.ADMIN, roles.EDITOR]);

const hasPermission = permission => {
  const action = permission.action;
  const accessLevelStore = store.getters.getAccessLevel;

  // search for feature key on getter store
  if (!accessLevelStore?.find(el => el[permission?.featureId])) return false;

  // search for permission id inside feature object
  const accessLevelUser = accessLevelStore?.find(el => el[permission?.featureId])[permission.featureId][permission?.id];

  if (!accessLevelUser) return false;

  if (mappings.has(action)) return mappings.get(action).includes(accessLevelUser);

  return false;
};

export default hasPermission;
export { actions, roles };
