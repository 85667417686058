<template>
  <lightbox-template :id="id" icon="cart" :title="$tc('product.title', 2)" :show-segment-group="false">
    <div class="container mb-4">
      <header class="d-flex align-items-center mb-4">
        <h5 class="modal-subtitle">
          {{ $t('categories.most-purchased.title') }}
        </h5>
      </header>
    </div>

    <lightbox-skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <tab-item v-if="!loading" :tab-list="tabList" :tab-active="tabTypeActive" />

    <div v-show="$_verifyLoaded('done')" class="col-12">
      <lightbox-table
        :id="id"
        :data="data"
        :data-type="tabTypeActive"
        :is-lightbox-active="isLightboxActive"
        :settings-data="settingsData"
        :col-scheme="['chart:absolute', 'chart:revenue']"
      />
    </div>
    <div v-show="$_verifyLoaded('info')" class="card">
      <info-card ref="message-error" :error="hasError" />
    </div>
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import { getTable } from '@/services/product';
import bus from '@/helpers/events/bus';
import { lightboxesMixin, verifyMixin, miniBarChartMixin } from '@/helpers/mixins';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import LightboxSkeleton from '@/components/lightbox/lightboxes/products/LightboxProductsMostPurchasedSkeleton';
import TabItem from '@/components/lightbox/_molecules/_atoms/TabItem';
import LightboxTable from '@/components/lightbox/_molecules/_atoms/LightboxTable';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'LightboxProductsMostPurchased',
  components: {
    LightboxTemplate,
    LightboxSkeleton,
    TabItem,
    LightboxTable,
    InfoCard,
  },
  mixins: [lightboxesMixin, miniBarChartMixin, verifyMixin],
  props: {
    settingsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: 'lightbox-products-most-purchased',
      isLightboxActive: true,
      productID: this.$route.params.id,
      data: {
        page: 1,
        count: 0,
        values: [],
        valueTotal: 0,
      },
      tabList: [
        {
          name: this.$tc('categories.size', 2),
          type: 'size',
        },
        {
          name: this.$tc('categories.color', 2),
          type: 'color',
        },
      ],
      tabTypeActive: 'size',
      type: 'filled',
    };
  },
  mounted() {
    this.fetchData();

    bus.$on('lightbox-update-tab', item => {
      this.tabTypeActive = item;
      this.data.page = 1; // reset page
      this.$_reqConfig();
      this.updateDataTable();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
    bus.$off('lightbox-update-tab');
  },

  methods: {
    verifyDataPerTab(data) {
      if (this.$_verifyData(data)) return false;
      return true;
    },
    fetchData() {
      this.$_reqConfig();
      this.updateDataTable();
    },
    fetchSizeData() {
      getTable({
        productID: this.productID,
        table: 'products_per_size',
        limit: this.data.limit,
        page: this.data.page,
        expand: 1,
      })
        .then(({ data }) => {
          if (!this.verifyDataPerTab(data.data)) return;
          this.data.values = data.data;
          this.data.count = data.total_count;
          this.data.valueTotal = data.value_total;
        })
        .catch(() => {
          this.data.count = 0;
          this.data.values = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchColorData() {
      getTable({
        productID: this.productID,
        table: 'products_per_color',
        limit: this.data.limit,
        page: this.data.page,
        expand: 1,
      })
        .then(({ data }) => {
          if (!this.verifyDataPerTab(data.data)) return;
          this.data.values = data.data;
          this.data.count = data.total_count;
          this.data.valueTotal = data.value_total;
        })
        .catch(() => {
          this.data.count = 0;
          this.data.values = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateDataTable() {
      switch (this.tabTypeActive) {
        case 'color':
          this.fetchColorData();
          break;
        case 'size':
          this.fetchSizeData();
          break;
        default:
          break;
      }
    },
  },
};
</script>
