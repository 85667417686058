<template>
  <div class="action">
    <actions-header :title="$t('header.sidebar.oto-messages')" :has-icon="true">
      <actions-message-header-new />
    </actions-header>
    <empty-state
      v-show="!getMessagesList.length"
      :title="$t('360.actions.empty-state-message.title')"
      :text="$t('360.actions.empty-state-message.text')"
      :button-text="$t('360.actions.empty-state-message.button')"
      :title-list-item="$t('360.actions.empty-state.example')"
      :final-text="$t('360.actions.empty-state.final-message')"
      :exec-function="openModal"
    >
      <template v-slot:image>
        <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" alt="" />
      </template>
      <template v-slot:list-items>
        <div v-for="(items, index) in listItems" :key="index">
          <i class="icon icon-check-filled"></i>
          <p>{{ items.title }}</p>
        </div>
      </template>
    </empty-state>
    <div v-show="getMessagesList.length">
      <actions-header :title="$t('360.actions.filters')" :has-icon="true" icon="icon-filter">
        <actions-message-header-filters />
      </actions-header>
      <actions-table :id="id" :pagination="pagination" :limit-range="limitRange" :refresh-list="getMessagesListData">
        <template v-slot:table>
          <actions-message-table />
        </template>
      </actions-table>
    </div>
    <actions-new-message-modal />
    <actions-dialog-error-modal />
  </div>
</template>

<script>
// libs
import { mapState, mapActions, mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
// components
import ActionsHeader from '@/components/oto/actions/_organisms/ActionsHeader';
import ActionsMessageHeaderNew from '@/components/oto/actions/_molecules/ActionsMessageHeaderNew';
import ActionsMessageHeaderFilters from '@/components/oto/actions/_molecules/ActionsMessageHeaderFilters';
import ActionsTable from '@/components/oto/actions/_organisms/ActionsTable';
import ActionsMessageTable from '@/components/oto/actions/_molecules/ActionsMessageTable';
import ActionsNewMessageModal from '@/components/oto/actions/modals/ActionsNewMessageModal';
import ActionsDialogErrorModal from '@/components/oto/actions/modals/ActionsDialogErrorModal';
import EmptyState from '@/components/_atoms/EmptyState';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'ActionsMessages',
  components: {
    ActionsHeader,
    ActionsMessageHeaderNew,
    ActionsMessageHeaderFilters,
    ActionsTable,
    ActionsMessageTable,
    ActionsNewMessageModal,
    ActionsDialogErrorModal,
    EmptyState,
  },
  data() {
    return {
      listItems: [
        {
          title: this.$t('360.actions.empty-state-message.item-1'),
        },
        {
          title: this.$t('360.actions.empty-state-message.item-2'),
        },
        {
          title: this.$t('360.actions.empty-state-message.item-3'),
        },
      ],
    };
  },
  computed: {
    ...mapState('actionMessages', ['id', 'pagination', 'limitRange']),
    ...mapGetters('actionMessages', ['getMessagesList', 'getIsEmpty', 'getLoading']),
  },
  mounted() {
    useMixpanelConfig('view_oto_messages');
  },
  methods: {
    ...mapActions('actionMessages', ['getMessagesListData']),
    openModal() {
      bus.$emit('open-actions-new-message-modal');
    },
  },
};
</script>
