<template>
  <base-card-v2 class="card push-notifications__header">
    <div class="row push-header">
      <div class="col-7 ph__title-wrapper">
        <icon-base class="ph__icon-phone" :box="'0 0 12 18'" w="12" h="18">
          <icon-phone />
        </icon-base>
        <h2 class="ph__title">{{ $t('360.push.title') }}</h2>
      </div>
      <div class="col-2">
        <button class="btn ph__button" @click.prevent="createCampaign">
          {{ $t('360.push.create-campaign') }}
        </button>
      </div>
    </div>
  </base-card-v2>
</template>

<script>
// libs
import { mapActions } from 'vuex';
// helpers
import bus from '@/helpers/events/bus';
// assets
import IconBase from '@/assets/vue-icons/IconBase';
import IconPhone from '@/assets/vue-icons/icons/Phone';

export default {
  name: 'PushNotificationsHeader',
  components: {
    IconBase,
    IconPhone,
  },
  data() {
    return {
      searchTerm: '',
      loading: false,
    };
  },
  watch: {
    searchTerm(value) {
      this.updateSearchTerm(value);
    },
  },
  methods: {
    ...mapActions('campaignsManagement', ['updateSearchTerm']),
    openPrioritiesModal() {
      bus.$emit('suggestion-priorities-modal');
    },
    createCampaign() {
      this.$router.push({ name: '360-push-create' });
    },
  },
};
</script>

<style lang="scss">
.push-header {
  align-items: center;
  justify-content: space-between;

  .ph__title-wrapper {
    display: flex;
    align-items: center;

    .ph__title {
      margin: 0 0 0 20px;
    }

    .ph__icon-phone {
      height: 20px;
      width: 18px;
      vertical-align: middle;
    }
  }

  .ph__icon-search {
    position: absolute;
    right: 30px;
    top: 7px;
    font-size: 26px;
    color: #bbb;
    cursor: pointer;
  }

  .ph__input {
    width: 240px;
    height: 40px;
    margin-right: 15px;
  }

  .ph__button {
    width: 170px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 15px;
  }

  .ph__icon-config {
    font-size: 22px;
  }

  button,
  input {
    float: right;
  }
}
</style>
