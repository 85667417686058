
// libs
import { defineComponent, PropType, toRefs } from 'vue';
// helpers
import { openLightbox } from '@/helpers/lightbox';
// types
import { StoresType } from '@/features/SalesRanking/types';

export default defineComponent({
  name: 'CustomerRankingLink',
  props: {
    stores: {
      type: Array as PropType<StoresType[]>,
      required: true,
    },
    selectedStore: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const { stores, selectedStore } = toRefs(props);

    function openModal(id: string) {
      const storeSelected = stores?.value.find(
        (store: { id: { toString: () => string } }) => store.id.toString() === selectedStore.value.toString(),
      );
      openLightbox(id, {
        storeSelected,
      });
    }

    return {
      openModal,
    };
  },
});
