<template>
  <el-dialog v-model="isVisible" :id="id" width="680" class="users-create-edit">
    <div class="cointainer">
      <h2 class="title m-0">{{ $t(`settings.users.${mode}.title`) }}</h2>
    </div>

    <div class="container">
      <form @submit.prevent="submit(userData.clientId)">
        <div class="form-container users-create-edit__form">
          <div class="row">
            <div class="col-8 pl-0 pr-3">
              <div class="form-group">
                <label :for="`${id}-name`">{{ $t('settings.users.create.name.label') }}</label>

                <div class="wrap">
                  <input
                    :id="`${id}-name`"
                    v-model="userData.name"
                    type="text"
                    :placeholder="$t('settings.users.create.name.placeholder')"
                    data-cy="user-input-name"
                  />
                </div>
              </div>
            </div>

            <div class="col-4 pl-0 pr-3">
              <div class="form-group">
                <label :for="`${id}-client`">{{ $t('settings.users.create.client.label') }}</label>
                <div class="wrap">
                  <v-select
                    v-model="userData.client"
                    label="name"
                    :clearable="false"
                    :options="clients"
                    :placeholder="$t('settings.users.create.client.placeholder')"
                    @option:selected="fetchStores()"
                    data-cy="user-input-client"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes" class="arrow-down"></span>
                    </template>
                    <template #no-options>
                      {{ $t('no-results') }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-8 pl-0 pr-3">
              <div class="form-group">
                <label :for="`${id}-email`">{{ $t('settings.users.create.email.label') }}</label>
                <div class="wrap">
                  <input
                    :id="`${id}-email`"
                    v-model="userData.email"
                    type="text"
                    :placeholder="$t('settings.users.create.email.placeholder')"
                    data-cy="user-input-email"
                  />
                </div>
              </div>
            </div>

            <div class="col-4 pl-0 pr-3">
              <div class="form-group">
                <label :for="`${id}-360-access`">{{ $t('settings.users.create.360-access.label') }}</label>

                <div class="wrap">
                  <v-select
                    v-model="user360Id"
                    label="name"
                    :clearable="false"
                    :searchable="false"
                    :input-id="`${id}-360-access`"
                    :options="user360"
                    :placeholder="$t('settings.users.create.360-access.placeholder')"
                    data-cy="user-input-access-oto"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes" class="arrow-down"></span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <p class="col-12">{{ $t('settings.users.password-email') }}</p>
          </div>

          <div class="row access-profile">
            <div class="col pl-0">
              <div class="form-group access-profile__form">
                <label :for="`${id}-profile`">{{ $t('settings.users.create.profile') }}</label>
                <div class="access-profile__content">
                  <v-select
                    v-model="userData.profile"
                    class="access-profile__content-select"
                    label="name"
                    :input-id="`${id}-profile`"
                    :options="profiles"
                    :clearable="false"
                    :searchable="false"
                    :placeholder="$t('settings.users.create.360-access.placeholder')"
                    :disabled="blockProfile"
                    data-cy="user-input-access-profile"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes" class="arrow-down"></span>
                    </template>
                  </v-select>
                  <p v-if="userData.profile">
                    {{ userData.profile.description }}
                  </p>
                  <p v-else>{{ $t('settings.users.select-profile') }}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="permissions.isVisible" class="row user-permissions">
            <div class="container">
              <div class="wrapper user-permissions__wrapper">
                <div :class="['box', 'routes', isShowingStores ? 'box-routes--large' : '']">
                  <h4>{{ $tc('settings.users.pages', 1) }}</h4>

                  <div :class="['list', isShowingStores ? 'b-routes--large' : 'b-routes']">
                    <p
                      v-html="
                        $t('settings.users.access', {
                          type: $tc('settings.users.pages', 2).toLowerCase(),
                        })
                      "
                    ></p>

                    <!-- search -->
                    <div class="form-wrapper search-and-filter">
                      <input
                        v-model="terms.routes"
                        class="findTerm"
                        :placeholder="
                          $t('settings.users.search', {
                            type: $tc('settings.users.pages', 2).toLowerCase(),
                          })
                        "
                      />
                      <i v-if="terms.routes !== ''" class="icon icon-close" @click="clearTerm(1)"></i>
                      <i v-else class="icon icon-search"></i>
                    </div>

                    <!-- check-all -->
                    <div class="settings-check-all">
                      <label class="label" @click.prevent="checkAll">
                        {{ $t('settings.check-all') }}
                        <check-box class="checkbox" :checked="isAllRoutesActive" data-cy="user-checkbox-all" />
                      </label>
                    </div>
                    <!-- list -->
                    <div class="listBox customScrollBar">
                      <div v-for="(route, key) in getFilteredRoutes(terms.routes)" :key="key" class="item">
                        <label class="label" @click.prevent="$_toggleSelection(route.id, filters.routes)">
                          <check-box
                            class="checkbox"
                            :checked="$_isSelected(route.id, filters.routes)"
                            data-cy="user-checkbox-items"
                          />
                          <span v-if="route.name">
                            <span v-if="route.name" class="name" v-html="getHighlightText(route.name, terms.routes)" />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="!isShowingStores" class="box stores">
                  <h4>{{ $tc('settings.users.stores', 1) }}</h4>
                  <div class="list b-stores">
                    <p
                      v-html="
                        $t('settings.users.access', {
                          type: $tc('settings.users.stores', 2).toLowerCase(),
                        })
                      "
                    ></p>

                    <!-- search -->
                    <div class="form-wrapper search-and-filter">
                      <input
                        v-model="terms.stores"
                        class="findTerm"
                        :placeholder="
                          $t('settings.users.search', {
                            type: $tc('settings.users.stores', 2).toLowerCase(),
                          })
                        "
                      />
                      <i v-if="terms.stores !== ''" class="icon icon-close" @click="clearTerm"></i>
                      <i v-else class="icon icon-search"></i>
                    </div>

                    <!-- check-all -->
                    <div class="settings-check-all">
                      <label
                        class="label"
                        @click.prevent="
                          toggleAllStores();
                          checkAllStores();
                        "
                      >
                        {{ $t('settings.check-all') }}
                        <check-box class="checkbox" :checked="isAllStoresActive" />
                      </label>
                    </div>

                    <!-- list -->
                    <div class="listBox customScrollBar">
                      <div v-for="(store, key) in getFilteredStores(terms.stores)" :key="key" class="item">
                        <label class="label" @click.prevent="$_toggleSelection(store.id, filters.stores)">
                          <check-box class="checkbox" :checked="$_isSelected(store.id, filters.stores)" />
                          <span v-if="store.store" class="name" v-html="getHighlightText(store.store, terms.stores)" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row users-create-edit__actions">
          <div class="col-12">
            <button
              :id="`${id}-submit`"
              class="btn users-create-edit__btn-confirm"
              :disabled="loading || disabled"
              v-tooltip.left="{
                theme: 'default',
                content: `<div class='uce--tooltip' data-cy='user-submit-button-tooltip'>${$t(
                  'campaigns.global-error',
                )}</div>`,
                shown: isShowingTooltip,
                html: true,
                triggers: [],
                distance: 8,
              }"
              data-cy="user-submit-button"
            >
              <span v-if="loading">{{ $tc('settings.users.loading') }}...</span>
              <span v-else>
                {{ $t(`settings.users.${mode}.btn`) }}
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </el-dialog>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import vSelect from 'vue-select';
import { isEmpty, escapeRegExp } from 'lodash';
import { stringParser } from '@/helpers/string-parser';
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
// services
import { validate, edit, create } from '@/services/userSettings';
import { getAccounts } from '@/services/clients';
import { getRoutesByProfile, getListProfiles } from '@/services/users';
import { getStoresById } from '@/services/stores';
// helpers
import { checkboxMixin, routeMixin } from '@/helpers/mixins';
import { menuItems } from '@/helpers/menu';
// components - atoms
import CheckBox from '@/components/_atoms/CheckBox';

const defaultfilters = {
  routes: [],
  stores: [],
};

const userTypeAdministratorId = 1;

export default {
  name: 'UserCreateEdit',
  components: {
    vSelect,
    CheckBox,
  },
  mixins: [checkboxMixin, routeMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  props: {
    userSelected: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: null,
    },
    resetFields: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      id: 'user-create-edit',
      clients: [],
      profiles: [],
      disabled: false,
      showClient: false,
      error: false,
      success: false,
      user360Id: null,
      user360: [
        {
          id: 0,
          name: this.$t('settings.users.user360.no-access'),
        },
        {
          id: 1,
          name: this.$t('settings.users.user360.bove'),
        },
        {
          id: 2,
          name: this.$t('settings.users.user360.app'),
        },
      ],
      // permissions
      loading: false,
      filters: { ...defaultfilters },
      routes: [],
      stores: [],
      subLevels: [],
      permissions: {
        isVisible: false,
      },
      terms: {
        routes: '',
        stores: '',
      },
      resolveRoutes: [],
      blockProfile: true,
      // check all - routes is true by default
      isAllStoresActive: false,
      isAllRoutesActive: true,
      isShowingStores: false,
      isVisible: false,
      isShowingTooltip: false,
      errorMessage: {},
    };
  },
  computed: {
    ...mapGetters(['selectedBrand']),
    checkError() {
      return !validate.fields([
        ['string', this.userData.name],
        ['email', this.userData.email],
        ['integer', this.userData.profile?.id],
        ['integer', this.userData.client?.id],
      ]);
    },

    userData() {
      return this.userSelected;
    },
    selectedRoutes() {
      return this.filters.routes;
    },
    getSelectedRoutes() {
      return this.routes || [];
    },
    /**
     * @description return all menu items options
     */
    menuItems() {
      return menuItems;
    },
    /**
     * @description filter routes by selected profile
     */
    filterRouteItems() {
      let filterRoutes = [];
      filterRoutes = this.$_filterRoutesByGroup(menuItems, this.routes);
      return filterRoutes;
    },
    /**
     * @description create an array with the correct sort order
     */
    orderRoutes() {
      if (!this.routes) return;
      return this.routes?.map(el => el.id);
    },
  },
  watch: {
    'userData.profile': function () {
      this.isShowingStores = this.userData?.profile?.id === userTypeAdministratorId;
      if (this.isShowingStores) {
        this.isAllStoresActive = true;
        this.checkAllStores();
      }
      this.onChangeSelectionBox();
    },
    selectedRoutes: {
      deep: true,
      handler() {
        this.verifyIfCheckAllRoutes();
      },
    },
    routes() {
      // update filtered route items
      this.filterRouteItems;
    },
    isVisible() {
      if (!this.isVisible) {
        this.hide();
        this.resetFields();
      }
    },
  },
  mounted() {
    bus.$on('user-edit-open', this.show);
    bus.$on('user-create-open', this.show);

    if (this.$route.name === 'UserCreate' || this.$route.name === 'UserEdit') this.show();

    this.fetchData();
  },
  beforeUnmount() {
    this.hide();
    bus.$off('user-edit-open');
    bus.$off('user-create-open');
  },
  methods: {
    checkAll() {
      this.toggleAllRoutes();
      this.checkAllRoutes();
    },
    show() {
      this.isVisible = true;
      this.updateInfo();

      this.$nextTick(() => {
        if (this.mode === 'edit') {
          this.validateForMultipleAccounts();

          this.user360Id = this.user360.find(el => el.id === this.userData.user_360);
          this.blockProfile = false;
          this.fetchStores();
        }
      });
    },
    verifyIfCheckAllRoutes() {
      const displayedRoutes = this.resolveRoutes.length ? this.resolveRoutes.map(route => route.id) : [];
      const checkedRoutes = this.filters.routes;
      const totalRoutesToChecked = checkedRoutes.filter(route => displayedRoutes.includes(route));
      if (totalRoutesToChecked.length === 0 && displayedRoutes.length === 0) return;
      if (totalRoutesToChecked.length < displayedRoutes.length) {
        this.isAllRoutesActive = false;
      } else {
        this.isAllRoutesActive = true;
      }
    },
    hide() {
      this.isVisible = false;

      if (this.$route.name === 'UserCreate' || this.$route.name === 'UserEdit') this.$router.push('/settings/users');

      this.reset();
    },
    reset() {
      this.isShowingTooltip = false;
      this.blockProfile = true;
      this.permissions.isVisible = false;
      this.user360Id = null;

      // reset data to check all checkbox options
      this.isAllStoresActive = false;
      this.filters = { ...defaultfilters };
    },
    fetchData() {
      this.fetchAccounts();
      this.fetchProfiles();
    },
    fetchAccounts() {
      getAccounts().then(({ data }) => {
        this.clients = [];
        data.data.forEach(client => {
          this.clients.push({
            clientId: client.clientId,
            name: client.account,
            id: client.accountId,
          });
        });
      });
    },
    fetchProfiles() {
      getListProfiles().then(response => {
        const data = response?.data?.data;
        this.profiles = data;
      });
    },
    fetchRoutes(id) {
      if (!id) return;

      getRoutesByProfile(id)
        .then(({ data }) => {
          this.routes = data?.data;
        })
        .then(() => {
          // filter all visible routes that could be selected
          this.resolveRoutes = this.flatRoutes(this.getSelectedRoutes).map(el => {
            return { ...el, name: this.$t(el.name) };
          });

          // filter all previous checked routes and insert to filter routes
          let checkedRoutes = this.flatRoutes(this.userData?.routes)?.map(el => {
            return { ...el, name: this.$t(el.name) };
          });

          if (!this.resolveRoutes.length) {
            checkedRoutes = [];
          }

          if (checkedRoutes) this.filters.routes = this.mapById(checkedRoutes);

          // after every update make the checkbox check-all active and routes selected
          this.verifyIfCheckAllRoutes();
        });
    },
    fetchStores() {
      const accountId = this.userData?.client?.id;
      this.blockProfile = false;
      this.filters.stores = [];
      getStoresById(accountId)
        .then(({ data }) => {
          this.stores = !isEmpty(data) ? data : false;
        })
        .then(() => {
          if (this.mode === 'edit') {
            if (this.userData?.client?.stores) this.filters.stores = this.userData?.client?.stores;
          }
        })
        .then(() => {
          // check if all stores are checked after every brand change
          if (this.isAllStoresActive) this.filters.stores = this.mapById(this.stores);
        })
        .catch(() => {
          this.userData.client.stores = [];
          this.stores = [];
        });
    },
    updateInfo() {
      this.disabled = false;
      this.error = false;
      this.success = false;
    },
    onChangeSelectionBox() {
      if (isEmpty(this.userData.profile)) return;

      this.permissions.isVisible = true;

      this.fetchRoutes(this.userData.profile?.id);

      this.filters.routes = [];
    },
    /**
     * @description filter array routes
     * @param {String} term - term to be searched
     */
    getFilteredRoutes(term) {
      // applyParse => add scape regex characters and ignore accents from characters on term argument
      if (!this.resolveRoutes) return;
      const applyParse = escapeRegExp(stringParser(term));
      return this.resolveRoutes.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
    },
    /**
     * @description filter array stores
     * @param {String} term - term to be searched
     */
    getFilteredStores(term) {
      // applyParse => add scape regex characters and ignore accents from characters on term argument
      if (!this.stores) return;
      const applyParse = escapeRegExp(stringParser(term));
      return this.stores.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.store)));
    },
    /**
     * @param {Array} routes
     */
    formatRoutes(routes) {
      if (!routes) return;

      return routes.join(',');
    },
    /**
     * @param {Array }stores
     */
    formatStores(stores) {
      const aux = stores ? stores?.join(',') : '';
      return `${this.userData?.client.id}:${aux}`;
    },
    /**
     * @method getHighlightText
     * @description get word and highlight term, escape special characters and accents and wrap highlight with strong tag before show on list
     * @param {String} text
     * @param {String} highlight
     * @return list item with hightlight term in bold
     */
    getHighlightText(text, highlight) {
      // remove accents from text and highlight word
      const parsedText = stringParser(text);
      const parsedHighlight = stringParser(highlight);
      // define pattern to search with escaped special characters
      const pattern = new RegExp(escapeRegExp(parsedHighlight), 'gi');
      // get first and last index from highlight
      const startIndex = pattern.exec(parsedText).index;
      const endIndex = startIndex + highlight.length;
      // extract highlight from original text and wrap with strong tag
      const slicedText = text.slice(startIndex, endIndex);
      const wrapHightlight = `<strong>${slicedText}</strong>`;
      // return original text with wrap highlight
      return text.replace(slicedText, wrapHightlight);
    },
    /**
     * @method clearTerm
     * @description clear input v-model based on argument
     * @param {Number} target
     */
    clearTerm(target) {
      if (target === 1) {
        this.terms.routes = '';
      } else {
        this.terms.stores = '';
      }
    },
    flatRoutes(rawRoutes) {
      if (!rawRoutes) return;

      const routes = rawRoutes.filter(item => item.id && !item.routes);
      const routesDeep = rawRoutes.flatMap(item => item.routes).filter(el => el !== undefined);
      const mergedRoutes = [...routes, ...routesDeep];

      const aux = mergedRoutes.reduce((acc, curr) => {
        this.menuItems.filter(item => {
          if (item.id === curr.id) acc.push(item);
        });
        if (typeof curr.id === 'number' && curr.name !== null) {
          acc.push({ id: `report-${curr.id}`, name: curr.name });
        }
        return acc;
      }, []);
      return aux;
    },
    submit() {
      if (this.checkError) {
        this.isShowingTooltip = true;
      } else {
        this.loading = true;
        this.disabled = true;
        this.isShowingTooltip = false;

        const ERRORS_CODES = [
          'required-params-not-set',
          'missing-user-create-permissions',
          'unauthorized-user-level-access',
          'error-creating-user',
          'unauthorized-client-access',
          'unauthorized-account-access',
          'unauthorized-store-access',
          'user-not-found',
          'edit-user-unauthorized',
          'invalid-user-email',
          'user-login-already-exists',
          'account-required-if-routes-are-set',
          'password-error-blacklist',
          'password-error-short-string',
          'password-error-missing-number',
          'password-error-missing-lower-letter',
          'password-error-missing-upper-letter',
          'failed-updating-user',
          'client-id-not-found',
          'user-type-not-found',
          'user-type-not-allowed-client',
          'user-type-not-allowed-domain',
          'failed-creating-new-user',
        ];

        if (this.mode === 'create') {
          create(
            this.userData.name,
            this.userData.email,
            this.userData.profile.id,
            this.userData.client.clientId,
            this.formatRoutes(this.filters.routes),
            this.formatStores(this.filters.stores),
            this.user360Id.id,
          )
            .then(response => {
              if (response?.data?.success) {
                this.success = true;
                this.errorMessage = {};
                bus.$emit('update-users', this.mode);
                setTimeout(() => {
                  this.disabled = true;
                  this.hide();
                }, 2500);
              } else {
                if (ERRORS_CODES.includes(response?.data?.errors?.code)) {
                  this.validateError(`settings.users.errors.${response?.data?.errors?.code}`, response?.data?.errors);
                } else {
                  this.validateError(`settings.users.create.error`);
                }
              }
            })
            .finally(() => (this.loading = false));
        } else {
          edit(
            this.userData.name,
            this.userData.email,
            this.userData.client.clientId,
            this.userData.profile.id,
            this.userData.id,
            this.formatRoutes(this.filters.routes),
            this.formatStores(this.filters.stores),
            this.user360Id.id,
          )
            .then(response => {
              if (response?.data?.success) {
                this.success = true;
                this.errorMessage = {};
                bus.$emit('update-users', this.mode);
                setTimeout(() => {
                  this.disabled = true;
                  this.hide();
                }, 2500);
              } else {
                if (ERRORS_CODES.includes(response?.data?.errors?.code)) {
                  this.validateError(`settings.users.errors.${response?.data?.errors?.code}`);
                } else {
                  this.validateError(`settings.users.edit.error`);
                }
              }
            })
            .finally(() => (this.loading = false));
        }
      }
    },
    validateError(message, error) {
      this.error = true;
      this.disabled = false;
      this.errorMessage = error;
      this.toast.error(this.$tc(message), { timeout: 10000 });
    },
    toggleAllRoutes() {
      this.isAllRoutesActive = !this.isAllRoutesActive;
    },
    checkAllRoutes() {
      if (this.isAllRoutesActive) this.filters.routes = this.mapById(this.resolveRoutes);
      else this.filters.routes = [];
    },
    toggleAllStores() {
      this.isAllStoresActive = !this.isAllStoresActive;
    },
    checkAllStores() {
      if (this.isAllStoresActive) this.filters.stores = this.mapById(this.stores);
      else this.filters.stores = [];
    },
    mapById(arr) {
      if (!arr) return;
      return arr.map(el => el?.id);
    },
    validateForMultipleAccounts() {
      const { hasMultipleAccounts } = this.userData?.client;
      if (hasMultipleAccounts) {
        bus.$emit('open-user-accounts-error-modal');
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users-create-edit {
  &__form {
    padding-top: 30px;
  }

  &__actions {
    padding-top: 35px;
  }

  &__btn-confirm {
    padding: 0 50px;
    margin: 10px 0 10px auto;
    &.disabled {
      background-color: $gray-500;
      pointer-events: none;
    }
  }

  &__btn-close {
    background-color: $gray-600;
    border-radius: 100px;
    color: $color-white;
    padding: 5px 70px;
    margin: 10px 0 10px auto;
    opacity: 1;
    font-size: 30px;
  }
}

.access-profile {
  height: 135px;
  &__form {
    position: relative;
    padding-left: 15px;
    label {
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 35px;
        left: 0;
        border: 1px solid #ccc;
        border-radius: 8px;
        z-index: 0;
      }
    }
  }

  &__content {
    &-select {
      width: 300px;
      background: $color-white;
      margin-bottom: 20px;
    }
    p {
      text-align: left;
    }
  }
}

.user-permissions {
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    padding-top: 30px;
    overflow: hidden;
  }

  .box {
    width: 50%;
    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: $gray-800;
    }
    h5 {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      color: $gray-600;
    }
  }

  .box-routes--large {
    width: 100%;

    .list {
      &.b-routes--large {
        border-radius: 8px;
      }
    }
  }

  .routes,
  .stores {
    .list {
      border: 1px solid $gray-400;
      box-shadow: inset 0px -3px 5px rgba(0, 0, 0, 0.07);
      height: 300px;
      overflow: hidden;
      padding: 20px 20px 0px;

      &.b-routes {
        border-radius: 8px 0 0 8px;
        border-right: 0;
      }

      &.b-stores {
        border-radius: 0 8px 8px 0;
      }

      .search-and-filter {
        position: relative;
        .findTerm {
          width: 100%;
        }
        i {
          position: absolute;
          right: 7px;
          top: 4px;
          font-size: 26px;
          color: $gray-500;
          cursor: pointer;
        }
      }

      .listBox {
        height: calc(240px - 34px - 20px - 25px);
        margin-top: 5px;
        overflow: auto;
        padding-bottom: 15px;

        .topic {
          font-weight: bold;
          padding: 4px 0;
          color: $gray-800;
        }
      }

      .item {
        padding-bottom: 3px;
        label {
          align-items: center;
          color: $gray-700;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          font-size: 12px;
          font-weight: normal;

          .checkbox {
            margin-right: 10px;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.3;
          }
        }
      }
    }
  }

  .settings-check-all {
    margin-top: 16px;
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: normal;

      .checkbox {
        margin-left: 6px;
      }
    }
  }
}
</style>

<style lang="scss">
#user-create-edit {
  @import '@/assets/scss/vendors/_v-select';
  position: absolute;
  top: 0;
  margin: 1.75rem auto;
  min-height: 500px;
  transition: max-height 0.5s ease-in-out;
  height: auto;

  select:disabled {
    background-color: #e3e3e3;
    color: $gray-600;
  }

  .el-dialog__body {
    padding: 0 50px 25px 50px;
  }
}

div.tooltip.tooltip-user-edit {
  .arrow::before {
    border-left-color: $color-white;
    border-top-color: transparent;
  }
}

.uce--tooltip {
  width: 200px;
}
</style>
