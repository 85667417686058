<template>
  <base-card-v2 :id="id" class="pr-2 pl-2 pr-sm-4 pl-sm-4">
    <header class="d-flex align-items-center justify-content-between mb-3">
      <h2 class="card-title mb-0">{{ title }}</h2>
      <v-select
        v-model="selectedOptionIndex"
        label="title"
        :clearable="false"
        :searchable="false"
        class="float-right ml-auto seasos-options"
        :options="seasons"
        @option:selected="updateSeason(selectedOptionIndex)"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('360.sellers.no-results') }}
        </template>
      </v-select>
    </header>
    <div v-if="data && data.options.length" class="options-table">
      <ul>
        <li class="header d-flex align-items-center justify-content-between">
          <span>{{ data.title }}</span>
          <span class="text-right">{{ $t('360.quantity') }}</span>
        </li>
        <li v-for="(option, key) in data.options" :key="key" class="d-flex align-items-center justify-content-between">
          <div class="column">
            <span class="order" :style="{ color }">{{ key + 1 }}º</span>
            <span class="pl-4">{{ option.name }}</span>
          </div>
          <div class="column color-bb text-right">
            {{ option.quantity }}
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      <empty-message :text="$t('360.person.empty.data')" />
    </div>
  </base-card-v2>
</template>

<script>
import bus from '@/helpers/events/bus';
import EmptyMessage from '@/components/_atoms/EmptyMessage';
import vSelect from 'vue-select';

export default {
  name: 'CustomerViewMostPurchased',
  components: {
    'empty-message': EmptyMessage,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedOptionIndex: {
        value: 'spring',
        title: this.$t('360.seasons.spring'),
      },
      seasons: [
        {
          value: 'spring',
          title: this.$t('360.seasons.spring'),
        },
        {
          value: 'summer',
          title: this.$t('360.seasons.summer'),
        },
        {
          value: 'fall',
          title: this.$t('360.seasons.fall'),
        },
        {
          value: 'winter',
          title: this.$t('360.seasons.winter'),
        },
      ],
    };
  },
  methods: {
    updateSeason(season) {
      this.$parent.season = season.value;
      bus.$emit('change-season', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.seasos-options {
  width: 145px;
}

.options-table {
  .color-bb {
    color: $gray-500;
  }
  .icon-right-14px {
    font-size: 14px;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    font-size: 12px;
    line-height: 20px;
    padding: 8px 5px;
    color: $gray-700;
    border-bottom: 1px solid #f1f1f1;
    &.header {
      font-weight: 600;
      color: $gray-800;
      border-bottom: 0;
      padding: 10px 5px 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .order {
      font-weight: bold;
    }
    .icon {
      font-size: 23px;
    }
  }
}
</style>

<style lang="scss">
#most-purchased-categories {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
