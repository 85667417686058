<template>
  <section>
    <header>
      <a v-show="!isEmpty" href="#" class="btn btn-new-campaign btn-link" @click.prevent="openCreate">{{
        $t('campaigns.create-campaign')
      }}</a>
    </header>
    <campaigns-list v-show="!isEmpty" :page-history="page" />
    <campaigns-empty v-if="isEmpty"></campaigns-empty>
    <campaigns-create />
  </section>
</template>

<script>
import CampaignsList from '@/components/campaigns/list/CampaignsList';
import CampaignsEmpty from '@/components/campaigns/list/CampaignsEmpty';
import CampaignsCreate from '@/components/campaigns/create/CampaignsCreate';
import bus from '@/helpers/events/bus';

export default {
  name: 'Campaigns',
  components: {
    CampaignsList,
    CampaignsCreate,
    CampaignsEmpty,
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      isEmpty: false,
    };
  },
  watch: {
    $route(to) {
      this.page = to.query.page;
    },
  },
  created() {
    bus.$on('campaigns-list-updated', data => {
      this.isEmpty = !data;
    });
  },
  methods: {
    openCreate() {
      this.$router.push('/performance/campaigns/create');
      bus.$emit('campaign-create-open');
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  margin: 40px 0 25px;
  display: flex;
  align-items: center;
  h1 {
    margin: 0;
  }
  .btn-new-campaign {
    background-color: transparent;
    font-weight: 600;
    font-size: 12px;
    color: $oto-omni;
    border: none;
    cursor: pointer;
    position: relative;
    right: 0;
    padding-left: 23px;
    text-decoration: underline;
  }
}
</style>
