<template>
  <section class="labels-custom-view">
    <div class="row">
      <div class="col-12">
        <router-link to="/labels">
          <img v-if="lang === 'en'" src="@/assets/img/figures/labels/en/list-custom.png" alt="Labels" />
          <img v-if="lang !== 'en'" src="@/assets/img/figures/labels/list-custom.png" alt="Labels" />
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    lang() {
      return this.$store.getters.user.language || 'pt-BR';
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: auto;
  box-shadow: 0px 24px 60px rgba(0, 0, 0, 0.07);
  margin-bottom: 12px;
}
</style>
