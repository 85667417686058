// libs
import moment from 'moment';
import { getNumeral } from '@/utilities/numbers';
// helpers
import { valuesByFormat, formatDecimal, formatCurrency, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';
export default function useTableMixin() {
  /**
   * @description return a value according to type and format passed
   * @param {*} vH
   * @param {*} amount
   * @returns string value or amount result
   */
  const getValueNewFormat = ({ vH, amount }, displaysZero = false) => {
    const metric = {};
    if (amount || (displaysZero && amount === 0)) metric.amount = amount;
    if (amount) metric.amount = amount;
    if (vH.type) metric.type = vH.type;
    if (vH.format) metric.format = vH.format;

    return valuesByFormat(metric);
  };

  /**
   * @description Verify if amount is between threshold values
   * @param {object} config
   * @returns css class of selected interval
   */

  const getCheckThreshold = config => {
    const { amount, threshold } = config;

    if (!threshold || !threshold.length || threshold.length < 2) return '';

    const pattern = threshold[0] > threshold[1]; // true => decreasing (bigger is better) | false => increasing (smaller is better)
    if (pattern) {
      if (amount < threshold[0] && amount >= threshold[1]) return 'pointer-light-blue';
      else if (amount >= threshold[0]) return 'pointer-strong-blue';
      else return 'pointer-sky-blue';
    } else {
      if (amount > threshold[0] && amount <= threshold[1]) return 'pointer-light-blue';
      else if (amount <= threshold[0]) return 'pointer-strong-blue';
      else return 'pointer-sky-blue';
    }
  };

  /**
   * @description get value according to type passed
   * @param {*} item
   * @returns string value or amount result
   */
  const useGetValue = item => {
    const { type, amount } = item;

    // Null values should be display as empty, not zero.
    if (amount === null) {
      return '';
    }

    switch (type) {
      case valueType.date:
        return moment(amount).format('DD/MM/YYYY');
      case valueType.percent:
        return formatNumber(amount * 100, '0.00%');
      case valueType.percentCircle:
        return Math.round(getNumeral(amount * 100));
      case valueType.decimal:
        return formatCurrency(amount);
      case valueType.float:
        return formatDecimal(amount, 2);
      case valueType.integer:
        return formatDecimal(amount);
      case valueType.string:
        return amount;
      default:
        return amount;
    }
  };

  return { getValueNewFormat, getCheckThreshold, useGetValue };
}
