<template>
  <div class="card">
    <div class="indicators-funnel">
      <template v-if="$_verifyLoaded('done')">
        <div class="row">
          <div class="if__col-full if__col card">
            <p class="if__purple-bg">
              {{ getFormattedMetric(funnelData.activities_total) }}
            </p>
            <p class="if__metric-description">
              {{ $t('360.indicators.funnel-header.text-activities') }}
            </p>
          </div>
        </div>

        <div class="if__line-full"></div>

        <div class="row">
          <div class="if__col-half if__col card">
            <p class="if__metric-title">
              {{ getFormattedMetric(funnelData.activities_started) }}
              <span>{{ $_percentageCalculation(funnelData.activities_started, funnelData.activities_total) }}</span>
            </p>
            <p class="if__metric-description">
              {{ $t('360.indicators.funnel.started-talk') }}
            </p>
          </div>

          <div class="if__col-metrics">
            <div class="if__col-metric if__col">
              <p class="if__metric-title">
                {{ getFormattedMetric(funnelData.activities_rescheduled) }}
                <span>
                  {{ $_percentageCalculation(funnelData.activities_rescheduled, funnelData.activities_total) }}
                </span>
              </p>
              <p class="if__metric-description">
                {{ $t('360.indicators.funnel.rescheduled') }}
              </p>
            </div>
            <div class="if__col-metric if__col">
              <p class="if__metric-title">
                {{ getFormattedMetric(funnelData.activities_awaiting) }}
                <span>
                  {{ $_percentageCalculation(funnelData.activities_awaiting, funnelData.activities_total) }}
                </span>
              </p>
              <p class="if__metric-description">
                {{ $t('360.indicators.funnel.not-performed') }}
              </p>
            </div>
            <div class="if__col-metric if__col">
              <p class="if__metric-title">
                {{ getFormattedMetric(funnelData.activities_rejected) }}
                <span>
                  {{ $_percentageCalculation(funnelData.activities_rejected, funnelData.activities_total) }}
                </span>
              </p>
              <p class="if__metric-description">
                {{ $t('360.indicators.funnel.rejected') }}
              </p>
            </div>
          </div>
        </div>

        <div class="if__line-half"></div>

        <div class="row">
          <div class="if__row-half">
            <div class="col-metrics">
              <div class="if__col-metric if__col">
                <p class="if__metric-title">
                  {{ getFormattedMetric(funnelData.contacts_failed) }}
                  <span>
                    {{ $_percentageCalculation(funnelData.contacts_failed, funnelData.activities_started) }}
                  </span>
                </p>
                <p class="if__metric-description">
                  {{ $t('360.indicators.funnel.not-talk') }}
                </p>
              </div>
            </div>

            <div class="if__col-half if__col card">
              <p class="if__metric-title">
                {{ getFormattedMetric(funnelData.contacts_success) }}
                <span>{{ $_percentageCalculation(funnelData.contacts_success, funnelData.activities_started) }}</span>
              </p>
              <p class="if__metric-description">{{ $t('360.indicators.funnel.yes-talk') }}</p>
              <div class="if__line-one-third"></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="if__row-one-third">
            <div class="col-metrics">
              <div class="if__col-metric if__col if__last-line-bottom">
                <p class="if__metric-title">
                  {{ getFormattedMetric(funnelData.contacts_active) }}
                  <span>{{ $_percentageCalculation(funnelData.contacts_active, funnelData.contacts_success) }}</span>
                </p>
                <p class="if__metric-description">
                  {{ $t('360.indicators.funnel.in-progress') }}
                </p>
              </div>
            </div>
            <div class="if__col-half if__col card if__last-line-bottom">
              <p class="if__metric-title">
                {{ getFormattedMetric(funnelData.contacts_finished) }}
                <span>{{ $_percentageCalculation(funnelData.contacts_finished, funnelData.contacts_success) }}</span>
              </p>
              <p class="if__metric-description">
                {{ $t('360.indicators.funnel.done-talk') }}
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="if__row-last-block">
            <div class="if__line-last-block"></div>
            <div class="col-metrics">
              <div class="if__col-metric if__col">
                <p class="if__metric-title">
                  {{ getFormattedMetric(funnelData.orders_tracked) }}
                  <span>{{ formatValuePercentage(funnelData.conversion_rate) }}</span>
                </p>
                <p class="if__metric-description">
                  {{ $t('360.indicators.funnel.sales') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>

      <base-loader v-show="$_verifyLoaded('loading')" :height="'75'">
        <template #message>
          <p class="assync-message">{{ $tc(`assync-status.${assyncCode}`) }}</p>
        </template>
      </base-loader>
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </div>
  </div>
</template>

<script>
// libs
import { mapGetters, mapState, mapActions } from 'vuex';

// helpers
import { formatDecimal, formatPercent } from '@/utilities/formatters';
import { verifyMixin, valueMixin } from '@/helpers/mixins';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

const assyncStatus = {
  started: 'job-started',
  pending: 'job-pending',
  finished: 'job-finished-successfully',
  cancelled: 'job-cancelled-with-errors',
};

export default {
  name: 'IndicatorFunnel',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, valueMixin],
  data() {
    return {
      metrics: [...Array(8)],
      funnelData: {},
      tab: 'tab-funnel',
      assyncCode: assyncStatus.started,
    };
  },
  computed: {
    ...mapGetters('indicators', [
      'getStores',
      'getSellers',
      'getSegments',
      'getSegmentId',
      'getStoreId',
      'getSellerId',
      'getCampaignId',
      'getSourceId',
    ]),

    ...mapState('indicators', ['conversionWindow', 'funnel', 'isLoadingFunnel', 'assyncCodeFunnel', 'hasErrorFunnel']),
  },
  watch: {
    funnel: function () {
      this.updateFunnelData();
    },
    isLoadingFunnel: function (isLoading) {
      this.updateIsLoading(isLoading);
    },
    assyncCodeFunnel: function () {
      this.updateAssyncCode();
    },
    hasErrorFunnel: function () {
      this.updateHasError();
    },
  },
  mounted() {
    useMixpanelConfig('view_oto_indicators_conversion-funnel');
    this.updateFunnelData();
    this.updateIsLoading(_.isEmpty(this.funnelData));
  },
  methods: {
    ...mapActions('indicators', ['setGetFunnelBatchId']),

    updateFunnelData() {
      this.funnelData = this.funnel;
    },

    updateIsLoading(isLoading) {
      this.loading = isLoading;
    },

    updateAssyncCode() {
      this.assyncCode = this.assyncCodeFunnel;
    },

    updateHasError() {
      this.hasError = this.hasErrorFunnel;
    },

    getFormattedMetric(amount) {
      let formattedMetric = '';
      formattedMetric = formatDecimal(amount, 2);

      return formattedMetric;
    },

    formatValuePercentage(value) {
      return `(${formatPercent(value, 1)})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicators-funnel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  min-height: 620px;
  padding: 20px;
  background-color: $gray-300;

  .if__metric-title {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;

    span {
      font-weight: 400;
      font-size: 12px;
      padding-left: 3px;
    }
  }

  .if__metric-description {
    margin-bottom: 0;
    text-align: center;
  }

  .if__block-metric-title {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .if__row-last-block {
    .if__col-metric {
      background-color: $oto-seller;
      padding: 0 15px;
      height: 60px;
      border-radius: 4px;

      .if__metric-description {
        font-size: 11px;
      }

      .if__metric-description,
      .if__metric-title,
      span {
        color: #fff;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
  }

  // cols
  .if__col {
    margin-top: 35px;
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .if__col-full {
    padding: 0;
    margin-top: 0;
    width: 100%;
  }

  .if__col-half {
    padding: 0;
    width: 50%;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -35px;
      height: 30px;
      width: 1px;
      background-color: #c9c9c9;
    }
  }

  .if__col-metrics {
    display: flex;
    justify-content: space-around;
    width: 50%;
  }

  .if__col-metric {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -35px;
      height: 30px;
      width: 1px;
      background-color: #c9c9c9;
    }
  }

  // block
  .if__block-metric {
    &:first-child {
      margin-bottom: 20px;

      &::before {
        top: 95px;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -50px;
      top: 20px;
      height: 1px;
      width: 30px;
      background-color: #c9c9c9;
    }
  }

  // rows
  .if__row-half {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin-left: 5%;
  }

  .if__row-one-third {
    display: flex;
    justify-content: space-between;
    width: 33%;
    margin-left: 25%;
  }

  .if__row-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    margin-top: 15px;
    margin-left: 50px;
  }

  .if__row-last-block {
    display: flex;
    justify-content: center;
    width: 20%;
    margin-left: 29%;
    margin-top: 28px;
  }

  // horizontal lines
  .if__line-full,
  .if__line-half,
  .if__line-one-third,
  .if__line-last-block {
    display: flex;
    padding: 0;
    margin: 0;
    height: 1px;
    background-color: #c9c9c9;
  }

  .if__line-full {
    align-self: center;
    width: 100%;
  }

  .if__line-half {
    width: 50%;
  }

  .if__line-one-third {
    position: absolute;
    width: 81.5%;
    bottom: -12px;
    left: 29px;
  }

  .if__line-last-block {
    position: absolute;
    width: 228px;
    margin-left: 10px;
  }

  // vertical lines
  .if__line-block-metrics {
    height: 75px;
    margin: 35px 0 0 50px;
    width: 1px;
    background-color: #c9c9c9;
    position: relative;

    &::before {
      background-color: #c9c9c9;
      content: '';
      display: block;
      position: absolute;
      left: -50px;
      top: 40px;
      height: 1px;
      width: 50px;
    }
  }

  .if__last-line-bottom {
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 80px;
      bottom: -41px;
      height: 35px;
      width: 1px;
      background-color: #c9c9c9;
    }

    &:first-child::after {
      left: 38px;
    }
  }
}

// texts colors
.if__purple-bg {
  padding: 0 5px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background-color: $oto-omni;
}

.if__purple-metric {
  color: $oto-omni;
  font-weight: 600;
  font-size: 14px;

  span {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    padding-left: 3px;
  }
}

.assync-message {
  width: 200px;
  text-align: center;
}
</style>
