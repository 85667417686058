<template>
  <table-list :id="id" :type="type" :data="data" :empty="!data.count">
    <table-list-header v-if="$_verifyLoaded('done')">
      <table-list-column class-name="col-3 col-lg-6">{{ $t('product.title') }}</table-list-column>
      <table-list-column class-name="text-right">{{ $t('clients') }}</table-list-column>
      <table-list-column class-name="text-right">{{ $t('orders') }}</table-list-column>
      <table-list-column class-name="text-right">{{ $t('quantity') }}</table-list-column>
      <table-list-column class-name="text-right">{{ $t('revenue') }}</table-list-column>
    </table-list-header>
    <table-list-body :class="{ loadHeight: loading || hasError || isEmpty }" class="detail-performance-per-product">
      <template v-if="$_verifyLoaded('done')">
        <table-list-row v-for="(row, index) in rows" :key="index">
          <table-list-column class-name="col-3 col-lg-6">{{ row[0] }}</table-list-column>
          <table-list-column class-name="text-right">{{ $_formatDecimal(row[1]) }}</table-list-column>
          <table-list-column class-name="text-right">{{ $_formatDecimal(row[2]) }}</table-list-column>
          <table-list-column class-name="text-right">{{ $_formatDecimal(row[3]) }}</table-list-column>
          <table-list-column class-name="text-right">{{ formatCurrency(row[4]) }}</table-list-column>
        </table-list-row>
      </template>
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </table-list-body>
  </table-list>
</template>

<script>
import getTable from '@/services/getTable';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      id: 'performance-per-product',
      type: 'filled',
      rows: [],
      reference: this.$route.params.id,
      data: {
        page: 1,
        limit: 10,
        count: 0,
      },
    };
  },
  mounted() {
    this.fetch_data();

    bus.$on(`${this.id}-change-page`, this.fetch_data);
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();
      getTable({
        metrics: 'campaign_product_table',
        page: this.data.page,
        limit: this.data.limit,
        dash: 'campaigns',
        campaign: this.reference,
      })
        .then(data => {
          if (this.$_verifyData(data.data.data)) return;

          this.data.count = data.data.total_count;
          this.rows = data.data.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-performance-per-product {
  &.loadHeight {
    height: 300px;
  }
}
</style>
