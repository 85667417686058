<template>
  <lightbox-template id="lightbox-frequency" :title="$t('segments-title')" icon="customer">
    <frequency-chart />
    <frequency-group-chart />
    <frequency-table />
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import FrequencyTable from '@/components/lightbox/lightboxes/_organisms/frequency/FrequencyTable';
import FrequencyChart from '@/components/lightbox/lightboxes/_organisms/frequency/FrequencyChart';
import FrequencyGroupChart from '@/components/lightbox/lightboxes/_organisms/frequency/FrequencyGroupChart';

export default {
  components: {
    LightboxTemplate,
    FrequencyTable,
    FrequencyChart,
    FrequencyGroupChart,
  },
};
</script>

<style lang="scss" scoped>
.loadHeight {
  min-height: 250px;
  position: relative;
}
</style>
