<template>
  <div class="serie-read mr-2" :class="{ editable: isEditable }">
    <div class="label-wrapper">
      <span
        :class="['label-icon', { 'default-bg-color': !buildLabels.color }]"
        :style="`background-color: #${buildLabels.color}`"
      >
        <icon-base w="19" h="22" color="#fff" box="0 0 19 22"><icon-label /></icon-base>
      </span>
      <span
        v-if="buildLabels && buildLabels.name"
        :class="['label-name', { 'default-color': !buildLabels.color }]"
        :style="`color: #${buildLabels.color}`"
      >
        {{ buildLabels.name }}
      </span>
    </div>
  </div>
</template>

<script>
// libs, helpers, services
import { mapGetters } from 'vuex';
// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconLabel from '@/assets/vue-icons/icons/Label';

export default {
  name: 'DetailSerie',
  components: {
    IconBase,
    IconLabel,
  },
  props: {
    group: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters('personas', ['getLabelDetails']),
    buildLabels() {
      return this.getLabelDetails(this.data?.label);
    },
  },
};
</script>

<style lang="scss" scoped>
.serie-read {
  align-items: flex-end;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .label-wrapper {
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    cursor: default;
    display: flex;
    height: 43px;
    line-height: 43px;
    width: 220px;

    .label-icon {
      align-items: center;
      border-radius: 8px 0 0 8px;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 32px;

      &.default-bg-color {
        background-color: #a167d9;
      }
    }

    .label-name {
      font-size: 12px;
      font-weight: 600;
      max-width: 142px;
      overflow: hidden;
      padding-left: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.default-color {
        color: #a167d9;
      }
    }
  }
}
</style>
