<template>
  <section class="register-customer">
    <register-customer-header />
    <register-customer-form />
  </section>
</template>

<script>
import RegisterCustomerHeader from '@/components/oto/registerCustomer/RegisterCustomerHeader';
import RegisterCustomerForm from '@/components/oto/registerCustomer/RegisterCustomerForm';

export default {
  name: 'RegisterCustomer',
  components: {
    RegisterCustomerHeader,
    RegisterCustomerForm,
  },
};
</script>
