import store from '@/store';
import mixpanel from 'mixpanel-browser';

export const useMixpanelDataIngestionHistory = (pageViewName: string, date: string, status) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    selectedDate: date,
    status: status,
  });
};

export const useMixpanelDataIngestionHistoryExport = (
  pageViewName: string,
  date: string,
  fileName: string,
  status: string,
) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    selectedDate: date,
    fileName: fileName,
    status: status,
  });
};

export const useMixpanelDataIngestionPagination = (pageViewName: string, page: number) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    page: page,
  });
};
