<template>
  <div :class="['btn-wrapper', { 'is-stretched': isStretched }]">
    <!-- Button that renders text with <img> or <i> on the left or right -->
    <button
      v-if="hasIcon && (iconLeft || iconRight)"
      :class="['btn', 'has-icon', colorBg, colorText, checkSize, { 'is-stretched': isStretched }]"
      :disabled="isDisabled"
      @click.prevent="execFunction"
    >
      <img v-if="hasIcon && iconLeft && svgPath" :src="svgPath" />
      <i v-if="hasIcon && iconLeft && !svgPath" :class="hasIcon ? iconPath : ''" />
      <slot>{{ text }}</slot>
      <img v-if="hasIcon && iconRight && svgPath" :src="svgPath" />
      <i v-if="hasIcon && iconRight && !svgPath" :class="hasIcon ? iconPath : ''" />
    </button>
    <!-- Button that only renders a <img> or <i> -->
    <button
      v-if="hasIcon && !(iconLeft || iconRight)"
      :class="['btn', 'has-only-icon', colorBg, colorText, checkSize, { 'is-stretched': isStretched }]"
      :disabled="isDisabled"
      @click.prevent="execFunction"
    >
      <img v-if="hasIcon && svgPath" :src="svgPath" />
      <i v-if="hasIcon" :class="hasIcon ? iconPath : ''" />
    </button>
    <!-- Button that only renders a text -->
    <button
      v-if="!hasIcon"
      :class="['btn', colorBg, colorText, checkSize, { 'is-stretched': isStretched }]"
      :disabled="isDisabled"
      @click.prevent="execFunction"
    >
      <slot v-if="!isLoading">{{ text }}</slot>
      <slot v-if="isLoading">{{ $tc('_loading-dots', 2) }}</slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: '',
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    iconPath: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    svgPath: {
      type: String,
      default: '',
    },
    colorBg: {
      type: String,
      default: '',
    },
    colorText: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    execFunction: {
      type: Function,
      default: () => {},
    },
    size: {
      type: String,
      default: '',
      // eslint-disable-next-line prettier/prettier
      validator: function (value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1;
      },
    },
    isStretched: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkSize() {
      return this.size ? `btn-size-${this.size}` : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
  .has-icon {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    i {
      font-size: 22px;
    }
  }

  .has-only-icon {
    width: 100%;
    height: 100%;
    font-size: 22px;
    padding: 5px;
    align-items: center;
    line-height: 0;
    i {
      font-size: 22px;
    }
  }
}
</style>
