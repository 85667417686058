<template>
  <base-card-v2 :class="{ loadHeight: loading }" class="order-analysis">
    <template v-if="$_verifyLoaded('done')">
      <base-card-header :title="$t('order-analysis')" :ready="!loading" />
      <template slot:item-right>
        <select v-if="select.length" v-model="selectedOption" class="float-right ml-auto">
          <option v-for="(option, index) in select" :key="index" :value="option.metric">
            {{ option.name }}
          </option>
        </select>
      </template>
      <div class="info-container row d-flex align-items-start">
        <div v-if="dataReturn.itens_order.has_data" class="text-left col-auto mr-4">
          <label class="chart-title">{{ $t('items-order') }}</label>
          <span class="chart-value-big d-block color-blue-500 text-lowercase">
            {{ $_formatDecimal(dataReturn.itens_order.data.value) }}
            {{ $tc('product.title', dataReturn.itens_order.data.value) }}
          </span>
        </div>
        <div v-if="dataReturn.aov.has_data" class="text-left col-auto mr-4">
          <label class="chart-title">{{ $t('aov-ticket') }}</label>
          <span class="chart-value-big d-block color-blue-500">{{ formatCurrency(dataReturn.aov.data.value) }}</span>
        </div>
        <div v-if="dataReturn.product_revenue_share.has_data" class="text-left col-auto">
          <label class="chart-title">{{ $t('product-revenue-share') }}</label>
          <span class="chart-value-big d-block color-blue-500">
            {{ $_formatNumber(dataReturn.product_revenue_share.data.value, '0,0%') }}
            {{ $t('product.of-revenue') }}
          </span>
        </div>
      </div>
    </template>
    <product-order-analysis-skeleton v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import { getMetrics } from '@/services/product';
import bus from '@/helpers/events/bus';
import ProductOrderAnalysisSkeleton from '@/components/products/product/ProductOrderAnalysisSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  name: 'OrderAnalysis',
  components: {
    'product-order-analysis-skeleton': ProductOrderAnalysisSkeleton,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  props: {
    productID: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      dataReturn: {},
      selectedOptionId: '',
      select: [
        {
          name: this.$t('all-channels'),
          metric: '',
        },
        {
          name: this.$t('website-behavior'),
          metric: 'on',
        },
        {
          name: this.$tc('in-store'),
          metric: 'off',
        },
      ],
    };
  },
  computed: {
    selectedOption: {
      get() {
        return this.selectedOptionId;
      },
      set(metric) {
        this.selectedOptionId = metric;
        this.fetch_data(metric);
      },
    },
  },
  mounted() {
    this.fetch_data();

    // called after a global date change or brand change
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetch_data(metric) {
      this.$_reqConfig();
      getMetrics({
        metrics: ['itens_order', 'aov', 'product_revenue_share'],
        productID: this.productID,
        filters: `store_type:${metric}`,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          this.dataReturn = response.data.metrics;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  width: 160px;
}
.info-container {
  width: 720px;
}

.order-analysis {
  &.loadHeight {
    height: 130px;
  }
}
</style>
