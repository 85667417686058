export type ContentConditions = {
  store_type: boolean;
};

export type MatchCityState = 'False' | 'True';
export enum MatchCityStateOption {
  ENABLED = 'True',
  DISABLED = 'False',
}

type ContentMatchCityState = {
  value: string;
};

export type ContentPriority = {
  value: string;
};

type ClauseOptions = 'DESC' | 'ASC';

type OrderByStore = 'STORE_REVENUE' | 'STORE_ORDERS' | 'STORE_LAST_ORDER' | 'MATCH_CITY_STATE';
export type ContentOrderByStore = {
  column: OrderByStore;
  clause: ClauseOptions;
  priority: number;
};

type OrderBySeller = 'SELLER_REVENUE' | 'SELLER_ORDERS' | 'SELLER_LAST_ORDER';

export type ContentOrderBySeller = {
  column: OrderBySeller;
  clause: ClauseOptions;
  priority: number;
};

export type ContentRule = {
  conditions: ContentConditions[];
  match_city_state: ContentMatchCityState;
  orderby: {
    seller: ContentOrderBySeller[];
    store: ContentOrderByStore[];
  };
  priority: ContentPriority;
};

export type ItemRule = {
  name: string;
  createdAt: string;
  mainRule: boolean;
  enableOverload: boolean;
  id: number;
  slot: number;
  content: ContentRule;
};

type Ids = { store: number; seller: number };
export type StateDrag = {
  id: Ids;
  store: ContentOrderByStore[];
  seller: ContentOrderBySeller[];
  matchCityState: ContentMatchCityState;
};
