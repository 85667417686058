<template>
  <div class="card indicator">
    <div v-if="$_verifyLoaded('done') && metric">
      <div class="chart-title title">
        {{ $t(`360.sellers-analytics.indicators.${metric.key}`) }}
      </div>
      <div class="chart-value-big color-oto-brand-omni value amount">
        {{ metric.value === undefined ? '-' : metric.value }}
      </div>
    </div>
    <load-spinner v-if="$_verifyLoaded('loading')" :height="'45'" />
    <info-card
      v-show="$_verifyLoaded('info')"
      ref="message-error"
      :height="'45px'"
      :error="hasError"
      :show-msg="false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { nextTick } from 'vue';
import InfoCard from '@/components/_atoms/InfoCard';
import LoadSpinner from '@/components/_atoms/BaseLoaderSpinner';
import { verifyMixin } from '@/helpers/mixins';

export default {
  components: {
    LoadSpinner,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    metric: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('sellersAnalytics', ['getSellerMetricsError', 'getSellerMetricsLoading', 'getSellerMetricsEmpty']),
  },
  created() {
    nextTick(() => {
      this.loading = this.getSellerMetricsLoading;
      this.isEmpty = this.getSellerMetricsEmpty;
      this.hasError = this.getSellerMetricsError;
    });
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 6px;
  line-height: 24px;
}
.amount {
  text-align: center;
}
.indicator {
  min-height: 96px;
  align-items: center;
}
.message-error img {
  margin-top: 20%;
}
</style>
