<template>
  <div class="input-search">
    <div class="form-wrapper">
      <input
        v-model="term"
        type="text"
        class="input-text-search"
        :placeholder="$t('search.title')"
        @input="isTyping = true"
      />
      <i class="icon icon-search"></i>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import bus from '@/helpers/events/bus';

export default {
  name: 'SearchForm',
  props: {
    id: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      term: undefined,
      isTyping: false,
    };
  },
  watch: {
    term: _.debounce(function debounce() {
      this.isTyping = false;
    }, 500),
    isTyping: function isT(value) {
      if (!value && this.term !== undefined) {
        this.search(this.term);
      }
    },
  },
  methods: {
    search(term) {
      bus.$emit(`search-filter-${this.id}`, term);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-search {
  position: relative;
  width: 180px;
  align-self: flex-end;
  padding-bottom: 18px;
  z-index: 20;

  .form-wrapper {
    position: relative;
    z-index: 1;

    input {
      width: 100%;
      height: 44px;
      background-color: transparent;
    }

    .icon-search {
      color: $gray-500;
      position: absolute;
      top: 6px;
      right: 8px;
    }
  }
}
</style>
