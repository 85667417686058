<template>
  <el-dialog v-model="isVisible" id="personas-save-modal" append-to-body>
    <div class="content">
      <h2>{{ $t('labels-personas.save-changes') }}</h2>
      <div class="button-container">
        <button class="btn btn-grey" @click="closeModal">
          {{ $t('labels-personas.cancel') }}
        </button>
        <button class="btn" @click="savePersona">
          {{ $t('labels-personas.save') }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers, services
import { isNil } from 'lodash';
import bus from '@/helpers/events/bus';
import { updatePersona } from '@/services/personas';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'SavePersonaModal',
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    ...mapState('personas', ['personaDetail']),
    ...mapGetters('personas', ['getPersonaDetailUnsavedCompose']),
  },
  mounted() {
    bus.$on('openSavePersonaModal', () => {
      this.openModal();
    });
  },
  methods: {
    ...mapActions('personas', ['createPersona']),
    savePersona() {
      if (!isNil(this.personaDetail?.personaId)) {
        updatePersona(this.getPersonaDetailUnsavedCompose);
      } else {
        this.createPersona();
      }
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: -25px 50px 0;
  h2 {
    margin-bottom: 17px;
  }
  .button-container {
    margin-top: 17px;
    display: flex;
    button {
      margin: auto;
      width: 130px;
      height: 40px;
      &.btn-grey {
        background: grey;
        &:hover {
          background: #585858;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#personas-save-modal {
  max-width: 440px;
}
</style>
