<template>
  <div class="card stores-orders">
    <div v-if="$_verifyLoaded('done') && ready" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-money"></i>
        <span>{{ $t('orders') }}</span>
      </h2>
      <div class="row chart-group">
        <div class="col-5">
          <label class="chart-title">{{ $t('orders') }}</label>
          <span class="chart-value-big color-blue-500 d-block">
            {{ $_formatDecimal(metrics.orders.data.value.amount) }}
          </span>
        </div>
        <div class="col-7">
          <label class="chart-title">{{ $t('avg-basket-size') }}</label>
          <span class="chart-value-big color-blue-500 d-block">
            {{ $_formatDecimal(metrics.itens_order.data.value.amount, 2) }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="chart-group">
            <label class="chart-title">{{ $t('identified') }}</label>
            <span class="chart-value-big color-blue-500 d-block">
              {{ $_formatNumber(metrics.orders_identified_rate.data.value.amount, '0,0.00%') }}
            </span>
          </div>
          <div class="chart-group">
            <label class="chart-title valid-identified">{{ $t('valid-identified') }}</label>
            <span class="chart-value-big color-blue-500 d-block">
              {{ $_formatNumber(metrics.orders_identified_valid_rate.data.value.amount, '0,0.00%') }}
            </span>
          </div>
        </div>
        <div class="col chart-group">
          <label class="chart-title text-center d-block">{{ $t('repeat-clients') }}</label>
          <div id="solid-gauge-stores-orders" class="w-100" style="height: 100px"></div>
        </div>
      </div>
      <div class="stores-orders__expand">
        <p @click="openLightbox('lightbox-orders')">{{ $t('show-details') }}</p>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';

import getMetrics from '@/services/getmetrics';

import bus from '@/helpers/events/bus';
import { openLightbox } from '@/helpers/lightbox';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { getNumeral } from '@/utilities/numbers';

import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

export default {
  name: 'StoresOrders',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      metrics: {},
      ready: false,
      selectedStore: '',
      recurring: 0,
    };
  },
  mounted() {
    bus.$on('store-changed', value => {
      this.selectedStore = value;
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('store-changed');
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        endpoint: 'stores/get_metrics',
        metrics: [
          'orders',
          'itens_order',
          'orders_identified_rate',
          'orders_identified_valid_rate',
          'customers_recurring',
          'customers',
        ],
        filters: `store_id:${this.selectedStore}`,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          this.metrics = response.data.metrics;
          this.ready = true;
          this.loading = false;
          this.$nextTick(() => {
            this.recurring =
              getNumeral(
                (
                  (this.metrics.customers_recurring.data.value.amount / this.metrics.customers.data.value.amount) *
                  100
                ).toFixed(0),
              ) || 0;
            this.SetupeSolidGauge();
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    SetupeSolidGauge() {
      Highcharts.chart('solid-gauge-stores-orders', {
        chart: {
          type: 'solidgauge',
          backgroundColor: 'transparent',
          margin: 0,
        },
        exporting: {
          enabled: false,
        },
        title: null,
        legend: {
          enabled: false,
        },
        pane: {
          center: ['50%', '75%'],
          size: '150%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
          },
        },
        tooltip: {
          enabled: false,
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          minorTickInterval: null,
          tickWidth: 0,
          title: {
            enabled: false,
          },
          labels: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 15,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
        series: [
          {
            name: '',
            data: [this.recurring],
            dataLabels: {
              format: `<div style="font-family: 'Montserrat', sans-serif;">
                      <h2>{y}%</h2></div>`,
            },
          },
        ],
        colors: ['#6c90f4'],
      });
    },
    openLightbox(id) {
      const storeSelected = this.$parent.stores.find(store => store.id.toString() === this.selectedStore.toString());
      openLightbox(id, {
        storeSelected,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.stores-orders {
  .valid-identified {
    line-height: 16px;
  }

  .stores-orders__expand {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 0 25px;
    border-top: 1px solid $gray-500;

    p {
      margin: 10px 0;
      color: $blue-500;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
