<template>
  <div id="minibar" :style="selfWidth ? `width: ${item.percentageamount}%;` : ''">
    <div
      v-if="item.typeclient"
      class="percentage"
      :class="[
        'percentage-' + (selfWidth ? 100 : item.percentageamount) + ' colorBar-' + item.typeclient,
        { 'row-reverse': isRowReverse },
      ]"
    ></div>
    <div
      v-else
      class="percentage"
      :class="[
        'percentage-' + (selfWidth ? 100 : item.percentageamount) + ' colorbase-' + item.colorbase,
        { 'row-reverse': isRowReverse },
      ]"
    ></div>
    <div v-if="item.tooltip" class="bar-tooltip" v-html="item.tooltip"></div>
  </div>
</template>

<script>
export default {
  name: 'MiniBar',
  props: {
    propData: {
      type: [Array, Object],
      default: null,
    },
    item: {
      type: Object,
      default: () => {},
    },
    isRowReverse: {
      type: Boolean,
      default: null,
    },
    selfWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#minibar {
  width: 30%;
}

.lineBar {
  width: 100%;
  height: 10px;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    .bar-tooltip {
      display: block;
    }
  }
}

.bar-tooltip {
  display: none;
  position: absolute;
  bottom: 18px;
  right: 0;
  background-color: $color-white;
  font-size: 12px;
  color: $gray-800;
  line-height: 20px;
  padding: 12px 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  &:before {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translate(-50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $color-white;
    bottom: -8px;
  }
  strong {
    font-weight: 600;
  }
}

.percentage {
  height: 8px;

  &.row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  &:after {
    content: '';
    display: block;
    background-color: #6c90f4;
    width: 50px;
    margin-bottom: 10px;
    height: 100%;
    position: relative;
    top: 0;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    &:after {
      background-color: #aaa;
    }
  }
}

@for $i from 0 through 100 {
  .percentage-#{$i} {
    &:after {
      $value: ($i * 1%);
      width: $value;
      transition: width 500ms cubic-bezier(0.25, 0.1, 0.25, 0.1);
    }
  }
  .colorBar-on {
    &:after {
      background-color: $oto-ecommerce;
    }
  }
  .colorBar-off {
    &:after {
      background-color: $purple-200;
    }
  }
  .colorBar-omni {
    &:after {
      background-color: $oto-omni;
    }
  }
  .colorbase-orange {
    &:after {
      background-color: $color-orange;
    }
  }
  .colorbase-red {
    &:after {
      background-color: $color-red;
    }
  }
  .colorbase-green {
    &:after {
      background-color: $oto-ecommerce;
    }
  }
  .colorbase-darkblue {
    &:after {
      background-color: #1569c2;
    }
  }
  .colorbase-purple {
    &:after {
      background-color: $purple-400;
    }
  }
}
</style>
