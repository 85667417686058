import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

export const getGlossary = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const lang = store.getters.user.language || 'pt-BR';

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/glossary/getGlossary',
    params: {
      brand: brand?.id,
      lang: lang === 'en' ? 'pt-BR' : lang,
    },
  });
};
