<template>
  <div>
    <div class="row">
      <div class="col-3">
        <skeleton-loader class="d-block" height="12px" width="80px"></skeleton-loader>
        <skeleton-loader class="d-block mt-2" height="12px" width="60px"></skeleton-loader>
      </div>
      <div class="col-3">
        <skeleton-loader class="d-block" height="12px" width="80px"></skeleton-loader>
        <skeleton-loader class="d-block mt-2" height="12px" width="60px"></skeleton-loader>
      </div>
      <div class="col-3">
        <skeleton-loader class="d-block" height="12px" width="80px"></skeleton-loader>
        <skeleton-loader class="d-block mt-2" height="12px" width="60px"></skeleton-loader>
      </div>
      <div class="col-3">
        <skeleton-loader class="d-block" height="12px" width="80px"></skeleton-loader>
        <skeleton-loader class="d-block mt-2" height="12px" width="60px"></skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
