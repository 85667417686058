<template>
  <base-card-v2 :id="id" :default-class="false">
    <base-card-header :title="personalData.name || $t('360.person.empty.data')" icon="icon-customer" />
    <div class="row">
      <div class="col-6 pr-3">
        <ul>
          <li>
            <i class="icon icon-money"></i>
            <span v-if="personalData.averageOrderValue">
              {{ $t('360.avg-ticket') }}
              <b>{{ formatCurrency(personalData.averageOrderValue) }}</b>
            </span>
            <span v-else class="empty">{{ $t('360.person.empty.data') }}</span>
          </li>
          <li>
            <i class="icon icon-label-18px"></i>
            <div v-if="personalData.segmentId" class="d-flex align-items-center">
              <span class="pr-2">{{ $t('360.segment') }}</span>
              <segment-bubble :segment-i-d="personalData.segmentId" />
            </div>
            <span v-else class="empty">{{ $t('360.person.empty.segment') }}</span>
          </li>
          <li>
            <i class="icon icon-box"></i>
            <span v-if="personalData.productsPerOrder">
              {{ $t('360.buys') }}
              <b>{{ $_formatDecimal(personalData.productsPerOrder) }}</b>
              {{ $t('360.products-on-avg') }}
            </span>
            <span v-else class="empty">{{ $t('360.person.empty.data') }}</span>
          </li>
          <li>
            <i class="icon icon-in-store"></i>
            <span v-if="personalData.preferredStore">
              {{ $tc('360.store', 1) }} <b>{{ personalData.preferredStore }}</b>
            </span>
            <span v-else class="empty">{{ $t('360.person.empty.data') }}</span>
          </li>
          <li>
            <i class="icon icon-in-store"></i>
            <span v-if="personalData.preferredSeller && personalData.preferredSeller.name">
              {{ $t('360.salesman') }}
              <b>{{ personalData.preferredSeller.name }}</b>
            </span>
            <span v-else class="empty">{{ $t('360.person.empty.data') }}</span>
          </li>
        </ul>
      </div>
      <div class="col-6 pl-3">
        <ul>
          <li>
            <i class="icon icon-calendar-18px"></i>
            <span v-if="createDate"
              >{{ $t('360.customer-since') }} <b>{{ createDate }}</b></span
            >
            <span v-else class="empty">{{ $t('360.person.empty.data') }}</span>
          </li>
          <li>
            <i class="icon icon-birthday-18px"></i>
            <div v-if="personalData.age">{{ personalData.age }} {{ $tc('360.year', personalData.age) }}</div>
            <span v-else class="empty">{{ $t('360.person.empty.birth') }}</span>
          </li>
          <li>
            <i class="icon icon-map-marker-18px"></i>
            <span v-if="personalData.address && personalData.address.city && personalData.address.state"
              >{{ personalData.address.city }}, {{ personalData.address.state }}</span
            >
            <span v-else class="empty">{{ $t('360.person.empty.address') }}</span>
          </li>
          <li>
            <i class="icon icon-phone-18px"></i>
            <a v-if="hasPhones" href="#" class="see-phones" @click="openPhoneLightbox">
              {{ $t('360.person.see-phones') }}
            </a>
            <span v-else class="empty">{{ $t('360.person.empty.phone') }}</span>
          </li>
          <li>
            <i class="icon icon-email-18px"></i>
            <!-- used to copy email -->
            <input v-if="email" id="customer-email" v-model="email" type="text" name="email" readonly />
            <!-- show truncated email -->
            <span
              v-if="email"
              v-tooltip="{
                theme: 'tooltip-blue',
                content: $t('360.person.copy-email'),
                distance: 8,
                placement: 'bottom',
              }"
              class="text-email"
              data-name="customer-email"
              @click.prevent="copy"
            >
              {{ email }}
            </span>
            <span v-else class="empty">{{ $t('360.person.empty.email') }}</span>
          </li>
        </ul>
      </div>
    </div>
  </base-card-v2>
</template>

<script>
import moment from 'moment';
import { numberMixin } from '@/helpers/mixins';
import SegmentBubble from '@/components/_atoms/SegmentBubble';
import { openLightbox } from '@/helpers/lightbox';
// helpers
import { dateCompleteVariation } from '@/utilities/constants';

export default {
  name: 'CustomerViewPersonalData',
  components: {
    SegmentBubble,
  },
  mixins: [numberMixin],
  props: {
    personalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: 'personal-data',
    };
  },
  computed: {
    createDate() {
      if (moment(this.personalData.sourceCreatedDate).isValid()) {
        return moment(this.personalData.sourceCreatedDate).format(dateCompleteVariation);
      }
      return false;
    },
    hasPhones() {
      return (
        this.personalData &&
        this.personalData.contacts &&
        this.personalData.contacts.phone.value &&
        this.personalData.contacts.sms.value &&
        this.personalData.contacts.whatsapp.value
      );
    },
    email() {
      return this.personalData.contacts.email.value;
    },
  },
  methods: {
    copy(event) {
      const { name } = event.target.dataset;

      if (name) {
        const text = document.getElementById(name);

        text.select();
        document.execCommand('copy');
      }
    },
    openPhoneLightbox() {
      openLightbox('lightbox-phones', { contacts: this.personalData.contacts });
    },
  },
};
</script>
<style lang="scss" scoped>
.card .card-title {
  margin-bottom: 30px;
}
#customer-email {
  position: absolute;
  top: -999px;
  text-indent: -9999px;
  background: transparent;
  border: 0;
}
.text-email {
  cursor: pointer;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
