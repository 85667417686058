<template>
  <div id="comparison-stores" class="form-group mt-3">
    <label>{{ $t('360.sellers-analytics.comparable.stores') }}</label>
    <VTooltip :shown="showAlertTooltip" :triggers="[]" placement="right" theme="error">
      <div id="selectStores" class="select-fake stores no-click" @click.prevent="toggleDropdown('storesOpened')">
        {{ getCriteria === 'cluster' ? clustersSelected : regionsSelected }}
      </div>
      <template #popper>
        <div class="comparison-stores-tooltip">
          {{ $t('comparison-stores-error') }}
        </div>
      </template>
    </VTooltip>
    <div v-if="getCriteria === 'region'" v-show="storesOpened" class="check-list dropdown-menu no-click">
      <div class="label text-uppercase mb-1 no-click">
        {{ $t('stores-filter-regions') }}
      </div>
      <div v-for="(value, key) in comparisonStoresList" :key="key" class="select no-click" :class="`select-${key}`">
        <label class="no-click" @click.prevent="toggleRegion(key)">
          <check-box class="no-click" :checked="isSelected(key, selectedRegions)" />
          <div class="text no-click">
            <span class="name no-click">{{ key }}</span>
          </div>
          <i class="icon icon-right-14px no-click"></i>
        </label>
        <div class="check-list-child no-click">
          <div class="list no-click">
            <div class="label text-uppercase mb-1 no-click">
              {{ $t('stores-filter-states') }}
            </div>
            <div class="searchStore no-click">
              <input
                v-model="term"
                type="text"
                class="no-click"
                :placeholder="$t('stores.stores-search')"
                autocomplete="none"
              />
              <i class="icon icon-search no-click"></i>
            </div>
            <div
              v-for="(stores, index) in value"
              v-show="filterResults(stores)"
              :key="index"
              class="select no-click"
              :class="`select-${index}`"
            >
              <label class="no-click" @click.prevent="toggleState(key, index)">
                <check-box class="no-click" :checked="isSelected(index, selectedStates)" />
                <div class="text state no-click">
                  <span class="name no-click">{{ index }}</span>
                </div>
              </label>
              <div class="check-list-subchild no-click">
                <div v-for="store in stores" :key="store.id" class="select no-click">
                  <label
                    v-show="filterResults(store)"
                    class="no-click"
                    @click.prevent="toggleStore(key, index, store.id)"
                  >
                    <check-box class="no-click" :checked="isSelected(store.id, filters.comparisonStores)" />
                    <div class="text no-click">
                      <span class="name no-click">{{ $tc('store', 1) }} {{ store.name }}</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else v-show="storesOpened" class="check-list dropdown-menu no-click">
      <div class="label text-uppercase mb-1 no-click">
        {{ $t('stores-filter-regions') }}
      </div>
      <div v-for="(stores, key) in orderbyClusterList" :key="key" class="select no-click" :class="`select-${key}`">
        <label class="no-click" @click.prevent="toggleCluster(key)">
          <check-box class="no-click" :checked="isSelected(key, selectedClusters)" />
          <div class="text no-click">
            <span class="name no-click">{{ key }}</span>
          </div>
          <i class="icon icon-right-14px no-click"></i>
        </label>
        <div class="check-list-child no-click">
          <div class="list no-click">
            <div class="label text-uppercase mb-1 no-click">
              {{ $t('stores-title') }}
            </div>
            <div class="searchStore no-click">
              <input
                v-model="term"
                type="text"
                class="no-click"
                :placeholder="$t('stores.stores-search')"
                autocomplete="none"
              />
              <i class="icon icon-search no-click"></i>
            </div>
            <div
              v-for="store in stores"
              v-show="filterResults(store)"
              :key="store.id"
              class="select no-click"
              :class="`select-${store.id}`"
            >
              <label class="no-click" @click.prevent="toggleStore(key, null, store.id)">
                <check-box class="no-click" :checked="isSelected(store.id, filters.comparisonStores)" />
                <div class="text state no-click">
                  <span class="name no-click">{{ $tc('store', 1) }} {{ store.store }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';

import CheckBox from '@/components/_atoms/CheckBox';
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'ComparisonStores',
  components: {
    'check-box': CheckBox,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    filters: {
      type: Object,
      required: true,
    },
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
    getCriteria: {
      type: Object,
      required: true,
    },
    setFilters: {
      type: Function,
      default: () => {},
    },
    resetFilters: {
      type: Function,
      default: () => {},
    },
    showAlertTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clustersSelected: this.$t('select'),
      regionsSelected: this.$t('select'),
      criteriaSelected: true,
      selectedClusters: [],
      selectedRegions: [],
      selectedStates: [],
      storesOpened: false,
      term: '',
    };
  },
  computed: {
    comparisonStoresList() {
      const list = this.getComparisonStoresList();
      const regions = Object.keys(this.getComparisonStoresList());
      regions.forEach(region => {
        const states = Object.keys(list[region]);
        // ordena lojas de A-Z
        states.forEach(state => {
          list[region][state].sort((a, b) => (a.name > b.name ? 1 : -1));
        });
      });
      return list;
    },
    orderbyClusterList() {
      return this.groupByCluster();
    },
  },
  watch: {
    getCriteria() {
      this.resetComparableStores();
      this.resetFilters();
    },
  },
  mounted() {
    this.bodyClick();
    bus.$on('reset-comparable-stores', () => {
      this.resetComparableStores();
    });
  },
  beforeUnmount() {
    bus.$off('reset-comparable-stores');
  },
  updated() {
    this.$nextTick(() => {
      this.watchSelectedClusters();
      this.watchSelectedRegions();
    });
  },
  methods: {
    toggleDropdown(current) {
      this[current] = !this[current];
      if (!current === 'storesOpened') return;

      this.storesRegion = false;
      this.name ?? useMixpanelConfig(`view_oto_${this.name}_comparable-filters_stores-opened`);
    },

    isSelected(key, obj) {
      return !!obj?.includes(key);
    },

    toggleSelection(key, obj) {
      if (this.isSelected(key, obj)) this.unselectOption(key, obj);
      else this.selectOption(key, obj);
    },

    selectOption(key, obj) {
      if (!this.isSelected(key, obj)) obj.push(key);
      this.setFilters(this.filters);
    },

    unselectOption(key, obj) {
      if (!this.isSelected(key, obj)) return;

      const index = obj.indexOf(key);
      obj.splice(index, 1);
      this.setFilters(this.filters);
    },

    toggleStore(region, state, store) {
      if (!this.isSelected(store, this.filters.comparisonStores)) {
        this.selectOption(store, this.filters.comparisonStores);
        if (this.getCriteria !== 'cluster') {
          this.selectOption(state, this.selectedStates);
          this.selectOption(region, this.selectedRegions);
        } else {
          this.selectOption(region, this.selectedClusters);
        }
      } else {
        this.unselectOption(store, this.filters.comparisonStores);
        if (this.getCriteria !== 'cluster') {
          if (!this.storesByState(region, state)) this.unselectOption(state, this.selectedStates);
          if (!this.statesByRegion(region)) this.unselectOption(region, this.selectedRegions);
        } else if (!this.storesByCluster(region)) {
          this.unselectOption(region, this.selectedClusters);
        }
      }
    },

    toggleRegion(region) {
      this.toggleSelection(region, this.selectedRegions);
      const states = Object.keys(this.comparisonStoresList[region]);
      states.forEach(state => {
        if (this.isSelected(region, this.selectedRegions)) this.selectState(region, state);
        else this.unselectState(region, state);
      });
    },

    toggleCluster(cluster) {
      if (this.isSelected(cluster, this.selectedClusters)) {
        this.unselectCluster(cluster);
      } else {
        this.selectCluster(cluster);
      }
    },

    selectCluster(cluster) {
      this.toggleClusterStores(cluster, true);
      this.selectOption(cluster, this.selectedClusters);
    },

    unselectCluster(cluster) {
      this.toggleClusterStores(cluster, false);
      this.unselectOption(cluster, this.selectedClusters);
    },

    toggleClusterStores(cluster, select) {
      this.orderbyClusterList[cluster].forEach(store => {
        if (select) this.selectOption(store.id, this.filters.comparisonStores);
        else this.unselectOption(store.id, this.filters.comparisonStores);
      });
    },

    toggleState(region, state) {
      if (this.isSelected(state, this.selectedStates)) {
        this.unselectState(region, state);
        if (!this.statesByRegion(region)) this.unselectOption(region, this.selectedRegions);
      } else {
        this.selectState(region, state);
        this.selectOption(region, this.selectedRegions);
      }
    },

    selectState(region, state) {
      this.toggleStateStores(region, state, true);
      this.selectOption(state, this.selectedStates);
    },

    unselectState(region, state) {
      this.toggleStateStores(region, state, false);
      this.unselectOption(state, this.selectedStates);
    },

    toggleStateStores(region, state, select) {
      this.comparisonStoresList[region][state].forEach(store => {
        if (select) this.selectOption(store.id, this.filters.comparisonStores);
        else this.unselectOption(store.id, this.filters.comparisonStores);
      });
    },

    statesByRegion(region) {
      const states = Object.keys(this.comparisonStoresList[region]);
      return states.some(v => this.selectedStates.includes(v));
    },

    storesByState(region, state) {
      return this.comparisonStoresList[region][state].some(v => this.filters.comparisonStores.includes(v.id));
    },

    storesByCluster(region) {
      return this.orderbyClusterList[region].some(v => this.filters.comparisonStores.includes(v.id));
    },

    getComparisonStoresList() {
      const comparisonStoresList = {};

      this.$parent.stores.forEach(store => {
        if (store.region) {
          if (typeof comparisonStoresList[store.region] !== 'object') comparisonStoresList[store.region] = {};
          if (!Array.isArray(comparisonStoresList[store.region][store.state]))
            comparisonStoresList[store.region][store.state] = [];
          comparisonStoresList[store.region][store.state].push({
            id: store.id,
            name: store.store,
          });
        }
      });
      this.ready = true;
      return comparisonStoresList;
    },

    groupByCluster() {
      const groupBy = key => array =>
        array
          .filter(store => store.id !== this.filters.store)
          .reduce((objectsByKeyValue, obj) => {
            let value = this.$t('stores.select-others');
            if (obj[key]) {
              value = obj[key];
            }
            const clipboard = objectsByKeyValue;
            clipboard[value] = (clipboard[value] || []).concat(obj);
            return clipboard;
          }, {});
      const groupByCluster = groupBy('cluster');
      return groupByCluster(this.stores);
    },

    filterResults(obj) {
      if (obj.name) return obj.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      else if (obj.store) return obj.store.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      return obj.filter(store => store.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1).length;
    },

    watchSelectedClusters() {
      if (!this.selectedClusters.length) {
        this.clustersSelected = this.$t('select');
        return;
      }
      const sortedClusters = [...this.selectedClusters].sort();
      const listString = sortedClusters.toString().split(',').join(', ');
      this.clustersSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },

    watchSelectedRegions() {
      if (!this.selectedRegions.length) {
        this.regionsSelected = this.$t('select');
        return;
      }
      const listString = this.selectedRegions.toString().split(',').join(', ');
      this.regionsSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },

    resetComparableStores() {
      this.clustersSelected = this.$t('select');
      this.regionsSelected = this.$t('select');
      this.selectedClusters = [];
      this.selectedRegions = [];
      this.selectedStates = [];
    },

    bodyClick() {
      document.querySelector('body').addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.storesOpened = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
.dropdown-menu {
  display: block;
  .searchStore {
    margin: 5px auto 18px;
    position: relative;
    max-width: 236px;
    input {
      width: 100%;
    }
    .icon-search {
      position: absolute;
      color: $gray-500;
      right: 5px;
      top: 2px;
    }
  }
}
.check-list {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  background-color: $color-white;
  position: absolute;
  padding: 18px 15px;
  width: 223px;
  z-index: 98;
  top: 58px;
  border: 0;
  margin: 0;
  left: 0;
  &.hide {
    display: none;
  }
  .select {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    label {
      grid-template-columns: 16px 1fr;
      align-items: center;
      user-select: none;
      cursor: pointer;
      display: grid;
      margin: 0;
      &:hover {
        .check-box:not(.checked) {
          border-color: $gray-600;
        }
      }
      .text {
        margin-left: 10px;
        color: $gray-700;
        .name {
          line-height: 15px;
          font-weight: 400;
          font-size: 12px;
          display: block;
        }
      }
    }
  }

  > .select {
    &:hover {
      .check-list-child {
        visibility: visible;
      }
    }
    label {
      grid-template-columns: 16px 1fr 25px;
    }
    .icon-right-14px {
      font-size: 10px;
    }
  }
  .check-list-child {
    transition: all 0.25s cubic-bezier(0.1, 0.65, 0.5, 1);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    background-color: $color-white;
    transition-delay: 0.3s;
    position: absolute;
    visibility: hidden;
    max-height: 315px;
    overflow: hidden;
    display: block;
    padding: 10px;
    width: 285px;
    left: 200px;
    top: 0;
    .list {
      max-height: 295px;
      overflow-y: auto;
      padding: 5px;
      .state .name {
        font-weight: 600;
      }
    }
    .check-list-subchild {
      padding: 12px 14px 12px 25px;
    }
  }
}
.check-list,
.list {
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $gray-300;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }
}
.select-fake {
  padding-right: 25px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.comparison-stores-tooltip {
  max-width: 170px;
}
</style>
