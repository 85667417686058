<template>
  <el-dialog
    v-model="isVisible"
    append-to-body
    width="750"
    id="analytics-edit-columns"
    :class-name="filters.metrics.length || filters.dimensions.length ? 'active' : ''"
  >
    <div class="container">
      <h2 class="title">
        {{ $t('dashboard.edit-columns.title') }}
      </h2>
    </div>
    <div class="container">
      <div class="wrapper">
        <div class="box dimensions">
          <h4>{{ $tc('dashboard.edit-columns.dimension', 2) }}</h4>
          <div class="list b-dimensions">
            <div class="form-wrapper search-and-filter">
              <input v-model="terms.dimension" class="findTerm" placeholder="Buscar dimensões" />
              <i v-if="terms.dimension !== ''" class="icon icon-close" @click="clearTerm(1)"></i>
              <i v-else class="icon icon-search"></i>
            </div>
            <div class="listBox customScrollBar">
              <div v-for="(dimension, key) in getFilteredDimensions(terms.dimension)" :key="key" class="item">
                <label
                  :class="['label', { disabled: affected.includes(dimension.key) }]"
                  @click.prevent="
                    $_toggleSelection(dimension.key, filters.dimensions),
                      checkInputs($event),
                      resolveFilteredDimension()
                  "
                >
                  <check-box
                    class="checkbox"
                    :checked="
                      affected.includes(dimension.key) ? false : $_isSelected(dimension.key, filters.dimensions)
                    "
                    :blocked="affected.includes(dimension.key)"
                  />
                  <span v-html="getHighlightText(dimension.name, terms.dimension)" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="box metrics">
          <h4>{{ $tc('dashboard.edit-columns.metric', 2) }}</h4>
          <div class="list b-metrics">
            <div class="form-wrapper search-and-filter">
              <input v-model="terms.metric" class="findTerm" placeholder="Buscar métricas" />
              <i v-if="terms.metric !== ''" class="icon icon-close" @click="clearTerm"></i>
              <i v-else class="icon icon-search"></i>
            </div>
            <div class="listBox customScrollBar">
              <div v-for="(metric, key) in getFilteredMetrics(terms.metric)" :key="key" class="item">
                <label
                  :class="['label', { disabled: affected.includes(metric.key) }]"
                  @click.prevent="
                    $_toggleSelection(metric.key, filters.metrics), checkInputs($event), resolveFilteredMetric()
                  "
                >
                  <check-box
                    class="checkbox"
                    :checked="affected.includes(metric.key) ? false : $_isSelected(metric.key, filters.metrics)"
                    :blocked="affected.includes(metric.key)"
                  />
                  <span v-html="getHighlightText(metric.name, terms.metric)" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="selected-items">
          <transition name="fade">
            <div v-show="filters.dimensions.length" class="box selected-list dimensions">
              <h5>
                {{ $t('dashboard.edit-columns.selected-dimensions') }}
              </h5>
              <draggable @end="onDrop('dimensions')">
                <div v-for="dimension in filterDimensions" :key="dimension.key" class="selected">
                  <div :id="dimension.key" class="selected-title">
                    <i class="icon icon-hamburger"></i>
                    <div>{{ dimension.name }}</div>
                  </div>
                  <i class="icon icon-trash" @click.prevent="removeSelectedDimension(dimension.key)"></i>
                </div>
              </draggable>
            </div>
          </transition>
          <transition name="fade">
            <div v-show="filters.metrics.length" class="box selected-list ml-auto metrics">
              <h5>
                {{ $t('dashboard.edit-columns.selected-metrics') }}
              </h5>
              <draggable @end="onDrop('metrics')">
                <div v-for="metric in filterMetrics" :key="metric.key" class="selected">
                  <div :id="metric.key" class="selected-title">
                    <i class="icon icon-hamburger"></i>
                    <div>{{ metric.name }}</div>
                  </div>
                  <i class="icon icon-trash" @click.prevent="removeSelectedMetric(metric.key)"></i>
                </div>
              </draggable>
            </div>
          </transition>
        </div>
      </div>
      <div class="info-wrapper">
        <p v-show="error" class="error">
          {{ $t('dashboard.edit-columns.error') }}
        </p>
        <button class="btn" @click="submit">
          {{ $t('dashboard.edit-columns.update') }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { stringParser } from '@/helpers/string-parser.js';
import { escapeRegExp } from 'lodash'; // eslint-disable-line no-unused-vars
import { VueDraggableNext } from 'vue-draggable-next';
import { mapState, mapActions, mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
import { checkboxMixin, dashboardMixin } from '@/helpers/mixins';
import CheckBox from '@/components/_atoms/CheckBox';

const defaultfilters = {
  metrics: [],
  dimensions: [],
};

const groups = {
  account_date: [
    'users',
    'sessions',
    'pageviews',
    'conversions_ga',
    'products',
    'revenue_ga',
    'rpe_ga',
    'conversion_rate_ga',
    'aov_ga',
    'rps',
    'conversionrate_ga',
    'revenue_rate_email',
    'sessions_rate_email',
  ],
  campaign: [
    'campaign',
    'ext_campaign_id',
    'launch_date',
    'launch_id',
    'marketing_program',
    'marketing_strategy',
    'subject',
  ],
  email: ['open_gmail', 'open_hotmail'],
};

export default {
  name: 'DashboardEditColumnsModal',
  components: {
    CheckBox,
    draggable: VueDraggableNext,
  },
  mixins: [checkboxMixin, dashboardMixin],
  props: {
    page: {
      type: String,
      default: 'email',
    },
  },
  data() {
    return {
      affected: [],
      filters: { ...defaultfilters },
      error: false,
      terms: {
        dimension: '',
        metric: '',
      },
      isVisible: false,
    };
  },
  computed: {
    ...mapState('npsAnalytics', [
      'metrics',
      'dimensions',
      'checkedMetrics',
      'checkedDimensions',
      'filterDimensions',
      'filterMetrics',
    ]),
    ...mapGetters('npsAnalytics', ['getLastConfigMetrics', 'getFilteredDimensions', 'getFilteredMetrics']),
  },
  mounted() {
    bus.$on('open-nps-edit-columns', this.openModal);
    bus.$on('dash-clear-data-state', this.resetAll());

    // get metrics and dimensions from api
    this.updateMetrics(this.page);
  },
  beforeUnmount() {
    this.resetAll();
    bus.$off('open-analytics-edit-columns');
    bus.$off('dash-clear-data-state');
  },
  methods: {
    ...mapActions('npsAnalytics', [
      'updateMetrics',
      'updateCheckedMetrics',
      'updateCheckedDimensions',
      'deleteCheckedMetric',
      'deleteCheckedDimension',
      'updateLastCustomConfig',
      'updateFilterDimensions',
      'updateFilterMetrics',
      'resetAllStoreMetrics',
    ]),
    /**
     * @description validate input against group control
     * @param {array} checked all checked values
     * @param {array} group all values to put inside affected array
     */
    checkInputs(event) {
      if (event?.target?.className.includes('disabled')) return;
      this.resolveAffectedInputs();
    },
    /**
     * @description select affected items from group metrics and dimentions
     */
    resolveAffectedInputs() {
      this.affected = [];

      // merge bove arrays
      const checked = [...this.filters.metrics, ...this.filters.dimensions];
      const checkedMetrics = [...this.filters.metrics];
      const checkedDimensions = [...this.filters.dimensions];

      // send metrics and dimensions arrays to vuex
      this.updateCheckedMetrics(checkedMetrics);
      this.updateCheckedDimensions(checkedDimensions);

      if (this.filter(checked, groups.account_date)) this.affected = [...groups.campaign];
      if (this.filter(checked, groups.campaign)) this.affected = [...groups.account_date, ...groups.email];
      if (this.filter(checked, groups.email)) this.affected = [...groups.campaign];
    },
    /**
     * @description filter affectedd arrays
     * @param {array} checked all checked values
     * @param {array} group all values to put inside affected array
     */
    filter(checked, group) {
      return checked.filter(el => group.includes(el)).length;
    },
    /**
     * @description hide affected metrics and dimensions
     */
    hideAffected() {
      this.checksDimensionAffected();
      this.checksMetricsAffected();
    },
    /**
     * @description reorder filtered items to bove type of metrics after some change
     */
    reorderFilterItems() {
      this.updateFilterDimensions(this.mapOrder(this.filterDimensions, this.checkedDimensions, 'key'));
      this.updateFilterMetrics(this.mapOrder(this.filterMetrics, this.checkedMetrics, 'key'));
    },
    /**
     * @description checks if has some dimension affected and update then
     */
    checksDimensionAffected() {
      let filtered = [];
      filtered = this.checkForAffectedDimensions();
      this.updateFilterDimensions(filtered);
    },
    /**
     * @description checks if has some metric affected and update then
     */
    checksMetricsAffected() {
      let filtered = [];
      filtered = this.checkForAffectedMetrics();
      this.updateFilterMetrics(filtered);
    },
    /**
     * @description after a dimension be checked
     * updates filtered dimensions elements
     * hides the affected and reorder drag and drop items
     */
    resolveFilteredDimension() {
      let filtered = [];
      filtered = this.checkForAffectedDimensions();
      this.updateFilterDimensions(this.mapOrder(filtered, this.filters.dimensions, 'key'));

      this.hideAffected();

      this.reorderFilterItems();
    },
    /**
     * @description after a metric be checked
     * updates filtered dimensions elements
     * hides the affected and reorder drag and drop items
     */
    resolveFilteredMetric() {
      let filtered = [];
      filtered = this.checkForAffectedMetrics();
      this.updateFilterMetrics(this.mapOrder(filtered, this.filters.metrics, 'key'));

      this.hideAffected();

      this.reorderFilterItems();
    },
    /**
     * @description check if has affected metrics inside drag and drop area
     */
    checkForAffectedMetrics() {
      let cleanFromAffected = [];
      cleanFromAffected = this.removeAffected(this.checkedMetrics);
      return this.metrics.filter(el => cleanFromAffected.includes(el.key));
    },
    /**
     * @description check if has affected dimensions inside drag and drop area
     */
    checkForAffectedDimensions() {
      let cleanFromAffected = [];
      cleanFromAffected = this.removeAffected(this.checkedDimensions);
      return this.dimensions.filter(el => cleanFromAffected.includes(el.key));
    },
    /**
     * @description remove affected values from drag selected area
     * @affected affected group values
     */
    removeAffected(checked) {
      return checked.filter(el => !this.affected.includes(el));
    },
    /**
     * @description remove selected metrics from filtered values and vuex state
     * @key removed key
     */
    removeSelectedMetric(key) {
      const index = this.filters.metrics.indexOf(key);
      this.filters.metrics = this.filters.metrics.filter(el => el !== key);
      this.deleteCheckedMetric(index);
      this.checksMetricsAffected();
      this.resetAffected();
    },
    /**
     * @description remove selected dimensions from filtered values and vuex state
     * @key removed key
     */
    removeSelectedDimension(key) {
      const index = this.filters.dimensions.indexOf(key);
      this.filters.dimensions = this.filters.dimensions.filter(el => el !== key);
      this.deleteCheckedDimension(index);
      this.checksDimensionAffected();
      this.resetAffected();
    },
    /**
     * @description called after sort at custom metric/dimension list
     * @type get ids from metrics or dimensions
     */
    onDrop(type) {
      const newArr = this.getManualMetricOrder(type);
      if (type === 'metrics') {
        this.filters.metrics = newArr;
        this.updateCheckedMetrics(newArr);
        this.updateFilterMetrics(this.mapOrder(this.filterMetrics, this.checkedMetrics, 'key'));
      } else {
        this.filters.dimensions = newArr;
        this.updateCheckedDimensions(newArr);
        this.updateFilterDimensions(this.mapOrder(this.filterDimensions, this.checkedDimensions, 'key'));
      }
    },
    /**
     * @description get all values from manual filter selection
     * @type get ids from metrics or dimensions
     */
    getManualMetricOrder(type) {
      const keys =
        type === 'metrics'
          ? document.querySelectorAll('.selected-list.metrics .selected .selected-title')
          : document.querySelectorAll('.selected-list.dimensions .selected .selected-title');
      const attrs = [];
      keys.forEach(el => {
        attrs.push(el.getAttribute('id'));
      });
      return attrs;
    },
    /**
     * @description update bove type of metrics with correct order
     */
    refreshCheckedValues() {
      this.updateCheckedDimensions(this.filterDimensions.map(el => el.key));
      this.updateCheckedMetrics(this.filterMetrics.map(el => el.key));
    },
    /**
     * @description submit custom fields to update the table
     */
    submit() {
      if (this.hasEmptyMetrics()) {
        this.error = true;
        return;
      }

      // update checked metrics and dimentions with final selected values
      this.refreshCheckedValues();

      this.resetAll();
      this.hideModal();
      this.updateLastCustomConfig();
      bus.$emit('render-analytics-table');
    },
    /**
     * @description checks if has items to be select - if not trigger error
     */
    hasEmptyMetrics() {
      return !(this.filterDimensions?.length && this.filterMetrics?.length);
    },
    /**
     * @description apply check to all previous selected items - update vuex states
     */
    markAsChecked() {
      const { metrics, dimensions, filterMetrics, filterDimensions } = this.getLastConfigMetrics;

      if (!metrics && !dimensions) return;

      this.filters = {
        metrics,
        dimensions,
      };

      // update drop area with items from the last search
      this.updateFilterMetrics(filterMetrics);
      this.updateFilterDimensions(filterDimensions);

      // add affected inputs checked previouslly
      this.resolveAffectedInputs();
    },
    /**
     * @description reset all state values
     */
    resetAll() {
      this.error = false;
      this.affected = [];
      this.filters = { metrics: [], dimensions: [] };
    },
    /**
     * @description reset affected items if no metric is selected
     */
    resetAffected() {
      if (this.filters.metrics) this.affected = [];
    },
    /**
     * @description open table custom config modal
     */
    openModal() {
      this.resetAllStoreMetrics();

      // mark checked previous selected checkbox
      this.markAsChecked();

      this.isVisible = true;
    },
    /**
     * @description hide table custom config modal
     */
    hideModal() {
      this.resetAffected();
      this.isVisible = false;
    },
    /**
     * @method getHighlightText
     * @description get word and highlight term, escape special characters and accents and wrap highlight with strong tag before show on list
     * @param {String} text
     * @param {String} highlight
     * @return list item with hightlight term in bold
     */
    getHighlightText(text, highlight) {
      // remove accents from text and highlight word
      const parsedText = stringParser(text);
      const parsedHighlight = stringParser(highlight);
      // define pattern to search with escaped special characters
      const pattern = new RegExp(escapeRegExp(parsedHighlight), 'gi');
      // get first and last index from highlight
      const startIndex = pattern.exec(parsedText).index;
      const endIndex = startIndex + highlight.length;
      // extract highlight from original text and wrap with strong tag
      const slicedText = text.slice(startIndex, endIndex);
      const wrapHightlight = `<strong>${slicedText}</strong>`;
      // return original text with wrap highlight
      return text.replace(slicedText, wrapHightlight);
    },
    /**
     * @method clearTerm
     * @description clear input v-model based on argument; if '1' clear terms.dimension, otherwise clear terms.metric
     * @param {Number} target
     */
    clearTerm(target) {
      if (target === 1) {
        this.terms.dimension = '';
      } else {
        this.terms.metric = '';
      }
    },
  },
};
</script>

<style lang="scss">
.el-popup-parent--hidden {
  #analytics-edit-columns {
    top: 0;
    position: absolute;
    margin: 1.75rem auto;
    transition: height 0.5s ease-in-out;
    min-height: 500px;
    overflow: hidden;

    .el-dialog__body {
      padding: 0 50px 25px 50px;
      overflow: hidden;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      flex-flow: wrap;
      padding-top: 30px;
      overflow: hidden;
    }

    .box {
      width: 50%;

      h4 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: #555555;
      }

      h5 {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        color: #888;
      }
    }

    .metrics,
    .dimensions {
      .list {
        border: 1px solid #dddddd;
        box-shadow: inset 0px -3px 5px rgba(0, 0, 0, 0.07);
        height: 300px;
        overflow: hidden;
        padding: 20px 20px 0px;

        &.b-metrics {
          border-radius: 0 8px 8px 0;
        }

        &.b-dimensions {
          border-radius: 8px 0 0 8px;
          border-right: 0;
        }

        .search-and-filter {
          position: relative;
          .findTerm {
            width: 100%;
          }
          i {
            position: absolute;
            right: 7px;
            top: 4px;
            font-size: 26px;
            color: #bbb;
            cursor: pointer;
          }
        }

        .listBox {
          height: calc(300px - 34px - 20px - 25px);
          margin-top: 25px;
          overflow: auto;
        }

        .item {
          padding-bottom: 3px;

          label {
            align-items: center;
            color: #666666;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            font-size: 12px;
            font-weight: normal;

            .checkbox {
              margin-right: 10px;
            }
            &.disabled {
              pointer-events: none;
              opacity: 0.3;
            }
          }
        }
      }
    }

    .selected-items {
      width: 100%;
      display: flex;
      padding: 20px 0;

      .selected-list {
        padding: 0 20px;
        height: 200px;
        max-height: 200px;
        overflow: auto;

        .selected {
          padding: 5px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666666;
          font-size: 12px;

          .selected-title {
            display: flex;
            align-items: center;

            .icon {
              padding-right: 5px;
            }
          }
        }
        .icon {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .error {
        color: $color-red;
        margin: 0;
      }

      .btn {
        width: 180px;
        height: 40px;
        margin: 0 0 0 auto;
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }

  &.active {
    height: 780px;
  }
}
</style>
