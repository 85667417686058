<template>
  <div class="col-lg-12 card cashback-report-header">
    <div class="row">
      <div class="col-md-3 crh__title-wrapper">
        <i class="icon icon-money crh__icon" />

        <h1 class="crh__title">{{ $t('360.cashback-report.header.title') }}</h1>
      </div>

      <div class="col-md-2 crh__item">
        <label>{{ $t('360.cashback-report.header.label-segment') }}</label>
        <v-select
          v-model="segmentSelected"
          label="name"
          :clearable="false"
          class="w-100"
          :options="getSegments"
          :placeholder="$t('select')"
          @option:selected="selectSegmentId"
        >
          <template #option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #selected-option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $tc('_no-results') }}
          </template>
        </v-select>
      </div>

      <div class="col-md-3 crh__item">
        <template v-if="segmentSelected.id === 'store'">
          <label>{{ $t('360.cashback-report.header.label-store') }}</label>
          <v-select
            v-model="storeSelected"
            label="name"
            :clearable="false"
            class="w-100"
            :options="getStores"
            :filter-by="storesFilter"
            :placeholder="$tc('_select', 2)"
            @option:selected="selectStoreId"
          >
            <template #option="{ name, id }">
              <span v-if="id" :title="`${name} - ${id}`">{{ $tc(name) }} - {{ id }}</span>
              <span v-else>{{ $tc(name) }}</span>
            </template>
            <template #selected-option="{ name, id }">
              <span v-if="id">{{ $tc(name) }} - {{ id }}</span>
              <span v-else>{{ $tc(name) }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $tc('_no-results') }}
            </template>
          </v-select>
        </template>

        <template v-if="segmentSelected.id === 'seller'">
          <label>{{ $t('360.cashback-report.header.label-seller') }}</label>
          <v-select
            v-model="sellerSelected"
            label="fullName"
            :clearable="false"
            class="w-100"
            :options="getSellers"
            :filter-by="sellersFilter"
            :placeholder="$tc('_select', 2)"
            @option:selected="selectSellerId"
            @search="searchTerm"
          >
            <template #option="{ fullName, enrolment }">
              <span v-if="enrolment" :title="`${fullName} - ${enrolment}`">{{ fullName }} - {{ enrolment }}</span>
              <span v-else>{{ $tc('360.cashback-report.all') }}</span>
            </template>
            <template #selected-option="{ fullName, enrolment }">
              <span v-if="enrolment">{{ $tc(fullName) }} - {{ enrolment }}</span>
              <span v-else>{{ $tc(fullName) }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $tc('_no-results') }}
            </template>
          </v-select>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';

// helpers
import bus from '@/helpers/events/bus';

export default {
  name: 'CashbackReportHeader',
  components: {
    vSelect,
  },
  data() {
    return {
      segmentSelected: '',
      storeSelected: null,
      sellerSelected: null,
    };
  },
  computed: {
    ...mapGetters('cashbackReport', ['getSegments', 'getStores', 'getSellers']),
  },
  mounted() {
    this.setStores();
    this.setSellers();
  },
  methods: {
    ...mapActions('cashbackReport', ['setStores', 'setSellers', 'setSegmentId', 'setSellerId', 'setStoreId']),
    // Todo, waiting for final endpoint
    ...mapActions('indicators', ['setIndicatorsByTerm']),
    selectSegmentId(segment) {
      if (segment.id === 'general') {
        this.setStoreId('');
        this.setSellerId('');

        this.storeSelected = null;
        this.sellerSelected = null;

        bus.$emit('cashback-report-change-segment-general');
      }

      if (segment && segment.id) this.setSegmentId(segment.id);
    },

    selectSellerId(seller) {
      if (seller) this.setSellerId(seller.id);

      // reset storeId
      this.setStoreId(null);
      this.storeSelected = null;

      bus.$emit('cashback-report-change-seller');
    },
    selectStoreId(store) {
      if (store) this.setStoreId(store.id);

      // reset sellerId
      this.setSellerId(null);
      this.sellerSelected = null;

      bus.$emit('cashback-report-change-store');
    },

    /**
     * @description stores filter options with two labels
     */
    storesFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name.toLowerCase()?.includes(temp) || option?.id?.includes(temp);
    },
    /**
     * @description sellers filter options with two labels
     */
    sellersFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name?.toLowerCase()?.includes(temp) || option?.enrolment?.toLowerCase()?.includes(temp);
    },

    /**
     * @description search by term
     */
    searchTerm(term) {
      if (!term) return;
      this.triggerDebounce(term);
    },

    /**
     * @description set term on seller store
     */
    triggerDebounce: debounce(function (term) {
      this.setIndicatorsByTerm({ term });
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.cashback-report-header {
  background: url(~@/assets/img/figures/genericOtoBg.png) no-repeat right -50px center, $color-white;
  padding: 20px;

  .crh__title-wrapper {
    display: flex;
    align-items: center;
    margin-right: 45px;
  }

  .crh__title {
    margin: 2px 0 0 10px;
  }

  .crh__icon {
    font-size: 28px;
    color: $oto-omni;
  }
}
</style>
