<template>
  <div class="cashback__ranges">
    <div class="cashback__range-header">
      <h4 class="cashback__title">
        {{ $t('360.cashback.ranges.title') }}
      </h4>
      <p class="m-0 cashback__desc">
        {{ $t('360.cashback.ranges.description') }}
      </p>
    </div>

    <div :class="['cashback__range-block']">
      <!-- loop throught cashback ranges -->
      <cashback-range-line v-for="(range, index) in getCashbackRanges" :key="index" :range-data="range" :index="index">
        <template v-if="getLastRangeIndex === index + 1" v-slot:range-input-items>
          <div :class="['cri__add-range', { disabled: getEmptyPersonaRange || !getPermissionCreate }]">
            <p class="m-0" @click="addRange">( + ) {{ $t('360.cashback.ranges.add') }}</p>
          </div>
        </template>
      </cashback-range-line>

      <!-- last range line -->
      <template v-if="getCashbackFreezeRange.length">
        <cashback-range-last-line
          v-for="(fRange, i) in getCashbackFreezeRange"
          :key="`fIndex-${i}`"
          :range-data="getCashbackFreezeRange[0]"
          :index="i"
        />
      </template>

      <!-- if empty freeze range -->
      <div v-if="!getCashbackFreezeRange.length" class="cri__range-empty-row">
        <p class="m-0">
          {{ $t('360.cashback.ranges.fill-values') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import { mapActions, mapGetters } from 'vuex';
// components
import CashbackRangeLine from '@/components/oto/cashback/_atoms/CashbackRangeLine';
import CashbackRangeLastLine from '@/components/oto/cashback/_atoms/CashbackRangeLastLine';

export default {
  name: 'CashbackRanges',
  components: {
    CashbackRangeLine,
    CashbackRangeLastLine,
  },
  data() {
    return {
      loading: false,
      isPeriodOptionsActive: false,
    };
  },
  computed: {
    ...mapGetters('cashback', [
      'getCashbackRanges',
      'getCashbackFreezeRange',
      'getEmptyPersonaRange',
      'getPermissionCreate',
    ]),
    getLastRangeIndex() {
      return this.getCashbackRanges.length;
    },
  },
  methods: {
    ...mapActions('cashback', ['includeRangeBuilder']),
    addRange() {
      this.includeRangeBuilder();
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback__ranges {
  .cashback__range-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: column;

    .cashback__title {
      width: 140px;
      font-size: 12px;
      margin-bottom: 4px;
    }
    p.cashback__desc {
      font-size: 12px;
    }
  }

  .cashback__range-block {
    margin-top: 16px;

    .cri__add-range {
      &.disabled {
        cursor: not-allowed;
        color: $gray-400;
      }
    }
  }

  .cri__range-empty-row {
    border: 1px solid $gray-400;
    border-top: none;
    border-radius: 0 0 10px 10px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
