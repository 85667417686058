import { getIndicators } from '../services';
import { IndicatorsResponse, RemoteIndicatorsResponse, IdicatorsParams } from '../models';

export const fetchIndicators = ({
  campaignId,
  sellerId,
  storeId,
  cluster,
  originSale,
  contactType,
}: IdicatorsParams): Promise<IndicatorsResponse> =>
  getIndicators({ campaignId, sellerId, storeId, cluster, originSale, contactType }).then(response =>
    formatResponse(response?.data),
  );

const formatResponse = (response: RemoteIndicatorsResponse): IndicatorsResponse => {
  const remoteKpis = response?.kpis;
  const { revenue, realized, revenue_per_contact, convertion_rate, aov, average_window } = remoteKpis;

  return {
    errors: response?.errors,
    success: response?.success,
    kpis: {
      revenue: {
        name: revenue?.name,
        key: revenue?.key,
        tooltip: revenue?.tooltip,
        value: revenue?.value ?? '0',
        totalSales: revenue?.total_sales ?? 0,
      },
      realized: {
        name: realized?.name,
        key: realized?.key,
        tooltip: realized?.tooltip,
        value: realized?.value ?? '0',
      },
      revenuePerContact: {
        name: revenue_per_contact?.name,
        key: revenue_per_contact?.key,
        tooltip: revenue_per_contact?.tooltip,
        value: revenue_per_contact?.value ?? '0',
      },
      convertionRate: {
        name: convertion_rate?.name,
        key: convertion_rate?.key,
        tooltip: convertion_rate?.tooltip,
        value: convertion_rate?.value ?? '0',
      },
      aov: {
        name: aov?.name,
        key: aov?.key,
        tooltip: aov?.tooltip,
        value: aov?.value ?? '0',
      },
      averageWindow: {
        name: average_window?.name,
        key: average_window?.key,
        tooltip: average_window?.tooltip,
        value: average_window?.value ?? '0',
      },
    },
    graphics: {
      labels: response?.graphics?.labels,
      metrics: {
        revenue: response?.graphics?.metrics?.REVENUE,
        totalSales: response?.graphics?.metrics?.TOTAL_SALES,
      },
    },
  };
};
