<template>
  <div id="label-detail">
    <header-details
      :path="'labels'"
      :vuex-detail="labelDetail"
      :action-set-name="setName"
      edit-description-emitter="openEditDescriptionModal"
    />
    <div class="audience-composition">
      <div>
        <section-include :is-include="true" />
        <section-include :is-include="false" />
      </div>
    </div>
    <!-- footer -->
    <labels-footer />
    <!-- modals -->
    <dialog-error />
    <dialog-success />
    <edit-description-modal />
    <save-label-modal />
  </div>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
import { mapState, mapActions, mapGetters } from 'vuex';
// components - molecules
import HeaderDetails from '@/components/_molecules/HeaderDetails';
// components - organisms
import DialogError from '@/components/labels/_organisms/DialogError';
import DialogSuccess from '@/components/labels/_organisms/DialogSuccess';
import EditDescriptionModal from '@/components/labels/_organisms/EditDescriptionModal';
import SaveLabelModal from '@/components/labels/_organisms/SaveLabelModal';
import LabelsFooter from '@/components/labels/_organisms/LabelsFooter';
// components - templates
import SectionInclude from '@/components/labels/templates/SectionInclude';

export default {
  name: 'LabelDetail',
  components: {
    DialogError,
    DialogSuccess,
    HeaderDetails,
    SectionInclude,
    EditDescriptionModal,
    SaveLabelModal,
    LabelsFooter,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    ...mapState('labels', ['labelDetail']),
    ...mapGetters('labels', ['duplicateModeEnabled']),
  },
  mounted() {
    this.setMode(this.mode);

    if (this.$route.path.includes('/labels/create/')) {
      this.actionAddSerieFill('create');
    } else {
      this.actionAddSerieFill('edit');
    }
    this.updateVuex();
    bus.$on('update-selected-brand', () => {
      this.actionResetCheckAccount360();
      this.updateVuex();
    });
  },
  methods: {
    ...mapActions('labels', [
      'getLabel',
      'clearLabelDetail',
      'setMode',
      'actionCheckAccount360',
      'actionResetCheckAccount360',
      'actionSetName',
      'actionAddSerieFill',
      'addGroup',
    ]),
    updateVuex() {
      this.getLabel(this.id);
      if (this.mode === 'create') {
        this.addGroup();
      }
      this.actionCheckAccount360();
    },
    setName(e) {
      this.actionSetName(e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-include,
.groups-exclude {
  margin-top: 35px;
}
.group-list {
  margin-bottom: 20px;
}
.audience-include-exclude {
  margin-bottom: 40px;
}
.empty-query {
  text-align: center;
  padding-top: 50px;
  margin: 0 auto;
  .btn {
    padding: 0 40px;
  }
}
</style>
