<template>
  <el-dialog v-model="dataIsVisible" id="modal-dialog-question" append-to-body>
    <dialog-alert :message="message" />
    <div class="dialog">
      <div class="cancel btn btn-secondary" @click="closeModal">{{ $t('cancel') }}</div>
      <div
        class="confirm btn btn-danger"
        @click="
          () => {
            deleteTrigger(deleteAction);
            closeModal();
          }
        "
      >
        {{ $t('confirm') }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import DialogAlert from '@/components/_atoms/DialogAlert';

export default {
  name: 'ModalDialogQuestion',
  components: {
    DialogAlert,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    deleteAction: {
      type: Function,
      default: () => {},
    },
    showAction: {
      type: Function,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    hideAction: {
      type: Function,
      default: () => {},
    },
    message: {
      type: String,
      default: 'audiences.do-you-want-delete',
    },
  },
  data() {
    return {
      dataIsVisible: false,
    };
  },
  watch: {
    isVisible: function () {
      if (this.isVisible) {
        return this.openModal();
      }
      return this.closeModal();
    },
    dataIsVisible: function () {
      if (!this.dataIsVisible) this.$emit('closeDialogModal', this.dataIsVisible);
    },
  },
  methods: {
    deleteTrigger() {
      this.deleteAction();
    },

    openModal() {
      this.dataIsVisible = true;
    },
    closeModal() {
      this.dataIsVisible = false;
      this.$emit('closeDialogModal', this.dataIsVisible);
    },
  },
};
</script>

<style lang="scss">
#modal-dialog-question {
  width: 500px;

  .el-dialog__header {
    padding: 10px;
  }

  .el-dialog__body {
    padding: 25px 0;
  }

  .dialog {
    display: flex;
    justify-content: center;
    margin: 28px 0 20px 0;

    .btn {
      width: 170px;
      border-radius: 80px;
      margin: 0 10px;
    }
  }
}
</style>
