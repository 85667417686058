<template>
  <div
    :id="id"
    :ref="id"
    class="lightbox-template"
    tabindex="0"
    role="dialog"
    :class="settings"
    @keydown.esc="closeLightbox"
    @click="clickCallback"
  >
    <div class="container">
      <lightbox-template-header
        :title="title"
        :icon="icon"
        :show-segment-group="showSegmentGroup"
        :subtracted-date="subtractedDate"
        :show-date="showDate"
      />
      <div class="lightbox-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import LightboxTemplateHeader from '@/components/lightbox/LightboxTemplateHeader';
import { closeLightbox } from '@/helpers/lightbox';
import bus from '@/helpers/events/bus';

export default {
  name: 'Lightbox',
  components: {
    LightboxTemplateHeader,
  },
  props: {
    fade: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Título',
    },
    showSegmentGroup: {
      type: Boolean,
      default: true,
    },
    subtractedDate: {
      type: String,
      default: '',
      required: false,
    },
    icon: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    showDate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      closing: false,
    };
  },
  computed: {
    settings() {
      return {
        hasFade: this.fade,
        closing: this.closing,
      };
    },
  },
  mounted() {
    this.$refs[this.id].focus();
  },
  methods: {
    closeLightbox() {
      this.closing = true;

      if (this.settings.hasFade) {
        setTimeout(closeLightbox, 150);
      } else closeLightbox();

      const { selectedSegment } = this.$store.getters;
      const { segmentSelected, segmentGroupSelected } = this.$parent;
      if (segmentSelected && segmentSelected !== selectedSegment.segmentId) {
        // TODO: Segments IDs are unique. Therefore a single method should be responsible
        // for propagating both segment and segmentGroup IDs.
        if (segmentGroupSelected && segmentGroupSelected !== selectedSegment.segmentGroupId) {
          bus.$emit('refresh-segment-group', segmentSelected, segmentGroupSelected);
        } else {
          bus.$emit('refresh-segment', segmentSelected);
        }
      }
    },
    clickCallback(event) {
      if (event.target.classList[0] === 'lightbox-template') this.closeLightbox();
    },
  },
};
</script>

<style lang="scss" scoped>
.lightbox-template {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 50px 0;
  overflow-x: auto;
  outline: none;

  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($gray-900, 0.85);
    z-index: -1;
  }

  &.hasFade:not(.closing) {
    animation: fadeIn 0.15s;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .container {
      animation: slideIn 0.15s;

      @keyframes slideIn {
        from {
          margin-top: -100px;
        }
        to {
          margin-top: 0;
        }
      }
    }
  }

  &.closing {
    &.hasFade {
      opacity: 0;
      animation: fadeOut 0.15s;

      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      .container {
        animation: slideOut 0.15s;
        margin-top: -100px;

        @keyframes slideOut {
          from {
            margin-top: 0;
          }
          to {
            margin-top: -100px;
          }
        }
      }
    }
  }

  > .container {
    margin: auto;
    background-color: white;
    padding: 0;
    max-width: 990px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 25px 60px 0 rgba(0, 0, 0, 0.07), 0 0 20px 0 rgba(0, 0, 0, 0.3);

    .lightbox-body {
      padding: 33px 0;

      :slotted(.container) {
        padding: 0 50px;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.lightbox-template {
  .table-list {
    .table-list-body {
      border-radius: 0;
      box-shadow: none;

      .table-list-row {
        &.active,
        &:hover {
          .card {
            background-color: $blue-100;

            .icon-filter {
              visibility: visible;
            }
          }
        }

        .card {
          padding: 0 6px;
        }

        .icon-filter {
          visibility: hidden;
          color: $gray-700;
          font-size: 14px;
          position: absolute;
          left: 30px;
          top: 20%;
        }

        .table-list-column {
          position: relative;

          &:first-child {
            font-weight: 600;
          }
        }
      }
    }
    .table-list-header {
      padding-left: 0;
      padding-right: 0;
    }
    .table-list-body,
    .table-list-header {
      .table-list-column {
        &:first-child {
          padding-left: 50px;
          @media screen and (min-width: 1200px) {
            min-width: 200px;
          }
        }

        &:last-child {
          padding-right: 50px;
        }
      }
    }
  }

  .highcharts-root {
    font-family: $font-family !important;
  }
}
</style>
