<template>
  <div class="row">
    <div class="col-12">
      <skeleton-loader class="mt-4 d-block" height="25px" width="300px"></skeleton-loader>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
