<template>
  <table-list :data="dataTable" class="loadHeight" type="filled">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column><modal-dates /></table-list-column>
        <table-list-column class="text-right">0-60 {{ $tc('day', 0) }}</table-list-column>
        <table-list-column class="text-right">61-120 {{ $tc('day', 0) }}</table-list-column>
        <table-list-column class="text-right">121-180 {{ $tc('day', 0) }}</table-list-column>
        <table-list-column class="text-right">181-240 {{ $tc('day', 0) }}</table-list-column>
        <table-list-column class="text-right">241-300 {{ $tc('day', 0) }}</table-list-column>
        <table-list-column class="text-right">301-365 {{ $tc('day', 0) }}</table-list-column>
        <table-list-column class="text-right">{{ $t('medium-recency') }}</table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row
          v-for="(item, index) in dataTable.rows"
          :key="`lightbox-recency-${index}`"
          :class="{ active: item.segment_id == segmentSelected }"
          :click-callback="() => $_handleTableRow(item.segment_id, index)"
        >
          <table-list-column> <i class="icon icon-filter"></i>{{ item.name }} </table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['0-60'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['61-120'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['121-180'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['181-240'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['241-300'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['301-365'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right"
            >{{ $_formatDecimal(item.metrics ? item.metrics.recency : 0) }}
            {{ $tc('day', 2) }}
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" :empty="isEmpty" />
  </table-list>
</template>

<script>
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import getTable from '@/services/getTable';
import ModalDates from '@/components/ModalDates';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'RecencyTable',
  components: {
    BaseLoader,
    InfoCard,
    ModalDates,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataTable: {
        count: 0,
      },
      segmentSelected: '',
    };
  },
  mounted() {
    this.reset();
    this.fetchData();
  },
  methods: {
    fetchData() {
      getTable({
        metrics: ['arr_active_recency_dd', 'recency'],
        expand: 1,
        isLightbox: true,
      })
        .then(({ data: { data } }) => {
          if (!data) this.isEmpty = true;
          if (this.$_verifyData(data)) return;
          this.dataTable.rows = data.map(({ name, metrics, segment_id }) => ({
            name,
            metrics,
            segment_id,
          }));
          this.dataTable.count = this.dataTable.rows.length;
          this.metricNames = Object.keys(this.dataTable.rows);
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$_reqConfig();
      this.ready = false;
      this.dataTable.rows = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
