<template>
  <base-card-v2 class="client-profile">
    <base-card-header :title="$t('product.client-profile')" icon="icon-customer" />
    <div class="row mb-3">
      <div class="col-12">
        <label v-if="dataMetrics.aov && !loading" class="pb-2"
          >{{ $t('avg-order-value') }} / {{ $t('category-average') }}</label
        >
        <span v-if="dataMetrics.aov && !loading" class="chart-value-big d-block color-gray-500">
          <span v-if="dataMetrics.aov" class="color-blue-400">{{ formatCurrency(dataMetrics.aov.data.value) }}</span>
          <span v-else class="color-blue-400">-</span>
          /
          <span v-if="dataMetrics.aov_category" class="color-gray-500">
            {{ formatCurrency(dataMetrics.aov_category.data.value) }}</span
          >
          <span v-else class="color-gray-500">-</span>
        </span>
        <product-client-profile-skeleton v-else />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <label v-if="dataMetrics.recency && !loading" class="pb-2"
          >{{ $t('recency') }} / {{ $t('category-average') }}</label
        >
        <span v-if="dataMetrics.recency && !loading" class="chart-value-big d-block color-gray-500">
          <span v-if="dataMetrics.recency" class="color-blue-400">
            {{ $_formatDecimal(dataMetrics.recency.data.value) }}
            {{ $tc('day', dataMetrics.recency.data.value) }}
          </span>
          <span v-else class="color-blue-400">-</span>
          /
          <span v-if="dataMetrics.recency_category" class="color-gray-500">
            {{ $_formatDecimal(dataMetrics.recency_category.data.value) }}
            {{ $tc('day', dataMetrics.recency_category.data.value) }}</span
          >
          <span v-else class="color-gray-500">-</span>
        </span>
        <product-client-profile-skeleton v-else />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label v-if="dataMetrics.frequency && !loading" class="pb-2"
          >{{ $t('frequency') }} / {{ $t('category-average') }}</label
        >
        <span v-if="dataMetrics.frequency && !loading" class="chart-value-big d-block color-gray-500">
          <span v-if="dataMetrics.frequency" class="color-blue-400">{{
            $_formatDecimal(dataMetrics.frequency.data.value)
          }}</span>
          <span v-else class="color-blue-400">-</span>
          /
          <span v-if="dataMetrics.frequency_category" class="color-gray-500">{{
            $_formatDecimal(dataMetrics.frequency_category.data.value)
          }}</span>
          <span v-else class="color-gray-500">-</span>
        </span>
        <product-client-profile-skeleton v-else />
      </div>
    </div>
  </base-card-v2>
</template>

<script>
import { getMetrics } from '@/services/product';
import bus from '@/helpers/events/bus';
import ProductClientProfileSkeleton from '@/components/products/product/ProductClientProfileSkeleton';
import { numberMixin } from '@/helpers/mixins';

export default {
  name: 'ProductClientProfile',
  components: {
    ProductClientProfileSkeleton,
  },
  mixins: [numberMixin],
  props: {
    productID: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dataMetrics: {},
    };
  },
  mounted() {
    this.fetchData();

    // called after a global date change or brand change
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.loading = true;
      getMetrics({
        productID: this.productID,
        metrics: ['aov', 'aov_category', 'recency', 'recency_category', 'frequency', 'frequency_category'],
      })
        .then(({ data }) => {
          this.dataMetrics = data.metrics;
        })
        .catch(() => {
          this.dataMetrics = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.client-profile {
  min-height: 350px;
}
</style>
