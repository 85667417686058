<template>
  <div class="row">
    <div class="col-3 mr-3">
      <skeleton-loader class="d-block" height="17px"></skeleton-loader>
      <skeleton-loader class="d-block mt-2" height="17px" width="102px"></skeleton-loader>
    </div>
    <div class="col-2 mr-3">
      <skeleton-loader class="d-block" height="17px"></skeleton-loader>
      <skeleton-loader class="d-block mt-2" height="17px" width="62px"></skeleton-loader>
    </div>
    <div class="col-3">
      <skeleton-loader class="d-block" height="17px"></skeleton-loader>
      <skeleton-loader class="d-block mt-2" height="17px" width="102px"></skeleton-loader>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin-top: 16px;
}
</style>
