<template>
  <div class="glossary">
    <header class="glossary__header">
      <div class="glossary__header__filter">
        <div class="sort mr-3">
          <select v-model="order" @change="orderBy">
            <option value="a-z">A / Z</option>
            <option value="z-a">Z / A</option>
          </select>
        </div>
        <div class="glossary__header__search">
          <input id="search-term" v-model="searchTerm" placeholder="Search" @input="searchBy" />
          <a class="glossary__header__search--btn" @click.prevent="searchBy"><i class="icon icon-search"></i></a>
        </div>
      </div>
    </header>
    <div v-for="(letter, key) in letters" :key="key" class="glossary--letter">
      <h2 class="glossary__title">{{ letter }}</h2>
      <div class="glossary__items">
        <div v-for="(item, key) in glossary[letter]" :key="key" class="glossary--item card">
          <div class="card-body">
            <h3 class="card-title d-flex align-items-center">
              <span>{{ item.name }}</span>
            </h3>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <footer class="glossary__footer">
      <span class="glossary__footer__text">{{ $t('glossary.end') }}</span>
      <span class="glossary__footer__info"
        >{{ $t('glossary.send-mail') }} <a href="mailToOtoSupport">{{ link }}</a></span
      >
    </footer>
  </div>
</template>
<script>
// services
import { getGlossary } from '@/services/glossary';

// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
import { otoSupportEmail } from '@/utilities/constants';

export default {
  name: 'Glossary',
  data() {
    return {
      glossary: {},
      letters: [],
      order: 'a-z',
      searchTerm: undefined,
      link: otoSupportEmail,
      mailToOtoSupport: `mailto:${otoSupportEmail}`,
    };
  },
  mounted() {
    useMixpanelConfig('view_glossary');
    this.fetchData();
  },
  methods: {
    fetchData() {
      getGlossary().then(response => {
        const data = response?.data?.data;
        const allLetters = data?.map(item => item.char);
        this.letters = allLetters.filter((item, pos) => allLetters.indexOf(item) === pos);
        data?.forEach(item => {
          if (!Array.isArray(this.glossary[item.char])) this.glossary[item.char] = [];
          this.glossary[item.char].push({
            name: item.name,
            description: item.description,
          });
        });
      });
    },
    orderBy() {
      this.letters.reverse();
    },
    searchBy() {
      if (this.searchTerm === '')
        document.querySelectorAll('.glossary--letter, .card').forEach(el => {
          el.classList.remove('glossary--hide');
        });
      else {
        document.querySelectorAll('.glossary--letter, .card').forEach(el => {
          el.classList.add('glossary--hide');
        });
        document.querySelectorAll('.card').forEach(el => {
          if (el.textContent.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase())) {
            el.classList.remove('glossary--hide');
            el.parentNode.closest('.glossary--letter').classList.remove('glossary--hide');
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.glossary {
  &--hide {
    display: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__filter {
      display: flex;
      justify-content: flex-end;
      select {
        width: $size-80;
      }
    }

    &__search {
      position: relative;
      input {
        width: $size-146;
        padding-right: $size-36;
      }

      &--btn {
        position: absolute;
        right: $size-4;
        top: $size-2;
      }
    }
  }

  &__title {
    font-size: $size-30;
    line-height: $size-30;
    margin: $size-20 0;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $size-12;
  }

  &__footer {
    margin-top: $size-40;
    &__text,
    &__info {
      display: block;
      font-weight: 600;
      font-size: $size-14;
      text-align: center;
    }

    a {
      color: $gray-800;
    }
  }
}
</style>
