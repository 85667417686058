<template>
  <template v-for="items in menu" :key="items.id">
    <menu-user-item :item="items" :active="active" />
  </template>
</template>

<script>
import MenuUserItem from '@/components/menu/_atoms/MenuUserItem';

export default {
  name: 'SidebarUser',
  components: {
    MenuUserItem,
  },
  props: {
    menu: {
      type: Array,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
