<template>
  <el-dialog v-model="isVisible" :id="id" @close="closeModal" width="580" class="custom-fields-modal">
    <section v-show="!getIsLoading">
      <h2>{{ $t('settings.custom-fields.title') }}</h2>
      <div class="custom-fields-form">
        <!-- custom field  -->
        <div class="custom-fields-modal__field">
          <div class="vselect">
            <label>{{ $t('settings.custom-fields.labels.field') }}</label>
            <v-select
              :disabled="getIsEditMode"
              v-model="customField"
              label="name"
              :clearable="false"
              :options="customFieldOptions"
              :placeholder="$t('select')"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('no-results') }}
              </template>
            </v-select>
          </div>
          <div class="--checkbox" v-if="getActiveTable === 'EXPORT'">
            <label class="label --checkbox-label" @click.prevent="isAllowCSV">
              <check-box class="checkbox" :checked="getAllowCSV" />
              <p class="--checkbox-text">{{ $t('settings.custom-fields.checkbox.csv') }}</p>
              <span
                class="icon-wrapper"
                v-tooltip="{
                  content: $t('settings.custom-fields.tooltips.exportation'),
                  theme: 'default',
                  skidding: -32,
                  distance: 8,
                }"
              >
                <i class="icon icon-question-14px"></i>
              </span>
            </label>
          </div>
        </div>
        <!-- #end# - custom field -->
        <!-- name / type -->
        <div class="custom-fields-modal__segmentation">
          <h3>{{ $t('settings.custom-fields.title-h3') }}</h3>
          <div class="custom-fields-modal__segmentation--wrapper">
            <div class="custom-fields-modal__segmentation--name">
              <label class="custom-fields-modal__label">
                {{ $t('settings.custom-fields.labels.name') }}

                <span
                  v-if="!getIsEditable"
                  class="icon-wrapper"
                  v-tooltip="{
                    content: $t('settings.custom-fields.tooltips.audience-name'),
                    theme: 'default',
                    shown: getIsEditable,
                  }"
                >
                  <i class="icon icon-question-14px"></i>
                </span>
              </label>
              <input
                :disabled="!getIsEditable"
                v-model="name"
                type="text"
                :placeholder="$t('settings.custom-fields.placeholder.name')"
                v-tooltip.bottom="{
                  content: $t('settings.custom-fields.tooltips.error-name'),
                  theme: 'error',
                  shown: getTooltipNameError,
                }"
              />
            </div>
            <div class="vselect">
              <label class="custom-fields-modal__label">
                {{ $t('settings.custom-fields.labels.type') }}
                <span class="icon-wrapper" v-tooltip="tooltipType">
                  <i class="icon icon-question-14px"></i>
                </span>
              </label>
              <v-select
                :disabled="!getIsEditable"
                v-model="type"
                :reduce="item => item.key"
                label="name"
                :clearable="false"
                :placeholder="$t('select-type')"
                :options="typeOptions"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes" class="arrow-down"></span>
                </template>
                <template #no-options>
                  {{ $t('no-results') }}
                </template>
              </v-select>
              <p>{{ formatTypeChosen }}</p>
            </div>
          </div>
        </div>
        <!-- #end# - name / type -->
        <!-- optionals -->
        <div v-if="getActiveTable !== 'ORDERS'" class="custom-fields-modal__optionals">
          <h3>Opcionais</h3>
          <div v-if="getActiveTable === 'EXPORT'" class="--checkbox">
            <div class="--checkbox-border">
              <label class="label --checkbox-label" @click.prevent="isShowOnApp">
                <check-box class="checkbox" :checked="getShowOnApp" />
                <p class="--checkbox-text">{{ $t('settings.custom-fields.checkbox.app') }}</p>
                <span
                  class="icon-wrapper"
                  v-tooltip="{
                    content: $t('settings.custom-fields.tooltips.app'),
                    theme: 'default',
                    skidding: -32,
                    distance: 8,
                  }"
                >
                  <i class="icon icon-question-14px"></i>
                </span>
              </label>
              <div v-if="getShowOnApp" class="custom-fields-modal__optionals--app">
                <div>
                  <label class="custom-fields-modal__label">
                    {{ $t('settings.custom-fields.labels.prefix') }}
                    <span
                      class="icon-wrapper"
                      v-tooltip="{
                        content: $t('settings.custom-fields.tooltips.prefix', {
                          prefix: `<b>${$t('prefix')}</b>`,
                        }),
                        theme: 'default',
                        html: true,
                        skidding: -32,
                        distance: 8,
                      }"
                    >
                      <i class="icon icon-question-14px"></i> </span
                  ></label>
                  <input v-model="prefix" type="text" :placeholder="$t('settings.custom-fields.placeholder.prefix')" />
                </div>
                <div>
                  <label class="custom-fields-modal__label">
                    {{ $t('settings.custom-fields.labels.sufix') }}
                    <span
                      class="icon-wrapper"
                      v-tooltip="{
                        content: $t('settings.custom-fields.tooltips.sufix', { sufix: `<b>${$t('sufix')}</b>` }),
                        theme: 'default',
                        html: true,
                        skidding: -32,
                        distance: 8,
                      }"
                    >
                      <i class="icon icon-question-14px"></i> </span
                  ></label>
                  <input v-model="sufix" type="text" :placeholder="$t('settings.custom-fields.placeholder.sufix')" />
                </div>
              </div>
            </div>
          </div>
          <div class="custom-fields-modal__optionals--variables">
            <div class="--checkbox">
              <label class="label --checkbox-label --checkbox-wrapper" @click.prevent="isVariableMessage">
                <check-box class="checkbox" :checked="getVariableForMessages" />
                <p class="--checkbox-text">{{ $t('settings.custom-fields.checkbox.variable') }}</p>
                <span
                  class="icon-wrapper"
                  v-tooltip="{
                    content: $t('settings.custom-fields.tooltips.variables'),
                    theme: 'default',
                    skidding: -32,
                    distance: 8,
                  }"
                >
                  <i class="icon icon-question-14px"></i>
                </span>
              </label>
              <div v-if="getVariableForMessages" class="--checkbox-wrapper">
                <label class="custom-fields-modal__label">{{ $t('settings.custom-fields.labels.variable') }}</label>
                <input
                  v-model="variable"
                  type="text"
                  :disabled="getIsEditMode"
                  :placeholder="$t('settings.custom-fields.placeholder.name')"
                  v-tooltip.right="{
                    content: $t('settings.custom-fields.tooltips.error-variable'),
                    theme: 'error',
                    shown: getTooltipVariableError,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- #end# - optionals -->
        <hr class="custom-fields-modal__line mt-4" />
        <div class="custom-fields-modal__save">
          <button class="btn" :disabled="getDisabled" @click="saveCustomField">
            {{ $t('settings.custom-fields.save') }}
          </button>
        </div>
      </div>
    </section>
    <base-loader v-show="getIsLoading" />
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
import vSelect from 'vue-select';
import { mapGetters, mapActions } from 'vuex';
// components
import CheckBox from '@/components/_atoms/CheckBox';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'CustomFieldsModal',
  components: {
    vSelect,
    CheckBox,
    BaseLoader,
  },
  data() {
    return {
      id: 'custom-fields-modal',
      typeOptions: [
        {
          key: 'Decimal',
          name: this.$t('settings.custom-fields.format.decimal'),
        },
        {
          key: 'Boolean',
          name: this.$t('settings.custom-fields.format.boolean'),
        },
        {
          key: 'Date',
          name: this.$t('settings.custom-fields.format.date'),
        },
        {
          key: 'Integer',
          name: this.$t('settings.custom-fields.format.integer'),
        },
        {
          key: 'String',
          name: this.$t('settings.custom-fields.format.string'),
        },
      ],
      disabled: true,
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters('customFields', [
      'getAllowCSV',
      'getShowOnApp',
      'getVariableForMessages',
      'getType',
      'getCustomFieldsAvailable',
      'getCustomField',
      'getName',
      'getPrefix',
      'getSufix',
      'getVariable',
      'getActiveTable',
      'getDisabled',
      'getTooltipNameError',
      'getTooltipVariableError',
      'getToastSaveSuccess',
      'getIsEditMode',
      'getIsLoading',
      'getIsEditable',
    ]),

    tooltipType() {
      if (this.getIsEditable) {
        return {
          content: this.$t('settings.custom-fields.tooltips.type'),
          theme: 'default',
          skidding: -32,
          distance: 8,
        };
      }

      return {
        content: this.$t('settings.custom-fields.tooltips.audience-type'),
        theme: 'default',
        shown: this.getIsEditable,
      };
    },

    formatTypeChosen() {
      const getType = {
        Decimal: this.$t('settings.custom-fields.format-example.decimal'),
        Boolean: this.$t('settings.custom-fields.format-example.boolean'),
        String: this.$t('settings.custom-fields.format-example.string'),
        Integer: this.$t('settings.custom-fields.format-example.integer'),
        Date: this.$t('settings.custom-fields.format-example.date'),
      };
      const setType = getType[this.type] ? getType[this.type] : '';
      return `${this.$t('settings.custom-fields.format.text')} ${setType}`;
    },
    customFieldOptions() {
      return this.getCustomFieldsAvailable;
    },
    type: {
      get() {
        return this.getType !== '' ? this.getType : [];
      },
      set(type) {
        this.setType(type);
      },
    },
    customField: {
      get() {
        return this.getCustomField !== '' ? this.getCustomField : [];
      },
      set(customField) {
        this.setCustomField(customField);
      },
    },
    name: {
      get() {
        return this.getName;
      },
      set(name) {
        this.setName(name);
        if (!this.getShowOnApp) {
          this.setPrefix(name);
        }
        if (!this.getVariableForMessages) {
          this.setVariable(this.formattedVariable(name));
        }
      },
    },
    prefix: {
      get() {
        return this.getPrefix;
      },
      set(prefix) {
        this.setPrefix(prefix);
      },
    },
    sufix: {
      get() {
        return this.getSufix;
      },
      set(sufix) {
        this.setSufix(sufix);
      },
    },
    variable: {
      get() {
        return this.formattedVariable(this.getVariable);
      },
      set(variable) {
        const variableFormatted = this.formattedVariable(variable);
        this.setVariable(variableFormatted);
      },
    },
  },
  watch: {
    getActiveTable() {
      return this.getActiveTable;
    },
    getToastSaveSuccess() {
      if (this.getToastSaveSuccess) {
        this.closeModal();
        bus.$emit('custom-fields-save-succes-toast');
      }
    },
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off(`open-${this.id}`);
  },
  methods: {
    ...mapActions('customFields', [
      'setIsAllowCSV',
      'setShowOnApp',
      'setVariablesForMessages',
      'setType',
      'setCustomField',
      'setName',
      'setPrefix',
      'setSufix',
      'setVariable',
      'setRegisterCustomField',
      'setOpenModal',
      'setEditable',
    ]),

    openModal() {
      this.isVisible = true;
    },

    closeModal() {
      this.isVisible = false;
      this.setEditable(true);
    },

    isAllowCSV() {
      this.setIsAllowCSV(!this.getAllowCSV);
    },

    isShowOnApp() {
      this.setShowOnApp(!this.getShowOnApp);
    },

    isVariableMessage() {
      this.setVariablesForMessages(!this.getVariableForMessages);
    },
    formattedVariable(variable) {
      return variable
        .replace(/[^\w\s]/gi, '')
        .replaceAll(' ', '_')
        .toLowerCase();
    },
    saveCustomField() {
      this.setRegisterCustomField();
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-fields-modal {
  &__field {
    display: flex;
    align-items: center;
  }
  .--checkbox {
    margin-left: 16px;

    &-wrapper {
      margin-top: 16px;
    }

    &-label {
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      align-items: center;
      width: fit-content;
    }

    .checkbox {
      margin-right: 5px;
    }

    &-text {
      color: $gray-700;
      font-size: 12px;
      margin-right: 9px;
      margin-bottom: 0px;
    }
  }

  &__segmentation {
    margin-top: 32px;

    &--wrapper {
      display: flex;
      align-items: baseline;
    }

    &--name {
      margin-right: 16px;
    }
  }

  &__optionals {
    margin-top: 48px;

    &--app {
      display: flex;
      margin-top: 16px;

      div:nth-child(1) {
        margin-right: 16px;
      }
    }
    &--variables {
      padding: 4px 0;
    }
    .--checkbox {
      margin-left: 0px;
    }
    .--checkbox {
      &-border {
        border-bottom: 1px solid $gray-300;
        padding-bottom: 24px;
      }
    }
  }

  .vselect {
    width: 200px;
    p {
      margin-top: 6px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    span {
      margin-left: 9px;
    }
  }

  input {
    width: 200px;
  }
  .icon {
    color: $gray-500;

    &-question-14px:hover {
      color: $blue-500;
    }

    &-wrapper {
      display: flex;
    }
  }

  &__save {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0 24px;

    .btn {
      width: 170px;
    }
  }

  &__line {
    border-top-color: $gray-300;
    border-top-style: solid;
    width: calc(100% + 92px);
    margin-left: -46px;
  }
}
</style>
<style lang="scss">
#custom-fields-modal {
  position: absolute;
  top: 0;
  margin: 1.75rem auto;

  h2 {
    padding: 37px 0 24px;
  }

  .el-dialog__header {
    position: absolute;
    z-index: 1;
    padding: 32px;
    right: 0;
  }

  .el-dialog__body {
    padding: 0 46px 0;
    min-height: 400px;
  }
}

.v-popper--theme-tooltip .v-popper__inner {
  max-width: 280px;
  b {
    text-decoration: underline;
  }
}
</style>
