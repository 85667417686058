<template>
  <div class="cdp-tabs">
    <div id="myTabContent" class="tab-content">
      <div id="Overview" class="tab-pane fade show active" role="Overview" aria-labelledby="Overview-tab">
        <div class="container pl-none">
          <div class="row">
            <div v-if="typeHeader" class="col">
              <div class="metrics-list d-flex double-match-content">
                <metric-item
                  v-for="(value, key) in contentInfo"
                  v-show="key !== 'match_rate'"
                  :key="key"
                  class="m-40"
                  :card-name="cardName"
                  :label-name="key"
                  :metric-value="value"
                  :variation="value.variation"
                />
              </div>
            </div>
            <div v-else class="col">
              <div class="metrics-list d-flex">
                <metric-item
                  v-for="(value, key) in contentInfo"
                  v-show="key !== 'match_rate'"
                  :key="key"
                  :card-name="cardName"
                  :label-name="key"
                  :metric-value="value"
                  :variation="value.variation"
                />
              </div>
            </div>
            <div class="col-fluid">
              <gauge-chart v-if="contentInfo['match_rate']" :chart-value="contentInfo['match_rate']" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul id="myTab" class="nav nav-tabs navbar-inverse" role="tablist">
      <tab-item :label="$t('overview')" status="active"></tab-item>
    </ul>
  </div>
</template>

<script>
import TabItem from '@/components/cdp/_molecules/_atoms/TabItem';
import MetricItem from '@/components/cdp/_molecules/_atoms/MetricItem';
import GaugeChart from '@/components/cdp/_molecules/_atoms/GaugeChart';

export default {
  name: 'TabContent',
  components: {
    'tab-item': TabItem,
    'metric-item': MetricItem,
    'gauge-chart': GaugeChart,
  },
  props: {
    overview: {
      type: Object,
      default: () => {},
    },
    contentInfo: {
      type: Object,
      default: () => {},
    },
    cardName: {
      type: String,
      default: '',
    },
    typeHeader: {
      type: Boolean,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.pl-none {
  padding-left: 0;
}
.double-match-content {
  flex-direction: row !important;
  .m-40:nth-child(n + 1) {
    margin-right: 55px;
  }
}
.cdp-tabs {
  .tab-content {
    padding: 15px 0 20px 0;
    max-height: 120px;
    .metrics-list {
      flex-direction: column;
      max-height: 110px;
      flex-wrap: wrap;
    }
  }
  .nav-tabs {
    border-top: 1px solid $gray-300;
    border-bottom: none;
    margin: 0 -25px;
    padding: 0 1.5rem;
  }
}
</style>
