<template>
  <div class="dashboard-campaign-revenue-eng">
    <template v-if="$_verifyLoaded('done')">
      <highcharts v-if="chartOptions" :options="chartOptions"></highcharts>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'30'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import { getMetrics } from '@/services/dashboard';
import { chartMixin, dashboardMixin, verifyMixin } from '@/helpers/mixins';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'DashboardCampaignRevenueAndEngagement',
  components: {
    highcharts: Chart,
    BaseLoader,
    InfoCard,
  },
  mixins: [dashboardMixin, chartMixin, verifyMixin],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      compareOption: 'month',
      tableEmbeddedItems: [
        {
          value: 'month',
          type: 'MoM',
          title: this.$t('dashboard.MoM'),
        },
        {
          value: 'year',
          type: 'YoY',
          title: this.$t('dashboard.YoY'),
        },
      ],
      monthYearTable: {},
      selectedOption: 'revenue',
      selectItems: [
        {
          value: 'revenue',
          title: this.$tc('dashboard.revenue', 1),
        },
        {
          value: 'engagement',
          title: this.$t('dashboard.engagement'),
        },
      ],
      labels: [],
      metrics: {},
      chartOptions: {},
      chartDateOptions: [],
    };
  },
  mounted() {
    this.fetchData(this.settings.campaign);
  },
  methods: {
    resolveChartSeries() {
      let series;
      if (this.selectedOption === 'revenue') {
        series = this.revenueChartSeries();
      } else if (this.selectedOption === 'engagement') {
        series = this.engagementChartSeries();
      }
      return series;
    },
    revenueChartSeries() {
      return [
        {
          id: 'sent',
          name: this.$tc('dashboard.send', 2),
          type: 'column',
          color: '#A05195',
          data: this.$_parseArrayToFloat(this.metrics.sent),
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          id: 'revenue',
          name: this.$tc('dashboard.revenue', 1),
          type: 'spline',
          color: '#36AAD6',
          data: this.$_parseArrayToFloat(this.metrics.revenue_ga),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
          yAxis: 1,
        },
      ];
    },
    engagementChartSeries() {
      return [
        {
          id: 'sent',
          name: this.$tc('dashboard.send', 2),
          type: 'column',
          color: '#A05195',
          data: this.$_parseArrayToFloat(this.metrics.sent),
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          name: 'OR',
          type: 'spline',
          color: '#F8A9D6',
          data: this.$_parseArrayToFloat(this.metrics.open_rate),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
          yAxis: 1,
        },
        {
          name: 'CTO',
          type: 'spline',
          color: '#F4E77E',
          data: this.$_parseArrayToFloat(this.metrics.cto),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
          yAxis: 1,
        },
        {
          name: 'CTR',
          type: 'spline',
          color: '#FB8C5B',
          data: this.$_parseArrayToFloat(this.metrics.ctr),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
          yAxis: 1,
        },
      ];
    },
    fetchData(campaign) {
      this.$_reqConfig();
      getMetrics({ metrics: 'revenue_ga,sent', graphic: 1, campaign })
        .then(({ data }) => {
          if (data.success) {
            if (this.$_verifyData(data.metrics?.data)) return;
            const metricsData = data.metrics.data;
            this.labels = metricsData?.labels;
            this.metrics = metricsData?.metrics;
          }
        })
        .then(() => {
          this.chartDateOptions = this.$_resolvePlotDateFormat(this.labels);
          this.chartOptions = this.resolveChartOptions();
        })
        .catch(() => {
          this.chartOptions = null;
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resolveChartOptions() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });
      return {
        chart: {
          zoomType: 'xy',
          height: 280,
          marginLeft: 90,
          marginRight: 90,
          style: {
            fontFamily: 'Montserrat',
            margin: '0 auto',
          },
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: -30,
          itemMarginBottom: 25,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 14,
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;
              if (x.series.userOptions?.id === 'revenue') {
                formattedValue = formatCurrency(x.y);
              } else if (x.series.userOptions?.id === 'sent') {
                formattedValue = formatDecimal(x.y, 1);
              } else {
                formattedValue = formatPercent(x.y, 2);
              }
              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
            pointWidth: this.$_setChartColWidth(this.labels?.length),
          },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            categories: this.chartDateOptions,
            tickWidth: 0,
          },
        ],
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value > 1000 && this.value <= 999999) return formatCurrency(this.value, { shortNumber: true });
                else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                return formatDecimal(this.value);
              },
            },
          },
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                const hasRevenue = this.chart.options.series.find(item => item.id === 'revenue');
                if (hasRevenue) {
                  if (this.value > 1000 && this.value <= 999999)
                    return formatCurrency(this.value, { shortNumber: true });
                  else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                  return formatCurrency(this.value);
                } else {
                  return formatPercent(this.value);
                }
              },
            },
            opposite: true,
          },
        ],
        series: this.resolveChartSeries(),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-campaign-revenue-eng {
  min-height: 280px;
  padding: 20px 50px;
  position: relative;

  .dash-select {
    width: 145px;
  }
}
</style>

<style lang="scss">
.dashboard-campaign-revenue-eng {
  .highcharts-xaxis-labels {
    font-weight: bold;
  }
}
</style>
