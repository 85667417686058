import { getMetrics } from '../services';
import { ResponseMetrics, FormattedMetrics } from '../models';

export const fetchMetrics = (dates, typeMetrics) =>
  getMetrics(dates, typeMetrics).then(response => formattedMetrics(response));

const formattedMetrics = ({ data }: { data: ResponseMetrics }) => {
  const metrics = data.data;
  let result: FormattedMetrics | null;

  if (metrics && metrics.labels.length) {
    result = {
      labels: metrics.labels || [],
      metrics: metrics.metrics || [],
    };
  } else {
    result = null;
  }

  return result;
};
