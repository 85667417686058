<template>
  <div class="col-7 fit-content">
    <base-card-v2 class="seller-suggestion">
      <div class="card-body">
        <template v-if="$_verifyLoaded('done')">
          <base-card-header :title="$t('Sugestões')" />
          <div class="suggestions-grid">
            <div class="suggestions-grid-header">
              <div class="suggestions-grid-row">
                <div
                  v-for="(row, key) in [
                    '',
                    $t('360.sellers-analytics.suggestions.conversion'),
                    $t('360.sellers-analytics.suggestions.suggestions'),
                    $t('360.sellers-analytics.suggestions.sales'),
                    $t('360.sellers-analytics.suggestions.revenue'),
                  ]"
                  :key="key"
                  class="suggestions-grid-col"
                >
                  {{ row }}
                </div>
              </div>
            </div>
            <div class="suggestions-grid-body">
              <div v-for="(row, key) in sellerSuggestions.data" :key="key" class="suggestions-grid-row">
                <div class="suggestions-grid-col">{{ row.type }}</div>
                <div class="suggestions-grid-col">
                  <div class="chart-bar">
                    <span :style="`width: ${getPercentual(row.conversionRate)}%`" />
                  </div>
                  {{ $_formatNumber(row.conversionRate, '0[.]0 %') }}
                </div>
                <div class="suggestions-grid-col">
                  {{ $_formatNumber(row.suggestions, row.suggestions >= 100000 ? '0 a' : '0,0') }}
                </div>
                <div class="suggestions-grid-col">
                  {{ $_formatNumber(row.sales, row.sales >= 1000000 ? '0 a' : '0,0') }}
                </div>
                <div class="suggestions-grid-col">
                  {{
                    row.sales >= 1000000
                      ? formatCurrency(row.revenue, { shortNumber: true })
                      : formatCurrency(row.revenue)
                  }}
                </div>
              </div>
            </div>
            <div class="suggestions-grid-footer">
              <div class="export-wrapper">
                <button ref="button" class="btn btn-link" @click="toggleExport">
                  {{ $t('360.sellers-analytics.suggestions.export') }}
                </button>
                <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
                  <div v-for="item in exportItems" :key="item.value" class="items" @click="exportFile(item.value)">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </base-card-v2>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// libs
import moment from 'moment';
import FileDownload from 'js-file-download';
import bus from '@/helpers/events/bus';
import vClickOutside from 'click-outside-vue3';
// services
import { getSellerSuggestionsTable } from '@/services/oto/sellers';
// store
import { mapState, mapGetters } from 'vuex';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
//mixin
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      isExportVisible: false,
      loadingExport: false,
      exportItems: [
        {
          value: 'csv',
          title: this.$t('360.export-type', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('360.export-type', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('360.export-type', { value: '.XLSX' }),
        },
      ],
    };
  },
  computed: {
    ...mapState('sellersAnalytics', ['sellerSelected', 'sellerSuggestions']),
    ...mapGetters('sellersAnalytics', [
      'getSellerSuggestionsError',
      'getSellerSuggestionsLoading',
      'getSellerSuggestionsEmpty',
    ]),
  },
  watch: {
    // Specific Sellers Analytics Watchers
    getSellerSuggestionsLoading() {
      this.loading = this.getSellerSuggestionsLoading;
    },
    getSellerSuggestionsEmpty() {
      this.isEmpty = this.getSellerSuggestionsEmpty;
    },
    getSellerSuggestionsError() {
      this.hasError = this.getSellerSuggestionsError;
    },
  },
  mounted() {
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
  },
  beforeUnmount() {
    bus.$off('render-page-loader');
  },
  methods: {
    getPercentual(n) {
      /**
       * @description returns bar table size
       */
      return (n / this.maxSuggestionRate) * 100;
    },
    toggleExport() {
      /**
       * @description toggle open/close dropdown export
       */
      this.isExportVisible = !this.isExportVisible;
    },
    exportFile(type) {
      /**
       * @description request to download selected file format
       */
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);
      getSellerSuggestionsTable(this.sellerSelected, type)
        .then(({ data }) => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${'campaignsManagement'}-${date}.${type}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$level_1_color: #3590b9;
$level_2_color: #51bfda;
$level_3_color: #35ba9b;

.fit-content {
  height: 260px;
}

.seller-suggestion {
  font-size: 12px;
  color: #555;
  height: 253px;
}
.suggestions-grid {
  position: relative;
  left: -25px;
  width: calc(100% + 50px);
  bottom: -25px;
  top: -23px;
}
.suggestions-grid-header {
  font-weight: 600;
}
.suggestions-grid-row {
  display: grid;
  grid-template-columns: 38% 17% 15% 15% 15%;
  height: 35px;
}
.suggestions-grid-col {
  align-self: center;
  &:not(:first-child) {
    padding-right: 20px;
    text-align: right;
  }
  &:first-child {
    padding-left: 25px;
  }
}
.suggestions-grid-body {
  .suggestions-grid-row {
    border-bottom: 1px solid #eee;
    .suggestions-grid-col:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .suggestions-grid-col:nth-child(2) {
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      .chart-bar {
        margin-right: 5px;
        span {
          display: block;
          height: 10px;
          background: $level_1_color;
          float: right;
        }
      }
    }
  }
}
.suggestions-grid-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  button {
    height: 12px;
    padding: 0;
    line-height: 12px;
    font-weight: 600;
    color: $level_1_color;
  }
}
.export-wrapper {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  width: fit-content;
  .export-container {
    position: absolute;
    top: 20px;
    z-index: 99;
    width: 130px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
    .items {
      background-color: $color-white;
      color: $gray-800;
      font-weight: bold;
      padding: 10px 15px;
      font-size: 12px;
      &:hover {
        background-color: $gray-300;
        cursor: pointer;
      }
    }
  }
}
</style>
