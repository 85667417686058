<template>
  <button class="audience-add-group-button" @click.prevent="action">
    <i class="icon icon-plus"></i>
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'DetailAddGroupButton',
  props: {
    title: {
      type: String,
      default: null,
    },
    action: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.audience-add-group-button {
  background-color: transparent;
  border: none;
  color: $gray-700;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
  i {
    background-color: $gray-400;
    color: $gray-700;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 14px;
  }
}
</style>
