import { ContactResponse, RemoteContactResponse } from '../models';
import { getFilterContact } from '../services';

export const fetchFilterContact = (): Promise<ContactResponse> =>
  getFilterContact().then(response => formatResponse(response?.data));

const formatResponse = (response: RemoteContactResponse): ContactResponse => ({
  data: Object.entries(response?.data ?? {}).map(([key, name]) => ({
    key,
    name,
  })),
  errors: response?.errors,
  success: response?.success,
});
