<template>
  <base-card-v2
    class="metrics-card"
    :class="{
      loadHeight: info === 'loading' || info === 'isEmpty' || info === 'error',
    }"
  >
    <template v-if="info == 'done'">
      <base-card-header :title="title" :icon="icon" />
      <div class="item">
        <template v-for="(value, key) in metrics" :key="key">
          <metric :label="key" :metric-raw="value" class="sub-item" />
        </template>
      </div>
    </template>
    <base-loader v-show="info == 'loading'" />
    <info-card
      v-show="info == 'isEmpty' || info == 'error'"
      ref="message-error"
      :height="'50px'"
      :error="true ? info == 'error' : false"
    />
  </base-card-v2>
</template>

<script>
import Metric from '@/components/_atoms/Metric';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'MetricsCard',
  components: {
    Metric,
    BaseLoader,
    InfoCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    metrics: {
      type: Object,
      default: () => {},
    },
    done: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: 'loading',
    },
  },
};
</script>

<style lang="scss" scoped>
.o-metrics_group .item {
  display: flex;
  justify-content: flex-start;
}
.o-metrics_group .sub-item {
  padding: 0 30px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
.o-metrics_group .col-6 .sub-item {
  padding: 0 15px;

  @media screen and (max-width: 1024px) {
    padding: 0 10px;
  }

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}
.o-metrics_group .col-4 .sub-item {
  padding: 0 20px;

  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.o-metrics_group .kpi-tags .sub-item {
  padding: 0 40px;
}

.metrics-card {
  &.loadHeight {
    min-height: 135px;
    box-sizing: border-box;
  }
}
</style>
