import axios from 'axios';
import store from '@/store';
// import moment from 'moment';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'segment';

/**
 * @description get segments group personas
 * @returns {Object}
 */
export const getSegmentPersonas = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios.get(`${URI}/getSegmentGroupPersonas`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate: startDate,
      endDate: endDate,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
