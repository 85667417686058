<template>
  <base-card-v-2 v-if="showHeader && isLogged()">
    <div id="personas-header">
      <div class="round-circle">
        {{ selectedPersona.name.substr(0, 2).toUpperCase() }}
      </div>
      <div class="main-content">
        <div class="container">
          <h3>{{ selectedPersona.name }}</h3>
          <p>
            <strong>{{ countPeople }}</strong> pessoas nessa persona hoje.
          </p>
        </div>
      </div>
      <div class="vertical-line"></div>
      <div class="info-text">
        <p>
          Você está analisando o comportamento das pessoas que estão hoje na persona "{{ selectedPersona.name }}" pelo
          período de <modal-dates></modal-dates>.
        </p>
      </div>
      <div class="clear-filter" @click.prevent="clearPersona()">Limpar filtro</div>
    </div>
  </base-card-v-2>
</template>
<script>
import bus from '@/helpers/events/bus';
import { formatDecimal } from '@/utilities/formatters';
import store from '@/store';
import { mapGetters } from 'vuex';
import BaseCardV2 from '@/components/_atoms/BaseCardV2';
import { getPersonaCount } from '@/services/personas';
import ModalDates from '@/components/ModalDates';

export default {
  name: 'PersonaHeader',
  components: {
    BaseCardV2,
    'modal-dates': ModalDates,
  },
  data() {
    return {
      // selectedPersona: this.$store.getters?.selectedPersona,
      count: null,
    };
  },
  computed: {
    ...mapGetters(['selectedPersona']),
    showHeader() {
      const { selectedPersona } = this.$store.getters;
      return selectedPersona && selectedPersona.id;
    },
    countPeople() {
      return this.count === null ? 'Calculando' : formatDecimal(this.count, 2);
    },
  },
  mounted() {
    this.getCount(this.selectedPersona.id);
    bus.$on('select-persona', ({ persona }) => {
      this.count = null;
      this.getCount(persona.id);
    });
  },
  beforeUnmount() {
    bus.$off('select-persona');
  },
  methods: {
    isLogged() {
      return store?.getters?.isLoggedIn ? true : false;
    },
    getCount(id) {
      if (!id) return;
      getPersonaCount({ personaId: id }).then(({ data }) => {
        this.count = data?.data?.count;
      });
    },
    clearPersona() {
      const defaultPersona = { id: null, name: 'Todos', group: null };
      this.$store.dispatch('changePersona', defaultPersona);
      bus.$emit('select-persona', defaultPersona);
      bus.$emit('render-cards');
    },
  },
};
</script>
<style lang="scss" scoped>
#personas-header {
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  margin-top: 10px;
  .round-circle {
    border: 1px solid #dddddd;
    border-radius: 50%;
    width: 41px;
    height: 41px;
    font-weight: 600;
    font-size: 19px;
    line-height: 40px;
    align-items: center;
    text-align: center;
    color: #bbbbbb;
  }
  .main-content {
    flex: 0.4;
    padding-left: 13px;
    .container {
      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 2px;
      }
      p {
        font-size: 12px;
        line-height: 20px;
        color: #666666;
        margin: 0;
      }
    }
  }
  .vertical-line {
    position: absolute;
    left: 400px;
    top: 55px;
    width: 32px;
    height: 0px;
    border: 1px solid #dddddd;
    transform: rotate(90deg);
  }
  .info-text {
    flex: 0.5;
    color: #888888;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
  .clear-filter {
    flex: 0.15;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    margin-top: 15px;
    color: #36aad6;
    cursor: pointer;
  }
}
</style>
