import { formatCurrency, formatDecimal } from '@/utilities/formatters';
import numeral from 'numeral';

const formatter = (name, value) => {
  let formattedValue = '';
  if (['revenue', 'averageOrderValue'].includes(name)) {
    formattedValue = formatCurrency(value);
  }
  if (['followUps', 'campaignsManagement', 'contacts', 'sales'].includes(name)) {
    formattedValue = formatDecimal(value, 1);
  }
  if (['registeredFollowups'].includes(name)) {
    formattedValue = `${formatDecimal(value, 1)}`;
  }
  if (['ignoredSuggestions'].includes(name)) {
    formattedValue = `${formatDecimal(value, 1)}`;
  }
  if (['conversionRate', 'contactsPercent', 'salesPercent'].includes(name)) {
    formattedValue = numeral(value).format('0[.]00%');
  }
  if (['averageLookback'].includes(name)) {
    formattedValue = `${formatDecimal(value, 2)} dias (média)`;
  }
  return formattedValue.length ? formattedValue : value;
};

export default formatter;
