<template>
  <div class="sizes-table">
    <ul v-if="sizes" class="content customScrollBar">
      <li v-for="(option, key) in sizes" :key="key">
        {{ `${option.category} ${$t('360.person.customer-sizes.size')} ` }}
        <span class="strong">{{ option.size }}</span>
      </li>
    </ul>
    <div v-else class="content">
      <empty-message :text="$t('360.person.empty.size')" />
    </div>
    <div class="action d-block text-right">
      <a class="add-size" @click="addSize">
        {{ $t('360.person.customer-sizes.add-new') }}
      </a>
    </div>
  </div>
</template>

<script>
import EmptyMessage from '@/components/_atoms/EmptyMessage';
import { openLightbox } from '@/helpers/lightbox';

export default {
  name: 'CustomerViewSizes',
  components: {
    'empty-message': EmptyMessage,
  },
  props: {
    sizes: {
      type: Array,
      default: () => [],
    },
    customerId: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    addSize() {
      openLightbox('lightbox-add-size', { customerId: this.customerId });
    },
  },
};
</script>
<style lang="scss" scoped>
$border: 1px solid #f1f1f1;
.sizes-table {
  margin-top: 30px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
  }
  .content {
    position: relative;
    height: 210px;
  }
  li {
    font-size: 12px;
    padding: 13px 5px;
    color: $gray-700;
    border-bottom: $border;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
    .icon {
      font-size: 23px;
    }
    .strong {
      font-weight: 600;
    }
  }
  .action {
    padding: 10px 20px;
    margin: 0 -25px -1rem;
    border-top: $border;
    .add-size {
      cursor: pointer;
      color: $oto-omni;
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      &:hover {
        color: $oto-omni;
      }
    }
  }
}
</style>
