<template>
  <div class="metrics-chart">
    <section id="metrics">
      <div class="col-lg-12 card row ml-0 mr-0">
        <div class="col-md-4 row align-items-center">
          <i class="icon dashboard-icon blue icon-trending-18px"></i>
          <h1 class="title">{{ $t('360.performance-dashboard') }}</h1>
        </div>
        <div class="col-md-8 row">
          <div class="col-5 select-container">
            <label>{{ $t('360.campaign') }}</label>
            <v-select
              v-model="campaign"
              label="name"
              class="select-dash-metrics"
              :clearable="false"
              :options="campaigns"
              :placeholder="$t('select')"
              @option:selected="handleUpdate"
            >
              <template #option="{ name }">
                {{ $t(`${name}`) }}
              </template>
              <template #selected-option="{ name }">
                {{ $t(`${name}`) }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('no-results') }}
              </template>
            </v-select>
          </div>
          <div class="col-4 select-container">
            <label>{{ $t('360.contact-store') }}</label>
            <v-select
              v-model="selectedStore"
              label="name"
              :clearable="false"
              class="w-100"
              :options="stores"
              :placeholder="$t('360.sellers.placeholder')"
              @option:selected="handleUpdate"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('360.sellers.no-results') }}
              </template>
            </v-select>
          </div>
          <div class="col-3 select-container">
            <label>{{ $t('360.conversion-window') }}</label>
            <v-select
              v-model="lookback"
              class="table-select"
              :clearable="false"
              :options="lookbackOptions"
              label="name"
              :placeholder="$tc('_select', 2)"
              @option:selected="handleUpdate"
            >
              <template #option="{ name }">
                {{ $tc(name, 1) }}
              </template>
              <template #selected-option="{ name }">
                {{ $tc(name, 1) }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('integrations.no-results') }}
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="(metric, key) in metrics" :key="key" class="col-md-3">
          <indicator
            :metric="metric"
            :is-empty="$_verifyLoaded('info')"
            :loading="$_verifyLoaded('loading')"
            :has-error="hasError"
          />
        </div>
      </div>
    </section>

    <indicator-chart
      :chart-options="chartOptions"
      :chart-date-options="chartDateOptions"
      :labels="labels"
      :contacts="contacts"
      :buyers-attribution="buyersAttribution"
      :loading="loadingChart"
      :is-empty="isEmptyChart"
      :has-error="hasErrorChart"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import Highcharts from 'highcharts';
import vSelect from 'vue-select';

import { getCampaigns, getStores, getMetrics } from '@/services/oto/indicators-old';

import bus from '@/helpers/events/bus';
import { verifyMixin, chartMixin } from '@/helpers/mixins';
import { formatDecimal, formatNumber } from '@/utilities/formatters';

import IndicatorCard from '@/components/_atoms/IndicatorCard';
import IndicatorChart from '@/components/oto/indicators-old/IndicatorChart';

export default {
  name: 'MetricsChart',
  components: {
    indicator: IndicatorCard,
    IndicatorChart,
    vSelect,
  },
  mixins: [verifyMixin, chartMixin],
  data() {
    return {
      campaign: { id: '', name: this.$t('360.all-options') },
      campaigns: [],

      selectedStore: { id: '', name: this.$t('360.all-options') },
      stores: [],

      lookback: { id: 30, name: `30 ${this.$tc('360.day', 30)}` },
      lookbackOptions: [],

      metrics: [...Array(8)],

      //chart states
      chartOptions: {},
      chartDateOptions: [],
      labels: [],
      contacts: [],
      buyersAttribution: [],
      loadingChart: false,
      isEmptyChart: false,
      hasErrorChart: false,
    };
  },
  mounted() {
    this.setup();

    // bus called when dates are changed globally
    bus.$on(['update-selected-brand', 'render-cards'], () => {
      this.resetMetrics();
      this.setup();
    });
  },
  beforeUnmount() {
    bus.$off(['update-selected-brand', 'render-cards']);
  },
  methods: {
    setup() {
      this.getCampaignsData();
      this.getStoresData();
      this.getMetricsData();
      this.getChartData();
      this.initLookback();
    },

    resetMetrics() {
      this.campaign = { id: '', name: this.$t('360.all-options') };
      this.campaigns = [];
      this.selectedStore = { id: '', name: this.$t('360.all-options') };
      this.stores = [];
    },

    getCampaignsData() {
      getCampaigns().then(({ data }) => {
        // added empty as key to be filtered to the first position
        const campaignsList = Object.entries(data).map(el => {
          return {
            id: el[0],
            name: el[1],
          };
        });
        this.campaigns = [{ id: '', name: this.$t('360.all-options') }, ...campaignsList];
      });
    },

    getStoresData() {
      getStores().then(({ data }) => {
        const temp = Object.entries(data)?.map(el => {
          return {
            id: el[0],
            name: el[1],
          };
        });
        this.stores = [{ id: '', name: this.$t('360.all-options') }, ...temp];
      });
    },

    getMetricsData() {
      this.$_reqConfig();

      const metrics =
        'contacts,transactions,aov,revenue,conversion_rate,conversion_rate_adjusted,stores,conversion_window';

      getMetrics(metrics, this.campaign?.id, this.selectedStore?.id, this.lookback?.id)
        .then(res => {
          if (this.$_verifyHasData(res.data.metrics)) return;
          this.metrics = res.data.metrics;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    initLookback() {
      // create an array for LOOKBACK/conversion window options
      this.lookbackOptions = Array(...Array(32)).map((x, i) => ({
        name: `${i} ${this.$tc('360.day', i)}`,
        id: i,
      }));
      this.lookbackOptions[0].name = this.$t('360.conversion-window-same-day');
      this.lookbackOptions[1].name = `1 ${this.$tc('360.day', 1)}`;
    },

    getChartData() {
      this.loadingChart = true;
      this.isEmptyChart = false;
      this.hasErrorChart = false;

      const metrics = 'contacts,buyers_attrib';

      getMetrics(metrics, this.campaign?.id, this.selectedStore?.id, this.lookback.id, 1)
        .then(({ data }) => {
          this.isEmptyChart = _.isEmpty(data.metrics);
          if (this.isEmptyChart) {
            this.$_componentHeight();
            this.loadingChart = false;
            return;
          }

          if (data.success) {
            const metricsData = data.metrics;
            this.labels = metricsData?.labels;
            this.contacts = metricsData?.contacts;
            this.buyersAttribution = metricsData?.buyers_attrib;
          }
        })
        .then(() => {
          this.chartDateOptions = this.$_resolvePlotDateFormat(this.labels);
          this.chartOptions = this.resolveChartOptions();
        })
        .catch(() => {
          this.hasErrorChart = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loadingChart = false;
        });
    },

    resolveChartSeries() {
      return [
        {
          id: 'assigned-buyers',
          format: 'integer',
          name: this.$t('360.assigned-buyers', 2),
          type: 'column',
          color: '#A05195',
          data: this.$_parseArrayToFloat(this.buyersAttribution),
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          id: 'contacts',
          name: this.$tc('360.contacts', 1),
          type: 'spline',
          color: '#36AAD6',
          data: this.$_parseArrayToFloat(this.contacts),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
        },
      ];
    },

    resolveChartOptions() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });
      return {
        chart: {
          zoomType: 'xy',
          height: 280,
          marginLeft: 90,
          marginRight: 90,
          style: {
            fontFamily: 'Montserrat',
            margin: '0 auto',
          },
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginBottom: 25,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 14,
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;
              formattedValue = formatDecimal(x.y, 1);
              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
            pointWidth: 26,
          },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            categories: this.chartDateOptions,
            tickWidth: 0,
          },
        ],
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value > 1000 && this.value <= 999999) return formatNumber(this.value, '0 a');
                else if (this.value >= 1000000) return formatNumber(this.value, '0 a');
                return formatDecimal(this.value);
              },
            },
          },
        ],
        series: this.resolveChartSeries(),
      };
    },

    handleUpdate() {
      bus.$emit('indicator-conversion-window', this.lookback?.id);
      this.getMetricsData();
      this.getChartData();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 16px;
  background-color: $color-white;
  min-height: 80px;
  box-shadow: 0px 24px 60px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  justify-content: space-between;
}
.row {
  flex-direction: row;
}
.metric-card-container {
  justify-content: space-between;
}
.metric-card {
  padding: 0;
}
.dashboard-icon {
  font-size: 26px;
  margin-left: 18px;
  &.blue {
    color: $oto-omni;
  }
}
.title {
  margin: 0 0 0 12px;
}
.select-container {
  padding-right: 16px;
}
</style>

<style lang="scss">
.metrics-chart {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
