<template>
  <div class="container loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('client-variation-lightbox') }}</h5>
        <span class="segment-label">{{ $_segmentNameSelect() }}</span>
      </header>
      <lightbox-template-chart :settings="chartClientVariation" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import moment from 'moment';
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import { dateFormat } from '@/utilities/constants';
import getMetrics from '@/services/getmetrics';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'RetentionChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  data() {
    return {
      dataChartClientVariation: {},
      segmentName: '',
      segmentSelected: '',
      chartClientVariation: undefined,
    };
  },
  mounted() {
    this.reset();
    this.fetchChart();
  },
  methods: {
    fetchChart() {
      this.loadChartClientVariation();

      getMetrics({
        metrics: ['customers_new', 'customers_inactive', 'customers_regained'],
        segments: this.segmentSelected,
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          const { data } = response.data;
          if (this.$_verifyData(data)) return;

          this.dataChartClientVariation = data;

          const { metrics } = data;
          const customersInactive = metrics.customers_inactive.map(value => value * -1);
          const customersNew = metrics.customers_new;
          const customersRegained = metrics.customers_regained;

          let { labels } = data;
          labels = this.format({ labels, formatTo: 'MMM/YY' });

          const date = {
            start: moment(this.$store.getters.dates.startDate, dateFormat),
            end: moment(this.$store.getters.dates.endDate, dateFormat),
            now: moment(new Date()),
          };

          const months = date.end.diff(date.start, 'month');

          if (date.end.month() === date.now.month()) {
            labels = labels.slice(0, -1);
            labels[labels.length] = this.$t('current-month');
          }

          this.loadChartClientVariation(
            customersInactive,
            customersNew,
            customersRegained,
            labels,
            labels.length - 0.5,
            labels.length - months - 1.5,
          );
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadChartClientVariation(customersInactive, customersNew, customersRegained, labels, minSelect, maxSelect) {
      this.chartClientVariation = {
        chart: {
          type: 'column',
          height: 280,
        },
        title: {
          text: null,
        },
        xAxis: {
          categories: labels,
          plotBands: [
            {
              from: minSelect,
              to: maxSelect,
              color: 'rgba(68, 170, 213, .2)',
            },
          ],
        },
        tooltip: {
          shared: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: this.format({ formatTo: 'tooltip' }),
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
          },
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderWidth: 0,
          },
        },
        series: [
          {
            name: this.$t('recovered'),
            data: customersRegained,
            color: '#EADC3B',
          },
          {
            name: this.$t('new'),
            data: customersNew,
            color: '#FA7234',
          },
          {
            name: this.$t('inactive'),
            data: customersInactive,
            color: '#FA2C43',
          },
        ],
      };
    },
    reset() {
      this.$_reqConfig();
      this.dataChartClientVariation = {};
      this.segmentName = this.$parent.segmentName;
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
