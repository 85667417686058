<template>
  <base-card-v2 class="dashboard-revenue">
    <div v-if="$_verifyLoaded('done')">
      <div class="churn-wrapper">
        <base-card-header :title="$t('churn.churn-per-period')" icon="icon-customer"></base-card-header>
        <div class="churn-amount">
          <div v-if="metricsHeader.churnAverageRate">
            <label>{{ metricsHeader.churnAverageRate.title }}</label>
            <div class="churn metric">
              {{
                metricsHeader.churnAverageRate.data ? $_formatValue(metricsHeader.churnAverageRate.data, 'value') : '-'
              }}
            </div>
          </div>
          <div v-if="metricsHeader.ltvAverageRate">
            <label>{{ metricsHeader.ltvAverageRate.title }}</label>
            <div class="ltv metric">
              {{ metricsHeader.ltvAverageRate.data ? $_formatValue(metricsHeader.ltvAverageRate.data, 'value') : '-' }}
            </div>
          </div>
        </div>
      </div>
      <highcharts v-if="chartOptions" :options="chartOptions"></highcharts>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
// libs
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import moment from 'moment';
import bus from '@/helpers/events/bus';
// services
import { getMetricsPeriod } from '@/services/churnLtv';
// helpers
import { chartMixin, valueMixin, verifyMixin, verifyThensMixin } from '@/helpers/mixins';
// components
import InfoCard from '@/components/_atoms/InfoCard';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import { formatCurrency, formatPercent } from '@/utilities/formatters';

export default {
  name: 'RevenueAndEngagement',
  components: {
    highcharts: Chart,
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, verifyThensMixin, valueMixin, chartMixin],
  data() {
    return {
      loadingEmbeddedTable: false,
      selectedItemIndexTable: 0,
      selectedItemIndexDash: 0,
      series: [],
      metrics: {},
      chartOptions: {},
      metricsHeader: {},
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetchData();
      }
    },
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      const metrics = 'ltvAverageRate,churnAverageRate';
      this.$_reqConfig();
      getMetricsPeriod({ metrics, graphic: 1 })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) return;

          if (data.success) {
            this.metrics = data?.metrics;
            this.series = data?.metrics?.series;
          }
        })
        .then(() => {
          this.chartOptions = this.resolveChartOptions();
        })
        .catch(() => {
          this.chartOptions = null;
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
          if (this.hasError) return;
          if (!this.chartOptions) this.$_reqConfig();
        });
      getMetricsPeriod({ metrics, graphic: 0 })
        .then(({ data }) => {
          this.metricsHeader = data?.metrics;
        })
        .catch(() => {
          this.chartOptions = null;
          this.hasError = true;
          this.$_componentHeight();
        });
    },
    resolveChartOptions() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });
      return {
        chart: {
          zoomType: 'xy',
          height: 280,
          marginLeft: 90,
          marginRight: 90,
          style: {
            fontFamily: 'Montserrat',
            margin: '0 auto',
          },
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: -30,
          itemMarginBottom: 25,
          reversed: true,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 14,
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;
              if (x.series.userOptions?.id === 'ltvAverageRate') {
                formattedValue = formatCurrency(x.y);
              } else {
                formattedValue = formatPercent(x.y, 2);
              }
              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
            pointWidth: 26,
          },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            categories: this.series.map(date => moment(date).format('MMM')),
            tickWidth: 0,
          },
        ],
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value >= 1000 && this.value <= 999999)
                  return formatCurrency(this.value, { shortNumber: true });
                else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                return formatCurrency(this.value);
              },
            },
            opposite: true,
          },
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                return formatPercent(this.value);
              },
            },
          },
        ],
        series: [
          {
            id: 'ltvAverageRate',
            name: 'LTV médio',
            type: 'column',
            color: '#36AAD6',
            data: this.$_parseArrayToFloat(this.metrics.ltvAverageRate),
            tooltip: {
              valueSuffix: '',
            },
          },
          {
            id: 'churnAverageRate',
            name: 'Churn rate',
            type: 'spline',
            color: '#A05195',
            data: this.$_parseArrayToFloat(this.metrics.churnAverageRate),
            marker: { symbol: 'line' },
            tooltip: {
              valueSuffix: '',
            },
            yAxis: 1,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-revenue {
  min-height: 370px;

  .highcharts-xaxis-labels {
    font-weight: bold;
  }
}

.table-select {
  margin-left: -14px;
}

.churn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .churn-amount {
    display: flex;

    > div {
      width: 140px;
    }

    .metric {
      font-weight: 600;
      font-size: 16px;
    }

    .churn {
      color: #a05195;
    }
    .ltv {
      color: #36aad6;
    }
  }
}
</style>
