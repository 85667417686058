<template>
  <div id="conversionsalesstoresbrands">
    <div class="row">
      <div class="col-12 mb-sm-6 mb-xl-0">
        <div class="card">
          <div v-if="$_verifyLoaded('done')" class="card-body">
            <h2 class="card-title d-flex align-items-center">
              <i class="icon icon-money"></i>
              <span>{{ $tc('store', 0) }}</span>
            </h2>
            <div class="row">
              <div class="col-sm-12 col-xl-6 mb-sm-2 mb-xl-0">
                <div class="col-10">
                  <chart-list :chart-name="$t('highest-revenue-stores')" :rows-list="objectChartListHighestStore" />
                </div>
              </div>
              <div class="col-sm-12 col-xl-6 mb-sm-2 mb-xl-0">
                <div class="col-10">
                  <chart-list :chart-name="$t('lowest-revenue-stores')" :rows-list="objectChartListLowestStore" />
                </div>
              </div>
            </div>
          </div>
          <base-loader v-show="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
        </div>
      </div>
      <div class="col-sm-12 col-xl-4 mb-sm-6 mb-xl-0 d-none">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title d-flex align-items-center">
              <i class="icon icon-money"></i>
              <span>{{ $tc('brands', 0) }}</span>
            </h2>
            <div class="row">
              <div class="col-sm-12 col-xl-12 mb-sm-6 mb-xl-0">
                <!-- <minibarchart class="fake blocked" :prop-data="JsonLBrands"></minibarchart> -->
                <chart-list :chart-name="$t('highest-revenue-brands')" :rows-list="objectChartListBrands" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from 'lodash';
import { getPercentual, getMax } from '@/utilities/numbers';
import { getOrderedValues, getFirsts } from '@/helpers/lists.js';
import ChartList from '@/components/_organisms/ChartList';
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import { formatCurrency } from '@/utilities/formatters';

export default {
  name: 'ConversionSalesStoresBrands',
  components: {
    'chart-list': ChartList,
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin],
  data() {
    return {
      ready: false,
      dataReturn: {},
      objectChartListHighestStore: [],
      objectChartListLowestStore: [],
      objectChartListBrands: [],
    };
  },
  computed: {
    totalamountStores() {
      const datavalue = this.dataReturn;
      const total = Object.values(datavalue).reduce((t, n) => parseInt(t, 10) + parseInt(n, 10));
      return total;
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'store_type',
        filters: 'best_sellers:revenue',
        version: 2,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          // get response
          this.dataReturn = response.data.metrics.store_type.data.series[0].data;
          //  clean data
          this.objectChartListHighestStore = [];
          this.objectChartListLowestStore = [];
          this.objectChartListBrands = [];
          // mount lists
          const storesHigh = getFirsts(getOrderedValues(this.dataReturn, 'desc'), 5);
          const storesLow = getFirsts(getOrderedValues(this.dataReturn, 'asc'), 5);
          const maxHighers = getMax(storesHigh);
          const maxLowers = getMax(storesLow);
          // iterate to fill chart bars
          _.each(storesHigh, (val, key) => {
            this.objectChartListHighestStore.push({
              label: key,
              progress: String(getPercentual(val, maxHighers)),
              value: formatCurrency(val, { shortNumber: true }),
              color: 'lightblue',
            });
          });
          _.each(storesLow, (val, key) => {
            this.objectChartListLowestStore.push({
              label: key,
              progress: String(getPercentual(val, maxLowers)),
              value: formatCurrency(val, { shortNumber: true, max: 1 }),
              color: 'red',
            });
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  @media (min-width: $xl-min-width) {
    min-height: 265px;
  }
}
</style>
