<template>
  <div class="card">
    <div v-if="hasData" v-show="$_verifyLoaded('done')">
      <div class="card-body">
        <div v-if="!!data" class="left labels-grid">
          <!-- In Store -->
          <div v-show="data.i_off.orders" class="label-grid">
            <div class="content-label">
              <div class="big color-purple-200">
                {{ $_formatDecimal(data.i_off.orders) }}
              </div>
              <div class="chart-title color-purple-200">
                {{ $tc('in-store') }}
              </div>
              <div class="bar-chart-text">
                {{ $tc('omnichannel-overview.interest-driven') }}
              </div>
            </div>
          </div>
          <!-- E-commerce -->
          <div v-show="data.i_on.orders" class="label-grid">
            <div class="content-label">
              <div class="big color-oto-ecommerce">
                {{ $_formatDecimal(data.i_on.orders) }}
              </div>
              <div class="chart-title color-oto-ecommerce">
                {{ $t('ecommerce') }}
              </div>
              <div class="bar-chart-text">
                {{ $tc('omnichannel-overview.interest-driven') }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!!data" id="omnichanneloverview-sankey"></div>
        <div v-if="!!data" class="right labels-grid">
          <!-- In Store -->
          <div v-show="data.p_off.orders" class="label-grid">
            <div class="content-label">
              <div class="big color-purple-200">
                {{ $_formatDecimal(data.p_off.orders) }}
              </div>
              <div class="chart-title color-purple-200">
                <span>{{ $t('orders') }}</span> {{ $tc('in-store') }}
              </div>
              <div class="bar-chart-text">
                {{ $t('revenue') }}:
                <span>{{ formatCurrency(data.p_off.revenue) }}</span>
              </div>
            </div>
          </div>
          <!-- E-commerce -->
          <div v-show="data.p_on.orders" class="label-grid">
            <div class="content-label">
              <div class="big color-oto-ecommerce">
                {{ $_formatDecimal(data.p_on.orders) }}
              </div>
              <div class="chart-title color-oto-ecommerce">
                <span>{{ $t('orders') }}</span> {{ $t('ecommerce') }}
              </div>
              <div class="bar-chart-text">
                {{ $t('revenue') }}:
                <span>{{ formatCurrency(data.p_on.revenue) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!!data" class="card-footer">
        <div class="label-center">
          <span>{{ $tc('omnichannel-overview.interest') }}</span>
        </div>
        <div class="label-center">
          <span>{{ $tc('omnichannel-overview.consideration') }}</span>
        </div>
        <div class="label-center">
          <span>{{ $tc('purchase', 1) }}</span>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { numberMixin } from '@/helpers/mixins';
import { formatDecimal, formatNumber } from '@/utilities/formatters';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [numberMixin],
  data() {
    return {
      data: undefined,
      hasData: false,
    };
  },
  mounted() {
    this.setup();
    bus.$on('render-cards', this.setup);
  },
  methods: {
    setupSankey(data, $i18n) {
      const offAlpha = 'rgba(64, 132, 203, 0.5)';
      const onAlpha = 'rgba(126, 55, 197, 0.5)';
      const omnipAlpha = 'rgb(239, 76, 139, 0.5)';

      const offSolid = 'rgb(64, 132, 203)';
      const onSolid = 'rgb(126, 55, 197)';
      const omniSolid = 'rgb(239,76,139)';
      const linearGradient = {
        x1: 0,
        x2: 1,
        y1: 1,
        y2: 1,
      };

      Highcharts.chart('omnichanneloverview-sankey', {
        title: {
          text: '',
        },
        subtitle: {
          text: '',
        },
        chart: {
          type: 'sankey',
        },
        position: {
          align: 'center',
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          fontSize: '12px',
          formatter: function formater() {
            const percent = {
              'in-store': {
                total: formatNumber(data.i_off.percent, '0%'),
                name: $i18n.tc('in-store'),
                label: $i18n.t('omnichannel-overview.interest-was'),
                description: $i18n.t('omnichannel-overview.where-consider'),
                sankey: [
                  {
                    percent: formatNumber(data.i_off.to.off.percent, '0%'),
                    color: '#4084CB',
                    label: $i18n.t('omnichannel-overview.in-store'),
                    total: formatDecimal(data.i_off.to.off.orders, 1),
                  },
                  {
                    percent: formatNumber(data.i_off.to.on.percent, '0%'),
                    color: '#A167D9',
                    label: $i18n.t('omnichannel-overview.in-e-commerce'),
                    total: formatDecimal(data.i_off.to.on.orders, 1),
                  },
                  {
                    percent: formatNumber(data.i_off.to.omni.percent, '0%'),
                    color: '#EF4C8B',
                    label: $i18n.tc('omnichannel-overview.in-omni'),
                    total: formatDecimal(data.i_off.to.omni.orders, 1),
                  },
                ],
              },
              'in-store-consideration': {
                total: formatNumber(data.c_off.percent, '0%'),
                name: $i18n.tc('in-store'),
                label: $i18n.t('omnichannel-overview.consider-was'),
                description: $i18n.t('omnichannel-overview.where-purchase'),
                sankey: [
                  {
                    percent: formatNumber(data.c_off.to.off.percent, '0%'),
                    color: '#4084CB',
                    label: $i18n.t('omnichannel-overview.in-store'),
                    total: formatDecimal(data.c_off.to.off.orders, 1),
                  },
                  {
                    percent: formatNumber(data.c_off.to.on.percent, '0%'),
                    color: '#A167D9',
                    label: $i18n.t('omnichannel-overview.in-e-commerce'),
                    total: formatDecimal(data.c_off.to.on.orders, 1),
                  },
                ],
              },
              'in-store-purchase': {
                total: formatNumber(data.p_off.percent, '0%'),
                name: $i18n.tc('in-store'),
                label: $i18n.t('omnichannel-overview.purchase-was'),
                description: $i18n.t('omnichannel-overview.where-people'),
                sankey: [
                  {
                    percent: formatNumber(data.p_off.from.off.percent, '0%'),
                    color: '#4084CB',
                    label: $i18n.t('omnichannel-overview.in-store'),
                    total: formatDecimal(data.p_off.from.off.orders, 1),
                  },
                  {
                    percent: formatNumber(data.p_off.from.on.percent, '0%'),
                    color: '#A167D9',
                    label: $i18n.t('omnichannel-overview.in-e-commerce'),
                    total: formatDecimal(data.p_off.from.on.orders, 1),
                  },
                  {
                    percent: formatNumber(data.p_off.from.omni.percent, '0%'),
                    color: '#EF4C8B',
                    label: $i18n.tc('omnichannel-overview.in-omni'),
                    total: formatDecimal(data.p_off.from.omni.orders, 1),
                  },
                ],
              },
              'e-commerce': {
                total: formatNumber(data.i_on.percent, '0%'),
                name: $i18n.tc('ecommerce'),
                label: $i18n.t('omnichannel-overview.interest-was'),
                description: $i18n.t('omnichannel-overview.where-consider'),
                sankey: [
                  {
                    percent: formatNumber(data.i_on.to.off.percent, '0%'),
                    color: '#4084CB',
                    label: $i18n.t('omnichannel-overview.in-store'),
                    total: formatDecimal(data.i_on.to.off.orders, 1),
                  },
                  {
                    percent: formatNumber(data.i_on.to.on.percent, '0%'),
                    color: '#A167D9',
                    label: $i18n.t('omnichannel-overview.in-e-commerce'),
                    total: formatDecimal(data.i_on.to.on.orders, 1),
                  },
                  {
                    percent: formatNumber(data.i_on.to.omni.percent, '0%'),
                    color: '#EF4C8B',
                    label: $i18n.tc('omnichannel-overview.in-omni'),
                    total: formatDecimal(data.i_on.to.omni.orders, 1),
                  },
                ],
              },
              'e-commerce-consideration': {
                total: formatNumber(data.c_on.percent, '0%'),
                name: $i18n.tc('ecommerce'),
                label: $i18n.t('omnichannel-overview.consider-was'),
                description: $i18n.t('omnichannel-overview.where-purchase'),
                sankey: [
                  {
                    percent: formatNumber(data.c_on.to.off.percent, '0%'),
                    color: '#4084CB',
                    label: $i18n.t('omnichannel-overview.in-store'),
                    total: formatDecimal(data.c_on.to.off.orders, 1),
                  },
                  {
                    percent: formatNumber(data.c_on.to.on.percent, '0%'),
                    color: '#A167D9',
                    label: $i18n.t('omnichannel-overview.in-e-commerce'),
                    total: formatDecimal(data.c_on.to.on.orders, 1),
                  },
                ],
              },
              'e-commerce-purchase': {
                total: formatNumber(data.p_on.percent, '0%'),
                name: $i18n.tc('ecommerce'),
                label: $i18n.t('omnichannel-overview.purchase-was'),
                description: $i18n.t('omnichannel-overview.where-people'),
                sankey: [
                  {
                    percent: formatNumber(data.p_on.from.off.percent, '0%'),
                    color: '#4084CB',
                    label: $i18n.t('omnichannel-overview.in-store'),
                    total: formatDecimal(data.p_on.from.off.orders, 1),
                  },
                  {
                    percent: formatNumber(data.p_on.from.on.percent, '0%'),
                    color: '#A167D9',
                    label: $i18n.t('omnichannel-overview.in-e-commerce'),
                    total: formatDecimal(data.p_on.from.on.orders, 1),
                  },
                  {
                    percent: formatNumber(data.p_on.from.omni.percent, '0%'),
                    color: '#EF4C8B',
                    label: $i18n.tc('omnichannel-overview.in-omni'),
                    total: formatDecimal(data.p_on.from.omni.orders, 1),
                  },
                ],
              },
              'omni-consideration': {
                total: formatNumber(data.c_omni.percent, '0%'),
                name: $i18n.tc('omnichannel-overview.omni'),
                label: $i18n.t('omnichannel-overview.consider-was'),
                description: $i18n.t('omnichannel-overview.where-purchase'),
                sankey: [
                  {
                    percent: formatNumber(data.c_omni.to.off.percent, '0%'),
                    color: '#4084CB',
                    label: $i18n.t('omnichannel-overview.in-store'),
                    total: formatDecimal(data.c_omni.to.off.orders, 1),
                  },
                  {
                    percent: formatNumber(data.c_omni.to.on.percent, '0%'),
                    color: '#A167D9',
                    label: $i18n.t('omnichannel-overview.in-e-commerce'),
                    total: formatDecimal(data.c_omni.to.on.orders, 1),
                  },
                ],
              },
            };

            const { total, sankey, name, label, description } = percent[this.point.from || this.key];

            /* eslint-disable */
            const totalS = `
              <div>
                <strong>${total}</strong> ${label}
                <span style="font-weight: 600; color: ${
                  this.color.stops ? this.color.stops[0][1] : this.color
                }">${name}</span>
              </div>
              <br>
            `;
            const item1 = () => `
              <div class="item">
                <div class="point" style="background-color: ${sankey[0].color}"></div>
                <strong>${sankey[0].percent}</strong> ${sankey[0].label} <span class="gray">(${sankey[0].total})</span>
              </div>
            `;
            const item2 = () => `
              <div class="item">
                <div class="point" style="background-color: ${sankey[1].color}"></div>
                <strong>${sankey[1].percent}</strong> ${sankey[1].label} <span class="gray">(${sankey[1].total})</span>
              </div>
            `;
            const item3 = () => `
              <div class="item">
                <div class="point" style="background-color: ${sankey[2].color}"></div>
                <strong>${sankey[2].percent}</strong> ${sankey[2].label} <span class="gray">(${sankey[2].total})</span>
              </div>
            `;
            const s = `
              <div>
                ${totalS}
                <div>${description}</div>
                ${sankey[0] ? item1() : ''}
                ${sankey[2] ? item3() : ''}
                ${sankey[1] ? item2() : ''}
              </div>
            `;
            /* eslint-enable */
            return s;
          },
        },
        colors: [offSolid, offSolid, omniSolid, onSolid, onSolid, offSolid, onSolid],
        series: [
          {
            keys: ['from', 'to', 'weight', 'color'],
            data: [
              [
                'in-store',
                'in-store-consideration',
                data.i_off.to.off.orders,
                {
                  linearGradient,
                  stops: [
                    [0, offAlpha],
                    [1, offAlpha],
                  ],
                },
              ],
              [
                'in-store',
                'omni-consideration',
                data.i_off.to.omni.orders,
                {
                  linearGradient,
                  stops: [
                    [0, offAlpha],
                    [1, omnipAlpha],
                  ],
                },
              ],
              [
                'in-store',
                'e-commerce-consideration',
                data.i_off.to.on.orders,
                {
                  linearGradient,
                  stops: [
                    [0, offAlpha],
                    [1, onAlpha],
                  ],
                },
              ],
              [
                'e-commerce',
                'in-store-consideration',
                data.i_on.to.off.orders,
                {
                  linearGradient,
                  stops: [
                    [0, onAlpha],
                    [1, offAlpha],
                  ],
                },
              ],
              [
                'e-commerce',
                'omni-consideration',
                data.i_on.to.omni.orders,
                {
                  linearGradient,
                  stops: [
                    [0, onAlpha],
                    [1, omnipAlpha],
                  ],
                },
              ],
              [
                'e-commerce',
                'e-commerce-consideration',
                data.i_on.to.on.orders,
                {
                  linearGradient,
                  stops: [
                    [0, onAlpha],
                    [1, onAlpha],
                  ],
                },
              ],
              [
                'in-store-consideration',
                'in-store-purchase',
                data.c_off.to.off.orders,
                {
                  linearGradient,
                  stops: [
                    [0, offAlpha],
                    [1, offAlpha],
                  ],
                },
              ],
              [
                'in-store-consideration',
                'e-commerce-purchase',
                data.c_off.to.on.orders,
                {
                  linearGradient,
                  stops: [
                    [0, offAlpha],
                    [1, onAlpha],
                  ],
                },
              ],
              [
                'omni-consideration',
                'in-store-purchase',
                data.c_omni.to.off.orders,
                {
                  linearGradient,
                  stops: [
                    [0, omnipAlpha],
                    [1, offAlpha],
                  ],
                },
              ],
              [
                'omni-consideration',
                'e-commerce-purchase',
                data.c_omni.to.on.orders,
                {
                  linearGradient,
                  stops: [
                    [0, omnipAlpha],
                    [1, onAlpha],
                  ],
                },
              ],
              [
                'e-commerce-consideration',
                'in-store-purchase',
                data.c_on.to.off.orders,
                {
                  linearGradient,
                  stops: [
                    [0, onAlpha],
                    [1, offAlpha],
                  ],
                },
              ],
              [
                'e-commerce-consideration',
                'e-commerce-purchase',
                data.c_on.to.on.orders,
                {
                  linearGradient,
                  stops: [
                    [0, onAlpha],
                    [1, onAlpha],
                  ],
                },
              ],
            ],
            type: 'sankey',
            name: ' ',
          },
        ],
      });
    },
    fetch_data() {
      this.$_reqConfig();

      return getMetrics({
        metrics: 'icp_omnichannel_overview',
      })
        .then(data => {
          if (this.$_verifyHasData(data.data.metrics)) return;
          const ICP = data.data.metrics.icp_omnichannel_overview;
          this.data = ICP.data;
          this.hasData = ICP.has_data;
          return this.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setup() {
      this.fetch_data()
        .then(data => {
          if (this.$_verifyData(data)) return;
          if (this.hasData) this.setupSankey(data, this.$i18n);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        });
    },
  },
};
</script>

<style lang="scss">
#omnichanneloverview-sankey {
  // Desktop
  @media (min-width: $xl-min-width) {
    width: 700px;
    min-width: 700px;
  }

  // Tablet horizontal
  @media (max-width: $tablet-max-width) and (min-width: $lg-min-width) {
    width: 80%;
  }

  // Tablet vertical
  @media (max-width: $md-max-width) {
    width: 75%;
  }
  max-width: 780px;

  svg > rect {
    fill: none;
  }

  .highcharts-data-label {
    display: none;
  }
  .item {
    margin-top: 3px;

    .point {
      width: 7px;
      height: 7px;
      display: inline-block;
      background-color: black;
      border-radius: 30px;
      margin-bottom: 1px;
      margin-right: 4px;
    }

    .gray {
      color: $gray-600;
    }
  }
}
</style>

<style lang="scss" scoped>
.card {
  min-height: 497px;

  .card-body {
    display: flex;
    justify-content: center;
    padding-right: 40px;
    padding-top: 50px;
  }
  .labels-grid {
    // position: absolute;
    display: flex;
    flex-direction: column;
    height: 330px;
    margin: 0 20px;
    min-width: 140px;

    &.left {
      text-align: right;
    }

    &.right {
      text-align: left;
    }

    .label-grid {
      flex: 1;
      display: flex;
      align-items: center;
      margin: 0;

      .content-label {
        width: 100%;
        display: block;
        & > * {
          margin: 3px 0;
          line-height: 15px;
        }

        .big {
          font-weight: 600;
          font-size: 18px;
        }

        .chart-title span {
          color: $gray-600;
          font-weight: 400;
        }

        .bar-chart-text span {
          font-weight: 600;
        }

        .color-purple-200 {
          color: $purple-200 !important;
        }
        .color-oto-ecommerce {
          color: $oto-ecommerce !important;
        }
        .color-blue-300 {
          color: $blue-300 !important;
        }
      }
    }
  }
  .card-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    width: 90%;
    margin: 0 70px 0 auto;
    padding-bottom: 50px;
    .label-center {
      color: #999;
      font-size: 12px;
    }
  }
}
</style>
