<template>
  <div>
    <section id="campaign">
      <campaigns-management-header />
      <campaigns-management-cards />
      <campaigns-management-priorities-modal />
      <campaigns-management-dialog-error-modal />
    </section>
    <load-spinner id="actions-campaings" color="purple" />
  </div>
</template>

<script>
// components
import CampaignsManagementHeader from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementHeader';
import CampaignsManagementCards from '@/components/oto/campaignsManagement/_organisms/CampaignsManagementCards';
import CampaignsManagementPrioritiesModal from '@/components/oto/campaignsManagement/modals/CampaignsManagementPrioritiesModal';
import CampaignsManagementDialogErrorModal from '@/components/oto/campaignsManagement/modals/CampaignsManagementDialogErrorModal';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import { validateCampaignActivationRoute } from '@/helpers/verify-routes';

export default {
  name: 'campaignsManagement',
  components: {
    CampaignsManagementHeader,
    CampaignsManagementCards,
    CampaignsManagementPrioritiesModal,
    CampaignsManagementDialogErrorModal,
    LoadSpinner,
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    checkAndRedirect() {
      if (!validateCampaignActivationRoute('360-campaigns')) this.$router.push('/oto/actions');
    },
  },
  mounted() {
    this.checkAndRedirect();
  },
};
</script>
