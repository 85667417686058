import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = '/user';

/**
 * @description get user routes by brand
 */
export const getRoutes = brandP => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const resBrand = brandP ? brandP : brand;

  return axios.get(`${URI}/routes`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: resBrand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
