<template>
  <div :id="id" class="table-list col-12" :class="type">
    <slot />
    <footer-skeleton v-if="loading" :is-lightbox-active="isLightboxActive" />
    <table-list-footer
      v-if="data.count && !loading && !info"
      :type="type"
      :data="data"
      :limit-range="limitRange"
      :identify="id"
      :is-lightbox-active="isLightboxActive"
    />
  </div>
</template>

<script>
import ListPagination from '@/components/_atoms/ListPagination';
import ListPaginationSkeleton from '@/components/_atoms/ListPaginationSkeleton';

export default {
  components: {
    'table-list-footer': ListPagination,
    'footer-skeleton': ListPaginationSkeleton,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    data: {
      type: [Array, Object],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: null,
    },
    isLightboxActive: {
      type: Boolean,
      default: null,
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limitRange: [5, 10, 20, 30, 50],
    };
  },
};
</script>

<style lang="scss" scoped>
.table-list {
  color: $gray-800;
}
</style>

<style lang="scss">
.table-list {
  margin-bottom: 12px;
  .pg-arrows {
    display: block;
    color: #fff;
    border: solid $gray-900;
    border-width: 0 1px 1px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;

    &.previous-arrow {
      transform: rotate(135deg);
      margin-right: -3px;
    }

    &.next-arrow {
      transform: rotate(-45deg);
      margin-left: -3px;
    }

    &.down-arrow {
      transform: translate(0, -50%) rotate(45deg);
    }

    &.up-arrow {
      transform: rotate(-135deg);
    }
  }
}
</style>
