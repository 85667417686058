// libs
import { mapValues, without, maxBy } from 'lodash';
// helpers
import formatter from '@/helpers/oto/seller-metrics-formatter';

// endpoints
import {
  getSellers,
  getSellerMetrics,
  getConversionFunnel,
  getSellerSuggestionsTable,
  getParallelCoordinates,
  getAvailableMetrics,
  getSellersResume,
} from '@/services/oto/sellers';

const sellersAnalytics = {
  namespaced: true,
  state: {
    firstLoad: null,
    sellersList: [],
    sellerSelected: null,
    sellerMetrics: {
      loading: true,
      empty: false,
      error: false,
      data: [...Array(8)],
    },
    sellerFunnel: {
      loading: true,
      empty: false,
      error: false,
      data: [],
    },
    sellerSuggestions: {
      loading: true,
      error: false,
      empty: false,
      data: [...Array(8)],
    },
    filters: {
      metrics: ['conversionRate', 'followUps', 'averageOrderValue', 'revenue', 'averageLookback'],
      comparisonStores: [],
    },
    criteria: 'cluster',
    parallelCoordinates: {
      loading: false,
      data: [],
    },
    availableMetrics: {
      loading: true,
      data: [],
    },
    sellerResumeList: [],
  },
  mutations: {
    SET_LOADING(state, obj) {
      const componentType = obj['type'];
      const isLoading = obj['verifyState'];

      switch (componentType) {
        case 'metrics':
          state.sellerMetrics.loading = isLoading;
          break;
        case 'funnel':
          state.sellerFunnel.loading = isLoading;
          break;
        case 'campaignsManagement':
          state.sellerSuggestions.loading = isLoading;
          break;
        case 'parallel':
          state.parallelCoordinates.loading = isLoading;
          break;
      }
    },
    SET_ERROR(state, obj) {
      const componentType = obj['type'];
      const isError = obj['verifyState'];

      switch (componentType) {
        case 'metrics':
          state.sellerMetrics.error = isError;
          break;
        case 'funnel':
          state.sellerFunnel.error = isError;
          break;
        case 'campaignsManagement':
          state.sellerSuggestions.error = isError;
          break;
      }
    },
    SET_EMPTY(state, obj) {
      const componentType = obj['type'];
      const isEmpty = obj['verifyState'];

      switch (componentType) {
        case 'metrics':
          state.sellerMetrics.empty = isEmpty;
          break;
        case 'funnel':
          state.sellerFunnel.empty = isEmpty;
          break;
        case 'campaignsManagement':
          state.sellerSuggestions.empty = isEmpty;
          break;
        case 'parallel':
          state.parallelCoordinates.empty = isEmpty;
          break;
      }
    },
    ADD_SELLERS(state, sellersList) {
      state.sellersList = sellersList;
    },
    CHANGE_SELLERSELECTED(state, sellerSelected) {
      state.sellerSelected = sellerSelected;
    },
    SET_SELLERMETRICS(state, metrics) {
      state.sellerMetrics.data = metrics;
    },
    SET_SELLERFUNNEL(state, funnel) {
      state.sellerFunnel.data = funnel;
    },
    SET_SELLERSUGGESTIONS(state, suggestions) {
      state.sellerSuggestions.data = suggestions;
      state.sellerSuggestions.loading = false;
    },
    SET_PARALLELCOORDINATES(state, parallelCoordinates) {
      state.parallelCoordinates.data = parallelCoordinates;
      state.parallelCoordinates.loading = false;
    },
    SET_AVAILABLEMETRICS(state, availableMetrics) {
      state.availableMetrics.data = availableMetrics;
      state.availableMetrics.loading = false;
    },
    SET_SELLER_RESUME_LIST(state, sellers) {
      state.sellerResumeList = sellers;
    },
    RESET_FILTERS(state) {
      state.filters = {
        metrics: ['conversionRate', 'followUps', 'averageOrderValue', 'revenue', 'averageLookback'],
        comparisonStores: [],
      };
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },

    SET_CRITERIA(state, criteria) {
      state.criteria = criteria;
    },
  },
  actions: {
    setSellersList({ commit, dispatch, state }) {
      getSellers()
        .then(data => {
          commit('ADD_SELLERS', data.data);
          state.firstLoad = true;
          dispatch('setSellerSelected', data.data[0].id);
        })
        .catch(() => {
          commit('SET_LOADING', { type: 'metrics', verifyState: false });
          commit('SET_ERROR', { type: 'metrics', verifyState: true });
          commit('SET_LOADING', { type: 'funnel', verifyState: false });
          commit('SET_ERROR', { type: 'funnel', verifyState: true });
        });
    },
    setSellerSelected({ commit, dispatch }, sellerId) {
      commit('CHANGE_SELLERSELECTED', sellerId);
      dispatch('setSellerMetrics');
      dispatch('setSellerFunnel');
      dispatch('setSellerSuggestions');
    },
    setSellerMetrics({ state, commit }) {
      commit('SET_LOADING', { type: 'metrics', verifyState: true });
      commit('SET_ERROR', { type: 'metrics', verifyState: false });
      commit('SET_EMPTY', { type: 'metrics', verifyState: false });

      getSellerMetrics(state.sellerSelected)
        .then(({ data }) => {
          const metrics = data?.data;

          if (metrics.length === 0) {
            commit('SET_EMPTY', { type: 'metrics', verifyState: true });
            return;
          }
          let isEmpty = true;
          for (const e in metrics) {
            const verifyIsEmpty = !metrics[e]?.hasData;
            if (verifyIsEmpty === false) isEmpty = false;
          }
          if (isEmpty === true) {
            commit('SET_EMPTY', { type: 'metrics', verifyState: isEmpty });
            return;
          }

          commit(
            'SET_SELLERMETRICS',
            without(Object.keys(metrics), 'followUpsRate', 'ignoredSuggestionsRate').map(key => ({
              key,
              value: formatter(key, metrics[key].value, metrics),
              hasdata: metrics[key]?.hasData,
            })),
          );
        })
        .catch(() => {
          commit('SET_LOADING', { type: 'metrics', verifyState: false });
          commit('SET_ERROR', { type: 'metrics', verifyState: true });
        })
        .finally(() => {
          commit('SET_LOADING', { type: 'metrics', verifyState: false });
        });
    },
    setSellerFunnel({ state, commit }) {
      commit('SET_LOADING', { type: 'funnel', verifyState: true });
      commit('SET_ERROR', { type: 'funnel', verifyState: false });

      getConversionFunnel(state.sellerSelected)
        .then(({ data }) => {
          const funnel = data.data;
          if (funnel.length === 0) {
            commit('SET_EMPTY', { type: 'funnel', verifyState: true });
            return;
          } else commit('SET_EMPTY', { type: 'funnel', verifyState: false });
          commit(
            'SET_SELLERFUNNEL',
            mapValues(funnel, (metricValue, metricName) => formatter(metricName, metricValue)),
          );
        })
        .catch(() => {
          commit('SET_LOADING', { type: 'funnel', verifyState: false });
          commit('SET_ERROR', { type: 'funnel', verifyState: true });
        })
        .finally(() => {
          commit('SET_LOADING', { type: 'funnel', verifyState: false });
        });
    },
    setSellerSuggestions({ state, commit }) {
      commit('SET_LOADING', { type: 'campaignsManagement', verifyState: true });

      getSellerSuggestionsTable(state.sellerSelected)
        .then(({ data }) => {
          const suggestions = data.data;
          if (suggestions.length === 0) {
            commit('SET_EMPTY', { type: 'campaignsManagement', verifyState: true });
            return;
          } else commit('SET_EMPTY', { type: 'campaignsManagement', verifyState: false });
          commit('SET_SELLERSUGGESTIONS', suggestions);
        })
        .catch(() => {
          commit('SET_LOADING', { type: 'campaignsManagement', verifyState: false });
          commit('SET_ERROR', { type: 'campaignsManagement', verifyState: true });
        })
        .finally(() => {
          commit('SET_LOADING', { type: 'campaignsManagement', verifyState: false });
        });
    },
    resetFilters({ commit }) {
      commit('RESET_FILTERS');
    },
    setFilters({ commit }, payload) {
      commit('SET_FILTERS', payload);
    },

    setCriteriaName({ commit }, payload) {
      commit('SET_CRITERIA', payload);
    },
    setParallelCoordinates({ state, commit }) {
      commit('SET_LOADING', { type: 'parallel', verifyState: true });
      commit('SET_EMPTY', { type: 'parallel', verifyState: false });
      getParallelCoordinates(state.sellerSelected, state.filters.comparisonStores.join(), state.filters.metrics.join())
        .then(({ data }) => {
          const parallelCoordinates = data.data;
          commit('SET_PARALLELCOORDINATES', parallelCoordinates);
          if (!state.parallelCoordinates.data.series?.length) {
            commit('SET_EMPTY', { type: 'parallel', verifyState: true });
          }
        })
        .finally(() => {
          commit('SET_LOADING', { type: 'parallel', verifyState: false });
          commit('SET_EMPTY', { type: 'parallel', verifyState: false });
        });
    },
    clearParallelCoordinates({ commit }) {
      commit('SET_PARALLELCOORDINATES', []);
    },
    setAvailableMetrics({ commit }) {
      getAvailableMetrics().then(({ data }) => {
        commit('SET_AVAILABLEMETRICS', data.data);
      });
    },
    setSellersResumeList({ commit, dispatch }) {
      getSellersResume()
        .then(({ data }) => {
          if (data.success) {
            commit('SET_SELLER_RESUME_LIST', data.data);
            dispatch('setSellerSelected', data.data[0].id);
          }
        })
        .catch(() => {
          commit('SET_LOADING', { type: 'metrics', verifyState: false });
          commit('SET_ERROR', { type: 'metrics', verifyState: true });
          commit('SET_LOADING', { type: 'funnel', verifyState: false });
          commit('SET_ERROR', { type: 'funnel', verifyState: true });
        });
    },
    setSellersByTerm({ commit }, { term } = {}) {
      getSellersResume({ term })
        .then(({ data }) => {
          if (data.success) {
            commit('SET_SELLER_RESUME_LIST', data.data);
          }
        })
        .catch(() => {
          commit('SET_LOADING', { type: 'metrics', verifyState: false });
          commit('SET_ERROR', { type: 'metrics', verifyState: true });
          commit('SET_LOADING', { type: 'funnel', verifyState: false });
          commit('SET_ERROR', { type: 'funnel', verifyState: true });
        });
    },
  },
  getters: {
    getFilters: state => state.filters,
    getCriteria: state => state.criteria,
    getSellerMetricsError: state => state.sellerMetrics.error,
    getSellerMetricsLoading: state => state.sellerMetrics.loading,
    getSellerMetricsEmpty: state => state.sellerMetrics.empty,
    getSellerFunnelError: state => state.sellerFunnel.error,
    getSellerFunnelLoading: state => state.sellerFunnel.loading,
    getSellerFunnelEmpty: state => state.sellerFunnel.empty,
    getSellerSuggestionsError: state => state.sellerSuggestions.error,
    getSellerSuggestionsLoading: state => state.sellerSuggestions.loading,
    getSellerSuggestionsEmpty: state => state.sellerSuggestions.empty,
    maxSuggestionRate: state =>
      maxBy(state.sellerSuggestions.data, suggestion => suggestion.conversionRate).conversionRate,
    getSellerObject: state => sellerId => state.sellerResumeList.filter(seller => seller.id === sellerId)[0],
    getActiveSellerObject: (state, getters) => getters.getSellerObject(state.sellerSelected),
    parallelCoordinatesHasData: state => state.parallelCoordinates.data.series?.length,
    getSellerResumeList: state => state.sellerResumeList,
  },
};

export default sellersAnalytics;
