<template>
  <div class="profile-card card">
    <div v-show="$_verifyLoaded('done')" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-customer"></i>
        <span>{{ $t('profiling-title') }}</span>
      </h2>
      <div class="row">
        <div class="col-sm-12 col-xl-6">
          <chart-list :chart-name="$t('most-filled-info')" :rows-list="objectChartListMostProfiling" />
        </div>
        <div class="col-sm-12 col-xl-6 mt-4 mt-xl-0">
          <chart-list :chart-name="$t('least-filled-info')" :rows-list="objectChartListLessProfilling" />
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// @ is an alias to /src
import _ from 'lodash';
import getMetrics from '@/services/getmetrics';
import { getPercentual, getNumeral } from '@/utilities/numbers';
import { formatPercent } from '@/utilities/formatters';
import ChartList from '@/components/_organisms/ChartList';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  components: {
    'chart-list': ChartList,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    numOfRows: {
      type: Number,
      default() {
        return 5;
      },
    },
    filterStore: {
      type: Boolean,
      default() {
        return false;
      },
    },
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataReturn: [],
      selectedStore: '-100',
      objectChartListMostProfiling: [],
      objectChartListLessProfilling: [],
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        if (this.filterStore === false) {
          this.fetch_data();
          bus.$on('render-cards', () => {
            this.fetch_data();
          });
        }
      }
    },
  },
  mounted() {
    bus.$on('store-changed', value => {
      this.selectedStore = value;
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('store-changed');
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'arr_field_filled',
        ...(this.filterStore && {
          endpoint: 'stores/get_metrics',
          filters: `store_id:${this.selectedStore}`,
        }),
      })
        .then(response => {
          const seriesData = response.data.metrics.arr_field_filled.data.series[0].data;
          if (this.$_verifyHasData(response.data.metrics)) return;

          // Chart: Mais preenchidos
          this.objectChartListMostProfiling = [];
          const seriesDesc = _(seriesData).toPairs().orderBy(1, 'desc').fromPairs().value();
          const maxMostProfilling = getNumeral(_.first(_.values(seriesDesc)));
          _.each(seriesDesc, (val, key) => {
            if (this.objectChartListMostProfiling.length >= this.numOfRows) return;
            this.objectChartListMostProfiling.push({
              label: key,
              progress: String(getPercentual(val, maxMostProfilling)),
              value: formatPercent(val / 100, 1),
              color: 'lightblue',
              tooltip: undefined,
            });
          });

          // Chart: Menos preenchidos
          this.objectChartListLessProfilling = [];
          const seriesAsc = _(seriesData).toPairs().orderBy(1, 'asc').fromPairs().value();
          const maxLessProfilling = getNumeral(
            _.size(seriesAsc) >= this.numOfRows ? _.values(seriesAsc)[this.numOfRows - 1] : _.last(_.values(seriesAsc)),
          );
          _.each(seriesAsc, (val, key) => {
            if (this.objectChartListLessProfilling.length >= this.numOfRows) return;
            this.objectChartListLessProfilling.push({
              label: key,
              progress: String(getPercentual(val, maxLessProfilling)),
              value: formatPercent(val / 100),
              color: 'lightblue',
              tooltip: undefined,
            });
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-card {
  height: 245px;
}
.title {
  height: 50px;
  font-size: 0.8em;
  font-weight: bold;
}
.card-pb-20 .card {
  padding-bottom: 20px;
}
</style>
