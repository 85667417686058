<template>
  <div id="app">
    <template v-if="!loading">
      <router-view></router-view>
      <lightboxes />
    </template>
    <loader-spinner v-if="loading" />
  </div>
</template>

<script>
// libs
import 'bootstrap';
import _ from 'lodash';
import axios from 'axios';
import axiosDefaults from 'axios/lib/defaults';
import { useToast } from 'vue-toastification';
import { provide } from 'vue';
// helpers
import bus from '@/helpers/events/bus';
import { setUserRead } from '@/helpers/warnings';
import endpointVersion from '@/helpers/endpoint-version';
import hasPermission from '@/helpers/permissions';
// utilities
import { permissionsSupport } from '@/utilities/accessLevel/WidgetSupportPermissions';
import { supportFreshdesk } from '@/utilities/freshdesk/supportFreshdesk';
// services
import { getTokenStatus } from '@/services/auth';
// views
import Lightboxes from '@/views/Lightboxes';
// components - atoms
import LoaderSpinner from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'App',
  title() {
    return 'Oto';
  },
  components: {
    Lightboxes,
    LoaderSpinner,
  },
  setup() {
    provide('languages', ['pt-BR', 'en']);
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      mfa: 'mfa',
      loading: true,
    };
  },
  computed: {
    getLanguage() {
      return this.$store.getters.user?.language;
    },
  },
  watch: {
    $route(to) {
      this.updatePageTitle(to);

      bus.$emit('dismiss-toasts');
      this.fetchTokenStatus();
    },
    getLanguage() {
      this.updatePageTitle(this.$route);
    },
  },
  created() {
    this.showWidgetSupport(permissionsSupport);
    this.fetchInterceptors();
    this.fetchTokenStatus();
    bus.$on('dismiss-toasts', () => {
      this.showWidgetSupport(permissionsSupport);
      this.toast.clear();
    });
    bus.$on('set-baseUrl', () => {
      this.handleBaseUrl();
    });
    bus.$on('start-change-brand', () => {
      this.loading = true;
    });
    bus.$on('finish-change-brand', () => {
      this.loading = false;
    });
  },
  beforeUnmount() {
    bus.$off('set-baseUrl');
  },
  methods: {
    updatePageTitle(route) {
      const isEmptyMeta = !route.meta?.title || Object.keys(route.meta.title).length === 0;

      const hasStore = !!this.$store && this.$store.getters.user;
      this.$i18n.locale = hasStore ? this.$store.getters.user.language : 'pt-BR';

      if (isEmptyMeta) {
        document.title = 'Oto. Quem conhece, vende.';
      } else {
        document.title = `Oto | ${this.$t('header.sidebar.' + route.meta.title)}`;
      }
      bus.$emit('dismiss-toasts');
      this.fetchTokenStatus();
    },

    fetchTokenStatus() {
      const isLoggedIn = this.$store?.getters?.isLoggedIn;
      if (!isLoggedIn) {
        this.loading = false;
        return;
      }

      getTokenStatus()
        .then(({ data }) => {
          if (!data.data?.authenticated) this.dispatchToLogout();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchInterceptors() {
      axios.interceptors.response.use(
        response => {
          if (_.size(response?.data?.warnings)) {
            const warningsList = response?.data?.warnings;
            _.forIn(warningsList, (message, id) => {
              this.toast.warning(message, {
                id,
                onClick: () => setUserRead(id),
              });
            });
          }

          if (this.hasStatus401(response)) this.dispatchToLogout();

          return response;
        },
        error => {
          if (this.hasStatus401(error.response) && error.response?.data?.errors?.error_id === 'mfa_error') {
            bus.$emit('mfa-change', this.mfa);
            return;
          }
          if (this.hasStatus401(error.response) && error.response.data.errors.error_id === 'mfa_must_validate') {
            bus.$emit('mfa-must-validate');
            return;
          }
          if (this.hasStatus401(error.response)) this.dispatchToLogout();
        },
      );
    },
    hasStatus401(response) {
      return response?.status === 401;
    },
    dispatchToLogout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/login'));
    },
    isAt(route) {
      return this.$router.currentRoute.value.matched.includes(route);
    },
    handleBaseUrl() {
      axiosDefaults.baseURL = endpointVersion();
    },
    showWidgetSupport(permissionsSupport) {
      const widget = hasPermission(permissionsSupport);
      supportFreshdesk(widget);
    },
  },
};
</script>
<style lang="scss">
@import '~bootstrap/scss/bootstrap';
@import '~daterangepicker/daterangepicker.css';
</style>
