<template>
  <lightbox-template
    id="lightbox-phones"
    :title="$t('360.person.lightbox-phones.title')"
    :small="true"
    :show-segment-group="false"
    :icon="''"
    :show-date="false"
  >
    <div class="container">
      <ul>
        <li v-if="contacts.phone">
          <i class="icon icon-phone-18px"></i>
          <span v-if="contacts.phone.value">
            <b>{{ $t('360.person.lightbox-phones.phone') }}</b>
            {{ contacts.phone.value }}
          </span>
          <span v-else class="empty">{{ $t('360.person.empty.phone') }}</span>
        </li>
        <li v-if="contacts.sms">
          <i class="icon icon-phone-18px"></i>
          <span v-if="contacts.sms.value">
            <b>{{ $t('360.person.lightbox-phones.mobile') }}</b>
            {{ contacts.sms.value }}
          </span>
          <span v-else class="empty">{{ $t('360.person.empty.phone') }}</span>
        </li>
        <li v-if="contacts.whatsapp">
          <i class="icon icon-whatsapp-18px"></i>
          <span v-if="contacts.whatsapp.value"> <b>Whatsapp</b> {{ contacts.whatsapp.value }} </span>
          <span v-else class="empty">{{ $t('360.person.empty.phone') }}</span>
        </li>
      </ul>
      <div class="dialog">
        <button class="btn confirm" @click="close">
          {{ $t('360.close') }}
        </button>
      </div>
    </div>
  </lightbox-template>
</template>

<script>
import { closeLightbox } from '@/helpers/lightbox';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';

export default {
  name: 'LightboxPhones',
  components: {
    LightboxTemplate,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    contacts() {
      return this.settings.contacts;
    },
  },
  methods: {
    close() {
      closeLightbox();
    },
  },
};
</script>

<style lang="scss">
#lightbox-phones {
  ul {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    li {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #666;
      padding: 10px 5px;
      overflow: hidden;

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }

      .icon {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
  .dialog {
    display: flex;
    justify-content: center;
  }
}
</style>
