<template>
  <div class="logo-container">
    <transition name="fade">
      <router-link :to="getDefautRoute ? getDefautRoute : '/'">
        <picture v-show="collapsedSidebar">
          <source media="(min-width: 992px)" srcset="@/assets/img/oto/logo/flat/oto_logo_flat_small.png" />
          <img src="@/assets/img/oto/logo/flat/oto_logo_flat_small.png" alt="Oto logo" title="oto" />
        </picture>
      </router-link>
    </transition>
    <transition name="fade1">
      <picture v-show="!collapsedSidebar">
        <source media="(min-width: 992px)" srcset="@/assets/img/oto/logo/flat/oto_logo_flat_smile.png" />
        <img src="@/assets/img/oto/logo/flat/oto_logo_flat_smile.png" alt="Oto logo" title="oto" />
      </picture>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'HeaderLogo',
  props: {
    collapsedSidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getDefautRoute() {
      return this.$store.getters.getDefaultRoute;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  align-items: center;
  display: flex;
  flex-basis: 264px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 70px;
  padding: 0 0 0 17px;

  img {
    height: 30px;
  }

  .fade-enter-active {
    transition: opacity 0s;
  }
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter {
    opacity: 1;
  }
  .fade-leave-to {
    opacity: 0;
  }

  .fade1-enter-active {
    transition: opacity 1s;
  }
  .fade1-leave-active {
    transition: opacity 0s;
  }
  .fade1-enter {
    opacity: 0;
  }
  .fade1-leave-to {
    opacity: 1;
  }
}
</style>
