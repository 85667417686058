<template>
  <div id="component-itempurchase" class="modal-table-wrapper scrollDiv customScrollBar">
    <div v-if="propData.data?.length > 0" class="purchases-list">
      <div
        v-for="(item, index) in propData.data"
        :key="index"
        class="row list-item"
        @click="selectOrderForOpenDetails(item)"
      >
        <div class="col-md-9 col-6 d-flex">
          <div class="icon-line icon-line pr-3">
            <i class="icon" :class="`${item.type === 'on' ? 'icon-ecommerce' : 'icon-in-store'}`"></i>
          </div>
          <div class="detail d-flex flex-column">
            <p>
              {{ $t('360.components.item-last-purchase.purchased-in') }}
              <strong class="date-desktop">{{ formatDate(item.date) }}</strong>
              <strong class="date-mobile">
                {{ formatDateMobile(item.date) }}
              </strong>
              ({{ dayNameDate(item.date) }})
            </p>
            <p v-if="item.type == 'on'" class="cl-88">
              {{ $t('360.components.item-last-purchase.in-ecommerce') }}
              <strong class="color-oto-ecommerce">{{ item.store }}</strong>
            </p>
            <p v-else class="cl-88">
              {{ $t('360.components.item-last-purchase.in-store') }}
              <strong class="color-purple-200">{{ item.store }}</strong>
            </p>
          </div>
        </div>
        <div class="col-md-3 col-6 column-values text-right">
          <p>
            {{ item.items }}
            {{ $tc('360.components.item-last-purchase.product', item.items) }}
          </p>
          <p v-if="item.return == -1" class="color-red mb-1">
            {{ item.return * -1 }}
            {{ $tc('360.components.item-last-purchase.return', 1) }}
          </p>
          <p v-if="item.return < -1" class="color-red mb-1">
            {{ item.return * -1 }}
            {{ $tc('360.components.item-last-purchase.return', 0) }}
          </p>
          <span class="chart-value-big color-oto-brand-omni">
            {{ formatCurrency(item.amount) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { numberMixin } from '@/helpers/mixins';
import { openLightbox } from '@/helpers/lightbox';
// helpers
import { dateWeekDay, dateCompleteVariation } from '@/utilities/constants';

export default {
  name: 'CustomerViewLastPurchase',
  mixins: [numberMixin],
  props: {
    propData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedOrderDetails: {},
    };
  },
  methods: {
    selectOrderForOpenDetails(order) {
      this.selectedOrderDetails = order;
      this.selectedOrderDetails.customerId = this.propData.pId;

      openLightbox('lightbox-order-details', {
        orderDetails: this.selectedOrderDetails,
      });
    },
    formatDate(date) {
      return moment(date).format(dateCompleteVariation);
    },
    formatDateMobile(date) {
      return moment(date).format('L');
    },
    dayNameDate(date) {
      return moment(date).format(dateWeekDay);
    },
  },
};
</script>

<style lang="scss" scoped>
#component-itempurchase {
  max-height: 245px;
}
p {
  margin-bottom: 0px;
  &.cl-88 {
    color: $gray-600;
    font-size: 11px;
  }
}
.purchases-list {
  .list-item {
    margin: 0;
    padding: 20px 25px;
    border-bottom: 1px solid $gray-300;
    .icon {
      color: $gray-700;
    }
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      background-color: #f5fbfd;
    }
  }
}
.scrollDiv {
  overflow-y: auto;
}
.column-values {
  min-width: 110px;
}
@media only screen and (min-width: $sm-min-width) {
  .date-desktop {
    display: inline-flex;
  }
  .date-mobile {
    display: none;
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .date-desktop {
    display: none;
  }
  .date-mobile {
    display: inline-flex;
  }
  .icon-line {
    display: none;
  }
}
</style>
