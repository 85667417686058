<template>
  <div class="lightbox-who-bought-skeleton">
    <div id="sales-per-sku-skeleton" class="table-list col-12" :type="type">
      <table-list-header>
        <table-list-column class-name="col-6 text-left">
          <skeleton-loader width="101px" height="14px" />
        </table-list-column>
        <table-list-column v-for="item in 3" :key="item" class-name="text-right col-2">
          <skeleton-loader width="99px" height="14px" />
        </table-list-column>
      </table-list-header>
      <table-list-body class="pt-3">
        <table-list-row v-for="row in rows" :key="row">
          <table-list-column class-name="text-left col-6">
            <skeleton-loader width="145px" height="14px" />
          </table-list-column>
          <table-list-column v-for="item in 3" :key="item" class-name="text-right col-2">
            <skeleton-loader width="65px" height="14px" />
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    rows() {
      const length = this.data.limit;
      const arr = new Array(length);

      for (let i = 0; i < length; i += 1) {
        arr[i] = (i + 1).toString();
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin: 40px 0 25px;
}
</style>
