<template>
  <div class="ico">
    <img v-if="ico === 'arrow-down'" src="@/assets/svgs/ArrowDown.svg" />
    <img v-if="ico === 'arrow-up'" src="@/assets/svgs/ArrowUp.svg" />
    <img v-if="ico === 'bars'" src="@/assets/svgs/Bars.svg" />
    <img v-if="ico === 'filled-bars'" src="@/assets/svgs/FilledBars.svg" />
  </div>
</template>

<script>
export default {
  name: 'IconsTable',
  props: {
    ico: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.ico {
  margin-right: 13px;
  img {
    width: 17px;
  }
}
</style>
