import { SellersResponse, SellersType } from '../models';
import { getSellers } from '../services';

export const fetchSellers = (): Promise<SellersType[]> => getSellers().then(response => formatResponse(response?.data));

const formatResponse = (response: SellersResponse): SellersType[] =>
  response?.data.map(seller => ({
    label: seller?.fullname + ' - ' + seller?.enrolment,
    value: seller?.id,
  }));
