<template>
  <div id="metrics" class="metrics">
    <h1 class="card-group-title">{{ $t('settings.metrics.title') }}</h1>
    <div class="card metrics__card">
      <div class="card-body">
        <div class="row metrics__row">
          <h2 class="col-4 card-title">
            <span>{{ $t('attribution-title') }}</span>
          </h2>
          <div class="col-8">
            <div class="mb-3">
              <div class="value-label metrics__value-label">
                {{ $t('settings.metrics.attribution') }}
              </div>
              <select
                id="channel-select"
                v-model="selectedType"
                class="filter-select"
                name="attribution-type"
                @change="changeSelect"
              >
                <option name="attribution-type" value="lasttouch">
                  {{ $t('attr-filter.last-touch') }}
                </option>
                <option name="attribution-type" value="firsttouch">
                  {{ $t('attr-filter.first-touch') }}
                </option>
                <option name="attribution-type" value="uniform">
                  {{ $t('attr-filter.uniform') }}
                </option>
              </select>
            </div>
            <div>
              <div class="value-label metrics__value-label">
                {{ $t('settings.metrics.interval') }}
              </div>
              <select
                id="interval-select"
                v-model="selectedInterval"
                class="filter-select"
                name="interval-select"
                @change="changeSelect"
              >
                <option v-for="option in intervalOptions" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row metrics__row">
          <h2 class="col-4 card-title pr-3">
            <span>{{ $t('medium-recency') }}</span>
          </h2>
          <div class="col-8 row">
            <div class="radio-wrapper">
              <div class="value-label metrics__value-label">
                {{ $t('settings.metrics.recency.label') }}
              </div>
              <div>
                <input id="recency-all" v-model="recency" type="radio" name="order-type" value="ALL" />
                <label for="recency-all" class="value-label metrics__value-label">{{
                  $t('settings.metrics.recency.all')
                }}</label>
              </div>
              <div>
                <input id="recency-active" v-model="recency" type="radio" name="order-type" value="12M" />
                <label for="recency-active" class="value-label metrics__value-label">{{
                  $t('settings.metrics.recency.only-active')
                }}</label>
              </div>
              <div>
                <input id="recency-active-inactive" v-model="recency" type="radio" name="order-type" value="24M" />
                <label for="recency-active-inactive" class="value-label metrics__value-label">{{
                  $t('settings.metrics.recency.active-inactive')
                }}</label>
              </div>
            </div>
          </div>
        </div>

        <global-quarantine v-if="permissionType" class="row metrics__row" />

        <div v-if="permissionType" class="row metrics__limits metrics__row">
          <h2 class="col-4 card-title pr-3">
            <span>{{ $t('limits-of-suggestions') }}</span>
          </h2>
          <div v-show="$_verifyLoaded('done')" class="metrics__limits__wrapper">
            <div class="metrics__limits-criteria">
              <span v-html="$t('settings.metrics.limit-description')" />
            </div>

            <div class="mt-4 d-flex justify-content-between align-items-end">
              <div class="d-flex flex-column">
                <div class="value-label metrics__value-label">
                  {{ $t('settings.metrics.limit-suggestion') }}
                </div>
                <div class="mb-3 metrics__limits-maximum-value">
                  <input
                    v-tooltip.left="{
                      theme: 'error',
                      html: true,
                      autoHide: false,
                      distance: 8,
                      content: $t('settings.metrics.limit-maximum-value'),
                      shown: isShowingTooltip,
                    }"
                    :class="{
                      'is-invalid': isShowingTooltip,
                    }"
                    v-model="maximumValue"
                    :placeholder="$t('settings.metrics.limits.placeholder')"
                    @input="removeNotNumber"
                  />
                </div>
              </div>

              <button class="btn" :disabled="disabledButton" @click="sendLimits">
                {{ $t('settings.metrics.limit-button') }}
              </button>
            </div>
          </div>
          <base-loader v-show="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { getSuggestionLimit, putSuggestionLimit } from '@/services/generalSuggestionsLimit';
import { profileType } from '@/utilities/constants';
import store from '@/store';
//components
import GlobalQuarantine from '@/features/GlobalQuarantineByPhone/components/GlobalQuarantine/GlobalQuarantine';
// helpers
import { verifyMixin } from '@/helpers/mixins';
// libs
import { useToast } from 'vue-toastification';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

const SUGGESTIONS_LIMIT_MAX_VALUE = 75;

export default {
  name: 'Metrics',
  components: {
    BaseLoader,
    InfoCard,
    GlobalQuarantine,
  },
  mixins: [verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      intervalOptions: [],
      selectedInterval: Number.isInteger(this.$store.getters.attributionInterval)
        ? this.$store.getters.attributionInterval
        : 31,
      selectedType:
        this.$store.getters.attributionType && this.$store.getters.attributionType !== 'linear'
          ? this.$store.getters.attributionType
          : 'lasttouch',
      recency: this.$store.getters.recency || 'ALL',
      optionSelected: {
        name: '',
      },
      getType: '',
      maximumValue: 0,
      defaultMaximumValue: 0,
      disabled: false,
      permissionType: false,
      isShowingTooltip: false,
    };
  },
  computed: {
    user() {
      return store.getters.user;
    },
    disabledButton() {
      return (
        this.maximumValue > SUGGESTIONS_LIMIT_MAX_VALUE ||
        this.maximumValue < 1 ||
        +this.maximumValue === +this.defaultMaximumValue
      );
    },
  },
  watch: {
    recency() {
      return this.$store.dispatch('changeRecency', this.recency);
    },
    optionSelected() {
      this.disabled = this.optionSelected.name === this.$t('settings.metrics.limit-options.no_limit') ? true : false;
    },
    maximumValue() {
      this.isShowingTooltip =
        +this.maximumValue > SUGGESTIONS_LIMIT_MAX_VALUE || (this.getType !== 'seller' && this.maximumValue === null);
    },
  },
  mounted() {
    useMixpanelConfig('view_settings_metrics');
    this.hasPermissionAdmin();
  },
  created() {
    this.fetchData();
    this.initIntervalOptions();
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getSuggestionLimit()
        .then(({ data }) => {
          this.getType = data?.data?.type;
          this.disabled = data?.data?.type !== 'seller';
          this.optionSelected.name = this.$t(`settings.metrics.limit-options.${this.getType}`);
          this.maximumValue = data?.data?.limit;
          this.defaultMaximumValue = this.maximumValue;
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initIntervalOptions() {
      this.intervalOptions = Array(...Array(32)).map((x, i) => ({
        text: `${i} ${this.$tc('day', i)}`,
        value: i,
      }));
      this.intervalOptions[0].text = this.$t('attr-filter.interval-same-day');
      this.intervalOptions[1].text = `1 ${this.$tc('day', 1)}`;
    },
    changeSelect(e) {
      const what = e.target.name;
      if (what.length > 0) {
        switch (what) {
          case 'attribution-type':
            this.$store.dispatch('changeAttributionType', this.selectedType);
            break;
          case 'interval-select':
            this.$store.dispatch('changeAttributionInterval', this.selectedInterval);
            break;
          default:
            break;
        }
      }
    },
    removeNotNumber(event) {
      this.maximumValue = event.target?.value?.replace(/[^,0-9]+/g, '');
    },
    sendLimits() {
      this.loading = true;

      const limitType = 'seller';
      const limitValue = this.maximumValue;

      const getSelectedLimits = { limitType, limitValue };

      putSuggestionLimit(limitType, limitValue)
        .then(({ data }) => {
          if (!data.success) throw Error;
          this.maximumValue = data?.data?.limit;
          this.defaultMaximumValue = this.maximumValue;

          this.toast.success(
            this.$t('settings.metrics.limit-toast', {
              timeout: 5000,
            }),
          );

          this.$store.dispatch('changeGeneralSuggestionsLimit', getSelectedLimits);
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hasPermissionAdmin() {
      if (this.user.profile === profileType.admin || this.user.profile === profileType.context)
        return (this.permissionType = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.metrics {
  &__card {
    padding: 50px;
  }

  &__row {
    padding: 40px 0;
    border-bottom: 1px solid $gray-400;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border: 0;
    }
  }

  select {
    width: 274px;
    padding: 8px 14px;
  }

  &__value-label {
    margin-bottom: 10px;
    display: block;
  }

  &__limits {
    position: relative;

    .error-message {
      top: 75%;
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 66%;
    }

    &-criteria {
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &-maximum-value {
      width: 100px;

      input {
        width: 100%;
      }
    }
  }
}
</style>
