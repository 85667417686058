<template>
  <section id="nps-analytics">
    <analytics-kpis />
    <analytics-chart />
    <analytics-channel-ratings />
    <analytics-item-ratings />
    <analytics-table />
  </section>
</template>

<script>
// components
import AnalyticsChannelRatings from '@/components/nps/analytics/AnalyticsChannelRatings';
import AnalyticsItemRatings from '@/components/nps/analytics/AnalyticsItemRatings';
import AnalyticsChart from '@/components/nps/analytics/AnalyticsChart';
import AnalyticsKpis from '@/components/nps/analytics/AnalyticsKpis';
import AnalyticsTable from '@/components/nps/analytics/AnalyticsTable';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'NpsAnalytics',
  components: {
    AnalyticsChannelRatings,
    AnalyticsChart,
    AnalyticsItemRatings,
    AnalyticsKpis,
    AnalyticsTable,
  },
  mounted() {
    useMixpanelConfig('view_nps_analytics');
  },
};
</script>
