<template>
  <div class="sales-per-sku">
    <h2 class="h1 card-group-title">{{ $t('product.sales-per-sku-title') }}</h2>
    <sales-per-sku-skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <table-list
      :id="id"
      :data="data"
      :type="type"
      :empty="!data.count"
      :info="$_verifyLoaded('info')"
      :loading="$_verifyLoaded('loading')"
    >
      <table-list-header v-show="$_verifyLoaded('done')">
        <table-list-column order="product_sku" class-name="col-3">{{ $t('product.reference') }}</table-list-column>
        <table-list-column order="product_color" class-name="col-1 text-right">{{
          $tc('product.color', 1)
        }}</table-list-column>
        <table-list-column order="product_size" class-name="col-2 text-right">{{
          $tc('product.size', 1)
        }}</table-list-column>
        <table-list-column order="product_quantity" class-name="col-2 text-right">{{
          $t('product.product_quantity')
        }}</table-list-column>
        <table-list-column order="product_asp" class-name="col-2 text-right">{{
          $t('product.avg-price')
        }}</table-list-column>
        <table-list-column order="product_revenue" class-name="col-2 text-right">{{
          $t('product.total')
        }}</table-list-column>
      </table-list-header>
      <table-list-body>
        <template v-if="$_verifyLoaded('done')">
          <table-list-row v-for="(sku, index) in data.rows" :key="index">
            <table-list-column class-name="sku col-3">{{ sku.sku }}</table-list-column>
            <table-list-column class-name="col-1 text-right">{{ sku.color }}</table-list-column>
            <table-list-column class-name="col-2 text-right">{{ sku.size }}</table-list-column>
            <table-list-column class-name="col-2 text-right">{{ sku.quantity }}</table-list-column>
            <table-list-column class-name="col-2 text-right">{{ formatCurrency(sku.avgPrice) }}</table-list-column>
            <table-list-column class-name="col-2 text-right">{{ formatCurrency(sku.totalRevenue) }}</table-list-column>
          </table-list-row>
        </template>
        <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
      </table-list-body>
    </table-list>
  </div>
</template>

<script>
import { getTable } from '@/services/product';
import bus from '@/helpers/events/bus';
import SalesPerSkuSkeleton from '@/components/products/product/ProductSalesPerSkuSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  name: 'ProductSalesPerSku',
  components: {
    SalesPerSkuSkeleton,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  props: {
    productID: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      id: 'sales-per-sku',
      data: {
        page: 1,
        limit: 10,
        count: 0,
        rows: [],
        series: [],
        order: 'product_sku',
        orderDefault: 'product_sku',
      },
      type: 'filled',
    };
  },
  mounted() {
    this.fetchData();

    bus.$on(`${this.id}-change-page`, () => {
      this.$_reqConfig();
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);

    bus.$on('render-cards', this.fetchData);
  },
  beforeUnmount() {
    bus.$off('render-cards', this.fetchData);
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getTable({
        table: 'sales_per_sku',
        productID: this.productID,
        limit: this.data.limit,
        page: this.data.page,
        orderBy: this.data.order,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.data.count = data.total_count;
          this.data.series = data.series;
          this.formatRows(data.data);
        })
        .catch(() => {
          this.data.count = 0;
          this.data.rows = [];
          this.data.series = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRows(data) {
      function ROW(row) {
        [this.sku, this.color, this.size, this.quantity, this.avgPrice, this.totalRevenue] = row;
      }

      this.data.rows = [];
      for (let i = 0; i < data.length; i += 1) {
        this.data.rows.push(new ROW(data[i]));
      }
    },
  },
};
</script>
