<template>
  <div id="raw-data-access">
    <section class="rda__table">
      <base-card-v2>
        <div class="tbl-shell">
          <div ref="tbl-inner-wrapper" class="tbl-inner-wrapper" @scroll="$_scrollTable">
            <template v-if="verifyLoaded('done')">
              <div ref="tbl-config" class="tbl-config"></div>

              <div ref="tableHeader" class="tbl-header">
                <div class="tbl-row">
                  <div v-for="(vH, kH) in dataTableHeader" :key="kH" class="tbl-col metric">
                    <div class="header-title">
                      {{ vH.name }}
                    </div>
                    <div
                      :ref="vH.key"
                      :class="[
                        `order-by ${vH.key}`,
                        {
                          active: isActive === vH.key,
                        },
                      ]"
                      @click.prevent="ordenate(vH.key)"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                    </div>
                    <el-popover
                      v-if="getSelectedTable === 'OPTOUT_CUSTOMERS' && COLUMNS_TOOLTIPS_OPTOUTS.includes(vH.key)"
                      trigger="hover"
                      placement="bottom"
                      :width="220"
                    >
                      <p>
                        {{ $t(`integrations.raw-data-access.opt-out-customers.tooltips.${vH.key.toLowerCase()}`) }}
                      </p>
                      <template #reference>
                        <i class="icon icon-question-12px ml--4" />
                      </template>
                    </el-popover>

                    <el-popover
                      v-if="
                        getSelectedTable === 'CONSOLIDATED_CUSTOMERS' && COLUMNS_TOOLTIPS_CONSOLIDATED.includes(vH.key)
                      "
                      trigger="hover"
                      placement="bottom"
                      :width="220"
                    >
                      <p>
                        {{ $t(`integrations.raw-data-access.consolidated_customers.tooltips.${vH.key.toLowerCase()}`) }}
                      </p>
                      <template #reference>
                        <i class="icon icon-question-12px ml--4" />
                      </template>
                    </el-popover>

                    <el-popover
                      v-if="getSelectedTable === 'CUSTOMERS_REGISTERED_APP' && COLUMNS_TOOLTIPS_APP.includes(vH.key)"
                      trigger="hover"
                      placement="bottom"
                      :width="220"
                    >
                      <p>
                        {{ $t(`integrations.raw-data-access.customers-app.tooltips.${vH.key.toLowerCase()}`) }}
                      </p>
                      <template #reference>
                        <i class="icon icon-question-12px ml--4" />
                      </template>
                    </el-popover>
                  </div>
                </div>
              </div>

              <div v-if="headerFixed" ref="header-fixed" class="tbl-header header-fixed">
                <div class="tbl-row">
                  <div v-for="(vH, kH) in dataTableHeader" :key="kH" class="tbl-col metric">
                    <div class="header-title">{{ vH.name }}</div>
                    <div
                      :ref="vH.key"
                      :class="[
                        `order-by ${vH.key}`,
                        {
                          active: isActive === vH.key,
                        },
                      ]"
                      @click.prevent="ordenate(vH.key)"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                    </div>

                    <el-popover
                      v-if="getSelectedTable === 'OPTOUT_CUSTOMERS' && COLUMNS_TOOLTIPS_OPTOUTS.includes(vH.key)"
                      trigger="hover"
                      placement="bottom"
                      :width="220"
                    >
                      <p>
                        {{ $t(`integrations.raw-data-access.opt-out-customers.tooltips.${vH.key.toLowerCase()}`) }}
                      </p>
                      <template #reference>
                        <i class="icon icon-question-12px ml--4" />
                      </template>
                    </el-popover>

                    <el-popover
                      v-if="
                        getSelectedTable === 'CONSOLIDATED_CUSTOMERS' && COLUMNS_TOOLTIPS_CONSOLIDATED.includes(vH.key)
                      "
                      trigger="hover"
                      placement="bottom"
                      :width="220"
                    >
                      <p>
                        {{ $t(`integrations.raw-data-access.consolidated_customers.tooltips.${vH.key.toLowerCase()}`) }}
                      </p>
                      <template #reference>
                        <i class="icon icon-question-12px ml--4" />
                      </template>
                    </el-popover>

                    <el-popover
                      v-if="getSelectedTable === 'CUSTOMERS_REGISTERED_APP' && COLUMNS_TOOLTIPS_APP.includes(vH.key)"
                      trigger="hover"
                      placement="bottom"
                      :width="220"
                    >
                      <p>
                        {{ $t(`integrations.raw-data-access.customers-app.tooltips.${vH.key.toLowerCase()}`) }}
                      </p>
                      <template #reference>
                        <i class="icon icon-question-12px ml--4" />
                      </template>
                    </el-popover>
                  </div>
                </div>
              </div>

              <div ref="tbl-body" class="tbl-body">
                <div v-for="(item, index) in dataTableBody.rows" :key="index" class="tbl-row">
                  <div v-for="(vH, kH) in dataTableHeader" :key="kH" class="tbl-col metric">
                    <div v-if="vH.key === 'STORE_STATUS'" class="status">
                      <span :class="[item[vH.key] === '1' ? 'active' : 'inactive']"></span>
                      <p>{{ translateActiveAndInactive(item[vH.key]) }}</p>
                    </div>
                    <i v-else-if="conditionalToShowIcons(vH.key)" :class="['icon', iconType(item[vH.key])]"></i>
                    <p
                      v-else
                      :class="[
                        'threshold',
                        `${$_checkThreshold({
                          amount: item[vH.key],
                          threshold: vH.threshold,
                        })}`,
                      ]"
                    >
                      {{ getValueNewFormat({ vH, amount: item[vH.key] }, true) }}
                      <template v-if="vH.key === 'REASON_OPTOUT'">
                        <el-popover trigger="hover" placement="top" :width="250">
                          <p v-html="getValueTooltip(item[vH.key])"></p>
                          <template #reference>
                            <i class="icon icon-question-12px" />
                          </template>
                        </el-popover>
                      </template>
                    </p>
                  </div>
                </div>
              </div>
            </template>

            <base-loader v-if="verifyLoaded('loading')" />
            <info-card v-if="verifyLoaded('info')" ref="message-error" :error="hasError" />
          </div>
        </div>

        <div class="rda__table--pagination" v-if="pagination.count > 0">
          <list-pagination :data="pagination" :limit="pagination.limit" :limit-range="limitRange" :identify="id" />
        </div>
      </base-card-v2>
    </section>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { reactive, toRefs, computed, watch } from 'vue';
import { useStore } from 'vuex';
// services
import { fetchDataTable } from '@/features/RawDataAccess/services';
// helpers
import { tableMixin, dashboardMixin } from '@/helpers/mixins';
import useVerifyMixin from '@/utilities/mixins/useVerifyMixin';
import useTableMixin from '@/utilities/mixins/useTableMixin';
// components
import ListPagination from '@/components/_atoms/ListPagination';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  components: {
    BaseLoader,
    InfoCard,
    ListPagination,
  },
  mixins: [tableMixin, dashboardMixin],
  setup() {
    const store = useStore();
    const { hasError, isLoading, reqConfig, verifyData, verifyLoaded } = useVerifyMixin();
    const { getValueNewFormat } = useTableMixin();

    const COLUMNS_TOOLTIPS_OPTOUTS = [
      'CUSTOMER_ID',
      'MKT_CLOUD_ID',
      'MOBILE_NUMBER_VALID',
      'WHATSAPP_PERMISSION',
      'CREATE_DATE',
      'FIRST_NAME',
      'GENDER',
      'CUSTOMER_TYPE',
      'TYPE_PERSON',
      'REVENUE_ALL',
      'STATUS',
      'ADDRESS_EMPTY',
    ];

    const COLUMNS_TOOLTIPS_CONSOLIDATED = [
      'CUSTOMER_ID',
      'MKT_CLOUD_ID',
      'MOBILE_NUMBER_VALID',
      'WHATSAPP_PERMISSION',
      'CREATE_DATE',
      'FIRST_NAME',
      'GENDER',
      'CUSTOMER_TYPE',
      'TYPE_PERSON',
      'REVENUE_ALL',
      'STATUS',
      'ADDRESS_EMPTY',
    ];

    const COLUMNS_TOOLTIPS_APP = [
      'CUSTOMER_ID',
      'MKT_CLOUD_ID',
      'MOBILE_NUMBER_VALID',
      'WHATSAPP_PERMISSION',
      'CREATE_DATE',
      'FIRST_NAME',
      'GENDER',
      'CUSTOMER_TYPE',
      'TYPE_PERSON',
      'REVENUE_ALL',
      'STATUS',
      'ADDRESS_EMPTY',
    ];

    const events = reactive({
      dataTableHeader: {},
      dataTableBody: {},
      headerFixed: false,
      isActive: null,
      id: 'raw-data-access',
      pagination: {
        count: 0,
        limit: 100,
        page: 1,
      },
      limitRange: [5, 10, 20, 30, 50, 100],
      status: '',
      isLoadingExport: false,
    });

    // getters
    const getSelectedTable = computed(() => store.getters['rawDataAccess/getSelectedTable']);
    const getFilters = computed(() => store.getters['rawDataAccess/getFilters']);
    const getIsApplyFilter = computed(() => store.getters['rawDataAccess/getIsApplyFilter']);
    const getOrderBy = computed(() => store.getters['rawDataAccess/getOrderBy']);

    // actions
    const setIsApplyFilter = boolean => store.dispatch('rawDataAccess/setIsApplyFilter', boolean);
    const setExportTableParameters = obj => store.dispatch('rawDataAccess/setExportTableParameters', obj);
    const setOrderBy = string => store.dispatch('rawDataAccess/setOrderBy', string);
    const setSummaryInfos = number => store.dispatch('rawDataAccess/setSummaryInfos', number);
    const setSummaryLoading = boolean => store.dispatch('rawDataAccess/setSummaryLoading', boolean);
    const setHasSummaryError = boolean => store.dispatch('rawDataAccess/setHasSummaryError', boolean);

    // watchers
    watch(getSelectedTable, () => {
      events.pagination.page = 1;
      fetchTable();
    });
    watch(getIsApplyFilter, () => {
      if (getIsApplyFilter.value) {
        fetchTable();
      }
    });

    function getValueTooltip(value) {
      const formattedValue = value.toLowerCase().replaceAll(' ', '-');

      const result = {
        'contato-pelo-whatsapp-recusado-na-carga-de-dados':
          'Detectamos que na carga de dados o cliente estava registrado com WHATSAPP_PERMISSION como Opt-out',
        'telefone-vazio-na-carga-de-dados':
          'Detectamos que na carga de dados o cliente estava sem o telefone de contato, logo, transformamos ele em Opt-out',
        'o-número-não-pertence-a-pessoa': 'Vendedor registrou no App que o número não pertence a pessoa',
        'o-número-não-existe-ou-deu-erro': 'Vendedor registrou no App que o número não existe ou deu erro',
        'consentimento-desativado-por-um-vendedor-no-app':
          'O vendedor desativou o consentimento no app porque o cliente solicitou não ser mais contatado',
        'cliente-clicou-no-link-de-opt-out-do-oto':
          'O cliente clicou no link de opt-out do Oto que foi enviado em uma mensagem',
      };

      return result[formattedValue] || 'Nada encontrado';
    }

    // functions
    function fetchTable() {
      reqConfig();

      const args = {
        table: getSelectedTable.value,
        orderBy: getOrderBy.value,
        page: events.pagination.page,
        limit: events.pagination.limit,
        filters: JSON.stringify(getFilters.value),
      };

      setExportTableParameters(args);
      setSummaryLoading(true);
      setHasSummaryError(false);

      fetchDataTable(args)
        .then(response => {
          if (verifyData(response?.data)) {
            setHasSummaryError(true);
            events.pagination.count = response.totalCount;
            return;
          }

          events.dataTableHeader = response.series;
          events.dataTableBody.rows = response?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                return [response.series[i].key, val];
              }),
            );
          });
          events.pagination.count = response.totalCount;
          setSummaryInfos({ totalCount: events.pagination.count, totalTable: response?.totalTable });
        })
        .catch(() => {
          setHasSummaryError(true);
          hasError.value = true;
        })
        .finally(() => {
          isLoading.value = false;
          setSummaryLoading(isLoading.value);
          setIsApplyFilter(false);
        });
    }

    function ordenate(order) {
      if (!getOrderBy.value) {
        setOrderBy(order);
        events.isActive = order;
        fetchTable();
        return;
      }

      events.isActive = order;

      if (getOrderBy.value === order) setOrderBy(`-${order}`);
      else setOrderBy(order);
      fetchTable();
    }

    function iconType(type) {
      const getIconType = {
        L: 'icon-in-store-18px',
        E: 'icon-ecommerce-18px',
        OTO: 'icon-table-18px',
        CARGA: 'icon-database-18px',
      };
      return getIconType[type];
    }

    function conditionalToShowIcons(item) {
      if (getSelectedTable.value !== 'CUSTOMERS')
        return item === 'DATA_SOURCE' || item === 'ORDER_STORE_TYPE' || item === 'STORE_TYPE';
    }

    return {
      ...toRefs(events),
      getValueTooltip,
      verifyLoaded,
      getSelectedTable,
      fetchTable,
      ordenate,
      getValueNewFormat,
      iconType,
      conditionalToShowIcons,
      COLUMNS_TOOLTIPS_OPTOUTS,
      COLUMNS_TOOLTIPS_CONSOLIDATED,
      COLUMNS_TOOLTIPS_APP,
    };
  },
  data() {
    return {
      statusText: {
        active: this.$t('item-active'),
        inactive: this.$t('item-inactive'),
      },
    };
  },
  mounted() {
    this.fetchTable();

    bus.$on('render-page-loader', isActive => {
      this.isLoadingExport = isActive;
    });

    bus.$on('render-cards', () => {
      this.fetchTable();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.fetchTable();
      this.headerFixed = false;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off(['render-cards', `${this.id}-change-page`, 'render-page-loader']);
  },
  methods: {
    translateActiveAndInactive(string) {
      return string === '1' ? this.statusText.active : this.statusText.inactive;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
.el-popper p {
  margin-bottom: 0 !important;
  word-break: break-word !important;
  text-align: left !important;
}

#raw-data-access {
  .tbl-body .tbl-col .el-tooltip__trigger {
    color: $gray-800;
    position: relative;
    bottom: 3px;
    left: -8px;

    &::before {
      font-size: 14px;
    }
  }

  .tbl-header .tbl-col .el-tooltip__trigger {
    font-size: 14px;
    margin-left: 8px;
    color: $gray-600;
  }
}
</style>
