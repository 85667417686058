
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Alert',
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'success',
      validator(value: string) {
        return ['success', 'info', 'error', 'warning', 'secondary'].includes(value);
      },
    },
  },
});
