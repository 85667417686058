<template>
  <div
    v-if="serie.type != 'I'"
    class="audience-integration-serie d-flex align-items-center"
    :class="{ enabled: serie.enabled, configurated: serie.scheduleID }"
  >
    <figure class="d-flex align-items-center">
      <img v-if="serie.alias" :src="getIcon(serie)" :alt="serie.alias" />
    </figure>

    <div :class="['item-name', 'align-items-left', isOmnichatAndNotEnabled() ? 'send-type' : '']">
      <div class="integration-name">
        <p>{{ serie.title }}</p>
        <div v-if="serie.enabled" class="active-integration">
          <span class="active-integration__circle"></span>
          <p>{{ $t('item-active') }}</p>
        </div>
      </div>
      <span v-if="isOmnichatAndNotEnabled()" v-html="isIndividualApproach()"></span>
    </div>

    <integration-form :serie="serie" :editable="editable" />

    <serie-button
      :toggle="buttonAction"
      :edit="editIntegration"
      :enabled="serie.enabled"
      :creator="creator"
      :is-view="isView"
      :serie="serie"
    />
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { dateMixin } from '@/helpers/mixins';
import integrationMixin from '@/helpers/mixins/integrationMixin';
import { dateTimeFormatVariation } from '@/utilities/constants';
import IntegrationSerieButtons from '@/components/audiences/create/integration/IntegrationSerieButtons';
import IntegrationForm from '@/components/audiences/create/integration/IntegrationForm';
import { mapState } from 'vuex';

export default {
  components: {
    'integration-form': IntegrationForm,
    'serie-button': IntegrationSerieButtons,
  },
  mixins: [integrationMixin, dateMixin],
  props: {
    serie: {
      type: Object,
      default: () => {},
    },
    isView: {
      type: Boolean,
      default: null,
    },
    creator: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      dateTimeFormatVariation,
    };
  },
  computed: {
    ...mapState('audience', ['isOmnichatAndIndividualApproach']),
    editable() {
      let editable = true;

      if (!this.serie.enabled) editable = false;

      return editable;
    },
  },
  methods: {
    buttonAction() {
      return this.serie.enabled ? this.toggleMode() : this.activeMode();
    },
    toggleMode() {
      /**
       * @description on view mode this button has a toggle behavior, dispatching the reverse of actually enabled button;
       */
      this.$store.dispatch('audience/updateIntegration', {
        alias: this.serie.alias,
        connect: this.serie.alias,
        icon: this.serie.icon,
        enabled: !this.serie.enabled,
        schedule: 0,
        hasDatetime: false,
      });
    },
    activeMode() {
      /**
       * @description on create mode this button has a enable mode that open modal to set integration previously created
       */
      if (this.serie.enabled === 1) {
        // dispatch event to update integration serie based in name of connector (alias property)
        this.$store.dispatch('audience/updateIntegration', {
          alias: this.serie.alias,
          connect: this.serie.alias,
          icon: this.serie.icon,
          enabled: 0,
          schedule: 0,
          hasDatetime: false,
        });
      } else {
        // enable occurs in integration modal
        bus.$emit('openIntegrationActive', this.serie);
      }
    },
    editIntegration() {
      bus.$emit('openIntegrationActive', this.serie);
    },
    isOmnichatAndNotEnabled() {
      return this.serie.alias === 'omnichat' && this.serie.enabled ? true : false;
    },
    isIndividualApproach() {
      return this.isOmnichatAndIndividualApproach
        ? this.$t('audiences.omnichat.individual-approach')
        : this.$t('audiences.omnichat.automatic-approach');
    },
  },
};
</script>
<style lang="scss">
.audience-integration-serie {
  padding: 25px 40px;
  margin-bottom: 12px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: -2px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;

  &.enabled {
    background-color: $color-white;
    > figure,
    > div:not(.inactive) {
      opacity: 1;
    }
  }

  > figure,
  > div:not(.action-button) {
    opacity: 0.5;
  }

  .label {
    display: block;
  }

  figure {
    margin: 0 0 0rem;
  }
  .item-name {
    width: 250px;
    p {
      text-align: left !important;
      width: fit-content;
      font-family: $font-family;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      padding-left: 10px;
      margin-bottom: unset !important;
    }

    .integration-name {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 260px;
    }

    .active-integration {
      max-width: 70px;
      background: #c8fadd;
      border-radius: 16px;
      padding: 2px 8px 2px 6px;
      gap: 4px;
      display: flex;
      align-items: center;
      height: 25px;

      &__circle {
        height: 8px;
        width: 8px;
        background: #1ea657;
        border-radius: 50%;
        padding: unset !important;
      }

      p {
        padding: unset !important;
        text-align: unset !important;
        margin-bottom: unset !important;
        font-size: 12px;
        color: #1ea657;
      }
    }
  }

  .send-type {
    p {
      margin-bottom: 5px;
    }

    span {
      padding-left: 10px;
      font-size: 12px;
      color: $gray-700;
    }
  }
}
</style>
