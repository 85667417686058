
interface Actions {
  name: string;
  action: void;
  hidden?: boolean;
  disabled?: boolean;
}

export default {
  name: 'MenuDropdown',
  props: {
    label: {
      type: String,
      default: null,
    },
    actions: {
      type: Object as () => Actions,
      default: () => ({}),
    },
  },
};
