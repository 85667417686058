<template>
  <div class="sales-report">
    <sales-report-header />
    <sales-report-metrics />
    <sales-report-graphic />
    <sales-report-table />
    <base-loader v-show="loadingExport" color="colored-block" class="render-loader" />
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
// helpers
import { verifyMixin } from '@/helpers/mixins';
// components
import SalesReportHeader from '@/components/oto/salesReport/SalesReportHeader';
import SalesReportMetrics from '@/components/oto/salesReport/SalesReportMetrics';
import SalesReportGraphic from '@/components/oto/salesReport/SalesReportGraphic';
import SalesReportTable from '@/components/oto/salesReport/SalesReportTable';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'SalesReport',
  components: {
    SalesReportHeader,
    SalesReportMetrics,
    SalesReportGraphic,
    SalesReportTable,
    BaseLoader,
  },
  mixins: [verifyMixin],
  data() {
    return {
      loadingExport: false,
      isActive: null,
    };
  },
  mounted() {
    useMixpanelConfig('view_oto_sales_report');
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
  },
  beforeUnmount() {
    bus.$off('render-page-loader');
  },
};
</script>

<style lang="scss" scoped>
.sales-report {
  .render-loader {
    background-color: $gray-900;
    height: 100%;
    left: 50%;
    opacity: 0.7;
    overflow: hidden;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 104;
  }
}
</style>
