import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const controller = '/360/performance';

export const getCampaigns = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getCampaigns`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};

export const getStores = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getStores`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};

export const getMetrics = (metrics, campaign, metricStore, lookback, graphic = 0) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getMetrics`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      metrics,
      campaign,
      store: metricStore,
      lookback,
      graphic,
    },
  });
};

export const getTable = (preset, lookback, fileType, responseType = '') => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getTable`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      lookback,
      preset,
      export: fileType,
    },
    responseType,
  }).then(data => data.data);
};

export const getTablePresets = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getTablePresets`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};
