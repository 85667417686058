<template>
  <div id="campaigns-empty">
    <div class="row align-items-center">
      <div class="figure-empty-campaigns col col-6">
        <img src="@/assets/img/figures/empty_campaigns.png" :alt="$t('campaigns.empty-campaigns.title')" />
      </div>
      <div class="campaign-empty-text col col-6">
        <h2>{{ $t('campaigns.empty-campaigns.title') }}</h2>
        <p class="subtitle" v-html="$t('campaigns.empty-campaigns.subtitle')"></p>
        <button class="btn btn-new-campaigns" :title="$t('campaigns.create.title')" @click="$parent.openCreate">
          {{ $t('campaigns.create.title') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.subtitle {
  margin-top: 20px;
}
.btn-new-campaigns {
  width: 170px;
  cursor: pointer;
  border-radius: 100px;
  margin-top: 20px;
}
.figure-empty-campaign {
  img {
    width: 100%;
    max-width: 478px;
    height: auto;
    max-height: 414px;
  }
}
</style>
