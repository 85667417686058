import moment from 'moment';

const mixin = {
  methods: {
    /**
     * @description verify the first date in param array with '$_isYMDformat' to define date format
     * @param {*} dateLabels array with series (dates) to use as labels in chart
     * @returns the input array with correct date format
     */
    $_resolvePlotDateFormat(dateLabels) {
      const templateToYMD = 'DD' + '/' + 'MM';
      const templateToYM = 'MMM' + '/' + 'YY';
      const applyTemplate = this.$_isYMDformat(dateLabels[0]) ? templateToYMD : templateToYM;
      const entryDateFormat = this.$_isYMDformat(dateLabels[0]) ? 'YYYY-M-D' : 'YYY-M';
      return dateLabels.map(date => moment(date, entryDateFormat).format(applyTemplate));
    },
    /**
     * @description check date format of param date to verify the YYYY-MM-DD format
     * @param {*} date date to analyze format
     * @returns true if date has YYYY-MM-DD format or false if it's anything else
     */
    $_isYMDformat(date) {
      const regex = /\d{4}(-\d{2}){2}/g;
      return regex.test(date);
    },
    /**
     * @description get number of chart columns to set correctly width to chart
     * @param {number} size number of chart columns
     * @returns width of chart
     */
    $_setChartColWidth(size) {
      return size !== undefined && size > 28 ? 20 : 26;
    },
    /**
     * @description parse all values inside array to float
     * @param {array} arr
     * @returns float array
     */
    $_parseArrayToFloat(arr) {
      if (!arr) return;
      return arr.map(el => parseFloat(el));
    },
    /**
     * @description parse all values inside array to int
     * @param {array} arr
     * @returns int array
     */
    $_parseArrayToInt(arr) {
      if (!arr) return;
      return arr.map(el => parseInt(el));
    },
  },
};

export default mixin;
