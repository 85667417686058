import { valueType } from '@/utilities/constants';

export const validate = {
  serieFill: null,
  groups(groups, serieFill) {
    this.serieFill = serieFill;
    const result = [];
    groups.forEach((series, group) => {
      result.push(this.group(series, group));
    });
    return result.every(arg => arg);
  },
  group(series, group) {
    const result = [];
    series.forEach((data, serie) => {
      result.push(this.serie(serie, group, data));
    });
    return result.every(arg => arg);
  },
  serie(serie, group, data) {
    // Columns
    if (!data.column) return false;

    let fill;
    this.serieFill.forEach(g => {
      const filter = g.columns.filter(col => col.key === data.column)[0];
      if (filter) fill = filter;
    });

    const { type, format, options } = fill;
    const between = data.operator === 'between';

    // isnull/notnull
    if (data.operator === 'isnull' || data.operator === 'notnull') return true;

    // Options
    if (options) return true;
    let result = this.field(format, type, data.value[0]);
    if (!result) return false;
    if (between) {
      result = this.field(format, type, data.value[1]);
      if (!result) return false;
    }
    return true;
  },
  connect(series) {
    const result = [];

    series.forEach(serie => {
      result.push(!!serie.connect);
      result.push(serie.schedule !== undefined);
      result.push(serie.hasDatetime !== undefined);
      if (serie.hasDatetime) result.push(this.test.datetime(serie.startDatetime));
    });
    return result.every(arg => arg);
  },
  field(format, type, value) {
    let str;

    if (value === 0 || value) {
      str = value.toString();
    } else {
      return false;
    }

    if (format === 'date_iso') return this.test.date(value);
    if (type === valueType.string) return this.test.string(value);
    if (type === valueType.integer) return this.test.integer(str);
    if (type === valueType.decimal) return this.test.decimal(str);

    return true;
  },
  test: {
    date(str) {
      return /\d{4}-\d{2}-\d{2}/.test(str);
    },
    datetime(str) {
      // AAAA-MM-DD HH:MM
      return /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}/.test(str);
    },
    string(str) {
      return str.length;
    },
    number(str) {
      return str.length && !Number.isNaN(str);
    },
    integer(str) {
      return this.number(str) && str.indexOf('.') < 0;
    },
    decimal(str) {
      return this.number(str);
    },
  },
};

/**
 * Format valeus from columns chained to use inside the condition column
 * @param {Array} keys
 * @param {Object} fill
 * @param {Boolean} isLastLevel
 */
export const helperFormatColumns = (keys, fill, isLastLevel = false) => {
  const options = [];
  keys.forEach(key => {
    const insideKeys = Object.keys(fill[key]);
    const values = isLastLevel ? fill[key] : helperFormatColumns(insideKeys, fill[key], true);
    options.push({
      key,
      ...(isLastLevel ? values : { columns: values }),
    });
  });
  return options;
};
