<template>
  <div class="welcome-cards">
    <div v-for="(item, index) in cards" :key="index" @click="redirect(item)" class="card welcome-cards__item">
      <div :class="`welcome-cards__bg--${item.icon} welcome-cards__bg`">
        <img :src="require(`@/assets/img/icons/welcome/${item.icon}.svg`)" :alt="$t('360.actions.dialog.alert-icon')" />
      </div>
      <div>
        <strong class="welcome-cards__item__title">{{ item.title }}</strong>
        <p class="welcome-cards__item__text">{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useMixpanelWelcome } from '@/utilities/mixpanel/welcome';

export default {
  name: 'WelcomeCard',
  data() {
    return {
      cards: [
        {
          title: this.$tc('welcome.cards.academy.title'),
          text: this.$tc('welcome.cards.academy.text'),
          icon: 'graduation',
          link: '/oto-academy',
        },
        {
          title: this.$tc('welcome.cards.indicators.title'),
          text: this.$tc('welcome.cards.indicators.text'),
          icon: 'report',
          link: '/oto/engagement-report',
        },
        {
          title: this.$tc('welcome.cards.sales.title'),
          text: this.$tc('welcome.cards.sales.text'),
          icon: 'money',
          link: '/oto/sales-report-new',
        },
        {
          title: this.$tc('welcome.cards.sellers.title'),
          text: this.$tc('welcome.cards.sellers.text'),
          icon: 'users',
          link: '/oto/sellers',
        },
        {
          title: this.$tc('welcome.cards.support.title'),
          text: this.$tc('welcome.cards.support.text'),
          icon: 'question',
          link: 'https://ajuda.otocrm.com.br/support/home',
        },
      ],
    };
  },
  methods: {
    redirect(item) {
      useMixpanelWelcome(item.link);

      if (item.title === 'Central de ajuda') {
        window.open(item.link, '_blank');
      } else {
        this.$router.push(item.link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-cards {
  display: flex;
  margin-bottom: 16px;

  &__item {
    box-shadow: 0px 4px 7px 0px #00000012;
    padding: 16px;
    cursor: pointer;
    margin-bottom: 0;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:hover {
      box-shadow: 0px 24px 48px -12px #0000002e;
    }

    &__title {
      color: $gray-800;
      font-size: 14px;
      font-weight: 600;
    }

    &__text {
      color: $gray-600;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0 !important;
    }
  }

  &__bg {
    display: flex;
    justify-content: center;
    height: auto;
    width: 48px;
    height: 48px;
    padding: 12px;
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 16px;

    svg {
      height: 30px;
    }

    &--graduation {
      background-color: #ff3162;
    }

    &--report {
      background-color: #6c90f4;
    }

    &--money {
      background-color: #25c2a4;
    }

    &--users {
      background-color: #f1c40f;
    }

    &--question {
      background-color: #a334ff;
    }
  }
}
</style>
