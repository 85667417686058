<template>
  <div class="col-sm p-2">
    <base-card-v2 id="customer-sizes" class="pr-2 pl-2 pb-3 pr-sm-4 pl-sm-4" :class="{ loadHeight: loading }">
      <base-card-header :title="$t('360.person.customer-sizes.title')" />
      <customer-view-sizes v-show="$_verifyLoaded('done')" :sizes="sizes" :customer-id="customerId" />
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </base-card-v2>
  </div>
</template>
<script>
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import CustomerViewSizes from '@/components/oto/customerview/components/CustomerViewSizes';
import InfoCard from '@/components/_atoms/InfoCard';
import { getSizes } from '@/services/oto/customers';
import { verifyMixin } from '@/helpers/mixins';

export default {
  components: {
    CustomerViewSizes,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      sizes: [],
      customerId: null,
    };
  },
  mounted() {
    bus.$on('load-profile', customer => {
      if (!customer) return;

      this.customerId = customer;
      this.loadData();
    });
  },
  methods: {
    loadData() {
      this.$_reqConfig();

      getSizes(this.customerId)
        .then(res => {
          if (this.$_verifyData(res.data.sizes)) return;
          this.sizes = res.data.sizes;
        })
        .catch(() => {
          this.sizes = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#customer-sizes {
  &.loadHeight {
    height: 325px;
  }
}
</style>
