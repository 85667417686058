<template>
  <div class="table-list-body-empty" :class="type">
    <p>{{ empty.message || $t('table-list.empty-message') }}</p>
  </div>
</template>

<script>
export default {
  computed: {
    type() {
      let $current = this.$parent;

      while (!$current.type) $current = $current.$parent;

      return $current.type;
    },
    empty() {
      let $current = this.$parent;

      while ($current && !$current.empty) $current = $current.$parent;

      return $current && $current.empty;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-body-empty {
  text-align: center;
  padding: 45px 0;
  width: 100%;

  p {
    color: $gray-900;
    font-weight: 600;
    font-size: 12px;
    cursor: default;
  }
}
</style>
