<template>
  <div class="lightbox-template-chart">
    <div :id="id" class="chart"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';

const template = {
  chart: {
    type: 'column',
    height: 280,
  },
  title: {
    text: null,
  },
  legend: {
    enabled: true,
    align: 'left',
    verticalAlign: 'top',
    itemStyle: {
      color: 'grey',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      borderWidth: 0,
    },
    series: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      lineWidth: 0,
      fillOpacity: 1,
    },
  },
  tooltip: {
    shared: true,
    valueDecimals: 0,
    borderWidth: 0,
    backgroundColor: '#fff',
  },
  series: [],
};

export default {
  props: {
    settings: {
      type: [Array, Object],
      default: null,
    },
  },
  data() {
    return {
      random: 0,
      chart: undefined,
      settingsProps: [],
    };
  },
  computed: {
    id() {
      return `chart-${this.random}`;
    },
    ready() {
      return this.settings;
    },
  },
  watch: {
    settings() {
      this.setup();
    },
  },
  mounted() {
    this.settingsProps = this.settings;
    this.random = Math.floor(Math.random() * 9000 + 1);
    this.setup();
  },
  methods: {
    setId() {
      template.chart.renderTo = this.id;
      if (this.settings && this.settings.chart) this.settingsProps.chart.renderTo = this.id;
    },
    setup() {
      this.setId();

      this.$nextTick(() => {
        this.chart = new Highcharts.Chart({ ...template, ...this.settings });
        if (!this.ready) this.chart.showLoading();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
