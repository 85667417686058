import { GlobalQuarantineDaysTypes, PutAndUpdateQuarantineResponse, GlobalQuarantineParams } from '../types';
import { getQuarantine, putQuarantine } from '@/services/globalQuarantine';

// fetch and format quarentine days
export const fetchDataQuarantineDays = (): Promise<GlobalQuarantineDaysTypes> =>
  getQuarantine().then(response => formatQuarantineDaysResponse(response?.data));

const formatQuarantineDaysResponse = (response: GlobalQuarantineDaysTypes): GlobalQuarantineDaysTypes => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});

// fetch and format quarentine days put method
export const fetchPutQuarantineDays = (params: GlobalQuarantineParams): Promise<PutAndUpdateQuarantineResponse> =>
  putQuarantine(params).then(response => formatPutQuarantineDaysResponse(response?.data));

const formatPutQuarantineDaysResponse = (response: PutAndUpdateQuarantineResponse): PutAndUpdateQuarantineResponse => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});
