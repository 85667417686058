// libs
import { escapeRegExp } from 'lodash';
// helpers
import { stringParser } from '@/helpers/string-parser.js';

// endpoints
import { getTableMetrics, getStores, getSellers } from '@/services/nps/analytics';

const $_objectToArray = value => {
  return Object.keys(value).map(item => ({
    key: value[item].key,
    name: value[item].name,
  }));
};

const npsAnalytics = {
  namespaced: true,
  state: {
    firstLoad: null,
    sellersList: [],
    sellerSelected: null,
    storesList: [],
    storeSelected: null,

    // Table
    metrics: [],
    dimensions: [],
    checkedMetrics: [
      'nps',
      'item_01_score',
      'item_02_score',
      'item_03_score',
      'item_04_score',
      'item_05_score',
      'item_06_score',
      'item_07_score',
      'item_08_score',
      'item_09_score',
      'item_10_score',
    ],
    checkedDimensions: ['store_city', 'store_name'],
    filterDimensions: [],
    filterMetrics: [],
    lastCustomConfig: {},
  },
  mutations: {
    SET_METRICS(state, metrics) {
      state.metrics = metrics;
    },
    SET_DIMENSIONS(state, dimensions) {
      state.dimensions = dimensions;
    },
    SET_CHECKED_METRICS(state, checked) {
      state.checkedMetrics = checked;
    },
    SET_CHECKED_DIMENSIONS(state, checked) {
      state.checkedDimensions = checked;
    },
    SET_FILTER_DIMENSIONS(state, dimensions) {
      state.filterDimensions = dimensions;
    },
    SET_FILTER_METRICS(state, metrics) {
      state.filterMetrics = metrics;
    },
    SET_LAST_CUSTOM_CONFIG(state) {
      state.lastCustomConfig = {
        metrics: state.checkedMetrics,
        dimensions: state.checkedDimensions,
        filterMetrics: state.filterMetrics,
        filterDimensions: state.filterDimensions,
      };
    },
    DELETE_FILTER_DIMENSIONS(state) {
      state.filterDimensions = state.filterDimensions.filter(el => state.checkedDimensions.includes(el.key));
    },
    DELETE_FILTER_METRICS(state) {
      state.filterMetrics = state.filterMetrics.filter(el => state.checkedMetrics.includes(el.key));
    },
    DELETE_CHECKED_METRIC(state, index) {
      state.checkedMetrics.splice(index, 1);
    },
    DELETE_CHECKED_DIMENSION(state, index) {
      state.checkedDimensions.splice(index, 1);
    },
    RESET_ALL_CHECKED(state) {
      state.checkedMetrics = [];
      state.checkedDimensions = [];
      state.filterMetrics = [];
      state.filterDimensions = [];
    },
    ADD_SELLERS(state, sellersList) {
      state.sellersList = sellersList;
    },
    ADD_STORES(state, storesList) {
      state.storesList = storesList;
    },
    CHANGE_SELLERSELECTED(state, sellerSelected) {
      state.sellerSelected = sellerSelected;
    },
    CHANGE_STORESELECTED(state, storeSelected) {
      state.storeSelected = storeSelected;
    },
  },
  actions: {
    updateMetrics({ commit }, payload) {
      getTableMetrics(payload).then(({ data }) => {
        commit('SET_METRICS', $_objectToArray(data.table_metrics.metrics));
        commit('SET_DIMENSIONS', $_objectToArray(data.table_metrics.dimensions));
      });
    },
    updateCheckedMetrics({ commit }, payload) {
      commit('SET_CHECKED_METRICS', payload);
    },
    updateCheckedDimensions({ commit }, payload) {
      commit('SET_CHECKED_DIMENSIONS', payload);
    },
    updateLastCustomConfig({ commit }) {
      commit('SET_LAST_CUSTOM_CONFIG');
    },
    updateFilterDimensions({ commit }, payload) {
      commit('SET_FILTER_DIMENSIONS', payload);
    },
    updateFilterMetrics({ commit }, payload) {
      commit('SET_FILTER_METRICS', payload);
    },
    deleteCheckedMetric({ commit }, payload) {
      commit('DELETE_CHECKED_METRIC', payload);
      commit('DELETE_FILTER_METRICS');
    },
    deleteCheckedDimension({ commit }, payload) {
      commit('DELETE_CHECKED_DIMENSION', payload);
      commit('DELETE_FILTER_DIMENSIONS');
    },
    resetAllStoreMetrics({ commit }) {
      commit('RESET_ALL_CHECKED');
    },
    setStoresList({ commit, state }) {
      getStores().then(data => {
        const stores = Object.entries(data.data).map(([id, name]) => {
          return { id, name };
        });
        commit('ADD_STORES', stores);
        state.firstLoad = true;
      });
    },
    setStoreSelected({ commit, dispatch }, storeId) {
      commit('CHANGE_STORESELECTED', storeId);
      dispatch('setSellersList');
    },
    setSellersList({ commit, state }) {
      getSellers().then(data => {
        commit('ADD_SELLERS', data.data);
        state.firstLoad = true;
      });
    },
    setSellerSelected({ commit }, sellerId) {
      commit('CHANGE_SELLERSELECTED', sellerId);
    },
    setSellersByTerm({ commit }, { term } = {}) {
      getSellers(term).then(({ data }) => {
        if (data.success) {
          commit('ADD_SELLERS', data.data);
        }
      });
    },
    setStoreByTerm({ commit }, { term } = {}) {
      getStores(term).then(({ data }) => {
        if (data.success) {
          commit('ADD_STORES', data.data);
        }
      });
    },
  },
  getters: {
    getAllCheckedMetrics: state => {
      return {
        metrics: state.checkedMetrics,
        dimensions: state.checkedDimensions,
      };
    },
    getCheckedMetrics: state => state.checkedMetrics?.join(','),
    getCheckedDimensions: state => state.checkedDimensions?.join(','),
    getLastConfigMetrics: state => {
      return state.lastCustomConfig;
    },
    getFilteredDimensions:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.dimensions.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getFilteredMetrics:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.metrics.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getSellers: state => state.sellersList,
    getActiveSellerObject: (state, getters) => getters.getSellerObject(state.sellerSelected),
    getSellerObject: state => sellerId => state.sellerResumeList.filter(seller => seller.id === sellerId)[0],
    getStores: state => state.storesList,
    getActiveStoreObject: (state, getters) => getters.getStoreObject(state.storeSelected),
    getStoreObject: state => storeId => state.storesList.filter(store => store.id === storeId)[0],
  },
};

export default npsAnalytics;
