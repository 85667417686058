import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'churnltv';

export const getMetricsPeriod = ({ metrics = '', graphic = 0 }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios.get(`${URI}/getMetricsPeriod`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      metrics: metrics.toString(),
      startDate,
      endDate,
      graphic,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getMetricsSegment = ({ metrics = '' }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios.get(`${URI}/getMetricsSegment`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      metrics: metrics.toString(),
      startDate,
      endDate,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
