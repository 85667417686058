<template>
  <div id="login-form">
    <login-form-logo />
    <div>
      <div class="email-change">
        <span class="description text-center email">{{ showEmail }}</span>
        <span class="description text-center change" @click.prevent="loaderBackToEmail">{{ $t('login.change') }}</span>
      </div>
    </div>
    <div class="card-body">
      <form novalidate @submit.prevent="onSubmit()">
        <div id="login-form-global" class="items">
          <div id="login-form-password" class="form-group" data-trigger="manual">
            <label>{{ $t('login.authentication-code') }}</label>
            <input
              v-model="mfa"
              v-focus
              type="text"
              name="mfa"
              :placeholder="$t('login.mfa-placeholder')"
              class="w-100"
              :class="{ 'is-invalid': v$.mfa.$invalid }"
              @keypress="maskInput"
              @blur="inputBlur"
              v-tooltip.left="{
                theme: 'error',
                autoHide: false,
                distance: 8,
                content: tooltipText,
                shown: isShowingTooltip,
              }"
            />
          </div>
        </div>
        <button type="submit" class="btn w-100 submit">
          <span>{{ $t('login.authentication') }}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
// libs
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
// helpers
import loginLoaderMixin from '@/helpers/mixins/loginLoaderMixin';
// components
import LoginFormLogo from '@/components/login/_atoms/LoginFormLogo';

export default {
  components: {
    LoginFormLogo,
  },
  mixins: [loginLoaderMixin],
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    showEmail: {
      type: String,
      default: '',
    },
    receivePassword: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      mfa: '',
      loading: true,
      showText: true,
      isShowingTooltip: false,
      tooltipText: this.$t('login.mfa-error'),
    };
  },
  validations() {
    return {
      mfa: {
        required,
      },
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  watch: {
    error(val) {
      if (val) {
        this.$parent.changeLoginLoading(!this.loading, !this.showText);
        this.showGlobalError();
      }
    },
  },
  methods: {
    loaderBackToEmail() {
      return this.$_loaderLogin('email', this.loading, this.showText);
    },
    onSubmit() {
      if (!this.v$.mfa.$invalid) {
        this.login();
      } else {
        this.showGlobalError();
      }
    },
    login() {
      this.$parent.changeLoginLoading(this.loading, !this.showText);

      // reset error to default
      this.$parent.error = false;

      this.$store.dispatch('login', {
        email: this.showEmail,
        password: this.receivePassword,
        mfa: this.mfa.replace(' ', ''),
      });
    },
    inputBlur() {
      this.hideTooltips();
      const element = document.querySelector('input[name=mfa]');

      if (this.v$.mfa.$invalid) {
        this.isShowingTooltip = true;
        element.classList.add('error');
      } else {
        this.isShowingTooltip = false;
        element.classList.remove('error');
      }
    },
    maskInput(event) {
      const { key } = event;
      this.mfa.length === 3 ? (this.mfa += ' ') : this.mfa.replace(' ', '');
      if (this.mfa.length >= 7 || !/\d/g.test(key)) event.preventDefault();
    },
    hideTooltips() {
      this.isShowingTooltip = false;
      const element = document.querySelector('input[name=mfa]');
      element.classList.remove('error');
    },
    showGlobalError() {
      this.hideTooltips();
      this.isShowingTooltip = true;

      const element = document.querySelector('input[name=mfa]');

      element.classList.add('error');
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
}
.email-change {
  align-self: center;
  text-align: center;
}
.description {
  display: block;
  text-align: left;
  color: $gray-700;
  font-size: 12px;
  font-weight: normal;
}
.change {
  color: #36aad6;
  font-weight: 600;
  display: inline-flex;
  padding: 0 0 0 10px;
  cursor: pointer;
}
.card-description {
  margin-bottom: 5px;
}
.email {
  margin-bottom: 12px;
  font-weight: 600;
  display: inline-flex;
}
img {
  margin-right: 12px;
}
.card-body {
  margin-top: 15px;
}
.btn {
  line-height: 36px;
}
</style>
