import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';
import { TableParams, ExportTableParams, IdicatorsParams } from '@/features/EngagementReport/models';

export const getSellers = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `seller/getSellersSegmenter`,
    params: {
      brand: brand.id,
    },
  });
};

export const getStores = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `360/performance/getStores`,
    params: {
      brand: brand.id,
    },
  });
};

export const getTableMetrics = (preset: string) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/engagement/getTableMetrics`,
    params: {
      brand: brand.id,
      preset,
    },
  });
};

export const getTable = ({
  preset,
  limit,
  page,
  orderBy,
  query,
  campaignId,
  storeId,
  sellerId,
  cluster,
}: TableParams) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  const serializedQuery = JSON.stringify(query);

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: '/oto/engagement/getTable',
    data: {
      brand: brand.id,
      preset,
      startDate,
      endDate,
      limit,
      page,
      orderBy,
      campaignId,
      sellerId,
      storeId,
      cluster,
      query: serializedQuery,
    },
  });
};

export const getExportTable = ({
  fileType,
  metrics,
  preset,
  campaignId,
  storeId,
  sellerId,
  cluster,
}: ExportTableParams) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: '/oto/engagement/getTable',
    data: {
      brand: brand.id,
      startDate,
      endDate,
      metrics,
      preset,
      export: fileType,
      campaignId,
      storeId,
      sellerId,
      cluster,
    },
  });
};

export const getTablePresets = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/engagement/getTablePresets`,
    params: {
      brand: brand.id,
    },
  });
};

export const getCampaigns = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/campaign/getCampaigns`,
    params: {
      brand: brand.id,
    },
  });
};

export const getClusters = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/engagement/getCluster`,
    params: {
      brand: brand.id,
    },
  });
};

export const getExportJobDetails = (batchId: string) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/engagement/getExportJobDetails`,
    params: {
      brand: brand.id,
      batchId,
    },
  });
};

export const getIndicators = ({ campaignId, sellerId, storeId, cluster }: IdicatorsParams) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `/oto/engagement/getIndicators`,
    data: {
      brand: brand.id,
      startDate,
      endDate,
      campaignId,
      sellerId,
      storeId,
      cluster,
    },
  });
};

export const postEditColumns = (metrics, preset, report) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: '/config/putUserConfig',
    params: {
      brand: brand.id,
      metrics,
      preset,
      report,
    },
  });
};
