<template>
  <div id="conversionsalesidentification" class="card">
    <div v-if="$_verifyLoaded('done') && ready" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-cart"></i>
        <span>{{ $t('identification-in-stores') }}</span>
      </h2>

      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-6 col-xl mb-sm-3">
              <div class="row">
                <div class="col-sm-6 col-xl-12">
                  <label class="chart-title">{{ $t('identified') }}</label>
                  <span class="chart-value-big d-block color-blue-500">{{
                    $_formatNumber(dataReturnValues.orders_identified_rate.data.value.amount, '0.[00]%')
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl mb-sm-3">
              <div class="row">
                <div class="col-sm-6 col-xl-12">
                  <label class="chart-title">{{ $t('valid-identified') }}</label>
                  <span class="chart-value-big d-block color-blue-500">{{
                    $_formatNumber(dataReturnValues.orders_identified_valid_rate.data.value.amount, '0.[00]%')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col">
              <chart-list :chart-name="$t('worst-performances')" :rows-list="objectChartListWorstPerformances" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// @ is an alias to /src
import getMetrics from '@/services/getmetrics';
import getTable from '@/services/getTable';
import { getPercentual, getMax } from '@/utilities/numbers';
import { formatPercent } from '@/utilities/formatters';
import ChartList from '@/components/_organisms/ChartList';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin, verifyThensMixin } from '@/helpers/mixins';

export default {
  name: 'ConversionSalesIdentification',
  components: {
    'chart-list': ChartList,
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, verifyThensMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataReturnValues: {},
      objectChartListWorstPerformances: [],
      loader: {
        loadingBadPerformances: false,
        loadingIdentified: false,
      },
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();
      this.componentHasData = [];
      this.loader.loadingBadPerformances = true;
      this.loader.loadingIdentified = true;

      getMetrics({
        metrics: ['orders_identified_rate', 'orders_identified_valid_rate'],
        segments: 1,
        version: 2,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.dataReturnValues = response.data.metrics;
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingIdentified = false;
          this.$_verifLoading();
        });

      getTable({
        metrics: ['store_name', 'orders_identified_valid_rate'],
        tbl: 'stores',
        filters: 'revenue:1',
        orderBy: 'orders_identified_valid_rate',
        limit: 3,
      })
        .then(response => {
          if (this.$_verifyData(response.data.data)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          const { data } = response.data;
          // Chart: Piores Performances - Lojas
          this.objectChartListWorstPerformances = [];
          const max = getMax(data);
          data.map(row => {
            this.objectChartListWorstPerformances.push({
              label: row[0],
              progress: String(getPercentual(row[1], max)),
              value: formatPercent(row[1] / 100, 2),
              color: 'red',
            });
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingBadPerformances = false;
          this.$_verifLoading();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @media (min-width: $xl-min-width) {
    min-height: 265px;
  }
}
</style>
