<template>
  <el-dialog v-model="isVisible" id="integration-question" class="integration-question" append-to-body>
    <div>
      <header class="integration-question">
        <i class="icon icon-error-60px color-red"></i>
      </header>
      <div class="row">
        <div class="col-12 pt-3">
          <span class="title integration-question__title">{{ $t('settings.integrations.exclude-msg') }}</span>
        </div>
      </div>
      <footer class="integration-question__action-buttons">
        <button class="btn btn-close" @click.prevent="closeModal">
          {{ $t('settings.integrations.cancel') }}
        </button>
        <button class="btn btn-confirm" @click.prevent="removeInstance">
          {{ $t('settings.integrations.continue') }}
        </button>
      </footer>
    </div>
  </el-dialog>
</template>

<script>
import bus from '@/helpers/events/bus';
import { toggleInstance } from '@/services/connectors';

export default {
  name: 'IntegrationQuestionModal',
  data() {
    return {
      id: null,
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('openQuestionModal', id => {
      this.id = id;
      this.openModal();
    });
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    removeInstance() {
      toggleInstance(this.id, 0)
        .then(() => {
          this.closeModal();
          bus.$emit('openSuccessModal', 'deactivated');
        })
        .catch(() => {
          console.error('can´t remove :/');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-question {
  padding: 0 50px;
  text-align: center;

  .icon-error-60px {
    font-size: 60px;
  }

  &__title {
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
  }

  &__action-buttons {
    display: flex;
    margin-top: 28px;
    justify-content: space-evenly;

    .btn {
      width: 136px;

      &.btn-confirm {
        background-color: $color-red;

        &:hover {
          background-color: #881e23;
        }
      }

      &.btn-close {
        background-color: $gray-600;
        &:hover {
          background-color: $gray-700;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#integration-question {
  text-align: center;
  width: 540px;
  min-height: 300px;
}
</style>
