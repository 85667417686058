<template>
  <el-dialog v-model="isVisible" id="integration-success" class="integration-success" append-to-body>
    <div>
      <header class="integration-success__header">
        <i class="icon icon-checked-60px color-oto-ecommerce"></i>
      </header>
      <div class="row">
        <div class="col-12 pt-3">
          <span class="title integration-success__title">{{ message }}</span>
        </div>
      </div>
      <footer>
        <button class="btn integration-success__btn-continue" @click.prevent="closeModal">
          {{ $t('settings.integrations.continue') }}
        </button>
      </footer>
    </div>
  </el-dialog>
</template>

<script>
import bus from '@/helpers/events/bus';

export default {
  name: 'IntegrationSuccessModal',
  data() {
    return {
      message: this.$t('settings.integrations.messages.added-success'),
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('openSuccessModal', messageType => {
      switch (messageType) {
        case 'activated':
          this.message = this.$t('settings.integrations.messages.activated-success');
          break;
        case 'deactivated':
          this.message = this.$t('settings.integrations.messages.deactivated-success');
          break;
        default:
          // default set to success message
          this.message = this.$t('settings.integrations.messages.added-success');
          break;
      }
      this.openModal();
    });
  },
  methods: {
    openModal() {
      // refresh integration list when modal open
      bus.$emit('reload-integration-list');
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-success {
  padding: 0 50px;
  text-align: center;

  .icon-checked-60px {
    font-size: 60px;
  }

  &__title {
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
  }

  &__btn-continue {
    width: 160px;
    margin: 0 auto;
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
#integration-success {
  text-align: center;
  width: 540px;
  min-height: 300px;
}
</style>
