<template>
  <div class="serie-read mr-2 mt-2" :class="{ editable: isEditable }">
    <a
      class="condition"
      @click.prevent="edit"
      v-tooltip="{
        theme: 'default',
        content: this.$t('audiences.click-edit'),
        distance: 8,
        shown: isShowingTooltip,
      }"
    >
      <span class="pr-1">{{ serie.name }}</span>
      <span v-if="!hasFilterKeys" class="text-lowercase">{{ $t(`audiences.operators.${data.operator}`) }}</span>
      <strong v-if="serie.multiple && /equal/g.test(data.operator) && data.labelField" class="pl-1">{{
        data.labelField
      }}</strong>
      <strong v-else-if="data.value" class="pl-1">{{ value }}</strong>
      <template v-if="hasFilterKeys">
        <span class="text-lowercase"
          ><span> {{ serie.filter.name }} </span>{{ $t(`audiences.operators.${data.f_operator}`) }}</span
        >
        <strong class="pl-1">{{ data.f_value[0] }}</strong>
        <template v-if="data.f_value.length == 2">
          <span class="text-lowercase"> e</span>
          <strong class="pl-1">{{ data.f_value[1] }}</strong>
        </template>
      </template>
    </a>
    <button v-if="isEditable" class="remove" :title="$t('remove')" @click.prevent="remove">
      <i aria-hidden="true" class="icon icon-close"></i>
    </button>
  </div>
</template>

<script>
// libs, helpers, services
import { isNil } from 'lodash';
import bus from '@/helpers/events/bus';

export default {
  name: 'DetailSerie',
  props: {
    id: {
      type: Number,
      default: null,
    },
    group: {
      type: Number,
      default: null,
    },
    include: {
      type: [Object, Boolean],
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: null,
    },
    formEnabled: {
      type: Boolean,
      default: null,
    },
    enable: {
      type: Function,
      default: null,
    },
    getSerie: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isShowingTooltip: false,
    };
  },
  computed: {
    serie() {
      return this.getSerie(this.data.column);
    },
    hasFilterKeys() {
      return Boolean(this.serie.filter) && Boolean(this.data.f_operator && this.data.f_value);
    },
    value() {
      const { value, labelField } = this.data;

      if (this.data.operator === 'between') return `${value[0]} - ${value[1]}`;

      if (this.serie.autocomplete && /equal|different/g.test(this.data.operator) && labelField) return labelField;

      if (!isNil(this.serie.options) && !isNil(this.serie.options[value[0]])) return this.serie.options[value[0]];

      return value[0];
    },
  },
  methods: {
    remove() {
      this.$store.dispatch('labels/removeSerie', {
        include: this.include,
        group: this.group,
        serie: this.id,
      });
    },
    edit() {
      if (this.isEditable) {
        this.enable();
        bus.$emit('audience-serie-edit', {
          /** @TODO emitter name */
          include: this.include,
          group: this.group,
          serie: this.id,
          data: { ...this.data },
        });
      }
    },
    showTooltip() {
      if (!this.isEditable) {
        this.isShowingTooltip = true;
      }
      this.isShowingTooltip = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.serie-read {
  font-size: 12px;
  color: $gray-700;
  line-height: 32px;
  background-color: $gray-300;
  border-radius: 8px;
  padding: 0 13px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.editable {
    padding-right: 5px;
    cursor: pointer;
  }

  &:hover {
    background-color: $gray-500;
    color: $color-white;
    .remove i {
      color: $color-white;
    }
  }

  .remove {
    background-color: transparent;
    border: none;
    font-size: 0;
    padding: 0;
    height: 25px;
    margin-left: 20px;
    cursor: pointer;

    i {
      color: $gray-500;
      font-size: 25px;
    }
  }
}
</style>
