<template>
  <div class="orders row">
    <div class="col-12">
      <h1 class="card-group-title">{{ $t('categories.client.title') }}</h1>
      <div class="row">
        <div class="col-sm-12 col-xl-8">
          <category-visualization-by-segment :category="category" />
        </div>
        <div class="col-sm-12 col-xl-4">
          <category-most-purchased :category="category" :product-info="productInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryMostPurchased from '@/components/categories/category/client/_molecules/CategoryMostPurchased';
import CategoryVisualizationBySegment from '@/components/categories/category/client/_molecules/CategoryVisualizationBySegment';
import bus from '@/helpers/events/bus';

export default {
  name: 'CategoryClient',
  components: {
    CategoryVisualizationBySegment,
    CategoryMostPurchased,
  },
  props: {
    category: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      productInfo: Object,
    };
  },
  mounted() {
    bus.$on('update-search-fixed-data', res => {
      this.productInfo = res;
    });
  },
};
</script>
