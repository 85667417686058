import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'label';

/**
 * @description get full labels list
 */
export const getLabels = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getLabels`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description delete (disable) label with given id
 * @param {Number} labelId
 */
export const deleteLabel = labelId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    `${URI}/delete`,
    {
      brand: brand.id,
      labelId,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @description update label
 * @param {Number} labelId
 * @param {Number=} category_id
 * @param {String=} name
 * @param {String=} description
 * @param {query=} query
 */
export const updateLabel = args => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  // check label360 key to replace to '360' and send to label/update
  const params = args;
  if (params['label360'] !== undefined) {
    params['360'] = Number(params['label360']);
    delete params['label360'];
  }

  return axios.post(
    `${URI}/update`,
    {
      brand: brand.id,
      token,
      ...params,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Labels-create
 * @param {String} name
 * @param {String} query
 * @param {String} token
 * @param {String=} description
 * @param {Number=} 360
 */
export const createLabel = args => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  // check label360 key to replace to '360' and send to label/update
  const params = args;
  if (params['label360'] !== undefined) {
    params['360'] = Number(params['label360']);
    delete params['label360'];
  }

  return axios.post(
    `${URI}/create`,
    {
      brand: brand.id,
      token,
      ...params,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @description get label
 * @param {Number} labelId
 */
export const getLabel = labelId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getLabel`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      labelId,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description get brand availability of 360
 */
export const checkAccount360 = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/checkAccount360`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description get label query columns
 */
export const getColumnsChained = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getColumnsChained`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description get label count from given query
 */
export const getLabelCount = ({ labelId, query }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  return axios.get(`${URI}/getCount`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      token,
      labelId,
      query,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Labels-getResultsByField
 * @param {String} field
 * @param {String} term
 * @param {Number=} limit
 * @param {Number=} page
 * @param {String=} orderby
 */
export const getResultsByField = (field, term) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getResultsByField`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      field,
      term,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Labels-hasLabelsLeft
 */
export const getHasLabelsLeft = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/hasLabelsLeft`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};
