<template>
  <div class="metrics-content">
    <p class="metrics-text">{{ $tc(`dashboard.${label}`, 2) }}</p>
    <template v-if="metricRaw.data">
      <p :class="`${$_checkThreshold(metricRaw.data)}`" class="metrics-text">
        <span :class="`${verifyTitle(metricRaw.title)}`" :title="$_getValue(metricRaw.data.value)">
          {{ $_getValue(metricRaw.data.value) }}
        </span>
        <icon-base v-if="iconRight" :name="iconRight" :color="iconRightColor" class="icon">
          <slot name="icon"></slot>
        </icon-base>
      </p>
    </template>
  </div>
</template>

<script>
import { dashboardMixin, tableMixin } from '@/helpers/mixins';
import IconBase from '@/assets/vue-icons/IconBase';

export default {
  name: 'Metric',
  components: {
    IconBase,
  },
  mixins: [dashboardMixin, tableMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    metricRaw: {
      type: Object,
      default: () => {},
    },
    iconRight: {
      type: String,
      default: '',
    },
    iconRightColor: {
      type: String,
      default: '#888',
    },
  },
  methods: {
    verifyTitle(title) {
      if (
        title === 'Opt-In Deliverables' ||
        title === 'Opt-Out Deliverables' ||
        title === 'Undeliverables' ||
        title === 'Engaged'
      ) {
        return 'status-card';
      } else if (title === 'Sent' || title === 'Unique Opens' || title === 'Unique Clicks') {
        return 'kpi-card';
      } else return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.metrics-content {
  flex-direction: column;
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    line-height: 12px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 0;
      max-width: 130px;

      &.status-card {
        max-width: 75px;
      }
      &.kpi-card {
        max-width: 80px;
      }
    }

    &:first-of-type {
      margin-bottom: 10px;
    }
    &:last-of-type {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0;
      color: #888888;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    &.pointer-green {
      .cell {
        color: #35ba9b;
      }
    }
    &.pointer-yellow {
      .cell {
        color: #ecc847;
      }
    }
    &.pointer-red {
      .cell {
        color: #ec3942;
      }
    }
  }

  .icon {
    margin-left: 3px;
  }
}
</style>
