<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <div>
    <base-card-v2 class="cdp-card" :class="{ loadHeight: loading || hasError || isEmpty }">
      <template v-if="$_verifyLoaded('done')">
        <base-card-header :icon="$t(cardsInfo.googleUserIdSync.icon)" />
        <template slot="item-right">
          <status-ico class="ml-auto status-color" :status="cardsInfo.googleUserIdSync.integration.status" />
        </template>
        <content-header
          :card-name="cardsInfo.googleUserIdSync.name"
          :header-info="getHeaderInfo()"
          :type-header="cardsInfo.googleUserIdSync.isDoubleMatch"
        ></content-header>
        <tab-content
          :card-name="cardsInfo.googleUserIdSync.name"
          :content-info="getContentInfo()"
          :overview="cardsInfo.googleUserIdSync.data.overview"
          :type-header="cardsInfo.googleUserIdSync.isDoubleMatch"
        />
        <integration-brand
          v-if="cardsInfo.googleUserIdSync.integration.name"
          :brand="cardsInfo.googleUserIdSync.integration.name"
        />
      </template>
    </base-card-v2>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// @ is an alias to /src
import bus from '@/helpers/events/bus';
import Status from '@/components/cdp/_molecules/Status';
import HeaderContent from '@/components/cdp/_molecules/HeaderContent';
import TabContent from '@/components/cdp/_molecules/TabContent';
import IntegrationBrand from '@/components/cdp/_molecules/_atoms/IntegrationBrand';
import { getGoogleUserIdSync } from '@/services/customerDataPlatform';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import VerifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  name: 'CdpGoogleUserIdSyncCard',
  components: {
    'status-ico': Status,
    'content-header': HeaderContent,
    'tab-content': TabContent,
    'integration-brand': IntegrationBrand,
    BaseLoader,
    InfoCard,
  },
  mixins: [VerifyMixin],
  props: {
    experimental: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      cardsInfo: {},
      headersRef: {
        googleUserIdSync: ['people', 'people_identified'],
      },
      loading: false,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.user.language || 'pt-BR';
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    // request API when change date range or brand
    bus.$on('render-cards', () => {
      this.fetchData();
    });
    bus.$on('store-changed', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('store-changed');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      getGoogleUserIdSync()
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          if (data && this.enableMockup(data)) {
            const appData = data;
            appData.name = 'google-user-id-sync';
            appData.isDoubleMatch = false;
            appData.icon = 'icon-digital-influenced';
            this.cardsInfo['googleUserIdSync'] = appData;
          }
        })
        .catch(() => {
          this.cardsInfo = {};

          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enableMockup(data) {
      return (data.mock && this.$experimental) || !data.mock;
    },
    getHeaderInfo() {
      let infoHeader = Object.assign({}, this.cardsInfo.googleUserIdSync.data.overview); // eslint-disable-line
      Object.keys(infoHeader).forEach(key => {
        if (this.headersRef.googleUserIdSync.indexOf(key) === -1) {
          delete infoHeader[key];
        }
      });
      return infoHeader;
    },
    getContentInfo() {
      let infoContent = Object.assign({}, this.cardsInfo.googleUserIdSync.data.overview); // eslint-disable-line
      Object.keys(infoContent).forEach(key => {
        if (this.headersRef.googleUserIdSync.indexOf(key) !== -1) {
          delete infoContent[key];
        }
      });
      return infoContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.cdp-card {
  padding-bottom: 5px;

  &.loadHeight {
    height: 300px;
  }
}
</style>
