import { getSellersResume, getStores, getCampaigns } from '@/services/oto/sales-report';

const salesReport = {
  namespaced: true,
  state: {
    // data of groups
    segments: [
      { id: 'general', name: '_general' },
      { id: 'store', name: '_store' },
      { id: 'seller', name: '_seller' },
      { id: 'campaign', name: '_campaign' },
    ],
    stores: [],
    sellers: [],
    campaigns: [],

    pagination: {
      count: 0,
      limit: 20,
      page: 1,
    },
    limitRange: [5, 10, 20, 30, 50],

    // data of id's
    conversionWindow: 30,
    segmentId: '',
    storeId: null,
    sellerId: null,
    campaignId: null,
  },

  mutations: {
    // mutations of groups
    SET_STORES(state, storesList) {
      state.stores = storesList;
    },

    SET_SELLERS(state, sellersList) {
      state.sellers = sellersList;
    },

    SET_CAMPAIGNS(state, campaignsList) {
      state.campaigns = campaignsList;
    },

    // mutations of id's
    SET_CONVERSION_WINDOW(state, conversionWindow) {
      state.conversionWindow = conversionWindow;
    },

    SET_SEGMENT_ID(state, segmentId) {
      state.segmentId = segmentId;
    },

    SET_STORE_ID(state, storeId) {
      state.storeId = !storeId ? null : storeId;
    },

    SET_SELLER_ID(state, sellerId) {
      state.sellerId = !sellerId ? null : sellerId;
    },

    SET_CAMPAIGN_ID(state, campaignId) {
      state.campaignId = !campaignId ? null : campaignId;
    },

    RESET_PAGINATION(state) {
      state.pagination.page = 1;
      state.pagination.count = 0;
    },

    RESET_SELECTED(state) {
      state.segmentId = '';
      state.storeId = null;
      state.sellerId = null;
      state.campaignId = null;
    },
  },

  actions: {
    // set groups
    setStores({ commit }) {
      getStores().then(({ data }) => {
        if (!data) {
          commit('SET_STORES', []);
          return;
        }

        const temp = Object.entries(data)?.map(el => {
          return {
            id: el[0],
            name: el[1],
          };
        });

        const stores = [{ id: null, name: '360.sales-report.all-stores' }, ...temp];
        commit('SET_STORES', stores);
      });
    },

    setCampaigns({ commit }) {
      getCampaigns().then(data => {
        if (!data?.data) {
          commit('SET_CAMPAIGNS', []);
          return;
        }

        const temp = data?.data;
        const campaigns = [{ id: null, name: 'Todas' }, ...temp];

        commit('SET_CAMPAIGNS', campaigns);
      });
    },

    setSellers({ commit }, { term } = {}) {
      getSellersResume({ term }).then(data => {
        if (!data?.data) {
          commit('SET_SELLERS', []);
          return;
        }

        const temp = data?.data?.data;
        const sellers = [{ id: null, fullName: '360.sales-report.all' }, ...temp];

        commit('SET_SELLERS', sellers);
      });
    },

    // set id's
    setConversionWindow({ commit }, conversionWindow) {
      commit('RESET_PAGINATION');
      commit('SET_CONVERSION_WINDOW', conversionWindow);
    },

    setSegmentId({ commit }, segmentId) {
      commit('RESET_PAGINATION');
      commit('SET_SEGMENT_ID', segmentId);
    },

    setStoreId({ commit }, storeId) {
      commit('RESET_PAGINATION');
      commit('SET_STORE_ID', storeId);
    },

    setSellerId({ commit }, sellerId) {
      commit('RESET_PAGINATION');
      commit('SET_SELLER_ID', sellerId);
    },

    setCampaignId({ commit }, campaignId) {
      commit('RESET_PAGINATION');
      commit('SET_CAMPAIGN_ID', campaignId);
    },

    resetSelected({ commit }) {
      commit('RESET_PAGINATION');
      commit('RESET_SELECTED');
    },

    resetPagination({ commit }) {
      commit('RESET_PAGINATION');
    },

    setSalesReportByTerm({ dispatch }, { term }) {
      dispatch('setSellers', { term });
    },
  },

  getters: {
    // get groups
    getSegments: state => state.segments,
    getStores: state => state.stores,
    getSellers: state => state.sellers,
    getCampaigns: state => state.campaigns,

    // get id's
    getConversionWindow: state => state.conversionWindow,
    getSegmentId: state => state.segmentId,
    getStoreId: state => state.storeId,
    getSellerId: state => state.sellerId,
    getCampaignId: state => state.campaignId,
  },
};

export default salesReport;
