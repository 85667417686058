<template>
  <div class="serie-read mr-2 mt-2" :class="{ editable: isEditable }">
    <a class="condition" @click.prevent="edit">
      <span class="pr-1">{{ serie.name }}</span>
      <span v-if="!hasFilterKeys" class="text-lowercase">{{ $t(`audiences.operators.${data.operator}`) }}</span>
      <strong
        v-if="serie.multiple && /equal/g.test(data.operator) && data.labelField"
        class="pl-1 pr-1"
        v-tooltip.bottom="{
          theme: 'default',
          content: showMultipleInfo,
          distance: 8,
          html: true,
        }"
        >{{ data.labelField }}</strong
      >
      <strong v-else-if="data.value" class="pl-1">{{ value }}</strong>
      <template v-if="hasFilterKeys">
        <span class="text-lowercase"
          ><span> {{ serie.filter.name }} </span> {{ $t(`audiences.operators.${data.f_operator}`) }}</span
        >
        <strong class="pl-1">{{ data.f_value[0] }}</strong>
        <template v-if="data.f_value.length == 2">
          <span class="text-lowercase"> e</span>
          <strong class="pl-1">{{ data.f_value[1] }}</strong>
        </template>
      </template>
    </a>
    <button
      v-if="isEditable"
      class="remove"
      :title="$t('remove')"
      @click.prevent="remove"
      data-cy="btn-remove-condition"
    >
      <i aria-hidden="true" class="icon icon-close"></i>
    </button>
  </div>
</template>

<script>
import { isNil, keys, values, drop } from 'lodash';
import bus from '@/helpers/events/bus';

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    group: {
      type: Number,
      default: null,
    },
    include: {
      type: [Object, Boolean],
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: null,
    },
    formEnabled: {
      type: Boolean,
      default: null,
    },
    enable: {
      type: Function,
      default: null,
    },
    getSerie: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isShowingTooltip: false,
      tooltipText: '',
    };
  },
  computed: {
    serie() {
      return this.getSerie(this.data.column);
    },
    hasFilterKeys() {
      return Boolean(this.serie.filter) && Boolean(this.data.f_operator && this.data.f_value);
    },
    value() {
      const { value, labelField } = this.data;

      if (this.data.operator === 'between') return `${value[0]} - ${value[1]}`;

      if (this.serie.autocomplete && /equal|different/g.test(this.data.operator) && labelField) return labelField;

      if (!isNil(this.serie.options) && !isNil(this.serie.options[value[0]])) return this.serie.options[value[0]];

      return value[0];
    },
  },
  methods: {
    showMultipleInfo() {
      if (!keys(this.data?.referenceObjects).length || keys(this.data?.referenceObjects).length === 1) return;

      this.tooltipText =
        '<div class="tooltip-multiple-info">' +
        drop(values(this.data.referenceObjects)).reduce((acc, value) => acc + `${value}<br/>`, '') +
        '</div>';

      return this.tooltipText;
    },
    remove() {
      this.$store.dispatch('audience/removeSerie', {
        include: this.include,
        group: this.group,
        serie: this.id,
      });
    },
    edit() {
      if (this.isEditable) {
        this.enable();
        bus.$emit('audience-serie-edit', {
          include: this.include,
          group: this.group,
          serie: this.id,
          data: { ...this.data },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.serie-read {
  font-size: 12px;
  color: $gray-700;
  line-height: 32px;
  background-color: $gray-300;
  border-radius: 8px;
  padding: 0 13px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.editable {
    padding-right: 5px;
    cursor: pointer;
  }

  &:hover {
    background-color: $gray-500;
    color: $color-white;
    .remove i {
      color: $color-white;
    }
  }

  .remove {
    background-color: transparent;
    border: none;
    font-size: 0;
    padding: 0;
    height: 25px;
    margin-left: 20px;
    cursor: pointer;

    i {
      color: $gray-500;
      font-size: 25px;
    }
  }

  span {
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.tooltip-multiple-info-container {
  .arrow::before {
    border-bottom-color: #fff !important;
  }
  .tooltip-multiple-info {
    text-align: left;
  }
}
</style>
