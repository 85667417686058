<template>
  <base-card-v2 class="product-overview">
    <div v-show="$_verifyLoaded('done')" class="row">
      <base-card-header icon="icon-database" :title="$t('overview')" />
      <div class="col-12">
        <div class="row">
          <div class="col-sm-6 col-xl mb-sm-3">
            <div class="row">
              <div class="col-sm-6 col-xl-12">
                <label class="chart-title">{{ $t('database-clients-period') }}</label>
                <span class="chart-value-big d-block color-blue-500">{{ $_formatDecimal(overview.customers) }}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xl mb-sm-3">
            <div class="row">
              <div class="col-sm-6 col-xl-12">
                <label class="chart-title">{{ $t('product.sold-products') }}</label>
                <span class="chart-value-big d-block color-blue-500">
                  {{ $_formatDecimal(overview.product_quantity) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col">
            <chart-list :chart-name="$t('product.revenue-contribution')" :rows-list="objectChartListRevenue" />
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="error" />
  </base-card-v2>
</template>

<script>
import { getPercentual, getMax } from '@/utilities/numbers';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { formatCurrency, formatNumber } from '@/utilities/formatters';

import ChartList from '@/components/_organisms/ChartList';
import InfoCard from '@/components/_atoms/InfoCard';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  components: {
    'chart-list': ChartList,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  props: {
    overview: {
      type: Object,
      default: () => {},
    },
    load: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      objectChartListRevenue: [],
      jsonRevenue: {},
    };
  },
  watch: {
    load() {
      this.loading = this.load;
    },
    overview() {
      this.mountJSON();
      this.$_verifyData(this.overview);
    },
  },
  methods: {
    mountTooltip(percent, value, alias) {
      const { product } = this.overview.revenue_share || {};
      return `${formatNumber(percent, '0.[00]%')} (<strong>${formatCurrency(product)}</strong>) <br>${this.$t(
        alias,
      )} <br>(<strong>${formatCurrency(value)}</strong>)`;
    },
    mountJSON() {
      const revenue = this.overview.revenue_share || {};
      const hasData = Object.keys(revenue).length;
      let revenuePercent = 0;
      let categoryPercent = 0;
      let subcategoryPercent = 0;
      if (hasData) {
        revenuePercent = (revenue.product / revenue.total) * 100;
        categoryPercent = (revenue.product / revenue.category) * 100;
        subcategoryPercent = (revenue.product / revenue.subcategory) * 100;
      }
      //  clean data
      this.objectChartListRevenue = [];
      // mount lists
      const max = getMax(revenuePercent, categoryPercent, subcategoryPercent);
      // fill chart bars
      this.objectChartListRevenue.push({
        label: `% ${this.$t('product.of-total-revenue')}`,
        progress: String(getPercentual(revenuePercent, max)),
        value: String(formatNumber(revenuePercent, '0.[00]%')),
        color: 'lightblue',
        tooltip: this.mountTooltip(revenuePercent, revenue.total, 'product.of-total-revenue'),
      });
      this.objectChartListRevenue.push({
        label: `% ${this.$t('product.of-category-revenue')}`,
        progress: String(getPercentual(categoryPercent, max)),
        value: String(formatNumber(categoryPercent, '0.[00]%')),
        color: 'lightblue',
        tooltip: this.mountTooltip(categoryPercent, revenue.category, 'product.of-category-revenue'),
      });
      this.objectChartListRevenue.push({
        label: `% ${this.$t('product.of-subcategory-revenue')}`,
        progress: String(getPercentual(subcategoryPercent, max)),
        value: String(formatNumber(subcategoryPercent, '0.[00]%')),
        color: 'lightblue',
        tooltip: this.mountTooltip(subcategoryPercent, revenue.subcategory, 'product.of-subcategory-revenue'),
      });
    },
  },
};
</script>
<style lang="scss">
.product-overview {
  .divText {
    width: 90%;
  }
}
</style>
