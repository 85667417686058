<template>
  <div class="card customer-resume" :class="{ loadHeight: loading }">
    <div v-if="$_verifyLoaded('done')" class="row">
      <div class="col-12 col-lg-6 pr-3">
        <customer-view-personal-data :personal-data="data" />
      </div>
      <div class="col-12 col-lg-6 pl-3">
        <customer-view-customer-channels :personal-data="data" />
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>
<script>
import bus from '@/helpers/events/bus';
import CustomerViewPersonalData from '@/components/oto/customerview/components/CustomerViewPersonalData';
import CustomerViewCustomerChannels from '@/components/oto/customerview/components/CustomerViewCustomerChannels';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin } from '@/helpers/mixins';
import { getFullProfile } from '@/services/oto/customers';

export default {
  components: {
    CustomerViewPersonalData,
    CustomerViewCustomerChannels,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      data: [],
      customerId: null,
    };
  },
  mounted() {
    bus.$on('load-profile', customerId => {
      this.customerId = customerId;
      if (customerId) this.loadData();
    });
  },
  beforeUnmount() {
    bus.$off('load-profile');
  },
  methods: {
    loadData() {
      this.$_reqConfig();
      getFullProfile(this.customerId)
        .then(res => {
          const profile = res?.data?.profile;

          if (!profile || (this.$_verifyData(profile.name) && this.$_verifyData(profile.contacts.email))) return;
          this.data = profile;

          // dispara evento para atualizar nome no breadcrumb
          bus.$emit('update-custom-name', this.data.name);
        })
        .catch(() => {
          this.data = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.customer-resume {
  &.loadHeight {
    height: 245px;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $gray-700;
      padding: 10px 5px;
      border-bottom: 1px solid #eee;
      overflow: hidden;
      &:last-child {
        border-bottom: 0;
      }
      i {
        font-size: 18px;
        margin-right: 10px;
      }
      a {
        color: $oto-omni;
      }
      .empty {
        color: $gray-500;
      }
    }
  }
}
</style>
