<template>
  <table-list :data="dataTable" class="mt-5 loadHeight" type="filled">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column><modal-dates /></table-list-column>
        <table-list-column class="text-right">{{ $tc('order', 0) }}</table-list-column>
        <table-list-column class="text-right col-1 offset-1">{{ $t('avg-basket-size') }}</table-list-column>
        <table-list-column class="text-right">% {{ $t('repeat-clients') }}</table-list-column>
        <table-list-column class="text-right">% {{ $t('identified') }}</table-list-column>
        <table-list-column class="text-right">% {{ $t('valid-identified') }}</table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row v-for="(item, index) in dataTable.rows" :key="index">
          <table-list-column class="text-capitalize">
            {{ item.period }}
          </table-list-column>
          <table-list-column class="text-right">{{ $_formatDecimal(item.metrics.orders) }}</table-list-column>
          <table-list-column class="text-right col-1 offset-1">{{
            $_formatDecimal(item.metrics.itens_order)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.customers_recurring_rate, '0.00%')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.orders_identified_rate, '0.00%')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.orders_identified_valid_rate, '0.00%')
          }}</table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </table-list>
</template>

<script>
import moment from 'moment';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import { monthYear } from '@/utilities/constants';
import getTable from '@/services/getTable';
import ModalDates from '@/components/ModalDates';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'OrdersTable',
  components: {
    ModalDates,
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  props: {
    storeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ready: false,
      dataTable: {
        count: 0,
        rows: [],
      },
    };
  },
  mounted() {
    this.reset();
    this.fetchData();
  },
  methods: {
    fetchData() {
      getTable({
        endpoint: 'stores/get_table',
        metrics: [
          'orders',
          'itens_order',
          'customers_recurring_rate',
          'orders_identified_rate',
          'orders_identified_valid_rate',
        ],
        tbl: 'stores',
        filters: `store_id:${this.storeId}`,
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.data)) return;
          this.dataTable.rows = response.data.data;
          this.dataTable.count = this.dataTable.rows.length;

          this.dataTable.rows.forEach(item => {
            item.period = moment(item.period, 'YYYY-M').format(monthYear); // eslint-disable-line
          });
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$_reqConfig();
      this.ready = false;
      this.dataTable.rows = [];
    },
  },
};
</script>
