<template>
  <el-dialog
    v-model="isVisible"
    id="stores-edit-columns"
    :class-name="filters.metrics.length ? 'active' : ''"
    append-to-body
    width="750"
  >
    <div class="container">
      <h2 class="title">
        {{ $t('settings.stores.edit-columns') }}
      </h2>
    </div>
    <div class="container">
      <div class="wrapper">
        <div class="box metrics">
          <h4>{{ $tc('settings.stores.metrics') }}</h4>
          <div class="list b-metrics">
            <div class="form-wrapper search-and-filter">
              <input v-model="terms.metric" class="findTerm" placeholder="Buscar métricas" />
              <i v-if="terms.metric !== ''" class="icon icon-close" @click="clearTerm"></i>
              <i v-else class="icon icon-search"></i>
            </div>
            <div class="listBox customScrollBar">
              <div v-for="(metric, key) in getFilteredMetrics(terms.metric)" :key="key" class="item">
                <label
                  :class="['label', { disabled: affected.includes(metric.key) }]"
                  @click.prevent="
                    $_toggleSelection(metric.key, filters.metrics), checkInputs($event), resolveFilteredMetric()
                  "
                >
                  <check-box
                    class="checkbox"
                    :checked="affected.includes(metric.key) ? false : $_isSelected(metric.key, filters.metrics)"
                    :blocked="affected.includes(metric.key)"
                  />
                  <span v-html="getHighlightText(metric.name, terms.metric)" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="box selected-items">
          <h4>{{ $tc('settings.stores.order') }}</h4>
          <div class="selected-items-list">
            <transition name="fade">
              <div>
                <h5>
                  {{ $t('settings.stores.edit-columns-modal.selected-metrics') }}
                </h5>
                <div class="box selected-list ml-auto metrics customScrollBar">
                  <draggable @end="onDrop('metrics')">
                    <div
                      v-show="filters.metrics.length"
                      v-for="metric in filterMetrics"
                      :key="metric.key"
                      class="selected"
                    >
                      <div :id="metric.key" class="selected-title">
                        <i class="icon icon-hamburger"></i>
                        <div>{{ metric.name }}</div>
                      </div>
                      <i class="icon icon-trash" @click.prevent="removeSelectedMetric(metric.key)"></i>
                    </div>
                  </draggable>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="info-wrapper">
        <p v-show="error" class="error">
          {{ $t('settings.stores.edit-columns-modal.error') }}
        </p>
        <button class="btn" @click="submit">
          {{ $t('settings.stores.edit-columns-modal.update') }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { stringParser } from '@/helpers/string-parser.js';
import { escapeRegExp } from 'lodash'; // eslint-disable-line no-unused-vars
import { VueDraggableNext } from 'vue-draggable-next';
import { mapState, mapActions, mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
import { checkboxMixin, dashboardMixin } from '@/helpers/mixins';
import CheckBox from '@/components/_atoms/CheckBox';

const defaultfilters = {
  metrics: [],
};

export default {
  name: 'DashboardEditColumnsModal',
  components: {
    CheckBox,
    draggable: VueDraggableNext,
  },
  mixins: [checkboxMixin, dashboardMixin],
  props: {
    page: {
      type: String,
      default: 'email',
    },
  },
  data() {
    return {
      affected: [],
      filters: { ...defaultfilters },
      error: false,
      terms: {
        metric: '',
      },
      isVisible: false,
    };
  },
  computed: {
    ...mapState('settingsStores', ['metrics', 'checkedMetrics', 'filterMetrics']),
    ...mapGetters('settingsStores', ['getLastConfigMetrics', 'getFilteredMetrics']),
  },
  mounted() {
    bus.$on('open-stores-edit-columns', this.openModal);
    bus.$on('dash-clear-data-state', this.resetAll());

    // get metrics and from api
    this.updateMetrics();
  },
  beforeUnmount() {
    this.resetAll();
    bus.$off('open-stores-edit-columns');
    bus.$off('dash-clear-data-state');
  },
  methods: {
    ...mapActions('settingsStores', [
      'updateMetrics',
      'updateCheckedMetrics',
      'deleteCheckedMetric',
      'updateLastCustomConfig',
      'updateFilterMetrics',
      'resetAllStoreMetrics',
    ]),
    /**
     * @description validate input against group control
     * @param {array} checked all checked values
     * @param {array} group all values to put inside affected array
     */
    checkInputs(event) {
      if (event?.target?.className.includes('disabled')) return;
      this.resolveAffectedInputs();
    },
    /**
     * @description select affected items from group metrics and dimentions
     */
    resolveAffectedInputs() {
      // merge bove arrays
      const checkedMetrics = [...this.filters.metrics];

      // send metrics arrays to vuex
      this.updateCheckedMetrics(checkedMetrics);
    },
    /**
     * @description filter affectedd arrays
     * @param {array} checked all checked values
     * @param {array} group all values to put inside affected array
     */
    /**
     * @description hide affected metrics
     */
    hideAffected() {
      this.checksMetricsAffected();
    },
    /**
     * @description reorder filtered items to bove type of metrics after some change
     */
    reorderFilterItems() {
      this.updateFilterMetrics(this.mapOrder(this.filterMetrics, this.checkedMetrics, 'key'));
    },
    /**
     * @description checks if has some metric affected and update then
     */
    checksMetricsAffected() {
      let filtered = [];
      filtered = this.checkForAffectedMetrics();
      this.updateFilterMetrics(filtered);
    },
    /**
     * @description after a metric be checked
     * updates filtered dimensions elements
     * hides the affected and reorder drag and drop items
     */
    resolveFilteredMetric() {
      let filtered = [];
      filtered = this.checkForAffectedMetrics();
      this.updateFilterMetrics(this.mapOrder(filtered, this.filters.metrics, 'key'));

      this.hideAffected();

      this.reorderFilterItems();
    },
    /**
     * @description check if has affected metrics inside drag and drop area
     */
    checkForAffectedMetrics() {
      let cleanFromAffected = [];
      cleanFromAffected = this.removeAffected(this.checkedMetrics);
      return this.metrics.filter(el => cleanFromAffected.includes(el.key));
    },
    /**
     * @description remove affected values from drag selected area
     * @affected affected group values
     */
    removeAffected(checked) {
      return checked.filter(el => !this.affected.includes(el));
    },
    /**
     * @description remove selected metrics from filtered values and vuex state
     * @key removed key
     */
    removeSelectedMetric(key) {
      const index = this.filters.metrics.indexOf(key);
      this.filters.metrics = this.filters.metrics.filter(el => el !== key);
      this.deleteCheckedMetric(index);
      this.checksMetricsAffected();
      this.resetAffected();
    },
    /**
     * @description called after sort at custom metric list
     * @type get ids from metrics
     */
    onDrop(type) {
      const newArr = this.getManualMetricOrder();
      if (type === 'metrics') {
        this.filters.metrics = newArr;
        this.updateCheckedMetrics(newArr);
        this.updateFilterMetrics(this.mapOrder(this.filterMetrics, this.checkedMetrics, 'key'));
      }
    },
    /**
     * @description get all values from manual filter selection
     * @type get ids from metrics
     */
    getManualMetricOrder() {
      const keys = document.querySelectorAll('.selected-list.metrics .selected .selected-title');
      const attrs = [];
      keys.forEach(el => {
        attrs.push(el.getAttribute('id'));
      });
      return attrs;
    },
    /**
     * @description update bove type of metrics with correct order
     */
    refreshCheckedValues() {
      this.updateCheckedMetrics(this.filterMetrics.map(el => el.key));
    },
    /**
     * @description submit custom fields to update the table
     */
    submit() {
      if (this.hasEmptyMetrics()) {
        this.error = true;
        return;
      }

      // update checked metrics and dimentions with final selected values
      this.refreshCheckedValues();

      this.resetAll();
      this.hideModal();
      this.updateLastCustomConfig();
      bus.$emit('dashboard-update-table');
    },
    /**
     * @description checks if has items to be select - if not trigger error
     */
    hasEmptyMetrics() {
      return !this.filterMetrics?.length;
    },
    /**
     * @description apply check to all previous selected items - update vuex states
     */
    markAsChecked() {
      const { metrics, filterMetrics } = this.getLastConfigMetrics;

      if (!metrics) return;

      this.filters = {
        metrics,
      };

      // update drop area with items from the last search
      this.updateFilterMetrics(filterMetrics);

      // add affected inputs checked previouslly
      this.resolveAffectedInputs();
    },
    /**
     * @description reset all state values
     */
    resetAll() {
      this.error = false;
      this.affected = [];
      this.filters = { metrics: [] };
    },
    /**
     * @description reset affected items if no metric is selected
     */
    resetAffected() {
      if (this.filters.metrics) this.affected = [];
    },
    /**
     * @description open table custom config modal
     */
    openModal() {
      this.resetAllStoreMetrics();

      // mark checked previous selected checkbox
      this.markAsChecked();

      this.isVisible = true;
    },
    /**
     * @description hide table custom config modal
     */
    hideModal() {
      this.resetAffected();
      this.isVisible = false;
    },
    /**
     * @method getHighlightText
     * @description get word and highlight term, escape special characters and accents and wrap highlight with strong tag before show on list
     * @param {String} text
     * @param {String} highlight
     * @return list item with hightlight term in bold
     */
    getHighlightText(text, highlight) {
      // remove accents from text and highlight word
      const parsedText = stringParser(text);
      const parsedHighlight = stringParser(highlight);
      // define pattern to search with escaped special characters
      const pattern = new RegExp(escapeRegExp(parsedHighlight), 'gi');
      // get first and last index from highlight
      const startIndex = pattern.exec(parsedText).index;
      const endIndex = startIndex + highlight.length;
      // extract highlight from original text and wrap with strong tag
      const slicedText = text.slice(startIndex, endIndex);
      const wrapHightlight = `<strong>${slicedText}</strong>`;
      // return original text with wrap highlight
      return text.replace(slicedText, wrapHightlight);
    },
    /**
     * @method clearTerm
     * @description clear input v-model terms.metric
     * @param {Number} target
     */
    clearTerm() {
      this.terms.metric = '';
    },
  },
};
</script>

<style lang="scss">
#stores-edit-columns {
  .el-dialog__body {
    padding: 0 50px 25px 50px;
    overflow: hidden;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    padding: 30px 0px;
    overflow: hidden;
  }

  .box {
    width: 50%;
    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #555555;
    }
    h5 {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      color: #888;
    }
  }

  .metrics {
    .list {
      border: 1px solid #dddddd;
      box-shadow: inset 0px -3px 5px rgba(0, 0, 0, 0.07);
      height: 300px;
      overflow: hidden;
      padding: 20px 20px 0px;

      &.b-metrics {
        border-radius: 8px 0 0 8px;
      }

      .search-and-filter {
        position: relative;
        .findTerm {
          width: 100%;
        }
        i {
          position: absolute;
          right: 7px;
          top: 4px;
          font-size: 26px;
          color: #bbb;
          cursor: pointer;
        }
      }

      .listBox {
        height: calc(300px - 34px - 20px - 25px);
        margin-top: 25px;
        overflow: auto;
      }

      .item {
        padding-bottom: 3px;

        label {
          align-items: center;
          color: #666666;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          font-size: 12px;
          font-weight: normal;

          .checkbox {
            margin-right: 10px;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.3;
          }
        }
      }
    }
  }

  .selected-items {
    width: 50%;

    .selected-items-list {
      border: 1px solid #dddddd;
      box-shadow: inset 0px -3px 5px rgba(0, 0, 0, 0.07);
      height: 300px;
      overflow: hidden;
      padding: 20px 20px 0px;
      margin-top: 20px;
      border-radius: 0 8px 8px 0;
    }

    .selected-list {
      width: 100%;
      height: calc(300px - 34px - 20px - 25px);
      margin-top: 25px;
      overflow: auto;
      padding-right: 10px;

      .selected {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        font-size: 12px;

        .selected-title {
          display: flex;
          align-items: center;

          .icon {
            padding-right: 5px;
          }
        }
      }
      .icon {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .error {
      color: $color-red;
      margin: 0;
    }

    .btn {
      width: 180px;
      height: 40px;
      margin: 0 0 0 auto;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
