import { ClustersResponse } from '../models';
import { getClusters } from '../services';

export const fetchClusters = (): Promise<ClustersResponse> =>
  getClusters().then(response => formatResponse(response?.data));

const formatResponse = (response: any): ClustersResponse =>
  response?.data.map((cluster: any, index: number) => ({
    label: cluster,
    value: index,
  }));
