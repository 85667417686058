
import { computed, onMounted, onBeforeMount, ref, reactive, defineComponent, toRefs } from 'vue';
import { first, last, values } from 'lodash';
import type { Ref } from 'vue';
import { useStore } from 'vuex';
import numeral from 'numeral';
import bus from '@/helpers/events/bus';

import { getPercentual, getNumeral } from '@/utilities/numbers';
import useVerifyMixin from '@/utilities/mixins/useVerifyMixin';
import '@/plugins/tooltip/tooltip';

import ChartList from '@/components/_organisms/ChartList.vue';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';
import InfoCard from '@/components/_atoms/InfoCard.vue';

import { TopRevenueTypes, DataTopRevenueTypeResponse, RevenueTypeMetricData } from '@/features/RevenueRanking/types';

import { fetchRevenueMetrics, fechTopRevenue } from '@/features/RevenueRanking/services';

export default defineComponent({
  name: 'RankingRanking',
  components: {
    ChartList,
    BaseLoader,
    InfoCard,
  },
  setup() {
    const { hasError, isLoading, reqConfig, verifyData, verifyLoaded } = useVerifyMixin();

    const store = useStore();
    const metrics: Ref<object> = ref([]);
    const tab = 'tab-store-comparison';

    const threshold = 1099999;
    const millionFormat = '$ 0.0a';
    const currencyFormat = '$ 0,0.00';
    const revenueCurrencyFormat = '$ 0.0a';

    const events = reactive<{
      objectChartListTopRevenue: TopRevenueTypes[];
      objectChartListLowerRevenue: TopRevenueTypes[];
      isViewChartList: boolean;
      isViewChartListLower: boolean;
    }>({
      objectChartListTopRevenue: [],
      objectChartListLowerRevenue: [],
      isViewChartList: false,
      isViewChartListLower: false,
    });

    const getStoreId = computed(() => store.getters['indicators/getStoreId']);
    const getSellerId = computed(() => store.getters['indicators/getSellerId']);
    const getSourceId = computed(() => store.getters['indicators/getSourceId']);
    const getCampaignId = computed(() => store.getters['indicators/getCampaignId']);
    const getConversionWindow = computed(() => store.getters['indicators/getConversionWindow']);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function fillChartListTopRevenueObject(response: any[], getTopRevenue: number | null) {
      response.map(item => {
        events.objectChartListTopRevenue.push({
          label: item?.store,
          progress: String(getPercentual(item?.revenue, getTopRevenue)),
          value: revenueFormatCurrency(item?.revenue),
          color: 'lightblue',
          valueTooltip: String(numeral(item?.revenue).format(currencyFormat)),
          labelTooltip: item?.store,
        });
      });

      handleChartList();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function fillChartListLowerRevenueObject(response: any[], getLowerRevenue: number | null) {
      response.map(item => {
        events.objectChartListLowerRevenue.push({
          label: item?.store,
          progress: String(getPercentual(item?.revenue, getLowerRevenue)),
          value: revenueFormatCurrency(item?.revenue),
          color: 'red',
          valueTooltip: String(numeral(item?.revenue).format(currencyFormat)),
          labelTooltip: item?.store,
        });
      });
    }

    function resolveDataTopRevenue(data: DataTopRevenueTypeResponse) {
      const dataTopRevenue = data?.higherValues.slice(0, 5);
      const revenueTop = dataTopRevenue?.map(item => item?.revenue);
      const getTopRevenue = getNumeral(first(values(revenueTop)));

      fillChartListTopRevenueObject(dataTopRevenue, getTopRevenue);
    }

    function resolveDataLowerRevenue(data: DataTopRevenueTypeResponse) {
      const dataLowerRevenue = data?.lowerValues.slice(0, 5);
      const revenueLower = dataLowerRevenue?.map(item => item?.revenue);
      const getLowerRevenue = getNumeral(last(values(revenueLower)));

      fillChartListLowerRevenueObject(dataLowerRevenue, getLowerRevenue);
    }

    function fetchRevenue() {
      reqConfig();

      const args = {
        source: getSourceId.value,
        storeId: getStoreId.value,
        sellerId: getSellerId.value,
        campaignId: getCampaignId.value,
        lookback: getConversionWindow.value,
      };

      fetchRevenueMetrics(args)
        .then(response => {
          if (verifyData(response?.data)) return (isLoading.value = false);
          metrics.value = response?.data;

          if (handleStoreSellerCampaignId()) fetchTopRevenue();
          else isLoading.value = false;
        })
        .catch(() => {
          hasError.value = true;
        });
    }

    function fetchTopRevenue() {
      reqConfig();

      const args = {
        source: getSourceId.value,
        storeId: getStoreId.value,
        sellerId: getSellerId.value,
        campaignId: getCampaignId.value,
        lookback: getConversionWindow.value,
      };

      fechTopRevenue(args)
        .then(response => {
          if (verifyData(response?.data)) return;

          events.objectChartListTopRevenue = [];
          events.objectChartListLowerRevenue = [];

          const data = response?.data;

          resolveDataTopRevenue(data);
          resolveDataLowerRevenue(data);
        })
        .catch(() => {
          hasError.value = true;
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function getFormattedAmount(metric: RevenueTypeMetricData) {
      const metricType = metric?.data?.type;
      const amount = metric?.data?.amount;

      let formattedAmount = '';

      if (metricType === 'decimal') {
        formattedAmount = formatCurrency(amount);
      } else if (metricType === 'float') {
        formattedAmount = numeral(amount).format('0.00') + ' dias (média)';
      } else if (metricType === 'integer') {
        formattedAmount = numeral(amount).format('0,0');
      } else if (metricType === 'percent') {
        formattedAmount = numeral(amount).format('0.00%');
      }

      return formattedAmount;
    }

    function formatCurrency(amount: string | number) {
      return amount > threshold ? numeral(amount).format(millionFormat) : numeral(amount).format(currencyFormat);
    }

    function revenueFormatCurrency(amount: string | number) {
      return amount > threshold ? numeral(amount).format(millionFormat) : numeral(amount).format(revenueCurrencyFormat);
    }

    function reset() {
      fetchRevenue();
      events.objectChartListTopRevenue = [];
      events.objectChartListLowerRevenue = [];
      events.isViewChartList = false;
      events.isViewChartListLower = false;
    }

    function handleStoreSellerCampaignId() {
      return !getStoreId.value && !getSellerId.value && !getCampaignId.value;
    }

    function handleChartList() {
      if (events.objectChartListTopRevenue.length) events.isViewChartList = true;
      if (events.objectChartListTopRevenue.length >= 5) events.isViewChartListLower = true;
    }

    function resolveTooltipAmount(metric: RevenueTypeMetricData) {
      const metricType = metric?.data?.type;
      const amount = metric?.data?.amount;

      if (metricType !== 'decimal') return;

      return numeral(amount).format(currencyFormat);
    }

    onMounted(() => {
      reset();
      bus.$on(`indicators-select-emit-${tab}`, () => {
        reset();
      });
    });

    onBeforeMount(() => {
      bus.$off(`indicators-select-emit-${tab}`);
    });

    return {
      ...toRefs(events),
      metrics,
      verifyLoaded,
      hasError,
      getFormattedAmount,
      resolveTooltipAmount,
      formatCurrency,
    };
  },
});
