<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body>
    <div v-if="$_verifyLoaded('done')">
      <div class="cashback-modal-content">
        <base-card-header :title="$t('360.customers.cashback.accumulation-rules.title')" />
        <span>{{ rulesData.text }}</span>
      </div>
      <div class="cashback-modal-footer">
        <button class="btn" @click="closeModal">
          {{ $t('360.customers.cashback.button.continue') }}
        </button>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </el-dialog>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
import { getRules } from '@/services/oto/cashback';
import { verifyMixin } from '@/helpers/mixins';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'CashbackAccumulationModal',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    customerId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      id: 'cashback-accumulation-modal',
      rulesData: {},
      isVisible: false,
    };
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
    });
    this.fetchData();
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    fetchData() {
      this.$_reqConfig();

      getRules({ customerId: this.customerId })
        .then(({ data }) => {
          if (!data) return;
          this.rulesData = data.data;
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#cashback-accumulation-modal {
  .cashback-modal-content {
    text-align: left;
    padding: 0 30px 30px;
    height: 150px;

    span {
      font-size: 12px;
    }
  }

  .cashback-modal-footer {
    border-top: 1px solid #f1f1f1;
    padding: 20px 0 0;
    text-align: center;

    .btn {
      display: inline-block;
      width: 260px;
    }
  }

  .btn {
    background: $oto-seller;
  }
}
</style>

<style lang="scss">
#cashback-accumulation-modal {
  width: 330px;
  height: 270px;

  .el-dialog__header {
    padding: 28px 30px 0px;
    z-index: 1;
  }

  .el-dialog__body {
    margin-top: -40px;
    height: 270px;
  }
}
</style>
