<template>
  <section id="kpis" class="card">
    <div>
      <div class="chart-title-container row">
        <i class="icon dashboard-icon light-gray icon-trending-18px"></i>
        <h2 class="card-title mb-0 ml-3">{{ $t('nps.kpis') }}</h2>
      </div>
      <div class="row col-sm-12">
        <div class="col-md-7">
          <kpi-group />
        </div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-6 select-container">
              <select-seller />
            </div>
            <div class="col-sm-6">
              <select-store />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import KpiGroup from '@/components/nps/_molecules/KpiGroup';
import SelectSeller from '@/components/nps/_organisms/SelectSeller';
import SelectStore from '@/components/nps/_organisms/SelectStore';

export default {
  name: 'AnalyticsKpis',
  components: {
    KpiGroup,
    SelectSeller,
    SelectStore,
  },
};
</script>

<style lang="scss" scoped>
.chart-title-container {
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.dashboard-icon {
  font-size: 26px;
  margin-left: 10px;
  &.light-gray {
    color: $gray-500;
  }
}
</style>
