import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';
import { ContentRule } from '../models';

export const getCustomerRuleList = (page = 1, limit = 10) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/customer/getCustomerRuleList', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      page,
      limit,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getCustomerRule = (id: number) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/customer/getCustomerRule', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      page: 1,
      limit: 10,
      id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const putCustomerRule = (
  id: number,
  name: string,
  slot: number,
  enableOverload: boolean,
  content: ContentRule,
) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    '/customer/putCustomerRule',
    {
      brand: brand.id,
      id,
      name,
      slot,
      enableOverload,
      content: JSON.stringify(content),
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};
