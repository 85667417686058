import axios from 'axios';
import store from '../store';

/*
  @args {
    byGroup: false,
    groupId: 1,
    segmentOrder: ''
  }
*/
export default ({ byGroup = false, groupId = 1, locale = 'pt-BR', segmentOrder = '' } = {}) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  let uri = 'get_segment_groups';

  if (byGroup) {
    uri = 'get_segments_by_group';
  }

  return axios.get(`/segments/${uri}`, {
    params: {
      brand: brand.id,
      locale: user?.language || locale,
      ...(byGroup && { group_id: groupId }),
      ...(segmentOrder && { segment_order: segmentOrder }),
    },
  });
};
