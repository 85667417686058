<template>
  <el-dialog
    v-model="isVisible"
    width="750"
    id="indicator-edit-columns"
    :class-name="filters.metrics.length ? 'active' : ''"
  >
    <div class="container">
      <h2 class="title">
        {{ $t('dashboard.edit-columns.title') }}
      </h2>
    </div>
    <div class="container">
      <div class="wrapper">
        <div class="box metrics">
          <h4>{{ $tc('dashboard.edit-columns.metric', 2) }}</h4>
          <div class="list b-metrics">
            <div class="form-wrapper search-and-filter">
              <input v-model="terms.metric" class="findTerm" placeholder="Buscar métricas" />
              <i v-if="terms.metric !== ''" class="icon icon-close" @click="clearTerm"></i>
              <i v-else class="icon icon-search"></i>
            </div>
            <div class="listBox customScrollBar">
              <div v-for="(metric, key) in getFilteredMetrics(terms.metric)" :key="key" class="item">
                <label @click.prevent="checkItems(metric)">
                  <check-box class="checkbox" :checked="isCheckedItems(metric.key)" :blocked="false" />
                  <span v-html="getHighlightText(metric.name, terms.metric)" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="box selected-items">
          <h4>{{ $tc('dashboard.edit-columns.order') }}</h4>
          <div class="selected-items-list">
            <transition name="fade">
              <div>
                <h5>
                  {{ $t('dashboard.edit-columns.selected-metrics') }}
                </h5>
                <div class="box selected-list ml-auto metrics customScrollBar">
                  <draggable @end="onDrop('metrics')">
                    <div
                      v-show="filters.metrics.length"
                      v-for="metric in filterMetrics"
                      :key="metric.key"
                      class="selected"
                    >
                      <div :id="metric.key" class="selected-title">
                        <i class="icon icon-hamburger"></i>
                        <div>{{ metric.name }}</div>
                      </div>
                      <i class="icon icon-trash" @click.prevent="deleteCheckedMetric(metric.key)"></i>
                    </div>
                  </draggable>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="info-wrapper">
        <p v-show="error" class="error">
          {{ $t('dashboard.edit-columns.error') }}
        </p>
        <button class="btn" @click="submit">
          {{ $t('table-settings.apply') }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { stringParser } from '@/helpers/string-parser.js';
import { escapeRegExp } from 'lodash'; // eslint-disable-line no-unused-vars
import { VueDraggableNext } from 'vue-draggable-next';
import { mapState, mapActions, mapGetters } from 'vuex';
import bus from '@/helpers/events/bus';
import { checkboxMixin, dashboardMixin } from '@/helpers/mixins';
import CheckBox from '@/components/_atoms/CheckBox';
import { useMixpanelUpdateColumns } from '@/utilities/mixpanel';

const defaultfilters = {
  metrics: [],
};

export default {
  name: 'DashboardEditColumnsModal',
  components: {
    CheckBox,
    draggable: VueDraggableNext,
  },
  mixins: [checkboxMixin, dashboardMixin],
  data() {
    return {
      filters: { ...defaultfilters },
      error: false,
      terms: {
        metric: '',
      },
      isVisible: false,
    };
  },
  computed: {
    ...mapState('indicators', ['metrics', 'checkedMetrics', 'filterMetrics']),
    ...mapGetters('indicators', ['getLastConfigMetrics', 'getFilteredMetrics']),
  },
  mounted() {
    bus.$on('open-indicator-edit-columns', this.openModal);
    bus.$on('dash-clear-data-state', this.resetAll());
    bus.$on('reset-all', () => {
      this.updateMetrics();
      this.resetAll();
    });

    // get metrics from api
    this.updateMetrics();
  },
  beforeUnmount() {
    this.resetAll();
    bus.$off('open-indicator-edit-columns');
    bus.$off('dash-clear-data-state');
    bus.$off('reset-all');
  },
  methods: {
    ...mapActions('indicators', [
      'updateMetrics',
      'updateCheckedMetrics',
      'deleteCheckedMetric',
      'updateLastCustomConfig',
      'updateFilterMetrics',
      'resetAllStoreMetrics',
      'setUpdateCheckedFilterMetrics',
    ]),
    checkItems(item) {
      if (this.filterMetrics.map(item => item.key).includes(item.key)) return this.deleteCheckedMetric(item.key);
      this.setUpdateCheckedFilterMetrics(item);
    },
    isCheckedItems(item) {
      const filterMetricsKeys = this.filterMetrics.map(item => item.key);
      return filterMetricsKeys.includes(item);
    },
    reorderFilterItems() {
      this.updateFilterMetrics(this.mapOrder(this.filterMetrics, this.checkedMetrics, 'key'));
    },
    onDrop(type) {
      const newArr = this.getManualMetricOrder();
      if (type === 'metrics') {
        this.filters.metrics = newArr;
        this.updateCheckedMetrics(newArr);
        this.updateFilterMetrics(this.mapOrder(this.filterMetrics, this.checkedMetrics, 'key'));
      }
    },
    getManualMetricOrder() {
      const keys = document.querySelectorAll('.selected-list.metrics .selected .selected-title');
      const attrs = [];
      keys.forEach(el => {
        attrs.push(el.getAttribute('id'));
      });
      return attrs;
    },
    refreshCheckedValues() {
      this.updateCheckedMetrics(this.filterMetrics.map(el => el.key));
    },
    submit() {
      if (this.hasEmptyMetrics()) {
        this.error = true;
        return;
      }
      useMixpanelUpdateColumns('view_oto_indicators_update-columns', this.filters.metrics);

      // update checked metrics and dimentions with final selected values
      this.refreshCheckedValues();

      this.resetAll();
      this.hideModal();
      this.updateLastCustomConfig();
      bus.$emit('dashboard-update-table');
    },
    hasEmptyMetrics() {
      return !this.filterMetrics?.length;
    },
    markAsChecked() {
      const { metrics, filterMetrics } = this.getLastConfigMetrics;

      if (!metrics) return;

      this.filters = {
        metrics,
      };

      // update drop area with items from the last search
      this.updateFilterMetrics(filterMetrics);
    },
    resetAll() {
      this.error = false;
      this.filters = { metrics: [] };
    },
    openModal() {
      this.resetAllStoreMetrics();

      // mark checked previous selected checkbox
      this.markAsChecked();

      this.isVisible = true;
    },
    hideModal() {
      this.isVisible = false;
    },
    getHighlightText(text, highlight) {
      // remove accents from text and highlight word
      const parsedText = stringParser(text);
      const parsedHighlight = stringParser(highlight);
      // define pattern to search with escaped special characters
      const pattern = new RegExp(escapeRegExp(parsedHighlight), 'gi');
      // get first and last index from highlight
      const startIndex = pattern.exec(parsedText).index;
      const endIndex = startIndex + highlight.length;
      // extract highlight from original text and wrap with strong tag
      const slicedText = text.slice(startIndex, endIndex);
      const wrapHightlight = `<strong>${slicedText}</strong>`;
      // return original text with wrap highlight
      return text.replace(slicedText, wrapHightlight);
    },
    clearTerm() {
      this.terms.metric = '';
    },
  },
};
</script>

<style lang="scss">
#indicator-edit-columns {
  .el-dialog__body {
    padding: 0 50px 25px 50px !important;
    overflow: hidden;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    padding: 30px 0px;
    overflow: hidden;
  }

  .box {
    width: 50%;
    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #555555;
    }
    h5 {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      color: #888;
    }
  }

  .metrics {
    .list {
      border: 1px solid #dddddd;
      box-shadow: inset 0px -3px 5px rgba(0, 0, 0, 0.07);
      height: 300px;
      overflow: hidden;
      padding: 20px 20px 0px;

      &.b-metrics {
        border-radius: 8px 0 0 8px;
      }

      .search-and-filter {
        position: relative;
        .findTerm {
          width: 100%;
        }
        i {
          position: absolute;
          right: 7px;
          top: 4px;
          font-size: 26px;
          color: #bbb;
          cursor: pointer;
        }
      }

      .listBox {
        height: calc(300px - 34px - 20px - 25px);
        margin-top: 25px;
        overflow: auto;
      }

      .item {
        padding-bottom: 3px;

        label {
          align-items: center;
          color: #666666;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          font-size: 12px;
          font-weight: normal;

          .checkbox {
            margin-right: 10px;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.3;
          }
        }
      }
    }
  }

  .selected-items {
    width: 50%;

    .selected-items-list {
      border: 1px solid #dddddd;
      box-shadow: inset 0px -3px 5px rgba(0, 0, 0, 0.07);
      height: 300px;
      overflow: hidden;
      padding: 20px 20px 0px;
      margin-top: 20px;
      border-radius: 0 8px 8px 0;
    }

    .selected-list {
      width: 100%;
      height: calc(300px - 34px - 20px - 25px);
      margin-top: 25px;
      overflow: auto;
      padding-right: 10px;

      .selected {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        font-size: 12px;

        .selected-title {
          display: flex;
          align-items: center;

          .icon {
            padding-right: 5px;
          }
        }
      }
      .icon {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .error {
      color: $color-red;
      margin: 0;
    }

    .btn {
      width: 180px;
      height: 40px;
      margin: 0 0 0 auto;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
