import _ from 'lodash';

const mixin = {
  data() {
    return {
      componentHasData: [],
    };
  },
  methods: {
    $_verifyThenIsEmpty() {
      // Verify if all component thens is empty
      this.isEmpty = false;
      this.thensIsNotEmpty = _.some(this.componentHasData, x => x == true);
      if (!this.thensIsNotEmpty) {
        this.$_componentHeight();
        return;
      }
    },
    // Checks and return which state a then specific is
    $_verifIsEmpty(isEmpty, hasError, loading) {
      if (hasError) return 'error';
      else if (loading) return 'loading';
      else if (isEmpty) return 'isEmpty';
      else return 'done';
    },
    // Checks if at least one component then is loading
    $_verifLoading() {
      this.loading = _.some(this.loader, x => x == true);
    },
  },
};

export default mixin;
