<template>
  <div class="logo">
    <img src="@/assets/img/oto/logo/flat/oto_logo_flat_large.png" alt="Pmweb Context Logo" title="oto" />
  </div>
</template>

<script>
export default {
  name: 'LoginFormLogo',
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
}
</style>
