import axios from 'axios';
import moment from 'moment';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'integration';

/**
 * @description get type load list
 * @see https://api-context.pmweb.com.br/v3/integration/typeLoadList
 */
export const getTypeLoadList = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/typeLoadList`, {
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @description get table list
 * @see https://api-context.pmweb.com.br/v3/integration/getAvailableTables
 */
export const getTableList = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getAvailableTables`, {
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @description get import history
 * @see https://api-context.pmweb.com.br/v3/integration/importsHistory
 * @param {String} id type Load filter
 * @param {String} status status filter
 * @param {Number} page page filter
 * @param {Number} limit limit
 * @param {String} orderBy order By
 */
export const getImportsHistory = ({ id = '', status = '', page = '', limit = '', orderBy = '' } = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = moment().subtract(31, 'days').format('YYYY-MM-DD');
  const endDate = moment().subtract(0, 'days').format('YYYY-MM-DD');

  return axios.get(`${URI}/importsHistory`, {
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      startDate: startDate,
      endDate: endDate,
      status,
      typeLoad: id,
      page,
      limit,
      orderBy,
    },
  });
};

/**
 * @description get export history
 * @see https://api-context.pmweb.com.br/v3/integration/exportsHistory
 * @param {String} export export filter
 * @param {String} connector connector filter
 * @param {Number} page Page filter
 * @param {Number} limit limit
 * @param {String} orderBy Order By
 */
export const getExportsHistory = ({
  exportData = '',
  status = '',
  connector = '',
  page = '',
  limit = '',
  orderBy = '',
} = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = moment().subtract(31, 'days').format('YYYY-MM-DD');
  const endDate = moment().subtract(0, 'days').format('YYYY-MM-DD');

  return axios.get(`${URI}/exportsHistory`, {
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      startDate: startDate,
      endDate: endDate,
      export: exportData,
      status,
      connector,
      page,
      limit,
      orderBy,
    },
  });
};

/**
 * @description get export log
 * @see https://api-context.pmweb.com.br/v3/integration/exportLog
 * @param {String} scheduleId connector filter
 * @param {Number} page page filter
 * @param {Number} limit
 */
export const getExportLog = ({ scheduleId, page, limit }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/exportLog`, {
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      scheduleId,
      page,
      limit,
    },
  });
};

/**
 * @description get import logs
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Integration-getImportLogs
 * @param {String} logId
 * @param {Number} page
 * @param {Number} limit
 */
export const getImportLogs = ({ logId, page, limit }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getImportLogs`, {
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      logId,
      page,
      limit,
    },
  });
};

/**
 * @description run export manually
 * @see https://api-context.pmweb.com.br/v3/integration/runExport
 * @param {String} scheduleId connector filter
 */
export const getRunExport = ({ scheduleId }) => {
  const brand = store.getters.selectedBrand;
  const token = store.state.auth.token;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/runExport`, {
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      scheduleId,
      token,
    },
  });
};

/**
 *
 * @description return instance groups by connector
 * @see https://labs.pmweb.com.br/docs/context/v3/integration/exportInstances
 */
export const getInstancesByConnector = state => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios.get(`${URI}/exportInstances`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      state,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description create a new audience or exportation
 * @see TODO
 */
export const putExportSchedule = ({
  instanceLoadTypeId,
  instanceConnectorId,
  instanceDateTime,
  instanceFrequencePeriod,
}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/putExportSchedule`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      type: instanceLoadTypeId,
      instance: instanceConnectorId,
      schedule: instanceDateTime,
      frequency: instanceFrequencePeriod,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
