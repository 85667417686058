<template>
  <div>
    <el-dialog v-model="isVisible" :id="id" width="540">
      <div class="importation-options d-flex flex-column align-items-center justify-content-center">
        <img
          src="@/assets/img/figures/importation_options_audiences.png"
          :alt="$t('audiences.importation.action.title')"
        />
        <div class="content">
          <div class="row">
            <div class="header col col-6">
              <span class="h1">{{ $t('audiences.importation.action.title') }}</span>
              <p class="subtitle mt-2">
                {{ $t('audiences.importation.action.subtitle') }}
              </p>
            </div>
            <div class="bt-group col col-6 d-flex flex-column align-items-center">
              <button class="btn btn-create" @click.prevent="changeMode('create')">
                {{ $t('audiences.importation.action.create') }}
              </button>
              <span class="mt-2 mb-2">{{ $t('audiences.importation.action.or') }}</span>
              <button class="btn btn-replace" @click.prevent="changeMode('replace')">
                {{ $t('audiences.importation.action.replace') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="isChildVisible" id="importation-option" append-to-body>
      <div class="content">
        <div class="title d-flex align-items-center">
          <button class="icon icon-back-arrow" @click="backOptions"></button>
          <span>{{ $t(`audiences.importation.action.option.${mode}`) }}</span>
        </div>
        <div v-if="mode === 'create'" class="input-wrapper">
          <label class="mb-2">{{ $t('audiences.importation.action.option.name') }}</label>
          <input
            id="importation-audience-name"
            v-model="audienceName"
            type="text"
            name="audience-name"
            :placeholder="$t('audiences.importation.action.option.placeholder')"
          />
        </div>
        <div v-else class="input-wrapper">
          <label class="mb-2">{{ $t('audiences.importation.action.option.audience') }}</label>
          <select id="importation-audience-id" v-model="audienceID">
            <option :value="undefined">{{ $t('select') }}</option>
            <option v-for="item in audiences" :key="item.id" :value="item.id">
              {{ `${item.id} - ${item.title}` }}
            </option>
          </select>
        </div>
        <div class="input-wrapper">
          <div class="label mb-2">
            {{ $t('audiences.importation.action.option.match') }}
          </div>
          <div class="wrap">
            <div class="d-flex mr-4 iw__container" v-for="item in importKeys" :key="item.id">
              <input :id="item.field" v-model="selectedMatch" type="radio" :name="item.field" :value="item.field" />
              <label :for="item.field">{{ formatLabelName(item.label) }}</label>
            </div>
          </div>
        </div>
        <button
          id="importation-option-submit"
          class="btn btn-submit"
          @click.prevent="submit"
          v-tooltip.right="{
            theme: 'default',
            content: `<div class='audience-importation--tooltip'>${$t('campaigns.global-error')}</div>`,
            distance: 8,
            shown: isShowingTooltip,
            html: true,
            triggers: [],
          }"
        >
          {{ $t('audiences.importation.action.option.finish') }}
        </button>
      </div>
      <load-spinner :id="id" color="white" :text="$t('audiences.importation.importing')" />
    </el-dialog>
  </div>
</template>
<script>
import bus from '@/helpers/events/bus';
import { getAudiences, getAudienceImportKeys } from '@/services/audiences';
import LoadSpinner from '@/components/_atoms/LoadSpinner';

export default {
  components: {
    'load-spinner': LoadSpinner,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      mode: undefined,
      audienceName: '',
      audienceID: '',
      selectedMatch: '',
      audiences: [],
      importKeys: [],
      isVisible: false,
      isChildVisible: false,
      isShowingTooltip: false,
    };
  },
  watch: {
    isVisible: function () {
      if (!this.isVisible && !this.isChildVisible) {
        this.reset();
      }
    },
    isChildVisible: function () {
      if (!this.isChildVisible) this.hideTooltip();
    },
  },
  mounted() {
    bus.$on('open-importation-options', () => {
      this.reset();
      this.triggerGetAudiences();
      this.isVisible = true;
    });
    this.fetchAudienceImportKeys();
  },
  beforeUnmount() {
    bus.$off('open-importation-options');
  },
  methods: {
    changeMode(mode) {
      this.mode = mode;
      this.close();
      setTimeout(() => (this.isChildVisible = true), 300);
    },
    triggerGetAudiences() {
      getAudiences().then(({ data }) => {
        const validAudiences = data.data.filter(data => data[4] === this.$store.getters.user.id);
        this.audiences = validAudiences.map(data => ({
          id: data[0],
          title: data[1],
        }));
      });
    },
    backOptions() {
      this.isChildVisible = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    submit() {
      this.hideTooltip();
      if ((this.audienceName || this.audienceID) && this.selectedMatch) {
        this.$parent.initUploadFile(this.id, this.audienceName, this.selectedMatch, this.audienceID);
        setTimeout(() => bus.$emit('open-modal-process-progress-importation'), 300);
        this.isChildVisible = false;
      } else {
        this.isShowingTooltip = true;
      }
    },
    hideTooltip() {
      this.isShowingTooltip = false;
    },
    reset() {
      this.audienceName = '';
      this.audienceID = '';
      this.selectedMatch = '';
      this.hideTooltip();
    },
    fetchAudienceImportKeys() {
      getAudienceImportKeys().then(({ data }) => {
        if (!data.data) return;
        this.importKeys = data.data.import_keys;
      });
    },
    formatLabelName(item) {
      return item.replace('_', ' ');
    },
  },
};
</script>

<style lang="scss">
#audiences-importation-options {
  .content {
    margin-top: 50px;
  }
  .btn {
    line-height: inherit;
    padding: 10px;
    width: 230px;
  }
  .el-dialog__body {
    padding: 25px 50px;
  }
}

#spinner-audiences-importation-options {
  &:before {
    border-radius: 10px;
  }
}

#importation-option {
  width: 540px;

  .content {
    padding: 0 50px 0;
  }
  .title {
    font-size: 18px;
    color: $gray-700;
    font-weight: 600;
    margin-bottom: 30px;
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: $gray-600;
      padding-left: 0;
    }
  }
  .input-wrapper {
    margin-bottom: 20px;
  }
  input,
  select {
    width: 100%;
  }
  .btn {
    margin: 0 auto;
  }

  .wrap {
    flex-wrap: wrap;
    .iw__container {
      margin-bottom: 10px;
    }
  }
}

.audience-importation--tooltip {
  width: 200px;
}
</style>
