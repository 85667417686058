<template>
  <div v-if="$_verifyLoaded('done')" class="customer-view-labels card">
    <div class="row">
      <div class="cvl__title col-md-2">
        <icon-base w="22" h="26" color="#666" box="0 0 19 22"><icon-label /></icon-base>
        <p>{{ $t('360.customer-view.customer-labels') }}</p>
      </div>
      <div class="col-md-10 cvl__cards">
        <div v-for="(label, index) in labels" :key="index" class="cvl__card">
          <icon-base w="16" h="18" color="#fff" box="0 0 19 22"><icon-label /></icon-base>
          <p>{{ label.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';

// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconLabel from '@/assets/vue-icons/icons/Label';

import { getFullProfile } from '@/services/oto/customers';

import { verifyMixin } from '@/helpers/mixins';

export default {
  name: 'CustomerLabels',
  components: {
    IconBase,
    IconLabel,
  },
  mixins: [verifyMixin],
  data() {
    return {
      labels: [],
      customerId: '',
    };
  },
  mounted() {
    bus.$on('load-profile', customerId => {
      this.customerId = customerId;
      if (customerId) this.fetchData();
    });
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getFullProfile(this.customerId)
        .then(res => {
          if (this.$_verifyData(res?.data?.profile?.labels)) return;

          this.labels = res?.data?.profile?.labels;
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-view-labels {
  height: auto;
  width: 100%;
  padding: 5px;

  .cvl__title {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p {
      padding: 0 5px;
      margin-bottom: 0;
    }
    svg {
      margin-top: 8px;
    }
  }

  .cvl__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 20px;
  }

  .cvl__card {
    background-color: $purple-200;
    margin: 10px;
    padding: 0 15px;
    border-radius: 20px;
    height: 28px;
    display: flex;
    align-items: center;

    p {
      font-size: 11px;
      color: $color-white;
      font-weight: 600;
      margin-left: 5px;
      margin-bottom: 0;
    }
    svg {
      margin-top: 2px;
    }
  }
}
</style>
