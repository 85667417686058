
// libs
import { useStore } from 'vuex';
import { ref, reactive, toRefs, computed, onMounted, watch, defineComponent } from 'vue';
import vSelect from 'vue-select';
// services
import { fetchAvailableColumns } from '@/features/RawDataAccess/services';

interface ArrayOfFiltersTypes {
  column: string;
  operator: string;
  value: string;
}

interface EventsTypes {
  columnsOptions: {
    key: string;
    name: string;
    operators?: string[];
    options?: object;
    type: string;
  }[];

  filter: {
    column: string;
    operator: string;
    value: string;
  };
  isLoading: boolean;
}

export default defineComponent({
  name: 'RawDataAccessFilter',
  components: {
    vSelect,
  },
  setup() {
    const store = useStore();

    const arrayOfFilters = ref<ArrayOfFiltersTypes[]>([]);
    const events: EventsTypes = reactive({
      columnsOptions: [],
      filter: {
        column: '',
        operator: '',
        value: '',
      },
      isLoading: false,
    });

    // getters
    const getIsFilterOpen = computed(() => store.getters['rawDataAccess/getIsFilterOpen']);
    const getSelectedTable = computed(() => store.getters['rawDataAccess/getSelectedTable']);

    // actions
    const setFilters = (filter: ArrayOfFiltersTypes[]) => {
      store.dispatch('rawDataAccess/setFilters', filter);
    };
    const setIsApplyFilter = (boolean: boolean) => store.dispatch('rawDataAccess/setIsApplyFilter', boolean);
    const setIsSummaryVisible = (boolean: boolean) => store.dispatch('rawDataAccess/setIsSummaryVisible', boolean);

    // watchers
    watch(getSelectedTable, () => {
      events.filter = { column: '', operator: '', value: '' };
      arrayOfFilters.value = [events.filter];
      setFilters(arrayOfFilters.value);
      fetchColumns();
    });
    watch(
      arrayOfFilters,
      () => {
        resetFilter();
      },
      { deep: true },
    );
    watch(getIsFilterOpen, () => {
      if (!getIsFilterOpen.value) {
        defaultFilter();
      }
    });

    // mounted
    onMounted(() => {
      fetchColumns();
    });

    // functions
    function applyFilter() {
      setFilters(arrayOfFilters.value);
      setIsApplyFilter(true);
      verifyIfHasOperator();
    }

    function verifyIfHasOperator() {
      const hasOperator = arrayOfFilters.value.map(filters => filters.operator).some(operator => operator !== '');
      setIsSummaryVisible(hasOperator);
    }

    function findColumn(selectedColumn: string) {
      const column = events.columnsOptions.find(column => column.key === selectedColumn);
      return column;
    }

    function addFilter(filter?: ArrayOfFiltersTypes) {
      let newFilter;
      if (filter) {
        newFilter = filter;
      } else {
        newFilter = { ...events.filter, operator: '', value: '' };
      }
      arrayOfFilters.value = [...arrayOfFilters.value, newFilter];
    }

    function removeFilter(itemIndex: number) {
      const filters = arrayOfFilters.value.filter((_, index) => index !== itemIndex);
      arrayOfFilters.value = [...filters];
    }

    function resetFilter() {
      if (arrayOfFilters.value.length === 0) {
        const reset = { column: '', operator: '', value: '' };
        events.filter = reset;
        addFilter(events.filter);
      }
    }

    function resetCurrentFieldsIfSelectedColumnChanged(filterIndex: number) {
      const filter = arrayOfFilters.value.map((filter, index) =>
        index === filterIndex
          ? {
              ...filter,
              operator: '',
              value: '',
            }
          : filter,
      );
      arrayOfFilters.value = [...filter];
    }

    function isShowingLastField(operator: string) {
      return operator === 'notnull' || operator === 'isnull';
    }

    function verifyIfLastFieldHasOptions(selectedColumn: string) {
      if (findColumn(selectedColumn)?.options) return true;
    }

    function showOptionsByColumn(selectedColumn: string) {
      return findColumn(selectedColumn)?.options;
    }

    function showOperatorByColumn(selectedColumn: string) {
      return findColumn(selectedColumn)?.operators;
    }

    function defaultFilter() {
      fetchColumns();
    }

    function fetchColumns() {
      events.isLoading = true;

      fetchAvailableColumns({ table: getSelectedTable.value })
        .then(response => {
          events.columnsOptions = response?.data;
          events.filter.column = response?.data[0]?.key;
          arrayOfFilters.value = [events.filter];
        })
        .finally(() => {
          events.isLoading = false;
        });
    }

    return {
      ...toRefs(events),
      arrayOfFilters,
      getIsFilterOpen,
      applyFilter,
      addFilter,
      removeFilter,
      showOperatorByColumn,
      resetCurrentFieldsIfSelectedColumnChanged,
      isShowingLastField,
      verifyIfLastFieldHasOptions,
      showOptionsByColumn,
    };
  },
});
