<template>
  <div id="audiences-empty">
    <div class="row align-items-center">
      <div class="figure-empty-audience col col-6">
        <img
          src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png"
          :alt="$t('audiences.empty-audience.title')"
        />
      </div>
      <div class="audiences-empty-text col col-6">
        <h2>{{ $t('audiences.empty-audience.title') }}</h2>
        <p class="subtitle">
          {{ $t('audiences.empty-audience.subtitle.start') }}<br />{{ $t('audiences.empty-audience.subtitle.end') }}
        </p>
        <router-link
          class="btn btn-new-audience btn-link"
          to="/audiences/create/"
          :title="$t('audiences.create-audience')"
          >{{ $t('audiences.create-audience') }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.subtitle {
  margin-top: 20px;
}
.btn-new-audience {
  background-color: $oto-omni;
  padding: 0 15px;
  color: $color-white;
  width: 170px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background-color: $blue-700;
    color: $color-white;
  }
}
.figure-empty-audience {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;

  img {
    width: 120px;
  }
}
</style>
