import { getTablePresets } from '../services';
import { TablePresets, ResponseTablePresets } from '../models';

export const fetchTablePresets = () => getTablePresets().then(response => formatPresets(response?.data));

const formatPresets = (response: ResponseTablePresets): TablePresets => {
  return {
    all: response?.presets?.all,
    integration: response?.presets?.integration,
  };
};
