<template>
  <div class="integration-list-item">
    <div class="card integration-list-item__card">
      <div class="card-body">
        <div class="row item-header no-gutters integration-list-item__header">
          <div class="col-5">
            <h3 class="integration-list-item__connector-title">
              {{ $tc('settings.integrations.title', 1) }}
            </h3>
          </div>
          <div class="col-6 integration-list-item__wrapper">
            <img v-if="integration.alias" :src="getIcon(integration)" alt="#" />
            <span v-if="integration.name" class="integration-list-item__name">{{ integration.name }} </span>
          </div>
          <div class="col-1 integration-list-item__option-dots">
            <div class="dropdown show">
              <a
                id="dropdownIntegration"
                class="dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="icon icon-menu-14px"></i>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownIntegration">
                <a class="dropdown-item" href="#">
                  {{ $t('settings.integrations.disable') }}
                </a>
                <a class="dropdown-item" href="#">{{ $t('settings.integrations.exclude') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row item-header no-gutters integration-list-item__header">
          <div class="col-5 column-spacer">
            <h3 class="integration-list-item__connector-title">
              {{ $tc('settings.integrations.configuration', 2) }}
            </h3>
          </div>
          <div class="col-7">
            <template v-for="instance in integration.instances" :key="instance.id">
              <div class="integration-list-item__set">
                <div class="integration-list-item__info">
                  <span :class="['status', instance.status ? 'active' : '']"></span>
                  <div>
                    <p v-if="instance.name">{{ instance.name }}</p>
                  </div>
                </div>
                <div class="integration-list-item__config-edit">
                  <div v-if="instance.export && instance.status" class="pr-3">
                    <img
                      src="@/assets/svgs/Export.svg"
                      alt="icon-export"
                      @click.prevent="openCreateExportModal(instance)"
                    />
                  </div>
                  <div class="integration-list-item--toggle-activation">
                    <a href="#" @click.prevent="toggleActivation(instance.id, instance.status)">{{
                      instance.status ? $t('settings.integrations.disable') : $t('settings.integrations.active')
                    }}</a>
                  </div>
                </div>
              </div>
            </template>
            <integration-oauth-form
              button-style="list"
              :configuration="Array.isArray(configuration) ? {} : configuration"
              :item="{ alias: integration.alias, id: integration.id }"
            >
              <div class="integration-list-item__btn-add-config" type="submit">
                <i class="icon icon-plus"></i>
                <div>
                  <p>{{ $t('settings.integrations.add-config') }}</p>
                </div>
              </div>
            </integration-oauth-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import integrationMixin from '@/helpers/mixins/integrationMixin';
import IntegrationOauthForm from '@/components/settings/integrations/IntegrationOauthForm';
import { toggleInstance, getConnector } from '@/services/connectors';

export default {
  name: 'IntegrationListItem',
  components: {
    IntegrationOauthForm,
  },
  mixins: [integrationMixin],
  props: {
    integration: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      configuration: [],
    };
  },
  mounted() {
    /** check if connector need auth */
    getConnector(this.integration.id).then(({ data }) => {
      this.configuration = data.data?.configuration;
    });
  },
  methods: {
    toggleActivation(instanceId, instanceStatus) {
      toggleInstance(instanceId, !instanceStatus).then(() => {
        bus.$emit('openSuccessModal', instanceStatus ? 'deactivated' : 'activated');
      });
    },
    openCreateExportModal(instance) {
      bus.$emit('open-create-export-modal', instance);
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-list-item {
  &__card {
    padding: 0;
  }

  &__header {
    min-height: 76px;
    border-bottom: 1px solid $gray-300;
  }

  .column-spacer {
    border-right: 1px solid $gray-300;
  }

  &__connector-title {
    align-items: center;
    color: $gray-600;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    height: 100%;
    margin: 0;
    padding: 25px 15px 25px 30px;
    text-transform: uppercase;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    .connector-icon {
      align-items: center;
      border-radius: 100%;
      border: 1px solid $gray-300;
      display: flex;
      height: 48px;
      justify-content: center;
      width: 48px;
      img {
        width: 14px;
        margin-bottom: -14px;
      }
    }
  }

  &__name {
    padding-left: 12px;
    font-weight: 600;
    color: $gray-800;
  }

  &__set {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 10px 30px 10px 15px;
    border-bottom: 1px solid $gray-300;
  }

  &__info {
    display: flex;
    align-items: center;
    .status {
      background-color: $gray-500;
      border-radius: 100%;
      height: 9px;
      width: 9px;
      margin-right: 18px;
      &.active {
        background-color: #33df0b;
      }
    }
    p {
      color: $gray-600;
      font-weight: 600;
      margin: 0;
    }
  }

  &__config-edit {
    display: flex;
    width: 54px;
    justify-content: flex-end; /* space-between after set edit button */
    opacity: 0;
    transition: opacity 0.5s;

    div {
      cursor: pointer;
    }
  }

  &--toggle-activation {
    a {
      font-weight: 600;
      font-size: 12px;
      color: #36aad6;
      padding: 0;
    }
  }

  &__set,
  &__btn-add-config {
    transition: background 0.5s;
    appearance: none;

    &:hover {
      background: $gray-300;
      transition: background 0.5s;

      .integration-list-item {
        &__config-edit {
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
    }
  }

  &__btn-add-config {
    align-items: center;
    color: $gray-500;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: flex-start;
    padding: 5px;

    .icon-plus {
      margin-right: 8px;
    }

    p {
      color: $gray-600;
      font-weight: 600;
      margin: 0;
    }
  }

  &__option-dots {
    align-items: center;
    cursor: pointer;
    display: none;
    justify-content: center;
    position: relative;

    a {
      text-decoration: none;
      &::after {
        display: none;
      }
      i {
        font-size: 25px;
        color: $gray-600;
        display: block;
      }
    }

    .dropdown-menu {
      border-radius: 0;
      border: 0;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
      padding: 0;
      transform: translate3d(-130px, 20px, 0px) !important;

      .dropdown-item {
        align-items: center;
        color: $gray-800;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        height: 36px;
      }
    }
  }

  &.disabled-item {
    opacity: 0.5;

    .integration-list-item {
      &__set,
      &__btn-add-config {
        pointer-events: none;
      }
    }
  }
}
</style>
