import { formatDate } from '@/utilities/formatters';

const mixin = {
  methods: {
    $_formatDate(date, format) {
      return formatDate(date, format);
    },
  },
};

export default mixin;
