import { getStores, getSellers } from '@/services/oto/cashbackReport';

const cashback = {
  namespaced: true,
  state: {
    // data of groups
    segments: [
      { id: 'general', name: '_general' },
      { id: 'store', name: '_store' },
      { id: 'seller', name: '_seller' },
    ],
    stores: [],
    sellers: [],

    // data of id's
    segmentId: '',
    storeId: null,
    sellerId: null,
  },

  mutations: {
    // mutations of groups
    SET_STORES(state, storesList) {
      state.stores = storesList;
    },

    SET_SELLERS(state, sellersList) {
      state.sellers = sellersList;
    },

    // mutations of id's
    SET_SEGMENT_ID(state, segmentId) {
      state.segmentId = segmentId;
    },

    SET_STORE_ID(state, storeId) {
      state.storeId = !storeId ? null : storeId;
    },

    SET_SELLER_ID(state, sellerId) {
      state.sellerId = !sellerId ? null : sellerId;
    },
  },

  actions: {
    // set groups
    setStores({ commit }) {
      getStores().then(({ data }) => {
        if (!data) {
          commit('SET_STORES', []);
          return;
        }

        const temp = Object.entries(data)?.map(el => {
          return {
            id: el[0],
            name: el[1],
          };
        });

        const stores = [{ id: null, name: '360.cashback-report.all-stores' }, ...temp];
        commit('SET_STORES', stores);
      });
    },

    setSellers({ commit }) {
      getSellers().then(({ data }) => {
        if (!data) {
          commit('SET_SELLERS', []);
          return;
        }

        const temp = data?.data;
        const sellers = [{ id: null, fullName: '360.cashback-report.all' }, ...temp];

        commit('SET_SELLERS', sellers);
      });
    },

    // set id's
    setSegmentId({ commit }, segmentId) {
      commit('SET_SEGMENT_ID', segmentId);
    },

    setStoreId({ commit }, storeId) {
      commit('SET_STORE_ID', storeId);
    },

    setSellerId({ commit }, sellerId) {
      commit('SET_SELLER_ID', sellerId);
    },
  },

  getters: {
    // get groups
    getSegments: state => state.segments,
    getStores: state => state.stores,
    getSellers: state => state.sellers,

    // get id's
    getSegmentId: state => state.segmentId,
    getStoreId: state => state.storeId,
    getSellerId: state => state.sellerId,
  },
};

export default cashback;
