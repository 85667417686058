// libs
import { orderBy, map } from 'lodash';
// endpoints
import { getStoresV3, getWarningStore } from '@/services/stores';
import { getActiveSellers, getAvailableEmails } from '@/services/oto/sellers';

const sellers = {
  namespaced: true,
  state: {
    activePage: 'Sellers',
    storesList: [],
    warningStoresList: [],
    activeSellersList: [],
    emailList: [],
    loadingEmailList: false,
    loadingTab: true,
    selectedTab: {
      id: 'tab-all-sellers',
      name: 'Todos os vendedores',
    },
  },
  mutations: {
    SET_ACTIVE_PAGE(state, payload) {
      state.activePage = payload;
    },
    SET_SELECTED_TAB(state, payload) {
      state.selectedTab = payload;
    },
    SET_LOADING_TAB(state, payload) {
      state.loadingTab = payload;
    },
    ADD_STORES(state, stores) {
      state.storesList = stores;
    },
    ADD_WARNING_STORES(state, stores) {
      state.warningStoresList = stores;
    },
    ADD_ACTIVE_SELLERS(state, activeSellers) {
      state.activeSellersList = activeSellers;
    },
    SET_EMAIL_LIST(state, emails) {
      state.emailList = emails;
    },
    UPDATE_EMAIL_LOADING(state, status) {
      state.loadingEmailList = status;
    },
    RESET_EMAIL_LIST(state) {
      state.emailList = [];
    },
  },
  actions: {
    setActivePage({ commit }, payload) {
      commit('SET_ACTIVE_PAGE', payload);
    },
    setSelectedTab({ commit }, payload) {
      commit('SET_SELECTED_TAB', payload);
    },
    setLoadingTab({ commit }, payload) {
      commit('SET_LOADING_TAB', payload);
    },
    getStoresList({ commit }) {
      getStoresV3().then(({ data }) => {
        commit('ADD_STORES', data);
      });
    },
    getWarningStoresList({ commit }) {
      getWarningStore().then(({ data }) => {
        commit('ADD_WARNING_STORES', data);
      });
    },
    getActiveSellersList({ commit }) {
      getActiveSellers().then(response => {
        const data = response?.data?.data;
        commit('ADD_ACTIVE_SELLERS', data);
      });
    },
    getAvailableEmailList({ commit }, email) {
      commit('UPDATE_EMAIL_LOADING', true);
      getAvailableEmails(email)
        .then(({ data }) => {
          commit('SET_EMAIL_LIST', data.data);
        })
        .finally(() => commit('UPDATE_EMAIL_LOADING', false));
    },
    resetEmailList({ commit }) {
      commit('RESET_EMAIL_LIST');
    },
  },
  getters: {
    getActivePage: state => state.activePage,
    getOrderedStores: state =>
      orderBy(
        map(state.storesList, store => ({ id: store.id, name: store.store })),
        'name',
      ),
    getOrderedWarningStores: state =>
      orderBy(
        map(state.warningStoresList, store => ({ id: store.id, name: store.store })),
        'name',
      ),
    getSelectedTab: state => state.selectedTab,
    getOrderedActiveSellers: state =>
      orderBy(
        map(state.activeSellersList, store => ({
          id: store.id,
          name: store.name,
        })),
        'name',
      ),
    getAvailableEmails: state => state.emailList,
    getEmailLoadingStatus: state => state.loadingEmailList,
  },
};

export default sellers;
