<template>
  <section class="push-notifications__table">
    <div class="row">
      <div class="col-12 align-self-center">
        <base-card-v2 class="push-list">
          <base-card-header :title="$tc('360.push.campaigns')" icon="icon-table-30px">
            <template v-slot:item-right>
              <div class="float-right ml-auto d-flex list-actions">
                <!-- search -->
                <div class="form-wrapper search-and-filter">
                  <input ref="inputTerm" v-model="term" class="find-term" :placeholder="$t('360.push.search-table')" />
                  <i v-if="term !== ''" class="icon icon-close" @click="clearTerm"></i>
                  <i v-else class="icon icon-search"></i>
                </div>
                <!-- export -->
                <div class="btn-options">
                  <div class="export-wrapper">
                    <button ref="button" class="btn btn-secondary export" @click="toggleExport">
                      <i class="icon icon-download 18px"></i>
                      {{ this.$t('360.export', { value: '' }) }}
                    </button>
                    <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
                      <div v-for="item in exportItems" :key="item.value" class="items" @click="exportFile(item.value)">
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </base-card-header>

          <template v-if="$_verifyLoaded('done')">
            <div class="tg-list">
              <div class="tg-row -header">
                <div class="tg-col">
                  <div class="header-title">
                    {{ $t('360.push.columns.title') }}
                  </div>
                  <div :class="['order-by', { active: /title/.test(orderBy) }]" @click.prevent="ordenate('title')">
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
                <div class="tg-col">
                  {{ $t('360.push.columns.content') }}
                  <div :class="['order-by', { active: /content/.test(orderBy) }]" @click.prevent="ordenate('content')">
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
                <div class="tg-col">
                  {{ $t('360.push.columns.schedule') }}
                  <div
                    :class="['order-by', { active: /schedule/.test(orderBy) }]"
                    @click.prevent="ordenate('schedule')"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
                <div class="tg-col">
                  {{ $t('360.push.columns.audience') }}
                  <div
                    :class="['order-by', { active: /audienceName/.test(orderBy) }]"
                    @click.prevent="ordenate('audienceName')"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
                <div class="tg-col tg-align-right">
                  {{ $t('360.push.columns.sent') }}
                  <div :class="['order-by', { active: /sent/.test(orderBy) }]" @click.prevent="ordenate('sent')">
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
                <div class="tg-col tg-align-right">
                  {{ $t('360.push.columns.bounce') }}
                  <div :class="['order-by', { active: /bounce/.test(orderBy) }]" @click.prevent="ordenate('bounce')">
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
                <div class="tg-col"></div>
              </div>

              <div
                v-for="(push, index) in getFilteredPushList(term)"
                :key="index"
                :class="['tg-row', { 'refreshing-list': loading }]"
                @click.self="openPush(push.id)"
              >
                <div class="tg-col" @click="openPush(push.id)">
                  <span :id="'push' + push.id" class="title ellipsis" :class="{ disabled: push.enabled === false }">
                    {{ push.title }}
                  </span>
                </div>
                <div class="tg-col" @click="openPush(push.id)">
                  <span class="tg-content" :class="{ disabled: push.enabled === false }" :title="push.content">
                    {{ push.content }}
                  </span>
                </div>
                <div class="tg-col" @click="openPush(push.id)">
                  <span :class="{ disabled: push.enabled === false }">{{ templateDate(push.schedule) }}</span>
                </div>
                <div class="tg-col" @click="openPush(push.id)">
                  <span :class="{ disabled: push.enabled === false }">{{ push.audience.name }}</span>
                </div>
                <div class="tg-col" @click="openPush(push.id)">
                  <span class="text-right" :class="{ disabled: push.enabled === false }">
                    {{ $_getValue({ type: 'integer', amount: push.sent }) }}
                  </span>
                </div>
                <div class="tg-col" @click="openPush(push.id)">
                  <span class="text-right" :class="{ disabled: push.enabled === false }">
                    {{ $_getValue({ type: 'percent', amount: push.bounce }) }}
                  </span>
                </div>
                <menu-dropdown
                  class="push__dropdown table__column table__dropdown"
                  :label="push.id"
                  :actions="actionsDropdown(push)"
                />
              </div>
              <div v-if="!loading && pagination.count === 0" class="no-results">
                {{ $t('360.push.not-found') }}
              </div>
            </div>
          </template>

          <base-loader v-show="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
        </base-card-v2>
      </div>
    </div>

    <list-pagination :data="pagination" :limit="pagination.limit" :limit-range="[10, 20, 50]" :identify="id" />
  </section>
</template>

<script>
// libs
import moment from 'moment';
import FileDownload from 'js-file-download';
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import vClickOutside from 'click-outside-vue3';
import { useToast } from 'vue-toastification';
// services
import { getNotifications, updateNotification } from '@/services/oto/push';
// helpers
import bus from '@/helpers/events/bus';
import formatDate from '@/helpers/filter-date-format';
import { tableMixin, verifyMixin } from '@/helpers/mixins';
import { dateComplete, dateFormatYMDHms } from '@/utilities/constants';
// components
import ListPagination from '@/components/_atoms/ListPagination';
import InfoCard from '@/components/_atoms/InfoCard.vue';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import MenuDropdown from '@/components/_atoms/MenuDropdown.vue';

export default {
  name: 'PushTable',
  components: {
    BaseLoader,
    ListPagination,
    InfoCard,
    MenuDropdown,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [tableMixin, verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      id: 'push-list',
      isExportVisible: false,
      term: '',
      selectedStore: null,
      storesList: null,
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
      },
      orderBy: '-schedule',
      exportItems: [
        {
          value: 'csv',
          title: this.$t('360.export-type', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('360.export-type', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('360.export-type', { value: '.XLSX' }),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('pushNotifications', ['getFilteredPushList']),
    isFilteredListEmpty: function () {
      return isEmpty(this.getFilteredPushList(this.term));
    },
  },
  watch: {
    selectedStore: function () {
      this.fetchPushCampaigns();
    },
  },
  mounted() {
    this.fetchPushCampaigns();

    bus.$on('render-cards', () => {
      this.fetchPushCampaigns();
    });

    bus.$on('push-refresh-list', () => {
      this.fetchPushCampaigns();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.fetchPushCampaigns();
    });
  },
  beforeUnmount() {
    bus.$off(['push-refresh-list', 'render-cards', `${this.id}-change-page`]);
  },
  methods: {
    ...mapActions('pushNotifications', ['setPushList']),

    /**
     * @description trigger export
     */
    exportFile(type) {
      this.fetchExportData(type);
    },

    /**
     * @description fetch export data
     */
    fetchExportData(fileType) {
      getNotifications({ fileType, responseType: 'blob' })
        .then(data => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('push-refresh-list');
        });
    },

    /**
     * @description fetch campaigns - send arg params
     */
    fetchPushCampaigns() {
      this.$_reqConfig();

      const args = {
        ...(!isEmpty(this.term) && { term: this.term }),
        orderBy: this.orderBy,
        page: this.pagination.page,
        limit: this.pagination.limit,
      };

      getNotifications({ args })
        .then(data => {
          if (this.$_verifyData(data?.data)) return;
          this.setPushList(data.data);
          this.pagination.count = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * @description redirect to details page
     */
    openPush(id) {
      this.$router.push({
        name: '360-push-detail',
        params: { id },
      });
    },

    /**
     * @description validate error code and fire emitter
     */
    showErrorMessage(errorCode) {
      if (errorCode === 429) {
        bus.$emit(
          'show-detail-error',
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('360-sellers.title', 2).toLowerCase(),
          }),
        );
      } else {
        this.toast.error(
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('360-sellers.title', 1).toLowerCase(),
          }),
        );
        bus.$emit('header-details-input-blur');
      }
    },

    /**
     * @description change toggle status
     */
    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },

    /**
     * @description format date
     */
    templateDate(date) {
      if (!date) return;
      return formatDate(date, dateComplete);
    },

    /**
     * @description change table order
     */
    ordenate(order) {
      if (!this.orderBy) {
        this.orderBy = order;
        this.fetchPushCampaigns();
        return;
      }

      if (this.orderBy === order) this.orderBy = `-${order}`;
      else this.orderBy = order;
      this.fetchPushCampaigns();
    },

    /**
     * @description clear term
     */
    clearTerm() {
      this.term = '';
    },

    /**
     * @description actions to menu dropdown
     */
    updateEnabledValue(push) {
      const { id, audience, title, content, schedule, field, enabled } = push;

      updateNotification({
        notificationId: id,
        audience: audience.id,
        title,
        notification: content,
        schedule,
        field,
        enabled: enabled ? 0 : 1,
      })
        .then(data => {
          bus.$emit('push-refresh-list');
          if (data.success) this.toast.success(this.$t(`360.push.toast.push-disabled-${enabled}`));
        })
        .catch(() => {
          this.toast.error(this.$t('360.push.error.push-disabled'));
        });
    },
    edit(push) {
      const { id } = push;
      this.$router.push({
        name: '360-push-edit',
        params: { id },
      });
    },
    actionsDropdown(push) {
      return [
        {
          name: push.enabled ? 'disable' : 'enable',
          action: () => this.updateEnabledValue(push),
        },
        {
          name: 'edit',
          action: () => this.edit(push),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.push-notifications__table {
  .push-list {
    min-height: 560px;
  }

  .btn-options {
    display: flex;
    position: relative;

    .export-wrapper {
      display: flex;
      position: relative;
      justify-content: space-evenly;
      width: fit-content;

      .btn {
        align-items: center;
        display: flex;
        height: 36px;
        justify-content: space-evenly;
        line-height: 20px;
        width: 125px;

        .icon {
          font-weight: bold;
        }
      }

      .export-container {
        position: absolute;
        top: 40px;
        z-index: 99;
        width: 100%;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);

        .items {
          background-color: $color-white;
          color: $gray-800;
          font-weight: bold;
          padding: 10px 15px;
          font-size: 12px;
          &:hover {
            background-color: $gray-300;
            cursor: pointer;
          }
        }
      }
    }
  }

  .tg-list {
    min-height: 300px;
    font-size: 12px;
    color: $gray-800;
    .tg-row {
      display: grid;
      grid-template-columns: 20% 24% 15% 15% 8% 12% 6%;
      grid-template-rows: 40px;
      border-bottom: 1px solid $gray-300;
      justify-items: start;
      align-items: center;

      &.-header {
        font-weight: 600;

        .order-by {
          background-color: $gray-400;
          border-radius: 5px;
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
          line-height: 100%;
          padding: 3px 0;
          margin-left: 5px;
          height: 18px;
          width: 18px;
          min-width: 18px;
          text-align: center;

          .dash-arrows {
            display: block;
            color: $color-white;
            border: solid $gray-900;
            border-width: 0 1px 1px 0;
            border-radius: 0;
            display: inline-block;
            padding: 3px;
            transform: translate(0, -50%) rotate(45deg);
          }

          &.active {
            background-color: $oto-omni;

            .dash-arrows {
              border-color: $color-white;
            }
            .asc {
              transform: translate(0, -50%) rotate(45deg);
            }
            .desc {
              transform: rotate(-135deg);
            }
          }
        }

        .tg-col {
          &.tg-align-right {
            justify-content: flex-end;
          }
          &:first-child {
            padding-left: 20px;
          }
        }
      }

      &:not(.-header) {
        cursor: pointer;
        &:nth-child(even) {
          background: #eeeeee4d;
        }
        &:hover {
          background: $gray-300;
        }
        .tg-col {
          span {
            width: 100%;

            &.tg-content {
              max-width: 210px;
            }
          }
        }
        .tg-col:first-child {
          padding-left: 15px;
        }
      }

      &.refreshing-list {
        opacity: 0.5;
      }
    }
  }

  .no-results {
    text-align: center;
    margin-top: 80px;
  }

  .tg-col {
    display: flex;
    align-items: center;
    width: 100%;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 500px;
      z-index: 1;

      &.ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.title {
        color: $oto-omni;
        user-select: text;
        max-width: 300px;
      }

      &.round-name {
        border: 1px solid $gray-500;
        padding: 6px;
        border-radius: 100%;
        margin-right: 12px;
        width: 31px;
        height: 31px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
    svg {
      margin-right: 8px;
    }
  }

  .table__dropdown {
    display: flex;
    justify-self: center;
  }

  .list-actions {
    .search-and-filter {
      width: 200px;
      margin-right: 15px;
      position: relative;
      .find-term {
        width: 100%;
        height: 36px;
      }
      i {
        position: absolute;
        right: 8px;
        top: 4px;
        font-size: 26px;
        color: $gray-500;
        cursor: pointer;
      }
    }
  }
}
</style>
