<template>
  <div class="card">
    <section id="raw-data-access-header">
      <div class="rdch__title">
        <i class="icon icon-database" />
        <h1>{{ $t('integrations.raw-data-access.title') }}</h1>
      </div>
      <div class="rdch__interactive">
        <raw-data-access-select-table />
        <div class="rdch__interactive--buttons">
          <raw-data-access-filter-button />
          <raw-data-access-export-button />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// components
import RawDataAccessSelectTable from '@/features/RawDataAccess/components/RawDataAccessHeader/RawDataAccessSelectTable/RawDataAccessSelectTable';
import RawDataAccessFilterButton from '@/features/RawDataAccess/components/RawDataAccessHeader/RawDataAccessFilterButton/RawDataAccessFilterButton';
import RawDataAccessExportButton from '@/features/RawDataAccess/components/RawDataAccessHeader/RawDataAccessExportButton/RawDataAccessExportButton';

export default {
  name: 'RawDataAccessHeader',
  components: {
    RawDataAccessSelectTable,
    RawDataAccessFilterButton,
    RawDataAccessExportButton,
  },
};
</script>

<style lang="scss">
@import './index.scss';
</style>
