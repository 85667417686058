<template>
  <div id="login-form">
    <login-form-logo />
    <div class="card-body">
      <form novalidate @submit.prevent="onSubmit()">
        <div id="login-form-global" class="items">
          <div id="login-form-email" class="form-group" data-trigger="manual">
            <label>{{ $t('login.email') }}</label>
            <input
              v-model="email"
              v-focus
              type="email"
              name="email"
              :placeholder="$t('login.email-placeholder')"
              class="w-100"
              :class="{ 'is-invalid': v$.email.$invalid }"
              autocomplete="email"
              @blur="inputBlur"
              data-cy="login-form-email"
              v-tooltip.left="{
                theme: 'error',
                autoHide: false,
                distance: 8,
                content: tooltipText,
                shown: isShowingTooltip,
              }"
            />
          </div>
        </div>
        <button type="submit" class="btn w-100 submit" data-cy="btn-login-submit">
          <span>{{ $t('login.next') }}</span>
        </button>
      </form>
    </div>
    <div class="card-footer text-center">
      <a href="#" class="card-link" @click.prevent="loader()">{{ $t('forgot-email') }}</a>
    </div>
  </div>
</template>

<script>
// libs
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
// helpers
import loginLoaderMixin from '@/helpers/mixins/loginLoaderMixin';
import bus from '@/helpers/events/bus';
// components
import LoginFormLogo from '@/components/login/_atoms/LoginFormLogo';
// services
import { getVerifyEmail } from '@/services/auth';

export default {
  components: {
    LoginFormLogo,
  },
  mixins: [loginLoaderMixin],
  setup() {
    const toast = useToast();

    return {
      v$: useVuelidate(),
      toast,
    };
  },
  data() {
    return {
      email: '',
      password: '',
      loading: true,
      showText: true,
      permissionError: this.$t('login.permission-error'),
      isShowingTooltip: false,
      tooltipText: this.$t('login.email-error'),
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
  mounted() {
    bus.$on('login-error', message => {
      this.$_loaderLogin('email', this.loading, this.showText);
      this.showPermissionError(message);
    });
  },
  methods: {
    /** *
     * @loader: Method that sent to mixin $_loaderLogin parameter to set a timeout and display
     * loader when not receiving data.
     ** */
    loader() {
      return this.$_loaderLogin('recover-email', this.loading, this.showText);
    },
    onSubmit() {
      this.hideTooltips();
      this.email = this.email.trim();
      if (!this.v$.email.$invalid) {
        this.$parent.displayEmail(this.email);
        getVerifyEmail(this.email).then(data => {
          const featureFlags = data?.data?.data?.featureFlags;
          if (data?.data?.data?.hasSso) {
            window.location = data?.data?.data?.endpoint;
          } else {
            this.$parent.changeLoginMode('password');
            this.$store.dispatch('setFeatureFlags', featureFlags);
            this.login();
          }
        });
      } else {
        this.showGlobalError();
      }
    },
    login() {
      this.$parent.changeLoginLoading(this.loading, this.showText);
      this.$store
        .dispatch('email', {
          email: this.email,
        })
        .then(() => {
          setTimeout(() => {
            this.$parent.changeLoginMode('password', !this.loading, this.showText);
          }, 1000);
        })
        .catch(() => {
          this.$parent.changeLoginLoading(!this.loading, this.showText);
          this.showGlobalError();
        });
    },
    inputBlur() {
      this.hideTooltips();
      const element = document.querySelector('input[name=email]');

      if (this.v$.email.$invalid) {
        this.isShowingTooltip = true;
        element.classList.add('error');
      } else {
        this.isShowingTooltip = false;
        element.classList.remove('error');
      }
    },
    hideTooltips() {
      this.isShowingTooltip = false;
      const element = document.querySelector('input[name=email]');
      element.classList.remove('error');
    },
    showGlobalError() {
      this.hideTooltips();
      this.isShowingTooltip = true;

      const element = document.querySelector('input[name=email]');

      element.classList.add('error');
    },
    showPermissionError(message) {
      this.toast.error(message);
      bus.$off('login-error');
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: block;
  text-align: left;
  color: $gray-700;
  font-size: 12px;
  font-weight: normal;
}
.card-description {
  margin-bottom: 36px;
}
img {
  margin-right: 12px;
}
.card-body {
  margin-top: 15px;
}
.btn {
  line-height: 36px;
}
</style>
