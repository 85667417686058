<template>
  <div class="items-group">
    <!-- Case A -->
    <div v-if="caseA" class="wrap">
      <select v-model="valueProp[0]" :class="`input-${serie}`">
        <option v-for="optionKey in Object.keys(options)" :key="optionKey" :value="optionKey">
          {{ options[optionKey] }}
        </option>
      </select>
    </div>

    <!-- Case B -->
    <div v-if="caseB" class="wrap">
      <input
        v-model="valueProp[0]"
        type="number"
        :class="(success[0] ? 'success' : 'error') + ` input-${serie}`"
        @blur="evt => blur(evt.target)"
        @keypress.enter="evt => blur(evt.target)"
      />
      <i class="icon icon-attention-14px"></i>
    </div>
    <span v-if="caseB && between" class="and">e</span>
    <div v-if="caseB && between" class="wrap">
      <input
        ref="inputBetween"
        v-model="valueProp[1]"
        type="number"
        data-between="true"
        :class="(success[1] ? 'success' : 'error') + ` input-${serie}`"
        @blur="evt => blur(evt.target)"
        @keypress.enter="evt => blur(evt.target)"
      />
      <i class="icon icon-attention-14px"></i>
    </div>

    <!-- Case C -->
    <div
      v-if="caseC"
      class="wrap element--datepicker"
      :class="`${success[0] ? 'success' : 'error'} input-${serie} datepicker-${name}-${serie}`"
    >
      <el-date-picker
        v-model="startDate"
        type="date"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        :clearable="false"
        :editable="true"
        :disabled-date="disabledForwardDates"
      />
      <i class="icon icon-attention-14px"></i>
    </div>
    <span v-if="caseC && between" class="and">{{ $t('360.and') }}</span>
    <div
      v-if="caseC && between"
      class="wrap element--datepicker"
      :class="`${success[1] ? 'success' : 'error'} input-${serie} datepicker-${name}-${serie}-between`"
    >
      <el-date-picker
        v-model="endDate"
        type="date"
        format="YYYY-MM-DD"
        data-between="true"
        value-format="YYYY-MM-DD"
        :clearable="false"
        :editable="true"
        :disabled-date="disabledEndDate"
      />
      <i class="icon icon-attention-14px"></i>
    </div>

    <!-- Case D -->
    <div v-if="caseD" class="wrap">
      <template v-if="autocomplete">
        <input
          v-model="label"
          type="text"
          :class="`${success[0] ? 'success' : 'error'} input-${serie}`"
          @keypress="evt => inputSearch(evt)"
          @click="evt => inputSearch(evt)"
        />
        <i class="icon icon-attention-14px"></i>
        <div v-if="activeDropdown" class="columns-list no-click">
          <div class="list no-click">
            <div class="customScrollBar">
              <div v-if="loading" class="spinner">
                <base-loader id="audience-autocomplete-loader" :height="'415'" />
              </div>
              <template v-if="autoCompleteHasData() && !loading">
                <div
                  v-for="(item, key) in fieldsAutoComplete"
                  :key="key"
                  :title="item"
                  :class="['item', { multiselection: /equal/g.test(operator) }]"
                  @click="updateField([key, item])"
                >
                  <template v-if="multipleSelection && /equal/g.test(operator)">
                    <check-box class="no-click multiselection-content" :checked="itemsMarked.indexOf(key) !== -1" />
                    <div class="text text-truncate multiselection-content">
                      {{ item }}
                    </div>
                  </template>
                  <template v-else>
                    {{ item }}
                  </template>
                </div>
              </template>
              <template v-if="hasNoResults()">
                <div class="item off">
                  {{ $t('audiences.no-results-data') }}
                </div>
              </template>
              <template v-if="emptySearchTrigger && !loading">
                <div class="item off">
                  {{ $t('audiences.type-your-search') }}
                </div>
              </template>
            </div>
            <div
              v-if="itemsMarked.length && multipleSelection && /equal/g.test(operator)"
              class="items-marked ig-click"
            >
              <div class="text mb-2 ig-click">
                {{ $t('audiences.selected') }}
              </div>
              <div class="customScrollBar">
                <div
                  v-for="(item, key) in referenceObjects"
                  :key="key"
                  :title="item"
                  class="selecteds ig-click"
                  @click="updateField([key, item])"
                >
                  <template v-if="itemsMarked.indexOf(key) !== -1">
                    <div class="text-truncate ig-click">{{ item }}</div>
                    <icon-base :color="'#EC3942'" class="trash-icon ig-click">
                      <icon-trash />
                    </icon-base>
                  </template>
                </div>
              </div>
              <div class="update-button ig-click">
                <button class="btn" @click="updateLabelField()">
                  {{ $t('audiences.select') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- has simple input to free writing -->
      <template v-else>
        <div class="wrap">
          <input
            v-model="valueProp[0]"
            type="text"
            class="input-autocomplete"
            :class="success[0] ? 'success' : 'error'"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import _ from 'lodash';
import { validate } from '@/helpers/oto/search';

// Languages import
import { getResultByTerm } from '@/services/audiences';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import CheckBox from '@/components/_atoms/CheckBox';

import IconBase from '@/assets/vue-icons/IconBase';
import IconTrash from '@/assets/vue-icons/icons/Trash';

// helpers
import { valueType } from '@/utilities/constants';
import { disabledForwardDates, disabledBackwardDatesFromStartDate } from '@/utilities/datepicker';

let timeoutKeyUp = null;

export default {
  name: 'CustomersFilterValue',
  components: {
    CheckBox,
    IconBase,
    IconTrash,
    BaseLoader,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    options: {
      type: [Array, Object],
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
    },
    operator: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
    between: {
      type: Boolean,
      default: false,
    },
    serie: {
      type: Number,
      default: 0,
    },
    multipleSelection: {
      type: Boolean,
      default: false,
    },
    referenceObjects: {
      type: [Object, Array],
      default: () => {},
    },
    labelField: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      success: [true, true],
      fieldsAutoComplete: {},
      activeDropdown: false,
      emptySearchTrigger: false,
      loading: false,
      itemsMarked: this.value || [],
      label: '',
      valueProp: [],
      endDate: null,
      startDate: null,
    };
  },
  computed: {
    // Se tiver options >> então terá um select
    caseA() {
      return !!this.options;
    },
    // Se não tiver options E tipo int >> poderá ter between e input será type integer
    caseB() {
      return !this.caseA && (this.type === valueType.integer || this.type === valueType.decimal);
    },
    // Se não tiver options E tipo string E não format date_iso >> então terá um input text
    caseC() {
      if (this.format === 'date_iso') {
        this.applyDefaultDatepickerValue();
        return true;
      }
      return false;
    },
    // Se não tiver options E tipo string E format date_iso
    // >> poderá ter between e input será type date
    caseD() {
      return !this.caseA && !this.caseC && this.type === valueType.string;
    },
    getReferenceObjects: function () {
      return this.referenceObjects;
    },
    getItemsMarked: function () {
      return this.itemsMarked;
    },
  },
  watch: {
    value() {
      this.itemsMarked = this.value || [];
      this.valueProp = this.value;
    },
    labelField() {
      this.label = this.labelField;
    },
  },
  mounted() {
    this.dropDownClose();
    this.valueProp = this.value;
  },
  updated() {
    this.$nextTick(() => {
      bus.$on('validate-fields', () => {
        const elements = document.querySelectorAll(`.input-${this.group}-${this.serie}:visible)`);

        elements.forEach(element => {
          this.blur(element);
        });
      });
    });
  },
  beforeUnmount() {
    bus.$off('validate-fields');
    bus.$off('update-operator-options');
  },
  methods: {
    blur(target) {
      const between = target.dataset.between ? 1 : 0;
      let result;
      if (this.caseD) {
        result = validate.field(this.format, this.type, this.label);
      } else {
        result = validate.field(this.format, this.type, between ? this.valueProp[1] : this.valueProp[0]);
      }
      this.success[between] = result;

      if (!result) {
        this.$emit('showTooltip', true);
        setTimeout(() => this.$emit('showTooltip', false), 2000);
      }
    },
    inputSearch(event) {
      // if already searched for something, and has some item selected
      // when click search by the first input word
      const userTerm = event.type === 'click' ? event.target.value.split(' ')[0] : event.target.value;
      const fieldName = this.name;

      this.loading = true;
      this.activeDropdown = true;
      this.fieldsAutoComplete = {};

      clearTimeout(timeoutKeyUp);

      timeoutKeyUp = setTimeout(() => {
        if (userTerm.length > 0) {
          getResultByTerm(fieldName, userTerm)
            .then(({ data }) => {
              bus.$emit('show-spinner', 'audience-autocomplete-loader');

              if (data.has_data) {
                this.fieldsAutoComplete = Object.assign({}, data.series);
              }
              this.activeDropdown = true;
              bus.$emit('hide-spinner', 'audience-autocomplete-loader');
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          this.fieldsAutoComplete = {};
          if (!this.multipleSelection) {
            this.valueProp = [];
            this.label = '';
            bus.$emit('audience-reset-form-condition');
          }
          this.activeDropdown = true;
          this.emptySearchTrigger = true;
        }
      }, 300);
      this.blur(event.target);
    },
    openDropDown() {
      this.loading = true;
      this.activeDropdown = true;
    },
    updateLabelField() {
      bus.$emit('audience-labelField-multiselect-update');
    },
    updateField(item) {
      // declare variable with referenced objects prop
      const itemsChecked = this.getReferenceObjects;
      // check if field has multiselection
      if (this.multipleSelection && /equal/g.test(this.operator)) {
        // store index of arg item
        const ITEM_ADDED_INDEX = this.itemsMarked.indexOf(item[0]);
        // if there isn't item in itemsMarked add it to itemsMarked and update audience with value, options and items
        // if item exists remove by index using splice
        if (ITEM_ADDED_INDEX === -1) {
          this.itemsMarked.push(item[0]);
          itemsChecked[item[0]] = item[1];
        } else {
          this.itemsMarked.splice(ITEM_ADDED_INDEX, 1);
        }
        // emmit event with difference between id's in itemsMarked and options with multipleSelection boolean
        bus.$emit(
          'audience-fields-update-' + this.serie,
          _.pick(itemsChecked, this.getItemsMarked),
          this.multipleSelection,
        );
      } else {
        this.activeDropdown = false;
        bus.$emit('audience-fields-update-' + this.serie, item);
      }
    },
    updateFieldOptions(item) {
      // declare variable with referenced objects prop
      const itemsChecked = this.getReferenceObjects;
      // argument is an array of [key, value] pair
      // check if field has multiselection
      if (this.multipleSelection && /equal/g.test(this.operator)) {
        // store index of arg item
        const ITEM_ADDED_INDEX = this.itemsMarked.indexOf(item[0]);
        // if there isn't item in itemsMarked add it to itemsMarked and update audience with value, options and items
        // if item exists remove by index using splice
        if (ITEM_ADDED_INDEX === -1) {
          this.itemsMarked.push(item[0]);
          itemsChecked[item[0]] = item[1];
        } else {
          this.itemsMarked.splice(ITEM_ADDED_INDEX, 1);
        }
        // emmit event with difference between id's in itemsMarked and options with multipleSelection boolean
        bus.$emit('audience-fields-update', _.pick(itemsChecked, this.getItemsMarked), this.multipleSelection);
      } else {
        this.activeDropdown = false;
        bus.$emit('audience-fields-update', item);
      }
    },
    dropDownClose() {
      document.body.addEventListener('click', e => {
        // know classes to avoid of click out dropdown
        const PREVENTION_CLASSES = [
          'input-autocomplete',
          'multiselection',
          'multiselection-content',
          'items-marked',
          'ig-click',
        ];
        // if has any class of array on classList of any targe element,
        //  close function will be ignored
        const HAS_ANY_CLASS = Boolean(PREVENTION_CLASSES.filter(cl => e.target.classList.contains(cl)).length);
        if (this.activeDropdown && !HAS_ANY_CLASS) {
          this.activeDropdown = false;
          this.emptySearchTrigger = false;
          this.fieldsAutoComplete = {};
        }
      });
    },
    /**
     * Check if has empty input
     * @return {boolean}
     */
    hasEmptySearch() {
      let value;
      if (!this.labelField) {
        value = true;
      }
      return value;
    },
    /**
     * Show new search info to user
     * Function trigger after focus event
     */
    checkEmptySearch() {
      if (this.hasEmptySearch()) {
        this.emptySearchTrigger = true;
        this.activeDropdown = true;
        if (!this.multipleSelection) {
          bus.$emit('audience-reset-form-condition');
        }
      }
    },
    /**
     * Check if has value inside the main object
     * @return {boolean}
     */
    autoCompleteHasData() {
      return Object.keys(this.fieldsAutoComplete).length > 0;
    },
    /**
     * When contains any text inside input and isn't loading
     * @return {boolean}
     */
    hasNoResults() {
      let value;
      if (!this.hasEmptySearch() && !this.loading) {
        this.emptySearchTrigger = false;
        value = Object.keys(this.fieldsAutoComplete).length === 0;
      }
      return value;
    },
    disabledForwardDates,
    applyDefaultDatepickerValue() {
      this.valueProp[0] = this.startDate;

      if (this.between) {
        this.valueProp[0] = this.startDate;
        this.valueProp[1] = this.endDate;
      }
    },
    disabledEndDate(time) {
      return disabledBackwardDatesFromStartDate(time, this.valueProp[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-attention-14px {
  display: none;
  color: $color-red;
  font-size: 20px;
  position: absolute;
  top: 7px;
  right: 10px;
}
.error + .icon-attention-14px {
  display: block;
}
.items-group {
  display: flex;
  .and {
    display: flex;
    align-items: center;
    padding: 0 5px;
    cursor: default;
    text-transform: lowercase;
  }
}
</style>

<style lang="scss" scoped>
.columns-list {
  width: 100%;
  padding-top: 35px;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  top: 0;
  border: 1px solid $gray-500;
  border-top: 0;
  border-radius: 18px;
  z-index: 99;
  .list {
    height: 100%;
    overflow: hidden;
    background-color: $color-white;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  .customScrollBar {
    max-height: 125px;
    overflow-y: auto;
    margin: 10px 0;
    list-style: none;
    padding: 0;
    pointer-events: auto;
    font-size: 12px;
    color: $gray-700;
    line-height: 26px;
    position: relative;
    .item {
      cursor: pointer;
      padding: 0 15px;
      font-size: 12px;
      color: $gray-700;
      text-transform: capitalize;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      &.multiselection {
        display: grid;
        grid-template-columns: 16px 1fr;
        align-items: center;
        .text {
          margin-left: 10px;
        }
      }
    }
    .off {
      text-align: center;
      text-transform: initial;
    }
    .spinner {
      min-height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }
  strong {
    padding: 0 15px;
  }
}
.items-marked {
  padding: 15px 0;
  font-size: 12px;
  color: #666;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #eee;
  pointer-events: auto;
  > .text {
    padding: 0 15px;
    font-weight: 500;
    color: grey;
  }
  .selecteds {
    display: grid;
    grid-template-columns: 1fr 26px;
    padding: 0 15px;
    cursor: pointer;
    .trash-icon {
      transition: 0.3s ease-in;
      opacity: 0.4;
      grid-column-end: none;
    }
    &:hover {
      .trash-icon {
        opacity: 1;
      }
    }
  }
  .update-button {
    margin-top: 15px;
    text-align: center;
    padding: 0 10%;
    button {
      margin: auto;
      width: 100%;
      height: 40px;
      max-width: 170px;
    }
  }
}

.wrap {
  .input-options {
    cursor: pointer;
  }
}
</style>
