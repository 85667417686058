<template>
  <div class="table-sales-report" id="table-sales-report">
    <base-card-v2 height="500px" class="table-sales-report__card">
      <el-dialog class="dialog-metrics" v-model="dialogVisible" fullscreen height="70vh" width="100%">
        <template #title>
          <div class="card-title d-flex align-items-center">
            <i class="icon icon-table-30px"></i>
            <h2 class="m-0">{{ $t('360.metrics') }}</h2>
          </div>
          <div class="config-options-header">
            <div class="btn-options">
              <v-select
                v-model="preset"
                label="name"
                :clearable="false"
                :options="presets"
                @option:selected="getTableInfo('preset')"
              >
                <template #option="{ name }">
                  {{ $t(`${name}`) }}
                </template>
                <template #selected-option="{ name }">
                  {{ $t(`${name}`) }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes" class="arrow-down"></span>
                </template>
                <template #no-options>
                  {{ $t('no-results') }}
                </template>
              </v-select>
              <header-datepicker
                :user="user"
                :language="user?.language"
                route="/oto/sales-report"
                class="datepicker-table"
              />
            </div>

            <div class="btn-options">
              <div class="export-wrapper">
                <el-dropdown>
                  <el-button round>
                    {{ $t('360.export') }}
                    <i class="icon ml--4 icon-down-14px"></i>
                  </el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="resolveExport('csv')">
                        {{ $t('dashboard.export', { value: '.CSV' }) }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('xls')">
                        {{ $t('dashboard.export', { value: '.XLS' }) }}
                      </el-dropdown-item>
                      <el-dropdown-item @click="resolveExport('xlsx')">
                        {{ $t('dashboard.export', { value: '.XLSX' }) }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>
        </template>
        <el-table
          v-if="!$_verifyLoaded('loading')"
          :data="tableData.rows"
          :scrollbar-always-on="true"
          :cell-class-name="tableRowClassName"
          :header-cell-class-name="tableHeaderClassName"
          :default-sort="sort"
          @sort-change="sortTable"
          style="width: 100%"
          height="80vh"
          empty-text=" "
          stripe
        >
          <template v-for="(column, index) in getColumns" :key="index">
            <el-table-column
              sortable
              :fixed="index === 0 ?? true"
              :formatter="formatter"
              :prop="column.key"
              :label="column.label"
              :min-width="getWidth(column.key)"
            >
              <template v-if="getColumnsToSearch.no_label.includes(column.key)" #header>
                <div class="el-table__column">
                  <label> {{ getLabel(column.key) }} </label>
                  <el-popover trigger="hover" placement="bottom" :width="260">
                    <el-input
                      v-model="search[column.key]"
                      v-mask="getMask(column.key)"
                      :placeholder="getPlaceholder(column.key)"
                      size="small"
                      @keyup.enter="searchByColumn(column.key, search[column.key], index)"
                    />
                    <div style="text-align: right">
                      <el-button
                        :disabled="!search[column.key]"
                        round
                        size="small"
                        text
                        @click="clearFilter(column.key, index)"
                      >
                        {{ search[column.key] ? 'limpar filtro' : 'cancelar' }}
                      </el-button>
                      <el-button
                        :disabled="!search[column.key]"
                        round
                        size="small"
                        type="primary"
                        @click="searchByColumn(column.key, search[column.key], index)"
                        >pesquisar</el-button
                      >
                    </div>
                    <template #reference>
                      <i
                        :class="{ 'color-oto-brand-omni': columnsFiltered.map(col => col.column).includes(column.key) }"
                        class="icon icon-search-12px ml--4"
                      ></i>
                    </template>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <list-pagination
          v-if="!$_verifyLoaded('loading')"
          :data="pagination"
          :limit="pagination.limit"
          :limit-range="limitRange"
          :identify="id"
          :show-list-pages="false"
          :show-quantity-lines="true"
        />
        <base-loader v-if="$_verifyLoaded('loading')" />
        <info-card
          v-if="
            ($_verifyLoaded('info') && !isCustomTable) || (!tableData.filtered.length && !$_verifyLoaded('loading'))
          "
          ref="message-error"
          :error="hasError && !isCustomTable"
        />
      </el-dialog>
      <div v-if="!$_verifyLoaded('loading')">
        <base-card-header :title="$t('metrics')" icon="icon-table-30px" />
        <div class="config-options-header">
          <v-select
            v-model="preset"
            label="name"
            :clearable="false"
            :options="presets"
            @option:selected="getTableInfo('preset')"
            data-cy="metric-table-select"
          >
            <template #option="{ name }">
              {{ $t(`${name}`) }}
            </template>
            <template #selected-option="{ name }">
              {{ $t(`${name}`) }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('no-results') }}
            </template>
          </v-select>

          <div class="btn-options">
            <div class="export-wrapper">
              <el-dropdown>
                <el-button round data-cy="sales-report-export-btn">
                  {{ $t('360.export') }}
                  <i class="icon ml--4 icon-down-14px"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="resolveExport('csv')" data-cy="csv">
                      {{ $t('dashboard.export', { value: '.CSV' }) }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="resolveExport('xls')" data-cy="xls">
                      {{ $t('dashboard.export', { value: '.XLS' }) }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="resolveExport('xlsx')" data-cy="xlsx">
                      {{ $t('dashboard.export', { value: '.XLSX' }) }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <button ref="button" class="btn export" @click="dialogVisible = true">
                {{ $t('dashboard.expand-table') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <el-table
        v-if="!$_verifyLoaded('loading')"
        :data="tableData.rows"
        :scrollbar-always-on="true"
        :cell-class-name="tableRowClassName"
        :header-cell-class-name="tableHeaderClassName"
        :default-sort="sort"
        @sort-change="sortTable"
        style="width: 100%"
        stripe
        empty-text=" "
        class="table-sales-report__table-page"
      >
        <template v-for="(column, index) in getColumns" :key="index">
          <el-table-column
            sortable
            :fixed="index === 0 ?? true"
            :formatter="formatter"
            :prop="column.key"
            :label="column.label"
            :min-width="getWidth(column.key)"
          >
            <template v-if="getColumnsToSearch.no_label.includes(column.key)" #header>
              <div :class="`el-table__column table-column-${column.key}`">
                <label> {{ getLabel(column.key) }} </label>
                <el-popover trigger="hover" placement="bottom" :width="260">
                  <el-input
                    v-model="search[column.key]"
                    v-mask="getMask(column.key)"
                    :placeholder="getPlaceholder(column.key)"
                    size="small"
                    :ref="column.key"
                    @keyup.enter="searchByColumn(column.key, search[column.key], index)"
                  />
                  <div style="text-align: right">
                    <el-button
                      round
                      size="small"
                      text
                      @click="clearFilter(column.key, index)"
                      :disabled="!search[column.key]"
                    >
                      {{ search[column.key] ? 'limpar filtro' : 'cancelar' }}
                    </el-button>
                    <el-button
                      :disabled="!search[column.key]"
                      round
                      size="small"
                      type="primary"
                      @click="searchByColumn(column.key, search[column.key], index)"
                      >pesquisar</el-button
                    >
                  </div>
                  <template #reference>
                    <i
                      :class="{ 'color-oto-brand-omni': columnsFiltered.map(col => col.column).includes(column.key) }"
                      class="icon icon-search-12px ml--4"
                    ></i>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <dashboard-edit-columns-modal :page="'email'" />
      <base-loader v-if="$_verifyLoaded('loading')" />
      <info-card
        v-if="($_verifyLoaded('info') && !isCustomTable) || (!tableData.filtered.length && !$_verifyLoaded('loading'))"
        ref="message-error"
        :error="hasError && !isCustomTable"
      />
      <list-pagination
        v-if="!$_verifyLoaded('loading')"
        :data="pagination"
        :limit="pagination.limit"
        :limit-range="limitRange"
        :identify="id"
        :show-list-pages="false"
        :show-quantity-lines="true"
      />
    </base-card-v2>
  </div>
</template>

<script>
// libs
import { mapGetters, mapState, mapActions } from 'vuex';
import vSelect from 'vue-select';
import moment from 'moment';
import FileDownload from 'js-file-download';
import vClickOutside from 'click-outside-vue3';
import HeaderDatepicker from '@/components/menu/_organisms/HeaderDatepicker';

// services
import { getTable, getTablePresets } from '@/services/oto/sales-report';

// helpers
import bus from '@/helpers/events/bus';
import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import ListPagination from '@/components/_atoms/ListPagination';

import { useMixpanelSelectAggregation } from '@/utilities/mixpanel';

export default {
  name: 'SalesReportTable',
  components: {
    BaseLoader,
    InfoCard,
    vSelect,
    ListPagination,
    HeaderDatepicker,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  data() {
    return {
      id: 'sales-report-table',
      headerFixedWidth: 0,
      headerFixed: false,
      tableHeader: [],
      loading: false,
      columnsFiltered: [],
      tableData: {
        rows: [],
        filtered: [],
        totalResults: [],
      },
      orderBy: '',
      preset: {
        id: 'sales',
        name: this.$t('sales'),
      },
      presets: [],
      isActive: null,
      isExportVisible: false,
      loadingExport: false,
      conversionWindow: 30,
      limit: 20,
      dialogVisible: false,
      order: null,
      sort: {
        prop: '',
        order: 'descending',
      },
      sortQuantity: 0,
      visible: {
        page: [],
        modal: [],
      },
      search: {},
      noMask: {
        mask: '*'.repeat(255),
        tokens: {
          '*': { pattern: /./ },
        },
      },
      orderType: 'asc',
      orderDefault: [],
    };
  },
  computed: {
    ...mapGetters('salesReport', ['getStoreId', 'getSellerId', 'getCampaignId', 'getConversionWindow']),
    ...mapState('salesReport', ['pagination', 'limitRange']),
    getColumns() {
      return this.tableHeader.map(column => ({
        key: column.key,
        label: column.name,
        type: column.type,
        isMetric: column.isMetric,
      }));
    },

    getColumnsToSearch() {
      return {
        no_label: this.getColumns.filter(column => !column.isMetric).map(c => c.key),
        with_label: this.getColumns.filter(column => !column.isMetric).map(c => ({ key: c.key, label: c.label })),
      };
    },

    getQuery() {
      return this.columnsFiltered.length
        ? JSON.stringify(this.columnsFiltered.map(result => ({ column: result.column, value: result.value })))
        : [];
    },
  },
  watch: {
    preset: function () {
      useMixpanelSelectAggregation('view_oto_sales-report_select-aggregation', this.preset.name);
    },
  },
  mounted() {
    this.setup();

    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });

    bus.$on('render-cards', () => {
      this.resetPagination();
      this.getTableInfo();
    });
    bus.$on('srh-change-store', () => {
      this.getTableInfo();
    });
    bus.$on('srh-change-seller', () => {
      this.getTableInfo();
    });
    bus.$on('srh-change-campaign', () => {
      this.getTableInfo();
    });
    bus.$on('srh-change-segment-general', () => {
      this.getTableInfo();
    });
    bus.$on('srh-change-conversion-window', () => {
      this.getTableInfo();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.getTableInfo();
      this.headerFixed = false;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off('render-page-loader');
    bus.$off('render-cards');
    bus.$off('srh-change-store');
    bus.$off('srh-change-seller');
    bus.$off('srh-change-campaign');
    bus.$off('srh-change-segment-general');
    bus.$off('srh-change-conversion-window');
    bus.$off(`${this.id}-change-page`);
  },
  methods: {
    ...mapActions('salesReport', ['resetPagination']),

    setup() {
      this.getTableInfo();
      this.getTablePresetsInfo();
    },

    resetOrdering() {
      this.sort = {
        prop: '',
        order: '',
      };
      this.orderBy = '';
    },

    sortTable({ prop }) {
      this.sortQuantity = this.sort.prop === prop ? (this.sortQuantity += 1) : 0;

      if (this.sortQuantity === 2) {
        this.resetOrdering();
        this.getTableInfo();

        return;
      }

      this.sort.prop = prop;

      const isDefaultOrder = !this.orderBy.includes('-');

      if (isDefaultOrder) {
        this.orderBy = `-${prop}`;
        this.sort.order = 'descending';
      }

      if (!this.orderBy || !isDefaultOrder) {
        this.orderBy = prop;
        this.sort.order = 'ascending';
      }

      this.getTableInfo();
    },

    getTableInfo(value) {
      this.loading = true;
      this.$_reqConfig();

      if (value === 'preset' || value === 'search') this.resetPagination();
      if (value === 'preset') this.resetOrdering();

      const params = {
        preset: this.preset.id,
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        page: this.pagination.page,
        limit: this.pagination.limit,
        lookback: this.getConversionWindow,
        orderBy: this.orderBy,
      };

      if (this.getQuery.length && value !== 'preset') params.query = this.getQuery;

      getTable(params)
        .then(response => {
          const data = response?.data;
          if (this.$_verifyData(data.data)) {
            this.tableData.filtered = [];
            this.tableData.rows = [];
            return;
          }

          if (value === 'preset') {
            this.search = {};
            this.columnsFiltered = [];
          }

          this.pagination.count = +data.totalCount;
          this.tableHeader = data?.series;

          const dataFormated = data?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                return [data.series[i].key, val];
              }),
            );
          });

          this.tableData.rows = dataFormated;
          this.tableData.filtered = dataFormated;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTablePresetsInfo() {
      getTablePresets().then(({ presets }) => {
        const dataPresets = Object.entries(presets);

        dataPresets.map(el => {
          this.presets.push({
            id: el[0],
            name: el[1],
          });
        });
      });
    },

    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },

    /**
     * @param {fileType} fileType generated file type
     */
    resolveExport(fileType) {
      this.fetchExportData(fileType, this.preset.id);
    },

    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    fetchExportData(fileType, preset) {
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getTable({
        preset,
        fileType,
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        lookback: this.getConversionWindow,
        responseType: 'blob',
      })
        .then(response => {
          const data = response?.data;
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset}-${date}.${fileType}`);
        })
        .finally(() => {
          this.loadingExport = false;
          bus.$emit('render-page-loader', false);
        });
    },

    getLabel(key) {
      return this.getColumnsToSearch.with_label.filter(column => column.key === key)[0]['label'];
    },
    getMask(column) {
      const mask = {
        date: '####/##/##',
      };

      const hasMask = Object.keys(mask).includes(column);
      return hasMask ? mask[column] : this.noMask;
    },

    getPlaceholder(column) {
      const placeholder = {
        date: '01/01/2000',
        contact_date: '2000-01-01 01:01:01',
        launch_time: '01:01:01',
      };

      const hasPlaceholder = Object.keys(placeholder).includes(column);
      return hasPlaceholder ? placeholder[column] : 'Busque nesta coluna';
    },

    getWidth(column) {
      const width = {
        campaign: 160,
        contact_date: 130,
        seller: 150,
        date: 130,
        seller_enrolment: 130,
        customer: 140,
        orders: 110,
        aov: 120,
        conversion_window: 120,
        store: 130,
      };

      const hasWidth = Object.keys(width).includes(column);
      return hasWidth ? width[column] : 130;
    },

    /**
     * @description formats the filter date to that of the pattern that comes from the api
     * @param {date} 12/12/2012
     * @returns 2012-12-12
     */
    formatDate(date) {
      const spplitedDate = date.split('/');
      return position => {
        if (position !== 0) {
          return spplitedDate.length > position ? `${spplitedDate[position]}-` : '';
        }
        return spplitedDate[position];
      };
    },

    /**
     * @description gets the result of the formatted date
     * @param {date} 12/12/2012
     * @returns 2012-12-12
     */
    getFormattedDate(date) {
      const getPosition = this.formatDate(date);
      return `${getPosition(2)}${getPosition(1)}${getPosition(0)}`;
    },
    equalizeValue(value) {
      return String(value).toLowerCase();
    },

    /**
     * @description format presets
     */
    triggerPresetFormat(presets) {
      return this.$_formatToArrayOfObjects(presets);
    },

    /**
     * @description filters table data for the searched value in a specific column
     * @param {column} Ex: date
     * @param {value} Ex: 12/12/2012
     */
    searchByColumn(column, value) {
      this.columnsFiltered.push({ column, value });

      this.getTableInfo('search');
    },

    /**
     * @description clears the filter for a specific column
     */
    clearFilter(column, index) {
      this.columnsFiltered = this.columnsFiltered.filter(col => col.column !== column);
      this.search[column] = '';
      this.visible[index] = false;

      this.getTableInfo();
    },
    tableRowClassName({ row, column, columnIndex }) {
      const threshold = this.getColumns[columnIndex]['threshold'];
      const isMetric = this.getColumns[columnIndex]['isMetric'] ? 'metric' : 'dimension';

      return `threshold  ${isMetric} ${this.$_checkThreshold({
        amount: row[column.property],
        threshold,
      })}`;
    },
    tableHeaderClassName({ columnIndex }) {
      const isMetric = this.getColumns[columnIndex]['isMetric'] ? 'metric' : 'dimension';
      return isMetric;
    },
    formatter(row, column) {
      const typeColumn = this.getColumns.filter(col => col.key === column.property)[0]['type'];
      const typeValue = column.property === 'date' || column.property === 'launch_date' ? 'date' : typeColumn;
      return this.$_getValue({ type: typeValue, amount: row[column.property] });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-presets-select {
  height: 36px;
  width: 225px;
  margin-left: 16px;
}
</style>

<style lang="scss">
.table-sales-report {
  &__card {
    min-height: 500px;
  }

  &__table-page {
    .el-table__empty-block {
      height: 385px !important;
    }
  }
}

#table-sales-report {
  min-height: 430px;

  .el-table .cell {
    word-break: inherit !important;
    padding: 0 4px !important;
  }

  .vs__dropdown-toggle {
    width: 200px;
  }

  .el-overlay-dialog .el-dialog__body {
    padding: 0 10px;
  }

  .el-overlay-dialog .el-dialog {
    border-radius: 0;
  }

  .el-dialog__body {
    .el-table--border .el-table__inner-wrapper::after,
    .el-table--border::after,
    .el-table--border::before,
    .el-table__inner-wrapper::before {
      background-color: transparent !important;
    }
  }

  .v-select {
    &.vs--open {
      z-index: 3;
    }
  }
}
</style>
