<template>
  <base-card-v2 class="product-lifecycle">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('product.life-cycle.title')" />
      <div id="life-cycle-chart"></div>

      <div>
        <div class="row card-footer no-gutters">
          <div class="col-7 price">
            <div class="title">{{ $t('product.life-cycle.prices') }}</div>
            <div class="avg-price">
              <label class="chart-title">{{ $t('product.avg-price') }}</label>
              <span class="chart-value-big d-block color-blue-400">{{ formatCurrency(product.asp) }}</span>
            </div>
            <div class="min-price">
              <label class="chart-title">{{ $t('product.life-cycle.min-price') }}</label>
              <div class="value">
                <span class="chart-value-big color-blue-400">{{ formatCurrency(product.min_price) }}</span>
                <span class="date">{{ $_formatDate(product.min_price_date, dateMonthYear) }}</span>
              </div>
            </div>
            <div class="max-price">
              <label class="chart-title">{{ $t('product.life-cycle.max-price') }}</label>
              <div class="value">
                <span class="chart-value-big color-blue-400">{{ formatCurrency(product.max_price) }}</span>
                <span class="date">{{ $_formatDate(product.max_price_date, dateMonthYear) }}</span>
              </div>
            </div>
          </div>
          <div class="col-5 order">
            <div class="title">{{ $t('orders') }}</div>
            <div class="avg-price">
              <label class="chart-title">{{ $t('product.life-cycle.avg-per-month') }}</label>
              <span class="chart-value-big d-block color-blue-700 text-lowercase"
                >{{ $_formatDecimal(ordersMonth) }} {{ $tc('product.life-cycle.order', ordersMonth) }}</span
              >
            </div>
            <div class="min-price">
              <label class="chart-title">{{ $t('product.life-cycle.pacing') }}</label>
              <span class="chart-value-big d-block color-blue-700"
                >{{ $_formatDecimal(ordersDay) }} {{ $tc('product.life-cycle.order-when', ordersDay) }} 24h</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import Highcharts from 'highcharts';
import { getLifeCycle } from '@/services/product';
import bus from '@/helpers/events/bus';
import Annotation from '@/../node_modules/highcharts/modules/annotations';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin, dateMixin } from '@/helpers/mixins';
import { dateCompleteVariation, dateMonthYear } from '@/utilities/constants';
import { formatCurrency, formatDecimal } from '@/utilities/formatters';

Annotation(Highcharts);

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin, dateMixin],
  props: {
    productID: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      product: {},
      ordersMonth: 0,
      ordersDay: 0,
      chart: {},
      chartConfig: {},
      dateMonthYear,
    };
  },
  computed: {
    ...mapGetters(['selectedFilter']),
  },
  mounted() {
    this.fetchData();
    // this card need to be rendered just once in the lifecycle or updated after segment change
    bus.$on('render-cards', () => {
      this.renderCards();
    });
    bus.$on('select-persona', () => {
      this.renderCards();
    });
  },
  beforeUnmount() {
    bus.$off(['render-cards', 'select-persona'], this.fetchData);
  },
  methods: {
    formatSerie(serie) {
      return serie.length ? serie.map((value, key) => [value, key.toString()]) : [];
    },
    fetchData() {
      this.$_reqConfig();

      this.fetchChart();

      getLifeCycle(this.productID, this.selectedFilter)
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.product = data.data;
          this.ordersMonth = this.product.pace['30d'];
          this.ordersDay = this.product.pace['24h'];

          const { series } = this.product;

          if (series) {
            const prodQuantity = this.formatSerie(series.product_quantity.data);
            const catQuantity = this.formatSerie(series.category_quantity.data);
            const asp = this.formatSerie(series.asp.data);
            const catAsp = this.formatSerie(series.asp_category.data);

            const { categories: labels } = this.product;

            this.fetchChart(
              false,
              prodQuantity,
              catQuantity,
              asp,
              catAsp,
              labels,
              this.product.first_order,
              this.product.last_order,
            );
          }
        })
        .catch(() => {
          this.product = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchChart(
      isLoading = true,
      quantity = [],
      qtCategory = [],
      asp = [],
      aspCategory = [],
      labels = [],
      firstOrder = '',
      lastOrder = '',
    ) {
      const self = this;

      this.chart = {
        isLoading,
        lang: {
          loading: self.$i18n.t('loading-dots'),
        },
        chart: {
          renderTo: 'life-cycle-chart',
          height: 320,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: null,
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        annotations: [
          {
            labelOptions: {
              backgroundColor: 'white',
              verticalAlign: 'bottom',
              y: 15,
              borderColor: 'transparent',
              borderRadius: 2,
              padding: 10,
              style: {
                color: '#555',
                fontSize: '12px',
                lineHeight: '20px',
              },
              shadow: true,
            },
            labels: [
              {
                point: {
                  x: 70,
                  y: -15,
                },
                shape: undefined,
                formatter: function formatter() {
                  return `${self.$i18n.t('first-sale')}: <br><b>${
                    firstOrder ? moment(firstOrder).format(dateCompleteVariation) : self.$i18n.t('loading-dots')
                  } </b>`;
                },
              },
              {
                point: {
                  x: 890,
                  y: -15,
                },
                shape: undefined,
                formatter: function formatter() {
                  return `${self.$i18n.t('last-sale')}: <br><b>${
                    lastOrder ? moment(lastOrder).format(dateCompleteVariation) : self.$i18n.t('loading-dots')
                  } </b>`;
                },
              },
            ],
          },
        ],
        xAxis: {
          categories: labels,
          lineWidth: 0,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              formatter: function formatter() {
                return formatDecimal(this.value);
              },
            },
          },
          {
            title: {
              text: null,
            },
            labels: {
              formatter: function formatter() {
                return formatCurrency(this.value);
              },
            },
            lineWidth: 0,
            opposite: true,
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            let s = '';
            this.points.forEach(points => {
              let formattedValue = '';
              switch (points.series.index) {
                case 0:
                  formattedValue = formatDecimal(points.y);
                  break;
                case 1:
                  formattedValue = formatDecimal(points.y, 2);
                  break;
                default:
                  formattedValue = formatCurrency(points.y);
                  break;
              }
              s += `<span style="color:${points.color}">\u25CF</span> ${points.series.name}: <b>${formattedValue}</b></br>`;
            });
            return s;
          },
          style: {
            color: '#555',
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          series: {
            pointPadding: 0,
            borderWidth: 0,
            shadow: false,
          },
        },
        series: [
          {
            name: this.$i18n.t('product.life-cycle.orders-product'),
            color: '#3354ad',
            keys: ['y', 'id'],
            data: quantity,
            type: 'column',
          },
          {
            name: this.$i18n.t('product.life-cycle.orders-avg-category'),
            color: '#9BB7FF',
            keys: ['y', 'id'],
            data: qtCategory,
            type: 'column',
          },
          {
            name: this.$i18n.t('product.life-cycle.avg-price-product'),
            color: '#fce74e',
            keys: ['y', 'id'],
            data: asp,
            type: 'spline',
            yAxis: 1,
          },
          {
            name: this.$i18n.t('product.life-cycle.avg-price-category'),
            color: '#ff3162',
            keys: ['y', 'id'],
            data: aspCategory,
            type: 'spline',
            yAxis: 1,
          },
        ],
      };
      this.chartConfig = Highcharts.chart(this.chart);
    },
    renderCards() {
      // validation to just render if is on the correct page
      const getBreadcrumb = this.$route.meta.breadcrumb?.map(list => list.name);
      if (getBreadcrumb.includes('product-detail')) {
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid $gray-300;

.product-lifecycle {
  min-height: 450px;

  .card-footer {
    border-top: $border;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: $gray-700;
    }

    .price,
    .order {
      display: flex;
      align-items: center;
      padding: 20px;
      > div:not(:last-child) {
        padding-right: 35px;
      }
    }

    .price {
      border-right: $border;
      .value {
        display: flex;
        align-items: center;

        .date {
          color: $gray-700;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.product-lifecycle.card {
  padding: 20px 0 0 0;
  .card-title,
  #life-cycle-chart {
    padding-left: 20px;
    padding-right: 20px;
  }
  .highcharts-annotation-label-box {
    width: 120px;
  }
}
</style>
