<template>
  <el-dialog v-model="isVisible" :id="id" width="438">
    <div v-show="!priorityListLoader">
      <h2 class="spm__title">{{ $t('360.priorities.title') }}</h2>
      <p class="spm__order-title">{{ $t('360.priorities.order.title') }}</p>
      <div class="spm__order">
        <div>
          <input id="priority-order" v-model="order" type="radio" name="order-type" value="priority" />
          <label for="priority-order" class="value-label">{{ $t('360.priorities.order.priority') }}</label>
        </div>
        <div>
          <input id="alphabetical-order" v-model="order" type="radio" name="order-type" value="name" />
          <label for="alphabetical-order" class="value-label">{{ $t('360.priorities.order.alphabetical') }}</label>
        </div>
      </div>
      <p class="spm__description">
        {{ $t('360.priorities.subtitle') }}
      </p>
      <div class="spm__priorities">
        <!-- priotities list -->
        <div class="spm__priorities-container">
          <draggable
            :list="priorityList"
            :group="{ name: 'priorities' }"
            ghost-class="ghost"
            draggable=".is-draggable"
            handle=".handle"
            class="spm__priorities-draggable customScrollBar"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <transition-group type="transition" name="flip-list">
              <div
                v-for="(item, i) in priorityList"
                :key="`${item.campaign}-${i}`"
                :class="['spm__priorities-item is-draggable', { 'has-group': item.group }]"
              >
                <span class="spm__priorities-value">{{ i + 1 }}.</span>
                <div class="spm__campaign-container">
                  <div class="spm__campaign-dragndrop">
                    <i class="icon icon-hamburger handle"></i>
                  </div>
                  <div class="spm__campaign">
                    <!-- single -->
                    <span v-if="!item.group" class="spm__campaign-name">
                      <p>{{ item.name }}</p>
                    </span>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>

      <!-- footer -->
      <div class="spm__submit-footer">
        <hr class="solid" />
        <div class="spm__submit-container">
          <button :disabled="loading" class="btn spm__submit-btn" @click.prevent="submit">
            <span v-if="loading">{{ $tc('settings.users.loading') }}...</span>
            <span v-else>{{ $t('360.campaigns.details.save') }}</span>
          </button>
        </div>
      </div>
    </div>
    <base-loader v-if="priorityListLoader" />
  </el-dialog>
</template>

<script>
// libs
import { useToast } from 'vue-toastification';
// import draggable from "vuedraggable";
import { VueDraggableNext } from 'vue-draggable-next';
// helpers
import bus from '@/helpers/events/bus';
// services
import { getListPriority, putPriorityList } from '@/services/actions';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'SuggestionPrioritiesModal',
  components: {
    draggable: VueDraggableNext,
    BaseLoader,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      id: 'suggestion-priorities-modal',
      priorityList: [],
      defaultList: [],
      error: false,
      isDragging: false,
      loading: false,
      priorityListLoader: false,
      order: '',
      isVisible: false,
    };
  },
  watch: {
    isVisible() {
      if (!this.isVisible) this.resetData();
    },
  },
  mounted() {
    this.fetchData();
    bus.$on('suggestion-priorities-modal', () => this.openModal());
    bus.$on('update-selected-brand', () => this.fetchData());
  },
  beforeUnmount() {
    bus.$off(['suggestion-priorities-modal', 'update-selected-brand']);
  },
  methods: {
    /**
     * @description fetch data
     */
    fetchData() {
      this.getPriorities();
    },
    /**
     * @description get priorities list
     */
    getPriorities() {
      this.priorityListLoader = true;

      // clear previous result
      this.priorityList = [];

      getListPriority()
        .then(response => {
          const data = response?.data;
          this.order = data.campaingsOrder;
          const temp = data?.data;

          this.priorityList = temp
            .flatMap(el => el)
            .map(el => {
              return { ...el };
            });

          // save original list format
          this.defaultList = [...this.priorityList];
        })
        .finally(() => {
          this.priorityListLoader = false;
        });
    },
    putPriorities() {
      this.loading = true;

      // create final format to submit values in a correct order
      const actions = this.priorityList.map(el => (el?.group?.length ? el?.group.map(el => el.id) : el?.id)).join(';');

      putPriorityList(actions, this.order)
        .then(() => {
          this.toast.success(this.$t('360.priorities.toast.updated'));
          this.closeModal();
        })
        .catch(() => {
          // trigger generic error
          bus.$emit('open-suggestion-campaign-modal-error');
          this.closeModal();
        })
        .finally(() => {
          this.loading = false;
          this.fetchData();
        });
    },
    /**
     * @description reset values after modal have been closed
     */
    resetData() {
      this.error = false;
      this.priorityList = [...this.defaultList];
    },
    /**
     * @description submit priorities modal
     */
    submit() {
      this.putPriorities();
    },
    /**
     * @description modal events
     */
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#suggestion-priorities-modal {
  .spm__description {
    padding-top: 12px;
  }

  .spm__order-title {
    font-weight: 600;
    margin-top: 16px;
  }

  .spm__order {
    display: flex;

    & div:first-child {
      margin-right: 16px;
    }
  }

  .spm__priorities {
    padding: 10px 0 0 0;
    height: 340px;
    min-height: 340px;
    .spm__include-group {
      padding: 10px 0;
      display: flex;
      align-items: center;
      .btn-plus-gray {
        padding: 0 15px;
      }
      .spm__include-text {
        p {
          font-weight: bold;
          margin: 0 0 0 10px;
          color: $gray-700;
        }
      }
      i.icon {
        font-size: 30px;
      }
    }
  }

  .spm__priorities-container {
    .spm__priorities-draggable {
      height: 300px;
      overflow-y: auto;
      max-height: 300px;
      overflow-x: hidden;
    }
    .spm__priorities-item {
      display: flex;
      align-items: center;

      .spm__priorities-value {
        color: $blue-400;
        font-size: 14px;
        font-weight: 600;
        min-width: 20px;
      }
      .spm__campaign-container {
        display: flex;
        align-items: center;
        min-height: 40px;
        width: 100%;
        min-width: 326px;
        .spm__campaign-dragndrop {
          display: flex;
          align-items: center;
          .icon {
            font-size: 28px;
            padding-left: 8px;
            color: $gray-600;
          }
        }
        .spm__campaign {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          .spm__campaign-group-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 100%;
            .spm__campaign-group {
              width: 100%;
              max-width: 245px;
              border: 1px dashed $gray-500;
              border-radius: 8px;
              padding: 14px 20px;
              margin: 0 0 0 6px;
              .spm__group-item {
                height: 28px;
                display: flex;
                align-items: center;
                p {
                  margin: 0;
                  line-height: 14px;
                }
              }
            }
            &.is-editable {
              .spm__group-item {
                p {
                  color: $gray-600;
                }
              }
            }

            .icon {
              font-size: 14px;
              padding-left: 10px;
              color: $gray-700;
            }
          }
          .spm__campaign-name {
            p {
              margin: 0 0 0 6px;
            }
          }
        }
      }

      &.has-group {
        margin: 10px 0;
      }
    }
  }

  .spm__draggable {
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    padding: 20px 0 100px 0;

    .spm__campaign-group-wrapper {
      display: flex;
      align-items: center;
      height: 90px;
      width: 100%;
      justify-content: center;
      .spm__campaign-group {
        width: 298px;
        border: 1px dashed $gray-500;
        border-radius: 8px;
        padding: 14px 20px;
        height: 90px;
        display: flex;
        flex-direction: column;
        max-height: 90px;
        overflow-x: auto;
        .spm__group-item {
          height: 28px;
          min-height: 28px;
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }

          .spm__remove-option {
            width: 12px;
            display: block;
            cursor: pointer;
            margin-left: 5px;
          }
        }
        .spm__group-empty {
          display: flex;
          align-items: center;
          height: 100%;

          p {
            margin: 0;
          }
        }
      }

      .spm__actions {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        align-items: center;

        .icon-checked-30px {
          font-size: 16px;
          transition: color ease-in 0.3s;

          &.disabled {
            color: $gray-400;
          }
        }
      }
    }
    .icon {
      font-size: 14px;
      padding-left: 10px;
      color: $gray-700;
    }
  }

  .spm__submit-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .spm__submit-container {
      height: 80px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 30px 0 0;
      .spm__submit-btn {
        width: 208px;
        height: 40px;
      }
    }
  }

  .spm__error {
    position: relative;
    p {
      bottom: -45px;
      position: absolute;
      margin: 0;
      color: $color-red;
    }
  }

  hr {
    margin-bottom: 0px;
    width: 100%;
  }

  .spm__draggable .ghost {
    display: none;
  }

  .icon {
    cursor: pointer;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
}
</style>

<style lang="scss">
#suggestion-priorities-modal {
  min-height: 597px;
  margin: 10rem auto;
  position: absolute;
  top: 0;

  .el-dialog__body {
    padding: 0 46px 0;
    overflow: hidden;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
