import { TopFiveParamsType, TopFiveRankingResponse, TopTableParamsType, TopTableRankingResponse } from '../types';
import {
  getTopSellers,
  getTopCustomers,
  getTopCustomersDetails,
  getTopSellersDetails,
} from '@/services/stores/SalesRanking';

// fetch and format topSellersRanking
export const fetchTopSellersRanking = ({ segments, filters }: TopFiveParamsType): Promise<TopFiveRankingResponse> =>
  getTopSellers({ segments, filters }).then(response => formatTopSellersResponse(response.data));

const formatTopSellersResponse = (response: TopFiveRankingResponse): TopFiveRankingResponse => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});

// fetch and format topCustomersRanking
export const fetchTopCustomersRanking = ({ segments, filters }: TopFiveParamsType): Promise<TopFiveRankingResponse> =>
  getTopCustomers({ segments, filters }).then(response => formatTopCustomersResponse(response.data));

const formatTopCustomersResponse = (response: TopFiveRankingResponse): TopFiveRankingResponse => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});

// fetch and format topCustomersDetailRankingTable
export const fetchTopCustomersDetailRanking = ({
  segments,
  storeId,
  orderBy,
  limit,
  page,
  term,
  fileType,
  responseType,
}: TopTableParamsType): Promise<TopTableRankingResponse> =>
  getTopCustomersDetails({ segments, storeId, orderBy, limit, page, term, fileType, responseType }).then(response =>
    formatTopCustomersDetailResponse(response.data),
  );

const formatTopCustomersDetailResponse = (response: TopTableRankingResponse): TopTableRankingResponse => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  export: response,
  data: response?.data,
  errors: response?.errors,
  series: response?.series,
  success: response?.success,
  totalCount: response?.totalCount,
});

// fetch and format topSellersDetailRankingTable
export const fetchTopSellersDetailRanking = ({
  segments,
  storeId,
  orderBy,
  limit,
  page,
  term,
  fileType,
  responseType,
}: TopTableParamsType): Promise<TopTableRankingResponse> =>
  getTopSellersDetails({ segments, storeId, orderBy, limit, page, term, fileType, responseType }).then(response =>
    formatTopSellersDetailResponse(response.data),
  );

const formatTopSellersDetailResponse = (response: TopTableRankingResponse): TopTableRankingResponse => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  export: response,
  data: response?.data,
  errors: response?.errors,
  series: response?.series,
  success: response?.success,
  totalCount: response?.totalCount,
});
