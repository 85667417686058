<template>
  <div>
    <h2 class="card-title">{{ channel }}</h2>
    <div class="nps-channel-ratings row">
      <div v-if="responseData" class="nps-channel-response col-3 row mr-5">
        <div v-for="(item, key) in responseData" :key="key" class="col-6">
          <metric-item :metric-value="item" />
        </div>
      </div>
      <div v-if="channelGroups" class="nps-channel-groups col-6 row pl-5">
        <div v-for="(item, key) in channelGroups" :key="key" class="col-4">
          <metric-item :metric-value="item" />
        </div>
      </div>
    </div>
    <div v-if="hasItems" class="nps-channel-scores row pt-3 mt-3">
      <div v-for="(item, key) in channelScores" :key="key" class="col-2">
        <metric-item :metric-value="item" />
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import MetricItem from '@/components/nps/_atoms/MetricItem';
import { isEmpty } from 'lodash';

export default {
  name: 'ChannelRatings',
  components: {
    MetricItem,
  },
  props: {
    channel: {
      type: String,
      default: '',
    },
    dataset: {
      type: Object,
      default: () => {},
    },
    metadata: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      responseData: {},
      channelGroups: {},
      channelScores: {},
      hasItems: false,
    };
  },
  mounted() {
    this.fetchData();
    bus.$on('update-channel-ratings', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('update-channel-ratings');
  },
  methods: {
    mapData() {
      // Merge dataset metrics with metadata
      return Object.fromEntries(
        Object.entries(this.dataset).map(([key, amount]) => {
          if (Object.prototype.hasOwnProperty.call(this.metadata, key)) {
            const { name, type, threshold } = this.metadata[key];
            return [key, { name, key, type, threshold, value: amount }];
          }
        }),
      );
    },
    fetchData() {
      const mappedData = this.mapData();

      // Destructure metrics
      const { nps, ratings, detractors_rate, passive_rate, promoters_rate } = mappedData;

      this.responseData = { nps, ratings };
      this.channelGroups = { detractors_rate, passive_rate, promoters_rate };

      // Map item scores and hide null ratings
      const channelScores = Object.fromEntries(
        Object.entries(mappedData).filter(([key, obj]) => key.startsWith('item_') && obj.value),
      );

      this.hasItems = !isEmpty(channelScores);
      this.channelScores = channelScores;
    },
  },
};
</script>

<style lang="scss" scoped>
.nps-channel-scores {
  border-top: 1px solid #dddddd;
}
.nps-channel-groups {
  border-left: 1px solid #dddddd;
}
</style>
