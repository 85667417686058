<template>
  <div :class="id">
    <div v-if="!loading">
      <div v-if="items.length > 0">
        <h1 class="card-group-title">{{ $tc('product.title', 0) }}</h1>
        <grid-list :id="`${id}-list`" :data="grid" :type="type">
          <grid-list-item
            v-for="(product, index) in items"
            :key="index"
            item-class="column"
            :click-callback="
              () => {
                $_openProduct(product.id);
              }
            "
          >
            <product-line :product="product" />
          </grid-list-item>
        </grid-list>
      </div>
      <div v-else>
        <empty-state
          :title="$t('product.empty-state-product.title')"
          :text="$t('product.empty-state-product.text')"
          :button-text="$t('product.empty-state-product.button')"
          :exec-function="focusSearch"
        >
          <template v-slot:image>
            <img src="@/assets/img/oto/emoji/large/oto_emoji_large_not_found.png" alt="" />
          </template>
        </empty-state>
      </div>
    </div>
    <div v-else>
      <skeleton-loader class="h1" height="24px" width="118px" />
      <grid-list :id="`${id}-skeleton-list`" :data="grid" :type="type" :loading="loading">
        <grid-list-item v-for="item in 8" :key="item">
          <skeleton />
        </grid-list-item>
      </grid-list>
    </div>
  </div>
</template>

<script>
import SearchFullItem from '@/components/products/search/SearchFullItem';
import Skeleton from '@/components/products/search/SearchFullItemSkeleton';
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';
import GridList from '@/components/_atoms/grid/GridList';
import GridListItem from '@/components/_atoms/grid/GridListItem';
import productsMixin from '@/helpers/mixins/productsMixin';
import getSearch from '@/services/product';
import bus from '@/helpers/events/bus';
import EmptyState from '@/components/_atoms/EmptyState';

export default {
  components: {
    'grid-list': GridList,
    'grid-list-item': GridListItem,
    'product-line': SearchFullItem,
    'skeleton-loader': SkeletonLoader,
    skeleton: Skeleton,
    EmptyState,
  },
  mixins: [productsMixin],
  data() {
    return {
      id: 'products-grid',
      grid: {
        count: 0,
        limit: 16,
        page: 1,
      },
      type: 'normal',
      items: [],
      term: undefined,
      loading: true,
    };
  },
  mounted() {
    this.listeners();
  },
  beforeUnmount() {
    this.listeners(true);
  },
  methods: {
    backToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    search() {
      this.backToTop();

      this.loading = true;
      getSearch({
        term: this.term,
        full: true,
        limit: this.grid.limit,
        page: this.grid.page,
      })
        .then(({ data }) => {
          this.items = data.data;
          this.grid.count = data.total_count;

          // update title and subtitle of search bar
          bus.$emit('update-search-fixed-data', {
            title: this.$t('search.searched-by', { term: this.term }),
            subtitle: `${this.grid.count} ${this.$tc('search.result', this.grid.count)}`,
          });
        })
        .catch(() => {
          this.items = [];
          this.grid.count = 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateTerm(term) {
      this.term = term;
      this.grid.page = 1;
      this.search();
    },
    listeners(off = false) {
      const action = off ? '$off' : '$on';
      bus[action]('set-search-term', this.updateTerm);
      bus[action](`${this.id}-list-change-page`, this.search);
      bus[action]('render-cards', this.search);
    },
    focusSearch() {
      bus.$emit('focus-search');
    },
  },
};
</script>

<style lang="scss" scoped>
.items-not-found {
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
  }
  .btn-search-again {
    width: 180px;
    height: 40px;
  }
}
</style>
