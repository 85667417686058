<template>
  <lightbox-template id="lightbox-recency" :title="$t('segments-title')" icon="customer">
    <recency-chart />
    <recency-group-chart />
    <recency-table />
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import RecencyChart from '@/components/lightbox/lightboxes/_organisms/recency/RecencyChart';
import RecencyGroupChart from '@/components/lightbox/lightboxes/_organisms/recency/RecencyGroupChart';
import RecencyTable from '@/components/lightbox/lightboxes/_organisms/recency/RecencyTable';

export default {
  name: 'LightboxRecency',
  components: {
    LightboxTemplate,
    RecencyChart,
    RecencyGroupChart,
    RecencyTable,
  },
};
</script>

<style lang="scss" scoped>
.loadHeight {
  min-height: 250px;
  position: relative;
}
</style>
