<template>
  <div class="card">
    <div :class="{ cardVisibility: !$_verifyLoaded('done') }" class="card-body">
      <header class="d-flex align-items-center justify-content-between mb-4">
        <h2 class="card-title mb-0">{{ $t('retention-chart') }}</h2>
        <div class="radio-wrapper d-flex">
          <div class="sub-cols mr-4">
            <input id="first" v-model="orderType" type="radio" name="order-type" value="first" @change="fetch_data" />
            <label for="first" class="value-label">{{ $t('first-purchase') }}</label>
          </div>
          <div class="sub-cols">
            <input id="any" v-model="orderType" type="radio" name="order-type" value="any" @change="fetch_data" />
            <label for="any" class="value-label">{{ $t('any-purchase') }}</label>
          </div>
        </div>
      </header>
      <div class="chart">
        <div class="titles">
          <span class="text">{{ $tc('cohort', 1) }}</span>
          <span class="text">{{ $t('clients') }}</span>
        </div>
        <div id="retention-chart"></div>
        <div class="legend">
          <div class="bar" :class="{ 'two-values': max >= 100 }"></div>
          <div class="percent">
            <span>0%</span>
            <!-- exemplo -->
            <span>{{ $_formatNumber(max, '0%') }}</span>
            <span v-if="max < 100">100%</span>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" :error="hasError" />
  </div>
</template>
<script>
import Highcharts from 'highcharts';

import getTable from '@/services/getTable';

import bus from '@/helpers/events/bus';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { formatDecimal, formatPercent } from '@/utilities/formatters';

import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      data: [],
      orderType: 'first',
      max: 0,
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getTable({
        metrics: 'retention_matrix',
        filters: `order_type:${this.orderType}`,
      })
        .then(response => {
          if (this.$_verifyHasData(response)) return;

          this.data = response.data;
          const matrix = [];
          const dates = [];
          const clients = [];
          const chartCategories = [];

          this.data.columns.forEach((item, index) => {
            if (index > 1) chartCategories.push(item);
          });
          this.data.data.reverse().forEach((row, index) => {
            // inicializa atribuindo o primeiro valor do array
            if (index === 0) this.max = row[2];
            row.forEach((column, i) => {
              if (i === 0) dates.push(column);
              else if (i === 1) clients.push(column);
              else {
                matrix.push([i - 2, index, column]);
                // verifica se o valor atual e maior que a variavel max
                if (column && column > this.max) this.max = column;
              }
            });
          });
          this.loadGraphic(matrix, dates, clients, chartCategories, this.max);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadGraphic(matrix, dates, clients, chartCategories, max) {
      const self = this;
      Highcharts.chart('retention-chart', {
        chart: {
          type: 'heatmap',
          height: 450,
          plotBorderWidth: 1,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: chartCategories,
          opposite: true,
          tickLength: 0,
          tickColor: '#FFFFFF',
          lineWidth: 0,
          labels: {
            style: {
              color: '#555555',
              fontWeight: '600',
            },
          },
        },
        yAxis: [
          {
            categories: clients,
            labels: {
              formatter: function formatter() {
                return formatDecimal(this.value);
              },
            },
            title: {
              // usado somente para preencher o espaço
              text: 'Clientes',
              align: 'high',
              rotation: 0,
              textAlign: 'middle',
              style: {
                fontWeight: 600,
                fontFamily: 'Montserrat',
                fontSize: '11px',
                color: '#FFFFFF',
              },
            },
          },
          {
            linkedTo: 0,
            categories: dates,
            title: {
              // usado somente para preencher o espaço
              text: 'Clientes',
              align: 'high',
              rotation: 0,
              textAlign: 'middle',
              style: {
                fontWeight: 600,
                fontFamily: 'Montserrat',
                fontSize: '11px',
                color: '#FFFFFF',
              },
            },
          },
        ],
        colorAxis: {
          stops: [
            [0, '#FFFFFF'],
            [max / 100, '#3354AD'],
            [(max + 1) / 100, '#FFFFFF'],
          ],
          min: 0,
          max,
          tickPositions: [0, max, 100],
          labels: {
            format: '{value}%',
          },
        },
        plotOptions: {
          heatmap: {
            states: {
              hover: {
                color: '#888888',
              },
            },
            dataLabels: {
              formatter: function formatter() {
                return formatPercent(this.point.value / 100, 2);
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function formatter() {
            const pointValue = this.point.value;
            if (pointValue !== null) {
              const pointY = this.series.yAxis.categories[this.point.y];
              const month = this.series.xAxis.categories[this.point.x].split(' ');
              const value = (pointY * pointValue) / 100;
              return `<b>${formatPercent(this.point.value / 100, 2)}</b><span class="gray"> (${formatDecimal(
                value,
              )})</span>
              ${self.$i18n.t('retention-tooltip.buy')} <b>${this.series.chart.yAxis[1].categories[this.point.y]}</b><br>
              ${self.$i18n.t('retention-tooltip.active')} <b>${month[1]} ${self.$i18n.t(
                'retention-tooltip.months',
              )}.</b>`;
            }
            return self.$i18n.t('no-data');
          },
        },
        series: [
          {
            name: '',
            data: matrix,
            nullColor: '#FFFFFF',
            dataLabels: {
              enabled: true,
              color: '#FFFFFF',
              style: {
                textOutline: 0,
              },
            },
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 900,
              },
              chartOptions: {
                chart: {
                  margin: [40, 40, 40, 160],
                },
              },
            },
          ],
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  justify-content: space-evenly;
}
#retention-chart {
  width: 918px;
  float: left;
}
.titles {
  position: absolute;
  z-index: 99;
  font-size: 11px;
  font-weight: 600;
  color: $gray-800;
  left: 89px;
  top: 79px;
  span {
    padding-right: 52px;
  }
}
.legend {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 65px;
  align-items: center;
  margin-left: 24px;
  float: left;
  margin-top: 40px;
}
.bar {
  width: 15px;
  height: 383px;
  border: 1px solid $gray-300;
  background: linear-gradient(180deg, rgba(54, 91, 107, 0.15) 0%, #3354ad 50%, $color-white 50.1%);
  box-sizing: border-box;
  &.two-values {
    background: linear-gradient(180deg, rgba(54, 91, 107, 0.15) 0%, #3354ad 50%);
  }
}
.percent {
  font-size: 12px;
  color: $gray-700;
  height: 100%;
  display: grid;
  align-content: space-between;
}

@media screen and (max-width: 1199px) {
  #retention-chart {
    width: 100%;
  }
  #retention-chart,
  .legend {
    float: none;
  }
  .titles {
    left: 30px;
  }
  .legend {
    display: block;
    width: 400px;
    height: 65px;
    margin: 15px auto 0 auto;
    .bar {
      height: 15px;
      width: 383px;
      background: linear-gradient(90deg, rgba(54, 91, 107, 0.15) 0%, $blue-500 50%, $color-white 50.1%);
    }
    .percent {
      grid-template-columns: 40px 40px 40px;
      margin-top: 15px;
      justify-content: space-between;
    }
  }
}
</style>
