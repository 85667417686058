<template>
  <form
    v-if="configuration"
    ref="form"
    :action="configuration.OauthUrl"
    method="post"
    @submit="storeConfigBeforeSubmit"
  >
    <input type="hidden" name="callback" :value="getCallBack()" />
    <input type="hidden" name="token" :value="getToken()" />
    <button v-if="Boolean(configuration.OauthUrl)" class="btn btn-large" type="submit">
      <slot></slot>
    </button>
    <button v-else class="btn btn-large" type="submit" @click.prevent="createInstance">
      <slot></slot>
    </button>
  </form>
</template>

<script>
import bus from '@/helpers/events/bus';

export default {
  name: 'IntegrationOauthForm',
  props: {
    configuration: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    storeConfigBeforeSubmit() {
      localStorage.integrationAlias = this.item.alias;
      localStorage.integrationId = this.item.id;
    },
    createInstance(e) {
      e.preventDefault();
      bus.$emit('openIntegrationCreateEdit', {
        alias: this.item.alias,
        id: this.item.id,
      });
      bus.$emit('integration-config-modal-close');
    },
    getToken() {
      return this.$store.state.auth.token;
    },
    getCallBack() {
      return `${window.location.href}/${this.item.id}`;
    },
  },
};
</script>
