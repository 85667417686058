<template>
  <footer id="footer">
    <div class="logo">
      <img src="@/assets/img/oto/logo/flat/oto_logo_flat_small.png" alt="Oto logo" title="oto" />
    </div>
    <div class="link">
      <a href="https://suporte.otocrm.com.br/pt/blog/termos-e-condicoes-de-uso" target="_blank">
        {{ $t('terms-of-use') }}
      </a>
    </div>
    <div class="link">
      <a href="https://suporte.otocrm.com.br" target="_blank">
        {{ $t('support') }}
      </a>
    </div>
    <div class="insta">
      <a href="https://instagram.com/otocrm" target="_blank">
        <img src="@/assets/img/insta.png" alt="Siga @otocrm no Instagram" title="@otocrm" height="45" width="271" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterContent',
  computed: {
    getDefautRoute() {
      return this.$store.getters.getDefaultRoute;
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  background-color: $color-white;
  position: absolute;
  width: 100%;
  min-height: 70px;
  bottom: auto;
  display: flex;
  align-items: center;
  margin-top: 40px;

  .logo {
    margin-left: 125px;
    padding: 0 30px;
  }

  .insta {
    text-align: right;
    margin-left: auto;
    padding-right: 30px;
  }

  .link {
    padding: 20px 10px;
  }

  a {
    font-size: 14px;
    color: $gray-700;
  }
}
</style>
