<template>
  <personas-filter />
</template>
<script>
import PersonasFilter from '@/components/personasFilter/PersonasFilter';
export default {
  components: {
    PersonasFilter,
  },
};
</script>
