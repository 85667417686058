<template>
  <section id="chart" class="card" :class="{ loadHeight: loading || isEmpty || hasError }">
    <div v-if="!loading && !isEmpty && !hasError">
      <div class="chart-title-container row">
        <i class="icon dashboard-icon light-gray icon-trending-18px"></i>
        <h2 class="card-title mb-0 ml-3">{{ $t('360.per-period') }}</h2>
      </div>
      <highcharts v-if="chartOptions" :options="chartOptions" />
    </div>
    <base-loader v-show="loading && !isEmpty && !hasError" />
    <info-card v-show="isEmpty || hasError" ref="message-error" :error="hasError" />
  </section>
</template>

<script>
import { Chart } from 'highcharts-vue';
import { chartMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'IndicatorChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [chartMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    chartDateOptions: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    buyersAttribution: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      highcharts: Chart,
      type: 'filled',
    };
  },
};
</script>

<style lang="scss" scoped>
#chart {
  &.loadHeight {
    height: 360px;
  }
}

.chart-title-container {
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;
}

.dashboard-icon {
  font-size: 26px;
  margin-left: 18px;
  &.light-gray {
    color: $gray-500;
  }
}
</style>
