import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'store';

export const getTable = ({ columns, term, page, limit, orderBy }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/store/getTable', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      page,
      limit,
      orderBy,
      term,
      columns,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getMetrics = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getTableColumns`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const putStore = data => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    `${URI}/putStore`,
    {
      brand: brand.id,
      ...data,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

export const getStore = storeId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getStore`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      storeId,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const updateStoreStatus = (storeId, status) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    `${URI}/updateStoreStatus`,
    {
      brand: brand.id,
      storeId,
      status,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};
