<template>
  <div id="dialog-loader">
    <div class="dialog__spinner">
      <base-loader />
    </div>
    <h3 class="dialog__title">{{ $t('wait') }}</h3>
    <p v-if="text" class="dialog__text">{{ text }}</p>
  </div>
</template>

<script>
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'DialogLoader',
  components: {
    BaseLoader,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
#dialog-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dialog__spinner {
    height: 70px;
    position: relative;
    margin-bottom: 46px;
  }

  h3.dialog__title {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
    padding-bottom: 10px;
  }

  p.dialog__text {
    font-size: 12px;
    font-weight: normal;
  }
}
</style>
