import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const controller = '/nps/ratings';

export const getChannelMetrics = metrics => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const storeId = store.state.npsAnalytics.storeSelected;
  const sellerId = store.state.npsAnalytics.sellerSelected;
  const persona = store.state.auth.selectedPersona?.id;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getChannelMetrics`,
    params: {
      brand: brand.id,
      persona,
      storeId,
      sellerId,
      startDate,
      endDate,
      metrics,
    },
  }).then(data => data.data);
};

export const getStores = (term = '') => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getStores`,
    params: {
      brand: brand.id,
      term,
    },
  }).then(data => data.data);
};

export const getSellers = (term = '') => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const storeId = store.state.npsAnalytics.storeSelected;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getSellers`,
    params: {
      brand: brand.id,
      storeId,
      term,
    },
  }).then(data => data.data);
};

export const getItemMetrics = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getMetrics`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
    },
  }).then(data => data.data);
};

export const getMetrics = metrics => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const storeId = store.state.npsAnalytics.storeSelected;
  const sellerId = store.state.npsAnalytics.sellerSelected;
  const persona = store.state.auth.selectedPersona?.id;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getMetrics`,
    params: {
      brand: brand.id,
      persona,
      storeId,
      sellerId,
      startDate,
      endDate,
      metrics,
    },
  }).then(data => data.data);
};

export const getMetricsPeriod = metrics => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const endDate = store.getters.dates.endDate;
  const storeId = store.state.npsAnalytics.storeSelected;
  const sellerId = store.state.npsAnalytics.sellerSelected;
  const persona = store.state.auth.selectedPersona?.id;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getMetricsPeriod`,
    params: {
      brand: brand.id,
      persona,
      storeId,
      sellerId,
      endDate,
      metrics,
    },
  }).then(data => data);
};

export const getTable = ({ metrics, dimensions, groupBy, fileType = '', responseType = '' }) => {
  const brand = store?.getters?.selectedBrand;
  const tokenV3 = store?.state?.auth?.tokenV3;
  const startDate = store?.getters?.dates?.startDate;
  const endDate = store?.getters?.dates?.endDate;
  const storeId = store?.state?.npsAnalytics?.storeSelected;
  const sellerId = store?.state?.npsAnalytics?.sellerSelected;
  const persona = store?.state?.auth?.selectedPersona?.id;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getTable`,
    params: {
      brand: brand.id,
      persona,
      storeId,
      sellerId,
      startDate,
      endDate,
      export: fileType,
      metrics,
      dimensions,
      groupBy,
    },
    responseType,
  });
};

export const getTableMetrics = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getTableMetrics`,
    params: {
      brand: brand.id,
    },
  }).then(data => data);
};
