import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

export default () => {
  const { startDate, endDate } = store.getters.dates;
  const brand = store.getters.selectedBrand;
  const persona = store.getters.selectedPersona;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `store/getStores`,
    params: {
      brand: brand.id,
      persona: persona.id,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

/**
 * @see https://api-context.pmweb.com.br/v3/store/getStores
 */
export const getStoresV3 = () => {
  const brand = store.getters.selectedBrand;
  const persona = store.getters.selectedPersona;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `store/getStores`,
    params: {
      brand: brand.id,
      persona: persona.id,
    },
  }).then(data => data?.data);
};

export const getWarningStore = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `store/getStoresSellersWarning`,
    params: {
      brand: brand.id,
    },
  }).then(data => data?.data);
};

/**
 * @see https://api-context.pmweb.com.br/v3/store/getStores
 */
export const getStoresById = (storeId = '') => {
  const persona = store.state.auth.selectedPersona;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `store/getStores`,
    params: {
      brand: storeId,
      persona: persona.id,
      lifetime_purchases: 0,
    },
  }).then(data => data?.data);
};

export const downloadTable = ({ columns, filters }) => {
  const brand = store.getters.selectedBrand;
  const { token } = store.getters;
  const { baseURL } = axios.defaults;
  const { startDate, endDate } = store.getters.dates;

  return `${baseURL}/stores/get_table/?brand=${brand.id}&m=${columns}&start_date=${startDate}&end_date=${endDate}&filter=${filters}&format=csv&dash=stores&table=stores&token=${token}`;
};
