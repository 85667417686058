<template>
  <div class="rdch__interactive--select-table">
    <div class="rd-select">
      <label>{{ $t('_table') }}</label>
      <div class="wrap rd-select__wrapper">
        <input
          ref="input-destination"
          v-model="selectedOption"
          type="text"
          :placeholder="$t('integrations.select')"
          v-focus="openDestinationSelect"
          @click="openDestinationSelect"
        />
        <i class="arrow-down"></i>
        <div
          v-if="isDestinationColumnsActive"
          v-click-outside="closeDestinationSelect"
          class="no-click rd-select__columns"
        >
          <div class="no-click rd-select__columns__list">
            <ul v-if="selectOptions.length" class="customScrollBar no-click rd-select__columns__groups">
              <li v-for="(connectors, index) in selectOptions" :key="index" class="no-click">
                <strong class="rd-select__columns__title">{{ connectors[0] }}</strong>
                <ul v-if="connectors[1].length" class="rd-select__columns__row">
                  <li
                    v-for="connector in connectors[1]"
                    :key="connector.key"
                    class="rd-select__columns__item"
                    @click="selectConnector(connector, $event)"
                    v-html="connector.name"
                  ></li>
                </ul>
              </li>
            </ul>
            <div v-else class="no-click rd-select__columns__empty-select">
              <span v-html="$t('integrations.empty-destiny')"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import { useStore } from 'vuex';
import { toRefs, onMounted, reactive } from 'vue';
// services
import { fetchAvailableTables } from '@/features/RawDataAccess/services';
// helpers
import useVerifyMixin from '@/utilities/mixins/useVerifyMixin';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'RawDataAccessSelectTable',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const store = useStore();
    const { reqConfig, verifyData } = useVerifyMixin();

    const events = reactive({
      selectedOption: '',
      result: '',
      instanceConnectorSelected: null,
      selectOptions: [],
      isLoading: false,
      isDestinationColumnsActive: false,
    });

    // actions
    const setSelectedTable = option => store.dispatch('rawDataAccess/setSelectedTable', option);

    // mounted
    onMounted(() => {
      fetchData();
      setSelectedTable('ORDERS');
    });

    const openDestinationSelect = () => {
      if (events.isDestinationColumnsActive) {
        closeDestinationSelect();
        return;
      }
      events.isDestinationColumnsActive = true;
    };

    const closeDestinationSelect = () => {
      events.isDestinationColumnsActive = false;
    };

    const selectConnector = (connector = 'ORDERS', event) => {
      events.selectedOption = event.target.textContent;
      closeDestinationSelect();
      selectTable(connector);
    };

    // functions
    function fetchData() {
      reqConfig();
      events.isLoading = true;

      fetchAvailableTables()
        .then(response => {
          if (verifyData(response?.data)) return;
          events.selectOptions = Object.entries(response.data);

          const columns = events.selectOptions.map(value => value[1]);
          events.result = [...columns[0], ...columns[1]];
        })
        .finally(() => {
          events.isLoading = false;
          events.selectedOption = events.result.filter(value => value.key === 'ORDERS')[0]['name'];
        });
    }

    function selectTable(connector) {
      if (events.selectedOption) {
        setSelectedTable(connector.key);
      }
    }

    return { ...toRefs(events), selectTable, openDestinationSelect, closeDestinationSelect, selectConnector };
  },
};
</script>
<style lang="scss">
@import './index.scss';

.rd-select {
  &__wrapper {
    position: relative;
    width: 250px;

    input {
      cursor: pointer;
      z-index: 102;
    }

    .arrow-down {
      position: absolute;
      margin: 4px 10px 0;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $gray-600;
      right: 0;
      top: 11px;
      z-index: 104;
    }
  }

  &__columns {
    background-color: transparent;
    border-radius: 18px;
    border-top: 0;
    border: 1px solid $gray-500;
    overflow: hidden;
    padding-top: 35px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 250px;
    z-index: 100;

    ul {
      list-style: none;
      padding: 0;
      pointer-events: auto;
      font-size: 12px;
      color: $gray-700;
      line-height: 26px;
    }

    &__title {
      font-family: $font-family;
      padding: 0 15px;
      color: $gray-800;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }

    &__list {
      height: 100%;
      overflow: hidden;
      background-color: $color-white;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
    }

    &__groups {
      max-height: 270px;
      min-height: 140px;
      overflow-y: auto;
      margin: 10px 0;

      > li:not(:first-child) {
        margin-top: 6px;
      }
    }

    &__row {
      margin: 0;
    }

    &__item {
      padding: 0 15px;

      &:hover {
        background-color: $gray-300;
        cursor: pointer;
      }
    }
  }

  &__empty-select {
    display: block;
    font-size: 12px;
    padding: 15px;
  }
}
</style>
