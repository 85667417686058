<template>
  <section id="campaigns-kpis" class="card">
    <div>
      <div class="chart-title-container row">
        <i class="icon dashboard-icon light-gray icon-trending-18px"></i>
        <h2 class="card-title mb-0 ml-3">{{ $t('nps.kpis') }}</h2>
      </div>
      <div class="row col-sm-12">
        <div class="col-md-7 campaigns-kpis-content">
          <kpi-group />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import KpiGroup from '@/components/nps/_molecules/KpiGroup';

export default {
  name: 'CampaignKpis',
  components: {
    KpiGroup,
  },
};
</script>

<style lang="scss" scoped>
.campaigns-kpis-content {
  min-height: 65px;
}
.chart-title-container {
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.dashboard-icon {
  font-size: 26px;
  margin-left: 10px;
  &.light-gray {
    color: $gray-500;
  }
}
</style>
