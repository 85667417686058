
// libs
import { reactive, toRefs, defineComponent, PropType } from 'vue';
// components
import SellerRankingTitle from './SellerRankingTitle/SellerRankingTitle.vue';
import SellerRankingLink from './SellerRankingLink/SellerRankingLink.vue';
import SellerRankingChartList from './SellerRankingChartList/SellerRankingChartList.vue';
// types
import { StoresType, SellerRankingDataTypes } from '@/features/SalesRanking/types';

export default defineComponent({
  name: 'SellerRanking',
  components: {
    SellerRankingTitle,
    SellerRankingLink,
    SellerRankingChartList,
  },
  props: {
    stores: {
      type: [] as PropType<StoresType[]>,
      required: true,
    },
  },
  setup() {
    const events: SellerRankingDataTypes = reactive({
      selectedStore: '-100',
      objectChartListTopSellers: [],
    });

    function storeChanged(value: string) {
      events.selectedStore = value;
    }

    return { ...toRefs(events), storeChanged };
  },
});
