<template>
  <div class="integration-serie-skeleton d-flex align-items-center">
    <div class="figure">
      <skeleton-loader height="46px" width="81px" />
    </div>
    <div class="d-flex align-items-end fields">
      <div class="group">
        <skeleton-loader height="11px" width="69px" class="d-block mb-2" />
        <skeleton-loader width="207px" />
      </div>
      <div class="group">
        <skeleton-loader height="11px" width="69px" class="d-block mb-2" />
        <skeleton-loader width="207px" />
      </div>
      <skeleton-loader width="207px" />
    </div>
    <skeleton-loader width="181px" />
  </div>
</template>
<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
<style lang="scss">
.integration-serie-skeleton {
  padding: 25px 40px;
  margin-bottom: 12px;
  border-radius: 15px;
  background-color: rgba($color-white, 0.5);
  -webkit-box-shadow: -2px 3px 10px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  .figure {
    margin-right: 80px;
  }
  .fields {
    margin-right: 60px;
  }
  .group {
    margin-right: 14px;
  }
}
</style>
