<template>
  <lightbox-template
    :id="id"
    icon="category-18px"
    :title="this.$tc('categories.category', 2)"
    :show-segment-group="false"
  >
    <div class="container mb-4">
      <header class="d-flex align-items-center mb-4">
        <h5 class="modal-subtitle">{{ $t('categories.revenue') }}</h5>
      </header>
    </div>

    <lightbox-skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <tab-item v-if="!loading" :tab-list="tabList" :tab-active="tabTypeActive" />
    <div v-if="$_verifyLoaded('done')" class="col-12">
      <lightbox-table
        :id="id"
        :data="data"
        :data-type="tabTypeActive"
        :is-lightbox-active="isLightboxActive"
        :settings-data="{
          title: `${$t('category')}: ${category}`,
          alt: category,
        }"
        :col-scheme="['chart:percentual', 'revenue']"
      />
    </div>
    <div v-show="$_verifyLoaded('info')" class="card">
      <info-card ref="message-error" :error="hasError" />
    </div>
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import bus from '@/helpers/events/bus';
import { getCategoryMetrics } from '@/services/categories';
import { lightboxesMixin, verifyMixin, miniBarChartMixin } from '@/helpers/mixins';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import LightboxSkeleton from '@/components/lightbox/lightboxes/categories/LightboxCategoryRevenueSkeleton';
import TabItem from '@/components/lightbox/_molecules/_atoms/TabItem';
import LightboxTable from '@/components/lightbox/_molecules/_atoms/LightboxTable';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'LightboxCategoryRevenue',
  components: {
    LightboxTemplate,
    LightboxSkeleton,
    TabItem,
    LightboxTable,
    InfoCard,
  },
  mixins: [lightboxesMixin, miniBarChartMixin, verifyMixin],
  data() {
    return {
      id: 'lightbox-category-revenue',
      isLightboxActive: true,
      category: this.$route.params.name,
      data: {
        limit: 10,
        page: 1,
        count: 0,
        values: [],
        valueTotal: 0,
      },
      tabList: [
        {
          name: this.$tc('in-store', 1),
          type: 'instore',
        },
        {
          name: this.$tc('ecommerce', 1),
          type: 'ecommerce',
        },
      ],
      tabTypeActive: 'instore',
      type: 'filled',
    };
  },

  mounted() {
    this.fetchData();

    bus.$on('lightbox-update-tab', item => {
      this.tabTypeActive = item;
      this.data.page = 1; // reset page
      this.$_reqConfig();
      this.updateDataTable();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.$_reqConfig();
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
    bus.$off('lightbox-update-tab');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      this.updateDataTable();
    },
    verifyDataPerTab(data) {
      if (this.$_verifyHasData(data)) return false;
      return true;
    },
    fetchInStoreData() {
      getCategoryMetrics(this.category.toUpperCase(), 'revenue_by_medium', {
        filter: 'store_type:off',
        top: this.data.limit,
        page: this.data.page,
      })
        .then(({ data }) => {
          if (!this.verifyDataPerTab(data.metrics)) return;
          this.data.valueTotal = data.metrics.revenue_by_medium.revenue_total;
          const dataValue = data.metrics.revenue_by_medium.data[0].series.data;
          this.data.values = this.mapDataValues(dataValue);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchEcommerceData() {
      getCategoryMetrics(this.category.toUpperCase(), 'revenue_by_medium', {
        filter: 'store_type:on',
        top: this.data.limit,
        page: this.data.page,
      })
        .then(({ data }) => {
          if (!this.verifyDataPerTab(data.metrics)) return;
          this.data.valueTotal = data.metrics.revenue_by_medium.revenue_total;
          const dataValue = data.metrics.revenue_by_medium.data[0].series.data;
          this.data.values = this.mapDataValues(dataValue);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    mapDataValues(item) {
      return Object.keys(item).map(key => [key, item[key]]);
    },

    updateDataTable() {
      switch (this.tabTypeActive) {
        case 'instore':
          this.fetchInStoreData();
          break;
        case 'ecommerce':
          this.fetchEcommerceData();
          break;
        default:
          break;
      }
    },
  },
};
</script>
