<template>
  <section class="register-custom">
    <div class="register-custom__title">
      <h2>{{ $t('settings.stores.register-store.custom-title') }}</h2>
    </div>
    <template v-if="!loadingStoreDetail">
      <div class="register-custom__form">
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-1') }}</label>
          <input v-model="custom1" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-2') }}</label>
          <input v-model="custom2" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-3') }}</label>
          <input v-model="custom3" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-4') }}</label>
          <input v-model="custom4" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-5') }}</label>
          <input v-model="custom5" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-6') }}</label>
          <input v-model="custom6" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-7') }}</label>
          <input v-model="custom7" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-8') }}</label>
          <input v-model="custom8" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-9') }}</label>
          <input v-model="custom9" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
        <div>
          <label>{{ $t('settings.stores.register-store.custom-field-10') }}</label>
          <input v-model="custom10" type="text" :placeholder="$t('settings.stores.placeholder.custom-fields')" />
        </div>
      </div>
    </template>
    <base-loader v-if="loadingStoreDetail" />
  </section>
</template>

<script>
// libs
import { mapState, mapActions, mapGetters } from 'vuex';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'StoresRegisterCustomFieldsForm',
  components: {
    BaseLoader,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('settingsStores', ['loadingStoreDetail']),
    ...mapGetters('settingsStores', [
      'getCustomField1',
      'getCustomField2',
      'getCustomField3',
      'getCustomField4',
      'getCustomField5',
      'getCustomField6',
      'getCustomField7',
      'getCustomField8',
      'getCustomField9',
      'getCustomField10',
    ]),
    custom1: {
      get() {
        return this.getCustomField1;
      },
      set(custom1) {
        this.updateCustomField1(custom1);
      },
    },
    custom2: {
      get() {
        return this.getCustomField2;
      },
      set(custom2) {
        this.updateCustomField2(custom2);
      },
    },
    custom3: {
      get() {
        return this.getCustomField3;
      },
      set(custom3) {
        this.updateCustomField3(custom3);
      },
    },
    custom4: {
      get() {
        return this.getCustomField4;
      },
      set(custom4) {
        this.updateCustomField4(custom4);
      },
    },
    custom5: {
      get() {
        return this.getCustomField5;
      },
      set(custom5) {
        this.updateCustomField5(custom5);
      },
    },
    custom6: {
      get() {
        return this.getCustomField6;
      },
      set(custom6) {
        this.updateCustomField6(custom6);
      },
    },
    custom7: {
      get() {
        return this.getCustomField7;
      },
      set(custom7) {
        this.updateCustomField7(custom7);
      },
    },
    custom8: {
      get() {
        return this.getCustomField8;
      },
      set(custom8) {
        this.updateCustomField8(custom8);
      },
    },
    custom9: {
      get() {
        return this.getCustomField9;
      },
      set(custom9) {
        this.updateCustomField9(custom9);
      },
    },
    custom10: {
      get() {
        return this.getCustomField10;
      },
      set(custom10) {
        this.updateCustomField10(custom10);
      },
    },
  },
  methods: {
    ...mapActions('settingsStores', [
      'updateCustomField1',
      'updateCustomField2',
      'updateCustomField3',
      'updateCustomField4',
      'updateCustomField5',
      'updateCustomField6',
      'updateCustomField7',
      'updateCustomField8',
      'updateCustomField9',
      'updateCustomField10',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.register-custom {
  display: flex;
  padding: 40px 20px 20px;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  height: 500px;
  h2 {
    font-size: 20px;
    width: 150px;
    line-height: 25px;
  }
  &__title {
    width: 30%;
  }
  &__form {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
      margin-bottom: 32px;
      input {
        width: 256px;
      }
    }

    &-box {
      display: flex;
      width: 256px;
      justify-content: space-between;
      div {
        width: 120px;
        margin-bottom: 0px;
        input {
          width: 100%;
          margin-right: unset;
        }
      }
    }

    label {
      span {
        color: $pink-500;
      }
    }
  }
}
</style>
