import { onBeforeUnmount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Action } from '../models';
import router from '@/router';

/**
 * Composable for managing header fixation during scrolling.
 * @param tableRef Reference to the table element.
 * @returns An object with the function to handle header fixation.
 */
export const useFixedHeader = (tableRef, scrollPosition) => {
  const HEADER_FIXED_SCROLL_POSITION = scrollPosition;

  const handleFixedHeaderOnScroll = () => {
    const tableHeaderElement = tableRef.value?.$el.querySelector('.el-table__header-wrapper');

    if (!tableHeaderElement) return;

    const shouldFixHeader = window.scrollY > HEADER_FIXED_SCROLL_POSITION;
    tableHeaderElement.classList.toggle('fixed-header', shouldFixHeader);
  };

  return {
    handleFixedHeaderOnScroll,
  };
};

/**
 * Composable for redirecting to the participants page.
 * @returns An object with the function to redirect to the participants page.
 */
export const useRedirect = () => {
  const store = useStore();

  const redirectToParticipantsPage = (action: Action) => {
    router.push({
      name: 'oto-actions-edit',
      params: {
        id: action.id,
      },
    });

    store.dispatch('campaignsManagement/setActivePage', 'participants');
  };

  const redirectToEditActionPage = (action: Action) => {
    router.push({
      name: 'oto-actions-edit',
      params: {
        id: action.id,
      },
    });
  };

  return {
    redirectToParticipantsPage,
    redirectToEditActionPage,
  };
};

export function useScrollEvent(callback: () => void) {
  onMounted(() => {
    window.addEventListener('scroll', callback);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', callback);
  });
}
