<template>
  <div :class="{ loadHeight: loading }" class="allsegmentgroups-frequency card">
    <div v-show="$_verifyLoaded('done')" class="card-body">
      <h5 class="card-title">{{ $t('dispersion-title') }}</h5>
      <div id="containerFrequency"></div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// @ is an alias to /src
import Highcharts from 'highcharts';
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import { formatCurrency, formatDecimal } from '@/utilities/formatters';

export default {
  name: 'AllSegmentGroupsFrequency',
  components: {
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin],
  data() {
    return {
      dataReturn: {},
      teste: 3,
      arrayOn: [],
      arrayOff: [],
      arrayOmni: [],
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    createChartFrequency() {
      const self = this;
      Highcharts.chart(
        'containerFrequency',
        {
          chart: {
            type: 'scatter',
            zoomType: 'x',
            height: 223,
            marginLeft: 40,
            marginTop: 50,
            marginBottom: 40,
            style: {
              fontFamily: 'Montserrat',
            },
          },
          title: {
            text: null,
          },
          subtitle: {
            text: null,
          },
          xAxis: {
            labels: {
              formatter: label => {
                if (label.value > 1000 && label.value <= 999999) return `${label.value / 1000}k`;
                else if (label.value >= 1000000) return `${label.value / 1000000}mi`;
                return label.value;
              },
            },
            title: {
              enabled: true,
              text: this.$i18n.t('frequency'),
              style: {
                fontWeight: '600',
                textTransform: 'uppercase',
                fontSize: '11px',
              },
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
          },
          yAxis: {
            labels: {
              x: -10,
              formatter: label => {
                if (label.value > 1000 && label.value <= 999999) return `${label.value / 1000}k`;
                else if (label.value >= 1000000) return `${label.value / 1000000}mi`;
                return label.value;
              },
            },
            title: {
              text: this.$t('aov'),
              rotation: 0,
              reserveSpace: 0,
              align: 'high',
              offset: 0,
              // Position change on english version
              x: this.$t('aov') === 'AOV' ? 0 : 52,
              y: -20,
              lineWidth: 1,
              tickWidth: 1,
              style: {
                fontWeight: '600',
                textTransform: 'uppercase',
                fontSize: '11px',
              },
            },
          },
          legend: {
            itemMarginTop: -15,
            itemMarginBottom: 25,
            enabled: true,
            align: 'right',
            verticalAlign: 'top',
            itemStyle: {
              color: 'grey',
              fontWeight: 400,
            },
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            scatter: {
              marker: {
                symbol: 'circle',
                radius: 3.5,
                states: {
                  hover: {
                    enabled: false,
                    lineColor: 'rgb(100,100,100)',
                  },
                },
              },
              states: {
                hover: {
                  marker: {
                    enabled: false,
                  },
                },
              },
            },
          },
          tooltip: {
            borderWidth: 0,
            backgroundColor: '#fff',
            padding: 15,
            style: {
              color: '#555',
              lineHeight: '20px',
            },
            formatter: function formatter() {
              const frequency = this.x === 10 ? `${formatDecimal(this.x, 1)}+` : formatDecimal(this.x, 1);
              return `<span style="font-weight: 600; color: ${this.series.color}">${self.$i18n.t(
                `clients-${this.series.name.toLowerCase()}`,
              )}</span><br>
              ${self.$i18n.t('aov-ticket')}: <span style="font-weight:600">${formatCurrency(this.y)}</span><br>
              ${self.$i18n.t('clients')}: <span style="font-weight:600">${formatDecimal(
                self[`array${this.series.name}`][this.point.index][2],
                1,
              )}</span><br>
              ${self.$i18n.t('frequency')}: <span style="font-weight:600">${frequency}</span>`;
            },
          },
          series: [
            {
              name: 'On',
              color: '#25c2a4',
              data: this.arrayOn,
            },
            {
              name: 'Off',
              color: '#a334ff',
              data: this.arrayOff,
            },
            {
              name: 'Omni',
              color: '#6c90f4',
              data: this.arrayOmni,
            },
          ],
        },
        chart => {
          // Jitter algorithm, displaces each X value with +/- 0.5
          if (chart.series && chart.series.length && chart.series[0].data) {
            chart.series[0].data.map(point => {
              point.graphic.attr({
                x: point.plotX + (Math.random() + 0.5),
              });

              return point;
            });
          }
        },
      );
    },
    fetch_data() {
      this.$_reqConfig();
      // valor total de before pela data inicial
      getMetrics({
        metrics: 'revenue_dispersion',
      })
        .then(response => {
          if (this.$_verifyData(response.data.data)) return;

          this.dataReturn = response.data;
          this.dataReturn.data.forEach(row => {
            if (row.m_type === 'omni') {
              this.arrayOmni = row.data.map(elem => [
                parseFloat(elem.frequency),
                parseFloat(elem.avg_order_value),
                parseFloat(elem.clients),
              ]);
            } else if (row.m_type === 'on') {
              this.arrayOn = row.data.map(elem => [
                parseFloat(elem.frequency),
                parseFloat(elem.avg_order_value),
                parseFloat(elem.clients),
              ]);
            } else if (row.m_type === 'off') {
              this.arrayOff = row.data.map(elem => [
                parseFloat(elem.frequency),
                parseFloat(elem.avg_order_value),
                parseFloat(elem.clients),
              ]);
            }
          });

          this.createChartFrequency();
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
