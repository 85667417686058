<template>
  <div id="conversionsalesproductscategories" :class="{ loadHeight: loading }" class="card">
    <div v-if="$_verifyLoaded('done')" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-money"></i>
        <span>{{ $t('products-categories') }}</span>
      </h2>
      <div class="row">
        <div class="col-lg-8 col-12 mb-sm-4">
          <chart-list :chart-name="$t('highest-revenue-products')" :rows-list="objectChartListProducts" />
        </div>
        <div class="col-lg-8 col-12 mb-sm-2 mb-xl-0">
          <chart-list :chart-name="$t('highest-revenue-categories')" :rows-list="objectChartListCategories" />
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import { getPercentual, getMax } from '@/utilities/numbers';
import ChartList from '@/components/_organisms/ChartList';
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import verifyThensMixin from '@/helpers/mixins/verifyThensMixin';
import { formatCurrency } from '@/utilities/formatters';

export default {
  name: 'ConversionSalesProdutcsCategories',
  components: {
    'chart-list': ChartList,
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, verifyThensMixin],
  data() {
    return {
      loader: {
        loadingProducts: false,
        loadingCategories: false,
        loadingAvg: false,
      },
      dataReturnProducts: {},
      dataReturnAvgs: {},
      dataReturnCategories: {},
      JsonProducts: {
        title: '',
        suffix: '',
        prefix: '',
        data: [],
      },
      JsonCategories: {
        title: '',
        suffix: '',
        prefix: '',
        data: [],
      },
      objectChartListProducts: [],
      objectChartListCategories: [],
    };
  },
  computed: {
    totalamountProducts() {
      const datavalue = this.dataReturnProducts;
      const total = Object.values(datavalue).reduce((t, n) => parseInt(t, 10) + parseInt(n, 10));
      return total;
    },
    totalamountCategories() {
      const datavalue = this.dataReturnCategories;
      const total = Object.values(datavalue).reduce((t, n) => parseInt(t, 10) + parseInt(n, 10));
      return total;
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();
      this.componentHasData = [];
      this.loader.loadingProducts = true;
      this.loader.loadingCategories = true;
      this.loader.loadingAvg = true;

      getMetrics({
        metrics: 'best_seller_prod',
        filters: 'best_sellers:revenue',
        version: 2,
        topvalues: '4',
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) {
            this.componentHasData.push(false);
            return;
          } else this.componentHasData.push(true);

          this.dataReturnProducts = response.data.metrics.best_seller_prod.data.series[0].data;
          // Chart: maior Receita - Produtos
          this.objectChartListProducts = [];
          const max = getMax(this.dataReturnProducts);
          Object.entries(this.dataReturnProducts).forEach(([key, value]) => {
            this.objectChartListProducts.push({
              label: key,
              progress: String(getPercentual(value, max)),
              value: formatCurrency(value, { shortNumber: true, max: 1 }),
              color: 'lightblue',
            });
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingProducts = false;
          this.$_verifLoading();
        });

      getMetrics({
        metrics: 'best_seller_categ',
        filters: 'best_sellers:revenue',
        version: 2,
        topvalues: '4',
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.dataReturnCategories = response.data.metrics.best_seller_categ.data.series[0].data;
          // Chart: maior Receita - Categorias
          this.objectChartListCategories = [];
          const max = getMax(this.dataReturnCategories);
          Object.entries(this.dataReturnCategories).forEach(([key, value]) => {
            this.objectChartListCategories.push({
              label: key,
              progress: String(getPercentual(value, max)),
              value: formatCurrency(value, { shortNumber: true }),
              color: 'lightblue',
            });
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingCategories = false;
          this.$_verifLoading();
        });

      getMetrics({
        metrics: 'avg_order_value,avg_order_value_off,avg_order_value_on,avg_order_value_app',
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.dataReturnAvgs = response.data.metrics;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingAvg = false;
          this.$_verifLoading();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @media (min-width: $xl-min-width) {
    min-height: 279px;
  }
}
#conversionsalesproductscategories {
  &.loadHeight {
    height: 312px;
  }
}
</style>
