<template>
  <path
    d="M15.1125 5.24891L15.1025 5.16002H15.0131H12.1798V3.28002C12.1798 1.90082 11.0309 0.83335 9.65977 0.83335C9.29094 0.83335 8.99329 0.905052 8.63715
    1.04422C8.33465 0.902484 7.96873 0.83335 7.60644 0.83335C6.23739 0.83335 5.08644 1.89881 5.08644 3.20668V5.08668H2.10644H2.01583L2.00692 5.17686L0.83359
    17.0569L0.822742 17.1667H0.933105H16.3331H16.4449L16.4325 17.0556L15.1125 5.24891ZM5.87311 3.28002V3.18002H5.80278C5.85771 2.27067 6.65864 1.62002 7.60644
    1.62002C7.69673 1.62002 7.77759 1.62055 7.85456 1.63457C7.39744 2.08934 7.13977 2.72111 7.13977 3.35335V5.23335H5.87311V3.64668V3.28002ZM2.78342
    5.87335H5.15977V7.16668V7.26668H5.25977H5.84644H5.94644V7.16668V5.87335H9.48644V7.16668V7.26668H9.58644H10.1731H10.2731V7.16668V5.87335H12.7787L11.987
    16.4534H1.70383L2.78342 5.87335ZM9.65977 1.62002C10.6309 1.62002 11.3951 2.38239 11.4664 3.28391V5.16002H10.1998V3.28002C10.1998 2.67458 9.96349 2.06958
    9.54205 1.62002H9.54261H9.54344H9.54427H9.5451H9.54593H9.54676H9.54759H9.54842H9.54925H9.55009H9.55092H9.55175H9.55258H9.55342H9.55425H9.55508H9.55592H9
    .55676H9.55759H9.55843H9.55926H9.5601H9.56094H9.56177H9.56261H9.56345H9.56429H9.56513H9.56597H9.56681H9.56765H9.56849H9.56933H9.57017H9.57101H9.57185H9
    .57269H9.57354H9.57438H9.57522H9.57607H9.57691H9.57775H9.5786H9.57944H9.58029H9.58113H9.58198H9.58283H9.58367H9.58452H9.58537H9.58621H9.58706H9.58791H9
    .58876H9.5896H9.59045H9.5913H9.59215H9.593H9.59385H9.5947H9.59555H9.5964H9.59725H9.5981H9.59895H9.5998H9.60065H9.60151H9.60236H9.60321H9.60406H9.60491H9
    .60577H9.60662H9.60747H9.60833H9.60918H9.61003H9.61089H9.61174H9.6126H9.61345H9.61431H9.61516H9.61602H9.61687H9.61773H9.61858H9.61944H9.62029H9.62115H9
    .62201H9.62286H9.62372H9.62457H9.62543H9.62629H9.62714H9.628H9.62886H9.62972H9.63057H9.63143H9.63229H9.63315H9.634H9.63486H9.63572H9.63658H9.63744H9.6383H9
    .63915H9.64001H9.64087H9.64173H9.64259H9.64345H9.64431H9.64516H9.64602H9.64688H9.64774H9.6486H9.64946H9.65032H9.65118H9.65204H9.6529H9.65376H9.65461H9
    .65547H9.65633H9.65719H9.65805H9.65891H9.65977ZM7.92644 3.28002C7.92644 2.75023 8.18066 2.28076 8.63861 1.93345C9.08125 2.21534 9.34388 2.74426 9.4131
    3.28631V5.16002H7.92644V3.28002ZM12.5542 16.4534L13.3459 5.87335H14.4107L15.6342 16.4534H12.5542Z"
    stroke-width="0.2"
  />
</template>
