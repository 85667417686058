<template>
  <div>
    <label class="mb-1">{{ labelName }}</label>
    <div class="row justify-content-between">
      <div class="col-6">{{ $t('nps.ratings') }}</div>
      <div class="amount col-6 text-right">{{ getRatings(ratings) }}</div>
    </div>
    <div class="row justify-content-between">
      <div class="col-6">{{ $t('nps.average') }}</div>
      <div class="amount col-6 text-right green">{{ getScore(score) }}</div>
    </div>
  </div>
</template>

<script>
import { formatDecimal } from '@/utilities/formatters';

export default {
  name: 'MetricItem',
  props: {
    labelName: {
      type: String,
      default: '',
    },
    ratings: {
      type: Number,
      default: null,
    },
    score: {
      type: Number,
      default: null,
    },
  },
  methods: {
    getRatings(val) {
      return formatDecimal(val, 1);
    },
    getScore(val) {
      return formatDecimal(val, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  color: $gray-700;
  line-height: 20px;
  font-weight: 400;
  font-size: 12px;
  label {
    font-weight: 600;
  }
  .amount {
    font-weight: 600;
  }
  .amount {
    &.green {
      color: $oto-ecommerce;
    }
    &.red {
      color: $color-red;
    }
    &.none {
      color: $gray-500;
    }
  }
  .pr-3 {
    padding-right: unset !important;
  }
}
</style>
