<template>
  <div>
    <section :id="`audience-${getModeString()}`" class="audience-include-exclude">
      <h2 v-if="!areRulesEmpty">{{ $t(`audiences.${getModeString()}`) }}</h2>

      <detail-group
        v-for="(group, index) in groups"
        :id="index"
        :key="index"
        :include="isInclude"
        :series="group"
        :is-editable="isEditable"
      />

      <detail-add-group-button
        v-if="isEditable"
        :title="$t(`audiences.add-group-${getModeString()}`)"
        :action="addGroupInternal"
      />

      <p v-if="areRulesEmpty ? false : !isEditable && !groups">
        {{ $t(`audiences.no-${getModeString()}`) }}
      </p>
    </section>
  </div>
</template>

<script>
// libs, helpers, services
import { mapState, mapGetters, mapActions } from 'vuex';
// components - molecules
import DetailAddGroupButton from '@/components/labels/_molecules/DetailAddGroupButton';
// components - organisms
import DetailGroup from '@/components/labels/_organisms/DetailGroup';

export default {
  name: 'SectionInclude',
  components: {
    DetailGroup,
    DetailAddGroupButton,
  },
  props: {
    isInclude: Boolean,
  },
  data() {
    return {
      isActiveFromServer: false,
    };
  },
  computed: {
    ...mapGetters('labels', ['areRulesEmpty']),
    ...mapState('labels', ['labelDetail', 'labelDetailMode']),
    isEditable() {
      return this.labelDetailMode !== 'view';
    },
    groups() {
      const { include, exclude } = this.labelDetail.query;
      return this.isInclude ? include : exclude;
    },
  },
  methods: {
    ...mapActions('labels', ['addGroup', 'addSerie', 'removeGroup', 'removeSerie']),
    /**
     * @description method to get mode name
     * @returns {String}
     */
    getModeString() {
      return this.isInclude ? 'include' : 'exclude';
    },
    addGroupInternal() {
      this.addGroup({ include: this.isInclude });
    },
  },
};
</script>

<style lang="scss" scoped>
.audience-include-exclude {
  margin-top: 40px;
}
</style>
