import store from '@/store';
import { valueType } from '../constants';

type CurrencyOptions = {
  shortNumber?: boolean;
  max?: number;
  noSymbol?: boolean;
};

type CurrencySettings = {
  isoCode: string;
  locale: string;
};

type CurrencyFormatting = {
  currency: string;
  style: string;
  notation: 'compact' | 'standard';
  maximumFractionDigits: number | undefined;
  currencyDisplay: string;
};

export const formatCurrency = (value: string | number, options?: CurrencyOptions) => {
  const DEFAULT_CURRENCY_SETTINGS = {
    isoCode: 'BRL',
    locale: 'pt-BR',
    symbol: 'R$',
  };

  const { locale, isoCode }: CurrencySettings = store.getters?.currency || DEFAULT_CURRENCY_SETTINGS;
  const currencyValue = !value || value === '0' ? 0 : +String(value).replaceAll(',', '.');

  let redondValueCount;
  const currencyString = String(currencyValue).split('.')[0];
  const numDigits = currencyString.length;

  if (numDigits === 13) {
    redondValueCount = 1e11;
  } else if (numDigits === 12) {
    redondValueCount = 1e9;
  } else if (numDigits === 9) {
    redondValueCount = 1e6;
  } else if (numDigits === 7) {
    redondValueCount = 1e5;
  } else if (numDigits === 8) {
    redondValueCount = 1e6;
  } else if (numDigits === 5 || numDigits === 6 || numDigits === 4) {
    redondValueCount = 1e3;
  } else if (numDigits >= 10) {
    redondValueCount = 1e8;
  } else {
    redondValueCount = 1;
  }

  const roundedValue = Math.floor(currencyValue / redondValueCount) * redondValueCount;

  const formatting: CurrencyFormatting = {
    currency: isoCode,
    style: valueType.currency,
    notation: options?.shortNumber ? 'compact' : 'standard',
    maximumFractionDigits: options?.max ? options.max : undefined,
    currencyDisplay: 'narrowSymbol',
  };

  const result = new Intl.NumberFormat(locale, formatting).format(options?.shortNumber ? roundedValue : currencyValue);

  if (options?.noSymbol) {
    return result.replace(/^(\D+)/, '');
  }

  return isoCode === 'PEN' ? result.replace('S/', 'S/.') : result;
};
