// libs
import _ from 'lodash';
import moment from 'moment';
import { getNumeral } from '@/utilities/numbers';

// helpers
import { valuesByFormat } from '@/utilities/formatters';

// utilities
import { formatDecimal, formatCurrency, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

const mixin = {
  methods: {
    isNil(val) {
      return _.isNil(val);
    },

    /**
     * @description row counter
     * @param {*} row
     * @param {*} limit
     * @returns row total
     */
    $_emptyRowCounter(row, limit) {
      return row < limit ? limit - row : false;
    },

    /**
     * @description get value according to type passed
     * @param {*} item
     * @returns string value or amount result
     */
    $_getValue(item) {
      const { type, amount } = item;

      // Null values should be display as empty, not zero.
      if (amount === null) {
        return '';
      }

      switch (type) {
        case valueType.date:
          return moment(amount).format('DD/MM/YYYY');
        case valueType.percent:
          return formatNumber(amount * 100, '0.00%');
        case valueType.percentCircle:
          return Math.round(getNumeral(amount * 100));
        case valueType.decimal:
          return formatCurrency(amount);
        case valueType.float:
          return formatDecimal(amount, 2);
        case valueType.integer:
          return formatDecimal(amount);
        case valueType.string:
          return amount;
        default:
          return amount;
      }
    },

    /**
     * @description return a value according to type and format passed
     * @param {*} vH
     * @param {*} amount
     * @returns string value or amount result
     */
    $_getValueNewFormat({ vH, amount }) {
      const metric = {};
      if (amount) metric.amount = amount;
      if (vH.type) metric.type = vH.type;
      if (vH.format) metric.format = vH.format;

      return valuesByFormat(metric);
    },

    /**
     * @description Verify if amount is between threshold values
     * @param {object} config
     * @returns css class of selected interval
     */
    $_checkThreshold(config) {
      const { amount, threshold } = config;

      if (!threshold || !threshold.length || threshold.length < 2) return '';

      const pattern = threshold[0] > threshold[1]; // true => decreasing (bigger is better) | false => increasing (smaller is better)
      if (pattern) {
        if (amount < threshold[0] && amount >= threshold[1]) return 'pointer-yellow';
        else if (amount >= threshold[0]) return 'pointer-green';
        else return 'pointer-red';
      } else {
        if (amount > threshold[0] && amount <= threshold[1]) return 'pointer-yellow';
        else if (amount <= threshold[0]) return 'pointer-green';
        else return 'pointer-red';
      }
    },

    /**
     * @description listening table scroll X, after this set the value on header fixed and on tbl-config.
     */
    $_scrollTable() {
      // Table visible width
      const tblVisibleWidth = 1132;
      const tableInnerWrapper = this.$refs['tbl-inner-wrapper'];
      const tableScrollPosition = tableInnerWrapper.scrollLeft;
      const tblBody = this.$refs['tbl-body'];
      const tblHeader = this.$refs['tableHeader'];
      const tblConfig = this.$refs['tbl-config'];
      let tblBodyWidth = window?.getComputedStyle(tblBody, null)?.getPropertyValue('width');
      let tblHeaderWidth = window?.getComputedStyle(tblHeader, null)?.getPropertyValue('width');
      let tblConfigLeft = window?.getComputedStyle(tblConfig, null)?.getPropertyValue('left');

      tblConfigLeft = tblConfigLeft.replace('px', '');
      tblConfigLeft = parseInt(tblConfigLeft);
      tblBodyWidth = tblBodyWidth.replace('px', '');
      tblBodyWidth = parseInt(tblBodyWidth);
      tblHeaderWidth = tblHeaderWidth.replace('px', '');
      tblHeaderWidth = parseInt(tblHeaderWidth);

      // If header width is higher of visible width, so set header width on table body
      if (tblHeaderWidth > tblVisibleWidth) {
        tblBody.style.minWidth = tblHeaderWidth + 'px';
      }

      const tblConfigMaxWidth = tblBodyWidth - tblVisibleWidth;

      // Calc left of table config
      if (tblConfigLeft < tblConfigMaxWidth) {
        tblConfig.style.left = tableScrollPosition + 20 + 'px';
        tblConfig.style.paddingLeft = 0;
      }
      if (tblConfigLeft >= tblConfigMaxWidth) {
        tblConfigLeft = parseInt(tblConfig.style.left.replace('px', ''));

        tblConfig.style.left = tableScrollPosition - 1 + 'px';
        tblConfig.style.paddingLeft = 21 + 'px';
      }

      // Set header fixed position
      if (!this.$refs['header-fixed']) return;
      this.$refs['header-fixed'].scrollTo(tableScrollPosition, 0);
    },

    /**
     * @description order in asc or desc
     * @param {object} data
     */
    $_tableOrdination(data) {
      // eslint-disable-next-line prefer-const
      let { tableData, order, orderType, key, verifyIsOrdenate } = data;
      if (order === key) {
        orderType === 'asc' ? (orderType = 'desc') : (orderType = 'asc');
      } else {
        if (order) {
          if (orderType === 'desc') orderType = 'asc';
          order = key;
        }
      }
      if (!order) order = key;

      verifyIsOrdenate = true;

      if (key === valueType.date) {
        tableData = _.orderBy(tableData, key, orderType);
      } else {
        tableData = _.orderBy(
          tableData,
          item => (_.isString(item[key]) ? item[key].toLowerCase() : parseFloat(item[key])),
          orderType,
        );
      }
      return { tableData, order, orderType, verifyIsOrdenate, key };
    },

    /**
     * @description order in asc or desc
     * @param {object} data
     */
    $_tableOrderType(data) {
      // eslint-disable-next-line prefer-const
      let { order, orderType, key } = data;
      if (order === key) {
        orderType === 'asc' ? (orderType = 'desc') : (orderType = 'asc');
      } else {
        if (order) {
          if (orderType === 'desc') orderType = 'asc';
          order = key;
        }
      }
      if (!order) order = key;
      return { order, orderType, key };
    },
  },
};

export default mixin;
