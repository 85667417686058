<template>
  <div class="integrations">
    <header class="integrations__header">
      <h1 class="card-group-title">
        {{ $tc('settings.integrations.title', 1) }}
      </h1>
      <button class="btn integrations__btn-add" @click.prevent="openConfigurationModal">
        {{ $t('settings.integrations.add') }}
        <i class="icon icon-plus"></i>
      </button>
    </header>

    <integration-list />
    <integration-create-edit />
    <integration-config-modal />
    <integration-success-modal />
    <integration-question-modal />
  </div>
</template>

<script>
import IntegrationList from '@/components/settings/integrations/IntegrationList';
import IntegrationCreateEdit from '@/components/settings/integrations/IntegrationCreateEdit';
import IntegrationConfigModal from '@/components/settings/integrations/IntegrationConfigModal';
import IntegrationSuccessModal from '@/components/settings/integrations/IntegrationSuccessModal';
import IntegrationQuestionModal from '@/components/settings/integrations/IntegrationQuestionModal';
// services
import { getState } from '@/services/connectors';
// libs
import bus from '@/helpers/events/bus';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'Integrations',
  components: {
    IntegrationList,
    IntegrationCreateEdit,
    IntegrationConfigModal,
    IntegrationSuccessModal,
    IntegrationQuestionModal,
  },
  props: {
    state: {
      type: String,
      default: null,
    },
  },
  mounted() {
    useMixpanelConfig('view_settings_integrations');
    // if has oauth2 state param
    if (this.state) {
      getState(this.state).then(({ data }) => {
        if (data.success) {
          this.$router.push({ name: 'Integrations' });
          if (localStorage.integrationAlias && localStorage.integrationId) {
            bus.$emit('openIntegrationCreateEdit', {
              alias: localStorage.integrationAlias,
              id: parseInt(localStorage.integrationId),
              refresh_token: data.data.token.refresh_token,
              fields: data.data.fields,
            });
            localStorage.integrationAlias = null;
            localStorage.integrationId = null;
          }
        }
      });
    }
  },
  methods: {
    openConfigurationModal() {
      bus.$emit('openConfigurationModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btn-add {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 220px;
  }
}
</style>
