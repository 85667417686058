<template>
  <table-list :data="dataTable" class="loadHeight" type="filled">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column><modal-dates /></table-list-column>
        <table-list-column v-for="(media, index) in dataTable.titles" :key="index" class="text-right text-capitalize">
          {{ media }}
        </table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row
          v-for="(item, index) in dataTable.rows"
          :key="index"
          :click-callback="() => $_handleTableRow(item.segment_id)"
          :class="{ active: item.segment_id == segmentSelected }"
        >
          <table-list-column>
            <i class="icon icon-filter"></i> {{ item.name }} ({{ getCurrencySymbol }})
          </table-list-column>
          <table-list-column v-for="(media, index) in dataTable.titles" :key="index" class="text-right">
            {{ $_formatDecimal(item.metrics && item.metrics[media]) }}
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </table-list>
</template>

<script>
import getTable from '@/services/getTable';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import ModalDates from '@/components/ModalDates';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import store from '@/store';

export default {
  name: 'RevByMediaTable',
  components: {
    ModalDates,
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataTable: {
        count: 0,
        rows: [],
        titles: [],
      },
      segmentSelected: '',
    };
  },
  computed: {
    getCurrencySymbol() {
      return store.getters?.currency?.symbol;
    },
  },
  mounted() {
    this.reset();
    this.fetchData();
  },
  methods: {
    fetchData() {
      getTable({
        metrics: 'revenue_by_medium',
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.data)) return;

          this.dataTable.rows = response.data.data;
          this.dataTable.count = this.dataTable.rows.length;
          this.dataTable.titles = Object.keys(this.dataTable.rows[0].metrics);
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$_reqConfig();
      this.ready = false;
      this.dataTable.rows = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-body,
.table-list-header {
  .table-list-column {
    &:first-child {
      padding-left: 30px !important;
      @media screen and (min-width: 1200px) {
        min-width: 160px !important;
      }
    }

    &:last-child {
      padding-right: 30px !important;
    }
  }
}
</style>
