<template>
  <section id="labels-footer">
    <div class="container">
      <div class="cols d-flex align-items-center">
        <div class="count">
          <labels-footer-counter :people="labelDetail.count" :time="time" />
        </div>
        <div v-show="brandHas360" class="column-360">
          <label @click="actionSetLabel360">
            <check-box :checked="labelDetail.label360" />
            <span>{{ $t('labels-personas.show-360') }}</span>
          </label>
        </div>
        <button class="btn btn-save-label" @click="saveLabel">
          {{ $tc('labels.home.save-label', 1) }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
// libs, helpers, services
import moment from 'moment';
import bus from '@/helpers/events/bus';
import { createLabel, updateLabel } from '@/services/labels';
import { mapState, mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
// components - atoms
import CheckBox from '@/components/_atoms/CheckBox';
// components - molecules
import LabelsFooterCounter from '@/components/labels/_molecules/LabelsFooterCounter';

export default {
  components: {
    LabelsFooterCounter,
    CheckBox,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      updated: false,
      lastUpdate: moment(),
      time: 0,
    };
  },
  computed: {
    ...mapState('labels', ['labelDetail', 'brandHas360', 'labelDetailMode']),
    ...mapGetters('labels', ['query', 'getLabelDetailUnsavedCompose']),
  },
  mounted() {
    setInterval(() => {
      if (!this.updated) {
        const time = moment().diff(this.lastUpdate) / 1000;
        if (time > 5) this.time = time;
      } else {
        this.time = 0;
      }
      this.updated = false;
    }, 1000);

    bus.$on('count-updated', () => {
      this.updated = true;
      this.lastUpdate = moment();
      this.time = 0;
    });
  },
  methods: {
    ...mapActions('labels', ['actionSetLabel360']),
    saveLabel() {
      if (this.labelDetail?.labelId && this.labelDetailMode === 'view') {
        // mode: update mode
        updateLabel(this.getLabelDetailUnsavedCompose).then(res => {
          if (!res?.data?.success) {
            this.showErrorMessage(res.status);
          } else {
            this.toast.success(this.$t('labels.detail.messages.updated'), {
              timeout: 5000,
            });
          }
        });
      } else {
        // mode: save/duplicate
        const { name, description, label360 } = this.labelDetail;
        createLabel({
          name,
          description,
          label360,
          query: JSON.stringify(this.query),
        }).then(res => {
          if (!res?.data?.success) {
            this.showErrorMessage(res.status);
          } else {
            bus.$emit('show-detail-success', res.data.data.labelId);
          }
        });
      }
    },
    showErrorMessage(errorCode) {
      if (errorCode === 429) {
        bus.$emit(
          'show-detail-error',
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('labels.title', 2).toLowerCase(),
          }),
        );
      } else {
        this.toast.error(
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('labels.title', 1).toLowerCase(),
          }),
        );
        bus.$emit('header-details-input-blur');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#labels-footer {
  padding: 18px 0;
  background-color: $color-white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  color: $gray-700;
  z-index: 99;

  .cols {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .count {
      flex-grow: 10;
    }
    .audience-steps {
      flex-grow: 1;
      justify-content: center;
      display: flex;
    }

    @media (max-width: $md-max-width) {
      flex-wrap: wrap;
    }
  }
}
.column-360 {
  margin-right: 20px;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin-left: 10px;
      color: $gray-700;
    }
  }
}

.btn-save-label {
  width: 180px;
  height: 40px;
}
</style>
