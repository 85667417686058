<template>
  <div class="indicators-funnel-header">
    <template v-if="$_verifyLoaded('done')">
      <div class="row ifh__metrics">
        <div v-for="(metric, key) in metrics" :key="key">
          <template v-if="metric && metric.has_data">
            <p>
              {{ metric.title }}
              <span
                class="icon-wrapper"
                v-tooltip="{
                  content: this.$t(`360.indicators.funnel-header.${key}`),
                  theme: 'default',
                  distance: 8,
                }"
              >
                <i class="icon icon-question-14px"></i>
              </span>
            </p>
            <span
              :class="['ifh__metric-amount', resolveTooltipAmount(metric) ? 'ifh__metrics-tooltip' : '']"
              v-tooltip="{
                content: resolveTooltipAmount(metric),
                theme: 'default',
                skidding: -32,
                distance: 8,
              }"
            >
              {{ getFormattedAmount(metric) }}
            </span>
          </template>
        </div>
      </div>
    </template>

    <base-loader v-show="$_verifyLoaded('loading')" :height="'75'">
      <template #message>
        <p class="assync-message">{{ $tc(`assync-status.${assyncCode}`) }}</p>
      </template>
    </base-loader>
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { mapGetters, mapState, mapActions } from 'vuex';
import store from '@/store';

import { getMetrics } from '@/services/oto/indicators';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';
import { verifyMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

import { thirtyDays, valueType } from '@/utilities/constants';

export const assyncStatus = {
  started: 'job-started',
  pending: 'job-pending',
  finished: 'job-finished-successfully',
  cancelled: 'job-cancelled-with-errors',
};

export default {
  name: 'IndicatorFunnelHeader',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      metrics: [...Array(8)],
      threshold: 1099999,
      batchId: '',
      isAssync: true,
      assyncCode: assyncStatus.started,
      daysAverage: this.$t('days-average'),
    };
  },
  watch: {
    metrics: function () {
      this.setFunnel(this.metrics?.funnel ?? {});
    },
    assyncCode: function () {
      this.setAssyncCodeFunnel(this.assyncCode);
    },
    hasError: function () {
      this.setHasErrorFunnel(this.hasError);
    },
  },
  computed: {
    ...mapGetters('indicators', ['getSegmentId', 'getStoreId', 'getSellerId', 'getCampaignId', 'getSourceId']),
    ...mapState('indicators', ['conversionWindow']),
    isDatepickerMoreThanThirtyDays() {
      return store?.getters?.getDifferenceInDays > thirtyDays;
    },
  },
  mounted() {
    this.fetchData();

    bus.$on('indicator-funnel-header-select', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off(['indicator-funnel-header-select']);
  },
  methods: {
    ...mapActions('indicators', [
      'setMetricsBatchId',
      'setFunnel',
      'setIsLoadingFunnel',
      'setAssyncCodeFunnel',
      'setHasErrorFunnel',
    ]),

    fetchData() {
      this.setIsLoadingFunnel(true);
      this.isAssync = this.isDatepickerMoreThanThirtyDays;

      this.$_reqConfig();

      this.resetBatchId();

      const metrics =
        'orders_tracked, revenue_tracked, aov, conversion_rate, orders_tracked_rate, conversion_window,unique_recipients,unique_customers';

      this.fetchDefaultMetrics({
        metrics,
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        source: this.getSourceId,
        lookback: this.conversionWindow,
        isAssync: this.isAssync === true ? 1 : 0,
      });
    },

    async fetchDefaultMetrics({ ...params }) {
      try {
        const res = await this.fetchAsyncMetrics({ ...params });

        if (!this.isAssync) {
          if (this.$_verifyHasData(res?.data?.data)) return;
          this.metrics = res?.data?.data;
          return;
        }

        this.batchId = res?.data?.data?.batchId;

        if (res?.data.success && this.batchId && this.isAssync) {
          await this.fetchByBatchId(this.batchId);
        }
      } catch (error) {
        this.hasError = true;
        this.loading = false;
        this.setIsLoadingFunnel(false);
        this.$_componentHeight();
      } finally {
        if (!this.isAssync) {
          this.loading = false;
          this.setIsLoadingFunnel(false);
        }
      }
    },

    async fetchByBatchId(batchId) {
      // prevent case without batch id or case has batch id but the assync option is 0
      if (!batchId) return;
      if (batchId && !this.isAssync) return;

      this.setMetricsBatchId(batchId);

      try {
        const res = await this.fetchAsyncMetrics({ batchId, isAssync: this.isAssync === true ? 1 : 0 });

        const code = res?.data?.data?.code;

        if (code === assyncStatus.cancelled) throw new Error();

        this.assyncCode = code;

        if (this.assyncCode !== assyncStatus.finished && res?.data.success) {
          await this.timeout(3000);
          await this.fetchByBatchId(batchId);
        } else this.metrics = res?.data?.data?.results;
      } catch (error) {
        this.hasError = true;
        this.loading = false;
        this.setIsLoadingFunnel(false);
        this.$_componentHeight();
      } finally {
        if (this.isAssync && this.assyncCode === assyncStatus.finished) {
          this.resetCode();
          this.loading = false;
          this.setIsLoadingFunnel(false);
          this.setMetricsBatchId('');
          this.resetBatchId();
        }

        if (!this.isAssync) {
          this.loading = false;
          this.setIsLoadingFunnel(false);
          this.setMetricsBatchId('');
        }
      }
    },

    async fetchAsyncMetrics(params) {
      return await getMetrics({ ...params });
    },

    async timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    resetBatchId() {
      this.batchId = '';
    },

    resetCode() {
      this.assyncCode = assyncStatus.started;
    },

    formattedCurrency(amount) {
      return amount > this.threshold ? formatCurrency(amount, { shortNumber: true }) : formatCurrency(amount);
    },

    resolveTooltipAmount(metric) {
      const metricType = metric?.data?.value?.type;
      const amount = metric?.data?.value?.amount;

      if (metricType === valueType.decimal) return formatCurrency(amount);
    },

    getFormattedAmount(metric) {
      const metricType = metric?.data?.value?.type;
      const amount = metric?.data?.value?.amount;

      let formattedAmount = '';

      if (metricType === valueType.decimal) {
        formattedAmount = this.formattedCurrency(amount);
      } else if (metricType === valueType.float) {
        formattedAmount = `${formatDecimal(amount, 2)} ${this.daysAverage}`;
      } else if (metricType === valueType.integer) {
        formattedAmount = formatDecimal(amount, 1);
      } else if (metricType === valueType.percent) {
        formattedAmount = formatPercent(amount, 1);
      }

      return formattedAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.indicators-funnel-header {
  min-height: 35px;
  margin-top: 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;

  .ifh__metrics-tooltip {
    cursor: pointer;
  }

  .ifh__text-wrapper {
    display: flex;
    align-items: center;
    margin-right: 15px;

    p {
      margin: 0;
      padding-left: 5px;
    }

    i {
      color: $gray-500;
    }
  }

  .ifh__radio-wrapper {
    display: flex;
    align-items: center;
  }

  .ifh__metrics {
    display: flex;

    div {
      margin: 0 15px;
    }

    p {
      font-weight: 600;
      margin: 0;
      padding-bottom: 5px;
    }

    .ifh__metric-amount {
      color: $oto-omni;
      font-size: 16px;
      font-weight: 600;
    }

    .icon-wrapper {
      cursor: pointer;
      color: $gray-500;
      line-height: 1em;
    }

    .v-popper--theme-tooltip .v-popper__inner {
      max-width: 280px;
      cursor: pointer;
      b {
        text-decoration: underline;
      }
    }
  }

  .assync-message {
    width: 200px;
    text-align: center;
  }
}
</style>
