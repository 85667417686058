import { PresetsResponse, RemotePresetsResponse } from '../models';
import { getTablePresets } from '../services';

export const fetchPresets = (): Promise<PresetsResponse> =>
  getTablePresets().then(response => formatResponse(response));

const formatResponse = (response: RemotePresetsResponse): PresetsResponse => ({
  presets: Object.entries(response?.presets ?? {}).map(([key, name]) => ({
    key,
    name,
  })),
  errors: response?.errors,
  success: response?.success,
});
