<template>
  <lightbox-template
    id="lightbox-category-best-seller"
    icon="trending-1"
    :title="$t('product.last-seven-days')"
    :show-segment-group="false"
    :subtracted-date="subtractedDate"
  >
    <div class="container mb-4">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $tc('product.category', 0) }}</h5>
      </header>
    </div>
    <lightbox-skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <table-list :id="id" :data="data" type="filled" :is-lightbox-active="isLightboxActive">
      <template v-if="$_verifyLoaded('done')">
        <table-list-header>
          <table-list-column class="col-3"><modal-dates :subtracted-date="subtractedDate" /></table-list-column>
          <table-list-column class="col-6 text-right">{{ $t('product.product_quantity') }}</table-list-column>
          <table-list-column class="col-3 text-right">{{ $t('product.total') }}</table-list-column>
        </table-list-header>
        <table-list-body>
          <table-list-row
            v-for="(item, index) in data.rows"
            :key="index"
            :cursor-type="'pointer'"
            :category-name="item.name"
          >
            <table-list-column class="col-6 capitalize">{{ item.name }}</table-list-column>
            <table-list-column class="col-3 text-right">{{ $_formatDecimal(item.qty, 3) }}</table-list-column>
            <table-list-column class="col-3 text-right">{{ formatCurrency(item.catRevenue) }}</table-list-column>
          </table-list-row>
        </table-list-body>
      </template>
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </table-list>
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import bus from '@/helpers/events/bus';
import { getTable } from '@/services/product';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import productsMixin from '@/helpers/mixins/productsMixin';
import ModalDates from '@/components/ModalDates';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import LightboxSkeleton from '@/components/lightbox/lightboxes/skeletons/LightboxCategoryBestSellerSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'LightboxCategoryBestSeller',
  components: {
    ModalDates,
    LightboxTemplate,
    LightboxSkeleton,
    InfoCard,
  },
  mixins: [lightboxesMixin, productsMixin, verifyMixin, numberMixin],
  data() {
    return {
      id: 'lightbox-category-best-seller',
      isLightboxActive: true,
      data: {
        limit: 10,
        page: 1,
        series: [],
        rows: [],
        count: 0,
      },
      type: 'filled',
      days: 6,
      subtractedDate: '',
    };
  },
  computed: {},
  mounted() {
    this.fetchData();

    bus.$on(`${this.id}-change-page`, () => {
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
  },

  methods: {
    fetchData() {
      this.$_reqConfig();
      this.subtractedDate = this.$_getSubtractedDate(this.days);
      getTable({
        table: 'categories_best_sellers',
        limit: this.data.limit,
        page: this.data.page,
        startDate: this.$_getSubtractedDate(this.days),
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.data.count = data.total_count;
          this.data.rows = [];
          this.formatRows(data.data);
        })
        .catch(() => {
          this.data.count = 0;
          this.data.rows = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRows(data) {
      const resolveRows = row => {
        const [name, qty, catRevenue] = row;

        return {
          name,
          qty,
          catRevenue,
        };
      };
      data.map(item => this.data.rows.push(resolveRows(item)));
    },
  },
};
</script>
