<template>
  <div>
    <el-dialog v-model="isVisible" id="integration-active-modal" append-to-body>
      <div v-if="!loading && !empty" class="center-block">
        <div class="header text-center">
          <img :src="getIcon(connector)" :alt="connectorName" />
          <h2 v-if="!connector.enabled" class="mt-4">{{ $t('audiences.integration.enable') }}</h2>
          <h2 v-else class="mt-4">{{ $t('integrations.edit-integration') }}</h2>
        </div>
        <div class="actions customScrollBar">
          <div class="group">
            <label for="select-instance">{{ $tc('settings.integrations.configuration') }}</label>
            <select id="select-instance" v-model="selected" @change="fetchData">
              <option v-for="instance in instances" :key="instance.id" :value="instance.id">
                {{ instance.name }}
              </option>
            </select>
          </div>

          <div v-for="(field, index) in fields" :key="index" class="group">
            <label :for="field.name">{{ field.label }}</label>
            <div v-if="isTypeSelect(field.type)" :class="['wrap', { 'template-box': field.name === 'templateId' }]">
              <select id="select-instance" v-model="field.value" @change="reloadTemplateObject">
                <option v-for="(option, index) in field.options" :key="index" :value="option.id">
                  {{ option.name }}
                </option>
              </select>
              <!-- TEMPLATE - sub block if field in array has a 'templateId' name, to render specific template selection -->
              <div v-if="field.name === 'templateId'">
                <div class="template-block">
                  <label>{{ $t('audiences.integration-modal.message') }}:</label>
                  <p class="template-message">
                    {{ getTemplateObjectSelected.text }}
                  </p>
                </div>
                <div class="template-fields">
                  <div
                    v-for="(field, index) in getTemplateFields"
                    :key="index"
                    :class="['subgroup', { freetext: selectModels[index] === 0 }]"
                  >
                    <label :for="`select-${field}`">{{
                      `${$t('audiences.integration-modal.parameter')} "${field}"`
                    }}</label>
                    <select
                      :id="`select-${field}`"
                      v-model="selectModels[index]"
                      :name="`select-${field}`"
                      @change="setFieldValue(field, $event)"
                    >
                      <option :value="0">
                        {{ $t('audiences.integration-modal.static-value') }}
                      </option>
                      <option v-for="column in templateColumns" :key="column.key" :value="column.key">
                        {{ column.name }}
                      </option>
                    </select>
                    <input
                      v-if="selectModels[index] === 0"
                      v-model="templateConfig[1].fields[field]"
                      :placeholder="$t('audiences.integration-modal.enter-value')"
                    />
                  </div>
                </div>
              </div>
              <!-- END TEMPLATE -->
            </div>

            <div v-else class="wrap">
              <input v-model="field.value" :type="field.type" :name="field.name" :class="[{ error: field.error }]" />
            </div>
          </div>
        </div>
        <div class="submit">
          <!-- TODO: use a more meaningful name here. Suggestion: btn-audience-integrations-config -->
          <button v-if="!connector.enabled" class="btn" data-cy="btn-audience-config" @click.prevent="submit">
            {{ $t('settings.integrations.active-integration') }}
          </button>
          <button v-else class="btn" data-cy="btn-audience-config" @click.prevent="submit">
            {{ $t('settings.integrations.edit-integration') }}
          </button>
        </div>
      </div>
      <div v-else-if="empty" class="mt-4 text-center">
        <error-message :message="'hasnt-config'" :button-click="closeModal" />
      </div>
      <base-loader-spinner v-else />
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty, isNull, values, orderBy } from 'lodash';
import bus from '@/helpers/events/bus';
import { getExportationColumns } from '@/services/audiences';
import { getFields } from '@/services/connectors';
import integrationMixin from '@/helpers/mixins/integrationMixin';
import BaseLoaderSpinner from '@/components/_atoms/BaseLoaderSpinner';
import ErrorMessage from '@/components/settings/integrations/ErrorMessage';
import { mapActions } from 'vuex';
import { valueType } from '@/utilities/constants';

export default {
  name: 'IntegrationActiveModal',
  components: {
    BaseLoaderSpinner,
    ErrorMessage,
  },
  mixins: [integrationMixin],
  data() {
    return {
      connectorName: null,
      connector: null,
      loading: false,
      empty: false,
      instances: [],
      fields: [], // used to create a config method
      templateConfig: [{ templateId: '' }, { fields: {} }], // used to create a config method when has templateObject
      selectModels: [],
      selected: 0,
      templateColumns: null,
      isVisible: false,
    };
  },
  computed: {
    /**
     * @function templateObjectItem
     * @description search for an templateId named field in this.fields
     * @returns object if exists or false
     */
    templateObjectItem: function () {
      const templateObjectArray = this.fields?.filter(e => e.name === 'templateId');
      return templateObjectArray.length ? templateObjectArray[0] : false;
    },
    /**
     * @function getTemplateObjectSelected
     * @description from template options list, match each object with id of selected template and returns his properties
     * @returns object selected if exists or false
     */
    getTemplateObjectSelected: function () {
      if (!this.templateObjectItem) return false;
      const templateObjectSelected = this.templateObjectItem.options.filter(
        e => e.id === this.templateObjectItem.value,
      );
      return templateObjectSelected.length ? templateObjectSelected[0] : false;
    },
    /**
     * @function getTemplateFields
     * @description from selected template object text, split strings between '{{' and '}}' symbols
     * @returns array with all fields matched with regex pattern
     */
    getTemplateFields: function () {
      if (!this.templateObjectItem) return false;
      return this.getTemplateObjectSelected?.text?.match(/{{[^}}]+}}/g)?.map(e => e.slice(2, -2));
    },
  },
  mounted() {
    bus.$on('openIntegrationActive', connector => {
      this.connector = connector;
      this.connectorName = connector.alias;
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off('openIntegrationActive');
  },
  methods: {
    ...mapActions('audience', ['updateIsOmnichanelAndIndividualApproach']),

    setFieldValue(fieldName, event) {
      this.templateConfig[1].fields[fieldName] = event.target?.value !== '0' ? `{{${event.target?.value}}}` : '';
    },
    isTypeSelect(fieldType) {
      return fieldType === 'select';
    },
    openModal() {
      this.isVisible = true;
      this.fetchData();
    },
    closeModal() {
      this.isVisible = false;
    },
    fetchData(event) {
      const isChangeEventType = event?.type === 'change';

      this.loading = true;
      if (!isChangeEventType) {
        this.empty = false;
        this.instances = [];
      }
      this.fields = [];
      // call get fields promisse and copy properties to this.fields
      getFields(this.connectorName.toLowerCase(), false, isChangeEventType ? this.selected : null)
        .then(response => {
          const data = response?.data;
          if (isEmpty(data.data?.instances) && !isChangeEventType) {
            this.empty = true;
            return;
          }
          if (!isChangeEventType) {
            this.instances = data?.data?.instances;
            this.selected = this.instances[0]?.id;
          }

          if (typeof data?.data?.audience !== valueType.string && !isEmpty(data?.data?.audience)) {
            this.fields = data.data.audience.map(entry => {
              const initialValue = entry.type === 'select' && entry?.options?.length ? entry?.options[0]?.id : '';
              return new Object({
                ...entry,
                value: initialValue,
                error: false,
              });
            });
          }

          // Change the positions of items inside array to turn "type_send_campaign" first
          if (this.connectorName === 'omnichat') {
            this.fields = this.fields.reverse();
          }

          // check if has template object and call template object mount
          this.reloadTemplateObject();
        })
        .finally(() => {
          this.getTemplateColumns();
          this.loading = false;
        });
    },
    /**
     * @function reloadTemplateObject
     * @description clear data properties, copy { id, fields } to data, mount selectModels with length of fields and set it's initial value as '0';
     */
    reloadTemplateObject() {
      // clear data properties
      this.selectModels = [];
      this.templateConfig[0].templateId = '';
      this.templateConfig[1].fields = {};
      // check if has an object property named templateId
      if (this.templateObjectItem) {
        this.templateConfig[0].templateId = this.getTemplateObjectSelected.id;
        this.getTemplateFields?.map(e => {
          this.templateConfig[1].fields[e] = '';
          this.selectModels.push(0);
        });
      }
    },
    /**
     * @function getTemplateColumns
     * @description if has templateObject in request and templateColumns it's empty (just needed call once), call columns ordered by name to fill templateColumns data
     */
    getTemplateColumns() {
      if (this.templateObjectItem && isNull(this.templateColumns)) {
        getExportationColumns().then(({ data }) => {
          this.templateColumns = orderBy(values(data.columns), 'name');
        });
      }
    },
    submit() {
      this.validate();

      if (this.connectorName === 'omnichat') this.verifyIfIsOmnichatAndIndividualApproach();
      /**
       * reduce to determine the quantity of fields with error;
       * if has someone with error submit function shall not pass
       */
      const config = this.templateObjectItem
        ? JSON.stringify(this.templateConfig)
        : JSON.stringify(this.fields.map(e => ({ [e.name]: e.value })));
      if (this.fields.reduce((acc, va) => (va.error ? acc + 1 : acc), 0) === 0) {
        // dispatch to enable integration (with default values of enabled integration)
        this.$store.dispatch('audience/updateIntegration', {
          connect: this.connectorName,
          alias: this.connectorName,
          icon: this.connector?.icon,
          type: this.connector?.type,
          enabled: 1,
          schedule: 0,
          hasDatetime: false,
          instance: this.selected,
          config,
          hasEdited: true,
        });
        // update vuex state and close modal
        this.closeModal();
      }
    },
    validate() {
      /**
       * check every field in this.fields,
       * if field is required and value is not empty return true;
       * if field is not required return true;
       * change boolean expression result from 'is valid' to 'has error'
       */
      this.fields.map((elem, index) => {
        this.fields[index].error = !((elem.required && elem.value.length > 0) || !elem.required);
      });
    },
    verifyIfIsOmnichatAndIndividualApproach() {
      const getFieldNameByTypeSendCampaign = this.fields?.filter(field => field.name === 'type_send_campaign');
      const isOmnichatAndIndividualApproach =
        getFieldNameByTypeSendCampaign && getFieldNameByTypeSendCampaign[0]?.value === '0';

      this.updateIsOmnichanelAndIndividualApproach(isOmnichatAndIndividualApproach);
    },
  },
};
</script>

<style lang="scss" scoped>
#integration-active-modal {
  .center-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 100%;
    }

    .header {
      margin-bottom: 35px;
      margin-top: -30px;
    }

    .actions {
      min-height: 130px;
      max-height: 350px;
      overflow: auto;
      background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      background-attachment: local, local, scroll, scroll;
      .group {
        padding: 0 30px;
        margin-bottom: 16px;
      }
    }

    .submit {
      border-top: 1px solid #eee;
      button {
        margin: 20px auto;
        width: 250px;
      }
    }
  }
  .customScrollBar {
    &::-webkit-scrollbar-thumb {
      background: $gray-500;
    }
  }
}

.template-box {
  flex-direction: column;
  > div {
    margin-top: 30px;
    p.template-message {
      font-size: 12px !important;
      font-weight: 400 !important;
      text-align: left !important;
    }
    .template-fields {
      margin-top: 30px;
      .subgroup {
        position: relative;
        margin-bottom: 12px;
        &.freetext::after {
          content: '';
          border: 1px solid #d4d4d4;
          width: calc(100% + 20px);
          height: 50px;
          display: block;
          position: absolute;
          bottom: 15px;
          left: -10px;
        }
        input,
        select {
          position: relative;
          z-index: 1;
        }
        input {
          margin-top: 12px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#integration-active-modal {
  width: 350px;
  min-height: 300px;
  overflow: hidden;

  .el-dialog__body {
    padding: unset;
  }
}
</style>
