<template>
  <section class="conversionsales-wrapper">
    <section class="cs__collapsed" @click="getCollapsed(id)">
      <div :class="['cs__collapsed-border', !collapsed ? '--gray' : '--pink']">
        {{ $t('360.analytics-o2o.conversion-sales') }}
      </div>
      <div class="atr__icon">
        <i v-show="!collapsed" class="icon icon-plus"></i>
        <i v-show="collapsed" class="icon icon-minus-30px"></i>
      </div>
    </section>
    <div v-show="collapsed" id="conversionsales">
      <h1 v-if="$route.path.includes('/dashboard/fullview')" class="card-group-title">
        {{ $t('conversion-sales') }}
      </h1>
      <div v-if="$experimental">
        <!-- <div> -->
        <div class="row">
          <div class="col-sm-12 col-xl-5">
            <!-- E-commerce Conversion -->
            <conversion-sales-ecommerce-conversion />
          </div>
          <div class="col-sm-12 col-xl-7">
            <!-- Total Revenue -->
            <conversion-sales-total-revenue />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-7">
            <!-- Orders -->
            <conversion-sales-orders />
          </div>
          <div class="col-sm-12 col-xl-5">
            <!-- Only for desktop | Products Categories -->
            <conversion-sales-products-categories />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xl-4">
            <!-- Only for desktop | Stores Brands -->
            <conversion-sales-identification />
          </div>
          <div class="col-sm-6 col-xl-8">
            <!-- Only for desktop | Stores Brands -->
            <conversion-sales-stores-brands />
          </div>
        </div>
      </div>
      <!-- AQUIIII \/ -->
      <div v-if="!$experimental">
        <div class="row">
          <div class="col-12 col-xl-7">
            <!-- Total Revenue -->
            <conversion-sales-total-revenue :make-api-call="makeApiCall" />
          </div>
          <div class="col-12 col-xl-5">
            <!-- Only for desktop | Products Categories -->
            <conversion-sales-products-categories :make-api-call="makeApiCall" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-5">
            <!-- Dispersion -->
            <segments-frequency :make-api-call="makeApiCall" />
          </div>
          <div class="col-12 col-lg-7">
            <!-- Orders -->
            <conversion-sales-orders :make-api-call="makeApiCall" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xl-4">
            <!-- Only for desktop | Stores Brands -->
            <conversion-sales-identification :make-api-call="makeApiCall" />
          </div>
          <div class="col-sm-6 col-xl-8">
            <!-- Only for desktop | Stores Brands -->
            <conversion-sales-stores-brands :make-api-call="makeApiCall" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <!-- Retention Chart -->
          <conversion-sales-retention-chart :make-api-call="makeApiCall" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';

// @ is an alias to /src
import ConversionSalesEcommerceConversion from '@/components/conversionSales/ConversionSalesEcommerceConversion';
import ConversionSalesTotalRevenue from '@/components/conversionSales/ConversionSalesTotalRevenue';
import ConversionSalesOrders from '@/components/conversionSales/ConversionSalesOrders';
import ConversionSalesProductsCategories from '@/components/conversionSales/ConversionSalesProductsCategories';
import ConversionSalesIdentification from '@/components/conversionSales/ConversionSalesIdentification';
import ConversionSalesStoresBrands from '@/components/conversionSales/ConversionSalesStoresBrands';
import ConversionSalesRetentionChart from '@/components/conversionSales/ConversionSalesRetentionChart';
import SegmentsFrequency from '@/components/segments/groupsFrequency/AllSegmentGroupsFrequency';

export default {
  name: 'Conversionsales',
  components: {
    ConversionSalesEcommerceConversion,
    ConversionSalesTotalRevenue,
    ConversionSalesOrders,
    ConversionSalesProductsCategories,
    ConversionSalesIdentification,
    ConversionSalesStoresBrands,
    ConversionSalesRetentionChart,
    SegmentsFrequency,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      collapsed: false,
      id: 'conversionsales',
      makeApiCall: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    });
    bus.$on('keep-last-collapsed-open', id => {
      if (id !== this.id) this.collapsed = false;
    });
  },
  beforeUnmount() {
    bus.$off('keep-last-collapsed-open');
  },
  methods: {
    getCollapsed(id) {
      this.collapsed = !this.collapsed;
      if (this.collapsed) {
        bus.$emit('is-active-collapse', id);
        this.makeApiCall = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conversionsales-wrapper {
  .cs__collapsed {
    background: $color-white;
    margin-bottom: 10px;
    border-radius: 8px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    .cs__collapsed-border {
      margin-left: 25px;
      font-weight: 500;

      &.--gray {
        color: $gray-700;
      }

      &.--pink {
        color: $pink-500;
      }
    }

    .icon {
      color: $pink-500;
      margin-right: 45px;
      font-weight: bold;
    }
  }

  .cs__collapsed::after {
    content: '';
    background: $pink-500;
    height: 100%;
    width: 5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    left: 0;
  }
}
#conversionsales {
  #conversionsalestotalrevenue {
    min-height: calc(100% - 12px);

    &.loadHeight {
      height: 312px;
    }
  }
}
</style>
