<template>
  <div class="comparison-store-item">
    <h6 class="chart-name">{{ options.name }}</h6>
    <div class="row">
      <div class="col min-max">
        <span class="strong">{{ $_formatValue(options.min) }}</span>
      </div>
      <div class="col col-xl-auto">
        <div :id="chartID"></div>
      </div>
      <div class="col min-max text-right">
        <span class="strong">{{ $_formatValue(options.max) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  name: 'ComparisonStoreItem',
  props: {
    options: {
      type: Object,
      default() {
        return {
          selectedStore: -100,
          value: 0,
          name: '',
          data: [],
          min: 0,
          max: 100,
          radius: 5,
          type: '',
        };
      },
    },
    storeSelectedName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartID: `comparison-store-item-chart-${this.options.key}`,
    };
  },
  computed: {
    storesIds() {
      const ids = [];
      Object.keys(this.options.data).forEach(item => {
        if (item !== this.options.selectedStore) ids.push(item);
      });
      return ids;
    },
    storesNames() {
      const names = {};
      this.$parent.stores.forEach(store => {
        if (store.id !== '-100' && store.id !== this.options.selectedStore)
          Object.assign(names, { [store.id]: store.store });
      });
      return names;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.createChart();
    });
  },
  methods: {
    createChart() {
      const self = this;
      Highcharts.chart(this.chartID, {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          height: 60,
          inverted: false,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: '',
        },
        xAxis: {
          title: null,
          startOnTick: true,
          endOnTick: false,
          labels: { enabled: false },
          visible: false,
          min: this.options.min,
          max: this.options.max,
        },
        yAxis: {
          title: {
            text: '',
          },
          visible: true,
          labels: { enabled: false },
          min: this.options.radius,
          max: this.options.radius,
          endOnTick: false,
          gridLineColor: '#888',
        },
        plotOptions: {
          scatter: {
            marker: {
              radius: this.options.radius,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(211, 211, 211)',
                },
              },
            },
            states: {
              hover: {
                marker: {
                  enabled: false,
                },
              },
            },
          },
        },
        tooltip: {
          formatter: function formatter() {
            const id = self.storesIds[this.point.index];
            const storeName = this.series.name === 'selected' ? self.storeSelectedName : self.storeName(id);
            return `<span style="font-weight: 600">${storeName}</span><br>
              ${self.options.name}: <span style="font-weight: 600">${self.$_formatValue(this.point.x)}</span>`;
          },
          backgroundColor: '#FFFFFF',
          style: {
            color: '#555555',
            lineHeight: '20px',
          },
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        legend: { enabled: false },
        series: [
          {
            name: 'others',
            color: 'rgb(211, 211, 211)',
            data: this.formatData(this.options.data),
          },
          {
            name: 'selected',
            color: 'lightblue',
            marker: {
              radius: this.options.radius * 2,
              symbol: 'circle',
            },
            data: [[this.formatNumber(this.options.value), this.options.radius]],
            dataLabels: {
              enabled: true,
              y: -30,
              formatter() {
                return self.$_formatValue(this.x);
              },
              style: {
                color: '#666666',
                fontWeight: 600,
              },
            },
          },
        ],
      });
    },
    formatData(data) {
      const formatted = [];
      Object.entries(data).forEach(([prop, value]) => {
        // eslint-disable-next-line
        if (prop != this.options.selectedStore) {
          formatted.push([this.formatNumber(value), this.options.radius]);
        }
      });

      return formatted;
    },
    formatNumber(num) {
      if (typeof num === valueType.string) {
        return Number(num.replace(',', '.'));
      }
      return num;
    },
    $_formatValue(value) {
      if (this.options.type === valueType.percent) return formatPercent(value / 100, 2);
      else if (this.options.type === valueType.integer) return formatDecimal(value, 1);
      else if (this.options.type === valueType.float) return formatCurrency(value);
      return value;
    },
    storeName(id) {
      return this.storesNames[id];
    },
  },
};
</script>

<style lang="scss" scoped>
.min-max {
  font-size: 12px;
  padding-top: 17px;
  color: $gray-700;
}
.chart-name {
  font-size: 12px;
  color: $gray-700;
}
.strong {
  font-weight: 600;
}
</style>
<style lang="scss">
.comparison-store-item {
  svg {
    overflow: initial;
  }
}
</style>
