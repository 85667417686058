<template>
  <lightbox-template :id="id" :title="$t('tos.title')" :show-segment-group="false" :show-date="false">
    <div class="l-tos__horizontal col-12">
      <p class="l-tos__text">
        {{ $t('tos.heading') }}
      </p>
      <p class="l-tos__text">
        <a href="https://suporte.otocrm.com.br/pt/blog/termos-e-condicoes-de-uso" target="_blank">
          {{ $t('tos.link') }}
        </a>
      </p>
    </div>
    <div class="l-tos__footer mt-3">
      <div class="l-tos__checkbox">
        <check-box :checked="isOptin" :click-option="toggleOptin" :label="$t('tos.checkbox')" />
      </div>
      <div class="l-tos__cta">
        <button class="btn l-tos__submit" @click="acceptOrClose">
          {{ $t('continue') }}
        </button>
      </div>
    </div>
  </lightbox-template>
</template>

<script>
// libs
import { mapActions } from 'vuex';
// helpers
import { closeLightbox } from '@/helpers/lightbox';
// components
import CheckBox from '@/components/_atoms/CheckBox';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';

export default {
  name: 'LightboxTermsOfUse',
  components: {
    CheckBox,
    LightboxTemplate,
  },
  data() {
    return {
      id: 'lightbox-terms-of-service',
      isOptin: false,
    };
  },
  methods: {
    ...mapActions(['acceptTermsOfService']),

    /**
     * @description toggle optin status
     */
    toggleOptin() {
      this.isOptin = !this.isOptin;
    },
    /**
     * @description submit TOS consent
     */
    acceptOrClose() {
      if (this.isOptin) {
        this.acceptTermsOfService();
      }
      closeLightbox();
    },
  },
};
</script>

<style lang="scss" scoped>
#lightbox-terms-of-service {
  .l-tos__text {
    padding: 0 20px;
  }

  .l-tos__horizontal {
    display: flex;
    flex-direction: column;
  }

  .l-tos__footer {
    border-top: 1px solid $gray-400;
    padding: 25px;
    display: flex;

    .l-tos__checkbox {
      display: flex;
      align-items: center;
      width: 300px;
    }

    .l-tos__cta {
      margin-left: auto;

      button {
        background-color: $oto-seller;
        width: 170px;
      }
    }
  }
}
</style>

<style lang="scss">
#lightbox-terms-of-service {
  .container {
    width: 565px;
  }
  .lightbox-header {
    padding: 25px;
  }
  .lightbox-body {
    padding: 0;
  }
}
</style>
