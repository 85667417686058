<template>
  <div id="comparison-metrics" class="mt-3">
    <label>{{ $t('360.sellers-analytics.comparable.metrics') }}</label>
    <VTooltip :shown="showAlertTooltip" :triggers="[]" placement="right" theme="error">
      <div id="selectMetrics" class="select-fake metrics no-click" @click.prevent="toggleDropdown('metricsOpened')">
        {{ metricsSelected || $t('select') }}
      </div>
      <template #popper>
        <div class="comparison-metrics-tooltip">
          {{ $t('comparison-metrics-error') }}
        </div>
      </template>
    </VTooltip>
    <menu-multiselect :items="metrics" :filters="filters.metrics" :opened="metricsOpened" />
  </div>
</template>
<script>
import MenuMultiselect from '@/components/menuMultiselect/MenuMultiselect';
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'ComparisonMetrics',
  components: {
    MenuMultiselect,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    action: {
      type: Function,
      default: null,
    },
    filters: {
      type: Object,
      required: true,
    },
    metrics: {
      type: Array,
      default: () => [],
    },
    showAlertTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      metricsOpened: false,
    };
  },
  computed: {
    metricsSelected() {
      const selectedMetrics = this.metrics
        .filter(item => this.filters.metrics.includes(item.key))
        .map(item => item.name);
      const selectedMetricsString = selectedMetrics.join(', ');
      return selectedMetricsString.length > 21 ? `${selectedMetricsString.slice(0, 21)}...` : selectedMetricsString;
    },
  },
  mounted() {
    this.bodyClick();
    this.action(this.filters);
  },
  methods: {
    toggleDropdown(current) {
      this[current] = !this[current];

      this.name ?? useMixpanelConfig(`view_oto_${this.name}_comparable-filters_metrics-opened`);
    },

    bodyClick() {
      document.querySelector('body').addEventListener('click', event => {
        const className = event.target?.className;
        if (typeof className === 'string' && !className.includes('no-click')) {
          this.metricsOpened = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#comparison-metrics {
  .select-fake {
    padding-right: 25px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    z-index: 2;
  }

  .comparison-metrics-tooltip {
    max-width: 170px;
  }
}
</style>
