<template>
  <section class="sellers">
    <tab-nav :tabs="selectItems" :selected="getSelectedTab.id" :loading="loadingTab" @selected="setSelected">
      <template v-slot:title>
        <h1 class="sellers__title">{{ $tc('360.sellers.header-title') }}</h1>
        <alert
          v-if="hasSellersWarning && enabledSellersWarning"
          type="warning"
          :message="$tc('360.sellers.warning.alert')"
          class="sellers__alert"
        />
        <div class="sellers__loading" v-if="loadingTab">
          <base-loader />
        </div>
      </template>

      <tab :isSelected="selectedTab('tab-all-sellers')">
        <sellers-table />
      </tab>
      <tab v-if="enabledSellersWarning" :isSelected="selectedTab('tab-warning-sellers')">
        <warning-sellers-table />
      </tab>
    </tab-nav>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
// libs
import { useToast } from 'vue-toastification';
// helpers
import bus from '@/helpers/events/bus';
import { tableMixin, verifyMixin } from '@/helpers/mixins';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
// store
import store from '@/store';
import TabNav from '@/components/_atoms/TabNav';
import Tab from '@/components/_atoms/Tab';
import SellersTable from '@/views/oto/SellersTable';
import WarningSellersTable from '@/views/oto/WarningSellersTable';
import Alert from '@/components/_atoms/Alert';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'Sellers',
  components: {
    TabNav,
    Tab,
    SellersTable,
    WarningSellersTable,
    Alert,
    BaseLoader,
  },
  mixins: [tableMixin, verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      id: 'sellers-list',
      order: null,
      isActive: '',
      sellersList: [],
      hasSellersWarning: null,
      selectItems: [
        {
          id: 'tab-all-sellers',
          name: this.$t('360.sellers.title'),
        },
      ],
      items: {
        'tab-all-sellers': 'tab-all-sellers',
      },
    };
  },
  computed: {
    ...mapGetters('sellers', ['getSelectedTab']),
    ...mapState('sellers', ['loadingTab']),
    enabledSellersWarning() {
      return store?.getters?.getEnabledSellersWarning;
    },
  },

  mounted() {
    useMixpanelConfig('view_oto_sellers');

    this.setSelectedTab({
      id: 'tab-all-sellers',
      name: this.$t('360.sellers.title'),
    });

    bus.$on('has-sellers-warning', value => {
      this.setLoadingTab(true);
      this.hasSellersWarning = value;
      this.setSelected(this.getSelectedTab);
      this.setLoadingTab(false);
    });

    if (this.$route.params.sellerId) {
      bus.$emit('seller-detail-modal');
    }
  },
  beforeUnmount() {
    this.setLoadingTab(true);
    bus.$off('render-cards');
    bus.$off('sellers-reload-list');
    bus.$off(`${this.id}-change-page`);
    bus.$off('has-sellers-warning');
  },
  methods: {
    ...mapActions('sellers', ['setSelectedTab', 'setLoadingTab']),
    setSelected(tab) {
      const isActive = Object.keys(this.items).includes('tab-warning-sellers');

      if (this.enabledSellersWarning && !isActive) {
        this.items = { ...this.items, 'tab-warning-sellers': 'tab-warning-sellers' };

        const warningSellers = {
          id: 'tab-warning-sellers',
          name: this.$t('360.sellers.warning.title'),
        };

        if (this.hasSellersWarning) warningSellers.icon = 'alert';
        this.selectItems.push(warningSellers);
      }
      this.setSelectedTab(tab);
    },

    selectedTab(selected) {
      return this.getSelectedTab.id === this.items[selected];
    },
  },
};
</script>

<style lang="scss">
.sellers {
  @import '@/assets/scss/vendors/_v-select';

  .card-tabs {
    padding: 7px 20px 0 20px !important;

    .nav-link {
      margin-right: 24px !important;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  &__alert {
    margin-bottom: 24px;
  }

  &__loading {
    padding: 25px 0;
  }

  .spinner {
    height: 40px;
  }
}
</style>
