<template>
  <div class="header-search">
    <div class="form-wrapper">
      <input
        ref="searchInput"
        v-model="textSearch"
        v-focus
        type="text"
        class="input-search"
        :placeholder="searchablePlaceholder"
        :class="{ 'has-error': hasError }"
        @input="isTyping = true"
        @keypress.enter="searchUser(textSearch)"
      />
      <i class="icon icon-search" @click="iconSearch"></i>
    </div>

    <div v-if="showSuggestions" class="results-wrapper">
      <div v-if="!loading && !hasError && results.length" class="results">
        <div class="scroll-bar">
          <div v-for="(customer, index) in results" :key="index" @click="openProfile(customer)">
            <div class="row item-search align-items-center">
              <div class="col align-items-center justify-content-start">
                <span class="name d-block" v-html="splitSearch(customer.name)"></span>
                <span v-if="customer.email" v-html="splitSearch(customer.email)"></span>
              </div>
              <div class="col-auto align-items-center">
                <segment-bubble :segment-i-d="customer.segment_id" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="loading" class="searching">
        <load-spinner :show-spinner="loading" />
      </div>
    </div>
  </div>
</template>

<script>
// libs
import _ from 'lodash';
import { mapGetters } from 'vuex';
// helpers
import bus from '@/helpers/events/bus';
// services
import { searchCustomer } from '@/services/oto/customers';
// components
import SegmentBubble from '@/components/_atoms/SegmentBubble';
import LoadSpinner from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'CustomerSearchProfiles',
  components: {
    SegmentBubble,
    LoadSpinner,
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  data() {
    return {
      hasError: false,
      isTyping: false,
      loading: false,
      results: [],
      showSuggestions: false,
      textSearch: '',
      searchablePlaceholder: '',
      isExactDocument: false,
    };
  },
  computed: {
    ...mapGetters(['getSearchableList']),
  },
  watch: {
    textSearch: _.debounce(function TS() {
      this.isTyping = false;
    }, 500),
    isTyping: function isT(value) {
      if (!value) {
        this.search();
      }
    },
  },
  mounted() {
    this.searchBy();
    bus.$on('clear-search', () => {
      this.results = [];
      this.textSearch = '';
    });

    bus.$on('render-cards', () => {
      this.searchBy();
    });

    // bus.$on("refresh-search", () => {
    //   this.searchUser(this.textSearch);
    // });

    document.body.addEventListener('click', e => {
      // hide suggestions after click outside the component
      if (this.showSuggestions && e.target.classList.value !== 'inputTextSearch') {
        this.showSuggestions = false;
      }
    });
  },
  beforeUnmount() {
    bus.$off('refresh-search');
  },
  methods: {
    search() {
      this.loading = true;
      this.showSuggestions = true;
      searchCustomer(this.textSearch)
        .then(res => {
          this.results = res;
          this.hasError = '';
        })
        .catch(() => {
          this.results = [];
          this.hasError = true;
          this.showSuggestions = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchUser(term) {
      this.$router.replace({
        name: '360-search',
        query: {
          t: term,
        },
      });
      this.showSuggestions = false;
      this.textSearch = '';
    },
    openProfile(item) {
      this.$router.push({
        name: '360-customerviewId',
        params: {
          id: item.id,
          brand: this.$store.getters.selectedBrand.id,
        },
      });
      bus.$emit('clear-search', '');
    },
    splitSearch(el) {
      let result = el;

      if (result && result.includes(this.textSearch)) {
        const split = result.split(this.textSearch);
        let mapped = [];
        if (split && split.length) {
          mapped = split.map(item => (item !== '' ? `<b>${item}</b>` : item));
          result = mapped.join(this.textSearch);
        }
      }
      return result;
    },
    iconSearch() {
      if (this.textSearch !== undefined) {
        this.searchUser(this.textSearch);
      } else {
        this.$refs.searchInput.focus();
      }
    },
    clearSearch() {
      bus.$emit('clear-search');
    },
    searchBy() {
      const searchable = [];
      this.getSearchableList?.map(item => {
        searchable.push(this.$t(`360.search.${item}`));
      });

      this.searchablePlaceholder = `${this.$t('360.search.placeholder')} ${searchable
        .toString()
        .replaceAll(',', ', ')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-search {
  position: relative;
  width: 450px;
  z-index: 20;

  .form-wrapper {
    position: relative;
    z-index: 1;

    .input-search {
      width: 100%;
      height: 44px;
    }
    .icon-search {
      color: $gray-500;
      position: absolute;
      top: 6px;
      right: 8px;
      cursor: pointer;
    }
  }
}

.title {
  color: $gray-800;
  font-weight: 600;
  padding: 13px 16px;
}
p {
  margin-bottom: 0;
}

.results-wrapper {
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #ccc;
  color: $gray-600;
  left: 0;
  padding: 46px 0 0;
  position: absolute;
  top: 3px;
  width: 100%;

  p,
  .icon {
    color: $gray-600;
  }

  span {
    font-size: 11px;
  }

  p,
  .name {
    font-size: 14px;
    line-height: 1em;
  }

  &:before {
    content: '';
    border-color: transparent transparent $color-white transparent;
    border-style: solid;
    border-width: 6px;
    position: absolute;
    top: -12px;
    right: 30px;
  }
}

.item-search {
  cursor: pointer;
  border-bottom: 1px solid $gray-300;
  padding: 12px 11px;
  margin: 0;
}

.searching {
  height: 80px;
  position: relative;
}

.advanced-search {
  padding: 12px 17px;
  cursor: pointer;

  &:hover {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &.empty:hover {
    border-radius: 8px;
  }
}

.item-search,
.advanced-search {
  &:hover {
    background-color: #f0fcff;
  }
}

.scroll-bar {
  max-height: 480px;
  overflow-y: auto;
}
</style>
