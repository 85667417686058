<template>
  <div class="stores-revenue card">
    <div v-show="$_verifyLoaded('done')" class="card-body">
      <h5 class="card-title customTitle">{{ $t('tracked-revenue.title') }}</h5>
      <div v-show="hasData" id="chartStoresRevenue"></div>
      <empty-message v-if="!hasData" />
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import moment from 'moment';
import Highcharts from 'highcharts';
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import EmptyMessage from '@/components/_atoms/EmptyMessage';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import { dateFormat } from '@/utilities/constants';
import { formatCurrency, formatNumber } from '@/utilities/formatters';

export default {
  name: 'StoresRevenue',
  components: {
    'empty-message': EmptyMessage,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      selectedStore: '',
      revenue: [],
      hasData: false,
    };
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
    bus.$on('store-changed', value => {
      this.selectedStore = value;
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('store-changed');
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        endpoint: 'stores/get_metrics',
        metrics: 'revenue_by_medium',
        filters: `store_id:${this.selectedStore}`,
        expand: 1,
      })
        .then(response => {
          if (this.$_verifyData(response.data.data.metrics)) return;

          const { data } = response.data;
          this.revenue = data.metrics;
          this.hasData = !!this.revenue;
          const { labels } = data;

          // trata os labels para converter para texto do Mes/Ano
          const lablesTratados = labels.map(formatLabel =>
            moment(formatLabel, 'YYYY-M')
              .format('MMM/YY')
              .replace(/\b\w/g, l => l.toUpperCase()),
          );

          // tratamento para a seleção do gráfico
          const dateStart = moment(this.$store.getters.dates.startDate, dateFormat);
          const dateEnd = moment(this.$store.getters.dates.endDate, dateFormat);
          const numberMonthsSelect = dateEnd.diff(dateStart, 'month');

          if (this.revenue) {
            this.loadChartRevenue(
              this.revenue,
              lablesTratados,
              lablesTratados.length - 1,
              lablesTratados.length - 1 - numberMonthsSelect - 0.5,
            );
          }
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadChartRevenue(revenue, labels, minSelect, maxSelect) {
      Highcharts.chart('chartStoresRevenue', {
        chart: {
          height: 300,
          type: 'spline',
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: '',
        },
        subtitle: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 35,
          itemDistance: 15,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
            paddingBottom: 20,
          },
        },
        colors: ['#FD639F', '#FA6F32', '#9EB4E1', '#840303', '#EEDA36', '#1E8E15', '#39B69A', '#FF222C', '#5DFFB8'],
        xAxis: [
          {
            gridLineWidth: 0,
            categories: labels,
            crosshair: true,
            plotBands: [
              {
                from: minSelect,
                to: maxSelect,
                color: 'rgba(68, 170, 213, .2)',
              },
            ],
          },
        ],
        yAxis: [
          {
            title: {
              text: '',
              style: {
                color: '#888',
              },
            },
            labels: {
              formatter: label => {
                if (label.value > 1000 && label.value <= 999999) return `$ ${label.value / 1000}k`;
                else if (label.value >= 1000000) return `$ ${label.value / 1000000}M`;
                return label.value;
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            let s = `<b>${this.x}</b>`;

            this.points.forEach(points => {
              s += `<br/><span style="color:${points.color}">\u25CF</span> ${points.series.name}: <b>${
                points.series.name.includes('%') ? formatNumber(points.y, '0%') : formatCurrency(points.y)
              }</b>`;
            });
            return s;
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
        },
        credits: {
          enabled: false,
        },
        series: Object.keys(revenue).map(name => ({
          name,
          data: revenue[name],
        })),
      });
    },
  },
};
</script>

<style lang="scss">
.stores-revenue {
  height: calc(100% - 12px);
  .highcharts-legend-item {
    text-transform: capitalize;
  }
}
</style>
