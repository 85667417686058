import store from '@/store';

export const supportFreshdesk = (permissionsSupport) => {
  const name = store?.getters?.user?.name;
  const email = store?.getters?.user?.email;
  if (!window?.FreshworksWidget) return
  
  window.FreshworksWidget('identify', 'ticketForm', {
    name: name,
    email: email,
  }, {
    formId: 150000002098,
  });
  
  const widget = permissionsSupport ? window.FreshworksWidget('show') : window.FreshworksWidget('hide');
  
  return widget;
}
