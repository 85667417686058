<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body>
    <template v-if="$_verifyLoaded('done')">
      <div class="cashback-modal-content container">
        <base-card-header :title="$t('360.customers.cashback.historic.title')" />
        <div class="cmc__main-content customScrollBar">
          <div v-for="(historic, index) in listHistoric" :key="index" class="cmc__wrapper-content">
            <div class="cmc__packer-content">
              <div :class="['cmc__content-circle', historic.type === 'credit' ? 'credit-bg' : 'debit-bg']"></div>
              <div class="cmc__content-line"></div>
            </div>
            <div class="cmc__text-content">
              <p :class="['cmc__content-title', historic.type === 'credit' ? 'credit-color' : 'debit-color']">
                {{ formatTextType(historic.type) }}
              </p>
              <p class="cmc__content-subtitle">
                {{ formatCurrency(historic.value) }}<span> - {{ formatDate(historic.date) }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cashback-modal-footer">
        <button class="btn" @click.prevent="closeModal">
          {{ $t('360.customers.cashback.button.continue') }}
        </button>
      </div>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </el-dialog>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
import moment from 'moment';
import { getHistoric } from '@/services/oto/cashback';
import { verifyMixin } from '@/helpers/mixins';
import { formatNumber } from '@/utilities/formatters';
import { dateCompleteVariation } from '@/utilities/constants';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'CashbackHistoricModal',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    customerId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      id: 'cashback-historic-modal',
      listHistoric: [],
      isVisible: false,
    };
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
      this.fetchListHistoric();
    });
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    fetchListHistoric() {
      this.$_reqConfig();

      getHistoric({ customerId: this.customerId, limit: 50 })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;
          this.listHistoric = data.data;
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    formatTextType(text) {
      if (text === 'credit') {
        return this.$t('360.customers.cashback.historic.list.title-cashback-accumulation');
      } else {
        return this.$t('360.customers.cashback.historic.list.title-cashback-use');
      }
    },

    formatValues(val, format) {
      return formatNumber(val, format);
    },
    formatDate(date) {
      return moment(date).format(dateCompleteVariation);
    },
  },
};
</script>

<style lang="scss" scoped>
#cashback-historic-modal {
  .container {
    padding: 0 30px;
    margin-top: -15px;

    .cmc__main-content {
      height: 200px;
      overflow: auto;

      p {
        font-size: 12px;
        margin-bottom: 0;
        text-align: left;
      }

      .cmc__wrapper-content {
        display: flex;

        .cmc__packer-content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .cmc__content-circle {
            width: 15px;
            height: 15px;
            border-radius: 50%;

            &.credit-bg {
              background: $blue-800;
            }

            &.debit-bg {
              background: $blue-500;
            }
          }

          .cmc__content-line {
            height: 35px;
            width: 2px;
            background: $gray-500;
            margin: 3px 0;
          }
        }

        .cmc__text-content {
          margin-left: 5px;

          .cmc__content-title {
            color: $blue-500;
            font-weight: 600;
            margin-top: -2px;

            &.credit-color {
              color: $blue-800;
            }

            &.debit-color {
              color: $blue-500;
            }
          }

          .cmc__content-subtitle {
            font-weight: bold;

            span {
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  .cashback-modal-footer {
    border-top: 1px solid #f1f1f1;
    padding: 20px 0 0;
    text-align: center;

    .btn {
      background: $oto-seller;
      display: inline-block;
      width: 260px;
    }
  }
}
</style>

<style lang="scss">
#cashback-historic-modal {
  height: 360px;
  width: 330px;

  .el-dialog__header {
    padding: 28px 30px 0px;
  }

  .el-dialog__body {
    padding: unset;
  }
}
</style>
