import { RawTableParamTypes, RawTableTypes, RawAvailableColumnsTypes, RawAvailableTables, TableType } from '../types';
import { getRawData, getRawDataAvailableTables, getAvailableColumns } from '@/services/rawData';

// fetch and format main table
export const fetchDataTable = ({
  table,
  orderBy,
  page,
  limit,
  filters,
  fileType,
  responseType,
}: RawTableParamTypes): Promise<RawTableTypes> =>
  getRawData({ table, orderBy, page, limit, filters, fileType, responseType }).then(response =>
    formatRawTableResponse(response?.data),
  );

const formatRawTableResponse = (response: RawTableTypes): RawTableTypes => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  export: response,
  data: response?.data,
  errors: response?.errors,
  series: response?.series,
  success: response?.success,
  totalCount: response?.totalCount,
  totalTable: response?.totalTable,
});

// fetch format available table
export const fetchAvailableTables = (): Promise<RawAvailableTables> =>
  getRawDataAvailableTables().then(response => formatAvailableTables(response?.data));

const formatAvailableTables = (response: RawAvailableTables): RawAvailableTables => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});

// fetch format available columns
export const fetchAvailableColumns = ({ table }: TableType): Promise<RawAvailableColumnsTypes> =>
  getAvailableColumns({ table }).then(response => formatAvailableColums(response?.data));

const formatAvailableColums = (response: RawAvailableColumnsTypes): RawAvailableColumnsTypes => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});
