<template>
  <div>
    <skeleton-loader
      v-if="isSkeletonDefaultValid"
      :class="{ 'ml-4': isSpaceActive }"
      class="mt-1 mb-4 d-block"
      height="17px"
      width="200px"
    ></skeleton-loader>
    <skeleton-loader v-if="isSkeletonTopValid" class="mt-1 mb-2 d-block" height="17px" width="95px"></skeleton-loader>
    <skeleton-loader v-if="isSkeletonTopValid" class="mb-3 d-block" height="17px" width="277px"></skeleton-loader>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    hasItemTopSlot: {
      type: Boolean,
      default: null,
    },
    ready: {
      type: Boolean,
      default: null,
    },
    isSpaceActive: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    /**
     * Skeleton to replace h2
     */
    isSkeletonDefaultValid() {
      return !this.hasItemTopSlot && !this.ready;
    },
    /**
     * Skeleton with label on the top of h2
     */
    isSkeletonTopValid() {
      return this.hasItemTopSlot && !this.ready;
    },
  },
};
</script>
