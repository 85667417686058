<template>
  <div id="layout">
    <el-config-provider :locale="datepickerLanguage">
      <experimental-bar />
      <main-header />
      <!-- Todo: Add route validation to a utility when applying it to more pages. -->
      <main class="container" :class="{ 'responsive-container': getRoute === 'campaign-new' }">
        <router-view></router-view>
      </main>
      <footer-content />
    </el-config-provider>
  </div>
</template>

<script>
import moment from 'moment';
import FooterContent from '@/components/FooterContent';
import MainHeader from '@/components/menu/TheHeader';
import ExperimentalBar from '@/components/menu/TheExperimentalBar';
import { datepickerLanguage } from '@/helpers/setup-datepicker';

export default {
  name: 'Layout',
  components: {
    MainHeader,
    ExperimentalBar,
    FooterContent,
  },
  methods: {
    openPriorityEditConfirmationModalAction(next) {
      if (this.isDataActionsUnchanged) {
        next();
      } else {
        this.$store.dispatch('campaignsManagement/setOpenConfirmEditPriorityModal', true);
        next(false);
      }
    },
  },
  computed: {
    datepickerLanguage() {
      return datepickerLanguage(this.$store?.getters?.user?.language);
    },
    getRoute() {
      return this.$route.name;
    },
    isDataActionsUnchanged() {
      return this.$store.state.campaignsManagement.isDataUnchanged;
    },
  },
  created() {
    const lang = this.$store.getters.user?.language;
    this.$i18n.locale = lang;
    moment.locale(lang);
  },

  beforeRouteUpdate(to, from, next) {
    if (from.name === 'campaign-new') {
      this.openPriorityEditConfirmationModalAction(next);
    } else {
      next();
    }
  },

  beforeRouteLeave(to, from, next) {
    if (from.name === 'campaign-new') {
      this.openPriorityEditConfirmationModalAction(next);
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 30px;
  height: 100%;
  min-height: 100vh;
}

.responsive-container {
  margin-right: 0;
  margin-left: 98px;
  max-width: 2416px;
  width: calc(100% - 120px);

  @media (min-width: 1236px) and (max-width: 2416px) {
    min-width: 1136px;
    width: calc(100% - 130px);
  }

  @media (min-width: 2417px) {
    max-width: 2416px;
    width: calc(100% - 300px);
    margin: auto;
  }
}
</style>
