<template>
  <div id="dialog-error">
    <i class="icon icon-error-60px color-red"></i>
    <p v-if="message" class="dialog__message" v-html="$t(message)"></p>
    <button class="dialog__confirm btn" @click="confirm()">
      {{ $tc('ok', 2) }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'DialogError',
  props: {
    message: {
      type: String,
      default: 'error-message-modal',
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    confirm() {
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
#dialog-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    font-size: 60px;
    margin-bottom: 20px;
  }

  .dialog__message {
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
    margin-bottom: 20px;
  }

  .dialog__confirm {
    width: 160px;
  }
}
</style>
