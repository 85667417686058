<template>
  <base-card-v2 class="push-create__selector">
    <div class="row">
      <div class="col-3">
        <div class="seller-select">
          <label>{{ $t('360.push.audience.select-audience') }}</label>
          <v-select
            v-model="actionType"
            label="name"
            :clearable="false"
            :options="audiences"
            :loading="loading"
            :placeholder="$t('360.sellers.placeholder')"
            :disabled="isViewMode"
            @option:selected="changeAudience"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('no-results') }}
            </template>
          </v-select>
        </div>
      </div>

      <div class="d-flex">
        <span class="vr mx-40"></span>
      </div>

      <div class="pc__audience-stats col-3">
        <div class="pc__sellers-block col">
          <p class="m-0">
            <span class="pc__total">{{ actionType ? actionType.sellers : '-' }}</span>
            {{ $tc('users', 2) }}
          </p>
        </div>
      </div>
    </div>
  </base-card-v2>
</template>

<script>
// libs
import { mapState, mapActions } from 'vuex';
import vSelect from 'vue-select';
// services
import { getAudiences } from '@/services/oto/push';

export default {
  name: 'PushAudienceSelector',
  components: {
    vSelect,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      term: '',
      actionType: '',
      audiences: [],
      audiencesTotal: {},
      loading: true,
    };
  },
  computed: {
    ...mapState('pushNotifications', ['audienceId']),

    resolveAudienceSelector() {
      return this.audiences.filter(el => el.id === this.audienceId);
    },

    isViewMode() {
      return this.mode === 'view';
    },
  },
  watch: {
    resolveAudienceSelector() {
      this.updateActionType();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions('pushNotifications', ['setPushAudienceId']),

    fetchData() {
      this.loading = true;
      getAudiences()
        .then(({ data }) => {
          this.audiences = data?.data;
          this.audiencesTotal = data?.totalResults || {};
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateActionType() {
      this.actionType = this.resolveAudienceSelector[0];
    },

    changeAudience() {
      this.setPushAudienceId(this.actionType?.id);
    },
  },
};
</script>

<style lang="scss">
.push-create__selector {
  .pc__audience-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      color: $gray-600;
    }

    span.pc__total {
      color: $oto-omni;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .pc__helper {
    color: $gray-600;
    font-size: 12px;
    padding-top: 12px;
  }
}
</style>

<style lang="scss">
.push-create__selector {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
