<template>
  <div id="preferences" class="preferences">
    <h1 class="card-group-title">{{ $t('settings.preferences.title') }}</h1>
    <div class="card preferences__card">
      <div class="card-body preferences__card-body">
        <div v-if="isContext" class="row">
          <div class="col-4">
            <h2 class="preferences__card-title">
              {{ $t('settings.preferences.experimental.title') }}
            </h2>
            <p>{{ $t('settings.preferences.experimental.subtitle') }}</p>
          </div>
          <div class="col-8">
            <button
              class="btn preferences__btn-experimental-mode"
              :class="{ disable: experimental }"
              @click.prevent="updateExperimental"
            >
              {{ experimental ? $t('settings.disable') : $t('settings.enable') }}
            </button>
          </div>
        </div>
        <div class="row">
          <h2 class="col-4 preferences__card-title">
            <span>{{ $t('settings.preferences.languages.title') }}</span>
          </h2>
          <div class="col-8">
            <div class="value-label preferences__value-label">
              {{ $t('settings.preferences.languages.select-language') }}
            </div>
            <select id="language-select" v-model="selectedLanguage" class="filter-select" name="language-select">
              <option value="pt-BR">
                {{ $t('settings.preferences.languages.options.pt') }}
              </option>
              <option value="en">
                {{ $t('settings.preferences.languages.options.en') }}
              </option>
              <option value="es">
                {{ $t('settings.preferences.languages.options.es') }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <h2 class="col-4 card-title pr-3">
            <span>{{ $t('settings.preferences.audiences-exportation.title') }}</span>
          </h2>
          <div class="col-8 row">
            <div class="preferences__checkboxes">
              <div v-for="(value, key) in checkboxes" :key="key" class="preferences__checkbox">
                <label :title="key" @click.prevent="$_toggleSelection(key)" class="preferences__checkbox--label">
                  <check-box :checked="$_isSelected(key)" />
                  <div>
                    <span class="preferences__checkbox--name">{{ value }}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from '@/components/_atoms/CheckBox';
// libs
import bus from '@/helpers/events/bus';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'Preferences',
  components: {
    CheckBox,
  },
  data() {
    return {
      experimental: localStorage.getItem('experimental') === 'true',
      selectedLanguage: this.$store.getters.user.language,
      audienceExportation: {
        invalidEmail: this.$store.getters.expInvalidEmail,
        invalidCellphone: this.$store.getters.expInvalidCellphone,
        invalidPhone: this.$store.getters.expInvalidPhone,
      },
      checkboxes: {
        invalidEmail: this.$t('settings.preferences.audiences-exportation.options.invalid-emails'),
        invalidCellphone: this.$t('settings.preferences.audiences-exportation.options.invalid-cellphones'),
        invalidPhone: this.$t('settings.preferences.audiences-exportation.options.invalid-phones'),
      },
    };
  },
  computed: {
    isContext() {
      return this.$store.getters.user?.profile === 1;
    },
  },
  watch: {
    selectedLanguage() {
      return this.$store.dispatch('changeLanguage', this.selectedLanguage);
    },
  },
  mounted() {
    useMixpanelConfig('view_settings_my_preferences');
  },
  methods: {
    changeCheckbox(name) {
      if (name.length > 0) {
        switch (name) {
          case 'invalidEmail':
            this.$store.dispatch('changeExpInvalidEmail', this.audienceExportation.invalidEmail);
            break;
          case 'invalidCellphone':
            this.$store.dispatch('changeExpInvalidCellphone', this.audienceExportation.invalidCellphone);
            break;
          case 'invalidPhone':
            this.$store.dispatch('changeExpInvalidPhone', this.audienceExportation.invalidPhone);
            break;
          default:
            break;
        }
      }
    },
    $_isSelected(key) {
      return +this.audienceExportation[key];
    },
    $_toggleSelection(key) {
      if (this.$_isSelected(key)) this.audienceExportation[key] = 0;
      else this.audienceExportation[key] = 1;

      this.changeCheckbox(key);
    },
    updateExperimental() {
      this.experimental = !this.experimental;
      bus.$emit('update-experimental-mode', this.experimental);
    },
  },
};
</script>

<style lang="scss" scoped>
.preferences {
  color: $gray-800;

  &__card {
    padding: 50px;

    &-body {
      > .row {
        padding: 40px 0;
        border-bottom: 1px solid $gray-400;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border: 0;
        }
      }
    }
  }

  &__value-label {
    margin-bottom: 10px;
    display: block;
  }

  select,
  input:not([type='checkbox']):not([type='radio']) {
    width: 274px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__checkbox {
    margin-bottom: 10px;

    &:hover {
      .check-box:not(.checked) {
        border-color: $gray-600;
      }
    }

    &--label {
      display: grid;
      align-items: center;
      user-select: none;
      cursor: pointer;
      margin: 0;
      grid-template-columns: 16px 1fr;
    }

    &--name {
      display: block;
      margin-left: 10px;
      color: $gray-700;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
    }
  }

  &__btn-experimental-mode {
    width: 200px;
    &.disable {
      background-color: $gray-500;
      &:hover {
        background-color: $gray-800;
      }
    }
  }
}
</style>
