<template>
  <div>
    <div class="row">
      <div class="col-2 mb-2">
        <skeleton-loader class="mt-1 d-block" height="18px" width="100px"></skeleton-loader>
      </div>
      <div class="col-1">
        <skeleton-loader class="mt-1 d-block" height="15px" width="80px"></skeleton-loader>
        <skeleton-loader class="mt-2 d-block" height="11px" width="70px"></skeleton-loader>
      </div>
      <div class="col-2 mb-2">
        <skeleton-loader class="mt-1 d-block ml-5" height="15px" width="100px"></skeleton-loader>
        <skeleton-loader class="mt-2 d-block ml-5" height="11px" width="85px"></skeleton-loader>
      </div>
      <div class="col-1">
        <skeleton-loader class="mt-1 d-block" height="15px" width="80px"></skeleton-loader>
        <skeleton-loader class="mt-2 d-block" height="16px" width="70px"></skeleton-loader>
      </div>
      <div class="col-2 mb-2">
        <skeleton-loader class="mt-1 d-block ml-5" height="15px" width="100px"></skeleton-loader>
        <skeleton-loader class="mt-2 d-block ml-5" height="11px" width="85px"></skeleton-loader>
      </div>
      <div class="col-2 mb-2">
        <skeleton-loader class="mt-1 d-block ml-4" height="15px" width="100px"></skeleton-loader>
        <skeleton-loader class="mt-2 d-block ml-4" height="11px" width="85px"></skeleton-loader>
      </div>
      <div class="col-2 mb-2">
        <skeleton-loader class="mt-1 d-block" height="15px" width="100px"></skeleton-loader>
        <skeleton-loader class="mt-2 d-block" height="11px" width="85px"></skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
