import moment from 'moment';
import _ from 'lodash';
import store from '@/store';

/**
 * @description apply readable date format
 * @param {Date} date
 * @param {String} format
 */
export const humanizeDate = (date, format = 'YYYY-MM-DD HH:mm') => {
  let dateTime = date;
  // check if date is valid
  if (moment(date).isValid()) {
    const diff = moment(date).diff(moment());
    // if date is in the past
    if (diff <= 0) {
      const minutes = Math.floor(diff / 60000);
      // humanize example: a hour ago
      dateTime = moment.duration(minutes, 'minutes').humanize(true);
    } else dateTime = moment(date).format(format);
  }
  return dateTime || '---';
};

/**
 * @description generic date formatter
 * @param {String} val input value to be changed
 * @param {String} format moment.js format string
 */
export const formatDate = (val, format = 'DD MMM YYYY') => {
  return moment(val).format(format);
};

// datetime
export const allDatesValid = (...dates) => {
  let valid = true;
  _.each(dates, date => {
    if (valid === true) {
      valid = moment(date, 'DD/MM/YYYY').isValid();
    }
  });
  return valid;
};

export const formatLongDate = date => {
  const language = store.getters?.user?.language;
  const [year, month, day] = date.split('-').map(Number);
  const formattedDate = new Intl.DateTimeFormat(language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(year, month - 1, day));

  return formattedDate;
};
