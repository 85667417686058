import axios from 'axios';
import store from '@/store';
import moment from 'moment';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'persona';

/**
 * @description get all personas
 * @returns {Object}
 */
export const getPersonas = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios.get(`${URI}/list`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate: startDate,
      endDate: endDate,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description delete (disable) persona with given id
 * @param {Number} personaId
 */
export const deletePersona = personaId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    `${URI}/delete`,
    {
      brand: brand.id,
      personaId,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @description update persona
 * @param {Number=} category_id
 * @param {String=} name
 * @param {String=} description
 * @param {query=} query
 */
export const updatePersona = args => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  // check persona360 key to replace to '360' and send to persona/update
  const params = args;
  if (params['persona360'] !== undefined) {
    params['360'] = Number(params['persona360']);
    delete params['persona360'];
  }

  return axios.post(
    `${URI}/update`,
    {
      brand: brand.id,
      token,
      ...params,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Personas-create
 * @param {String} name
 * @param {String} query
 * @param {String} token
 * @param {String=} description
 * @param {Number=} 360
 */
export const createPersona = args => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  // check persona360 key to replace to '360' and send to persona/update
  const params = args;

  if (params['persona360'] !== undefined) {
    params['360'] = Number(params['persona360']);
    delete params['persona360'];
  }

  return axios.post(
    `${URI}/create`,
    {
      brand: brand.id,
      token,
      ...params,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @description get persona
 * @param {Number} personaId
 */
export const getPersona = personaId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios.get(`${URI}/getPersona`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate: startDate,
      endDate: endDate,
      personaId,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @description get if user has 360 module
 */
export const checkAccount360 = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  // this endpoint works for both - labels and personas
  return axios.get(`label/checkAccount360`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Personas-listAvailableLabels
 * @description list available labels
 * @returns {Object}
 */
export const getAvailableLabels = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`label/getLabels`, {
    params: {
      brand: brand.id,
    },
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Personas-getCount
 * @description get persona count from given query
 * @param {Number} personaId
 * @param {String} query
 */
export const getPersonaCount = ({ personaId, query }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  return axios.get(`${URI}/getCount`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      token,
      personaId,
      query,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Personas-hasPersonasLeft
 */
export const getHasPersonasLeft = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/hasPersonasLeft`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};

// api v2 - compare
/**
 * @description get all info for all boards
 * @param {Number} main persona target ID
 * @param {Number} compared persona base ID [0 for all database]
 * @returns {Object}
 */
export const getComparedPersonas = (main, compared, endpoint) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  let { startDate } = store.getters.dates;
  let { endDate } = store.getters.dates;

  if (startDate && endDate) {
    startDate = moment(startDate).format('YYYY-MM-DD');
    endDate = moment(endDate).format('YYYY-MM-DD');
  }
  return axios.get(`${URI}/${endpoint}`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate,
      endDate,
      personaMain: main,
      personaCompared: compared,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};

// api v2 - Personas Group
/**
 * @description get all groups of personas
 * @returns {Object}
 */

export const getPersonasGroups = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${URI}/getPersonaGroups`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
  });
};
