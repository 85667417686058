<template>
  <div :class="isCellChart() ? 'pd-auto' : ''" class="data-cell d-flex align-items-center">
    <template v-if="index === 1">
      <p v-html="formatString(dataItem.name)" />
      <i v-if="dataItem.expanded" class="icon icon-down-12px" />
    </template>

    <template v-if="index === 2">
      <circle-chart
        v-if="dataItem.merged_values.icon === 'circle-bar'"
        :percentage="parseInt(/\d*/g.exec(dataItem.merged_values.value)[0])"
      />
      <Icons v-else :ico="dataItem.merged_values.icon"></Icons>
      <p>
        <b>{{ $_formatValue(dataItem.merged_values, 'value') }} </b>
        <span v-html="formatString(dataItem.merged_values.description)" />
      </p>
    </template>
    <template v-if="index === 3 || index === 4">
      <bar-chart
        v-if="dataItem.persona_main.is_chart"
        :chart-props="index === 3 ? dataItem.persona_main.values : dataItem.persona_compared.values"
      />
      <value-cell
        v-else-if="dataItem.persona_main.values"
        :data-value="index === 3 ? dataItem.persona_main.values : dataItem.persona_compared.values"
      />
      <!-- rule for old object format -->
      <value-cell v-else :data-value="index === 3 ? [dataItem.persona_main] : [dataItem.persona_compared]" />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import CircleChart from '@/components/_atoms/CircleChart';
import valueMixin from '@/helpers/mixins/valueMixin';
import BarChart from '@/components/personasCompare/_atoms/BarChart';
import ValueCell from '@/components/personasCompare/_atoms/ValueCell';
import Icons from '@/components/personasCompare/_atoms/Icons';

export default {
  name: 'Cell',
  components: {
    CircleChart,
    Icons,
    ValueCell,
    BarChart,
  },
  mixins: [valueMixin],
  props: {
    dataItem: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    iconSelect: {
      type: String,
      default: '',
    },
  },
  methods: {
    isCellChart() {
      return _.findKey(this.dataItem, prop => prop.is_chart) && (this.index === 3 || this.index === 4);
    },
    hasHighlight(txtString) {
      return /(omni\S+|loja\s\S|e.*commerce)/gi.test(txtString);
    },
    formatString(txtString) {
      let stringTarget = null;
      // let stringClass = null;
      let format = txtString;
      if (!this.hasHighlight(txtString)) {
        return txtString;
      }
      if (/\bomni\S+/gi.test(txtString)) {
        stringTarget = /\bomni\S+/gi.exec(txtString);
        format = `<span class="txt-color-omni">${stringTarget}</span>`;
      } else if (/\bloja\s\S+/gi.test(txtString)) {
        stringTarget = /\bloja\s\S+/gi.exec(txtString);
        format = `<span class="txt-color-off">${stringTarget}</span>`;
      } else if (/\be.*commerce/gi.test(txtString)) {
        stringTarget = /\be-?commerce/gi.exec(txtString);
        format = `<span class="txt-color-on">${stringTarget}</span>`;
      }
      return txtString.replace(stringTarget, format);
    },
  },
};
</script>

<style lang="scss" scoped>
.data-row {
  .data-cell {
    width: 288px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    &:nth-of-type(2) {
      width: 384px;
    }
    &:nth-of-type(3) {
      background-color: rgba(108, 144, 244, 0.07);
      width: 234px;
      border-right: 1px solid $gray-400;
    }
    &:nth-of-type(4) {
      width: 234px;
    }
    &.pd-auto {
      padding: 0 2%;
    }
    p {
      margin-bottom: 0;
    }
  }
  &[aria-expanded] {
    .data-cell {
      .icon {
        font-size: 14px;
        position: absolute;
        right: 20px;
      }
    }
    &[aria-expanded='true'] {
      .data-cell .icon {
        transform: rotate(180deg);
      }
    }
  }
}
.exp-sub {
  .data-cell {
    padding-left: 54px;
  }
}
.exp-parent {
  .data-cell {
    font-weight: 600;
  }
}
</style>

<style lang="scss">
.txt-color-on {
  color: $oto-ecommerce;
}
.txt-color-off {
  color: $purple-200;
}
.txt-color-omni {
  color: $oto-omni;
}
</style>
