<template>
  <section id="personas-footer">
    <div class="container">
      <div class="cols d-flex align-items-center">
        <div class="count">
          <personas-footer-counter :people="personaDetail.count" :time="time" />
        </div>
        <div v-show="brandHas360" class="column-360">
          <label @click="actionSetPersona360">
            <check-box :checked="personaDetail.persona360" />
            <span>{{ $t('labels-personas.show-360') }}</span>
          </label>
        </div>
        <button class="btn btn-save-persona" @click="savePersona">
          {{ $t('personas.footer.save-persona') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
// libs, helpers, services
import { useToast } from 'vue-toastification';
import moment from 'moment';
import bus from '@/helpers/events/bus';
import { createPersona, updatePersona } from '@/services/personas';
import { mapState, mapActions, mapGetters } from 'vuex';
// components - atoms
import CheckBox from '@/components/_atoms/CheckBox';
// components - molecules
import PersonasFooterCounter from '@/components/personas/_molecules/PersonasFooterCounter';

export default {
  name: 'PersonasFooter',
  components: {
    PersonasFooterCounter,
    CheckBox,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      updated: false,
      lastUpdate: moment(),
      time: 0,
    };
  },
  computed: {
    ...mapState('personas', ['personaDetail', 'brandHas360', 'personaDetailMode']),
    ...mapGetters('personas', ['query', 'getPersonaDetailUnsavedCompose']),
  },
  mounted() {
    setInterval(() => {
      if (!this.updated) {
        const time = moment().diff(this.lastUpdate) / 1000;
        if (time > 5) this.time = time;
      } else {
        this.time = 0;
      }
      this.updated = false;
    }, 1000);

    bus.$on('count-updated', () => {
      this.updated = true;
      this.lastUpdate = moment();
      this.time = 0;
    });
  },
  methods: {
    ...mapActions('personas', ['actionSetPersona360']),
    savePersona() {
      if (this.personaDetail?.personaId && this.personaDetailMode === 'view') {
        // mode: update
        updatePersona(this.getPersonaDetailUnsavedCompose).then(res => {
          if (!res?.data?.success) {
            this.showErrorMessage(res.status);
          } else {
            this.toast.success(this.$t('personas.detail.messages.updated'), {
              timeout: 5000,
            });
          }
        });
      } else {
        // mode: save/duplicate
        const { name, description, persona360, groupId } = this.personaDetail;
        createPersona({
          name,
          description,
          persona360,
          query: JSON.stringify(this.query),
          group: groupId,
        }).then(res => {
          if (!res?.data?.success) {
            this.showErrorMessage(res.status);
          } else {
            bus.$emit('show-detail-success', res.data.data.personaId);
          }
        });
      }
    },
    showErrorMessage(errorCode) {
      if (errorCode === 429) {
        bus.$emit(
          'show-detail-error',
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('personas.title', 2).toLowerCase(),
          }),
        );
      } else {
        this.toast.error(
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('personas.title', 1).toLowerCase(),
          }),
        );
        bus.$emit('header-details-input-blur');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#personas-footer {
  padding: 18px 0;
  background-color: $color-white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  color: $gray-700;
  z-index: 99;

  .cols {
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .count {
      flex-grow: 10;
    }
    .audience-steps {
      flex-grow: 1;
      justify-content: center;
      display: flex;
    }

    @media (max-width: $md-max-width) {
      flex-wrap: wrap;
    }
  }
}
.column-360 {
  margin-right: 20px;
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin-left: 10px;
      color: $gray-700;
    }
  }
}
.btn-save-persona {
  width: 180px;
  height: 40px;
}
</style>
