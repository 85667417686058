<template>
  <base-card-v2 class="card table-indicators">
    <template v-if="$_verifyLoaded('done')">
      <base-card-header :title="$t('nps.metrics')" icon="icon-table-30px" />
      <div class="tbl-shell">
        <div ref="tbl-inner-wrapper" class="tbl-inner-wrapper" @scroll="$_scrollTable">
          <div ref="tbl-config" class="tbl-config">
            <div class="tbl-config-options-header row">
              <div class="btn-options">
                <div class="export-wrapper">
                  <button ref="button" class="btn export btn-export" @click="toggleExport">
                    <i class="icon export-icon icon-download"></i>
                    {{ this.$t('360.export', { value: '' }) }}
                  </button>
                  <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
                    <div
                      v-for="item in exportItems"
                      :key="item.value"
                      class="items"
                      @click="onChangeExport(preset, item.value)"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                  <button
                    v-if="!isCustomReport"
                    ref="button-edit"
                    class="btn btn-secondary edit-columns"
                    @click="openEditColumnsModal"
                  >
                    <i class="icon icon-config 18px"></i>
                    {{ this.$t('dashboard.edit-columns.title') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div ref="tableHeader" class="tbl-header">
            <div class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <div class="header-title">
                  {{ vH.name }}
                </div>
                <div
                  :ref="vH.key"
                  :class="[
                    `order-by ${vH.key}`,
                    {
                      active: isActive === vH.key,
                    },
                  ]"
                  @click.prevent="$_dashOrdination(vH.key)"
                >
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- table header fixed -->
          <div v-if="headerFixed" ref="header-fixed" class="tbl-header header-fixed">
            <div class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <div class="header-title">
                  {{ vH.name }}
                </div>
                <div
                  :ref="vH.key"
                  :class="[
                    `order-by ${vH.key}`,
                    {
                      active: isActive === vH.key,
                    },
                  ]"
                  @click.prevent="$_dashOrdination(vH.key)"
                >
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
            </div>
          </div>
          <div ref="tbl-body" class="tbl-body">
            <div v-for="(item, index) in tableData.rows" :key="index" class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <p
                  :class="[
                    'threshold',
                    `${$_checkThreshold({
                      amount: item[vH.key],
                      threshold: vH.threshold,
                    })}`,
                  ]"
                >
                  {{ $_getValue({ type: vH.type, amount: item[vH.key] }) }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="tableData.totalResults" class="tbl-footer">
            <div v-for="(item, index) in tableData.totalResults" :key="index" class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <p :class="['footer-item']">
                  {{
                    vH.key == 'store'
                      ? $t('360.lightbox.order-details.total')
                      : !isNil(item[vH.key])
                      ? $_getValue({ type: vH.type, amount: item[vH.key] })
                      : '-'
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <edit-columns-modal-campaigns page="nps-campaigns" />
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import moment from 'moment';
import FileDownload from 'js-file-download';
import bus from '@/helpers/events/bus';
import vClickOutside from 'click-outside-vue3';
import { mapGetters } from 'vuex';
import { getTablePresets } from '@/services/oto/indicators';
import { getTable } from '@/services/nps/campaigns';
import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import EditColumnsModalCampaigns from '@/components/nps/_organisms/EditColumnsModalCampaigns';

export default {
  components: {
    BaseLoader,
    EditColumnsModalCampaigns,
    InfoCard,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  data() {
    return {
      headerFixedWidth: 0,
      headerFixed: false,
      tableData: {},
      tableHeader: {},
      tablePresets: {},
      preset: 'nps-campaigns',
      metrics: ['sent', 'delivered_rate', 'open_rate', 'ctr', 'cto', 'bounces'],
      groupBy: ['date', 'subject'],
      isActive: null,
      orderDefault: [],
      order: null,
      orderType: 'asc',
      isExportVisible: false,
      isCustomReport: false,
      exportItems: [
        {
          value: 'csv',
          title: this.$t('360.export-type', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('360.export-type', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('360.export-type', { value: '.XLSX' }),
        },
      ],
      loadingExport: false,
      conversionWindow: 30,
    };
  },
  computed: {
    ...mapGetters({
      getAllCheckedMetrics: 'npsCampaigns/getAllCheckedMetrics',
    }),
  },
  created() {
    // bus called when dates are changed globally
    bus.$on('render-campaigns-table', () => {
      this.fetchCheckedMetrics();
    });
  },
  mounted() {
    this.setup();
    bus.$on('render-cards', () => {
      this.setup();
    });
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });

    this.fetchCheckedMetrics();

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off('render-page-loader');
    bus.$off('render-campaigns-table');
  },
  methods: {
    setup() {
      this.getTableInfo();
      this.getTablePresetsInfo();
    },
    getTableInfo() {
      this.$_reqConfig();

      if (this.isActive == '' || this.isNil(this.isActive)) {
        this.isActive = this.orderDefault;
        this.order = this.orderDefault;
      }
      getTable({
        metrics: this.metrics.join(','),
        groupBy: this.groupBy.join(','),
      })
        .then(({ data }) => {
          if (this.$_verifyData(data?.data)) return;

          this.tableData.totalResults = data?.totalResults;
          this.tableData.rows = data?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                return [data.series[i].key, val];
              }),
            );
          });
          this.tableHeader = data?.series;
          this.order = data.orderBy ? data.orderBy : null;
          this.isActive = data.orderBy ? data.orderBy : null;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
          if (this.$_dashVerifyIsActive) {
            this.order = this.isActive;
            this.$_dashOrderBy(this.isActive);
          }
        });
    },
    getTablePresetsInfo() {
      getTablePresets().then(res => {
        this.tablePresets = res.presets;
      });
    },
    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },
    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    fetchExportData(preset, fileType) {
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getTable({
        metrics: this.metrics.join(','),
        groupBy: this.groupBy.join(','),
        fileType,
        responseType: 'blob',
      })
        .then(({ data }) => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset}-${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },
    /**
    /**
     * @description emit event to open dash columns editor
     */
    openEditColumnsModal() {
      bus.$emit('open-campaigns-edit-columns');
    },
    /**
     * @description fetch data after select custom table options
     */
    fetchCheckedMetrics() {
      const { metrics, dimensions } = this.getAllCheckedMetrics;
      this.metrics = metrics;
      this.groupBy = dimensions;
      this.getTableInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.export-icon {
  font-size: 17px;
  font-weight: 600;
}
.table-presets-select {
  height: 36px;
  width: 225px;
  margin-left: 16px;
}
.table-indicators {
  min-height: 430px;
}
</style>
