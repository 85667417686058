import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, vShow as _vShow, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f1a2349"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row global-quarantine" }
const _hoisted_2 = { class: "col-4 card-title pr-3" }
const _hoisted_3 = { class: "global-quarantine__container" }
const _hoisted_4 = { class: "global-quarantine__cards" }
const _hoisted_5 = { class: "global-quarantine__cards__dynamic--flag" }
const _hoisted_6 = { class: "global-quarantine__checkbox" }
const _hoisted_7 = {
  for: "isDynamic",
  class: "value-label"
}
const _hoisted_8 = { class: "global-quarantine__cards__dynamic--tag" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "global-quarantine__checkbox" }
const _hoisted_11 = {
  for: "static",
  class: "value-label"
}
const _hoisted_12 = { class: "global-quarantine__cards__static--form" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loader = _resolveComponent("base-loader")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('global-quarantine-by-phone')), 1)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.metrics.quarantine.text')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "global-quarantine__cards__dynamic",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.updateQuarantineType('isDynamic')), ["prevent"]))
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('settings.metrics.quarantine.dynamic.flag')), 1),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              id: "isDynamic",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedQuarantine) = $event)),
              type: "radio",
              name: "quarantine-type",
              value: "isDynamic"
            }, null, 512), [
              [_vModelRadio, _ctx.selectedQuarantine]
            ]),
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('settings.metrics.quarantine.dynamic.title')), 1),
            _createElementVNode("span", _hoisted_8, [
              _createTextVNode(" IA "),
              _createElementVNode("img", {
                class: "icon",
                src: require(`@/assets/img/icons/stars.svg`)
              }, null, 8, _hoisted_9)
            ])
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.metrics.quarantine.dynamic.text')), 1)
        ]),
        _createElementVNode("div", {
          class: "global-quarantine__cards__static",
          onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.updateQuarantineType('static')), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createElementVNode("input", {
              id: "static",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedQuarantine) = $event)),
              type: "radio",
              name: "quarantine-type",
              value: "static"
            }, null, 512), [
              [_vModelRadio, _ctx.selectedQuarantine]
            ]),
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('settings.metrics.quarantine.static.title')), 1)
          ]),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('settings.metrics.quarantine.static.text')), 1),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", null, [
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass({
                  'is-invalid': _ctx.isShowingTooltip,
                }),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.days) = $event)),
                placeholder: _ctx.$t('settings.metrics.quarantine.static.input.placeholder'),
                onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.removeNotNumbers && _ctx.removeNotNumbers(...args)))
              }, null, 42, _hoisted_13), [
                [
                  _directive_tooltip,
                  {
                  theme: 'error',
                  html: true,
                  autoHide: false,
                  distance: 8,
                  content: _ctx.$t('settings.metrics.quarantine.static.input.content'),
                  shown: _ctx.isShowingTooltip,
                },
                  void 0,
                  { left: true }
                ],
                [_vModelText, _ctx.days]
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('settings.metrics.quarantine.days')), 1)
            ])
          ])
        ]),
        _createElementVNode("button", {
          class: "btn global-quarantine__cards__btn-save",
          disabled: _ctx.disabledButton,
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.saveQuarantineSettings && _ctx.saveQuarantineSettings(...args)))
        }, _toDisplayString(_ctx.$t('settings.metrics.quarantine.button')), 9, _hoisted_14)
      ])
    ], 512), [
      [_vShow, !_ctx.isLoading]
    ]),
    _withDirectives(_createVNode(_component_base_loader, null, null, 512), [
      [_vShow, _ctx.isLoading]
    ])
  ]))
}