<template>
  <div class="o-metrics_group">
    <div class="row">
      <div class="col-4">
        <metrics-card
          :title="$tc('dashboard.kpi', 2)"
          icon="icon-media"
          :metrics="mKpi"
          :info="$_verifIsEmpty(mKpiInfo, mKpiHasError, mKpiLoaded)"
        />
      </div>
      <div class="col-8">
        <metrics-card
          :title="$tc('dashboard.performance', 1)"
          icon="icon-mail"
          :metrics="mOpens"
          :info="$_verifIsEmpty(mOpensInfo, mOpensHasError, mOpensLoaded)"
        />
      </div>
    </div>
    <!--
    <div class="row">
      <div class="col-6">
        <metrics-card
          :title="$t('dashboard.deliverability')"
          icon="icon-mail"
          :metrics="mDeliv"
          :info="$_verifIsEmpty(mDelivInfo, mDelivHasError, mDelivLoaded)"
        />
      </div>
      <div class="col-6">
        <metrics-card
          :title="$t('dashboard.revenue-conversion-ga')"
          icon="icon-money"
          :metrics="mRevGA"
          :info="$_verifIsEmpty(mRevGAInfo, mRevGAHasError, mRevGALoaded)"
        />
      </div>
    </div>
    -->
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { getMetrics } from '@/services/dashboard';
import { verifyMixin, verifyThensMixin } from '@/helpers/mixins';
import MetricsCard from '@/components/_molecules/MetricsCard';

export default {
  name: 'DashboardSmsMetricsGroup',
  components: {
    MetricsCard,
  },
  mixins: [verifyMixin, verifyThensMixin],
  data() {
    return {
      mKpi: { a: {}, b: {}, c: {}, d: {}, e: {}, f: {} },
      mKpiInfo: false,
      mKpiHasError: false,
      mKpiLoaded: false,
      mOpens: { a: {}, b: {}, c: {} },
      mOpensInfo: false,
      mOpensHasError: false,
      mOpensLoaded: false,
      /*
      mDeliv: { a: {}, b: {}, c: {} },
      mDelivInfo: false,
      mDelivHasError: false,
      mDelivLoaded: false,
      mRevGA: { a: {}, b: {}, c: {}, d: {} },
      mRevGAInfo: false,
      mRevGAHasError: false,
      mRevGALoaded: false,
      */
    };
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      this.mKpiLoaded = true;
      this.mOpensLoaded = true;
      /*
      this.mDelivLoaded = true;
      this.mRevGALoaded = true;
      */

      getMetrics({ dashboard: 'sms', metrics: 'sent,split_sent,bounces' })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) {
            this.mKpiInfo = true;
            return;
          } else this.mKpiInfo = false;

          this.mKpi = data.metrics;
        })
        .catch(() => {
          this.$_componentHeight();
          this.mKpiHasError = true;
        })
        .finally(() => {
          this.mKpiLoaded = false;
          this.$_verifLoading();
        });
      getMetrics({
        dashboard: 'sms',
        metrics: 'skipped,hard_bounce_rate,soft_bounce_rate,delivered_rate',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) {
            this.mOpensInfo = true;
            return;
          } else this.mOpensInfo = false;

          this.mOpens = data.metrics;
        })
        .catch(() => {
          this.mOpensHasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.mOpensLoaded = false;
          this.$_verifLoading();
        });
      /*
      getMetrics({ metrics: 'optin_deliverable,optout_deliverable,undeliverable,engaged' })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) {
            this.mDelivInfo = true;
            return;
          } else this.mDelivInfo = false;

          this.mDeliv = data.metrics;
        })
        .catch(() => {
          this.mDelivHasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.mDelivLoaded = false;
          this.$_verifLoading();
        });
      getMetrics({ metrics: 'revenue_ga,rpe_ga,conversions_ga,aov_ga' })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) {
            this.mRevGAInfo = true;
            return;
          } else this.mRevGAInfo = false;

          this.mRevGA = data.metrics;
        })
        .catch(() => {
          this.mRevGAHasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.mRevGALoaded = false;
          this.$_verifLoading();
        });
      */
    },
  },
};
</script>

<style lang="scss" scoped>
.o-metrics_group {
  margin-top: 12px;
}
</style>
