<template>
  <!-- "typecard" has two options: "table" or "card", choose as a prop
when importing this component to define if this card will
display css for card or table. -->

  <div class="link-container row d-flex justify-content-end b-tp" :class="tableOrCard()">
    <slot name="bottom-button"></slot>

    <!-- Usually this is the default configuration for
    this component when call it by slot. -->

    <!--<label
      class="link color-oto-brand-omni col-auto text-right mr-3"
      @click="openLightbox('name-your-lightbox')"
    >{{ $t('name-your-translation') }}</label>-->
  </div>

  <!-- Probably will be necessary to set some padding
or margin at your card when use this component.
This css is not set here cause it's possible to
break the config from other cards. -->
</template>

<script>
export default {
  name: 'BaseCardBottomButton',
  props: {
    typecard: {
      type: String,
      default: null,
    },
  },
  methods: {
    tableOrCard() {
      let value = '';
      const type = this.typecard;
      if (type === 'card') {
        value = 'is-card';
      } else {
        value = '';
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.is-card {
  bottom: 0;
  position: absolute;
  width: 100%;
  margin: 0 -25px;
}
.b-tp {
  border-top: 1px solid $gray-300;
}
:slotted(.link) {
  cursor: pointer;
  padding: 10px;
}
</style>
