<template>
  <div class="sales-per-brand">
    <div class="d-flex align-items-center justify-content-between">
      <h2 class="h1 card-group-title">{{ $t('categories.brands.title') }}</h2>
      <search :id="id" />
    </div>
    <skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <table-list :id="id" :data="data" :type="type" :empty="!data.count" :loading="$_verifyLoaded('loading')">
      <table-list-header v-if="$_verifyLoaded('done')">
        <table-list-column order="name" class="col-2">{{ $t('categories.list.name') }}</table-list-column>
        <table-list-column order="revenue" class="col-2 text-right">{{ $t('categories.revenue') }}</table-list-column>
        <table-list-column order="sale" class="col-2 text-right">{{ $t('categories.list.sales') }}</table-list-column>
        <table-list-column order="quantity_sold" class="col-2 text-right">
          <span v-html="$t('categories.list.sold-qty')"></span>
        </table-list-column>
        <table-list-column order="average_price" class="col-2 text-right">
          <span v-html="$t('categories.list.average-price')"></span>
        </table-list-column>
        <table-list-column order="total_revenue" class="col-2 text-right">{{
          $t('categories.total-revenue')
        }}</table-list-column>
      </table-list-header>
      <table-list-body>
        <template v-if="$_verifyLoaded('done')">
          <table-list-row v-for="(item, index) in data.rows" :key="index">
            <table-list-column class="col-2">{{ item.name }}</table-list-column>
            <table-list-column class="col-2 text-right">{{ $_formatNumber(item.revenue, '% 0') }}</table-list-column>
            <table-list-column class="col-2 text-right">{{ $_formatNumber(item.sale, '% 0') }}</table-list-column>
            <table-list-column class="col-2 text-right">
              {{ $_formatDecimal(item.quantity_sold) }}
            </table-list-column>
            <table-list-column class="col-2 text-right">{{ formatCurrency(item.average_price) }}</table-list-column>
            <table-list-column class="col-2 text-right">{{ formatCurrency(item.total_revenue) }}</table-list-column>
          </table-list-row>
        </template>
        <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
      </table-list-body>
    </table-list>
  </div>
</template>

<script>
import { getTable } from '@/services/categories';
import bus from '@/helpers/events/bus';
import SalesPerBrandSkeleton from '@/components/categories/category/brands/SalesPerBrandSkeleton';
import Search from '@/components/categories/search/SearchForm';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  name: 'SalesPerBrand',
  components: {
    skeleton: SalesPerBrandSkeleton,
    Search,
    InfoCard,
  },
  mixins: [verifyMixin, numberMixin],
  props: {
    category: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      id: 'sales-per-brand',
      data: {
        page: 1,
        limit: 10,
        count: 0,
        rows: [],
        order: 'name',
        orderDefault: 'name',
        term: undefined,
      },
      type: 'filled',
    };
  },
  mounted() {
    this.fetchData();

    bus.$on(`${this.id}-change-page`, () => {
      this.$_reqConfig();
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);

    bus.$on('render-cards', this.fetchData);

    bus.$on(`search-filter-${this.id}`, term => {
      this.data.term = term;
      this.data.term ? (this.data.page = undefined) : (this.data.page = 1);
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards', this.fetchData);
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getTable({
        name: this.category,
        table: 'sale_per_brands',
        limit: this.data.limit,
        page: this.data.page,
        order_by: this.data.order,
        term: this.data.term ? this.data.term : '',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.data.rows = data.data;
          this.data.count = data.total_count;
        })
        .catch(() => {
          this.data.count = 0;
          this.data.rows = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
