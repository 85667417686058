<template>
  <base-card-v2 class="card table-indicators">
    <template v-if="$_verifyLoaded('done')">
      <base-card-header :title="$t('360.metrics')" icon="icon-table-30px" />
      <div class="tbl-shell">
        <div ref="tbl-inner-wrapper" class="tbl-inner-wrapper" @scroll="$_scrollTable">
          <div ref="tbl-config" class="tbl-config">
            <div class="tbl-config-options-header row">
              <v-select
                v-model="preset"
                label="name"
                :clearable="false"
                :options="presets"
                @option:selected="getTableInfo"
              >
                <template #option="{ name }">
                  {{ $t(`${name}`) }}
                </template>
                <template #selected-option="{ name }">
                  {{ $t(`${name}`) }}
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes" class="arrow-down"></span>
                </template>
                <template #no-options>
                  {{ $t('360.sellers.no-results') }}
                </template>
              </v-select>
              <div class="btn-options">
                <div class="export-wrapper">
                  <button ref="button" class="btn export btn-export" @click="toggleExport">
                    <i class="icon export-icon icon-download"></i>
                    {{ $t('360.export', { value: '' }) }}
                  </button>
                  <div v-if="isExportVisible" v-click-outside="toggleExport" class="export-container">
                    <div
                      v-for="item in exportItems"
                      :key="item.value"
                      class="items"
                      @click="onChangeExport(preset.id, item.value)"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="tableHeader" class="tbl-header">
            <div class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <div class="header-title">
                  {{ vH.name }}
                </div>
                <div
                  :ref="vH.key"
                  :class="[
                    `order-by ${vH.key}`,
                    {
                      active: isActive === vH.key,
                    },
                  ]"
                  @click.prevent="$_dashOrdination(vH.key)"
                >
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="headerFixed" ref="header-fixed" class="tbl-header header-fixed">
            <div class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <div class="header-title">
                  {{ vH.name }}
                </div>
                <div
                  :ref="vH.key"
                  :class="[
                    `order-by ${vH.key}`,
                    {
                      active: isActive === vH.key,
                    },
                  ]"
                  @click.prevent="$_dashOrdination(vH.key)"
                >
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
            </div>
          </div>
          <div ref="tbl-body" class="tbl-body">
            <div v-for="(item, index) in tableData.data" :key="index" class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <p
                  :class="[
                    'threshold',
                    `${$_checkThreshold({
                      amount: item[vH.key],
                      threshold: vH.threshold,
                    })}`,
                  ]"
                >
                  {{ $_getValue({ type: vH.type, amount: item[vH.key] }) }}
                </p>
              </div>
            </div>
          </div>
          <div class="tbl-footer">
            <div v-for="(item, index) in tableData.totalResults" :key="index" class="tbl-row">
              <div
                v-for="(vH, kH) in tableHeader"
                :key="kH"
                :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
              >
                <p :class="['footer-item']">
                  {{
                    vH.key == 'store'
                      ? $t('360.lightbox.order-details.total')
                      : !isNil(item[vH.key])
                      ? $_getValue({ type: vH.type, amount: item[vH.key] })
                      : '-'
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import moment from 'moment';
import vSelect from 'vue-select';
import FileDownload from 'js-file-download';
import bus from '@/helpers/events/bus';
import { getTable, getTablePresets } from '@/services/oto/indicators-old';
import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import vClickOutside from 'click-outside-vue3';

export default {
  components: {
    BaseLoader,
    InfoCard,
    vSelect,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  data() {
    return {
      headerFixedWidth: 0,
      headerFixed: false,
      tableData: {},
      tableHeader: {},
      tablePresets: {},
      preset: {
        id: 'store_campaign',
        name: 'Loja e Campanha',
      },
      presets: [],
      isActive: null,
      orderDefault: [],
      order: null,
      orderType: 'asc',
      isExportVisible: false,
      exportItems: [
        {
          value: 'csv',
          title: this.$t('360.export-type', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('360.export-type', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('360.export-type', { value: '.XLSX' }),
        },
      ],
      loadingExport: false,
      conversionWindow: 30,
    };
  },
  created() {
    // bus called when dates are changed globally
    bus.$on('render-cards', () => {
      this.getTableInfo();
    });
  },
  mounted() {
    this.setup();
    bus.$on('changeSelectedOption', newSelectedOption => {
      if (newSelectedOption.id === 'table-select') {
        this.preset = newSelectedOption.index;
        this.getTableInfo();
      }
    });
    bus.$on('indicator-conversion-window', value => {
      this.conversionWindow = value;
      this.getTableInfo();
    });
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off('changeSelectedOption');
    bus.$off('render-page-loader');
    bus.$off('render-cards');
    bus.$off('indicator-conversion-window');
  },
  methods: {
    setup() {
      this.getTableInfo();
      this.getTablePresetsInfo();
    },
    getTableInfo() {
      this.$_reqConfig();

      if (this.isActive == '' || this.isNil(this.isActive)) {
        this.isActive = this.orderDefault;
        this.order = this.orderDefault;
      }
      getTable(this.preset.id, this.conversionWindow)
        .then(res => {
          if (this.$_verifyData(res.data)) return;

          this.tableData = res;
          this.tableHeader = res.series;
          this.order = res.orderBy ? res.orderBy : null;
          this.isActive = res.orderBy ? res.orderBy : null;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
          if (this.$_dashVerifyIsActive) {
            this.order = this.isActive;
            this.$_dashOrderBy(this.isActive);
          }
        });
    },
    getTablePresetsInfo() {
      getTablePresets().then(({ presets }) => {
        const dataPresets = Object.entries(presets);

        dataPresets.map(el => {
          this.presets.push({
            id: el[0],
            name: el[1],
          });
        });
      });
    },
    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },
    /**
     * @description fetch data to export file
     * @param {fileType} fileType generated file type
     * @param {object} preset selected preset
     */
    fetchExportData(preset, fileType) {
      this.loadingExport = true;
      bus.$emit('render-page-loader', true);

      getTable(preset, this.conversionWindow, fileType, 'blob')
        .then(data => {
          const date = moment().format(dateFormatYMDHms);
          FileDownload(data, `${preset}-${date}.${fileType}`);
        })
        .finally(() => {
          bus.$emit('render-page-loader', false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.export-icon {
  font-size: 17px;
  font-weight: 600;
}
.table-presets-select {
  height: 36px;
  width: 225px;
  margin-left: 16px;
}
.table-indicators {
  min-height: 430px;
}
</style>
