<template>
  <lightbox-template id="lightbox-revenue-by-media" class="lightbox-revenue-by-media" :title="$t('attribution-title')">
    <rev-by-media-chart />
    <rev-by-media-table />
  </lightbox-template>
</template>

<script>
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import RevByMediaChart from '@/components/lightbox/lightboxes/_organisms/revByMedia/RevByMediaChart';
import RevByMediaTable from '@/components/lightbox/lightboxes/_organisms/revByMedia/RevByMediaTable';

export default {
  name: 'LightboxRevByMedia',
  components: {
    LightboxTemplate,
    RevByMediaChart,
    RevByMediaTable,
  },
};
</script>
<style lang="scss">
.lightbox-revenue-by-media {
  .highcharts-legend-item {
    text-transform: capitalize;
  }
}

.loadHeight {
  min-height: 250px;
  position: relative;
}
</style>
