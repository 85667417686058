<template>
  <div class="container loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('tracked-revenue.title') }}</h5>
        <span class="segment-label">{{ $_segmentNameSelect() }}</span>
      </header>
      <lightbox-template-chart :settings="chartTrackedRevenue" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import { formatCurrency, formatPercent } from '@/utilities/formatters';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'TrackedRevenueChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  data() {
    return {
      chartTrackedRevenue: [],
      localSegment: '',
      segmentSelected: '',
      segmentName: '',
    };
  },
  mounted() {
    this.reset();
    this.loadGraphic();
  },
  methods: {
    loadGraphic() {
      getMetrics({
        metrics: ['revenue', 'revenue_tracked', 'revenue_tracked_total_rate'],
        segments: this.segmentSelected,
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          const { data } = response.data;
          if (this.$_verifyData(data.metrics)) return;

          const { metrics } = data;
          let { labels } = data;
          labels = this.format({ labels, formatTo: 'MMM/YY' });
          const { revenue } = metrics;
          const revenueTracked = metrics.revenue_tracked;
          const revenueTrackedTotal = metrics.revenue_tracked_total_rate;

          this.loadChartTrackedRevenue(revenue, revenueTracked, revenueTrackedTotal, labels);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadChartTrackedRevenue(revenue = [], revenueTracked = [], revenueTrackedTotal = [], labels = []) {
      this.chartTrackedRevenue = {
        chart: {
          renderTo: 'containerTrackedRevenue',
          height: 270,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        title: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        xAxis: {
          categories: labels,
          lineWidth: 0,
        },
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
          },
          {
            title: {
              text: null,
            },
            labels: {
              format: '{value}%',
            },
            lineWidth: 0,
            opposite: true,
          },
        ],
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            let s = `<b>${this.x}</b>`;

            this.points.forEach(points => {
              s += `<br/><span style="color:${points.color}">\u25CF</span> ${points.series.name}: <b>${
                points.series.name.includes('%') ? formatPercent(points.y / 100, 2) : formatCurrency(points.y)
              }</b>`;
            });
            return s;
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          series: {
            pointPadding: 0,
            borderWidth: 0,
            shadow: false,
          },
        },
        series: [
          {
            name: this.$t('revenue'),
            color: '#62d4ba',
            data: revenue,
            type: 'column',
          },
          {
            name: this.$t('tracked-revenue.attributed'),
            color: '#fe6822',
            data: revenueTracked,
            type: 'column',
          },
          {
            name: `% ${this.$t('tracked-revenue.attributed')}`,
            color: '#ef4b8c',
            data: revenueTrackedTotal,
            type: 'spline',
            yAxis: 1,
          },
        ],
      };
    },
    reset() {
      this.$_reqConfig();
      this.chartTrackedRevenue = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
  },
};
</script>
