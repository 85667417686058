import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

/**
 * @see https://api-context.pmweb.com.br/v3/customField/getCustomFields
 */
export const getCustomFields = ({ table, orderBy, term }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/customField/getCustomFields',
    params: {
      brand: brand.id,
      table,
      orderBy,
      term,
    },
  });
};

/**
 * @see https://api-context.pmweb.com.br/v3/customField/getAvailableCustomFields
 */
export const getAvailableCustomFields = table => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/customField/getAvailableCustomFields',
    params: {
      brand: brand.id,
      table,
    },
  });
};

/**
 * @see https://api-context.pmweb.com.br/v3/customField/putCustomFields
 */
export const putCustomFields = data => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.post(
    '/customField/putCustomFields',
    {
      brand: brand.id,
      ...data,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
      validateStatus: () => true,
    },
  );
};

/**
 * @see https://api-context.pmweb.com.br/v3/customField/getCustomField
 */
export const getCustomField = ({ table, customField }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/customField/getCustomField',
    params: {
      brand: brand.id,
      customField,
      table,
    },
  });
};
