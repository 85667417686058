<template>
  <div v-if="validateIfHasPermission(permissionsLimits)" id="limits" class="limits">
    <header class="limits__header">
      <h1 class="card-group-title">{{ $t('settings.limits.title') }}</h1>
    </header>
    <div v-if="$_verifyLoaded('done')" class="limits__table-list">
      <ul>
        <li v-for="(limit, index) in limits" :key="index">
          <span class="col-7">{{ limit.title }}</span>
          <span class="col-5" v-html="limit.value"></span>
        </li>
      </ul>
      <span class="limits__text-footer">{{ $t('settings.limits.increase-limits') }}</span>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
//services
import { getLimits } from '@/services/getLimits';

// helpers
import verifyMixin from '@/helpers/mixins/verifyMixin';
import hasPermission from '@/helpers/permissions';

//utilities
import { permissionsLimits } from '@/utilities/accessLevel/LimitsPermissions';
import { useMixpanelConfig } from '@/utilities/mixpanel';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'Limits',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      limits: [],
      permissionsLimits: permissionsLimits,
    };
  },
  mounted() {
    useMixpanelConfig('view_settings_planLimits');
    this.fetchData();
  },
  methods: {
    fetchData() {
      getLimits()
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;
          this.limits = data.data;
          this.limits.forEach(item => {
            item.value = item.value.replace(/{/g, '<b>'); // eslint-disable-line
            item.value = item.value.replace(/}/g, '</b>'); // eslint-disable-line
          });
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateIfHasPermission(permissionGroup) {
      return hasPermission(permissionGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
.limits {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__table-list {
    width: 852px;
    border-radius: 10px;
    background: $color-white;
    margin-bottom: 12px;

    ul {
      padding: 0;

      li {
        display: flex;
        padding-top: 47px;
        padding-bottom: 23px;
        border-bottom: 1px solid $gray-300;
        margin: 0 50px;

        span:first-child {
          font-size: 16px;
          font-weight: 600;
          color: $gray-800;
        }

        span:last-child {
          font-size: 14px;
          color: $gray-700;
        }
      }
    }
  }

  &__text-footer {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    padding-bottom: 24px;
  }
}
</style>
