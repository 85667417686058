<template>
  <div class="table-list-row" :class="[type, cursor]" @click.prevent="clickFunction" @click="clickRedirect">
    <div class="card" :class="type">
      <div class="card-body" :class="type">
        <div :class="itemClass">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { closeLightbox } from '@/helpers/lightbox';

export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    clickCallback: {
      type: Function,
      default: () => null,
    },
    itemClass: {
      type: String,
      default: 'row',
    },
    cursorType: {
      type: String,
      default: 'default',
    },
    productID: {
      type: String,
      default: null,
    },
    categoryName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      prodLightBox: '',
      catLightBox: '',
    };
  },
  computed: {
    /**
     * If cursorType is 'default' return a empty value
     */
    cursor() {
      if (this.cursorType === 'default') return '';
      return `cursor-${this.cursorType}`;
    },
    type() {
      let $current = this.$parent;

      while (!$current.type) $current = $current.$parent;

      return $current.type;
    },
  },
  created() {
    this.prodLightBox = document.getElementById('lightbox-product-best-seller');
    this.catLightBox = document.getElementById('lightbox-category-best-seller');
  },
  methods: {
    /**
     * Send click event if isn't an anchor "a" or an icon "i"
     * @param {Object} event
     */
    clickFunction(event) {
      if (event.target.nodeName !== 'A' && event.target.nodeName !== 'I') {
        if (this.clickCallback !== undefined) this.clickCallback(event);
      }
    },
    /**
     * Method used to redirect to product or category page
     */
    clickRedirect() {
      if (this.productID && this.categoryName) return;

      let routerType;
      if (this.productID) {
        const productRouter = {
          name: 'product',
          params: { id: this.productID },
        };
        routerType = productRouter;
      } else if (this.categoryName) {
        const categoryRouter = {
          name: 'category',
          params: { name: this.categoryName.toLowerCase() },
        };
        routerType = categoryRouter;
      }

      if (routerType) this.$router.push(routerType);
      if (this.prodLightBox || this.catLightBox) {
        if (document.getElementsByClassName('lightbox-template hasFade').length) closeLightbox();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-row {
  width: 100%;
  cursor: default;

  &.cursor-pointer {
    cursor: pointer;
  }

  &.normal {
    padding-bottom: 10px;
  }

  .card {
    padding: 10px 25px;
    margin-bottom: 0;
    font-size: 12px;
    box-shadow: none;

    &.normal {
      transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 0.1);
      min-height: 50px;
      border-radius: 10px;
    }

    &.filled {
      border-radius: 0;
      margin-left: -6px;
      padding-left: 31px;
      margin-right: -6px;
      padding-right: 31px;
      min-height: 40px;
      width: calc(100% + 12px);

      &:hover {
        background-color: rgba($gray-300, 0.5);
      }
    }

    .card-body {
      align-items: center;
      display: flex;

      .row {
        width: calc(100% + 12px);
        align-items: center;
      }

      .column {
        height: 100%;
      }
    }
  }

  &.filled:not(:last-child) .card {
    border-bottom: 1px solid #ddd;
  }

  &.normal:hover > .card,
  &.current.normal > .card {
    opacity: 1;
    cursor: pointer;
    box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.12);
  }
}
</style>
