<template>
  <div>
    <el-dialog
      v-model="isVisible"
      :width="isVisibleSuccess ? '785px' : '500px'"
      :id="id"
      :class="!isVisibleSuccess ? 'campaings-create-padding' : ''"
      append-to-body
    >
      <div class="container" v-if="!isVisibleSuccess">
        <h2 class="title">{{ $t('campaigns.create.title') }}</h2>
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label :for="`${id}-name`">{{ $t('campaigns.create.campaign-name') }}</label>
                <input
                  :id="`${id}-name`"
                  v-model="title"
                  type="text"
                  :placeholder="$t('campaigns.create.fill-with-name')"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label :for="`${id}-audience`">{{ $t('audiences.single-title') }}</label>
                <select :id="`${id}-audience`" v-model="audience">
                  <option :value="undefined">{{ $t('select') }}</option>
                  <option v-for="item in audiences" :key="item.id" :value="item.id">
                    {{ `${item.id} - ${item.title}` }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pr-2">
              <div class="form-group">
                <label :for="`${id}-date-start`">{{ $t('campaigns.create.date-start') }}</label>
                <div class="wrap element--datepicker">
                  <el-date-picker
                    v-model="dateStart"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :clearable="false"
                    :editable="false"
                    :prefix-icon="customPrefix"
                    :disabled-date="disabledDateInStartDate"
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label :for="`${id}-date-end`">{{ $t('campaigns.create.date-end') }}</label>
                <div class="wrap element--datepicker">
                  <el-date-picker
                    v-model="dateEnd"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :clearable="false"
                    :editable="false"
                    :prefix-icon="customPrefix"
                    :disabled-date="disabledDateInEndDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pr-2">
              <div class="form-group mb-0">
                <label :for="`${id}-investment`"
                  >{{ $t('investment') }} <span class="opt">({{ $t('optional') }})</span></label
                >
                <div class="wrap">
                  <input
                    :id="`${id}-investment`"
                    type="text"
                    v-model="formatInvestment"
                    :placeholder="$t('value')"
                    @blur="updateInvestment"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 pl-2">
              <div class="form-group mb-0">
                <label :for="`${id}-media`"
                  >{{ $t('used-medias') }} <span class="opt">({{ $t('optional') }})</span></label
                >
                <div class="wrap">
                  <div :id="`${id}-media`" class="select-fake" @click.prevent="toggleMedia">
                    {{ !medias.length ? $t('select') : mediasSelected }}
                  </div>
                  <div v-if="isToggleMedia" v-click-outside="toggleMedia" class="check-list">
                    <div v-for="(value, key) in mediasList" :key="key" class="select">
                      <label @click.prevent="$_toggleSelection(key)">
                        <check-box :checked="$_isSelected(key)" />
                        <div class="text">
                          <span class="name">{{ $t(value) }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <el-tooltip
                :visible="isVisible && isShowingTooltip"
                placement="right"
                popper-class="tooltip-default td--create-campaings"
                offset="8"
              >
                <template #content>
                  <span v-html="$t('campaigns.global-error')"></span>
                </template>
                <button :id="`${id}-submit`" class="btn confirm-button" :class="disabled ? 'disabled' : ''">
                  {{ $t('campaigns.create.finish-register') }}
                </button>
              </el-tooltip>
            </div>
          </div>
        </form>
      </div>
      <div v-else :id="`${id}-success`">
        <div class="container">
          <i class="icon icon-checked-60px" aria-hidden="true"></i>
          <p v-html="$t('campaigns.create.success')"></p>
          <div class="dialog">
            <button class="cancel btn btn-secondary" @click="hideSuccess">
              {{ $t('close') }}
            </button>
            <button class="confirm btn" @click="details">
              {{ $t('show-details') }}
            </button>
            <button
              class="confirm btn"
              @click="
                () => {
                  hideSuccess();
                  show();
                }
              "
            >
              {{ $t('campaigns.create.create-other-new') }}
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import bus from '@/helpers/events/bus';
import CheckBox from '@/components/_atoms/CheckBox';
import { getAudiences } from '@/services/audiences';
import { validate, create } from '@/services/campaigns';
import { dateFormat } from '@/utilities/constants';
import { formatCurrency } from '@/utilities/formatters';
import vClickOutside from 'click-outside-vue3';
import '@/plugins/tooltip/tooltip';
import { h, shallowRef } from 'vue';
import { disabledBackwardDates, disabledBackwardDatesFromStartDate } from '@/utilities/datepicker';

export default {
  components: {
    'check-box': CheckBox,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const customPrefix = shallowRef({
      render() {
        return h('i', {
          class: 'icon icon-calendar-30px',
        });
      },
    });

    return { customPrefix };
  },
  data() {
    return {
      id: 'campaigns-create',
      audiences: [],
      title: '',
      audience: undefined,
      dateStart: moment().format(dateFormat),
      dateEnd: moment().format(dateFormat),
      investment: undefined,
      formatInvestment: undefined,
      medias: [],
      mediasList: {
        email: 'email',
        mala_direta: 'direct-mail',
        remarketing: 'Remarketing',
        sms: 'SMS',
        search: 'Search',
        social: 'Social',
        telemarketing: 'Telemarketing',
        outra: 'other',
      },
      disabled: false,
      lastCampaignCreated: undefined,
      isVisible: false,
      isVisibleSuccess: false,
      isToggleMedia: false,
      isShowingTooltip: false,
    };
  },
  computed: {
    error() {
      return !validate.fields([
        [null, 'string', this.title],
        [null, null, this.audience],
        ['date_iso', null, this.dateStart],
        ['date_iso', null, this.dateEnd],
      ]);
    },
    mediasSelected() {
      const listString = this.medias
        .map(key => this.$t(this.mediasList[key]))
        .toString()
        .split(',')
        .join(', ');
      return listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
  },
  watch: {
    isVisible: function () {
      if (!this.isVisible) this.hide();
    },
    dateStart() {
      this.dateEnd = this.dateStart;
    },
  },
  mounted() {
    bus.$on('campaign-create-open', this.show);

    if (this.$route.name === 'campaignsCreate') this.show();
  },
  beforeUnmount() {
    this.hide();
    bus.$off('campaign-create-open');
  },
  methods: {
    updateAudiences() {
      getAudiences({
        orderby: this.orderBy,
      }).then(({ data }) => {
        this.audiences = data.data.map(data => ({
          id: data[0],
          title: data[1],
        }));
      });
    },
    updateInvestment() {
      this.investment = this.formatInvestment.replace(',', '.');
      // formata valor para ser exibido no input
      this.formatInvestment = formatCurrency(this.formatInvestment);
      // formata valor para ser enviado na requisição
    },
    show() {
      this.isVisible = true;
      this.isVisibleSuccess = false;
      this.isShowingTooltip = false;
      this.reset();
    },
    hide() {
      this.isVisible = false;
      if (this.$route.name === 'campaignsCreate') this.$router.push('/performance/campaigns');
    },
    reset() {
      this.updateAudiences();

      this.title = '';
      this.audience = undefined;
      this.disabled = false;
      this.dateStart = moment().format(dateFormat);
      this.dateEnd = moment().format(dateFormat);
      this.investment = undefined;
      this.formatInvestment = undefined;
      this.medias = [];

      this.isToggleMedia = false;
    },
    submit() {
      if (this.error) {
        this.isShowingTooltip = true;
      } else {
        this.disabled = true;
        this.isShowingTooltip = false;
        create({
          title: this.title,
          audience: this.audience,
          start: this.dateStart,
          end: this.dateEnd,
          investment: this.investment,
          media: this.medias.toString(),
        }).then(data => {
          this.isVisibleSuccess = true;
          this.lastCampaignCreated = data.data.data.cid;
          bus.$emit('render-cards');
        });
      }
    },
    details() {
      this.hideSuccess();
      this.$router.push(`/campaigns/${this.lastCampaignCreated}`);
    },
    hideSuccess() {
      this.isVisible = false;
    },
    toggleMedia() {
      this.isToggleMedia = !this.isToggleMedia;
    },
    $_isSelected(key) {
      return this.medias.includes(key);
    },
    $_toggleSelection(key) {
      if (this.$_isSelected(key)) this.unselectMedia(key);
      else this.selectMedia(key);
    },
    selectMedia(media) {
      if (!this.$_isSelected(media)) this.medias.push(media);
    },
    unselectMedia(media) {
      if (!this.$_isSelected(media)) return;

      const index = this.medias.indexOf(media);
      this.medias.splice(index, 1);
    },
    disabledDateInStartDate(time) {
      return disabledBackwardDates(time);
    },
    disabledDateInEndDate(time) {
      return disabledBackwardDatesFromStartDate(time, this.dateStart);
    },
  },
};
</script>

<style lang="scss" scoped>
#campaigns-create {
  .container {
    width: 85%;
  }

  .title {
    font-size: 18px;
    color: $gray-700;
    cursor: default;
    margin-bottom: 30px;
  }

  input,
  select {
    width: 100%;
  }

  .opt {
    font-weight: 400;
  }

  .wrap {
    position: relative;

    .icon:not(.check-box) {
      color: $gray-500;
      position: absolute;
      right: 15px;
      font-size: 24px;
      pointer-events: none;
    }
  }

  .check-list {
    position: absolute;
    bottom: -62px;
    left: 195px;
    width: 180px;
    padding: 18px 15px;
    background-color: white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    z-index: 100;
    &.hide {
      display: none;
    }
    .select {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        .check-box:not(.checked) {
          border-color: $gray-600;
        }
      }

      label {
        display: grid;
        align-items: center;
        user-select: none;
        cursor: pointer;
        margin: 0;
        grid-template-columns: 16px 1fr;

        .text {
          margin-left: 10px;
          color: $gray-700;
          .name {
            display: block;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .select-fake {
    width: 100%;
    overflow: hidden;
    padding-right: 25px;
  }
  .confirm-button {
    padding: 0 50px;
    margin: 30px auto;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
#campaigns-create-success {
  .container {
    width: 85%;
  }

  .icon {
    display: block;
    text-align: center;
    font-size: 76px;
    margin-bottom: 34px;
    color: $oto-ecommerce;
  }
}
</style>

<style lang="scss">
.campaings-create-padding {
  .el-dialog__body {
    padding: unset;
  }
}
#campaigns-create {
  #campaigns-create-success {
    p {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .dialog {
      display: flex;
      justify-content: center;
      margin: 28px 0 20px 0;
      gap: 20px;

      .btn {
        width: 205px;
        padding: 0 15px;
      }
    }
  }
}

.el-popper.td--create-campaings {
  width: 200px;
}
</style>
