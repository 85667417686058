import axios from 'axios';
import store from '@/store';

export default ({ endpoint = '/metrics/get_table_metrics', table = '', locale = 'pt-BR', filterId } = {}) => {
  const { user } = store.getters;
  const persona = store.getters.selectedPersona;

  return axios.get(endpoint, {
    params: {
      locale: user.language || locale,
      persona: persona.id,
      tbl: table,
      order_filters: filterId,
    },
  });
};
