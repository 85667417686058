import axios from 'axios';
import store from '@/store';

/**
 * @description get all personas
 * @returns {Object}
 */
export const setUserRead = warningId => {
  const brand = store.getters.selectedBrand.id;
  return axios.get('/warnings/userRead', {
    params: {
      brand,
      warningId,
    },
  });
};

export default setUserRead;
