<template>
  <div id="login-recover-alternative">
    <div class="card-header justify-content-start d-flex">
      <button class="back" @click.prevent="loaderBackToRecover()">
        {{ $t('back') }}
      </button>
    </div>
    <div class="card-body">
      <h3 class="title">{{ $t('login.other-recover') }}</h3>
      <p class="description" v-html="$t('login.recover-alternative-text')"></p>
    </div>
  </div>
</template>

<script>
import loginLoaderMixin from '@/helpers/mixins/loginLoaderMixin';

export default {
  mixins: [loginLoaderMixin],
  data() {
    return {
      loading: true,
      showText: true,
    };
  },
  methods: {
    loaderBackToRecover() {
      return this.$_loaderLogin('recover', this.loading, this.showText);
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: block;
  text-align: left;
  color: $gray-700;
  font-size: 12px;
  font-weight: normal;
}
</style>
