/** *
 * @$_loaderLogin: Mixin to set a timeout when loading doesn't set data.
 ** */
const mixin = {
  methods: {
    $_loaderLogin(page, loader, text) {
      this.$parent.changeLoginMode(page, loader, text);
      setTimeout(() => {
        this.$parent.changeLoginMode(page, !loader, text);
      }, 1000);
    },
  },
};

export default mixin;
