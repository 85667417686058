<template>
  <lightbox-template
    id="lightbox-last-order"
    :title="$t('360.profile-resume.last-purchase')"
    :show-segment-group="false"
    :icon="''"
    :show-date="false"
  >
    <div v-if="ready" class="container">
      <div class="header">
        <p>
          {{ $t('360.lightbox.last-order.purchase-made-in') }}
          <b>{{ formatDate(order.date) }}</b> ({{ dayNameDate(order.date) }})
          {{ $t('360.lightbox.last-order.in-store') }}
          <b class="store-name">{{ order.store }}</b
          >.
        </p>
        <p class="mt-3 text-uppercase">
          {{ $t('360.lightbox.last-order.order') }}: <b>{{ order.orderId }}</b>
        </p>
      </div>
      <div class="list-products">
        <div v-for="(item, index) in order.products" :key="index" class="row product">
          <div class="col-7 pl-0 pr-1 text-left d-flex">
            <div class="pr-3 icon-line">
              <i class="icon icon-in-store"></i>
            </div>
            <div class="detail d-flex flex-column">
              <b>{{ item.name }}</b>
              <span class="detail-value">{{ $t('360.lightbox.last-order.sku') }}: {{ item.sku }}</span>
              <span class="detail-value">
                {{ `${$t('360.lightbox.last-order.quantity')}: ` }}
                {{ $_formatDecimal(Math.abs(item.quantity), 0) }}
              </span>
            </div>
          </div>
          <div class="col-5 pl-1 pr-0">
            <div class="row">
              <div class="col-12 col-lg-6 text-right">
                <p class="label mb-0">
                  {{ $t('360.lightbox.last-order.unit-value') }}
                </p>
                <span class="detail-value item-unit-value" :class="{ 'color-red': item.price < 0 }">
                  {{ formatCurrency(item.price) }}
                </span>
              </div>
              <div class="col-12 col-lg-6 text-right">
                <p class="label mb-0">
                  {{ $t('360.lightbox.last-order.total-value') }}
                </p>
                <span class="item-value" :class="{ 'color-red': item.price < 0 }">
                  {{ formatCurrency(Math.abs(item.price * item.quantity)) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-12 text-right pt-3 d-flex flex-column">
            <p class="label text-uppercase mb-0">
              {{ $t('360.lightbox.last-order.total-value') }}
            </p>
            <b class="item-total-value color-oto-ecommerce">
              {{ formatCurrency(order.orderTotal) }}
            </b>
          </div>
        </div>
      </div>
    </div>
    <component-spinner :show-spinner="showSpinner"></component-spinner>
  </lightbox-template>
</template>

<script>
import moment from 'moment';
import { getLastOrder } from '@/services/oto/orders';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import ComponentSpinner from '@/components/_atoms/LoadSpinner';
import { numberMixin } from '@/helpers/mixins';
//helpers
import { dateWeekDay } from '@/utilities/constants';

export default {
  name: 'LightboxLastOrder',
  components: {
    ComponentSpinner,
    LightboxTemplate,
  },
  mixins: [numberMixin],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ready: false,
      order: {},
      showSpinner: true,
    };
  },
  computed: {
    count() {
      return this.text.length;
    },
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      const { customerId } = this.settings;

      this.showSpinner = true;
      this.ready = false;
      this.fetch(customerId);
    },
    formatDate(date) {
      return moment(date).format('LL');
    },
    dayNameDate(date) {
      return moment(date).format(dateWeekDay);
    },
    fetch(pmwebId) {
      this.showSpinner = true;

      getLastOrder(pmwebId)
        .then(res => {
          this.order = res.data;
          this.ready = true;
          this.error = '';
        })
        .catch(error => {
          this.order = {};
          this.error = error;
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
  },
};
</script>

<style lang="scss">
#lightbox-last-order {
  .product {
    color: $gray-700;
    font-size: 12px;
    padding: 25px 0;
    margin: 0;
    border-bottom: 1px solid $gray-300;
  }
  .store-name {
    color: #51bfda;
  }
  span.detail-value {
    font-size: 11px;
    color: $gray-600;
  }
  .item-value {
    font-size: 16px;
    font-weight: 600;
  }
  .item-total-value {
    font-size: 18px;
  }
  @media only screen and (max-width: $mobile-max-width) {
    .icon-line {
      display: none;
    }
  }
}
</style>
