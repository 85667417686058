<template>
  <div id="stores-register" class="stores-register">
    <header class="stores-register__header">
      <button class="icon icon-back-arrow" @click="back"></button>
      <h1 class="card-group-title stores-register__title">{{ title }}</h1>
    </header>
    <section class="register-form">
      <base-card-v2>
        <infos-form :mode="mode" />
        <locale-form />
        <custom-form />
        <modal-form :mode="mode" />
        <div class="stores-register__save">
          <button class="btn" @click="saveForm">{{ $t('settings.stores.save') }}</button>
        </div>
      </base-card-v2>
    </section>
  </div>
</template>

<script>
// libs
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { isEmpty } from 'lodash';
import { useToast } from 'vue-toastification';
// helpers
import bus from '@/helpers/events/bus';
// components
import InfosForm from '@/components/settings/stores/StoresRegisterInfosForm';
import LocaleForm from '@/components/settings/stores/StoresRegisterLocaleForm';
import CustomForm from '@/components/settings/stores/StoresRegisterCustomFieldsForm';
import ModalForm from '@/components/settings/stores/StoresModal';

export default {
  name: 'StoresSettingsRegister',
  components: {
    InfosForm,
    LocaleForm,
    CustomForm,
    ModalForm,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup() {
    const required = useVuelidate();
    const toast = useToast();

    return { required, toast };
  },
  computed: {
    ...mapGetters('settingsStores', ['getAllFormFields']),
    isEditMode() {
      return this.mode === 'edit';
    },
    title() {
      return this.isEditMode
        ? this.$t('settings.stores.register-store.edit-mode-title')
        : this.$t('settings.stores.register-store.title');
    },
  },
  mounted() {
    this.setMode(this.mode);

    // bring the data if it is duplicate or edit mode, to verify this, if there is an id, is one of this modes
    if (this.id) this.setActionId(this.id);
  },
  beforeMount() {
    this.resetFields();
  },
  methods: {
    ...mapActions('settingsStores', ['setActionId', 'setMode', 'resetFields']),
    back() {
      this.$router.push('/settings/stores');
    },
    saveForm() {
      const getRequiredFields = this.required.$silentErrors.map(item => item.$propertyPath);
      getRequiredFields.forEach(item => {
        bus.$emit('required-fields', item);
      });

      if (isEmpty(getRequiredFields)) {
        bus.$emit('hide-tooltips');
        bus.$emit('open-store-settings-modal');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.stores-register {
  &__save {
    display: flex;
    padding: 40px 20px 20px;
    justify-content: flex-end;
    button {
      width: 170px;
    }
  }

  &__title {
    margin: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    margin: 50px 0 40px;

    > button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: $gray-600;
      padding-left: 0;
    }
  }
}
</style>
