import { actions } from '@/helpers/permissions';

const featureId = 'cashback';

export const permissionBalance = {
  id: 'cashback-balance',
  featureId,
  action: actions.VIEW,
};

export const permissionHistoric = {
  id: 'cashback-history',
  featureId,
  action: actions.VIEW,
};

export const permissionValidate = {
  id: 'cashback-validation',
  featureId,
  action: actions.MODIFY,
};
