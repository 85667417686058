<template>
  <!-- eslint-disable max-len -->
  <base-card-v2>
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('revenue-by-channel')" icon="icon-money" />
      <omni-resume :data="values"></omni-resume>
      <svg
        id="Camada_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 903 615"
        style="enable-background: new 0 0 903 597"
        xml:space="preserve"
      >
        <g id="Camada_2_1_">
          <g id="Camada_1-2">
            <path
              class="st2"
              d="M445.8,25.8c-23.5,10.6-45,25.3-63.5,43.3c87.1,85,88.8,224.5,3.8,311.7c-1.3,1.3-2.5,2.6-3.8,3.8c18.5,18,39.9,32.7,63.5,43.3c111.1-50,160.5-180.6,110.5-291.7C534.2,
            87.2,494.9,47.9,445.8,25.8L445.8,25.8z"
            />
            <path
              class="st1"
              d="M536.4,26.4c110.7,0.1,200.5,89.9,200.4,200.7c-0.1,80.5-48.2,153.2-122.4,184.5c-102.4,42.2-219.6-6.5-261.8-108.9c-41.7-101.2,5.4-217.1,105.8-260.5C483,31.7,509.6,
            26.3,536.4,26.4 M536.4,6.4c-121.8,0-220.5,98.7-220.5,220.5s98.7,220.5,220.5,220.5s220.5-98.7,220.5-220.5S658.1,6.4,536.4,6.4z"
            />
            <path
              class="st2"
              d="M228.4,26.4c110.7,0.1,200.5,89.9,200.4,200.7c-0.1,80.5-48.2,153.2-122.4,184.5C204,453.8,86.8,405.1,44.5,302.7C2.8,201.5,49.9,85.6,150.4,42.1C175,31.7,201.6,26.3,
            228.4,26.4 M228.4,6.4C106.6,6.4,7.9,105.1,7.9,226.9s98.7,220.5,220.5,220.5s220.5-98.7,220.5-220.5S350.1,6.4,228.4,6.4z"
            />
            <path
              class="st3"
              d="M382.4,101.5c62,74.3,62,176.4,0,250.7C325.4,282.4,320.3,175.8,382.4,101.5 M382.4,69.1c-87.1,85-88.8,224.6-3.8,311.7c1.3,1.3,2.5,2.6,3.8,3.8c87.1-85,88.8-224.6,
            3.8-311.7C384.9,71.6,383.6,70.4,382.4,69.1z"
            />
          </g>
        </g>
        <text transform="matrix(1 0 0 1 651 272)" class="st4 st9 thin">
          {{ $_formatNumber(values.revenueOffPercent, '0%') }}
        </text>
        <text transform="matrix(1 0 0 1 646 202)" class="st7 st6 uppercase strong">
          {{ $tc('store', 1) }}
        </text>
        <text transform="matrix(1 0 0 1 62 237)" class="st4 st9">
          {{ formatCurrency(values.revenueOn.amount, { shortNumber: true, noSymbol: true, max: 1 }) }}
        </text>
        <text transform="matrix(1 0 0 1 61.8412 202)" class="st10 st6 uppercase strong">
          {{ $t('ecommerce') }}
        </text>
        <text text-anchor="middle" transform="matrix(1 0 0 1 510 239)" class="st11 st9">
          {{ formatCurrency(values.revenueTrackedOff.amount, { shortNumber: true, noSymbol: true }) }}
        </text>
        <text text-anchor="middle" transform="matrix(1 0 0 1 383 239)" class="st3 st17">
          {{ formatCurrency(values.revenueOmni.amount, { shortNumber: true, noSymbol: true, max: 0 }) }}
        </text>
        <text
          v-tooltip="{
            content: $_formatNumber((values.revenueTrackedOff.amount / values.revenueOff.amount) * 100, '0.000000%'),
          }"
          transform="matrix(1 0 0 1 485 525)"
          class="st12 st13"
        >
          {{ $_formatNumber((values.revenueTrackedOff.amount / values.revenueOff.amount) * 100, '0.00%') }}
        </text>
        <text transform="matrix(1 0 0 1 670  500)">
          <tspan x="0" y="0" class="st14 st15 uppercase strong">
            {{ $t('digital-influenced1') }}
          </tspan>
          <tspan x="0" y="30" class="st14 st15 uppercase strong">
            {{ $t('digital-influenced2') }}
          </tspan>
        </text>
        <g id="linha">
          <g>
            <rect x="444.5" y="382.2" class="st16" width="2.3" height="154.1" />
          </g>
        </g>
        <text
          v-tooltip="{
            content: $_formatNumber((values.revenueOmni.amount / values.revenue.amount) * 100, '0.000000%'),
          }"
          transform="matrix(1 0 0 1 230 525)"
          class="st3 st13"
        >
          {{ $_formatNumber((values.revenueOmni.amount / values.revenue.amount) * 100, '0.00%') }}
        </text>
        <text transform="matrix(1 0 0 1 10 505)">
          <tspan x="90" y="0" class="st14 st15 uppercase strong">
            {{ $t('omnichannel-revenue1') }}
          </tspan>
          <tspan x="8" y="30" class="st14 st15 uppercase strong">
            {{ $t('omnichannel-revenue2') }}
          </tspan>
        </text>
        <g id="linha_1_">
          <g>
            <rect x="378.6" y="368.2" class="st16" width="2.3" height="166.2" />
          </g>
        </g>
        <text transform="matrix(1 0 0 1 62 272)" class="st4 st9 thin">
          {{ $_formatNumber((values.revenueOmni.amount / values.revenue.amount) * 100, '0%') }}
        </text>
        <text text-anchor="end" transform="matrix(1 0 0 1 710 237)" class="st4 st9">
          {{ formatCurrency(values.revenueOff.amount, { shortNumber: true, noSymbol: true, max: 1 }) }}
        </text>
      </svg>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="error" />
  </base-card-v2>
</template>

<script>
import { numberMixin, verifyMixin } from '@/helpers/mixins';
import OmnichannelOverviewResume from '@/components/overview/OmnichannelOverviewResume';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

import bus from '@/helpers/events/bus';
import getMetrics from '@/services/getmetrics';
import { getNumeral } from '@/utilities/numbers';

export default {
  name: 'OmnichannelOverviewDiagramaVenn',
  components: {
    'omni-resume': OmnichannelOverviewResume,
    BaseLoader,
    InfoCard,
  },
  mixins: [numberMixin, verifyMixin],
  data() {
    return {
      values: {
        revenue: 0,
        revenueOmni: 0,
        revenueOff: 0,
        revenueOffPercent: 0,
        revenueOn: 0,
        revenueOnPercent: 0,
        revenueTracked: 0,
        revenueTrackedOff: 0,
      },
    };
  },
  mounted() {
    this.setupMetrics();
    bus.$on('render-cards', this.setupMetrics);
  },
  methods: {
    setup() {
      this.setupMetrics();
    },
    setupMetrics() {
      this.$_reqConfig();

      getMetrics({
        metrics: ['revenue', 'revenue_on', 'revenue_off', 'revenue_omni', 'revenue_tracked', 'revenue_tracked_off'],
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) return;

          const { metrics } = data;
          this.values.revenue = this.split(metrics.revenue);
          this.values.revenueOn = this.split(metrics.revenue_on);
          this.values.revenueOnPercent = (this.values.revenueOn.amount / this.values.revenue.amount) * 100;
          this.values.revenueOff = this.split(metrics.revenue_off);
          this.values.revenueOffPercent = (this.values.revenueOff.amount / this.values.revenue.amount) * 100;
          this.values.revenueOmni = this.split(metrics.revenue_omni);
          this.values.revenueTrackedOff = this.split(metrics.revenue_tracked_off);
          this.values.revenueTracked = this.split(metrics.revenue_tracked);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    split(data) {
      const obj = {};
      if (data.data.value) {
        obj.amount = getNumeral(data.data.value.amount);
      } else if (data.data.series) {
        obj.clients = getNumeral(data.data.series.data.Clientes);
        obj.revenue = getNumeral(data.data.series.data.Receita);
        obj.revenuePerClient = getNumeral(obj.revenue / obj.clients);
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  font-weight: 600;
  width: 100%;

  .thin {
    font-weight: 400;
  }

  .st0 {
    fill: #b88de3;
  }

  .st1 {
    fill: $purple-400;
  }

  .st2 {
    fill: $oto-ecommerce;
  }

  .st3 {
    fill: $oto-omni;
  }

  .st4 {
    fill: $gray-700;
  }

  .st6 {
    font-size: 22.725px;
  }

  .st7 {
    fill: $purple-400;
  }

  .st9 {
    font-size: 27px;
  }

  .st10 {
    fill: $oto-ecommerce;
  }

  .st11 {
    fill: $color-white;
  }

  .st12 {
    fill: $oto-ecommerce;
  }

  .st13 {
    font-size: 45.45px;
  }

  .st14 {
    fill: $gray-700;
  }

  .st15 {
    font-size: 22.9975px;
  }

  .st16 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: $gray-500;
  }

  .st17 {
    font-size: 24px;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .strong {
    font-weight: bold;
  }
}
</style>
