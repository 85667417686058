<template>
  <div class="register-customer-header">
    <div class="rch__title-wrapper">
      <icon-base :color="'#BBB'" :box="'0 0 20 20'" w="24" h="24">
        <icon-user />
      </icon-base>
      <h1 class="rch__title">{{ $t('360.register-customer.title') }}</h1>
    </div>
    <div class="rch__cpf-wrapper">
      <label>{{ $t('360.register-customer.cpf') }}</label>
      <input
        data-cy="input_cpf_customer"
        ref="documentNumber"
        v-model="document_number"
        class="rch__input"
        :placeholder="'000.000.000-00'"
      />
      <div v-if="loading" class="rch__loader">
        <base-loader :height="'25'" />
      </div>
    </div>
  </div>
</template>

<script>
// helpers
import bus from '@/helpers/events/bus';
import { useToast } from 'vue-toastification';

import { verifyCustomerId } from '@/services/oto/customers';
import IconBase from '@/assets/vue-icons/IconBase';
import IconUser from '@/assets/vue-icons/icons/User';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

import { ddi } from '@/utilities/constants';

export default {
  name: 'RegisterCustomerHeader',
  components: {
    IconBase,
    IconUser,
    BaseLoader,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      document_number: '',
      loading: false,
      ddi: '',
    };
  },
  watch: {
    document_number() {
      this.changeDocumentNumber();
    },
  },
  methods: {
    changeDocumentNumber() {
      // remove special characters of document_number
      const document_number = this.document_number.replace(/\D/g, '');

      // check if document_number with mask has the right length
      if (document_number.length === 11 || document_number.length === 14) {
        this.loading = true;

        verifyCustomerId(document_number)
          .then(data => {
            if (data.status === 204) {
              // enable form
              bus.$emit('validate-new-customer', {
                document_number: this.document_number,
                isDisabled: false,
                ddi: ddi.br,
              });
            } else {
              // disabled form
              bus.$emit('validate-new-customer', { document_number: null, isDisabled: true, ddi: ddi.br });

              this.toast.error('Cliente já cadastrado');
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // disabled form
        bus.$emit('validate-new-customer', { document_number: null, isDisabled: true, ddi: ddi.br });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-customer-header {
  height: 170px;
  padding: 20px 50px;
  background: url(~@/assets/img/figures/genericOtoBgBig.png) no-repeat right 0 center, #fff;

  .rch__title-wrapper {
    display: flex;

    .rch__title {
      font-size: 18px;
    }
    .icon {
      margin: 12px 10px 0 0;
    }
  }
  .rch__cpf-wrapper {
    margin-left: 5px;
    position: relative;
    max-width: 270px;
    box-sizing: border-box;

    .rch__input {
      width: 270px;
    }
    label {
      font-weight: 600;
    }
  }
}

.rch__loader {
  right: 0;
  position: absolute;
  width: 30px;
  right: 5px;
  top: 23px;
  height: 35px;
}
</style>
