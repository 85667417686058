<template>
  <div class="card suggestion-edit-header">
    <!-- campaign name -->
    <div class="sdh__container">
      <div>
        <label>{{ $t('360.campaigns.details.name') }}</label>
        <input
          :disabled="loadingSuggestionsDetail"
          class="sdh__name-input"
          type="text"
          name="name"
          v-model="suggestionDetail.name"
        />
      </div>
    </div>

    <ul class="seh__nav nav nav-tabs nav-justified">
      <li class="seh__item nav-item">
        <a
          class="seh__link nav-link"
          :class="{ active: isActive('composition') }"
          @click.prevent="setActive('composition')"
          >{{ $t('campaigns.action-composition') }}</a
        >
      </li>
      <li v-if="suggestionDetail.isEnabledApproval" class="seh__item nav-item">
        <a
          class="seh__link nav-link"
          :class="{ active: isActive('participants') }"
          @click.prevent="setActive('participants')"
          >{{ $t('campaigns.list-of-participants') }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
// libs
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'CampaignsManagementEditHeader',
  data() {
    return {
      activeItem: 'composition',
    };
  },
  computed: {
    ...mapState('campaignsManagement', ['suggestionDetail', 'loadingSuggestionsDetail']),
    ...mapGetters('campaignsManagement', ['getActivePage']),
  },
  mounted() {
    this.resetData();
  },
  methods: {
    ...mapActions('campaignsManagement', ['updateSuggestionName', 'setActivePage', 'resetData']),

    changeName(e) {
      this.updateSuggestionName(e.target.value);
    },

    isActive(menuItem) {
      return this.getActivePage === menuItem;
    },
    setActive(menuItem) {
      this.setActivePage(menuItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-edit-header {
  position: relative;
  margin-bottom: 20px;
  height: 156px;
  width: 100%;
  margin: 10px 0 30px;
  padding: 30px 50px;
  background: url(~@/assets/img/figures/genericOtoBgBig.png) no-repeat right -70px center, white;

  .sdh__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input.sdh__name-input {
      width: 330px;
    }
  }
  .seh__nav {
    width: 40%;
    margin-top: 28px;
    border: none;
  }

  .seh__item {
    justify-content: flex-start;
    flex-grow: initial;
    flex-basis: initial;
    cursor: pointer;

    &:first-child {
      margin-left: -10px;
    }
  }

  .seh__link {
    color: $gray-700;
    border: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    &.active {
      color: $oto-omni;
      border-bottom: 3px solid $oto-omni;
    }
  }
}
</style>
