<template>
  <div id="category">
    <header class="title-area">
      <button class="icon icon-back-arrow" @click="back"></button>
      <h1 class="card-group-title">{{ $tc('category') }}</h1>
    </header>
    <category-overview :category="category" />
    <category-client :category="category" />
    <category-orders :category="category" />
    <subcategories :category="category" />
    <sales-per-brand :category="category" />
    <sales-per-store :category="category" />
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import CategoryOverview from '@/components/categories/category/overview/CategoryOverview';
import Subcategories from '@/components/categories/category/subcategories/Subcategories';
import SalesPerBrand from '@/components/categories/category/brands/SalesPerBrand';
import SalesPerStore from '@/components/categories/category/stores/SalesPerStore';
import CategoryOrders from '@/components/categories/category/orders/CategoryOrders';
import CategoryClient from '@/components/categories/category/client/CategoryClient';
// utilities
import { useMixpanelConfigCategorytDetail } from '@/utilities/mixpanel';

export default {
  components: {
    CategoryOverview,
    CategoryOrders,
    CategoryClient,
    Subcategories,
    SalesPerBrand,
    SalesPerStore,
  },
  data() {
    return {
      category: this.$route.params.name,
    };
  },
  mounted() {
    useMixpanelConfigCategorytDetail(
      'view_analytics_products_detail_category',
      this.category.charAt(0).toUpperCase() + this.category.slice(1),
    );
    bus.$on('update-selected-brand', this.back);
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
  },
  methods: {
    back() {
      this.$router.push('/products');
    },
  },
};
</script>
<style lang="scss" scoped>
.title-area {
  display: flex;
  align-items: center;
  margin: 50px 0 40px;

  > button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $gray-600;
    padding-left: 0;
  }

  .card-group-title {
    margin: 0;
  }
}
</style>
