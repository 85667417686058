<template>
  <button class="btn btn-secondary filter-btn" @click.prevent="openFilter">
    <i class="icon icon-filter"></i>
    {{ $t('filter-table') }}
  </button>
</template>
<script>
// libs
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

export default {
  name: 'RawDataAccessFilterButton',
  setup() {
    const store = useStore();

    // actions
    const setIsFilterOpen = boolean => store.dispatch('rawDataAccess/setIsFilterOpen', boolean);

    // getters
    const getIsFilterOpen = computed(() => store.getters['rawDataAccess/getIsFilterOpen']);

    // mounted
    onMounted(() => {
      setIsFilterOpen(false);
    });

    function openFilter() {
      setIsFilterOpen(!getIsFilterOpen.value);
    }

    return {
      openFilter,
    };
  },
};
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
