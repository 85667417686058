<template>
  <div v-if="getAll.length" :class="contextType" class="dropdown-persona dropdown-toggle">
    <button
      type="button"
      class="btn persona-selected"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <persona-item :persona="getSelected()" level="-selected" />
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <template v-for="persona in getFiltered()" :key="persona.id">
        <button class="dropdown-item" type="button" @click="updatePersona(persona.id)">
          <persona-item :persona="persona" :active="getSelected().id === persona.id ? '-active' : ''" />
        </button>
      </template>
      <button
        v-if="contextType === 'ctx-compared'"
        :class="['dropdown-item', contextType]"
        type="button"
        @click="updatePersona(personaDatabase().id)"
      >
        <persona-item
          :persona="personaDatabase()"
          :active="getSelected().id === personaDatabase().id ? '-active' : ''"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PersonaItem from '@/components/personasCompare/_atoms/PersonaItem';

export default {
  name: 'PersonaDropdown',
  components: {
    'persona-item': PersonaItem,
  },
  props: {
    isContextMain: {
      type: Boolean,
      default: false,
    },
    contextType: {
      type: String,
      default: 'ctx-compared',
    },
  },
  computed: {
    ...mapGetters({
      getAll: 'personas/getAll',
      getAllFiltered: 'personas/getAllFiltered',
      getPersonaMain: 'personas/getPersonaMain',
      getPersonaCompared: 'personas/getPersonaCompared',
      getAllFilteredMain: 'personas/getAllFilteredMain',
      getAllFilteredCompared: 'personas/getAllFilteredCompared',
      getSelectedByContext: 'personas/getByContext',
    }),
  },
  mounted() {
    if (this.isContextMain) this.updatePersonas();
  },
  methods: {
    ...mapActions({
      updatePersonas: 'personas/updatePersonas',
      updatePersonaMain: 'personas/updatePersonaMain',
      updatePersonaCompared: 'personas/updatePersonaCompared',
    }),
    getSelected() {
      if (this.isContextMain) {
        return this.getPersonaMain;
      }
      return this.getPersonaCompared;
    },
    getFiltered() {
      if (this.isContextMain) {
        return this.getAllFilteredMain;
      }
      return this.getAllFilteredCompared;
    },
    updatePersona(id) {
      if (this.isContextMain) {
        this.updatePersonaMain(id);
      } else {
        this.updatePersonaCompared(id);
      }
    },
    personaDatabase() {
      return { name: 'Toda a base', id: 0 };
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-persona {
  width: 232px;
  height: 60px;
  display: flex;
  border-right: 1px solid #ddd;
  cursor: pointer;
  &::after {
    color: #888888;
    align-self: center;
    left: -25px;
    position: relative;
  }
  &.ctx-main {
    border-left: 1px solid #ddd;
  }

  .dropdown-menu {
    width: 232px;
    margin: 0;
    padding: 0;
    border: 1px solid #dddddd;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    .dropdown-item {
      padding-top: 0;
      padding-bottom: 0;
      background-color: #61cae410;
      &:active {
        outline: 0;
        background-color: transparent;
      }
    }
    img {
      padding: 10px 0;
      border-bottom: 1px solid #eeeeee;
      margin: 0 15px;
    }
  }
  .persona-selected {
    background: rgba(108, 144, 244, 0.07) !important;
    width: 100%;
    padding: 10px 20px;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }
}
</style>
