<template>
  <table-list :data="dataTable" class="loadHeight" type="filled">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column><modal-dates /></table-list-column>
        <table-list-column v-if="showLeads" class="text-right">{{ $t('lost-leads') }}</table-list-column>
        <table-list-column v-if="showLeads" class="text-right">{{
          $t('not-converted-leads-abbrev')
        }}</table-list-column>
        <table-list-column v-if="showLeads" class="text-right">{{ $t('potential-leads') }}</table-list-column>
        <table-list-column class="text-right">{{ $tc('lost-client', 0) }}</table-list-column>
        <table-list-column class="text-right">{{ $tc('inactive-client', 0) }}</table-list-column>
        <table-list-column class="text-right">{{ $t('active-clients') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('new') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('retained') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('abrev-recovered') }}</table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row
          v-for="(item, index) in dataTable.rows"
          :key="`lightbox-recency-${index}`"
          :class="{ active: item.segment_id == segmentSelected }"
          :click-callback="() => $_handleTableRow(item.segment_id, index)"
        >
          <table-list-column> <i class="icon icon-filter"></i>{{ item.name }} </table-list-column>
          <table-list-column v-if="showLeads" class="text-right">{{
            $_formatNumber(item.metrics.leads_lost, '0a')
          }}</table-list-column>
          <table-list-column v-if="showLeads" class="text-right">{{
            $_formatNumber(item.metrics.leads_not_converted, '0a')
          }}</table-list-column>
          <table-list-column v-if="showLeads" class="text-right">{{
            $_formatNumber(item.metrics.leads_potential, '0a')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.customers_lost, '0a')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.customers_inactive, '0a')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.customers_active, '0a')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.customers_new, '0a')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.customers_recurring, '0a')
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatNumber(item.metrics.customers_regained, '0a')
          }}</table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </table-list>
</template>

<script>
import getTable from '@/services/getTable';
import ModalDates from '@/components/ModalDates';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'DatabaseTable',
  components: {
    ModalDates,
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  props: {
    segmentSelected: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ready: false,
      dataTable: {
        count: 0,
      },
    };
  },
  computed: {
    showLeads() {
      return this.$store.getters.selectedSegment.segmentGroupId;
    },
  },
  mounted() {
    this.reset();
    this.loadTable();
  },
  methods: {
    loadTable() {
      getTable({
        metrics: [
          'leads_potential',
          'leads_not_converted',
          'customers_active',
          'customers_lost',
          'customers_inactive',
          'customers_new',
          'customers_recurring',
          'leads_lost',
          'customers_regained',
        ],
        expand: 1,
        isLightbox: true,
      })
        .then(({ data: { data } }) => {
          if (this.$_verifyHasData(data)) return;
          this.dataTable.rows = data.map(({ name, metrics, segment_id }) => ({
            // eslint-disable-line
            name,
            metrics,
            segment_id,
          }));

          this.dataTable.count = this.dataTable.rows.length;
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$_reqConfig();
      this.ready = false;
      this.dataTable.rows = [];
    },
  },
};
</script>
