<template>
  <div class="overview row">
    <div class="col-lg-5 col-12">
      <product-details :details="product" :error="hasError" :load="loading" />
    </div>
    <div class="col-lg-3 col-12">
      <product-photo :url="productImg" :error="hasError" :alt="product.name" :load="loading" />
    </div>
    <div class="col-lg-4 col-12">
      <product-overview :overview="product" :error="hasError" :load="loading" />
    </div>
  </div>
</template>
<script>
import Details from '@/components/products/product/overview/Details';
import Photo from '@/components/products/product/overview/Photo';
import Overview from '@/components/products/product/overview/Overview';
import bus from '@/helpers/events/bus';
import { getOverview } from '@/services/product';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  components: {
    'product-details': Details,
    'product-photo': Photo,
    'product-overview': Overview,
  },
  mixins: [verifyMixin],
  props: {
    productID: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      product: {},
      productImg: null,
    };
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', this.fetchData);
  },
  beforeUnmount() {
    bus.$off('render-cards', this.fetchData);
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getOverview(this.productID)
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.product = {};
            if (this.product.name === undefined || this.productID === undefined) {
              bus.$emit('update-search-fixed-data', {
                title: this.$t('empty-product-message'),
                subtitle: '',
              });
            }
            this.productImg = null;
            return;
          }
          this.product = data.data;
          this.productImg = this.product.product_img;

          // update title and subtitle of search bar
          if (this.product.name === undefined || this.productID === undefined) {
            bus.$emit('update-search-fixed-data', {
              title: this.$t('empty-product-message'),
              subtitle: '',
            });
          } else {
            bus.$emit('update-search-fixed-data', {
              title: this.product.name,
              subtitle: `${this.$t('product.ref')}: ${this.productID}`,
            });
          }
        })
        .catch(() => {
          this.product = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.overview {
  figure,
  .product-overview,
  .product-details {
    height: 270px;
  }
}
</style>
