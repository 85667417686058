import { createApp } from 'vue';
import App from '@/App';
import numFormat from '@/helpers/filter-number-format';
import dateFormat from '@/helpers/filter-date-format';

const Vue = createApp(App);

// Vue Filter for Numeral.js
Vue.use('numFormat', numFormat);

// Vue Filter for Moment.js
Vue.use('dateFormat', dateFormat);

Vue.use('capitalize', value => {
  if (!value) return '';
  const res = value.toString();
  return res.charAt(0).toUpperCase() + res.slice(1);
});
