<template>
  <div class="suggestion-details-header">
    <div class="sdh__container">
      <div>
        <label
          v-tooltip.right="{
            theme: 'error',
            html: true,
            autoHide: false,
            distance: 8,
            content: $t('campaigns.required-field'),
            shown: !suggestionDetail.name && !loadingSuggestionsDetail && tooltipEmptyError.name,
          }"
          >{{ $t('360.campaigns.details.name') }} <span class="required">*</span></label
        >
        <input class="sdh__name-input" type="text" name="name" v-model="suggestionDetail.name" />
      </div>
      <div v-show="getHasCampaignApproval" class="sdh__approve-container">
        <label class="label sdh__checkbox" @click.prevent="toggleNeedApprove">
          <check-box class="checkbox" :checked="!suggestionDetail.isEnabledApproval" />
          <span class="sdh__approve">{{ $t('360.campaigns.details.label-approval') }}</span>
        </label>
      </div>
    </div>
    <div v-show="suggestionDetail.isEnabledApproval && getHasCampaignApproval" class="sdh__container">
      <div class="sdh__textarea-wrapper">
        <label>{{ $t('360.campaigns.details.text-area-label') }}</label>
        <textarea
          v-model="suggestionDetail.description"
          name="message"
          cols="30"
          rows="3"
          :placeholder="$tc('360.campaigns.details.placeholder')"
        ></textarea>
      </div>
      <div class="sdh__select-fake-wrapper">
        <div id="comparison-criteria" class="form-group mt-3">
          <label>{{ $t('360.campaigns.details.criteria') }}</label>
          <div
            id="selectCriteria"
            class="select-fake metrics no-click"
            @click.prevent="toggleDropdown('criteriaOpened')"
          >
            {{ $t(`stores.criteria-selection-${criteriaSelectedName}`) }}
          </div>
          <div v-show="criteriaOpened" class="check-list dropdown-menu no-click">
            <div class="list no-click">
              <div class="select no-click">
                <label class="no-click" @click.prevent="toggleCriteria('cluster')">
                  <check-box class="no-click" :checked="criteriaSelected" />
                  <div class="text no-click">
                    <span class="name no-click">{{ $t('stores.criteria-selection-cluster') }}</span>
                  </div>
                </label>
              </div>
              <div class="select no-click">
                <label class="no-click" @click.prevent="toggleCriteria('region')">
                  <check-box class="no-click" :checked="!criteriaSelected" />
                  <div class="text no-click">
                    <span class="name no-click">{{ $t('stores.criteria-selection-region') }}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div id="comparison-stores" class="form-group mt-3">
          <label>{{ $t('360.campaigns.details.stores-approval') }}</label>
          <div id="selectStores" class="select-fake stores no-click" @click.prevent="toggleDropdown('storesOpened')">
            {{ criteriaSelectedName === 'cluster' ? clustersSelected : regionsSelected }}
          </div>
          <div v-if="criteriaSelectedName === 'region'" v-show="storesOpened" class="check-list dropdown-menu no-click">
            <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-regions') }}</div>
            <div
              v-for="(value, key) in comparisonStoresList"
              :key="key"
              class="select no-click"
              :class="`select-${key}`"
            >
              <label class="no-click" @click.prevent="toggleRegion(key)">
                <check-box class="no-click" :checked="isSelected(key, selectedRegions)" />
                <div class="text no-click">
                  <span class="name no-click">{{ key }}</span>
                </div>
                <i class="icon icon-right-14px no-click"></i>
              </label>
              <div class="check-list-child no-click">
                <div class="list no-click">
                  <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-states') }}</div>
                  <div class="searchStore no-click">
                    <input
                      v-model="term"
                      type="text"
                      class="no-click"
                      :placeholder="$t('stores.stores-search')"
                      autocomplete="none"
                    />
                    <i class="icon icon-search no-click"></i>
                  </div>
                  <div
                    v-for="(stores, index) in value"
                    v-show="filterResults(stores)"
                    :key="index"
                    class="select no-click"
                    :class="`select-${index}`"
                  >
                    <label class="no-click" @click.prevent="toggleState(key, index)">
                      <check-box class="no-click" :checked="isSelected(index, selectedStates)" />
                      <div class="text state no-click">
                        <span class="name no-click">{{ index }}</span>
                      </div>
                    </label>
                    <div class="check-list-subchild no-click">
                      <div v-for="store in stores" :key="store.id" class="select no-click">
                        <label
                          v-show="filterResults(store)"
                          :class="['no-click', checkboxDisabled(store.id) ? 'disabled' : '']"
                          @click.prevent="toggleStore(key, index, store.id)"
                        >
                          <check-box
                            class="no-click"
                            :checked="isSelected(store.id, filters.comparisonStores)"
                            :disabled="checkboxDisabled(store.id)"
                          />
                          <div class="text no-click">
                            <span class="name no-click">{{ $tc('store', 1) }} {{ store.name }}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else v-show="storesOpened" class="check-list dropdown-menu no-click">
            <div class="label text-uppercase mb-1 no-click">{{ $t('stores-filter-regions') }}</div>
            <div
              v-for="(stores, key) in orderbyClusterList"
              :key="key"
              class="select no-click"
              :class="`select-${key}`"
            >
              <label class="no-click" @click.prevent="toggleCluster(key)">
                <check-box class="no-click" :checked="isSelected(key, selectedClusters)" />
                <div class="text no-click">
                  <span class="name no-click">{{ key }}</span>
                </div>
                <i class="icon icon-right-14px no-click"></i>
              </label>
              <div class="check-list-child no-click">
                <div class="list no-click">
                  <div class="label text-uppercase mb-1 no-click">{{ $t('stores-title') }}</div>
                  <div class="searchStore no-click">
                    <input
                      v-model="term"
                      type="text"
                      class="no-click"
                      :placeholder="$t('stores.stores-search')"
                      autocomplete="none"
                    />
                    <i class="icon icon-search no-click"></i>
                  </div>
                  <div
                    v-for="store in stores"
                    v-show="filterResults(store)"
                    :key="store.id"
                    class="select no-click"
                    :class="`select-${store.id}`"
                  >
                    <label
                      :class="['no-click', checkboxDisabled(store.id) ? 'disabled' : '']"
                      @click.prevent="toggleStore(key, null, store.id)"
                    >
                      <check-box
                        class="no-click"
                        :checked="isSelected(store.id, filters.comparisonStores)"
                        :disabled="checkboxDisabled(store.id)"
                      />
                      <div class="text state no-click">
                        <span class="name no-click">{{ $tc('store', 1) }} {{ store.store }}</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="note">
            <strong>*</strong>{{ $t('360.campaigns.details.note.text') }}
            <span>{{ $t('360.campaigns.details.note.span') }}</span>
            {{ $t('360.campaigns.details.note.text-2') }}
            {{ $t('360.campaigns.details.note.text-3') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import { mapState, mapActions, mapGetters } from 'vuex';
// components
import CheckBox from '@/components/_atoms/CheckBox';

export default {
  name: 'CampaignsManagementDetailsHeader',
  components: {
    'check-box': CheckBox,
  },
  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      term: '',
      storesList: '',
      ready: false,
      criteriaOpened: false,
      criteriaSelected: true,
      storesOpened: false,
      selectedClusters: [],
      selectedRegions: [],
      selectedStates: [],
      criteriaSelectedName: 'cluster',
      clustersSelected: this.$t('select'),
      regionsSelected: this.$t('select'),
    };
  },
  computed: {
    ...mapState('campaignsManagement', ['suggestionDetail', 'mode', 'loadingSuggestionsDetail', 'tooltipEmptyError']),
    ...mapGetters(['getHasCampaignApproval']),
    comparisonStoresList() {
      const list = this.getComparisonStoresList();
      const regions = Object.keys(this.getComparisonStoresList());
      regions.forEach(region => {
        const states = Object.keys(list[region]);
        // ordena lojas de A-Z
        states.forEach(state => {
          list[region][state].sort((a, b) => (a.name > b.name ? 1 : -1));
        });
      });
      return list;
    },
    orderbyClusterList() {
      return this.groupByCluster();
    },
  },
  watch: {
    criteriaSelectedName() {
      this.selectedClusters = [];
      this.selectedRegions = [];
      this.selectedStates = [];
      this.clustersSelected = this.$t('select');
      this.regionsSelected = this.$t('select');
    },
  },
  mounted() {
    this.bodyClick();

    if (this.mode === 'create') {
      this.setStatusTooltip({ key: 'name', value: false });
    }
  },
  updated() {
    this.$nextTick(() => {
      this.getStores();
      this.watchSelectedClusters();
      this.watchSelectedRegions();
    });
  },
  methods: {
    ...mapActions('campaignsManagement', [
      'updateSuggestionName',
      'updateSuggestionDescription',
      'setEnabledApproval',
      'updateSuggestionStores',
      'setStatusTooltip',
    ]),

    changeName(e) {
      this.updateSuggestionName(e.target.value);
    },

    toggleNeedApprove() {
      this.setEnabledApproval();
    },
    getStores() {
      this.storesList = this.filters.comparisonStores.toString();
      this.suggestionDetail.stores = this.storesList;
      this.updateSuggestionStores(this.suggestionDetail.stores);
    },
    toggleDropdown(current) {
      this[current] = !this[current];
      if (current === 'storesOpened') this.storesRegion = false;
      else if (current === 'metricsOpened') this.criteriaOpened = false;
      else this.storesOpened = false;
    },
    isSelected(key, obj) {
      return !!obj.includes(key);
    },
    toggleSelection(key, obj) {
      if (this.isSelected(key, obj)) this.unselectOption(key, obj);
      else this.selectOption(key, obj);
    },
    selectOption(key, obj) {
      if (!this.isSelected(key, obj)) obj.push(key);
    },
    unselectOption(key, obj) {
      if (!this.isSelected(key, obj)) return;

      const index = obj.indexOf(key);
      obj.splice(index, 1);
    },
    toggleStore(region, state, store) {
      if (!this.isSelected(store, this.filters.comparisonStores)) {
        // seleciona a loja e seus respectivos estados e regiao
        this.selectOption(store, this.filters.comparisonStores);
        if (this.criteriaSelectedName !== 'cluster') {
          this.selectOption(state, this.selectedStates);
          this.selectOption(region, this.selectedRegions);
        } else {
          this.selectOption(region, this.selectedClusters);
        }
      } else {
        this.unselectOption(store, this.filters.comparisonStores);
        if (this.criteriaSelectedName !== 'cluster') {
          // valida se todas as lojas estao desmarcadas e entao desmarca estado
          if (!this.storesByState(region, state)) this.unselectOption(state, this.selectedStates);
          // valida se todos os estados estao desmarcados e entao desmarca a regiao
          if (!this.statesByRegion(region)) this.unselectOption(region, this.selectedRegions);
        } else if (!this.storesByCluster(region)) {
          this.unselectOption(region, this.selectedClusters);
        }
      }
    },
    toggleCriteria(criteriaName) {
      if (criteriaName !== this.criteriaSelectedName) {
        this.criteriaSelectedName = criteriaName;
        this.criteriaSelected = !this.criteriaSelected;
      }
    },
    toggleRegion(region) {
      this.toggleSelection(region, this.selectedRegions);
      const states = Object.keys(this.comparisonStoresList[region]);
      states.forEach(state => {
        if (this.isSelected(region, this.selectedRegions)) this.selectState(region, state);
        else this.unselectState(region, state);
      });
    },
    toggleCluster(cluster) {
      if (this.isSelected(cluster, this.selectedClusters)) {
        this.unselectCluster(cluster);
      } else {
        this.selectCluster(cluster);
      }
    },
    selectCluster(cluster) {
      this.toggleClusterStores(cluster, true);
      this.selectOption(cluster, this.selectedClusters);
    },
    unselectCluster(cluster) {
      this.toggleClusterStores(cluster, false);
      this.unselectOption(cluster, this.selectedClusters);
    },
    toggleClusterStores(cluster, select) {
      this.orderbyClusterList[cluster].forEach(store => {
        if (select) this.selectOption(store.id, this.filters.comparisonStores);
        else this.unselectOption(store.id, this.filters.comparisonStores);
      });
    },
    toggleState(region, state) {
      if (this.isSelected(state, this.selectedStates)) {
        this.unselectState(region, state);
        // valida se todos os estados estao desmarcados e entao desmarca a regiao
        if (!this.statesByRegion(region)) this.unselectOption(region, this.selectedRegions);
      } else {
        this.selectState(region, state);
        this.selectOption(region, this.selectedRegions);
      }
    },
    selectState(region, state) {
      this.toggleStateStores(region, state, true);
      this.selectOption(state, this.selectedStates);
    },
    unselectState(region, state) {
      this.toggleStateStores(region, state, false);
      this.unselectOption(state, this.selectedStates);
    },
    toggleStateStores(region, state, select) {
      this.comparisonStoresList[region][state].forEach(store => {
        if (select) this.selectOption(store.id, this.filters.comparisonStores);
        else this.unselectOption(store.id, this.filters.comparisonStores);
      });
    },
    statesByRegion(region) {
      const states = Object.keys(this.comparisonStoresList[region]);
      return states.some(v => this.selectedStates.includes(v));
    },
    storesByState(region, state) {
      return this.comparisonStoresList[region][state].some(v => this.filters.comparisonStores.includes(v.id));
    },
    storesByCluster(region) {
      return this.orderbyClusterList[region].some(v => this.filters.comparisonStores.includes(v.id));
    },
    getComparisonStoresList() {
      const comparisonStoresList = {};

      this.$parent.stores.forEach(store => {
        if (store.region) {
          if (typeof comparisonStoresList[store.region] !== 'object') comparisonStoresList[store.region] = {};
          if (!Array.isArray(comparisonStoresList[store.region][store.state]))
            comparisonStoresList[store.region][store.state] = [];
          comparisonStoresList[store.region][store.state].push({
            id: store.id,
            name: store.store,
          });
        }
      });
      this.ready = true;
      return comparisonStoresList;
    },
    groupByCluster() {
      const groupBy = key => array =>
        array
          .filter(store => store.id !== this.filters.store)
          .reduce((objectsByKeyValue, obj) => {
            let value = this.$t('stores.select-others');
            if (obj[key]) {
              value = obj[key];
            }
            const clipboard = objectsByKeyValue;
            clipboard[value] = (clipboard[value] || []).concat(obj);
            return clipboard;
          }, {});
      const groupByCluster = groupBy('cluster');
      return groupByCluster(this.stores);
    },
    filterResults(obj) {
      if (obj.name) return obj.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      else if (obj.store) return obj.store.toUpperCase().indexOf(this.term.toUpperCase()) !== -1;
      return obj.filter(store => store.name.toUpperCase().indexOf(this.term.toUpperCase()) !== -1).length;
    },
    checkboxDisabled(store) {
      const mapStoresIds = this.suggestionDetail.storesId?.map(store => store.id);
      if (mapStoresIds) return mapStoresIds.includes(store);
    },
    watchSelectedClusters() {
      if (!this.selectedClusters.length) {
        this.clustersSelected = this.$t('select');
        return;
      }
      const sortedClusters = [...this.selectedClusters].sort();
      const listString = sortedClusters.toString().split(',').join(', ');
      this.clustersSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
    watchSelectedRegions() {
      if (!this.selectedRegions.length) {
        this.regionsSelected = this.$t('select');
        return;
      }
      const listString = this.selectedRegions.toString().split(',').join(', ');
      this.regionsSelected = listString.length > 21 ? `${listString.slice(0, 21)}...` : listString;
    },
    bodyClick() {
      const body = document.querySelector('body');
      body.addEventListener('click', event => {
        const className = event.target?.className;

        if (typeof className === 'string') {
          if (!className?.includes('no-click')) {
            this.criteriaOpened = false;
            this.storesOpened = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion-details-header {
  width: 100%;
  margin: 10px 0 30px;

  .sdh__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input.sdh__name-input {
      width: 305px;
    }

    textarea {
      width: 305px;
      min-height: 178px;
      border: 1px solid $gray-500;
      box-sizing: border-box;
      border-radius: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $gray-600;
    }

    .sdh__approve-container {
      display: flex;
      align-items: center;
      margin: 20px 0 0 54px;

      .sdh__approve {
        color: $gray-700;
        font-size: 12px;
      }

      .sdh__checkbox {
        cursor: pointer;
        margin-right: 10px;
        display: flex;

        .checkbox {
          margin-right: 5px;
        }
      }
    }

    .sdh__textarea-wrapper {
      display: flex;
      flex-direction: column;
    }

    .sdh__select-fake-wrapper {
      margin-left: 54px;

      .note {
        font-size: 12px;
        max-width: 255px;

        strong {
          color: $color-red;
          margin-right: 5px;
        }

        span {
          font-weight: bold;
        }
      }
    }
  }

  .sdh__container:nth-child(2) {
    align-items: baseline;
    margin-top: 20px;
  }

  .form-group {
    position: relative;
  }
  .dropdown-menu {
    display: block;
    .searchStore {
      position: relative;
      max-width: 236px;
      margin: 5px auto 18px;
      input {
        width: 100%;
      }
      .icon-search {
        position: absolute;
        right: 5px;
        top: 2px;
        color: #bbbbbb;
      }
    }
  }

  .check-list {
    position: absolute;
    top: 58px;
    left: 0;
    width: 226px;
    padding: 18px 15px;
    background-color: white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    z-index: 98;
    border: 0;
    margin: 0;
    &.hide {
      display: none;
    }
    .select {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }

      label {
        display: grid;
        align-items: center;
        user-select: none;
        cursor: pointer;
        margin: 0;
        grid-template-columns: 16px 1fr;
        &:hover {
          .check-box:not(.checked) {
            border-color: $gray-600;
          }
        }
        .text {
          margin-left: 10px;
          color: $gray-700;
          .name {
            display: block;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
          }
        }
      }
    }

    > .select {
      &:hover {
        .check-list-child {
          visibility: visible;
        }
      }
      label {
        grid-template-columns: 16px 1fr 25px;
      }
      .icon-right-14px {
        font-size: 10px;
      }
    }
    .check-list-child {
      display: block;
      visibility: hidden;
      width: 285px;
      max-height: 315px;
      padding: 10px;
      position: absolute;
      left: 200px;
      top: 0;
      background-color: #fff;
      overflow: hidden;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
      transition: all 0.25s cubic-bezier(0.1, 0.65, 0.5, 1);
      transition-delay: 0.3s;
      .list {
        padding: 5px;
        max-height: 295px;
        overflow-y: auto;
        .state .name {
          font-weight: 600;
        }
      }
      .check-list-subchild {
        padding: 12px 14px 12px 25px;
      }
    }
  }

  .check-list,
  .list {
    &::-webkit-scrollbar-track {
      background-color: $gray-300;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      background-color: $gray-300;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbb;
      border-radius: 10px;
    }
  }
  .select-fake {
    width: 226px;
    overflow: hidden;
    padding-right: 25px;
    margin-bottom: 28px;
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
  }
}
</style>
