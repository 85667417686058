<template>
  <div class="table">
    <template v-if="$_verifyLoadedVuex('done')">
      <div class="table__header">
        <div class="table__row">
          <div v-for="item in series" :key="item" :class="['table__column', resolveTextAlign(item)]">
            <div class="table__header-title" v-html="$t(`360.actions.${item}`)"></div>
            <div
              :class="['table__order-by', { 'table__order-by--active': isActive === item }]"
              @click.prevent="ordenate(item)"
            >
              <span :class="['table__dash-arrows', isActive === item ? `table__dash-arrows--${orderType}` : '']"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="table__body">
        <div v-for="(item, index) in tableData" :key="index" class="table__row">
          <div class="table__column message" :title="item.text">
            {{ item.text ? item.text : '-' }}
          </div>
          <div class="table__column channel">
            {{ item.channel ? $tc(`_${item.channel}`, 2) : '-' }}
          </div>
          <div v-if="item.scope && getScopeType == ''" class="table__column scope">
            {{ item.scope.name ? $tc(`_${item.scope.key}`, 1) + ': ' + item.scope.name : '-' }}
          </div>
          <div v-else-if="item.scope" class="table__column scope">
            {{ item.scope.name ? item.scope.name : '-' }}
          </div>
          <div v-else class="table__column scope">-</div>

          <div class="table__column created-at text-right">
            {{ item.createdAt ? $_formatDate(item.createdAt) : '-' }}
          </div>
          <div class="table__column moment text-right">
            {{ item.moment ? formatMoment(item.moment) : '-' }}
          </div>
          <menu-dropdown
            class="table__column table__dropdown"
            :label="item.id"
            :actions="actionsDropdown(item, modalId)"
          />
        </div>
      </div>
      <modal-dialog-question
        :id="modalId"
        :delete-action="triggerAction"
        :is-visible="isVisible"
        @closeDialogModal="isVisible = $event"
        :message="$t('360.actions.dialog.delete-msg')"
      />
    </template>
    <base-loader v-show="$_verifyLoadedVuex('loading')" />
    <info-card v-show="$_verifyLoadedVuex('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapState, mapGetters, mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
// helpers
import { tableMixin, valueUnitMixin, verifyMixin, actionsMixin } from '@/helpers/mixins';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import ModalDialogQuestion from '@/components/_atoms/ModalDialogQuestion';
import MenuDropdown from '@/components/_atoms/MenuDropdown.vue';

export default {
  name: 'ActionMessageTable',
  components: {
    MenuDropdown,
    BaseLoader,
    InfoCard,
    ModalDialogQuestion,
  },
  mixins: [tableMixin, valueUnitMixin, verifyMixin, actionsMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      messageId: null,

      order: null,
      orderType: 'asc',
      isActive: '',
      modalId: 'action-messages',
      tableData: null,
      isHovering: false,
      isCancelRecurrenceModalActive: false,
      isVisible: false,
    };
  },
  computed: {
    ...mapState('actionMessages', ['messagesList', 'series', 'loadingGlobal', 'isEmptyGlobal', 'hasErrorGlobal']),
    ...mapGetters('actionMessages', ['getMessagesList', 'getIsEmpty', 'getHasError', 'getLoading', 'getScopeType']),
  },
  beforeUpdate() {
    this.updateTable();
  },
  mounted() {
    this.resetSelected();
    this.getMessagesListData();

    bus.$on('update-selected-brand', () => {
      this.callEventBusFunctions();
    });

    bus.$on('render-cards', () => {
      this.callEventBusFunctions();
    });

    bus.$on('actions-messages-refresh-list', () => {
      this.callEventBusFunctions();
    });

    bus.$on('actions-message-toast-delete', () => {
      this.toast.success(this.$t('360.actions.toast.msg-delete'), {
        timeout: 5000,
      });
    });

    bus.$on(`${this.modalId}-open-confirm-modal`, id => {
      this.isVisible = true;
      this.messageId = id;
    });
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
    bus.$off('render-cards');
    bus.$off('actions-message-toast-delete');
    bus.$off('actions-messages-refresh-list');
    bus.$off(`${this.modalId}-open-confirm-modal`);
  },
  methods: {
    ...mapActions('actionMessages', ['getMessagesListData', 'resetSelected', 'disableMessage', 'setPaginationOrder']),

    /**
     * @description Verify list order, filter and organize personas list
     */

    updateTable() {
      this.tableData = this.getMessagesList;
    },

    /**
     * @description Receive and send data for $_tableOrdination
     * @param {Object} data
     */
    ordenate(key) {
      // update internal table state with order type mixin
      const { order, orderType } = this.$_tableOrderType({
        key,
        order: this.order,
        orderType: this.orderType,
      });
      this.isActive = key;
      this.order = order;
      this.orderType = orderType;

      // increment key with desc to be sent by action
      const orderFormat = orderType === 'asc' ? key : `${key}Desc`;
      this.setPaginationOrder(orderFormat);
    },

    /**
     * @description disable message
     */
    triggerAction() {
      this.disableMessage(this.messageId);
    },

    /**
     * @description fix alignment based on item key
     */
    resolveTextAlign(key) {
      let align;
      if (key === 'text' || key === 'channel' || key === 'scope') {
        align = 'text-left';
      }
      if (key === 'created-at' || key === 'moment') {
        align = 'text-right';
      }
      return align;
    },

    formatMoment(term) {
      let text = '';
      switch (term) {
        case 'begin':
          text = this.$t('360.actions.start');
          break;
        case 'middle':
          text = this.$t('360.actions.middle');
          break;
        case 'end':
          text = this.$t('360.actions.end');
          break;
        default:
          break;
      }
      return text;
    },
    callEventBusFunctions() {
      this.resetSelected();
      this.getMessagesListData();
    },

    /**
     * @description actions to menu dropdown
     */
    edit(item) {
      bus.$emit(`open-actions-new-message-modal`, item.id);
    },
    remove(item, modalId) {
      bus.$emit(`${modalId}-open-confirm-modal`, item.id);
    },
    actionsDropdown(item, modalId) {
      return [
        {
          name: 'edit',
          action: () => this.edit(item),
        },
        {
          name: 'delete',
          action: () => this.remove(item, modalId),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  color: $gray-800;
  font-size: 12px;
  height: auto;
  min-height: 630px;
  margin: 0;

  .table__header,
  .table__body {
    .table__row {
      color: $gray-800;
      display: grid;
      grid-template-rows: 60px;
      grid-template-columns: 40% 10% 15% 12% 15% 8%;
      align-items: center;
      padding: 0 25px;
    }
  }

  .table__header {
    font-weight: 600;
    .table__row {
      border-bottom: 1px solid $gray-300;
    }
  }

  .table__body {
    .table__row {
      grid-auto-columns: 80px;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: none;
      }

      .table__column {
        .table__status-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .table__recurrent {
            margin-left: 12px;
            min-width: 20px;
            img {
              cursor: pointer;
            }
          }
          .table__status-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65%;
          }
        }
        &.table__dropdown {
          display: grid;
          grid-column: 6;
          justify-content: right;
          padding-right: 10px;
        }
        .table__icon-wrapper {
          display: flex;
          align-items: center;

          .icon {
            font-size: 14px;
          }

          &:hover {
            &[data-toggle='tooltip'] {
              cursor: pointer;
            }
          }
        }

        &.message {
          max-width: 380px;
          line-height: 20px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        &.channel,
        &.moment {
          font-weight: 600;
        }
      }
    }
  }

  .table__header-title {
    display: inline-block;
    width: fit-content;
    white-space: initial;
    margin-right: 5px;
  }

  .table__order-by {
    background-color: $gray-400;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    padding: 3px 0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    text-align: center;

    .table__dash-arrows {
      display: block;
      color: $color-white;
      border: solid $gray-900;
      border-width: 0 1px 1px 0;
      border-radius: 0;
      display: inline-block;
      padding: 3px;
      transform: translate(0, -50%) rotate(45deg);

      &--asc {
        transform: translate(0, -50%) rotate(45deg);
      }
      &--desc {
        transform: rotate(-135deg);
      }
    }

    &--active {
      background-color: $oto-omni;

      .table__dash-arrows {
        border-color: $color-white;
      }

      .table__dash-arrows {
        &--asc {
          transform: translate(0, -50%) rotate(45deg);
        }
        &--desc {
          transform: rotate(-135deg);
        }
      }
    }
  }
}
</style>
