<template>
  <section id="analytics-chart" class="card" :class="{ loadHeight: loading || isEmpty || hasError }">
    <template v-if="$_verifyLoaded('done')">
      <div class="chart-title-container row">
        <i class="icon dashboard-icon light-gray icon-calendar-18px"></i>
        <h2 class="card-title mb-0 ml-3">{{ $t('nps.evolution-chart') }}</h2>
      </div>

      <div>
        <highcharts :options="chartOptions" />
      </div>
    </template>

    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </section>
</template>

<script>
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import { getMetricsPeriod } from '@/services/nps/analytics';
import bus from '@/helpers/events/bus';
import { verifyMixin, chartMixin } from '@/helpers/mixins';
import { formatDecimal, formatNumber } from '@/utilities/formatters';
import InfoCard from '@/components/_atoms/InfoCard';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';

export default {
  name: 'AnalyticsChart',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, chartMixin],
  data() {
    return {
      highcharts: Chart,
      metrics: [...Array(8)],

      //chart states
      chartOptions: {},
      chartDateOptions: [],
      labels: [],
      nps: [],
      promoters: [],
      passive: [],
      detractors: [],
    };
  },
  created() {
    bus.$on('render-cards', () => {
      this.setup();
    });
  },
  mounted() {
    this.setup();
  },
  beforeUnmount() {
    bus.$off('changeSelectedOption');
  },
  methods: {
    setup() {
      this.getChartInfo();
    },
    getChartInfo() {
      this.$_reqConfig();

      const metrics = 'nps,promoters,passive,detractors';

      getMetricsPeriod(metrics)
        .then(({ data }) => {
          if (this.$_verifyData(data?.metrics?.series)) return;

          if (data.success) {
            const metricsData = data.metrics;
            this.labels = metricsData?.series;
            this.nps = this.$_parseArrayToFloat(metricsData?.nps);
            this.promoters = this.$_parseArrayToInt(metricsData?.promoters);
            this.passive = this.$_parseArrayToInt(metricsData?.passive);
            this.detractors = this.$_parseArrayToInt(metricsData?.detractors);
          }
        })
        .then(() => {
          this.chartDateOptions = this.$_resolvePlotDateFormat(this.labels);
          this.chartOptions = this.resolveChartOptions();
        })
        .catch(e => {
          console.error(e);
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resolveChartSeries() {
      return [
        {
          id: 'promoters',
          format: 'integer',
          name: this.$t('nps.promoters', 2),
          type: 'column',
          color: '#35BA9B',
          data: this.promoters,
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          id: 'passive',
          format: 'integer',
          name: this.$t('nps.passive', 2),
          type: 'column',
          color: '#DDDDDD',
          data: this.passive,
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          id: 'detractors',
          format: 'integer',
          name: this.$t('nps.detractors', 2),
          type: 'column',
          color: '#EC3942',
          data: this.detractors,
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          id: 'nps',
          name: this.$tc('nps.nps', 1),
          format: 'float',
          type: 'spline',
          color: '#36AAD6',
          data: this.nps,
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
          yAxis: 1,
        },
      ];
    },
    resolveChartOptions() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });
      return {
        chart: {
          zoomType: 'xy',
          height: 280,
          marginLeft: 90,
          marginRight: 90,
          style: {
            fontFamily: 'Montserrat',
            margin: '0 auto',
          },
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginBottom: 25,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 14,
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;
              if (x.series.userOptions?.id === 'nps') {
                formattedValue = formatDecimal(x.y, 1);
              } else {
                formattedValue = formatDecimal(x.y, 1);
              }
              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
            pointWidth: 14,
            pointPadding: 0,
          },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: true,
            shadow: false,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            categories: this.chartDateOptions,
            tickWidth: 0,
          },
        ],
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value > 1000 && this.value <= 999999) return formatNumber(this.value, '0 a');
                else if (this.value >= 1000000) return formatNumber(this.value, '0 a');
                return formatDecimal(this.value);
              },
            },
          },
          {
            opposite: true,
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value > 1000 && this.value <= 999999) return formatNumber(this.value, '0 a');
                else if (this.value >= 1000000) return formatNumber(this.value, '0 a');
                return formatDecimal(this.value, 1);
              },
            },
          },
        ],
        series: this.resolveChartSeries(),
      };
    },
    setStore() {
      this.getChartInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 16px;
  background-color: $color-white;
  box-shadow: 0px 24px 60px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  justify-content: space-between;
}
.row {
  flex-direction: row;
}
.metric-card-container {
  justify-content: space-between;
}
.metric-card {
  padding: 0;
}
.dashboard-icon {
  font-size: 26px;
  margin-left: 18px;
  &.blue {
    color: $oto-omni;
  }
}
.title {
  margin: 0 0 0 12px;
}
.select-container {
  padding-right: 16px;
}

.chart-title-container {
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;
}

.dashboard-icon {
  font-size: 26px;
  margin-left: 18px;
  &.light-gray {
    color: $gray-500;
  }
}
#analytics-chart {
  &.loadHeight {
    height: 358px;
  }
}
</style>
