<template>
  <lightbox-template
    id="lightbox-campaign-datails"
    icon="clock-18px"
    :title="$t('integrations.history-export')"
    :show-segment-group="false"
    :show-date="false"
  >
    <template v-if="$_verifyLoaded('done')">
      <div class="container mb-4">
        <header class="d-flex align-items-center mb-3">
          <h5 v-if="getLogData" class="modal-subtitle">{{ getLogData.audience }} | {{ getLogData.date }}</h5>
        </header>
      </div>
      <div class="table col-12">
        <div class="table__header">
          <div class="table__row row">
            <div class="table__column col-3">
              <span>{{ $t('integrations.logs.level') }}</span>
            </div>
            <div class="table__column col-3">
              <span>{{ $t('integrations.logs.date') }}</span>
            </div>
            <div class="table__column col-6">
              <span>{{ $t('integrations.logs.message') }}</span>
            </div>
          </div>
        </div>
        <div class="table__body">
          <div v-for="(item, index) in data.rows" :key="index" class="table__row row">
            <div class="table__column col-3 text-left bold">
              {{ item.level ? item.level.toUpperCase() : '-' }}
            </div>
            <div class="table__column col-3 text-left">
              {{ item.date ? item.date : '-' }}
            </div>
            <div class="table__column col-6 text-left">
              {{ item.message ? item.message : '-' }}
            </div>
          </div>
        </div>
      </div>
      <list-pagination
        v-if="pagination && pagination.count"
        class="table__pagination"
        :data="pagination"
        :limit-range="limitRange"
        :identify="id"
      />
    </template>
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    <base-loader v-show="$_verifyLoaded('loading')" />
  </lightbox-template>
</template>

<script>
// libs
import { mapState, mapGetters, mapActions } from 'vuex';
// helpers
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import bus from '@/helpers/events/bus';
// components
import InfoCard from '@/components/_atoms/InfoCard';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import ListPagination from '@/components/_atoms/ListPagination';

export default {
  name: 'LightboxHistoryExport',
  components: {
    LightboxTemplate,
    ListPagination,
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  props: {
    settingsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: 'lightbox-history-export',
      limitRange: [5, 10, 20, 30, 50],
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
        order: '',
      },
      data: {
        rows: [],
      },
    };
  },
  computed: {
    ...mapState('historyExport', ['logIsEmpty', 'logLoading', 'logHasError']),
    ...mapGetters('historyExport', ['getLogData']),
  },
  watch: {
    getLogData(newVal) {
      this.data.rows = newVal?.table?.data;
      this.pagination.count = newVal?.table?.totalCount;
    },
    logLoading() {
      this.loading = this.logLoading;
    },
    logIsEmpty() {
      this.isEmpty = this.logIsEmpty;
    },
    logHasError() {
      this.hasError = this.logHasError;
    },
  },
  mounted() {
    bus.$on(`${this.id}-change-page`, () => {
      this.setLogData({
        scheduleId: this.settingsData.scheduleId,
        page: this.pagination.page,
        limit: this.pagination.limit,
      });
    });
    bus.$on(
      `${this.id}-change-order`,
      this.setLogData({
        scheduleId: this.settingsData.scheduleId,
        page: this.pagination.page,
        limit: this.pagination.limit,
      }),
    );
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
  },
  methods: {
    ...mapActions('historyExport', ['setLogData']),
  },
};
</script>

<style lang="scss" scoped>
#lightbox-campaign-datails {
  .table {
    color: $gray-800;
    font-size: 12px;
    min-height: 550px;
  }

  .table__header {
    padding: 35px 0 20px 0;
    line-height: 16px;
    font-weight: 600;
    color: $gray-900;
    z-index: 10;

    span {
      display: block;
    }
  }

  .table__body {
    width: 100%;

    &:hover .card {
      opacity: 0.6;
    }

    .table__row {
      height: 40px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
    }
  }

  .table__column {
    &:first-child {
      padding-left: 50px;
      @media screen and (min-width: 1200px) {
        min-width: 200px;
      }
    }

    &:last-child {
      padding-right: 50px;
    }

    &.bold {
      font-weight: 600;
    }
  }

  .table__pagination {
    padding: 0 35px 0 40px;
  }
}
</style>

<style lang="scss">
#lightbox-campaign-datails {
  .lightbox-body {
    min-height: 550px;
    display: block;
    position: relative;
  }
}
</style>
