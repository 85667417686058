<!-- eslint-disable max-len -->
<template>
  <section class="warning-sellers">
    <div class="row">
      <div class="warning-sellers__container">
        <base-card-v2 class="warning-sellers__list">
          <template v-if="!loading">
            <base-card-header
              v-if="(!sellersList.length && hasSellersWarning) || (sellersList.length && hasSellersWarning)"
              :title="$tc('360.sellers.warning.title')"
              svg="User"
            >
              <template #item-right>
                <div class="warning-sellers__actions">
                  <!-- stores -->
                  <v-select
                    v-model="selectedStore"
                    label="name"
                    :clearable="false"
                    class="warning-sellers__select-store"
                    :options="[
                      { id: null, name: $t('360.sellers.select-default.warning') },
                      ...getOrderedWarningStores,
                    ]"
                    :filter-by="storesFilter"
                    :reduce="store => store.id"
                    :placeholder="$t('360.select')"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes" class="arrow-down"></span>
                    </template>
                    <template #option="{ name, id }">
                      <span v-if="id" :title="`${name} - ${id}`">{{ name }} - {{ id }}</span>
                      <span v-else>{{ name }}</span>
                    </template>
                    <template #selected-option="{ name, id }">
                      <span v-if="id">{{ name }} - {{ id }}</span>
                      <span v-else>{{ name }}</span>
                    </template>
                    <template #no-options>
                      {{ $t('360.sellers-analytics.select-empty') }}
                    </template>
                  </v-select>
                  <!-- search -->
                  <div class="warning-sellers__search-and-filter">
                    <input
                      ref="inputTerm"
                      v-model="term"
                      class="findTerm"
                      :placeholder="$t('360.sellers.search-sellers')"
                    />
                    <i v-if="term !== ''" class="icon icon-close" @click="clearTerm"></i>
                    <i v-else class="icon icon-search"></i>
                  </div>
                  <div class="export-wrapper">
                    <button ref="button" class="btn btn-primary warning-sellers__export" @click="exportFile('xlsx')">
                      <i class="icon icon-download 18px"></i>
                      {{ this.$t('360.export', { value: '.XLSX' }) }}
                    </button>
                  </div>
                </div>
              </template>
            </base-card-header>
            <template v-if="sellersList.length">
              <p class="warning-sellers__text">
                <span
                  class="warning-sellers__container"
                  v-html="
                    isToggleText
                      ? $t('360.sellers.warning.text.show-more-text')
                      : $t('360.sellers.warning.text.show-less-text')
                  "
                />
                <strong @click="toggleText">{{ $tc('_read-more-or-less', showBtnText) }}</strong>
              </p>

              <div v-if="enabledEnrollmentFix" class="warning-sellers__oto-ai">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.5 22V17M4.5 7V2M2 4.5H7M2 19.5H7M13 3L11.2658 7.50886C10.9838 8.24209 10.8428 8.60871 10.6235 8.91709C10.4292 9.1904 10.1904 9.42919 9.91709 9.62353C9.60871 9.8428 9.24209 9.98381 8.50886 10.2658L4 12L8.50886 13.7342C9.24209 14.0162 9.60871 14.1572 9.91709 14.3765C10.1904 14.5708 10.4292 14.8096 10.6235 15.0829C10.8428 15.3913 10.9838 15.7579 11.2658 16.4911L13 21L14.7342 16.4911C15.0162 15.7579 15.1572 15.3913 15.3765 15.0829C15.5708 14.8096 15.8096 14.5708 16.0829 14.3765C16.3913 14.1572 16.7579 14.0162 17.4911 13.7342L22 12L17.4911 10.2658C16.7579 9.98381 16.3913 9.8428 16.0829 9.62353C15.8096 9.42919 15.5708 9.1904 15.3765 8.91709C15.1572 8.60871 15.0162 8.24209 14.7342 7.50886L13 3Z"
                    stroke="#A334FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span v-html="$tc('360.sellers.warning.oto-ai')"></span>
              </div>

              <strong class="warning-sellers__info">
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.99967 10.5V6.83337M7.99967 4.83337H8.00634M14.6663 7.50004C14.6663 11.1819 11.6816 14.1667 7.99967 14.1667C4.31778 14.1667 1.33301 11.1819 1.33301 7.50004C1.33301 3.81814 4.31778 0.833374 7.99967 0.833374C11.6816 0.833374 14.6663 3.81814 14.6663 7.50004Z"
                    stroke="#555555"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                {{ $t('360.sellers.warning.info') }}
              </strong>
              <div class="tg-list">
                <div class="tg-row -header">
                  <div class="tg-col">
                    <div class="header-title">
                      {{ $t('360.sellers.columns.name') }}
                    </div>
                    <div :class="['order-by', { active: /name/.test(orderBy) }]" @click.prevent="ordenate('name')">
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.customers') }}
                    <div
                      :class="['order-by', { active: /customers/.test(orderBy) }]"
                      @click.prevent="ordenate('customers')"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.enrollment') }}
                    <div
                      :class="['order-by', { active: /enrolment/.test(orderBy) }]"
                      @click.prevent="ordenate('enrolment')"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.storeName') }}
                    <div
                      :class="['order-by', { active: /storeName/.test(orderBy) }]"
                      @click.prevent="ordenate('storeName')"
                    >
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.columns.store') }}
                    <div :class="['order-by', { active: orderBy === 'store' }]" @click.prevent="ordenate('store')">
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                  <div class="tg-col">
                    {{ $t('360.sellers.status') }}
                    <div :class="['order-by', { active: /status/.test(orderBy) }]" @click.prevent="ordenate('status')">
                      <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'asc' : 'desc'}`]"></span>
                    </div>
                  </div>
                </div>

                <div
                  v-for="(seller, index) in sellersList"
                  :key="index"
                  :class="['tg-row', { 'refreshing-list': loading }]"
                  :data-cy="`table-sellers-${index}`"
                  @click.self="openSeller(seller.id)"
                >
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span class="warning-sellers__customer-icon">
                      <i class="icon icon-customer"></i>
                    </span>
                    <span :id="'seller' + seller.id" ref="persona_list_item" class="persona-name ellipsis">
                      {{ seller.name }}
                    </span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{
                      $_getValue({
                        type: 'integer',
                        amount: seller.customers,
                      })
                    }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{ seller.enrolment }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{ seller.storeName }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>{{ seller.store }}</span>
                  </div>
                  <div class="tg-col" @click="openSeller(seller.id)">
                    <span>
                      <span class="bol" :class="`${seller.status}`"></span>
                      {{ $t(`360.sellers.${seller.status}`) }}
                    </span>
                  </div>
                </div>

                <div v-if="!loading && pagination.count === 0" class="warning-sellers__no-results">
                  {{ $t('360.sellers.not-found') }}
                </div>
              </div>
            </template>
          </template>

          <base-loader v-show="$_verifyLoaded('loading')" />
          <warning-sellers-empty
            v-if="!loading && !sellersList.length && !hasSellersWarning"
            :is-empty="!sellersList.length"
          />
          <info-card
            v-if="!loading && !sellersList.length && hasSellersWarning"
            ref="message-error"
            :error="hasError"
          />
        </base-card-v2>
      </div>
    </div>

    <list-pagination :data="pagination" :limit="pagination.limit" :limit-range="[10, 20, 50]" :identify="id" />
    <delete-persona />
    <seller-detail :seller-id="$route.params.sellerId || 0" />
    <question-modal />
  </section>
</template>

<script>
// libs
import moment from 'moment';
import FileDownload from 'js-file-download';
import { mapActions, mapGetters } from 'vuex';
import { isEmpty, debounce } from 'lodash';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification';
// services
import { getSellersWarning, getExportSellersWarning } from '@/services/oto/sellers';
// helpers
import bus from '@/helpers/events/bus';
import { tableMixin, verifyMixin } from '@/helpers/mixins';
// atoms
import ListPagination from '@/components/_atoms/ListPagination';
import InfoCard from '@/components/_atoms/InfoCard.vue';
// components
import DeletePersona from '@/components/personas/_organisms/DeletePersona';
import QuestionModal from '@/components/oto/sellers/QuestionModal';
import SellerDetail from '@/components/oto/sellers/SellerDetail';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import WarningSellersEmpty from '@/views/oto/WarningSellersEmpty';
// store
import store from '@/store';
import { dateFormat } from '@/utilities/constants';

export default {
  name: 'WarningSellers',
  components: {
    BaseLoader,
    DeletePersona,
    SellerDetail,
    ListPagination,
    QuestionModal,
    InfoCard,
    vSelect,
    WarningSellersEmpty,
  },
  mixins: [tableMixin, verifyMixin],
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      id: 'sellers-list',
      term: '',
      order: null,
      isActive: '',
      tableData: null,
      selectedStore: [{ id: null, name: this.$t('360.sellers.select-default.warning') }],
      storesList: null,
      sellersList: [],
      hasSellersWarning: false,
      isExportVisible: false,
      args: {},
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
      },
      orderBy: 'name',
      hasFilter: false,
      isToggleText: false,
    };
  },
  computed: {
    ...mapGetters('sellers', ['getOrderedWarningStores']),
    loginWithPin() {
      return !store.getters.hasSso;
    },
    showBtnText() {
      const showMore = 1;
      const showLess = 2;

      return this.isToggleText ? showLess : showMore;
    },
    getBrand() {
      return store.state.auth.selectedBrand?.brand;
    },
    enabledEnrollmentFix() {
      return store?.getters?.getEnabledEnrollmentFix;
    },
  },
  watch: {
    selectedStore: function () {
      this.pagination.page = 1;
      this.fetchWarningSellers();
    },
    term: debounce(function () {
      this.pagination.page = 1;
      this.fetchWarningSellers();
    }, 800),
  },
  mounted() {
    // load vuex lists
    this.fetchWarningSellers();
    this.getWarningStoresList();
    this.getActiveSellersList();

    if (this.$route.params.sellerId) {
      bus.$emit('seller-detail-modal');
    }
    bus.$on('render-cards', () => {
      this.getWarningStoresList();
      this.fetchWarningSellers();
    });
    bus.$on('sellers-reload-list', () => {
      this.getWarningStoresList();
      this.fetchWarningSellers();
    });
    bus.$on(`${this.id}-change-page`, () => {
      this.fetchWarningSellers();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('sellers-reload-list');
    bus.$off(`${this.id}-change-page`);
  },
  methods: {
    ...mapActions('sellers', ['getWarningStoresList', 'getActiveSellersList']),

    exportFile(type) {
      this.fetchExportData(type);
    },

    fetchExportData(fileType) {
      this.loading = true;
      const filter = {
        storeId:
          typeof this.selectedStore === 'string' || this.selectedStore === null
            ? this.selectedStore
            : this.selectedStore[0].id,
      };

      if (filter.storeId) {
        this.args.filter = `storeId:${filter.storeId}`;
      }

      if (this.term) {
        this.args.term = this.term;
      }

      const date = moment().format(dateFormat);

      getExportSellersWarning(fileType, 'blob', this.args)
        .then(data => {
          FileDownload(data, `Vendedores em alerta - ${this.getBrand} - exportado em ${date}.${fileType}`);
        })
        .finally(() => {
          this.fetchWarningSellers();
          this.isExportVisible = false;
        });
    },

    fetchWarningSellers() {
      this.hasFilter = false;
      this.$_reqConfig();

      const filter = {
        storeId:
          typeof this.selectedStore === 'string' || this.selectedStore === null
            ? this.selectedStore
            : this.selectedStore[0].id,
      };

      const args = {
        ...(!this.showInactives && { enabled: 1 }),
        ...(!isEmpty(this.term) && { term: this.term }),
        orderBy: this.orderBy,
        page: this.pagination.page,
        limit: this.pagination.limit,
      };

      if (filter.storeId) {
        this.hasFilter = true;
        args.filter = `storeId:${filter.storeId}`;
      }

      getSellersWarning(args.limit, args.page, args)
        .then(data => {
          if (this.$_verifyData(data?.data)) {
            this.sellersList = [];
            return;
          }
          this.sellersList = data?.data.map(r => {
            return Object.fromEntries(
              r.map((val, i) => {
                return [data.series[i].key, val];
              }),
            );
          });

          this.hasSellersWarning = data.hasSellersWarning;
          this.pagination.count = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ordenate(order) {
      this.orderBy = this.orderBy === order ? `-${order}` : order;
      this.fetchWarningSellers();
    },
    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },
    toggleText() {
      this.isToggleText = !this.isToggleText;
    },
    /**
     * @description clear term of search input
     */
    clearTerm() {
      this.term = '';
    },
    /**
     * @description change route to detailed label page
     * @param {Number} id
     */
    openSeller(id) {
      this.$router.push({
        name: '360-sellers-detail',
        params: { sellerId: id },
      });
    },
    /**
     * @description validate error code and fire emitter
     */
    showErrorMessage(errorCode) {
      if (errorCode === 429) {
        bus.$emit(
          'show-detail-error',
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('360-sellers.title', 2).toLowerCase(),
          }),
        );
      } else {
        this.toast.error(
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('360-sellers.title', 1).toLowerCase(),
          }),
        );
        bus.$emit('header-details-input-blur');
      }
    },
    /**
     * @description filter options with two labels
     */
    storesFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name.toLowerCase()?.includes(temp) || option?.id?.includes(temp);
    },
  },
};
</script>

<style lang="scss" scoped>
.tg-list {
  min-height: 300px;
  font-size: 12.1px;
  color: #888;
  .tg-row {
    display: grid;
    grid-template-columns: 35% 10% 13% 19% 14% 10%;
    grid-template-rows: 40px;
    border-bottom: 1px solid #eee;
    justify-items: start;
    align-items: center;

    &.-header {
      font-weight: 600;

      .order-by {
        background-color: $gray-400;
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        line-height: 100%;
        padding: 3px 0;
        margin-left: 5px;
        height: 15px;
        width: 15px;
        min-width: 18px;
        text-align: center;

        .dash-arrows {
          display: block;
          color: $color-white;
          border: solid $gray-900;
          border-width: 0 1px 1px 0;
          border-radius: 0;
          display: inline-block;
          padding: 3px;
          transform: translate(0, -50%) rotate(45deg);
        }

        &.active {
          background-color: $oto-omni;

          .dash-arrows {
            border-color: $color-white;
          }
          .asc {
            transform: translate(0, -50%) rotate(45deg);
          }
          .desc {
            transform: rotate(-135deg);
          }
        }
      }
      .tg-col:first-child {
        padding-left: 20px;
      }
    }
    &:not(.-header) {
      cursor: pointer;
      &:nth-child(even) {
        background: #eeeeee4d;
      }
      &:hover {
        background: #eeeeee;
      }
      .tg-col:first-child {
        padding-left: 15px;
      }
    }

    &.refreshing-list {
      opacity: 0.5;
    }
  }
}

.tg-col {
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    z-index: 1;

    .bol {
      border-radius: 50%;
      display: inline-block;
      height: 9px;
      width: 9px;
      margin-right: 10px;
    }

    .active {
      background: #35ba9b;
    }

    .deleted {
      background: $pink-500;
    }

    .locked,
    .pending {
      background: $yellow-400;
    }

    &.ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.persona-name {
      color: $oto-omni;
      user-select: text;
      max-width: 300px;
      &:focus {
        max-width: 100%;
        min-width: 150px;
        outline: 0 none;
        border-bottom: 1px solid;
        background: #fbfbfb;
        z-index: 2;
      }
    }

    &.round-name {
      border: 1px solid #bbb;
      padding: 6px;
      border-radius: 100%;
      margin-right: 12px;
      width: 31px;
      height: 31px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  svg {
    margin-right: 8px;
  }
}
</style>

<style lang="scss">
.warning-sellers {
  @import '@/assets/scss/vendors/_v-select';

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  &__export {
    display: flex !important;
    align-items: center;
    gap: 6px;
    padding: 0 24px !important;
  }

  &__info {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #555;
    padding: 8px 0;
    border-bottom: 1px solid #eeeeee;

    svg {
      margin-right: 8px;
    }
  }

  &__oto-ai {
    display: flex;
    padding: 11px;
    margin-bottom: 10px;
    align-items: center;
    font-size: 12px;
    border-radius: 8px;
    background-color: #d7a6ff26;
    color: #a334ff;

    svg {
      align-self: self-start;
      margin-right: 10px;
      min-width: 26px;
      min-height: 26px;
    }

    span {
      font-weight: 500;
    }

    span > a {
      background-color: transparent;
      color: #a334ff;
      text-decoration: underline;
    }
  }

  &__text {
    width: 50%;
    position: relative;
    left: 42px;
    bottom: 20px;

    strong {
      color: #6c90f4;
      font-weight: bold;
      cursor: pointer;
    }

    ol {
      padding: 0 12px 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &__container {
    display: inline-block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  &__list {
    min-height: 557px;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__select-store {
    width: 170px;
    margin-right: 15px;
  }

  &__search-and-filter {
    width: 170px;
    margin-right: 15px;
    position: relative;
    .findTerm {
      width: 100%;
    }
    i {
      position: absolute;
      right: 8px;
      top: 4px;
      font-size: 26px;
      color: #bbb;
      cursor: pointer;
    }
  }

  &__customer-icon {
    margin-right: 10px;
    .icon {
      font-size: 23px;
      line-height: inherit;
    }
  }

  &__no-results {
    text-align: center;
    margin-top: 80px;
  }
}
</style>
