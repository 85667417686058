import { escapeRegExp } from 'lodash'; // eslint-disable-line no-unused-vars

/**
 * @description convert any string to lower case
 * @param {String} str a string with uppercase characters
 */
export const lowerCase = str => String(str)?.toLowerCase();

/**
 * @description convert any string normalize form (without accents)
 * @param {String} str a string with normalize form
 */
export const removeAccents = str => str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * @description parse any string to lower case and normalize form
 * @param {String} str a string with upper case and / or accentuation
 */
export const stringParser = str => removeAccents(lowerCase(str));

/**
 * @description get initial letters from specific word
 * @param {String} word
 * @param {String} first
 * @param {String} last
 */
export const getInitialLetters = (word, first = 0, last = 1) => word.substring(first, last).toUpperCase();

/**
 * @description craete label key to all array items
 * @param {Array} data
 */
export const createLabelObject = data =>
  data.reduce((acc, curr, i) => {
    // eslint-disable-next-line no-param-reassign
    acc[i] = { label: curr };
    return acc;
  }, []);

/**
 * @method getHighlightText
 * @description get word and highlight term, escape special characters and accents and wrap highlight with strong tag before show on list
 * @param {String} text
 * @param {String} highlight
 * @return list item with hightlight term in bold
 */
export const getHighlightText = (text, highlight) => {
  // remove accents from text and highlight word
  const parsedText = stringParser(text);
  const parsedHighlight = stringParser(highlight);
  // define pattern to search with escaped special characters
  const pattern = new RegExp(escapeRegExp(parsedHighlight), 'gi');
  // get first and last index from highlight
  const startIndex = pattern.exec(parsedText).index;
  const endIndex = startIndex + highlight.length;
  // extract highlight from original text and wrap with strong tag
  const slicedText = text.slice(startIndex, endIndex);
  const wrapHightlight = `<strong>${slicedText}</strong>`;
  // return original text with wrap highlight
  return text.replace(slicedText, wrapHightlight);
};
