import store from '@/store';
import { valueType } from '../constants';

type Locale = {
  locale: string;
};

type DecimalFormatting = {
  style: string;
  maximumFractionDigits: number | undefined;
};

export const formatDecimal = (value: string | number, options?: number) => {
  const DEFAULT_LANGUAGE = {
    locale: 'pt-BR',
  };

  const { locale }: Locale = store.getters?.currency || DEFAULT_LANGUAGE;
  const decimalValue = value ? +Number(typeof value === 'string' ? value.replace(',', '.') : value) : 0;
  const formatting: DecimalFormatting = {
    style: valueType.decimal,
    maximumFractionDigits: options ? options : 0,
  };

  const result = new Intl.NumberFormat(locale, formatting).format(decimalValue);

  return result;
};
