import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'campaignConfig';

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-CampaignConfig-getCampaign
 */
export const getCampaign = key => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getCampaign`,
    params: {
      brand: brand.id,
      campaign: key,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-CampaignConfig-listPriority
 */
export const getListPriority = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/listPriority`,
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-CampaignConfig-switch
 */
export const postSwitchCampaign = ({ campaign, enabled }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/switch`,
    params: {
      brand: brand.id,
      campaign,
      enabled,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-CampaignConfig-update
 */
export const postUpdateCampaign = ({ name, campaign, startDate, endDate, dayOfWeek, dayOfYear }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI}/update`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      name,
      campaign,
      startDate,
      endDate,
      dayOfWeek: dayOfWeek.join(),
      dayOfYear: dayOfYear.join(),
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-CampaignConfig-putPriority
 */
export const putPriorityList = campaigns => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI}/putPriority`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      campaigns,
    },
  });
};

export const getApprovalList = ({ fileType, responseType, page, limit, orderBy, actionId, term, status }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/action/getApprovalList', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      format: fileType,
      actionId,
      page,
      limit,
      orderBy,
      term,
      status,
    },
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
