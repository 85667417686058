<template>
  <div>
    <skeleton-loader class="d-block mb-2" height="17px" width="78px" />
    <skeleton-loader class="d-block mb-2" height="17px" width="111px" />
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>
