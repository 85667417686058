<template>
  <div>
    <skeleton v-show="$_verifyLoaded('loading')" :data="data" :type="type" />
    <table-list class="product-best-seller" :data="data" :type="type" :empty="!data.total_count">
      <table-list-header v-if="$_verifyLoaded('done')" :title="$tc('product.title', 0)" :icon="'icon-trending-1'">
        <table-list-column class="col-6">{{ $tc('product.title', 1) }}</table-list-column>
        <table-list-column class="col-3 text-right">{{ $t('product.product_quantity') }}</table-list-column>
        <table-list-column class="col-3 text-right">{{ $t('product.total') }}</table-list-column>
      </table-list-header>
      <table-list-body :remove-last-line="true">
        <template v-if="$_verifyLoaded('done')">
          <table-list-row
            v-for="(item, index) in data.rows"
            :key="index"
            :cursor-type="'pointer'"
            :product-i-d="item.id"
          >
            <table-list-column class="col-6" :title="item.prodName" :elipsis="true">{{
              item.prodName
            }}</table-list-column>
            <table-list-column class="col-3 text-right">{{ $_formatDecimal(item.prodQty, 3) }}</table-list-column>
            <table-list-column class="col-3 text-right">
              {{ formatCurrency(item.prodRevenue) }}
            </table-list-column>
          </table-list-row>
          <div class="link-container row d-flex justify-content-end">
            <label
              class="link color-oto-brand-omni col-auto text-right mr-3"
              @click="openLightbox('lightbox-product-best-seller')"
              >{{ $t('product.see-more-products') }}</label
            >
          </div>
        </template>
        <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
      </table-list-body>
    </table-list>
  </div>
</template>

<script>
import { getTable } from '@/services/product';
import { openLightbox } from '@/helpers/lightbox';
import bus from '@/helpers/events/bus';
import productsMixin from '@/helpers/mixins/productsMixin';
import ProductBestSellerSkeleton from '@/components/products/product/ProductBestSellerSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  name: 'ProductBestSeller',
  components: {
    skeleton: ProductBestSellerSkeleton,
    InfoCard,
  },
  mixins: [productsMixin, verifyMixin, numberMixin],
  data() {
    return {
      data: {
        limit: 5,
        page: 1,
        series: [],
        rows: [],
        total_count: 0,
      },
      type: 'filled',
      days: 6,
    };
  },
  mounted() {
    this.fetchData();

    // called after a global date change or brand change
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getTable({
        table: 'products_best_sellers',
        limit: this.data.limit,
        startDate: this.$_getSubtractedDate(this.days),
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.data.total_count = data.total_count;
          this.data.rows = [];
          this.formatRows(data.data);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRows(data) {
      const resolveRows = row => {
        const [id, prodName, prodQty, prodRevenue] = row;

        return {
          id,
          prodName,
          prodQty,
          prodRevenue,
        };
      };
      data.map(item => this.data.rows.push(resolveRows(item)));
    },
    openLightbox(id) {
      openLightbox(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  padding: 10px;
}
</style>
