<template>
  <div :class="['nav-item', 'user-item', item.id, { active: active }]">
    <div class="nav-icon">
      <icon-base v-if="item.id === 'glossary'" :box="'0 0 23 23'" w="23" h="23">
        <icon-table />
      </icon-base>

      <icon-base v-if="item.id === 'config'" :box="'0 0 23 23'" w="23" h="23">
        <icon-config />
      </icon-base>
    </div>
    <span v-show="active" class="nav-categorie">
      <template v-if="!item.blank && !item.logout">
        <router-link :to="item.url">{{ $t(`${item.name}`) }}</router-link>
        <span v-if="item.url === '/oto-academy'">Novo</span>
      </template>
    </span>
  </div>
</template>

<script>
// components
import IconBase from '@/assets/vue-icons/IconBase';
import IconTable from '@/assets/vue-icons/icons/Table';
import IconConfig from '@/assets/vue-icons/icons/Config';

export default {
  name: 'MenuUserItem',
  components: {
    IconBase,
    IconTable,
    IconConfig,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    methods: {
      logout() {
        this.$router.push('/logout');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;

  .nav-icon {
    height: 22px;
    min-width: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    position: relative;
  }

  &.active {
    justify-content: flex-start;

    .nav-icon {
      margin: 0 15px 0 6px;
    }
  }

  &.highlight-router {
    background: $pink-100;
    border-radius: 8px;
    transition: background ease-in 2s;
    padding: 6px;
  }

  .nav-categorie {
    white-space: nowrap;
    width: 100%;

    a {
      display: flex;
      align-items: center;
      height: 35px;
      width: 100%;
      color: $gray-700;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;

      &.no-pointer {
        cursor: auto;
      }

      .icon {
        width: 10px;
        display: block;
        margin-left: auto;

        .arrow-down {
          display: inline-block;
          border: solid $gray-500;
          border-width: 0 1px 1px 0;
          border-radius: 0;
          padding: 3px;
          transform: translate(0, -15px) rotate(45deg);
        }
      }
    }
  }
}
.glossary {
  svg {
    width: 18px;
    height: 18px;
  }
}
</style>
