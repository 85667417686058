import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';
import { Dates, TypeMetrics } from '../models';

export const getIndicators = (dates: Dates) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/oto/cultureRegistration/getIndicators', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate: dates.startDate,
      endDate: dates.endDate,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getMetrics = (dates: Dates, typeMetrics: TypeMetrics) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/oto/cultureRegistration/getMetrics', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate: dates.startDate,
      endDate: dates.endDate,
      metrics: typeMetrics,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getTablePresets = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/oto/cultureRegistration/getTablePresets', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getTable = params => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/oto/cultureRegistration/getTable', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      startDate: params.dates.startDate,
      endDate: params.dates.endDate,
      preset: params.preset,
      limit: params.limit,
      page: params.page,
      query: params.query,
      orderBy: params.orderBy,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getExportTable = (
  preset: string,
  dates: Dates,
  fileType: string,
  responseType?: 'blob',
  query?: [{ column: string; value: string }],
) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  const params = {
    brand: brand.id,
    startDate: dates.startDate,
    endDate: dates.endDate,
    query,
    preset,
    export: fileType,
  };

  return axios.get('/oto/cultureRegistration/getTable', {
    baseURL: endpointVersion('v3'),
    params,
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
