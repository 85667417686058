<template>
  <table-list :data="dataTable" class="loadHeight" type="filled">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column><modal-dates /></table-list-column>
        <table-list-column v-for="(value, key) in metricNames" :key="key" class="text-right">
          {{ value.includes('frequency') ? $t('avg-frequency') : `${value} ${$tc('purchase', value)}` }}
        </table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row
          v-for="(item, index) in dataTable.rows"
          :key="`lightbox-frequency-${index}`"
          :class="{ active: item.segment_id == segmentSelected }"
          :click-callback="() => $_handleTableRow(item.segment_id, index)"
        >
          <table-list-column> <i class="icon icon-filter"></i>{{ item.name }} </table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['1'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['2'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['3'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['4'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['5'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics['6+'] : 0)
          }}</table-list-column>
          <table-list-column class="text-right">{{
            $_formatDecimal(item.metrics ? item.metrics.frequency : 0)
          }}</table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" :empty="isEmpty" />
  </table-list>
</template>

<script>
import ModalDates from '@/components/ModalDates';
import getTable from '@/services/getTable';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'FrequencyTable',
  components: {
    ModalDates,
    InfoCard,
    BaseLoader,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataTable: {
        count: 0,
        rows: [],
      },
      segmentSelected: '',
      metricNames: [],
    };
  },
  mounted() {
    this.reset();
    this.loadTable();
  },
  methods: {
    loadTable() {
      getTable({
        metrics: ['arr_active_frequency_dd', 'frequency'],
        expand: 1,
        isLightbox: true,
      })
        .then(({ data: { data } }) => {
          if (!data) this.isEmpty = true;
          if (this.$_verifyData(data)) return;
          this.dataTable.rows = data.map(({ name, metrics, segment_id }) => ({
            name,
            metrics,
            segment_id,
          }));
          this.dataTable.count = this.dataTable.rows.length;
          this.metricNames = Object.keys(this.dataTable.rows[0].metrics);
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.$_reqConfig();
      this.ready = false;
      this.dataTable.rows = [];
    },
  },
};
</script>
