<template>
  <base-card-v2 class="product-revenue">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('revenue')" icon="icon-money" />
      <div class="row channel-revenue">
        <div class="col-4">
          <span class="label">{{ $t('tracked-revenue.total') }}</span>
          <span class="chart-value-big color-oto-brand-omni">{{ formatCurrency(revenue) }}</span>
        </div>
        <div class="col-4">
          <span class="label">{{ $tc('in-store', 1) }}</span>
          <span class="chart-value-big color-purple-400">{{ formatCurrency(revenueOff) }}</span>
        </div>
        <div class="col-4">
          <span class="label">{{ $t('ecommerce') }}</span>
          <span class="chart-value-big color-oto-ecommerce">{{ formatCurrency(revenueOn) }}</span>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <mini-bar-chart v-if="instoreTotal && jsonOff.data.length" :prop-data="jsonOff" />
        </div>
        <div class="col-6">
          <mini-bar-chart v-if="ecommerceTotal && jsonOn.data.length" :prop-data="jsonOn" />
        </div>
        <bottom-button :typecard="'card'">
          <template v-slot:bottom-button>
            <label
              class="link color-oto-brand-omni col-auto text-right mr-3"
              @click="openLightbox('lightbox-products-revenue', productInfo)"
              >{{ $t('product.see-all-origins') }}
            </label>
          </template>
        </bottom-button>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import { getMetrics } from '@/services/product';
import { openLightbox } from '@/helpers/lightbox';
import bus from '@/helpers/events/bus';
import MiniBarChart from '@/components/MiniBarChart';
import BottomButton from '@/components/_atoms/BaseCardBottomButton';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin, verifyThensMixin, miniBarChartMixin } from '@/helpers/mixins';

export default {
  name: 'ProductRevenue',
  components: {
    'mini-bar-chart': MiniBarChart,
    BottomButton,
    BaseLoader,
    InfoCard,
  },
  mixins: [miniBarChartMixin, verifyMixin, verifyThensMixin, numberMixin],
  props: {
    productID: {
      type: String,
      default: '',
      required: true,
    },
    productInfo: {
      type: [Object, Function],
      default: null,
      required: false,
    },
  },
  data() {
    return {
      topvalues: 5,
      instore: {},
      instoreTotal: 0,
      ecommerce: {},
      ecommerceTotal: 0,
      revenue: undefined,
      revenueOn: undefined,
      revenueOff: undefined,
      loading: false,
      loader: {
        loadingChannel: false,
        loadingInStore: false,
        loadingEcommerce: false,
      },
    };
  },
  computed: {
    jsonOn() {
      if (this.ecommerceTotal === 0) return;
      const parseArray = Object.keys(this.ecommerce).map(key => [key, this.ecommerce[key]]);
      return this.$_mountMiniBarTotalRange('purple', this.$t('ecommerce'), parseArray, this.ecommerceTotal);
    },
    jsonOff() {
      if (this.instoreTotal === 0) return;
      const parseArray = Object.keys(this.instore).map(key => [key, this.instore[key]]);
      return this.$_mountMiniBarTotalRange('blue', this.$tc('in-store', 1), parseArray, this.instoreTotal);
    },
  },
  mounted() {
    this.fetchData();

    bus.$on('render-cards', this.fetchData);
  },
  beforeUnmount() {
    bus.$on('render-cards', this.fetchData);
  },
  methods: {
    fetchData() {
      this.componentHasData = [];

      this.$_reqConfig();
      this.loader.loadingChannel = true;
      this.loader.loadingInStore = true;
      this.loader.loadingEcommerce = true;

      // load channel revenue
      getMetrics({
        productID: this.productID,
        metrics: ['revenue', 'revenue_off', 'revenue_on'],
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.revenue = data.metrics.revenue.data.value;
          this.revenueOn = data.metrics.revenue_on.data.value;
          this.revenueOff = data.metrics.revenue_off.data.value;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingChannel = false;
          this.$_verifLoading();
        });

      // instore
      getMetrics({
        productID: this.productID,
        metrics: 'revenue_by_medium',
        filters: 'store_type:off',
        topvalues: this.topvalues,
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.instore = data.metrics.revenue_by_medium.data[0].series.data;
          this.instoreTotal = data.metrics.revenue_by_medium.value_total;
        })
        .catch(() => {
          this.instore = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingInStore = false;
          this.$_verifLoading();
        });

      // ecommerce
      getMetrics({
        productID: this.productID,
        metrics: 'revenue_by_medium',
        filters: 'store_type:on',
        topvalues: this.topvalues,
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          this.ecommerce = data.metrics.revenue_by_medium.data[0].series.data;
          this.ecommerceTotal = data.metrics.revenue_by_medium.value_total;
        })
        .catch(() => {
          this.ecommerce = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingEcommerce = false;
          this.$_verifLoading();
        });
    },
    openLightbox(id, settings) {
      openLightbox(id, settings);
    },
  },
};
</script>
