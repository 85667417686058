<template>
  <table-list :id="id" :data="data" type="filled" :loading="data.loading" :is-lightbox-active="isLightboxActive">
    <table-list-header v-show="!data.loading">
      <table-list-column :class="`col-${12 - colScheme.length * 3}`" class="font-weight-normal">{{
        settingsData.title
      }}</table-list-column>
      <table-list-column v-for="(colName, colIndex) in colScheme" :key="colIndex" class="col-3 text-right">
        <span v-if="/percentual/g.test(colName)">%</span>
        <span v-else-if="/revenue/g.test(colName)">{{ $t('revenue') }}</span>
        <span v-else>{{ $tc('categories.order', 2) }}</span>
      </table-list-column>
    </table-list-header>
    <table-list-body v-show="!data.loading">
      <table-list-row
        v-for="(item, index) in data.values"
        :key="index"
        :loading="data.loading"
        :cursor-type="'pointer'"
      >
        <table-list-column :class="`col-${12 - colScheme.length * 3}`">{{ item[0] }}</table-list-column>
        <table-list-column v-for="(colName, colIndex) in colScheme" :key="colIndex" class="col-3 text-right d-block">
          <mini-bar
            v-if="/chart/g.test(colName)"
            class="mini-bar-size"
            :item="parseObj(item, colName)"
            :is-row-reverse="true"
          />
          <span v-if="/percentual/g.test(colName)" class="number" :style="`width: ${parseNumToPX('%')}`">{{
            $_formatNumber((item[getIndexByCol(colName)] / data.valueTotal) * 100, '0.00%')
          }}</span>
          <span v-if="/absolute/g.test(colName)" class="number" :style="`width: ${parseNumToPX()}`">{{
            $_formatDecimal(item[getIndexByCol(colName)], 3)
          }}</span>
          <span v-if="/revenue/g.test(colName)" class="number" :style="`width: ${parseNumToPX('$')}`">
            {{ formatCurrency(item[getIndexByCol(colName, item.length)]) }}
          </span>
        </table-list-column>
      </table-list-row>
    </table-list-body>
  </table-list>
</template>

<script>
import MiniBar from '@/components/MiniBar';
import { numberMixin } from '@/helpers/mixins';
import { formatCurrency, formatDecimal, formatNumber } from '@/utilities/formatters';
/**
 * Prop: colScheme
 * Receive an array with datatype and optional chart to show on table.
 * There are put in String array for one index by column
 * .
 * Available keys: percentual | absolute | revenue | chart
 *  - percentual: shows col name "Percentual" and % values;
 *  - absolute: show col name "Orders" and float number (quantitative data)
 *  - revenue: show col name "Revenue" and currency value
 *  - chart: optional to show chart (relative size to biggest number on column)
 * .
 *  Samples:
 *  :colScheme="['chart:absolute','chart:percentual']"
 *  :colScheme="['chart:revenue','percentual']"
 *  :colScheme="['absolute','revenue:chart']"
 */
export default {
  components: {
    MiniBar,
  },
  mixins: [numberMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
    settingsData: {
      type: Object,
      default: () => {},
    },
    isLightboxActive: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    dataType: {
      type: String,
      default: '',
    },
    colScheme: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getBigger() {
      /**
       * @description filter all values data.values object, map only values and get maximum value to use in minibar
       */
      const values = Object.values(this.data.values).filter(obj => obj instanceof Array);
      const valuesFiltered = values.map(num => num[1]);
      return Math.max(...valuesFiltered);
    },
    parseObj(item, colName) {
      /**
       * @description get a value from data.values and generate an object to use in minibar component
       */
      let color = 'darkblue';
      if (/revenue/g.test(colName)) {
        color = 'green';
      }
      return {
        type: 'percentage',
        amount: item[1],
        percentageamount: ((item[1] / this.getBigger()) * 100).toFixed(),
        colorbase: color,
        tooltip: `${item[0]}: ${item[1]}`,
      };
    },
    parseNumToPX(format) {
      /**
       * @description parse any number to relative px length, based on 8px per character
       */
      let numLength = this.data.values[0][1];
      switch (format) {
        case '%':
          numLength = (numLength / this.data.valueTotal) * 100;
          numLength = formatNumber(numLength, '% 0.00');
          numLength = String(numLength).length + 2;
          break;
        case '$':
          numLength = formatCurrency(numLength);
          numLength = String(numLength).length + 3;
          break;
        default:
          numLength = formatDecimal(numLength, 1);
          numLength = String(numLength).length;
          break;
      }
      return `${numLength * 8}px`;
    },
    getIndexByCol(colName = undefined, itemLength = 2) {
      /**
       * @description get index of each colScheme array to get specific type of value. e.g.: qtt, revenue when there are more than 1 value in array. Default is index 1;
       */
      if (/revenue/g.test(colName) && itemLength > 2) {
        return 2;
      }
      return 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-column {
  #minibar {
    display: inline-block;
    width: 30%;
  }
  span.number {
    display: inline-block;
    max-width: 65%;
    margin-left: 5%;
  }
}
</style>
