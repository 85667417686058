<template>
  <base-card
    id="customer-search"
    :ready="true"
    :title="$t('search.title')"
    :style="{
      backgroundImage: `url(${require('@/assets/img/figures/genericOtoBgBig.png')})`,
    }"
  >
    <customer-search-profiles />
  </base-card>
</template>
<script>
import CustomerSearchProfiles from '@/components/oto/customers/components/CustomerSearchProfiles';

export default {
  name: 'CustomerSearchBox',
  components: {
    CustomerSearchProfiles,
  },
};
</script>
<style lang="scss">
#customer-search {
  padding: 44px 50px;
  background-repeat: no-repeat;
  background-position: right top;
}
</style>
