import moment from 'moment';
import { dateTimeInverseFormat } from '@/utilities/constants';

const makeRangeForHourOrMinutes = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, index) => index);
};

export const disabledForwardDates = (time: Date) => {
  return time.getTime() > Date.now();
};

export const disabledBackwardDates = (time: Date) => {
  return time.getTime() < moment().subtract(1, 'days').valueOf();
};

export const disabledBackwardDatesFromStartDate = (time: Date, startDate: string) => {
  return time.getTime() < moment(startDate).valueOf();
};

export const disabledHours = (startDate: string) => {
  if (startDate > moment().format(dateTimeInverseFormat)) return [];
  return makeRangeForHourOrMinutes(0, moment().hour() - 1);
};

export const disabledMinutes = (startDate: string) => {
  if (startDate > moment().format(dateTimeInverseFormat)) return [];
  return makeRangeForHourOrMinutes(0, moment().minutes());
};

export const disabledCurrentDateAndForwardDates = (time: Date) => {
  return time.getTime() >= moment().subtract(1, 'days').valueOf();
};
