<template>
  <div>
    <header v-if="ready">
      <!-- subtitle top - ex: category name -->
      <slot name="item-top" />
      <div class="card-title d-flex align-items-center">
        <i v-if="icon" class="icon" :class="icon" />
        <img v-if="svg" class="icon" :src="require(`@/assets/svgs/${svg}.svg`)" />
        <h2 v-if="title" class="m-0">{{ title }}</h2>

        <!-- slot to append some item at the right corner - ex: select -->
        <slot name="item-right" />
      </div>
    </header>

    <!-- skeletons to title top or default h2 -->
    <base-card-skeleton :has-item-top-slot="hasItemTopSlot" :ready="ready" :is-space-active="isSpaceActive" />
  </div>
</template>

<script>
import BaseCardSkeleton from '@/components/_atoms/BaseCardSkeleton';

export default {
  name: 'BaseCardHeader',
  components: {
    BaseCardSkeleton,
  },
  props: {
    ready: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
    svg: {
      type: String,
      default: '',
      required: false,
    },
    isSpaceActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasItemTopSlot() {
      return !!this.$slots['item-top'];
    },
  },
};
</script>
