const mixin = {
  methods: {
    /**
     * @description sort array of objects by another array
     * @param {Array} array - array to be ordered
     * @param {Array} order - array order
     * @param {*} key - key to sort
     */
    $_mapOrder(array, order, key) {
      array.sort((a, b) => {
        const A = a[key];
        const B = b[key];
        return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
      });
      return array;
    },
    /**
     * @description filter routes by some route selection
     * @param {Array} menuItems - array with all default routes
     * @param {Array} routes - routes to be filtered
     */
    $_filterRoutesByGroup(menuItems, routes) {
      if (!menuItems || !routes) return;
      return menuItems.filter(el => routes.find(item => item.id === el.id));
    },
    /**
     * @description check if has subcategories
     */
    $_hasSubcategories(subLevels, id) {
      return subLevels.find(el => el.id.includes(id));
    },
    /**
     * @description get routes to build sublevels
     */
    $_buildSubLevel(subLevels, id) {
      return subLevels.find(el => el.id === id)?.routes;
    },
    /**
     * @description get all main routes that has sub routes
     */
    $_filterRoutesBySublevel(getSelectedRoutes) {
      if (!getSelectedRoutes) return;
      return getSelectedRoutes.filter(el => el.routes);
    },
    /**
     * @description get all routes that has sublevels and return the final routes
     * report has a number as id and doesn't need to search inside menuItems
     */
    $_resolveSubLevels(subLevels, menuItems) {
      const aux = [];
      subLevels.map(el => {
        if (el.id === 'reports') {
          const key = el.id;
          const routes = el.routes;
          aux.push({ id: key, routes });
        } else {
          const key = el.id;
          const routes = menuItems.filter(items => el.routes.find(el => el.id === items.id));
          const parseName = routes.map(item => {
            return { ...item, name: this.$t(item.name) };
          });
          aux.push({ id: key, routes: parseName });
        }
      });
      return aux;
    },
  },
};

export default mixin;
