import { valueType } from '@/utilities/constants';

export const validate = {
  serieFill: null,
  serie(data) {
    // Columns
    if (!data.column) return false;

    const { type, format, options } = this.serieFill[data.column];
    const between = data.operator === 'between';

    // isnull/notnull
    if (data.operator === 'isnull' || data.operator === 'notnull') return true;

    // Options
    if (options) return true;
    let result = this.field(format, type, data.value[0]);
    if (!result) return false;
    if (between) {
      result = this.field(format, type, data.value[1]);
      if (!result) return false;
    }
    return true;
  },
  field(format, type, value) {
    if (!value) return false;

    if (format === 'date_iso') return this.test.date(value);
    if (type === valueType.string) return this.test.string(value);
    if (type === valueType.integer) return this.test.integer(value);
    if (type === valueType.decimal) return this.test.decimal(value);

    return true;
  },
  test: {
    date(str) {
      return /\d{4}-\d{2}-\d{2}/.test(str);
    },
    string(str) {
      return str.length;
    },
    number(str) {
      return str.length && !Number.isNaN(str);
    },
    integer(str) {
      const value = toString(str);
      return this.number(value) && value.indexOf('.') < 0;
    },
    decimal(str) {
      return this.number(str);
    },
  },
};

export default {
  validate,
};
