<template>
  <div :class="['nav-item', { active: active }]">
    <div class="nav-icon">
      <img v-if="item.id === 'oto-academy'" src="@/assets/img/oto-academy/icon-academy.svg" />

      <icon-base v-if="item.id === 'analytics'" :color="color" :box="'0 0 18 18'" w="18" h="18">
        <icon-layout />
      </icon-base>

      <icon-base v-if="item.id === 'audience-mgmt'" :color="color" :box="'0 0 22 22'" w="22" h="22">
        <icon-graphic-pizza />
      </icon-base>

      <icon-base v-if="item.id === 'performance'" :color="color" :box="'0 0 20 16'" w="20" h="16">
        <icon-perf />
      </icon-base>

      <icon-base v-if="item.id === '360'" :color="color" :box="'0 0 12 18'" w="12" h="18">
        <icon-phone />
      </icon-base>

      <icon-base v-if="item.id === 'integrations'" :color="color" :box="'0 0 21 21'" w="21" h="21">
        <icon-circle-arrows />
      </icon-base>

      <!-- DEPRECATED -->
      <icon-base v-if="item.id === '360-desk'" :color="color" :box="'0 0 12 18'" w="12" h="18">
        <icon-phone />
      </icon-base>

      <icon-base v-if="item.id === 'audiences'" :color="color" :box="'0 0 22 22'" w="22" h="22">
        <icon-graphic-pizza />
      </icon-base>

      <icon-base v-if="item.id === 'dashboard-fullview'" :color="color" :box="'0 0 18 18'" w="18" h="18">
        <icon-layout />
      </icon-base>
      <icon-base v-if="item.id === 'segments'" :color="color" :box="'0 0 22 22'" w="22" h="22">
        <icon-graphic-pizza />
      </icon-base>

      <icon-base v-if="item.id === 'personas'" :color="color" :box="'0 0 20 20'" w="20" h="20">
        <icon-user />
      </icon-base>

      <icon-base v-if="item.id === 'reports'" :color="color" :box="'0 0 18 15'" w="18" h="15">
        <icon-drilldown />
      </icon-base>

      <icon-base v-if="item.id === 'dashboard-email'" :color="color" :box="'0 0 20 16'" w="20" h="16">
        <icon-perf />
      </icon-base>

      <icon-base v-if="item.id === 'dashboard-rockintags'" :color="color" :box="'0 0 16 18'" w="16" h="18">
        <icon-connect />
      </icon-base>

      <icon-base v-if="item.id === 'stores'" :color="color" :box="'0 0 17 18'" w="17" h="18">
        <icon-bag />
      </icon-base>

      <icon-base v-if="item.id === 'products'" :color="color" :box="'0 0 19 20'" w="19" h="20">
        <icon-cube />
      </icon-base>

      <icon-base v-if="item.id === 'campaigns'" :color="color" :box="'0 0 19 18'" w="19" h="18">
        <icon-megaphone />
      </icon-base>

      <icon-base v-if="item.id === 'labels'" :color="color" :box="'0 0 17 18'" w="17" h="18">
        <icon-label />
      </icon-base>

      <icon-base v-if="item.id === 'nps'" :color="color" :box="'0 0 20 20'" w="20" h="20">
        <icon-user />
      </icon-base>
    </div>

    <span v-show="active && item.name" :class="`nav-categorie nav-categorie--${item.id}`">
      <template v-if="item.blank">
        <a :href="item.url" target="_blank">{{ $t(`${item.name}`) }}</a>
      </template>

      <!-- For backwards compatibility, until API lists reports as sub-items -->
      <template v-else-if="item.id === 'reports'">
        <router-link :to="{ name: 'reports', params: { id: item.id } }" exact-path>
          {{ $t(`${item.name}`) }}
        </router-link>
      </template>

      <template v-else>
        <template v-if="hasSubcategories(item.id)">
          <a @click.prevent="toggle">
            {{ $t(`${item.name}`) }}
            <span class="icon">
              <i class="arrow-down"></i>
            </span>
          </a>
        </template>

        <template v-else>
          <router-link :to="item.url" @click="toggle">
            {{ $t(`${item.name}`) }}
            <span>
              <i class="arrow-down"></i>
            </span>
          </router-link>
        </template>

        <div v-show="categorieContainer.isVisible" v-if="hasSubcategories(item.id)" class="nav-subcategorie">
          <div v-for="item in buildSubLevel(item.id)" :key="item.id" class="sub--level1">
            <template v-if="typeof item.id === 'number'">
              <router-link :to="{ name: 'reports', params: { id: item.id } }" exact-path>
                {{ $t(`${item.name}`) }}
              </router-link>
            </template>

            <template v-else>
              <router-link
                :to="{ name: item.id, params: { id: item.id } }"
                exact-path
                :class="this.$route.name == item.id ? 'router-link-exact-active' : ''"
              >
                {{ $t(`${item.name}`) }}
              </router-link>
              <img src="@/assets/svgs/Star.svg" class="tag-new" v-if="showStarIcon(item.url)" />
            </template>
          </div>
        </div>
      </template>
    </span>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { exceptionallyIncludeIconStarOnRoutes } from '@/helpers/menu';

import IconBase from '@/assets/vue-icons/IconBase';
import IconLayout from '@/assets/vue-icons/icons/Layout';
import IconGraphicPizza from '@/assets/vue-icons/icons/GraphicPizza';
import IconUser from '@/assets/vue-icons/icons/User';
import IconDrilldown from '@/assets/vue-icons/icons/Drilldown';
import IconPerf from '@/assets/vue-icons/icons/Performance';
import IconConnect from '@/assets/vue-icons/icons/Connect';
import IconBag from '@/assets/vue-icons/icons/Bag';
import IconCube from '@/assets/vue-icons/icons/Cube';
import IconCircleArrows from '@/assets/vue-icons/icons/CircleArrows';
import IconMegaphone from '@/assets/vue-icons/icons/Megaphone';
import IconPhone from '@/assets/vue-icons/icons/Phone';
import IconLabel from '@/assets/vue-icons/icons/Label';
import vClickOutside from 'v-click-outside';

export default {
  name: 'MenuSidebarItem',
  components: {
    IconBase,
    IconLayout,
    IconGraphicPizza,
    IconUser,
    IconDrilldown,
    IconPerf,
    IconConnect,
    IconBag,
    IconCube,
    IconCircleArrows,
    IconMegaphone,
    IconPhone,
    IconLabel,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: '#888',
    },
    subLevels: {
      type: Array,
      default: null,
    },
    idActive: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categorieContainer: {
        isVisible: false,
      },
    };
  },
  computed: {
    checkActiveItem() {
      return this.idActive === this.item.id;
    },
  },
  mounted() {
    bus.$on('sidebar-visible-sublevels', () => {
      if (this.checkActiveItem) this.categorieContainer.isVisible = true;
      else this.categorieContainer.isVisible = false;
    });
  },
  beforeUnmount() {
    bus.$off('sidebar-visible-sublevels');
  },
  methods: {
    /**
     * @description check if has subcategories
     */
    hasSubcategories(id) {
      return this.subLevels.find(el => el.id.includes(id));
    },

    /**
     * @description get routes to build sublevels
     */
    buildSubLevel(id) {
      return this.subLevels.find(el => el.id === id)?.routes;
    },

    /**
     * @description toggle subcategories container
     */
    toggle() {
      this.categorieContainer.isVisible = !this.categorieContainer.isVisible;
    },

    /**
     * @description show star icon with route is included on exceptionallyIncludeIconStarOnRoutes
     */
    showStarIcon(route) {
      return exceptionallyIncludeIconStarOnRoutes.includes(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;

  .nav-icon {
    height: 22px;
    min-width: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    position: relative;
  }

  &.active {
    justify-content: flex-start;

    .nav-icon {
      margin: 0 15px 0 6px;
      align-self: flex-start;
      margin-top: 6px;
    }
  }

  &.highlight-router {
    background: $pink-100;
    border-radius: 8px;
    transition: background ease-in 2s;
    padding: 6px;
  }

  .nav-categorie {
    white-space: nowrap;
    width: 100%;

    &--oto-academy {
      display: flex;
      align-items: center;
    }

    a {
      display: flex;
      align-items: center;
      height: 35px;
      width: 100%;
      color: $gray-700;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;

      &.no-pointer {
        cursor: auto;
      }

      .icon {
        width: 10px;
        display: block;
        margin-left: auto;

        .arrow-down {
          display: inline-block;
          border: solid $gray-500;
          border-width: 0 1px 1px 0;
          border-radius: 0;
          padding: 3px;
          transform: translate(0, -15px) rotate(45deg);
        }
      }
    }
  }

  .nav-subcategorie {
    max-width: 185px;
    height: auto;

    .sub--level1 {
      padding-top: 10px;
      display: flex;
      align-items: center;
      position: relative;

      a {
        display: flex;
        max-width: 190px;
        color: $gray-800;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 5px;

        &.router-link-exact-active {
          background: $pink-100;
          border-radius: 8px;
          font-weight: 600;
        }
      }
    }
  }
}

.tag-new {
  border-radius: 100px;
  font-family: 'Montserrat';
  font-size: 8px;
  font-weight: 700;
  line-height: 17px;
  color: #ffffff;
  background: #ff3162;
  position: absolute;
  right: 0px;
}
</style>
