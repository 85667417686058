<template>
  <table-list :data="dataTable" type="filled" class="loadHeight">
    <template v-if="$_verifyLoaded('done')">
      <table-list-header>
        <table-list-column class="col-3"><modal-dates /></table-list-column>
        <table-list-column class="text-right">{{ $t('ecommerce') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('mobile') }}</table-list-column>
        <table-list-column class="text-right">{{ $tc('in-store') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('omnichannel') }}</table-list-column>
        <table-list-column class="text-right">{{ $t('total') }}</table-list-column>
      </table-list-header>
      <table-list-body v-if="ready">
        <table-list-row
          v-for="(item, index) in dataTable.rows"
          :key="index"
          :click-callback="() => $_handleTableRow(item.segment_id)"
          :class="{ active: item.segment_id == segmentSelected }"
        >
          <table-list-column class="col-3"> <i class="icon icon-filter"></i> {{ item.name }} </table-list-column>
          <table-list-column class="text-right">{{ formatCurrency(item.metrics.revenue_on) }}</table-list-column>
          <table-list-column class="text-right">{{ formatCurrency(item.metrics.revenue_app) }}</table-list-column>
          <table-list-column class="text-right">{{ formatCurrency(item.metrics.revenue_off) }}</table-list-column>
          <table-list-column class="text-right">{{ formatCurrency(item.metrics.revenue_omni) }}</table-list-column>
          <table-list-column class="text-right">
            {{
              formatCurrency(
                item.metrics.revenue_on +
                  item.metrics.revenue_app +
                  item.metrics.revenue_off +
                  item.metrics.revenue_omni,
              )
            }}
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </table-list>
</template>

<script>
import getTable from '@/services/getTable';
import ModalDates from '@/components/ModalDates';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'OmnichannelRevenueTable',
  components: {
    ModalDates,
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  data() {
    return {
      ready: false,
      dataChartOmnichannelRevenue: {},
      dataTable: {},
      segmentSelected: undefined,
    };
  },
  mounted() {
    this.reset();
    this.fetchData();
  },
  methods: {
    reset() {
      this.ready = false;
      this.dataTable.rows = [];
      this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
    },
    fetchData() {
      getTable({
        metrics: ['arr_customer_type_revenue'],
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.data)) return;

          this.dataTable.rows = response.data.data;
          this.dataTable.count = this.dataTable.rows.length;
          this.ready = true;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
