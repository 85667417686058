<template>
  <base-card-v2 class="category-overview" :ready="!loading">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header icon="icon-database" :title="$t('overview')" />
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-6 col-xl mb-sm-3">
              <div class="row">
                <div class="col-sm-6 col-xl-12">
                  <label class="chart-title">{{ $t('categories.overview.clients-period') }}</label>
                  <span class="chart-value-big d-block color-blue-500">{{ $_formatDecimal(overview.customers) }}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl mb-sm-3">
              <div class="row">
                <div class="col-sm-6 col-xl-12">
                  <label class="chart-title">{{ $t('categories.overview.products-sold') }}</label>
                  <span class="chart-value-big d-block color-blue-500">
                    {{ $_formatDecimal(overview.products_sale) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl mb-sm-3">
              <div class="row">
                <div
                  class="col-sm-6 col-xl-12 cursor-pointer"
                  v-tooltip="{ content: tooltipText, distance: 8, html: true, theme: 'default' }"
                >
                  <label class="chart-title tooltip-overview cursor-pointer">{{
                    $t('categories.overview.revenue-total')
                  }}</label>
                  <span class="chart-value-big d-block color-blue-500">{{
                    $_formatPercent(getTotalPercentRevenue, 2)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col">
              <minibar-chart :prop-data="jsonOverview" />
            </div>
          </div>
        </div>
      </div>
      <div class="link-container row d-flex justify-content-end pt-10">
        <label class="link color-oto-brand-omni col-auto text-right mr-3" @click="anchor()">{{
          $t('categories.see-more-subcategories')
        }}</label>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="error" />
  </base-card-v2>
</template>

<script>
import bus from '@/helpers/events/bus';
import { getTable } from '@/services/categories';
import MiniBarChart from '@/components/MiniBarChart';
import miniBarChartMixin from '@/helpers/mixins/miniBarChartMixin';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin } from '@/helpers/mixins';
import { formatCurrency, formatPercent } from '@/utilities/formatters';

export default {
  name: 'CategoryOverview',
  components: {
    'minibar-chart': MiniBarChart,
    BaseLoader,
    InfoCard,
  },
  mixins: [miniBarChartMixin, verifyMixin, numberMixin],
  props: {
    overview: {
      type: Object,
      default: () => {},
    },
    category: {
      type: String,
      default: '',
      required: true,
    },
    load: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      jsonOverview: [],
      errorProps: Boolean,
    };
  },
  computed: {
    getTotalPercentRevenue() {
      return this.overview.category_revenue / this.overview.revenue;
    },
  },
  watch: {
    load() {
      this.loading = this.load;
    },
    overview() {
      if (parseInt(this.overview.category_revenue) == 0) this.isEmpty = true;
      else this.isEmpty = false;
    },
  },
  created() {
    return this.fetch_data();
  },
  mounted() {
    this.errorProps = this.error;
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetch_data() {
      if (parseInt(this.overview.category_revenue) == 0) this.isEmpty = true;
      else this.isEmpty = false;

      getTable({
        name: this.category,
        table: 'list_subcategories_revenue',
        limit: 5,
      })
        .then(({ data }) => {
          this.jsonOverview = this.$_mountMiniBarTotalRange(
            'blue',
            this.$t('categories.overview.highest-subcategories-stores'),
            data.data,
            data.value_total,
          );
        })
        .catch(() => {
          this.errorProps = true;
          this.$_componentHeight();
        });
    },
    anchor() {
      window.scroll({
        top: 1800,
        left: 0,
        behavior: 'smooth',
      });
    },
    tooltipText() {
      const tooltipMsgStart = this.$t('categories.overview.tooltip.category-revenue');
      const tooltipMsgMid = this.$t('categories.overview.tooltip.represents');
      const tooltipMsgEnd = this.$t('categories.overview.tooltip.total');
      const catRevenue = formatCurrency(this.overview.category_revenue);
      const percentRevenue = formatPercent(this.getTotalPercentRevenue, 2);
      const totalRevenue = formatCurrency(this.overview.revenue);
      const title = `${tooltipMsgStart} <b style="text-decoration:none">(${catRevenue})</b> ${tooltipMsgMid} ${percentRevenue} ${tooltipMsgEnd} <b style="text-decoration:none">(${totalRevenue})</b>`;
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1200px) {
  .category-overview {
    height: 290px;
    .divText {
      width: 90%;
    }
  }
}
.top-subcategory-label {
  color: $gray-700;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.link {
  cursor: pointer;
}
.pt-10 {
  padding-top: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
