import { Threshold, FormatValue, SearchColumns } from '../models';
import { formatDecimal, formatCurrency, formatNumber } from '@/utilities/formatters';

const DEFAULT_MASK = {
  mask: '*'.repeat(255),
  tokens: {
    '*': { pattern: /./ },
  },
};

/*
 * Verify if amount is between threshold values
 * [66, 34] = > than 66 is a positive status, < than 34 is a negative status
 * [34, 66] = < than 34 is a positive status, > than 66 is negative status
 */
export const checkThreshold = (config: Threshold) => {
  if (!config.threshold || !config.threshold.length || config.threshold.length < 2) return '';

  const amount = parseInt(config.amount);
  const [positive, negative] = config.threshold;

  const theBiggestNumberIsTheBestResult = positive > negative;
  const VALID = {
    biggerNumber: {
      positive: amount >= positive,
      median: amount < positive && amount >= negative,
    },
    lowerNumber: {
      positive: amount <= positive,
      median: amount > positive && amount <= negative,
    },
  };

  const STATUS = {
    positive: 'pointer-green',
    negative: 'pointer-red',
    median: 'pointer-yellow',
  };

  if (theBiggestNumberIsTheBestResult) {
    if (VALID.biggerNumber.positive) return STATUS.positive;
    else if (VALID.biggerNumber.median) return STATUS.median;
    return STATUS.negative;
  } else {
    if (VALID.lowerNumber.positive) return STATUS.positive;
    else if (VALID.lowerNumber.median) return STATUS.median;
    return STATUS.negative;
  }
};

/*
 * Format of table values
 */
export const formatValue = (item: FormatValue) => {
  const { type, amount } = item;

  const formattedResults = {
    percent: formatNumber(+amount, '0.00%'),
    decimal: formatCurrency(amount),
    integer: formatDecimal(amount),
    default: amount,
    string: amount,
  };

  return formattedResults[type];
};

/*
 * Formats table column width
 */
export const getWidth = (column: string) => {
  const width = {
    order_year_month: 110,
    order_store: 120,
    cluster: 120,
    total_orders: 130,
    order_date: 150,
    order_id: 210,
    full_name: 210,
  };

  const hasWidth = Object.keys(width).includes(column);
  return hasWidth ? width[column] : 180;
};

/*
 * Add mask type to column search input
 */
export const getMask = (column: SearchColumns) => {
  return column === 'order_year_month' || column === 'order_date' ? '####-##' : DEFAULT_MASK;
};

/*
 * Adds placeholder to column search input
 */
export const getPlaceholder = (column: SearchColumns) => {
  return column === 'order_year_month' || column === 'order_date' ? '2000-12' : 'Busque nesta coluna';
};

/*
 * Formats table rows
 */

export const formatDataTable = (data, columns) => {
  return data.map(row => {
    return Object.fromEntries(
      row.map((value, index) => {
        return [columns[index].key, value];
      }),
    );
  });
};
