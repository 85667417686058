<template>
  <div class="error-message">
    <div class="error-message__icon"><i class="icon icon-error-60px"></i></div>
    <div class="error-message__message">
      <p>{{ $t(`settings.integrations.messages.${message}`) }}</p>
    </div>
    <div class="error-message__button">
      <button class="btn btn-continue" @click.prevent="buttonClick">
        {{ $t('confirm') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    message: {
      type: String,
      default: 'something-wrong',
    },
    buttonClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  margin-bottom: 50px;

  &__icon {
    color: $color-red;
  }

  &__message p {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__button button {
    margin: auto;
    min-width: 160px;
  }
}
</style>
