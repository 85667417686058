<template>
  <div id="push-notifications-preview" class="card">
    <div class="row">
      <div class="col-6">
        <form class="pp__message-config">
          <!-- title -->
          <div class="container">
            <label for="lightbox-new-message-template-message" class="label">
              {{ $t('360.push.preview.subject') }}
            </label>
            <input
              v-model="subject"
              :placeholder="$t('360.push.preview.subject-placeholder')"
              :disabled="isViewMode"
              @blur="isSubmitButtonActive"
            />
            <div class="block-textarea d-flex aling-items-center">
              <span v-show="countSubject > limitSubject" class="text-left count">
                <i class="icon icon-attention-filled p-0"></i>
                {{ $t('360.push.preview.message-preview') }}
              </span>
              <span class="d-block text-right count bar-chart">
                <span :class="countSubject > limitSubject ? 'red' : 'green'">
                  {{ countSubject }}
                </span>
                {{ $t('360.push.preview.character', { limit: limitSubject }) }}
              </span>
            </div>
          </div>
          <!-- dragndrop area -->
          <div class="container drag-drop">
            <label for="lightbox-new-message-template-message" class="label">
              {{ $t('360.push.preview.message') }}
            </label>

            <textarea
              ref="textarea"
              v-model="text"
              name="message"
              cols="30"
              rows="3"
              autofocus
              :placeholder="$t('360.push.preview.message-placeholder')"
              :disabled="isViewMode"
              @dragover="focusTextarea"
              @blur="isSubmitButtonActive"
            ></textarea>
            <div class="block-textarea d-flex aling-items-center">
              <span v-show="countText > limitText" class="text-left count">
                <i class="icon icon-attention-filled p-0"></i>
                {{ $t('360.push.preview.message-preview') }}
              </span>
              <span class="d-block text-right count bar-chart">
                <span :class="countText > limitText ? 'red' : 'green'">
                  {{ countText }}
                </span>
                {{ $t('360.push.preview.character', { limit: limitText }) }}
              </span>
            </div>

            <p>{{ $t('360.drag-the-variables-below-into-the-message-field') }}</p>
            <ul>
              <li
                v-for="label in labels"
                :key="label.id"
                :draggable="true"
                @dragstart="
                  evt => {
                    onDrag(evt, label.value);
                  }
                "
              >
                {{ label.value }}
              </li>
            </ul>
          </div>
          <div class="row mt-3">
            <!-- Link -->
            <div class="col-6">
              <div class="form-group pp__datepicker">
                <label class="value-label">{{ $t('360.push.preview.notification-link') }}</label>
                <v-select
                  v-model="selectedLink"
                  :clearable="false"
                  :searchable="false"
                  :options="linkOptions"
                  label="name"
                  :disabled="isViewMode"
                  :placeholder="$tc('_select', 2)"
                  @option:selected="onChangeSelectedLink"
                  @blur="isSubmitButtonActive"
                >
                  <template #option="{ name }">
                    {{ $tc(name, 2) }}
                  </template>
                  <template #selected-option="{ name }">
                    {{ $tc(name, 2) }}
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class="arrow-down"></span>
                  </template>
                  <template #no-options>
                    {{ $t('integrations.no-results') }}
                  </template>
                </v-select>
              </div>
            </div>
            <!--page -->
            <div v-if="isPageListActive" class="col-6 pp__no-date-group">
              <div class="form-group pp__datepicker">
                <label class="value-label">{{ $t('360.push.preview.page') }}</label>
                <v-select
                  v-model="selectedPage"
                  :clearable="false"
                  :searchable="false"
                  :options="pageList"
                  label="name"
                  :reduce="item => item.id"
                  :disabled="isViewMode"
                  :placeholder="$tc('_select', 2)"
                  @blur="isSubmitButtonActive"
                >
                  <template #option="{ name }">
                    {{ $tc(name, 2) }}
                  </template>
                  <template #selected-option="{ name }">
                    {{ $tc(name, 2) }}
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes" class="arrow-down"></span>
                  </template>
                  <template #no-options>
                    {{ $t('integrations.no-results') }}
                  </template>
                </v-select>
              </div>
            </div>
            <!-- url -->
            <div v-else class="col-6 pp__no-date-group">
              <div class="form-group pp__datepicker">
                <label class="value-label">Url</label>
                <input
                  v-model="url"
                  type="url"
                  placeholder="www.oto.com.br/"
                  :disabled="isViewMode"
                  @blur="isSubmitButtonActive"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <!-- schedule date -->
            <div class="col-6">
              <div class="form-group element--datepicker">
                <label class="value-label">{{ $t('360.push.preview.schedule-date') }}</label>
                <el-date-picker
                  v-model="scheduleDate"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :clearable="false"
                  :prefix-icon="customPrefix"
                  :disabled-date="disabledBackwardDates"
                  :placeholder="$tc('_select', 2)"
                  :disabled-hours="disabledDatepickerHours"
                  :disabled-minutes="disabledDatepickerMinutes"
                  :disabled="isViewMode"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-6">
        <!-- phone preview -->
        <div class="pp__phone-screen">
          <div class="widget">
            <span class="app-name">{{ $t('360.push.phone.app') }}</span>
            <span class="push-subject">{{ subject }}</span>
            <span class="push-message">{{ text }}</span>
          </div>
          <img src="https://pmweblabs.s3.amazonaws.com/assets/phone-screen.png" width="274" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import vSelect from 'vue-select';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters, mapActions, mapState } from 'vuex';
import { h, shallowRef } from 'vue';
// services
import { getVariables } from '@/services/oto/push';
// helpers
import { checkboxMixin } from '@/helpers/mixins';
import { disabledBackwardDates, disabledHours, disabledMinutes } from '@/utilities/datepicker';

export default {
  name: 'PushCreateMessage',
  components: {
    vSelect,
  },
  mixins: [checkboxMixin],
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  setup() {
    const customPrefix = shallowRef({
      render() {
        return h('i', {
          class: 'icon icon-calendar-30px',
        });
      },
    });
    return { v$: useVuelidate(), customPrefix };
  },
  data() {
    return {
      id: 'push-preview',
      labels: [],
      hasError: false,
      loading: false,
      limitSubject: 60,
      limitText: 120,
      isPageListActive: true,
    };
  },
  validations() {
    return {
      text: {
        required,
      },
      subject: {
        required,
      },
      scheduleDate: {
        required,
      },
      audienceId: {
        required,
      },
      url: {
        required,
      },
      selectedPage: {
        required,
      },
    };
  },
  computed: {
    ...mapState('pushNotifications', ['pageList', 'linkOptions', 'selectedLink', 'selectedPage', 'url', 'audienceId']),
    ...mapGetters('pushNotifications', [
      'getPreviewText',
      'getPreviewSubject',
      'getScheduleDate',
      'getUrl',
      'getPage',
      'getLink',
      'getField',
    ]),
    countSubject() {
      return this.subject.length;
    },
    countText() {
      return this.text.length;
    },
    text: {
      get() {
        return this.getPreviewText;
      },
      set(text) {
        this.updatePreviewText(text);
      },
    },
    subject: {
      get() {
        return this.getPreviewSubject;
      },
      set(subject) {
        this.updatePreviewSubject(subject);
      },
    },
    scheduleDate: {
      get() {
        return this.getScheduleDate;
      },
      set(date) {
        this.updateScheduleDate(date);
      },
    },
    url: {
      get() {
        return this.getUrl;
      },
      set(url) {
        this.updateUrl(url);
      },
    },
    selectedPage: {
      get() {
        return this.getPage;
      },
      set(selectedPage) {
        this.updatePage(selectedPage);
      },
    },
    selectedLink: {
      get() {
        return this.getLink;
      },
      set(selectedLink) {
        this.updateLink(selectedLink);
      },
    },

    isViewMode() {
      return this.mode === 'view';
    },

    isEditMode() {
      return this.mode === 'edit';
    },
  },
  watch: {
    searchTerm(value) {
      this.updateSearchTerm(value);
    },
    getScheduleDate() {
      if (this.isViewMode || this.isEditMode) this.handleFieldsCaseHasMode();
    },
    v$() {
      this.isSubmitButtonActive();
    },
  },
  mounted() {
    this.fetchData();
    this.isSubmitButtonActive();
  },
  methods: {
    ...mapActions('pushNotifications', [
      'updatePreviewSubject',
      'updatePreviewText',
      'updateScheduleDate',
      'updateSubmitStatus',
      'updateUrl',
      'updatePage',
      'updateLink',
    ]),

    fetchData() {
      getVariables().then(({ data }) => {
        this.labels = data || [];
      });
    },

    validateSchedule() {
      if (this.$v.scheduleDate.numeric === false) this.$v.scheduleDate.$model = '';
    },

    isSubmitButtonActive() {
      if (
        !this.v$.text.$invalid &&
        !this.v$.subject.$invalid &&
        !this.v$.scheduleDate.$invalid &&
        !this.v$.audienceId.$invalid &&
        (!this.v$.url.$invalid || !this.v$.selectedPage.$invalid)
      ) {
        this.updateSubmitStatus(true);
      } else this.updateSubmitStatus(false);
    },

    onChangeSelectedLink() {
      if (this.selectedLink.id === 'screen') {
        this.selectedPage = { id: 'notifications', name: 'header.sidebar.360-notifications' };
        this.isPageListActive = true;
        this.url = '';
      } else {
        this.selectedPage = '';
        this.isPageListActive = false;
      }
    },

    setDrag(id) {
      this.dradding = id;
    },

    onDrag(evt, variable) {
      evt.dataTransfer.setData('text/plain', variable);
      this.setDrag(variable);
    },

    focusTextarea() {
      this.$refs.textarea.focus();
    },

    changeExpireStatus() {
      this.isSubmitButtonActive();
    },

    handleFieldsCaseHasMode() {
      this.selectedLink = `360.push.preview.${this.getField.type}`;
      if (this.getField.type === 'screen') {
        this.isPageListActive = true;
        this.selectedPage = this.getField.fields.name;
      } else {
        this.isPageListActive = false;
        this.url = this.getField.value;
      }
    },
    disabledDatepickerHours() {
      return disabledHours(this.scheduleDate);
    },
    disabledDatepickerMinutes() {
      return disabledMinutes(this.scheduleDate);
    },
    disabledBackwardDates,
  },
};
</script>

<style lang="scss" scoped>
#push-notifications-preview {
  min-height: 668px;
  display: flex;
  justify-content: center;
  .pp__message-config {
    max-width: 480px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .count {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 11px;
    color: $gray-600;
    cursor: default;
    .green {
      color: #35ba9b;
      margin-right: 0;
    }
    .red {
      color: $color-red;
    }
  }

  .bar-chart {
    white-space: nowrap;
    margin-left: auto;
  }

  input {
    width: 100%;
  }

  .drag-drop {
    margin-top: 20px;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;

      li {
        margin: 5px;
        border-radius: 5px;
        background-color: $purple-400;
        color: white;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        padding: 5px;
        cursor: move;
      }
    }

    textarea {
      width: 100%;
      min-height: 126px;
      border-radius: 15px;
      padding: 20px 12px;
      box-sizing: border-box;
      border: 1px solid $gray-500;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $gray-600;
    }
  }

  button.submit {
    float: right;
    padding: 0 30px;
    width: 208px;
  }

  hr {
    margin-left: -50px;
    width: calc(100% + 100px);
  }

  .pp__datepicker {
    margin-bottom: 0;
    position: relative;
    width: 230px;
    .pp__datepicker-input {
      min-width: 230px;
      height: 36px;
    }
    .icon {
      color: $gray-600;
      position: absolute;
      right: 15px;
      bottom: 5px;
      font-size: 24px;
      pointer-events: none;
    }
  }

  .icon-attention-filled {
    font-size: 15px;
  }

  .pp__no-date-group {
    .pp__no-date {
      display: flex;
    }
  }

  .pp__phone-screen {
    text-align: center;

    .widget {
      font-size: 12px;
      position: absolute;
      text-align: left;
      top: 207px;
      left: 170px;
    }
    .app-name {
      font-size: 13px;
      color: $gray-600;
      font-weight: 500;
      padding-left: 28px;
    }
    .push-subject {
      font-weight: 600;
      color: $gray-900;
      display: block;
      max-width: 215px;
      padding-top: 8px;
      line-height: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .push-message {
      display: block;
      padding-top: 5px;
      max-width: 215px;
      font-size: 11px;
      color: $gray-900;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
</style>

<style lang="scss">
#push-notifications-preview {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
