<template>
  <section v-if="activePage === 'participants'" class="suggestions-participants">
    <campaigns-management-participants-header :action-id="id" />
    <campaigns-management-participants-table :action-id="id" />
  </section>
</template>

<script>
// libs
import { mapState } from 'vuex';

// components
import CampaignsManagementParticipantsHeader from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementParticipantsHeader';
import CampaignsManagementParticipantsTable from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementParticipantsTable';

export default {
  name: 'SuggestionParticipants',
  components: {
    CampaignsManagementParticipantsHeader,
    CampaignsManagementParticipantsTable,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('campaignsManagement', ['activePage']),
  },
};
</script>
