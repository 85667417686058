<template>
  <div class="card card-tabs">
    <slot name="title"></slot>
    <ul class="nav nav-tabs" v-if="!loading">
      <li v-for="tab in tabs" :key="tab" class="nav-item">
        <a class="nav-link d-flex align-items-center" :class="{ active: tab.id === selected }" @click="setTab(tab)">
          <span v-if="tab.name">{{ tab.name }}</span>
          <img v-if="tab.icon" :src="require(`@/assets/img/icons/alert.svg`)" />
        </a>
      </li>
    </ul>
  </div>

  <slot></slot>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    setTab(tab) {
      this.$emit('selected', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 7px 0 0 20px;
  .nav-tabs {
    border: 0;
    .nav-link {
      font-size: 12px;
      padding-bottom: 15px;
      border: 0;
      position: relative;
      cursor: pointer;
      margin-right: 30px;
      span {
        color: $gray-700;
        font-weight: 600;
      }
      &:hover {
        border-bottom: 3px solid #5172cf;
        span {
          color: #5172cf;
        }
      }
      &.active {
        border-bottom: 3px solid $oto-omni;
        span {
          color: $oto-omni;
        }
      }

      img {
        margin-left: 6px;
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>
