<template>
  <div class="card">
    <div class="row header-suggestions">
      <div class="col-5">
        <icon-base class="hs__icon-oto" color="#fff" w="30" h="30" :box="'0 0 30 30'">
          <icon-oto />
        </icon-base>
        <h1 class="hs__title">{{ $t('360.campaigns.management') }}</h1>
      </div>
      <div class="col-auto">
        <input v-model="searchTerm" class="hs__input" :placeholder="$t('360.campaigns.search-campaigns')" />
        <i class="icon icon-search hs__icon-search"></i>
      </div>
      <div class="col-auto">
        <button class="btn btn-secondary hs__edit" @click="openPrioritiesModal()">
          <i class="icon icon-config hs__icon-config"></i>
          {{ $t('360.campaigns.edit-priority') }}
        </button>
      </div>
      <div class="col-auto">
        <button class="btn hs__new-action" @click="redirect">
          {{ $t('360.campaigns.details.new') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import { mapActions } from 'vuex';
import { debounce } from 'lodash';
// helpers
import bus from '@/helpers/events/bus';
// assets
import IconBase from '@/assets/vue-icons/IconBase';
import IconOto from '@/assets/vue-icons/icons/Oto';

export default {
  name: 'CampaignsManagementHeader',
  components: {
    IconBase,
    IconOto,
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  watch: {
    searchTerm(term) {
      this.triggerDebounce(term);
    },
  },
  methods: {
    ...mapActions('campaignsManagement', ['updateSearchTerm']),
    openPrioritiesModal() {
      bus.$emit('suggestion-priorities-modal');
    },

    triggerDebounce: debounce(function (term) {
      this.updateSearchTerm(term);
    }, 500),

    redirect() {
      this.$router.push({ name: 'oto-actions-create-old' });
    },
  },
};
</script>

<style lang="scss">
.header-suggestions {
  align-items: center;

  .hs__title {
    padding: 0 10px;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  .hs__icon-oto {
    height: 30px;
    width: 30px;
    vertical-align: middle;
    stroke: $oto-omni;
  }

  .hs__icon-search {
    position: absolute;
    right: 20px;
    top: 7px;
    font-size: 26px;
    color: $gray-500;
    cursor: pointer;
  }

  .hs__input {
    width: 240px;
    height: 40px;
  }

  .hs__edit {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 197px;
    padding: 0 15px;
  }

  .hs__new-action {
    width: 160px;
  }

  .hs__icon-config {
    font-size: 22px;
  }

  button,
  input {
    float: right;
    height: 40px;
  }
}
</style>
