import { FetchTableResponse, EngagementReportExport, TableParams, ExportTableParams } from '../models';
import { getTable, getExportTable } from '../services';

export const fetchTable = ({
  preset,
  limit,
  page,
  orderBy,
  query,
  campaignId,
  storeId,
  sellerId,
  cluster,
}: TableParams): Promise<FetchTableResponse> =>
  getTable({ preset, limit, page, orderBy, query, campaignId, storeId, sellerId, cluster }).then(response =>
    formatResponse(response?.data),
  );

const formatResponse = (response: FetchTableResponse): FetchTableResponse => ({
  data: response?.data,
  errors: response?.errors,
  series: response?.series,
  success: response?.success,
  totalCount: response?.totalCount,
  warnings: response?.warnings,
});

export const exportTable = ({
  fileType,
  metrics,
  preset,
  campaignId,
  storeId,
  sellerId,
  cluster,
}: ExportTableParams): Promise<EngagementReportExport> =>
  getExportTable({ fileType, metrics, preset, campaignId, storeId, sellerId, cluster }).then(response =>
    formatExport(response?.data),
  );

const formatExport = (response: EngagementReportExport): EngagementReportExport => ({
  data: response?.data,
});
