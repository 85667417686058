<template>
  <div>
    <div class="card table-suggestion-participants" :class="{ loadHeight: isEmpty || loading }">
      <template v-if="$_verifyLoaded('done')">
        <div class="tbl-shell">
          <div ref="tbl-inner-wrapper" class="tbl-inner-wrapper">
            <div ref="tableHeader" class="tbl-header">
              <div class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
                >
                  <div class="header-title">
                    {{ vH.name }}
                  </div>
                  <div
                    :ref="vH.key"
                    :class="[
                      `order-by ${vH.key}`,
                      {
                        active: isActive === vH.key,
                      },
                    ]"
                    @click.prevent="ordenate(vH.key)"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="headerFixed" ref="header-fixed" class="tbl-header header-fixed">
              <div class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
                >
                  <div class="header-title">
                    {{ vH.name }}
                  </div>
                  <div
                    :ref="vH.key"
                    :class="[
                      `order-by ${vH.key}`,
                      {
                        active: isActive === vH.key,
                      },
                    ]"
                    @click.prevent="ordenate(vH.key)"
                  >
                    <span :class="['dash-arrows', `${/-/.test(orderBy) ? 'desc' : 'asc'}`]"></span>
                  </div>
                </div>
              </div>
            </div>
            <div ref="tbl-body" class="tbl-body">
              <div v-for="(item, index) in tableData.rows" :key="index" class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'f', { campaign: vH.key === 'campaign' }]"
                >
                  <div v-if="vH.key === 'status'" class="tbs__status-container">
                    <div :class="['tbs__status-color', getStatus(item[vH.key])]"></div>
                    <p
                      :class="[
                        'threshold',
                        `${$_checkThreshold({
                          amount: item[vH.key],
                          threshold: vH.threshold,
                        })}`,
                      ]"
                    >
                      {{ $_getValueNewFormat({ vH, amount: item[vH.key] }) }}
                    </p>
                    <i
                      v-if="getStatus(item[vH.key]) === 'waiting'"
                      v-tooltip="{ content: this.$t('360.campaigns.tooltip.icon'), distance: 8 }"
                      class="icon icon-question-14px"
                    ></i>
                  </div>
                  <p
                    v-else-if="!!item[vH.key]"
                    :class="[
                      'threshold',
                      `${$_checkThreshold({
                        amount: item[vH.key],
                        threshold: vH.threshold,
                      })}`,
                    ]"
                  >
                    {{ $_getValueNewFormat({ vH, amount: item[vH.key] }) }}
                  </p>
                  <p v-else>-</p>
                </div>
              </div>
            </div>
            <div class="tbl-footer">
              <div v-for="(item, index) in tableData.totalResults" :key="index" class="tbl-row">
                <div
                  v-for="(vH, kH) in tableHeader"
                  :key="kH"
                  :class="['tbl-col', vH.isMetric ? 'metric' : 'dimension', { campaign: vH.key === 'campaign' }]"
                >
                  <p :class="['footer-item']">
                    {{
                      vH.key == 'store'
                        ? $t('360.lightbox.order-details.total')
                        : !isNil(item[vH.key])
                        ? $_getValueNewFormat({ vH, amount: item[vH.key] })
                        : '-'
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </div>
    <list-pagination :data="pagination" :limit="pagination.limit" :limit-range="limitRange" :identify="id" />
  </div>
</template>

<script>
// libs
import { mapState, mapActions } from 'vuex';
import { isEmpty } from 'lodash';

// services
import { getApprovalList } from '@/services/oto/campaigns';

// helpers
import bus from '@/helpers/events/bus';
import { dashboardMixin, verifyMixin, tableMixin } from '@/helpers/mixins';

// coomponents
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import ListPagination from '@/components/_atoms/ListPagination';

export default {
  name: 'CampaignsManagementParticipantsTable',
  components: {
    BaseLoader,
    InfoCard,
    ListPagination,
  },
  mixins: [dashboardMixin, tableMixin, verifyMixin],
  props: {
    actionId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: '',
      headerFixedWidth: 0,
      headerFixed: false,
      tableData: {},
      tableHeader: {},
      isActive: null,
      loadingExport: false,
      conversionWindow: 30,

      // pagination data
      id: 'suggestion-participants-table',
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
      },
      orderBy: 'store',
      limitRange: [5, 10, 20, 30, 50],
    };
  },
  computed: {
    ...mapState('campaignsManagement', ['term', 'filterStatus']),
  },
  created() {
    // bus called when dates are changed globally
    bus.$on(['render-cards'], () => {
      this.getTableInfo();
    });
  },
  mounted() {
    this.resetStatus();
    this.$nextTick(() => {
      bus.$on('updateApprovalTerm', () => {
        this.searchTerm = this.term;
        this.getTableInfo();
      });
      bus.$on('updateFilterStatus', () => {
        this.getTableInfo();
      });
    });
    this.getTableInfo();
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
    bus.$on(`${this.id}-change-page`, () => {
      this.getTableInfo();
      this.headerFixed = false;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off(['render-page-loader', 'render-cards', 'updateApprovalTerm', 'updateFilterStatus']);
    bus.$off(`${this.id}-change-page`);
  },
  methods: {
    ...mapActions('campaignsManagement', ['resetStatus']),
    getTableInfo() {
      this.$_reqConfig();

      const args = {
        ...(!isEmpty(this.term) && { term: this.searchTerm }),
        status: this.filterStatus,
        actionId: this.actionId,
        page: this.pagination.page,
        limit: this.pagination.limit,
        orderBy: this.orderBy,
      };

      getApprovalList(args)
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.tableHeader = data.series;
          this.tableData.rows = data.data;
          this.pagination.count = data.totalCount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    toggleExport() {
      this.isExportVisible = !this.isExportVisible;
    },

    ordenate(order) {
      if (!this.orderBy) {
        this.orderBy = order;
        this.getTableInfo();
        return;
      }

      this.isActive = order;

      if (this.orderBy === order) this.orderBy = `-${order}`;
      else this.orderBy = order;
      this.getTableInfo();
    },
    getStatus(name) {
      if (name === this.$t('360.campaigns.status.waiting')) {
        return 'waiting';
      } else if (name === this.$t('360.campaigns.status.participating')) {
        return 'participating';
      } else if (name === this.$t('360.campaigns.status.rejected')) {
        return 'rejected';
      } else if (name === this.$t('360.campaigns.status.no-response')) {
        return 'no-response';
      }
    },
    showIcon(status) {
      if (status === this.$t('360.campaigns.status.waiting')) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-suggestion-participants {
  padding-top: 0;

  &.loadHeight {
    min-height: 400px;
  }

  .tbl-inner-wrapper {
    min-height: initial;
    flex-direction: column;
  }

  .tbl-header {
    height: 50px;
  }

  .tbl-col {
    max-width: 190px;
    padding-top: 5px;
    text-align: left;

    &:first-child {
      margin-left: 30px;
    }
  }

  .tbl-body {
    .tbl-col {
      padding-left: 8px;

      &:first-child {
        margin-left: 30px;
        padding-left: 0;
      }
    }
  }

  .tbs__status-container {
    display: flex;
    align-items: center;

    .tbs__status-color {
      background-color: black;
      border-radius: 100%;
      width: 9px;
      height: 9px;
      margin-right: 8px;
    }

    .participating {
      background: $green-400;
    }
    .rejected {
      background: $color-red;
    }
    .no-response {
      background: $gray-500;
    }
    .waiting {
      background: $yellow-400;
    }

    .icon {
      margin-left: 3px;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
.tooltip {
  .tooltip-inner {
    text-align: left;
  }
}
</style>
