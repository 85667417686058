<template>
  <div class="double-match">
    <div class="col">
      <div class="left-graphic-content">
        <div class="row left-row-title">
          <div class="left-graphic-title-card">
            <slot name="left-graphic-title"></slot>
          </div>
        </div>
        <div class="row left-row-content">
          <div class="col left-col-content">
            <slot name="left-graphic"></slot>
            <slot name="left-value"></slot>
          </div>
          <div class="col">
            <span name="left-line" class="left-line"></span>
            <span name="left-ico" class="icon icon-database"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="title-content">
        <slot name="title-content-value"></slot>
      </div>
      <div class="percent-content">
        <slot name="percent-content-value"></slot>
      </div>
    </div>
    <div class="col">
      <div class="right-graphic-content">
        <div class="row right-row-title">
          <div class="right-graphic-title-card">
            <slot name="right-graphic-title"></slot>
          </div>
        </div>
        <div class="row right-row-content">
          <div class="col">
            <span name="right-ico" class="icon icon-database"></span>
            <span name="right-line" class="right-line"></span>
          </div>
          <div class="col right-col-content">
            <slot name="right-graphic"></slot>
            <slot name="right-value"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.m-15 {
  margin: 0 10px 0 10px;
}
.left-row-title {
  width: 125px;
  margin: -5px -5px;
}
.left-row-content {
  width: 190px;
  height: 50px;
  color: #bbbbbb;
}
.left-col-content {
  top: 11.5px;
  padding-right: 0px;
  margin: 0 -1px;
  color: $purple-200;
}
.left-line {
  border-top: 1px solid $purple-200;
  width: 100px;
  margin: 0px -6px;
  padding: 0px 1rem;
}
:slotted(.left-value) {
  padding-left: 60px;
  height: 10px;
}
.left-graphic-title-card {
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 15px;
}
.right-row-title {
  width: 125px;
  margin: -5px 0 -5px 55px;
}
.right-row-content {
  width: 190px;
  height: 50px;
  color: #bbbbbb;
}
.right-col-content {
  top: 11.5px;
  padding: 0px 72px 0 0px;
  margin: 0px -61px 0 -70px;
  color: $oto-ecommerce;
}
.right-line {
  border-top: 1px solid $oto-ecommerce;
  width: 100px;
  margin: 0px -7px;
  padding: 0px 1rem;
}
:slotted(.right-value) {
  height: 10px;
}
.right-graphic-title-card {
  text-align: left;
  font-size: 11px;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 15px;
}
.title-content {
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 5px;
}
.double-match {
  display: inherit;
  padding: 17px 0 15px 0;
}
.percent-content {
  text-align: center;
  font-size: 35px;
  color: #ef4c8b;
}
</style>
