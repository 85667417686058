<template>
  <div class="card" :style="{ backgroundImage: `url(${image})` }">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-3">
          <h2 class="card-title d-flex align-items-center mb-0">
            <i class="icon icon-cart"></i>
            <span>{{ $t('select-store-title') }}</span>
          </h2>
        </div>
        <div v-if="!isEmpty" class="col-3">
          <label>{{ $tc('store', 1) }}</label>
          <select id="store-select" v-model="$parent.filters.store" class="w-100" @change="changeStore">
            <option v-for="store in orderedStores" :key="store.id" :value="store.id">
              {{ store.store }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  name: 'StoreSelect',
  mixins: [verifyMixin],
  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      ready: false,
      image: require('@/assets/img/figures/genericOtoBg.png'), // eslint-disable-line global-require
    };
  },
  computed: {
    orderedStores() {
      const stores = this.stores.slice(0);
      return stores.sort((a, b) => {
        if (a.store < b.store) return -1;
        if (a.store > b.store) return 1;
        return 0;
      });
    },
  },
  watch: {
    stores() {
      this.$_verifyData(this.stores);
    },
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.$parent.filters = { ...this.$parent.defaultfilters, store: this.$parent.filters.store };
    });

    this.$_verifyData(this.stores);
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    filterHandler() {
      bus.$emit('store-changed', this.$parent.filters.store);
      bus.$emit('comparison-stores-filters', this.$parent.filters);
    },
    changeStore() {
      this.filterHandler();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 20px 25px;
  background-repeat: no-repeat;
  background-position: top right;
}
</style>
