<template>
  <div :id="id" :class="{ card: defaultClass }">
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCardV2',
  props: {
    id: {
      type: String,
      default: null,
      required: false,
    },
    defaultClass: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
