<template>
  <div class="lightbox-who-bought-skeleton">
    <div id="sales-per-sku-skeleton" class="table-list col-12" :type="type">
      <div class="col-6 d-flex wrapper align-item-center py-2">
        <div class="col-3">
          <skeleton-loader width="62px" height="14px" />
        </div>
        <div class="col-2">
          <skeleton-loader width="35px" height="14px" />
        </div>
        <div class="col-3 text-center">
          <skeleton-loader width="70px" height="14px" />
        </div>
      </div>
      <table-list-header class="pt-5 pb-3">
        <table-list-column class-name="text-left col-6">
          <skeleton-loader width="110px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col-3">
          <skeleton-loader width="70px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col-3">
          <skeleton-loader width="70px" height="14px" />
        </table-list-column>
      </table-list-header>
      <table-list-body>
        <table-list-row v-for="row in rows" :key="row">
          <table-list-column class-name="text-left col-6">
            <skeleton-loader width="68px" height="14px" />
          </table-list-column>
          <table-list-column class-name="text-right col-3">
            <skeleton-loader width="140px" height="14px" />
          </table-list-column>
          <table-list-column class-name="text-right col-3">
            <skeleton-loader width="140px" height="14px" />
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    rows() {
      const length = this.data.limit;
      const arr = new Array(length);

      for (let i = 0; i < length; i += 1) {
        arr[i] = (i + 1).toString();
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin: 0 50px;
}
</style>
