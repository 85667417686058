<template>
  <div class="row">
    <div v-if="!empty.active" class="table-list-body col-12" :class="[type, { 'remove-last-line': removeLastLine }]">
      <slot />
    </div>
    <table-list-body-empty v-if="empty.active" />
  </div>
</template>

<script>
import TableListBodyEmpty from '@/components/_atoms/table/TableListBodyEmpty';

export default {
  components: {
    'table-list-body-empty': TableListBodyEmpty,
  },
  props: {
    removeLastLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      let $current = this.$parent;

      while (!$current.type) $current = $current.$parent;

      return $current.type;
    },
    empty() {
      const state = {
        active: false,
        message: '',
      };

      let $current = this.$parent;

      while ($current && !$current.empty) $current = $current.$parent;

      if (!$current) return state;
      const { empty } = $current;

      if (typeof empty === 'object') return empty;

      state.active = empty;

      return state;
    },
  },
};
</script>

<style lang="scss">
.remove-last-line {
  .table-list-row:nth-last-child(2) .card {
    border: none !important;
  }
}
</style>

<style lang="scss" scoped>
.table-list-body,
.table-list-body-empty {
  width: 100%;

  &.filled {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 20px 60px rgba(51, 58, 69, 0.07);
    overflow: hidden;
    min-height: 10px;
  }

  &:hover .card {
    opacity: 0.6;
  }
}
</style>
