<template>
  <div v-show="visible" class="nav-breadcrumb">
    <div class="nav-breadcrumb-container">
      <router-link :to="{ path: getDefaultRoute }" exact>{{ $t('breadcrumb.home') }}</router-link>
      <template v-for="(bread, index) in breadcrumbList" :key="index">
        <div>
          <span class="arrow"> > </span>
          <router-link :class="[isCursorPointer(bread.link)]" :to="{ path: bread.link }">{{
            $t(`breadcrumb.${bread.name}`)
          }}</router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderBreadcrumb',
  data() {
    return {
      breadcrumbList: this.getBreadcrumb(),
      visible: true,
    };
  },
  computed: {
    ...mapGetters(['getDefaultRoute']),
  },
  watch: {
    $route() {
      this.breadcrumbList = this.getBreadcrumb();
    },
    visible() {
      bus.$emit('breadcrumb-visibility', this.visible);
    },
  },
  methods: {
    isAtPersonasCompare() {
      return this.$route.path.includes('/compare');
    },
    getBreadcrumb() {
      const breadcrumb = this.$route?.meta?.breadcrumb?.map(item =>
        !item.link
          ? {
              ...item,
              link: '',
            }
          : item,
      );
      return breadcrumb;
    },
    isCursorPointer(link) {
      return link === '' ? 'no-cursor' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-breadcrumb {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px;
  flex-grow: 1;

  .nav-breadcrumb-container {
    display: flex;
    height: 100%;
    align-items: center;
    .arrow {
      color: #bbb;
    }
    a {
      padding: 8px;
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      color: $oto-seller;
      &.router-link-exact-active {
        pointer-events: none;
      }
      &:first-child {
        padding-left: 0;
      }
    }

    > div:last-of-type {
      a {
        color: #555;
        pointer-events: none;
      }
    }

    .no-cursor {
      cursor: default;
    }
  }
}
</style>
