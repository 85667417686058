<template>
  <div class="grid-list-item" :class="type" @click.prevent="clickFunction">
    <div class="card" :class="type">
      <div class="card-body" :class="type">
        <div class="column">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clickCallback: {
      type: Function,
      default: null,
    },
  },
  computed: {
    type() {
      let $current = this.$parent;

      while (!$current.type) $current = $current.$parent;

      return $current.type;
    },
  },
  methods: {
    clickFunction(event) {
      if (event.target.nodeName !== 'A' && event.target.nodeName !== 'I') {
        if (this.clickCallback !== undefined) this.clickCallback(event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-list-item {
  width: 100%;

  .card {
    height: 100%;
    padding: 0;
    margin-bottom: 0;
    font-size: 12px;
    box-shadow: none;

    .card-body {
      display: flex;

      .column {
        height: 100%;
      }
    }
  }

  &:hover > .card,
  &.current > .card {
    opacity: 1;
    cursor: pointer;
    box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.12);
  }
}
</style>
