import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const DASHBOARDS = {
  email: '/email',
  sms: '/sms',
  push: '/push',
  webpush: '/webpush',
};

/**
 * Endpoint for card metrics & graphics
 * @param {string} metrics List of metrics to get data from
 * @param {number} graphic Enable graphic data
 */
export const getMetrics = ({ metrics = '', graphic = 0, dashboard = 'email', compare, campaign = null }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const URI = DASHBOARDS[dashboard];
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios.get(`${URI}/getMetrics`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      metrics: metrics.toString(),
      startDate,
      endDate,
      graphic,
      compare,
      filter: campaign ? `campaign:${campaign}` : null,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Endpoint for table metrics
 * @param {string} metrics List of metrics to get data from
 * @param {string} preset Preset name to get data from
 * @param {string} export Download the report in the selected format
 */
export const getTableMetrics = ({
  preset = 'campaign',
  fileType,
  responseType = '',
  filter = '',
  metrics = [],
  dimensions = [],
  dashboard = 'email',
}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const URI = DASHBOARDS[dashboard];
  const { startDate } = store.getters.dates;
  const { endDate } = store.getters.dates;

  return axios.get(`${URI}/getTable`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      preset,
      startDate,
      endDate,
      export: fileType,
      filter,
      metrics: metrics.toLocaleString(),
      groupBy: dimensions.toLocaleString(),
    },
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Endpoint for table presets
 * @param {string} table Preset name to get data from
 */
export const getTablePresets = ({ table = '', dashboard = 'email' } = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const URI = DASHBOARDS[dashboard];

  return axios.get(`${URI}/getTablePresets`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      table,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * Endpoint to get metrics and dimensions
 */
export const getMetricsDimensions = (dashboard = 'email') => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const URI = DASHBOARDS[dashboard];

  return axios.get(`${URI}/getTableMetrics`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
