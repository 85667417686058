// libs
import moment from 'moment';
import i18n from '@/i18n';
import numeral from 'numeral';

/**
 * @description Manage local storage actions
 * @param {*} prop
 */
export const getFromStorage = prop => localStorage.getItem(prop);
export const setStorage = (prop, value) => localStorage.setItem(prop, value);

/**
 * @description Default dates from local storage
 */
export const datesLocalStorage = localStorage.getItem('dates') ? JSON.parse(localStorage.getItem('dates')) : null;

/**
 * @description Set moment dates
 */
export const datesMoment = {
  startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
  endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
};

/**
 * @description Set default segment
 */
export const selectedSegmentDefault = {
  segmentGroupId: 1,
  segmentId: 1,
};

/**
 * @description Set defaults when change locale
 * @param {String} locale
 */
export const onChangeLocale = locale => {
  const languages = ['pt-BR', 'en', 'es'];
  if (languages.indexOf(locale) >= 0) {
    i18n.global.locale = locale;
    moment.locale(locale);
    numeral.locale(locale.toLowerCase());
  }
};
