<template>
  <div v-if="$experimental && $route.name !== 'Preferences'" v-show="show" class="experimental-mode">
    <span class="text">
      {{ $t('settings.preferences.experimental.phrase') }}
      <router-link to="/settings">{{ $t('settings.preferences.experimental.click-here') }}</router-link>
      {{ $t('settings.preferences.experimental.to-disable') }}
    </span>
    <i class="icon icon-close" @click.prevent="close"></i>
  </div>
</template>

<script>
export default {
  name: 'HeaderExperimental',
  data() {
    return {
      show: !this.getCookie('hideExperimentalMessage'),
    };
  },
  methods: {
    getCookie(cname) {
      const name = `${cname}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        // eslint-disable-line
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    setCookie(cname) {
      const expires = new Date().setHours(23, 59, 59, 0);
      document.cookie = `${cname}=true; expires=${new Date(expires)}; path=/`;
    },
    close() {
      this.setCookie('hideExperimentalMessage');
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.experimental-mode {
  position: fixed;
  top: 70px;
  background: $color-red;
  padding: 0 5px;
  width: 100%;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  color: $color-white;
  text-align: center;
  z-index: 99;
  span {
    line-height: 30px;
  }
  a {
    color: $color-white;
    text-decoration: underline;
  }
  i {
    float: right;
  }
}
</style>
