import { formatCurrency, formatDecimal, formatNumber } from '@/utilities/formatters';

const mixin = {
  methods: {
    /**
     * Create MiniBarChart with a total value to generate the bars.
     * @param {string} color - bar color
     * @param {string} title - chart title
     * @param {array} data - data content
     */
    $_mountMiniBar(color, title, data) {
      const json = {};
      json.data = [];
      json.title = title;
      json.suffix = '';

      if (data) {
        // total used to calculate percentage
        const total = data.reduce((a, c) => a + c[1], 0);

        // loop with [key, value] of data param to mount json.data
        data.map(([key, value]) => {
          // const percent = ((value / total) * 100).toFixed(0);
          const percent = total;
          return json.data.push({
            text: key,
            amount: formatNumber(percent, '0%'),
            percentageamount: percent,
            colorbase: color,
            tooltip: `${key}: <br> ${formatNumber(percent, '0%')} (<strong>${formatDecimal(value, 1)}</strong>)`,
          });
        });
      }
      return json;
    },

    /**
     * Create MiniBarChart with a 100% range to the biggest result.
     * @param {string} color - bar color
     * @param {string} title - chart title
     * @param {array} data - data content
     * @param {number} valueTotal - number to show the total value in all results
     * @param {string=} typeCard - string to explicit which card should mount
     */
    $_mountMiniBarTotalRange(color, title, data, valueTotal, typeCard = 'revenue') {
      const jsonVariationValues = {
        data: [],
        title,
        suffix: '',
      };

      // array with just number results
      const numberArr = data.map(el => el[1]);

      // biggest value from number array
      const bigger = Math.max(...numberArr);

      data.map(el => {
        const name = el[0];
        const value = el[1];
        const totalPercentBigger = ((value / bigger) * 100).toFixed(0);
        const totalPercent = (value / valueTotal) * 100;
        let displayValue = null;
        let tooltipReference = null;
        let tooltipValue = null;
        let tooltipValueTotal = null;
        switch (typeCard) {
          case 'revenue':
            displayValue = formatNumber(totalPercent, '0%');
            tooltipReference = formatNumber(totalPercent, '0.00%');
            tooltipValue = formatCurrency(value);
            tooltipValueTotal = formatCurrency(valueTotal);
            break;
          case 'quantity':
            displayValue = formatNumber(totalPercent, '0%');
            tooltipReference = formatNumber(totalPercent, '0.00%');
            tooltipValue = formatDecimal(value);
            tooltipValueTotal = formatDecimal(valueTotal);
            break;
          case 'quantityUnits':
            displayValue = formatDecimal(value);
            tooltipReference = formatNumber(totalPercent, '0.00%');
            tooltipValue = formatDecimal(value);
            tooltipValueTotal = formatDecimal(valueTotal);
            break;
          default:
            break;
        }
        return jsonVariationValues.data.push({
          text: name,
          type: 'percentage',
          amount: displayValue,
          percentageamount: totalPercentBigger,
          colorbase: color,
          tooltip: `${name}: <br> ${tooltipReference} (<strong>${tooltipValue}</strong>) ${this.$t(
            'tooltip.total',
          )} <br>(${tooltipValueTotal})`,
        });
      });

      return jsonVariationValues;
    },
  },
};

export default mixin;
