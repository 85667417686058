import { getAvailableCustomFields, putCustomFields, getCustomField } from '@/services/customFields';

const customFields = {
  namespaced: true,
  state: {
    activeTable: 'EXPORT',
    type: '',
    customField: '',
    name: '',
    prefix: '',
    sufix: '',
    variable: '',
    isEditable: true,
    registerCustomField: {},
    customFieldsOptions: [],
    group: [
      {
        name: 'SEGMENTATION',
        isActive: true,
      },
      {
        name: 'PROFILE',
        isActive: false,
      },
      {
        name: 'MESSAGES',
        isActive: false,
      },
    ],

    isEmptyState: true,
    allowCSV: true,
    showOnApp: false,
    variableForMessages: false,
    tooltipNameError: false,
    tooltipVariableError: false,
    toastSaveSuccess: false,
    isMaximumByTable: false,
    isLoading: false,
    isEditMode: false,
  },
  mutations: {
    SET_ACTIVE_FIELD(state, payload) {
      state.activeTable = payload;
    },

    SET_IS_EMPTY_STATE(state, payload) {
      const isEmpty = payload > 0 ? false : true;
      state.isEmptyState = isEmpty;
    },

    SET_ALLOW_CSV(state, payload) {
      state.allowCSV = payload;
    },

    SET_SHOW_ON_APP(state, payload) {
      state.showOnApp = payload;
    },

    SET_VARIABLES_FOR_MESSAGES(state, payload) {
      state.variableForMessages = payload;
    },

    SET_TYPE(state, payload) {
      state.type = payload;
    },

    SET_CUSTOM_FIELDS_OPTIONS(state, payload) {
      state.customFieldsOptions = payload;
    },

    SET_CUSTOM_FIELD(state, payload) {
      state.customField = payload;
    },

    SET_EDITABLE(state, payload) {
      state.isEditable = payload;
    },

    SET_NAME(state, payload) {
      state.name = payload;
    },

    SET_PREFIX(state, payload) {
      state.prefix = payload;
    },

    SET_SUFIX(state, payload) {
      state.sufix = payload;
    },

    SET_VARIABLE(state, payload) {
      state.variable = payload;
    },

    SET_GROUP(state, payload) {
      state.group = payload;
    },

    SET_TOOLTIP_NAME_ERROR(state, payload) {
      state.tooltipNameError = payload;
    },

    SET_TOOLTIP_VARIABLE_ERROR(state, payload) {
      state.tooltipVariableError = payload;
    },

    SET_TOAST_SAVE_SUCCESS(state, payload) {
      state.toastSaveSuccess = payload;
    },

    SET_IS_MAXIMUM_BY_TABLE(state, payload) {
      state.isMaximumByTable = payload;
    },

    SET_REGISTER_CUSTOM_FIELD(state) {
      const showOnApp = state.showOnApp;
      const variableForMessages = state.variableForMessages;

      // Return string with groups name
      const groups = state.group
        .filter(group => group.isActive !== false)
        .map(group => group.name)
        .toString();

      state.registerCustomField.customField = state.customField;
      state.registerCustomField.fieldName = state.name;
      state.registerCustomField.fieldType = state.type;
      state.registerCustomField.table = state.activeTable;
      state.registerCustomField.prefix = !showOnApp ? '' : state.prefix;
      state.registerCustomField.suffix = !showOnApp ? '' : state.sufix;
      state.registerCustomField.field = !variableForMessages ? '' : state.variable;
      state.registerCustomField.audienceReport = state.activeTable === 'EXPORT' ? String(+state.allowCSV) : '0';
      state.registerCustomField.group = groups;
    },

    SET_EDIT_CUSTOM_FIELD(state, payload) {
      const data = payload;

      state.customField = data.customField;
      state.allowCSV = data.audienceReport;
      state.name = data.fieldName;
      state.type = data.type;
      state.isEditable = data.isEditable;
      state.showOnApp = data.profile;
      state.variableForMessages = data.messages;
      state.prefix = data.prefix;
      state.sufix = data.suffix;
      state.variable = data.field;

      // Set prefix to recive filedName value with is empty when try to edit
      if (!state.showOnApp && state.isEditMode) {
        state.prefix = data.fieldName;
      }

      // PROFILE
      state.showOnApp ? (state.group[1].isActive = true) : (state.group[1].isActive = false);
      // MESSAGES
      state.variableForMessages ? (state.group[2].isActive = true) : (state.group[2].isActive = false);
    },

    SET_RESET(state) {
      state.customField = '';
      state.type = '';
      state.name = '';
      state.prefix = '';
      state.sufix = '';
      state.variable = '';
      state.allowCSV = true;
      state.showOnApp = false;
      state.variableForMessages = false;
      state.isEditMode = false;
      // PROFILE
      state.group[1].isActive = false;
      // MESSAGES
      state.group[2].isActive = false;
    },

    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_IS_EDIT_MODE(state, payload) {
      state.isEditMode = payload;
    },
  },
  actions: {
    setCustomFieldsAvailable({ commit, dispatch }, payload) {
      dispatch('setIsMaximumByTable', false);
      getAvailableCustomFields(payload)
        .then(({ data }) => {
          if (!data) return;
          if (data.data.length === 0) {
            dispatch('setIsMaximumByTable', true);
          }
          commit('SET_CUSTOM_FIELDS_OPTIONS', data.data);
        })
        .catch(() => {
          commit('SET_CUSTOM_FIELDS_OPTIONS', []);
        });
    },

    setRegisterCustomField({ commit, state, dispatch }) {
      dispatch('setToastSaveSuccess', false);
      dispatch('setTooltipNameError', false);
      dispatch('setTooltipVariableError', false);

      commit('SET_REGISTER_CUSTOM_FIELD');

      putCustomFields(state.registerCustomField).then(({ data }) => {
        if (data.success) {
          dispatch('setToastSaveSuccess', true);
          dispatch('setCustomFieldsAvailable', state.activeTable);
        }
        if (data.errors.code === 'duplicated-custom-field-name') {
          dispatch('setTooltipNameError', true);
        }
        if (data.errors.code === 'duplicated-custom-field-variable') {
          dispatch('setTooltipVariableError', true);
        }
      });
    },

    setCustomFieldToEdit({ commit, state }, payload) {
      commit('SET_IS_EDIT_MODE', true);
      commit('SET_IS_LOADING', true);
      getCustomField({ customField: payload, table: state.activeTable })
        .then(({ data }) => {
          commit('SET_EDIT_CUSTOM_FIELD', data.data);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    },

    setActiveTable({ commit, dispatch }, payload) {
      commit('SET_ACTIVE_FIELD', payload);
      dispatch('setCustomFieldsAvailable', payload);
    },

    setIsEmptyState({ commit }, payload) {
      commit('SET_IS_EMPTY_STATE', payload);
    },

    setIsAllowCSV({ commit }, payload) {
      commit('SET_ALLOW_CSV', payload);
    },

    setShowOnApp({ commit, dispatch }, payload) {
      commit('SET_SHOW_ON_APP', payload);
      dispatch('setGroup', 'PROFILE');
    },

    setVariablesForMessages({ commit, dispatch }, payload) {
      commit('SET_VARIABLES_FOR_MESSAGES', payload);
      dispatch('setGroup', 'MESSAGES');
    },

    setType({ commit }, payload) {
      commit('SET_TYPE', payload);
    },

    setCustomField({ commit }, payload) {
      commit('SET_CUSTOM_FIELD', payload);
    },

    setName({ commit }, payload) {
      commit('SET_NAME', payload);
    },

    setEditable({ commit }, payload) {
      commit('SET_EDITABLE', payload);
    },

    setPrefix({ commit }, payload) {
      commit('SET_PREFIX', payload);
    },

    setSufix({ commit }, payload) {
      commit('SET_SUFIX', payload);
    },

    setVariable({ commit }, payload) {
      commit('SET_VARIABLE', payload);
    },

    setTooltipNameError({ commit }, payload) {
      commit('SET_TOOLTIP_NAME_ERROR', payload);
    },

    setTooltipVariableError({ commit }, payload) {
      commit('SET_TOOLTIP_VARIABLE_ERROR', payload);
    },

    setToastSaveSuccess({ commit }, payload) {
      commit('SET_TOAST_SAVE_SUCCESS', payload);
    },

    setIsMaximumByTable({ commit }, payload) {
      commit('SET_IS_MAXIMUM_BY_TABLE', payload);
    },

    setGroup({ commit, state }, payload) {
      const group = state.group.map(item =>
        item.name === payload
          ? {
              ...item,
              isActive: !item.isActive,
            }
          : item,
      );
      commit('SET_GROUP', group);
    },

    setReset({ commit }) {
      commit('SET_RESET');
    },
  },
  getters: {
    getActiveTable: state => state.activeTable,
    getIsEmptyState: state => state.isEmptyState,
    getAllowCSV: state => state.allowCSV,
    getShowOnApp: state => state.showOnApp,
    getVariableForMessages: state => state.variableForMessages,
    getType: state => state.type,
    getCustomFieldsAvailable: state => state.customFieldsOptions,
    getCustomField: state => state.customField,
    getName: state => state.name,
    getPrefix: state => state.prefix,
    getSufix: state => state.sufix,
    getVariable: state => state.variable,
    getDisabled: state => (state.name && state.customField && state.type ? false : true),
    getTooltipNameError: state => state.tooltipNameError,
    getIsEditable: state => state.isEditable,
    getTooltipVariableError: state => state.tooltipVariableError,
    getToastSaveSuccess: state => state.toastSaveSuccess,
    getIsMaximumByTable: state => state.isMaximumByTable,
    getIsLoading: state => state.isLoading,
    getIsEditMode: state => state.isEditMode,
  },
};

export default customFields;
