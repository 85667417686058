const base = {
  namespaced: true,
  state: {
    isBrandRefreshActive: false,
  },
  mutations: {
    SET_SELECTED_BRAND(state, data) {
      state.isBrandRefreshActive = data;
    },
  },
  actions: {
    updateSelectedBrand({ commit }, data) {
      commit('SET_SELECTED_BRAND', data);
    },
  },
  getters: {
    getSelectedBrandStatus: state => state.isBrandRefreshActive,
  },
};

export default base;
