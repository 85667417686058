<template>
  <section class="stores-view">
    <div class="row">
      <div class="col-12">
        <store-select :stores="stores"></store-select>
      </div>
    </div>

    <!-- Database -->
    <stores-database></stores-database>
    <div class="row">
      <div class="col col-xl-4">
        <total-revenue class="stores-total-revenue"></total-revenue>
      </div>
      <div class="col col-xl-4 sv__stores-orders">
        <stores-orders></stores-orders>
      </div>
      <div class="col col-xl-4">
        <omnichannel-profile-card :filter-store="true"></omnichannel-profile-card>
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-3">
        <!-- Retençao -->
        <retention-card :filter-store="true"></retention-card>
      </div>
      <div class="col col-xl-4">
        <age-gender-card :filter-store="true"></age-gender-card>
      </div>
      <div class="col col-xl-5 px-0">
        <div class="col-sm-6 col-xl-12 order-xl-2">
          <profile-card :num-of-rows="8" :filter-store="true" class="card-pb-20"></profile-card>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-6">
        <seller-ranking :stores="stores"></seller-ranking>
      </div>
      <div class="col col-xl-6">
        <customer-ranking :stores="stores"></customer-ranking>
      </div>
    </div>

    <!-- Revenue by medium -->
    <div v-if="$experimental">
      <h2 class="h1 card-group-title">{{ $t('stores-medium-title') }}</h2>
      <div class="row">
        <div class="col col-xl-8">
          <stores-revenue></stores-revenue>
        </div>
        <div class="col col-xl-4">
          <stores-by-medium></stores-by-medium>
        </div>
      </div>
    </div>

    <div v-if="stores.length > 1">
      <h2 class="h1 card-group-title">{{ $t('comparable-title') }}</h2>
      <div class="row">
        <div class="col col-xl-3 card-height">
          <comparison-stores-filter
            :stores="stores"
            :filters="filters"
            class="comparison-filter"
          ></comparison-stores-filter>
        </div>
        <div class="col col-xl-9 card-height">
          <comparison-stores
            v-if="storesToPlot"
            :stores="storesToPlot"
            :filters="filters"
            class="comparison"
          ></comparison-stores>
        </div>
      </div>
    </div>

    <!-- Table -->
    <h2 class="h1 card-group-title">{{ $t('metrics') }}</h2>
    <div class="row">
      <div class="col-12">
        <comparison-stores-table />
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import StoreSelect from '@/components/stores/StoreSelect';
import StoresDatabase from '@/components/stores/StoresDatabase';
import AgeGenderCard from '@/components/storeSegments/ageGenderCard/AgeGenderCard';
import ProfileCard from '@/components/storeSegments/profileCard/ProfileCard';
import TotalRevenue from '@/components/stores/StoresTotalRevenue';
import StoresOrders from '@/components/stores/StoresOrders';
import StoresRevenue from '@/components/stores/StoresRevenue';
import StoresByMedium from '@/components/stores/StoresRevenueByMedium';
import ComparisonStores from '@/components/stores/ComparisonStores';
import ComparisonStoresFilter from '@/components/stores/ComparisonStoresFilter';
import ComparisonStoresTable from '@/components/stores/ComparisonStoresTable';
import RetentionCard from '@/components/storeSegments/retentionCard/RetentionCard';
import OmnichannelProfileCard from '@/components/stores/OmnichannelProfileCard';
import SellerRanking from '@/features/SalesRanking/components/SellerRanking/SellerRanking';
import CustomerRanking from '@/features/SalesRanking/components/CustomerRanking/CustomerRanking';
import listStores from '@/services/stores';
import getTableMetrics from '@/services/getTableMetrics';
import bus from '@/helpers/events/bus';
import { mapGetters } from 'vuex';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

const defaultfilters = {
  metrics: ['customers_active', 'customers_new', 'revenue', 'avg_order_value'],
  comparisonStores: [],
};

export default {
  name: 'Stores',
  components: {
    StoreSelect,
    StoresDatabase,
    AgeGenderCard,
    ProfileCard,
    TotalRevenue,
    StoresOrders,
    StoresRevenue,
    StoresByMedium,
    ComparisonStores,
    ComparisonStoresFilter,
    ComparisonStoresTable,
    RetentionCard,
    OmnichannelProfileCard,
    SellerRanking,
    CustomerRanking,
  },
  data() {
    return {
      stores: [],
      storesToPlot: [],
      filters: { ...defaultfilters, store: '' },
      defaultfilters,
      metrics: [],
    };
  },
  computed: {
    ...mapGetters(['selectedFilter']),
    storeName() {
      const selected = this.stores.find(item => item.id === this.filters.store);
      return selected ? selected.store : '';
    },
  },
  created() {
    this.getStoresInitial();
    this.getMetrics();
  },
  mounted() {
    useMixpanelConfig('view_analytics_stores');

    bus.$on('reset-filters', () => {
      this.resetFilters();
    });
    bus.$on('render-cards', () => {
      this.getStoresRenderCards();
      this.getMetrics();
    });
    bus.$on('wrap-stores-plot', () => {
      this.wrapStoresToPlot();
    });
    bus.$on('restore-default-filters', () => {
      this.filters.metrics = ['customers_active', 'customers_new', 'revenue', 'avg_order_value'];
    });
  },
  beforeUnmount() {
    bus.$off('wrap-stores-plot');
    bus.$off('render-cards');
  },
  methods: {
    getStoresInitial() {
      listStores().then(({ data }) => {
        this.stores = data.data || [];
        this.filters.store = this.stores[0]?.id;
        bus.$emit('store-changed', this.filters.store);
      });
    },
    getStoresRenderCards() {
      listStores()
        .then(({ data }) => {
          this.stores = data.data || [];
          this.filters.store = this.stores[0]?.id;
          this.wrapStoresToPlot();
          this.resetComparisonStores();
          bus.$emit('get-stores-list');
        })
        .then(() => {
          bus.$emit('store-changed', this.filters.store);
        });
    },
    wrapStoresToPlot() {
      this.storesToPlot = this.stores;
      this.filters.comparisonStore = [];
    },
    resetComparisonStores() {
      this.filters.comparisonStore = [];
    },
    getMetrics() {
      getTableMetrics({
        endpoint: 'stores/get_table_metrics',
        table: 'parallel_coordinates',
        filterId: this.selectedFilter,
      }).then(({ data }) => {
        const { metrics } = data.table_metrics;

        this.metrics = Object.keys(metrics).map(item => ({
          key: metrics[item].key,
          name: metrics[item].name,
        }));
      });
    },
    resetFilters() {
      // reset metrics and comparisonStores
      this.filters.metrics = ['customers_active', 'customers_new', 'revenue', 'avg_order_value'];
      this.resetComparisonStores();
      bus.$emit('comparison-stores-filters', this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.age-gender-card.closed,
.profile-card,
.retention-card {
  min-height: 280px;
  padding-bottom: 0;
}
.age-gender-card.closed {
  height: 280px;
}
.stores-total-revenue,
.stores-orders,
.omnichannel-profile-card {
  padding-bottom: 0;
}
.stores-orders,
.omnichannel-profile-card {
  min-height: calc(100% - 12px);
}
.stores-total-revenue {
  min-height: 300px;
}
.card-height {
  min-height: 370px;
  margin-bottom: 12px;
  .card {
    height: 100%;
    margin-bottom: 0;
  }
}
.sv__stores-orders {
  position: relative;
}
</style>

<style lang="scss">
.stores-view {
  .retention-card {
    .icon-expand {
      display: none;
    }
  }
}
</style>
