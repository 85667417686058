<template>
  <div class="comparison-stores-table">
    <table-list :id="id" :data="data" type="filled" :empty="!data.count">
      <div :class="{ loadHeight: isEmpty || loading || hasError }" class="table-card">
        <header v-if="$_verifyLoaded('done')" class="d-flex align-items-center justify-content-between">
          <h2 class="card-title d-flex align-items-center mb-0">
            <i class="icon icon-in-store"></i>
            <span>{{ $t('stores-information') }}</span>
          </h2>
          <div class="dropdown">
            <button
              id="dropdownStoresTable"
              class="btn btn-plus-blue export"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click.prevent="opened = !opened"
            >
              <i class="icon icon-download 18px"></i>
              {{ this.$t('360.export', { value: '' }) }}
            </button>
            <div v-show="opened" class="dropdown-menu" aria-labelledby="dropdownStoresTable">
              <a class="dropdown-item" :href="downloadURL">{{ $t('csv-export') }}</a>
              <a class="dropdown-item" href="#" @click.prevent="openSettings">{{ $t('select-table-metrics') }}</a>
            </div>
          </div>
        </header>
        <div v-show="$_verifyLoaded('done')" class="table-container">
          <table-list-header>
            <table-list-column v-for="(serie, index) in series" :key="index" :class="{ 'text-right': index !== 0 }">
              {{ serie.name }}
            </table-list-column>
          </table-list-header>
          <table-list-body>
            <table-list-row v-for="(row, index) in rows" :key="index">
              <table-list-column v-for="(column, index) in row" :key="index" :class="{ 'text-right': index !== 0 }">
                {{ format(index, column) }}
              </table-list-column>
            </table-list-row>
          </table-list-body>
        </div>
        <load-spinner v-show="$_verifyLoaded('loading')" :id="id" color="white" />
        <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
      </div>
    </table-list>
    <comparison-stores-settings />
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import getTable from '@/services/getTable';
import { downloadTable } from '@/services/stores';
import ComparisonStoresTableSettings from '@/components/stores/ComparisonStoresTableSettings';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import { formatCurrency, formatDecimal, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  components: {
    'comparison-stores-settings': ComparisonStoresTableSettings,
    'load-spinner': LoadSpinner,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      isEmpty: true,
      id: 'comparison-stores-table',
      rows: [],
      series: [],
      data: {
        count: 0,
        page: 1,
        limit: 10,
      },
      columns: [
        'store_name',
        'customers_new',
        'customers_active',
        'customers_lost',
        'customers_inactive',
        'revenue',
        'orders',
      ],
      opened: false,
    };
  },
  computed: {
    downloadURL() {
      return downloadTable({
        columns: this.columns,
        filters: '', // Omit stores filter on download
      });
    },
  },
  mounted() {
    bus.$on('update-table-settings', () => {
      this.fetch_data();
    });

    bus.$on('store-changed', () => {
      this.fetch_data();
      bus.$emit(`${this.id}-pagination-reset`);
    });

    bus.$on(`${this.id}-change-page`, this.fetch_data);

    this.bodyClick();
  },
  beforeUnmount() {
    bus.$off(['store-changed', 'update-table-settings']);
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      bus.$emit('show-spinner', this.id);
      getTable({
        endpoint: 'stores/get_table',
        metrics: this.columns.toString(),
        table: 'stores',
        page: this.data.page,
        limit: this.data.limit,
      })
        .then(data => {
          if (this.$_verifyData(data.data.data)) return;

          this.data.count = data.data.total_count;
          this.rows = data.data.data;
          this.series = data.data.series;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openSettings() {
      bus.$emit('comparison-stores-settings');
    },
    format(i, value) {
      if (this.series[i].type === valueType.percent) return formatNumber(value, '0.00%');
      else if (this.series[i].type === valueType.integer) return formatDecimal(value, 1);
      else if (this.series[i].type === valueType.float) return formatCurrency(value);
      return value;
    },
    bodyClick() {
      const body = document.querySelector('body');
      body.addEventListener('click', event => {
        if (event.target.className !== 'btn btn-plus-blue export') {
          this.opened = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#comparison-stores-table {
  padding: 0;
  .table-list-header {
    padding-top: 25px;
  }
  .download {
    max-width: 50px;
    a {
      text-decoration: none;
      .icon {
        color: $gray-500;
      }
    }
  }
  .icon-options {
    line-height: 16px;
  }
}
.dropdown {
  .btn {
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: space-evenly;
    line-height: 20px;
    width: 125px;

    .icon {
      font-weight: bold;
    }
  }

  .dropdown-menu {
    min-width: 100px;
    box-shadow: -1px 2px 9px rgba(0, 0, 0, 0.25);
    border: 0 none;
    border-radius: 0;
    padding: 0;
    left: auto !important;
    right: 0;
    top: 12px !important;
    transform: translate3d(0px, 25px, 0px) !important;
    z-index: 98 !important;

    .dropdown-item {
      font-size: 11.46px;
      padding: 3px 12px;
      color: $gray-600;
      line-height: 22px;
      font-weight: 600;
      cursor: pointer;

      &:hover:not(.disabled) {
        background-color: $gray-400;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
</style>
<style lang="scss">
#comparison-stores-table {
  .table-card {
    border-radius: 10px;
    background: $color-white;
    padding-top: 25px;
    position: relative;

    &.loadHeight {
      min-height: 500px;
    }

    header {
      padding: 0 25px;
    }
    .table-container {
      overflow-x: auto;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      &::-webkit-scrollbar-track {
        background-color: $gray-300;
        border-radius: 10px;
      }
      &::-webkit-scrollbar {
        height: 10px;
        background-color: $gray-300;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $gray-500;
        border-radius: 10px;
      }
      > .row {
        margin: 0;
      }
    }
  }
  .row {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
  .table-list-body.filled {
    width: fit-content;
    max-width: initial;
    overflow: initial;
  }
  .table-list-header {
    min-width: fit-content;
  }
  .table-list-column {
    min-width: 120px;
  }
}
</style>
