import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const controller = '360/push';
const controllerNotification = 'notification';

/**
 * registrar o link do novo endpoint
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Notification-getAudiences
 */
export const getAudiences = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerNotification}/getAudiences`,
    params: {
      brand: brand.id,
    },
  });
};

/**
 * registrar o link do novo endpoint
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Notification-getNotifications
 */
export const getNotifications = ({ args, fileType, responseType = '' }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerNotification}/getNotifications`,
    params: {
      brand: brand.id,
      ...args,
      export: fileType,
    },
    responseType,
  }).then(data => data.data);
};

/**
 * registrar o link do novo endpoint
 *
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Notification-getNotification
 */
export const getNotification = id => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerNotification}/getNotification`,
    params: {
      brand: brand.id,
      id,
    },
  }).then(data => data.data);
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-360_Push-getVariables
 */
export const getVariables = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getVariables`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};

/**
 * registrar o link do novo endpoint
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Notification-putNotification
 */
export const putNotification = ({ audience, title, notification, schedule, field, enabled }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const { user } = store.getters;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${controllerNotification}/putNotification`,
    params: {
      brand: brand.id,
      userId: user.id,
      audience,
      title,
      notification,
      schedule,
      field,
      enabled,
    },
  }).then(data => data.data);
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Notification-updateNotification
 */
export const updateNotification = ({ notificationId, audience, title, notification, schedule, field, enabled }) => {
  const brand = store.getters.selectedBrand;
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${controllerNotification}/updateNotification`,
    params: {
      brand: brand.id,
      notificationId,
      userId: user.id,
      audience,
      title,
      notification,
      schedule,
      field,
      enabled,
    },
  }).then(data => data.data);
};
