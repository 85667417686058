<template>
  <div class="sidebar-user-logout">
    <div v-if="!active" class="sidebar-user-logout__icon">
      <icon-base :box="'0 0 23 23'" w="23" h="23">
        <icon-logout />
      </icon-base>
    </div>
    <div v-if="active" class="sidebar-user-logout__active">
      <div v-if="getUserEmail">
        <span :title="getUserEmail" class="sidebar-user-logout__user">@{{ getUserEmail }}</span>
      </div>
      <div class="ud__logout">
        <span @click="logout" class="sidebar-user-logout__logout">{{ $t(`${item.name}`) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// helpers
import { menuUserLogout } from '@/helpers/menu';
// components
import IconBase from '@/assets/vue-icons/IconBase';
import IconLogout from '@/assets/vue-icons/icons/Logout';

export default {
  name: 'SidebarUserLogout',
  components: {
    IconBase,
    IconLogout,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    getUserEmail() {
      return this.$store.getters?.user?.email.split('@')[0];
    },
  },
  mounted() {
    this.getLogoutOption();
  },
  methods: {
    logout() {
      this.$router.push('/login');
      if (!this.$store.state.campaignsManagement.isDataUnchanged) {
        return;
      }

      this.$store.dispatch('logout').then(() => this.$router.push('/login'));
    },
    getLogoutOption() {
      this.item = menuUserLogout;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-user-logout {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 10%;

  &__icon {
    align-self: end;
  }

  &__active {
    align-self: end;
    display: flex;
    justify-content: space-between;
    width: inherit;
  }

  &__user {
    font-size: 12px;
    font-weight: 600;
    color: $gray-700;
  }

  &__logout {
    cursor: pointer;
    color: $blue-500;
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
