<template>
  <section id="indicators">
    <metrics-chart />
    <indicator-table />
    <base-loader v-show="loadingExport" color="colored-block" class="render-loader" />
  </section>
</template>

<script>
import IndicatorTable from '@/components/oto/indicators-old/IndicatorTable';
import MetricsChart from '@/components/oto/indicators-old/IndicatorMetricsChart';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import bus from '@/helpers/events/bus';

export default {
  name: 'Indicators',
  components: {
    BaseLoader,
    IndicatorTable,
    MetricsChart,
  },
  data() {
    return {
      loadingExport: false,
    };
  },
  mounted() {
    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });
  },
};
</script>

<style lang="scss" scoped>
.render-loader {
  background-color: #232642;
  height: 100%;
  left: 50%;
  opacity: 0.7;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 9999;
}
</style>
