export type Segments = {
  id: string;
  name: string;
};
export enum SelectedFilter {
  ALL = 'all',
  STORE = 'store',
  SELLER = 'seller',
  CAMPAIGN = 'campaign',
  CLUSTER = 'cluster',
}

export type SelectedFilterListOptions = {
  value: number;
  label: string;
  isChecked: boolean;
};

export type LoadingBySegments = {
  isLoadingStores: boolean;
  isLoadingSellers: boolean;
  isLoadingCampaigns: boolean;
  isLoadingClusters: boolean;
};
