<template>
  <section class="push-notifications">
    <push-header />
    <push-table />
  </section>
</template>

<script>
import PushHeader from '@/components/oto/pushNotifications/_organisms/PushHeader';
import PushTable from '@/components/oto/pushNotifications/_organisms/PushTable';
// utils
import { useMixpanelPushCommunications } from '@/utilities/mixpanel';

export default {
  name: 'PushNotifications',
  components: {
    PushHeader,
    PushTable,
  },
  mounted() {
    useMixpanelPushCommunications('view_oto_push_communications');
  },
};
</script>
