<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body :show-close="false">
    <dialog-error :close="closeModal" />
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
import DialogError from '@/components/_atoms/DialogError';

export default {
  name: 'SuggestionDialogErrorModal',
  components: {
    DialogError,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: 'suggestion-dialog-error-modal',
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('open-suggestion-campaign-modal-error', () => {
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off('open-suggestion-campaign-modal-error');
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#suggestion-dialog-error-modal {
  padding: 0 50px;
  text-align: center;

  .title {
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
  }

  .action-buttons {
    justify-content: space-evenly;
    width: 360px;
    margin: 28px auto;

    .btn {
      width: 160px;
      height: 40px;

      &.btn-close {
        background-color: $gray-600;
        &:hover {
          background-color: darken($gray-600, 20%);
        }
      }
    }
  }
}
</style>

<style lang="scss">
#suggestion-dialog-error-modal {
  width: 540px;
  max-width: 540px;
  min-height: 300px;

  .el-dialog__body {
    padding: 40px 0;
  }
}
</style>
