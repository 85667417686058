import { DataInconsistencyResponse, DataInconsistencyExport } from '../types';
import { getDataInconsistency } from '@/services/dataInconsistency';

export const fetchDataInconsistency = (): Promise<DataInconsistencyResponse> =>
  getDataInconsistency().then(response => formatAvailableTables(response?.data));

const formatAvailableTables = (response: DataInconsistencyResponse): DataInconsistencyResponse => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
  status: response?.status,
  series: response?.series,
  totalCount: response?.totalCount,
  lastUpdate: response?.lastUpdate,
});

// export data inconsistency
export const exportDataInconsistency = (fileType?: string, responseType?: 'blob'): Promise<DataInconsistencyExport> =>
  getDataInconsistency(fileType, responseType).then(response => formatExport(response?.data));

const formatExport = (response: DataInconsistencyExport): DataInconsistencyExport => response;
