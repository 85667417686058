<template>
  <div class="button-wrapper audience-add-serie-button ml-1">
    <button class="btn mr-3" @click.prevent="action">{{ title }}</button>
    <button v-show="isEdit" class="btn btn-secondary" @click.prevent="reset">{{ $t('cancel') }}</button>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: null,
    },
    action: {
      type: Function,
      default: null,
    },
    reset: {
      type: Function,
      default: null,
    },
  },
  computed: {
    title() {
      return this.isEdit ? this.$t('update') : this.$t('add');
    },
  },
};
</script>

<style lang="scss" scoped>
.audience-add-serie-button {
  display: flex;
  button {
    line-height: 34px;
    min-width: 128px;
  }
}
</style>
