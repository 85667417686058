<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.466797 10.5882C0.466797 5.11753 4.74353 0.666626 10.0001 0.666626C15.2567 0.666626 19.5335 5.11753 19.5335 10.5882V15.3333H0.466797V10.5882ZM1.29578
    11.0196V14.4705H18.7045V11.0196H16.632V10.1568H18.6838C18.5851 7.98443 17.7511 6.01478 16.4314 4.50498L14.9823 6.01219L14.3962 5.40223L15.8453
    3.89502C14.3946 2.52153 12.502 1.65361 10.4146 1.55094V1.96074V3.68623H9.58564V1.96074V1.55094C7.49825 1.65361 5.60568 2.52153 4.15495 3.89502L5.60402
    5.40223L5.01793 6.01219L3.56886 4.50498C2.24912 6.01478 1.41516 7.98443 1.31651 10.1568H3.36825V11.0196H1.29578ZM11.9292 8.95415L14.9061 8.43737L15.042
    9.28804L12.0651 9.80482C12.0237 10.9574 11.1168 11.8823 10.0001 11.8823C8.85779 11.8823 7.92767 10.9152 7.92767 9.72545C7.92767 8.53572 8.85779 7.56859
    10.0001 7.56859C10.8805 7.56859 11.6299 8.14576 11.9292 8.95415ZM8.75665 9.72545C8.75665 10.4389 9.31456 11.0196 10.0001 11.0196C10.6857 11.0196 11.2436
    10.4389 11.2436 9.72545C11.2436 9.01196 10.6857 8.43133 10.0001 8.43133C9.31456 8.43133 8.75665 9.01196 8.75665 9.72545Z"
  />
</template>
