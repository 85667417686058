
// libs
import bus from '@/helpers/events/bus';
import { reactive, toRefs, defineComponent, onMounted, watch } from 'vue';
import { isEmpty, debounce } from 'lodash';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vClickOutside from 'click-outside-vue3';
import moment from 'moment';
import FileDownload from 'js-file-download';
// helpers
import lightboxesMixin from '@/helpers/mixins/lightboxesMixin';
import { tableMixin, dashboardMixin, numberMixin } from '@/helpers/mixins';
import { dateFormatYMDHms } from '@/utilities/constants';
// utilities
import useVerifyMixin from '@/utilities/mixins/useVerifyMixin';
import useTableMixin from '@/utilities/mixins/useTableMixin';
// services
import { fetchTopCustomersDetailRanking } from '@/features/SalesRanking/services';
// components
import LightboxTemplate from '@/components/lightbox/LightboxTemplate.vue';
import ListPagination from '@/components/_atoms/ListPagination.vue';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';
import InfoCard from '@/components/_atoms/InfoCard.vue';
// types
import { salesRankingState } from '@/features/SalesRanking/types';

export default defineComponent({
  components: {
    LightboxTemplate,
    BaseLoader,
    InfoCard,
    ListPagination,
  },
  mixins: [lightboxesMixin, tableMixin, dashboardMixin, numberMixin],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const { hasError, isLoading, reqConfig, verifyData, verifyLoaded } = useVerifyMixin();
    const { getValueNewFormat } = useTableMixin();

    const events: salesRankingState = reactive({
      id: 'customer-ranking-table',
      identify: 'customer-ranking-table',
      term: '',
      storeId: '',
      pagination: {
        count: 0,
        limit: 10,
        page: 1,
      },
      limitRange: [5, 10, 20, 30, 50, 100],
      orderBy: '',
      dataTableHeader: {},
      dataTableBody: {
        rows: [],
      },
      headerFixed: false,
      isActive: null,
      isExportVisible: false,
      isLoadingExport: false,
    });

    //watchers
    watch(
      () => events.term,
      debounce(function (value) {
        events.pagination.page = 1;
        events.term = value;
        fetchTable();
      }, 800),
    );

    // mounted
    onMounted(() => {
      fetchTable();
    });

    // functions
    function fetchTable() {
      reqConfig();

      const args = {
        orderBy: events.orderBy,
        page: events.pagination.page,
        limit: events.pagination.limit,
        storeId: `storeId:${events.storeId}`,
        ...(!isEmpty(events.term) && { term: events.term }),
        segments: '',
      };

      fetchTopCustomersDetailRanking(args)
        .then(response => {
          if (verifyData(response?.data)) return;

          events.dataTableHeader = response.series;
          events.dataTableBody.rows = response?.data.map(row => {
            return Object.fromEntries(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              row.map((value: any, index: any) => {
                return [response.series[index].key, value];
              }),
            );
          });
          events.pagination.count = response.totalCount;
        })
        .catch(() => {
          hasError.value = true;
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function ordenate(order: string | null) {
      if (!events.orderBy) {
        events.orderBy = order;
        events.isActive = order;
        fetchTable();
        return;
      }

      events.isActive = order;

      if (events.orderBy === order) events.orderBy = `-${order}`;
      else events.orderBy = order;
      fetchTable();
    }

    function clearTerm() {
      events.term = '';
    }

    function toggleExport() {
      events.isExportVisible = !events.isExportVisible;
    }

    return {
      ...toRefs(events),
      verifyLoaded,
      getValueNewFormat,
      fetchTable,
      ordenate,
      clearTerm,
      toggleExport,
    };
  },
  data() {
    return {
      exportItems: [
        {
          value: 'csv',
          title: this.$t('360.export-type', { value: '.CSV' }),
        },
        {
          value: 'xls',
          title: this.$t('360.export-type', { value: '.XLS' }),
        },
        {
          value: 'xlsx',
          title: this.$t('360.export-type', { value: '.XLSX' }),
        },
      ],
    };
  },
  mounted() {
    bus.$on('render-page-loader', (isActive: boolean) => {
      this.isLoadingExport = isActive;
    });

    bus.$on(`${this.identify}-change-page`, () => {
      this.fetchTable();
      this.headerFixed = false;
    });

    window.onscroll = () => this.debounce();
  },
  beforeUnmount() {
    bus.$off([`${this.identify}-change-page`, 'render-page-loader']);
  },
  beforeMount() {
    this.storeId = this.settings.storeSelected.id;
  },
  methods: {
    fetchExportData(fileType: string) {
      this.isLoadingExport = true;
      bus.$emit('render-page-loader', true);

      const args = {
        storeId: `storeId:${this.storeId}`,
        term: this.term,
        fileType: fileType,
        segments: '',
        responseType: 'blob',
      };

      fetchTopCustomersDetailRanking(args)
        .then(response => {
          const date = moment().format(dateFormatYMDHms);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          FileDownload(response.export, `${date}.${fileType}`);
        })
        .finally(() => {
          this.isLoadingExport = false;
          bus.$emit('render-page-loader', false);
        });
    },
  },
});
