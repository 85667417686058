<template>
  <el-dialog
    v-model="isVisible"
    :id="id"
    :close-on-click-modal="false"
    append-to-body
    :close-on-press-escape="false"
    :show-close="false"
  >
    <dialog-success :title="$t('360.cashback.success.create')" :action="redirect" />
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
// components
import DialogSuccess from '@/components/_atoms/DialogSuccess';

export default {
  name: 'CashbackDialogSuccessModal',
  components: {
    DialogSuccess,
  },
  data() {
    return {
      id: 'cashback-dialog-success-modal',
      isVisible: false,
    };
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off([`open-${this.id}`]);
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    redirect() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#cashback-dialog-success-modal {
  padding: 0 50px;
  text-align: center;
}
</style>

<style lang="scss">
#cashback-dialog-success-modal {
  width: 540px;
  max-width: 540px;
  min-height: 300px;

  .el-dialog__body {
    padding: 40px 0;
  }
}
</style>
