/* eslint-disable no-restricted-globals */
/**
 * number formatting helper functions
 * can be used in vue filters
 */
import numeral from 'numeral';
import store from '@/store';
import '@/locales/numeral/pt-BR'; // 'pt-br'
import '@/locales/numeral/es'; // 'pt-br'

const language = store.getters.user ? store.getters.user.language.toLowerCase() : 'pt-br';
numeral.options.scalePercentBy100 = false;
numeral.locale(language || 'pt-br');

/**
 * apply a numeral.js formatting string to an input value
 * @param {Number} val input value to be changed
 * @param {String} [format='0,0'] numeral.js format string
 * @return {String} formatted output string
 */
export default (val, format = '0,0') => {
  // Regra para retornar zero quando o valor for Infinity(dividido por zero).
  if (val === Infinity || val === -Infinity) {
    return numeral(0).format(format);
  }
  return numeral(val).format(format);
};
