<template>
  <div>
    <base-card-v2 class="history__table">
      <slot class="history__container" name="table"></slot>
    </base-card-v2>
    <list-pagination
      v-if="pagination && pagination.count"
      :data="pagination"
      :limit-range="limitRange"
      :identify="id"
    />
  </div>
</template>

<script>
// helpers
import bus from '@/helpers/events/bus';
// components
import ListPagination from '@/components/_atoms/ListPagination';

export default {
  name: 'HistoryTable',
  components: {
    ListPagination,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    limitRange: {
      type: Array,
      default: () => [],
    },
    refreshList: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    bus.$on(`${this.id}-change-page`, () => {
      this.refreshList();
    });
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
  },
};
</script>

<style lang="scss" scoped>
.history__table {
  padding: 30px 0 0;
}
</style>
