// libs
import moment from 'moment';

// utilities
import { formatDecimal } from '@/utilities/formatters';

const mixin = {
  methods: {
    $_formatDate(date) {
      let dateTime = date;
      // check if date is valid
      if (moment(date).isValid()) {
        const diff = moment(date).diff(moment());
        // if date is in the past
        if (diff <= 0) {
          const minutes = Math.floor(diff / 60000);
          // humanize example: a hour ago
          dateTime = moment.duration(minutes, 'minutes').humanize(true);
        } else dateTime = moment(date).format('YYYY-MM-DD HH:mm');
      }
      return dateTime || '---';
    },
    $_rawDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm:ss');
    },
    $_formatTotalCount(value) {
      return formatDecimal(value);
    },
  },
};

export default mixin;
