<template>
  <el-dialog
    v-model="isVisible"
    :id="id"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    class="user-unlock-modal"
  >
    <template v-if="!loading && !errorModal">
      <div>
        <header>
          <icon-base :color="'#ec3942'" :box="'0 0 60 60'" w="60" h="60">
            <icon-alert />
          </icon-base>
        </header>
        <div class="row">
          <div class="col-12 pt-3">
            <span class="title user-unlock-modal__title">{{ $t('settings.users.unlock-question') }}</span>
          </div>
        </div>
        <footer class="user-unlock-modal__action-buttons d-flex">
          <button class="btn" @click.prevent="unlockUser">
            {{ $t('settings.users.unlock') }}
          </button>
          <button class="btn user-unlock-modal__btn-close" @click.prevent="closeModal">
            {{ $t('settings.users.cancel') }}
          </button>
        </footer>
      </div>
    </template>
    <template v-if="loading && !errorModal">
      <dialog-loader :text="this.$t('settings.users.unlock-message')" />
    </template>
    <template v-if="errorModal">
      <dialog-error :close="closeModal" />
    </template>
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';
import { useToast } from 'vue-toastification';
// services
import { getUnlockUser } from '@/services/userSettings';
// components
import DialogLoader from '@/components/_atoms/DialogLoader';
import DialogError from '@/components/_atoms/DialogError';
// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconAlert from '@/assets/vue-icons/icons/Alert';

export default {
  name: 'UserQuestionModal',
  components: {
    IconBase,
    IconAlert,
    DialogError,
    DialogLoader,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      id: 'user-unlock-modal',
      loadingModal: false,
      errorModal: false,
      loading: false,
      error: false,
      targetId: '',
      targetName: '',
      isVisible: false,
    };
  },
  watch: {
    loading(loading) {
      this.loadingModal = loading;
      if (!this.loadingModal && this.errorModal === false) this.closeModal();
    },
    error(error) {
      this.errorModal = error;
    },
  },
  mounted() {
    bus.$on('openUserUnlockModal', user => {
      this.targetId = user.id;
      this.targetName = user.name;
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off('openUserUnlockModal');
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    unlockUser() {
      if (!this.targetId) return;
      this.loading = true;
      getUnlockUser({ targetId: this.targetId })
        .then(({ data }) => {
          if (data.success) this.toast.success(this.$t('settings.users.unlock-sucess', { name: this.targetName }));
        })
        .catch(() => {
          this.errorUnlock = true;
        })
        .finally(() => {
          this.loading = false;
          bus.$emit('update-users');
        });
    },
  },
};
</script>

<style lang="scss">
.user-unlock-modal {
  width: 540px;
  max-width: 540px;
  min-height: 300px;
  text-align: center;

  &__title {
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
  }

  &__action-buttons {
    justify-content: space-evenly;
    width: 360px;
    margin: 28px auto;

    .btn {
      width: 160px;
      height: 40px;

      &-close {
        background-color: $gray-600;

        &:hover {
          background-color: darken($gray-600, 20%);
        }
      }
    }
  }

  .el-dialog__body {
    padding: 40px 0;
  }
}
</style>
