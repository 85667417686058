import { CampaignsResponse } from '../models';
import { getCampaigns } from '../services';

export const fetchCampaigns = (): Promise<CampaignsResponse> =>
  getCampaigns().then(response => formatResponse(response?.data));

const formatResponse = (response: any): CampaignsResponse =>
  response?.data.map((campaign: any) => ({
    label: campaign?.name,
    value: campaign?.id,
  }));
