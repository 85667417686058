import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const URI = 'account';

export const getSuggestionLimit = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI}/getSuggestionLimit`,
    params: {
      brand: brand.id,
    },
  });
};

export const putSuggestionLimit = (limitType, limitValue) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI}/putSuggestionLimit`,
    data: {
      brand: brand.id,
      limitType: limitType,
      limitValue: limitValue,
    },
  });
};
