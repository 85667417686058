<template>
  <div class="history__defaults">
    <i v-if="hasIcon" :class="`icon ${icon}`"></i>
    <h1 v-if="title" :class="`history__title${hasIcon ? '--icon-is-active' : ''}`">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'HistoryDefaults',
  props: {
    title: {
      type: String,
      default: '',
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'icon-clock-18px',
    },
  },
};
</script>

<style lang="scss" scoped>
.history__defaults {
  display: flex;
  align-items: center;

  h1 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .history__title {
    &--icon-is-active {
      padding: 0 20px 0;
    }
  }

  .icon {
    color: $gray-500;
    font-size: 26px;
  }
}
</style>
