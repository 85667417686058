<template>
  <section class="omnichanneloverview-wrapper">
    <section class="omni__collapsed" @click="getCollapsed(id)">
      <div :class="['omni__collapsed-border', !collapsed ? '--gray' : '--pink']">
        {{ $t('360.analytics-o2o.omnichannel') }}
      </div>
      <div class="omni__icon">
        <i v-show="!collapsed" class="icon icon-plus"></i>
        <i v-show="collapsed" class="icon icon-minus-30px"></i>
      </div>
    </section>
    <section v-show="collapsed" id="omnichanneloverview">
      <!-- eslint-disable no-param-reassign -->
      <h1 v-if="$route.path.includes('/dashboard/fullview')" class="card-group-title">
        {{ $t('omnichannel-overview.title') }}
      </h1>
      <div class="row justify-content-lg-between justify-content-center">
        <div class="col-sm-7 col-xl-5 mh-omni">
          <omnichannel-overview-diagrama-venn :make-api-call="makeApiCall" />
        </div>
        <div class="col-sm-12 col-xl-7 mh-omni">
          <omnichannel-overview-table :make-api-call="makeApiCall" />
        </div>
      </div>
      <omnichannel-overview-ecommerce-in-store v-if="$experimental" />
      <omnichannel-overview-sankey v-if="$experimental" />
    </section>
  </section>
</template>

<script>
/* eslint-disable no-underscore-dangle */
// libs
import bus from '@/helpers/events/bus';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
// components
import OmnichannelOverviewDiagramaVenn from '@/components/overview/OmnichannelOverviewDiagramaVenn';
import OmnichannelOverviewTable from '@/components/overview/OmnichannelOverviewTable';
import OmnichannelOverviewEcommerceInStore from '@/components/overview/OmnichannelOverviewEcommerceInStore';
import OmnichannelOverviewSankey from '@/components/overview/OmnichannelOverviewSankey';

export default {
  name: 'Omnichanneloverview',
  components: {
    OmnichannelOverviewDiagramaVenn,
    OmnichannelOverviewTable,
    OmnichannelOverviewEcommerceInStore,
    OmnichannelOverviewSankey,
  },
  data() {
    return {
      id: 'omnichanneloverview',
      collapsed: true,
    };
  },
  mounted() {
    useMixpanelConfig('view_analytics_o2o');
    bus.$on('keep-last-collapsed-open', id => {
      if (id !== this.id) this.collapsed = false;
    });
  },
  beforeUnmount() {
    bus.$off('keep-last-collapsed-open');
  },
  methods: {
    getCollapsed(id) {
      this.collapsed = !this.collapsed;
      if (this.collapsed) bus.$emit('is-active-collapse', id);
    },
  },
};
</script>

<style lang="scss">
.omnichanneloverview-wrapper {
  .omni__collapsed {
    background: $color-white;
    margin-bottom: 10px;
    border-radius: 8px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;

    .omni__collapsed-border {
      margin-left: 25px;
      font-weight: 500;

      &.--gray {
        color: $gray-700;
      }

      &.--pink {
        color: $pink-500;
      }
    }

    .icon {
      color: $pink-500;
      margin-right: 45px;
      font-weight: bold;
    }
  }

  .omni__collapsed::after {
    content: '';
    background: $pink-500;
    height: 100%;
    width: 5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    left: 0;
  }

  #omnichanneloverview {
    .venn-table {
      padding: 50px 35px 40px;
    }
  }

  @media (min-width: $tablet-max-width) {
    .mh-omni .card {
      min-height: 465px;
    }
  }
}
</style>
