<template>
  <lightbox-template
    id="lightbox-campaign-datails"
    icon="mail"
    :title="settingsData.campaign ? settingsData.campaign : '-'"
    :show-segment-group="false"
  >
    <div class="container mb-4">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">
          {{ this.$t('dashboard.campaign-group.campaign-perf') }}
        </h5>
      </header>
    </div>
    <div v-if="!loading">
      <dashboard-campaign-metrics :settings="settingsData" />
      <dashboard-campaign-rev-and-eng :settings="settingsData" />
      <dashboard-campaign-main-metrics :settings="settingsData" />
      <dashboard-campaign-table :settings="settingsData" />
    </div>
    <base-loader-spinner v-else />
  </lightbox-template>
</template>

<script>
import lightboxesMixin from '@/helpers/mixins/lightboxesMixin';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import BaseLoaderSpinner from '@/components/_atoms/BaseLoaderSpinner';
import DashboardCampaignMetrics from '@/components/dashboard/metricsTable/DashboardCampaignMetrics';
import DashboardCampaignMainMetrics from '@/components/dashboard/metricsTable/DashboardCampaignMainMetrics';
import DashboardCampaignRevAndEng from '@/components/dashboard/metricsTable/DashboardCampaignRevAndEng';
import DashboardCampaignTable from '@/components/dashboard/metricsTable/DashboardCampaignTable';

export default {
  name: 'LightboxCampaignDetails',
  components: {
    LightboxTemplate,
    BaseLoaderSpinner,
    DashboardCampaignMetrics,
    DashboardCampaignMainMetrics,
    DashboardCampaignRevAndEng,
    DashboardCampaignTable,
  },
  mixins: [lightboxesMixin],
  props: {
    settingsData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
