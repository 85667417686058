<template>
  <base-card-v2 id="customer-channels" :default-class="false">
    <base-card-header :title="$t('360.person.identified-channels.title')" icon="icon-media" />
    <customer-view-channels class="channels-profile" :prop-data="personalData.identifiedChannels" />
    <ul class="customer-data">
      <li>
        <i class="icon icon-cart"></i>
        <span v-if="personalData.lastPurchase">
          {{ $t('360.last-purchase') }} {{ $t('360.in') }} <b>{{ lastPurchase }}</b> -
          <a href="#" @click="openLastOrder">
            {{ $t('360.see-details') }}
          </a>
        </span>
        <span v-else class="empty">{{ $t('360.person.empty.order') }}</span>
      </li>
      <li class="row justify-content-between">
        <div class="col-3 d-flex align-items-center">
          <i class="icon icon-ecommerce"></i>
          <span>{{ $t('360.ecommerce') }}</span>
        </div>
        <div v-if="personalData.ordersOn && personalData.ordersOn.orders" class="col-4">
          <minibar-chart class="customersView" :prop-data="jsonOn" />
        </div>
        <div class="col-5 text-right">
          <span v-if="personalData.ordersOn && personalData.ordersOn.orders">
            <b class="color-oto-ecommerce pr-2">{{ formatCurrency(personalData.ordersOn.revenue) }}</b>
            <span>
              {{ $t('360.in') }}
              {{ $_formatDecimal(personalData.ordersOn.orders) }}
              {{ $tc('360.purchase', personalData.ordersOn.orders) }}
            </span>
          </span>
          <span v-else class="empty">{{ $t('360.person.empty.data') }}</span>
        </div>
      </li>
      <li class="row justify-content-between align-items-center">
        <div class="col-3 d-flex align-items-center">
          <i class="icon icon-in-store"></i>
          <span>{{ $tc('360.store', 1) }}</span>
        </div>
        <div v-if="personalData.ordersOff && personalData.ordersOff.orders" class="col-4">
          <minibar-chart class="customersView" :prop-data="jsonOff" />
        </div>
        <div class="col-5 d-flex justify-content-end">
          <span v-if="personalData.ordersOff && personalData.ordersOff.orders">
            <b class="color-purple-200 pr-2">{{ formatCurrency(personalData.ordersOff.revenue) }}</b>
            <span>
              {{ $t('360.in') }}
              {{ $_formatDecimal(personalData.ordersOff.orders) }}
              {{ $tc('360.purchase', personalData.ordersOff.orders) }}
            </span>
          </span>
          <span v-else class="empty">{{ $t('360.person.empty.data') }}</span>
        </div>
      </li>
    </ul>
  </base-card-v2>
</template>
<script>
import moment from 'moment';
import { numberMixin } from '@/helpers/mixins';
import CustomerViewChannels from '@/components/oto/customerview/components/CustomerViewChannels';
import MinibarChart from '@/components/MiniBarChart';
import { openLightbox } from '@/helpers/lightbox';
import { dateCompleteVariation } from '@/utilities/constants';
import { formatPercent } from '@/utilities/formatters';

export default {
  name: 'CustomerViewCustomerChannels',
  components: {
    CustomerViewChannels,
    MinibarChart,
  },
  mixins: [numberMixin],
  props: {
    personalData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    lastPurchase() {
      return moment(this.personalData.lastPurchase).format(dateCompleteVariation);
    },
    jsonOff() {
      return {
        title: '',
        suffix: '',
        prefix: '',
        data: [
          {
            text: '',
            type: '',
            amount: formatPercent(this.personalData.ordersOff.percent / 100),
            percentageamount: parseFloat(this.personalData.ordersOff.percent).toFixed(0) || 0,
            typeclient: 'off',
            colorbase: '#4084CB',
          },
        ],
      };
    },
    jsonOn() {
      return {
        title: '',
        suffix: '',
        prefix: '',
        data: [
          {
            text: '',
            type: '',
            amount: formatPercent(this.personalData.ordersOn.percent / 100),
            percentageamount: parseFloat(this.personalData.ordersOn.percent).toFixed(0) || 0,
            typeclient: 'on',
            colorbase: '#A167D9',
          },
        ],
      };
    },
  },
  methods: {
    openLastOrder() {
      openLightbox('lightbox-last-order', { customerId: this.personalData.id });
    },
  },
};
</script>
<style lang="scss" scoped>
.card .card-title {
  margin-bottom: 30px;
}
.col-4 {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
#customer-channels {
  #minibarchart {
    width: 100%;
  }
  .divText {
    width: auto;
  }
  .chart-title {
    display: none;
  }
  .lineBar {
    width: 100%;
    height: 16px;
  }
}
</style>
