import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const controller = '360/sellers';
const controllerSeller = '360/seller';

export const getSellers = (limit = 10, page = 1, args) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    validateStatus: () => true,
    url: `seller/getTable`,
    baseURL: endpointVersion('v3'),
    params: {
      limit,
      page,
      brand: brand.id,
      ...args,
    },
  }).then(data => data?.data);
};

export const getSellersWarning = (limit = 10, page = 1, args) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    url: `seller/getTable`,
    baseURL: endpointVersion('v3'),
    params: {
      limit,
      page,
      sellersWarning: 1,
      brand: brand.id,
      ...args,
    },
  }).then(data => data?.data);
};

export const getExportSellersWarning = (fileType, responseType, args) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    url: `seller/getTable`,
    baseURL: endpointVersion('v3'),
    params: {
      sellersWarning: 1,
      brand: brand.id,
      export: fileType,
      ...args,
    },
    responseType,
  }).then(data => data?.data);
};

/**
 * @see https://labs.pmweb.com.br/docs/360/#api-Sellers-GetSeller
 * @param {Number} id Seller's unique ID.
 */
export const getSeller = id => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    url: `seller/getSeller`,
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      id,
    },
  }).then(data => data?.data);
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Seller-activeSellers
 */
export const getActiveSellers = (storeId, term = '') => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/activeSellers`,
    params: {
      brand: brand.id,
      term,
      storeId,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Seller-alternateSeller
 */
export const setAlternateSeller = (sellerId, alternate) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/alternateSeller`,
    params: {
      brand: brand.id,
      sellerId,
      alternate,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Seller-generatePin
 */
export const generatePin = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/generatePin`,
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/360/#api-Sellers-PutSeller
 */
export const putSeller = args => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    headers: { Authorization: `Bearer ${tokenV3}` },
    validateStatus: () => true,
    baseURL: endpointVersion('v3'),
    url: '/360/seller/putSeller',
    params: {
      brand: brand.id,
      ...args,
    },
  });
};

export const getSellerMetrics = sellerId => {
  const brand = store.getters.selectedBrand;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/sellerMetrics`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      sellerId,
    },
  });
};

export const getConversionFunnel = sellerId => {
  const brand = store.getters.selectedBrand;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/conversionFunnel`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      sellerId,
    },
  });
};

export const getSellerSuggestionsTable = (sellerId, exportData = null, limit = 4) => {
  const brand = store.getters.selectedBrand;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/suggestionsTable`,
    ...(exportData !== null && { responseType: 'blob' }),
    params: {
      brand: brand.id,
      startDate,
      endDate,
      sellerId,
      ...(exportData === null && { limit }),
      ...(exportData !== null && { export: exportData }),
      orderBy: '-revenue',
    },
  });
};

export const getParallelCoordinates = (sellerId, stores, metrics = null) => {
  const brand = store.getters.selectedBrand;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/parallelCoordinates`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      sellerId,
      stores,
      metrics,
    },
  });
};

export const getAvailableMetrics = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerSeller}/getAvailableMetrics`,
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/360/#api-Sellers-listAvailableEmails
 */
export const getAvailableEmails = (email = '') => {
  const tokenV3 = store.state.auth.tokenV3;
  const brand = store.getters.selectedBrand;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `seller/listAvailableEmails`,
    params: {
      brand: brand.id,
      email,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/360/#getSellersResume
 */
export const getSellersResume = ({ enabled = 1, storeId, name, userId, limit, page, orderBy, term = '' } = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `seller/getSellersResume`,
    params: {
      brand: brand.id,
      enabled,
      storeId,
      name,
      userId,
      limit,
      page,
      orderBy,
      term,
    },
  });
};

/**
 *
 * @description return the response of a register validation
 * @see https://labs.pmweb.com.br/docs/context/v3/seller/verifyEnrolment
 */
export const getVerifyEnrolment = ({ enrolment }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('seller/verifyEnrolment', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      enrolment,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
