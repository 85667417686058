<template>
  <div class="audience-historic-skeleton">
    <skeleton-loader width="267px" height="20px" class="header" />

    <table-list id="audience-list-skeleton" :data="table" :type="type" :loading="true">
      <table-list-header>
        <table-list-column class-name="text-center col-1">
          <skeleton-loader width="63px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-center col-2">
          <skeleton-loader width="74px" height="14px" />
        </table-list-column>
        <table-list-column class-name="col-3">
          <skeleton-loader width="115px" height="14px" />
        </table-list-column>
        <table-list-column class-name="col-2">
          <skeleton-loader width="72px" height="14px" />
        </table-list-column>
        <table-list-column class-name="col-2">
          <skeleton-loader width="123px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col-2"> </table-list-column>
      </table-list-header>
      <table-list-body>
        <table-list-row v-for="row in rows" :key="row">
          <table-list-column class-name="text-center col-1 pl-0 pr-0">
            <skeleton-loader width="156px" height="14px" />
          </table-list-column>
          <table-list-column class-name="text-center col-2">
            <skeleton-loader width="38px" height="14px" />
          </table-list-column>
          <table-list-column class-name="col-3">
            <skeleton-loader width="160px" height="14px" />
          </table-list-column>
          <table-list-column class-name="col-2">
            <skeleton-loader width="82px" height="14px" />
          </table-list-column>
          <table-list-column class-name="col-2">
            <skeleton-loader width="82px" height="14px" />
          </table-list-column>
          <table-list-column class-name="text-right col-2">
            <skeleton-loader width="23px" height="14px" />
          </table-list-column>
        </table-list-row>
      </table-list-body>
    </table-list>
  </div>
</template>
<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    table: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    rows() {
      const length = this.table.limit;
      const arr = new Array(length);

      for (let i = 0; i < length; i += 1) {
        arr[i] = (i + 1).toString();
      }
      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  margin: 40px 0 25px;
}
</style>
