<template>
  <div class="omnichannel-resume row">
    <div class="col-6">
      <span class="label">{{ $tc('stores-total-revenue-title') }}</span>
      <div class="value">
        <span class="color-oto-brand-omni revenue">{{ formatCurrency(data.revenue.amount) }}</span>
      </div>
    </div>
    <div class="col-6">
      <span class="label">{{ $tc('digital-influenced') }}</span>
      <div class="value">
        <span class="color-oto-ecommerce revenue">{{ formatCurrency(data.revenueTrackedOff.amount) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OmnichannelOverviewResume',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.omnichannel-resume {
  padding: 0 15px 42px;
}
.revenue {
  font-weight: 600;
  font-size: 16px;
}
.percent {
  color: $gray-700;
  font-size: 12px;
  padding-left: 10px;
}
</style>
