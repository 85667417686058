import { cloneDeep, escapeRegExp } from 'lodash';
import { stringParser } from '@/helpers/string-parser';
// endpoints
import { putNotification, updateNotification, getNotification } from '@/services/oto/push';

import bus from '@/helpers/events/bus';

const defaultState = {
  pushId: null,
  audienceId: null,
  pushList: [],
  mode: null,
  publicType: 'existing-public',
  title: '',
  content: '',
  isSubmitBtnActive: false,
  scheduleDate: '',
  loadingPreview: false,
  url: '',
  selectedLink: { id: 'screen', name: '360.push.preview.screen' },
  linkOptions: [
    { id: 'screen', name: '360.push.preview.screen' },
    { id: 'url', name: '360.push.preview.url' },
  ],
  selectedPage: 'notifications',
  pageList: [
    { id: 'campaigns', name: 'header.sidebar.360-campaigns' },
    { id: 'home', name: 'breadcrumb.home' },
    { id: 'notifications', name: 'header.sidebar.360-notifications' },
    { id: 'sellers', name: 'header.sidebar.360-sellers' },
  ],
  field: '',
};

const pushNotifications = {
  namespaced: true,
  state: cloneDeep(defaultState),
  mutations: {
    SET_PUSHID(state, payload) {
      state.pushId = payload;
    },
    SET_PUSHLIST(state, payload) {
      state.pushList = payload;
    },
    SET_PUSH_AUDIENCEID(state, payload) {
      state.audienceId = payload;
    },
    SET_MODE(state, payload) {
      state.mode = payload;
    },
    CHANGE_PUBLIC(state, payload) {
      state.publicType = payload;
    },
    SET_PREVIEW_SUBJECT(state, payload) {
      state.title = payload;
    },
    SET_PREVIEW_TEXT(state, payload) {
      state.content = payload;
    },
    SET_PAGE(state, payload) {
      state.selectedPage = payload;
    },
    SET_LINK(state, payload) {
      state.selectedLink = payload;
    },
    SET_SCHED_DATE(state, payload) {
      state.scheduleDate = payload;
    },
    SET_URL(state, payload) {
      state.url = payload;
    },
    SET_FIELD(state, payload) {
      state.field = payload;
    },
    SET_SUBMIT_STATUS(state, payload) {
      state.isSubmitBtnActive = payload;
    },
    SET_LOADING(state, payload) {
      state.loadingPreview = payload;
    },
    SET_RESETDATA(state) {
      Object.assign(state, defaultState);
    },
    SET_CAMPAIGN_DETAIL(state, campaignDetail) {
      const { id, title, content, schedule, audience, field } = campaignDetail;
      state.pushId = id;
      state.audienceId = audience?.id;
      state.title = title;
      state.content = content;
      state.scheduleDate = schedule;
      state.field = field;
    },
  },
  actions: {
    setPushId({ commit }, payload) {
      commit('SET_PUSHID', payload);
    },
    setPushList({ commit }, payload) {
      commit('SET_PUSHLIST', payload);
    },
    setPushAudienceId({ commit }, payload) {
      commit('SET_PUSH_AUDIENCEID', payload);
    },
    setMode({ commit }, payload) {
      commit('SET_MODE', payload);
    },
    updatePublic({ commit }, payload) {
      commit('CHANGE_PUBLIC', payload);
    },
    updatePreviewSubject({ commit }, payload) {
      commit('SET_PREVIEW_SUBJECT', payload);
    },
    updatePreviewText({ commit }, payload) {
      commit('SET_PREVIEW_TEXT', payload);
    },
    updateScheduleDate({ commit }, payload) {
      commit('SET_SCHED_DATE', payload);
    },
    updateLink({ commit }, payload) {
      commit('SET_LINK', payload);
    },
    updatePage({ commit }, payload) {
      commit('SET_PAGE', payload);
    },
    updateSubmitStatus({ commit }, payload) {
      commit('SET_SUBMIT_STATUS', payload);
    },
    updateUrl({ commit }, payload) {
      commit('SET_URL', payload);
    },
    updateLoadingPreview({ commit }, payload) {
      commit('SET_LOADING', payload);
    },
    submitPush({ commit, dispatch, state }) {
      commit('SET_LOADING', true);

      if (state.mode === 'create') dispatch('actionPostCampaign');
      else dispatch('actionUpdateCampaign');
    },
    actionPostCampaign({ commit, state }) {
      if (state.selectedLink.id === 'screen') state.field = { type: 'screen', fields: { name: state.selectedPage } };
      else if (state.selectedLink.id === 'url') state.field = { type: 'url', value: state.url };

      putNotification({
        title: state.title,
        audience: state.audienceId,
        notification: state.content,
        schedule: state.scheduleDate,
        field: state.field,
      })
        .then(({ data }) => {
          bus.$emit('open-push-dialog-success-modal', {
            redirectId: data[0]?.id,
            redirectRouteName: '360-push-detail',
          });
        })
        .then(() => {
          commit('SET_RESETDATA');
        })
        .catch(() => {
          bus.$emit('open-push-dialog-error-modal');
          commit('SET_RESETDATA');
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    actionUpdateCampaign({ commit, state }) {
      if (state.selectedLink.id === 'screen') state.field = { type: 'screen', fields: { name: state.selectedPage } };
      else if (state.selectedLink.id === 'url') state.field = { type: 'url', value: state.url };

      updateNotification({
        notificationId: state.pushId,
        title: state.title,
        audience: state.audienceId,
        notification: state.content,
        schedule: state.scheduleDate,
        field: state.field,
      })
        .then(() => {
          bus.$emit('open-push-dialog-success-modal', {
            redirectId: state.pushId,
            redirectRouteName: '360-push-detail',
          });
        })
        .catch(() => {
          bus.$emit('open-push-dialog-error-modal');
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    actionResetData({ commit }) {
      commit('SET_RESETDATA');
    },
    actionGetNotification({ commit }, id) {
      if (!id) return;

      getNotification(id)
        .then(({ data }) => {
          commit('SET_CAMPAIGN_DETAIL', data[0]);
        })
        .catch(() => {
          // TODO trigger error
        });
    },
  },
  getters: {
    getMode: state => state.mode,
    getPublicType: state => state.publicType,
    getPreviewSubject: state => state.title,
    getPreviewText: state => state.content,
    getScheduleDate: state => state.scheduleDate,
    getPage: state => state.selectedPage,
    getLink: state => state.selectedLink,
    getUrl: state => state.url,
    getField: state => state.field,
    getFilteredPushList:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.pushList.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.title)));
      },
    getSubmitStatus: state => state.isSubmitBtnActive,
    getLoadingStatus: state => state.loadingPreview,
  },
};

export default pushNotifications;
