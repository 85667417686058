<template>
  <path
    d="M2.38298 2.1844V0.695035C2.38298 0.297872 2.68085 0 3.07801 0H7.04965C7.44681 0 7.74468 0.297872 7.74468
    0.695035V2.08511H6.75177V0.992908H3.37589V2.1844H2.38298ZM10.227 3.67376C10.227 3.37589 10.0284 3.1773 9.7305
    3.1773H0.496454C0.198582 3.1773 0 3.37589 0 3.67376C0 3.97163 0.198582 4.17021 0.496454 4.17021H9.7305C10.0284
    4.17021 10.227 3.97163 10.227 3.67376ZM9.82979 5.16312L9.03546 14H1.19149L0.397163 5.16312H1.39007L1.98582
    11.617L2.08511 13.0071H8.14184L8.73759 5.75887V5.26241H9.82979V5.16312ZM3.2766 11.617L3.07801 5.16312H4.07092L4.17021
    11.617H3.2766ZM5.95745 11.617L6.05674 5.16312H7.04965L6.95035 11.617H5.95745Z"
  />
</template>
