<template>
  <div>
    <div v-if="!empty" id="seller-select">
      <label>{{ $t('nps.seller') }}</label>
      <v-select
        v-model="seller"
        label="fullName"
        :clearable="true"
        class="w-100"
        :options="getSellers"
        :filter-by="sellerFilter"
        :placeholder="$t('nps.select')"
        @search="searchTerm"
        @option:selected="changeSeller(seller)"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #option="{ fullName, enrolment }">
          <span v-if="fullName">{{ fullName }} - {{ enrolment }}</span>
        </template>
        <template #selected-option="{ fullName, enrolment }">
          <span v-if="fullName">{{ fullName }} - {{ enrolment }}</span>
        </template>
        <template #no-options>
          {{ $t('nps.select-empty') }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
// components
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      empty: true,
      seller: [],
      isFirstSearch: true,
    };
  },
  computed: {
    ...mapGetters('npsAnalytics', ['getSellers']),
  },
  watch: {
    getSellers() {
      this.$nextTick(() => {
        // at the first load - set input details (name - enrolment)
        if (!this.getSellers) return;
        this.empty = false;
        // this.term = this.getSellers[0];
      });
    },
    seller() {
      if (!this.seller) {
        const id = null;
        this.setSellerSelected(id);
        bus.$emit('render-cards');
      }
    },
  },
  created() {
    this.setSellersList();
  },
  methods: {
    ...mapActions('npsAnalytics', ['setSellersList', 'setSellerSelected', 'setSellersByTerm']),
    /**
     * @description search by term
     */
    searchTerm(term) {
      if (!term) return;
      this.triggerDebounce(term);
    },
    /**
     * @description set term on seller store
     */
    triggerDebounce: debounce(function (term) {
      this.setSellersByTerm({ term });
    }, 500),
    /**
     * @description change seller after and bofore select update
     */
    changeSeller(sellerId) {
      const id = sellerId?.id ? sellerId.id : null;
      this.setSellerSelected(id);
      bus.$emit('render-cards');
    },
    /**
     * @description filter options with two labels
     */
    sellerFilter(option, fullName, search) {
      const temp = search.toLowerCase();
      return fullName.toLowerCase()?.includes(temp) || option?.enrolment?.includes(temp);
    },
  },
};
</script>

<style lang="scss">
#seller-select {
  @import '@/assets/scss/vendors/_v-select';

  .v-select {
    .vs__selected-options {
      max-width: 80%;
    }
    .vs__clear {
      margin-right: 0;
    }
    .vs__dropdown-option {
      font-size: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
</style>
