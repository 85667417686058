import { escapeRegExp, pick, isNil, isEmpty, cloneDeep, has, set, each, isArray } from 'lodash';
import { stringParser } from '@/helpers/string-parser.js';
import { getLabels, getLabel, checkAccount360, getColumnsChained, getLabelCount } from '@/services/labels/index.js';
import { getDataFiltered } from '@/helpers/filter-audiences-and-labels';

const defaultLabelDetail = {
  name: '',
  description: '',
  label360: false,
  count: 0,
  query: {
    include: [],
    exclude: [],
  },
};

const defaultState = {
  list: [],
  totalLabels: 0,
  requestsCounter: 0,
  labelDetailMode: '',
  brandHas360: null,
  labelDetail: cloneDeep(defaultLabelDetail),
  labelDetailReadOnly: cloneDeep(defaultLabelDetail),
  labelDetailUnsaved: [],
  serieFill: {},
  loadingGlobal: true,
  hasErrorGlobal: false,
  isEmptyGlobal: false,
};

const labels = {
  namespaced: true,
  state: cloneDeep(defaultState),
  mutations: {
    SET_LABELS_LIST(state, labels) {
      state.list = labels;
      if (isEmpty(labels)) {
        state.isEmptyGlobal = true;
        state.loadingGlobal = false;
      } else state.isEmptyGlobal = false;
    },
    SET_LOADING(state, isLoading) {
      state.loadingGlobal = isLoading;
    },
    SET_ERROR(state, hasError) {
      state.hasErrorGlobal = hasError;
      state.loadingGlobal = false;
    },
    SET_LABELS_COUNT(state, count) {
      state.totalLabels = count;
    },
    SET_LABELDETAIL(state, label) {
      const labelInfo = cloneDeep(label);
      if (state.labelDetailMode === 'create' && !isNil(label.labelId)) {
        // Run in DupicateMode,
        // - remove name
        // - check if has 'query', 'include' and 'exclude' properties and creates if doesn't exist
        labelInfo['name'] = '';
        isArray(labelInfo.query) && (labelInfo.query = {});
        each(['include', 'exclude'], queryItem => {
          !has(labelInfo, `query.${queryItem}`) && set(labelInfo, `query.${queryItem}`, []);
        });
      }
      state.labelDetail = Object.assign({}, labelInfo);
      state.labelDetailReadOnly = Object.assign({}, labelInfo);
    },
    RESET_LABELDETAIL(state) {
      state.labelDetail = cloneDeep(defaultLabelDetail);
      state.labelDetailReadOnly = cloneDeep(defaultLabelDetail);
      state.labelDetailUnsaved = [];
    },
    SET_MODE(state, mode) {
      state.labelDetailMode = mode;
    },
    SET_BRAND_HAS_360(state, brandHas360) {
      state.brandHas360 = brandHas360;
    },
    RESET_BRAND_HAS_360(state) {
      state.brandHas360 = null;
    },
    SET_NAME(state, newName) {
      state.labelDetail.name = newName;
      if (!state.labelDetailUnsaved.includes('name')) {
        state.labelDetailUnsaved.push('name');
      }
    },
    SET_DESCRIPTION(state, newDescription) {
      state.labelDetail.description = newDescription;
      if (!state.labelDetailUnsaved.includes('description')) {
        state.labelDetailUnsaved.push('description');
      }
    },
    SET_LABEL360(state) {
      state.labelDetail.label360 = !state.labelDetail.label360;
      if (!state.labelDetailUnsaved.includes('label360')) {
        state.labelDetailUnsaved.push('label360');
      }
    },
    SET_SERIEFILL(state, columns) {
      state.serieFill = Object.assign({}, columns);
    },
    /**
     * Add a group
     * @param {Boolean} include Set type of condition: true for include or false for exclude
     */
    ADD_GROUP(state, include) {
      if (include) {
        state.labelDetail.query.include.push([]);
      } else {
        state.labelDetail.query.exclude.push([]);
      }
    },
    /**
     * Remove a group
     * @param {Object} data Object with condition type (include or exclude) and group index
     */
    REMOVE_GROUP(state, { include = true, group }) {
      if (include) {
        if (state.labelDetail.query.include.length > 1) {
          state.labelDetail.query.include.splice(group, 1);
        }
      } else {
        state.labelDetail.query.exclude.splice(group, 1);
      }
    },
    /**
     * Add a serie to a group
     * @param {Object} data Object with serie data, condition type (boolean: include - true - or exclude - false) and group index
     */
    ADD_SERIE(state, { data, include = true, group = 0 }) {
      const condition = { ...data };
      if (include) state.labelDetail.query.include[group].push(condition);
      else state.labelDetail.query.exclude[group].push(condition);
    },
    /**
     * Remove a serie
     * @param {Object} data Object with condition type (include or exclude), group index and serie index
     */
    REMOVE_SERIE(state, { include = true, group, serie }) {
      if (include) {
        state.labelDetail.query.include[group].splice(serie, 1);
      } else {
        state.labelDetail.query.exclude[group].splice(serie, 1);
      }
    },
    /**
     * Update serie data
     * @param {*} state
     * @param {Object} data Object with serie index, group index, condition type (boolean: include - true - or exclude - false) and serie data
     */
    UPDATE_SERIE(state, { serie, group, include, data }) {
      const condition = { ...data };

      if (include) state.labelDetail.query.include[group][serie] = condition;
      else state.labelDetail.query.exclude[group][serie] = condition;
    },
    UPDATE_COUNT(state, getters) {
      const { labelId } = state.labelDetail;
      const isNilId = isNil(labelId);
      const isDuplicateMode = !isNilId && state.labelDetailMode === 'create';
      // send ID to labelCount if isn't null
      const params = {
        ...(isNilId || isDuplicateMode ? { query: JSON.stringify(getters.query) } : labelId),
      };
      state.requestsCounter += 1;

      getLabelCount(params).then(({ data }) => {
        state.labelDetail.count = Number(data?.data?.count);
        state.requestsCounter -= 1;
      });
    },
  },
  actions: {
    getLabelsList({ commit }) {
      commit('SET_LOADING', true);
      getLabels()
        .then(({ data }) => {
          commit('SET_LABELS_LIST', data.data);
          commit('SET_LABELS_COUNT', data.totalCount);
        })
        .catch(() => {
          commit('SET_LABELS_LIST', defaultState.list);
          commit('SET_LABELS_COUNT', defaultState.totalLabels);
          commit('SET_ERROR', true);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    getLabel({ state, dispatch, commit }, id) {
      commit('RESET_LABELDETAIL');
      if (id !== 0) {
        getLabel(id).then(({ data }) => {
          commit('SET_LABELDETAIL', data.data);
          if (state.labelDetailMode === 'create') {
            dispatch('updateCount');
          }
        });
      }
    },
    setMode({ commit }, mode) {
      commit('SET_MODE', mode);
    },
    /**
     * @description add/change label name
     */
    actionSetName({ commit }, newName) {
      commit('SET_NAME', newName);
    },
    /**
     * @description add/change label description
     */
    actionSetDescription({ commit }, newDescription) {
      commit('SET_DESCRIPTION', newDescription);
    },
    /**
     * @description enable/disable 360 feature
     */
    actionSetLabel360({ commit }) {
      commit('SET_LABEL360');
    },
    actionResetCheckAccount360({ commit }) {
      commit('RESET_BRAND_HAS_360');
    },
    actionCheckAccount360({ state, commit }) {
      if (state.brandHas360 !== null) return;
      checkAccount360().then(({ data }) => {
        commit('SET_BRAND_HAS_360', data.data['360']);
      });
    },
    actionAddSerieFill({ commit }, type) {
      getColumnsChained().then(data => {
        let result;
        if (type === 'create') {
          result = getDataFiltered(data?.data?.data);
        } else {
          result = data?.data?.data;
        }

        commit('SET_SERIEFILL', result);
      });
    },
    addGroup({ commit }, { include = true } = {}) {
      commit('ADD_GROUP', include);
    },
    addSerie({ commit, dispatch }, data) {
      commit('ADD_SERIE', data);
      dispatch('updateCount');
    },
    removeGroup({ commit, dispatch }, data) {
      commit('REMOVE_GROUP', data);
      dispatch('updateCount');
    },
    removeSerie({ commit, dispatch }, data) {
      commit('REMOVE_SERIE', data);
      dispatch('updateCount');
    },
    updateSerie({ commit, dispatch }, data) {
      commit('UPDATE_SERIE', data);
      dispatch('updateCount');
    },
    updateCount({ commit, getters }) {
      commit('UPDATE_COUNT', getters);
    },
  },
  getters: {
    getLoading: state => state.loadingGlobal,
    getIsEmpty: state => state.isEmptyGlobal,
    getHasError: state => state.hasErrorGlobal,
    getFilteredLabelsList:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.list.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    areRulesEmpty: state => {
      const { include, exclude } = state.labelDetail.query;
      return !include?.length && !exclude?.length;
    },
    getLabelDetailUnsaved: state => {
      return pick(state.labelDetail, state.labelDetailUnsaved);
    },
    getLabelDetailUnsavedCompose: (state, getters) => {
      const { labelId } = state.labelDetail;
      const objectReady = getters.getLabelDetailUnsaved;
      Object.assign(objectReady, { labelId });
      return objectReady;
    },
    formateColumns:
      (state, getters) =>
      (keys, fill, isLastLevel = false) => {
        const options = [];

        keys.forEach(key => {
          const chaves = Object.keys(fill[key]);
          const values = isLastLevel ? fill[key] : getters.formateColumns(chaves, fill[key], true);
          options.push({
            key,
            ...(isLastLevel ? values : { columns: values }),
          });
        });

        return options;
      },
    columnOptions: (state, getters) => {
      const { serieFill } = state;
      const keys = Object.keys(serieFill);

      return getters.formateColumns(keys, serieFill);
    },
    /**
     * @description create an object with valid include/exclude arrays from labelDetail.query
     */
    query: state => {
      const obj = {};
      const { include, exclude } = state.labelDetail.query;
      if (!isEmpty(include) && !isEmpty(include[0])) Object.assign(obj, { include });
      if (!isEmpty(exclude) && !isEmpty(exclude[0])) Object.assign(obj, { exclude });
      return obj;
    },
    /**
     * @description check if duplicate mode in label details page
     */
    duplicateModeEnabled: state => {
      return state.labelDetailMode === 'create' && !isNil(state.labelDetail.labelId);
    },
  },
};

export default labels;
