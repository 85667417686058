<template>
  <div class="expansion-wall">
    <div class="wall"></div>
    <div class="wrapper default">
      <span class="label">
        {{ $t('audiences.expansions.modal-text') }}
      </span>
      <button class="btn" :disabled="!countPeopleLookALike" @click="updateLookALikeStatus">
        {{ $t('audiences.expansions.modal-button') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'AudienceExpansionWall',
  computed: {
    ...mapState('audience', [
      'isLookALikeActive', // -> this.isLookALikeActive
    ]),
    ...mapGetters('audience', [
      'countPeopleLookALike', // -> this.countPeopleLookALike
    ]),
  },
  methods: {
    ...mapActions('audience', [
      'updateLookALikeStatus', // -> this.updateLookALikeStatus()
    ]),
  },
};
</script>

<style lang="scss" scoped>
.expansion-wall {
  height: 215px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 1;
  .wall {
    background: $color-white;
    border-radius: 16px;
    min-height: 100%;
    opacity: 0.8;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;

    .label {
      color: $gray-700;
      font-size: 12px;
      font-weight: normal;
      line-height: 22px;
      margin: 20px 0;
      text-align: center;
      width: 420px;
    }
    .btn {
      width: 175px;
      padding: 0 36px;

      &:disabled {
        background: $gray-500;
      }
    }
  }
}
</style>
