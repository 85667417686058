<template>
  <div class="audience-step">
    <!-- STEP 1: composition -->
    <div v-if="selectedStep === 1" class="audience-composition">
      <div v-if="compositionLoading">
        <skeleton-composition />
      </div>
      <div v-else>
        <audience-include :is-include="true" />
        <audience-exclude :is-include="false" />

        <div v-if="hasEmptyEntries" class="empty-query col-md-6">
          <p>{{ $t('audiences.empty-audience.empty-query') }}</p>
          <button class="btn mx-auto mt-4" data-cy="audience-empty-button" @click="changeStep(2)">
            {{ $t('audiences.empty-audience.see-integrations') }}
          </button>
        </div>
      </div>
    </div>

    <!-- STEP 2: integrations - connect -->
    <div v-if="selectedStep === 2" class="audience-integrations">
      <integrations-list />
    </div>

    <!-- STEP 3: control groups - not enabled -->
    <div v-if="showControlGroups" class="audience-control-groups">
      {{ $t('feature-not-enabled') }}
    </div>

    <!-- STEP 3: integrations historic - if view mode -->
    <div v-if="showHistoric" class="audience-historic">
      <audience-historic />
    </div>
  </div>
</template>
<script>
import AudienceIncludeExclude from '@/components/audiences/create/composition/AudienceIncludeExclude';
import AudienceIncludeExcludeSkeleton from '@/components/audiences/create/composition/AudienceIncludeExcludeSkeleton';
import IntegrationsList from '@/components/audiences/create/integration/IntegrationsList';
import AudienceHistoric from '@/components/audiences/create/historic/AudienceHistoric';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  components: {
    'audience-include': AudienceIncludeExclude,
    'audience-exclude': AudienceIncludeExclude,
    'skeleton-composition': AudienceIncludeExcludeSkeleton,
    'integrations-list': IntegrationsList,
    'audience-historic': AudienceHistoric,
  },
  computed: {
    ...mapGetters('audience', [
      'isView', // -> this.isView
      'compositionLoading', // -> this.compositionLoading
      'hasEmptyEntries',
    ]),
    ...mapState('audience', [
      'selectedStep', // -> this.selectedStep
    ]),
    showHistoric() {
      return this.isView && this.selectedStep === 3;
    },
    showControlGroups() {
      return !this.isView && this.selectedStep === 3;
    },
    getRouteStep() {
      return this.$route.query?.step;
    },
  },
  methods: {
    ...mapActions('audience', ['changeStep', 'redirectToIntegrations']),
  },
  mounted() {
    if (this.getRouteStep === 'integrations') {
      this.redirectToIntegrations(true);
    }
  },
  unmounted() {
    this.redirectToIntegrations(false);
  },
};
</script>
<style lang="scss">
.audience-include-exclude {
  margin-bottom: 40px;
}
.empty-query {
  text-align: center;
  padding-top: 50px;
  margin: 0 auto;
  .btn {
    padding: 0 40px;
  }
}
</style>
