<template>
  <div id="login-form">
    <login-form-logo />
    <div>
      <div class="email-change">
        <span class="description text-center email">{{ showEmail }}</span>
        <span class="description text-center change" @click.prevent="loaderBackToEmail">{{ $t('login.change') }}</span>
      </div>
    </div>
    <div class="card-body">
      <form novalidate @submit.prevent="onSubmit()">
        <div id="login-form-global" class="items">
          <div id="login-form-password" class="form-group login-password" data-trigger="manual">
            <label>{{ $t('login.password') }}</label>
            <div class="d-flex">
              <input
                v-model="password"
                v-focus
                :type="typePassword"
                name="password"
                :placeholder="$t('login.password-placeholder')"
                class="w-100"
                :class="{ 'is-invalid': v$.password.$invalid }"
                autocomplete="current-password"
                data-cy="login-form-password"
                @blur="inputBlur"
                v-tooltip.left="{
                  theme: 'error',
                  autoHide: false,
                  distance: 8,
                  content: tooltipText,
                  shown: isShowingTooltip,
                }"
              />
              <img @click="showPassword" class="icon" :src="require(`@/assets/img/icons/${getIcon}.svg`)" />
            </div>
          </div>
        </div>
        <button type="submit" class="btn w-100 submit" data-cy="btn-login-submit">
          <span>{{ $t('login.sign-in') }}</span>
        </button>
      </form>
    </div>
    <div class="card-footer text-center">
      <a href="#" class="card-link" @click.prevent="loader()">{{ $t('forgot-password') }}</a>
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import { required } from '@vuelidate/validators';
// helpers
import loginLoaderMixin from '@/helpers/mixins/loginLoaderMixin';
// components
import LoginFormLogo from '@/components/login/_atoms/LoginFormLogo';

export default {
  components: {
    LoginFormLogo,
  },
  mixins: [loginLoaderMixin],
  setup() {
    const toast = useToast();

    return {
      v$: useVuelidate(),
      toast,
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  props: {
    showEmail: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      loading: true,
      showText: true,
      mfa: '',
      mfaMustValidate: false,
      isShowingTooltip: false,
      tooltipText: '',
      getIcon: 'eye-blocked',
      typePassword: 'password',
    };
  },
  watch: {
    error(val) {
      if (val) {
        this.$parent.changeLoginLoading(!this.loading, !this.showText);
        this.showGlobalError();
      }
    },
  },
  validations() {
    return {
      password: {
        required,
      },
    };
  },
  mounted() {
    bus.$on('mfa-must-validate', () => {
      this.mfaMustValidate = true;
    });
  },
  methods: {
    loader() {
      return this.$_loaderLogin('recover', this.loading, this.showText);
    },
    loaderBackToEmail() {
      return this.$_loaderLogin('email', this.loading, this.showText);
    },
    onSubmit() {
      this.mfaMustValidate = false;
      this.password = this.password.trim();
      if (!this.v$.password.$invalid) {
        this.$parent.sendPassword(this.password);
        this.login();
      } else {
        this.showGlobalError();
      }
    },
    login() {
      this.$parent.changeLoginLoading(this.loading, !this.showText);
      // reset error to default
      this.$parent.error = false;
      this.$store.dispatch('login', {
        email: this.showEmail,
        password: this.password,
        mfa: this.mfa,
      });
    },
    inputBlur() {
      this.hideTooltips();
      const element = document.querySelector('input[name=password]');

      if (this.v$.password.$invalid) {
        this.isShowingTooltip = true;
        this.tooltipText = this.$t('validations.messages.required');
        element.classList.add('error');
      } else {
        element.classList.remove('error');
      }
    },
    showPassword() {
      const isThePasswordHidden = this.getIcon === 'eye-blocked';

      if (isThePasswordHidden) {
        this.getIcon = 'eye';
        this.typePassword = 'text';
      } else {
        this.getIcon = 'eye-blocked';
        this.typePassword = 'password';
      }
    },
    hideTooltips() {
      this.isShowingTooltip = false;
      const element = document.querySelector('input[name=password]');
      element.classList.remove('error');
    },
    showGlobalError() {
      this.hideTooltips();

      if (this.mfaMustValidate) {
        this.toast.error(this.$t('login.mfa-send-email'));
      } else {
        this.isShowingTooltip = true;
        const element = document.querySelector('input[name=password]');
        this.tooltipText = this.$t('login.password-error');
        element.classList.add('error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-change {
  align-self: center;
  text-align: center;
}
.description {
  display: block;
  text-align: left;
  color: $gray-700;
  font-size: 12px;
  font-weight: normal;
}
.change {
  color: #36aad6;
  font-weight: 600;
  display: inline-flex;
  padding: 0 0 0 10px;
  cursor: pointer;
}
.card-description {
  margin-bottom: 5px;
}
.email {
  margin-bottom: 12px;
  font-weight: 600;
  display: inline-flex;
}
.card-body {
  margin-top: 15px;
}
.btn {
  line-height: 36px;
}

.login-password {
  input {
    position: relative;
  }

  img {
    position: absolute;
    right: 40px;
    cursor: pointer;
    bottom: 129px;
  }
}
</style>

<style lang="scss">
.Vue-Toastification__toast {
  width: 394px !important;
}
</style>
