<template>
  <section id="nps-campaigns">
    <campaigns-kpis />
    <campaigns-metrics />
    <campaigns-chart />
    <campaigns-table />
  </section>
</template>

<script>
// components
import CampaignsKpis from '@/components/nps/campaigns/CampaignsKpis';
import CampaignsMetrics from '@/components/nps/campaigns/CampaignsMetrics';
import CampaignsChart from '@/components/nps/campaigns/CampaignsChart';
import CampaignsTable from '@/components/nps/campaigns/CampaignsTable';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'NpsCampaigns',
  components: {
    CampaignsKpis,
    CampaignsMetrics,
    CampaignsChart,
    CampaignsTable,
  },
  mounted() {
    useMixpanelConfig('view_nps_campaigns');
  },
};
</script>
