<template>
  <div class="history-export">
    <history-header :title="$t('integrations.history-export')" :history-type="'export'">
      <template v-slot:select-group>
        <history-export-selects />
      </template>
    </history-header>
    <history-table :id="id" :pagination="pagination" :limit-range="limitRange" :refresh-list="refreshExportList">
      <template #table>
        <history-export-table />
      </template>
    </history-table>
    <create-export-modal />
  </div>
</template>

<script>
// libs
import { mapState, mapActions } from 'vuex';
// components
import HistoryHeader from '@/components/history/_organisms/HistoryHeader';
import HistoryExportSelects from '@/components/history/_molecules/HistoryExportSelects';
import HistoryTable from '@/components/history/_organisms/HistoryTable';
import HistoryExportTable from '@/components/history/_molecules/HistoryExportTable';
import CreateExportModal from '@/components/history/CreateExportModal';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'HistoryExport',
  components: {
    HistoryHeader,
    HistoryExportSelects,
    HistoryTable,
    HistoryExportTable,
    CreateExportModal,
  },
  computed: {
    ...mapState('historyExport', ['id', 'pagination', 'limitRange']),
  },
  mounted() {
    useMixpanelConfig('view_integrations_export');

    // datepicker range is disable on mount
    this.$isDatePickerRangeActive = false;
    // set new state and force datepicker to refresh
    this.updateSelectedBrand(true);
  },
  beforeUnmount() {
    // datepicker range is enable before destroy
    this.$isDatePickerRangeActive = true;
  },
  methods: {
    ...mapActions('historyExport', ['refreshExportList']),
    ...mapActions('base', ['updateSelectedBrand']),
  },
};
</script>
