<template>
  <div class="sales-report-metrics card">
    <template v-if="$_verifyLoaded('done')">
      <h2 class="srm_group-performance">
        <i class="icon icon-trending-18px"></i>{{ $tc('dashboard.performance', 1) }}
      </h2>
      <div v-for="(metric, key) in metrics" :key="key" class="srm__group-metrics">
        <h4 class="srm__metric-title">{{ metric.title }}</h4>

        <p class="srm__metric-amount" :data-cy="key">{{ formatMetric(metric) }}</p>
      </div>
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card
      v-show="$_verifyLoaded('info')"
      ref="message-error"
      :error="hasError"
      :show-msg="false"
      :height="'45px'"
    />
  </div>
</template>

<script>
// libs
import { mapGetters } from 'vuex';

// services
import { getMetrics } from '@/services/oto/sales-report';

// helpers
import bus from '@/helpers/events/bus';
import { valuesByFormat } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';
import { verifyMixin } from '@/helpers/mixins';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'SalesReportMetrics',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      metrics: [],
    };
  },

  computed: {
    ...mapGetters('salesReport', ['getStoreId', 'getSellerId', 'getCampaignId', 'getConversionWindow']),
  },
  mounted() {
    this.fetchData();

    // bus called when dates are changed globally
    bus.$on('render-cards', () => {
      this.fetchData();
    });
    bus.$on('srh-change-store', () => {
      this.fetchData();
    });
    bus.$on('srh-change-seller', () => {
      this.fetchData();
    });
    bus.$on('srh-change-campaign', () => {
      this.fetchData();
    });

    bus.$on('srh-change-segment-general', () => {
      this.fetchData();
    });
    bus.$on('srh-change-conversion-window', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off([
      'render-cards',
      'srh-change-store',
      'srh-change-seller',
      'srh-change-campaign',
      'srh-change-segment-general',
      'srh-change-conversion-window',
    ]);
  },

  methods: {
    fetchData() {
      this.$_reqConfig();

      getMetrics({
        storeId: this.getStoreId,
        sellerId: this.getSellerId,
        campaignId: this.getCampaignId,
        lookback: this.getConversionWindow,
        graphic: 0,
        metrics: 'orders,revenue_tracked,aov,conversion_window,unique_customers',
      })
        .then(({ data }) => {
          this.$_verifyData(data.data);

          this.metrics = data.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })

        .finally(() => {
          this.loading = false;
        });
    },

    formatMetric(metric) {
      const metricType = metric?.data?.value?.type;
      const metricValue = metric?.data?.value;

      let formattedAmount = '';

      if (metricType === valueType.float) formattedAmount = `${valuesByFormat(metricValue)} (${this.$t('average')})`;
      else formattedAmount = valuesByFormat(metricValue);

      return formattedAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-report-metrics {
  display: flex;
  flex-direction: row;
  min-height: 95px;
  height: 95px;
  position: relative;

  .srm_group-performance {
    display: flex;
    padding-top: 15px;
    color: $gray-800;
    font-weight: 600;
    font-size: 18px;

    .icon-trending-18px {
      line-height: inherit;
      color: $gray-500;
    }
  }

  .srm__group-metrics {
    width: 23%;

    &:first-child {
      margin-left: 5px;
      width: 20%;
    }
  }

  .srm__metric-title {
    color: $gray-700;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    text-align: center;
  }

  .srm__metric-amount {
    color: $oto-omni;
    font-weight: 600;
    font-size: 16px;
    padding-top: 10px;
    margin: 0;
    text-align: center;
  }
}
</style>
