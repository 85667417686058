<template>
  <div id="dialog-success">
    <i class="icon icon-checked-60px" aria-hidden="true"></i>
    <p v-if="title" class="dialog__title">{{ title }}</p>
    <div class="dialog__btn-wrapper">
      <button class="btn" @click.prevent="action">{{ $t('continue') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogSuccess',
  props: {
    title: {
      type: String,
      default: '',
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
#dialog-success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon {
    display: block;
    text-align: center;
    font-size: 76px;
    margin-bottom: 34px;
    color: $oto-ecommerce;
  }

  p.dialog__title {
    font-size: 14px;
    line-height: 22px;
  }

  .dialog__btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    button {
      width: 160px;
      height: 40px;
    }
  }
}
</style>
