<template>
  <base-card-v2 class="history__header" :style="{ backgroundImage: `url(${imageSrc})` }">
    <div class="history__container">
      <history-defaults :title="title" :has-icon="hasIcon" />
      <slot name="select-group"></slot>
    </div>
  </base-card-v2>
</template>

<script>
import HistoryDefaults from '@/components/history/_molecules/HistoryDefaults';

export default {
  name: 'HistoryHeader',
  components: {
    HistoryDefaults,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    historyType: {
      type: String,
      default: 'import',
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSrc() {
      return this.historyType === 'import' ? require(`@/assets/img/figures/genericOtoBg.png`) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.history__header {
  background: no-repeat right #fff;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  height: 100px;
  width: 100%;

  .history__container {
    display: flex;
    align-items: center;
  }
}
</style>
