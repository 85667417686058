import store from '@/store';
import mixpanel from 'mixpanel-browser';
import { Dates } from '@/features/RegistrationCulture/models/table';

export const useMixpanelCultureReportSort = (pageViewName: string, column: string, order: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    column,
    order,
  });
};

export const useMixpanelCultureReportExport = (
  pageViewName: string,
  typeFile: string,
  table: string,
  dates: Dates,
  query: string,
) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    typeFile,
    table,
    dates,
    query: JSON.stringify(query),
  });
};

export const useMixpanelCultureReportSearch = (pageViewName: string, column: string, value: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    column,
    value,
  });
};

export const useMixpanelCultureReportSelectedTable = (pageViewName: string, table: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    table,
  });
};
