/* eslint-disable no-restricted-globals */
/**
 * date formatting helper functions
 * can be used in vue filters
 */
import moment from 'moment';
import '@/locales/moment/pt-BR'; // 'pt-br'

/**
 * apply a moment.js formatting string to an input date
 * @param {Number} val input date to be changed
 * @param {String} [format='DD MMM YYYY'] moment.js format string
 * @return {String} formatted output string
 */
export default (date, format = 'DD MMM YYYY') => moment(date).format(format);
