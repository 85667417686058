<template>
  <div class="audience-integration">
    <skeleton-list v-if="loading" />

    <div v-if="!loading && series.length" class="content">
      <h2>
        {{ $t('audiences.integration.title') }}
        <span v-if="!isView">({{ $t('optional') }})</span>
      </h2>
      <div class="audience-integration-list">
        <integration-serie
          v-for="(serie, index) in series"
          :key="index"
          :serie="serie"
          :is-view="isView"
          :creator="creator"
          data-cy="audience-integrations-list"
        />
      </div>
    </div>
    <p v-else>{{ $t('audiences.integration.no-data') }}</p>
  </div>
</template>

<script>
import IntegrationSerie from '@/components/audiences/create/integration/IntegrationSerie';
import IntegrationsListSkeleton from '@/components/audiences/create/integration/IntegrationsListSkeleton';
import { getConnectorsbyAudience } from '@/services/audiences';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    'integration-serie': IntegrationSerie,
    'skeleton-list': IntegrationsListSkeleton,
  },
  data() {
    return {
      enabled: [],
      loading: true,
    };
  },
  computed: {
    // map state properties
    ...mapGetters('audience', ['isView']), // -> this.isView
    ...mapState('audience', {
      reference: 'reference', // -> this.reference
      series: 'integrationSeries', // -> this.series
      creator: 'creator', // -> this.creator
    }),
  },
  mounted() {
    this.getIntegrations();
  },
  methods: {
    getIntegrations() {
      if (this.isView && this.reference) {
        this.loading = true;
        getConnectorsbyAudience(this.reference).then(({ data }) => {
          this.enabled = data.data;
          this.integrations(data.data);
          this.$store.dispatch('audience/getIntegrationsFromRequest', data.data);
        });
      } else this.loading = false;
    },
    integrations(series) {
      if (series && series.length) {
        series.forEach(conf => {
          // dispatch action to update integration serie
          this.$store.dispatch('audience/updateIntegration', {
            id: conf.connector_id,
            alias: conf.alias,
            icon: conf.icon,
            type: conf.type,
            connect: conf.alias,
            enabled: conf.active,
            hasDatetime: !!conf.starting_date,
            schedule: conf.interval,
            startDatetime: conf.starting_date,
            scheduleID: conf.schedule_id,
            lastExecution: conf.last_execution,
            editable: conf.editable,
            status: conf.status,
          });
        });
      }
      this.loading = false;
    },
  },
};
</script>
