<template>
  <el-dialog
    v-model="isVisible"
    id="personas-detail-dialog-success"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <i class="icon icon-checked-60px" aria-hidden="true"></i>
    <p>Persona criada com sucesso</p>
    <a :href="`/personas/view/${this.redirectId}`">
      <div class="dialog">
        <div class="btn">{{ $t('continue') }}</div>
      </div>
    </a>
  </el-dialog>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';

export default {
  name: 'DialogSuccess',
  data() {
    return {
      redirectId: null,
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('show-detail-success', redirectId => {
      this.redirectId = redirectId;
      this.show();
    });
    bus.$on('hide-detail-success', () => {
      this.message = 'error';
      this.hide();
    });
  },
  beforeUnmount() {
    bus.$off(['show-detail-success', 'hide-detail-success']);
  },
  methods: {
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
#personas-detail-dialog-success {
  width: 500px;
  text-align: center;

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }

  .btn {
    width: 170px;
  }

  .icon {
    display: block;
    text-align: center;
    font-size: 76px;
    margin-bottom: 34px;
    color: $oto-ecommerce;
  }
}
</style>
