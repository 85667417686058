<template>
  <div class="indicators-comparable-filters">
    <div id="comparison-filters-title" class="form-group mt-3">
      <comparison-filters-title
        :title="$t('360.indicators.comparable.title')"
        :subtitle="$t('360.indicators.comparable.subtitle')"
        :has-selected-seller="hasSelectedSeller"
        :full-name="getActiveSellerObject.fullName"
        :has-selected-store="hasSelectedStore"
        :store-name="getActiveStoreObject.name"
      />
    </div>

    <div id="comparison-metrics" class="form-group mt-3">
      <comparison-metrics
        name="indicators"
        :filters="filters"
        :action="setFilters"
        :metrics="$parent.metrics"
        :showAlertTooltip="showSelectMetricsTooltip"
      />
    </div>

    <div id="comparison-criteria" class="form-group mt-3">
      <comparison-criteria name="indicators" :setCriteriaName="setCriteriaName" />
    </div>

    <div id="comparison-stores" class="form-group mt-3">
      <comparison-stores
        name="indicators"
        :stores="stores"
        :showAlertTooltip="showSelectStoresTooltip"
        :filters="filters"
        :getCriteria="getCriteria"
        :setFilters="setFilters"
        :resetFilters="resetFilters"
      />
    </div>

    <div id="filter-handle" class="form-group mt-3">
      <button class="btn btn-update" @click.prevent="filterHandler">
        {{ $t('360.sellers-analytics.comparable.update') }}
      </button>

      <a href="#" class="btn-reset" @click.prevent="resetComparableFilters">
        {{ $t('360.sellers-analytics.comparable.reset') }}
      </a>
    </div>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapActions, mapGetters, mapState } from 'vuex';

import { useMixpanelConfig, useMixpanelComparableUpdate } from '@/utilities/mixpanel';

import ComparisonFiltersTitle from '@/components/oto/indicators/ComparisonFiltersTitle';
import ComparisonCriteria from '@/components/comparisons/comparisonCriteria/ComparisonCriteria';
import ComparisonMetrics from '@/components/comparisons/comparisonMetrics/ComparisonMetrics';
import ComparisonStores from '@/components/comparisons/comparisonStores/ComparisonStores';

export default {
  name: 'ComparisonStoresFilter',
  components: {
    ComparisonFiltersTitle,
    ComparisonCriteria,
    ComparisonMetrics,
    ComparisonStores,
  },
  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      term: '',
      ready: false,
      selectedStates: [],
      selectedRegions: [],
      tab: 'tab-store-comparison',
      showSelectStoresTooltip: false,
      showSelectMetricsTooltip: false,
    };
  },
  computed: {
    ...mapState('indicators', ['filters']),
    ...mapGetters('indicators', [
      'getActiveSellerObject',
      'getActiveStoreObject',
      'getSellerId',
      'getStoreId',
      'getCampaignId',
      'getFilters',
      'getCriteria',
    ]),
    comparisonStoresList() {
      const list = this.getComparisonStoresList();
      const regions = Object.keys(this.getComparisonStoresList());
      regions.forEach(region => {
        const states = Object.keys(list[region]);
        // ordena lojas de A-Z
        states.forEach(state => {
          list[region][state].sort((a, b) => (a.name > b.name ? 1 : -1));
        });
      });
      return list;
    },
    hasSelectedSeller() {
      return this.getActiveSellerObject?.id ? true : false;
    },
    hasSelectedStore() {
      return this.getActiveStoreObject?.id ? true : false;
    },
    hasMetrics() {
      return this.getFilters?.metrics.length;
    },
    hasStores() {
      return this.getFilters?.comparisonStores.length;
    },
    hasFilter() {
      return this.getStoreId || this.getCampaignId || this.getSellerId;
    },
  },
  watch: {
    getStoreId() {
      this.reset();
    },
    getSellerId() {
      this.reset();
    },
    getCampaignId() {
      this.reset();
    },
  },
  mounted() {
    this.reset();
    this.resetFilters();

    bus.$on(`indicators-select-emit-${this.tab}`, brand => {
      this.handleChangeBrand(brand);
    });
  },
  beforeUnmount() {
    bus.$off([`indicators-select-emit-${this.tab}`]);
  },
  methods: {
    ...mapActions('indicators', [
      'setParallelCoordinates',
      'clearParallelCoordinates',
      'setFilters',
      'resetFilters',
      'setCriteriaName',
    ]),
    handleChangeBrand(brand) {
      if (!brand && this.hasFilter) this.filterHandler();
      else {
        this.resetFilters();
        this.clearParallelCoordinates();
        bus.$emit('reset-comparable-stores');
      }
    },

    filterHandler() {
      this.handleComparableMetricsValues();

      useMixpanelComparableUpdate(
        'view_oto_indicators_comparable-update-btn',
        this.getFilters.metrics,
        this.getCriteria,
        this.comparisonStoresList,
      );
      bus.$emit('wrap-stores-plot');
    },

    // Manipula os valores das métricas comparáveis
    handleComparableMetricsValues() {
      if (!this.hasMetrics) return this.emitMetricsTooltip();
      else if (!this.hasStores) return this.emitStoresTooltip();
      else this.fetchParallelCoordinates();
    },

    emitMetricsTooltip() {
      this.showSelectMetricsTooltip = true;
      setTimeout(() => (this.showSelectMetricsTooltip = false), 2000);
    },

    emitStoresTooltip() {
      this.showSelectStoresTooltip = true;
      setTimeout(() => (this.showSelectStoresTooltip = false), 2000);
    },

    fetchParallelCoordinates() {
      if (!this.hasMetrics || !this.hasStores) return this.clearParallelCoordinates();

      this.setParallelCoordinates();
    },

    resetComparableFilters() {
      useMixpanelConfig('view_oto_indicators_comparable-filters_reset-btn');
      bus.$emit('reset-comparable-stores');

      this.clearParallelCoordinates();
      this.resetFilters();
    },

    reset() {
      this.selectedRegions.length = 0;
      this.selectedStates.length = 0;
      this.ready = false;
    },

    getComparisonStoresList() {
      const comparisonStoresList = {};

      this.$parent.stores.forEach(store => {
        if (store.region) {
          if (typeof comparisonStoresList[store.region] !== 'object') comparisonStoresList[store.region] = {};
          if (!Array.isArray(comparisonStoresList[store.region][store.state]))
            comparisonStoresList[store.region][store.state] = [];
          comparisonStoresList[store.region][store.state].push({
            id: store.id,
            name: store.store,
          });
        }
      });
      this.ready = true;
      return comparisonStoresList;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  border-radius: 100px;
  margin-top: 45px;
  padding: 0 15px;
}
.btn-reset {
  display: block;
  font-weight: 600;
  line-height: 46px;
  font-size: 12px;
  text-align: center;
  color: $gray-700;
}
.form-group {
  position: relative;
}
</style>
