<template>
  <div id="login-recover">
    <div class="card-header justify-content-start d-flex">
      <button class="back" @click.prevent="loaderBackToPassword()">
        {{ $t('back') }}
      </button>
    </div>
    <div class="card-body">
      <form class="text-center" novalidate @submit.prevent="onSubmit()">
        <div class="form-group">
          <h3 class="title">{{ $t('login.password-recover') }}</h3>
          <p class="description">{{ $t('login.type-your-email') }}</p>
        </div>
        <div class="form-group">
          <input
            id="login-recover-email"
            v-model="email"
            v-focus
            type="email"
            name="email"
            placeholder="E-mail"
            class="w-100"
            :class="{ 'is-invalid': v$.email.$invalid }"
            autocomplete="email"
            @focus="showError = false"
            @blur="inputBlur"
            v-tooltip.left="{
              theme: 'error',
              autoHide: false,
              distance: 8,
              content: this.$t('validations.messages.email'),
              shown: isShowingTooltip,
            }"
          />
        </div>
        <span v-if="showError" class="message-is-invalid mb-15">{{ $t('login.email-error') }}</span>
        <button type="submit" class="btn w-100 submit">
          <span>{{ $t('login.recover-my-password') }}</span>
        </button>
      </form>
    </div>
    <div class="card-footer text-center">
      <a href="#" class="card-link" @click.prevent="loader()">{{ $t('login.other-recover') }}</a>
    </div>
  </div>
</template>

<script>
import loginLoaderMixin from '@/helpers/mixins/loginLoaderMixin';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
  mixins: [loginLoaderMixin],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: '',
      showError: false,
      loading: true,
      showText: true,
      isShowingTooltip: false,
    };
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  mounted() {
    this.email = this.$store.state.auth.lastEmail.email;
  },
  methods: {
    loaderBackToPassword() {
      return this.$_loaderLogin('password', this.loading, this.showText);
    },
    loader() {
      return this.$_loaderLogin('recover-alternative', this.loading, this.showText);
    },
    onSubmit() {
      if (!this.v$.email.$invalid) {
        this.recover();
      } else {
        this.inputBlur();
      }
    },
    recover() {
      this.$parent.changeLoginLoading(this.loading, !this.showText);
      this.$parent.recoverEmail = this.email;
      this.$store
        .dispatch('forgot', this.email)
        .then(() => {
          this.$parent.changeLoginMode('recover-success', !this.loading, !this.showText);
        })
        .catch(() => {
          this.$parent.changeLoginLoading(!this.loading, !this.showText);
          this.showError = true;
        });
    },
    inputBlur() {
      this.hideTooltips();
      const element = document.querySelector('input[name=email]');

      if (this.v$.email.$invalid) {
        this.isShowingTooltip = true;
        element.classList.add('error');
      } else {
        this.isShowingTooltip = false;
        element.classList.remove('error');
      }
    },
    hideTooltips() {
      this.isShowingTooltip = false;
      const element = document.querySelector('input[name=email]');
      element.classList.remove('error');
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  display: block;
  text-align: left;
  color: $gray-700;
  font-size: 12px;
  font-weight: normal;
}
</style>
