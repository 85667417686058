//libs
import { escapeRegExp, isEmpty } from 'lodash';
// helpers
import { stringParser } from '@/helpers/string-parser';
// endpoints
import { getTypeLoadList, getImportsHistory, getImportLogs } from '@/services/integrations';
import { updateAudience } from '@/services/audiences';

const historyImport = {
  namespaced: true,
  state: {
    status: [
      { label: 'integrations.all', key: '' },
      { label: 'audiences.status.success', key: 'success' },
      { label: 'audiences.status.scheduled', key: 'scheduled' },
      { label: 'audiences.status.cancelled', key: 'cancelled' },
      { label: 'audiences.status.running', key: 'running' },
      { label: 'audiences.status.error', key: 'error' },
    ],
    historySerie: ['status', 'typeLoad', 'execTime', 'exportDate', 'processedCount', 'size'],
    typeList: [],
    historyData: [],
    isEmptyGlobal: false,
    loadingGlobal: false,
    hasErrorGlobal: false,
    selectedStatus: '',
    selectedLoadTypeId: null,
    // pagination data
    id: 'import-history',
    pagination: {
      count: 0,
      limit: 10,
      page: 1,
      order: '',
    },
    limitRange: [5, 10, 20, 30, 50],
    log: {},
    logIsEmpty: false,
    logLoading: false,
    logHasError: false,
  },
  mutations: {
    SET_TYPELIST(state, typeList) {
      state.typeList = typeList;
    },
    SET_IMPORT_HISTORY(state, history) {
      state.historyData = history;
      if (isEmpty(history)) {
        state.isEmptyGlobal = true;
        state.loadingGlobal = false;
      } else state.isEmptyGlobal = false;
    },
    SET_LOADING(state, isLoading) {
      state.loadingGlobal = isLoading;
    },
    SET_ERROR(state, hasError) {
      state.hasErrorGlobal = hasError;
    },
    SET_SELECTED_STATUS(state, status) {
      state.selectedStatus = status;
    },
    SET_SELECTED_LOADTYPE(state, id) {
      state.selectedLoadTypeId = id;
    },
    SET_IMPORT_TOTALCOUNT(state, total) {
      state.pagination.count = total;
    },
    SET_PAGINATION_ORDER(state, order) {
      state.pagination.order = order;
    },
    RESET_PAGINATION(state) {
      state.pagination.page = 1;
    },
    RESET_SELECTED(state) {
      state.selectedStatus = '';
      state.selectedLoadTypeId = null;
    },
    SET_LOG_DATA(state, payload) {
      state.log = payload;
      if (isEmpty(payload)) {
        state.logIsEmpty = true;
        state.logLoading = false;
      } else state.logIsEmpty = false;
    },
    SET_LOG_LOADING(state, isLoading) {
      state.logLoading = isLoading;
    },
    SET_LOG_ERROR(state, hasError) {
      state.logHasError = hasError;
    },
    RESET_LOG_DATA(state) {
      state.log = {};
    },
  },
  actions: {
    setTypeList({ commit }) {
      getTypeLoadList().then(({ data }) => {
        if (!data.success) return;
        const all = { id: 0, name: 'Todos' };
        data.data.unshift(all);
        commit('SET_TYPELIST', data.data);
      });
    },
    getImportHistoryList({ commit, state }) {
      commit('SET_ERROR', false);
      commit('SET_LOADING', true);
      getImportsHistory({
        id: state.selectedLoadTypeId,
        status: state.selectedStatus,
        page: state.pagination.page,
        limit: state.pagination.limit,
        orderBy: state.pagination.order,
      })
        .then(({ data }) => {
          if (!data.success) {
            commit('SET_ERROR', true);
            return;
          }
          commit('SET_IMPORT_HISTORY', data?.data);
          commit('SET_IMPORT_TOTALCOUNT', data?.totalCount);
        })
        .catch(() => {
          commit('SET_ERROR', true);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    setSelectedStatus({ commit, dispatch }, { status }) {
      commit('SET_SELECTED_STATUS', status);
      commit('RESET_PAGINATION');
      dispatch('getImportHistoryList');
    },
    setSelectedLoadTypeId({ commit, dispatch }, { id }) {
      commit('SET_SELECTED_LOADTYPE', id);
      commit('RESET_PAGINATION');
      dispatch('getImportHistoryList');
    },
    setPaginationOrder({ commit, dispatch }, order) {
      commit('SET_PAGINATION_ORDER', order);
      dispatch('getImportHistoryList');
    },
    refreshImportList({ dispatch }) {
      dispatch('getImportHistoryList');
    },
    setCancelRecurrence({ dispatch }, scheduleId) {
      const enabled = 0;
      updateAudience({ scheduleID: scheduleId, enabled }).then(() => {
        dispatch('getExportHistoryList');
      });
    },
    resetSelected({ commit }) {
      commit('RESET_SELECTED');
    },
    setLogData({ commit, dispatch }, { logId, page, limit } = {}) {
      dispatch('resetLogData');
      commit('SET_LOG_LOADING', true);
      commit('SET_LOG_ERROR', false);
      getImportLogs({ logId, page, limit })
        .then(({ data }) => {
          if (!data.success) return;
          commit('SET_LOG_DATA', data?.data);
        })
        .catch(() => {
          dispatch('resetLogData');
          commit('SET_LOG_ERROR', true);
        })
        .finally(() => {
          commit('SET_LOG_LOADING', false);
        });
    },
    resetLogData({ commit }) {
      commit('RESET_LOG_DATA');
    },
  },
  getters: {
    getFilteredHistoryList:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.historyData.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getHistoryData: state => state.historyData,
    getLogData: state => state.log,
    // Verify Mixin Getters
    getLoading: state => state.loadingGlobal,
    getIsEmpty: state => state.isEmptyGlobal,
    getHasError: state => state.hasErrorGlobal,
  },
};

export default historyImport;
