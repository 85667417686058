<template>
  <lightbox-template :id="id" icon="cart" :title="$tc('product.title', 2)" :show-segment-group="false">
    <div class="container mb-4">
      <header class="d-flex align-items-center mb-4">
        <h5 class="modal-subtitle">{{ $t('categories.orders.revenue') }}</h5>
      </header>
    </div>

    <lightbox-skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <tab-item v-if="!loading" :tab-list="tabList" :tab-active="tabTypeActive" />

    <div v-show="$_verifyLoaded('done')" class="col-12">
      <lightbox-table
        :id="id"
        :data="data"
        :data-type="tabTypeActive"
        :is-lightbox-active="isLightboxActive"
        :settings-data="settingsData"
        :col-scheme="['chart:percentual', 'revenue']"
      />
    </div>
    <div v-show="$_verifyLoaded('info')" class="card">
      <info-card ref="message-error" :error="hasError" />
    </div>
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import bus from '@/helpers/events/bus';
import { getMetrics } from '@/services/product';
import { lightboxesMixin, verifyMixin, miniBarChartMixin } from '@/helpers/mixins';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import LightboxSkeleton from '@/components/lightbox/lightboxes/products/LightboxProductsRevenueSkeleton';
import TabItem from '@/components/lightbox/_molecules/_atoms/TabItem';
import LightboxTable from '@/components/lightbox/_molecules/_atoms/LightboxTable';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'LightboxProductsRevenue',
  components: {
    LightboxTemplate,
    LightboxSkeleton,
    TabItem,
    LightboxTable,
    InfoCard,
  },
  mixins: [lightboxesMixin, miniBarChartMixin, verifyMixin],
  props: {
    settingsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: 'lightbox-products-revenue',
      isLightboxActive: true,
      productID: this.$route.params.id,
      data: {
        limit: 10,
        page: 1,
        count: 0,
        values: [],
        valueTotal: 0,
      },
      tabList: [
        {
          name: this.$t('categories.orders.in-store'),
          type: 'instore',
        },
        {
          name: this.$t('categories.orders.ecommerce'),
          type: 'ecommerce',
        },
      ],
      tabTypeActive: 'instore',
      type: 'filled',
    };
  },
  mounted() {
    this.fetchData();

    bus.$on('lightbox-update-tab', item => {
      this.tabTypeActive = item;
      this.data.page = 1; // reset page
      this.$_reqConfig();
      this.updateDataTable();
    });

    bus.$on(`${this.id}-change-page`, () => {
      this.$_reqConfig();
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
    bus.$off('lightbox-update-tab');
  },

  methods: {
    fetchData() {
      this.$_reqConfig();
      this.updateDataTable();
    },
    verifyDataPerTab(data) {
      if (this.$_verifyHasData(data)) return false;
      return true;
    },
    fetchEcommerceData() {
      getMetrics({
        productID: this.productID,
        metrics: 'revenue_by_medium',
        filters: 'store_type:on',
        limit: this.data.limit,
        page: this.data.page,
      })
        .then(({ data }) => {
          if (!this.verifyDataPerTab(data.metrics)) return;
          const receivedValues = data.metrics.revenue_by_medium.data[0].series.data;
          const getArray = Object.keys(receivedValues).map(item => [item, receivedValues[item]]);
          this.data.values = getArray;
          this.data.count = data.total_count;
          this.data.valueTotal = data.metrics.revenue_by_medium.value_total;
        })
        .catch(() => {
          this.data.count = 0;
          this.data.values = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    fetchInstoreData() {
      getMetrics({
        productID: this.productID,
        metrics: 'revenue_by_medium',
        filters: 'store_type:off',
        limit: this.data.limit,
        page: this.data.page,
      })
        .then(({ data }) => {
          if (!this.verifyDataPerTab(data.metrics)) return;
          const receivedValues = data.metrics.revenue_by_medium.data[0].series.data;
          const getArray = Object.keys(receivedValues).map(item => [item, receivedValues[item]]);
          this.data.values = getArray;
          this.data.count = data.total_count;
          this.data.valueTotal = data.metrics.revenue_by_medium.value_total;
        })
        .catch(() => {
          this.data.count = 0;
          this.data.values = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateDataTable() {
      switch (this.tabTypeActive) {
        case 'ecommerce':
          this.fetchEcommerceData();
          break;
        case 'instore':
          this.fetchInstoreData();
          break;
        default:
          break;
      }
    },
  },
};
</script>
