<template>
  <div class="suggestion-details-dates">
    <!-- campaign exclude dates -->
    <div class="sdd__exclude-dates">
      <p class="sdd__exclude-dates-desc" v-html="$t('360.campaigns.details.exclude-dates')"></p>
      <div class="sdd__exclude-input-wrapper">
        <div class="sdd__options-input-container">
          <div class="sdd__exclude-period">
            <p class="sdd__exclude-date label">
              {{ $t('360.campaigns.details.exclude-date-label') }}
            </p>
            <label class="sdd__exclude-period-label ml-2" @click.prevent="toggleExclusionPeriod">
              <check-box class="checkbox" :checked="isExclusionPeriodActive" />
            </label>
            <p class="sdd__exclude-period mb-0 ml-2">
              {{ $t('360.campaigns.details.period') }}
            </p>
          </div>
          <!-- exclude period date -->
          <div v-show="isExclusionPeriodActive" class="form-group sdd__datepicker element--datepicker">
            <el-date-picker
              v-model="datesValue"
              type="daterange"
              format="DD MMM YYYY"
              value-format="YYYY-MM-DD"
              :start-placeholder="$tc('start-date')"
              :end-placeholder="$tc('end-date')"
              :clearable="false"
              :disabled-date="disabledBackwardDates"
              :prefix-icon="customPrefix"
            />
          </div>
          <!-- exclude single date -->
          <div v-show="!isExclusionPeriodActive" class="form-group sdd__datepicker element--datepicker">
            <el-date-picker
              v-model="campaignExclusionPeriod.singleDate"
              type="date"
              format="DD MMM YYYY"
              value-format="YYYY-MM-DD"
              :clearable="false"
              :placeholder="$tc('_select', 2)"
              :disabled-date="disabledBackwardDates"
              :prefix-icon="customPrefix"
            />
          </div>
        </div>
        <button class="btn btn-outline-ctx-primary" @click.prevent="addExcludedDate">
          {{ $t('360.campaigns.details.add') }}
        </button>
      </div>
      <div v-if="campaignExclusionDates" class="sdd__excluded-dates-list customScrollBar">
        <div v-for="(date, index) in campaignExclusionDates" :key="index" class="sdd__date-item">
          <span class="sdd__date">
            {{ formatReadbleDayOfYear(date) }}
          </span>
          <div class="sdd__icon-container" @click="deleteExludedDate(index)">
            <icon-base :color="'#EC3942'" class="sdd__trash-icon">
              <icon-trash />
            </icon-base>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import moment from 'moment';
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import { h, shallowRef } from 'vue';

// helpers
import { checkboxMixin } from '@/helpers/mixins';
import { dateFormatDayAndMonth } from '@/utilities/constants';

// utilities
import { disabledBackwardDates } from '@/utilities/datepicker';

// assets
import IconBase from '@/assets/vue-icons/IconBase';
import IconTrash from '@/assets/vue-icons/icons/Trash';

// components
import CheckBox from '@/components/_atoms/CheckBox';

export default {
  name: 'CampaignsManagementDetailsDates',
  components: {
    IconBase,
    IconTrash,
    CheckBox,
  },
  setup() {
    const customPrefix = shallowRef({
      render() {
        return h('i', {
          class: 'icon icon-calendar-30px',
        });
      },
    });

    return { customPrefix };
  },
  mixins: [checkboxMixin],
  data() {
    return {
      isExclusionPeriodActive: false,
      datesValue: '',
      campaignExclusionPeriod: {
        startDate: '',
        endDate: '',
        singleDate: '',
      },
    };
  },
  computed: {
    ...mapGetters('campaignsManagement', ['getSuggestionDetail']),

    campaignExclusionDates() {
      return this.getSuggestionDetail.dayOfYear;
    },
  },
  methods: {
    /**
     * @description add to exclude single or period dates
     */
    addExcludedDate() {
      if (this.hasEmptyValues()) return;

      this.campaignExclusionPeriod.startDate = this.datesValue[0];
      this.campaignExclusionPeriod.endDate = this.datesValue[1];

      if (this.isExclusionPeriodActive) {
        this.campaignExclusionDates.push(
          `${this.campaignExclusionPeriod.startDate}:${this.campaignExclusionPeriod.endDate}`,
        );
      } else {
        this.campaignExclusionDates.push(`${this.campaignExclusionPeriod.singleDate}`);
      }
    },

    /**
     * @description delete excluded date
     */
    deleteExludedDate(index) {
      this.campaignExclusionDates.splice(index, 1);
    },

    /**
     * @description validate if there are any empty dates
     */
    hasEmptyValues() {
      this.campaignExclusionPeriod.startDate = this.datesValue[0];

      return isEmpty(
        (this.isExclusionPeriodActive && this.campaignExclusionPeriod.startDate) ||
          this.campaignExclusionPeriod.singleDate,
      );
    },

    /**
     * @description format YYYY-MM-DD ex: 2021-03-03 to DD/MM/YYYY ex: 25/03/2021
     */
    formatReadbleDayOfYear(date) {
      const splitted = date.split(':');
      const formattedDate =
        splitted.length === 1
          ? moment(splitted[0]).format(dateFormatDayAndMonth)
          : `${moment(splitted[0]).format(dateFormatDayAndMonth)} - ${moment(splitted[1]).format(
              dateFormatDayAndMonth,
            )}`;
      return formattedDate;
    },

    /**
     * @description toggle exclusion period
     */
    toggleExclusionPeriod() {
      this.isExclusionPeriodActive = !this.isExclusionPeriodActive;
    },

    disabledBackwardDates,
  },
};
</script>

<style lang="scss" scoped>
.suggestion-details-dates {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  .sdd__exclude-dates {
    border: 1px solid $gray-400;
    padding: 30px 27px;
    margin-top: 25px;
    height: 200px;
    width: 550px;
    .sdd__exclude-dates-desc {
      margin: 0;
    }
    .sdd__exclude-input-wrapper {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .sdd__options-input-container {
        width: 258px;
        .sdd__exclude-period {
          display: flex;
          align-items: center;
        }
      }
      .btn-outline-ctx-primary {
        margin-left: 10px;
        width: 115px;
      }
    }
    .sdd__excluded-dates-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(130px, 1fr));
      grid-auto-rows: 40px;
      align-items: center;
      margin-top: 10px;
      max-height: 65px;
      overflow-y: scroll;

      .sdd__date-item {
        display: flex;
        align-items: center;
        .sdd__date {
          font-size: 11px;
          color: $gray-700;
          margin-right: 10px;
        }
        .sdd__icon-container {
          display: flex;
          height: 14px;
          .sdd__trash-icon {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.sdd__datepicker {
  margin-bottom: 0;
  position: relative;
  width: 257px;
  .sdd__datepicker-input {
    min-width: 257px;
  }
  .icon {
    color: $gray-600;
    position: absolute;
    right: 15px;
    bottom: 5px;
    font-size: 24px;
    pointer-events: none;
  }
}
</style>
