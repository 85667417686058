<template>
  <div id="websitebehaviorecommercesessions" class="card fake blocked" style="min-height: 350px">
    <div class="card-body">
      <h5 class="card-title customTitle">{{ $t('sessions') }}</h5>
      <div id="chartEcommerceSessions"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Highcharts from 'highcharts';
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import { dateDayMonthYear } from '@/utilities/constants';
import { formatDecimal, formatNumber } from '@/utilities/formatters';

function CreateChartSessions(categories, data, $i18n) {
  Highcharts.chart('chartEcommerceSessions', {
    chart: {
      height: 300,
      style: {
        fontFamily: 'Montserrat',
      },
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: [
      {
        gridLineWidth: 0,
        categories,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        gridLineWidth: 0,
        labels: {
          formatter: function format() {
            return `${formatNumber(this.value, '0,0a')}`;
          },

          style: {
            color: '#888',
          },
        },
        title: {
          text: '',
          style: {
            color: '#888',
          },
        },
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: '',
          style: {
            color: '#FA6F32',
          },
        },

        labels: {
          formatter: function format() {
            return `${this.value}%`;
          },
          style: {
            color: '#888',
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      valueDecimals: 0,
      borderWidth: 0,
      backgroundColor: '#fff',
      useHTML: true,
      formatter: function format() {
        let tooltip = `<div>${this.x.replace('<br/>', ' ')}</div>`;
        this.points.forEach((item, index) => {
          tooltip += `<div><span class="point" aria-hidden="true" style="background-color: ${item.color}"></span>${
            item.series.name
          }: <strong>${index === 2 ? formatNumber(item.y, '0%') : formatDecimal(item.y, 1)}</strong></div>`;
        });
        return tooltip;
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemMarginTop: -30,
      itemMarginBottom: 25,
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      itemStyle: {
        color: 'grey',
        fontWeight: 400,
      },
    },
    series: [
      {
        name: $i18n.t('sessions'),
        type: 'column',
        yAxis: 0,
        color: '#7C48BB',
        pointWidth: 5,
        data: data[0],
      },
      {
        name: $i18n.t('identified'),
        type: 'column',
        yAxis: 0,
        color: '#63C5EA',
        pointWidth: 5,
        data: data[1],
      },
      {
        name: $i18n.t('match-rate'),
        type: 'spline',
        yAxis: 1,
        color: '#FA6F32',
        dashStyle: 'long',
        lineWidth: 3,
        data: data[2].map(value => +value.toFixed(0)),
      },
    ],
  });
}
export default {
  name: 'WebSiteBehaviorEcommerceSessions',
  data() {
    return {
      ready: false,
      dataReturn: {},
      dataChart1: {
        name: '',
        type: '',
        data: [],
        tooltip: {
          valueSuffix: '',
        },
      },
      dataChart2: {
        name: '',
        type: '',
        data: [],
        tooltip: {
          valueSuffix: '',
        },
      },
      dataChart3: {
        name: '',
        type: '',
        data: [],
        tooltip: {
          valueSuffix: '',
        },
      },
    };
  },
  created() {
    // segmento default
    return this.fetch_data();
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetch_data() {
      getMetrics({
        metrics: 'ec_sessions_total,ec_sessions_identified,ec_sessions_match_rate',
        expand: true,
      }).then(data => {
        const categories = data.data.data.labels.map(date => moment(new Date(`${date}`) - 1).format(dateDayMonthYear));
        const { metrics } = data.data.data;
        const serieData = Object.keys(metrics).map(key => metrics[key]);
        CreateChartSessions(categories, serieData, this.$i18n);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customTitle {
  @media (min-width: 1200px) {
    position: absolute;
    z-index: 1;
  }
}
</style>
<style lang="scss">
.point {
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: black;
  border-radius: 30px;
  margin-bottom: 1px;
  margin-right: 4px;
}
</style>
