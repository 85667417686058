<template>
  <section id="reports" class="reports">
    <header-reports :loading="loading" :reports="reports" :selected="getReportById" />
    <div class="card" :loading="loading">
      <div class="card-body reports__card-body">
        <iframe
          v-if="getReportById"
          :width="getReportById.width"
          :height="getReportById.height"
          :src="getReportById.url"
          class="reports__docs"
        ></iframe>
        <div v-if="getReportById" class="reports__footer">
          <b>{{ $store.getters.selectedBrand.brand }}</b>
          <span class="pl-1 pr-3">{{ getReportById.reportName }}</span>
          <img src="@/assets/img/logo_preto.png" alt="Pmweb" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import bus from '@/helpers/events/bus';
import HeaderReports from '@/components/reports/HeaderReports';
import { getReports } from '@/services/reports';

export default {
  name: 'Reports',
  components: {
    HeaderReports,
  },
  data() {
    return {
      reports: [],
      selectedId: null,
      loading: false,
    };
  },
  computed: {
    getReportById: {
      get() {
        return this.reports ? this.reports.find(el => el.id === this.selectedId) : false;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
  watch: {
    // update selected id if route changes
    $route() {
      this.updateSelectedId();
    },
  },
  mounted() {
    this.updateSelectedId();
    this.fetchData();

    bus.$on('change-report', item => {
      this.selectedId = parseInt(item.id);
      this.$router.replace({ name: 'reportDetail', params: { id: item.id } });
    });
    bus.$on('update-selected-brand', this.updateReport);
  },

  beforeUnmount() {
    bus.$off(['change-report', 'update-selected-brand']);
  },
  methods: {
    /**
     * @description get the first id value
     */
    getSelectedId() {
      return this.reports ? (this.selectedId = this.reports[0]?.id) : false;
    },
    /**
     * @description get param id if have one
     */
    updateSelectedId() {
      const id = parseInt(this.$route.params?.id);
      id ? (this.selectedId = id) : false;
    },
    /**
     * @description reset report and fetch data again
     */
    updateReport() {
      this.resetReport();
      this.fetchData();
    },
    /**
     * @description clear report
     */
    resetReport() {
      this.selectedId = null;
      this.getReportById = null;
    },
    /**
     * @description fetch reports
     */
    fetchData() {
      this.loading = true;

      getReports()
        .then(({ data }) => {
          const reports = data?.data ? data.data : [];
          if (!reports) return;
          this.reports = reports;

          if (!this.selectedId) this.selectedId = reports[0]?.id;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  &__footer {
    width: 100%;
    height: 43px;
    padding: 0 30px;
    background-color: $color-white;
    font-size: 11px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__docs {
    border: 0;
  }

  &__card-body {
    position: relative;
    min-height: 710px;
  }
}
</style>
