<template>
  <div :class="{ loadHeight: loading }" class="crm-segments-blocks">
    <div v-show="$_verifyLoaded('done')" class="row">
      <div class="vertical-arrow col-sm-1">
        <span>{{ $t('value') }}</span>
      </div>
      <div class="list-groups col-sm-10">
        <div class="row no-gutters">
          <div v-for="(group, index) in dataRequest" :key="`${group.title}-${index}`" class="group col-outside">
            <div class="row title">
              <span class="col-sm-12">{{ group.name }}</span>
            </div>
            <div class="row row-content new">
              <div
                v-for="(column, i) in group.columns"
                :key="`column-${i}`"
                class="column col-inside"
                :class="`col-sm-${12 / Object.keys(group.columns).length}`"
              >
                <div
                  v-for="(block, key) in column"
                  :key="`block${key}`"
                  class="block row full"
                  :class="`${segment == block.id ? ' active' : ''}${segment && segment != 1 ? ' dimmed' : ''}`"
                  :style="`flex-basis: ${block.height}%; background-color: #${block.color}`"
                  @click="changeSegment(block.id, 2)"
                >
                  <span class="name">
                    {{ block.name }}
                    <span class="percent">
                      {{ $_formatNumber(block.data.percent.value, '0.0%') }}
                    </span>
                  </span>
                  <div class="block-tooltip">
                    <div class="content">
                      <i class="arrow-left"></i>
                      <span class="title">{{ block.name }}</span>
                      <ul>
                        <li>
                          <span>{{ $t('revenue-12m') }}:</span>
                          <span class="value">{{ formatCurrency(block.data.revenue.value) }}</span>
                        </li>
                        <li>
                          <span>{{ $t('clients') }}:</span>
                          <span class="value">{{ $_formatDecimal(block.data.customers.value) }}</span>
                          <span class="percent">({{ $_formatNumber(block.data.percent.value, '0.0%') }})</span>
                        </li>
                        <li>
                          <span>{{ $t('frequency') }}:</span>
                          <span class="value">{{ $_formatDecimal(block.data.frequency.value) }}</span>
                        </li>
                        <li>
                          <span>{{ $t('avg-value') }}:</span>
                          <span class="value">
                            {{ formatCurrency(block.data.avg_revenue.value) }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>
<script>
/* eslint-disable no-param-reassign, no-underscore-dangle */
import getMetrics from '@/services/getmetrics';

import bus from '@/helpers/events/bus';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'CrmSegmentsBlocks',
  components: {
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      dataRequest: [],
      segment: 0,
      segmentGroup: 2,
      active: undefined,
    };
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.setup();
      }
    },
  },
  mounted() {
    bus.$on('render-cards', this.setup);
  },
  methods: {
    createData() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'rfv_matrix_v2',
        segmentGroup: 2,
      })
        .then(dataValues => {
          const series = dataValues.data.metrics.rfv_matrix_v2.data;
          if (this.$_verifyData(series)) return;

          this.dataRequest = [];
          const seriesNames = Object.keys(series);
          seriesNames.forEach(name => {
            if (name && name !== '') {
              this.dataRequest.push({
                name,
                columns: this.formatGroups(series[name]),
              });
            }
          });
          this.calculeHeight(this.dataRequest);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatGroups(columns) {
      const formatedGroups = {};
      columns.forEach(column => {
        const order = column.order.toString().split('');
        // cria indice baseado na ordem da coluna
        if (typeof formatedGroups[order[0]] !== 'object') formatedGroups[order[0]] = [];
        // adiciona bloco dentro da respectiva coluna
        formatedGroups[order[0]].push({
          color: column.color,
          data: column.data,
          name: column.name,
          id: column.segment_id,
          order: order[1],
        });
      });
      return formatedGroups;
    },
    setup(data) {
      // Recover infos by store
      this.segment = this.$store.getters.selectedSegment.segmentId;
      this.segmentGroup = this.$store.getters.selectedSegment.segmentGroupId;

      // If is the blacklist
      if (data && data.blacklist) return;

      this.createData();
    },
    changeSegment(segment, segmentGroup) {
      // If the group it's not change
      if (segmentGroup === this.segmentGroup) {
        // If it's current block
        if (this.segment === segment) {
          this.changeSegmentGroup(1, 1);
        } else {
          bus.$emit('refresh-segment', segment);
        }
      } else {
        this.changeSegmentGroup(segmentGroup, segment);
      }
    },
    changeSegmentGroup(segmentGroup, segment) {
      bus.$emit('refresh-segment-group', segmentGroup, segment);
    },
    calculeHeight(groups) {
      groups.forEach(group => {
        Object.keys(group.columns).forEach(col => {
          let total = 0;
          const column = group.columns[col];
          column.forEach(block => {
            // soma as porcentagens da coluna
            total += block.data.percent.value;
          });
          column.forEach(block => {
            // se existe apenas um bloco na coluna
            if (Object.keys(column).length === 1) return;
            block.height = (block.data.percent.value / total) * 100;
          });
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.crm-segments-blocks {
  .vertical-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 0;
    margin: 34px 0 4px 20px;
    &:after {
      content: '';
      background-color: $gray-500;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 43%;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 16px;
      height: 9px;
      margin: -1px 0 0 -1px;
      background-image: url('../../../assets/img/icons/png/arrow-up.png');
      left: calc(43% - (14px / 2));
    }
    span {
      background-color: $color-white;
      color: $gray-800;
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 10px 0;
      position: relative;
      z-index: 10;
      cursor: default;
    }
  }
  .col-inside,
  .col-outside {
    display: flex;
  }
  .col-outside {
    flex-direction: column;
    max-width: 300px;
    flex-grow: 1;
  }
  .col-inside {
    flex-flow: column;
  }
  .list-groups {
    > .row {
      min-height: 232px;
      .group {
        margin: 0 10px;

        .row-content {
          height: 232px;
          .column {
            display: flex;
            flex-direction: column;
            padding: 0 5px;
            height: 100%;
            .block {
              position: relative;
              padding: 10px;
              display: block;
              margin: 5px 0;
              box-sizing: border-box;
              height: 100%;
              color: $color-white;
              cursor: pointer;
              transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 0.1);
              max-width: 110px;
              & > * {
                transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 0.1);
              }
              > span {
                font-size: 11px;
                line-height: 14px;
                display: block;
                font-weight: 400;
                .percent {
                  font-weight: 600;
                }
              }
              &.bg-gray {
                background-color: $gray-400;
                color: $gray-800;
                font-weight: 100;
                .percent {
                  font-weight: 700;
                }
              }
              &.active {
                color: $color-white;
                background-color: $color-orange !important;
                box-shadow: 0 10px 15px rgba(51, 58, 69, 0.2);
              }
              .block-tooltip {
                position: absolute;
                display: none;
                top: 50%;
                left: calc(100% + 15px);
                right: initial;
                transform: translate(-15px, -50%);
                z-index: 2;
                .content {
                  position: relative;
                  background-color: $color-white;
                  color: $gray-700;
                  padding: 15px;
                  font-size: 12px;
                  line-height: 20px;
                  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
                  .arrow-left {
                    position: absolute;
                    left: -8px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 8px 6px 0;
                    border-color: transparent $color-white transparent transparent;
                  }
                  .title {
                    font-weight: 600;
                    font-size: 11px;
                    text-transform: uppercase;
                  }
                  ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                      display: flex;
                      span {
                        white-space: nowrap;
                      }
                      .value {
                        font-weight: 600;
                        padding: 0 5px;
                      }
                      .percent {
                        color: $gray-600;
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
              &.alone {
                .block-tooltip {
                  bottom: initial;
                  top: 28px;
                }
              }
              &:hover {
                .block-tooltip {
                  display: block;
                }
              }
            }
          }
        }
        .title span {
          font-weight: 600;
          display: block;
          height: 28px;
          color: $gray-900;
          text-transform: uppercase;
          font-size: 13px;
          cursor: default;
          text-align: center;
        }
      }
    }
  }
  &.loadHeight {
    height: 260px;
  }
}
</style>
