<template>
  <div>
    <section :id="`audience-${mode}`" class="audience-include-exclude">
      <h2 v-if="!hasEmptyEntries">{{ $t(`audiences.${mode}`) }}</h2>

      <audience-group
        v-for="(group, index) in groups"
        :id="index"
        :key="index"
        :include="isInclude"
        :series="group"
        :is-editable="isEditable"
      />

      <audience-add-group-button
        v-if="isEditable"
        :title="$t(`audiences.add-group-${mode}`)"
        :action="addGroupInternal"
        :data-cy="`audiences-add-group-${mode}`"
      />

      <p v-if="hasEmptyEntries ? false : !isEditable && !groups.length">
        {{ $t(`audiences.no-${mode}`) }}
      </p>
    </section>
    <section v-if="isEditable && isActiveFromServer">
      <div v-if="mode === 'exclude'">
        <audience-expansion />
      </div>
    </section>
  </div>
</template>

<script>
import AudienceGroup from '@/components/audiences/create/composition/AudienceGroup';
import AudienceAddGroupButton from '@/components/audiences/create/composition/AudienceAddGroupButton';
import AudienceExpansion from '@/components/audiences/lookalike/AudienceExpansion';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'AudiencesIncludeExclude',
  components: {
    'audience-group': AudienceGroup,
    'audience-add-group-button': AudienceAddGroupButton,
    'audience-expansion': AudienceExpansion,
  },
  props: {
    isInclude: Boolean,
  },
  data() {
    return {
      isActiveFromServer: false,
    };
  },
  computed: {
    ...mapGetters('audience', ['isView', 'hasEmptyEntries']), // -> this.isView
    ...mapState('audience', [
      'include', // -> this.include
      'exclude', // -> this.exclude
    ]),
    mode() {
      return this.isInclude ? 'include' : 'exclude';
    },
    isEditable() {
      return !this.isView;
    },
    groups() {
      return this.isInclude ? this.include : this.exclude;
    },
  },
  methods: {
    ...mapActions('audience', [
      'addGroup', // -> this.addGroup()
      'addSerie', // -> this.addSerie()
      'removeGroup', // -> this.removeGroup()
      'removeSerie', // -> this.removeSerie()
    ]),
    addGroupInternal() {
      this.addGroup({ include: this.isInclude });
    },
  },
};
</script>
