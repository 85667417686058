<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body>
    <div class="start-time-error-container">
      <i class="icon icon-alert-60px"></i>
      <p>
        {{ $t('audiences.start-time-error-modal.text') }}
      </p>
      <button class="btn" @click="closeModal">{{ $t('audiences.start-time-error-modal.button') }}</button>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';

export default {
  name: 'AudienceStartTimeErrorModal',
  data() {
    return {
      id: 'audience-start-time-error-modal',
      isVisible: false,
    };
  },
  mounted() {
    bus.$on(`open-${this.id}`, () => {
      this.openModal();
    });
  },
  beforeUnmount() {
    bus.$off(`hide-${this.id}`);
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
#audience-start-time-error-modal {
  max-width: 500px;
  text-align: center;

  .el-dialog__header {
    padding: 10px;
  }

  .el-dialog__body {
    padding: 25px 0;

    .start-time-error-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 43px;

      p {
        margin: 20px 0;
        font-weight: normal;
        font-size: 18px;
      }

      i {
        color: $yellow-500;
      }
    }
  }
}
</style>
