<template>
  <h3 class="d-flex align-items-center">
    <span>{{ this.name }}</span>
    <i
      class="icon icon-question-12px tooltip-metrics"
      v-tooltip="{
        theme: 'default',
        content: this.name,
        distance: 8,
      }"
    ></i>
  </h3>
</template>

<script>
export default {
  name: 'SubtitleSectionBlock',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 12px;
  padding: 17px 0;
  padding-left: 37px;
  background-color: $gray-200;
  border-bottom: 1px solid $gray-400;
  color: $gray-800;
  margin-bottom: 0;
}
.tooltip-metrics {
  color: $gray-500;
  margin-left: 5px;
  font-size: 12px;
}
</style>
