import { BannerModel, RemoteResponse } from '../types';
import { getHomeBanner } from '@/services/home';

export const fetchBanner = () => getHomeBanner().then(({ data }) => formatResponse(data?.data?.[0]));

const formatResponse = (remoteBanner: RemoteResponse): BannerModel => {
  const DEFAULT_LINK = '';
  const DEFAULT_IMAGE =
    'https://firebasestorage.googleapis.com/v0/b/pmweb-firebase.appspot.com/o/oto-analytics%2Fbanner-welcome.png?alt=media';

  return {
    redirectLink: remoteBanner?.href ?? DEFAULT_LINK,
    urlImage: remoteBanner?.image ?? DEFAULT_IMAGE,
    title: remoteBanner?.title ?? 'Oto',
  };
};
