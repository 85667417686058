function getColumnsIsEnabled(columns) {
  return columns.filter(column => column[1].enabled);
}

function getDataFiltered(data) {
  const columnsEnabled = Object.entries(data)
    .map(column => getColumnsIsEnabled(Object.entries(column[1])))
    .map(item => Object.fromEntries(item));

  const result = Object.fromEntries(
    Object.entries(data)
      .map((item, index) => [item[0], columnsEnabled[index]])
      .filter(column => Object.keys(column[1]).length !== 0),
  );

  return result;
}

export { getDataFiltered };
