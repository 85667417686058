<template>
  <lightbox-template id="lightbox-omnichannel-revenue" :title="$t('conversion-sales')" icon="cart">
    <omnichannel-revenue-chart />
    <omnichannel-revenue-table />
  </lightbox-template>
</template>

<script>
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import OmnichannelRevenueChart from '@/components/lightbox/lightboxes/_organisms/omnichannelRevenue/OmnichannelRevenueChart';
import OmnichannelRevenueTable from '@/components/lightbox/lightboxes/_organisms/omnichannelRevenue/OmnichannelRevenueTable';

export default {
  name: 'LightboxOmnichannelRevenue',
  components: {
    LightboxTemplate,
    OmnichannelRevenueChart,
    OmnichannelRevenueTable,
  },
};
</script>

<style lang="scss" scoped>
.loadHeight {
  min-height: 250px;
  position: relative;
}
</style>
