<template>
  <div class="status text-capitalize" :class="status">
    <i v-if="status === 'success'" class="icon icon-check-filled icon-18"></i>
    <i v-if="status === 'error' || status === 'cancelled'" class="icon icon-attention-filled icon-18"></i>
    <i v-if="status === 'scheduled'" class="icon icon-clock-filled-18px"></i>
    <i v-if="status === 'running'" class="icon icon-running-filled-18px"></i>
    {{ $t(`audiences.status.${status}`) }}
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  .icon {
    margin-right: 5px;
  }
  &.scheduled {
    color: $oto-omni;
  }
  &.running {
    color: $color-orange;
  }
  &.error,
  &.cancelled {
    color: $color-red;
  }
  &.success {
    color: $oto-ecommerce;
  }
}
</style>
