import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'clients';

export const getClients = ({ active = '' } = {}) => {
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;
  /* @description requisita lista de clientes
   * @param {id} adm_id id do usuário administrador logado
   * @param {numeral} active apenas clientes ativos(1) ou inativos (0) - o padrão é ambos ('')
   */
  return axios.get(`client/getClients`, {
    method: 'GET',
    params: {
      admId: user.id,
      active,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    baseURL: endpointVersion('v3'),
  });
};

export const getAccounts = () => {
  const { user } = store.getters;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`account/getAccounts`, {
    method: 'GET',
    params: {
      admId: user?.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
    baseURL: endpointVersion('v3'),
  });
};

export const putClient = (name, active, id) => {
  const { user } = store.getters;
  /* @description cria novo cliente
   * @param {id} adm_id id do usuário administrador logado
   * @param {string} client_name nome do novo cliente
   * @param {number} client_enabled indica se o novo cliente estará ativo (1) ou inativo (0)
   */
  return axios.get(`${URI}/put_client`, {
    params: {
      adm_id: user.id,
      client_id: id,
      client_name: name,
      client_enabled: active,
    },
  });
};

export const disableClient = (clientId, active) => {
  const { user } = store.getters;
  /* @description habilita/desabilita cliente
   * @param {id} adm_id id do usuário administrador logado
   * @param {id} client_id id do cliente selecionado
   * @param {number} client_enabled determina se o cliente deve ser ativado (1) ou desativado (0)
   */
  return axios.get(`${URI}/put_client`, {
    params: {
      adm_id: user.id,
      client_id: clientId,
      client_enabled: active,
    },
  });
};
