<template>
  <div id="conversionsalestotalrevenue" :class="{ loadHeight: loading || hasError || !thensIsNotEmpty }" class="card">
    <div v-if="$_verifyLoaded('done')" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-money"></i>
        <span>{{ $t('total-revenue') }}</span>
        <i
          class="icon icon-expand ml-auto"
          data-toggle="modal"
          @click="openLightbox('lightbox-omnichannel-revenue')"
        ></i>
      </h2>

      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 col-lg mb-sm-5">
              <div class="row">
                <div class="col-sm-6 col-xl-12">
                  <label class="chart-title">{{ $t('total-revenue') }}</label>
                  <span class="chart-value-big d-block color-blue-500">{{
                    formatCurrency(dataReturnRevenues.metrics.revenue.data.value.amount)
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg mb-sm-5">
              <div class="row">
                <div class="col-sm-6 col-lg-12">
                  <label class="chart-title">{{ $tc('in-store') }}</label>
                  <span class="chart-value-big d-block color-purple-400">{{
                    formatCurrency(dataReturnRevenues.metrics.revenue_off.data.value.amount)
                  }}</span>
                </div>
                <div class="col-sm-6 d-lg-none">
                  <!-- <minibarchart :prop-data="JsonInStore"></minibarchart> -->
                  <div class="row chart-group">
                    <div class="col-12">
                      <chart-list :chart-name="$tc('in-store')" :rows-list="objectChartListInstore" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg mb-sm-5">
              <div class="row">
                <div class="col-sm-6 col-lg-12">
                  <label class="chart-title">{{ $t('ecommerce') }}</label>
                  <span class="chart-value-big d-block color-oto-ecommerce">{{
                    formatCurrency(dataReturnRevenues.metrics.revenue_on.data.value.amount)
                  }}</span>
                </div>
                <div class="col-sm-6 d-lg-none">
                  <!-- <minibarchart :prop-data="JsonEcommerce"></minibarchart> -->
                  <div class="row chart-group">
                    <div class="col-12">
                      <chart-list :chart-name="$t('ecommerce')" :rows-list="objectChartListEcommerce" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showApp" class="col-sm-12 col-lg mb-sm-5">
              <div class="row">
                <div class="col-sm-6 col-lg-12">
                  <label class="chart-title">{{ $t('mobile') }}</label>
                  <span class="chart-value-big d-block color-blue-400">{{
                    formatCurrency(dataReturnRevenues.metrics.revenue_app.data.value.amount)
                  }}</span>
                </div>
                <div class="col-sm-6 d-lg-none">
                  <!-- <minibarchart :prop-data="JsonApp"></minibarchart> -->
                  <div class="row chart-group">
                    <div class="col-12">
                      <chart-list :chart-name="$t('mobile')" :rows-list="objectChartListApp" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-sm-none d-lg-block">
          <div class="row">
            <div :class="showApp ? 'col-4' : 'col-5'">
              <chart-list :chart-name="$tc('in-store')" :rows-list="objectChartListInstore" />
              <!-- <minibarchart :prop-data="JsonInStore"></minibarchart> -->
            </div>
            <div :class="showApp ? 'col-4' : 'col-5'">
              <chart-list :chart-name="$t('ecommerce')" :rows-list="objectChartListEcommerce" />
              <!-- <minibarchart :prop-data="JsonEcommerce"></minibarchart> -->
            </div>
            <div v-if="showApp" class="col-4">
              <chart-list :chart-name="$t('mobile')" :rows-list="objectChartListApp" />
              <!-- <minibarchart :prop-data="JsonApp"></minibarchart> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// @ is an alias to /src
import _ from 'lodash';
import getMetrics from '@/services/getmetrics';
import { openLightbox } from '@/helpers/lightbox';
import { getPercentual, getMax } from '@/utilities/numbers';
import { formatPercent } from '@/utilities/formatters';
import ChartList from '@/components/_organisms/ChartList';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, numberMixin, verifyThensMixin } from '@/helpers/mixins';

export default {
  name: 'ConversionSalesTotalRevenue',
  components: {
    'chart-list': ChartList,
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, verifyThensMixin, numberMixin],
  data() {
    return {
      loader: {
        loadingRevenue: true,
        loadingEcommerce: false,
        loadingApp: false,
        loadingInStore: false,
      },
      dataReturnOn: {},
      dataReturnRevenues: {},
      dataReturnOff: {},
      dataReturnApp: {},
      showApp: false,
      objectChartListInstore: [],
      objectChartListEcommerce: [],
      objectChartListApp: [],
    };
  },
  computed: {
    totalamountOn() {
      const datavalue = this.dataReturnOn;
      const total = Object.values(datavalue).reduce((t, n) => parseInt(t, 10) + parseInt(n, 10));
      return total;
    },
    totalamountApp() {
      const datavalue = this.dataReturnApp;
      const total = Object.values(datavalue).reduce((t, n) => parseInt(t, 10) + parseInt(n, 10));
      return total;
    },
    totalamountOff() {
      const datavalue = this.dataReturnOff;
      const total = Object.values(datavalue).reduce((t, n) => parseInt(t, 10) + parseInt(n, 10));
      return total;
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();
      this.loadingRevenue = true;
      this.loadingEcommerce = true;
      this.loadingInStore = true;

      getMetrics({
        metrics: 'revenue,revenue_on,revenue_off,revenue_app',
        version: 2,
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          this.dataReturnRevenues = response.data;
          this.showApp = this.dataReturnRevenues.metrics.revenue_app.has_data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingRevenue = false;
          this.$_verifLoading();
        });

      // E-commerce
      getMetrics({
        metrics: 'revenue_by_medium',
        filters: 'store_type:on',
        version: 2,
        topvalues: '4_',
      })
        .then(response => {
          this.dataReturnOn = response.data.metrics.revenue_by_medium.data.series[0].data;
          // Chart: Receita Total - Ecommerce
          this.objectChartListEcommerce = [];
          const max = getMax(this.dataReturnOn);
          const seriesDesc = _(this.dataReturnOn).toPairs().orderBy(1, 'desc').fromPairs().value();
          _.each(seriesDesc, (val, key) => {
            this.objectChartListEcommerce.push({
              label: key,
              progress: String(getPercentual(val, max)),
              value: formatPercent(val / this.totalamountOn),
              color: 'green',
            });
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingEcommerce = false;
          this.$_verifLoading();
        });
      // App
      if (this.showApp) {
        getMetrics({
          metrics: 'revenue_by_medium',
          filters: 'store_type:app',
          version: 2,
          topvalues: '4_',
        })
          .then(response => {
            this.dataReturnApp = response.data.metrics.revenue_by_medium.data.series[0].data;
            // Chart: Receita Total - App
            this.objectChartListApp = [];
            const max = getMax(..._.values(this.dataReturnApp));
            const seriesDesc = _(this.dataReturnApp).toPairs().orderBy(1, 'desc').fromPairs().value();
            _.each(seriesDesc, (val, key) => {
              this.objectChartListApp.push({
                label: key,
                progress: String(getPercentual(val, max)),
                value: formatPercent(val / this.totalamountApp),
              });
            });
          })
          .catch(() => {
            this.hasError = true;
            this.$_componentHeight();
          })
          .finally(() => {
            this.loadingApp = false;
            this.$_verifLoading();
          });
      }
      // Off
      getMetrics({
        metrics: 'revenue_by_medium',
        filters: 'store_type:off',
        version: 2,
        topvalues: '4_',
      })
        .then(response => {
          this.dataReturnOff = response.data.metrics.revenue_by_medium.data.series[0].data;
          // Chart: Receita Total - Instore
          this.objectChartListInstore = [];
          const max = getMax(this.dataReturnOff);
          const seriesDesc = _(this.dataReturnOff).toPairs().orderBy(1, 'desc').fromPairs().value();
          _.each(seriesDesc, (val, key) => {
            this.objectChartListInstore.push({
              label: key,
              progress: String(getPercentual(val, max)),
              value: formatPercent(val / this.totalamountOff),
              color: 'darkpurple',
            });
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loadingInStore = false;
          this.$_verifLoading();
        });
    },
    openLightbox(id) {
      openLightbox(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @media (min-width: $xl-min-width) {
    min-height: 318px;
  }
}
</style>
