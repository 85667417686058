<template>
  <div id="crm-segments-form">
    <div class="row">
      <div class="col-xl-12 col-sm-4 form">
        <label for="crm-segments-form-select">{{ $t('visualize-customers-by') }}</label>
        <select id="crm-segments-form-select">
          <option>{{ $t('rfm-select-option') }}</option>
        </select>
      </div>
      <div class="col-xl-12 col-sm-8">
        <p>{{ $t('rfm-text1') }}</p>
        <p>{{ $t('rfm-text2') }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#crm-segments-form {
  padding: 30px 50px 0 0;

  .form {
    margin-bottom: 20px;

    @media (max-width: $tablet-max-width) {
      padding-right: 20px;
    }

    select {
      width: 100%;
    }
  }
}
</style>
