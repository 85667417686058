<template>
  <div class="card indicator">
    <div v-if="!loading && !isEmpty && !hasError && metric">
      <div class="chart-title title">{{ metric.title }}</div>
      <div class="chart-value-big color-oto-brand-omni value amount">
        {{ getFormattedAmount(metric.data.value.amount) }}
      </div>
    </div>
    <load-spinner v-if="loading && !isEmpty && !hasError" :height="'45'" />
    <info-card v-show="isEmpty || hasError" ref="message-error" :height="'45px'" :error="hasError" :show-msg="false" />
  </div>
</template>

<script>
import InfoCard from '@/components/_atoms/InfoCard';
import LoadSpinner from '@/components/_atoms/BaseLoaderSpinner';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  components: {
    LoadSpinner,
    InfoCard,
  },
  props: {
    metric: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formattedAmount: '',
      threshold: 1099999,
      daysAverage: this.$t('days-average'),
    };
  },
  methods: {
    formattedCurrency(amount) {
      return amount > this.threshold ? formatCurrency(amount, { shortNumber: true }) : formatCurrency(amount);
    },
    getFormattedAmount(amount) {
      const metricType = this.metric.data.value.type;
      if (metricType === valueType.decimal) {
        this.formattedAmount = this.formattedCurrency(amount);
      } else if (metricType === valueType.float) {
        this.formattedAmount = `${formatDecimal(amount, 2)} ${this.daysAverage}`;
      } else if (metricType === valueType.integer) {
        this.formattedAmount = formatDecimal(amount, 1);
      } else if (metricType === valueType.percent) {
        this.formattedAmount = formatPercent(amount / 100, 2);
      }

      return this.formattedAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 6px;
  line-height: 24px;
}
.amount {
  text-align: center;
}
.indicator {
  align-items: center;
}
.message-error img {
  margin-top: 20%;
}
</style>
