<template>
  <div class="input-search">
    <div class="form-wrapper">
      <input
        ref="searchInput"
        v-model="term"
        type="text"
        v-focus
        class="input-text-search"
        :placeholder="$t('search.placeholder')"
        @keypress.enter="$_fullSearch(term)"
        @input="isTyping = true"
      />
      <i class="icon icon-search" @click="iconSearch"></i>
    </div>

    <search-suggestion :term="term" :results="products" :active="suggestion" />
  </div>
</template>
<script>
import _ from 'lodash';
import SearchSuggestion from '@/components/products/search/SearchSuggestion';
import productsMixin from '@/helpers/mixins/productsMixin';
import getProducts from '@/services/product';
import bus from '@/helpers/events/bus';

export default {
  components: {
    'search-suggestion': SearchSuggestion,
  },
  mixins: [productsMixin],
  data() {
    return {
      term: undefined,
      products: [],
      isTyping: false,
      suggestion: false,
    };
  },
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  watch: {
    term: _.debounce(function TS() {
      this.isTyping = false;
    }, 500),
    isTyping: function isT(value) {
      if (this.term === '') {
        this.clear();
      }
      if (!value && this.term !== undefined) {
        this.search();
      }
    },
  },
  beforeUnmount() {
    bus.$off('clear-search');
    bus.$off('focus-search');
  },
  mounted() {
    this.closeSuggestions();
    bus.$on('clear-search', this.clear);
    bus.$on('focus-search', this.focus);
  },
  methods: {
    search() {
      this.suggestion = true;
      bus.$emit('show-spinner', 'suggestion-loader');
      getProducts({ term: this.term })
        .then(({ data }) => {
          this.products = data.data;

          bus.$emit('hide-spinner', 'suggestion-loader');
        })
        .catch(() => {
          this.products = [];
        });
    },
    closeSuggestions() {
      document.body.addEventListener('click', e => {
        // hide suggestions on click out of search input
        if (this.suggestion && e.target.classList.value !== 'input-text-search') {
          this.suggestion = false;
        }
      });
    },
    clear() {
      this.term = undefined;
      this.products = [];
      this.suggestion = false;
    },
    focus() {
      this.$refs.searchInput.focus();
    },
    iconSearch() {
      if (this.term !== undefined) {
        this.$_fullSearch(this.term);
      } else {
        this.$refs.searchInput.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-search {
  position: relative;
  width: 450px;
  z-index: 20;

  .form-wrapper {
    position: relative;
    z-index: 1;

    .input-text-search {
      width: 100%;
      height: 44px;
    }

    .icon-search {
      color: $gray-500;
      position: absolute;
      top: 6px;
      right: 8px;
      cursor: pointer;
    }
  }
}
</style>
