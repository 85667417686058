<template>
  <div class="container loadHeight mb-4">
    <template v-if="$_verifyLoaded('done')">
      <header class="d-flex align-items-center mb-3">
        <h5 class="modal-subtitle">{{ $t('people') }}</h5>
        <span class="segment-label">{{ nameSelect }}</span>
      </header>
      <lightbox-template-chart v-if="true" :settings="people" />
    </template>
    <base-loader v-show="$_verifyLoaded('loading')" :height="'45'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import moment from 'moment';
import getMetrics from '@/services/getmetrics';
import { lightboxesMixin, verifyMixin } from '@/helpers/mixins';
import { dateFormat } from '@/utilities/constants';
import { formatNumber } from '@/utilities/formatters';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'PeopleChartWrapper',
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin],
  props: {
    nameSelect: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      people: [],
    };
  },
  mounted() {
    this.reset();
    this.loadClients();
  },
  methods: {
    reset() {
      this.$_reqConfig();
      this.people = [];
    },
    loadClients() {
      getMetrics({
        metrics: ['leads_not_converted', 'leads_potential', 'customers_total', 'leads_lost'],
        expand: 1,
        isLightbox: true,
      })
        .then(response => {
          const { data } = response.data;
          if (this.$_verifyData(data.metrics)) return;

          const { metrics } = data;
          const notConverted = metrics.leads_not_converted;
          const potential = metrics.leads_potential;
          const customersTotal = metrics.customers_total;
          const lost = metrics.leads_lost;

          let { labels } = data;
          labels = this.format({ labels, formatTo: 'MMM/YY' });

          const date = {
            start: moment(this.$store.getters.dates.startDate, dateFormat),
            end: moment(this.$store.getters.dates.endDate, dateFormat),
            now: moment(new Date()),
          };

          const months = date.end.diff(date.start, 'month');

          if (date.end.month() === date.now.month()) {
            labels = labels.slice(0, -1);
            labels[labels.length] = this.$t('current-month');
          }

          // Carrega o gráfico
          this.loadDashPeople(
            notConverted,
            potential,
            customersTotal,
            lost,
            labels,
            labels.length - 0.5,
            labels.length - months - 1.5,
          );
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadDashPeople(
      notConverted = [],
      potential = [],
      customersTotal = [],
      lost = [],
      labels = [],
      minSelect,
      maxSelect,
    ) {
      this.people = {
        chart: {
          type: 'areaspline',
          height: 280,
        },
        title: {
          text: null,
        },
        legend: {
          itemMarginTop: -30,
          itemMarginBottom: 25,
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemStyle: {
            color: 'grey',
            fontWeight: 600,
          },
        },
        xAxis: {
          allowDecimals: false,
          lineWidth: 0,
          categories: labels,
          plotBands: [
            {
              from: minSelect,
              to: maxSelect,
              color: 'rgba(68, 170, 213, .2)',
            },
          ],
        },
        yAxis: {
          title: {
            text: null,
          },
          lineWidth: 0,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          formatter: function formater() {
            let s = `<b>${this.x}</b>`;

            this.points.forEach(points => {
              s += `<br/><span style="color:${points.color}">\u25CF</span> ${points.series.name}: <b>${formatNumber(
                points.y,
                '0a',
              )}</b>`;
            });
            return s;
          },
        },
        plotOptions: {
          area: {
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
          series: {
            // remove os pontos do hover do grafico
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                },
              },
            },
            lineWidth: 0,
            fillOpacity: 0.8,
          },
        },
        series: [
          {
            name: this.$t('lost-leads'),
            color: '#36AAD6',
            data: lost,
          },
          {
            name: this.$t('not-converted-leads'),
            color: '#FA6F32',
            data: notConverted,
          },
          {
            name: this.$t('potential-leads'),
            color: '#EEDA36',
            data: potential,
          },
          {
            name: this.$tc('total-clients', 0),
            color: '#EC3942',
            data: customersTotal,
          },
        ],
      };
    },
  },
};
</script>
