<template>
  <div class="cashback__details">
    <!-- expire days -->
    <div class="form-group">
      <div class="cd__expiration-balance">
        <label>{{ $t('360.cashback.balance-expiration') }}</label>
        <div class="cd__expiration-block">
          <input
            v-mask="['###']"
            class="cd__expiration-input"
            type="text"
            :disabled="!getPermissionCreate"
            :value="cashbackDetails.expireDays"
            @blur="onChangeExpiration"
          />
          <p class="m-0 text-lowercase text-semibold">{{ $tc('_day', 2) }}</p>
        </div>
      </div>
    </div>
    <!-- credit days -->
    <div class="form-group">
      <div class="cd__crediting-time">
        <label>{{ $t('360.cashback.crediting-time') }}</label>
        <div class="cd__crediting-block">
          <input
            v-mask="['###']"
            :disabled="!getPermissionCreate"
            class="cd__crediting-input"
            type="text"
            :value="cashbackDetails.creditDays"
            @blur="onChangeCrediting"
          />
          <p class="m-0 text-lowercase text-semibold">{{ $tc('_day', 2) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// libs
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'CashbackDetails',
  components: {},
  computed: {
    ...mapState('cashback', ['cashbackDetails']),
    ...mapGetters('cashback', ['getPermissionCreate']),
  },
  methods: {
    ...mapActions('cashback', ['setExpireDays', 'setCreditDays']),

    onChangeExpiration(e) {
      this.setExpireDays(e.target.value);
    },

    onChangeCrediting(e) {
      this.setCreditDays(e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback__details {
  display: flex;

  .cd__expiration-balance,
  .cd__crediting-time {
    width: 208px;

    .cd__expiration-block,
    .cd__crediting-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cd__expiration-input,
      .cd__crediting-input {
        width: 170px;
      }
    }
  }

  .cd__crediting-time {
    margin-left: 20px;
  }
}
</style>
