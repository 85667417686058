<template>
  <div id="subcategories-skeleton" class="table-list col-12" :data="data" :type="type" :loading="true">
    <table-list-header>
      <table-list-column class-name="col text-left p-0">
        <skeleton-loader width="63px" height="14px" />
      </table-list-column>
      <table-list-column class-name="text-right col-3">
        <skeleton-loader width="175px" height="14px" />
      </table-list-column>
      <table-list-column class-name="text-right col-2">
        <skeleton-loader width="133px" height="14px" />
      </table-list-column>
      <table-list-column class-name="text-right col-1">
        <skeleton-loader width="90px" height="14px" />
      </table-list-column>
      <table-list-column class-name="text-right col-2">
        <skeleton-loader width="141px" height="14px" />
      </table-list-column>
      <table-list-column class-name="text-right col">
        <skeleton-loader width="67px" height="14px" />
      </table-list-column>
      <table-list-column class-name="text-right col">
        <skeleton-loader width="102px" height="14px" />
      </table-list-column>
    </table-list-header>
    <table-list-body>
      <table-list-row v-for="row in rows" :key="row">
        <table-list-column class-name="text-left col pl-0 pr-0">
          <skeleton-loader width="95px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col-3">
          <skeleton-loader width="40px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col-2">
          <skeleton-loader width="62px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col-1">
          <skeleton-loader width="32px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col-2">
          <skeleton-loader width="62px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col">
          <skeleton-loader width="35px" height="14px" />
        </table-list-column>
        <table-list-column class-name="text-right col">
          <skeleton-loader width="85px" height="14px" />
        </table-list-column>
      </table-list-row>
    </table-list-body>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    rows() {
      const length = this.data.limit;
      const arr = new Array(length);

      for (let i = 0; i < length; i += 1) {
        arr[i] = (i + 1).toString();
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin: 40px 0 25px;
}
</style>
