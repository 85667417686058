import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2994180e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "raw-data-access-filter" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "rdaf__add-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_base_card_v2 = _resolveComponent("base-card-v2")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_card_v2, null, {
      default: _withCtx(() => [
        _createElementVNode("section", _hoisted_1, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('filter-results')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayOfFilters, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "rdaf__fields",
              key: index
            }, [
              _createElementVNode("div", null, [
                _createVNode(_component_v_select, {
                  modelValue: item.column,
                  "onUpdate:modelValue": ($event: any) => ((item.column) = $event),
                  label: "name",
                  reduce: item => item.key,
                  clearable: false,
                  options: _ctx.columnsOptions,
                  loading: _ctx.isLoading,
                  placeholder: _ctx.$t('select'),
                  "onOption:selected": ($event: any) => (_ctx.resetCurrentFieldsIfSelectedColumnChanged(index))
                }, {
                  "open-indicator": _withCtx(({ attributes }) => [
                    _createElementVNode("span", _mergeProps(attributes, { class: "arrow-down" }), null, 16)
                  ]),
                  "no-options": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('_no-results')), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "reduce", "options", "loading", "placeholder", "onOption:selected"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_v_select, {
                  modelValue: item.operator,
                  "onUpdate:modelValue": ($event: any) => ((item.operator) = $event),
                  label: "name",
                  reduce: item => item.key,
                  clearable: false,
                  options: _ctx.showOperatorByColumn(item.column),
                  placeholder: _ctx.$t('select')
                }, {
                  "open-indicator": _withCtx(({ attributes }) => [
                    _createElementVNode("span", _mergeProps(attributes, { class: "arrow-down" }), null, 16)
                  ]),
                  "no-options": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('_no-results')), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "reduce", "options", "placeholder"])
              ]),
              _withDirectives(_createElementVNode("div", null, [
                (!_ctx.verifyIfLastFieldHasOptions(item.column))
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 0,
                      "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                      type: "text"
                    }, null, 8, _hoisted_2)), [
                      [_vModelText, item.value]
                    ])
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_v_select, {
                        modelValue: item.value,
                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                        label: "name",
                        reduce: item => item.key,
                        clearable: false,
                        options: _ctx.showOptionsByColumn(item.column),
                        placeholder: _ctx.$t('select')
                      }, {
                        "open-indicator": _withCtx(({ attributes }) => [
                          _createElementVNode("span", _mergeProps(attributes, { class: "arrow-down" }), null, 16)
                        ]),
                        "no-options": _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('_no-results')), 1)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "reduce", "options", "placeholder"])
                    ]))
              ], 512), [
                [_vShow, !_ctx.isShowingLastField(item.operator)]
              ]),
              _createElementVNode("i", {
                class: "icon icon-close",
                onClick: _withModifiers(($event: any) => (_ctx.removeFilter(index)), ["prevent"])
              }, null, 8, _hoisted_4)
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.addFilter()), ["prevent"]))
            }, _toDisplayString(_ctx.$t('filter-add')), 1),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.applyFilter && _ctx.applyFilter(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$t('filter-apply')), 1)
          ])
        ])
      ]),
      _: 1
    })
  ], 512)), [
    [_vShow, _ctx.getIsFilterOpen]
  ])
}