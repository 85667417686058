<template>
  <div v-if="true" class="row content-group b-bottom">
    <value-atom v-for="(item, key) in dataset" :key="key">
      <template v-slot:title-header-content>
        <span>
          <label class="header-content-title">{{ $t(`nps.${key}`) }}</label>
        </span>
      </template>
      <template v-slot:header-content>
        <span class="cl-header-content" :class="subclass(key)">{{ getNumeralType(item) }}</span>
      </template>
      <template v-slot:little-percent>
        <div v-if="item.variation" class="tooltip-metrics-group">
          <strong class="cl-little-percent" :class="getVariationDirection(item)">{{ getVariationNumber(item) }}</strong>
          <span v-if="getVariationDirection(item) === 'none'" class="tooltip-metrics">
            <i
              class="icon icon-question-12px"
              v-tooltip="{
                theme: 'default',
                content: $t('cdp.tooltip'),
                distance: 8,
              }"
            ></i>
          </span>
        </div>
      </template>
    </value-atom>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';

import { getMetrics } from '@/services/nps/campaigns';
import ValueAtom from '@/components/cdp/_molecules/_atoms/ValueAtom';
import { formatCurrency, formatDecimal, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  name: 'EmailMetrics',
  components: {
    'value-atom': ValueAtom,
  },
  data() {
    return {
      dataset: {},
    };
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  methods: {
    subclass(key) {
      if (key == 'nps') {
        return 'blue';
      }
    },
    fetchData() {
      getMetrics('sent,opens,clicks').then(res => {
        this.dataset = Object.fromEntries(
          Object.entries(res.metrics).map(([key, item]) => {
            const { data } = item;
            const { type, amount, variation } = data;
            return [key, { type, variation, value: amount }];
          }),
        );
      });
    },
    resolveMillion(item) {
      return formatCurrency(item.value);
    },
    isMillion(item) {
      return item.type === valueType.float && item.value >= 1000000;
    },
    getNumeralType(item) {
      let value = 0;
      if (item.type === valueType.float && item.value < 1000000) {
        value = formatCurrency(item.value);
      } else if (item.type === valueType.float && item.value >= 1000000) {
        value = formatCurrency(item.value, { shortNumber: true });
      } else if (item.type === valueType.integer) {
        value = formatDecimal(item.value, 1);
      } else if (item.type === valueType.percent) {
        value = formatNumber(item.value * 100, '0,0%').replace(',', '.');
      }
      return value;
    },
    getSign(item) {
      if (item > 0) {
        return '+';
      }
      return '';
    },
    getVariationNumber(item) {
      let variationType = '';
      if (item.variation === null) {
        variationType = 'N/A';
      } else {
        return this.getSign(item.variation) + formatNumber(item.variation, '% 0.00');
      }
      return variationType;
    },
    getVariationDirection(metric) {
      const moreIsBetter = metric.positive === 1;
      const typeOfVariation = metric.variation;
      const isPositiveVariation = metric.variation >= 0;
      if (typeOfVariation === null) {
        return 'none';
      }
      if (moreIsBetter) {
        return 'green';
      }
      if (!isPositiveVariation || !moreIsBetter) {
        return 'red';
      }
      return 'green';
    },
    colorHeaderContent() {
      let colorValue = '';
      switch (this.cardName) {
        case 'app':
          colorValue = 'blue';
          break;
        case 'ecommerce':
          colorValue = 'purple';
          break;
        case 'email':
          colorValue = 'blue';
          break;
        case 'sms':
          colorValue = 'blue';
          break;
        case 'cdp-in-store':
          colorValue = 'blue';
          break;
        case 'google-user-id-sync':
          colorValue = 'purple';
          break;
        case 'push':
          colorValue = 'blue';
          break;
        case 'rockin-tags':
          colorValue = 'purple';
          break;
        case 'sac':
          colorValue = 'blue';
          break;
        default:
          colorValue = '';
      }
      return colorValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-content-title {
  font-size: 11px;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 20px;
}
.content-group {
  margin: 0 auto;
}
.b-bottomx {
  border-bottom: $gray-400 solid 1px;
}
.cl-little-percent {
  &.red {
    color: $color-red;
  }
  &.green {
    color: $oto-ecommerce;
  }
  &.none {
    color: $gray-500;
  }
}
.cl-header-content {
  cursor: default;
  &.blue {
    color: $purple-200;
  }
  &.purple {
    color: $oto-ecommerce;
  }
}
.tooltip-metrics-group {
  display: inline-flex;
  .tooltip-metrics {
    color: $gray-500;
    padding: 1.5px 0 0 4px;

    i {
      font-size: 12px;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
