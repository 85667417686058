<template>
  <section v-if="false" id="audience-expansion">
    <div class="d-flex pb-3">
      <check-box :checked="isLookALikeActive" :disabled="!countPeopleLookALike" :click-option="updateLookALikeStatus" />
      <h2 class="pl-2 m-0">{{ $t('audiences.expansions.title') }}</h2>
      <i
        class="icon icon-question-12px tooltip-lookalike"
        v-tooltip.right="{
          theme: 'error',
          content: $t('audiences.expansions.tooltip'),
          distance: 8,
          html: true,
          triggers: ['hover'],
        }"
      ></i>
    </div>
    <div class="expansion-wrapper">
      <expansion-wall v-show="!isLookALikeActive" />
      <expansion-options :obj-expansion="lookALikeData" :is-expansion-active="isLookALikeActive" />
      <load-spinner id="lookalike-loader" color="white" :text="spinnerText" />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLookALike } from '@/services/audiences';
import CheckBox from '@/components/_atoms/CheckBox';
import ExpansionWall from '@/components/audiences/lookalike/AudienceExpansionWall';
import ExpansionOptions from '@/components/audiences/lookalike/AudienceExpansionOptions';
import LoadSpinner from '@/components/_atoms/LoadSpinner';
import bus from '@/helpers/events/bus';

export default {
  name: 'AudienceExpansion',
  components: {
    CheckBox,
    ExpansionWall,
    ExpansionOptions,
    LoadSpinner,
  },
  props: {
    activation: Boolean,
  },
  data() {
    return {
      spinnerText: this.$t('audiences.expansions.loading'),
      timeInterval: 0,
    };
  },
  computed: {
    ...mapState('audience', [
      'isLookALikeActive', // -> this.isLookALikeActive
      'token', // -> this.token
      'lookALikeData',
    ]),
    ...mapGetters('audience', [
      'countPeopleLookALike', // -> this.countPeopleLookALike
    ]),
  },
  watch: {
    /**
     * @param {boolean} isActive
     */
    isLookALikeActive(isActive) {
      if (isActive) {
        bus.$emit('show-spinner', 'lookalike-loader');
        this.fetchData();
      } else {
        this.updateLookALikeData({});
        bus.$emit('hide-spinner', 'lookalike-loader');
        clearInterval(this.timeInterval);
      }
    },
  },
  methods: {
    fetchData() {
      if (this.isLookALikeActive) {
        this.triggerInterval();
      }
    },
    triggerInterval() {
      this.timeInterval = setInterval(() => {
        this.getExpansionData();
      }, 5000);
    },
    getExpansionData() {
      getLookALike(this.token).then(({ data }) => {
        if (data.data.status === 'done') {
          const getData = data.data.data;
          this.updateLookALikeData(getData);
          bus.$emit('hide-spinner', 'lookalike-loader');
          clearInterval(this.timeInterval);
        }
        if (data.data.status === 'error') {
          bus.$emit('hide-spinner', 'lookalike-loader');
          bus.$emit('audience-expansion-error');
          this.updateLookALikeStatus();
          clearInterval(this.timeInterval);
        }
      });
    },
    ...mapActions('audience', [
      'updateLookALikeStatus', // -> this.updateLookALikeStatus()
      'updateLookALikeData',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.expansion-wrapper {
  position: relative;
}

.tooltip-lookalike {
  font-size: 16px;
  margin: 2px 0 0 6px;
  color: $gray-500;
}
</style>
