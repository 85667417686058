<template>
  <div class="products-list">
    <header class="title-area">
      <button class="icon icon-back-arrow" @click="back"></button>
      <h1 class="card-group-title" v-html="$t('search.products-with-term', { term: searchTerm })"></h1>
    </header>

    <search-fixed />

    <search-full-results />
  </div>
</template>
<script>
import SearchFullResults from '@/components/products/search/SearchFullResults';
import SearchFixed from '@/components/products/search/SearchFixed';
import bus from '@/helpers/events/bus';

export default {
  components: {
    SearchFixed,
    SearchFullResults,
  },
  data() {
    return {
      searchTerm: this.$route.query.term,
    };
  },
  watch: {
    '$route.query.term': {
      handler(value) {
        this.searchTerm = value;
        bus.$emit('set-search-term', this.searchTerm);
      },
      deep: true,
    },
  },
  mounted() {
    bus.$emit('set-search-term', this.searchTerm);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.title-area {
  display: flex;
  align-items: center;
  margin: 20px 0 40px;

  > button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $gray-600;
    padding-left: 0;
  }

  .card-group-title {
    margin: 0;
  }
  strong {
    font-weight: 600;
  }
}
</style>
