<template>
  <div v-show="show" class="content" :class="color">
    <div class="spinner">
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="length" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
      <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="28"></circle>
      </svg>
    </div>
    <p v-if="text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'LoadSpinner',
  props: {
    show: {
      type: Boolean,
      default: null,
    },
    color: {
      type: String,
      default: 'white',
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  p {
    color: $color-white;
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
  }
  &:before {
    content: '';
    background-color: $blue-800;
    opacity: 0.85;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &.white {
    position: absolute;
    z-index: 98;
    &:before {
      background-color: $color-white;
      opacity: 1;
      border-radius: 5px;
    }
    p {
      color: $gray-800;
    }
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

svg {
  display: block;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.spinner-type {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spinner {
  width: 66px;
  height: 66px;
  animation: contanim 2s linear infinite;
}

$colors-white: #40467b, #a167d9, #ef4c8b, #4084cb;
$colors-purple: #4084cb, #a167d9, #ef4c8b, #ffffff;
$d: 175.6449737548828;

svg {
  width: 100%;
  height: 100%;

  left: 0;
  top: 0;
  position: absolute;

  transform: rotate(-90deg);

  @for $i from 1 through 4 {
    &:nth-child(#{$i}) circle {
      stroke: nth($colors-purple, $i);
      stroke-dasharray: 1, 300;
      stroke-dashoffset: 0;

      animation: strokeanim 3s calc(0.2s * (#{$i})) ease infinite;
      transform-origin: center center;
    }
  }
}
.white {
  svg {
    @for $i from 1 through 4 {
      &:nth-child(#{$i}) circle {
        stroke: nth($colors-white, $i);
      }
    }
  }
}
@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -$d / 3;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -$d;
  }
}

@keyframes contanim {
  100% {
    transform: rotate(360deg);
  }
}
</style>
