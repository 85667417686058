<template>
  <label @click.prevent="resolveWhenDisabled()">
    <div
      :class="[
        'check-box icon no-click',
        { checked: checked },
        { disabled: disabled },
        { 'icon-check': !blocked },
        { 'border-color': borderColor },
      ]"
      aria-hidden="true"
      data-cy="checkbox-component"
    ></div>
    <span v-if="label" class="a-cb__text m-0 ml-2">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    checked: {
      type: [Boolean, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    clickOption: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: null,
    },
    borderColor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    resolveWhenDisabled() {
      if (this.disabled) return false;
      return this.clickOption();
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: flex;
  align-items: center;
  font-weight: normal;
}
.check-box {
  border: 1px solid $gray-500;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease, border-color 0.1s ease, font-size 0.2s ease;
  font-size: 0;
  cursor: pointer;
  &.checked {
    font-size: 14px;
    background-color: $oto-omni;
    border-color: $oto-omni;
  }
  &.disabled {
    font-size: 14px;
    background-color: gray;
    border-color: gray;
  }
  &.blocked {
    font-size: 14px;
    opacity: 0.3;
    border: 1px solid $gray-500;
    background-color: $color-white;
    ::before {
      content: '';
    }
  }
  &.border-color {
    border-color: $blue-500;
  }
}
</style>
