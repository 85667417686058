<template>
  <div v-if="isEmpty" id="custom-fields-empty-state" class="custom-fields-empty-state">
    <empty-state
      :title="$t('settings.custom-fields.empty-state-title')"
      :text="$t('settings.custom-fields.empty-state-text')"
      :has-button="true"
      :button-text="$t('settings.custom-fields.button-empty-state')"
      :exec-function="openModal"
    >
      <template v-slot:image>
        <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" />
      </template>
    </empty-state>
  </div>
</template>
<script>
// libs
import { mapGetters, mapActions } from 'vuex';
import bus from '@/helpers/events/bus';
// components
import EmptyState from '@/components/_atoms/EmptyState';

export default {
  name: 'CustomFieldsEmptyState',
  components: {
    EmptyState,
  },
  props: {
    isEmpty: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('customFields', ['getActiveTable']),
  },
  methods: {
    ...mapActions('customFields', ['setReset']),

    openModal() {
      this.setReset();
      bus.$emit('open-custom-fields-modal');
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-fields-empty-state {
  width: 80%;
  margin: 0 auto;
}
</style>
<style lang="scss">
#custom-fields-empty-state {
  .container {
    margin-top: 64px;
  }
  .esc__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 390px;
    h2 {
      line-height: 22px;
      margin-bottom: 8px;
    }
    p {
      width: 340px;
    }
    button {
      width: 178px;
      padding: 10px;
      line-height: unset;
      margin-top: 0;
    }
  }
}
</style>
