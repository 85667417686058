<template>
  <div>
    <skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <table-list class="product-who-bought" :data="data" :type="type" :empty="!data.total_count">
      <table-list-header v-show="$_verifyLoaded('done')">
        <table-list-column v-for="item in data.manualSeries" :key="item.key" :class="columnClass(item.key)">
          <span v-html="item.name"></span>
        </table-list-column>
      </table-list-header>
      <table-list-body :remove-last-line="true">
        <template v-if="$_verifyLoaded('done')">
          <table-list-row v-for="(item, index) in data.rows" :key="index">
            <table-list-column class="col-6 capitalize">{{ item.name }}</table-list-column>
            <table-list-column class="col-2 text-right">
              {{ $_formatDecimal(item.boughtOn, 3) }}
              <span class="color-gray-700 text-semibold">({{ $_formatNumber(item.percentBoughtOn, '0.00%') }})</span>
            </table-list-column>
            <table-list-column class="col-2 text-right">
              {{ $_formatDecimal(item.boughtOff, 3) }}
              <span class="color-gray-700 text-semibold">({{ $_formatNumber(item.percentBoughtOff, '0.00%') }})</span>
            </table-list-column>
            <table-list-column class="col-2 text-right">
              {{ $_formatDecimal(item.boughtTotal, 3) }}
              <span class="color-gray-700 text-semibold">({{ $_formatNumber(item.percentBoughtTotal, '0.00%') }})</span>
            </table-list-column>
          </table-list-row>
          <table-list-row v-for="(item, index) in $_emptyRowCounter(data.rows.length, data.limit)" :key="`id-${index}`">
            <table-list-column class="col-6 capitalize">-</table-list-column>
            <table-list-column v-for="(item, index) in 3" :key="`counter-${index}`" class="col-2 text-right"
              >-</table-list-column
            >
          </table-list-row>
          <div class="link-container row d-flex justify-content-end">
            <label
              class="link color-oto-brand-omni col-auto mr-3 text-center"
              @click="openLightbox('lightbox-who-bought')"
              >{{ $t('product.see-more-products') }}</label
            >
          </div>
        </template>
        <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
      </table-list-body>
    </table-list>
  </div>
</template>

<script>
import { getTable } from '@/services/product';

import bus from '@/helpers/events/bus';
import { openLightbox } from '@/helpers/lightbox';
import { tableMixin, verifyMixin, numberMixin } from '@/helpers/mixins';

import ProductWhoBoughtSkeleton from '@/components/products/product/ProductWhoBoughtSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'ProductWhoBought',
  components: {
    skeleton: ProductWhoBoughtSkeleton,
    InfoCard,
  },
  mixins: [tableMixin, verifyMixin, numberMixin],
  props: {
    productID: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      data: {
        limit: 5,
        page: 1,
        series: [],
        manualSeries: [
          {
            key: 'product_name',
            name: this.$t('product.bought-too'),
          },
          {
            key: 'orders_off',
            name: this.$t('product.orders-off'),
          },
          {
            key: 'orders_on',
            name: this.$t('product.orders-on'),
          },
          {
            key: 'orders',
            name: this.$t('product.all-orders'),
          },
        ],
        rows: [],
        total_count: 0,
      },
      type: 'filled',
    };
  },
  mounted() {
    this.fetchData();

    // called after a global date change or brand change
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getTable({
        productID: this.productID,
        table: 'basket_analysis',
        limit: this.data.limit,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) return;

          this.data.total_count = data.total_count;
          this.data.rows = [];
          this.formatRows(data.data);
        })
        .catch(() => {
          this.data.total_count = 0;
          this.data.rows = [];
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRows(data) {
      const resolveRows = row => {
        const [name, boughtOn, percentBoughtOn, boughtOff, percentBoughtOff, boughtTotal, percentBoughtTotal] = row;

        return {
          name,
          boughtOn,
          percentBoughtOn,
          boughtOff,
          percentBoughtOff,
          boughtTotal,
          percentBoughtTotal,
        };
      };
      data.map(item => this.data.rows.push(resolveRows(item)));
    },
    columnClass(key) {
      let size = '';
      if (key === 'product_name') {
        size = 'col-6';
      } else if (key === 'orders_on') {
        size = 'col-2 color-oto-ecommerce text-right';
      } else if (key === 'orders_off') {
        size = 'col-2 color-purple-400 text-right';
      } else {
        size = 'col-2 color-oto-brand-omni text-right';
      }
      return size;
    },
    openLightbox(id, settings) {
      openLightbox(id, settings);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  padding: 10px;
}
.table-list-header.filled {
  padding-top: 22px;
}
.table-list-body {
  min-height: 285px;
  .link-container {
    margin-top: 40px;
    border-top: 1px solid #e8e8e8;
  }
}

.loadHeight {
  min-height: 480px;
}
</style>
