<template>
  <div v-click-outside="hide" class="select-fake-wrapper" :style="'width:' + width">
    <div :id="id" :class="['select-fake', { 'is-active': isSelectOptionsVisible }]" @click="toggleSelectFake()">
      <span v-if="showSpanMsg">{{ this.$tc('dashboard.report', 1) }}: </span>
      {{ selectedOptionText }}
    </div>
    <div v-show="isSelectOptionsVisible" class="select-fake-container customScrollBar">
      <template v-if="isArray">
        <div
          v-for="(item, index) in selectItems"
          :key="index"
          :class="['items', item.value]"
          @click="onChangeSelectFake(item.value, index)"
        >
          {{ item.title }}
        </div>
      </template>
      <template v-else>
        <div
          v-for="(item, index) in selectItemsObject"
          :key="index"
          class="items"
          @click="onChangeSelectFake(null, index)"
        >
          {{ item }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import vClickOutside from 'v-click-outside';

export default {
  name: 'SelectFake',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    selectItems: {
      type: Array,
      default: null,
    },
    selectItemsObject: {
      type: Object,
      default: () => {},
    },
    selectedItemIndex: {
      type: Number,
      default: 0,
    },
    selectedItemIndexObject: {
      type: String,
      default: '',
    },
    showSpanMsg: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isArray: false,
      isSelectOptionsVisible: false,
      selectedOptionText: '',
    };
  },
  watch: {
    selectedItemIndex() {
      this.selectedOptionText = this.selectItems[this.selectedItemIndex].title;
    },
    selectedItemIndexObject() {
      this.selectedOptionText = this.selectItemsObject[this.selectedItemIndexObject];
    },
    selectItemsObject() {
      this.selectedOptionText = this.selectItemsObject[this.selectedItemIndexObject];
    },
  },
  mounted() {
    this.popupItem = this.$el;
    if (this.selectItems) {
      this.isArray = true;
      this.selectedOptionText = this.selectItems[this.selectedItemIndex].title;
    } else {
      this.isArray = false;
      this.selectedOptionText = this.selectItemsObject[this.selectedItemIndexObject];
    }
  },
  methods: {
    hide() {
      this.isSelectOptionsVisible = false;
    },
    toggle() {
      this.isSelectOptionsVisible = true;
    },
    toggleSelectFake() {
      this.isSelectOptionsVisible = !this.isSelectOptionsVisible;
    },

    onChangeSelectFake(value, index) {
      bus.$emit('changeSelectedOption', {
        selectedOption: value,
        index: index,
        id: this.id,
      });
      this.isSelectOptionsVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-fake-wrapper {
  position: relative;
  z-index: 2;

  .select-fake {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 2;
  }
  .select-fake-container {
    position: absolute;
    overflow-y: auto;
    max-height: 300px;
    min-width: 100%;
    width: auto;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
    border: 1px solid #bbbbbb;
    border-top: none;
    border-radius: 0 0 18px 18px;
    overflow-x: hidden;
    padding-top: 12px;
    margin-top: -12px;
    z-index: -1;
    background-color: $color-white;

    .items {
      color: $gray-700;
      font-weight: bold;
      padding: 10px 15px;
      font-size: 12px;

      &:hover {
        background-color: $gray-300;
        cursor: pointer;
      }
    }
  }
  span {
    padding-right: 4px;
    font-weight: 400;
  }
}

.border-none .select-fake {
  border: none;
  outline: none;
}
</style>
