<template>
  <div class="orders row">
    <div class="col-12">
      <h1 class="card-group-title">{{ $tc('categories.orders.title') }}</h1>
      <div class="row">
        <div class="col-5">
          <revenue :category="category" />
        </div>
        <div class="col-7">
          <stores :category="category" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <historic :category="category" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Revenue from '@/components/categories/category/orders/_molecules/Revenue';
import Stores from '@/components/categories/category/orders/_molecules/Stores';
import Historic from '@/components/categories/category/orders/_molecules/Historic';

export default {
  components: {
    revenue: Revenue,
    stores: Stores,
    historic: Historic,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
  },
};
</script>
