<template>
  <section id="indicators">
    <alert type="warning">
      <strong>{{ $t('360.indicators.alert-message.strong') }}</strong> {{ $t('360.indicators.alert-message.text-1') }}
      <router-link to="/oto/engagement-report">{{ $t('360.indicators.alert-message.router-link') }}</router-link
      >{{ $t('360.indicators.alert-message.text-2') }}
      <a :href="externalLink" target="_blank">{{ $t('360.indicators.alert-message.external-link') }}</a
      >.
    </alert>
    <indicator-header />
    <tab-nav :tabs="selectItems" :selected="selected" @selected="setSelected">
      <tab :isSelected="selectedTab('tab-funnel')">
        <indicator-funnel />
      </tab>
      <tab :isSelected="selectedTab('tab-store-comparison')">
        <div v-if="isPmwebBrand" class="d-flex">
          <conversation-ranking />
          <revenue-ranking />
        </div>
        <indicator-comparable-sellers />
      </tab>
      <tab :isSelected="selectedTab('tab-table')">
        <indicator-table />
      </tab>
    </tab-nav>
    <base-loader v-show="loadingExport" color="colored-block" class="render-loader" />
  </section>
</template>

<script>
import bus from '@/helpers/events/bus';
import { useToast } from 'vue-toastification';

// store
import { mapActions, mapGetters } from 'vuex';

import IndicatorHeader from '@/components/oto/indicators/IndicatorHeader';
import IndicatorFunnel from '@/components/oto/indicators/IndicatorFunnel';
import IndicatorTable from '@/components/oto/indicators/IndicatorTable';
import IndicatorComparableSellers from '@/components/oto/indicators/IndicatorComparableSellers';
import TabNav from '@/components/_atoms/TabNav';
import Tab from '@/components/_atoms/Tab';
import ConversationRanking from '@/features/ConversationRanking/components/ConversationRanking';
import RevenueRanking from '@/features/RevenueRanking/components/RevenueRanking';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import Alert from '@/components/_atoms/Alert';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';
// services
import { cancelRequestsJob } from '@/services/oto/indicators';

export default {
  name: 'Indicators',
  components: {
    IndicatorHeader,
    IndicatorFunnel,
    IndicatorTable,
    IndicatorComparableSellers,
    TabNav,
    Tab,
    ConversationRanking,
    RevenueRanking,
    Alert,
    BaseLoader,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      loadingExport: false,
      selected: {
        id: 'tab-funnel',
        name: this.$t('360.indicators.tabs.conversion-funnel'),
      },
      selectItems: [
        {
          id: 'tab-funnel',
          name: this.$t('360.indicators.tabs.conversion-funnel'),
        },
        {
          id: 'tab-store-comparison',
          name: this.$t('360.indicators.tabs.stores-comparison'),
        },
        {
          id: 'tab-table',
          name: this.$t('360.indicators.tabs.complete-table'),
        },
      ],
      items: {
        'tab-funnel': 'tab-funnel',
        'tab-store-comparison': 'tab-store-comparison',
        'tab-table': 'tab-table',
      },
      batchIds: [],
      isAssync: false,
      externalLink: 'https://oto-academy.memberkit.com.br/186252-relatorio-de-engajamento',
    };
  },
  computed: {
    ...mapGetters('indicators', ['getFunnelBatchId', 'getMetricsBatchId']),
    isPmwebBrand() {
      return this.$store.getters.selectedBrand?.id === 1;
    },
  },
  mounted() {
    useMixpanelConfig('view_oto_suggestions_report');
    this.setAvailableMetrics();
    this.setSelected(this.selected);

    bus.$on('render-page-loader', isActive => {
      this.loadingExport = isActive;
    });

    bus.$on('change-seller', () => {
      this.clearParallelCoordinates();
    });

    bus.$on('render-cards', days => {
      this.clearParallelCoordinates();
      this.datepickerLongRequestToast(days);
      this.cancelRequestByBatchId();
    });
  },
  beforeUnmount() {
    this.cancelRequestByBatchId();
    bus.$off(['render-cards', 'change-seller', 'render-page-loader']);
  },
  methods: {
    ...mapActions('indicators', ['setAvailableMetrics', 'clearParallelCoordinates', 'setParallelCoordinates']),

    setSelected(tab) {
      this.selected = tab.id;
      bus.$emit('indicators-change-tab-nav', this.selected);
    },

    selectedTab(selected) {
      return this.selected === this.items[selected];
    },

    createArrayOfBatchIds() {
      const arrayOfBatchIds = [this.getFunnelBatchId, this.getMetricsBatchId];
      const filterBatchIds = arrayOfBatchIds.filter(id => id !== '');

      this.formatBatchIdsToString(filterBatchIds);
    },

    formatBatchIdsToString(batchIds) {
      let ids;
      if (batchIds.length > 1) {
        ids = batchIds.toLocaleString().replace(' ', ',');
      } else {
        ids = batchIds.toLocaleString();
      }
      this.batchIds = ids;
    },

    cancelRequestByBatchId() {
      this.createArrayOfBatchIds();

      if (this.batchIds) {
        cancelRequestsJob(this.batchIds);
      }
    },

    datepickerLongRequestToast(days) {
      if (days > 90) {
        this.toast.warning(this.$t('datepicker-long-request-toast'), {
          timeout: 6000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#indicators {
  :deep(.alert__warning) {
    a {
      color: #b28d29 !important;
      text-decoration: underline !important;
    }
  }
}
.render-loader {
  background-color: #232642;
  height: 100%;
  left: 50%;
  opacity: 0.7;
  overflow: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 9999;
}
</style>
