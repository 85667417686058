<template>
  <div class="history__container">
    <div class="history__item">
      <label for="status">{{ $t('integrations.status') }}</label>
      <v-select
        :clearable="false"
        :searchable="false"
        :options="status"
        label="label"
        :placeholder="$t('integrations.select')"
        @option:selected="setStatus"
      >
        <template #option="{ label }">
          <span style="text-transform: capitalize">{{ $t(label) }}</span>
        </template>
        <template #selected-option-container="{ option }">
          <div class="vs__selected" style="text-transform: capitalize">
            {{ $t(option.label) }}
          </div>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
    <div class="history__item">
      <label for="status">{{ $t('integrations.import-header.typeLoad') }}</label>
      <v-select
        :clearable="false"
        :searchable="false"
        :options="typeList"
        label="name"
        :placeholder="$t('integrations.select')"
        @option:selected="onChangeLoadType"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { mapState, mapActions } from 'vuex';
import bus from '@/helpers/events/bus';

export default {
  name: 'HistoryImportSelects',
  components: {
    vSelect,
  },
  computed: {
    ...mapState('historyImport', ['status', 'typeList']),
  },
  mounted() {
    this.setTypeList();
    bus.$on('update-selected-brand', () => {
      this.setTypeList();
    });
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
  },
  methods: {
    ...mapActions('historyImport', ['setTypeList', 'setSelectedStatus', 'setSelectedLoadTypeId']),
    setStatus(status) {
      if (!status) return;
      this.setSelectedStatus({ status: status.key });
    },
    onChangeLoadType(type) {
      if (!type) return;
      this.setSelectedLoadTypeId({ id: type.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.history__container {
  display: flex;
  justify-content: space-between;
  margin-left: 45px;
  width: 400px;

  .history__item {
    display: flex;
    justify-content: baseline;
    flex-direction: column;
    width: 192px;

    select {
      height: 36px;
    }
  }
}
</style>

<style lang="scss">
.history__container {
  @import '@/assets/scss/vendors/_v-select';

  select:disabled {
    background-color: #e3e3e3;
    color: $gray-600;
  }
}
</style>
