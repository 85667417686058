import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = '/customer';
const controller = '360/customers';
const controllerCustomer = '360/customer';

/**
 * Get Categories List
 *
 * @param {Number} customerId
 */
export const getCategoriesList = customerId => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/getCategoriesList`, {
      params: {
        brand: brand.id,
        limit: 0,
        customerId,
      },
    })
    .then(data => data.data);
};

/**
 * Get the Full Profile
 *
 * @param {Number} customerId
 */
export const getFullProfile = customerId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios
    .get(`${controllerCustomer}/getProfile`, {
      baseURL: endpointVersion('v3'),
      params: {
        brand: brand.id,
        customerId,
      },
      headers: { Authorization: `Bearer ${tokenV3}` },
    })
    .then(data => data.data);
};

/**
 * Get the Size list
 *
 * @param {Number} customerId
 */
export const getSizes = customerId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios
    .get(`${URI}/getSizes`, {
      baseURL: endpointVersion('v3'),
      params: {
        brand: brand.id,
        customerId,
      },
      headers: { Authorization: `Bearer ${tokenV3}` },
    })
    .then(data => data?.data);
};

/**
 * Get the Top categories list
 *
 * @param {Number} customerId
 * @param {String} season (Optional)
 * @param {Number} limit (Optional) default = 5
 */
export const getCategories = (customerId, season, limit = 5) => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/getCategories`, {
      params: {
        brand: brand.id,
        customerId,
        limit,
        season,
      },
    })
    .then(data => data.data);
};

/**
 * Get Category Sizes List
 *
 * @param {Number} customerId
 * @param {String} category Selected category (optional)
 */
export const getCategorySizesList = (customerId, category) => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/getCategorySizesList`, {
      params: {
        brand: brand.id,
        customerId,
        category,
      },
    })
    .then(data => data.data);
};

export const putCategorySizes = (customerId, category, size) => {
  const brand = store.getters.selectedBrand;
  return axios({
    method: 'POST',
    url: `${controller}/putCategorySizes`,
    params: {
      brand: brand.id,
      customerId,
      category,
      size,
    },
  }).then(data => data.data);
};

/**
 * Get the Top brands list
 *
 * @param {Number} customerId
 * @param {String} season (Optional)
 * @param {Number} limit (Optional) default = 5
 */
export const getBrands = (customerId, season, limit = 5) => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/getBrands`, {
      params: {
        brand: brand.id,
        customerId,
        limit,
        season,
      },
    })
    .then(data => data.data);
};

/**
 * List customers and filter by term and columns
 *
 * @param {String} term Customer's name to search for. It performs a search in the columns FIRST_NAME, MIDDLE_NAME, LAST_NAME and FULL_NAME.
 * @param {String} filter A JSON object with the column filtered, the operator and value. Ex.: {"include":[[{"column":"CITY","operator":"equal","value":["porto%20alegre"]}]]}
 * @param {String} columns A comma-separated list of columns to retrieve. Available values: id, customer, last_order, avg_value, revenue
 * @param {Number} limit Maximum number of results to return. Default value: 100
 * @param {Number} page Pagination
 */
export const getCustomersTable = (term = '', filter = '', columns = '', limit = '', page = 1) => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/getTable`, {
      params: {
        brand: brand.id,
        tbl: 'customersList',
        term,
        filter,
        columns,
        limit,
        page,
      },
    })
    .then(data => data.data);
};

/**
 * Search customers by term - used in header
 *
 * @param {String} term Customer's name to search for. It performs a search in the columns FIRST_NAME, MIDDLE_NAME, LAST_NAME and FULL_NAME.
 * @param {Number} limit Maximum number of results to return. Default value: 4
 * @param {Number} storeFilter Enables or disables store filter in search. Default value: 1
 */
export const searchCustomer = (term = '', limit = 4, storeFilter = 1) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios
    .get(`${URI}/search`, {
      baseURL: endpointVersion('v3'),
      params: {
        brand: brand.id,
        s: term,
        limit,
        storeFilter,
      },
      headers: { Authorization: `Bearer ${tokenV3}` },
    })
    .then(data => data.data.data.database);
};

/*
 * List customer filters
 */
export const getFilterColumns = () => {
  const brand = store.getters.selectedBrand;
  // This need to be replaced to the axios default settings
  const locale = store.getters.user ? store.getters.user.language.toLowerCase() : 'pt-br';

  return axios.get(`${controller}/getFilterColumns`, {
    params: {
      brand: brand.id,
      locale,
    },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Customers-getResultsByField
 * @param {String} field
 * @param {String} term
 * @param {Number=} limit
 * @param {Number=} page
 * @param {String=} orderby
 */
export const getResultsByField = ({ field, term, where }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${controllerCustomer}/getResultsByField`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      field,
      term,
      where,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Customers-getFields
 */
export const getFieldsNewCustomer = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${controllerCustomer}/getFields`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see https://labs.pmweb.com.br/docs/context-v3/#api-Customers-getZipCode
 * @param {String} code
 */
export const getCustomerZipCode = code => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`${controllerCustomer}/getZipCode`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      code,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const putCustomer = data => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  return axios.post(
    `${controllerCustomer}/putCustomer`,
    {
      ...data,
      brand: brand.id,
      token,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};

export const verifyCustomerId = document_number => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const token = store.state.auth.token;

  return axios.post(
    `${controllerCustomer}/verifyId`,
    {
      brand: brand.id,
      token,
    },
    {
      baseURL: endpointVersion('v3'),
      params: {
        document_number,
      },
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};
