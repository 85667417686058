<template>
  <div class="serie">
    <div class="form-group option">
      <customers-filter-option
        :key="`option-${id}`"
        :update-operator-options="updateOperatorOptions"
        :data="data"
        :filter-options="filterOptions"
        :serie="id"
      />
    </div>
    <div class="form-group operator">
      <customers-filter-operator :operator-options="operatorOptions" />
    </div>
    <div class="form-group value" :class="valueBetween ? 'between' : ''">
      <el-tooltip
        :visible="showTooltip"
        :raw-content="true"
        :content="$t('audiences.value-error')"
        placement="top"
        popper-class="el--tooltip-error customers-fs"
      >
        <customers-filter-value
          v-show="!(data.operator === 'isnull' || data.operator === 'notnull')"
          :type="valueType"
          :options="valueOptions"
          :value="data.value"
          :operator="data.operator"
          :name="data.column"
          :format="valueFormat"
          :serie="id"
          :between="valueBetween"
          :multiple-selection="selectedColumnInfos.multiple"
          :reference-objects="data.referenceObjects"
          :label-field="data.labelField"
          :autocomplete="selectedColumnInfos.autocomplete"
          @showTooltip="$event => (showTooltip = $event)"
        />
      </el-tooltip>
    </div>
    <button class="remove" :title="$t('360.remove')" @click.prevent="remove(data.id)">
      <i aria-hidden="true" class="icon icon-close"></i> {{ $t('360.remove') }}
    </button>
  </div>
</template>

<script>
import CustomersFilterOption from '@/components/oto/customers/components/CustomersFilterOption';
import CustomersFilterOperator from '@/components/oto/customers/components/CustomersFilterOperator';
import CustomersFilterValue from '@/components/oto/customers/components/CustomersFilterValue';
import bus from '@/helpers/events/bus';

export default {
  name: 'CustomersFilterSerie',
  components: {
    CustomersFilterOption,
    CustomersFilterOperator,
    CustomersFilterValue,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    fill: {
      type: Array,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedColumnInfos: [],
      dataProps: [],
      stringOr: `${this.$t('or').toLowerCase()}`,
      stringOrAnother: `${this.$t('or').toLowerCase()} ${this.$t('another')}`,
      showTooltip: false,
    };
  },
  computed: {
    filterOptions() {
      const start = [{ name: this.$t('360.select'), key: undefined, hidden: true }];
      const { fill } = this;
      const keys = Object.keys(fill);

      keys.forEach(key => {
        start.push({
          key,
          ...fill[key],
        });
      });
      return start;
    },
    operatorOptions() {
      let start = [];
      if (this.data.column) {
        const operatorKeys = this.selectedColumnInfos.operators;

        start = operatorKeys.map(key => ({
          key,
          name: this.$t(`360.filter.operators.${key}`),
        }));
      }
      return start;
    },
    valueOptions() {
      if (this.data.column) {
        return this.selectedColumnInfos.options;
      }
      return undefined;
    },
    valueType() {
      if (this.data.column) {
        return this.selectedColumnInfos.type;
      }
      return undefined;
    },
    valueFormat() {
      if (this.data.column) {
        return this.selectedColumnInfos.format;
      }
      return undefined;
    },
    valueBetween() {
      if (this.data.column) {
        return this.data.operator === 'between';
      }
      return undefined;
    },
    operatorName() {
      if (this.operatorOptions.length && this.data.operator) {
        const operator = this.operatorOptions.filter(op => op.key === this.data.operator)[0];
        return operator.name;
      }
      return undefined;
    },
  },
  mounted() {
    this.dataProps = this.data;
    bus.$on('audience-fields-update-' + this.id, this.updateTextField);
    bus.$on('audience-labelField-multiselect-update', () => this.updateLabelField());
  },
  beforeUnmount() {
    bus.$off('audience-labelField-multiselect-update');
  },
  methods: {
    remove(id) {
      bus.$emit('remove-serie', { serie: id });
    },
    getSelectedColumnInfos() {
      this.selectedColumnInfos = [];
      const { column } = this.data;

      if (column) {
        this.fill.forEach(group => {
          if (group && group.columns) {
            const filter = group.columns.filter(col => col.key === column)[0];
            if (filter) this.selectedColumnInfos = filter;
          }
        });
      }
    },
    updateOperatorOptions() {
      this.dataProps.value = [];

      this.getSelectedColumnInfos();
      if (this.data.column) {
        this.dataProps.operator = this.operatorOptions[0].key;
        bus.$emit('update-operator-options', { serie: this.id });
        this.updateValueInfos();
      }
    },
    updateValueInfos(manual = true) {
      if (this.valueOptions && manual) {
        let index = 0;
        Object.keys(this.valueOptions).forEach(item => {
          if (index === 0) {
            this.dataProps.value[0] = item;
            index += 1;
          }
        });
      }
    },
    updateTextField(infos, multipleSelection = false) {
      const infosSeries = infos;
      // check if receive multipleSelection param, otherwise is false
      if (multipleSelection) {
        // infos is an object with all previously selection
        //  used to compare with array of selection id's
        // const keys = Object.keys(infos);
        if (this.data) this.dataProps.value = Object.keys(infosSeries);
        // add all key-value infos to condition (will saved with audience)
        this.dataProps.referenceObjects = { ...infosSeries };
        // input value update
        this.updateLabelField();
      } else {
        const [value, key] = infosSeries;
        this.dataProps.value = [value];
        this.dataProps.labelField = key;
      }
    },
    updateLabelField() {
      // concat multipleSelection values with 'or', 'another' dict string, etc.
      // if select more than 2, add 'another x' selected
      const labels = Object.values(this.data?.referenceObjects);
      let outputString;
      if (labels.length > 2) {
        outputString = `${labels[0]} ${this.stringOrAnother} ${labels.length - 1}`;
      } else {
        outputString = labels.join().replace(/,/g, ` ${this.stringOr} `);
      }
      this.dataProps.labelField = outputString;
    },
  },
};
</script>

<style lang="scss" scoped>
.serie {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 50px;
  &:only-of-type {
    .remove,
    .logic-operator {
      display: none;
    }
    .value {
      margin-right: 40px;
    }
  }
  .form-group {
    margin: 0 10px 0 0;
    width: 100%;
    display: flex;
    max-width: 236px;
    > * {
      width: 100%;
    }
  }
  .remove {
    background-color: transparent;
    border: none;
    font-size: 0;
    cursor: pointer;
    padding: 4px;

    i {
      font-size: 22px;
      color: $gray-600;
    }
  }
}
</style>
<style lang="scss">
.wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  input,
  select {
    width: 100% !important;
    &.error {
      border-color: $color-red !important;
      box-shadow: 0 0 7px rgba($color-red, 0.15);
    }
  }
  .error-alert {
    color: $color-red;
    position: absolute;
    right: 15px;
    font-size: 18px;
  }
  &.select .error-alert {
    margin-right: 15px;
  }
}
</style>
<style lang="scss">
.customers-fs {
  width: 200px;
}
</style>
