<template>
  <div
    ref="chart"
    class="chart"
    v-tooltip="{
      theme: 'default',
      content: `<div class='tooltip-personas'>${this.mountTooltip()}</div>`,
      distance: 8,
      html: true,
    }"
  >
    <template v-for="item in getPercentuals()" :key="item.name">
      <div class="block" :style="`width:${noValues() ? 33 : item.total}%`">
        <span :class="`percentual color-${item.name}`">{{ resolveTotalPercent(item.total) }}</span>
        <span :class="`bar color-${item.name}`"></span>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { formatNumber, formatDecimal } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  name: 'BarChart',
  props: {
    chartProps: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    resolveTotalPercent(total) {
      return formatNumber(total, '0%');
    },
    getPercentuals() {
      return _.orderBy(
        this.chartProps.filter(prop => prop.format === valueType.percentual),
        'name',
      );
    },
    noValues() {
      return this.getPercentuals().reduce((acc, value) => acc + value.total, 0) === 0;
    },
    mountTooltip() {
      let html = '';
      const group = _.groupBy(this.chartProps, 'name');
      Object.keys(group)
        .sort()
        .forEach(e => {
          const value = formatDecimal(group[e].filter(vl => vl.format !== valueType.percentual)[0].total, 1);
          const percentual = formatNumber(group[e].filter(pc => pc.format === valueType.percentual)[0].total, '0%');
          html += '<span class="line">';
          html += `<span class='circle color-${e}'></span>`;
          html += `<span class='channel-name'> ${this.$t('personas.acronyms.'.concat(e))}:</span>`;
          html += `<span class='value-bold'> ${value}</span>`;
          html += `<span class='percentual'> (${percentual})</span>`;
          html += '</span>';
        });
      return html;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  display: flex;
  width: 100%;
  height: 32px;
  text-align: center;
  margin: auto;
  .block {
    min-width: 10%;
  }
  .percentual {
    font-size: 12px;
    font-weight: 600;
    &.color-on {
      color: $oto-ecommerce;
    }
    &.color-off {
      color: $purple-200;
    }
    &.color-omni {
      color: $oto-omni;
    }
  }
  .bar {
    width: 100%;
    height: 6px;
    display: block;
    &.color-on {
      background-color: $oto-ecommerce;
    }
    &.color-off {
      background-color: $purple-200;
    }
    &.color-omni {
      background-color: $oto-omni;
    }
  }
}
</style>

<style lang="scss">
.tooltip-personas {
  .tooltip-inner {
    max-width: 350px;
  }
  .line {
    text-align: left;
    display: block;
    line-height: 20px;
  }
  .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.color-on {
      background-color: $oto-ecommerce;
    }
    &.color-off {
      background-color: $purple-200;
    }
    &.color-omni {
      background-color: $oto-omni;
    }
  }
  .value-bold {
    font-weight: 600;
  }
}
</style>
