<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute  -->
  <base-card-v2 class="dashboard-revenue">
    <div v-if="$_verifyLoaded('done')">
      <base-card-header :title="$t('dashboard.sms-title')" icon="icon-money" />
      <highcharts v-if="chartOptions" :options="chartOptions"></highcharts>
      <div v-if="monthYearTable" class="embedded-table">
        <v-select
          slot="item-right"
          v-model="tableSelect"
          class="table-select"
          :clearable="false"
          :options="tableEmbeddedItems"
          label="name"
          :placeholder="$tc('_select', 2)"
          @option:selected="onChangeTableSelect"
        >
          <template #option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #selected-option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('integrations.no-results') }}
          </template>
        </v-select>
        <dashboard-month-year-table
          :month-year-table="monthYearTable"
          :selected-option="selectedOption"
          :labels="labels"
          :loading="loadingEmbeddedTable"
        />
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import vSelect from 'vue-select';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import { getMetrics } from '@/services/dashboard';
import bus from '@/helpers/events/bus';
import { chartMixin, dashboardMixin, verifyMixin, verifyThensMixin } from '@/helpers/mixins';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import DashboardMonthYearTable from '@/components/dashboard/revenueAndEngagement/DashboardMonthYearTable';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'DashboardSmsChart',
  components: {
    highcharts: Chart,
    BaseLoader,
    DashboardMonthYearTable,
    InfoCard,
    vSelect,
  },
  mixins: [verifyMixin, verifyThensMixin, dashboardMixin, chartMixin],
  data() {
    return {
      loadingEmbeddedTable: false,
      selectedItemIndexTable: 0,
      selectedItemIndexDash: 0,
      compareOption: 'month',
      tableEmbeddedItems: [
        {
          id: 'month',
          type: 'MoM',
          name: this.$t('dashboard.MoM'),
        },
        {
          id: 'year',
          type: 'YoY',
          name: this.$t('dashboard.YoY'),
        },
      ],
      monthYearTable: {},
      tableSelect: 'MoM',
      selectedOption: 'sent',
      selectItems: [
        {
          value: 'sent',
          title: this.$tc('dashboard.sent', 1),
        },
      ],
      labels: [],
      metrics: {},
      chartOptions: {},
      chartDateOptions: [],
    };
  },
  mounted() {
    this.fetchData(this.selectedOption);
    bus.$on('render-cards', () => {
      this.fetchData(this.selectedOption);
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    selectMetrics(type) {
      let metrics;
      if (type === 'sent') {
        metrics = 'sent';
      }
      return metrics;
    },
    resolveChartSeries() {
      let series;
      if (this.selectedOption === 'sent') {
        series = this.revenueChartSeries();
      }
      return series;
    },
    revenueChartSeries() {
      return [
        {
          id: 'sent',
          name: this.$tc('dashboard.send', 2),
          type: 'column',
          color: '#6C90F4',
          data: this.$_parseArrayToFloat(this.metrics.sent),
          tooltip: {
            valueSuffix: '',
          },
        },
      ];
    },
    fetchData(selectedOption) {
      const metrics = this.selectMetrics(selectedOption);
      this.$_reqConfig();
      getMetrics({
        metrics,
        dashboard: 'sms',
        graphic: 1,
        compare: this.compareOption,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) return;

          if (data.success) {
            const metricsData = data.metrics.data;
            this.labels = metricsData?.labels;
            this.metrics = metricsData?.metrics;
            this.monthYearTable = data.metrics?.table;
          }
        })
        .then(() => {
          this.chartDateOptions = this.$_resolvePlotDateFormat(this.labels);
          this.chartOptions = this.resolveChartOptions();
        })
        .catch(() => {
          this.chartOptions = null;
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
          if (this.hasError) return;
          if (!this.chartOptions) this.$_reqConfig();
        });
    },
    fetchDataEmbeddedTable(selectedOption) {
      const metrics = this.selectMetrics(selectedOption);

      this.loadingEmbeddedTable = true;
      getMetrics({
        metrics,
        dashboard: 'sms',
        graphic: 1,
        compare: this.compareOption,
      })
        .then(({ data }) => {
          if (this.hasError == true || this.isEmpty == true) return;
          if (data.success) {
            this.monthYearTable = data.metrics?.table;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loadingEmbeddedTable = false;
        });
    },
    resolveChartOptions() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });
      return {
        chart: {
          zoomType: 'xy',
          height: 280,
          marginLeft: 90,
          marginRight: 90,
          style: {
            fontFamily: 'Montserrat',
            margin: '0 auto',
          },
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: -30,
          itemMarginBottom: 25,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 14,
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;
              if (x.series.userOptions?.id === 'revenue') {
                formattedValue = formatCurrency(x.y);
              } else if (x.series.userOptions?.id === 'sent') {
                formattedValue = formatDecimal(x.y, 1);
              } else {
                formattedValue = formatPercent(x.y, 2);
              }
              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
            pointWidth: 26,
          },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            categories: this.chartDateOptions,
            tickWidth: 0,
          },
        ],
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value > 1000 && this.value <= 999999) return formatCurrency(this.value, { shortNumber: true });
                else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                return formatDecimal(this.value);
              },
            },
          },
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                const hasRevenue = this.chart.options.series.find(item => item.id === 'revenue');
                if (hasRevenue) {
                  if (this.value > 1000 && this.value <= 999999)
                    return formatCurrency(this.value, { shortNumber: true });
                  else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                  return formatCurrency(this.value);
                } else {
                  return formatPercent(this.value);
                }
              },
            },
            opposite: true,
          },
        ],
        series: this.resolveChartSeries(),
      };
    },
    onChangeTableSelect(value) {
      this.compareOption = value.id;
      this.fetchDataEmbeddedTable(this.selectedOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-revenue {
  min-height: 370px;

  .highcharts-xaxis-labels {
    font-weight: bold;
  }

  .table-select {
    margin-left: -14px;
    width: 85px !important;
    border: none !important;
  }
}
</style>

<style lang="scss">
.dashboard-revenue {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
