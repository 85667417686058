<template>
  <div :class="['nav-sidebar', { active: active }]" @mouseenter="changeStatus" @mouseleave="changeStatus">
    <header-logo :collapsed-sidebar="active" />
    <div class="menu">
      <div :class="['nav-brands', { active: active }]">
        <div v-click-outside="hideBrands" class="brand-wrapper">
          <img
            v-if="selectedBrand.icon"
            class="brand-image"
            :src="selectedBrand.icon"
            :alt="`${selectedBrand.brand}-logo`"
          />
          <span v-else class="brand-image">{{ getBrandFirstLetter }}</span>
          <div v-show="active" class="brand-select" @click="toggle">
            <span v-if="selectedBrand.brand" class="brand-name">{{ selectedBrand.brand }}</span>
            <span v-show="brands.length >= 2" class="icon">
              <i class="arrow-down"></i>
            </span>
          </div>
          <div v-show="brandContainer.isVisible && brands.length >= 2" class="brand-container customScrollBar">
            <span v-for="brand in brands" :key="brand.id" class="brand-items" @click="selectBrand(brand)">
              {{ brand.brand }}
            </span>
          </div>
        </div>
      </div>
      <div :class="['nav-list', { active: active }]">
        <div class="scrollMenuItems customScrollBar">
          <template v-for="items in resolveSidebar" :key="items.id">
            <menu-sidebar-item
              :data-item="items.id"
              :active="active"
              :color="color"
              :item="items"
              :sub-levels="resolveSubLevels"
              :id-active="idActive"
            />
          </template>
          <menu-sidebar-user :menu="menuUserOptions" :active="active" />
        </div>
        <menu-sidebar-user-logout :active="active" />
      </div>
    </div>
  </div>
</template>

<script>
// libs
import { isEmpty } from 'lodash';
import { mapActions } from 'vuex';
import vClickOutside from 'v-click-outside';
import bus from '@/helpers/events/bus';
// components
import HeaderLogo from '@/components/menu/_molecules/HeaderLogo';
import MenuSidebarItem from '@/components/menu/_atoms/MenuSidebarItem';
import MenuSidebarUser from '@/components/menu/_organisms/SidebarUser';
import MenuSidebarUserLogout from '@/components/menu/_organisms/SidebarUserLogout';

export default {
  name: 'SidebarMenu',
  components: {
    HeaderLogo,
    MenuSidebarItem,
    MenuSidebarUser,
    MenuSidebarUserLogout,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    brands: {
      type: Array,
      default: null,
    },
    selectedBrand: {
      type: Object,
      default: null,
    },
    menu: {
      type: Array,
      default: null,
    },
    subLevels: {
      type: Array,
      default: null,
    },
    menuItems: {
      type: Array,
      default: null,
    },
    brandRoutes: {
      type: Array,
      default: null,
    },
    menuUserOptions: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      active: false,
      idActive: '',
      color: '#888',
      brandContainer: {
        isVisible: false,
      },
    };
  },
  computed: {
    resolveSidebar() {
      const order = this.mapOrder(this.menu, this.orderRoutes, 'id');

      // as welcome is a main route - remove it from menu
      return order.filter(el => el.id !== 'welcome');
    },
    getBrandFirstLetter() {
      return this.selectedBrand?.brand ? this.selectedBrand?.brand[0].toUpperCase() : false;
    },
    /**
     * @description get all routes that has sublevels and return the final routes
     * report has a number as id and doesn't need to search inside menuItems
     */
    resolveSubLevels() {
      const aux = [];

      this.subLevels.map(el => {
        if (el.id === 'reports') {
          const key = el.id;
          const routes = el.routes;
          aux.push({ id: key, routes });
        } else {
          const key = el.id;
          const routes = this.menuItems.filter(items => el.routes.find(el => el.id === items.id));
          aux.push({ id: key, routes });
        }
      });

      return aux;
    },
    /**
     * @description create an array with the correct sort order
     */
    orderRoutes() {
      if (!this.brandRoutes) return;
      return this.brandRoutes?.map(el => el.id);
    },
  },
  mounted() {
    this.highlightMenu();

    this.triggerVisibleSubcategorie();

    bus.$on('sidebar-brands-fallback', brand => this.selectBrand(brand));
  },
  beforeUnmount() {
    bus.$off('sidebar-brands-fallback');
  },
  methods: {
    ...mapActions(['changeBrand']),
    ...mapActions('base', ['updateSelectedBrand']),
    /**
     * @description change sidebar status
     */
    changeStatus() {
      this.active = !this.active;
      this.hideBrands();

      this.highlightMenu(this.active);

      this.triggerVisibleSubcategorie();
    },
    /**
     * @description highlight selected menu option
     */
    highlightMenu(active) {
      this.$nextTick(() => {
        if (!active) {
          const exact =
            document.querySelector('.nav-subcategorie .router-link-exact-active') ||
            document.querySelector('.nav-categorie .router-link-exact-active');
          const navSelected = exact?.closest('.nav-item');
          navSelected?.classList.add('highlight-router');
        } else document.querySelector('.nav-item.highlight-router')?.classList.remove('highlight-router');
      });
    },
    /**
     * @description get id from active sidebar
     */
    resolveSelectedId() {
      this.$nextTick(() => {
        const exact =
          document.querySelector('.nav-subcategorie .router-link-exact-active') ||
          document.querySelector('.nav-categorie .router-link-exact-active');
        this.idActive = exact?.closest('.nav-item').dataset.item;
      });
    },
    /**
     * @description set selected id / emit event to update visible subcategorie
     */
    triggerVisibleSubcategorie() {
      this.resolveSelectedId();
      bus.$emit('sidebar-visible-sublevels');
    },
    /**
     * @description hide brand container
     */
    hideBrands() {
      this.brandContainer.isVisible = false;
    },
    /**
     * @description toggle brand container
     */
    toggle() {
      this.brandContainer.isVisible = !this.brandContainer.isVisible;
    },
    /**
     * @description select new brand
     */
    selectBrand(brand) {
      this.hideBrands();

      if (isEmpty(brand)) return;

      this.changeBrand(brand);
      bus.$emit('set-baseUrl');

      bus.$emit('dismiss-toasts');
      bus.$emit('update-selected-brand');
      bus.$emit('render-cards', brand);

      this.updateSelectedBrand(true);
    },
    /**
     * @description sort array of objects by another array
     * @param {Array} array - array to be ordered
     * @param {Array} order - array order
     * @param {*} key - key to sort
     */
    mapOrder(array, order, key) {
      array.sort((a, b) => {
        const A = a[key];
        const B = b[key];
        return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
      });
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-sidebar {
  background: $blue-700;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: width ease 0.8s;
  width: 64px;
  z-index: 100;

  &.active {
    width: 264px;
  }

  .menu {
    background: $color-white;
    height: 100%;
    z-index: 101;
  }

  .nav-brands {
    align-items: center;
    border-bottom: 1px solid $gray-400;
    display: flex;
    height: 52px;
    padding: 0 19px;
    width: 100%;

    &.active {
      align-items: center;
    }

    .brand-wrapper {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 50px;
      position: relative;
      width: auto;
      min-width: 150px;

      .brand-image {
        align-items: center;
        background: $pink-300;
        border-radius: 100%;
        color: $color-white;
        cursor: auto;
        display: flex;
        font-size: 12px;
        font-weight: normal;
        height: 26px;
        justify-content: center;
        line-height: 20px;
        margin-right: 12px;
        min-width: 26px;
        width: 26px;
      }

      .brand-select {
        align-items: center;
        color: $gray-600;
        display: flex;
        font-size: 12px;
        font-weight: bold;
        justify-content: flex-start;
        width: 100%;

        .brand-name {
          width: auto;
          white-space: nowrap;
        }

        span.icon {
          width: 10px;
          display: flex;
          margin: 3px 0 0 10px;

          .arrow-down {
            border: solid $gray-500;
            border-width: 0 1px 1px 0;
            border-radius: 0;
            display: inline-block;
            padding: 3px;
            transform: translate(0, -50%) rotate(45deg);
          }
        }
      }

      .brand-container {
        align-items: center;
        background: $color-white;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
        flex-direction: column;
        justify-content: flex-start;
        max-height: 330px;
        overflow: auto;
        position: absolute;
        top: 48px;
        width: 100%;
        z-index: 9;

        .brand-items {
          align-items: center;
          color: $gray-700;
          display: flex;
          font-size: 12px;
          font-weight: normal;
          height: 32px;
          padding: 0 16px;
          line-height: 14px;

          &:hover {
            background-color: $gray-300;
          }
        }
      }
    }
  }

  .nav-list {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    padding: 27px 10px 0;
    height: calc(100vh - 135px);
    .scrollMenuItems {
      align-self: center;
      height: 90%;
    }

    &.active {
      overflow-y: auto;
      overflow-x: hidden;
      .scrollMenuItems {
        width: 100%;
        overflow-x: hidden;
        padding-right: 10px;
      }
    }
  }
}
</style>
