<template>
  <div :class="['search-bar', { 'is-collapsed': isCollapsed }]">
    <div class="container">
      <div class="search-title">
        <span v-if="title" class="title pr-2">{{ title }}</span>
        <skeleton-loader v-else width="200px" height="20px" class="mr-2" />

        <span v-if="subtitle || subtitle === ''" class="subtitle">{{ subtitle }}</span>
        <skeleton-loader v-else width="100px" height="14px" />
      </div>

      <search-form />
    </div>
  </div>
</template>
<script>
import SearchForm from '@/components/products/search/SearchForm';
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';
import bus from '@/helpers/events/bus';

export default {
  components: {
    'search-form': SearchForm,
    'skeleton-loader': SkeletonLoader,
  },
  data() {
    return {
      title: undefined,
      subtitle: undefined,
      isCollapsed: false,
    };
  },
  mounted() {
    this.listeners();
  },
  methods: {
    updateInfo({ title, subtitle }) {
      this.title = title;
      this.subtitle = subtitle;
    },
    updatePosition(isVisible) {
      this.isCollapsed = !isVisible;
    },
    listeners(off = false) {
      const action = off ? '$off' : '$on';
      bus[action]('update-search-fixed-data', this.updateInfo);
      bus[action]('breadcrumb-visibility', this.updatePosition);
    },
  },
  beforeDestoy() {
    this.listeners(true);
  },
};
</script>
<style lang="scss" scoped>
.search-bar {
  width: 100%;
  padding: 10px 0;
  position: fixed;
  left: 0;
  z-index: 2;
  background-color: $color-white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: 0.2s top;
  top: 70px;

  &.is-collapsed {
    transition: 0.2s top;
    top: 70px;
  }

  &:not(.is-collapsed) {
    border-top: 1px solid #ebebeb;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-weight: 600;
    line-height: 18px;
    color: $gray-800;
  }
  .subtitle {
    font-size: 11px;
    line-height: 16px;
    color: $gray-600;
  }
}
</style>
