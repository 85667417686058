<template>
  <div class="stores-revenue-by-medium card px-0">
    <div v-show="$_verifyLoaded('done')" class="card-body">
      <div class="row medium-header">
        <div class="col">
          <modal-dates class="dates"></modal-dates>
        </div>
        <div class="col medium-title">{{ $t('tracked-revenue.title') }}</div>
      </div>
      <div v-for="(medium, index) in revenueByMedium" v-show="hasData" :key="index" class="row medium-list">
        <div class="col medium-name">{{ medium.medium }}</div>
        <div class="col medium-value">{{ medium.value }}</div>
      </div>
    </div>
    <empty-message v-if="!hasData && !loading" />
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import ModalDates from '@/components/ModalDates';
import EmptyMessage from '@/components/_atoms/EmptyMessage';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';
import { formatCurrency } from '@/utilities/formatters';

export default {
  name: 'StoresRevenueByMedium',
  components: {
    'modal-dates': ModalDates,
    'empty-message': EmptyMessage,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      selectedStore: '',
      revenueByMedium: [],
      hasData: true,
    };
  },
  mounted() {
    bus.$on('store-changed', value => {
      this.selectedStore = value;
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('store-changed');
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        endpoint: 'stores/get_metrics',
        metrics: 'revenue_by_medium',
        filters: `store_id:${this.selectedStore}`,
      })
        .then(response => {
          this.hasData = response.data.metrics.revenue_by_medium.has_data;
          this.formatMedium(response.data.metrics.revenue_by_medium.data.series[0].data);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatMedium(data) {
      this.revenueByMedium = [];
      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          this.revenueByMedium.push({
            medium: key,
            value: formatCurrency(value),
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stores-revenue-by-medium {
  min-height: 390px;
  .medium-header {
    padding: 10px 40px;
    .dates {
      font-size: 11px;
      color: $gray-600;
      text-transform: capitalize;
    }
    .medium-title {
      color: $gray-900;
      font-size: 12px;
      font-weight: 600;
      text-align: right;
    }
  }
  .medium-list {
    border-bottom: 1px solid $gray-400;
    padding: 10px 40px;
    .medium-name {
      font-size: 12px;
      color: $gray-700;
      font-weight: 600;
    }
    .medium-value {
      font-size: 12px;
      color: $gray-600;
      font-weight: 400;
      text-align: right;
    }
  }
  .empty-message {
    text-align: center;
    padding: 45px;
  }
}
</style>
