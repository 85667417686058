<template>
  <div class="sdc__dropdown">
    <a
      id="dropdownTemplate"
      href="#"
      class="dropdown-toggle"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="sdc__dropdown-icon icon icon-menu-14px"></i>
    </a>

    <div class="dropdown-menu" aria-labelledby="dropdownTemplate">
      <a :class="['dropdown-item', { disabled: !hasAudienceId }]" @click.prevent="edit">
        {{ $tc('_edit', 2) }}
      </a>
      <a :class="['dropdown-item', { disabled: !hasAudienceId || isActiveEnable }]" @click.prevent="active">
        {{ $tc('_activate', 2) }}
      </a>
      <a :class="['dropdown-item', { disabled: !hasAudienceId || isInactiveEnable }]" @click.prevent="inactive">
        {{ $tc('_inactivate', 2) }}
      </a>
      <a :class="['dropdown-item', { disabled: !hasAudienceId || isDeleteEnabled }]" @click.prevent="remove">
        {{ $tc('_delete', 2) }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import bus from '@/helpers/events/bus';

export default {
  name: 'CampaignsManagementDetailsCampaignDropdown',
  props: {
    id: {
      type: String,
      default: '',
    },
    campaign: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    enabled: {
      type: Number,
      default: null,
    },
    hasSuggestions: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      item: this.campaign?.status,
    };
  },
  computed: {
    hasAudienceId() {
      return this.campaign?.audience === null ? false : true;
    },

    isDeleteEnabled() {
      return this.item === null || this.item === 2 || this.hasSuggestions;
    },

    isActiveEnable() {
      return this.item === null || this.item === 1;
    },

    isInactiveEnable() {
      return this.item === null || this.item === 0;
    },
  },
  watch: {
    enabled(val) {
      this.item = val;
    },
  },
  methods: {
    ...mapActions('campaignsManagement', ['actionDeleteCampaign', 'actionActiveCampaign', 'actionInactiveCampaign']),

    /**
     * @description edit campaign
     */
    edit() {
      bus.$emit('open-suggestion-detail-campaigns-modal', {
        campaign: this.campaign,
        index: this.index,
        isEdit: true,
      });
    },

    /**
     * @description add enabled 2 as key
     */
    remove() {
      this.actionDeleteCampaign(this.index);
    },

    /**
     * @description add enabled 1 as key
     */
    active() {
      this.actionActiveCampaign(this.index);
    },

    /**
     * @description add enabled 0 as key
     */
    inactive() {
      this.actionInactiveCampaign(this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.sdc__dropdown {
  margin-left: 40px;

  .dropdown-toggle {
    cursor: pointer;
    text-decoration: none;

    &:after {
      display: none;
    }
  }
  .sdc__dropdown-icon {
    color: $gray-500;
    margin-top: 3px;
  }

  .dropdown-menu {
    min-width: 100px;
    box-shadow: -1px 2px 9px rgba(0, 0, 0, 0.25);
    border: 0 none;
    border-radius: 0;
    padding: 0;
    left: -20px !important;

    .dropdown-item {
      font-size: 11.46px;
      padding: 3px 12px;
      color: $gray-600;
      line-height: 22px;
      font-weight: 600;
      cursor: pointer;

      &:hover:not(.disabled) {
        background-color: $gray-400;
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
</style>
