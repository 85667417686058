<template>
  <section class="personas-view personas-card">
    <div class="row">
      <div class="col-12 align-self-center">
        <base-card-v2>
          <base-card-header class="personas-list" :title="$tc('personas.title', 2)" icon="icon-folder-30px f-30">
            <template v-slot:item-right>
              <div class="float-right ml-auto d-flex list-actions">
                <!-- SEARCH AND ADD MENU -->
                <div v-show="!getIsEmpty && getHasError" class="form-wrapper search-and-filter">
                  <input v-model="term" class="findTerm" :placeholder="$t('personas.home.search-persona')" />
                  <i v-if="term !== ''" class="icon icon-close" @click="clearTerm"></i>
                  <i v-else class="icon icon-search"></i>
                </div>
                <button
                  class="btn btn-add-persona"
                  :title="$t('personas.home.add-persona')"
                  @click.prevent="addPersona"
                >
                  {{ $t('personas.home.add-persona') }}
                </button>
                <!-- END SEARCH AND ADD MENU -->
              </div>
            </template>
          </base-card-header>
          <div v-if="$_verifyLoadedVuex('done')" class="tg-list">
            <div class="tg-row -header">
              <div class="tg-col">
                <div class="header-title">{{ $t('personas.home.name') }}</div>
                <div :class="['order-by', { active: isActive === 'name' }]" @click.prevent="ordenate('name')">
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
              <div class="tg-col">
                {{ $t('personas.home.size') }}
                <div :class="['order-by', { active: isActive === 'count' }]" @click.prevent="ordenate('count')">
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
              <div class="tg-col">
                {{ $t('personas.home.percent-base') }}
                <div
                  :class="['order-by', { active: isActive === 'base_percent' }]"
                  @click.prevent="ordenate('base_percent')"
                >
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
              <div class="tg-col">
                {{ $t('personas.home.revenue') }}
                <div :class="['order-by', { active: isActive === 'revenue' }]" @click.prevent="ordenate('revenue')">
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
              <div class="tg-col">
                {{ $t('personas.home.percent-revenue') }}
                <div
                  :class="['order-by', { active: isActive === 'revenue_percent' }]"
                  @click.prevent="ordenate('revenue_percent')"
                >
                  <span :class="['dash-arrows', `${orderType}`]"></span>
                </div>
              </div>
              <div class="tg-col"></div>
            </div>
            <div
              v-for="(persona, index) in groupSingle"
              :key="index"
              class="tg-row"
              @click.self="verifyIsRename(persona)"
            >
              <div class="tg-col" @click="verifyIsRename(persona)">
                <span class="round-name">{{ resolveInitialLetters(persona.name) }}</span>
                <span
                  :id="'persona' + persona.id"
                  ref="persona_list_item"
                  class="persona-name ellipsis"
                  :contenteditable="editPersonaName === persona.id"
                  @keypress.enter.prevent="onEnter"
                  @blur="onBlur(persona.id, persona.name)"
                >
                  {{ persona.name }}
                </span>
              </div>
              <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                <icon-base v-if="persona.count != null" w="11" h="13" color="#888" box="0 0 11 13">
                  <icon-user-outline />
                </icon-base>
                {{ resolveAmount('integer', persona.count) }}
              </div>
              <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                <circle-chart
                  v-if="persona.base_percent != null"
                  :percentage="resolveAmount('percentCircle', persona.base_percent)"
                />
                <span>
                  {{ resolveAmount('percent', persona.base_percent) }}
                </span>
              </div>
              <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                <span>
                  {{ resolveAmount('decimal', persona.revenue) }}
                </span>
              </div>
              <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                <circle-chart
                  v-if="persona.revenue_percent != null"
                  :percentage="resolveAmount('percentCircle', persona.revenue_percent)"
                />
                <span v-if="persona.revenue_percent != null">
                  {{ resolveAmount('percent', persona.revenue_percent) }} da receita
                </span>
              </div>
              <div class="tg-col">
                <menu-dropdown :label="persona.id" :actions="actionsDropdown(persona, index)" />
              </div>
            </div>
            <div v-if="filteredListIsEmpty" class="no-results">
              {{ $t('personas.home.no-results') }}
            </div>
            <template v-if="!filteredListIsEmpty">
              <div v-for="(value, personaType) in groupsType" :id="personaType" :key="personaType + 'group'">
                <template v-if="pasteSearchHasResults(personaType)">
                  <div class="personas-paste tg-row" @click="openPaste(personaType)">
                    <div
                      class="tg-col"
                      :class="{
                        pasteActive: verifyFolderIsActive(personaType),
                      }"
                    >
                      <i class="arrow-down arrow-left"></i>
                      <i class="icon icon-folder-30px f-30" />
                      <span>{{ value }}</span>
                    </div>
                  </div>
                  <div
                    v-for="(persona, index) in groupsPersonas[personaType]"
                    :key="index"
                    @click.self="verifyIsRename(persona)"
                  >
                    <div
                      v-if="verifyFolderIsActive(personaType)"
                      class="persona-group-wrapper"
                      :class="{
                        pasteActive: verifyFolderIsActive(personaType),
                      }"
                    >
                      <div class="tg-row">
                        <div class="tg-col" @click="verifyIsRename(persona)">
                          <span class="round-name">{{ resolveInitialLetters(persona.name) }}</span>
                          <span
                            :id="'persona' + persona.id"
                            ref="persona_list_item"
                            class="persona-name ellipsis"
                            :contenteditable="editPersonaName === persona.id"
                            @keypress.enter.prevent="onEnter"
                            @blur="onBlur(persona.id, persona.name)"
                          >
                            {{ persona.name }}
                          </span>
                        </div>
                        <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                          <icon-base v-if="persona.count != null" w="11" h="13" color="#888" box="0 0 11 13">
                            <icon-user-outline />
                          </icon-base>
                          {{ resolveAmount('integer', persona.count) }}
                        </div>
                        <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                          <circle-chart
                            v-if="persona.base_percent"
                            :percentage="resolveAmount('percentCircle', persona.base_percent)"
                          />
                          <span>
                            {{ resolveAmount('percent', persona.base_percent) }}
                          </span>
                        </div>
                        <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                          <span>
                            {{ resolveAmount('decimal', persona.revenue) }}
                          </span>
                        </div>
                        <div class="tg-col" @click="openPersona(persona.id, persona.name)">
                          <circle-chart
                            v-if="persona.revenue_percent != null"
                            :percentage="resolveAmount('percentCircle', persona.revenue_percent)"
                          />
                          <span v-if="persona.revenue_percent != null">
                            {{ resolveAmount('percent', persona.revenue_percent) }} da receita
                          </span>
                        </div>
                        <div class="tg-col">
                          <personas-list-dropdown :persona-id="persona.id" :persona-index="index" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </base-card-v2>
        <empty-state
          v-show="getIsEmpty && !getHasError"
          :title="$t('personas.empty-state-personas.title')"
          :text="$t('personas.empty-state-personas.text')"
          :button-text="$t('personas.empty-state-personas.button')"
          :exec-function="addPersona"
        >
          <template v-slot:image>
            <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" alt="" />
          </template>
        </empty-state>
        <base-loader v-show="$_verifyLoadedVuex('loading')" class="card personas-info loadHeight" />
        <info-card v-if="getHasError" class="card personas-info loadHeight" :error="true" />
      </div>
    </div>
    <delete-persona />
  </section>
</template>

<script>
// libs, helpers, services
import { isEmpty } from 'lodash';
import bus from '@/helpers/events/bus';
import { mapState, mapGetters, mapActions } from 'vuex';
import { updatePersona, getHasPersonasLeft } from '@/services/personas';
import { getInitialLetters } from '@/helpers/string-parser';
import { tableMixin, verifyMixin } from '@/helpers/mixins';
import { useToast } from 'vue-toastification';
// utilities
import { useMixpanelConfig, useMixpanelConfigPersonas } from '@/utilities/mixpanel';
// icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconUserOutline from '@/assets/vue-icons/icons/UserOutline';
// components
import DeletePersona from '@/components/personas/_organisms/DeletePersona';
import MenuDropdown from '@/components/_atoms/MenuDropdown.vue';
import CircleChart from '@/components/_atoms/CircleChart';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '../components/_atoms/InfoCard.vue';
import EmptyState from '@/components/_atoms/EmptyState';

export default {
  name: 'Personas',
  components: {
    IconBase,
    IconUserOutline,
    MenuDropdown,
    DeletePersona,
    CircleChart,
    BaseLoader,
    InfoCard,
    EmptyState,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  mixins: [tableMixin, verifyMixin],
  data() {
    return {
      groupsType: {},
      groupsPersonas: {},
      pasteActiveArray: [],
      isRenameId: '',
      term: '',
      editPersonaName: null,
      order: null,
      orderType: 'asc',
      isActive: '',
      tableData: null,
      verifyIsOrdenate: false,
      renameCell: {
        index: null,
        name: null,
      },
    };
  },
  computed: {
    ...mapState('personas', ['list', 'personasGroupsType']),
    ...mapGetters('personas', [
      'getFilteredPersonasList',
      'getPersonasGroupsType',
      'getFirstLoad',
      'getIsEmpty',
      'getHasError',
      'getLoading',
    ]),
    filteredListIsEmpty: function () {
      return isEmpty(this.getFilteredPersonasList(this.term));
    },
    groupSingle() {
      return this.tableData?.filter(element => element.group === null || element.group === '');
    },
  },
  watch: {
    getPersonasGroupsType() {
      this.groupsType = this.getPersonasGroupsType;
      this.insertDataGroupPersonas();
    },
  },
  beforeUpdate() {
    this.organizeList();
  },
  mounted() {
    useMixpanelConfig('view_audience_management_personas');
    this.verifyIsOrdenate = false;
    this.updateVuex();
    bus.$on(['update-selected-brand'], () => {
      this.updateVuex();
    });
    bus.$on(['personas-reload-list'], () => {
      this.updateVuex();
    });
    bus.$on(['render-cards'], () => {
      this.updateVuex();
    });
    bus.$on('restore-cell-name', this.restoreCellName);
    bus.$on('personas-rename-item', ({ personaId, personaIndex }) => {
      this.isRenameId = personaId;
      this.renameCell.index = personaIndex;
      this.renameCell.name = this.$refs.persona_list_item[personaIndex].innerText;
      this.renamePersona(personaId);
    });
  },
  beforeUnmount() {
    bus.$off(['update-selected-brand', 'personas-reload-list', 'personas-rename-item', 'restore-cell-name']);
  },
  methods: {
    ...mapActions('personas', ['getPersonasList', 'actionCheckAccount360']),
    pasteSearchHasResults(group) {
      if (this.term === '') return true;
      else if (this.groupsPersonas[group]?.length > 0) return true;
      else return false;
    },
    resolveAmount(type, amount) {
      return amount ? this.$_getValue({ type: type, amount: amount }) : '';
    },
    openPaste(personaType) {
      this.insertDataGroupPersonas();
      const verifyIfIsActive = this.pasteActiveArray.filter(el => el == personaType);
      if (verifyIfIsActive.length > 0) this.pasteActiveArray.splice(this.pasteActiveArray.indexOf(personaType), 1);
      else this.pasteActiveArray.push(personaType);
    },
    verifyFolderIsActive(personaType) {
      const verifyArray = this.pasteActiveArray.filter(el => el === personaType);
      if (verifyArray.length > 0) return true;
      else return false;
    },
    insertDataGroupPersonas() {
      for (const index in this.groupsType) {
        this.groupsPersonas[index] = [];
        const groupData = this.tableData?.filter(element => element.group.toString() === index);
        this.groupsPersonas[index] = groupData;
      }
    },
    /**
     * @description Verify list order, filter and organize personas list
     */
    organizeList() {
      if (!this.verifyIsOrdenate) {
        this.tableData = this.getFilteredPersonasList(this.term);
      } else {
        this.tableData = this.getFilteredPersonasList(this.term);

        this.orderType === 'asc' ? (this.orderType = 'desc') : (this.orderType = 'asc');
        this.ordenate(this.isActive);
      }
      this.insertDataGroupPersonas();
    },
    /**
     * @description Verify if this Persona is with rename active and call openPersona method
     * @param {Number} id
     */
    verifyIsRename(persona) {
      const { id, name } = persona;
      if (this.isRenameId != id) this.openPersona(id, name);
    },
    /**
     * @description Receive and send data for $_tableOrdination
     * @param {Object} data
     */
    ordenate(data) {
      const { tableData, order, orderType, verifyIsOrdenate } = this.$_tableOrdination({
        tableData: this.tableData,
        order: this.order,
        orderType: this.orderType,
        key: data,
        verifyIsOrdenate: this.verifyIsOrdenate,
      });
      this.tableData = tableData;
      this.order = order;
      this.orderType = orderType;
      this.verifyIsOrdenate = verifyIsOrdenate;
      this.isActive = data;
      this.insertDataGroupPersonas();
    },
    addPersona() {
      getHasPersonasLeft().then(({ data }) => {
        if (data.data.personasLeft <= 0) {
          this.toast.error(
            this.$t(`errors.err-429`, {
              type: this.$tc('personas.title', 2).toLowerCase(),
            }),
          );
          return;
        }
        this.$router.push({
          path: `/personas/create/`,
        });
      });
    },
    updateVuex() {
      this.getPersonasList();
      this.actionCheckAccount360();
    },
    /**
     * @description clear term of search input
     */
    clearTerm() {
      this.term = '';
    },
    /**
     * @description change route to detailed label page
     * @param {Number} id
     */
    openPersona(id, name) {
      useMixpanelConfigPersonas('view_audience_management_personas_details', id, name);

      this.$router.push({
        name: 'PersonaView',
        params: { id },
      });
    },
    /**
     * @description set id of renaming label and add events to open span tag edit mode
     * @param {Number} personaId
     * @param {Number} personaIndex
     */
    renamePersona(personaId) {
      this.editPersonaName = personaId;
      this.$nextTick(() => {
        for (const index in this.$refs.persona_list_item) {
          if (this.$refs?.persona_list_item[index]?.id === 'persona' + personaId) {
            this.$refs?.persona_list_item[index].focus();
            this.onFocus(index);
          }
        }
      });
    },
    /**
     * @description update label with span inner text and call eventbus to reload personas list
     * @param {Number} personaId
     */
    renameAction(personaId) {
      updatePersona({ personaId, name: event.target.innerText }).then(res => {
        if (!res?.data?.success) {
          bus.$emit('restore-cell-name');
          this.showErrorMessage(res.status);
        } else {
          this.toast.success(this.$t('personas.detail.messages.updated'), {
            timeout: 5000,
          });
          bus.$emit('personas-reload-list');
        }
      });
      this.editPersonaName = null;
      this.isRenameId = '';
    },
    /**
     * @description change rename innerText to data name/index stored by 'personas-rename-item' emitter
     */
    restoreCellName() {
      this.$refs.persona_list_item[this.renameCell.index].innerText = this.renameCell.name;
      this.renameCell.index = null;
      this.renameCell.name = null;
    },
    /**
     * @description validate error code and fire emitter
     */
    showErrorMessage(errorCode) {
      if (errorCode === 429) {
        bus.$emit(
          'show-detail-error',
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('personas.title', 2).toLowerCase(),
          }),
        );
      } else {
        this.toast.error(
          this.$t(`errors.err-${errorCode}`, {
            type: this.$tc('personas.title', 1).toLowerCase(),
          }),
        );
        bus.$emit('header-details-input-blur');
      }
    },
    /**
     * @description add focus event to element to simulate input element
     * @param {Number} index of label object
     */
    onFocus(index) {
      const element = this.$refs.persona_list_item[index];
      element.addEventListener('focus', () => {
        const cursorPosition = element.innerText.length;
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(element.childNodes[0], cursorPosition);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      });
    },
    /**
     * @description action to update persona name on blur event
     * @param {Number} personaId
     * @param {String} name
     */
    onBlur(personaId, name) {
      if (event.target.innerText === '') {
        bus.$emit('restore-cell-name');
        this.showErrorMessage('422');
        return false;
      }
      if (event.target.innerText === name) return;
      this.renameAction(personaId);
    },
    /**
     * @description action to remove focus of rename persona
     */
    onEnter() {
      event.target.blur();
    },
    /**
     * @description create box with initial letters
     * @param {String} name
     */
    resolveInitialLetters(name) {
      return getInitialLetters(name, 0, 2);
    },

    /**
     * @description actions to menu dropdown
     */
    rename(persona, index) {
      bus.$emit('personas-rename-item', { personaId: persona.id, personaIndex: index });
    },
    delete(persona) {
      bus.$emit('personas-delete-item', persona.id);
    },
    duplicate(persona) {
      this.$router.push({ name: 'PersonaDuplicate', params: { id: persona.id } });
    },
    compare(persona) {
      this.$router.push({ name: 'personasCompareId', params: { id: persona.id } });
    },
    actionsDropdown(persona, index) {
      return [
        {
          name: 'personas.compare-this',
          action: () => this.compare(persona),
        },
        {
          name: 'duplicate',
          action: () => this.duplicate(persona),
        },
        {
          name: 'rename',
          action: () => this.rename(persona, index),
        },
        {
          name: 'delete',
          action: () => this.delete(persona),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.personas-info {
  &.loadHeight {
    min-height: 514px;
  }
}
.tg-list {
  margin-top: 20px;
  min-height: 250px;
  font-size: 12.1px;
  color: #888;
  .tg-row {
    display: grid;
    grid-template-columns: 22% 15% 21% 17% 21% 3%;
    grid-template-rows: 40px;
    border-bottom: 1px solid #eee;
    justify-items: start;
    align-items: center;

    &.-header {
      font-weight: 600;

      .order-by {
        background-color: $gray-400;
        border-radius: 5px;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        line-height: 100%;
        padding: 3px 0;
        margin-left: 5px;
        height: 15px;
        width: 15px;
        min-width: 18px;
        text-align: center;

        .dash-arrows {
          display: block;
          color: $color-white;
          border: solid $gray-900;
          border-width: 0 1px 1px 0;
          border-radius: 0;
          display: inline-block;
          padding: 3px;
          transform: translate(0, -50%) rotate(45deg);
        }

        &.active {
          background-color: $oto-omni;

          .dash-arrows {
            border-color: $color-white;
          }
          .asc {
            transform: translate(0, -50%) rotate(45deg);
          }
          .desc {
            transform: rotate(-135deg);
          }
        }
      }
      .tg-col:first-child {
        padding-left: 20px;
      }
    }
    &.personas-paste {
      background: #eeeeee4d;
    }
    &:not(.-header) {
      cursor: pointer;
      &:hover {
        background: #eeeeee;
      }
      .tg-col:first-child {
        padding-left: 15px;
      }
    }
  }
}

.no-results {
  text-align: center;
  margin-top: 80px;
}
.tg-col {
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 500px;
    z-index: 1;

    &.ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.persona-name {
      user-select: text;
      max-width: 150px;
      &:focus {
        max-width: 100%;
        min-width: 150px;
        outline: 0 none;
        border-bottom: 1px solid;
        background: #fbfbfb;
        z-index: 2;
      }
    }

    &.round-name {
      border: 1px solid $blue-500;
      padding: 6px;
      border-radius: 100%;
      margin-right: 12px;
      width: 31px;
      height: 31px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $blue-500;
    }
  }
  svg {
    margin-right: 8px;
  }
}

.list-actions {
  .search-and-filter {
    width: 310px;
    margin-right: 15px;
    position: relative;
    .findTerm {
      width: 100%;
    }
    i {
      position: absolute;
      right: 0px;
      top: 4px;
      font-size: 26px;
      color: #bbb;
      cursor: pointer;
    }
  }
  .btn-add-persona {
    width: 170px;
    height: 34px;
    display: block;
    line-height: 34px;
  }
}
.personas-list {
  &.loadHeight {
    min-height: 300px;
  }
}

.personas-paste .icon {
  margin-right: 12px;
}
.persona-group-wrapper {
  .tg-row {
    padding-left: 50px;
  }
}
.pasteActive {
  .arrow-down {
    transform: rotate(0);
  }
}
.arrow-down {
  margin: 4px 10px 0;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $gray-600;
  transform: rotate(-90deg);
}
</style>

<style lang="scss">
.f-30 {
  font-size: 30px !important;
}

.personas-card {
  .card {
    padding: 18px !important;
    .card-title {
      margin-bottom: 0 !important;
    }
  }
}
</style>
