<template>
  <div id="comparison-filters-title">
    <h2 class="card-title d-flex align-items-center">
      <span>{{ title }}</span>
    </h2>
    <p>
      {{ subtitle }}
      <span v-if="hasSelectedSeller">
        com <strong>{{ fullName }}</strong>
      </span>
      <span v-else-if="hasSelectedStore">
        com a loja <strong>{{ storeName }}</strong>
      </span>
      <span v-else>.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ComparisonFiltersTitle',
  props: {
    title: String,
    subtitle: String,
    hasSelectedSeller: Boolean,
    fullName: String,
    hasSelectedStore: Boolean,
    storeName: String,
  },
};
</script>

<style lang="scss" scoped></style>
