<template>
  <div id="conversionsalesecommerceconversion" class="card">
    <div class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-cart"></i>
        <span>{{ $t('ecommerce-conversion') }}</span>
      </h2>
    </div>
    <div class="row">
      <div class="col-5">
        <label class="chart-title">{{ $t('conversion-rate') }}</label>
        <p class="chart-value-big color-blue-400">
          {{ $_formatNumber((funnel.Buy * 100) / funnel.Sessions, '0.00%') }}
        </p>
        <label class="chart-title">{{ $t('funnel') }}</label>
        <div class="d-flex justify-content-left align-items-center mb-1">
          <div class="circle-ecommerce-conversion bg-blue-600"></div>
          <span class="bar-chart-text">{{ $t('home') }}</span>
        </div>
        <div class="d-flex justify-content-left align-items-center mb-1">
          <div class="circle-ecommerce-conversion bg-blue-500"></div>
          <span class="bar-chart-text">{{ $t('product-page') }}</span>
        </div>
        <div class="d-flex justify-content-left align-items-center mb-1">
          <div class="circle-ecommerce-conversion" style="background-color: #348fbc"></div>
          <span class="bar-chart-text">{{ $t('cart') }}</span>
        </div>
        <div class="d-flex justify-content-left align-items-center mb-1">
          <div class="circle-ecommerce-conversion" style="background-color: #2f92b9"></div>
          <span class="bar-chart-text">{{ $t('checkout') }}</span>
        </div>
        <div class="d-flex justify-content-left align-items-center mb-1">
          <div class="circle-ecommerce-conversion bg-blue-300"></div>
          <span class="bar-chart-text">{{ $tc('purchase', 1) }}</span>
        </div>
      </div>
      <div class="col-7">
        <div class="chart-ecommerce-funnel">
          <div class="chart-ecommerce-line bg-blue-600 d-flex align-items-center justify-content-center">
            <span class="bar-chart-text chart-ecommerce-line-value">{{ $_formatDecimal(funnel.Sessions) }}</span>
          </div>
          <div class="shadows" style="width: 100%"></div>

          <div
            class="chart-ecommerce-line bg-blue-500 d-flex align-items-center justify-content-center"
            style="width: 80%"
          >
            <span class="bar-chart-text chart-ecommerce-line-value">{{ $_formatDecimal(funnel.Detail) }}</span>
          </div>
          <div class="shadows" style="width: 80%"></div>

          <div
            class="chart-ecommerce-line d-flex align-items-center justify-content-center"
            style="width: 60%; background-color: #348fbc"
          >
            <span class="bar-chart-text chart-ecommerce-line-value">{{ $_formatDecimal(funnel.Cart) }}</span>
          </div>
          <div class="shadows" style="width: 60%"></div>

          <div
            class="chart-ecommerce-line bg-blue-400 d-flex align-items-center justify-content-center"
            style="width: 37%; background-color: #2f92b9"
          >
            <span class="bar-chart-text chart-ecommerce-line-value">{{ $_formatDecimal(funnel.Checkout) }}</span>
          </div>
          <div class="shadows" style="width: 37%"></div>

          <div class="chart-ecommerce-line bg-blue-300" style="width: 11%">
            <div class="bar-chart-text chart-ecommerce-line pt-2" style="margin-left: 30px; width: 60px">
              {{ $_formatDecimal(funnel.Buy) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import bus from '@/helpers/events/bus';
import { numberMixin } from '@/helpers/mixins';

export default {
  name: 'ConversionSalesEcommerceConversion',
  mixins: [numberMixin],
  data() {
    return {
      funnel: {
        Sessions: 0,
        Detail: 0,
        Cart: 0,
        Checkout: 0,
        Buy: 0,
      },
    };
  },
  created() {
    this.fetch_data();
  },
  mounted() {
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      getMetrics({
        metrics: 'arr_sessions_funnel',
      }).then(response => {
        const { series } = response.data.metrics.arr_sessions_funnel.data;
        if (series.length) {
          const { data } = series[0];
          if (data) {
            this.funnel.Sessions = data.Sessions;
            this.funnel.Detail = data.Detail;
            this.funnel.Cart = data.Cart;
            this.funnel.Checkout = data.Checkout;
            this.funnel.Buy = data.Buy;
          } else this.reset();
        }
      });
    },
    reset() {
      this.funnel = {
        Sessions: 0,
        Detail: 0,
        Cart: 0,
        Checkout: 0,
        Buy: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @media (min-width: $xl-min-width) {
    min-height: 278px;
  }
}
.shadows {
  line-height: 0;
  height: 1px;
  border: 10px solid;
  border-bottom: 0px;
  border-color: #ebebeb transparent transparent transparent;
  margin-left: auto;
  margin-right: auto;
}

.circle-ecommerce-conversion {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 15px;
}

.chart-ecommerce-funnel {
  padding-left: 20px;
  padding-right: 20px;
}

.chart-ecommerce-line {
  height: 31px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.chart-ecommerce-line-value {
  color: #fff;
}
</style>
