<template>
  <div class="actions-attachment__container">
    <button class="btn" @click="openModal">
      {{ $t('360.actions.new.attachment') }}
    </button>
  </div>
</template>

<script>
// libs, helpers
import bus from '@/helpers/events/bus';

export default {
  name: 'ActionsAttachmentHeaderNew',
  data() {
    return {};
  },
  methods: {
    openModal() {
      bus.$emit('open-actions-new-attachment-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-attachment__container {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  width: 100%;

  .btn {
    height: 36px;
    line-height: 20px;
    width: 194px;

    .icon {
      font-weight: bold;
    }
  }
}
</style>
