<template>
  <div v-if="isEmpty" id="warning-sellers-empty-state" class="warning-sellers-empty-state">
    <empty-state
      :title="$t('360.sellers.warning.empty.title')"
      :text="$t('360.sellers.warning.empty.description')"
      :has-button="true"
      :button-text="$t('360.sellers.warning.empty.button')"
      :exec-function="redirect"
    >
      <template v-slot:image>
        <img src="@/assets/img/oto/emoji/large/oto_emoji_large_finished.png" />
      </template>
    </empty-state>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
// components
import EmptyState from '@/components/_atoms/EmptyState';

export default {
  name: 'WarningSellersEmpty',
  components: {
    EmptyState,
  },
  props: {
    isEmpty: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions('sellers', ['setSelectedTab']),
    redirect() {
      this.setSelectedTab({
        id: 'tab-all-sellers',
        name: 'Todos os vendedores',
      });
    },
  },
};
</script>
<style lang="scss">
#warning-sellers-empty-state {
  width: 80%;
  margin: 0 auto;

  .container {
    margin-top: 90px;
  }

  .esc__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 540px;

    h2 {
      margin-bottom: 0;
    }

    p {
      width: 530px;
      margin: 16px 0 14px;
    }

    button {
      padding: 10px 16px;
      line-height: unset;
      margin-top: 0;
      max-width: max-content;
      position: relative;
      bottom: 18px;
    }
  }
}
</style>
