<template>
  <div v-if="!typeHeader" class="row content-group b-bottom">
    <value-atom v-for="(item, key) in headerInfo" :key="key">
      <template #title-header-content>
        <span>
          <label class="header-content-title">{{ $t(`cdp.${cardName}.${key.replace(/_/g, '-')}`) }}</label>
        </span>
      </template>
      <template #header-content>
        <span
          class="cl-header-content"
          :class="[
            colorHeaderContent(cardName),
            isMillion(item) ? `cursor-pointer tooltip-overview-${cardName}-${key}` : '',
          ]"
          v-tooltip="{
            theme: 'default',
            content: showTooltip(item),
            distance: 8,
          }"
          >{{ getNumeralType(item) }}</span
        >
      </template>
      <template #little-percent>
        <div class="tooltip-metrics-group">
          <strong class="cl-little-percent" :class="getVariationDirection(item)">{{ getVariationNumber(item) }}</strong>
          <span v-if="getVariationDirection(item) === 'none'" class="tooltip-metrics">
            <i
              class="icon icon-question-12px"
              v-tooltip="{
                theme: 'default',
                content: $t('cdp.tooltip'),
                distance: 8,
              }"
            ></i>
          </span>
        </div>
      </template>
    </value-atom>
  </div>
  <div v-else class="row content-group b-bottom">
    <double-match>
      <template #left-graphic-title>
        <span>{{ $t(`cdp.${cardName}.custommer-identified-offline-store`) }}</span>
      </template>
      <template #left-graphic>
        <bar-chart :percentage-left="headerInfo.custommer_identified_offline_store.value" :type-bar="'left'" />
      </template>
      <template #left-value>
        <strong class="left-value">{{
          $_formatNumber(headerInfo.custommer_identified_offline_store.value, '0.00%')
        }}</strong>
      </template>
      <template #title-content-value>
        <span name="graphic-content">{{ $t(`cdp.${cardName}.double-match`) }}</span>
      </template>
      <template #percent-content-value>
        <span name="graphic-content">{{ $_formatNumber(headerInfo.double_match.value, '0.00%') }}</span>
      </template>
      <template #right-graphic-title>
        <span>{{ $t(`cdp.${cardName}.match-rate-ecommerce`) }}</span>
      </template>
      <template #right-graphic>
        <bar-chart :percentage-right="headerInfo.match_rate_ecommerce.value" :type-bar="'right'" />
      </template>
      <template #right-value>
        <strong class="right-value">{{ $_formatNumber(headerInfo.match_rate_ecommerce.value, '0.00%') }}</strong>
      </template>
    </double-match>
  </div>
</template>

<script>
import ValueAtom from '@/components/cdp/_molecules/_atoms/ValueAtom';
import DoubleMatch from '@/components/cdp/_molecules/_atoms/DoubleMatchHeader';
import CdpBarChart from '@/components/cdp/_molecules/_atoms/CdpBarChart';
import { formatCurrency, formatDecimal, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';
import { numberMixin } from '@/helpers/mixins';

export default {
  name: 'HeaderContent',
  components: {
    'value-atom': ValueAtom,
    'double-match': DoubleMatch,
    'bar-chart': CdpBarChart,
  },
  mixins: [numberMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    typeHeader: {
      type: Boolean,
      default: null,
    },
    leftTitle: {
      type: String,
      default: '',
    },
    rightTitle: {
      type: String,
      default: '',
    },
    doubleMatch: {
      type: String,
      default: '',
    },
    headerInfo: {
      type: Object,
      default: () => {},
    },
    cardName: {
      type: String,
      default: '',
    },
  },
  methods: {
    resolveMillion(item) {
      return formatCurrency(item.value);
    },
    isMillion(item) {
      return item.type === valueType.float && item.value >= 1000000;
    },
    getNumeralType(item) {
      let value = 0;
      if (item.type === valueType.float && item.value < 1000000) {
        value = formatCurrency(item.value);
      } else if (item.type === valueType.float && item.value >= 1000000) {
        value = formatCurrency(item.value, { shortNumber: true, max: 2 });
      } else if (item.type === valueType.integer) {
        value = formatDecimal(item.value, 1);
      }
      return value;
    },
    getSign(item) {
      if (item > 0) {
        return '+';
      }
      return '';
    },
    getVariationNumber(item) {
      let variationType = '';
      if (item.variation === null) {
        variationType = 'N/A';
      } else {
        return this.getSign(item.variation) + formatNumber(item.variation, '% 0.00');
      }
      return variationType;
    },
    getVariationDirection(metric) {
      const moreIsBetter = metric.positive === 1;
      const typeOfVariation = metric.variation;
      const isPositiveVariation = metric.variation >= 0;
      if (typeOfVariation === null) {
        return 'none';
      }
      if (moreIsBetter) {
        return 'green';
      }
      if (!isPositiveVariation || !moreIsBetter) {
        return 'red';
      }
      return 'green';
    },
    colorHeaderContent() {
      let colorValue = '';
      switch (this.cardName) {
        case 'app':
          colorValue = 'blue';
          break;
        case 'ecommerce':
          colorValue = 'green';
          break;
        case 'email':
          colorValue = 'blue';
          break;
        case 'sms':
          colorValue = 'blue';
          break;
        case 'cdp-in-store':
          colorValue = 'purple';
          break;
        case 'google-user-id-sync':
          colorValue = 'purple';
          break;
        case 'push':
          colorValue = 'blue';
          break;
        case 'rockin-tags':
          colorValue = 'purple';
          break;
        case 'sac':
          colorValue = 'blue';
          break;
        default:
          colorValue = '';
      }
      return colorValue;
    },
    showTooltip(item) {
      if (!this.isMillion(item)) return;

      const title = this.resolveMillion(item);

      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-content-title {
  font-size: 11px;
  font-weight: 400;
  color: #8c8c8c;
  line-height: 20px;
}
.content-group {
  margin: 0 auto;
}
.b-bottom {
  border-bottom: $gray-400 solid 1px;
}
.cl-little-percent {
  &.red {
    color: $color-red;
  }
  &.green {
    color: $oto-ecommerce;
  }
  &.none {
    color: $gray-500;
  }
}
.cl-header-content {
  cursor: default;
  &.blue {
    color: $blue-500;
  }
  &.purple {
    color: $purple-400;
  }
  &.green {
    color: $oto-ecommerce;
  }
}
.tooltip-metrics-group {
  display: inline-flex;
  .tooltip-metrics {
    color: $gray-500;
    padding: 1.5px 0 0 4px;

    i {
      font-size: 12px;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
