<template>
  <el-dialog v-model="isVisible" :id="id" append-to-body>
    <form v-if="!loadingModal" class="actions-attachment">
      <h2 class="mb-3">{{ $t('360.actions.add-file') }}</h2>
      <div class="row form-group">
        <!-- name -->
        <div class="col-6">
          <label>{{ $t('360.actions.name') }}</label>
          <input
            v-model="name"
            :class="[inputError ? 'att__name--error' : '', 'att__name w-100']"
            type="text"
            :placeholder="$t('360.actions.type-name')"
          />
        </div>

        <!-- type -->
        <div class="col-6">
          <label>{{ $t('360.actions.method') }}</label>
          <v-select
            v-model="type"
            :reduce="item => item.id"
            label="name"
            :clearable="false"
            :options="fileList"
            :placeholder="$t('select')"
            @option:selected="changeTypeFile"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #option="{ name }">
              {{ $t(`${name}`) }}
            </template>
            <template #selected-option="{ name }">
              {{ $t(`${name}`) }}
            </template>
            <template #no-options>
              {{ $t('no-results') }}
            </template>
          </v-select>
        </div>
      </div>

      <div class="row form-group">
        <!-- scope -->
        <div class="col-6">
          <div class="att__scope">
            <label>{{ $t('360.actions.scope') }}</label>
            <v-select
              v-model="scope"
              label="name"
              class="w-100"
              :clearable="false"
              :reduce="item => item.id"
              :options="scopeTypeList"
              :placeholder="$t('select')"
              @option:selected="changeScopeType"
            >
              <template #option="{ name }">
                {{ $tc(`${name}`, 1) }}
              </template>
              <template #selected-option="{ name }">
                {{ $tc(`${name}`, 1) }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes" class="arrow-down"></span>
              </template>
              <template #no-options>
                {{ $t('no-results') }}
              </template>
            </v-select>
          </div>
        </div>

        <!-- scope / value -->
        <div class="col-6">
          <label>{{ getScopeValueText }}</label>
          <v-select
            v-model="scopeValue"
            label="name"
            :clearable="false"
            :loading="loadingScope"
            :disabled="checkScopeTypeBrand()"
            :options="scopeList"
            :placeholder="$t('select')"
            @option:selected="changeScopeValue"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('no-results') }}
            </template>
          </v-select>
        </div>
      </div>

      <div class="row form-group">
        <!-- period -->
        <div class="col-6">
          <actions-attachment-period :scope="scope" :disabled="disabledPeriod" />
        </div>
        <!-- channel -->
        <div class="col-6">
          <label>{{ $t('360.campaigns.contact-medium') }}</label>
          <v-select
            v-model="channel"
            :reduce="item => item.id"
            label="name"
            :clearable="false"
            :options="channelTypes"
            :placeholder="$t('select')"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('no-results') }}
            </template>
          </v-select>
        </div>
      </div>

      <div class="row form-group">
        <!-- url -->
        <label>{{ $t('360.actions.file') }}</label>
        <div v-if="type === 'url'" class="col-12">
          <input v-model="url" class="att__url w-100" type="text" :placeholder="'Cole a URL'" />
        </div>
        <!-- dragndrop area -->
        <div
          v-if="type === 'file'"
          class="att__drop-area"
          :class="isDropping"
          @dragover.prevent="dragOver"
          @dragleave.prevent="dragLeave"
          @drop.prevent="drop($event)"
        >
          <div class="att__drop" v-if="!showEditFileInfos">
            <div class="att__drop-file col-md-3">
              <div v-if="!hasFileName">
                <input
                  id="actionsNewAsset"
                  ref="file"
                  type="file"
                  name="file"
                  class="att__search-input"
                  accept="image/png, image/gif, image/jpg, image/jpeg, .pdf"
                  @change="onChangeFile"
                />
                <label v-if="!hasFileName" for="actionsNewAsset" class="att__search-label">
                  <i class="icon icon-upload-18px"></i>
                  {{ $t('360.actions.search-file') }}
                </label>
              </div>
              <div v-else class="att__drop-file-icon">
                <i class="icon icon-checked-60px" aria-hidden="true"></i>
              </div>
            </div>
            <div class="att__drop-msg col-md-9">
              <div v-if="!hasFileName" class="att__desc">
                <p class="m-o" v-html="$t('360.actions.file-msg')"></p>
              </div>
              <div v-else>
                <div class="att_edit-file-infos">
                  <h4 class="att__edit-file-title">{{ fileName }}</h4>
                  <span class="att__edit-file-size">{{ fileSize }}kb</span>
                  <button class="btn btn-link att__edit-file-btn" type="button" @click="removeFile">
                    {{ $t('360.actions.remove-file') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showEditFileInfos" class="att__drop">
            <div class="att__drop-file col-md-3">
              <img v-if="!checkIsPdf()" class="att__edit-file-img" :src="fileArquive" />
            </div>
            <div class="att_edit-file-infos att__drop-msg col-md-9">
              <h4 class="att__edit-file-title">{{ fileEditName }}</h4>
              <span class="att__edit-file-size">{{ fileEditSize }}</span>
              <button class="btn btn-link att__edit-file-btn" type="button" @click="removeFile">
                {{ $t('360.actions.remove-file') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr class="solid mt-4" />
      <!-- submit -->
      <button :disabled="disabledSubmitButton()" class="btn submit mt-3" type="submit" @click.prevent="submit">
        <span v-if="loading">{{ $tc('_loading-dots', 2) }}</span>
        <span v-else>
          <template v-if="!isEditMode">
            {{ $t('360.actions.add-file') }}
          </template>
          <template v-if="isEditMode">
            {{ $t('360.actions.edit-file') }}
          </template>
        </span>
      </button>
    </form>

    <base-loader v-if="loadingModal" />
  </el-dialog>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification';
import { mapState, mapActions } from 'vuex';

// helpers
import bus from '@/helpers/events/bus';

// services
import { putAttachment, getAttachments } from '@/services/actions';
import { getScopeValue } from '@/services/scope';

// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import ActionsAttachmentPeriod from '@/components/oto/actions/_molecules/ActionsAttachmentPeriod';

// multi select scope
// import ActionsAttachmentScopeSelector from '@/components/oto/actions/_molecules/ActionsAttachmentScopeSelector';
export default {
  name: 'ActionsNewAttachmentModal',
  components: {
    vSelect,
    BaseLoader,
    ActionsAttachmentPeriod,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      id: 'actions-new-attachment-modal',
      attachmentId: null,
      name: '',
      scope: 'brand',
      scopeValue: {},
      url: '',
      channel: 'whatsapp',
      disabledPeriod: false,
      fileEditSize: '',
      fileEditName: '',

      channelTypes: [
        {
          id: 'whatsapp',
          name: this.$t('360.whatsapp'),
        },
        {
          id: 'sms',
          name: this.$t('360.sms'),
        },
      ],
      scopeTypeList: [
        {
          id: 'brand',
          name: '_brand',
        },
        {
          id: 'action',
          name: '_action',
        },
        {
          id: 'campaign',
          name: '_campaign',
        },
        {
          id: 'store',
          name: '_store',
        },
      ],
      scopeList: [],

      type: 'url',
      fileArquive: null,
      fileList: [
        {
          id: 'file',
          name: '360.actions.make-upload',
        },
        {
          id: 'url',
          name: '360.actions.make-cloud',
        },
      ],

      loadingScope: false,
      loadingModal: false,

      dradding: null,
      isEditMode: false,
      showEditFileInfos: false,
      loading: false,

      fileDraglist: [],
      isDragging: false,

      isVisible: false,

      inputError: false,
    };
  },

  computed: {
    ...mapState('actionAttachments', ['periodStartDate', 'periodEndDate', 'modalScopeValue']),
    hasFileName() {
      return this.fileDraglist && this.fileDraglist[0]?.name;
    },

    getScopeValueText() {
      let scopeValueText;
      switch (this.scope) {
        case 'campaign':
          scopeValueText = this.$t('360.actions.campaign');
          break;
        case 'action':
          scopeValueText = this.$t('360.actions.action');
          break;
        case 'store':
          scopeValueText = this.$t('360.actions.store');
          break;
        default:
          scopeValueText = '';
          break;
      }
      return scopeValueText;
    },

    fileName() {
      return this.fileDraglist[0]?.name;
    },

    fileSize() {
      return this.fileDraglist[0]?.size;
    },

    fileType() {
      return this.fileDraglist[0]?.type;
    },

    isDropping() {
      return { 'is-dragging': this.isDragging };
    },
  },
  watch: {
    isVisible() {
      if (!this.isVisible) this.resetData();
    },
  },
  mounted() {
    bus.$on(`open-${this.id}`, attachmentId => {
      if (attachmentId) this.isEditMode = true;

      this.fetchEditData(attachmentId);
      this.openModal();
    });
  },

  beforeUnmount() {
    bus.$off([`open-${this.id}`]);
  },

  methods: {
    ...mapActions('actionAttachments', ['updateDates']),

    fetchData() {
      this.loadingScope = true;
      getScopeValue({ type: this.scope })
        .then(({ data }) => {
          if (!data.success) return;
          this.scopeList = data?.data;
        })
        .finally(() => {
          this.loadingScope = false;
        });
    },

    fetchEditData(attachmentId) {
      if (!attachmentId) return;

      this.loadingModal = true;
      this.attachmentId = attachmentId;

      getAttachments({ attachmentId })
        .then(res => {
          const data = res?.data?.data;
          if (!data || data.length === 0) return;

          const attachmentData = data[0];

          this.name = attachmentData?.name;
          this.channel = attachmentData?.channel;
          this.type = attachmentData?.type;
          this.attachmentId = attachmentData?.id;
          this.scope = attachmentData?.scope?.key;
          this.scopeValue.name = attachmentData?.scope?.name;
          this.scopeValue.id = attachmentData?.scope?.id;
          this.url = attachmentData?.url;
          this.fileEditName = attachmentData?.filename;
          this.fileEditSize = attachmentData?.filesize;
          this.fileArquive = attachmentData?.file;
          this.fileEditType = attachmentData?.mimeType;

          const startDate = attachmentData?.startDate;
          const endDate = attachmentData?.endDate;
          this.checkDates(startDate, endDate);

          if (this.type === 'file') this.showEditFileInfos = true;
          if (!this.checkScopeTypeBrand()) this.fetchData();
        })
        .finally(() => {
          this.loadingModal = false;
        });
    },

    submit() {
      this.alertIfContainSpecialCharactersOnName();

      if (this.inputError) return;

      const attachmentId = this.attachmentId;
      const name = this.name;
      const scope = this.scope;
      const scopeValue = this.scopeValue?.id;
      const channel = this.channel;
      const url = this.url;
      const startDate = this.periodStartDate;
      const endDate = this.periodEndDate;
      const type = this.type;
      const file = this.fileArquive;

      this.loading = true;

      putAttachment({ attachmentId, name, scope, scopeValue, channel, url, startDate, endDate, type, file })
        .then(() => {
          bus.$emit('actions-attachments-refresh-list');
          const toastMsg = this.isEditMode ? '360.actions.toast.file-update' : '360.actions.toast.file-success';
          this.toast.success(this.$t(`${toastMsg}`), {
            timeout: 5000,
          });
        })
        .catch(() => {
          bus.$emit('open-actions-dialog-error-modal');
        })
        .finally(() => {
          this.closeModal();
          this.loading = false;
        });
    },

    onChangeFile() {
      this.fileDraglist = this.$refs.file.files;
      this.fileArquive = this.fileDraglist[0];
      this.isDragging = false;

      if (!this.isTypeValid() || !this.isSizeValid()) {
        const typeError = !this.isTypeValid() ? 'format' : 'size';
        this.removeFile();
        this.fileError(typeError);
      }
    },

    removeFile() {
      this.fileArquive = null;
      this.fileDraglist = [];
      this.resetFileInfos();
    },

    changeTypeFile() {
      this.url = '';
      this.removeFile();
    },

    changeScopeType() {
      this.resetDates();
      this.scopeValue = {};

      // if scope is type brand, return
      if (this.checkScopeTypeBrand()) return;

      // fetch scope list
      this.fetchData();
    },

    changeScopeValue() {
      this.resetDates();

      const startDate = this.scopeValue?.startDate;
      const endDate = this.scopeValue?.endDate;

      if (startDate && endDate) this.checkDates(startDate, endDate);
    },

    /**
     * @description if the scope is type brand, disable the scope input
     */
    checkScopeTypeBrand() {
      return this.scope === 'brand' || !this.scope;
    },

    /**
     * @description if file type is PDF the size limit is 20MB, if not, it's 1MB
     */

    isSizeValid() {
      const sizetoKb = this.fileSize / 1000;
      const limitFileSize = this.fileType === 'application/pdf' ? 20000 : 1000;
      return sizetoKb <= limitFileSize;
    },

    isTypeValid() {
      const typesAllowed = ['application/pdf', 'image/png', 'image/gif', 'image/jpeg'];
      return typesAllowed.includes(this.fileType);
    },

    checkIsPdf() {
      const typePDF = ['application/pdf'];
      return typePDF.includes(this.fileEditType);
    },

    checkDates(startDate, endDate) {
      const hasDates = this.hasStartDateEndDate(startDate, endDate);
      if (!hasDates) this.resetDates();
      else if (!this.checkScopeIsBrandOrStore() && hasDates) {
        this.updateDates({ startDate, endDate });
        this.disabledPeriod = true;
      } else {
        this.updateDates({ startDate, endDate });
        this.disabledPeriod = false;
      }
    },

    hasStartDateEndDate(startDate, endDate) {
      return startDate && endDate ? true : false;
    },

    checkScopeIsBrandOrStore() {
      return this.scope === 'brand' || this.scope === 'store';
    },

    checkFileAndUrlAreEmpty() {
      return (this.type === 'file' && !this.fileArquive) || (this.type === 'url' && !this.url);
    },

    disabledSubmitButton() {
      return !this.name || this.checkFileAndUrlAreEmpty();
    },

    /**
     * @description modal events
     */
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },

    /**
     * @description drag events
     */
    dragOver() {
      this.isDragging = true;
    },

    dragLeave() {
      this.isDragging = false;
    },

    drop(e) {
      const files = e.dataTransfer.files;

      if (files?.length === 1) {
        this.$refs.file.files = files;
        this.onChangeFile();
      } else {
        this.isDragging = false;
        this.fileError('request');
      }
    },

    fileError(typeError) {
      let errorMessage;

      if (typeError === 'format') errorMessage = this.$t('360.actions.toast.file-format-error');
      else if (typeError === 'size') errorMessage = this.$t('360.actions.toast.file-size-error');
      else errorMessage = this.$t('_error-message');

      this.toast.error(errorMessage);
    },

    resetFileInfos() {
      this.fileEditName = '';
      this.fileEditSize = '';
      this.fileArquive = '';
      this.isEditMode = false;
      this.showEditFileInfos = false;
    },

    resetData() {
      this.type = 'url';
      this.attachmentId = null;
      this.isEditMode = false;
      this.name = '';
      this.scope = 'brand';
      this.scopeValue = {};
      this.url = '';
      this.channel = 'whatsapp';

      this.updateDates({});
      this.removeFile();
    },

    resetDates() {
      this.disabledPeriod = false;
      this.updateDates({});
    },

    containsSpecialCharacters(str) {
      const pattern = /[/\\|.?<!>*:"]/;
      return pattern.test(str);
    },

    alertIfContainSpecialCharactersOnName() {
      if (this.containsSpecialCharacters(this.name)) {
        this.inputError = true;
        this.toast.error(this.$t('360.actions.toast.msg-contain-special-characters'));
        return;
      }
      this.inputError = false;
    },
  },
};
</script>

<style lang="scss">
#actions-new-attachment-modal {
  @import '@/assets/scss/vendors/_v-select';

  max-width: 820px;
  width: 70vw;
  height: auto;

  .el-dialog__body {
    min-height: 60vh;
    padding: 0 46px 0;
    overflow: hidden;
  }

  .att__name--error {
    border-color: $color-red;
  }

  .att__drop-area {
    background: $gray-200;
    border: 1px dashed $gray-400;
    box-sizing: border-box;
    height: 160px;
    width: 100%;
    border-radius: 8px;
    padding: 10px;

    display: flex;
    align-items: center;

    .btn-link {
      padding: 0px;
    }

    &.is-dragging {
      background-color: #ddd;
      border-color: #fff;
    }

    .att__edit-file {
      display: flex;
      justify-content: center;
      width: 100%;

      .att_edit-file-infos {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .att__edit-file-title,
      .att__edit-file-size {
        text-align: left;
        width: 140px;
      }

      .att__edit-file-title {
        font-size: 14px;
        margin: 0;
      }

      .att__edit-file-size {
        font-size: 12px;
        padding-top: 2px;
        color: $gray-800;
      }

      .att__edit-file-btn {
        padding: 0;
        width: 100%;
        text-align: left;
      }
    }

    .att__drop {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: row;
      justify-content: flex-start;
      width: 100%;

      .att__drop-file {
        padding-left: 40px;

        .att__edit-file-img {
          border-radius: 7px;
          opacity: 0.7;
          height: 130px;
          max-width: 150px;
          border: 1px dashed $gray-400;
          width: auto;
          object-position: center;
          object-fit: cover;
        }

        .att__drop-file-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .icon-checked-60px {
            font-size: 72px;
            color: $green-400;
          }
        }

        .att__search-input {
          display: none;
        }

        .att__search-label {
          width: auto;
          max-width: 120px;
          height: 105px;
          display: block !important;
          justify-content: center;

          background: #ffffff;
          border: 1px dashed #eeeeee;
          box-sizing: border-box;
          border-radius: 5px;

          display: flex;
          align-items: center;
          text-align: center;

          padding: 15px 25px;
          cursor: pointer;
          overflow: hidden;

          font-size: 12px;
          font-weight: normal;
          line-height: 20px;

          .icon-upload-18px {
            color: $oto-omni;
            font-size: 25px;
            font-weight: 100;
            padding-bottom: 10px;
          }
        }
      }

      .att__drop-msg {
        padding-left: 24px;

        .att__file-name-box {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          overflow: hidden;
        }

        .att__desc {
          p {
            text-align: left;
          }
        }
      }

      p {
        color: #555;
        font-size: 12px;
      }
    }
  }

  p.error {
    color: $color-red;
  }

  button.submit {
    float: right;
    padding: 0 30px;
    width: 208px;
    margin-bottom: 25px;
  }

  hr {
    margin-left: -50px;
    width: calc(100% + 100px);
  }
}
</style>

<style lang="scss">
#actions-new-attachment-modal {
  @import '@/assets/scss/vendors/_v-select';

  .modal-content {
    width: 70vw;
    height: auto;
  }

  .modal-body {
    min-height: 60vh;
    padding: 0 46px 0;
    overflow: hidden;
  }
}
</style>
