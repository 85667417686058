<template>
  <div>
    <div class="suggestions card">
      <!-- header -->
      <campaigns-management-card-header :campaign="campaign" />
      <hr class="solid mt-1 mb-3" />
      <!-- content -->
      <campaigns-management-card-content :campaign="campaign" />
    </div>
  </div>
</template>

<script>
import CampaignsManagementCardHeader from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementCardHeader';
import CampaignsManagementCardContent from '@/components/oto/campaignsManagement/_molecules/CampaignsManagementCardContent';

export default {
  name: 'SuggestionCardItem',
  components: {
    CampaignsManagementCardHeader,
    CampaignsManagementCardContent,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestions {
  padding-bottom: 5px;

  hr {
    margin-left: -25px;
    width: calc(100% + 50px);
  }
}
</style>
