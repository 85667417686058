<template>
  <header class="suggestion-card-header">
    <div class="sch__title-container col-11">
      <div class="sch__title-block">
        <p class="mb-0 mr-2">{{ $tc('_action', 1) }}:</p>
        <h2 v-if="campaign.action" class="sch__title mb-0 mr-2">
          {{ campaign.action }}
        </h2>
      </div>
      <div class="sch__icon-pencil" @click.prevent="redirect(campaign.id, $event)">
        <icon-base w="16" h="20" color="#6c90f4" box="0 0 16 20">
          <icon-pencil />
        </icon-base>
      </div>
      <div
        v-if="getHasCampaignApproval && campaign.campaignsApproval !== null && campaign.campaignsApproval.total > 0"
        class="d-flex"
      >
        <div class="sch__title-separator"></div>
        <div class="sch__title-block">
          <span class="sch__title mb-0">
            <strong>
              {{ campaign.campaignsApproval.participating }}
              {{ $tc('360.profile-resume.of') }}
              {{ campaign.campaignsApproval.total }}
              {{ $tc('360.push.audience.store') }}
            </strong>
            {{ $tc('360.campaigns.are-participating') }}:
            <a href="#" class="sch__redirect-participants" @click="redirect(campaign.id, $event)">
              {{ $tc('360.campaigns.see-list') }}
            </a>
          </span>
        </div>
      </div>
    </div>
    <div class="sch__toggle col-1">
      <toggle :enabled="campaign.enabled" :callback="updateToggle" />
    </div>
  </header>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapGetters, mapActions } from 'vuex';
import { useToast } from 'vue-toastification';
// services
import { postSwitchCampaign } from '@/services/actions';
//icons
import IconBase from '@/assets/vue-icons/IconBase';
import IconPencil from '@/assets/vue-icons/icons/Pencil';
// components
import Toggle from '@/components/_atoms/Toggle';

export default {
  name: 'CampaignsManagementCardHeader',
  components: {
    IconBase,
    IconPencil,
    Toggle,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isToggleActive: false,
      active: '',
    };
  },
  computed: {
    ...mapGetters(['getHasCampaignApproval']),
  },
  mounted() {
    this.getToggleValue();
  },
  updated() {
    this.getToggleValue();
  },
  methods: {
    ...mapActions('campaignsManagement', ['setActivePage']),
    redirect(id, event) {
      this.$router.push({
        name: 'oto-actions-edit-old',
        params: {
          id,
        },
      });
      if (event.target?.className === 'sch__redirect-participants') {
        return this.setActive('participants');
      }
      return this.setActive('composition');
    },
    setActive(menuItem) {
      this.setActivePage(menuItem);
    },
    updateToggle() {
      bus.$emit('toggle-suggestion-base-loader', true);

      this.isToggleActive = !this.isToggleActive;
      this.postToggle();
    },
    getToggleValue() {
      this.isToggleActive = this.campaign?.enabled;
    },
    postToggle() {
      bus.$emit('show-spinner', 'actions-campaings');

      postSwitchCampaign({
        actionId: this.campaign.id,
        enabled: Number(this.isToggleActive),
      })
        .then(({ data }) => {
          if (!data.success) throw Error();
          this.toast.success(this.$t('360.campaigns.toast.status'), {
            timeout: 5000,
          });
          bus.$emit('suggestion-campaign-refresh', () => {});
        })
        .catch(() => {
          bus.$emit('open-suggestion-campaign-modal-error');
          bus.$emit('hide-spinner', 'actions-campaings');
        })
        .finally(() => {
          bus.$emit('hide-spinner', 'actions-campaings');
        });
    },
  },
};
</script>

<style lang="scss">
.suggestion-card-header {
  display: flex;
  width: 100%;
  padding-bottom: 10px;

  .sch__title-container {
    display: flex;

    .sch__title-block {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: underline;
      }

      p {
        font-size: 14px;
      }

      .sch__title {
        font-size: 14px;
        color: $gray-700;
        margin-right: 10px;
      }
    }
    .sch__icon-pencil {
      cursor: pointer;
      display: flex;
    }
    .sch__title-separator {
      height: 32px;
      width: 1px;
      background: #dddddd;
      margin: 0px 30px;
    }
  }
  .sch__toggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
