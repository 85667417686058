<template>
  <div class="empty-message">
    <p>{{ $t('attribution.empty-message') }}</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.empty-message {
  display: flex;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  p {
    cursor: default;
    color: $gray-800;
    // font-weight: 600;
    font-size: 12px;
  }
}
</style>
