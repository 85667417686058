<template>
  <div :class="{ loadHeight: load }" class="card campaigns-detail-information">
    <div v-show="!load">
      <div class="row">
        <div class="col-2">
          <h3 class="card-title d-flex align-items-center">
            <span>{{ $tc('information', 0) }}</span>
          </h3>
        </div>
        <div v-if="data.audienceName" class="metric col">
          <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
            {{ $t('audiences.single-title') }}
          </div>
          <div class="data-value data-text">{{ data.audienceName }}</div>
        </div>
        <div v-if="data.audienceId" class="metric col">
          <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
            {{ $t('audience-campaign') }}
          </div>
          <div class="data-value data-text">{{ data.audienceId }}</div>
        </div>
        <div v-if="data.period.start && data.period.end" class="metric col">
          <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
            {{ $tc('period', 1) }}
          </div>
          <div class="data-value data-text">
            {{ data.period.start }} {{ $t('campaigns.detail.until') }} <br />
            {{ data.period.end }}
          </div>
        </div>
        <div v-if="data.investment" class="metric col">
          <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
            {{ $t('investment') }}
          </div>
          <div class="data-value data-text">
            {{ formatCurrency(data.investment) }}
          </div>
        </div>
        <div v-if="data.createBy" class="metric col">
          <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
            {{ $t('created-by') }}
          </div>
          <div class="data-value data-text">{{ data.createBy }}</div>
        </div>
        <div v-if="data.createdAt" class="metric col">
          <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
            {{ $t('creation-date') }}
          </div>
          <div class="data-value data-text">{{ data.createdAt }}</div>
        </div>
        <div v-if="data.lastUpdate" class="metric col">
          <div class="value-label d-flex justify-content-left align-items-center" style="white-space: nowrap">
            {{ $t('last-update') }}
          </div>
          <div class="data-value col data-text">{{ data.lastUpdate }}</div>
        </div>
      </div>
    </div>
    <information-skeletons v-show="load" />
  </div>
</template>

<script>
import { numberMixin } from '@/helpers/mixins';
import InformationSkeletons from '@/components/campaigns/detail/CampaignsDetailInformationSkeletons';

export default {
  components: {
    'information-skeletons': InformationSkeletons,
  },
  mixins: [numberMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
    load: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .data-text {
    color: $gray-700;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
