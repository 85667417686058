<template>
  <div id="allviews">
    <personas-filter-header />
    <omnichannel-overview />
    <conversion-sales />
    <all-segment-groups />
    <customer-base />
    <web-site-behavior v-if="$experimental" />
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import AllSegmentGroups from '@/views/AllSegmentGroups';
import OmnichannelOverview from '@/views/OmnichannelOverview';
import WebSiteBehavior from '@/views/WebSiteBehavior';
import ConversionSales from '@/views/ConversionSales';
import PersonasFilterHeader from '@/views/PersonasFilterHeader';
import CustomerBase from '@/views/CustomerBase';

export default {
  name: 'Allviews',
  components: {
    AllSegmentGroups,
    OmnichannelOverview,
    WebSiteBehavior,
    ConversionSales,
    PersonasFilterHeader,
    CustomerBase,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      getLastCollapsedComponent: '',
    };
  },
  mounted() {
    this.getLastCollapsedComponent = 'Omnichanneloverview';
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
    });
    bus.$on('render-cards', () => {
      bus.$emit('keep-last-collapsed-open', this.getLastCollapsedComponent);
    });
    bus.$on('is-active-collapse', id => {
      this.getLastCollapsedComponent = id;
    });
  },
  beforeUnmount() {
    bus.$off(['render-cards', 'is-active-collapse']);
  },
};
</script>
