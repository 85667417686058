import axios from 'axios';
import store from '@/store';

const controller = '360/profile';

/**
 * Get Last Purchases list
 *
 * @param {Number} customerId
 * @param {Number} limit (Optional) default = 6
 * @param {Number} page (Optional) default = 1
 * @param {String} orderby (Optional) default = 'date_desc'
 */
export const getLastPurchases = (customerId, limit = 6, page = 1, orderby = 'date_desc') => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/get_last_purchases`, {
      params: {
        brand: brand.id,
        pid: customerId,
        limit,
        page,
        orderby,
      },
    })
    .then(data => data.data);
};

/**
 * Get the Timeline data
 *
 * @param {Number} customerId
 */
export const getTimeline = customerId => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/get_timeline`, {
      params: {
        brand: brand.id,
        pid: customerId,
      },
    })
    .then(data => data.data);
};

/**
 * Get Order Details
 *
 * @param {Number} customerId
 * @param {Number} orderId
 * @param {Number} orderStore
 * @param {String} orderDate
 */
export const getOrderDetails = (customerId, orderId, orderStore, orderDate) => {
  const brand = store.getters.selectedBrand;
  return axios
    .get(`${controller}/get_order_details`, {
      params: {
        brand: brand.id,
        pid: customerId,
        order_id: orderId,
        order_store: orderStore,
        order_date: orderDate,
      },
    })
    .then(data => data.data);
};
