<template>
  <lightbox-template :id="id" :title="settingsData.card.title" :show-segment-group="false" :show-date="false">
    <div v-if="isFormatVertical" class="l-welcome__vertical col-12">
      <p class="col-6 l-welcome__text">
        {{ settingsData.card.text }}
      </p>
      <div class="col-6 l-welcome__video">
        <iframe
          frameborder="0"
          :src="buildIframeSrc(settingsData.card.lightbox.video)"
          width="200"
          height="360"
          allow="autoplay"
        ></iframe>
      </div>
    </div>
    <div v-else class="l-welcome__horizontal col-12">
      <img
        v-if="settingsData.card.lightbox.image"
        class="l-welcome__image"
        :src="settingsData.card.lightbox.image"
        :alt="settingsData.card.title"
      />
      <p class="l-welcome__text">
        {{ settingsData.card.text }}
      </p>
    </div>
    <welcome-contact />
  </lightbox-template>
</template>

<script>
// components
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import WelcomeContact from '@/components/welcome/_organisms/WelcomeContact';

export default {
  name: 'LightboxWelcomeCard',
  components: {
    LightboxTemplate,
    WelcomeContact,
  },
  props: {
    settingsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: 'lightbox-welcome-card',
    };
  },
  computed: {
    isFormatVertical() {
      return this.settingsData.card.lightbox.format === 'vertical';
    },
  },
  methods: {
    /**
     * @description receive regular share path and replace it to create a correct embeded link
     * @param {String} src
     */
    buildIframeSrc(src) {
      if (!src) return;
      return src.replace(/view.*/, 'preview');
    },
  },
};
</script>

<style lang="scss" scoped>
#lightbox-welcome-card {
  .l-welcome__text {
    padding: 0 20px 0 30px;
    overflow: hidden;
  }

  .l-welcome__image {
    width: 450px;
    height: 334px;
    background-size: contain;
    margin: 0 auto;
  }

  .l-welcome__vertical {
    display: flex;
    flex-direction: row;
    height: 412px;
  }

  .l-welcome__horizontal {
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
#lightbox-welcome-card {
  .container {
    width: 500px;
    min-height: 500px;
    height: auto;
    max-height: 765px;
  }
}
</style>
