<template>
  <div class="allsegmentgroups-clientsbyregion card">
    <div class="card-body">
      <div v-show="$_verifyLoaded('done')" class="row">
        <h2 class="card-title d-flex align-items-center">
          <i class="icon icon-customer"></i>
          <span>{{ $t('clients-by-region') }}</span>
        </h2>
        <div class="col-7">
          <div id="container"></div>
        </div>
        <div class="col-5 legend">
          <chart-list v-if="ready" :rows-list="buildListMap" preset="subtitle" direction="right" />
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// @ is an alias to /src
import { getPercentual, getMax } from '@/utilities/numbers';
import { formatPercent } from '@/utilities/formatters';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import ChartList from '@/components/_organisms/ChartList';
import miniBarChartMixin from '@/helpers/mixins/miniBarChartMixin';
import getMetrics from '@/services/getmetrics';
import brazilMap from '@/maps/br-all.geo.json';
import bus from '@/helpers/events/bus';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

let dataMundo = '';
if (typeof window !== 'undefined') {
  HighchartsMap(Highcharts);
  Highcharts.maps['custom/br/br-all'] = brazilMap;
  dataMundo = Highcharts.geojson(brazilMap);
}

export default {
  name: 'AllSegmentGroupsClientsByRegion',
  components: {
    'chart-list': ChartList,
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [miniBarChartMixin, verifyMixin],
  data() {
    return {
      ready: false,
      options: [],
    };
  },
  computed: {
    list() {
      const datavalue = this.ready ? this.options.data.series[0].data : [];
      const listtop = Object.entries(datavalue);
      return listtop.slice(0, 5);
    },
    totalamount() {
      const datavalue = this.ready ? this.options.data.series[0].data : null;
      const total = Object.values(datavalue).reduce((t, n) => t + n);
      return total;
    },
    arrayMapChart() {
      let arraymap = {};
      const datavalue = this.ready ? this.options.data.series[0].data : null;
      if (datavalue) {
        arraymap = Object.entries(datavalue).map(elem => [`br-${elem[0].toLowerCase()}`, elem[1]]);
      }
      return arraymap;
    },
    buildListMap() {
      const max = getMax(this.list);
      return Object.keys(this.list).map(key => ({
        label: this.GetNamesMap('br-' + this.list[key][0].toLowerCase()),
        progress: String(getPercentual(this.list[key][1], max)),
        value: formatPercent(this.list[key][1] / this.totalamount),
        color: 'lightblue',
      }));
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        // segmento default
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    createChartClientsByState(seriesMapa) {
      Highcharts.mapChart('container', {
        chart: {
          marginTop: 0,
          marginBottom: 0,
          spacingTop: 0,
          spacingBottom: 0,
          height: 210,
          style: {
            fontFamily: 'Montserrat',
          },
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: null,
        },
        subtitle: {
          text: '',
        },
        colorAxis: {
          min: 0,
          minColor: '#F0F6FF',
          maxColor: '#6c90f4',
        },
        mapNavigation: {
          enabled: false,
          buttonOptions: {
            verticalAlign: 'bottom',
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          backgroundColor: '#fff',
          color: '#444',
          padding: 13,
          headerFormat:
            '<b style="font-family: Montserrat; font-size: 12px; padding-bottom: 5px;">{series.name}</b><br/>',
          pointFormat:
            '<span style="font-family: Montserrat;height: 100px; color:{point.color}">\u25CF</span> {point.name}: <b>{point.value}</b><br/>',
          useHTML: true,
        },
        series: [
          {
            showInLegend: false,
            data: seriesMapa,
            mapData: brazilMap,
            name: this.$t('localization'),
            joinBy: 'hc-key',
            states: {
              hover: {
                color: 'darkblue',
              },
            },
          },
        ],
      });
    },
    GetNamesMap(key) {
      let nameMap = '';
      dataMundo.forEach(data => {
        if (data.properties['hc-key'] === key) {
          nameMap = data.properties.name;
        }
      });
      return nameMap;
    },
    fetch_data() {
      this.$_reqConfig();

      // valor total de before pela data inicial
      getMetrics({
        metrics: 'arr_country_state',
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          this.options = response.data.metrics.arr_country_state;
          this.ready = this.options.has_data;
          if (this.ready) this.createChartClientsByState(this.arrayMapChart);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  order: 1;
}
.card {
  @media (min-width: $xl-min-width) {
    height: 290px;
  }

  @media (max-width: $tablet-max-width) {
    height: 310px;
  }
}

.legend {
  padding-left: 18px;
}

.mini-bar {
  display: inline-block;
  height: 8px;
  max-width: 100%;
  margin-right: 5px;
}

@for $i from 1 through 100 {
  .percentage-#{$i} {
    $value: ($i * 1%);
    width: $value;
    transition: width 500ms cubic-bezier(0.25, 0.1, 0.25, 0.1);
  }
}
</style>
