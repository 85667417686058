<template>
  <div class="history-import">
    <history-header :title="$t('integrations.history-import')" :has-icon="true">
      <template v-slot:select-group>
        <history-import-selects />
      </template>
    </history-header>
    <history-table :id="id" :pagination="pagination" :limit-range="limitRange" :refresh-list="refreshImportList">
      <template v-slot:table>
        <history-import-table />
      </template>
    </history-table>
  </div>
</template>

<script>
// libs
import { mapState, mapActions } from 'vuex';
// components
import HistoryHeader from '@/components/history/_organisms/HistoryHeader';
import HistoryImportSelects from '@/components/history/_molecules/HistoryImportSelects';
import HistoryTable from '@/components/history/_organisms/HistoryTable';
import HistoryImportTable from '@/components/history/_molecules/HistoryImportTable';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'HistoryImport',
  components: {
    HistoryHeader,
    HistoryImportSelects,
    HistoryTable,
    HistoryImportTable,
  },
  computed: {
    ...mapState('historyImport', ['id', 'pagination', 'limitRange']),
  },
  mounted() {
    useMixpanelConfig('view_integrations_import');

    // datepicker range is disable on mount
    this.$isDatePickerRangeActive = false;
    // set new state and force datepicker to refresh
    this.updateSelectedBrand(true);
  },
  beforeUnmount() {
    // datepicker range is enable before destroy
    this.$isDatePickerRangeActive = true;
  },
  methods: {
    ...mapActions('historyImport', ['refreshImportList']),
    ...mapActions('base', ['updateSelectedBrand']),
  },
};
</script>
