<template>
  <base-card-v2 class="retention-card">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header :title="$t('retention-title')" icon="icon-customer">
        <template v-slot:item-right>
          <i class="icon icon-expand ml-auto" @click="openLightbox('lightbox-retention')"></i>
        </template>
      </base-card-header>
      <div class="row chart-group">
        <div class="col">
          <label class="chart-title d-block">{{ $t('before') }}</label>
          <span
            v-if="firstDay.hasData"
            class="chart-value-big"
            :class="!(lastDay.amount > firstDay.amount) ? ' color-oto-brand-omni' : ' color-oto-brand-omni'"
          >
            {{ $_formatDecimal(firstDay.amount) }}</span
          >
          <span v-else class="chart-value-big color-gray">-</span>
        </div>
      </div>
      <div class="row chart-group">
        <div class="col-12">
          <minibarchart :prop-data="JsonClientVariation" />
        </div>
      </div>
      <div class="row chart-group">
        <div class="col-12">
          <label class="chart-title d-block">{{ $t('after') }}</label>
          <span
            v-if="lastDay.hasData"
            class="chart-value-big d-flex align-items-center"
            :class="!(firstDay.amount > lastDay.amount) ? ' color-red' : ' color-red'"
          >
            {{ $_formatDecimal(lastDay.amount) }}
            <span v-if="differenceCustomers" class="label pl-2">
              {{ $_formatNumber(differenceCustomers, '0.[00]%') }}</span
            >
          </span>
          <span v-else class="chart-value-big color-gray">-</span>
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('infoThens')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
// @ is an alias to /src
import getMetrics from '@/services/getmetrics';
import MiniBarChart from '@/components/MiniBarChart';
import bus from '@/helpers/events/bus';
import { openLightbox } from '@/helpers/lightbox';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin, verifyThensMixin, numberMixin } from '@/helpers/mixins';
import { formatDecimal } from '@/utilities/formatters';
import { getNumeral } from '@/utilities/numbers';

export default {
  name: 'AllSegmentGroupsRentention',
  components: {
    minibarchart: MiniBarChart,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin, verifyThensMixin, numberMixin],
  props: {
    filterStore: {
      type: Boolean,
      default() {
        return false;
      },
    },
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: {
        loadingFirstDay: false,
        loadingLastDay: false,
        loadingMiniBarData: false,
        loadingAll: false,
      },
      ready: false,
      firstDay: {
        hasData: false,
        amount: undefined,
      },
      lastDay: {
        hasData: false,
        amount: undefined,
      },
      selectedStore: -100,
      inactivePercent: 0,
      newPercent: 0,
      recoveredPercent: 0,
      JsonClientVariation: {
        title: '',
        totalAmount: 1,
        suffix: '',
        prefix: '',
        data: [],
      },
      brand: null,
    };
  },
  computed: {
    dateStart() {
      return this.$store.getters.dates.startDate;
    },
    dateEnd() {
      return this.$store.getters.dates.endDate;
    },
    formatFirstDay() {
      return this.firstDay.hasData ? formatDecimal(this.firstDay.amount, 1) : '-';
    },
    formatLastDay() {
      return this.lastDay.hasData ? formatDecimal(this.lastDay.amount, 1) : '-';
    },
    differenceCustomers() {
      if (this.firstDay.hasData && this.lastDay.hasData) {
        return ((this.lastDay.amount - this.firstDay.amount) / (this.firstDay.amount || 1)) * 100;
      }
      return false;
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        // request API on change the selected brand or date
        if (this.filterStore === false) {
          this.fetchData();
          bus.$on('render-cards', () => {
            this.fetchData();
          });
        }
      }
    },
  },
  mounted() {
    // update selected store and request API on change store
    bus.$on('store-changed', value => {
      this.selectedStore = value;
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('store-changed');
  },
  methods: {
    fetchFirstDay() {
      this.componentHasData = [];

      this.$_reqConfig();
      this.loader.loadingFirstDay = true;

      getMetrics({
        metrics: 'customers_active',
        startdate: this.dateStart,
        enddate: this.dateStart,
        version: 2,
        ...(this.filterStore && {
          endpoint: 'stores/get_metrics',
          filters: `store_id:${this.selectedStore}`,
        }),
      })
        .then(({ data }) => {
          if (this.$_verifyHasData(data.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          const customers = data.metrics.customers_active;
          this.firstDay.hasData = customers.has_data;
          this.firstDay.amount = customers.data.value.amount;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loader.loadingFirstDay = false;
          this.$_verifLoading();
        });
    },
    fetchLastDay() {
      this.loader.loadingLastDay = true;

      getMetrics({
        metrics: 'customers_active',
        startdate: this.dateEnd,
        enddate: this.dateEnd,
        version: 2,
        ...(this.filterStore && {
          endpoint: 'stores/get_metrics',
          filters: `store_id:${this.selectedStore}`,
        }),
      })
        .then(response => {
          const data = response?.data;
          if (this.$_verifyHasData(data?.metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          const customers = data.metrics.customers_active;
          this.lastDay.hasData = customers.has_data;
          this.lastDay.amount = customers.data.value.amount;
        })
        .finally(() => {
          this.loader.loadingLastDay = false;
          this.$_verifLoading();
        });
    },
    fetchMinibarData() {
      this.loader.loadingMiniBarData = true;

      getMetrics({
        metrics: ['customers_new', 'customers_regained', 'customers_inactive'],
        ...(this.filterStore && {
          endpoint: 'stores/get_metrics',
          filters: `store_id:${this.selectedStore}`,
        }),
      })
        .then(({ data: { metrics } }) => {
          if (this.$_verifyHasData(metrics)) {
            this.componentHasData.push(false);
            this.$_verifyThenIsEmpty();
            return;
          } else this.componentHasData.push(true);

          // reset array
          this.JsonClientVariation.data = [];
          this.JsonClientVariation.data.length = 0;
          this.JsonClientVariation.title = this.$t('client-variation');

          const inactivePercent = getNumeral(metrics.customers_inactive.data.value.amount);
          const newPercent = getNumeral(metrics.customers_new.data.value.amount);
          const recoveredPercent = getNumeral(metrics.customers_regained.data.value.amount);

          let bigger = inactivePercent;

          if (newPercent > bigger) bigger = newPercent;
          if (recoveredPercent > bigger) bigger = recoveredPercent;

          this.inactivePercent = ((inactivePercent / bigger) * 100).toFixed(0);
          this.newPercent = ((newPercent / bigger) * 100).toFixed(0);
          this.recoveredPercent = ((recoveredPercent / bigger) * 100).toFixed(0);

          // INACTIVE data array
          this.JsonClientVariation.data.push({
            text: this.$t('inactive'),
            type: 'percentage',
            amount: formatDecimal(inactivePercent),
            percentageamount: this.inactivePercent,
            colorbase: 'red',
          });

          // NEW data array
          this.JsonClientVariation.data.push({
            text: this.$t('new'),
            type: 'percentage',
            amount: formatDecimal(newPercent),
            percentageamount: this.newPercent,
            colorbase: 'blue',
          });
          // Recovered data array
          this.JsonClientVariation.data.push({
            text: this.$t('recovered'),
            type: 'percentage',
            amount: formatDecimal(recoveredPercent),
            percentageamount: this.recoveredPercent,
            colorbase: 'blue',
          });
        })
        .finally(() => {
          this.loader.loadingMiniBarData = false;
          this.$_verifLoading();
        });
    },
    fetchData() {
      this.loader.loadingAll = true;
      this.brand = this.$store.getters.selectedBrand?.id;

      Promise.all([this.fetchMinibarData(), this.fetchFirstDay(), this.fetchLastDay()])
        .then(() => {
          this.ready = true;
        })
        .finally(() => {
          this.loader.loadingAll = false;
          this.$_verifLoading();
        });
    },
    openLightbox(id) {
      openLightbox(id);
    },
  },
};
</script>
<style lang="scss" scoped>
.retention-card {
  min-height: 290px;
}
.color-gray {
  color: $gray-600;
}
</style>
