<template>
  <div>
    <label>{{ $t(`cdp.${cardName}.${labelName.replace(/_/g, '-')}`) }}</label>
    <span class="price">{{ getNumeralType(metricValue) }}</span>
    <span class="variation" :class="getVariationDirection(metricValue)">
      {{ getVariationNumber() }}
      <span class="tooltip-metrics">
        <i
          class="icon icon-question-12px"
          v-tooltip="{
            theme: 'default',
            content: $t('cdp.tooltip'),
            distance: 8,
          }"
        ></i>
      </span>
    </span>
  </div>
</template>

<script>
import { formatCurrency, formatDecimal, formatPercent, formatNumber } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

export default {
  name: 'MetricItem',
  props: {
    labelName: {
      type: String,
      default: '',
    },
    metricValue: {
      type: Object,
      default: () => {},
    },
    variation: {
      type: Number,
      default: null,
    },
    cardName: {
      type: String,
      default: '',
    },
  },
  methods: {
    getNumeralType(metric) {
      let value = 0;
      if (metric.type === valueType.float) {
        value = formatCurrency(metric.value);
      } else if (metric.type === valueType.integer) {
        value = formatDecimal(metric.value, 1);
      } else if (metric.type === valueType.percent) {
        value = formatNumber(metric.value, '% 0.00');
      } else if (metric.type === valueType.decimal) {
        value = formatDecimal(metric.value, 2);
      }
      return value;
    },
    getSign() {
      return this.variation > 0 ? '+' : '';
    },
    getVariationNumber() {
      let variationType = '';
      if (this.variation === null) {
        variationType = 'N/A';
      } else {
        return this.getSign() + formatPercent(this.variation / 100, 2);
      }
      return variationType;
    },
    getVariationDirection(metric) {
      const moreIsBetter = metric.positive === 1;
      const isPositiveVariation = metric.variation >= 0;
      if (this.variation === null) {
        return 'none';
      }
      if (moreIsBetter) {
        return 'green';
      }
      if (!isPositiveVariation || !moreIsBetter) {
        return 'red';
      }
      return 'green';
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  display: inline-block;
  color: $gray-700;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  label {
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    display: block;
  }
  .price {
    font-size: 12px;
    margin-right: 5px;
  }
  .variation {
    font-size: 11px;
    display: inline-flex;
    &.green {
      color: $oto-ecommerce;
    }
    &.red {
      color: $color-red;
    }
    &.none {
      color: $gray-500;
    }

    i {
      font-size: 12px;
    }
  }
}
.tooltip-metrics {
  color: $gray-500;
  padding: 1.5px 0 0 4px;
}
</style>
