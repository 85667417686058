<template>
  <section class="suggestion-details">
    <base-card-v2 class="section">
      <div v-show="!loadingSuggestionsDetail" class="suggestion-details__title">
        <base-card-header :title="title" svg="Oto"></base-card-header>
        <div class="suggestion-details__status" v-if="isEditMode">
          <strong>Status: </strong>
          <span
            :class="{
              'suggestion-details__status--active': suggestionDetail.enabled,
              'suggestion-details__status--inactive': !suggestionDetail.enabled,
            }"
            >{{ suggestionDetail.enabled ? $t('item-active') : $t('item-inactive') }}</span
          >
        </div>
      </div>
      <campaigns-management-details-group v-show="!loadingSuggestionsDetail" :loading="loadingSuggestionsDetail" />
      <base-loader v-if="loadingSuggestionsDetail" />
    </base-card-v2>
    <!-- suggestion modal dialogs -->
    <campaigns-management-dialog-error-modal />
  </section>
</template>

<script>
// libs
import { useToast } from 'vue-toastification';
import { mapActions, mapState, mapGetters } from 'vuex';

// helpers
import bus from '@/helpers/events/bus';
// components - organisms
import CampaignsManagementDetailsGroup from '@/components/oto/campaignsManagement/_organisms/CampaignsManagementDetailsGroup';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
// base modals - ex: success, error
import CampaignsManagementDialogErrorModal from '@/components/oto/campaignsManagement/modals/CampaignsManagementDialogErrorModal';

export default {
  name: 'SuggestionDetail',
  components: {
    CampaignsManagementDetailsGroup,
    CampaignsManagementDialogErrorModal,
    BaseLoader,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  computed: {
    ...mapState('campaignsManagement', ['loadingSuggestionsDetail', 'showToastSuccess', 'suggestionDetail']),
    ...mapGetters(['getHasCampaignApproval']),

    isEditMode() {
      return this.mode === 'edit';
    },
    title() {
      return this.isEditMode ? this.$t('360.campaigns.details.edit') : this.$t('360.campaigns.details.new');
    },
  },
  watch: {
    showToastSuccess() {
      if (this.showToastSuccess) this.showToast();
    },
    mode() {
      this.setMode(this.mode);
    },
  },
  beforeMount() {
    this.setMode(this.mode);
  },
  mounted() {
    if (this.isEditMode) this.setActionId(this.id);

    bus.$on('suggestion-trigger-reload', ({ id }) => {
      this.setActionId(id);
    });
  },
  beforeUnmount() {
    this.resetData();
  },
  methods: {
    ...mapActions('campaignsManagement', ['setMode', 'setActionId', 'resetData']),
    showToast() {
      const text = this.isEditMode ? this.$t('360.campaigns.toast.updated') : this.$t('360.campaigns.toast.created');
      this.toast.success(text);

      setTimeout(() => {
        this.toast.clear();
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.suggestion-details {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__status {
    display: flex;
    align-items: center;

    strong {
      margin-right: 4px;
      color: #555555;
      font-weight: 600;
    }

    &--active,
    &--inactive {
      display: inline-flex;
      align-items: center;
      padding: 2px 10px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 12px;
      font-weight: 500;

      &::before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 6px;
        border-radius: 50%;
      }
    }

    &--active {
      background-color: #e0ffe9;
      color: #27ae60;

      &::before {
        background-color: #27ae60;
      }
    }

    &--inactive {
      color: #888888;
      background: rgba(244, 244, 244, 1);

      &::before {
        background-color: #888;
      }
    }
  }

  .section {
    min-height: 800px;
  }

  hr {
    margin-left: -50px;
    width: calc(100% + 100px);
  }
}
</style>
