<template>
  <el-dialog v-model="dataIsVisible" :id="'modal-dashed'" :class="modalType" append-to-body>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body" :class="{ 'border-red': isRed }">
          <slot></slot>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isRed: {
      type: Boolean,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataIsVisible: false,
    };
  },
  watch: {
    isVisible: function () {
      if (this.isVisible) {
        return this.openModal();
      }
      return this.closeModal();
    },
    dataIsVisible: function () {
      if (!this.dataIsVisible) this.$emit('closeModalDashed', this.dataIsVisible);
    },
  },
  methods: {
    openModal() {
      this.dataIsVisible = true;
    },
    closeModal() {
      this.dataIsVisible = false;
      this.$emit('closeModalDashed', this.dataIsVisible);
    },
  },
};
</script>

<style lang="scss">
#modal-dashed {
  align-items: center;
  max-height: 100vh;
  width: 540px;

  .el-dialog__body {
    padding: 10px 0;
  }

  .el-dialog__header {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
  }

  .modal-dialog {
    margin: 0 auto;
    max-width: 520px;

    .modal-content {
      border: unset;
      height: 100%;

      .modal-body {
        padding: 25px 40px;
        border: 2px dashed $oto-omni;
        border-radius: 10px;
        border-color: $oto-omni;

        &.border-red {
          border-color: $color-red;
        }
      }
    }
  }
}
</style>
