<template>
  <div class="tbl-shell">
    <div class="tbl-body">
      <base-loader v-show="loading" :height="'30'" />
      <template v-if="!loading && selectedOption === 'revenue'">
        <div class="tbl-row">
          <div class="tbl-col header">{{ $tc('dashboard.sent', 2) }}</div>
          <div v-for="(item, index) in metricValues" :key="index" class="tbl-col">
            <div>{{ validateMetric(item.sent) }}</div>
          </div>
        </div>
        <div class="tbl-row">
          <div class="tbl-col header">{{ $tc('dashboard.revenue', 1) }}</div>
          <div v-for="(item, index) in metricValues" :key="index" class="tbl-col">
            <div>{{ validateMetric(item.revenue) }}</div>
          </div>
        </div>
      </template>
      <template v-if="!loading && selectedOption === 'engagement'">
        <div class="tbl-row">
          <div class="tbl-col header">{{ $tc('dashboard.sent', 2) }}</div>
          <div v-for="(item, index) in metricValues" :key="index" class="tbl-col">
            <div>{{ validateMetric(item.sent) }}</div>
          </div>
        </div>
        <div class="tbl-row">
          <div class="tbl-col header">{{ $tc('dashboard.or', 1) }}</div>
          <div v-for="(item, index) in metricValues" :key="index" class="tbl-col">
            <div>{{ validateMetric(item.open_rate) }}</div>
          </div>
        </div>
        <div class="tbl-row">
          <div class="tbl-col header">{{ $tc('dashboard.ctr', 1) }}</div>
          <div v-for="(item, index) in metricValues" :key="index" class="tbl-col">
            <div>{{ validateMetric(item.ctr) }}</div>
          </div>
        </div>
        <div class="tbl-row">
          <div class="tbl-col header">{{ $tc('dashboard.cto', 1) }}</div>
          <div v-for="(item, index) in metricValues" :key="index" class="tbl-col">
            <div>{{ validateMetric(item.cto) }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import { formatPercent } from '@/utilities/formatters';

export default {
  name: 'DashboardMonthYearTable',
  components: {
    BaseLoader,
  },
  props: {
    monthYearTable: {
      type: Object,
      default: null,
    },
    labels: {
      type: Array,
      default: null,
    },
    selectedOption: {
      type: String,
      default: 'revenue',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      metricValues: [],
      dataMonthYearTable: {},
    };
  },
  watch: {
    monthYearTable(newVal) {
      this.dataMonthYearTable = newVal;
      this.metricValues = this.resolveMetricValues();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.metricValues = this.resolveMetricValues();
      this.dataMonthYearTable = this.monthYearTable;
    });
  },
  methods: {
    validateMetric(metric) {
      if (!metric) return '-';
      return formatPercent(metric, 2);
    },
    resolveMetricValues() {
      const metricGroup = [];
      this.labels.map(date => {
        const metrics = this.monthYearTable[date];
        const { revenue_ga: revenue, sent, open_rate, cto, ctr } = { ...metrics };
        metricGroup.push({ revenue, sent, open_rate, cto, ctr });
      });
      return metricGroup;
    },
  },
};
</script>

<style lang="scss" scoped>
.tbl-shell {
  margin: 0 -25px -25px;

  .tbl-body {
    min-height: 92px;
    position: relative;

    .tbl-row {
      display: flex;
      padding: 14px 118px 14px 25px;
      font-size: 12px;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      width: 100%;

      &:last-child {
        border-radius: 0 0 8px 8px;
        border: 0;
      }
      &:nth-child(odd) {
        background: #eee5;
      }
      &:hover {
        background: #ecc84720;
      }

      .tbl-col {
        color: $gray-600;
        flex-grow: 1;
        max-width: 50px;
        min-width: 50px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        font-weight: normal;

        &.header {
          text-align: left;
          font-weight: 600;
          max-width: 80px;
          min-width: 80px;
        }
      }
    }
  }
}
</style>
