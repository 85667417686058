<template>
  <div id="settings">
    <div class="row">
      <div class="col-lg-3 col-5">
        <settings-menu :routes="filterSettingsRoutes" />
      </div>
      <div class="col-lg-9 col-7">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsMenu from '@/components/settings/SettingsMenu';

export default {
  name: 'Settings',
  components: {
    SettingsMenu,
  },
  computed: {
    filterSettingsRoutes() {
      const routes = this.filterRoutesBySublevel.find(item => item?.id === 'settings').routes;
      return routes.map(el => el?.id);
    },
    filterRoutesBySublevel() {
      if (!this.getSelectedBrandRoutes) return;
      return this.getSelectedBrandRoutes.filter(el => el.routes);
    },
    getSelectedBrandRoutes() {
      return this.$store.getters?.getRoutes;
    },
  },
};
</script>
