<template>
  <div class="col-lg-12 card sales-report__header">
    <div class="row">
      <div class="col-md-3 srh__title-wrapper">
        <i class="icon icon-money srh__icon" />
        <h1 class="srh__title">{{ $t('360.sales-report.header.title') }}</h1>
      </div>

      <div class="col-md-2 srh__item">
        <label>{{ $t('360.conversion-window') }}:</label>
        <p class="srh__conversion-window-text">
          {{ conversionWindowSelected }} {{ $tc('day', conversionWindowSelected) }}
        </p>
      </div>

      <div class="col-md-3 srh__item">
        <label>{{ $t('360.sales-report.header.label-segment') }}</label>
        <v-select
          v-model="segmentSelected"
          label="name"
          :clearable="false"
          class="w-100"
          :options="getSegments"
          :placeholder="$t('select')"
          @option:selected="selectSegmentId"
          data-cy="segment-by-select"
        >
          <template #option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #selected-option="{ name }">
            {{ $tc(name, 1) }}
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes" class="arrow-down"></span>
          </template>
          <template #no-options>
            {{ $t('360.sellers.no-results') }}
          </template>
        </v-select>
      </div>

      <div class="col-md-3 srh__item">
        <template v-if="segmentSelected.id === 'store'">
          <label>{{ $t('360.sales-report.header.label-store') }}</label>
          <v-select
            v-model="storeSelected"
            label="name"
            :clearable="false"
            class="w-100"
            :options="getStores"
            :filter-by="storesFilter"
            :placeholder="$tc('_select', 2)"
            @option:selected="selectStoreId"
            data-cy="store-select"
          >
            <template #option="{ name, id }">
              <span v-if="id" :title="`${name} - ${id}`">{{ $t(name) }} - {{ id }}</span>
              <span v-else>{{ $t(name) }}</span>
            </template>
            <template #selected-option="{ name, id }">
              <span v-if="id">{{ $t(name) }} - {{ id }}</span>
              <span v-else>{{ $t(name) }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </template>

        <template v-if="segmentSelected.id === 'seller'">
          <label>{{ $t('360.sales-report.header.label-seller') }}</label>
          <v-select
            v-model="sellerSelected"
            label="fullName"
            :clearable="false"
            class="w-100"
            :options="getSellers"
            :filter-by="sellersFilter"
            :placeholder="$t('select')"
            @search="searchTerm"
            @option:selected="changeSeller"
            data-cy="seller-select"
          >
            <template #option="{ fullName, enrolment }">
              <span v-if="enrolment" :title="`${fullName} - ${enrolment}`">{{ $t(fullName) }} - {{ enrolment }}</span>
              <span v-else>{{ $t(fullName) }}</span>
            </template>
            <template #selected-option="{ fullName, enrolment }">
              <span v-if="enrolment">{{ $t(fullName) }} - {{ enrolment }}</span>
              <span v-else>{{ $t(fullName) }}</span>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </template>

        <template v-if="segmentSelected.id === 'campaign'">
          <label>{{ $t('360.sales-report.header.label-campaign') }}</label>
          <v-select
            v-model="campaignSelected"
            label="name"
            :clearable="false"
            class="w-100"
            :options="getCampaigns"
            :placeholder="$tc('_select', 2)"
            @option:selected="selectCampaignId"
            data-cy="campaign-select"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('360.sellers.no-results') }}
            </template>
          </v-select>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// libs
import vSelect from 'vue-select';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';

// helpers
import bus from '@/helpers/events/bus';

export default {
  name: 'SalesReportHeader',
  components: {
    vSelect,
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  data() {
    return {
      conversionWindowSelected: 30,
      segmentSelected: '',
      storeSelected: null,
      sellerSelected: null,
      campaignSelected: null,
    };
  },
  computed: {
    ...mapGetters('salesReport', ['getConversionWindow', 'getSegments', 'getStores', 'getSellers', 'getCampaigns']),
    ...mapGetters(['selectedBrand']),
  },
  created() {
    this.initConversionWindowOptions();
  },
  mounted() {
    this.setStores();
    this.setSellers();
    this.setCampaigns();
    this.resetSelected();

    bus.$on('render-cards', days => {
      this.verifyResetValues(days);
    });

    bus.$on('update-selected-brand', () => {
      this.initConversionWindowOptions();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
    bus.$off('update-selected-brand');
  },
  methods: {
    ...mapActions('salesReport', [
      'setConversionWindow',
      'setStores',
      'setSellers',
      'setCampaigns',
      'setSegmentId',
      'setSellerId',
      'setStoreId',
      'setCampaignId',
      'setSalesReportByTerm',
      'resetSelected',
    ]),

    verifyResetValues(days) {
      if (days?.brand) {
        this.setStores();
        this.setSellers();
        this.setCampaigns();
        this.resetSelected();
        this.resetSelectedModel();
      }

      this.datepickerLongRequestToast(days);
    },

    selectSegmentId(segment) {
      if (segment.id === 'general') {
        this.setStoreId('');
        this.setSellerId('');
        this.setCampaignId('');

        this.storeSelected = null;
        this.sellerSelected = null;
        this.campaignSelected = null;

        bus.$emit('srh-change-segment-general');
      }

      bus.$emit('selected-segment', segment);

      if (segment && segment.id) this.setSegmentId(segment.id);
    },

    initConversionWindowOptions() {
      const { lookback } = this.selectedBrand;
      this.conversionWindowSelected = lookback;
      this.setConversionWindow(lookback);
    },

    resetSelectedModel() {
      this.storeSelected = null;
      this.sellerSelected = null;
      this.campaignSelected = null;
      this.segmentSelected = '';
    },

    changeSeller(seller) {
      if (seller) this.setSellerId(seller.id);

      // reset storeId and campaignId
      this.setStoreId(null);
      this.storeSelected = null;
      this.setCampaignId(null);
      this.campaignSelected = null;

      bus.$emit('srh-change-seller');
    },
    selectStoreId(store) {
      if (store) this.setStoreId(store.id);

      // reset sellerId and campaignId
      this.setSellerId(null);
      this.sellerSelected = null;
      this.setCampaignId(null);
      this.campaignSelected = null;

      bus.$emit('srh-change-store');
    },
    selectCampaignId(campaign) {
      if (campaign) this.setCampaignId(campaign.id);

      // reset sellerId ans storeId
      this.setStoreId(null);
      this.storeSelected = null;
      this.setSellerId(null);
      this.sellerSelected = null;

      bus.$emit('srh-change-campaign');
    },

    /**
     * @description stores filter options with two labels
     */
    storesFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name.toLowerCase()?.includes(temp) || option?.id?.includes(temp);
    },

    /**
     * @description sellers filter options with two labels
     */
    sellersFilter(option, name, search) {
      const temp = search.toLowerCase();
      return name.toLowerCase()?.includes(temp) || option?.enrolment?.toLowerCase()?.includes(temp);
    },

    /**
     * @description search by term
     */
    searchTerm(term) {
      if (!term) return;
      this.triggerDebounce(term);
    },

    /**
     * @description set term on seller store
     */
    triggerDebounce: debounce(function (term) {
      this.setSalesReportByTerm({ term });
    }, 500),

    /**
     * @description A condition to show a toast if the difference between the search days is higher than 90
     */
    datepickerLongRequestToast(days) {
      if (days > 90) {
        this.toast.warning(this.$t('datepicker-long-request-toast'), {
          timeout: 6000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sales-report__header {
  .srh__icon {
    font-size: 30px;
    color: $gray-500;
  }

  .srh__title-wrapper {
    display: flex;
    align-items: center;
  }
  .srh__title {
    margin: 0;
    padding-left: 10px;
    color: $gray-800;
    font-weight: 600;
    font-size: 18px;
  }

  .srh__conversion-window-text {
    font-weight: 600;
    margin-top: 7px;
  }
}
</style>

<style lang="scss">
.sales-report__header {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
