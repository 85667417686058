<template>
  <div class="audience-count" data-cy="audience-count">
    <p>
      {{ $t(`audiences.${people === undefined ? 'add-condition' : 'people-in-audience'}`) }}
    </p>
    <p class="number">
      <i class="icon icon-customer" data-cy="audience-count-icon"></i>
      <span v-if="showCount" data-cy="audience-count-number">{{ countPeople }}</span>
      <span v-else>-</span>
      <i
        v-if="isLookALikeActive"
        class="icon icon-question-12px tooltip-audience-count"
        v-tooltip="{
          theme: 'default',
          content: `<div class='tooltip-expansion-count'>${updateTitleExpansion}</div>`,
          distance: 8,
          html: true,
        }"
      ></i>
      <span v-if="showTime" class="time">
        <span v-if="time < 5">{{ $t('audiences.updatedAt.now') }}</span>
        <span v-if="time >= 5 && time < 60">{{
          $t('audiences.updatedAt.atTime', { time: Number.parseInt(time) })
        }}</span>
        <span v-if="time > 60">{{ $t('audiences.updatedAt.long-time') }}</span>
      </span>
      <span v-if="showCalculating" class="time">{{ $t('audiences.calculating') }}...</span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { formatDecimal } from '@/utilities/formatters';

export default {
  props: {
    people: {
      type: Number,
      default: null,
    },
    time: {
      type: Number,
      default: null,
    },
    isView: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showCount() {
      return !this.requestCount && this.people !== undefined;
    },
    showTime() {
      return !this.requestCount && this.people !== undefined && !this.isView;
    },
    showCalculating() {
      return this.requestCount && !this.isView;
    },
    countPeople() {
      const expansionData = this.lookALikeData[this.$store.state.audience.expansion];
      return this.isLookALikeActive && expansionData
        ? formatDecimal(this.people + expansionData, 2)
        : formatDecimal(this.people, 2);
    },
    updateTitleExpansion() {
      return `Audiência: <b>${formatDecimal(this.people, 2)}</b><br>Expansão: <b>
        ${formatDecimal(this.lookALikeData[this.$store.state.audience.expansion], 2)}</b>`;
    },
    ...mapState('audience', ['requestCount', 'lookALikeData', 'isLookALikeActive']),
  },
};
</script>

<style lang="scss" scoped>
.audience-count {
  p {
    color: $gray-800;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 0;
    cursor: default;
    &.number {
      font-size: 16px;
      margin-top: 7px;
    }
    .icon {
      margin-right: 5px;
      color: $gray-600;
    }
  }
  .time {
    font-size: 12px;
    font-weight: normal;
    color: $gray-600;
    display: block;
    margin-left: 14px;
    cursor: default;
  }
  .tooltip-audience-count {
    font-size: 16px;
    margin: 2px 0 0 6px;
    color: $gray-500;
  }
}
</style>

<style lang="scss">
.tooltip-expansion-count {
  text-align: left;

  b {
    text-decoration: none !important;
  }
}
</style>
