<template>
  <section id="customer-view">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <customer-resume />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <customer-labels />
      </div>
    </div>
    <div class="row d-flex">
      <customer-cashback :customer-id="id" />
      <customer-sizes />
      <most-purchased-categories />
    </div>
    <div class="row timeline">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <time-line />
      </div>
    </div>
    <div class="row d-flex">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <last-purchases />
      </div>
    </div>
  </section>
</template>

<script>
import bus from '@/helpers/events/bus';
import CustomerResume from '@/components/oto/customerview/CustomerResume';
import CustomerLabels from '@/components/oto/customerview/CustomerLabels';
import CustomerSizes from '@/components/oto/customerview/CustomerSizes';
import LastPurchases from '@/components/oto/customerview/LastPurchases';
import MostPurchasedCategories from '@/components/oto/customerview/MostPurchasedCategories';
import TimeLine from '@/components/oto/customerview/TimeLine';
import CustomerCashback from '@/components/oto/customerview/CustomerCashback';

export default {
  name: 'CustomerView',
  components: {
    CustomerResume,
    CustomerLabels,
    TimeLine,
    CustomerSizes,
    MostPurchasedCategories,
    LastPurchases,
    CustomerCashback,
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  watch: {
    $route(to) {
      bus.$emit('load-profile', to.params.id);
    },
  },
  mounted() {
    if (!this.id) {
      this.$router.push({
        name: '360-customers',
      });
    }
    bus.$emit('load-profile', this.id);
    bus.$on('update-selected-brand', this.back);
  },
  beforeUnmount() {
    bus.$off('update-selected-brand');
  },
  methods: {
    back() {
      this.$router.push({
        name: '360-customers',
        query: {
          page: this.pageHistory,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: $mobile-max-width) {
  .timeline {
    display: none;
  }
}
</style>
