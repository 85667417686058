import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI_ACTION = 'action';
const URI_CAMPAIGN = 'campaign';
const URI_ATTACHMENT = 'attachment';
const URI_MESSAGE = 'template';

/**
 * @see -
 */
export const postUpdateCampaign = ({ name, campaign, startDate, endDate, dayOfWeek, dayOfYear }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI_CAMPAIGN}/update`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      name,
      campaign,
      startDate,
      endDate,
      dayOfWeek: dayOfWeek.join(),
      dayOfYear: dayOfYear.join(),
    },
  });
};

/**
 * @see -
 */
export const getListPriority = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/listPriority`,
    params: {
      brand: brand.id,
    },
  });
};

export const getActionCategoryList = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getActionCategoryList`,
    params: {
      brand: brand.id,
    },
    validateStatus: () => true,
  });
};

/**
 * @see -
 */
export const putPriorityList = (actions, order) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI_ACTION}/putPriority`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      campaigns_order: order,
      actions,
    },
  });
};

/**
 * @see
 */
export const getActions = ({ limit, term, page } = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getActions`,
    params: {
      brand: brand.id,
      limit,
      term,
      page,
    },
  });
};

/**
 * @see -
 */
export const putAction = ({
  name,
  description,
  enabledApproval,
  stores,
  startDate,
  endDate,
  dayOfWeek,
  dayOfYear,
  campaigns,
  actionCategory,
}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI_ACTION}/putAction`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      name,
      description,
      enabledApproval,
      stores,
      startDate,
      endDate,
      dayOfWeek: dayOfWeek.join(),
      dayOfYear: dayOfYear.join(),
      campaigns,
      actionCategory,
    },
  });
};

/**
 * @see -
 */
export const updateAction = ({
  actionId,
  name,
  description,
  enabledApproval,
  stores,
  startDate,
  endDate,
  dayOfWeek,
  dayOfYear,
  campaigns,
  actionCategory,
}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI_ACTION}/update`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      actionId,
      name,
      description,
      enabledApproval,
      stores,
      startDate,
      endDate,
      dayOfWeek: dayOfWeek.join(),
      dayOfYear: dayOfYear.join(),
      campaigns,
      actionCategory,
    },
  });
};

/**
 * @see -
 */
export const getTemplateVariables = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getTemplateVariables`,
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @see -
 */
export const getTemplates = id => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getTemplates`,
    params: {
      brand: brand.id,
      id,
    },
  });
};

/**
 * @see -
 */
export const getAction = (id, enabledOnly = 0) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getAction`,
    params: {
      brand: brand.id,
      actionId: id,
      enabledOnly,
    },
  });
};

/**
 * @see -
 */
export const postSwitchCampaign = ({ actionId, enabled }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/switch`,
    params: {
      brand: brand.id,
      actionId,
      enabled,
    },
  });
};

/**
 * @see -
 */
export const getMessages = ({ templateId, moment, channel, scope, scopeValue, page, limit, orderBy }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getTemplates`,
    params: {
      brand: brand.id,
      templateId,
      moment,
      channel,
      scope,
      scopeValue,
      page,
      limit,
      orderBy,
    },
  });
};

/**
 * @see -
 */
export const putTemplate = ({ templateId, scope, scopeValue, channel, moment, text, enabled }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI_ACTION}/putTemplate`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    data: {
      brand: brand.id,
      templateId,
      scope,
      scopeValue,
      channel,
      moment,
      text,
      enabled,
    },
  });
};

export const deleteTemplate = ({ templateId, enabled = 0 }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI_ACTION}/putTemplate`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      templateId,
      enabled,
    },
  });
};

/**
 * @see -
 */
export const getAttachments = ({ attachmentId, term, scope, scopeValue, channel, page, limit, orderBy }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ATTACHMENT}/getAttachments`,
    params: {
      brand: brand.id,
      attachmentId,
      term,
      scope,
      scopeValue,
      channel,
      page,
      limit,
      orderBy,
    },
  });
};

/**
 * @see -
 */
export const putAttachment = ({
  attachmentId = null,
  name,
  description,
  url,
  file,
  scope,
  scopeValue,
  channel,
  startDate,
  endDate,
  type,
}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  const formData = new FormData();
  formData.append('brand', brand.id);
  formData.append('attachmentId', attachmentId ?? '');
  formData.append('name', name ?? '');
  formData.append('description', description ?? '');
  formData.append('scope', scope ?? '');
  formData.append('scopeValue', scopeValue ?? '');
  formData.append('channel', channel ?? '');
  formData.append('startDate', startDate ?? '');
  formData.append('endDate', endDate ?? '');
  formData.append('type', type ?? '');

  type === 'url' ? formData.append('url', url ?? '') : formData.append('file', file);

  return axios.post(`${URI_ATTACHMENT}/putAttachment`, formData, {
    baseURL: endpointVersion('v3'),
    headers: { 'content-type': 'multipart/form-data', Authorization: `Bearer ${tokenV3}` },
  });
};

/**
 * @see -
 */
export const deleteAttachment = ({ attachmentId }) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    method: 'POST',
    url: `${URI_ATTACHMENT}/putAttachment`,
    baseURL: endpointVersion('v3'),
    headers: { Authorization: `Bearer ${tokenV3}` },
    params: {
      brand: brand.id,
      attachmentId,
      enabled: 0,
    },
  });
};

/**
 * @see -
 */
export const getAudiences = ({ term, limit } = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getAudiences`,
    params: {
      brand: brand.id,
      term,
      limit,
    },
  });
};

/**
 * @description get all campaigns and related audiences
 */
export const getCampaigns = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${URI_ACTION}/getCampaigns`,
    params: {
      brand: brand.id,
    },
  });
};

/**
 * @description get render message
 */
export const getRenderMessage = message => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `${URI_MESSAGE}/renderMessage`,
    data: {
      brand: brand.id,
      message,
    },
  });
};
