
import { watch, defineComponent, reactive, toRefs, computed, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { fetchDataQuarantineDays, fetchPutQuarantineDays } from '@/features/GlobalQuarantineByPhone/services';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner.vue';
import i18n from '@/i18n';
import { GlobalQuarantineState } from '@/features/GlobalQuarantineByPhone/types';

const QUARANTINE_MINIMUM_STATIC_VALUE = 20;

export default defineComponent({
  name: 'GlobalQuarantine',
  components: {
    BaseLoader,
  },
  setup() {
    const toast = useToast();
    const state = reactive<GlobalQuarantineState>({
      selectedQuarantine: 'static',
      days: null,
      defaultDays: 0,
      isLoading: false,
      isShowingTooltip: false,
      params: {},
    });

    // Computed
    const invalidStaticQuarantine = computed(
      () => state.selectedQuarantine === 'static' && +state.days < QUARANTINE_MINIMUM_STATIC_VALUE,
    );
    const isSameValue = computed(() => +state.days === +state.defaultDays);
    const disabledButton = computed(() => isSameValue.value || invalidStaticQuarantine.value);

    // Methods
    const removeNotNumbers = (event: Event) => {
      const target = event.target as HTMLInputElement;
      state.days = target.value?.replace(/[^,0-9]+/g, '');
    };

    const handleInputValidation = () => {
      state.isShowingTooltip = invalidStaticQuarantine.value;
    };

    watch(
      () => state.days,
      () => {
        handleInputValidation();
      },
    );

    const resetDays = () => {
      state.days = null;
    };

    const setDefaultDays = () => {
      const isNull = state.defaultDays === null;
      state.days = isNull ? 0 : state.defaultDays;
    };

    const updateQuarantineType = (type: 'static' | 'isDynamic') => {
      if (type === 'isDynamic') resetDays();
      if (type === 'static') setDefaultDays();

      state.selectedQuarantine = type;
    };

    const resetValuesToDefault = valueQuarantine => {
      if (valueQuarantine === 'isDynamic') {
        state.defaultDays = null;

        updateQuarantineType('isDynamic');
      } else {
        state.defaultDays = valueQuarantine;

        updateQuarantineType('static');
      }
    };

    const loadInitialQuarantineData = () => {
      state.isLoading = true;

      fetchDataQuarantineDays()
        .then(response => {
          if (response.data.quarantine === 'isDynamic') {
            state.selectedQuarantine = 'isDynamic';
          } else {
            state.selectedQuarantine = 'static';
            state.days = response.data.quarantine;
            state.defaultDays = state.days;
          }
        })
        .finally(() => {
          state.isLoading = false;
        });
    };

    const saveQuarantineSettings = () => {
      state.isLoading = true;

      const values = {
        isDynamic: { isDynamic: 1 },
        static: { days: +state.days },
      };

      state.params = values[state.selectedQuarantine];

      fetchPutQuarantineDays(state.params)
        .then(response => {
          if (response?.success) {
            toast.success(i18n.global.t('settings.metrics.quarantine.toast-success'), { timeout: 5000 });

            resetValuesToDefault(response?.data?.quarantine);
            return;
          }
          toast.error(i18n.global.t('settings.metrics.quarantine.toast-error'), { timeout: 5000 });
        })
        .finally(() => {
          state.isLoading = false;
        });
    };

    onMounted(loadInitialQuarantineData);

    return {
      ...toRefs(state),
      updateQuarantineType,
      saveQuarantineSettings,
      invalidStaticQuarantine,
      removeNotNumbers,
      disabledButton,
      loadInitialQuarantineData,
      resetValuesToDefault,
    };
  },
});
