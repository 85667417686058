<template>
  <div class="serie-create-edit">
    <div class="label">{{ $t('audiences.add-serie') }}</div>
    <div class="form">
      <div class="form-group column ml-0">
        <audience-column
          :key="`column-${group}-${id}`"
          :update-column-info="getSelectedColumn"
          :include="include"
          :data="condition"
          :serie="id"
          :group="group"
        />
      </div>
      <div v-show="!hasFilterKey()" class="form-group operator">
        <select v-show="!!operatorOptions.length" v-model="condition.operator" @change="updateValueInfos">
          <option v-for="operator in operatorOptions" :key="operator.key" :value="operator.key">
            {{ operator.name }}
          </option>
        </select>
        <select v-show="!operatorOptions.length" disabled>
          <option :value="undefined">
            {{ $t('audiences.operators.equal') }}
          </option>
        </select>
      </div>
      <div class="form-group value" :class="valueBetween ? 'between' : ''">
        <el-tooltip
          :visible="showTooltip"
          :raw-content="true"
          :content="$t('audiences.value-error')"
          placement="top"
          popper-class="el--tooltip-error audience-asc"
        >
          <audience-value
            v-if="handleAudienceValue"
            :type="valueType"
            :options="valueOptions"
            :value="condition.value"
            :label-field="condition.labelField"
            :name="condition.column"
            :format="valueFormat"
            :serie="id"
            :include="include"
            :group="group"
            :between="valueBetween"
            :success="success"
            :autocomplete="selectedColumnInfos.autocomplete || false"
            :multiple-selection="selectedColumnInfos.multiple"
            :operator="condition.operator"
            :reference-objects="condition.referenceObjects"
            @showTooltip="$event => (showTooltip = $event)"
          />
        </el-tooltip>
      </div>
      <div v-if="hasFilterKey()" class="form-group optional-filter" :class="isEdit ? 'edit-mode' : ''">
        <span>{{ selectedColumnInfos.filter.name }}</span>
        <div class="form-group operator">
          <select v-show="Boolean(selectedColumnInfos.filter.operators.length)" v-model="filterAddOn.f_operator">
            <option v-for="(operator, index) in selectedColumnInfos.filter.operators" :key="index" :value="operator">
              {{ $t(`audiences.operators.${operator}`) }}
            </option>
          </select>
          <select v-show="!Boolean(selectedColumnInfos.filter.operators.length)" disabled>
            <option :value="undefined">
              {{ $t('audiences.operators.equal') }}
            </option>
          </select>
        </div>
        <audience-value
          :type="selectedColumnInfos.filter.type === 'integer' ? 'integer' : 'date'"
          :options="selectedColumnInfos.filter.options"
          :value="filterAddOn.f_value"
          :label-field="condition.labelField"
          :name="condition.column"
          :format="selectedColumnInfos.filter.format"
          :serie="id"
          :include="include"
          :group="group"
          :between="filterAddOn.f_operator === 'between'"
          :success="success"
          :autocomplete="false"
          :operator="filterAddOn.f_operator"
          value-origin="filter"
        />
      </div>
      <audience-add-serie-button
        :is-edit="isEdit"
        :action="addSerie"
        :reset="resetForm"
        :disabled="!condition.operator"
        :data-cy="include ? 'btn-add-condition-include' : 'btn-add-condition-exclude'"
      />
    </div>
  </div>
</template>
<script>
import ConditionColumn from '@/components/audiences/create/composition/condition/ConditionColumn';
import ConditionValue from '@/components/audiences/create/composition/condition/ConditionValue';
import AudienceAddSerieButton from '@/components/audiences/create/composition/AudienceAddSerieButton';
import bus from '@/helpers/events/bus';
import { dateFormat } from '@/utilities/constants';

const templateFilter = {
  f_operator: 'equal',
  f_value: [],
};

export default {
  components: {
    'audience-column': ConditionColumn,
    'audience-value': ConditionValue,
    'audience-add-serie-button': AudienceAddSerieButton,
  },
  props: {
    group: {
      type: Number,
      default: null,
    },
    include: {
      type: [Object, Boolean],
      default: null,
    },
    disable: {
      type: Function,
      default: null,
    },
    getSelectedColumn: {
      type: Function,
      default: null,
    },
    selectedColumnInfos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: undefined,
      showTooltip: false,
      condition: {
        column: undefined,
        operator: undefined,
        value: [],
        referenceObjects: [], // stores key:value objects reference from multiple selections
      },
      isEdit: false,
      idleTime: 0,
      timeoutID: undefined,
      columnValid: true,
      success: [true, true],
      filterAddOn: Object.assign({}, templateFilter),
      stringOr: `${this.$t('or').toLowerCase()}`,
      stringOrAnother: `${this.$t('or').toLowerCase()} ${this.$t('another')}`,
    };
  },
  computed: {
    handleAudienceValue() {
      return ['isnull', 'notnull'].includes(this.condition.operator) === false && !!this.operatorOptions.length;
    },
    operatorOptions() {
      let start = [];
      if (!this.selectedColumnInfos) {
        this.getSelectedColumn();
      }
      if (this.condition.column) {
        const operatorKeys = this.selectedColumnInfos.operators || [];

        start = operatorKeys.map(key => ({
          key,
          name: this.$i18n.t(`audiences.operators.${key}`),
        }));
        this.setDefaultOperator();
      }
      return start;
    },
    valueOptions() {
      if (this.condition.column) {
        return this.selectedColumnInfos.options;
      }
      return undefined;
    },
    valueType() {
      if (this.condition.column) {
        return this.selectedColumnInfos.type;
      }
      return undefined;
    },
    valueFormat() {
      if (this.condition.column) {
        return this.selectedColumnInfos.format;
      }
      return undefined;
    },
    valueBetween() {
      if (this.condition.column) {
        return this.condition.operator === 'between';
      }
      return undefined;
    },
    operatorName() {
      if (this.operatorOptions.length && this.condition.operator) {
        const operator = this.operatorOptions.filter(op => op.key === this.condition.operator)[0];
        return operator.name;
      }
      return undefined;
    },
  },
  watch: {
    selectedColumnInfos(newValue, oldValue) {
      // validação para definir se o operador e o valor da condição devem ser resetados
      // !oldValue.column indica que a coluna não foi alterada
      // isEdit indica se uma condição já existente está sendo editada
      // caso ambos sejam true (significa a coluna foi modificada) ou não está em modo de edição, o operador e o valor devem ser resetados
      if ((oldValue.column && this.isEdit) || !this.isEdit) this.updateOperatorOptions();
    },
  },
  created() {
    this.listeners();
    bus.$on('audience-reset-form', () => this.resetForm());
    bus.$on('audience-reset-form-condition', () => this.resetFormCondition());
  },
  mounted() {
    bus.$on('audience-labelField-multiselect-update', () => this.updateLabelField());
  },
  beforeUnmount() {
    this.listeners(true);
    bus.$off('audience-labelField-multiselect-update');
    bus.$off('audience-reset-form-condition');
    bus.$off('audience-reset-form');
  },
  methods: {
    updateOperatorOptions() {
      const { column } = this.condition;

      // reset value and operator
      if (column) {
        this.condition.value = [];
        this.condition.operator = this.operatorOptions[0].key;
        if (this.hasFilterKey()) {
          [this.filterAddOn.f_operator] = this.selectedColumnInfos.filter.operators;
          this.filterAddOn.f_value = [];
        }
        bus.$emit('operator-options-updated', {
          group: this.group,
          serie: this.id,
        });
        this.updateValueInfos();
      }
    },
    updateValueInfos() {
      bus.$emit('audience-column-changed');
      if (this.valueOptions && !this.selectedColumnInfos.multiple) {
        let index = 0;
        Object.keys(this.valueOptions).forEach(item => {
          if (index === 0) {
            this.condition.value[0] = item;
            index += 1;
          }
        });
      }
      if (this.selectedColumnInfos.multiple && this.condition.value.length === 0) {
        this.condition.labelField = '';
      }
      this.success = [true, true];
    },
    updateLabelField() {
      // concat multipleSelection values with 'or', 'another' dict string, etc.
      // if select more than 2, add 'another x' selected
      const labels = Object.values(this.condition.referenceObjects);
      let outputString;
      if (labels.length > 2) {
        outputString = `${labels[0]} ${this.stringOrAnother} ${labels.length - 1}`;
      } else {
        outputString = labels.join().replace(/,/g, ` ${this.stringOr} `);
      }
      this.condition.labelField = outputString;
    },
    updateTextField(infos, multipleSelection = false) {
      // check if receive multipleSelection param, otherwise is false
      if (multipleSelection) {
        // infos is an object with all previously selection
        //  used to compare with array of selection id's
        // const keys = Object.keys(infos);
        this.condition.value = Object.keys(infos);
        // add all key-value infos to condition (will saved with audience)
        this.condition.referenceObjects = { ...infos };
        // input value update
        this.updateLabelField();
      } else {
        const [value, key] = infos;
        this.condition.value = [value];
        this.condition.labelField = key;
        if (value && key) this.condition.referenceObjects = { [value]: key };
      }
    },
    openSerie({ include, group, serie, data }) {
      if (include === this.include && group === this.group) {
        this.id = serie;
        // deep copy to remove object and nested array reference
        this.condition = JSON.parse(JSON.stringify(data));
        this.isEdit = true;
        this.getSelectedColumn(this.condition.column);
        this.filterAddOn.f_value = this.condition.f_value ? this.condition.f_value : [];
        this.filterAddOn.f_operator = this.condition.f_operator ? this.condition.f_operator : 'equal';
      }
    },
    updateDate({ include, group, event, between, valueOrigin }) {
      if (include === this.include && group === this.group) {
        if (valueOrigin === 'filter' && this.filterAddOn.f_value !== undefined) {
          this.filterAddOn.f_value[between] = event.format(dateFormat);
        } else {
          this.condition.value[between] = event.format(dateFormat);
        }
      }
    },
    addSerie() {
      const data = { ...this.condition };
      if (data.operator === 'isnull' || data.operator === 'notnull') {
        // clear value if operator is isnull or notnull
        data.value = [];
      }
      if (data.operator !== 'equal' && data.operator !== 'different') {
        delete data.labelField;
      }
      if (this.filterAddOn.f_value.length === 2 && this.filterAddOn.f_operator !== 'between') {
        this.filterAddOn.f_value.pop();
      }
      bus.$emit('validate-fields', {
        include: !!this.include,
        group: this.group,
      });
      if (this.hasFilterKey()) {
        if (
          !this.condition.value.length ||
          (this.filterAddOn.length && (!this.filterAddOn.f_value.length || !this.filterAddOn.f_value[0].length))
        ) {
          this.success = [false, false];
        } else {
          this.success = [true, true];
        }
      }
      this.resetTimer();
      if (this.columnValid && this.success[0] && this.success[1]) {
        // if has filter options, merge with local data and dispatch to create/update audience
        if (this.hasFilterKey()) {
          this.filterAddOn.f_type = this.selectedColumnInfos.filter.f_type;
          Object.assign(data, this.filterAddOn);
        } else {
          delete data.f_operator;
          delete data.f_type;
          delete data.f_value;
        }
        if (this.id !== undefined) {
          this.$store.dispatch('audience/updateSerie', {
            include: !!this.include,
            group: this.group,
            serie: this.id,
            data,
          });
        } else {
          this.$store.dispatch('audience/addSerie', {
            include: !!this.include,
            group: this.group,
            data,
          });
        }

        this.resetForm();
      }
    },
    validateColumn() {
      this.columnValid = Boolean(this.condition.column === undefined);
    },
    startTimer() {
      this.resetTimer();
      this.timeoutID = window.setInterval(() => {
        this.idleTime += 1;
      }, 1000);
    },
    resetTimer() {
      if (this.timeoutID) {
        this.idleTime = 0;
        window.clearInterval(this.timeoutID);
      }
    },
    resetForm() {
      this.id = undefined;
      this.templateFilter = { ...templateFilter };
      this.filterAddOn = { ...templateFilter };
      this.isEdit = false;
      this.success = [true, true];
      this.clearConditionProps();
      this.disable();
      bus.$emit('clear-term');
    },
    resetFormCondition() {
      this.condition.labelField = '';
      this.condition.value = [];
    },
    setDefaultOperator() {
      const defaultOperator = this.selectedColumnInfos.operators[0];
      const { column, operator } = this.condition;
      if (column && !operator) {
        this.condition.operator = defaultOperator;
      }
      if (this.hasFilterKey()) {
        [this.filterAddOn.f_operator] = this.selectedColumnInfos.filter.operators;
        if (this.condition.f_operator && this.condition.f_operator.length) {
          this.filterAddOn.f_operator = this.condition.f_operator;
        }
      }
    },
    listeners(off = false) {
      const action = off ? '$off' : '$on';
      bus[action]('change-audience-date', this.updateDate);
      bus[action]('audience-serie-edit', this.openSerie);
      bus[action]('audience-fields-update', ({ items, isMultipleSelection }) => {
        this.updateTextField(items, isMultipleSelection);
      });
      bus[action]('audience-column-changed', this.resetFormCondition);
    },
    hasFilterKey() {
      return Boolean(this.selectedColumnInfos.filter);
    },
    clearConditionProps() {
      // erase all condition keys data
      this.condition.column = undefined;
      this.condition.operator = undefined;
      this.condition.value = [];
      this.condition.referenceObjects = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.serie-create-edit .form {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &:first-of-type:last-of-type {
    .remove {
      display: none;
    }
  }
  &:first-of-type {
    .logic-operator {
      display: none;
    }
    .column {
      margin-left: 0;
    }
  }
  .operator-and {
    background-color: $gray-300;
    padding: 5px 15px;
    color: $gray-800;
    font-weight: 600;
    border-radius: 7px;
    font-size: 11px;
    width: 35px;
    display: flex;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    cursor: default;
  }
  .form-group {
    margin: 0 7.5px;
    width: 100%;
    display: flex;

    input[disabled] {
      background-color: $color-white;
    }
    &.operator,
    &.value {
      max-width: 215px;
      &.between {
        max-width: 250px;
      }
    }
    > * {
      width: 100%;
    }
    &.optional-filter {
      width: 170%;
      > span {
        color: #666;
        font-size: 12px;
        display: flex;
        align-items: center;
        width: 25%;
      }
      .operator {
        width: 45%;
      }
      &.edit-mode {
        width: 225%;
        > span {
          width: 25%;
        }
        .operator {
          width: 43%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  input,
  select {
    width: 100% !important;
    &.error {
      border-color: $color-red !important;
      box-shadow: 0 0 7px rgba($color-red, 0.15);
    }
  }
  .error-alert {
    color: $color-red;
    position: absolute;
    right: 15px;
    font-size: 18px;
  }
  &.select .error-alert {
    margin-right: 15px;
  }
}

.el-popper.is-dark.el--tooltip-error.audience-asc {
  width: 200px;
}
</style>
