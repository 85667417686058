<template>
  <el-dialog v-model="isVisible" id="labels-save-modal" append-to-body>
    <div class="content">
      <h2>{{ $t('labels-personas.save-changes') }}</h2>
      <div class="button-container">
        <button class="btn btn-grey" @click="closeModal">
          {{ $t('labels-personas.cancel') }}
        </button>
        <button class="btn" @click="saveLabel">
          {{ $t('labels-personas.save') }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers, services
import { isNil } from 'lodash';
import bus from '@/helpers/events/bus';
import { updateLabel } from '@/services/labels/index';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'SaveLabelModal',
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    ...mapState('labels', ['labelDetail']),
    ...mapGetters('labels', ['getLabelDetailUnsavedCompose']),
  },
  mounted() {
    bus.$on('openSaveLabelModal', () => {
      this.openModal();
    });
  },
  methods: {
    ...mapActions('labels', ['createLabel']),
    saveLabel() {
      if (!isNil(this.labelDetail?.labelId)) {
        updateLabel(this.getLabelDetailUnsavedCompose);
      }
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: -25px 50px 0;
  h2 {
    margin-bottom: 17px;
  }
  .button-container {
    margin-top: 17px;
    display: flex;
    button {
      margin: auto;
      width: 130px;
      height: 40px;
      &.btn-grey {
        background: grey;
        &:hover {
          background: #585858;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#labels-save-modal {
  max-width: 440px;
}
</style>
