<template>
  <div
    v-show="validateIfHasPermission(permissionBalance) && !cashbackDisabled && !cashbackUnavailable"
    class="col-sm p-2"
  >
    <base-card-v2 id="customer-cashback">
      <template v-if="$_verifyLoaded('done')">
        <base-card-header title="Cashback" />
        <div class="cashback-container">
          <div class="cc__content">
            <h3>
              <span>{{ formatBalance().symbol }}</span>
              {{ formatBalance().cash }}
              <span>{{ formatBalance().cents }}</span>
            </h3>
            <p v-if="balanceData.expires">
              {{ formatCurrency(balanceData.expires.value) }} <span> {{ $t('360.customers.cashback.expires') }} </span>
              {{ formatDate(balanceData.expires.date) }}
            </p>
            <a href="#" @click.prevent="openAccumulationModal">{{
              $t('360.customers.cashback.accumulation-rules.link')
            }}</a>
          </div>
          <div class="cc__modals">
            <a
              href="#"
              :disabled="!validateIfHasPermission(permissionHistoric)"
              :class="{ disabled: !validateIfHasPermission(permissionHistoric) }"
              @click.prevent="openHistoricModal"
              >{{ $t('360.customers.cashback.historic.link') }}
            </a>
            <a
              href="#"
              :disabled="!validateIfHasPermission(permissionValidate)"
              :class="{ disabled: !validateIfHasPermission(permissionValidate) }"
              @click.prevent="openValidationModal"
            >
              {{ $t('360.customers.cashback.validation.link') }}
            </a>
          </div>
        </div>
      </template>
      <cashback-accumulation-modal :customer-id="customerId" />
      <cashback-validation-modal
        :balance="balanceData.balance"
        :customer-id="customerId"
        :is-modal-voucher="isModalVoucher"
      />
      <cashback-historic-modal :customer-id="customerId" />
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </base-card-v2>
  </div>
</template>

<script>
// libs
import moment from 'moment';

// services
import { getBalance } from '@/services/oto/cashback';

// utilities
import { permissionBalance, permissionHistoric, permissionValidate } from '@/utilities/accessLevel/CashbackPermissions';

// helpers
import bus from '@/helpers/events/bus';
import { formatNumber, formatSplitCash } from '@/utilities/formatters';
import { verifyMixin } from '@/helpers/mixins';
import hasPermission from '@/helpers/permissions';
import { dateCompleteVariation } from '@/utilities/constants';

// components
import CashbackAccumulationModal from '@/components/oto/customerview/modals/CashbackAccumulationModal';
import CashbackValidationModal from '@/components/oto/customerview/modals/CashbackValidationModal';
import CashbackHistoricModal from '@/components/oto/customerview/modals/CashbackHistoricModal';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'CustomerCashback',
  components: {
    CashbackAccumulationModal,
    CashbackValidationModal,
    CashbackHistoricModal,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      permissionBalance: permissionBalance,
      permissionHistoric: permissionHistoric,
      permissionValidate: permissionValidate,
      isModalVoucher: false,
      balanceData: {},
      cashbackDisabled: false,
      cashbackUnavailable: false,
    };
  },
  mounted() {
    bus.$on('open-cashback-voucher-modal', value => {
      this.isModalVoucher = value;
    });

    bus.$on('cashback-update-balance', () => {
      this.fetchBalance();
    });

    this.fetchBalance();
  },
  beforeUnmount() {
    bus.$off(['open-cashback-voucher-modal', 'permission-cachback-validation', 'cashback-update-balance']);
  },
  methods: {
    openAccumulationModal() {
      bus.$emit('open-cashback-accumulation-modal');
    },

    openValidationModal() {
      bus.$emit('open-cashback-validation-modal');
      this.isModalVoucher = false;
    },

    openHistoricModal() {
      bus.$emit('open-cashback-historic-modal');
    },

    fetchBalance() {
      this.$_reqConfig();

      getBalance({ customerId: this.customerId, balance: this.balance })
        .then(({ data }) => {
          if (data.errors.code === 'cashback-disabled') this.cashbackDisabled = true;
          if (data.errors.code === 'cashback-unavailable') this.cashbackUnavailable = true;
          if (this.$_verifyData(data.data)) return;
          this.balanceData = data.data;
        })
        .catch(() => {
          this.hasError = true;
        })
        .finally(() => {
          this.loading = false;
          this.formatBalance();
        });
    },

    formatDate(date) {
      return moment(date).format(dateCompleteVariation);
    },

    formatValues(value, format) {
      return formatNumber(value, format);
    },

    formatBalance() {
      return formatSplitCash(this.balanceData.balance);
    },

    // validate permission for a specific functionality
    validateIfHasPermission(permissionGroup) {
      return hasPermission(permissionGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
#customer-cashback {
  height: 325px;
  position: relative;

  .cashback-container {
    a {
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
    }
    .cc__content {
      text-align: center;
      h3 {
        color: $oto-seller;
        line-height: unset;
        font-size: 60px;
        display: flex;
        align-items: baseline;
        justify-content: center;
        span {
          font-size: 17px;
        }
      }
      p {
        margin-bottom: 22px;
        font-size: 14px;
        font-weight: bold;

        span {
          font-weight: normal;
        }
      }

      a {
        color: $oto-seller;
      }
    }

    .cc__modals {
      border-top: 1px solid #f1f1f1;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      left: 0;
      margin-top: 75px;
      padding: 10px 25px;
      position: absolute;
      width: 100%;

      a:nth-child(1) {
        margin-right: 30px;
      }

      a {
        &.disabled,
        [disabled] {
          cursor: not-allowed;
          color: $gray-400;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
