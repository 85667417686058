<template>
  <lightbox-template id="lightbox-who-bought" icon="money" :title="$tc('product.title', 0)" :show-segment-group="false">
    <lightbox-skeleton v-if="$_verifyLoaded('loading')" :data="data" :type="type" />
    <template v-if="$_verifyLoaded('done')">
      <div class="container mb-4">
        <header class="d-flex align-items-center mb-3">
          <h5 class="modal-subtitle">{{ $t('product.bought-too-inline') }}</h5>
        </header>
      </div>
      <table-list
        :id="id"
        :data="data"
        type="filled"
        :loading="$_verifyLoaded('loading')"
        :is-lightbox-active="isLightboxActive"
      >
        <table-list-header v-show="!data.loading">
          <table-list-column class="col-6">
            <modal-dates />
          </table-list-column>
          <table-list-column v-for="(item, index) in data.manualSeries" :key="index" :class="columnClass(item.key)">
            <span v-html="item.name"></span>
          </table-list-column>
        </table-list-header>
        <table-list-body v-show="!data.loading">
          <table-list-row v-for="(item, index) in data.rows" :key="index" :loading="data.loading">
            <table-list-column class="col-6 capitalize">{{ item.name }}</table-list-column>
            <table-list-column class="col-2 text-right">
              {{ $_formatDecimal(item.boughtOn, 3) }}
              <span class="color-gray-700 text-semibold">({{ $_formatNumber(item.percentBoughtOn, '0.00%') }})</span>
            </table-list-column>
            <table-list-column class="col-2 text-right">
              {{ $_formatDecimal(item.boughtOff, 3) }}
              <span class="color-gray-700 text-semibold">({{ $_formatNumber(item.percentBoughtOff, '0.00%') }})</span>
            </table-list-column>
            <table-list-column class="col-2 text-right">
              {{ $_formatDecimal(item.boughtTotal, 3) }}
              <span class="color-gray-700 text-semibold">({{ $_formatNumber(item.percentBoughtTotal, '0.00%') }})</span>
            </table-list-column>
          </table-list-row>
        </table-list-body>
      </table-list>
    </template>
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" class="card loadHeight" :error="hasError" />
  </lightbox-template>
</template>

<script>
// @ is an alias to /src
import bus from '@/helpers/events/bus';
import { getTable } from '@/services/product';
import ModalDates from '@/components/ModalDates';
import { lightboxesMixin, verifyMixin, numberMixin } from '@/helpers/mixins';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import LightboxSkeleton from '@/components/lightbox/lightboxes/skeletons/LighboxWhoBoughtSkeleton';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'LightboxWhoBought',
  components: {
    ModalDates,
    LightboxTemplate,
    LightboxSkeleton,
    InfoCard,
  },
  mixins: [lightboxesMixin, verifyMixin, numberMixin],
  data() {
    return {
      id: 'lightbox-who-bought',
      isLightboxActive: true,
      data: {
        limit: 10,
        page: 1,
        series: [],
        rows: [],
        count: 0,
        manualSeries: [
          {
            key: 'orders_off',
            name: this.$t('product.orders-off'),
          },
          {
            key: 'orders_on',
            name: this.$t('product.orders-on'),
          },
          {
            key: 'orders',
            name: this.$t('product.all-orders'),
          },
        ],
      },
      type: 'filled',
      productID: this.$route.params.id,
    };
  },
  mounted() {
    this.fetchData();

    bus.$on(`${this.id}-change-page`, () => {
      this.$_reqConfig();
      this.fetchData();
    });
    bus.$on(`${this.id}-change-order`, this.fetchData);
  },
  beforeUnmount() {
    bus.$off(`${this.id}-change-page`);
  },

  methods: {
    fetchData() {
      this.$_reqConfig();

      getTable({
        productID: this.productID,
        table: 'basket_analysis',
        limit: this.data.limit,
        page: this.data.page,
      })
        .then(({ data }) => {
          this.data.count = data.total_count;
          this.data.rows = [];
          this.formatRows(data.data);
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRows(data) {
      const resolveRows = row => {
        const [name, boughtOn, percentBoughtOn, boughtOff, percentBoughtOff, boughtTotal, percentBoughtTotal] = row;

        return {
          name,
          boughtOn,
          percentBoughtOn,
          boughtOff,
          percentBoughtOff,
          boughtTotal,
          percentBoughtTotal,
        };
      };
      data.map(item => this.data.rows.push(resolveRows(item)));
    },
    columnClass(key) {
      let size = '';
      if (key === 'orders_on') {
        size = 'col-2 text-right';
      } else if (key === 'orders_off') {
        size = 'col-2 text-right';
      } else {
        size = 'col-2 text-right';
      }
      return size;
    },
  },
};
</script>

<style lang="scss" scoped>
.loadHeight {
  min-height: 550px;
}
</style>
