import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

type PutQuarantineParams = {
  days: string | number;
};

type UpdateQuarantineParams = {
  actionId: string;
};

/**
 *
 * @description return quarentine days
 * @see https://labs.pmweb.com.br/docs/context/v3/config/getQuarantine
 */
export const getQuarantine = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/config/getQuarantine',
    params: {
      brand: brand.id,
    },
  });
};

/**
 *
 * @description send quarentine days
 * @see https://labs.pmweb.com.br/docs/context/v3/config/putQuarantine
 */
export const putQuarantine = params => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: '/config/putQuarantine',
    validateStatus: () => true,
    params: {
      brand: brand.id,
      ...params,
    },
  });
};

/**
 *
 * @description return send quarentine days
 * @see https://labs.pmweb.com.br/docs/context/v3/action/updateQuarantine
 */
export const updateQuarantine = ({ actionId }: UpdateQuarantineParams) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: '/action/updateQuarantine',
    validateStatus: () => true,
    params: {
      brand: brand.id,
      actionId,
    },
  });
};

/**
 *
 * @description return quarantine actions list
 * @see https://labs.pmweb.com.br/docs/context/v3/action/listActions
 */
export const getQuarantineActionsList = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: '/action/listActions',
    params: {
      brand: brand.id,
    },
  });
};
