import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

const URI = 'reports';

export const getReports = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get(`/${URI}/getCustomReports`, {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
    },
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const postReports = data => {
  const { name, url, description, width, height } = data;
  const tokenV3 = store.state.auth.tokenV3;
  const brand = store.getters.selectedBrand;

  return axios.post(
    `/${URI}/create`,
    {
      brand: brand.id,
      name,
      url,
      description,
      width,
      height,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};
export const deleteReport = customReportId => {
  const tokenV3 = store.state.auth.tokenV3;
  const brand = store.getters.selectedBrand;

  return axios.post(
    `/${URI}/delete`,
    {
      brand: brand.id,
      customReportId,
    },
    {
      baseURL: endpointVersion('v3'),
      headers: { Authorization: `Bearer ${tokenV3}` },
    },
  );
};
