<template>
  <div class="atom-bar-chart" :class="setCustomClasses()">
    <p
      class="atom-value"
      :class="[valueTooltip ? 'atom-tooltip' : '', progress === '0' ? '' : 'with-progress']"
      v-tooltip.top-start="{
        content: valueTooltip,
        theme: 'default',
        distance: 8,
      }"
    >
      {{ value }}
    </p>
    <div class="atom-bar" :style="direction.length ? `width: ${progress}%` : ''">
      <span
        class="atom-progress"
        :class="`-${color}`"
        :style="direction.length ? 'width: 100%' : `width: ${progress}%`"
      />
    </div>
  </div>
</template>

<script>
import '@/plugins/tooltip/tooltip';

export default {
  name: 'ChartBar',
  props: {
    value: {
      type: String,
      default: '0',
    },
    progress: {
      type: String,
      default: '100',
    },
    color: {
      type: String,
      default: 'lightblue',
    },
    direction: {
      type: String,
      default: '',
    },
    valueTooltip: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    setCustomClasses() {
      const classDirection = this.direction.length ? `atom-s-${this.direction}` : '';
      return `${classDirection}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-bar-chart {
  display: flex;
  position: relative;
  max-width: 58%;
  .atom-tooltip {
    cursor: pointer;
  }
}
.atom-value {
  flex-grow: 1;
  text-align: right;
  margin: 0 10px 0 0;
  font-weight: 600;
  width: 65%;
}
.atom-bar {
  align-self: center;
  width: 65px;
  height: 8px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .atom-progress {
    display: block;
    height: 100%;
    &.-lightblue {
      background-color: $oto-omni;
    }
    &.-blue {
      background-color: $blue-700;
    }
    &.-darkblue {
      background-color: $blue-800;
    }
    &.-purple {
      background-color: $purple-300;
    }
    &.-darkpurple {
      background-color: $purple-400;
    }
    &.-red {
      background-color: $color-red;
    }
    &.-orange {
      background-color: $color-orange;
    }
    &.-green {
      background-color: $oto-ecommerce;
    }
    &.-pink {
      background-color: $pink-400;
    }
  }
}
/** custom */
.atom-s-right {
  max-width: 100%;
  .atom-value {
    order: 2;
    text-align: left;

    &.with-progress {
      margin: 0 0 0 10px;
    }
  }
  .atom-bar {
    order: 1;
  }
}
.atom-s-left {
  max-width: 100%;
}
</style>
