<template>
  <div class="nps-ratings card">
    <template v-if="$_verifyLoaded('done')">
      <h2 class="card-title">{{ $t('nps.item-ratings') }}</h2>
      <div class="nps-item-ratings row mb-3 customScrollBar">
        <div v-for="(item, key) in dataset" :key="key" class="nps-item-rating col-2 mr-3">
          <item-rating :label-name="item.name" :ratings="item.ratings" :score="item.score" />
        </div>
      </div>
    </template>
    <div v-else class="card">
      <base-loader v-show="$_verifyLoaded('loading')" />
      <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { getMetrics } from '@/services/nps/analytics';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import ItemRating from '@/components/nps/_atoms/ItemRating';
import InfoCard from '@/components/_atoms/InfoCard';
import { verifyMixin } from '@/helpers/mixins';

export default {
  name: 'ItemRatings',
  components: {
    BaseLoader,
    InfoCard,
    ItemRating,
  },
  mixins: [verifyMixin],
  data() {
    return {
      dataset: {},
    };
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      const items = [
        'item_01_score',
        'item_02_score',
        'item_03_score',
        'item_04_score',
        'item_05_score',
        'item_06_score',
        'item_07_score',
        'item_08_score',
        'item_09_score',
        'item_10_score',
        'item_01_ratings',
        'item_02_ratings',
        'item_03_ratings',
        'item_04_ratings',
        'item_05_ratings',
        'item_06_ratings',
        'item_07_ratings',
        'item_08_ratings',
        'item_09_ratings',
        'item_10_ratings',
      ];

      getMetrics(items.join(','))
        .then(res => {
          if (this.$_verifyHasData(res.metrics)) return;

          const obj = {};
          Object.entries(res.metrics).map(([key, item]) => {
            // Convert item_01_* to item_01
            const k = key.substring(0, 7);
            const m = key.substring(8);
            const v = Number.parseFloat(item.data?.amount);

            if (v) {
              if (!Object.prototype.hasOwnProperty.call(obj, k)) {
                obj[k] = {
                  name: item.title,
                  ratings: 0,
                  secore: 0,
                };
              }

              obj[k][m] = v;
            }
          });
          this.dataset = obj;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nps-ratings .nps-item-ratings {
  max-height: 204px;
  overflow: auto;
}
</style>
