import _ from 'lodash';
import { isNil, isEmpty } from 'lodash';
import { valueType } from '@/utilities/constants';

const mixin = {
  methods: {
    isNil(val) {
      return isNil(val);
    },

    isEmptyValue(val) {
      return isEmpty(val);
    },

    /**
     * @description sort array of objects based on another array
     * @param {*} array array to be ordered
     * @param {*} order correct order
     * @param {*} key key to filter
     */
    mapOrder(array, order, key) {
      array.sort(function (a, b) {
        const A = a[key],
          B = b[key];

        if (order.indexOf(A) > order.indexOf(B)) {
          return 1;
        } else {
          return -1;
        }
      });

      return array;
    },

    /**
     * @description checks if the screen is over the table header
     */
    debounce() {
      if (!this.$refs['tableHeader'] || !this.$refs['tbl-inner-wrapper']) return;

      this.headerFixed =
        this.$refs?.tableHeader?.getBoundingClientRect()?.top <= 65 &&
        this.$refs['tbl-inner-wrapper']?.getBoundingClientRect()?.bottom >= 135;

      // Set header fixed position
      if (this.$refs['header-fixed']) {
        const tableInnerWrapper = this.$refs['tbl-inner-wrapper'];
        const tableScrollPosition = tableInnerWrapper.scrollLeft;
        this.$refs['header-fixed'].scrollTo(tableScrollPosition, 0);
      }
    },

    /**
     * @description checks table column direction
     * @param {*} colName
     */
    checkColDirection(colName) {
      const colsLeftAlign = ['date'];
      return colsLeftAlign.includes(colName) ? 'to-left' : '';
    },

    /**
     * @description update table according select option
     */
    onChangeSelect() {
      if (!this.verifyIsActive()) this.orderType = 'asc';
      this.fetchData(this.selectedOption);
    },

    /**
     * @description when export change calls fetch export data
     */
    onChangeExport(fileType, preset) {
      this.fetchExportData(fileType, preset);
    },

    /**
     * @description order in asc or desc
     * @param {string} key
     */
    $_dashOrdination(key) {
      if (this.order === key) {
        this.orderType === 'asc' ? (this.orderType = 'desc') : (this.orderType = 'asc');
      } else {
        if (this.order) {
          if (this.orderType === 'desc') this.orderType = 'asc';
          this.order = key;
        }
      }
      if (!this.order) this.order = key;

      this.$_dashOrderBy(key);
    },

    /**
     * @description order table according type passed
     * @param {string} key
     */
    $_dashOrderBy(key) {
      if (!this.tableData.data) {
        // values not date need to be parsed to fix the ordination results
        if (key === valueType.date) {
          this.tableData.rows = _.orderBy(this.tableData.rows, key, this.orderType);
        } else {
          if (this.orderType === 'asc') {
            this.tableData.rows = this.tableData?.rows?.sort((a, b) =>
              _.isString(a[key])
                ? String(a[key]).localeCompare(String(b[key]), undefined, {
                    numeric: true,
                  })
                : parseFloat(a[key]) - parseFloat(b[key]),
            );
          } else {
            this.tableData.rows = this.tableData.rows.sort((b, a) =>
              _.isString(a[key])
                ? String(a[key]).localeCompare(String(b[key]), undefined, {
                    numeric: true,
                  })
                : parseFloat(a[key]) - parseFloat(b[key]),
            );
          }
        }
      } else {
        if (key === valueType.date) {
          this.tableData.data = _.orderBy(this.tableData.data, key, this.orderType);
        } else {
          if (this.orderType === 'asc') {
            this.tableData.rows = this.tableData?.rows?.sort((a, b) =>
              _.isString(a[key])
                ? String(a[key]).localeCompare(String(b[key]), undefined, {
                    numeric: true,
                  })
                : parseFloat(a[key]) - parseFloat(b[key]),
            );
          } else {
            this.tableData.rows = this.tableData.rows.sort((b, a) =>
              _.isString(a[key])
                ? String(a[key]).localeCompare(String(b[key]), undefined, {
                    numeric: true,
                  })
                : parseFloat(a[key]) - parseFloat(b[key]),
            );
          }
        }
      }

      this.isActive = key;
    },

    /**
     * @description checks if key is active
     */
    $_dashVerifyIsActive() {
      let index;
      for (index in this.tableHeader) {
        if (this.isActive == this.tableHeader[index].key) {
          return true;
        }
      }
    },

    /**
     * @return return an array of objects
     * @param {Array} arr
     */
    $_formatToArrayOfObjects(arr) {
      if (!arr) return;
      const aux = [];
      Object.entries(arr).map(el => aux.push({ id: el[0], name: el[1] }));
      return aux;
    },
  },
};

export default mixin;
