import axios from 'axios';
import endpointVersion from '@/helpers/endpoint-version';
import store from '@/store';

const controller = '/360/performance';
const controllerCampaigns = '/campaign';

export const getCampaigns = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controllerCampaigns}/getCampaigns`,
    params: {
      brand: brand.id,
    },
  }).then(data => data?.data);
};

export const getStores = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `${controller}/getStores`,
    params: {
      brand: brand.id,
    },
  }).then(data => data?.data);
};

export const getTablePresets = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/campaigns/getTablePresets`,
    params: {
      brand: brand.id,
    },
  }).then(data => data.data);
};

/**
 * @see https://labs.pmweb.com.br/docs/360/#getSellersResume
 */
export const getSellersResume = ({ enabled = 1, storeId, name, userId, limit, page, orderBy, term = '' } = {}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `seller/getSellersResume`,
    params: {
      brand: brand.id,
      enabled,
      storeId,
      name,
      userId,
      limit,
      page,
      orderBy,
      term,
    },
  });
};

export const getMetrics = ({
  metrics,
  storeId = '',
  sellerId = '',
  campaignId = '',
  source = '',
  lookback = null,
  batchId = null,
  isAssync = false,
}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/performance/getMetrics`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      metrics,
      storeId,
      campaignId,
      sellerId,
      source,
      lookback,
      batchId,
      isAssync,
    },
  });
};

export const getTable = ({
  metrics,
  preset,
  fileType,
  responseType = '',
  storeId,
  sellerId,
  sourceId,
  campaignId,
  lookback = null,
}) => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;

  return axios.get('/oto/campaigns/getTable', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      metrics,
      preset,
      startDate,
      endDate,
      export: fileType,
      source: sourceId,
      storeId,
      sellerId,
      campaignId,
      lookback,
    },
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};

export const getParallelCoordinates = (
  sellerId = null,
  storeId = null,
  campaignId = null,
  stores,
  metrics = null,
  lookback = null,
) => {
  const brand = store.getters.selectedBrand;
  const startDate = store.getters.dates.startDate;
  const endDate = store.getters.dates.endDate;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/performance/parallelCoordinates`,
    params: {
      brand: brand.id,
      startDate,
      endDate,
      sellerId,
      storeId,
      campaignId,
      stores,
      metrics,
      lookback,
    },
  });
};

export const getAvailableMetrics = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/performance/getAvailableMetrics`,
    params: {
      brand: brand.id,
    },
  });
};

export const getTableMetrics = () => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'GET',
    baseURL: endpointVersion('v3'),
    url: `/oto/campaigns/getTableMetrics`,
    params: {
      brand: brand.id,
    },
  });
};

export const cancelRequestsJob = batchId => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios({
    headers: { Authorization: `Bearer ${tokenV3}` },
    method: 'POST',
    baseURL: endpointVersion('v3'),
    url: `oto/performance/cancelJob`,
    params: {
      batchId,
      brand: brand.id,
    },
  });
};
