import _ from 'lodash';
import { getNumeral } from '@/utilities/numbers';

export const getOrderedValues = (obj, order = 'asc') => {
  return _(parseValueToNumber(obj)).toPairs().orderBy(1, order).fromPairs().value();
};

export const getFirsts = (obj, n = 5) => {
  return _(obj).toPairs().take(n).fromPairs().value();
};

export const parseValueToNumber = obj => {
  return _.mapValues(obj, val => getNumeral(val));
};
