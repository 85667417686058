<template>
  <div id="websitebehavior">
    <div class="row">
      <div class="col-md-12 col-xl-6">
        <website-behavior-ecommerce-sessions />
      </div>
      <div class="col-md-12 col-xl-6">
        <website-behavior-behavior />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WebsiteBehaviorBehavior from '@/components/WebSiteBehaviorBehavior';
import WebsiteBehaviorEcommerceSessions from '@/components/WebSiteBehaviorEcommerceSessions';

export default {
  name: 'Websitebehavior',
  components: {
    WebsiteBehaviorBehavior,
    WebsiteBehaviorEcommerceSessions,
  },
};
</script>
