<template>
  <div id="component-channels">
    <div v-if="propData" class="row">
      <div class="col cl-chanels" :class="{ 'cl-mail': propData.email }">
        <i class="icon icon-mail"></i>
        <span>{{ $t('360.components.channels.mail') }}</span>
      </div>
      <div class="col cl-chanels" :class="{ 'color-oto-ecommerce': propData.on }">
        <i class="icon icon-ecommerce"></i>
        <span>{{ $t('360.components.channels.site') }}</span>
      </div>
      <div class="col cl-chanels" :class="{ 'color-purple-200': propData.off }">
        <i class="icon icon-in-store"></i>
        <span>{{ $t('360.components.channels.store') }}</span>
      </div>
      <div class="col cl-chanels" :class="{ 'cl-facebook': propData.facebook }">
        <i class="icon icon-facebook"></i>
        <span>{{ $t('360.components.channels.facebook') }}</span>
      </div>
      <div class="col cl-chanels" :class="{ 'cl-sms': propData.sms }">
        <i class="icon icon-engagement"></i>
        <span>{{ $t('360.components.channels.sms') }}</span>
      </div>
      <div class="col cl-chanels" :class="{ 'cl-app': propData.app }">
        <i class="icon icon-mobile"></i>
        <span>{{ $t('360.components.channels.app') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'CustomerViewChannels',
  props: {
    propData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
#component-channels {
  margin-bottom: 30px;
  div div span {
    white-space: nowrap;
  }
  .cl-chanels {
    color: #c3c3c3;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    i {
      font-size: 26px;
    }
  }

  .color-oto-ecommerce {
    color: $oto-ecommerce;
  }

  .color-purple-200 {
    color: $purple-200;
  }

  .cl-mail {
    color: $gray-500;
  }

  .cl-sms {
    color: $color-red;
  }

  .cl-facebook {
    color: $blue-600;
  }

  .cl-app {
    color: $pink-400;
  }
}
</style>
