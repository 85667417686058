<template>
  <el-dialog v-model="isVisible" id="success-audiences-importation" append-to-body>
    <div class="audience-importation-success">
      <header class="d-flex align-items-center justify-content-center border-b">
        <i class="icon icon-checked-60px color-oto-ecommerce"></i>
        <span class="title ml-3">{{ $t('audiences.importation.success.title') }}</span>
      </header>
      <div class="row border-b">
        <div class="col col-7 pt-3">
          <span class="title mb-3">{{ $t('audiences.importation.success.resume') }}</span>
          <div class="row">
            <div class="col-7">
              <span class="label">{{ $t('audiences.importation.success.send') }}</span>
            </div>
            <div class="col-3">
              <span class="value">{{ $_formatDecimal(result.totalSent, 1) }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <span class="label">{{ $t('audiences.importation.success.ignored') }}</span>
            </div>
            <div class="col-3">
              <span class="value">{{ $_formatDecimal(result.ignored, 1) }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <span class="label">{{ $t('audiences.importation.success.invalid') }}</span>
            </div>
            <div class="col-3">
              <span class="value">{{ $_formatDecimal(result.invalid, 1) }}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12 d-flex align-items-end">
              <span class="title">{{ $t('total') }}:</span
              ><span class="h1 mb-0">{{ $_formatDecimal(result.audience, 1) }} {{ $t('people') }}</span>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <button class="btn btn-continue" @click.prevent="close">
          {{ $t('continue') }}
        </button>
      </footer>
    </div>
  </el-dialog>
</template>

<script>
import bus from '@/helpers/events/bus';
import { numberMixin } from '@/helpers/mixins';

export default {
  mixins: [numberMixin],
  data() {
    return {
      result: {},
      isVisible: false,
    };
  },
  mounted() {
    bus.$on('importation-success', result => {
      setTimeout(() => (this.isVisible = true), 300);
      this.result = result;
    });
  },
  methods: {
    close() {
      this.isVisible = false;
      bus.$emit('render-cards');
    },
  },
};
</script>

<style lang="scss">
#success-audiences-importation {
  width: 540px;

  .audience-importation-success {
    padding: 0 50px;
    footer {
      overflow: hidden;
    }
    .icon-checked-60px {
      font-size: 60px;
      line-height: 60px;
    }
    .h1 {
      line-height: 21px;
      padding-left: 5px;
    }
    .title {
      display: block;
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      color: $gray-800;
    }
    .value {
      font-size: 12px;
      line-height: 20px;
      color: $gray-700;
    }
    .border-b {
      border-bottom: 1px solid $gray-400;
      padding-bottom: 25px;
      margin-bottom: 15px;
    }
    .btn {
      width: 200px;
      margin: 10px auto 0 auto;
    }
  }
}
</style>
<style lang="scss">
.highcharts-title {
  text-align: center;
  top: 60px !important;
}
</style>
