<template>
  <div class="dashboard-campaign-main-metrics">
    <div v-show="$_verifyLoaded('done')" class="metric-box">
      <template v-for="(value, key) in metrics" :key="key">
        <metric :label="`campaign-group.${key}`" :metric-raw="value" />
      </template>
    </div>
    <base-loader-spinner v-show="$_verifyLoaded('loading')" :height="'30'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import BaseLoaderSpinner from '@/components/_atoms/BaseLoaderSpinner';
import Metric from '@/components/_atoms/Metric';
import { getMetrics } from '@/services/dashboard';
import { verifyMixin } from '@/helpers/mixins';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'DashboardCampaignMainMetrics',
  components: {
    Metric,
    BaseLoaderSpinner,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      metrics: {},
    };
  },
  mounted() {
    this.fetchData(this.settings.campaign);
  },
  methods: {
    fetchData(campaign) {
      this.$_reqConfig();

      getMetrics({
        metrics: 'open_rate,ctr,cto,soft_bounce_rate,hard_bounce_rate',
        campaign,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) return;
          this.metrics = data?.metrics;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-campaign-main-metrics {
  min-height: 80px;
  margin: 12px 45px;
  position: relative;
  .metric-box {
    align-items: center;
    border: 1px solid rgba(187, 187, 187, 0.3);
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: space-between;
    margin: 0 auto;
    padding: 24px 160px;
    position: relative;
    width: 900px;
    > div {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
