<template>
  <div>
    <p class="chartlist-title">{{ chartName }}</p>
    <template v-for="(row, index) in rowsList" :key="index">
      <chart-row
        :label="row.label"
        :progress="row.progress"
        :value="row.value"
        :color="row.color"
        :tooltip="row.tooltip"
        :labelTooltip="row.labelTooltip"
        :valueTooltip="row.valueTooltip"
        :preset="preset"
        :direction="direction"
      />
    </template>
  </div>
</template>

<script>
import ChartRow from '@/components/_molecules/ChartRow';

export default {
  name: 'ChartList',
  components: {
    ChartRow,
  },
  props: {
    chartName: {
      type: String,
      default: '',
    },
    rowsList: {
      type: Array,
      default: () => [],
    },
    preset: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.chartlist-title {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 12px;
  color: #666;
  font-weight: 600;
}
.custom-p-subtitle {
  flex-direction: column;
  .bar-chart-label {
    max-width: 100%;
  }
}
</style>
