<template>
  <!-- Todo: Add route validation to a utility when applying it to more pages. -->
  <div class="header-wrapper" :class="{ 'responsive-header': getRoute === 'campaign-new' }">
    <header id="header" class="navbar navbar-expand">
      <div class="nav-wrapper">
        <sidebar-menu
          :brands="brands"
          :selected-brand="selectedBrand"
          :menu="filteredMenuItems"
          :sub-levels="filterRoutesBySublevel"
          :menu-items="menuItems"
          :brand-routes="getSelectedBrandRoutes"
          :menu-user-options="menuUserOptions"
        />
        <header-breadcrumb />
        <header-selector />
        <header-segments :selected-segment="selectedSegment" />
        <header-conversion-window :route="currentRoute" />
        <header-datepicker :language="user?.language" :route="currentRoute" />
      </div>
    </header>
  </div>
</template>

<script>
// libs
import bus from '@/helpers/events/bus';
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';

// helpers
import { menuItems, menuUserOptions, includeExperimentalRoutes } from '@/helpers/menu';

// components
import HeaderSegments from '@/components/menu/_organisms/HeaderSegments';
import HeaderDatepicker from '@/components/menu/_organisms/HeaderDatepicker';
import SidebarMenu from '@/components/menu/TheSidebarMenu';
import HeaderBreadcrumb from '@/components/menu/_organisms/HeaderBreadcrumb';
import HeaderSelector from '@/components/menu/_organisms/HeaderSelector';
import HeaderConversionWindow from '@/components/menu/_organisms/HeaderConversionWindow.vue';

export default {
  name: 'Header',
  components: {
    HeaderSegments,
    HeaderDatepicker,
    SidebarMenu,
    HeaderBreadcrumb,
    HeaderSelector,
    HeaderConversionWindow,
  },
  setup() {
    const route = useRoute();

    return { route };
  },
  data() {
    return {
      selectedMenu: undefined,
      experimental: this.$experimental,
      currentRoute: this.$route.path,
    };
  },
  computed: {
    ...mapGetters(['brands', 'selectedBrand', 'user', 'isLoggedIn', 'getRoutes', 'selectedSegment', 'getDefaultRoute']),
    /**
     * @description checks if brand has selected routes and show then
     */
    filteredMenuItems() {
      let filterRoutes;
      filterRoutes = this.filterRoutesBySelectedBrand || [];
      filterRoutes = this.checksExperimentalRoutes(filterRoutes);
      return filterRoutes;
    },
    /**
     * @description filter routes by selected brand
     */
    filterRoutesBySelectedBrand() {
      if (!this.getSelectedBrandRoutes || !menuItems) return;
      return menuItems.filter(el =>
        this.getSelectedBrandRoutes.find(item => item.id === el.id || el.id === 'oto-academy'),
      );
    },
    /**
     * @description get all main routes that has sub routes
     */
    filterRoutesBySublevel() {
      if (!this.getSelectedBrandRoutes) return;
      return this.getSelectedBrandRoutes.filter(el => el.routes);
    },
    /**
     * get brand routes
     */
    getSelectedBrandRoutes() {
      return this.$store.getters?.getRoutes;
    },
    /**
     * @description return all menu items options
     */
    menuItems() {
      return menuItems;
    },
    /**
     * @description return all menu user options
     */
    menuUserOptions() {
      return menuUserOptions;
    },
    getRoute() {
      return this.$route.name;
    },
  },
  watch: {
    $route(to) {
      this.currentRoute = to.path;
      this.updateMenu(to);
    },
  },
  mounted() {
    this.updateMenu();
    this.setRouteFallback();
    this.currentRoute = this.$route.path;
  },
  beforeUnmount() {
    bus.$off('update-brand');
  },
  methods: {
    ...mapActions(['setRouteFallback']),
    /**
     * @description get default routes and filter by experimental
     */
    checksExperimentalRoutes(filter) {
      let filterExperimental = filter;
      // if not in experimental mode remove all experimental routes
      if (!this.experimental) filterExperimental = this.filterExperimentalRoutes(filterExperimental);
      return filterExperimental;
    },
    /**
     * @description filter experimental routes
     */
    filterExperimentalRoutes(routes) {
      return routes.filter(el => !el.url.includes(includeExperimentalRoutes));
    },
    /**
     * @description updates menu with the active route
     */
    updateMenu(to) {
      this.refreshExperimental();

      const routeTitle = to?.meta?.title || this.$route?.meta?.title;

      if (routeTitle && (routeTitle.includes('settings') || routeTitle.includes('glossary'))) {
        this.selectedMenu = this.$t(`header.menu.${routeTitle}`);
      } else if (routeTitle) {
        this.selectedMenu = this.$t(`header.sidebar.${routeTitle}`);
      }
    },

    refreshExperimental() {
      this.experimental = this.$experimental;
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-header {
  height: 80px !important;
}

.header-wrapper {
  height: 120px;
  header {
    height: 70px;
    .nav-wrapper {
      height: 70px;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 104px;
      padding-right: 20px;
      position: fixed;
      right: 0;
      left: 0;
      top: 0;
      z-index: 9;
    }
    .nav-wrapper,
    .nav-sidebar {
      z-index: 99;
      background-color: $color-white;
    }
  }
}
</style>
