import moment from 'moment';
import numeral from 'numeral';
import store from '@/store';
import { formatCurrency, formatDecimal } from '@/utilities/formatters';
import { valueType } from '@/utilities/constants';

/**
 * @description generic number formatter
 * @param {Number} val input value to be changed
 * @param {String} [format='0,0'] numeral.js format string
 */
export const formatNumber = (val, format = '0,0') => {
  return numeral(val).format(format);
};

/**
 * @description get value according to format passed
 * @param {*} item
 * @returns string value or amount result
 */

export const valuesByFormat = item => {
  const format = item?.format;
  const type = item?.type;
  const amount = item?.amount;
  const percentInt = item?.percentInt;

  // Null values should be display as zero.
  if (amount === null) return 0;

  switch (type) {
    case valueType.string:
      if (format === valueType.date) {
        return moment(amount).format('DD/MM/YYYY');
      }
      // ex: '30/07/2021'
      else if (format === 'dateAndHour') {
        return moment(amount).format('DD MMM YYYY - h:mm');
      }
      // ex: '18 ago 2019 - 11:22'
      else return amount;
    // ex 'text'

    case valueType.integer:
      if (format === 'days') return formatDecimal(amount) + ' dias';
      // ex "10 dias"
      else if (format === 'averageDays') return formatDecimal(amount, 2) + ' dias (média)';
      // ex "10.00 dias (média)"
      else {
        return formatDecimal(amount);
      }
    //   ex 10

    case valueType.percent:
      return percentInt ? formatNumber(amount * 100, '0%') : formatNumber(amount * 100, '0.00%');
    // ex '10%' ou '10.00%'

    case valueType.decimal:
    case valueType.currency:
      return amount > 1000000 ? formatCurrency(amount, { shortNumber: true }) : formatCurrency(amount);
    // ex 'R$: 10,00 ou 'R$: 10mi'

    case valueType.float:
      return formatDecimal(amount, 2);
    // ex 10.000,00

    default:
      return amount;
  }
};

/**
 * @description generic split cash formatter
 * @param {Number} value input value to be changed
 * @return {Object}
 */
export const formatSplitCash = value => {
  if (value === null) return;
  const currencyValue = formatCurrency(value, { noSymbol: true });

  let cash = '';
  let cents = '';

  if (currencyValue.includes(',')) {
    cash = currencyValue.split(',')[0];
    cents = `,${currencyValue.split(',')[1]}`;
  }

  if (currencyValue.includes('.')) {
    cash = currencyValue.split('.')[0];
    cents = `.${currencyValue.split('.')[1]}`;
  }

  const symbol = store.getters?.currency?.symbol || 'R$';

  return { symbol, cash, cents };
};

/**
 * @description String formatter to number replacing , for . in the cents
 * @param {String} value input value to be changed
 * @return {Number}
 */
export const formatCashToNumber = value => {
  if (!value) return;
  const splitValue = value.split(' ');
  const lastSplitValue = splitValue[1];
  let removeDot = '';
  let formattedValue = '';
  if (value.includes('R$')) {
    removeDot = lastSplitValue.replace('.', '');
    formattedValue = removeDot.replace(',', '.');
  } else {
    removeDot = lastSplitValue.replace(',', '');
    formattedValue = removeDot;
  }
  return formattedValue;
};
