/* eslint-disable no-param-reassign */
// libs
import { escapeRegExp, isEmpty } from 'lodash';
// helpers
import { stringParser } from '@/helpers/string-parser';
// endpoints
import { getExportsHistory, getExportLog } from '@/services/integrations';
import { getAudiences, updateAudience } from '@/services/audiences';
import { getConnectors } from '@/services/connectors';

const historyExport = {
  namespaced: true,
  state: {
    status: [
      { label: 'integrations.all', key: '' },
      { label: 'audiences.status.success', key: 'success' },
      { label: 'audiences.status.scheduled', key: 'scheduled' },
      { label: 'audiences.status.cancelled', key: 'cancelled' },
      { label: 'audiences.status.running', key: 'running' },
      { label: 'audiences.status.error', key: 'error' },
    ],
    historySerie: ['name', 'connector', 'export', 'execTime', 'exportDate', 'processedCount', 'size'],
    audiences: [],
    connectors: [],
    historyData: [],
    isEmptyGlobal: false,
    loadingGlobal: false,
    hasErrorGlobal: false,
    selectedStatus: '',
    selectedConnector: '',
    selectedAudience: '',
    // pagination data
    id: 'export-history',
    pagination: {
      count: 0,
      limit: 10,
      page: 1,
      order: '',
    },
    limitRange: [5, 10, 20, 30, 50],
    log: {},
    logIsEmpty: false,
    logLoading: false,
    logHasError: false,
  },
  mutations: {
    SET_CONNECTORS(state, connectors) {
      state.connectors = connectors;
    },
    SET_AUDIENCES(state, audiences) {
      state.audiences = audiences;
    },
    SET_EXPORT_HISTORY(state, history) {
      state.historyData = history;
      if (isEmpty(history)) {
        state.isEmptyGlobal = true;
        state.loadingGlobal = false;
      } else state.isEmptyGlobal = false;
    },
    SET_LOADING(state, isLoading) {
      state.loadingGlobal = isLoading;
    },
    SET_ERROR(state, hasError) {
      state.hasErrorGlobal = hasError;
    },
    SET_SELECTED_STATUS(state, status) {
      state.selectedStatus = status;
    },
    SET_SELECTED_AUDIENCE(state, audience) {
      state.selectedAudience = audience;
    },
    SET_SELECTED_CONNECTOR(state, connector) {
      state.selectedConnector = connector;
    },
    SET_EXPORT_TOTALCOUNT(state, total) {
      state.pagination.count = total;
    },
    SET_PAGINATION_ORDER(state, order) {
      state.pagination.order = order;
    },
    RESET_PAGINATION(state) {
      state.pagination.page = 1;
    },
    RESET_SELECTED(state) {
      state.selectedStatus = '';
      state.selectedConnector = '';
      state.selectedAudience = '';
    },
    SET_LOG_DATA(state, payload) {
      state.log = payload;
      if (isEmpty(payload)) {
        state.logIsEmpty = true;
        state.logLoading = false;
      } else state.logIsEmpty = false;
    },
    SET_LOG_LOADING(state, isLoading) {
      state.logLoading = isLoading;
    },
    SET_LOG_ERROR(state, hasError) {
      state.logHasError = hasError;
    },
    RESET_LOG_DATA(state) {
      state.log = {};
    },
  },
  actions: {
    setConnectors({ commit }) {
      getConnectors().then(({ data }) => {
        if (!data.success) return;
        if (!data.data) data.data = [];

        const all = { id: 0, alias: '', name: 'Todos' };

        data.data.unshift(all);
        commit('SET_CONNECTORS', data.data);
      });
    },
    setAudiences({ commit }) {
      getAudiences({ active: 1 }).then(response => {
        const data = response?.data;
        if (!data.success) return;
        commit(
          'SET_AUDIENCES',
          data?.data.map(el => {
            return {
              id: el[0],
              name: el[1],
            };
          }),
        );
      });
    },
    getExportHistoryList({ commit, state }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', false);
      getExportsHistory({
        exportData: state.selectedAudience,
        status: state.selectedStatus,
        connector: state.selectedConnector,
        page: state.pagination.page,
        limit: state.pagination.limit,
        orderBy: state.pagination.order,
      })
        .then(({ data }) => {
          if (!data.success) {
            commit('SET_ERROR', true);
            return;
          }
          commit('SET_EXPORT_HISTORY', data?.data);
          commit('SET_EXPORT_TOTALCOUNT', data?.totalCount);
        })
        .catch(() => {
          commit('SET_ERROR', true);
          commit('SET_LOADING', false);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    setSelectedStatus({ commit, dispatch }, { status }) {
      commit('SET_SELECTED_STATUS', status);
      commit('RESET_PAGINATION');
      dispatch('getExportHistoryList');
    },
    setSelectedAudience({ commit, dispatch }, { audience }) {
      commit('SET_SELECTED_AUDIENCE', audience);
      commit('RESET_PAGINATION');
      dispatch('getExportHistoryList');
    },
    setSelectedConnector({ commit, dispatch }, { connector }) {
      commit('SET_SELECTED_CONNECTOR', connector);
      commit('RESET_PAGINATION');
      dispatch('getExportHistoryList');
    },
    setPaginationOrder({ commit, dispatch }, order) {
      commit('SET_PAGINATION_ORDER', order);
      dispatch('getExportHistoryList');
    },
    refreshExportList({ dispatch }) {
      dispatch('getExportHistoryList');
    },
    setCancelRecurrence({ dispatch }, scheduleId) {
      const enabled = 0;
      updateAudience({ scheduleID: scheduleId, enabled }).then(() => {
        dispatch('getExportHistoryList');
      });
    },
    resetSelected({ commit }) {
      commit('RESET_SELECTED');
    },
    setLogData({ commit, dispatch }, { scheduleId, page, limit } = {}) {
      dispatch('resetLogData');
      commit('SET_LOG_LOADING', true);
      commit('SET_LOG_ERROR', false);
      getExportLog({ scheduleId, page, limit })
        .then(({ data }) => {
          if (!data.success) return;
          commit('SET_LOG_DATA', data?.data);
        })
        .catch(() => {
          dispatch('resetLogData');
          commit('SET_LOG_ERROR', true);
        })
        .finally(() => {
          commit('SET_LOG_LOADING', false);
        });
    },
    resetLogData({ commit }) {
      commit('RESET_LOG_DATA');
    },
  },
  getters: {
    getFilteredAudience:
      state =>
      (term = '') => {
        // applyParse => add scape regex characters and ignore accents from characters on term argument
        const applyParse = escapeRegExp(stringParser(term));
        return state.historyData.filter(e => new RegExp(applyParse, 'gi').test(stringParser(e.name)));
      },
    getHistoryData: state => state.historyData,
    getLogData: state => state.log,
    // Verify Mixin Getters
    getLoading: state => state.loadingGlobal,
    getIsEmpty: state => state.isEmptyGlobal,
    getHasError: state => state.hasErrorGlobal,
  },
};

export default historyExport;
