<template>
  <div class="d-flex flex-column h-100 full-size">
    <figure>
      <skeleton-loader class="full-size" height="120px" />
    </figure>
    <div class="details">
      <skeleton-loader class="full-size mb-2" height="22px" width="220px" />
      <div class="list">
        <skeleton-loader class="full-size" height="14px" width="117px" />
        <skeleton-loader class="full-size" height="14px" width="117px" />
      </div>
      <div class="results">
        <div class="item col-3 pl-0">
          <skeleton-loader class="full-size" height="14px" width="49px" />
          <skeleton-loader class="full-size" height="14px" width="41px" />
        </div>
        <div class="item col-3">
          <skeleton-loader class="full-size" height="14px" width="49px" />
          <skeleton-loader class="full-size" height="14px" width="41px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
};
</script>

<style lang="scss" scoped>
.full-size {
  width: 276px;
}

figure {
  height: 120px;
  overflow: hidden;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0;
}

.list {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: $gray-300;
}

.details {
  padding: 20px;

  .list,
  .results {
    display: flex;
    padding: 12px 0;
  }

  .list {
    flex-flow: column;

    span:last-child {
      margin-top: 12px;
    }
  }

  .results {
    flex-flow: row;

    .item {
      padding-top: 6px;
    }

    .item span:last-child {
      margin-top: 16px;
      width: 100%;
    }
  }
}
</style>
