import store from '@/store';
import router from '@/router';

/**
 * @description Checks if the client has the route he is trying to access.
 */
export const hasMatch = routeTitle => {
  const getRoutes = store?.getters?.getRoutes;

  for (const i in getRoutes) {
    if (getRoutes[i].routes) {
      for (const index in getRoutes[i].routes) {
        if (getRoutes[i].routes[index].id === routeTitle) return true;
      }
    } else {
      if (getRoutes[i].id === routeTitle) return true;
    }
  }
  router.push('/');
  return false;
};

/**
 * @description Checks if the client has the sub route.
 */
export const hasMatchSubRoutes = (routeId, subRouteId) => {
  const routesGroup = store.getters?.getRoutes.find(route => route.id == routeId)?.routes;
  if (!routesGroup) return false;
  const verifyId = routesGroup.find(route => route.id == subRouteId)?.id;

  return verifyId === subRouteId;
};

export const validateAudienceActivationRoute = activationRouteId => {
  const audienceManagementRoute = store.getters?.getRoutes.find(route => route.id === 'audience-mgmt');
  const isAudienceActivationRouteValid = audienceManagementRoute?.routes.some(
    subRoute => subRoute.id === activationRouteId,
  );
  return !!isAudienceActivationRouteValid;
};

export const validateCampaignActivationRoute = activationRouteId => {
  const campaignManagementRoute = store.getters?.getRoutes.find(route => route.id === '360');
  const isCampaignActivationRouteValid = campaignManagementRoute?.routes.some(
    subRoute => subRoute.id === activationRouteId,
  );
  return !!isCampaignActivationRouteValid;
};

/**
 * @description Checks if the client has the route.
 */
export const hasMatchRoutes = routeId => Boolean(store.getters?.getRoutes.find(route => route.id == routeId)?.id);

/**
 * @description check if has valid route inside getRoutes on all levels
 * @param {String} routeId
 * @returns {Boolean}
 */
export const hasValidRoute = routeId => {
  const routes = store.getters?.getRoutes;

  let hasFilteredRoutes = Boolean(routes?.filter(el => el.id === routeId).length);
  if (!hasFilteredRoutes) {
    hasFilteredRoutes = Boolean(
      routes?.filter(el => el.routes).filter(item => item.routes.find(route => route.id === routeId)).length,
    );
  }
  return hasFilteredRoutes;
};
