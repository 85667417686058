import { FetchTableResponse, TableParams, ExportTableParams, SalesReportExport, RemoteExportResponse } from '../models';
import { getTable, getExportTable } from '../services';

export const fetchTable = ({
  preset,
  limit,
  page,
  orderBy,
  query,
  campaignId,
  storeId,
  sellerId,
  cluster,
  contactType,
  originSale,
}: TableParams): Promise<FetchTableResponse> =>
  getTable({
    preset,
    limit,
    page,
    orderBy,
    query,
    campaignId,
    storeId,
    sellerId,
    cluster,
    contactType,
    originSale,
  }).then(response => formatResponse(response?.data));

const formatResponse = (response: FetchTableResponse): FetchTableResponse => ({
  data: response?.data,
  errors: response?.errors,
  series: response?.series.map(serie => ({
    isMetric: serie?.isMetric,
    key: serie?.key,
    name: serie?.name,
    preview: serie?.preview,
    tooltip: serie?.tooltip,
    type: serie?.type,
    threshold: serie?.threshold,
  })),
  success: response?.success,
  totalCount: response?.totalCount,
  warnings: response?.warnings,
});

export const exportTable = ({
  fileType,
  metrics,
  preset,
  campaignId,
  storeId,
  sellerId,
  cluster,
  contactType,
  originSale,
}: ExportTableParams): Promise<SalesReportExport> =>
  getExportTable({ fileType, metrics, preset, campaignId, storeId, sellerId, cluster, contactType, originSale }).then(
    response => formatExport(response?.data),
  );

const formatExport = (response: RemoteExportResponse): SalesReportExport => ({
  batchId: response?.data?.batchId,
  success: response?.data?.success,
});
