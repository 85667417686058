<template>
  <div class="check-list no-click" v-show="opened">
    <div class="list no-click">
      <div v-for="(item, index) in items" :key="index" class="select no-click">
        <label class="no-click" @click.prevent="toggleSelection(item.key, filters)">
          <check-box class="no-click" :checked="isSelected(item.key, filters)" />
          <div class="text no-click">
            <span class="name no-click">{{ item.name }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from '@/components/_atoms/CheckBox';

export default {
  name: 'MenuMultiselect',
  components: {
    'check-box': CheckBox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      required: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isSelected(key, obj) {
      return obj.includes(key);
    },
    toggleSelection(key, obj) {
      if (this.isSelected(key, obj)) {
        this.unselectOption(key, obj);
      } else {
        this.selectOption(key, obj);
      }
    },
    selectOption(key, obj) {
      if (!this.isSelected(key, obj)) {
        obj.push(key);
      }
    },
    unselectOption(key, obj) {
      if (!this.isSelected(key, obj)) {
        return;
      }
      const index = obj.indexOf(key);
      obj.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  max-height: 235px;
  overflow-y: auto;
  padding: 5px;
}
.check-list {
  display: block;
  padding: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  background-color: $color-white;
  position: absolute;
  padding: 18px 15px;
  width: 100%;
  z-index: 1;
  top: 44px;
  border: 0;
  margin: 0;
  left: 0;
  &.hide {
    display: none;
  }
  .select {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    label {
      grid-template-columns: 16px 1fr;
      align-items: center;
      user-select: none;
      cursor: pointer;
      display: grid;
      margin: 0;
      &:hover {
        .check-box:not(.checked) {
          border-color: $gray-600;
        }
      }
      .text {
        margin-left: 10px;
        color: $gray-700;
        .name {
          line-height: 15px;
          font-weight: 400;
          font-size: 12px;
          display: block;
        }
      }
    }
  }

  > .select {
    &:hover {
      .check-list-child {
        visibility: visible;
      }
    }
    label {
      grid-template-columns: 16px 1fr 25px;
    }
    .icon-right-14px {
      font-size: 10px;
    }
  }
  .check-list-child {
    transition: all 0.25s cubic-bezier(0.1, 0.65, 0.5, 1);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    background-color: $color-white;
    transition-delay: 0.3s;
    visibility: hidden;
    position: absolute;
    max-height: 315px;
    overflow: hidden;
    display: block;
    padding: 10px;
    width: 285px;
    left: 200px;
    top: 0;
    .list {
      max-height: 295px;
      overflow-y: auto;
      padding: 5px;
      .state .name {
        font-weight: 600;
      }
    }
    .check-list-subchild {
      padding: 12px 14px 12px 25px;
    }
  }
}

.check-list,
.list {
  &::-webkit-scrollbar-track {
    background-color: $gray-300;
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $gray-300;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-500;
    border-radius: 10px;
  }
}
</style>
