<template>
  <div class="actions__defaults">
    <i v-if="hasIcon" :class="`icon ${icon}`"></i>
    <slot name="svg-icon"></slot>
    <h1 v-if="title" :class="`actions__title${hasIcon || svg ? '--icon-is-active' : ''}`">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: 'ActionsDefaults',
  props: {
    title: {
      type: String,
      default: '',
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'icon-message-18px',
    },
    svg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.actions__defaults {
  display: flex;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin: 0;
  }

  .actions__title {
    &--icon-is-active {
      padding: 0 20px 0;
    }
  }

  .icon {
    color: $oto-omni;
    font-size: 26px;
  }

  .svg-icon {
    width: 20px;
    height: 25px;
  }
}
</style>
