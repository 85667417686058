<template>
  <div class="col-5 fit-content">
    <base-card-v2 class="seller-conversion-funnel">
      <div class="card-body">
        <template v-if="$_verifyLoaded('done')">
          <base-card-header
            :title="$t('360.sellers-analytics.conversionFunnel.conversionFunnelTitle')"
            icon="icon-cart"
          />
          <div class="chart-area">
            <div class="chart-description">
              <div class="conversion-text">
                <p class="value-label">
                  {{ $t('360.sellers-analytics.conversionFunnel.conversionRate') }}
                </p>
                <p>
                  <span class="conversion-rate">{{ sellerFunnel.data.salesPercent }}</span
                  >{{ $t('360.sellers-analytics.conversionFunnel.contactsMade') }}
                </p>
              </div>
              <div class="chart-subtitles">
                <p class="value-label">
                  {{ $t('360.sellers-analytics.conversionFunnel.funnel') }}
                </p>
                <ul>
                  <li><span />{{ $t('360.sellers-analytics.conversionFunnel.systemSuggestions') }}</li>
                  <li><span />{{ $t('360.sellers-analytics.conversionFunnel.contacts') }}</li>
                  <li><span />{{ $t('360.sellers-analytics.conversionFunnel.sales') }}</li>
                </ul>
              </div>
            </div>
            <div class="vertical-funnel-chart d-flex">
              <div class="levels level-1">
                <span>{{ sellerFunnel.data.suggestions }}</span>
                <svg width="126" height="17" viewBox="0 0 126 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H126L98 17H28L0 0Z" fill="#EBEBEB" />
                </svg>
              </div>
              <div class="levels level-2">
                <span>{{ sellerFunnel.data.contacts }}</span>
                <span class="percentual">({{ sellerFunnel.data.contactsPercent }})</span>
                <svg width="70" height="17" viewBox="0 0 70 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0H70L56 17H15L0 0Z" fill="#EBEBEB" />
                </svg>
              </div>
              <div class="levels level-3">
                <span>{{ sellerFunnel.data.sales }}</span
                ><span class="percentual">({{ sellerFunnel.data.salesPercent }})</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </base-card-v2>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
// store
import { mapGetters, mapState } from 'vuex';
// components
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
//mixin
import { verifyMixin } from '@/helpers/mixins';

export default {
  components: {
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  computed: {
    ...mapState('sellersAnalytics', ['sellerFunnel']),
    ...mapGetters('sellersAnalytics', ['getSellerFunnelEmpty', 'getSellerFunnelLoading', 'getSellerFunnelError']),
  },
  watch: {
    getSellerFunnelLoading() {
      this.loading = this.getSellerFunnelLoading;
    },
    getSellerFunnelError() {
      this.hasError = this.getSellerFunnelError;
    },
    getSellerFunnelEmpty() {
      this.isEmpty = this.getSellerFunnelEmpty;
    },
  },
  created() {
    this.$_reqConfig();
  },
};
</script>

<style lang="scss" scoped>
$level_1_color: #3590b9;
$level_2_color: #51bfda;
$level_3_color: #35ba9b;

.fit-content {
  height: 260px;
}

.seller-conversion-funnel {
  min-height: 253px;
}
.chart-area {
  display: grid;
  grid-template-columns: 50% 50%;
  .chart-description {
    .conversion-text {
      .conversion-rate {
        font-weight: 600;
        font-size: 16px;
        color: $level_3_color;
        margin-right: 8px;
      }
    }
    .chart-subtitles {
      margin-top: 20px;
      ul {
        list-style: none;
        padding-left: 0;
        font-size: 12px;
        color: #666;
        li::before {
          content: '';
          border-radius: 11px;
          width: 11px;
          height: 11px;
          display: inline-block;
          margin-right: 11px;
        }
        li:nth-child(1)::before {
          background-color: $level_1_color;
        }
        li:nth-child(2)::before {
          background-color: $level_2_color;
        }
        li:nth-child(3)::before {
          background-color: $level_3_color;
        }
      }
    }
  }
}
.vertical-funnel-chart {
  width: 126px;
  flex-direction: column;
  align-items: center;
  place-self: center;
  .levels {
    height: 35px;
    position: relative;
    margin-bottom: 17px;
    width: 126px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    .percentual {
      position: absolute;
      right: -58px;
    }
    &.level-1 {
      background: $level_1_color;
    }
    &.level-2 {
      background-color: $level_2_color;
      width: 70px;
      .percentual {
        color: $level_2_color;
      }
    }
    &.level-3 {
      background-color: $level_3_color;
      width: 41px;
      .percentual {
        color: $level_3_color;
      }
    }
    svg {
      position: absolute;
      bottom: -17px;
    }
  }
}
</style>
