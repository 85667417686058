<template>
  <div class="o-metrics_group">
    <div class="row">
      <div class="col-4 kpi-tags">
        <metrics-card
          :title="$tc('tags.kpi', 2)"
          icon="icon-media"
          :metrics="mTags"
          :info="$_verifIsEmpty(mTagsInfo, mTagsHasError, mTagsLoaded)"
        />
      </div>
      <div class="col-8">
        <metrics-card
          :title="$tc('tags.funnel', 1)"
          icon="icon-mail"
          :metrics="mFunnel"
          :info="$_verifIsEmpty(mFunnelInfo, mFunnelHasError, mFunnelLoaded)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/helpers/events/bus';
import { getMetrics } from '@/services/dashboard';
import { verifyMixin, verifyThensMixin } from '@/helpers/mixins';
import MetricsCard from '@/components/_molecules/MetricsCard';

export default {
  name: 'DashboardTagsMetricsGroup',
  components: {
    MetricsCard,
  },
  mixins: [verifyMixin, verifyThensMixin],
  data() {
    return {
      mTags: { a: {}, b: {} },
      mTagsInfo: false,
      mTagsHasError: false,
      mTagsLoaded: false,
      mFunnel: { a: {}, b: {}, c: {}, d: {} },
      mFunnelInfo: false,
      mFunnelHasError: false,
      mFunnelLoaded: false,
    };
  },
  mounted() {
    this.fetchData();
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      this.mTagsLoaded = true;
      this.mFunnelLoaded = true;

      getMetrics({ metrics: 'tags_hits,tags_match_rate' })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) {
            this.mTagsInfo = true;
            return;
          } else this.mTagsInfo = false;

          this.mTags = data.metrics;
        })
        .catch(() => {
          this.$_componentHeight();
          this.mTagsHasError = true;
        })
        .finally(() => {
          this.mTagsLoaded = false;
          this.$_verifLoading();
        });
      getMetrics({
        metrics: 'tags_cat,tags_detail,tags_cart,tags_checkout,tags_purchase',
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) {
            this.mFunnelInfo = true;
            return;
          } else this.mFunnelInfo = false;

          this.mFunnel = data.metrics;
        })
        .catch(() => {
          this.mFunnelHasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.mFunnelLoaded = false;
          this.$_verifLoading();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.o-metrics_group {
  margin-top: 12px;
}
</style>
