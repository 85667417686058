<template>
  <div class="dashboard-campaign-metrics-top">
    <template v-if="$_verifyLoaded('done')">
      <template v-for="(value, key) in metrics" :key="key">
        <metric
          :label="`campaign-group.${key}`"
          :metric-raw="value"
          :icon-right="key === 'revenue_ga' ? 'security' : ''"
          :icon-right-color="key === 'revenue_ga' ? '#ecc847' : ''"
        >
          <template v-slot:icon>
            <icon-security-fill />
          </template>
        </metric>
      </template>
    </template>
    <base-loader-spinner v-if="$_verifyLoaded('loading')" :height="'30'" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import IconSecurityFill from '@/assets/vue-icons/icons/SecurityFill';
import BaseLoaderSpinner from '@/components/_atoms/BaseLoaderSpinner';
import Metric from '@/components/_atoms/Metric';
import { getMetrics } from '@/services/dashboard';
import { verifyMixin } from '@/helpers/mixins';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'DashboardCampaignMetrics',
  components: {
    Metric,
    BaseLoaderSpinner,
    IconSecurityFill,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      metrics: {},
    };
  },
  mounted() {
    this.fetchData(this.settings.campaign);
  },
  methods: {
    fetchData(campaign) {
      this.$_reqConfig();
      getMetrics({
        metrics: 'sent,revenue_ga,conversions_ga,conversion_rate_ga',
        campaign,
      })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) return;
          // mounted manually to not copy revenue_ga obj reference
          const revenuePerSent = {
            revenue_per_sent: {
              data: {
                value: {
                  amount: data.metrics.revenue_ga.data.value.amount / data.metrics.sent.data.value.amount,
                  type: 'decimal',
                },
              },
            },
          };
          this.metrics = { ...data.metrics, ...revenuePerSent };
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-campaign-metrics-top {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: flex-start;
  padding: 0 50px;
  position: relative;
  min-height: 80px;
  position: relative;
  > div {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    &:first-child {
      padding: 0;
    }
  }
}
</style>
