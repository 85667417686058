<template>
  <span class="skeleton-loader" :class="{ purple }" :style="{ height, width }"></span>
</template>

<script>
export default {
  name: 'SkeletonLoader',
  props: {
    height: {
      default: '36px',
      type: String,
    },
    width: {
      default: '100%',
      type: String,
    },
    purple: Boolean,
  },
};
</script>

<style lang="scss">
@mixin gradient-color($color) {
  background-image: linear-gradient(
    90deg,
    rgba($color, 0) 0,
    rgba($color, 0.2) 20%,
    rgba($color, 0.5) 60%,
    rgba($color, 0)
  );
}

.skeleton-loader {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  background-color: $gray-400;
  max-width: 100%;
  max-height: 100%;
  border-radius: 2px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include gradient-color($color-white);
    transform: translateX(-100%);
    animation: shimmer 1.6s infinite;
  }

  &.purple {
    background-color: $blue-600;
    &:after {
      @include gradient-color($blue-500);
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
