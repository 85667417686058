<template>
  <section class="reports-list">
    <div class="row">
      <div class="reports-list__container">
        <base-card-v2 class="reports-list__card" :class="{ loadHeight: loading || isEmpty || hasError }">
          <template v-if="$_verifyLoaded('done')">
            <base-card-header :title="$t('reports.title')" icon="icon-folder-30px icon-30">
              <template v-slot:item-right>
                <div class="reports-list__list-actions">
                  <button
                    class="btn reports-list__btn-add"
                    :title="$t('reports.home.add-report')"
                    @click.prevent="openCreateReportModal"
                  >
                    {{ $t('reports.home.add-report') }}
                  </button>
                </div>
              </template>
            </base-card-header>
            <div class="tg-list reports-list__table">
              <div class="tg-row reports-list__table__row -header">
                <div class="tg-col reports-list__table__col">
                  <span>{{ $t('reports.home.name') }}</span>
                </div>
              </div>

              <div v-for="(report, index) in reports" :key="index" class="tg-row reports-list__table__row">
                <div class="tg-col reports-list__table__col">
                  <i class="icon icon-folder-30px icon-30" />
                  <span v-if="report.name" class="reports-list__table__col__name" @click="openReport(report.id)">
                    {{ report.name }}
                  </span>
                </div>
                <div class="tg-col reports-list__table__col">
                  <menu-dropdown :label="report.id" :actions="actionsDropdown(report, index)" />
                </div>
              </div>
            </div>
          </template>
          <base-loader v-show="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
        </base-card-v2>
      </div>
    </div>
    <create-report-modal />
    <modal-dialog-question
      :id="modalId"
      :delete-action="triggerConfirmDelete"
      :is-visible="isVisible"
      @closeDialogModal="isVisible = $event"
    />
  </section>
</template>

<script>
// helpers
import bus from '@/helpers/events/bus';
import verifyMixin from '@/helpers/mixins/verifyMixin';
// services
import { getReports, deleteReport } from '@/services/reports';
// organisms
import ModalDialogQuestion from '@/components/_atoms/ModalDialogQuestion';
// molecules
import MenuDropdown from '@/components/_atoms/MenuDropdown.vue';
import CreateReportModal from '@/components/reports/CreateReportModal';
// atoms
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
// utilities
import { useMixpanelConfig } from '@/utilities/mixpanel';

export default {
  name: 'ReportsList',
  components: {
    MenuDropdown,
    CreateReportModal,
    BaseLoader,
    InfoCard,
    ModalDialogQuestion,
  },
  mixins: [verifyMixin],
  data() {
    return {
      modalId: 'modal-report-confirm-delete',
      reports: [],
      reportId: '',
      isVisible: false,
    };
  },
  mounted() {
    useMixpanelConfig('view_reports_list');

    bus.$on('report-render-list', () => {
      this.fetchData();
    });
    bus.$on('show-modal-confirm-delete', reportId => {
      this.isVisible = true;
      this.reportId = reportId;
    });
    this.fetchData();
  },
  methods: {
    openReport(reportId) {
      this.$router.push({
        name: 'reportDetail',
        params: {
          id: reportId,
        },
      });
    },
    fetchData() {
      this.$_reqConfig();
      getReports()
        .then(({ data }) => {
          if (!data?.data) return;
          this.reports = data.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openCreateReportModal() {
      bus.$emit('open-create-report-modal');
    },
    triggerConfirmDelete() {
      deleteReport(this.reportId).then(() => {
        this.fetchData();
      });
    },

    /**
     * @description actions to menu dropdown
     */
    delete(report) {
      bus.$emit('show-modal-confirm-delete', report.id);
    },
    actionsDropdown(report) {
      return [
        {
          name: 'delete',
          action: () => this.delete(report),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.reports-list {
  &__container {
    align-self: center;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }

  &__card {
    &.loadHeight {
      min-height: 350px;
    }
  }

  &__table {
    min-height: 250px;
    font-size: 12.1px;
    color: $gray-600;

    &__row {
      display: grid;
      grid-template-columns: 95% 5%;
      grid-template-rows: 40px;
      border-bottom: 1px solid $gray-300;
      justify-items: start;
      align-items: center;

      &.-header {
        font-weight: 600;
      }

      &:not(.-header) {
        cursor: pointer;

        &:nth-child(even) {
          background: #eeeeee4d;
        }
        &:hover {
          background: $gray-300;
        }
        .tg-col:first-child {
          padding-left: 15px;
        }
      }
    }

    &__col {
      display: flex;

      &__name {
        color: $oto-omni;
        user-select: text;
        max-width: 260px;
        padding-top: 7px;
        margin-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:focus {
          max-width: 100%;
          min-width: 150px;
          outline: 0 none;
          border-bottom: 1px solid;
          background: #fbfbfb;
          z-index: 2;
        }
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 500px;
        z-index: 1;
      }

      svg {
        margin-right: 8px;
      }
    }
  }

  &__list-actions {
    margin-left: auto;
    display: flex;
    float: right;
  }

  &__btn-add {
    width: 170px;
    height: 34px;
    display: block;
    line-height: 34px;
  }
}
</style>
