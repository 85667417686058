<template>
  <el-dialog v-model="isVisible" id="personas-label-list-modal" append-to-body>
    <div class="content">
      <h2>{{ $t('labels-personas.label-list') }}</h2>
      <div>
        <div class="form-wrapper search-and-filter">
          <input v-model="labelList" class="find-term" :placeholder="$t('labels.search-label')" />
          <i v-if="labelList !== ''" class="icon icon-close" @click="clearSearch"></i>
          <i v-else class="icon icon-search"></i>
        </div>
      </div>
      <div class="checkbox-container customScrollBar">
        <div v-for="label in getFilteredLabels(labelList)" :key="label.id" class="label-list-item">
          <label class="label label-checkbox" @click.prevent="$_toggleSelection(label.id, checkedLabels)">
            <check-box class="checkbox" :checked="$_isSelected(label.id, checkedLabels)" />
          </label>
          <div class="label-group">
            <div v-if="label.name" class="name">{{ label.name }}</div>
            <p v-if="label.description" class="desc">{{ label.description }}</p>
          </div>
        </div>
      </div>
      <div class="button-container">
        <button class="btn" :disabled="!checkedLabels.length ? true : false" @click="handleLabels">
          {{ $tc('labels.home.save-label', 2) }}
        </button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// libs, helpers, services
import bus from '@/helpers/events/bus';
import { mapState, mapGetters, mapActions } from 'vuex';
import { checkboxMixin } from '@/helpers/mixins';
// components - atoms
import CheckBox from '@/components/_atoms/CheckBox';

export default {
  name: 'PersonasLabelsListModal',
  components: {
    CheckBox,
  },
  mixins: [checkboxMixin],
  data() {
    return {
      labelList: '',
      checkedLabels: [],
      groupDetail: {},
      isVisible: false,
    };
  },
  computed: {
    ...mapState('personas', ['personaDetail', 'labelsAvailable']),
    ...mapGetters('personas', ['getFilteredLabels']),
  },
  mounted() {
    bus.$on('open-persona-label-list-modal', groupDetail => {
      this.groupDetail = groupDetail;
      this.handleCheckedLabels();
      setTimeout(() => {
        this.openModal();
      }, 150);
    });
  },
  methods: {
    ...mapActions('personas', ['addSerie', 'updateSerie']),
    /**
     * @description add series ou just update if already has any
     */
    handleLabels() {
      const labelGroup = this.flatGroupDetailSeries();
      this.handleDuplicatedLabels();
      labelGroup.length ? this.handleUpdateLabels() : this.handleSaveLabels();
      this.closeModal();
      bus.$emit('persona-update-label-group');
    },
    /**
     * @description update serie
     */
    handleUpdateLabels() {
      this.updateSerie({
        include: !!this.groupDetail.include,
        group: this.groupDetail.group,
        data: this.handleAddNewLabels(),
      });
    },
    /**
     * @description save labels
     */
    handleSaveLabels() {
      this.addSerie({
        include: !!this.groupDetail.include,
        group: this.groupDetail.group,
        data: this.checkedLabels,
      });
    },
    /**
     * @description get previous selected labels
     */
    handleCheckedLabels() {
      if (this.groupDetail?.series) this.checkedLabels = this.groupDetail?.series.map(el => el.label);
    },
    /**
     * @description flat group detail to get just the ids
     */
    flatGroupDetailSeries() {
      return this.groupDetail?.series.map(el => el.label);
    },
    /**
     * @description handle reset and filter previous checked items
     */
    handleDuplicatedLabels() {
      this.resetCheckedValues();
      this.filterByCheckedItems();
    },
    /**
     * @description reset group detail series
     */
    resetCheckedValues() {
      if (!this.checkedLabels.length) this.groupDetail.series = [];
    },
    /**
     * @description filter by previous selected items
     */
    filterByCheckedItems() {
      if (this.checkedLabels.length) {
        this.groupDetail.series = this.groupDetail.series.filter(el =>
          this.checkedLabels.find(item => item === el.label),
        );
      }
    },
    /**
     * @description merge arrays and remove duplicates
     */
    handleAddNewLabels() {
      return [...new Set([...this.checkedLabels, ...this.groupDetail?.series.map(item => item.label)])];
    },
    /**
     * @description clear search
     */
    clearSearch() {
      this.labelList = '';
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: -25px 50px 0;

  h2 {
    margin-bottom: 30px;
  }

  .checkbox-container {
    box-shadow: inset -12px -1px 12px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
    margin-top: 34px;
    max-height: 200px;
    overflow: auto;
    padding: 8px 0;

    .label-list-item {
      display: flex;
      margin: 8px 0;

      .label-checkbox {
        margin-right: 8px;
      }

      .label-group {
        display: flex;
        flex-flow: column;

        .name {
          color: #666;
          font-size: 12px;
          font-weight: 600;
        }

        p.desc {
          text-align: left;
          font-size: 12px;
          font-weight: normal;
          margin: 0;
        }
      }
    }
  }

  .button-container {
    margin-top: 17px;

    button {
      margin: auto;
      width: 180px;
      height: 40px;
    }
  }

  .search-and-filter {
    position: relative;

    .find-term {
      width: 100%;
    }

    i {
      position: absolute;
      right: 7px;
      top: 4px;
      font-size: 26px;
      color: #bbb;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
#personas-label-list-modal {
  max-width: 385px;
}
</style>
