<template>
  <div id="allaegmentgroups_database">
    <div class="row">
      <div class="col-12 col-xl-8">
        <div :class="{ loadHeight: loading || isEmpty || hasError }" class="card">
          <div v-if="$_verifyLoaded('done')" class="card-body">
            <div class="row">
              <div class="col">
                <div class="chart-title">{{ $t('database-people') }}</div>
                <label class="chart-value-big color-oto-brand-omni">
                  {{ $_formatDecimal(dataReturn.metrics.users_total.data.value.amount) }}
                </label>
              </div>
              <div class="col">
                <div class="chart-title">{{ $tc('total-clients', 0) }}</div>
                <label class="chart-value-big color-oto-brand-omni">
                  {{ $_formatDecimal(dataReturn.metrics.customers_total.data.value.amount) }}
                </label>
              </div>
              <div class="col">
                <div class="chart-title">
                  {{ $t('database-active-clients') }}
                </div>
                <label class="chart-value-big color-oto-brand-omni">
                  {{ $_formatDecimal(dataReturn.metrics.customers_active.data.value.amount) }}
                </label>
              </div>
              <div class="col">
                <div class="chart-title">
                  {{ $t('database-clients-period') }}
                </div>
                <label class="chart-value-big color-oto-brand-omni">
                  {{ $_formatDecimal(dataReturn.metrics.customers.data.value.amount) }}
                </label>
              </div>
            </div>
          </div>
          <base-loader v-if="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
        </div>
      </div>
      <!-- Recency -->
      <div class="col">
        <div :class="{ loadHeight: loading } && { loadHeightInfo: isEmpty || hasError }" class="card recency">
          <div v-if="$_verifyLoaded('done')" class="card-body mt-0">
            <div class="row">
              <div class="col">
                <div class="chart-title">{{ $t('recency') }}</div>
                <label class="chart-value-big color-oto-brand-omni value">
                  {{
                    dataReturn.metrics[recency].data.value.amount ? dataReturn.metrics[recency].data.value.amount : 0
                  }}
                  {{ $tc('day', parseInt(dataReturn.metrics[recency].data.value.amount)) }}
                </label>
              </div>
              <div class="col-3 text-right">
                <i class="icon icon-expand ml-auto" @click="openLightbox('lightbox-recency')"></i>
              </div>
            </div>
          </div>
          <base-loader v-if="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :height="'45px'" :error="hasError" />
        </div>
      </div>
      <!-- Frequency -->
      <div class="col">
        <div :class="{ loadHeight: loading } && { loadHeightInfo: isEmpty || hasError }" class="card frequency">
          <div v-if="$_verifyLoaded('done')" class="card-body mt-0">
            <div class="row">
              <div class="col">
                <div class="chart-title">{{ $t('frequency') }}</div>
                <label class="chart-value-big color-oto-brand-omni value">
                  {{ $_formatDecimal(dataReturn.metrics.frequency.data.value.amount, 2) }}
                </label>
              </div>
              <div class="col-3 text-right">
                <i class="icon icon-expand ml-auto" @click="openLightbox('lightbox-frequency')"></i>
              </div>
            </div>
          </div>
          <base-loader v-if="$_verifyLoaded('loading')" />
          <info-card v-show="$_verifyLoaded('info')" ref="message-error" :height="'45px'" :error="hasError" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import getMetrics from '@/services/getmetrics';
import { openLightbox } from '@/helpers/lightbox';
import bus from '@/helpers/events/bus';
import InfoCard from '@/components/_atoms/InfoCard';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import { verifyMixin, numberMixin } from '@/helpers/mixins';

export default {
  name: 'AllSegmentGroupsDataBase',
  components: {
    BaseLoader,
    InfoCard,
  },
  props: {
    makeApiCall: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [verifyMixin, numberMixin],
  data() {
    return {
      dataReturn: {},
      brand: null,
    };
  },
  computed: {
    recency() {
      let metric = 'recency';
      if (this.$store.getters.recency) {
        const recency = this.$store.getters.recency.toLowerCase();
        if (recency !== 'all') metric = `recency_${recency}`;
      }
      return metric;
    },
  },
  watch: {
    makeApiCall() {
      if (this.makeApiCall) {
        // segmento default
        this.fetch_data();
      }
    },
  },
  mounted() {
    // bus chamado quando se altera uma data globalmente ou brand
    bus.$on('render-cards', () => {
      this.fetch_data();
    });
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();
      this.brand = this.$store.getters.selectedBrand?.id;

      getMetrics({
        metrics: ['customers', 'customers_total', 'customers_active', 'frequency', this.recency, 'users_total'],
      })
        .then(response => {
          if (this.$_verifyHasData(response.data.metrics)) return;

          if (!response) {
            this.isEmpty = true;
            return;
          }
          this.dataReturn = response.data;
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openLightbox(id) {
      openLightbox(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 88px;
  &.recency,
  &.frequency {
    .value {
      @media (max-width: $tablet-max-width) {
        font-size: 22px;
        line-height: 22px;
      }
    }
  }
}
.expand {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.recency,
.frequency {
  &.loadHeight {
    height: 88px;
  }
  &.loadHeightInfo {
    height: 150px;
  }
}

#allaegmentgroups_database {
  .loadHeight {
    min-height: 88px;
  }
}
</style>
