<template>
  <div id="dialog-alert">
    <img src="@/assets/svgs/Alert.svg" :alt="$t('360.actions.dialog.alert-icon')" />
    <p v-if="message" class="dialog__message">{{ $t(`${message}`) }}</p>
  </div>
</template>

<script>
export default {
  name: 'DialogAlert',
  props: {
    message: {
      type: String,
      default: '_do-you-want-to-delete',
    },
  },
};
</script>

<style lang="scss" scoped>
#dialog-alert {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 20px;
  }

  .dialog__message {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: block;
    width: 100%;
    color: $gray-700;
    line-height: 26px;
    padding: 0 20px;
  }

  .dialog__confirm {
    width: 160px;
  }
}
</style>
