<template>
  <div class="omnichannel-profile-card card">
    <div v-show="$_verifyLoaded('done')" class="card-body">
      <h2 class="card-title d-flex align-items-center">
        <i class="icon icon-customer"></i>
        <span>{{ $t('segments-profile-title') }}</span>
      </h2>
      <div class="row chart-group">
        <div class="col-11">
          <chart-list :chart-name="$t('client-type')" :rows-list="objectChartListType" />
        </div>
      </div>
      <div class="row chart-group">
        <div class="col-11">
          <chart-list :chart-name="$t('client-origin')" :rows-list="objectChartListOrigin" />
        </div>
      </div>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </div>
</template>

<script>
import getMetrics from '@/services/getmetrics';
import ChartList from '@/components/_organisms/ChartList';
import bus from '@/helpers/events/bus';
import { getPercentual, getMax } from '@/utilities/numbers';
import { formatPercent } from '@/utilities/formatters';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  name: 'OmnichannelProfileCard',
  components: {
    ChartList,
    BaseLoader,
    InfoCard,
  },
  mixins: [verifyMixin],
  props: {
    filterStore: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      selectedStore: '',
      source: undefined,
      active: undefined,
      type: undefined,
      digital: undefined,
      customers: undefined,
      ready: false,
      objectChartListType: [],
      objectChartListOrigin: [],
    };
  },
  mounted() {
    if (this.filterStore === false) {
      this.fetch_data();
      bus.$on('render-cards', () => {
        this.fetch_data();
      });
    }
    bus.$on('store-changed', value => {
      this.selectedStore = value;
      this.fetch_data();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetch_data() {
      this.$_reqConfig();

      getMetrics({
        metrics: 'arr_contacts_source,customers_active,customers_type,digital_influence,customers',
        version: 2,
        ...(this.filterStore && {
          endpoint: 'stores/get_metrics',
          filters: `store_id:${this.selectedStore}`,
        }),
      })
        .then(response => {
          const { metrics } = response.data;

          if (this.$_verifyHasData(metrics)) return;

          this.source = metrics.arr_contacts_source.data.series.map(item => item.value);
          this.active = metrics.customers_active.data.value.amount;
          this.type = metrics.customers_type.data.series.map(item => item.value);
          this.digital = metrics?.digital_influence.data.series.map(item => item?.value);
          this.customers = metrics?.customers?.data?.value?.amount;

          // Chart: Onde compram?
          this.objectChartListType = [];
          const maxType = getMax(this.type[0], this.type[1], this.type[2]);
          this.objectChartListType.push({
            label: this.$i18n.tc('ecommerce'),
            progress: String(getPercentual(this.type[0], maxType)),
            value: formatPercent(this.type[0] / this.active, 1),
            color: 'green',
            tooltip: undefined,
          });
          this.objectChartListType.push({
            label: this.$i18n.tc('in-store'),
            progress: String(getPercentual(this.type[1], maxType)),
            value: formatPercent(this.type[1] / this.active, 1),
            color: 'darkpurple',
            tooltip: undefined,
          });

          // Chart: De onde vêm?
          this.objectChartListOrigin = [];
          const maxOrigin = getMax(this.source[0], this.source[1]);
          this.objectChartListOrigin.push({
            label: this.$i18n.tc('only-in-store'),
            progress: String(getPercentual(this.source[1], maxOrigin)),
            value: formatPercent(this.source[1] / this.active, 1),
            color: 'darkpurple',
            tooltip: undefined,
          });
          this.objectChartListOrigin.push({
            label: this.$t('digital-influenced-en'),
            progress: String(this.digital[0] * 100),
            value: formatPercent(this.digital[0]),
            color: 'green',
            tooltip: this.digital[0] ? this.tooltipDigital() : null,
          });
          this.objectChartListOrigin.push({
            label: this.$i18n.tc('omnichannel'),
            progress: String(getPercentual(this.type[2], maxType)),
            value: formatPercent(this.type[2] / this.active, 1),
            color: 'lightblue',
            tooltip: undefined,
          });
        })
        .catch(() => {
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tooltipDigital() {
      return `${formatPercent(this.digital[0])} (${this.digital[1]}) do total (${this.customers})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.omnichannel-profile-card {
  .title {
    height: 50px;
    font-size: 0.8em;
    font-weight: bold;
  }

  @media screen and(mix-width: 992px) {
    .padding-left {
      padding: 0 0 0 12px;
    }
    .padding-right {
      padding: 0 12px 0 0;
    }
  }
}
</style>

<style lang="scss">
.omnichannel-profile-card {
  .bar-tooltip {
    bottom: 25px;
    right: -10px;
  }
}
</style>
