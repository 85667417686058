<template>
  <div class="overview row">
    <div class="col-lg-5 col-12">
      <category-details :details="categoryData" :category="category" :load="loading" :error="hasError" />
    </div>
    <div class="col-lg-3 col-12">
      <category-photo :url="categoryDataImg" :alt="category" :load="loading" :error="hasError" />
    </div>
    <div class="col-lg-4 col-12">
      <category-overview :overview="categoryData" :category="category" :load="loading" :error="hasError" />
    </div>
  </div>
</template>

<script>
import { getCategoryOverview } from '@/services/categories';
import bus from '@/helpers/events/bus';
import CategoryDetails from '@/components/categories/category/overview/_molecules/Details';
import CategoryOverview from '@/components/categories/category/overview/_molecules/Overview';
import CategoryPhoto from '@/components/categories/category/overview/_molecules/Photo';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  components: {
    CategoryDetails,
    CategoryPhoto,
    CategoryOverview,
  },
  mixins: [verifyMixin],
  props: {
    category: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      categoryData: {},
      categoryDataImg: null,
    };
  },
  mounted() {
    this.loading = this.load;
    this.fetchData();
    bus.$on('render-cards', this.fetchData);
  },
  beforeUnmount() {
    bus.$off('render-cards', this.fetchData);
  },
  methods: {
    fetchData() {
      this.$_reqConfig();

      getCategoryOverview(this.category)
        .then(({ data }) => {
          if (this.$_verifyData(data.data)) {
            this.categoryData = {};
            if (this.category === undefined) {
              bus.$emit('update-search-fixed-data', {
                title: '-',
                subtitle: '-',
              });
            }
            this.categoryDataImg = null;
            return;
          }

          this.categoryData = data.data;
          this.categoryDataImg = data.data.category_img;

          bus.$emit('update-search-fixed-data', {
            title: this.category,
            alt: this.category,
          });
        })
        .catch(() => {
          this.categoryData = {};
          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1200px) {
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 26.66667%;
    flex: 0 0 26.66667%;
    max-width: 26.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -moz-flex: 0 0 33.33333%;
    flex: 0 0 45.33333%;
    max-width: 43.33333%;
  }
}
</style>
