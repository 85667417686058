
// libs
import { defineComponent, PropType, ref, toRefs } from 'vue';
// helpers
import { openLightbox } from '@/helpers/lightbox';
// types
import { StoresType } from '@/features/SalesRanking/types';

export default defineComponent({
  name: 'SellerRankingLink',
  props: {
    stores: {
      type: Array as PropType<StoresType[]>,
      required: true,
    },
    selectedStore: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const { stores, selectedStore } = toRefs(props);

    const storeSelected = ref<StoresType>();

    const storeSelectedFind = () => {
      storeSelected.value = stores?.value.find(
        (store: { id: { toString: () => string } }) => store.id.toString() === selectedStore.value.toString(),
      );
    };

    function openModal(id: string) {
      storeSelectedFind();

      openLightbox(id, {
        storeSelected,
      });
    }

    return {
      openModal,
    };
  },
});
