<template>
  <div class="integration-list-skeleton">
    <skeleton-loader height="20px" width="219px" class="mb-2" />

    <div class="connector">
      <skeleton-serie />
      <skeleton-serie />
      <skeleton-serie />
      <skeleton-serie />
    </div>
  </div>
</template>
<script>
import IntegrationSerieSkeleton from '@/components/audiences/create/integration/IntegrationSerieSkeleton';
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
    'skeleton-serie': IntegrationSerieSkeleton,
  },
};
</script>
<style lang="scss">
.audience-group-skeleton {
  background-color: #f5f5f5;
  padding: 25px 40px;
  margin-bottom: 18px;
  border-radius: 15px;
  box-shadow: -2px 3px 10px rgba(black, 0.1);
  .form {
    > span {
      margin-right: 15px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
