<template>
  <div
    v-if="connector.connect !== 'omnichat' || (connector.connect === 'omnichat' && !isOmnichatAndIndividualApproach)"
    class="form d-flex"
    :class="`${connector.connect} ${!editable ? 'inactive' : ''}`"
  >
    <el-tooltip
      :visible="isShowingScheduleSelectTooltip"
      :raw-content="true"
      :content="$t('audiences.value-error')"
      placement="top"
      popper-class="el--tooltip-error integration-form--tooltip"
    >
      <div class="form-group mr">
        <label>{{ $t(`audiences.integration.update`) }}</label>
        <select
          v-model="connector.schedule"
          class="schedule if--schedule"
          data-name="schedule"
          :disabled="!editable"
          @change="change"
        >
          <option :value="undefined">{{ $t('select') }}</option>
          <option v-for="(option, index) in schedules" :key="index" :value="option.value">
            {{ $t(`audiences.integration.schedule.${option.name}`) }}
          </option>
        </select>
      </div>
    </el-tooltip>
    <el-tooltip
      :visible="isShowingHasDatetimeTooltip"
      :raw-content="true"
      :content="$t('audiences.value-error')"
      placement="top"
      popper-class="el--tooltip-error integration-form--tooltip"
    >
      <div class="form-group mr">
        <label>{{ $t(`audiences.integration.first-exportation`) }}</label>
        <select
          v-model="connector.hasDatetime"
          class="schedule if--hasDatetime"
          data-name="hasDatetime"
          :disabled="!editable"
          @change="change"
          :data-cy="`first-exportation-select-${connector.alias}`"
        >
          <option :value="undefined">{{ $t('select') }}</option>
          <option :value="false">
            {{ $t('audiences.integration.on-save') }}
          </option>
          <option :value="true">
            {{ $t('audiences.integration.scheduled') }}
          </option>
        </select>
      </div>
    </el-tooltip>
    <div v-show="connector.hasDatetime" class="form-group element--datepicker datepicker__integration-form">
      <label>{{ $t(`audiences.integration.date`) }}</label>
      <el-date-picker
        v-model="connector.startDatetime"
        type="datetime"
        format="YYYY-MM-DD HH:mm"
        value-format="YYYY-MM-DD HH:mm"
        :clearable="false"
        :prefix-icon="customPrefix"
        :disabled-date="disabledBackwardDates"
        :placeholder="$tc('_select', 2)"
        :disabled-hours="disabledDatepickerHours"
        :disabled-minutes="disabledDatepickerMinutes"
        :disabled="!editable"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import bus from '@/helpers/events/bus';
import { dateTimeInverseFormat } from '@/utilities/constants';
import { mapState } from 'vuex';
import { h, shallowRef } from 'vue';
import { disabledBackwardDates, disabledHours, disabledMinutes } from '@/utilities/datepicker';

export default {
  setup() {
    const customPrefix = shallowRef({
      render() {
        return h('i', {
          class: 'icon icon-calendar-30px',
        });
      },
    });
    return { customPrefix };
  },
  props: {
    serie: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      connector: { ...this.serie },
      schedules: [
        { name: 'zero', value: 0 },
        { name: 'one', value: 1 },
        { name: 'two', value: 2 },
        { name: 'four', value: 4 },
        { name: 'six', value: 6 },
        { name: 'eight', value: 8 },
        { name: 'twelve', value: 12 },
        { name: 'day', value: 24 },
        { name: 'two-days', value: 48 },
        { name: 'seven-days', value: 168 },
        { name: 'fifteen-days', value: 360 },
        { name: 'thirty-days', value: 720 },
      ],
      isShowingScheduleSelectTooltip: false,
      isShowingHasDatetimeTooltip: false,
    };
  },
  computed: {
    ...mapState('audience', ['isOmnichatAndIndividualApproach']),
  },
  watch: {
    'connector.startDatetime'(newVal) {
      // eslint-disable-line
      // dispatch event to update integration serie based in connect property (name of connector)
      this.$store.dispatch('audience/updateIntegration', {
        connect: this.connector.connect,
        startDatetime: newVal,
        hasEdited: true,
      });
    },
    serie: {
      handler(newVal) {
        this.connector = { ...newVal };
      },
      deep: true,
    },
  },
  mounted() {
    bus.$on('validate-connect-fields', series => {
      series.forEach(serie => {
        if (serie.connect === this.connector.connect) {
          const elements = document.querySelectorAll(
            `.${this.connector.connect} select, .${this.connector.connect} input:not([style*="display:none"]`,
          );

          elements.forEach(element => {
            this.validate(element);
          });
        }
      });
    });
  },
  beforeUnmount() {
    bus.$off('validate-connect-fields');
  },
  methods: {
    change(event) {
      const { name } = event.target.dataset;

      // dispatch event to update integration serie based in connect property (name of connector)
      this.$store.dispatch('audience/updateIntegration', {
        connect: this.connector.connect,
        [name]: this.connector[name],
        hasEdited: true,
      });

      if (name === 'hasDatetime' && this.connector.hasDatetime) {
        this.connector.startDatetime = moment().second(0).add(15, 'minutes').format(dateTimeInverseFormat);
        // dispatch event to update integration serie based in connect property (name of connector)
        this.$store.dispatch('audience/updateIntegration', {
          connect: this.connector.connect,
          startDatetime: this.connector.startDatetime,
          hasEdited: true,
        });
      }
    },
    validate(target) {
      if (this.serie[target.dataset.name] === undefined) {
        if (target.classList.contains('if--hasDatetime')) {
          this.isShowingHasDatetimeTooltip = true;
          setTimeout(() => (this.isShowingHasDatetimeTooltip = false), 2000);
        }
        if (target.classList.contains('if--schedule')) {
          this.isShowingScheduleSelectTooltip = true;
          setTimeout(() => (this.isShowingScheduleSelectTooltip = false), 2000);
        }
      }
    },
    disabledDatepickerHours() {
      return disabledHours(this.connector.startDatetime);
    },
    disabledDatepickerMinutes() {
      return disabledMinutes(this.connector.startDatetime);
    },
    disabledBackwardDates,
  },
};
</script>
<style lang="scss" scoped>
.form {
  padding-left: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: cente;
}
.form-group {
  position: relative;
  margin-bottom: 0;
  width: 170px;
}

select {
  width: 170px;
}
</style>
<style lang="scss">
.datepicker__integration-form {
  .el-input__wrapper {
    flex-grow: 0;
  }
}
.el-popper.is-dark.el--tooltip-error.integration-form--tooltip {
  width: 200px;
}
</style>
