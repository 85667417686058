<template>
  <lightbox-template id="lightbox-database" icon="customer" :title="$t('segments-title')">
    <people-chart :name-select="$_segmentNameSelect()" />
    <clients-chart :name-select="$_segmentNameSelect()" />
    <database-table :segment-selected="segmentSelected" />
  </lightbox-template>
</template>

<script>
import lightboxesMixin from '@/helpers/mixins/lightboxesMixin';
import LightboxTemplate from '@/components/lightbox/LightboxTemplate';
import PeopleChart from '@/components/lightbox/lightboxes/_organisms/database/PeopleChart';
import ClientsChart from '@/components/lightbox/lightboxes/_organisms/database/ClientsChart';
import DatabaseTable from '@/components/lightbox/lightboxes/_organisms/database/DatabaseTable';

export default {
  name: 'LightboxDatabase',
  components: {
    LightboxTemplate,
    PeopleChart,
    ClientsChart,
    DatabaseTable,
  },
  mixins: [lightboxesMixin],
  data() {
    return {
      segmentSelected: null,
    };
  },
  mounted() {
    this.segmentSelected = this.$store.getters.selectedSegment.segmentId;
  },
};
</script>

<style lang="scss" scoped>
.loadHeight {
  min-height: 250px;
  position: relative;
}
</style>
