<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <base-card-v2 class="dashboard-tags">
    <div v-if="$_verifyLoaded('done')">
      <base-card-header :title="$t('tags.server-calls-title')" icon="icon-money">
        <template v-slot:item-right>
          <v-select
            v-model="dashTagsSelect"
            :clearable="false"
            class="dash-select float-right ml-auto"
            :options="selectItems"
            label="name"
            :placeholder="$t('select')"
            @option:selected="onChangeDash"
          >
            <template #option="{ name }">
              {{ $tc(name, 1) }}
            </template>
            <template #selected-option="{ name }">
              {{ $tc(name, 1) }}
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes" class="arrow-down"></span>
            </template>
            <template #no-options>
              {{ $t('integrations.no-results') }}
            </template>
          </v-select>
        </template>
      </base-card-header>
      <highcharts v-if="chartOptions" :options="chartOptions"></highcharts>
    </div>
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import vSelect from 'vue-select';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import { getMetrics } from '@/services/dashboard';
import bus from '@/helpers/events/bus';
import { chartMixin, dashboardMixin, verifyMixin, verifyThensMixin } from '@/helpers/mixins';
import { formatCurrency, formatDecimal, formatPercent } from '@/utilities/formatters';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';

export default {
  name: 'DashboardServerCalls',
  components: {
    highcharts: Chart,
    BaseLoader,
    InfoCard,
    vSelect,
  },
  mixins: [verifyMixin, verifyThensMixin, dashboardMixin, chartMixin],
  data() {
    return {
      selectedItemIndex: 0,
      loadingEmbeddedTable: false,
      compareOption: 'month',
      tableEmbeddedItems: [
        {
          value: 'month',
          type: 'MoM',
          title: this.$t('dashboard.MoM'),
        },
        {
          value: 'year',
          type: 'YoY',
          title: this.$t('dashboard.YoY'),
        },
      ],
      monthYearTable: {},
      dashTagsSelect: this.$tc('tags.hits'),
      selectedOption: 'hits',
      selectItems: [
        {
          id: 'hits',
          name: this.$tc('tags.hits'),
        },
        {
          id: 'funnel',
          name: this.$t('tags.funnel'),
        },
      ],
      labels: [],
      metrics: {},
      chartOptions: {},
      chartDateOptions: [],
    };
  },
  mounted() {
    this.fetchData(this.selectedOption);
    bus.$on('render-cards', () => {
      this.fetchData(this.selectedOption);
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    onChangeEmbeddedSelect() {
      this.fetchDataEmbeddedTable(this.selectedOption);
    },
    onChangeSelect() {
      this.compareOption = 'month';
      this.fetchData(this.selectedOption);
    },
    selectMetrics(type) {
      let metrics;
      if (type === 'hits') {
        metrics = 'tags_match_rate,tags_hits';
      } else if (type === 'funnel') {
        metrics = 'tags_detail,tags_cart,tags_checkout,tags_purchase';
      }
      return metrics;
    },
    resolveChartSeries() {
      let series;
      if (this.selectedOption === 'hits') {
        series = this.serverCallsChartSeries();
      } else if (this.selectedOption === 'funnel') {
        series = this.funnelChartSeries();
      }
      return series;
    },
    serverCallsChartSeries() {
      return [
        {
          id: 'tags_hits',
          format: 'integer',
          name: this.$tc('tags.hits', 2),
          type: 'column',
          color: '#6C90F4',
          data: this.$_parseArrayToFloat(this.metrics.tags_hits),
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          id: 'tags_match_rate',
          name: this.$tc('tags.match_rate', 1),
          type: 'spline',
          color: '#ff3162',
          data: this.$_parseArrayToFloat(this.metrics.tags_match_rate),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
          yAxis: 1,
        },
      ];
    },
    funnelChartSeries() {
      return [
        {
          format: 'integer',
          name: this.$tc('dashboard.tags_detail'),
          type: 'spline',
          color: '#A05195',
          data: this.$_parseArrayToFloat(this.metrics.tags_detail),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          format: 'integer',
          name: this.$tc('dashboard.tags_cart'),
          type: 'spline',
          color: '#F8A9D6',
          data: this.$_parseArrayToFloat(this.metrics.tags_cart),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          format: 'integer',
          name: this.$tc('dashboard.tags_checkout'),
          type: 'spline',
          color: '#F4E77E',
          data: this.$_parseArrayToFloat(this.metrics.tags_checkout),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
        },
        {
          format: 'integer',
          name: this.$tc('dashboard.tags_purchase'),
          type: 'spline',
          color: '#FB8C5B',
          data: this.$_parseArrayToFloat(this.metrics.tags_purchase),
          marker: { symbol: 'line' },
          tooltip: {
            valueSuffix: '',
          },
        },
      ];
    },
    fetchData(selectedOption) {
      const metrics = this.selectMetrics(selectedOption);
      this.$_reqConfig();
      getMetrics({ metrics, graphic: 1, compare: this.compareOption })
        .then(({ data }) => {
          if (this.$_verifyData(data.metrics)) return;

          if (data.success) {
            const metricsData = data.metrics.data;
            this.labels = metricsData?.labels;
            this.metrics = metricsData?.metrics;
            this.monthYearTable = data.metrics?.table;
          }
        })
        .then(() => {
          this.chartDateOptions = this.$_resolvePlotDateFormat(this.labels);
          this.chartOptions = this.resolveChartOptions();
        })
        .catch(() => {
          this.chartOptions = null;
          this.hasError = true;
          // this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
          if (this.hasError) return;
          if (!this.chartOptions) this.$_reqConfig();
        });
    },
    fetchDataEmbeddedTable(selectedOption) {
      const metrics = this.selectMetrics(selectedOption);

      this.loadingEmbeddedTable = true;
      getMetrics({ metrics, graphic: 1, compare: this.compareOption })
        .then(({ data }) => {
          if (this.hasError == true || this.isEmpty == true) return;
          if (data.success) {
            this.monthYearTable = data.metrics?.table;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loadingEmbeddedTable = false;
        });
    },
    resolveChartOptions() {
      Highcharts.addEvent(Highcharts.Series, 'afterInit', function () {
        this.symbolUnicode =
          {
            circle: '●',
            line: '━',
          }[this.symbol] || '●';
      });
      return {
        chart: {
          zoomType: 'xy',
          height: 280,
          marginLeft: 90,
          marginRight: 90,
          style: {
            fontFamily: 'Montserrat',
            margin: '0 auto',
          },
        },
        title: {
          text: '',
        },
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          itemMarginTop: -30,
          itemMarginBottom: 25,
          itemStyle: {
            color: '#555',
            fontWeight: 600,
          },
        },
        tooltip: {
          shared: true,
          useHTML: true,
          valueDecimals: 0,
          borderWidth: 0,
          backgroundColor: '#fff',
          padding: 14,
          formatter: function formater() {
            const htmlTooltip = this.points.map(x => {
              let formattedValue = String;
              if (x.series.userOptions?.id === 'revenue') {
                formattedValue = formatCurrency(x.y);
              } else if (x.series.userOptions?.format === 'integer') {
                formattedValue = formatDecimal(x.y, 1);
              } else {
                formattedValue = formatPercent(x.y, 2);
              }
              return `<span style="color:${x.series.color}">${x.series.symbolUnicode}</span> ${x.series.name}: <b>${formattedValue}</b></br>`;
            });
            return htmlTooltip;
          },
        },
        plotOptions: {
          series: {
            marker: { enabled: false },
            pointWidth: 26,
          },
          spline: {
            marker: {
              enabled: false,
              shadow: false,
            },
            lineWidth: 3,
          },
          column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            categories: this.chartDateOptions,
            tickWidth: 0,
          },
        ],
        yAxis: [
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                if (this.value > 1000 && this.value <= 999999) return formatCurrency(this.value, { shortNumber: true });
                else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                return formatDecimal(this.value);
              },
            },
          },
          {
            title: {
              text: null,
            },
            lineWidth: 0,
            labels: {
              style: {
                width: '60px',
                whiteSpace: 'normal',
              },
              formatter: function formatter() {
                const hasRevenue = this.chart.options.series.find(item => item.id === 'revenue');
                if (hasRevenue) {
                  if (this.value > 1000 && this.value <= 999999)
                    return formatCurrency(this.value, { shortNumber: true });
                  else if (this.value >= 1000000) return formatCurrency(this.value, { shortNumber: true });
                  return formatCurrency(this.value);
                } else {
                  return formatPercent(this.value);
                }
              },
            },
            opposite: true,
          },
        ],
        series: this.resolveChartSeries(),
      };
    },
    onChangeDash(value) {
      this.selectedOption = value.id;
      this.fetchData(this.selectedOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-tags {
  min-height: 370px;

  .dash-select {
    width: 200px;
  }
  .embedded-table {
    select {
      border: 0;
      margin: 0 -25px 0 -15px;
      color: $gray-600;
    }
  }
}
</style>

<style lang="scss">
.dashboard-revenue {
  .highcharts-xaxis-labels {
    font-weight: bold;
  }
}
</style>
