<template>
  <div class="empty-state-content container">
    <slot name="image"></slot>
    <div class="esc__box">
      <h2>{{ title }}</h2>
      <p v-html="text" />
      <div>
        <span v-if="titleListItem">{{ titleListItem }}</span>
        <div class="esc__list-items">
          <slot name="list-items" />
        </div>
        <p v-if="finalText" class="esc__general-text">{{ finalText }}</p>
      </div>
      <button v-show="hasButton" class="btn" @click.prevent="execFunction">{{ buttonText }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    titleListItem: {
      type: String,
      default: '',
    },
    finalText: {
      type: String,
      default: '',
    },
    execFunction: {
      type: Function,
      default: () => {},
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: $gray-800;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
  flex-wrap: wrap;

  :slotted(img) {
    height: 150px;
    width: 150px;
    margin-right: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    width: 365px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }

  .esc__list-items {
    margin-top: 10px;
    padding-left: 0;

    :slotted(div) {
      display: flex;

      i {
        font-size: 18px;
        color: $gray-500;
        margin-right: 5px;
      }
    }
  }

  .esc__general-text {
    margin-top: 12px;
  }

  button {
    margin-top: 30px;
  }
}
</style>
