<template>
  <div class="actions-attachment-period">
    <!-- campaign active period -->
    <label>{{ $t('360.actions.period') }}</label>
    <div class="aap__datepicker-container">
      <div class="form-group aap__datepicker element--datepicker" :class="{ disabledPeriod: disabled }">
        <el-date-picker
          v-model="dateValues"
          type="daterange"
          format="DD MMM YYYY"
          value-format="YYYY-MM-DD"
          :start-placeholder="$tc('start-date')"
          :end-placeholder="$tc('end-date')"
          :clearable="false"
          :disabled-date="disabledBackwardDates"
          :prefix-icon="customPrefix"
        />
      </div>
      <transition name="fade">
        <div
          v-show="(startDate && endDate && !disabled) || (formattedDates && !disabled)"
          class="aap__icon-container"
          @click="deleteActivePeriodDate()"
        >
          <icon-base :color="'#EC3942'" class="aap__trash-icon">
            <icon-trash />
          </icon-base>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// libs
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { h, shallowRef } from 'vue';

// helpers
import { checkboxMixin } from '@/helpers/mixins';
import { dateCompleteVariation } from '@/utilities/constants';

// utilities
import { disabledBackwardDates } from '@/utilities/datepicker';

// assets
import IconBase from '@/assets/vue-icons/IconBase';
import IconTrash from '@/assets/vue-icons/icons/Trash';

export default {
  name: 'SuggestionDetailsPeriod',
  components: {
    IconBase,
    IconTrash,
  },
  setup() {
    const customPrefix = shallowRef({
      render() {
        return h('i', {
          class: 'icon icon-calendar-30px',
        });
      },
    });
    return { customPrefix };
  },
  mixins: [checkboxMixin],
  data() {
    return {
      campaign: {
        startDate: '',
        endDate: '',
      },
    };
  },
  props: {
    scope: {
      default: 'brand',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    scope() {
      this.checkStateDatepicker();
    },
  },
  computed: {
    ...mapState('actionAttachments', ['periodStartDate', 'periodEndDate']),
    startDate() {
      return this.periodStartDate;
    },
    endDate() {
      return this.periodEndDate;
    },

    formattedDates() {
      if (!this.startDate && !this.endDate) return;
      return `${moment(this.startDate).format(dateCompleteVariation)} - ${moment(this.endDate).format(
        dateCompleteVariation,
      )}`;
    },

    dateValues: {
      get() {
        const dates = {
          startDate: this.periodStartDate,
          endDate: this.periodEndDate,
        };
        return [dates.startDate, dates.endDate];
      },
      set(newDate) {
        this.campaign.startDate = newDate[0];
        this.campaign.endDate = newDate[1];
        this.updateDates(this.campaign);
      },
    },
  },
  methods: {
    ...mapActions('actionAttachments', ['updateDates']),

    /**
     * @description delete active period date
     */
    deleteActivePeriodDate() {
      this.updateDates({});
    },

    checkStateDatepicker() {
      const isScopeBrandOrStore = this.scope === 'brand' || this.scope === 'store';
      if (isScopeBrandOrStore) this.deleteActivePeriodDate();
    },
    disabledBackwardDates,
  },
};
</script>

<style lang="scss" scoped>
.actions-attachment-period {
  .aap__datepicker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .aap__icon-container {
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      .aap__trash-icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
  }

  .aap__datepicker {
    margin-bottom: 0;
    position: relative;
    width: 100%;
  }
}
</style>
<style lang="scss">
.aap__datepicker {
  .el-date-editor .el-range-input {
    width: 30%;
  }
  .el-date-editor .el-range-separator {
    flex: 0;
  }
}
</style>
