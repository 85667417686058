import axios from 'axios';
import store from '@/store';
import endpointVersion from '@/helpers/endpoint-version';

export const getDataInconsistency = (fileType?: string, responseType?: 'blob') => {
  const brand = store.getters.selectedBrand;
  const tokenV3 = store.state.auth.tokenV3;

  return axios.get('/integration/getDataInconsistency', {
    baseURL: endpointVersion('v3'),
    params: {
      brand: brand.id,
      export: fileType,
    },
    responseType,
    headers: { Authorization: `Bearer ${tokenV3}` },
  });
};
