<template>
  <el-dialog v-model="isVisible" :id="id" class="comparison-stores-table-settings" width="530">
    <div class="title">
      <h2>{{ $t('table-settings.title') }}</h2>
      <p>{{ $t('table-settings.subtitle') }}</p>
    </div>
    <div class="row">
      <div class="col col-6">
        <h3>{{ $t('dimensions') }}</h3>
        <div class="dimensions">
          <div class="checkboxes">
            <div v-for="item in dimensions" :key="item.key" class="checkbox">
              <label :title="item.name" @click.prevent="$_toggleSelection(item.key, selectedDimensions)">
                <check-box :checked="$_isSelected(item.key, selectedDimensions)" />
                <div class="text">
                  <span class="name">{{ item.name }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-6">
        <h3>{{ $t('metrics') }}</h3>
        <div class="metrics">
          <div class="checkboxes">
            <div v-for="item in metrics" :key="item.key" class="checkbox">
              <label :title="item.name" @click.prevent="$_toggleSelection(item.key, selectedMetrics)">
                <check-box :checked="$_isSelected(item.key, selectedMetrics)" />
                <div class="text">
                  <span class="name">{{ item.name }}</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="confirm">
      <button class="btn btn-clean mr-3" @click.prevent="reset">
        {{ $t('table-settings.clear-selection') }}
      </button>
      <button
        class="btn btn-submit"
        @click.prevent="submit"
        v-tooltip.right="{
          theme: 'default',
          content: `<div class='csts--tooltip'>${$t('comparison-metrics-error')}</div>`,
          shown: isShowingTooltip,
          html: true,
          triggers: [],
          distance: 8,
        }"
      >
        {{ $t('table-settings.apply') }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable no-param-reassign */
import bus from '@/helpers/events/bus';
import CheckBox from '@/components/_atoms/CheckBox';
import getTableMetrics from '@/services/getTableMetrics';

export default {
  components: {
    'check-box': CheckBox,
  },
  data() {
    return {
      id: 'comparison-stores-table-settings',
      metrics: [],
      dimensions: [],
      selectedMetrics: [
        'customers_new',
        'customers_active',
        'customers_lost',
        'customers_inactive',
        'revenue',
        'orders',
      ],
      selectedDimensions: ['store_name'],
      isVisible: false,
      isShowingTooltip: false,
    };
  },
  watch: {
    isVisible() {
      if (!this.isVisible) this.isShowingTooltip = false;
    },
  },
  mounted() {
    this.getMetrics();
    bus.$on('comparison-stores-settings', () => (this.isVisible = true));
  },
  beforeUnmount() {
    bus.$off('comparison-stores-settings');
  },
  methods: {
    getMetrics() {
      getTableMetrics({
        endpoint: 'stores/get_table_metrics',
        table: 'stores',
      }).then(response => {
        const { metrics, dimensions } = response?.data?.table_metrics;
        const keysMet = Object.keys(metrics);
        const keysDim = Object.keys(dimensions);

        this.metrics = keysMet.reduce((result, item, i) => {
          result[i] = {
            key: metrics[item].key,
            name: metrics[item].name,
          };
          return result;
        }, {});
        this.dimensions = keysDim.reduce((result, item, i) => {
          result[i] = {
            key: dimensions[item].key,
            name: dimensions[item].name,
          };
          return result;
        }, {});
      });
    },
    $_isSelected(key, obj) {
      return !!obj.includes(key);
    },
    $_toggleSelection(key, obj) {
      if (this.$_isSelected(key, obj)) this.$_unselectOption(key, obj);
      else this.$_selectOption(key, obj);
    },
    $_selectOption(key, obj) {
      if (!this.$_isSelected(key, obj)) obj.push(key);
    },
    $_unselectOption(key, obj) {
      if (!this.$_isSelected(key, obj)) return;

      const index = obj.indexOf(key);
      obj.splice(index, 1);
    },
    submit() {
      if (this.selectedMetrics.length) {
        this.$parent.columns = this.selectedDimensions.concat(this.selectedMetrics);
        bus.$emit('update-table-settings');
        this.close();
      } else {
        this.isShowingTooltip = true;
      }
    },
    close() {
      this.isVisible = false;
    },
    reset() {
      this.selectedMetrics = [];
      this.selectedDimensions = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 23px;
}
h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: $gray-800;
  margin-bottom: 19px;
}
.confirm {
  text-align: center;
}
.btn {
  border: none;
  padding: 10px;
  color: $color-white;
  font-weight: 600;
  font-size: 12px;
  width: 180px;
  border-radius: 100px;
  margin-top: 16px;
}
.btn-submit {
  background-color: $oto-omni;
  &:hover {
    background-color: $blue-500;
  }
}
.btn-clean {
  background-color: $gray-600;
  &:hover {
    background-color: $gray-500;
  }
}
.checkbox {
  margin-bottom: 10px;

  &:hover {
    .check-box:not(.checked) {
      border-color: $gray-600;
    }
  }
  label {
    display: grid;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin: 0;
    grid-template-columns: 16px 1fr;

    .text {
      margin-left: 10px;
      color: $gray-700;

      .name {
        display: block;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }
}
</style>
<style lang="scss">
#comparison-stores-table-settings {
  position: absolute;
  top: 0;
  margin: 1.75rem auto;
  max-width: 530px;
  .el-dialog__body {
    padding: 25px 50px;
    padding-top: 0;
  }
}

.csts--tooltip {
  width: 200px;
}
</style>
