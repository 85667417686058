<!-- eslint-disable -->
<template>
  <div>
    <div class="progress" :data-percentage="percentage">
      <span class="progress-left">
        <span class="progress-bar"></span>
      </span>
      <span class="progress-right">
        <span class="progress-bar"></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  /** from https://codepen.io/fuzzywalrus/pen/OxYENY */
  name: 'CircleChart',
  props: {
    percentage: Number,
  },
};
</script>

<style lang="scss" scoped>
/* eslint-disable */
$borderWidth: 3px;
$animationTime: 0.5s;
$border-color-default: #dddddd;
$border-color-fill: $oto-omni;
$size: 17px;
//Create how many steps
$howManySteps: 100; //this needs to be even.
.progress {
  width: $size;
  height: $size;
  line-height: $size;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  margin-right: 13px;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-left {
    left: 0;
  }
  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-fill;
  }
  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-right {
    right: 0;
    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
}
/*
This for loop creates the necessary css animation names
Due to the split circle of progress-left and progress right,
we must use the animations on each side.
*/
@for $i from 1 through $howManySteps {
  $stepName: ($i * (100 / $howManySteps));
  //animation only the left side if below 50%
  @if $i <= ($howManySteps/2) {
    .progress[data-percentage='#{$stepName}'] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }
      .progress-left .progress-bar {
        animation: 0;
      }
    }
  }
  //animation only the right side if above 50%
  @if $i > ($howManySteps/2) {
    .progress[data-percentage='#{$stepName}'] {
      .progress-right .progress-bar {
        animation: loading-#{($howManySteps/2)}
          $animationTime
          linear
          forwards; //set the animation to longest animation
      }
      .progress-left .progress-bar {
        animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
      }
    }
  }
}
//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/ ($howManySteps/2));
  $degrees: ($degrees * $i);
  @keyframes loading-#{$i} {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate($degrees);
      transform: rotate(#{$degrees}deg);
    }
  }
}
</style>
