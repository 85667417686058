import store from '@/store';
import mixpanel from 'mixpanel-browser';

export const useMixpanelOtoAcademy = (pageViewName: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
  });
};

export const useMixpanelOpenModal = (pageViewName: string, userExists: boolean, nameButton: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    userExists,
    nameButton,
  });
};

export const useMixpanelClickAccess = (pageViewName: string, modal: string) => {
  mixpanel.track(pageViewName, {
    user: store?.getters?.user?.id,
    brand: store?.getters?.selectedBrand?.brand,
    modal,
  });
};
