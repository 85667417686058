<template>
  <base-card id="product-search" :ready="true" :title="$t('search.title')">
    <search-form />
  </base-card>
</template>
<script>
import SearchForm from '@/components/products/search/SearchForm';

export default {
  components: {
    SearchForm,
  },
};
</script>
<style lang="scss">
#product-search {
  height: 170px;
  padding: 44px 50px;
  background: url(~@/assets/img/figures/genericOtoBgBig.png) no-repeat right 0 center, white;
}
</style>
