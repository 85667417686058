import { getActionList } from '../services';
import { ResponseDataFetchActions, ParamsFetchAction } from '../models';

export const fetchActionsList = (params: ParamsFetchAction) =>
  getActionList(params).then(response => formattedData(response.data));

const formattedData = (response: ResponseDataFetchActions) => {
  return {
    data: response?.data.map(remoteAction => ({
      action: remoteAction.action,
      campaignsApproval: remoteAction.campaignsApproval,
      createDate: remoteAction.createDate,
      enabled: remoteAction.enabled,
      enabledApproval: remoteAction.enabledApproval,
      id: remoteAction.id,
      isActivable: remoteAction.isActivable,
      period: remoteAction.period,
      priority: remoteAction.priority,
      actionCategory: remoteAction.actionCategory,
    })),
    errors: response.errors,
    columns: response?.series?.map(serie => ({ label: serie.name, key: serie.key })),
    totalCount: response?.totalCount,
    success: response?.success,
  };
};
