<template>
  <div id="product-best-seller-skeleton" class="table-list col-12" :data="data" :type="type" :loading="true">
    <table-list-header class="p-bottom">
      <header class="d-flex w-100">
        <skeleton-loader width="145px" height="14px" />
      </header>
      <table-list-column class-name="col-3 p-0">
        <skeleton-loader width="25px" height="14px" />
      </table-list-column>
      <table-list-column v-for="item in 3" :key="item" class-name="text-right col-3">
        <skeleton-loader width="85px" height="14px" />
      </table-list-column>
    </table-list-header>
    <table-list-body :remove-last-line="true">
      <table-list-row v-for="row in rows" :key="row">
        <table-list-column class-name="text-left col-3 pl-0 pr-0">
          <skeleton-loader width="95px" height="14px" />
        </table-list-column>
        <table-list-column v-for="item in 3" :key="item" class-name="text-right col-3">
          <skeleton-loader width="75px" height="14px" />
        </table-list-column>
      </table-list-row>
      <div class="bottom-row d-flex">
        <skeleton-loader width="140px" height="14px" />
      </div>
    </table-list-body>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/_atoms/SkeletonLoader';

export default {
  components: {
    'skeleton-loader': SkeletonLoader,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    rows() {
      const length = this.data.limit;
      const arr = new Array(length);

      for (let i = 0; i < length; i += 1) {
        arr[i] = (i + 1).toString();
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 6px 0 32px 0;
  line-height: 16px;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.p-bottom {
  padding-bottom: 36px;
}
.bottom-row {
  padding: 14px 20px;
  justify-content: flex-end;
}
</style>
