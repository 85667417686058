<template>
  <base-card-v2 class="category-most-purchased" :ready="!loading">
    <div v-show="$_verifyLoaded('done')">
      <base-card-header icon="icon-customer" :title="$t('categories.most-purchased.title')" />
      <div class="row mt-4">
        <div v-if="checkEmptyValue.size" class="col-6">
          <mini-bar-chart v-if="readySize" :prop-data="jsonSize" />
        </div>
        <div v-if="checkEmptyValue.brand" class="col-6">
          <mini-bar-chart v-if="readyBrand" :prop-data="jsonBrand" />
        </div>
      </div>
      <div class="row pt-4">
        <div v-if="checkEmptyValue.color" class="col-6">
          <mini-bar-chart v-if="readyColor" :prop-data="jsonColor" />
        </div>
      </div>
      <bottom-button :typecard="'card'">
        <template v-slot:bottom-button>
          <label
            class="link color-oto-brand-omni col-auto text-right mr-3"
            @click="openLightbox('lightbox-category-most-purchased', productInfo)"
            >{{ $t('categories.see-all') }}</label
          >
        </template>
      </bottom-button>
    </div>
    <empty-message v-if="hasEmptyData && !loading" />
    <base-loader v-show="$_verifyLoaded('loading')" />
    <info-card v-show="$_verifyLoaded('info')" ref="message-error" :error="hasError" />
  </base-card-v2>
</template>

<script>
import { getTable } from '@/services/categories';
import { openLightbox } from '@/helpers/lightbox';
import bus from '@/helpers/events/bus';
import EmptyMessage from '@/components/_atoms/ShortEmptyMessage';
import MiniBarChart from '@/components/MiniBarChart';
import miniBarChartMixin from '@/helpers/mixins/miniBarChartMixin';
import BottomButton from '@/components/_atoms/BaseCardBottomButton';
import BaseLoader from '@/components/_atoms/BaseLoaderSpinner';
import InfoCard from '@/components/_atoms/InfoCard';
import verifyMixin from '@/helpers/mixins/verifyMixin';

export default {
  name: 'CategoryMostPurchased',
  components: {
    'mini-bar-chart': MiniBarChart,
    BaseLoader,
    EmptyMessage,
    BottomButton,
    InfoCard,
  },
  mixins: [verifyMixin, miniBarChartMixin],
  props: {
    category: {
      type: String,
      default: null,
      required: true,
    },
    productInfo: {
      type: [Object, Function],
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      size: [],
      brand: [],
      color: [],
      readySize: false,
      readyBrand: false,
      readyColor: false,
      loading: false,
      valueTotalSize: 0,
      valueTotalBrand: 0,
      valueTotalColor: 0,
      checkEmptyValue: {
        size: true,
        brand: true,
        color: true,
      },
    };
  },
  computed: {
    jsonSize() {
      return this.$_mountMiniBarTotalRange(
        'blue',
        this.$tc('categories.most-purchased.size', 2),
        this.size,
        this.valueTotalSize,
        'quantity',
      );
    },
    jsonBrand() {
      return this.$_mountMiniBarTotalRange(
        'blue',
        this.$tc('categories.most-purchased.brand', 2),
        this.brand,
        this.valueTotalBrand,
        'quantity',
      );
    },
    jsonColor() {
      return this.$_mountMiniBarTotalRange(
        'blue',
        this.$tc('categories.most-purchased.color', 2),
        this.color,
        this.valueTotalColor,
        'quantity',
      );
    },
    hasEmptyData() {
      return !this.checkEmptyValue.size && !this.checkEmptyValue.color && !this.checkEmptyValue.brand;
    },
  },
  mounted() {
    this.fetchData();

    // called after a global date change or brand change
    bus.$on('render-cards', () => {
      this.fetchData();
    });
  },
  beforeUnmount() {
    bus.$off('render-cards');
  },
  methods: {
    fetchData() {
      this.$_reqConfig();
      getTable({
        name: this.category,
        table: 'categories_per_size',
        limit: 5,
        order_by: 'category_quantity_desc',
      })
        .then(({ data }) => {
          this.size = data.data;
          this.readySize = true;
          this.valueTotalSize = data.value_total;
          this.size.length ? (this.checkEmptyValue.size = true) : (this.checkEmptyValue.size = false);
        })
        .catch(() => {
          this.size = [];

          this.hasError = true;
          this.$_componentHeight();
        })
        .finally(() => {
          this.loading = false;
        });

      getTable({
        name: this.category,
        table: 'categories_per_brand',
        limit: 5,
        order_by: 'category_quantity_desc',
      })
        .then(({ data }) => {
          this.brand = data.data;
          this.readyBrand = true;
          this.valueTotalBrand = data.value_total;
          this.brand.length ? (this.checkEmptyValue.brand = true) : (this.checkEmptyValue.brand = false);
        })
        .catch(() => {
          this.brand = [];
        })
        .finally(() => {
          this.loading = false;
        });

      getTable({
        name: this.category,
        table: 'categories_per_color',
        limit: 5,
        order_by: 'category_quantity_desc',
      })
        .then(({ data }) => {
          this.color = data.data;
          this.readyColor = true;
          this.valueTotalColor = data.value_total;
          this.color.length ? (this.checkEmptyValue.color = true) : (this.checkEmptyValue.color = false);
        })
        .catch(() => {
          this.color = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openLightbox(id, settings) {
      openLightbox(id, settings);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  min-height: 378px;
}
</style>
