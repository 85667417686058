import { getRevenueMetrics, getRevenue } from '@/services/indicators/RevenueRanking';
import { RevenueMetricsType, RevenueMetricsTypeResponse, TopRevenueTypeResponse } from '../types';

// fetch and format fetchFollowUpMetrics
export const fetchRevenueMetrics = ({
  source,
  storeId,
  sellerId,
  campaignId,
  lookback,
}: RevenueMetricsType): Promise<RevenueMetricsTypeResponse> =>
  getRevenueMetrics({ source, storeId, sellerId, campaignId, lookback }).then(response =>
    formatRevenueMetricsResponse(response.data),
  );

const formatRevenueMetricsResponse = (response: RevenueMetricsTypeResponse): RevenueMetricsTypeResponse => ({
  data: response?.data,
  errors: response?.errors,
  success: response?.success,
});

// fetch and format fechTopRevenue
export const fechTopRevenue = ({
  source,
  storeId,
  sellerId,
  campaignId,
  lookback,
}: RevenueMetricsType): Promise<TopRevenueTypeResponse> =>
  getRevenue({ source, storeId, sellerId, campaignId, lookback }).then(response =>
    formatTopRevenueResponse(response.data),
  );

const formatTopRevenueResponse = (response: TopRevenueTypeResponse): TopRevenueTypeResponse => ({
  data: response?.data,
});
