<template>
  <div class="cashback__select">
    <div class="w-100">
      <label>{{ $tc('_status', 2) }}</label>
      <v-select
        v-model="selectedStatus"
        :disabled="!getPermissionCreate"
        :clearable="false"
        :searchable="false"
        :options="status"
        :reduce="status => status.id"
        label="name"
        :placeholder="$tc('_select', 2)"
        @option:selected="onChangeStatus"
      >
        <template #option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #selected-option="{ name }">
          {{ $tc(name, 1) }}
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes" class="arrow-down"></span>
        </template>
        <template #no-options>
          {{ $t('integrations.no-results') }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'CashbackSelect',
  components: {
    vSelect,
  },
  data() {
    return {
      selectedStatus: 'enabled',
      status: [
        {
          id: 'enabled',
          name: '_active',
        },
        {
          id: 'disabled',
          name: '_inactive',
        },
      ],
    };
  },
  computed: {
    ...mapState('cashback', ['cashbackDetails']),
    ...mapGetters('cashback', ['getPermissionCreate']),
    validateStatus() {
      return this.cashbackDetails.enabled ? 'enabled' : 'disabled';
    },
  },
  mounted() {
    this.selectedStatus = this.validateStatus;
  },
  methods: {
    ...mapActions('cashback', ['setCashbackStatus']),

    onChangeStatus() {
      this.setCashbackStatus(this.selectedStatus);
    },
  },
};
</script>

<style lang="scss" scoped>
.cashback__select {
  display: flex;
  width: 240px;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
</style>

<style lang="scss">
.cashback__select {
  @import '@/assets/scss/vendors/_v-select';
}
</style>
